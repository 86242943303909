import { DropdownItemEvent, DropdownItemValue } from '@vip/ui/dropdown-menu';
export type SelectValue = DropdownItemValue;
export type SelectEventType = 'selectItem';

export class SelectEvent extends Event {
  constructor(
    type: SelectEventType,
    public value: SelectValue,
    public text?: string,
    public originalEvent?: KeyboardEvent | DropdownItemEvent
  ) {
    super('DropdownItemEvent.' + type, {
      bubbles: originalEvent?.bubbles,
      cancelable: originalEvent?.cancelable,
      composed: originalEvent?.composed,
    });
  }
}
