import { Injectable } from '@angular/core';
import { EnvironmentService, IAplicativo } from '@vip/core';
import { BaseApiResponse } from '../common/base-api-response';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AplicativoApiService extends HttpClient {
  private readonly _defaultHeaders: HttpHeaders;
  private readonly baseUrl = this.environmentService.getApiAplicativosUrl();

  constructor(
    _httpHandler: HttpHandler,
    private environmentService: EnvironmentService
  ) {
    super(_httpHandler);
    this._defaultHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  public override get<T>(
    url: string,
    options?: Record<string, unknown>
  ): Observable<T> {
    return super.get<T>(this.baseUrl + url, {
      ...options,
      headers: this._defaultHeaders,
    });
  }

  public override post<T>(
    url: string,
    body: unknown,
    options?: Record<string, unknown>
  ): Observable<T> {
    return super.post<T>(this.baseUrl + url, body, {
      ...options,
      headers: this._defaultHeaders,
    });
  }

  public override put<T>(
    url: string,
    body: unknown,
    options?: Record<string, unknown>
  ): Observable<T> {
    return super.put<T>(this.baseUrl + url, body, {
      ...options,
      headers: this._defaultHeaders,
    });
  }

  public override delete<T>(
    url: string,
    options?: Record<string, unknown>
  ): Observable<T> {
    return super.delete<T>(this.baseUrl + url, {
      ...options,
      headers: this._defaultHeaders,
    });
  }

  getAplicativo(id: number) {
    return this.get<BaseApiResponse<{ aplicativo: IAplicativo }>>(
      `/aplicativos/${id}`
    );
  }

  getAplicativoFromVipcommerceFilialId(filialId: number) {
    return this.get<BaseApiResponse<{ aplicativo: IAplicativo }>>(
      `/aplicativos/filiais/${filialId}/aplicativos`
    );
  }
}
