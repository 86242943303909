import {
  ICentroDistribuicao,
  IEndereco,
  TipoExibicaoRetiradaEnum,
} from '@vip/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'vip-card-endereco-entrega',
  templateUrl: './card-endereco-entrega.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardEnderecoEntregaComponent {
  tipoExibicaoRetirada = TipoExibicaoRetiradaEnum;

  @Input() cd?: ICentroDistribuicao;
  @Input() endereco?: IEndereco;
  @Input() selecionado = false;
  @Input() isCard = true;
  @Input() showBody = false;
  @Input() isIconLocation = false;
  @Output()
  changed: EventEmitter<ICentroDistribuicao | IEndereco> = new EventEmitter();
}
