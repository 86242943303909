import { createFeatureSelector, createSelector } from '@ngrx/store';
import { COMBO_FEATURE_KEY, ComboState, comboAdapter } from './combo.reducer';
import { GenericStoreStatusEnum } from '@vip/state/utils';

const { selectEntities } = comboAdapter.getSelectors();

export const selectComboFeature =
  createFeatureSelector<ComboState>(COMBO_FEATURE_KEY);

export const getAllCombos = createSelector(
  selectComboFeature,
  (state: ComboState) => state.combos
);

export const getComboArrays = createSelector(
  selectComboFeature,
  (state: ComboState) => selectEntities(state)
);

export const getComboProdutos = createSelector(
  selectComboFeature,
  (state: ComboState) => selectEntities(state)
);

export const getComboProdutosByArray = createSelector(
  selectComboFeature,
  (state: ComboState) => selectEntities(state)
);

export const getCombosProdutosArray = createSelector(
  selectComboFeature,
  (state: ComboState) => state.produtos
);

export const isLoading = createSelector(
  selectComboFeature,
  (state: ComboState) => state.status === GenericStoreStatusEnum.LOADING
);

export const getCombosDepartamentos = createSelector(
  selectComboFeature,
  (state: ComboState) => state.combos
);
