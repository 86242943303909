<nav>
  <ul class="flex items-center justify-center">
    <li class="mr-2">
      <vip-button
        [customSize]="true"
        [disabled]="page <= 1"
        type="outline"
        size="small"
        data-cy="vip-btn-prev-page"
        (btnClick)="handlePageClick(page - 1)"
      >
        <span class="text-2xl icon-chevron_left"></span>
      </vip-button>
    </li>
    <li *ngFor="let numberPage of pages; let index = index" class="mr-2">
      <vip-button
        [customSize]="true"
        [disabled]="numberPage === 0"
        [type]="numberPage === paginator.page ? 'raised' : 'normal'"
        [attr.data-cy]="
          numberPage === 0 ? 'vip-btn-page-ellipsis' : 'vip-btn-page-number'
        "
        size="small"
        class="text-sm"
        (btnClick)="handlePageClick(numberPage)"
        >{{ numberPage === 0 ? '...' : numberPage }}</vip-button
      >
    </li>
    <li class="ml-2">
      <vip-button
        [customSize]="true"
        [disabled]="page >= totalPages"
        type="outline"
        size="small"
        data-cy="vip-btn-next-page"
        (btnClick)="handlePageClick(page + 1)"
      >
        <span class="text-2xl icon-chevron_right"></span>
      </vip-button>
    </li>
  </ul>
</nav>
