import { ICentroDistribuicao } from '../interfaces/centro-distribuicao.interface';

export const cd1Mock = {
  id: 1,
  nome: 'Principal',
  nome_site: 'Principal',
  observacao_retirada: 'CD Express - limite de 100 itens',
  tipo_exibicao_retirada: 2,
} as ICentroDistribuicao;

export const cd2Mock = {
  id: 2,
  nome: 'Secundário',
  nome_site: 'Secundário',
  endereco: {
    cep: '32315040',
    logradouro: 'Avenida José Faria da Rocha',
    numero: '1000',
    complemento: '',
    bairro: 'Eldorado',
    cidade: 'Contagem',
    estado: 'Minas Gerais',
  },
  tipo_exibicao_retirada: 1,
} as ICentroDistribuicao;

export const cd3Mock = {
  id: 3,
  nome: 'Secundário',
  nome_site: 'Secundário',
  endereco: {
    cep: '32315040',
    logradouro: 'Avenida José Faria da Rocha',
    numero: '1000',
    complemento: '',
    bairro: 'Eldorado',
    cidade: 'Contagem',
    estado: 'Minas Gerais',
  },
  tipo_exibicao_retirada: 3,
} as ICentroDistribuicao;

export const cdLojaAutonomaMock = {
  id: 3,
  nome: 'Loja Autonoma',
  nome_site: 'Loja Autonoma',
  endereco: {
    cep: '32315040',
    logradouro: 'Avenida José Faria da Rocha',
    numero: '1000',
    complemento: '',
    bairro: 'Eldorado',
    cidade: 'Contagem',
    estado: 'Minas Gerais',
  },
  tipo_exibicao_retirada: 1,
  loja_autonoma: true,
} as ICentroDistribuicao;
