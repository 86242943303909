import {
  ofertaAtacarejoMock,
  ofertaAtacarejoUmaFaixaMock,
  ofertaComboMock,
  ofertaLevePagueMock,
  ofertaMaisPorMenosMock,
  ofertaPrecoVipMock,
} from './oferta.mock';
import { IProduto } from './../interfaces/produto.interface';
import {
  colecaoIconeLabelMock,
  colecaoIconeMock,
} from './produto-colecoes.mock';
import { unidadeFracao500gMock } from './unidade-fracao.mock';
import { IOferta } from '../interfaces';
import { TipoOfertaEnum } from '../enums';

export const ofertaMock: IOferta = {
  categoria: 'default-category',
  exibe_preco_original: false,
  familia: false,
  menor_preco: 1.99,
  classe_oferta: 'default-class',
  nome: '',
  oferta_id: 0,
  oferta_item_id: '',
  preco_antigo: 0,
  preco_antigo_fracao: 0,
  preco_oferta: 0,
  preco_oferta_fracao: 0,
  preco_original: 0,
  quantidade_maxima: 917,
  quantidade_minima: 0,
  quantidade_oferta: 0,
  quantidade_pagar: 0,
  tag: '',
  tipo_oferta_id: TipoOfertaEnum.PRODUTO_COM_DESCONTO,
};

export const produtoSemOfertaMock: IProduto = {
  bebida_alcoolica: false,
  classificacao_mercadologica_id: 13,
  codigo_barras: '7897123884142',
  colecoes: [colecaoIconeLabelMock, colecaoIconeMock],
  descricao: 'Água Mineral Natural Prata  1,5l Alcalina Pet',
  disponivel: true,
  em_oferta: false,
  exibe_preco_original: false,
  id: 1111,
  imagem: 'https://picsum.photos/130',
  link: 'agua-mineral-natural-prata-15l-alcalina-pet',
  oferta: ofertaMock,
  permitir_observacao_na_compra: false,
  possui_unidade_diferente: false,
  preco: 4.45,
  preco_original: 0,
  produto_id: 1111,
  quantidade_maxima: 917,
  quantidade_minima: 1,
  quantidade_unidade_diferente: 1,
  quantidade_vendida: 0,
  tipo: 1,
  unidade_sigla: 'UN',
  volume_principal: null,
  classe_oferta: 'some-default-class',
};

export const produtoIndisponivelMock: IProduto = {
  ...produtoSemOfertaMock,
  id: 1010,
  produto_id: 1010,
  disponivel: false,
};

export const produtoOfertaLevePagueMock: IProduto = {
  ...produtoSemOfertaMock,
  id: 2222,
  produto_id: 2222,
  em_oferta: true,
  oferta: ofertaLevePagueMock,
  anunciado: true,
};

export const produtoDescontoSimplesMock: IProduto = {
  ...produtoSemOfertaMock,
  id: 3333,
  produto_id: 3333,
  em_oferta: true,
  oferta: ofertaPrecoVipMock,
};

export const produtoAtacarejoMock: IProduto = {
  ...produtoSemOfertaMock,
  id: 4444,
  produto_id: 4444,
  em_oferta: true,
  oferta: ofertaAtacarejoMock,
};

export const produtoAtacarejoUmaFaixaMock: IProduto = {
  ...produtoSemOfertaMock,
  id: 5555,
  produto_id: 5555,
  em_oferta: true,
  oferta: ofertaAtacarejoUmaFaixaMock,
};

export const produtoOfertaMaisPorMenosMock: IProduto = {
  ...produtoSemOfertaMock,
  id: 6666,
  produto_id: 6666,
  em_oferta: true,
  oferta: ofertaMaisPorMenosMock,
};

export const produtoOfertaComboMock: IProduto = {
  ...produtoSemOfertaMock,
  id: 7777,
  produto_id: 7777,
  em_oferta: true,
  oferta: ofertaComboMock,
};

export const produtoMiniSemOfertaMock: IProduto = {
  ...produtoSemOfertaMock,
  imagem: 'https://picsum.photos/100',
};

export const produtoMiniOfertaLevePagueMock: IProduto = {
  ...produtoOfertaLevePagueMock,
  imagem: 'https://picsum.photos/100',
};

export const produtoMiniDescontoSimplesMock: IProduto = {
  ...produtoDescontoSimplesMock,
  imagem: 'https://picsum.photos/100',
};

export const produtoMiniAtacarejoMock: IProduto = {
  ...produtoAtacarejoMock,
  imagem: 'https://picsum.photos/100',
};

export const produtoUnidadeDiferenteKGMock: IProduto = {
  ...produtoSemOfertaMock,
  id: 8888,
  produto_id: 8888,
  exibe_preco_original: true,
  preco_original: 8.9,
  possui_unidade_diferente: true,
  quantidade_unidade_diferente: 0.5,
  unidade_sigla: 'KG',
};

export const produtoUnidadeDiferenteUNMock: IProduto = {
  ...produtoUnidadeDiferenteKGMock,
  id: 9999,
  produto_id: 9999,
  unidade_sigla: 'UN',
};

export const produtoUnidadeDiferenteKGOfertaDescontoSimplesMock: IProduto = {
  ...produtoUnidadeDiferenteKGMock,
  em_oferta: true,
  oferta: ofertaPrecoVipMock,
};

export const produtoUnidadeDiferenteKGOfertaLevePagueMock: IProduto = {
  ...produtoUnidadeDiferenteKGMock,
  em_oferta: true,
  oferta: ofertaLevePagueMock,
};

export const produtoUnidadeDiferenteKGOfertaAtacarejoMock: IProduto = {
  ...produtoUnidadeDiferenteKGMock,
  em_oferta: true,
  oferta: ofertaAtacarejoMock,
};

export const produtoUnidadeDiferenteKGOfertaAtacarejoUmaFaixaMock: IProduto = {
  ...produtoUnidadeDiferenteKGMock,
  em_oferta: true,
  oferta: ofertaAtacarejoUmaFaixaMock,
};

export const produtoUnidadeDiferenteKGOfertaMaisPorMenosMock: IProduto = {
  ...produtoUnidadeDiferenteKGMock,
  em_oferta: true,
  oferta: ofertaMaisPorMenosMock,
};

export const produtoUnidadeDiferenteKGOfertaComboMock: IProduto = {
  ...produtoUnidadeDiferenteKGMock,
  em_oferta: true,
  oferta: ofertaComboMock,
};

export const produtoUnidadeFracao500gSemOfertaMock: IProduto = {
  ...produtoSemOfertaMock,
  unidade_fracao: unidadeFracao500gMock,
};

export const produtoUnidadeFracao500gOfertaDescontoSimplesMock: IProduto = {
  ...produtoSemOfertaMock,
  unidade_fracao: unidadeFracao500gMock,
  em_oferta: true,
  oferta: ofertaPrecoVipMock,
};

export const produtoUnidadeFracao500gOfertaLevePagueMock: IProduto = {
  ...produtoSemOfertaMock,
  unidade_fracao: unidadeFracao500gMock,
  em_oferta: true,
  oferta: ofertaLevePagueMock,
};

export const produtoUnidadeFracao500gOfertaAtacarejoMock: IProduto = {
  ...produtoSemOfertaMock,
  unidade_fracao: unidadeFracao500gMock,
  em_oferta: true,
  oferta: ofertaAtacarejoMock,
};

export const produtoUnidadeFracao500gOfertaAtacarejoUmaFaixaMock: IProduto = {
  ...produtoSemOfertaMock,
  unidade_fracao: unidadeFracao500gMock,
  em_oferta: true,
  oferta: ofertaAtacarejoUmaFaixaMock,
};

export const produtoUnidadeFracao500gOfertaMaisPorMenosMock: IProduto = {
  ...produtoSemOfertaMock,
  unidade_fracao: unidadeFracao500gMock,
  em_oferta: true,
  oferta: ofertaMaisPorMenosMock,
};

export const produtoUnidadeFracao500gOfertaComboMock: IProduto = {
  ...produtoSemOfertaMock,
  unidade_fracao: unidadeFracao500gMock,
  em_oferta: true,
  oferta: ofertaComboMock,
};

export const produtoUnidadeDiferenteEUnidadeFracaoKGMock: IProduto = {
  ...produtoUnidadeDiferenteKGMock,
  unidade_fracao: unidadeFracao500gMock,
};
export const produtoPatrocinadoMock1: IProduto = {
  ...produtoUnidadeDiferenteKGMock,
  unidade_fracao: unidadeFracao500gMock,
  anunciado: true,
};

export const produtoUnidadeDiferenteEUnidadeFracaoKGOfertaDescontoSimplesMock: IProduto =
  {
    ...produtoUnidadeDiferenteEUnidadeFracaoKGMock,
    em_oferta: true,
    oferta: ofertaPrecoVipMock,
    unidade_fracao: unidadeFracao500gMock,
  };

export const produtoUnidadeDiferenteEUnidadeFracaoKGOfertaLevePagueMock: IProduto =
  {
    ...produtoUnidadeDiferenteEUnidadeFracaoKGMock,
    em_oferta: true,
    oferta: ofertaLevePagueMock,
    unidade_fracao: unidadeFracao500gMock,
  };

export const produtoUnidadeDiferenteEUnidadeFracaoKGOfertaAtacarejoMock: IProduto =
  {
    ...produtoUnidadeDiferenteEUnidadeFracaoKGMock,
    em_oferta: true,
    oferta: ofertaAtacarejoMock,
    unidade_fracao: unidadeFracao500gMock,
  };

export const produtoUnidadeDiferenteEUnidadeFracaoKGOfertaAtacarejoUmaFaixaMock: IProduto =
  {
    ...produtoUnidadeDiferenteEUnidadeFracaoKGMock,
    em_oferta: true,
    oferta: ofertaAtacarejoUmaFaixaMock,
    unidade_fracao: unidadeFracao500gMock,
  };

export const produtoUnidadeDiferenteEUnidadeFracaoKGOfertaMaisPorMenosMock: IProduto =
  {
    ...produtoUnidadeDiferenteEUnidadeFracaoKGMock,
    em_oferta: true,
    oferta: ofertaMaisPorMenosMock,
    unidade_fracao: unidadeFracao500gMock,
  };

export const produtoUnidadeDiferenteEUnidadeFracaoKGOfertaComboMock: IProduto =
  {
    ...produtoUnidadeDiferenteEUnidadeFracaoKGMock,
    em_oferta: true,
    oferta: ofertaComboMock,
    unidade_fracao: unidadeFracao500gMock,
  };

export const productsMock = [
  produtoSemOfertaMock,
  produtoOfertaLevePagueMock,
  produtoDescontoSimplesMock,
  produtoOfertaMaisPorMenosMock,
  produtoOfertaComboMock,
  produtoAtacarejoUmaFaixaMock,
  produtoAtacarejoMock,
  produtoIndisponivelMock,
];

export const produtosUnidadeDiferenteMock = [
  produtoUnidadeDiferenteKGMock,
  produtoUnidadeDiferenteUNMock,
  produtoUnidadeDiferenteKGOfertaDescontoSimplesMock,
  produtoUnidadeDiferenteKGOfertaLevePagueMock,
  produtoUnidadeDiferenteKGOfertaComboMock,
  produtoUnidadeDiferenteKGOfertaMaisPorMenosMock,
  produtoUnidadeDiferenteKGOfertaAtacarejoUmaFaixaMock,
  produtoUnidadeDiferenteKGOfertaAtacarejoMock,
];

export const produtosUnidadeFracaoMock = [
  produtoUnidadeFracao500gSemOfertaMock,
  produtoUnidadeFracao500gOfertaDescontoSimplesMock,
  produtoUnidadeFracao500gOfertaLevePagueMock,
  produtoUnidadeFracao500gOfertaComboMock,
  produtoUnidadeFracao500gOfertaMaisPorMenosMock,
  produtoUnidadeFracao500gOfertaAtacarejoUmaFaixaMock,
  produtoUnidadeFracao500gOfertaAtacarejoMock,
];
export const produtosUnidadeDiferenteEUnidadeFracaoMock = [
  produtoUnidadeDiferenteEUnidadeFracaoKGMock,
  produtoUnidadeDiferenteEUnidadeFracaoKGOfertaDescontoSimplesMock,
  produtoUnidadeDiferenteEUnidadeFracaoKGOfertaLevePagueMock,
  produtoUnidadeDiferenteEUnidadeFracaoKGOfertaComboMock,
  produtoUnidadeDiferenteEUnidadeFracaoKGOfertaMaisPorMenosMock,
  produtoUnidadeDiferenteEUnidadeFracaoKGOfertaAtacarejoUmaFaixaMock,
  produtoUnidadeDiferenteEUnidadeFracaoKGOfertaAtacarejoMock,
];

export const produtosMiniMock = [
  produtoMiniSemOfertaMock,
  produtoMiniOfertaLevePagueMock,
  produtoMiniDescontoSimplesMock,
  produtoMiniAtacarejoMock,
];

export const produtosPatrocinados = [
  produtoPatrocinadoMock1,
  produtoPatrocinadoMock1,
  produtoPatrocinadoMock1,
  produtoPatrocinadoMock1,
  produtoPatrocinadoMock1,
];
