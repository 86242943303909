import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ICartao } from '@vip/core';
import * as CartaoActions from './cartao.actions';
import { cartaoSelectors } from './cartao.selectors';
import { DialogService } from '@vip/ui/modal';

@Injectable()
export class CartaoFacade {
  loading$ = this.store.pipe(select(cartaoSelectors.isLoading()));
  error$ = this.store.pipe(select(cartaoSelectors.getError()));
  cartoes$ = this.store.pipe(select(cartaoSelectors.getCartoes()));
  cartaoSelecionadoId$ = this.store.pipe(
    select(cartaoSelectors.getCartaoSelecionadoId())
  );

  constructor(private dialogService: DialogService, private store: Store) {}

  getCartoes(): void {
    this.store.dispatch(CartaoActions.getCartoes());
  }

  setCartaoSelecionado(cartaoId: string | null): void {
    this.store.dispatch(CartaoActions.setCartaoSelecionado({ cartaoId }));
  }

  deletarCartao(cartao: ICartao): void {
    this.dialogService.openDialog({
      open: true,
      title: 'Excluir cartão salvo?',
      subTitle: `Deseja realmente excluir o cartão com final 
      ${cartao.numero_cartao.slice(-4)}?`,
      disabled: false,
      buttonConfirmText: 'Excluir cartão',
      buttonCancelText: 'Manter cartão',
    });

    this.dialogService.dialogClick.subscribe((value) => {
      if (value)
        this.store.dispatch(CartaoActions.deletarCartao({ id: cartao.id }));
      this.dialogService.clearDialog();
    });
  }

  deletarCartaoSemDialog(cartaoId: string): void {
    this.store.dispatch(CartaoActions.deletarCartao({ id: cartaoId }));
  }
}
