import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermosUsoContainerComponent } from './termos-uso-container/termos-uso-container.component';
import { RouterModule } from '@angular/router';
import { TermosUsoModule } from '@vip/views/termos-uso';
import { TermosUsoStateModule } from '@vip/state/termos-uso';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: TermosUsoContainerComponent,
      },
    ]),
    TermosUsoModule,
    TermosUsoStateModule,
  ],
  declarations: [TermosUsoContainerComponent],
  exports: [TermosUsoContainerComponent],
})
export class TermosUsoContainerModule {}
