import { createSelector } from '@ngrx/store';
import { CEP_FEATURE_KEY, CepState } from './cep.reducer';
import { SelectorUtil } from '@vip/state/utils';

export class CepSelectors extends SelectorUtil<CepState> {
  constructor() {
    super(CEP_FEATURE_KEY);
  }

  cep = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CepState) => state.data.cep
    );

  enderecoDoCep = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CepState) => state.data.enderecoDoCep
    );

  enderecoDoCepFormatado = () =>
    createSelector(this.createFeatureSelector, (state: CepState) => {
      const endereco = state.data.enderecoDoCep;
      if (endereco) {
        if (endereco?.logradouro) {
          return `${endereco.logradouro} - ${endereco.bairro} - ${endereco.cidade}/${endereco.sigla}`;
        }
        return `${endereco.cep} - ${endereco.bairro} - ${endereco.cidade}/${endereco.sigla}`;
      }
      return '';
    });

  cepAtendido = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CepState) => state.data.cepAtendido
    );

  apenasRetirada = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CepState) => state.data.apenasRetirada
    );

  solicitacaoDeCepEnviada = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CepState) => state.data.solicitacaoDeCepEnviada
    );
}

export const cepSelectors = new CepSelectors();
