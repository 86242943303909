import { IProdutoSpin, ISpinEventComProduto } from '@vip/ui/spin';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';
import { DialogService, IDialog } from '@vip/ui/modal';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  IProduto,
  IProdutoCarrinho,
  IProdutoCombo,
  LayoutUtilsService,
  ProdutoUtilsService,
  StringUtilsService,
} from '@vip/core';
import { CurrencyPipe } from '@angular/common';
import { CardProdutoDirective } from '@vip/ui/card-produto';
import SwiperCore, {
  SwiperOptions,
  Autoplay,
  Navigation,
  Pagination,
} from 'swiper';
import { CompraEmProcessoService } from '@vip/state/compra-em-processo';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
SwiperCore.use([Navigation, Autoplay, Pagination]);
@UntilDestroy()
@Component({
  selector: 'vip-card-combo',
  templateUrl: './card-combo.component.html',
  styleUrls: ['./card-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CardComboComponent extends CardProdutoDirective implements OnInit {
  showPropsListaCard = false;
  showSpinLarge = true;
  swiperConfig?: SwiperOptions;

  @Input() showResume = false;
  @Input() showTagOferta = true;
  @Input() loading = false;
  @Input() editable = true;
  @Input() combosNoCarrinho?: Map<
    number,
    { item_id: number; quantidade: number; observacao?: string }
  > | null = null;
  @Input() disabled = false;
  @Input() isDesktop = false;
  @Input() hasProductInfo = false;
  @Input() fullPage = false;
  @Input() comboCategory = false;
  @Input() hasCarrinho = false;
  @Input() hasCombo = false;
  @Output() deleteItemClick = new EventEmitter();

  itensMap: { [k: string]: string } = {
    '=1': '1 item',
    other: '# itens',
  };

  combosMap: { [k: string]: string } = {
    '=1': '1 combo',
    other: '# combos',
  };

  isTablet$ = this.layoutUtilsService.isTablet$;
  isTablet = false;
  quantidadeMaximaSpinner = 0;

  constructor(
    private dialogService: DialogService,
    public override router: Router,
    public override produtoUtilsService: ProdutoUtilsService,
    carrinhoItensFacade: CarrinhoItensFacade,
    public currencyPipe: CurrencyPipe,
    private compraEmProcessoService: CompraEmProcessoService,
    public layoutUtilsService: LayoutUtilsService
  ) {
    super(router, produtoUtilsService, carrinhoItensFacade);
    this.layoutUtilsService.screenWidth;
  }

  ngOnInit(): void {
    this.isTablet$.pipe(untilDestroyed(this)).subscribe((isTablet) => {
      this.isTablet = isTablet;
      this.swiperConfig = {
        direction: 'horizontal',
        slidesPerView:
          this.isTablet ||
          !this.hasProductInfo ||
          this.layoutUtilsService.screenWidth < 1200
            ? 3
            : this.isTablet && this.hasProductInfo
            ? 4
            : this.isTablet && this.hasCarrinho
            ? 4
            : 4,
        spaceBetween:
          !this.hasProductInfo && this.hasCarrinho && !this.isTablet ? 102 : 0,
        allowTouchMove: true,
        slidesOffsetAfter:
          !this.hasProductInfo && this.hasCarrinho && !this.isTablet ? 102 : 0,
        slidesOffsetBefore: 10,
      };
    });

    this.setMaxItemSpinCombo();
  }

  get valorDiferenca() {
    return (this.combo.preco_antigo - this.combo.preco_oferta).toFixed(2);
  }

  get quantidade(): number {
    return (
      this.combosNoCarrinho?.get(this.combo.oferta_id)?.quantidade ||
      this.combo.quantidade ||
      0
    );
  }

  override get id(): number {
    if (this.combo.item_id) return this.combo.item_id;
    return (
      this.combosNoCarrinho?.get(this.combo.oferta_id)?.item_id ||
      this.combo.oferta_id
    );
  }

  override get observacao() {
    const obsevacoesSalvas = this.combosNoCarrinho?.get(
      this.combo.oferta_id
    )?.observacao;
    if (obsevacoesSalvas) return JSON.parse(obsevacoesSalvas);
    return {};
  }

  getObservacoes(produtoCombo: IProdutoSpin) {
    const observacoes = this.observacao;
    observacoes[produtoCombo.produto_id] = produtoCombo.observacao || '';
    return observacoes;
  }

  handleProdutoAlterado(spinEvent: ISpinEventComProduto) {
    this.produtoChanged.emit({
      event: new Event('changeObservacao'),
      quantity: this.quantidade,
      produto: {
        ...this.produto,
        item_id: this.id,
        observacao: this.getObservacoes(<IProdutoSpin>spinEvent.produto),
      },
    });
  }

  getProdutoCarrinho(produto: IProduto) {
    return produto as IProdutoCarrinho;
  }

  handleDeleteItemClick(event: IProdutoCombo) {
    const produtoCombo = event;
    if (this.compraEmProcessoService.temCompraEmProcesso) return;
    if (this.disabled) return;

    const dialog: IDialog = {
      open: true,
      title: 'Deseja realmente remover o item?',
      subTitle: produtoCombo.nome || 'Descrição não disponível',
      buttonCancelText: 'Cancelar',
      buttonConfirmText: 'Remover item',
      disabled: false,
    };

    this.dialogService.openDialog(dialog);
    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        if (event) {
          this.deleteItemClick.emit(produtoCombo.item_id);
        }
        this.dialogService.clearDialog();
      });
  }

  public currencyComboProdutoView(produtoValue?: number) {
    return this.currencyPipe.transform(produtoValue);
  }

  public calcComboProdutoView(
    produtoValue?: number,
    quantidade?: number
  ): string | null {
    if (produtoValue && quantidade) {
      return this.currencyPipe.transform(produtoValue * quantidade);
    }
    return null;
  }
  public unidadeMedidaCombo(
    tipoUnidade?: string,
    quantidade: number = 1
  ): 'kg' | 'g' | 'unidades' | 'unidade' {
    switch (tipoUnidade) {
      case 'KG':
        return 'kg';
      case 'G':
        return 'g';
      case 'UN':
        return quantidade > 1 ? 'unidades' : 'unidade';
      default:
        return 'unidade';
    }
  }

  redirectToProductCombo(produto: IProduto) {
    if (produto) {
      const slug = StringUtilsService.slugify(produto.descricao);
      this.router.navigateByUrl(`/produto/${produto.produto_id}/${slug}`);
    }
  }

  public setMaxItemSpinCombo(): number | null {
    if (
      !this.combo ||
      !this.combo.produtos ||
      this.combo.produtos.length === 0
    ) {
      return null;
    }

    const minQuantidadeMaxima = this.combo.produtos.reduce((min, produto) => {
      const quantidadeMaximaOferta = produto.quantidade_maxima ?? Infinity;
      return Math.min(min, quantidadeMaximaOferta);
    }, Infinity);

    if (minQuantidadeMaxima === Infinity) {
      return null;
    }
    this.quantidadeMaximaSpinner = minQuantidadeMaxima;
    return minQuantidadeMaxima;
  }
}
