import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormaEntregaEnum,
  ICentroDistribuicao,
  ISwitchOption,
  LayoutUtilsService,
  TipoExibicaoRetiradaEnum,
} from '@vip/core';
import { EntregaRetiradaPropertiesBaseDirective } from '../entrega-retirada-properties-base.directive';

@Component({
  selector: 'vip-entrega-retirada',
  templateUrl: './entrega-retirada.component.html',
  styleUrls: ['./entrega-retirada.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntregaRetiradaComponent
  extends EntregaRetiradaPropertiesBaseDirective
  implements OnInit, OnChanges
{
  tipoExibicaoRetirada = TipoExibicaoRetiradaEnum;

  @Input()
  isMobile: boolean;

  @Input()
  loadingEnderecos = false;

  @Input() isDesktop = false;

  @Output()
  cadastrarEndereco: EventEmitter<Event> = new EventEmitter();

  @Output()
  alterarEndereco: EventEmitter<Event> = new EventEmitter();

  @Output()
  qrCodeScan: EventEmitter<boolean> = new EventEmitter();

  @Output() goBackClick = new EventEmitter();

  @Output() informarCepClick = new EventEmitter();

  @Output() confirmarClick = new EventEmitter();

  @Input()
  clienteLogado = false;

  @Input()
  qtdItensCarrinho = 0;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService
  ) {
    super();
    this.isMobile = this.layoutUtilsService.isMobile();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('formaEntrega' in changes && !changes['formaEntrega'].isFirstChange()) {
      this.verifyCheckedOptionIsDisabled();
    }

    if ('cdsRetirada' in changes && !changes['cdsRetirada'].isFirstChange()) {
      this.verifyCheckedOptionIsDisabled();
    }
  }

  ngOnInit(): void {
    this.populateTiposEntregasOptions();
  }

  handleTiposEntregasChanged(option: ISwitchOption): void {
    if (!this.tiposEntregasOptions) return;
    switch (option.key) {
      case FormaEntregaEnum.TIPO_ENTREGA:
        this.formaEntrega = FormaEntregaEnum.TIPO_ENTREGA;
        this.setEntrega(true, false);
        break;
      case FormaEntregaEnum.TIPO_RETIRADA:
        this.formaEntrega = FormaEntregaEnum.TIPO_RETIRADA;
        this.setEntrega(false, false);
        break;
      case FormaEntregaEnum.TIPO_AUTONOMA:
        this.formaEntrega = FormaEntregaEnum.TIPO_AUTONOMA;
        this.setEntrega(false, false);
        break;
    }

    this.formaEntregaChange.emit(this.formaEntrega);
  }

  handleCdsRetiradaChange(cd: ICentroDistribuicao) {
    const isEntrega = false;

    this.setEntrega(isEntrega, false);
    this.cdChange.emit({ cd, isFromRetirada: true });
  }

  private setEntrega(isEntrega: boolean, modifyState: boolean) {
    this.entregaChange.emit({ isEntrega, modifyState });
  }

  private verifyCheckedOptionIsDisabled(): void {
    if (
      this.tiposEntregasOptions &&
      this.tiposEntregasOptions['checked']?.disabled
    ) {
      for (const key in this.tiposEntregasOptions) {
        if (
          Object.prototype.hasOwnProperty.call(this.tiposEntregasOptions, key)
        ) {
          if (!this.tiposEntregasOptions[key].disabled) {
            const firstEnableOption = this.tiposEntregasOptions[key];
            this.handleTiposEntregasChanged(firstEnableOption);
            break;
          }
        }
      }
    }
  }
}
