import { Action, createReducer, on } from '@ngrx/store';

import {
  FilterTypeEnum,
  filtroOrdenacaoCombo,
  IFilter,
  IFilterCombos,
  IFilterOptions,
  PaginaFiltro,
} from '@vip/core';
import * as FiltroActions from './filtro.actions';
import { IGenericState } from '@vip/state/utils';
import { ComboActions } from '@vip/state/combo';

export const FILTRO_FEATURE_KEY = 'filtro';

interface FiltroData {
  filtroAtivo: PaginaFiltro | null;
  filtroClassificacaoMercadologica: IFilter[];
  filtrosBusca: IFilter[];
  filtroFavoritos: IFilter[];
  filtrosColecao: IFilter[];
  filtroMinhasListas: IFilter[];
  filtrosOfertas: IFilter[];
  filtrosCombos: IFilter[];
}

const setInitialOptionsCombos = (data: IFilterCombos[]) => {
  return data.map((item) => ({
    label: item.descricao,
    value: String(item.departamento_id),
    checked: false,
  }));
};

const setInitialOptionsCombosExternal = (data: IFilterOptions[]) => {
  return data.map((item) => ({
    label: item.label,
    value: String(item.value),
    checked: false,
  }));
};

const setInitialFilters = (data: IFilterCombos[]) => {
  const todos = { value: '0', label: 'Todos', checked: true };

  return [
    {
      name: 'departamento',
      type: FilterTypeEnum.INTERNAL,
      label: 'Departamento',
      multiple: false,
      options: [todos, ...setInitialOptionsCombos(data)],
    },
    {
      name: 'departamento',
      type: FilterTypeEnum.EXTERNAL,
      label: 'Departamento',
      multiple: false,
      options: setInitialOptionsCombosExternal(filtroOrdenacaoCombo.options),
    },
  ];
};

export type FiltroState = IGenericState<FiltroData>;

export const initialState: FiltroState = {
  data: {
    filtroAtivo: null,
    filtroClassificacaoMercadologica: [],
    filtrosBusca: [],
    filtroFavoritos: [],
    filtrosColecao: [],
    filtroMinhasListas: [],
    filtrosOfertas: [],
    filtrosCombos: [],
  },
  status: 'pending',
  error: null,
};

const filtroReducer = createReducer(
  initialState,
  on(
    FiltroActions.abrirFiltro,
    FiltroActions.setFiltroAtivo,
    (state, { paginaFiltro }) => ({
      ...state,
      data: {
        ...state.data,
        filtroAtivo: paginaFiltro,
      },
    })
  ),

  on(
    FiltroActions.getFiltroBusca,
    FiltroActions.getFiltroApiSecao,
    FiltroActions.getFiltroApiDepartamento,
    FiltroActions.getFiltroApiFavoritos,
    FiltroActions.getFiltroColecao,
    FiltroActions.getFiltroMinhasListas,
    FiltroActions.getFiltrosOfertas,
    FiltroActions.getFiltrosCombos,
    (state) => ({
      ...state,
      status: 'loading',
    })
  ),

  on(FiltroActions.aplicarFiltro, (state, { filtros, paginaFiltro }) => ({
    ...state,
    data: {
      ...state.data,
      [paginaFiltro]: filtros,
    },
  })),

  on(
    FiltroActions.getFiltroApiDepartamentoSuccess,
    FiltroActions.getFiltroApiSecaoSuccess,
    (state, { filtros }) => ({
      ...state,
      data: {
        ...state.data,
        filtroClassificacaoMercadologica: [...filtros],
      },
      status: 'success',
    })
  ),

  on(FiltroActions.getFiltroBuscaSuccess, (state, { filtros }) => ({
    ...state,
    data: {
      ...state.data,
      filtrosBusca: [...filtros],
    },
    status: 'success',
  })),

  on(FiltroActions.getFiltroApiFavoritosSuccess, (state, { filtros }) => ({
    ...state,
    data: {
      ...state.data,
      filtroFavoritos: [...filtros],
    },
    status: 'success',
  })),

  on(FiltroActions.getFiltroColecaoSuccess, (state, { filtros }) => ({
    ...state,
    data: {
      ...state.data,
      filtrosColecao: [...filtros],
    },
    status: 'success',
  })),
  on(FiltroActions.getFiltroMinhasListasSuccess, (state, { filtros }) => ({
    ...state,
    data: {
      ...state.data,
      filtroMinhasListas: [...filtros],
    },
    status: 'success',
  })),
  on(FiltroActions.getFiltrosOfertasSuccess, (state, { filtros }) => ({
    ...state,
    data: {
      ...state.data,
      filtrosOfertas: [...filtros],
    },
    status: 'success',
  })),
  on(ComboActions.getComboSuccess, (state, { filtro, possuiFiltro }) => ({
    ...state,
    data: {
      ...state.data,
      filtrosCombos: possuiFiltro
        ? state.data.filtrosCombos
        : setInitialFilters(filtro),
    },
    status: 'success',
  })),
  on(
    FiltroActions.getFiltroApiDepartamentoFailure,
    FiltroActions.getFiltroApiSecaoFailure,
    FiltroActions.getFiltroBuscaFailure,
    FiltroActions.getFiltroApiFavoritosFailure,
    FiltroActions.getFiltroColecaoFailure,
    FiltroActions.getFiltroMinhasListasFailure,
    FiltroActions.getFiltrosOfertasFailure,
    FiltroActions.getFiltrosCombosFailure,
    (state, { error }) => ({
      ...state,
      error: error.error ? error.error.error : error,
      status: 'error',
    })
  ),
  on(FiltroActions.atualizarFiltroCombo, (state, { filtroOrdenacao }) => ({
    ...state,
    data: {
      ...state.data,
      filtrosCombos: [{ ...state.data.filtrosCombos[0] }, filtroOrdenacao],
    },
  }))
);

export function reducer(state: FiltroState | undefined, action: Action) {
  return filtroReducer(state, action);
}
