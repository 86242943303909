<div
  class="vip-selectable-tile flex flex-col content-start rounded cursor-pointer"
  [class.checked]="checked"
  [class.change-color]="changeTextColor"
  (click)="toggleChecked()"
  vipRipple
  rippleBorderRadius="5px"
>
  <div class="flex justify-between items-center">
    <i
      *ngIf="icon"
      class="mr-2 text-xl {{ icon }}"
      [ngClass]="
        checked ? 'vip-color-secondary-main' : 'vip-color-secondary-default'
      "
    ></i>
    <div class="w-full flex flex-col">
      <ng-content select="[title]"></ng-content>
      <ng-content select="[body]"></ng-content>
    </div>
    <i
      *ngIf="showIcon"
      class="vip-selectable-title-icon ml-2 icon-check {{ iconSize }}"
      [class.invisible]="!checked"
    ></i>
  </div>
</div>
