<div class="content-video space-top">
  <i class="icon icon-close space-top" (click)="closeModal.emit($event)"></i>
  <iframe
    *ngIf="url"
    id="youtubeIframe"
    width="100%"
    [src]="url"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</div>
