import { Action, createReducer, on } from '@ngrx/store';
import { INotificacao } from '@vip/core';
import * as NotificacaoActions from './notificacao.actions';
import { IGenericState } from '@vip/state/utils';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const NOTIFICACAO_FEATURE_KEY = 'notificacao';

export interface NotificacaoState
  extends IGenericState<null>,
    EntityState<INotificacao> {}

export const notificacaoAdapter: EntityAdapter<INotificacao> =
  createEntityAdapter<INotificacao>();
export const initialState: NotificacaoState =
  notificacaoAdapter.getInitialState({
    data: null,
    error: null,
    status: 'pending',
  });

const NotificacaoReducer = createReducer(
  initialState,
  on(NotificacaoActions.getNotificacoes, (state) => ({
    ...state,
    status: 'loading',
    error: null,
  })),
  on(NotificacaoActions.getNotificacoesSuccess, (state, { notificacoes }) =>
    notificacaoAdapter.setAll(notificacoes, {
      ...state,
      status: 'success',
      error: null,
    })
  ),
  on(NotificacaoActions.getNotificacoesFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error,
  })),
  on(NotificacaoActions.setNotificationReadSuccess, (state, { id }) => {
    const notificacao = state.entities[id];
    if (notificacao) {
      const clienteNofiticacao = { ...notificacao.cliente_notificacoes[0] };
      clienteNofiticacao.visualizada = true;
      return notificacaoAdapter.updateOne(
        {
          id,
          changes: { cliente_notificacoes: [clienteNofiticacao] },
        },
        state
      );
    }
    return {
      ...state,
      status: 'success',
      error: null,
    };
  })
);

export function reducer(state: NotificacaoState | undefined, action: Action) {
  return NotificacaoReducer(state, action);
}
