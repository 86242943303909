<div
  class="vip-carousel"
  [ngClass]="{ 'remove-block-margins': removeBlockMargins }"
>
  <div
    #contentHeader
    class="vip-carousel-header space-items scrollable"
    [ngClass]="{
      'flex justify-center': slides.length === 1,
      'site-xsmall': type === 'xs' && !isMobile,
      'site-small': type === 'sm' && !isMobile,
      'site-medium': type === 'md' && !isMobile,
      'site-large': type === 'lg' && !isMobile,
      'site-vertical': type === 'vt' && !isMobile,
      'site-wide': wide
    }"
    (mouseup)="scrollableService.disableIsDown()"
    (mouseleave)="scrollableService.disableIsDown()"
    (mousedown)="scrollableService.onMouseDown($event)"
    (mousemove)="scrollableService.onMouseMove($event)"
  >
    <vip-banner-imagem-skeleton
      *ngIf="isLoading; else bannersTemplate"
      [quantity]="slidesPerViewForSkeleton"
    ></vip-banner-imagem-skeleton>
    <ng-template #bannersTemplate>
      <ng-container *ngIf="isMobile; else swiperCarousel">
        <ng-container *ngFor="let slide of slides">
          <vip-banner-imagem
            [isHomeOmni]="isHomeOmni"
            [banner]="slide"
            [style]="'width: ' + width"
            class="rounded vip-carousel-img"
          ></vip-banner-imagem>
        </ng-container>
      </ng-container>

      <ng-template #swiperCarousel>
        <swiper
          [config]="swiperConfig"
          [navigation]="true"
          [allowTouchMove]="swiperOn()"
          [slidesPerView]="'auto'"
          (swiper)="onSwiper($event)"
        >
          <ng-template
            swiperSlide
            [data-swiper-autoplay]="getSlideTime(slide)"
            *ngFor="let slide of slides"
          >
            <vip-banner-imagem
              [isHomeOmni]="isHomeOmni"
              [banner]="slide"
              class="rounded vip-carousel-img"
            ></vip-banner-imagem>
          </ng-template>
        </swiper>
      </ng-template>
    </ng-template>
  </div>
</div>
