import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { BucketsFacade } from '@vip/state/buckets';
import { ClienteFacade } from '@vip/state/cliente';
import { ProdutoApiService } from '@vip/api';
import { ProdutoStore } from './produto.store';
import { mergeTakeOne } from '@vip/state/utils';
import { ProdutoFacade } from './produto.facade';

@Injectable()
export class ProdutosFavoritosStore extends ProdutoStore {
  readonly getFavoritosByDepartamento = this.effect(
    (payload$: Observable<{ cmId: number; filtro: string }>) => {
      return payload$.pipe(
        mergeTakeOne(
          this.cdFacade.filialECdSelecionado$,
          this.bucketsFacade.bucketProduto$.pipe(
            filter((bucket) => bucket !== '')
          )
        ),
        map(([payload, [filial, cdSelecionado], bucket]) => {
          return { ...payload, filial, cdSelecionado, bucket };
        }),
        switchMap((payload) => {
          this.updateStatus('loading');
          return this.produtoApiService
            .getFavoritosByDepartamento(
              payload.filial.id,
              payload.cdSelecionado.id,
              payload.cmId,
              payload.filtro
            )
            .pipe(
              tapResponse(
                (response) => {
                  this.updateProdutosAndPaginator(
                    {
                      produtos: response.data,
                      paginator: response.paginator,
                    },
                    payload.bucket
                  );
                },
                (error) =>
                  this.updateError((error as HttpErrorResponse).error.error)
              )
            );
        })
      );
    }
  );

  readonly getFavoritos = this.effect((payload$) => {
    return payload$.pipe(
      mergeTakeOne(
        this.cdFacade.filialECdSelecionado$,
        this.bucketsFacade.bucketProduto$.pipe(
          filter((bucket) => bucket !== '')
        ),
        this.clienteFacade.cliente$.pipe(filter((cliente) => !!cliente))
      ),
      map(([, [filial, cdSelecionado], bucket]) => {
        return { filial, cdSelecionado, bucket };
      }),
      switchMap((payload) => {
        this.updateStatus('loading');
        return this.produtoApiService
          .getFavoritos(payload.filial.id, payload.cdSelecionado.id)
          .pipe(
            tapResponse(
              (response) => {
                this.updateProdutosAndPaginator(
                  {
                    produtos: response.data,
                    paginator: response.paginator,
                  },
                  payload.bucket
                );
                this.produtoFacade.enviaImpressao(response.data);
              },
              (error) =>
                this.updateError((error as HttpErrorResponse).error.error)
            )
          );
      })
    );
  });

  constructor(
    private produtoApiService: ProdutoApiService,
    private bucketsFacade: BucketsFacade,
    private cdFacade: CentroDistribuicaoFacade,
    private clienteFacade: ClienteFacade,
    private produtoFacade: ProdutoFacade
  ) {
    super();
  }
}
