import { Injectable } from '@angular/core';
import { IProduto } from '../../interfaces';

Injectable({
  providedIn: 'root',
});

export class ProdutoUtilsService {
  getObservacao(
    produto: IProduto,
    produtosNoCarrinho:
      | Map<
          number,
          { item_id: number; quantidade: number; observacao?: string }
        >
      | null
      | undefined
  ): string {
    return (
      produtosNoCarrinho?.get(produto.produto_id)?.observacao ||
      produto?.observacao ||
      ''
    );
  }
}
