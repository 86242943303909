import { createAction, props } from '@ngrx/store';
import { ICompra, IProduto, IProdutoCarrinho } from '@vip/core';

export const beginCheckout = createAction(
  '[Analytics] Consumidor Inicia O Processo De Finalização Da Compra'
);

export const addShippingInfo = createAction(
  '[Analytics] Consumidor Envia As Informações De Entrega',
  props<{ compra: ICompra }>()
);

export const addPaymentInfo = createAction(
  '[Analytics] Consumidor Envia As Informações De Pagamento',
  props<{ tipoPagamentoId: number; compraEmProcesso: ICompra }>()
);

export const purchase = createAction(
  '[Analytics] Consumidor Finaliza a Compra',
  props<{ pedido: ICompra }>()
);

export const viewCart = createAction(
  '[Analytics] Consumidor Visualiza O Carrinho'
);

export const addToCart = createAction(
  '[Analytics] Consumidor Adiciona Um Item Ao Carrinho',
  props<{ item: IProdutoCarrinho }>()
);

export const removeFromCart = createAction(
  '[Analytics] Consumidor Remove Um Item, Um Produto Ou Limpa O Carrinho',
  props<{ itens: IProdutoCarrinho[] }>()
);

export const addToWishList = createAction(
  '[Analytics] Consumidor Adiciona Algum Item Na Lista De Compras',
  props<{ product: IProduto }>()
);

export const viewItem = createAction(
  '[Analytics] Consumidor Visualiza A Página Do Produto',
  props<{ product: IProduto }>()
);

export const search = createAction(
  '[Analytics] Consumidor Pesquisa Um Produto',
  props<{ searchTerm: string }>()
);

export const signUp = createAction(
  '[Analytics] Consumidor Cadastra No App Onmi'
);

export const login = createAction(
  ' [Analytics] Consumidor Realiza O Login No App Omni'
);
