import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCombo from './+state/combo.reducer';
import { ComboEffects } from './+state/combo.effects';
import { ComboFacade } from './+state/combo.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCombo.COMBO_FEATURE_KEY, fromCombo.comboReducer),
    EffectsModule.forFeature([ComboEffects]),
  ],
  providers: [ComboFacade],
})
export class ComboStateModule {}
