import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromFidelidade from './+state/fidelidade.reducer';
import { FidelidadeFacade } from './+state/fidelidade.facade';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FidelidadeEffects } from './+state/fidelidade.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromFidelidade.FIDELIDADE_FEATURE_KEY,
      fromFidelidade.reducer
    ),
    EffectsModule.forFeature([FidelidadeEffects]),
  ],
  providers: [FidelidadeFacade],
})
export class FidelidadeStateModule {}
