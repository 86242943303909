import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { combineLatest, Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { BucketsFacade } from '@vip/state/buckets';
import { BuscaApiService } from '@vip/api';
import { LoadingFacade } from '@vip/state/loading';
import { ProdutoStore } from './produto.store';
import { AnalyticsFacade } from '@vip/state/analytics';
import { ProdutoFacade } from './produto.facade';

@Injectable()
export class ProdutosBuscaRapidaStore extends ProdutoStore {
  readonly getProdutosByBuscaRapida = this.effect(
    (payload$: Observable<{ termo: string }>) => {
      return combineLatest([
        payload$,
        this.cdFacade.filialECdSelecionado$.pipe(
          filter(
            (filialECdSelecionado) =>
              filialECdSelecionado[0] !== undefined &&
              filialECdSelecionado[1] !== undefined
          )
        ),
        this.bucketsFacade.bucketProduto$.pipe(
          filter((bucket) => bucket !== '')
        ),
      ]).pipe(
        tap(() => this.updateStatus('loading')),
        switchMap(([payload, [filial, cdSelecionado], bucket]) => {
          this.analyticsFacade?.buscarItem(payload.termo);
          return this.buscaApiService
            .quickSearchProdutosByTermo(
              filial.id,
              cdSelecionado.id,
              payload.termo
            )
            .pipe(
              tapResponse(
                (response) => {
                  const produtos = response.data.produtos
                    ? response.data.produtos
                    : [];

                  this.updateProdutosAndPaginator(
                    {
                      produtos: produtos,
                      paginator: this.initialPaginator,
                    },
                    bucket
                  );
                  this.produtoFacade?.enviaImpressao(produtos);
                },
                (error) =>
                  this.updateError((error as HttpErrorResponse).error.error)
              )
            );
        })
      );
    }
  );

  constructor(
    private buscaApiService: BuscaApiService,
    private bucketsFacade: BucketsFacade,
    private cdFacade: CentroDistribuicaoFacade,
    public loadingFacade?: LoadingFacade,
    private analyticsFacade?: AnalyticsFacade,
    private produtoFacade?: ProdutoFacade
  ) {
    super();
  }
}
