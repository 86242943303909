import {
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injectable,
  Type,
} from '@angular/core';
import { ComponentInjectorService } from '@vip/core';
import { ModalComponent } from '../modal.component';
import { IModalOptions } from '../modal-options.interface';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ModalService<T> {
  public modalComponent: ComponentRef<ModalComponent>;
  public contentRef: ComponentRef<T> | undefined;

  public closeClick = new EventEmitter();

  constructor(
    private componentInjector: ComponentInjectorService<ModalComponent>,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.modalComponent =
      this.componentInjector.createComponentInApplication(ModalComponent);
  }

  openModal(component: Type<T>, modalOptions: IModalOptions = {}) {
    this.modalComponent =
      this.componentInjector.createComponentInApplication(ModalComponent);

    Object.assign(this.modalComponent.instance, modalOptions);
    this.modalComponent.changeDetectorRef.detectChanges();

    this.subscribeCloseEvents();
    const factory =
      this.componentFactoryResolver.resolveComponentFactory(component);
    this.contentRef =
      this.modalComponent.instance.modalContainer?.createComponent(factory);
    this.modalComponent.instance.open = true;

    return this.contentRef;
  }

  subscribeCloseEvents() {
    this.closeClick = new EventEmitter();
    this.modalComponent.instance.closeClick.subscribe(() => {
      this.closeClick.emit();
    });
    this.modalComponent.instance.afterClose.subscribe(() => {
      this.modalComponent.instance.modalContainer?.clear();
    });
  }

  clearModal() {
    return new Promise((resolve) => {
      if (this.modalComponent && this.modalComponent.instance.open) {
        this.modalComponent.instance.afterClose.pipe(take(1)).subscribe(() => {
          resolve(true);
        });
        this.modalComponent.instance.open = false;
        this.modalComponent.instance.large = false;
        this.modalComponent.instance.ultraLarge = false;
        this.modalComponent.instance.paddingZoom = false;
        this.modalComponent.instance.hasPrazoEntrega = false;
        this.modalComponent.changeDetectorRef.detectChanges();
        this.closeClick.complete();
      } else {
        resolve(true);
      }
    });
  }

  isOpened() {
    return this.modalComponent?.instance?.open;
  }

  canClose() {
    return (
      this.modalComponent?.instance && !this.modalComponent.instance.disabled
    );
  }

  clearIfAllowed() {
    if (this.canClose()) {
      this.clearModal();
    }
  }
}
