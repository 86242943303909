import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { VitrineDedicadaFacade } from './+state/vitrine-dedicada.facade';
import * as fromVitrineDedicada from './+state/vitrine-dedicada.reducer';
import { EffectsModule } from '@ngrx/effects';
import { VitrineDedicadaEffects } from './+state/vitrine-dedicada.effect';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromVitrineDedicada.VITRINE_DEDICADA_FEATURE_KEY,
      fromVitrineDedicada.reducer
    ),
    EffectsModule.forFeature([VitrineDedicadaEffects]),
  ],
  providers: [VitrineDedicadaFacade],
})
export class VitrineDedicadaStateModule {}
