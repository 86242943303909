import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

Injectable({
  providedIn: 'root',
});
export class EntregaRetiradaUtilsService {
  private _openModalEntregaRetiradaSubject = new Subject<void>();
  private _closeModalEntregaRetiradaSubject = new Subject<void>();
  private _openModalSolicitarCepSubject = new BehaviorSubject<boolean | null>(
    null
  );

  openModalEntregaRetirada$ =
    this._openModalEntregaRetiradaSubject.asObservable();

  closeModalEntregaRetirada$ =
    this._closeModalEntregaRetiradaSubject.asObservable();

  openModalSolicitarCep$ = this._openModalSolicitarCepSubject.asObservable();

  openModalEntregaRetirada(): void {
    this._openModalEntregaRetiradaSubject.next();
  }

  closeModalEntregaRetirada(): void {
    this._closeModalEntregaRetiradaSubject.next();
  }
  openModalSolicitarCep(): void {
    this._openModalSolicitarCepSubject.next(true);
  }
}
