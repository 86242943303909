import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfertasContainerComponent } from './ofertas-container/ofertas-container.component';
import { CoreModule } from '@vip/core';
import { CarrinhoItensStateModule } from '@vip/state/carrinho-itens';
import { ListaProdutosModule } from '@vip/views/lista-produtos';
import { RouterModule } from '@angular/router';
import { AtualizarCarrinhoService } from '@vip/container/carrinho';
import { FiltroStateModule } from '@vip/state/filtro';
import { BannerStateModule } from '@vip/state/banner';
import { ListaProdutosDesktopModule } from '@vip/views/lista-produtos-desktop';
import { ClienteStateModule } from '@vip/state/cliente';
import { CentroDistribuicaoStateModule } from '@vip/state/centro-distribuicao';
import { ScreenTitleModule } from '@vip/ui/screen-title';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ListaProdutosModule,
    ListaProdutosDesktopModule,
    FiltroStateModule,
    CarrinhoItensStateModule,
    BannerStateModule,
    ClienteStateModule,
    RouterModule.forChild([
      {
        path: '',
        component: OfertasContainerComponent,
      },
    ]),
    CentroDistribuicaoStateModule,
    ScreenTitleModule,
  ],
  declarations: [OfertasContainerComponent],
  providers: [AtualizarCarrinhoService],
})
export class OfertasContainerModule {}
