<form
  [formGroup]="formGroup"
  [class.form-grid]="displayGrid"
  class="form-pessoa-juridica"
  [class.modal-template]="isModal"
>
  <vip-field
    label="RAZÃO SOCIAL*"
    [error]="
      formGroup.controls.razao_social.touched &&
      formGroup.controls.razao_social.invalid
    "
    [message]="validations.default"
    [validations]="formGroup.controls.razao_social.errors"
  >
    <input
      id="razao_social"
      type="text"
      formControlName="razao_social"
      required
    />
  </vip-field>

  <vip-field
    label="NOME FANTASIA*"
    [error]="
      formGroup.controls.nome_fantasia.touched &&
      formGroup.controls.nome_fantasia.invalid
    "
    [message]="validations.default"
    [validations]="formGroup.controls.nome_fantasia.errors"
  >
    <input
      id="nome_fantasia"
      type="text"
      formControlName="nome_fantasia"
      required
    />
  </vip-field>

  <vip-field
    label="CNPJ*"
    [error]="formGroup.controls.cnpj.touched && formGroup.controls.cnpj.invalid"
    [message]="validations.cnpj"
    [validations]="formGroup.controls.cnpj.errors"
  >
    <input
      id="cnpj"
      type="text"
      formControlName="cnpj"
      [mask]="masks.cnpj"
      [dropSpecialCharacters]="false"
      inputmode="numeric"
    />
  </vip-field>

  <vip-field
    label="INSCRIÇÃO ESTADUAL"
    [error]="
      formGroup.controls.inscricao_estadual.touched &&
      formGroup.controls.inscricao_estadual.invalid
    "
  >
    <input
      id="inscricao_estadual"
      type="text"
      formControlName="inscricao_estadual"
    />
  </vip-field>
</form>
