import { CurrencyPipe, I18nPluralPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  IProduto,
  ProdutoUtilsService,
  TipoOfertaEnum,
  UnidadeDiferentePipe,
} from '@vip/core';
import { DialogService, IDialog } from '@vip/ui/modal';
import { CardProdutoDirective } from '../card-produto/card-produto.directive';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';
import { CompraEmProcessoService } from '@vip/state/compra-em-processo';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'vip-card-produto-lista',
  templateUrl: './card-produto-lista.component.html',
  styleUrls: ['./card-produto-lista.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CardProdutoListaComponent
  extends CardProdutoDirective
  implements OnChanges, OnInit, OnDestroy
{
  @Input() showObservacaoProduto = true;
  @Input() showLayoutReceita = false;
  @Input() showIconDelete = true;
  @Input() confirmDeletion = true;
  @Input() showSpin = true;
  @Input() showTag = true;
  @Input() showUnidade = false;
  @Input() showOferta = true;
  @Input() isCombo = false;
  @Input() disabled = false;
  @Input() isDesktop = false;
  isComboOpen = false;

  @Output() deleteItemClick = new EventEmitter();
  quantityMessageMap: { [k: string]: string } = {
    '=0': '',
    '=1': '1 unidade',
    other: '# unidades',
  };
  exibetagPrecoOfertaAtacarejo = true;
  faixa = 1;
  combosNoCarrinho!: Map<
    number,
    {
      item_id: number;
      quantidade: number;
      observacao?: string;
      seletor_medida_id: number | null | undefined;
    }
  >;

  quantidades = 0;
  produtoId?: number;
  itemCombo!: IProduto;
  produtoItemComboId!: number;

  private destroy$ = new Subject<void>();

  constructor(
    private dialogService: DialogService,
    private i18nPipe: I18nPluralPipe,
    public currencyPipe: CurrencyPipe,
    private unidadeDiferentePipe: UnidadeDiferentePipe,
    public produtoUtilsService: ProdutoUtilsService,
    private compraEmProcessoService: CompraEmProcessoService,
    public router: Router,
    carrinhoItensFacade: CarrinhoItensFacade
  ) {
    super(router, produtoUtilsService, carrinhoItensFacade);
  }

  ngOnInit() {
    if (this.combosNoCarrinho$) {
      this.combosNoCarrinho$
        .pipe(takeUntil(this.destroy$))
        .subscribe((combosCarrinho) => {
          this.combosNoCarrinho = combosCarrinho;
        });
    }
  }

  ngOnChanges(): void {
    this.isReceitaRoute();
    this.exibetagPrecoOfertaAtacarejo = true;
    if (this.produto.oferta?.faixas_precos) {
      const quantidades = this.produto.oferta?.faixas_precos.map(
        (objeto) => objeto.quantidade
      );
      this.faixa = Math.min(...quantidades);
    }
    if (
      this.produto.oferta?.tipo_oferta_id === TipoOfertaEnum.OFERTA_ATACAREJO &&
      this.quantidade < this.faixa
    ) {
      this.exibetagPrecoOfertaAtacarejo = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get quantidade(): number {
    return this.produto.quantidade || 0;
  }

  get detalheOferta(): string {
    let detalheOferta = '';
    if (this.isCombo) {
      detalheOferta = this.i18nPipe.transform(
        this.produto.oferta?.quantidade_minima,
        this.quantityMessageMap
      );
    } else if (this.isBrinde) {
      detalheOferta = this.i18nPipe.transform(
        this.produto.quantidade,
        this.quantityMessageMap
      );
    } else if (
      this.showOferta &&
      this.produto.em_oferta &&
      this.produto.oferta
    ) {
      detalheOferta = this.produto.oferta.nome;
    }
    return detalheOferta;
  }

  get produtoPreco() {
    let produtoPreco = 0;
    const { oferta } = this.produto;
    if (this.isCombo && oferta) {
      produtoPreco = oferta.preco_oferta * oferta.quantidade_minima;
    } else if (this.isBrinde) return 'Brinde';
    else {
      produtoPreco =
        (this.produto.preco ?? this.produto.preco_antigo) * this.quantidade;
    }
    return this.currencyPipe.transform(produtoPreco);
  }

  get produtoPrecoSemOferta(): string {
    let produtoPreco: null | string = null;
    const { oferta } = this.produto;
    if (oferta) {
      produtoPreco = this.currencyPipe.transform(
        oferta.preco_antigo * this.quantidade
      );
    }
    return produtoPreco || '';
  }

  get produtoUnidadePreco() {
    const unidade = this.mostrarUnidadeOuUnidadeDiferente(this.produto);
    return `${this.precoUnitario} | ${unidade}`;
  }

  get produtoPrecoComOferta(): string {
    if (this.produto.em_oferta && this.produto.oferta) {
      const precoFormatado = this.currencyPipe.transform(
        this.produto.oferta.preco_oferta
      );
      const unidade = this.mostrarUnidadeOuUnidadeDiferente(this.produto);
      return precoFormatado !== null ? `${precoFormatado} | ${unidade}` : ''; // Inclua a unidade
    }
    return '';
  }

  get precoUnitario() {
    let produtoPreco = 0;

    this.exibetagPrecoOfertaAtacarejo = true;
    const { oferta } = this.produto;
    if (oferta) {
      produtoPreco = oferta.preco_antigo;
      if (
        oferta.tipo_oferta_id === TipoOfertaEnum.OFERTA_ATACAREJO &&
        this.quantidade >= this.faixa
      ) {
        produtoPreco = this.produto.preco_base || 0;
      }
    } else {
      produtoPreco = this.produto.preco_antigo ?? this.produto.preco;
    }
    return this.currencyPipe.transform(produtoPreco);
  }

  precoUnitarioComboView() {
    let produtoPreco = 0;

    this.exibetagPrecoOfertaAtacarejo = true;
    const { oferta } = this.produto;
    if (oferta) {
      produtoPreco = oferta.preco_antigo;
      if (
        oferta.tipo_oferta_id === TipoOfertaEnum.OFERTA_ATACAREJO &&
        this.quantidade >= this.faixa
      ) {
        produtoPreco = this.produto.preco_base || 0;
      }
    } else {
      produtoPreco = this.produto.preco_antigo ?? this.produto.preco;
    }
    return this.currencyPipe.transform(produtoPreco);
  }

  get isBrinde() {
    return this.produto.brinde;
  }

  mostrarUnidadeOuUnidadeDiferente(produto: IProduto): string {
    let unidade = produto.unidade_sigla?.toLowerCase();
    if (produto.possui_unidade_diferente) {
      unidade = this.unidadeDiferentePipe.transform(
        produto.quantidade_unidade_diferente
      );
    }
    return unidade;
  }

  getPercentOferta() {
    const preco =
      this.produto.oferta?.tipo_oferta_id === TipoOfertaEnum.OFERTA_ATACAREJO
        ? this.produto.preco_base || 0
        : this.produto.oferta?.preco_antigo || 0;
    if (this.produto.em_oferta && this.produto.oferta) {
      const diff = preco - (this.produto.preco ?? this.produto.preco_antigo);

      return -((diff * 100) / preco).toFixed(2) + '%';
    } else {
      return '';
    }
  }

  handleDeleteItemClick(event: Event) {
    event.stopImmediatePropagation();
    if (this.compraEmProcessoService.temCompraEmProcesso) return;
    if (this.disabled) return;
    const dialog: IDialog = {
      open: true,
      title: 'Deseja realmente remover o item?',
      subTitle: this.produto.descricao,
      buttonCancelText: 'Cancelar',
      buttonConfirmText: 'Remover item',
      disabled: false,
    };

    this.dialogService.openDialog(dialog);
    //eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.dialogService.dialogClick.subscribe((event) => {
      if (event) {
        this.deleteItemClick.emit(this.produto.item_id);
      }
      this.dialogService.clearDialog();
    });
  }

  handleChangeObservacao(item: IProduto, observacao: string) {
    this.produtoChanged.emit({
      event: new Event('changeObservacao'),
      quantity: item.quantidade ? item.quantidade : 0,
      produto: { ...item, observacao },
    });
  }

  handleChangeObservacaoCombo(
    item: IProduto,
    observacao: string,
    produtoId?: { combo?: { oferta_id: number } } | number,
    quantidade?: number,
    produtoItemId?: number
  ) {
    this.quantidades = quantidade as number;

    this.produtoId =
      typeof produtoId === 'object' && 'combo' in produtoId
        ? produtoId?.combo?.oferta_id
        : (produtoId as number);

    this.itemCombo = item;
    this.produtoItemComboId = produtoItemId as number;
    this.handleProdutoAlteradoCombo(item, observacao, produtoItemId);
  }

  handleProdutoAlteradoCombo(
    produto: IProduto,
    observacao: string,
    produtoId?: number
  ) {
    const observacoesAtualizadas = this.getObservacoes(produtoId, observacao);
    this.produtoChanged.emit({
      event: new Event('changeObservacao'),
      quantity: this.quantidades,
      produto: {
        ...produto,
        item_id: produto.item_id,
        observacao: observacoesAtualizadas,
      },
    });
  }

  get observacao() {
    const observacoesSalvas = this.combosNoCarrinho?.get(
      this.produtoId as number
    )?.observacao;
    if (observacoesSalvas) return JSON.parse(observacoesSalvas);
    return '';
  }

  getObservacoes(produtoId: number | undefined, observacao: string) {
    const observacoes = this.observacao;
    if (produtoId) {
      observacoes[produtoId] = observacao || '';
    }
    return observacoes;
  }

  isReceitaRoute(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.includes('/receita/');
  }

  verCombo() {
    this.isComboOpen = !this.isComboOpen;
  }

  public currencyComboProdutoView(produtoValue?: number) {
    return this.currencyPipe.transform(produtoValue);
  }

  public calcComboProdutoView(
    produtoValue?: number,
    quantidade?: number
  ): string | null {
    if (produtoValue && quantidade) {
      return this.currencyPipe.transform(produtoValue * quantidade);
    }
    return null;
  }
}
