import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LayoutUtilsService } from '@vip/core';

@Component({
  selector: 'vip-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.desktop]': 'isDesktop',
  },
})
export class VideoModalComponent {
  url!: SafeResourceUrl | null;
  isDesktop = this.layoutUtilsService.isDesktop();
  @Output()
  closeModal: EventEmitter<Event> = new EventEmitter();

  constructor(
    private sanitizer: DomSanitizer,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  @Input()
  set videoId(videoId: string) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.youtube.com/embed/' + videoId
    );
  }
}
