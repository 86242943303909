import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  NgZone,
} from '@angular/core';
import { TipoEntregaFacade } from '@vip/state/tipo-entrega';
import {
  IHorariosFormatados,
  ITipoEntrega,
  LayoutUtilsService,
} from '@vip/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CompraEmProcessoFacade } from '@vip/state/compra-em-processo';
import { LayoutStore } from '@vip/state/layout';

@UntilDestroy()
@Component({
  selector: 'vip-alterar-horario-container',
  templateUrl: './alterar-horario-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlterarHorarioContainerComponent implements AfterViewInit {
  tipoEntregaSelecionado$ = this.tipoEntregaFacade.tipoEntregaSelecionado$;
  horarioSelecionado$ = this.tipoEntregaFacade.horarioSelecionado$;
  isLoading$ = this.tipoEntregaFacade.loading$;
  horarioSelecionado?: IHorariosFormatados;
  hasCompraEmProcesso = false;
  isDesktop = this.layoutUtilsService.isDesktop$;
  isDesktopWithoutScreenWidth =
    this.layoutUtilsService.isDesktopWithoutScreenWidth();
  isDesktopResponsive = this.layoutUtilsService.isDesktopResponsive();

  constructor(
    private tipoEntregaFacade: TipoEntregaFacade,
    private router: Router,
    private compraEmProcessoFacade: CompraEmProcessoFacade,
    private layoutUtilsService: LayoutUtilsService,
    private layoutStore: LayoutStore,
    private ngZone: NgZone
  ) {
    this.compraEmProcessoFacade.compraEmProcesso$
      .pipe(untilDestroyed(this))
      .subscribe((res) => (this.hasCompraEmProcesso = !!res?.id));
  }

  ngAfterViewInit(): void {
    if (this.isDesktopResponsive) this.layoutStore.setHeaderVisibility(false);
  }

  handleHorarioChange(horarioSelecionado: IHorariosFormatados) {
    this.horarioSelecionado = horarioSelecionado;

    this.tipoEntregaSelecionado$
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.compraEmProcessoFacade.setTipoEntrega(
          response as ITipoEntrega,
          horarioSelecionado
        );
      });

    this.tipoEntregaFacade.selectHorarioTipoEntrega(horarioSelecionado);
  }

  confirm() {
    if (this.horarioSelecionado) {
      this.ngZone.run(() => {
        this.router.navigateByUrl(
          this.hasCompraEmProcesso ? 'pagamento' : 'resumo-compra'
        );
      });
    }
  }
}
