import { RadioModule } from '@vip/ui/radio';
import { RippleModule } from '@vip/ui/ripple';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeletorDePesoComponent } from './seletor-de-peso/seletor-de-peso.component';

@NgModule({
  imports: [CommonModule, FormsModule, RippleModule, RadioModule],
  exports: [SeletorDePesoComponent],
  declarations: [SeletorDePesoComponent],
})
export class SeletorDePesoModule {}
