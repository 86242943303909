import { Directive } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ICliente, LayoutUtilsService, TipoClienteEnum } from '@vip/core';
import { ClienteFacade } from '@vip/state/cliente';
import { EnderecoFacade } from '@vip/state/endereco';
import { FilialFacade } from '@vip/state/filial';
import { TipoDeEdicaoMeusDados } from '@vip/views/meus-dados';
import { Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { EnderecoBaseDirective } from '@vip/container/endereco';
import { CepFacade } from '@vip/state/cep';

@UntilDestroy()
@Directive()
export abstract class MeusDadosBaseDirective extends EnderecoBaseDirective {
  cliente$ = this.clienteFacade.cliente$;
  tipoDeEdicao: TipoDeEdicaoMeusDados = TipoDeEdicaoMeusDados.PessoaFisica;
  filial$ = this.filialFacade.filial$;
  isDesktop = this.layoutUtilsService.isDesktop();
  isDesktop$ = this.layoutUtilsService.isDesktop$;
  showEnderecosForm = false;

  constructor(
    protected router: Router,
    private clienteFacade: ClienteFacade,
    private filialFacade: FilialFacade,
    protected enderecoFacade: EnderecoFacade,
    protected cepFacade: CepFacade,
    private layoutUtilsService: LayoutUtilsService
  ) {
    super(enderecoFacade, cepFacade);
    this.enderecoFacade.getEnderecos();
    this.setTipoDeEdicao();
    this.enderecoFacade.enderecosArray$
      .pipe(untilDestroyed(this))
      .subscribe((enderecos) => {
        this.showEnderecosForm = enderecos.length === 0;
      });
  }

  setTipoDeEdicao() {
    if (this.isDesktop) {
      this.cliente$.pipe(take(1), untilDestroyed(this)).subscribe((cliente) => {
        this.tipoDeEdicao =
          cliente?.tipo_cliente === TipoClienteEnum.PESSOA_JURIDICA
            ? TipoDeEdicaoMeusDados.Ambos
            : TipoDeEdicaoMeusDados.PessoaFisica;
      });
    } else {
      switch (this.router.url) {
        case '/minha-conta/completar-cadastro':
          this.cliente$
            .pipe(take(1), untilDestroyed(this))
            .subscribe((cliente) => {
              this.tipoDeEdicao =
                cliente?.tipo_cliente === TipoClienteEnum.PESSOA_JURIDICA
                  ? TipoDeEdicaoMeusDados.Ambos
                  : TipoDeEdicaoMeusDados.PessoaFisica;
            });
          break;
        case '/minha-conta/dados-cadastrais':
          this.tipoDeEdicao = TipoDeEdicaoMeusDados.PessoaFisica;
          break;
        case '/minha-conta/dados-comerciais':
          this.tipoDeEdicao = TipoDeEdicaoMeusDados.PessoaJuridica;
          break;
      }
    }
  }

  saveMeusDados(cliente: ICliente & { password: string }) {
    combineLatest([this.urlToRedirect, this.cliente$])
      .pipe(take(1), untilDestroyed(this))
      .subscribe(([urlToredirect, client]) => {
        cliente.email = cliente.email ? cliente.email : client?.email || '';
        return this.clienteFacade.editCliente({ ...cliente }, urlToredirect);
      });
  }

  get urlToRedirect(): Observable<string> {
    return this.enderecoFacade.enderecosArray$.pipe(
      map((enderecos) => {
        if (!enderecos.length && !this.isDesktop)
          return '/minha-conta/endereco?completar-cadastro=true';
        if (
          this.router.url === '/minha-conta/completar-cadastro' ||
          (this.isDesktop &&
            this.router.url !== '/minha-conta/dados-cadastrais')
        )
          return '/resumo-compra';
        return '/minha-conta';
      })
    );
  }
}
