import { Pipe, PipeTransform } from '@angular/core';
import { IOferta } from '../../interfaces';
import { TipoOfertaEnum } from '../../enums';

@Pipe({ name: 'isOfertaComDescontoSimples' })
export class IsOfertaComDescontoSimplesPipe implements PipeTransform {
  transform(oferta?: IOferta | null): boolean {
    if (!oferta) return false;
    return (
      (oferta.tipo_oferta_id === TipoOfertaEnum.PRODUTO_COM_DESCONTO ||
        oferta.tipo_oferta_id === TipoOfertaEnum.CATEGORIA_DE_PRODUTOS) &&
      oferta.quantidade_minima === 1
    );
  }
}
