<li *ngIf="product" class="grid grid-cols-12" (click)="cliqueAdsCard()">
  <div class="vip-product-image-wrapper col-span-2">
    <vip-image
      class="cursor-pointer"
      [alt]="product.descricao"
      [attr.data-cy]="'item_product_' + product.id + '_image'"
      [imageUrl]="product.imagem || ''"
      (click)="productClick.emit(product)"
    ></vip-image>
  </div>

  <div
    class="col-span-3 vip-product-description-wrapper pr-4 flex flex-col justify-center"
  >
    <span
      class="text-tiny patrocinado-color"
      *ngIf="product.anunciado && product.disponivel"
    >
      Patrocinado
    </span>
    <p
      class="text-sm vip-color-second-default cursor-pointer"
      [attr.title]="product.descricao"
      [attr.tabindex]="opened ? 0 : -1"
      (click)="productClick.emit(product)"
      (keydown)="handleProductKeyboardEntry($event, product)"
    >
      {{ product.descricao }}
    </p>
    <vip-tag
      *ngIf="product.em_oferta && product.disponivel"
      class="block mt-1 cursor-pointer"
      [classeOferta]="product.oferta?.classe_oferta"
      [label]="product.oferta?.nome || ''"
      [tag]="product.oferta?.tag"
      (tagClick)="tagClick.emit()"
    ></vip-tag>
  </div>

  <div class="col-span-3 vip-product-price-wrapper">
    <div class="flex items-center h-full">
      <span
        class="text-xs vip-color-primary-main mr-2 cursor-pointer"
        [attr.data-cy]="'item_product_' + product.id + '_preco'"
        [attr.tabindex]="opened ? 0 : -1"
        >{{ produtoPreco }}</span
      >
      <vip-tag
        *ngIf="
          product.em_oferta &&
          product.disponivel &&
          percentOferta !== '0.00%' &&
          product.oferta?.classe_oferta === 'preco-vip'
        "
        [attr.data-cy]="'item_product_percent_' + product.id + '_tag'"
        [classeOferta]="product.oferta?.classe_oferta"
        [type]="product.possui_unidade_diferente ? 'ex-small' : 'small'"
        [label]="percentOferta"
        (tagClick)="tagClick.emit()"
      ></vip-tag>
    </div>
    <vip-produto-preco-unidade
      class="block mt-1"
      *ngIf="product.possui_unidade_diferente"
      [preco]="product | unidadePrecoPipe: true"
      [siglaUnidade]="product | unidadeSigla"
      [isTag]="false"
    ></vip-produto-preco-unidade>
  </div>

  <div
    class="col-span-4 vip-product-buttons-wrapper flex flex-col justify-center"
  >
    <vip-spin
      *ngIf="!isBrinde"
      size="small"
      [attr.data-cy]="'item_product_' + product.id + '_spin'"
      [confimDeletion]="true"
      [max]="product | quantidadeMaximaProduto"
      [quantityUnity]="product | quantidadeUnidadeDiferenteProduto"
      [quantity]="product | quantidadeProduto: produtosNoCarrinho"
      [subTitleModalConfimDeletion]="product.descricao"
      [unityMeasureAbbreviation]="product.unidade_sigla"
      [large]="true"
      [focusable]="opened"
      (changed)="handleClickSpinChange($event)"
      (openedConfirmDeleteModal)="openedConfirmDeleteModal.emit($event)"
      (adsClick)="cliqueAdsCard()"
      [seletorDePesoHabilitado]="product.habilitar_seletor_unidade_peso"
      [produto]="product"
      [tipoDeMedida]="product.seletor_medida_id"
    ></vip-spin>
  </div>
</li>
