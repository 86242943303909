import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { CupomApiService } from '@vip/api';
import { map } from 'rxjs/operators';
import * as CupomActions from './cupom.actions';

@Injectable()
export class CupomEffects {
  getCupons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CupomActions.loadCupons),
      fetch({
        run: () =>
          this.cupomApiService.getMeusCupons().pipe(
            map((result) =>
              CupomActions.loadCupomSuccess({
                meusCupons: result.data,
              })
            )
          ),
        onError: (_, error) => {
          return CupomActions.loadCupomFailure({ error });
        },
      })
    )
  );

  getCuponsDisponiveis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CupomActions.loadCuponsDisponiveis),
      fetch({
        run: () =>
          this.cupomApiService.getMeusCuponsDisponiveis().pipe(
            map((result) =>
              CupomActions.loadCuponsDisponiveisSuccess({
                meusCupons: result.data,
              })
            )
          ),
        onError: (_, error) => {
          return CupomActions.loadCuponsDisponiveisFailure({ error });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private cupomApiService: CupomApiService
  ) {}
}
