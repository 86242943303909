import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../common/base-api-service';
import { GetClassificacoesMercadologicasResponse } from './classificacao-mercadologica-api.service.types';

@Injectable({
  providedIn: 'root',
})
export class ClassificacaoMercadologicaApiService {
  constructor(
    private api: BaseApiService<ClassificacaoMercadologicaApiService>
  ) {}

  loadArvore(
    filialId: number,
    cdId: number
  ): Observable<GetClassificacoesMercadologicasResponse> {
    const route = '/loja/classificacoes_mercadologicas/departamentos/arvore';
    const filters = `/filial/${filialId}/centro_distribuicao/${cdId}`;
    return this.api
      .get<GetClassificacoesMercadologicasResponse>(
        'loadArvore',
        filters + route
      )
      .pipe();
  }
}
