import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ITipoEntrega } from '@vip/core';

@Component({
  selector: 'vip-alterar-entrega-retirada',
  templateUrl: './alterar-entrega-retirada.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlterarEntregaRetiradaComponent {
  @Output() showIcon = true;
  @Input() isLoading = false;
  @Input() isRetirada = false;
  @Input() tiposEntrega: ITipoEntrega[] | null = [];
  @Input() tipoSelecionado!: ITipoEntrega | null;
  @Input() qtdItensCarrinho = 0;
  @Output() tipoEntregaChange = new EventEmitter();
  @Output() goBackClick = new EventEmitter();

  getTiposEntregaOrdenados(tiposEntrega: ITipoEntrega[]) {
    return tiposEntrega.sort((a, b) => {
      if (a.alocar == b.alocar) {
        return a.descricao > b.descricao ? 1 : -1;
      } else {
        return Number(b.alocar) - Number(a.alocar);
      }
    });
  }
}
