import { ITheme } from '@vip/core';

export const DEFAULT_THEME: ITheme = {
  color: {
    primary: {
      main: '#0E3E86',
      darken: '',
      contrast: '',
    },
    secondary: {
      main: '#266CC4',
      darken: '',
      contrast: '',
    },
    complementary: {
      main: '#F0F3FF',
      darken: '',
      contrast: '#545454',
    },
    complementarySecond: {
      main: '#D0D7E2',
      darken: '',
      contrast: '#545454',
    },
    firstDefault: {
      main: '#2F3138',
      contrast: '',
    },
    secondaryDefault: {
      main: '#545454',
      contrast: '',
      darken: '',
    },
    thirdDefault: {
      main: '#9E9E9E',
      contrast: '',
      darken: '',
    },
    fourthDefault: {
      main: '#EBECEF',
      contrast: '',
    },
    success: {
      main: '#6EB555',
      darken: '',
      contrast: '',
    },
    error: {
      main: '#D12018',
      darken: '',
      contrast: '',
    },
    warning: {
      main: '#FF8B00',
      darken: '',
      contrast: '',
    },
    disabled: {
      main: '#9E9E9E',
      contrast: '',
    },
    appBackground: {
      main: '#FFFFFF',
    },
    black: {
      main: '#000000',
      contrast: '',
    },
    white: {
      main: '#FFFFFF',
      contrast: '',
    },
  },

  component: {
    badge: {
      color: {
        background: '#D12018',
        contrast: '',
      },
    },
    cupom: {
      color: {
        checked: '#5492DF',
      },
    },
    button: {
      color: {
        main: '',
        darken: '',
        contrast: '',
      },
    },
    cashback: {
      color: {
        background: '',
        contrast: '',
      },
    },
    divider: {
      color: {
        background: '#ebecef',
      },
    },
    dropdown: {
      color: {
        default: '',
        defaultContrast: '',
        selectedContrast: '',
        disabled: '',
        selected: '',
      },
    },
    footerDesktop: {
      color: {
        background: '',
        text: '',
        divider: '',
        link: '',
      },
    },
    header: {
      type: 'type_1',
      color: {
        main: '',
        contrast: '',
      },
    },
    headerDesktop: {
      color: {
        background: '',
      },
    },
    input: {
      color: {
        background: '',
        border: '',
      },
    },
    mainMenuDesktop: {
      color: {
        background: '',
        text: '',
        divider: '',
      },
    },
    message: {
      color: {
        success: '#EAF9E5',
        error: '#EFDDDC',
        info: '#D2DAE2',
        icon: {
          success: '#6EB555',
          error: '#D12018',
          info: '#266CC4',
        },
      },
    },
    navigationBar: {
      color: {
        background: '',
        icon: {
          main: '',
          darken: '',
          active: '',
        },
        label: {
          main: '',
          darken: '',
          active: '',
        },
        notch: {
          main: '',
          darken: '',
          contrast: '',
        },
      },
    },
    bottomNavigation: {
      type: 'type_1',
      buttons: {
        home: true,
        departments: false,
        search: false,
        favorites: false,
        shop: true,
        shopping_cart: true,
        menu: true,
        cashback: false,
        collections: false,
        contact: false,
        institutional: false,
        invite: false,
        lists: false,
        logout: false,
        policy: false,
        profile: false,
        promotions: false,
        purchases: false,
        recipes: false,
        signatures: false,
        encerrar_sessao: false,
        combos: true,
      },
    },
    search: {
      color: {
        background: '',
        text: '',
        placeholder: '',
      },
    },
    stepper: {
      color: {
        background: '',
        progress: '',
        icon: '',
      },
    },
    radio: {
      color: {
        background: '',
      },
    },
  },
};

export const DEFAULT_TAG_OFERTAS = {
  tag_atacarejo_cor_background: '#3A318B',
  tag_atacarejo_cor_fonte: '#ffffff',
  tag_base_cor_background: '#f6cb30',
  tag_base_cor_fonte: '#ff7a11',
  tag_combos_cor_background: '#EC5922',
  tag_combos_cor_fonte: '#ffffff',
  tag_leve_e_pague_cor_background: '#FF8B00',
  tag_leve_e_pague_cor_fonte: '#ffffff',
  tag_mais_por_menos_cor_background: '#7D73B2',
  tag_mais_por_menos_cor_fonte: '#ffffff',
  tag_oferta_exclusiva_cor_background: '#ffbfb6',
  tag_oferta_exclusiva_cor_fonte: '#ffffff',
  tag_ofertas_cor_background: '#387722',
  tag_ofertas_cor_fonte: '#ffffff',
  //Fixas no admin
  tag_oferta_preto_fonte: '#fff',
  tag_oferta_preto_background: '#000',
  tag_oferta_vermelho_fonte: '#fff',
  tag_oferta_vermelho_background: '#b30e00',
  tag_oferta_laranja_fonte: '#fff',
  tag_oferta_laranja_background: '#ff8448',
  tag_anunciado: '#c6c6c6',
};
