import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { VideoModalComponent } from './video-modal/video-modal.component';
import { VideoPlayerService } from './video-modal/service/video-modal.service';

@NgModule({
  imports: [CommonModule],
  declarations: [VideoPlayerComponent, VideoModalComponent],
  exports: [VideoPlayerComponent],
  providers: [VideoPlayerService],
})
export class VideoPlayerModule {}
