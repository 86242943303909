import { ComponentStore } from '@ngrx/component-store';
import { EntityState, Update, createEntityAdapter } from '@ngrx/entity';
import { IProduto, IApiPaginator, MultipleProdutoStateData } from '@vip/core';
import {
  GenericComponentStore,
  GenericStoreStatus,
  GenericStoreStatusEnum,
  IGenericState,
} from '@vip/state/utils';
import { filter, takeWhile } from 'rxjs/operators';

interface ProdutoStateData {
  produtos: Array<IProduto>;
  paginator: IApiPaginator;
}

interface MultipleProdutoState extends EntityState<MultipleProdutoStateData> {
  data: Array<any>;
  status: GenericStoreStatus;
  error: string | null;
}

const multipleProdutoAdapter = createEntityAdapter<MultipleProdutoStateData>({
  selectId: (lista) => lista.id,
});
const initialState = multipleProdutoAdapter.getInitialState({
  data: [],
  status: GenericStoreStatusEnum.PENDING,
  error: null,
});
const { selectAll, selectEntities } = multipleProdutoAdapter.getSelectors();

export abstract class ProdutoStore extends GenericComponentStore<ProdutoStateData> {
  readonly produtos$ = this.select(
    (stateContext: IGenericState<ProdutoStateData>) =>
      stateContext.data.produtos
  );

  readonly paginator$ = this.select(
    (stateContext: IGenericState<ProdutoStateData>) =>
      stateContext.data.paginator
  );

  readonly page$ = this.select(
    (stateContext: IGenericState<ProdutoStateData>) =>
      stateContext.data.paginator.page
  );

  readonly status$ = this.select(
    (stateContext: IGenericState<ProdutoStateData>) => stateContext.status
  );

  readonly initialPaginator = {
    page: 1,
    total_pages: 1,
  };

  readonly initialState = {
    data: {
      produtos: [],
      paginator: {
        page: 1,
        total_pages: 1,
      },
    },
  };

  readonly setData = this.updater(
    (
      state,
      data: {
        produtos: IProduto[];
        paginator: IApiPaginator;
      }
    ) => {
      if (data.paginator && data.paginator?.page > 1) {
        return {
          ...state,
          data: {
            produtos: [...state.data.produtos, ...data.produtos],
            paginator: data.paginator,
          },
          status: 'success',
        };
      }
      return {
        ...state,
        data: { ...data },
        status: 'success',
      };
    }
  );

  protected constructor() {
    super({
      produtos: [],
      paginator: {
        page: 1,
        total_pages: 1,
      },
    });
  }

  nextPage() {
    this.status$
      .pipe(
        takeWhile((status) => status !== GenericStoreStatusEnum.LOADING),
        filter(
          (status) =>
            status === GenericStoreStatusEnum.SUCCESS ||
            status === GenericStoreStatusEnum.PENDING
        )
      )
      .subscribe(() => {
        this.patchState((state) => ({
          data: {
            paginator: {
              page:
                state.data.paginator.page < state.data.paginator.total_pages
                  ? state.data.paginator.page + 1
                  : state.data.paginator.page,
              total_pages: state.data.paginator.total_pages,
            },
            produtos: state.data.produtos,
          },
        }));
      });
  }

  goToPage(page: number) {
    this.patchState((state) => ({
      data: {
        paginator: {
          ...state.data.paginator,
          page,
        },
        produtos: [],
      },
    }));
  }

  resetPage() {
    this.patchState((state) => ({
      data: {
        paginator: this.initialPaginator,
        produtos: state.data.produtos,
      },
    }));
  }

  resetState() {
    this.patchState(() => this.initialState);
  }

  updateProdutosAndPaginator(
    data: { produtos: IProduto[]; paginator?: IApiPaginator },
    bucket?: string
  ) {
    this.setData({
      produtos: data.produtos.map((produto) => {
        return this.mapProdutos(produto, bucket);
      }),
      paginator: data.paginator || this.initialPaginator,
    });
  }

  protected mapProdutos(produto: IProduto, bucket?: string) {
    return {
      ...produto,
      imagem: produto.imagem ? `${bucket}/${produto.imagem}` : produto.imagem,
    };
  }
}

export abstract class MultipleProdutoStore extends ComponentStore<MultipleProdutoState> {
  readonly loading$ = this.select(
    (stateContext: MultipleProdutoState) =>
      stateContext.status === GenericStoreStatusEnum.LOADING
  );

  readonly loaded$ = this.select(
    (stateContext: MultipleProdutoState) =>
      stateContext.status === GenericStoreStatusEnum.SUCCESS ||
      stateContext.status === GenericStoreStatusEnum.ERROR
  );

  readonly listaVitrinesArray$ = this.select((state: MultipleProdutoState) =>
    selectAll(state)
  );

  readonly selectEntities$ = this.select((state: MultipleProdutoState) =>
    selectEntities(state)
  );

  readonly status$ = this.select((state) => state.status);
  readonly error$ = this.select((state) => state.error);

  readonly resetState = this.updater((state) =>
    multipleProdutoAdapter.removeAll({ ...state, ...initialState })
  );

  readonly initialPaginator = {
    page: 1,
    total_pages: 1,
  };

  readonly addListaProdutos = this.updater(
    (state, data: MultipleProdutoStateData[]) => {
      return multipleProdutoAdapter.upsertMany(
        data.map((vitrine) => {
          if (vitrine.paginator && vitrine.paginator?.page > 1) {
            return {
              id: vitrine.id,
              title: vitrine.title,
              perfil: vitrine.perfil,
              produtos: [
                ...(state.entities[vitrine.id]?.produtos ?? []),
                ...vitrine.produtos,
              ],
              paginator: vitrine.paginator,
            } as MultipleProdutoStateData;
          }
          return {
            id: vitrine.id,
            title: vitrine.title,
            perfil: vitrine.perfil,
            produtos: vitrine.produtos,
            paginator: vitrine.paginator,
          } as MultipleProdutoStateData;
        }),
        {
          ...state,
          status: GenericStoreStatusEnum.SUCCESS,
          error: null,
        }
      );
    }
  );

  readonly updateLista = this.updater(
    (state, update: Update<MultipleProdutoStateData>) => {
      return multipleProdutoAdapter.updateOne(update, {
        ...state,
        status: GenericStoreStatusEnum.SUCCESS,
        error: null,
      });
    }
  );

  readonly updateStatus = this.updater((state, status: GenericStoreStatus) => {
    return multipleProdutoAdapter.updateOne(
      { id: '', changes: {} },
      {
        ...state,
        status,
        error: null,
      }
    );
  });

  readonly updateError = this.updater((state, error: string | null) => {
    return multipleProdutoAdapter.updateOne(
      { id: '', changes: {} },
      {
        ...state,
        error: error,
      }
    );
  });

  readonly nextPage = this.updater((state, listaId: string) =>
    multipleProdutoAdapter.updateOne(
      {
        id: listaId,
        changes: {
          paginator: {
            ...state.entities[listaId]?.paginator,
            page: this.definePage(state.entities[listaId]?.paginator),
          },
        },
      } as Update<MultipleProdutoStateData>,
      {
        ...state,
      }
    )
  );

  readonly goToPage = this.updater(
    (state, data: { listaId: string; page: number }) =>
      multipleProdutoAdapter.updateOne(
        {
          id: data.listaId,
          changes: {
            paginator: {
              ...state.entities[data.listaId]?.paginator,
              page: data.page,
            },
          },
        } as Update<MultipleProdutoStateData>,
        {
          ...state,
        }
      )
  );

  readonly resetPage = this.updater((state, listaId: string) =>
    multipleProdutoAdapter.updateOne(
      {
        id: listaId,
        changes: {
          paginator: this.initialPaginator,
        },
      } as Update<MultipleProdutoStateData>,
      {
        ...state,
      }
    )
  );

  constructor() {
    super(initialState);
  }

  readonly produtos$ = (listaId: string) =>
    this.select(this.selectEntities$, (lista) =>
      listaId && lista[listaId] ? lista[listaId]?.produtos : null
    );

  readonly paginator$ = (listaId: string) =>
    this.select(this.selectEntities$, (lista) =>
      listaId && lista[listaId]
        ? lista[listaId]?.paginator
        : this.initialPaginator
    );

  readonly page$ = (listaId: string) =>
    this.select(this.selectEntities$, (lista) =>
      listaId && lista[listaId] ? lista[listaId]?.paginator.page : 1
    );

  definePage(paginator: IApiPaginator | undefined) {
    if (paginator) {
      return paginator.page < paginator.total_pages
        ? paginator.page + 1
        : paginator.page;
    }
    return 1;
  }

  readonly updateProdutosAndPaginator = (data: {
    id: string;
    title: string;
    produtos: IProduto[];
    paginator?: IApiPaginator;
    bucket?: string;
  }) =>
    this.addListaProdutos([
      {
        id: data.id,
        title: data.title,
        produtos: data.produtos.map((produto) => {
          return this.mapProdutos(produto, data.bucket);
        }),
        paginator: data.paginator || this.initialPaginator,
      },
    ]);

  protected mapProdutos(produto: IProduto, bucket?: string) {
    return {
      ...produto,
      imagem: produto.imagem ? `${bucket}/${produto.imagem}` : produto.imagem,
    };
  }
}
