import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  IBreadCrumb,
  IFilter,
  IFilterOptions,
  ISlide,
  IApiPaginator,
  LayoutUtilsService,
  IMAGES_PATH,
  IProduto,
  FilterTypeEnum,
} from '@vip/core';
import { DropdownDirective } from '@vip/ui/dropdown';
import { ProdutosAndFiltrosDirective } from '@vip/views/lista-produtos';
import { Inject } from '@angular/core';
import { CampanhaPatrocinadoService } from '@vip/api';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';

type SelectedFiltersOption = {
  filterIndex: number;
  optionIndex: number;
} & IFilterOptions;
@Component({
  selector: 'vip-lista-produtos-desktop',
  templateUrl: './lista-produtos-desktop.component.html',
  styleUrls: ['./lista-produtos-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListaProdutosDesktopComponent
  extends ProdutosAndFiltrosDirective
  implements OnChanges
{
  dropdownOrdenarPorOpen = false;
  selectedOrderingOption?: IFilterOptions;
  selectedFilters: SelectedFiltersOption[] = [];
  isDesktop = this.layoutUtilsService.isDesktop();

  readonly bannersPerPage = 1;

  @Input()
  slides: ISlide[] = [];
  @Input()
  breadcrumbs: IBreadCrumb[] = [];

  @Input()
  paginator?: IApiPaginator;
  @Input()
  isLogged = false;
  @Input()
  orderingOptions: IFilterOptions[] = [];
  @Input()
  qtdMaxVisibleItens = 5;
  @Input()
  emptyText = 'Não conseguimos encontrar nenhum produto';
  @Input()
  emptyImageUrl = `${this.imagesPath}shelf.svg`;

  @Output()
  breadCrumbClick = new EventEmitter<string>();

  @Output()
  visible = new EventEmitter<IProduto>();
  @Output()
  filtersChange = new EventEmitter<IFilter[]>();
  @Output()
  pageClick = new EventEmitter<number>();

  @ViewChild(DropdownDirective)
  dropdownOrdenar!: DropdownDirective;

  @Input() hasSideMenu = false;

  @Input() adsValue: boolean | null = false;
  @Input() filterType = FilterTypeEnum.INTERNAL;

  abrirModalProcurarProduto = false;
  produtosMessageMap: { [k: string]: string } = {
    '=0': 'Sem produtos',
    '=1': '1 Produto',
    other: '# Produtos',
  };
  valueAds: boolean | null = false;

  constructor(
    private layoutUtilsService: LayoutUtilsService,
    @Inject(IMAGES_PATH) private imagesPath: string,
    private campanhaPatrocinadoService: CampanhaPatrocinadoService,
    carrinhoItensFacade: CarrinhoItensFacade
  ) {
    super(carrinhoItensFacade);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filters'] && changes['filters'].currentValue?.length > 0) {
      this.generateSelectedFilters(changes['filters'].currentValue);
    } else if (
      changes['orderingOptions'] &&
      changes['orderingOptions'].currentValue?.length > 0
    ) {
      const { currentValue } = changes['orderingOptions'];

      this.selectedOrderingOption = currentValue.find(
        (option: IFilterOptions) => option.checked
      );
    }
    this.valueAds = this.adsValue;
    this.updateProductsWithCartInfo();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(_: Event) {
    this.isDesktop = this.layoutUtilsService.isDesktop();
  }

  handleSelectedFilterClick(
    selectedFilter?: SelectedFiltersOption,
    index?: number
  ): void {
    if (selectedFilter && typeof index === 'number') {
      const { filterIndex, optionIndex } = selectedFilter;
      const updatedOption = {
        ...this.filters[filterIndex].options[optionIndex],
        checked: false,
      };
      const updatedOptions = [
        ...this.filters[filterIndex].options.slice(0, optionIndex),
        updatedOption,
        ...this.filters[filterIndex].options.slice(optionIndex + 1),
      ];
      this.filters = [
        ...this.filters.slice(0, filterIndex),
        {
          ...this.filters[filterIndex],
          options: updatedOptions,
        },
        ...this.filters.slice(filterIndex + 1),
      ];
      this.selectedFilters.splice(index, 1);
    } else {
      this.filters = this.deselectAllFilters();
      this.selectedFilters = [];
    }

    this.handleFiltersChange(this.filters);
  }

  deselectAllFilters(): IFilter[] {
    return this.filters.map((filter) => {
      return {
        ...filter,
        options: filter.options.map((option) => ({
          ...option,
          checked: false,
        })),
      };
    });
  }

  handleFiltersChange(filters: IFilter[]): void {
    this.generateSelectedFilters(filters);
    this.filtersChange.emit(filters);
  }

  handleOrderingOptionClick(selectedOption: IFilterOptions): void {
    this.orderingOptions = this.orderingOptions.map((option) => {
      return {
        ...option,
        checked: option.value === selectedOption.value,
      };
    });
    this.selectedOrderingOption = { ...selectedOption, checked: true };
    this.externalFilterClick.emit(this.selectedOrderingOption);
    this.dropdownOrdenar?.close();
  }

  private generateSelectedFilters(filters: IFilter[]) {
    this.selectedFilters = [];
    filters
      .filter((filter) => {
        if (filter.name === 'orderby') {
          this.orderingOptions =
            filter.options.length > 0 ? filter.options : this.orderingOptions;
          this.selectedOrderingOption = this.orderingOptions.find(
            (option) => option.checked
          );

          return false;
        }
        return true;
      })
      .map((filter, filterIndex) => {
        return filter.options.reduce((acc, curr, index) => {
          if (curr.checked && curr.value != '0') {
            acc.push({
              ...curr,
              filterIndex,
              optionIndex: index,
            });
          }
          return acc;
        }, [] as SelectedFiltersOption[]);
      })
      .forEach((checkedOptions) => {
        this.selectedFilters.push(...checkedOptions);
      });
  }

  getClique(event: IProduto) {
    this.campanhaPatrocinadoService.clique(event);
  }
}
