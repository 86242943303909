<div class="flex flex-col pb-3" [class.vip-entrega-retirada]="isMobile">
  <vip-screen-title
    title="Entrega e Retirada"
    [customActions]="true"
    *ngIf="isMobile"
  >
    <vip-button-icon-rounded
      class="z-10"
      type="outline"
      data-cy="voltar"
      (btnClick)="goBackClick.emit()"
    >
      <i class="icon-arrow_back vip-color-secondary-main"></i
    ></vip-button-icon-rounded>
  </vip-screen-title>

  <div
    class="p-3 pb-0 flex flex-col flex-grow"
    [ngClass]="{
      'entrega-retira-scroll': isDesktop,
      'overflow-y-scroll': !isDesktop
    }"
  >
    <h3
      class="font-medium vip-color-first-default"
      [ngClass]="isMobile ? 'text-lg' : 'text-base'"
    >
      {{ 'Como deseja receber sua compra?' | desktopText: 'Você deseja:' }}
    </h3>

    <div class="selecionar-tipo-entrega flex justify-center my-4 flex-col">
      <vip-switch
        *ngIf="tiposEntregasOptions"
        class="block mb-3"
        [firstOption]="tiposEntregasOptions[formaEntregaEnum.TIPO_ENTREGA]"
        [secondOption]="tiposEntregasOptions[formaEntregaEnum.TIPO_RETIRADA]"
        [thirdOption]="tiposEntregasOptions[formaEntregaEnum.TIPO_AUTONOMA]"
        [optionChecked]="tiposEntregasOptions['checked']"
        (changedItemActive)="handleTiposEntregasChanged($event)"
      ></vip-switch>
    </div>

    <hr class="vip-divider mb-4" *ngIf="isMobile" />

    <ng-container [ngSwitch]="formaEntrega">
      <ng-container *ngSwitchCase="formaEntregaEnum.TIPO_ENTREGA">
        <h3
          class="font-medium mb-3 vip-color-first-default"
          [ngClass]="isMobile ? 'text-lg' : 'text-base'"
        >
          Em qual endereço deseja receber?
        </h3>
        <ng-container *ngIf="!loadingEnderecos">
          <div
            class="vip-cards-enderecos-wrapper"
            [ngClass]="{
              contents: isMobile,
              'with-scroll': !isMobile
            }"
          >
            <vip-card-endereco-entrega
              class="block"
              *ngFor="let endereco of enderecos; let last = last"
              [ngClass]="{ 'mb-3': !last }"
              [endereco]="endereco"
              [selecionado]="enderecoSelecionado?.id === endereco.id"
              [isCard]="isMobile"
              (changed)="enderecoEntregaChange.emit(endereco)"
              data-cy="endereco-entrega"
            >
            </vip-card-endereco-entrega>
          </div>
        </ng-container>
        <vip-button
          *ngIf="clienteLogado && isMobile"
          class="mt-4"
          [size]="isMobile ? 'medium' : 'small'"
          [type]="isMobile ? 'outline' : 'underline'"
          data-cy="novo-endereco"
          (click)="cadastrarEndereco.emit($event)"
        >
          Adicionar novo endereço
        </vip-button>

        <vip-button
          *ngIf="!clienteLogado && enderecos.length === 0"
          class="mt-4"
          [size]="isMobile ? 'medium' : 'small'"
          [type]="isMobile ? 'outline' : 'underline'"
          data-cy="informar-cep"
          (click)="informarCepClick.emit($event)"
        >
          Informar um CEP
        </vip-button>

        <a
          *ngIf="clienteLogado && !isMobile"
          class="mt-3 novo-endereco-link"
          data-cy="novo-endereco"
          (click)="cadastrarEndereco.emit($event)"
        >
          Novo endereço
        </a>

        <ng-container
          *ngIf="cdsEntrega && cdsEntrega.length > 1 && !!enderecoSelecionado"
        >
          <hr class="vip-divider my-4" />

          <h3
            class="font-medium mb-3 vip-color-first-default"
            id="cds-entrega"
            [ngClass]="isMobile ? 'text-lg' : 'text-base'"
          >
            Em qual loja deseja efetuar sua compra:
          </h3>
          <div
            class="vip-cards-enderecos-wrapper"
            [ngClass]="{
              contents: isMobile,
              'with-scroll flex flex-col': !isMobile
            }"
          >
            <vip-card-endereco-entrega
              class="mb-3"
              *ngFor="let cd of cdsEntrega"
              [cd]="cd"
              [selecionado]="cdSelecionado?.id === cd.id"
              [isCard]="isMobile"
              [showBody]="true"
              (changed)="cdChange.emit({ cd, isFromRetirada: false })"
              data-cy="cd-entrega"
            >
            </vip-card-endereco-entrega>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="formaEntregaEnum.TIPO_RETIRADA">
        <h3
          class="font-medium mb-3 vip-color-first-default"
          [ngClass]="isMobile ? 'text-lg' : 'text-base'"
        >
          Em qual loja deseja retirar sua compra?
        </h3>
        <div
          class="vip-cards-enderecos-wrapper"
          [ngClass]="{
            contents: isMobile,
            'with-scroll flex flex-col': !isMobile
          }"
        >
          <vip-card-endereco-entrega
            *ngFor="let cd of cdsRetirada"
            class="mb-3"
            [cd]="cd"
            [selecionado]="cdSelecionado?.id === cd.id"
            [isCard]="isMobile"
            [showBody]="true"
            [isIconLocation]="true"
            (changed)="handleCdsRetiradaChange(cd)"
            data-cy="cd-retirada"
          >
          </vip-card-endereco-entrega>
        </div>
        <a
          *ngIf="clienteLogado && !isMobile"
          class="mt-0 alterar-endereco-link"
          data-cy="alterar-endereco"
          (click)="alterarEndereco.emit($event)"
        >
          Alterar o CEP
        </a>
      </ng-container>

      <ng-container *ngSwitchCase="formaEntregaEnum.TIPO_AUTONOMA">
        <ng-container
          *ngIf="!isLojaAutonoma; else lojaAutonomaSelecionadaTemplate"
        >
          <h3 class="font-medium text-lg mb-3 vip-color-first-default">
            Loja Autônoma
          </h3>

          <p class="text-sm mb-3 mx-2 text-center">
            Clique no botão abaixo e com a câmera do seu celular, leia o QrCode
            para identificar a loja que você se encontra.
          </p>

          <vip-button
            data-cy="btn-qrcode-scan"
            type="outline"
            (btnClick)="qrCodeScan.emit(qtdItensCarrinho > 0)"
            >Ler QrCode</vip-button
          >
        </ng-container>
        <ng-template #lojaAutonomaSelecionadaTemplate>
          <h3 class="font-medium text-lg mb-3 vip-color-first-default">
            Você está comprando em:
          </h3>
          <vip-card-endereco-entrega
            *ngIf="cdSelecionado"
            class="mb-3"
            [cd]="cdSelecionado"
            [selecionado]="true"
            [isCard]="true"
            data-cy="cd-loja-autonoma"
          >
          </vip-card-endereco-entrega>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="isMobile">
    <hr class="vip-divider mb-3" />
    <div class="mx-3 my-0">
      <vip-button
        size="medium"
        data-cy="btn-confirmar"
        (click)="confirmarClick.emit()"
      >
        Confirmar
      </vip-button>
    </div>
  </div>
</div>
