import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ICarousel, IPaginaInstitucional, LayoutUtilsService } from '@vip/core';
import { InstitucionalFacade } from '@vip/state/institucional';
import { TipoEntregaFacade } from '@vip/state/tipo-entrega';
import { BannerFacade } from '@vip/state/banner';
import { DialogService, IDialog } from '@vip/ui/modal';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'vip-simular-frete-container',
  templateUrl: './simular-frete-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimularFreteContainerComponent implements OnInit {
  paginas$ = this.institucionalFacade.paginas$;
  tiposEntregas$ = this.tipoEntregaFacade.tiposEntregas$;
  banners$!: Observable<ICarousel | null>;
  pagina!: IPaginaInstitucional;
  errorTiposEntregas$!: Subscription;
  isDesktop$ = this.layoutUtilsService.isDesktop$;
  isDesktopDevice = this.layoutUtilsService.isDesktopWithoutScreenWidth();
  isMobile = this.layoutUtilsService.isMobile();

  constructor(
    private institucionalFacade: InstitucionalFacade,
    private tipoEntregaFacade: TipoEntregaFacade,
    private bannerFacade: BannerFacade,
    private dialogService: DialogService,
    private changeDetector: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService
  ) {
    this.institucionalFacade.getPaginasInstitucionais();
    this.banners$ = this.bannerFacade.bannersInstitucionais$;
  }

  ngOnInit(): void {
    this.errorTiposEntregas$ = this.tipoEntregaFacade.error$
      .pipe(untilDestroyed(this))
      .subscribe((_error) => {
        if (_error != null) {
          this.exibeMensagemCepNaoAtendido();
        }
      });

    this.limparTiposEntrega();
    this.paginas$.pipe(untilDestroyed(this)).subscribe((_paginas) => {
      this.pagina = _paginas.find(
        (_pag) => _pag.slug == 'simulador-de-frete'
      ) as IPaginaInstitucional;
      this.changeDetector.detectChanges();
    });

    this.bannerFacade.getBannersInstitucionais('simulador-de-frete');
  }

  getFretesPorCep(cep: string) {
    this.tiposEntregas$ = this.tipoEntregaFacade.tiposEntregas$;
    this.tipoEntregaFacade.getFretesPorCep(cep);
  }

  exibeMensagemCepNaoAtendido() {
    const errorDialog: IDialog = {
      open: true,
      title: 'Atenção',
      subTitle: `Nossos serviços ainda não estão
      disponíveis em sua região, mas adoraríamos
      recebê-lo em uma de nossas lojas físicas.`,
      disabled: true,
      buttonConfirmText: 'Ok, entendi',
    };

    this.dialogService.openDialog(errorDialog);
    this.dialogService.dialogClick.pipe(untilDestroyed(this)).subscribe(() => {
      this.dialogService.clearDialog();
    });
  }

  filtrarTiposEntrega(tipoEntrega: { retirada: boolean; entrega: boolean }) {
    this.tiposEntregas$ = this.tipoEntregaFacade.tiposEntregas$;
    if (tipoEntrega.entrega && !tipoEntrega.retirada) {
      this.tiposEntregas$ = this.tipoEntregaFacade.tiposEntregas$.pipe(
        map((_tipoEntregas) =>
          _tipoEntregas.filter(
            (_tipoEntrega) => _tipoEntrega.retirada_loja == false
          )
        )
      );
    }
    if (tipoEntrega.retirada && !tipoEntrega.entrega) {
      this.tiposEntregas$ = this.tipoEntregaFacade.tiposEntregas$.pipe(
        map((_tipoEntregas) =>
          _tipoEntregas.filter(
            (_tipoEntrega) => _tipoEntrega.retirada_loja == true
          )
        )
      );
    }
  }

  limparTiposEntrega() {
    this.tipoEntregaFacade.resetTiposEntrega();
  }
}
