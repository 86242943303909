import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  CampanhaPatrocinadoService,
  ProdutoApiService,
  SolicitarProdutoRequest,
} from '@vip/api';
import { IProduto } from '@vip/core';

@Injectable()
export class ProdutoFacade {
  constructor(
    private produtoApiService: ProdutoApiService,
    private campanhaPatrocinadoService: CampanhaPatrocinadoService
  ) {}

  solicitarProduto(filialId: number, data: SolicitarProdutoRequest) {
    return this.produtoApiService.solicitarProduto(filialId, data);
  }

  enviaImpressao(produtos: IProduto[]) {
    return this.campanhaPatrocinadoService.impressao(produtos);
  }
}
