import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  IProdutoCarrinho,
  IProdutoCombo,
  IProdutosCarrinhoGroup,
} from '@vip/core';
import { ProdutosAndFiltrosDirective } from '@vip/views/lista-produtos';

@Component({
  selector: 'vip-lista-produtos-selecionados',
  templateUrl: './lista-produtos-selecionados.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListaProdutosSelecionadosComponent extends ProdutosAndFiltrosDirective {
  @Output() deleteItemClick = new EventEmitter();

  @Input() showIconDelete = true;
  @Input() showLayoutReceita = false;
  @Input() showSpin = true;
  @Input() divider = false;
  @Input() confirmDeletion = true;
  @Input() showPromotions = true;
  @Input() showCombos = true;
  @Input() isDesktop = false;
  @Input() combosNoCarrinho?: Map<
    number,
    { item_id: number; quantidade: number; observacao?: string }
  > | null = null;
  @Input() disabled = false;
  @Input() hiddenSeletor = false;
  @Input() isTablet = false;

  private _editable = true;

  get editable(): boolean {
    return this._editable;
  }

  @Input()
  set editable(value: boolean) {
    if (!value) {
      this.showIconDelete = false;
    }
    this._editable = value;
  }

  private _produtosAgrupados!: IProdutosCarrinhoGroup;
  get produtosAgrupados(): IProdutosCarrinhoGroup {
    return this._produtosAgrupados;
  }

  @Input()
  set produtosAgrupados(item: IProdutosCarrinhoGroup) {
    const sortedItem = item;
    sortedItem.produtos.sort((a, b) => (!a?.combo && !!b?.combo && 1) || -1);
    this._produtosAgrupados = sortedItem;
  }

  trackByFnCarrinho(_: number, product: IProdutoCarrinho): number | null {
    return product.produto_id;
  }

  getCombo(ItemCarrinho: IProdutoCarrinho): IProdutoCombo {
    return {
      ...(ItemCarrinho.combo as IProdutoCombo),
      item_id: ItemCarrinho.item_id,
      produto_id: ItemCarrinho.mercadoria_id,
      tipo: ItemCarrinho.tipo,
      quantidade: ItemCarrinho.quantidade,
      possui_unidade_diferente: ItemCarrinho.possui_unidade_diferente,
      quantidade_unidade_diferente: ItemCarrinho.quantidade_unidade_diferente,
    };
  }
}
