<div class="mx-4">
  <div class="flex flex-col flex-grow">
    <div class="mt-4">
      <p class="text-base mt-1 mb-2 vip-color-secondary-default">
        Escolha uma forma de pagamento:
      </p>
    </div>
    <div class="flex flex-wrap">
      <div *ngFor="let carteira of formasPagamentosCarteiraDigital">
        <vip-selectable-card
          class="items-center"
          data-cy="card-forma-pagamento"
          [showIcon]="true"
          [fullWidth]="true"
          [expanded]="true"
          [checked]="
            (formaPagamentoSelecionada &&
              formaPagamentoSelecionada.id === carteira.id) ||
            false
          "
          (changed)="handleChangeFormaPagamento(carteira.id)"
        >
          <div title class="flex flex-col">
            <div class="flex items-center justify-between">
              <div
                class="logomarca"
                [ngStyle]="{
                  'background-image': 'url(' + carteira.logomarca + ')'
                }"
              ></div>
              <i
                *ngIf="
                  (formaPagamentoSelecionada &&
                    formaPagamentoSelecionada.id === carteira.id) ||
                  false
                "
                class="vip-icon icon-check"
              ></i>
            </div>
            <div class="mt-10">
              <span class="text-xs vip-color-first-default text">
                {{ carteira.descricao }}
              </span>
            </div>
          </div>
        </vip-selectable-card>
      </div>
    </div>
  </div>
</div>
