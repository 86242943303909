import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'vip-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResumeComponent {
  @Input() label!: string;

  @Input() color: 'primary' | 'error' = 'primary';

  @Input() itens!: number;

  @Input() valor!: number;

  @Output() resumeClick = new EventEmitter();

  itensMap: { [k: string]: string } = {
    '=0': 'Sem itens',
    '=1': '1 item',
    other: '# itens',
  };

  get rippleColor() {
    return `color-${this.color}-contrast`;
  }
}
