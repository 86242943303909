import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'vip-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent {
  @Input() checked = false;
  @Input() disabled = false;
  @Input() isDesktopView = false;
  @Output() toggle = new EventEmitter();

  inputChange() {
    if (!this.disabled) {
      this.checked = !this.checked;
      this.toggle.emit(this.checked);
    }
  }
}
