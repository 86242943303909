import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService, environment } from '@vip/core';
import { App } from '@capacitor/app';
import { FilialFacade } from '@vip/state/filial';

@Injectable({
  providedIn: 'root',
})
export class BaseWsService<T> {
  protected baseUrl = '';

  appVersion: string | null = null;

  get defaultHeaders() {
    const defaultHeaders: { [key: string]: string } = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      DomainKey: this.environmentService.getDomainKey(),
      OrganizationId: String(this.environmentService.getOrgId()),
      FilialID: String(this.filialFacade.filial.id),
    };
    if (this.appVersion) {
      defaultHeaders['App-Version'] = this.appVersion;
    }
    return defaultHeaders;
  }

  versao!: string;

  constructor(
    protected http: HttpClient,
    private environmentService: EnvironmentService,
    private filialFacade: FilialFacade
  ) {
    this.baseUrl = this.getWsUrl();
    if (environment.isApp) {
      this.getAppVersion();
    }
  }

  public post<Request, Response>(
    method: keyof T,
    route: string,
    body?: Request,
    headers?: { [key: string]: string }
  ) {
    return <Observable<Response>>this.http.post(
      `${this.baseUrl}${route}`,
      body,
      {
        headers: {
          ...this.defaultHeaders,
          ...headers,
        },
      }
    );
  }

  public get<Response>(
    method: keyof T,
    route: string,
    headers?: { [key: string]: string }
  ) {
    return <Observable<Response>>this.http.get(`${this.baseUrl}${route}`, {
      headers: {
        ...this.defaultHeaders,
        ...headers,
      },
    });
  }

  public delete<Response>(
    method: keyof T,
    route: string,
    headers?: { [key: string]: string }
  ) {
    return <Observable<Response>>this.http.delete(`${this.baseUrl}${route}`, {
      headers: {
        ...this.defaultHeaders,
        ...headers,
      },
    });
  }

  private async getAppVersion() {
    try {
      const appInfo = await App.getInfo();
      this.appVersion = appInfo.version;
    } catch (_error) {
      this.appVersion = '9.9.9';
    }
  }

  getWsUrl() {
    return this.environmentService.getWsUrl();
  }
}
