import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ICliente, MaskPatterns, CustomValidators } from '@vip/core';

@Component({
  selector: 'vip-form-pessoa-juridica',
  templateUrl: './form-pessoa-juridica.component.html',
  styleUrls: ['./form-pessoa-juridica.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPessoaJuridicaComponent {
  formGroup: UntypedFormGroup;

  readonly masks = {
    cnpj: MaskPatterns.CNPJ,
  };

  validations = {
    default: {
      required: 'Campo obrigatório',
    },
    cnpj: {
      invalidCnpj: 'CNPJ inválido',
      required: 'Campo obrigatório',
    },
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.formGroup = this.formBuilder.group({
      razao_social: ['', [Validators.required]],
      nome_fantasia: ['', [Validators.required]],
      cnpj: ['', [CustomValidators.cnpj, Validators.required]],
      inscricao_estadual: [''],
    });
  }

  private _isEdit = false;

  @Input() set isEdit(isEdit: boolean) {
    this._isEdit = isEdit;
    if (this._isEdit) {
      this.formGroup.get('cnpj')?.disable();
      this.formGroup.get('inscricao_estadual')?.disable();
    }
  }
  @Input() displayGrid = false;
  @Input() isModal = false;

  setValue(cliente: ICliente) {
    this.formGroup.patchValue(cliente);
    this.formGroup.controls.cnpj.setValue(cliente.cnpj_formatado);
    this.changeDetectorRef.detectChanges();
  }

  submit() {
    this.formGroup.markAllAsTouched();
    this.changeDetectorRef.markForCheck();
  }
}
