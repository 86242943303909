import { DEFAULT_CURRENCY_CODE, LOCALE_ID } from '@angular/core';
import {
  DATE_FN_LOCALE,
  DATE_FORMAT,
  IMAGES_PATH,
  IS_APP,
} from './injection-tokens';
import { ptBR } from 'date-fns/locale';

export const LOCALIZATION_PROVIDERS = [
  { provide: LOCALE_ID, useValue: 'pt-BR' },
  { provide: DATE_FN_LOCALE, useValue: ptBR },
  { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
  { provide: DATE_FORMAT, useValue: 'MM/dd/yyyy' },
];

export const PROVIDERS_STORYBOOK = [
  ...LOCALIZATION_PROVIDERS,
  { provide: IMAGES_PATH, useValue: '' },
  { provide: IS_APP, useValue: true },
];

export const PROVIDERS_STORYBOOK_DESKTOP = [
  ...LOCALIZATION_PROVIDERS,
  { provide: IMAGES_PATH, useValue: '' },
  { provide: IS_APP, useValue: false },
];

export const PROVIDERS_APP = [
  ...LOCALIZATION_PROVIDERS,
  { provide: IMAGES_PATH, useValue: '../assets/images/' },
  { provide: IS_APP, useValue: true },
];

export const PROVIDERS_SITE = [
  ...LOCALIZATION_PROVIDERS,
  { provide: IMAGES_PATH, useValue: '../assets/images/' },
  { provide: IS_APP, useValue: false },
];
