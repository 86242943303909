import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { ListaApiService, ParticiparListaRequest } from '@vip/api';
import { IList, INovaLista } from '@vip/core';
import { DialogService, IDialog } from '@vip/ui/modal';
import { ListaWsService } from '@vip/ws';
import * as ListaActions from './lista.actions';
import { listaSelectors } from './lista.selectors';
import { map } from 'rxjs/operators';

@Injectable()
export class ListaFacade {
  error$ = this.store.pipe(select(listaSelectors.getError()));
  loading$ = this.store.pipe(select(listaSelectors.isLoading()));
  minhasListas$ = this.store.pipe(
    select(listaSelectors.getMinhasListasArray())
  );
  compartilhadas$ = this.store.pipe(
    select(listaSelectors.getListasCompartilhadasArray())
  );
  minhasListasECompartilhadas$ = this.store.pipe(
    select(listaSelectors.getListasArray())
  );
  criarListaSuccess$ = this.actions$.pipe(
    ofType(ListaActions.criarListaSuccess),
    map((action) => action.lista)
  );
  listaSelecionada$ = this.store.pipe(
    select(listaSelectors.getListaSelecionada())
  );

  constructor(
    private store: Store,
    private listaWsService: ListaWsService,
    private listaApiService: ListaApiService,
    private actions$: Actions,
    private dialogService: DialogService
  ) {}

  getListas() {
    this.store.dispatch(ListaActions.loadListas());
  }

  getLista(listaId: number) {
    this.store.dispatch(ListaActions.getLista({ listaId }));
  }

  getListaPerSlug(filialId: number, slug: string) {
    return this.listaWsService.getListaPerSlug(filialId, slug);
  }

  participarLista(filialId: number, data: ParticiparListaRequest) {
    return this.listaApiService.participarLista(filialId, data);
  }

  criarLista(novaLista: INovaLista): void {
    this.store.dispatch(ListaActions.criarLista({ novaLista }));
  }

  editarLista(lista: IList): void {
    this.store.dispatch(ListaActions.editarLista({ lista }));
  }

  selecionarLista(listaId: number): void {
    this.store.dispatch(ListaActions.selecionarLista({ listaId }));
  }

  limparListaSelecionada() {
    this.store.dispatch(ListaActions.limparListaSelecionada());
  }

  excluirLista(lista: IList) {
    const dataDialog: IDialog = {
      open: true,
      title: 'Excluir lista?',
      subTitle: `Tem certeza que deseja excluir a lista "${lista.nome}"`,
      disabled: false,
      buttonConfirmText: 'Excluir lista!',
      buttonCancelText: 'Manter lista!',
    };

    this.dialogService.openDialog(dataDialog);

    this.dialogService.dialogClick.subscribe((value) => {
      if (value)
        this.store.dispatch(
          ListaActions.excluirLista({ listaId: lista.lista_id })
        );
      this.dialogService.clearDialog();
    });
  }
}
