<vip-base-component-skeleton [quantity]="quantity">
  <ng-template #skeletonContent>
    <div
      class="mb-3 px-3 py-2 vip-simple-card-skeleton rounded"
      data-cy="vip-simple-card-skeleton"
    >
      <div title class="flex justify-between">
        <ng-container
          *vipSkeleton="true; height: '15px'; width: '100px'"
        ></ng-container>
        <div *vipSkeleton="true; height: '15px'; width: '70px'"></div>
      </div>
      <div *vipSkeleton="true; className: ['mt-3']"></div>
    </div>
  </ng-template>
</vip-base-component-skeleton>
