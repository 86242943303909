import { createSelector } from '@ngrx/store';
import { TERMOS_USO_FEATURE_KEY, TermosUsoState } from './termos-uso.reducer';
import { SelectorUtil } from '@vip/state/utils';

class TermosUsoSelectors extends SelectorUtil<TermosUsoState> {
  constructor() {
    super(TERMOS_USO_FEATURE_KEY);
  }

  getAprovacaoPendente = () =>
    createSelector(
      this.createFeatureSelector,
      (state: TermosUsoState) => state.aprovacaoPendente
    );
}

export const termosUsoSelectors = new TermosUsoSelectors();
