import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiMessages } from '../common/base-api-messages';
import { ClienteApiService } from './cliente-api.service';

/**
 * Converte as mensagens de erro retornadas pela api-admin, para cada método,
 * em códigos de erro.
 * Retorna undefined, caso não seja identificado um erro específico
 */

export type MessageCode = 'AUTH_FAIL' | 'NO_INITIAL_JWT';

@Injectable({
  providedIn: 'root',
})
export class ClienteApiServiceMessages
  implements IApiMessages<ClienteApiService>
{
  getErrorCode = (
    response: HttpErrorResponse,
    method: keyof ClienteApiService
  ): MessageCode | void => {
    switch (method) {
      case 'login':
        if (
          response.error.error == 'Autorização negada. Credenciais inválidas.'
        )
          return 'AUTH_FAIL';
        if (response.status == 401) return 'NO_INITIAL_JWT';
    }
  };
}
