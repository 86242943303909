import { Inject, Injectable } from '@angular/core';
import {
  STORAGE,
  IStorage,
  LayoutUtilsService,
  DEFAULT_COOKIE_EXPIRATION_TIME,
  CLIENTE_TOKEN,
} from '@vip/core';

@Injectable({
  providedIn: 'root',
})
export class ClienteTokenService {
  private tokenCache: string | null = null;
  readonly defaultExpirationTime = DEFAULT_COOKIE_EXPIRATION_TIME;
  private isSite = this.layoutUtilsService.isDesktopWithoutScreenWidth();

  constructor(
    @Inject(STORAGE) readonly storage: IStorage,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  getToken(): string | null {
    if (this.tokenCache) {
      return this.tokenCache;
    }
    this.tokenCache = this.storage.getItem(CLIENTE_TOKEN) || '';
    return this.tokenCache;
  }

  setToken(value: string) {
    if (this.isSite) {
      this.storage.setItem(
        CLIENTE_TOKEN,
        value,
        this.defaultExpirationTime,
        '/'
      );
      return;
    }
    this.storage.setItem(CLIENTE_TOKEN, value);
  }

  clearToken() {
    this.tokenCache = null;
    if (this.isSite) {
      this.storage.removeItem(CLIENTE_TOKEN, '/');
      return;
    }
    this.storage.removeItem(CLIENTE_TOKEN);
  }
}
