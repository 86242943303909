<section class="vip-opcoes-pagamento-main-wrapper">
  <ng-container *ngIf="!isLoading; else skeletonTitleTemplate">
    <h2>
      <i class="icon-attach_money vip-color-primary-main"></i>
      <span class="font-bold">Opções de pagamento</span>
    </h2>
  </ng-container>
  <ng-template #skeletonTitleTemplate>
    <div class="flex p-4" data-cy="vip-skeleton-title">
      <div
        *vipSkeleton="true; height: '24px'; width: '24px'; className: ['mr-3']"
      ></div>
      <div *vipSkeleton="true; height: '24px'; width: '160px'"></div>
    </div>
  </ng-template>

  <hr class="vip-divider" />
  <article class="relative vip-opcoes-pagamento-width">
    <vip-tab-group-desktop
      [separeteDividerFromContent]="false"
      [isLoading]="isLoading"
      (tabChange)="handleTabChange($event)"
      (selectedTabEmitter)="selectedTab = $event"
    >
      <ng-container
        *ngIf="!isLoading; then opcoesTemplate; else skeletonContentTemplate"
      ></ng-container>
      <ng-template #opcoesTemplate>
        <ng-container
          *ngIf="
            tiposPagamentoOnline.length >= 1 && !isTelevendas;
            else emptyTemplateOnline
          "
        >
          <vip-tab
            *ngFor="
              let pagamento of tiposPagamentoOnline;
              let first = first;
              let index = index
            "
            [showParent]="first"
            [icon]="first ? 'icon-phone_iphone' : ''"
            [label]="first ? 'Pagar online' : ''"
            [chidrenLabel]="pagamento.label"
            [chidrenIcon]="pagamento.titulo | iconPagamentoOnlinePipe"
            [id]="pagamento.titulo"
            [disabled]="isTelevendas"
          >
            <ng-container [ngSwitch]="pagamento.titulo">
              <article
                class="relative mb-4"
                [ngClass]="{ 'pb-4': semValorPendente }"
              >
                <section
                  *ngSwitchCase="TITULO_PAGAMENTO_ENUM.CREDITO"
                  [class.disable-payment]="semValorPendente"
                >
                  <vip-credito-opcao-pagamento
                    [cartoes]="cartoes"
                    [parcelas]="parcelas"
                    [cartaoSelecionadoId]="cartaoSelecionadoId"
                    [formaPagamentoSelecionada]="formaPagamentoSelecionada"
                    [permitirSalvarCartao]="permitirSalvarCartao"
                    [compraEmProcesso]="compraEmProcesso"
                    [resetarCcNumber$]="resetarCcNumber$"
                    [privateLabelBandeiraAutomatica]="
                      privateLabelBandeiraAutomatica
                    "
                    [formaPagamentosOptionsCartaoCredito]="
                      formaPagamentosOptionsCartaoCredito
                    "
                    [formasPagamentosPrePagasCartaoCredito]="
                      formasPagamentosPrePagasCartaoCredito
                    "
                    [permiteSelecaoManual]="permiteSelecaoManual"
                    [permitirBandeiraAutomatica]="permitirBandeiraAutomatica"
                    [limparForm$]="formaPagamentoChange"
                    [compraEmProcessoError]="compraEmProcessoError"
                    (selectParcela)="selectParcela.emit($event)"
                    (cvvValueChange)="cvvValueChange.emit($event)"
                    (deletarCartao)="deletarCartao.emit($event)"
                    (cartaoSalvoChange)="cartaoSalvoChange.emit($event)"
                    (alterarBandeiraCartao)="
                      handleAlterarBandeiraCartao($event)
                    "
                    (buscarBandeiraCartao)="handleBuscarBandeiraCartao($event)"
                    (adicionarCartao)="adicionarCartao.emit($event)"
                    (resetarFormaPgto)="formaPagamentoChange.emit()"
                    (formEmitter)="
                      addFormsToList($event, TITULO_PAGAMENTO_ENUM.CREDITO)
                    "
                    (formCartaoSalvoEmitter)="formCartaoSalvo = $event"
                    (cadastrarNovoCartaoEmitter)="handleTabChange(index)"
                  ></vip-credito-opcao-pagamento>
                </section>

                <section
                  *ngSwitchCase="TITULO_PAGAMENTO_ENUM.CARTEIRA_DIGITAL"
                  [class.disable-payment]="semValorPendente"
                >
                  <vip-carteira-digital-pagamento
                    [formaPagamentoSelecionada]="formaPagamentoSelecionada"
                    [compraEmProcesso]="compraEmProcesso"
                    [formasPagamentosOptionsCarteiraDigital]="
                      formasPagamentosOptionsCarteiraDigital
                    "
                    [formasPagamentosCarteiraDigital]="
                      formasPagamentosCarteiraDigital
                    "
                    (formaPagamentoChange)="carteiraDigitalChange($event)"
                  ></vip-carteira-digital-pagamento>
                </section>

                <section
                  *ngSwitchCase="TITULO_PAGAMENTO_ENUM.VOUCHER"
                  [class.disable-payment]="semValorPendente"
                >
                  <vip-credito-opcao-pagamento
                    [tituloPagamento]="TITULO_PAGAMENTO_ENUM.VOUCHER"
                    [parcelas]="parcelas"
                    [formaPagamentoSelecionada]="formaPagamentoSelecionada"
                    [compraEmProcesso]="compraEmProcesso"
                    [resetarCcNumber$]="resetarCcNumber$"
                    [privateLabelBandeiraAutomatica]="
                      privateLabelBandeiraAutomatica
                    "
                    [formaPagamentosOptionsCartaoCredito]="
                      formasPagamentosOptionsVoucher
                    "
                    [formasPagamentosPrePagasCartaoCredito]="
                      formasPagamentosPrePagasVoucher
                    "
                    [permiteSelecaoManual]="permiteSelecaoManual"
                    [permitirBandeiraAutomatica]="permitirBandeiraAutomatica"
                    [limparForm$]="formaPagamentoChange"
                    (selectParcela)="selectParcela.emit($event)"
                    (cvvValueChange)="cvvValueChange.emit($event)"
                    (deletarCartao)="deletarCartao.emit($event)"
                    (alterarBandeiraCartao)="
                      handleAlterarBandeiraCartao($event)
                    "
                    (adicionarCartao)="adicionarCartao.emit($event)"
                    (buscarBandeiraCartao)="handleBuscarBandeiraCartao($event)"
                    (formEmitter)="
                      addFormsToList($event, TITULO_PAGAMENTO_ENUM.VOUCHER)
                    "
                  ></vip-credito-opcao-pagamento>
                </section>

                <section
                  *ngSwitchCase="TITULO_PAGAMENTO_ENUM.CARTAO_DA_LOJA"
                  [class.disable-payment]="semValorPendente"
                >
                  <vip-cartao-loja-opcao-pagamento
                    [formaPagamentoSelecionada]="formaPagamentoSelecionada"
                    [compraEmProcesso]="compraEmProcesso"
                    [privateLabelBandeiraAutomatica]="
                      privateLabelBandeiraAutomatica
                    "
                    [formaPagamentosOptionsCartaoLoja]="
                      formaPagamentosOptionsCartaoLoja
                    "
                    [formasPagamentosPrePagasCartaoLoja]="
                      formasPagamentosPrePagasCartaoLoja
                    "
                    [resetarCcNumber$]="resetarCcNumber$"
                    [parcelas]="parcelas"
                    [limparForm$]="formaPagamentoChange"
                    (alterarBandeiraCartao)="
                      handleAlterarBandeiraCartao($event)
                    "
                    (adicionarCartao)="adicionarCartao.emit($event)"
                    (formEmitter)="
                      addFormsToList(
                        $event,
                        TITULO_PAGAMENTO_ENUM.CARTAO_DA_LOJA
                      )
                    "
                  ></vip-cartao-loja-opcao-pagamento>
                </section>
                <ng-container *ngTemplateOutlet="noDebtsMessage"></ng-container>
              </article>
            </ng-container>
            <vip-codigo-promocional-opcao-pagamento
              [cupons]="cupons"
              [cuponsSelecionados]="cuponsSelecionados"
              [codigoPromocional]="codigoPromocional"
              (ativarCodigo)="ativarCodigo.emit($event)"
              (deleteCodigoPromocionalClick)="
                deleteCodigoPromocionalClick.emit($event)
              "
              (changedSelecionados)="changedCuponsSelecionados.emit($event)"
            ></vip-codigo-promocional-opcao-pagamento>
          </vip-tab>
        </ng-container>
        <ng-template #emptyTemplateOnline>
          <vip-tab
            [showParent]="false"
            [icon]="'icon-phone_iphone'"
            [label]="'Pagar online'"
            [disabled]="true"
            disabledText="Indisponível"
          ></vip-tab>
        </ng-template>

        <ng-container
          *ngIf="tiposPagamentoEntrega.length >= 1; else emptyTemplateEntrega"
        >
          <vip-tab icon="icon-local_shipping" label="Pagar na entrega">
            <section
              class="p-4 tab-pagamento"
              [class.disable-payment]="semValorPendente"
            >
              <span *ngIf="!removerPrepagos; else prePagoBloqueado"
                >Escolha a opção de pagamento desejada:</span
              >
              <ng-template #prePagoBloqueado>
                <span>
                  O pagamento online foi bloqueado devido à várias tentativas
                  falhas no pagamento. Selecione um método para realizar o
                  pagamento na entrega:</span
                >
              </ng-template>
              <div
                class="flex gap-3 flex-wrap mt-4 max-h-80"
                [ngStyle]="{
                  overflow:
                    getPagamentosEntrega().length > 6 ? 'scroll' : 'none'
                }"
              >
                <ng-container *ngFor="let pagamento of getPagamentosEntrega()">
                  <vip-card-basico
                    [ngClass]="{
                      active: pagamento.id === formaPagamentoSelecionada?.id
                    }"
                    [contentFull]="true"
                    (cardClick)="
                      pagamento.id === formaPagamentoSelecionada?.id
                        ? formaPagamentoChange.emit()
                        : formaPagamentoChange.emit(pagamento.id)
                    "
                  >
                    <div class="card-content">
                      <div class="flex flex-row justify-between">
                        <img
                          [src]="pagamento.logomarca"
                          [alt]="pagamento.descricao"
                        />
                        <i
                          *ngIf="pagamento.id === formaPagamentoSelecionada?.id"
                          class="icon-check vip-color-primary-main"
                        ></i>
                      </div>
                      <span>{{ pagamento.descricao }}</span>
                    </div>
                  </vip-card-basico>
                </ng-container>
              </div>
            </section>

            <ng-container *ngIf="formaPagamentoSelecionada?.id === 1">
              <div [class.disable-payment]="semValorPendente">
                <span class="tab-pagamento ml-4"
                  >Você vai precisar de troco?</span
                >
                <form [formGroup]="form">
                  <vip-field
                    class="input-troco"
                    label="TROCO PARA*"
                    [message]="validations"
                    [error]="
                      form.controls['troco'].invalid &&
                      (form.controls['troco'].touched ||
                        form.controls['troco'].dirty)
                    "
                    [validations]="form.controls['troco'].errors"
                  >
                    <input
                      id="input-troco"
                      type="text"
                      currencyMask
                      formControlName="troco"
                      inputmode="decimal"
                      (keyup)="setTroco($event)"
                      (input)="setTroco($event)"
                    />
                  </vip-field>
                </form>
                <hr class="vip-divider mt-4" />
              </div>
            </ng-container>
            <ng-container *ngTemplateOutlet="noDebtsMessage"></ng-container>
            <vip-codigo-promocional-opcao-pagamento
              [cupons]="cupons"
              [cuponsSelecionados]="cuponsSelecionados"
              [codigoPromocional]="codigoPromocional"
              (ativarCodigo)="ativarCodigo.emit($event)"
              (deleteCodigoPromocionalClick)="
                deleteCodigoPromocionalClick.emit($event)
              "
              (changedSelecionados)="changedCuponsSelecionados.emit($event)"
            ></vip-codigo-promocional-opcao-pagamento>
          </vip-tab>
        </ng-container>

        <ng-template #emptyTemplateEntrega>
          <ng-container
            *ngTemplateOutlet="
              genericEmptyTemplate;
              context: {
                label: 'Pagar na entrega',
                icon: 'icon-local_shipping'
              }
            "
          ></ng-container>
        </ng-template>

        <ng-template #genericEmptyTemplate let-label="label" let-icon="icon">
          <vip-tab
            [icon]="icon"
            [label]="label"
            [disabled]="true"
            disabledText="Indisponível"
          ></vip-tab>
        </ng-template>
        <ng-template #noDebtsMessage>
          <div
            *ngIf="semValorPendente"
            class="vip-no-debts w-full bg-white bg-opacity-60 flex items-end"
            data-cy="vip-no-debts"
          >
            <span class="vip-color-success-main ml-4"
              >Não há débitos a serem cobrados. Por favor, clique em “Concluir
              compra”</span
            >
          </div>
        </ng-template>
      </ng-template>
      <ng-template #skeletonContentTemplate>
        <vip-credito-opcao-pagamento-skeleton></vip-credito-opcao-pagamento-skeleton>
      </ng-template>
    </vip-tab-group-desktop>
  </article>
</section>
