import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FilialStateModule } from '@vip/state/filial';
import { EnderecoStateModule } from '@vip/state/endereco';
import * as fromTipoEntregas from './+state/tipo-entrega.reducer';
import { TipoEntregaEffects } from './+state/tipo-entrega.effects';
import { TipoEntregaFacade } from './+state/tipo-entrega.facade';
import { CepStateModule } from '@vip/state/cep';
import { LoadingStateModule } from '@vip/state/loading';
import { CompraEmProcessoProviderModule } from '@vip/state/compra-em-processo-provider';

@NgModule({
  imports: [
    CommonModule,
    FilialStateModule,
    EnderecoStateModule,
    CepStateModule,
    LoadingStateModule,
    StoreModule.forFeature(
      fromTipoEntregas.TIPO_ENTREGAS_FEATURE_KEY,
      fromTipoEntregas.reducer
    ),
    EffectsModule.forFeature([TipoEntregaEffects]),
    CompraEmProcessoProviderModule,
  ],
  providers: [TipoEntregaFacade],
})
export class TipoEntregaStateModule {}
