import { CardEnderecoModule } from '@vip/ui/card-endereco';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnderecoComponent } from './endereco/endereco.component';
import { ScreenTitleModule } from '@vip/ui/screen-title';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@vip/core';
import { ButtonModule } from '@vip/ui/button';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { CheckboxModule } from '@vip/ui/checkbox';
import { SelectModule } from '@vip/ui/select';
import { DropdownMenuModule } from '@vip/ui/dropdown-menu';
import { FieldModule } from '@vip/ui/field';
import { FormEnderecoComponent } from './form-endereco/form-endereco.component';
import { MeusEnderecosComponent } from './meus-enderecos/meus-enderecos.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    DropdownMenuModule,
    FormsModule,
    ScreenTitleModule,
    FieldModule,
    SelectModule,
    ButtonModule,
    CheckboxModule,
    CardEnderecoModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [MaskPipe],
  declarations: [
    EnderecoComponent,
    FormEnderecoComponent,
    MeusEnderecosComponent,
  ],
  exports: [EnderecoComponent, FormEnderecoComponent, MeusEnderecosComponent],
})
export class EnderecoModule {}
