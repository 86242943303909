import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxBaseDirective } from '../checkbox-base.directive';

@Component({
  selector: 'vip-checkbox-pill',
  templateUrl: './checkbox-pill.component.html',
  styleUrls: ['./checkbox-pill.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxPillComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxPillComponent extends CheckboxBaseDirective {
  @Input() labelSize = 'sm';

  constructor(private changeDetector: ChangeDetectorRef) {
    super();
  }

  protected changeModelValue(value: boolean) {
    this.checkboxValue = value;
    this.changeDetector.detectChanges();
  }
}
