import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ICarousel, IFilter, IFilterOptions } from '@vip/core';

@Component({
  selector: 'vip-grid-card',
  templateUrl: './grid-card.component.html',
  styleUrls: ['./grid-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCardComponent {
  @ViewChild('cardListContainer')
  cardContainer!: ElementRef;

  @Input()
  defaultFilter = '0';

  @Input() scrollContainerSelector?: string;

  @Input() filters: IFilter[] = [];

  @Input() carousel: ICarousel | null = null;

  @Input() isDesktop = false;

  @Input() isLoading = false;

  @Input() distanceScroll = 10;

  @Output() loadMore = new EventEmitter();

  @Output()
  externalFilterClick: EventEmitter<IFilterOptions> = new EventEmitter();
  @Output() filterClick: EventEmitter<Event> = new EventEmitter();

  onFilterClick(filter: IFilterOptions) {
    this.externalFilterClick.emit(filter);

    this.cardContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
