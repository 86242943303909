import { Action, createReducer, on } from '@ngrx/store';
import * as ParametrosActions from './parametros.actions';
import { IGenericState } from '@vip/state/utils';
import { IParametros } from '@vip/core';

export const PARAMETROS_FEATURE_KEY = 'parametros';

//eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ParametrosState extends IGenericState<IParametros | null> {
  tentativasRealizadas: number | null;
}

export const initialState: ParametrosState = {
  data: null,
  status: 'pending',
  error: null,
  tentativasRealizadas: null,
};

const parametrosReducer = createReducer(
  initialState,
  on(ParametrosActions.loadParametrosSuccess, (state, { parametros }) => ({
    data: parametros,
    status: 'success',
    error: null,
    tentativasRealizadas: state.tentativasRealizadas,
  })),
  on(ParametrosActions.setTentativasRealizadas, (state, { tentativas }) => ({
    ...state,
    tentativasRealizadas: tentativas,
  }))
);

export function reducer(state: ParametrosState, action: Action) {
  return parametrosReducer(state, action);
}
