import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { configuracaoOmnichannelSelectors } from './configuracao-omnichannel.selectors';
import * as ConfiguracaoOmnichannelActions from './configuracao-omnichannel.actions';
import { TipoDispositivoEnum } from '@vip/core';

@Injectable()
export class ConfiguracaoOmnichannelFacade {
  configuracoes$ = this.store.pipe(
    select(configuracaoOmnichannelSelectors.getData())
  );
  loading$ = this.store.pipe(
    select(configuracaoOmnichannelSelectors.isLoading())
  );
  error$ = this.store.pipe(select(configuracaoOmnichannelSelectors.getError()));

  constructor(private readonly store: Store) {}

  loadConfiguracaoOmnichannel(dispositivo: TipoDispositivoEnum) {
    this.store.dispatch(
      ConfiguracaoOmnichannelActions.loadConfiguracaoOmnichannel({
        dispositivo,
      })
    );
  }
}
