import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EstadoOptions, LayoutUtilsService, MaskPatterns } from '@vip/core';

@Component({
  selector: 'vip-form-endereco',
  templateUrl: './form-endereco.component.html',
  styleUrls: ['./form-endereco.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FormEnderecoComponent implements AfterViewInit {
  @Input() form!: UntypedFormGroup;
  @Input() novoUsuario = false;
  @Input() naoPossuiEndereco = false;
  @Input() novoEndereco = false;
  @Input() possuiEnderecoPrincipalCadastrado = false;
  @Input() isEnderecoPrincipal = false;
  @Input() isModalCompletarCadastro = false;

  maskCep = MaskPatterns.CEP;

  estadoOptions = EstadoOptions;

  validations = {
    default: {
      required: 'Campo obrigatório',
    },
    cep: {
      mask: 'CEP inválido',
      required: 'Campo obrigatório',
      naoAtendido: 'O CEP informado não é atendido',
    },
    numero: {
      required: 'Campo obrigatório',
      pattern: 'Este campo deve possuir somente números',
    },
  };

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public layoutUtilsService: LayoutUtilsService
  ) {}

  ngAfterViewInit() {
    if (this.novoEndereco) {
      this.form.controls.principal.setValue(
        !this.possuiEnderecoPrincipalCadastrado
      );
      if (!this.possuiEnderecoPrincipalCadastrado) {
        this.form.controls.principal.disable();
      }
    } else {
      if (this.isEnderecoPrincipal) {
        this.form.controls.principal.disable();
      }
    }
  }

  get isDesktop(): boolean {
    return this.layoutUtilsService.isDesktop();
  }

  submit() {
    this.form.markAllAsTouched();
    this.changeDetectorRef.markForCheck();
  }
}
