import { Inject, Injectable } from '@angular/core';
import { DialogService, IDialog } from '@vip/ui/modal';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import {
  BarcodeScanner,
  SupportedFormat,
} from '@capacitor-community/barcode-scanner';
import { DOCUMENT } from '@angular/common';

@UntilDestroy()
@Injectable()
export class CodeScannerService {
  barcodeScanner = BarcodeScanner;

  constructor(
    private dialogService: DialogService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  async checkPermission() {
    const status = await this.barcodeScanner.checkPermission({ force: true });
    if (status.granted) {
      return true;
    } else {
      const dataDialog: IDialog = {
        open: true,
        title: 'Permissão necessária',
        subTitle: `Precisamos da sua permissão para usar a câmera para escanear códigos`,
        disabled: false,
        showCloseButton: true,
        buttonConfirmText: 'Configurações',
      };

      this.dialogService.openDialog(dataDialog);

      this.dialogService.dialogClick
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.dialogService.clearDialog();
          this.barcodeScanner.openAppSettings();
        });
      return false;
    }
  }

  async prepareScan() {
    const granted = await this.checkPermission();
    if (granted) {
      this.barcodeScanner.hideBackground();
      this.document.querySelector('body')?.classList.add('scanner-active');
    }
  }

  async scanBarcode() {
    await this.prepareScan();
    const barCode = await this.barcodeScanner.startScan({
      targetedFormats: [
        SupportedFormat.EAN_8,
        SupportedFormat.EAN_13,
        SupportedFormat.CODE_39,
        SupportedFormat.CODE_93,
        SupportedFormat.CODE_128,
      ],
    });
    this.exitScan();
    return barCode.content;
  }

  async scanQRCode() {
    await this.prepareScan();
    const qrCode = await this.barcodeScanner.startScan({
      targetedFormats: [SupportedFormat.QR_CODE],
    });
    this.exitScan();
    return qrCode.content;
  }
  exitScan() {
    this.document.querySelector('body')?.classList.remove('scanner-active');
    this.barcodeScanner.showBackground();
    this.barcodeScanner.stopScan();
  }

  isScanning() {
    return this.document
      .querySelector('body')
      ?.classList.contains('scanner-active');
  }
}
