import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'vip-modal-cvv',
  templateUrl: './modal-cvv.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCvvComponent {
  cvvControl = new UntypedFormControl('', [Validators.required]);

  @Output()
  cvvChange = new EventEmitter<string>();

  confirm(): void {
    if (this.cvvControl.invalid) {
      this.cvvControl.markAsTouched();
      return;
    }
    this.cvvChange.emit(this.cvvControl.value ?? '');
  }
}
