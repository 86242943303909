import { Inject, Injectable } from '@angular/core';
import {
  STORAGE,
  IStorage,
  LayoutUtilsService,
  DEFAULT_COOKIE_EXPIRATION_TIME,
  CODIGO_COMPARTILHAMENTO_LISTA,
} from '@vip/core';

@Injectable({
  providedIn: 'root',
})
export class CodigoCompartilhamentoService {
  private codigoCache: string | null = null;
  readonly defaultExpirationTime = DEFAULT_COOKIE_EXPIRATION_TIME;
  private isSite = this.layoutUtilsService.isDesktopWithoutScreenWidth();

  constructor(
    @Inject(STORAGE) readonly storage: IStorage,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  getCodigo(): string | null {
    if (this.codigoCache) {
      return this.codigoCache;
    }
    this.codigoCache =
      this.storage.getItem(CODIGO_COMPARTILHAMENTO_LISTA) || '';
    return this.codigoCache;
  }

  setCodigo(value: string) {
    if (this.isSite) {
      this.storage.setItem(
        CODIGO_COMPARTILHAMENTO_LISTA,
        value,
        this.defaultExpirationTime,
        '/'
      );
      return;
    }
    this.storage.setItem(CODIGO_COMPARTILHAMENTO_LISTA, value);
  }

  clearCodigo() {
    this.codigoCache = null;
    if (this.isSite) {
      this.storage.removeItem(CODIGO_COMPARTILHAMENTO_LISTA, '/');
      return;
    }
    this.storage.removeItem(CODIGO_COMPARTILHAMENTO_LISTA);
  }
}
