import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { LayoutUtilsService, IClassificacaoMercadologica } from '@vip/core';
import SwiperCore, { Navigation, SwiperOptions } from 'swiper';

SwiperCore.use([Navigation]);

@Component({
  selector: 'vip-carousel-category',
  templateUrl: './carousel-category.component.html',
  styleUrls: ['./carousel-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CarouselCategoryComponent implements AfterViewInit {
  @Input() isLoading = false;
  @Input() categoriaItens: IClassificacaoMercadologica[] = [];

  @Output()
  categoriaItemClick = new EventEmitter<IClassificacaoMercadologica>();

  swiperConfig: SwiperOptions = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: 10,
    allowTouchMove: false,
    slidesOffsetAfter: 15,
    slidesOffsetBefore: 15,
  };
  isMobile!: boolean;

  private getScreenWidth: any;

  constructor(private layoutUtilsService: LayoutUtilsService) {
    this.isMobile = this.layoutUtilsService.isMobile();
  }

  ngAfterViewInit(): void {
    this.getScreenWidth = window.innerWidth;
    this.isMobile = this.swiperOn();
  }

  trackByFn(_: number, item: IClassificacaoMercadologica) {
    return item.parent_id + '-' + item.total_ofertas;
  }

  swiperOn() {
    if (!this.layoutUtilsService.isMobile()) {
      return this.getScreenWidth < 800 ? true : false;
    }
    return true;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    this.getScreenWidth = event.target.innerWidth;
    this.isMobile = this.swiperOn();
  }
}
