import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IFormaPagamento, IPagamentosViewModel } from '@vip/core';
import { filter } from 'rxjs/operators';
import * as FormaPagamentosActions from './forma-pagamento.actions';
import { formaPagamentosSelectors } from './forma-pagamento.selectors';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class FormaPagamentosFacade {
  error$ = this.store.pipe(select(formaPagamentosSelectors.getError()));
  formaPagamentos$ = this.store.pipe(
    select(formaPagamentosSelectors.getFormaPagamentos())
  );
  removerPrePagos$ = this.store.pipe(
    select(formaPagamentosSelectors.getRemoverPrePagos())
  );
  formaPagamentosSelecionadas$ = this.store.pipe(
    select(formaPagamentosSelectors.getFormaPagamentoSelecionada()),
    filter(
      (formaPagamento): formaPagamento is IFormaPagamento =>
        formaPagamento !== undefined
    )
  );
  valorTroco$ = this.store.pipe(
    select(formaPagamentosSelectors.getValorTroco())
  );
  permiteSelecaoManual$ = this.store.pipe(
    select(formaPagamentosSelectors.getPermiteSelecaoManual())
  );
  bandeiraNaoAtendida$ = this.store.pipe(
    select(formaPagamentosSelectors.getBandeiraNaoAtendida())
  );
  bandeiraNaoAtendidaDispatched$ = this.actions$.pipe(
    ofType(FormaPagamentosActions.buscarBandeiraCartaoFailure)
  );
  buscarBandeiraDispatched$ = this.actions$.pipe(
    ofType(FormaPagamentosActions.buscarBandeiraCartaoSuccess)
  );
  pagamentosOnline$ = this.store.pipe(
    select(formaPagamentosSelectors.getPagamentosOnline())
  );
  pagamentosEntrega$ = this.store.pipe(
    select(formaPagamentosSelectors.getPagamentosEntrega())
  );
  pagamentos$ = this.store.pipe(
    select(formaPagamentosSelectors.getPagamentos())
  );
  isLoaded$ = this.store.pipe(select(formaPagamentosSelectors.getIsLoaded()));
  readonly emptyPagamentosViewModel: IPagamentosViewModel = {
    entrega: {
      cartao_da_loja: [],
      carteira_digital: [],
      credito: [],
      debito: [],
      voucher: [],
      todos_pagamentos: [],
    },
    online: {
      cartao_da_loja: [],
      carteira_digital: [],
      credito: [],
      debito: [],
      voucher: [],
      todos_pagamentos: [],
    },
  };

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions
  ) {}

  getFormaPagamentos() {
    this.store.dispatch(FormaPagamentosActions.getFormaPagamentos());
  }

  selectFormaPagamento(formaPagamentoId: number) {
    this.store.dispatch(
      FormaPagamentosActions.selectFormaPagamento({ formaPagamentoId })
    );
  }

  resetFormaPagamentoSelecionada() {
    this.store.dispatch(
      FormaPagamentosActions.resetFormaPagamentoSelecionada()
    );
  }

  setValorTroco(valorTroco: number) {
    this.store.dispatch(FormaPagamentosActions.setValorTroco({ valorTroco }));
  }

  buscarBandeiraCartao(
    cardNumber: string,
    tipoCartao: 'credito' | 'voucher',
    makeRequestAgain?: boolean,
    dontChangeStateStatus?: boolean
  ) {
    this.store.dispatch(
      FormaPagamentosActions.buscarBandeiraCartao({
        cardNumber,
        tipoCartao,
        makeRequestAgain,
        dontChangeStateStatus,
      })
    );
  }

  resetBandeiraNaoAtendidaCartao() {
    this.store.dispatch(
      FormaPagamentosActions.resetBandeiraNaoAtendidaCartao()
    );
  }

  resetPermiteSelecaoManual(): void {
    this.store.dispatch(FormaPagamentosActions.resetPermiteSelecaoManual());
  }

  cancelarRequestBuscarBandeiraCartao(): void {
    this.store.dispatch(FormaPagamentosActions.cancelarRequestBuscarBandeira());
  }
}
