import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ReceitasService } from '@vip/api';
import { FilialFacade } from '@vip/state/filial';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';

import * as IngredientesReceitaActions from './ingredientes-receita.actions';
import { ingredientesReceitaSelectors } from './ingredientes-receita.selectors';
import { BucketsFacade, BucketsService } from '@vip/state/buckets';
import { ProdutoFacade } from '@vip/state/produto';

@Injectable({
  providedIn: 'root',
})
export class IngredientesReceitaFacade {
  error$ = this.store.pipe(select(ingredientesReceitaSelectors.getError()));
  itensAtualizadosENaoAtualizados$ = this.store.pipe(
    select(ingredientesReceitaSelectors.getItemsAtualizadosENaoAtualizados())
  );

  constructor(
    private store: Store,
    private receitasService: ReceitasService,
    private filialFacade: FilialFacade,
    private cdFacade: CentroDistribuicaoFacade,
    private bucketsFacade: BucketsFacade,
    private bucketsService: BucketsService,
    private produtoFacade: ProdutoFacade
  ) {}

  getIngredientes(listaId: number) {
    return combineLatest([
      this.filialFacade.filial$,
      this.cdFacade.cd$,
      this.bucketsFacade.bucketProduto$,
    ]).pipe(
      switchMap(([filial, cd, bucket]) => {
        return this.receitasService
          .getIngredientes(filial.id, cd.id, listaId)
          .pipe(
            map((res) => {
              this.store.dispatch(
                IngredientesReceitaActions.enviaImpressao({ res })
              );
              return res.data.map((item) => {
                const produto = this.bucketsService.addBucketProduto(
                  item.produto,
                  bucket
                );
                return {
                  ...produto,
                  quantidade: item.quantidade,
                };
              });
            })
          );
      })
    );
  }

  comprarIngredientes(listaId: number) {
    this.store.dispatch(
      IngredientesReceitaActions.addIngredientesCarrinho({ listaId })
    );
  }

  resetIngredientes() {
    this.store.dispatch(IngredientesReceitaActions.resetIngredientes());
  }
}
