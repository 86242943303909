export enum TipoVisualizacaoOmnichannelEnum {
  CASHBACK = 1,
  QUADRADO = 2,
  VERTICAL = 3,
  HORIZONTAL_PEQUENO = 4,
  HORIZONTAL_GRANDE = 5,
  GRID = 6,
  PREENCHIMENTO_COMPLETO = 7,
  VITRINE = 8,
}
