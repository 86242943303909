import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CardDetalhesCobrancaModule } from '@vip/ui/card-detalhes-cobranca';
import { StepperDesktopModule } from '@vip/ui/stepper-desktop';
import { TabsEntregaRetiradaModule } from '@vip/ui/tabs-entrega-retirada';
import { EntregaRetiradaDesktopComponent } from './entrega-retirada-desktop/entrega-retirada-desktop.component';

@NgModule({
  imports: [
    CommonModule,
    StepperDesktopModule,
    TabsEntregaRetiradaModule,
    RouterModule,
    CardDetalhesCobrancaModule,
  ],
  declarations: [EntregaRetiradaDesktopComponent],
  exports: [EntregaRetiradaDesktopComponent],
})
export class EntregaRetiradaDesktopModule {}
