import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { AtualizarCarrinhoItemRequest } from '@vip/api';
import { ICarrinho, IProdutoCarrinho } from '@vip/core';

export const getCarrinhoFailure = createAction(
  '[Carrinho/API] Busca Carrinho de cliente Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getItensCarrinho = createAction(
  '[Carrinho/Page] Busca itens de carrinho de cliente'
);

export const getItensCarrinhoSuccess = createAction(
  '[Carrinho/API] Busca itens de carrinho de clienteSuccess',
  props<{ itens: IProdutoCarrinho[] }>()
);

export const getItensCarrinhoFailure = createAction(
  '[Carrinho/API] Busca itens de carrinho de cliente Failure',
  props<{ error: HttpErrorResponse }>()
);

export const setItemCarrinho = createAction(
  '[Carrinho/Page] atualiza item de carrinho de cliente',
  props<{
    payload: AtualizarCarrinhoItemRequest;
    exibirMensagemAdicionado: boolean;
  }>()
);

export const setItemCarrinhoSuccess = createAction(
  '[Carrinho/API] atualiza item de carrinho de clienteSuccess',
  props<{ itens: IProdutoCarrinho[] }>()
);

export const setItemCarrinhoFailure = createAction(
  '[Carrinho/API] atualiza item de carrinho de cliente Failure',
  props<{
    payload: AtualizarCarrinhoItemRequest;
    error: HttpErrorResponse;
  }>()
);

export const setListaItensCarrinho = createAction(
  '[Carrinho/Page] atualiza vários itens de carrinho de cliente',
  props<{
    payload: AtualizarCarrinhoItemRequest[];
    exibirMensagemAdicionado?: boolean;
    buscaCarrinhoAposConclusao?: boolean;
  }>()
);

export const setListaItensCarrinhoSuccess = createAction(
  '[Carrinho/API] atualiza vários itens de carrinho de clienteSuccess',
  props<{
    carrinho: ICarrinho;
    itens: IProdutoCarrinho[];
    buscaCarrinhoAposConclusao?: boolean;
  }>()
);

export const setListaItensCarrinhoFailure = createAction(
  '[Carrinho/API] atualiza vários itens de carrinho de cliente Failure',
  props<{ error: HttpErrorResponse }>()
);

export const removeItemCarrinho = createAction(
  '[Carrinho/Page] remove item de carrinho de cliente',
  props<{ itemId: number }>()
);

export const removeItemCarrinhoSuccess = createAction(
  '[Carrinho/API] remove item de carrinho success',
  props<{ itemId: number; itens: IProdutoCarrinho[] }>()
);

export const removeItemCarrinhoComItenAtualizados = createAction(
  '[Carrinho/API] remove item de carrinho com iten atualizados',
  props<{ itens: IProdutoCarrinho[] }>()
);

export const removeItemCarrinhoFailure = createAction(
  '[Carrinho/API] remove item de carrinho de cliente Failure',
  props<{ error: HttpErrorResponse }>()
);

export const resetItensCarrinho = createAction(
  '[Carrinho/API] resetItensCarrinho'
);

export const usuarioNaoAutorizado = createAction(
  '[Carrinho/Page] Usuario nao autorizado'
);

export const setItens = createAction(
  '[Carrinho] Seta itens ao store',
  props<{ itens: IProdutoCarrinho[] }>()
);
