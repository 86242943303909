import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { LayoutUtilsService } from '@vip/core';

@Directive()
export class ModalDirective {
  isMobile: boolean;
  isTablet: boolean;

  constructor(
    private _elementRef: ElementRef,
    private layoutUtilsService: LayoutUtilsService
  ) {
    this.isMobile = this.layoutUtilsService.isMobileUserAgent();
    this.isTablet = this.layoutUtilsService.isTablet();
  }

  @Input() disabled = false;

  private _bottom = false;

  @Input() set bottom(value: boolean | undefined) {
    this._bottom = !!value && this.isMobile;
  }

  get bottom() {
    return this._bottom;
  }

  @Output()
  closeClick = new EventEmitter();

  @Output()
  afterClose = new EventEmitter();

  @Input() open = false;

  @Input() showCloseButton = true;

  @Input() fullscreen = false;

  @Input() large = false;

  @Input() ultraLarge = false;

  @Input() paddingZoom = false;

  @Input() hasPrazoEntrega = false;

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement) {
    const vipModalContent =
      this._elementRef.nativeElement.querySelector('.vip-modal-content');
    if (vipModalContent == targetElement.firstChild) {
      this.handleClick();
    }
  }

  public handleClick() {
    if (!this.disabled) {
      this.open = false;
      this.closeClick.emit();
      this.ultraLarge = false;
      this.paddingZoom = false;
      this.hasPrazoEntrega = false;
    }
  }

  onAnimationDone() {
    if (!this.open) {
      this.afterClose.emit();
    }
  }
}
