import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';

@Directive({ selector: '[vipAutofocus]' })
export class FocusDirective implements AfterViewInit, OnDestroy {
  @Input('vipAutofocus') active = true;

  private oldFocus: HTMLElement | null = null;

  constructor(
    private host: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.changeDetector.detectChanges();
    this.oldFocus = document.activeElement as HTMLElement;
    if (this.active) this.host.nativeElement.focus();
  }

  ngOnDestroy(): void {
    if (this.oldFocus) this.oldFocus.focus();
  }
}
