import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { CepFacade, CepStateData } from '@vip/state/cep';
import { of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ParametrosFacade } from '@vip/state/parametros';

@UntilDestroy()
@Component({
  selector: 'vip-validar-cep-container',
  templateUrl: './validar-cep-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidarCepContainerComponent implements OnInit {
  @Output() validateSuccess: EventEmitter<boolean> = new EventEmitter();
  @Output() solicitacaoEnviadaSuccess = new EventEmitter();

  loading$ = this.cepFacade.loading$;
  cep$ = this.cepFacade.cep$;
  solicitacaoDeCepEnviada$ = this.cepFacade.solicitacaoDeCepEnviada$;
  error$ = this.cepFacade.error$;
  currentView:
    | 'solicitarCep'
    | 'cepNaoAtendido'
    | 'apenasRetirada'
    | 'solicitacaoCadastrada'
    | '' = 'solicitarCep';
  selecaoCdObrigatoria$ = this.parametrosFacade.selecaoCdObrigatorio$;
  selecaoCdObrigatoria = false;
  cep = '';
  private error: string | null | undefined = '';
  private cepStateData: CepStateData | null = null;

  constructor(
    private cepFacade: CepFacade,
    private parametrosFacade: ParametrosFacade
  ) {}

  ngOnInit(): void {
    this.cep$
      .pipe(untilDestroyed(this))
      .subscribe((value) => (this.cep = value));

    this.selecaoCdObrigatoria$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.selecaoCdObrigatoria = value;
    });

    this.cepFacade.isLoaded$
      .pipe(
        switchMap((isLoaded) => {
          if (isLoaded) {
            return this.cepFacade.getData$;
          }
          return of(null);
        }),
        tap((data) => {
          this.cepStateData = data;
        }),
        switchMap((data) => {
          if (data) {
            return this.cepFacade.error$;
          }
          return of(null);
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe((error) => {
        this.error = error;
        this.validarCepAtendido(this.cepStateData);
      });
  }

  private validarCepAtendido(cepState: CepStateData | null) {
    if (cepState) {
      if (
        !cepState.cepAtendido &&
        !this.error?.includes('Solicitação já cadastrada para este CEP.')
      ) {
        this.currentView = 'cepNaoAtendido';
      } else if (cepState.apenasRetirada) {
        this.currentView = 'apenasRetirada';
      } else if (
        this.error?.includes('Solicitação já cadastrada para este CEP.')
      ) {
        this.currentView = 'solicitacaoCadastrada';
      } else {
        this.validateSuccess.emit(true);
      }
    }
  }

  onConfirmSolicitarCep(cep: string) {
    this.cepFacade.validarCepAtendidoPelaFilial(cep);

    this.solicitacaoDeCepEnviada$
      .pipe(
        filter((solicitacaoDeCepEnviada) => solicitacaoDeCepEnviada === true),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.cepFacade.cepReset();
        this.solicitacaoEnviadaSuccess.emit();
      });
  }

  onConfirmCepNaoAtendido(email: string) {
    this.cepFacade.addSolicitacaoCep(this.cep, email);
  }

  onConfirmSolicitacaoCadastrada() {
    this.cepFacade.cepReset();
    if (this.selecaoCdObrigatoria) {
      this.currentView = 'solicitarCep';
    } else {
      this.solicitacaoEnviadaSuccess.emit();
    }
  }

  onConfirmSomenteRetirada() {
    this.validateSuccess.emit(false);
  }
}
