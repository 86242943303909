import { createSelector } from '@ngrx/store';
import { SelectorUtil } from '@vip/state/utils';
import {
  CLASSIFICACAO_MERCADOLOGICA_FEATURE_KEY,
  ClassificacaoMercadologicaState,
} from './classificacao-mercadologica.reducer';

export class ClassificacaoMercadologicaSelectors extends SelectorUtil<ClassificacaoMercadologicaState> {
  constructor() {
    super(CLASSIFICACAO_MERCADOLOGICA_FEATURE_KEY);
  }

  loadArvore = () =>
    createSelector(
      this.createFeatureSelector,
      (state: ClassificacaoMercadologicaState) => state.data.arvore
    );
}

export const classificacaoMercadologicaSelectors =
  new ClassificacaoMercadologicaSelectors();
