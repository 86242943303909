import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilialFacade } from '../+state/filial.facade';
import { IFilial } from '@vip/core';

@Injectable()
export class FilialSemEcommerceGuard implements CanActivate {
  constructor(private router: Router, private filialFacade: FilialFacade) {}

  canActivate(): Observable<boolean> {
    return this.filialFacade.filial$.pipe(
      map((filial: IFilial) => {
        if (filial?.filial_sem_ecommerce) {
          this.router.navigateByUrl('/home');
          return false;
        }
        return true;
      })
    );
  }
}
