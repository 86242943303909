import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { ERRO_PARCEIRO_CASHBACK_INVALIDO } from '@vip/core';

@Directive({
  selector: '[vipCashbackHidden]',
})
export class CashbackHiddenDirective {
  @Input() set vipCashbackHidden(input: {
    errorMessage: string | null;
    keyIsCashback: boolean;
    cashbackIsLoaded: boolean | null;
    exibeCashback: boolean;
  }) {
    if (input.keyIsCashback && !input.cashbackIsLoaded) {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    } else if (input.keyIsCashback && input.cashbackIsLoaded) {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
    }
    if (
      input.keyIsCashback &&
      input.errorMessage == ERRO_PARCEIRO_CASHBACK_INVALIDO
    ) {
      this.renderer.removeChild(
        this.el.nativeElement.parentNode,
        this.el.nativeElement
      );
    }
    if (input.keyIsCashback && input.exibeCashback) {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
    } else if (input.keyIsCashback && !input.exibeCashback) {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    }
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}
}
