import { Component, Input } from '@angular/core';
import { IProdutoCarrinho, IProdutoCompra } from '@vip/core';
import { ImageSizesEnum } from '@vip/ui/image';

@Component({
  selector: 'vip-card-resumo-produtos-desktop',
  templateUrl: './card-resumo-produtos-desktop.component.html',
  styleUrls: ['./card-resumo-produtos-desktop.component.scss'],
})
export class CardResumoProdutosDesktopComponent {
  @Input() produtos: IProdutoCompra[] = [];
  imageSizesEnum = ImageSizesEnum;
}
