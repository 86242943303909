import { SexoEnum, TipoClienteEnum, TipoDispositivoEnum } from '../enums';
import { ICliente, ConsultaSolicitacaoSMS } from '../interfaces';
import { addEnderecoRequestMock } from './endereco.mock';

export const cliente1Mock = {
  id: 1,
  cpf: '10990023366',
  email: 'teste@teste.com',
} as ICliente;

export const cliente2Mock = {
  id: 2,
  cpf: '10990023366',
  email: 'teste@teste.com',
  chave_acesso: '123456',
} as ICliente;

export const cliente3Mock = {
  id: 1,
  cpf: '64382763673',
  email: 'goldenberg88@tst.com',
  chave_acesso: '123456',
} as ICliente;

export const clientePessoaFisicaMock = {
  id: 1,
  cpf: '64382763673',
  cpf_formatado: '643.827.636-73',
  email: 'goldenberg88@tst.com',
  data_nascimento: new Date(1947, 10, 12),
  data_nascimento_formatada: '12/10/1947',
  nome_completo: 'Jaqueline Pereira dos Santos Silva',
  nome: 'Jaqueline',
  sobrenome: 'Pereira dos Santos Silva',
  telefone_celular: '02074995044',
  telefone_celular_formatado: '(31) 98499-5044',
  telefone_fixo: '0052196238',
  telefone_fixo_formatado: '(31) 3219-6238',
  tipo_cliente: TipoClienteEnum.PESSOA_FISICA,
  sexo: SexoEnum.FEMININO,
  primeira_compra: true,
} as ICliente;

export const clientePessoaJuridicaMock = {
  id: 1,
  cpf: '64382763673',
  email: 'goldenberg88@tst.com',
  data_nascimento: new Date(1947, 10, 12),
  data_nascimento_formatada: '12/10/1947',
  nome_completo: 'Jaqueline Pereira dos Santos Silva',
  telefone_celular: '02074995044',
  telefone_fixo: '0052196238',
  tipo_cliente: TipoClienteEnum.PESSOA_JURIDICA,
  razao_social: 'Vipcommerce Sistemas LTDA',
  nome_fantasia: 'Vipcommerce',
  cnpj: '22209371000110',
  cnpj_formatado: '22.209.371/0001-10',
  inscricao_estadual: 'Isento',
  sexo: SexoEnum.FEMININO,
} as ICliente;

export const clientePessoaJuridicaMock2 = {
  id: 1,
  cpf: '64382763673',
  email: 'goldenberg88@tst.com',
  data_nascimento: new Date(1947, 10, 12),
  nome_completo: 'Jaqueline Pereira dos Santos Silva',
  nome: 'Jaqueline',
  sobrenome: 'Pereira dos Santos Silva',
  telefone_celular: '02074995044',
  telefone_fixo: '0052196238',
  tipo_cliente: TipoClienteEnum.PESSOA_JURIDICA,
  razao_social: 'Vipcommerce Sistemas LTDA',
  nome_fantasia: 'Vipcommerce',
  cnpj: '15742989000186',
  inscricao_estadual: '22209371000110',
  comunicacao_email: true,
  comunicacao_sms: false,
} as ICliente;

export const clientePessoaFisicaMock2 = {
  id: 1,
  cpf: '64382763673',
  email: 'goldenberg88@tst.com',
  data_nascimento: new Date(1947, 10, 12),
  nome_completo: 'Jaqueline Pereira dos Santos Silva',
  nome: 'Jaqueline',
  sobrenome: 'Pereira dos Santos Silva',
  telefone_celular: '02074995044',
  telefone_fixo: '0052196238',
  tipo_cliente: TipoClienteEnum.PESSOA_FISICA,
  comunicacao_email: true,
  comunicacao_sms: false,
} as ICliente;

export const clienteToEditMock = {
  id: 1,
  comunicacao_email: true,
  comunicacao_sms: false,
  nome: 'Jaqueline',
  sobrenome: 'Pereira dos Santos Silva',
  email: 'goldenberg88@tst.com',
  password: 'password',
  nome_fantasia: 'Vipcommerce',
  razao_social: 'Vipcommerce Sistemas LTDA',
  telefone_celular: '02074995044',
  telefone_fixo: '0052196238',
  sexo: SexoEnum.FEMININO,
};

export const clienteToAddMock = {
  cpf: '64382763673',
  email: 'goldenberg88@tst.com',
  email_confirm: 'goldenberg88@tst.com',
  data_nascimento: '12/10/1947',
  nome: 'Jaqueline',
  sobrenome: 'Pereira dos Santos Silva',
  password: 'password',
  password_confirm: 'password',
  telefone_celular: '22974995044',
  telefone_fixo: '2274995044',
  tipo_cliente: TipoClienteEnum.PESSOA_FISICA,
  endereco: addEnderecoRequestMock,
  codigo_indicacao: 'indicacao',
  termos_aceite: true,
  sexo: SexoEnum.FEMININO,
  cnpj: '22209371000110',
  tipo_dispositivo: TipoDispositivoEnum.APLICATIVO,
  aplicativo_em_revisao: false,
  captchaToken: '',
};

export const clienteCashbackMock1 = {
  id: 1,
  cpf: '64382763673',
  cpf_formatado: '643.827.636-73',
  email: 'goldenberg88@tst.com',
  data_nascimento: new Date(1947, 10, 12),
  data_nascimento_formatada: '12/10/1947',
  nome_completo: 'Jaqueline Pereira dos Santos Silva',
  nome: 'Jaqueline',
  sobrenome: 'Pereira dos Santos Silva',
  telefone_celular: '02074995044',
  telefone_fixo: '0052196238',
  tipo_cliente: TipoClienteEnum.PESSOA_FISICA,
  sexo: SexoEnum.FEMININO,
  informacoes_cashback: { izio: { cod_pessoa: 18649 } } as {
    [key: string]: unknown;
  },
} as ICliente;

export const solicitacaoSMSMock: ConsultaSolicitacaoSMS = {
  expire_at: '2023-09-20T14:51:04.536Z',
  telefone_celular: '31981111111',
};
