import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { IFilial, ITipoEntrega } from '@vip/core';
import { FilialFacade } from '@vip/state/filial';
import { MessageService } from '@vip/ui/message';
import { DescontoWsService, GetCompraDescontos } from '@vip/ws';
import { combineLatest } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import * as DescontoActions from './desconto.actions';
import { DescontoFacade } from './desconto.facade';
import { FormaPagamentosFacade } from '@vip/state/forma-pagamento';
import { select, Store } from '@ngrx/store';
import { descontoSelectors } from './desconto.selectors';
import { TipoEntregaFacade } from '@vip/state/tipo-entrega';

@Injectable()
export class DescontoEffects {
  getDescontos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DescontoActions.getDescontos),
      (action) => combineLatest([action, this.filialFacade.filial$]),
      fetch({
        run: (action, filial: IFilial) =>
          this.descontoWsService
            .getDescontos({ filialId: filial.id })
            .pipe(
              map(({ descontos }) =>
                DescontoActions.getDescontosSuccess(
                  descontos.descontos
                    ? { descontos: descontos.descontos }
                    : { descontos: [] }
                )
              )
            ),

        onError: (_, error) => {
          return DescontoActions.getDescontosSuccessFailure({ error });
        },
      })
    )
  );

  validarCodigo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DescontoActions.validarCodigo),
      withLatestFrom(
        this.filialFacade.filial$,
        this.descontoFacade.codigoPromocional$,
        this.tipoEntregaFacade.tipoEntregaSelecionado$
      ),
      map(([action, filial, codigoPromocional, tipoEntregaSelecionado]) => ({
        ...action,
        filial,
        codigoPromocional,
        tipoEntregaSelecionado,
      })),
      fetch({
        run: (action) =>
          this.descontoWsService
            .getDescontos({ filialId: action.filial.id, codigo: action.codigo })
            .pipe(
              map(({ descontos, descontosFrete }) => {
                if (descontos.descontos.length) {
                  return this.validarDescontoPorCodigo(
                    descontos,
                    action.codigo
                  );
                } else if (
                  descontosFrete.descontos.length &&
                  action.tipoEntregaSelecionado
                ) {
                  return this.validarDescontoDeFretePorCodigo(
                    descontosFrete,
                    action.codigo,
                    action.tipoEntregaSelecionado
                  );
                }

                this.exibirMensagemCupomInvalido();
                return DescontoActions.validarCodigoSuccessValido({
                  codigo: null,
                  descontosValidados: [],
                });
              })
            ),

        onError: (_, error) => {
          return DescontoActions.validarCodigoFailure({
            error,
          });
        },
      })
    )
  );

  getDescontosCompra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DescontoActions.getDescontosCompra),
      withLatestFrom(
        this.filialFacade.filial$,
        this.formaPagamentosFacade.formaPagamentosSelecionadas$,
        this.store.pipe(select(descontoSelectors.getCodigoPromocional()))
      ),
      map(([action, filial, formaPagamentoSelecionada, codigoPromocional]) => ({
        ...action,
        filial,
        formaPagamentoSelecionada,
        codigoPromocional,
      })),
      fetch({
        run: (action) => {
          return this.descontoWsService
            .getDescontos({
              filialId: action.filial.id,
              compraId: action.compraId || 0,
              codigo: action.codigoPromocional.codigo
                ? action.codigoPromocional.codigo
                : '',
              formaPagamentoId: action.formaPagamentoSelecionada
                ? action.formaPagamentoSelecionada.id
                : 0,
            })
            .pipe(
              map(({ descontos }) => {
                return DescontoActions.getDescontosCompraSuccess(
                  descontos.descontos
                    ? { descontos: descontos.descontos }
                    : { descontos: [] }
                );
              })
            );
        },
        onError: (action, error) =>
          DescontoActions.getDescontosCompraFailure({ error }),
      })
    )
  );

  validarDescontoPorCodigo(
    descontos: GetCompraDescontos['descontos'],
    codigoInserido: string
  ) {
    const descontosValidados = descontos.descontos.filter((desconto) => {
      return desconto.codigo?.toUpperCase() == codigoInserido.toUpperCase();
    });

    if (descontosValidados.length > 0) {
      this.messageService.openSuccessMessage(
        'Código promocional ativado!',
        1.5
      );
      return DescontoActions.validarCodigoSuccessValido({
        codigo: codigoInserido,
        descontosValidados,
      });
    }

    this.exibirMensagemCupomInvalido();

    return DescontoActions.validarCodigoSuccessInvalido({
      codigo: null,
      descontosValidados: [],
    });
  }

  validarDescontoDeFretePorCodigo(
    descontos: GetCompraDescontos['frete_descontos'],
    codigoInserido: string,
    tipoEntregaSelecionado: ITipoEntrega
  ) {
    const descontosValidados = descontos.descontos.filter((desconto) => {
      return desconto.codigo?.toUpperCase() == codigoInserido.toUpperCase();
    });

    if (descontosValidados.length > 0) {
      const possuiFreteGratis =
        tipoEntregaSelecionado.frete - tipoEntregaSelecionado.desconto <= 0;
      if (possuiFreteGratis) {
        this.messageService.openInfoMessage(
          'Infelizmente o desconto de frete não pôde ser aplicado, uma vez que o frete já é gratuito',
          2.5
        );
        return DescontoActions.validarCodigoSuccessInvalido({
          codigo: null,
          descontosValidados: [],
        });
      }
      this.messageService.openSuccessMessage(
        'Desconto de frete foi aplicado com sucesso',
        1.5
      );
      return DescontoActions.validarCodigoFreteSuccessValido({
        codigo: codigoInserido,
        descontosValidados: descontosValidados,
        descontoFrete: descontos.total,
      });
    }
    this.exibirMensagemCupomInvalido();

    return DescontoActions.validarCodigoSuccessInvalido({
      codigo: null,
      descontosValidados: [],
    });
  }

  exibirMensagemCupomInvalido() {
    this.messageService.openErrorMessage(
      'Código inexistente, fora de validade ou valor da compra insuficiente.',
      1.5
    );
  }

  constructor(
    private actions$: Actions,
    private filialFacade: FilialFacade,
    private descontoWsService: DescontoWsService,
    private messageService: MessageService,
    private descontoFacade: DescontoFacade,
    private formaPagamentosFacade: FormaPagamentosFacade,
    private tipoEntregaFacade: TipoEntregaFacade,
    private store: Store
  ) {}
}
