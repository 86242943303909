import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';

import { ButtonModule } from '@vip/ui/button';
import { CheckboxModule } from '@vip/ui/checkbox';
import { FieldModule } from '@vip/ui/field';
import { ScreenTitleModule } from '@vip/ui/screen-title';
import { MeusDadosComponent } from './meus-dados/meus-dados.component';
import { ConfirmarSenhaComponent } from './confirmar-senha/confirmar-senha.component';
import { FormPessoaFisicaComponent } from './form-pessoa-fisica/form-pessoa-fisica.component';
import { FormPessoaJuridicaComponent } from './form-pessoa-juridica/form-pessoa-juridica.component';
import { SelectModule } from '@vip/ui/select';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    CheckboxModule,
    FieldModule,
    ScreenTitleModule,
    NgxMaskModule.forRoot(),
    SelectModule,
    RouterModule
  ],
  declarations: [
    ConfirmarSenhaComponent,
    FormPessoaFisicaComponent,
    FormPessoaJuridicaComponent,
    MeusDadosComponent,
  ],
  exports: [
    ConfirmarSenhaComponent,
    FormPessoaFisicaComponent,
    FormPessoaJuridicaComponent,
    MeusDadosComponent,
  ],
})
export class MeusDadosViewModule {}
