import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as CupomActions from './cupom.actions';
import { cupomSelectors } from './cupom.selectors';
import { ICupomCredito } from '@vip/core';

@Injectable()
export class CupomFacade {
  loaded$ = this.store.pipe(select(cupomSelectors.isLoaded()));
  meusCupons$ = this.store.pipe(select(cupomSelectors.getData()));
  cuponsSelecionados$ = this.store.pipe(
    select(cupomSelectors.getCuponsSelecionados())
  );

  totalCuponsSelecionados$ = this.store.pipe(
    select(cupomSelectors.getTotalCupons())
  );

  constructor(private store: Store) {}

  getCupons() {
    this.store.dispatch(CupomActions.loadCupons());
  }

  getCuponsDisponiveis() {
    this.store.dispatch(CupomActions.loadCuponsDisponiveis());
  }

  setCuponsSelecionados(selecionados: ICupomCredito[]) {
    this.store.dispatch(CupomActions.setCuponsSelecionados({ selecionados }));
  }

  removerCupomSelecionado(cupom: ICupomCredito) {
    this.store.dispatch(CupomActions.removeCupomSelecionado({ cupom }));
  }

  resetCupons() {
    this.store.dispatch(CupomActions.resetCupons());
  }
}
