import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { PushNotificationData } from '@vip/core';
import { NotificationUtilsService } from '@vip/native/push-notification';
import * as notificacaoActions from './notificacao.actions';
import { notificacaoSelectors } from './notificacao.selectors';

@Injectable()
export class NotificacaoFacade {
  notificacoes$ = this.store.pipe(
    select(notificacaoSelectors.getNotificacoesArray())
  );
  loading$ = this.store.pipe(select(notificacaoSelectors.isLoading()));
  quantidadeNaoLidas$ = this.store.pipe(
    select(notificacaoSelectors.getQuantidadeNaoLidas())
  );

  constructor(
    private store: Store,
    private notificationUtilsService: NotificationUtilsService
  ) {}

  getNotificacoes() {
    this.store.dispatch(notificacaoActions.getNotificacoes());
  }

  abrirPaginaNotificacao(notificacao: PushNotificationData) {
    this.notificationUtilsService.performAction(notificacao);
  }

  registrarNotificacaoVisualizada(id: number) {
    this.store.dispatch(notificacaoActions.setNotificationRead({ id }));
  }
}
