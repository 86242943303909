import { NgModule } from '@angular/core';
import { RippleModule } from '@vip/ui/ripple';
import { CommonModule } from '@angular/common';
import { SelectableCardComponent } from './selectable-card/selectable-card.component';
import { SelectableCardSkeletonComponent } from './selectable-card-skeleton/selectable-card-skeleton.component';
import { SkeletonModule } from '@vip/ui/skeleton';

@NgModule({
  imports: [CommonModule, RippleModule, SkeletonModule],
  declarations: [SelectableCardComponent, SelectableCardSkeletonComponent],
  exports: [SelectableCardComponent, SelectableCardSkeletonComponent],
})
export class SelectableCardModule {}
