<vip-selectable-card
  *ngIf="formaPagamento"
  data-cy="card-forma-pagamento"
  class="pb-2"
  [showIcon]="true"
  [fullWidth]="true"
  [checked]="
    (formaPagamentoSelecionada &&
      formaPagamentoSelecionada.id === formaPagamento.id) ||
    false
  "
  (changed)="handleChangeFormaPagamento(formaPagamento.id)"
>
  <div title class="flex items-center my-1">
    <vip-image
      class="mr-3"
      [alt]="formaPagamento.descricao"
      [imageUrl]="formaPagamento.logomarca || ''"
      classNames="vip-card-forma-pagamento-image-size"
    ></vip-image>
    <div class="text-sm">{{ formaPagamento.descricao }}</div>
  </div>
</vip-selectable-card>
