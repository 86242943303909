import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  CacheIdsEnum,
  EnvironmentService,
  IS_APP,
  IStorage,
  STORAGE,
} from '@vip/core';
import { DialogService, IDialog, ModalService } from '@vip/ui/modal';
import { Router } from '@angular/router';
import { isPlatformBrowser, Location } from '@angular/common';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  take,
  tap,
} from 'rxjs/operators';
import { FilialFacade } from '@vip/state/filial';
import { AplicativoFacade } from '@vip/state/aplicativo';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class CacheUtilsService {
  isBrowser;

  constructor(
    @Inject(STORAGE) private readonly storage: IStorage,
    private router: Router,
    private location: Location,
    private dialogService: DialogService,
    private aplicativoFacade: AplicativoFacade,
    private filialFacade: FilialFacade,
    private modalService: ModalService<unknown>,
    @Inject(IS_APP) private readonly isApp: boolean,
    private environmentService: EnvironmentService,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  isCacheValid(cacheId: CacheIdsEnum) {
    const cachedData =
      !this.isApp && this.isBrowser
        ? localStorage.getItem(cacheId)
        : this.storage.getItem(cacheId);
    const storageData = cachedData
      ? (JSON.parse(cachedData) as {
          value: any;
          timestamp: Date;
        })
      : null;
    return storageData
      ? new Date(storageData.timestamp).getTime() > new Date().getTime()
      : false;
  }

  showCacheInvalidDialog() {
    const dialog: IDialog = {
      open: true,
      title: 'Erro de comunicação',
      subTitle:
        'Estamos enfrentado dificuldades para nos comunicar com nossos servidores no momento. Por favor, verifique sua conexão com a internet ou tente novamente mais tarde. Se o problema persistir, entre em contato com o nosso suporte',
      showCloseButton: true,
      buttonConfirmText: 'Contatar suporte',
      disabled: false,
    };

    this.dialogService.openDialog(dialog);
    this.dialogService.dialogClick.pipe(untilDestroyed(this)).subscribe(() => {
      this.modalService.clearModal();
      const url = this.isApp ? '/fale-conosco' : '/institucional/fale-conosco';
      this.router.navigateByUrl(url, { replaceUrl: true });
      this.dialogService.clearDialog();
    });
    this.dialogService.closeClick
      .pipe(untilDestroyed(this), take(1))
      .subscribe(() => {
        this.isApp
          ? this.location.back()
          : this.router.navigateByUrl('/', { replaceUrl: true });
      });
  }

  checkAplicativoFilialCache() {
    return new Promise<boolean>((resolve) => {
      combineLatest([
        this.filialFacade.filial$,
        this.aplicativoFacade.aplicativo$,
      ])
        .pipe(
          distinctUntilChanged(
            (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
          ),
          filter(([filial, aplicativo]) => {
            const usingCache = !!filial.fromCache || !!aplicativo?.fromCache;
            if (!usingCache) resolve(true);
            return usingCache;
          }),
          tap(() => {
            this.isApp
              ? this.aplicativoFacade.init()
              : this.aplicativoFacade.initFromVipcommerceFilialId(
                  this.environmentService.getVipcommerceFilialId()
                );
          }),
          mergeMap((filialApp) =>
            combineLatest([
              this.filialFacade.isLoaded$,
              this.aplicativoFacade.loaded$,
            ]).pipe(
              filter(([isLoaded, loaded]) => isLoaded && loaded),
              map(() => filialApp)
            )
          ),
          take(1)
        )
        .subscribe(([filial, app]) => {
          const isInvalid =
            (filial.fromCache && !this.isCacheValid(CacheIdsEnum.FILIAL_ID)) ||
            (app?.fromCache && !this.isCacheValid(CacheIdsEnum.APLICATIVO_ID));

          if (isInvalid) {
            this.showCacheInvalidDialog();
          }
          resolve(!isInvalid);
        });
    });
  }
}
