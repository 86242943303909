import { Inject, Injectable } from '@angular/core';
import { STORAGE, IStorage, EnvironmentService } from '@vip/core';
import { LojaApiService } from './loja-api.service';

export const LOJA_TOKEN = 'vip-token';

@Injectable({
  providedIn: 'root',
})
export class LojaTokenService {
  private tokenCache: string | null = null;

  constructor(
    @Inject(STORAGE) readonly storage: IStorage,
    private lojaApiService: LojaApiService,
    private environmentService: EnvironmentService
  ) {}

  async init(): Promise<void> {
    const token = this.getToken();
    return new Promise<void>((resolve, reject) => {
      (async () => {
        try {
          if (!token) {
            const vipToken = (
              await this.lojaApiService
                .getInitialJWT(this.environmentService.getDomainKey())
                .toPromise()
            ).data;
            this.setToken(vipToken);
            resolve();
          } else {
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      })();
    });
  }

  getToken(): string | null {
    if (this.tokenCache) {
      return this.tokenCache;
    }
    this.tokenCache = this.storage.getItem(LOJA_TOKEN) || '';
    return this.tokenCache;
  }

  setToken(value: string) {
    this.tokenCache = value;
    this.storage.setItem(LOJA_TOKEN, value);
  }
}
