import { createSelector } from '@ngrx/store';
import {
  ENDERECO_FEATURE_KEY,
  enderecoAdapter,
  enderecoEntregaAdapter,
  EnderecoState,
} from './endereco.reducer';
import { SelectorUtil } from '@vip/state/utils';

const { selectAll: selectEnderecos, selectEntities: selectEntitiesEnderecos } =
  enderecoAdapter.getSelectors();
const { selectAll: selectEnderecosEntrega } =
  enderecoEntregaAdapter.getSelectors();

class EnderecoSelectors extends SelectorUtil<EnderecoState> {
  constructor() {
    super(ENDERECO_FEATURE_KEY);
  }

  getAllEnderecos = () =>
    createSelector(this.createFeatureSelector, (state: EnderecoState) => {
      return state ? selectEntitiesEnderecos(state.data.enderecos) : undefined;
    });

  getAllEnderecosArray = () =>
    createSelector(this.createFeatureSelector, (state: EnderecoState) =>
      selectEnderecos(state.data.enderecos)
    );

  getAllEnderecosEntregaArray = () =>
    createSelector(this.createFeatureSelector, (state: EnderecoState) =>
      selectEnderecosEntrega(state.data.enderecosEntrega)
    );

  possuiEnderecoCadastrado = () =>
    createSelector(
      this.getAllEnderecosArray(),
      (enderecos) => enderecos.length > 0
    );

  possuiEnderecoPrincipalCadastrado = () =>
    createSelector(this.getAllEnderecosArray(), (enderecos) => {
      return enderecos.some((endereco) => endereco.principal);
    });

  enderecos = (state: EnderecoState) => state.data.enderecos.entities;
  enderecosEntrega = (state: EnderecoState) =>
    state.data.enderecosEntrega.entities;
  enderecoSelecionadoId = (state: EnderecoState) =>
    state.data.enderecoSelecionadoId;
  enderecoEntregaSelecionadoId = (state: EnderecoState) =>
    state.data.enderecoEntregaSelecionadoId;

  getEnderecoSelecionado = () =>
    createSelector(this.createFeatureSelector, (state: EnderecoState) => {
      const enderecoSelecionadoId = state.data.enderecoSelecionadoId;
      const enderecosEntrega = state.data.enderecosEntrega.entities;
      const enderecos = state.data.enderecos.entities;

      return enderecoSelecionadoId
        ? enderecos[enderecoSelecionadoId] ??
            enderecosEntrega[enderecoSelecionadoId] ??
            null
        : null;
    });

  getEnderecoEntrega = () =>
    createSelector(this.createFeatureSelector, (state: EnderecoState) => {
      const enderecoEntregaSelecionadoId =
        state.data.enderecoEntregaSelecionadoId;
      const enderecosEntrega = state.data.enderecosEntrega.entities;
      const enderecos = state.data.enderecos.entities;
      return enderecoEntregaSelecionadoId
        ? enderecosEntrega[enderecoEntregaSelecionadoId] ??
            enderecos[enderecoEntregaSelecionadoId] ??
            null
        : null;
    });
}

export const enderecoSelectors = new EnderecoSelectors();
