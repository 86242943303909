import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ITermosUso } from '@vip/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'vip-termos-uso',
  templateUrl: './termos-uso.component.html',
  styleUrls: ['./termos-uso.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TermosUsoComponent {
  aceiteTermosUso = false;

  @Input()
  canGoBack = true;

  @Input()
  openAsModal = false;

  @Input()
  loading = false;

  @Input()
  aprovacaoPendente = false;

  @Input()
  termosUso: ITermosUso | null = null;

  @Input()
  isDesktopModal = false;

  @Output()
  goBack: EventEmitter<Event> = new EventEmitter();

  @Output()
  confirm: EventEmitter<Event> = new EventEmitter();

  constructor(public sanitizer: DomSanitizer) {}

  onChangeAceiteTermosUso(value: boolean) {
    this.aceiteTermosUso = value;
  }

  aceitarTermos() {
    this.confirm.emit();
  }

  handleGoBack() {
    this.goBack.emit();
  }
}
