<div
  vipRipple
  (click)="resumeClick.emit()"
  [rippleColor]="rippleColor"
  rippleBorderRadius="5px"
  data-cy="vip-resume"
  class="flex flex-row text-sm justify-between rounded vip-resume cursor-pointer {{
    color
  }}"
>
  <span data-cy="vip-resume-itens" class="flex">{{
    itens | i18nPlural: itensMap
  }}</span>
  <span data-cy="vip-resume-label" class="flex font-bold">{{ label }}</span>
  <span data-cy="vip-resume-valor" class="flex">{{
    valor | currency: 'BRL'
  }}</span>
</div>
