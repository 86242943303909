import { CardDetalhesCobrancaComponent } from './card-detalhes-cobranca/card-detalhes-cobranca.component';
import { CardBasicoModule } from '@vip/ui/card-basico';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@vip/core';
import { ButtonModule } from '@vip/ui/button';
import { CardCashbackDesktopModule } from '@vip/ui/card-cashback-desktop';
import { CardResumoProdutosDesktopComponent } from './card-resumo-produtos-desktop/card-resumo-produtos-desktop.component';
import { ImageModule } from '@vip/ui/image';
import { CardDetalhesCobrancaSkeletonComponent } from './card-detalhes-cobranca-skeleton/card-detalhes-cobranca-skeleton.component';
import { SkeletonModule } from '@vip/ui/skeleton';

@NgModule({
  imports: [
    CommonModule,
    CardBasicoModule,
    CoreModule,
    ButtonModule,
    CardCashbackDesktopModule,
    ImageModule,
    SkeletonModule,
  ],
  declarations: [
    CardDetalhesCobrancaComponent,
    CardResumoProdutosDesktopComponent,
    CardDetalhesCobrancaSkeletonComponent,
  ],
  exports: [CardDetalhesCobrancaComponent],
})
export class CardDetalhesCobrancaModule {}
