<vip-tab-item
  [label]="label"
  [count]="count"
  class="vip-radio-pill"
  [class.opacity-50]="disabled"
  [class.pointer-events-none]="disabled"
  [icon]="icon"
  [selected]="!!checked"
  [rounded]="true"
  [color]="color"
  (tabClick)="onClick(this)"
></vip-tab-item>
