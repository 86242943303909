import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BucketsStateModule } from '@vip/state/buckets';
import { CentroDistribuicaoStateModule } from '@vip/state/centro-distribuicao';
import { ClienteStateModule } from '@vip/state/cliente';
import { CarrinhoItensStateModule } from '@vip/state/carrinho-itens';
import { ProdutoFacade } from './+state/produto.facade';
import { ProdutosSecaoStore } from './+state/produtos-secao.store';
import { ProdutosFavoritosStore } from './+state/produtos-favoritos.store';
import { ProdutosDepartamentoStore } from './+state/produtos-departamento.store';
import { ProdutosMaisVendidosStore } from './+state/produtos-mais-vendidos.store';
import { ProdutosOfertasStore } from './+state/produtos-ofertas.store';
import { ProdutosOfertasExclusivasStore } from './+state/produtos-ofertas-exclusivas.store';
import { ProdutosColecaoStore } from './+state/produtos-colecao.store';
import { ProdutosIndisponiveisStore } from './+state/produtos-indisponiveis.store';
import { ProdutosIndisponiveisService } from './services/produtos-indisponiveis.service';
import { AnalyticsStateModule } from '@vip/state/analytics';
import { ProdutosTagStore } from './+state/produtos.tag.store';
import { ProdutosVitrineDedicadaStore } from './+state/produtos-vitrine-dedicada.store';
import { ProdutosVitrinesEspecificasStore } from './+state/produtos-vitrines-especificas.store';
import { ProdutosDestaquesStore } from './+state/produtos-destaques.store';
import { ProdutosVitrinesPatrocinadosStore } from './+state/produtos-vitrines-patrocinados.store';

@NgModule({
  imports: [
    CommonModule,
    BucketsStateModule,
    CentroDistribuicaoStateModule,
    ClienteStateModule,
    CarrinhoItensStateModule,
    AnalyticsStateModule,
  ],
  providers: [
    ProdutoFacade,
    ProdutosDepartamentoStore,
    ProdutosFavoritosStore,
    ProdutosOfertasStore,
    ProdutosOfertasExclusivasStore,
    ProdutosMaisVendidosStore,
    ProdutosSecaoStore,
    ProdutosColecaoStore,
    ProdutosIndisponiveisStore,
    ProdutosTagStore,
    ProdutosIndisponiveisService,
    ProdutosVitrineDedicadaStore,
    ProdutosVitrinesEspecificasStore,
    ProdutosDestaquesStore,
    ProdutosVitrinesPatrocinadosStore,
  ],
})
export class ProdutoStateModule {}
