import { HttpErrorResponse } from '@angular/common/http';
import { VipMessagesInterface } from './vip-message-type';

export class VipMessages {
  static readonly API_MESSAGES: VipMessagesInterface = {
    error: {
      api: {
        AUTH_FAIL: {
          title: 'Login Inválido',
          message:
            'Por favor, verifique se você está digitando o usuário e a senha corretamente e tente novamente.',
        },
        NO_INITIAL_JWT: {
          title: 'Ops! Algo deu errado',
          message:
            'Desculpe, não foi possível autenticar suas credenciais. Verifique seu nome de usuário e senha, tente novamente. Se o problema persistir, por favor, contate o nosso suporte.',
        },
        ERROR_UNKNOWN: {
          title: 'Erro de comunicação',
          message:
            'Estamos enfrentando dificuldades para nos comunicar com nossos servidores no momento. Por favor, verifique sua conexão com a internet e tente novamente. Se o problema persistir, entre em contato com o nosso suporte ou tente novamente mais tarde.',
        },
      },
      app: {
        INVALID_DATA: {
          title: 'Dados inválidos',
          message: 'Por favor, verifique os dados informados',
        },
        ERROR_UNKNOWN: {
          title: 'Erro Desconhecido',
          message:
            'Ocorreu um erro desconhecido. Por favor, entre em contato com o suporte',
        },
      },
    },
  };

  static getApiError(error: HttpErrorResponse | string) {
    const errorCode =
      typeof error == 'string'
        ? error
        : error.error?.errorCode || 'ERROR_UNKNOWN';
    return {
      ...(this.API_MESSAGES.error.api[errorCode] ||
        this.API_MESSAGES.error.api['ERROR_UNKNOWN']),
      errorCode,
    };
  }

  static getAppError(errorCode: string) {
    return (
      this.API_MESSAGES.error.app[errorCode || 'ERROR_UNKNOWN'] ||
      this.API_MESSAGES.error.app['ERROR_UNKNOWN']
    );
  }
}
