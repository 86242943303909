import {
  FormaPagamentoEnum,
  TipoClienteEnum,
  TituloPagamentoEnum,
} from '../enums';
import { IFormaPagamento } from '../interfaces';

export const formaPagamentoCartaoMock: IFormaPagamento = {
  adquirente: {
    id: 4,
    component: 'CieloEcommerce',
    permitir_cofre_cartoes: true,
  },
  adquirente_id: 4,
  bandeira: 'mastercard',
  descricao: 'Master Cr\u00e9dito',
  mensagem:
    '\u003Cp\u003E\u003Cspan style=\u0022line-height:20.7999992370605px\u0022\u003EO pagamento com Cart\u0026atilde;o D\u0026eacute;bito dever\u0026aacute; ser\u0026nbsp;realizado no momento da entrega.\u003C/span\u003E\u003C/p\u003E\r\n',
  logomarca: 'https://picsum.photos/100',
  parcelas_sem_juros: 1,
  parcelas_proprias: 0,
  pagamento: FormaPagamentoEnum.PRE_PAGO,
  id: 2,
  exibe_mobile: true,
  exibe_loja: false,
  tipo_cliente: TipoClienteEnum.AMBOS,
  carteira_digital: false,
  parcelas: 1,
  juros: 1,
  valor_minimo_parcela: 0,
  mascara_cartao: '9999 9999 9999 9999',
  tipo_pagamento: TituloPagamentoEnum.CREDITO,
};

export const formaPagamentoDinheiroMock: IFormaPagamento = {
  adquirente: null,
  adquirente_id: null,
  bandeira: 'Master',
  descricao: 'Dinheiro',
  mensagem:
    '\u003Cp\u003E\u003Cspan style=\u0022line-height:20.7999992370605px\u0022\u003EO pagamento com Cart\u0026atilde;o D\u0026eacute;bito dever\u0026aacute; ser\u0026nbsp;realizado no momento da entrega.\u003C/span\u003E\u003C/p\u003E\r\n',
  logomarca: 'https://picsum.photos/100',
  parcelas_sem_juros: 1,
  parcelas_proprias: 0,
  pagamento: FormaPagamentoEnum.POS_PAGO,
  id: 1,
  exibe_mobile: true,
  exibe_loja: false,
  carteira_digital: false,
  tipo_cliente: TipoClienteEnum.AMBOS,
  parcelas: 1,
  juros: 0,
  valor_minimo_parcela: 0,
  tipo_pagamento: TituloPagamentoEnum.DEBITO,
};

export const formaPagamentoCarteiraDigitalMock: IFormaPagamento = {
  adquirente_id: null,
  adquirente: null,
  bandeira: 'Pix',
  descricao: 'Pix',
  mensagem:
    '\u003Cp\u003E\u003Cspan style=\u0022line-height:20.7999992370605px\u0022\u003EO pagamento com Cart\u0026atilde;o D\u0026eacute;bito dever\u0026aacute; ser\u0026nbsp;realizado no momento da entrega.\u003C/span\u003E\u003C/p\u003E\r\n',
  logomarca: 'https://picsum.photos/100',
  parcelas_sem_juros: 1,
  parcelas_proprias: 0,
  pagamento: FormaPagamentoEnum.PRE_PAGO,
  id: 3,
  exibe_mobile: true,
  exibe_loja: false,
  tipo_cliente: TipoClienteEnum.PESSOA_FISICA,
  carteira_digital: true,
  parcelas: 1,
  juros: 0,
  valor_minimo_parcela: 0,
  tipo_pagamento: TituloPagamentoEnum.DEBITO,
};

export const formaPagamentoCartaoComJurosMock: IFormaPagamento = {
  adquirente: null,
  adquirente_id: null,
  bandeira: 'Master',
  descricao: 'Master Cr\u00e9dito',
  mensagem:
    '\u003Cp\u003E\u003Cspan style=\u0022line-height:20.7999992370605px\u0022\u003EO pagamento com Cart\u0026atilde;o D\u0026eacute;bito dever\u0026aacute; ser\u0026nbsp;realizado no momento da entrega.\u003C/span\u003E\u003C/p\u003E\r\n',
  logomarca: 'https://picsum.photos/100',
  parcelas_sem_juros: 1,
  parcelas_proprias: 0,
  pagamento: FormaPagamentoEnum.PRE_PAGO,
  id: 1,
  exibe_mobile: true,
  exibe_loja: false,
  tipo_cliente: TipoClienteEnum.AMBOS,
  carteira_digital: false,
  parcelas: 3,
  juros: 1,
  valor_minimo_parcela: 50,
  tipo_pagamento: TituloPagamentoEnum.CREDITO,
};

export const formaPagamentoCartaoComJuros2Mock: IFormaPagamento = {
  adquirente_id: null,
  adquirente: null,
  bandeira: 'Master',
  descricao: 'Master Cr\u00e9dito',
  mensagem:
    '\u003Cp\u003E\u003Cspan style=\u0022line-height:20.7999992370605px\u0022\u003EO pagamento com Cart\u0026atilde;o D\u0026eacute;bito dever\u0026aacute; ser\u0026nbsp;realizado no momento da entrega.\u003C/span\u003E\u003C/p\u003E\r\n',
  logomarca: 'https://picsum.photos/100',
  parcelas_sem_juros: 0,
  parcelas_proprias: 0,
  pagamento: FormaPagamentoEnum.PRE_PAGO,
  id: 1,
  exibe_mobile: true,
  exibe_loja: false,
  tipo_cliente: TipoClienteEnum.AMBOS,
  carteira_digital: false,
  parcelas: 3,
  juros: 1,
  valor_minimo_parcela: 50,
  tipo_pagamento: TituloPagamentoEnum.CREDITO,
};
