import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BucketsStateModule } from '@vip/state/buckets';
import { BuscaStore } from './+state/busca.store';
import { CentroDistribuicaoStateModule } from '@vip/state/centro-distribuicao';
import { AnalyticsStateModule } from '@vip/state/analytics';
@NgModule({
  imports: [
    CommonModule,
    BucketsStateModule,
    CentroDistribuicaoStateModule,
    AnalyticsStateModule,
  ],
  providers: [BuscaStore],
})
export class BuscaStateModule {}
