<vip-base-component-skeleton [quantity]="2">
  <ng-template #skeletonContent let-odd>
    <hr *ngIf="odd" class="vip-divider mx-4 my-2" />
    <div class="ml-1 p-3 w-[235px] h-[54px]" data-cy="vip-tab-group-skeleton">
      <div class="flex items-center">
        <div
          *vipSkeleton="
            true;
            height: '24px';
            width: '24px';
            className: ['mr-2']
          "
        ></div>
        <div *vipSkeleton="true; height: '16px'; width: '110px'"></div>
      </div>
    </div>
  </ng-template>
</vip-base-component-skeleton>
