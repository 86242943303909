export class RouteTranslateService {
  static getLinkInterno(link: string, isDesktop: boolean = false) {
    const linkMap: { [key: string]: string } = {
      pag_home: '/loja',
      pag_fidelidade: '/fidelidade',
      pag_perfil: '/minha-conta',
      pag_cashback: isDesktop ? '/minha-conta/cashback' : '/cashback',
      pag_compras: '/minha-conta/historico-de-compras',
      pag_listas: isDesktop ? '/minha-conta/minhas-listas' : '/minhas-listas',
      pag_favoritos: isDesktop ? '/minha-conta/favoritos' : '/favoritos',
      pag_departamentos: '/departamentos',
      pag_promocoes: '/promocoes',
      pag_colecoes: '/colecoes',
      pag_receitas: '/receitas',
      pag_convide_amigos: isDesktop
        ? '/minha-conta/convide-amigos'
        : '/convide-amigos',
      pag_fale_conosco: isDesktop
        ? 'institucional/fale-conosco'
        : '/fale-conosco',
      pag_institucional: '/institucional',
      pag_termos: '/termos-uso',
      pag_politicas: '/institucional/pagina/politica-de-privacidade',
      pag_home_omni: '/home',
      pag_menu: '/menu',
      pag_busca: '/busca',
      pag_ofertas: '/ofertas',
    };
    return linkMap[link] || link;
  }
}
