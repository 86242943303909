import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromBuckets from './+state/buckets.reducer';
import { BucketsFacade } from './+state/buckets.facade';
import { BucketsService } from './services/buckets.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromBuckets.BUCKETS_FEATURE_KEY,
      fromBuckets.reducer
    ),
  ],
  providers: [BucketsFacade, BucketsService],
})
export class BucketsStateModule {}
