<ng-container *ngIf="!isDesktop; else desktopTemplate">
  <vip-radio-pill
    *ngIf="getInternalFilters().length"
    class="mr-1"
    data-cy="filter_pill_filtros"
    icon="icon-filter_list"
    (click)="filterClick.emit()"
    label="Filtros"
    [count]="
      getInternalFiltersCount() > 0 ? getInternalFiltersCount().toString() : ''
    "
    [checked]="getInternalFiltersCount() > 0"
  ></vip-radio-pill>
  <vip-radio-group [disabled]="false" direction="horizontal">
    <ng-container *ngIf="getExternalFilters().length">
      <vip-radio-pill
        *ngFor="let filterItem of getExternalFilters()[0].options"
        [value]="filterItem.value"
        [label]="filterItem.label"
        [count]="filterItem.count ? filterItem.count.toString() : ''"
        [checked]="filterItem.checked || false"
        [attr.data-cy]="'filter_pill_' + filterItem.value"
        (click)="onChangeExternalFilter(filterItem)"
      ></vip-radio-pill>
    </ng-container>
  </vip-radio-group>
</ng-container>
<ng-template #desktopTemplate>
  <vip-radio-pill
    *ngIf="getInternalFilters().length"
    class="mr-1"
    data-cy="filter_pill_filtros"
    icon="icon-filter_list"
    (click)="filterClick.emit()"
    label="Filtros"
    [count]="
      getInternalFiltersCount() > 0 ? getInternalFiltersCount().toString() : ''
    "
    [checked]="getInternalFiltersCount() > 0"
  ></vip-radio-pill>
  <vip-radio-group [disabled]="false" direction="horizontal">
    <ng-container *ngIf="getExternalFilters().length">
      <swiper
        id="swiper-header-filter"
        [config]="swiperConfig"
        [navigation]="true"
      >
        <ng-template
          swiperSlide
          *ngFor="let filterItem of getExternalFilters()[0].options"
        >
          <vip-radio-pill
            [value]="filterItem.value"
            [label]="filterItem.label"
            [count]="filterItem.count ? filterItem.count.toString() : ''"
            [checked]="filterItem.checked || false"
            [attr.data-cy]="'filter_pill_' + filterItem.value"
            (click)="onChangeExternalFilter(filterItem)"
          ></vip-radio-pill>
        </ng-template>
      </swiper>
    </ng-container>
  </vip-radio-group>
</ng-template>
