import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timeToEnd' })
export class TimeToEndPipe implements PipeTransform {
  transform(endDate: Date, isDesktop: boolean = false): string | null {
    if (isNaN(endDate.getTime())) return '--h--min';

    const toDate = new Date();
    const durationSecond = Math.round((+endDate - +toDate) / 1000);

    if (isDesktop) {
      return this.formatarHoraEMinutos(durationSecond);
    } else {
      const dias = Math.floor(durationSecond / 86400);
      if (dias > 0) {
        return this.formatDate(endDate);
      } else {
        return this.formatarHoraEMinutos(durationSecond);
      }
    }
  }

  // Função para formatar horas e minutos
  formatarHoraEMinutos(durationSecond: number): string {
    const horas = Math.floor(Math.abs(durationSecond % 86400) / 3600);
    const minutos = Math.floor((Math.abs(durationSecond % 86400) % 3600) / 60);
    return `${horas}h${minutos}min`;
  }

  // Função para converter a data para o formato 'dd/mm/yyyy'
  formatDate(date: Date): string {
    return date.toISOString().substr(0, 10).split('-').reverse().join('/');
  }
}
