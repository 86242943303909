import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IList } from '@vip/core';

@Component({
  selector: 'vip-convite-lista',
  templateUrl: './convite-lista.component.html',
  styleUrls: ['./convite-lista.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConviteListaComponent {
  @Input() lista!: IList;
  @Input() isDesktop = false;
  @Input() estaParticipando = false;

  @Output() acessarLista = new EventEmitter();
  @Output() participarLista = new EventEmitter();

  handleConviteClick() {
    if (this.estaParticipando) {
      this.acessarLista.emit(this.lista.id);
    } else {
      this.participarLista.emit();
    }
  }
}
