<div
  class="vip-selectable-card flex rounded cursor-pointer"
  [ngClass]="{
    'inline-flex': !fullWidth,
    'change-color': (checked && changeTextColor) || error,
    'flex-col content-start': !showControl,
    'justify-between': showControl,
    'my-2 mx-2 card-expanded': expanded,
    shadow: !noShadow,
    'no-shadow': noShadow,
    checked: checked,
    error: error,
    disabled: disabled || error
  }"
  (click)="toggleChecked()"
  vipRipple
  rippleBorderRadius="5px"
  [rippleDisabled]="disabled || error"
>
  <div
    [ngClass]="{
      'flex flex-col content-start': showControl,
      'w-full': expanded,
      contents: !showControl && !fullHeight,
      'flex flex-col content-start h-full justify-between': fullHeight
    }"
  >
    <div
      [ngClass]="{ flex: !expanded }"
      class="justify-between items-center text-sm"
    >
      <div class="w-full">
        <ng-content select="[title]"></ng-content>
      </div>
      <i
        *ngIf="showIcon && !expanded"
        class="vip-selectable-card-icon ml-2 icon-check {{ iconSize }}"
        [class.invisible]="!checked"
      ></i>
    </div>
    <div class="flex justify-between items-center text-sm">
      <div class="w-full">
        <ng-container *ngIf="error; else contentTemplate">
          <p class="vip-color-error-main text-sm font-normal mt-2">
            Erro ao trazer prazo de entrega
          </p>
        </ng-container>
        <ng-template #contentTemplate>
          <ng-content select="[body]"></ng-content>
        </ng-template>
      </div>
    </div>
  </div>
  <div *ngIf="showControl">
    <ng-content select="[control]"></ng-content>
  </div>
</div>
