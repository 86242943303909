<ng-container *ngIf="!isDesktop; else desktopTemplate">
  <vip-endereco
    class="vip-page-content"
    [endereco]="
      (enderecoSelecionado$ | async) || (newEndereco$ | async) || null
    "
    [cepAtendido]="(cepAtendido$ | async) === true"
    [cepValido]="(cepAtendido$ | async) === true"
    [isNew]="(enderecoSelecionado$ | async) === null"
    [possuiEnderecoCadastrado]="(possuiEnderecoCadastrado$ | async) === true"
    [loading]="(loadingCep$ | async) || (loadingEndereco$ | async) === true"
    [possuiEnderecoPrincipalCadastrado]="
      !!(possuiEnderecoPrincipalCadastrado$ | async)
    "
    (changeCep)="changeCep($event)"
    (confirm)="onSaveEndereco($event)"
    (deleteClick)="deleteEndereco($event)"
  ></vip-endereco>
</ng-container>
<ng-template #desktopTemplate>
  <vip-meus-enderecos
    [enderecos]="(enderecos$ | async) || []"
    (editarEndereco)="handleEditarEndereco($event.id)"
    (adicionarEndereco)="handleAdicionarEndereco()"
  >
  </vip-meus-enderecos>
</ng-template>
