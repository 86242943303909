import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { fetch } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { ClienteApiService, CookiesApiService } from '@vip/api';
import {
  FacebookPixelService,
  ICliente,
  IEndereco,
  IFilial,
  IParametros,
  LGPD_COOKIE,
  LayoutUtilsService,
  STORAGE,
  VipMessages,
} from '@vip/core';
import { BiometricsService } from '@vip/native/biometric';
import { DialogService, IDialog } from '@vip/ui/modal';
import { MessageService } from '@vip/ui/message';
import { FilialActions, FilialFacade } from '@vip/state/filial';
import { TermosUsoFacade } from '@vip/state/termos-uso';
import { ClienteWsService } from '@vip/ws';

import * as ClienteActions from './cliente.actions';
import { ClienteTokenService } from '../services/cliente-token.service';
import { PushNotificationService } from '@vip/native/push-notification';
import { isPlatformBrowser } from '@angular/common';
import { ParametrosFacade } from '@vip/state/parametros';
import { ClienteFacade } from './cliente.facade';
import { untilDestroyed } from '@ngneat/until-destroy';
import { CodigoCompartilhamentoService } from '../services/codigo-compartilhamento.service';

@Injectable()
export class ClienteEffects {
  isBrowser: boolean;
  userLoginLogoutChannel?: BroadcastChannel;

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClienteActions.login),
      withLatestFrom(this.filialFacade.filial$),
      fetch({
        run: (action, filial: IFilial) =>
          this.clienteApiService.login(filial.id, action.login).pipe(
            map((token) =>
              ClienteActions.loginSuccess({
                token: token.data,
                login: action.login,
                redirectTo: action.redirectTo,
                validarPin: action.validarPin,
              })
            )
          ),

        onError: (action, error) => {
          return ClienteActions.loginFailure({ error });
        },
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClienteActions.logout),
      fetch({
        run: (action) =>
          this.clienteWsService.logout().pipe(
            map(() => {
              return ClienteActions.logoutSuccess({
                redirectTo: action.redirectTo,
              });
            })
          ),
        onError: (action) =>
          ClienteActions.logoutSuccess({
            redirectTo: action.redirectTo,
          }),
      })
    )
  );

  consultaValidacaoPin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClienteActions.validarOTPSuccess),
      withLatestFrom(
        this.filialFacade.filial$,
        this.clienteFacade.isLogged$,
        this.parametroFacade.parametros$
      ),
      filter(
        ([action, filial, islogged, parametros]) =>
          !!islogged && !!parametros.pin_ativo
      ),
      fetch({
        run: (action, filial: IFilial, isLogged, parametros) =>
          this.clienteApiService.consultaSolicitacaoSMS(filial.id).pipe(
            map((result) => {
              return ClienteActions.getConsultaSMSSucess({
                consultaSMS: result,
              });
            })
          ),
        onError: (action, error) => ClienteActions.getConsultaSMSFailure(error),
      })
    )
  );

  consultaValidacaoPinLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClienteActions.getClienteSuccess),
      withLatestFrom(
        this.filialFacade.filial$,
        this.clienteFacade.isLogged$,
        this.parametroFacade.parametros$
      ),
      filter(
        ([action, filial, islogged, parametros]) =>
          islogged &&
          !!action.requestConsultaSms &&
          !!action.validarPin &&
          !!parametros.pin_ativo
      ),
      fetch({
        run: (action, filial: IFilial, isLogged) =>
          this.clienteApiService.consultaSolicitacaoSMS(filial.id).pipe(
            map((result) => {
              return ClienteActions.getConsultaSMSSucess({
                consultaSMS: result,
                edicaoNumero: action.validarPin,
              });
            })
          ),
        onError: (action, error) => ClienteActions.getConsultaSMSFailure(error),
      })
    )
  );

  setTokenInStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ClienteActions.loginSuccess,
          ClienteActions.addClienteSuccess,
          ClienteActions.validaPinLoginSucess
        ),
        tap((action) => {
          this.clienteTokenService.setToken(action.token);
          this.userLoginLogoutChannel?.postMessage('login');
        })
      ),
    { dispatch: false }
  );

  tokenLgpd$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.loginSuccess),
        tap((action) => {
          const cookieSalvo = this.storage.get(LGPD_COOKIE);

          if (cookieSalvo !== '' && cookieSalvo == 'true') {
            this.cookiesApiService.aceitarUsoCookie();
            this.cookiesApiService.salvarAutorizacaoUsoCookie();
          }
        })
      ),
    { dispatch: false }
  );

  salvarBiometria$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.loginSuccess),
        tap((action) => {
          if (action.login) {
            this.salvarBiometria(action.login);
          }
        })
      ),
    { dispatch: false }
  );

  showLoginErrorDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.loginFailure),
        filter((action) => !action.error.error.error.marketplace),
        tap((action) => {
          const message = VipMessages.getApiError(action.error);
          const isAuthError = message.errorCode === 'AUTH_FAIL';
          const dialogData: IDialog = {
            open: true,
            title: message.title,
            subTitle: message.message,
            disabled: false,
            buttonConfirmText: 'Tentar novamente',
          };

          if (!isAuthError) dialogData.buttonCancelText = 'Contatar suporte';

          this.dialogService.openDialog({ ...dialogData }, true);

          if (!isAuthError)
            this.dialogService.dialogClick.pipe(take(1)).subscribe((action) => {
              if (!action)
                this.router.navigateByUrl(
                  this.layoutUtilsService.isDesktop()
                    ? '/institucional/fale-conosco'
                    : '/fale-conosco'
                );
            });
        })
      ),
    { dispatch: false }
  );

  showLoginErrorDialogParaClientesCadastradosPorMarketplace$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.loginFailure),
        filter((action) => !!action.error.error.error.marketplace),
        tap((action) => {
          this.dialogService.openDialog(
            {
              open: true,
              title: 'Criar Senha',
              subTitle: `Prezado(a), você já tem cadastro conosco  pelo nosso parceiro ${action.error.error.error.marketplace}. Para prosseguir, redefina sua senha para uso nesta plataforma.`,
              disabled: false,
              buttonConfirmText: 'Redefinir Senha',
            },
            true
          );
          this.dialogService.dialogClick.subscribe((redefinir) => {
            if (redefinir)
              this.router.navigateByUrl('/clientes/redefinirSenha');
            this.dialogService.clearDialog();
          });
        })
      ),
    { dispatch: false }
  );

  redirectAfterLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ClienteActions.loginSuccess,
          ClienteActions.validaPinLoginSucess
        ),
        tap((action) => {
          this.router.navigateByUrl(action?.redirectTo || '/');
        })
      ),
    { dispatch: false }
  );

  redirectAfterLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.logoutSuccess),
        tap((action) => {
          this.router.navigateByUrl(action?.redirectTo || '/');
        })
      ),
    { dispatch: false }
  );

  clearTokenInStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.logoutSuccess),
        tap(() => this.clienteTokenService.clearToken())
      ),
    { dispatch: false }
  );

  clearCodigoCompartilhamento$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.logoutSuccess),
        tap(() => this.codigoCompartilhamentoService.clearCodigo())
      ),
    { dispatch: false }
  );

  registrarDispositivo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ClienteActions.loginSuccess,
          ClienteActions.addClienteSuccess,
          ClienteActions.validaPinLoginSucess
        ),
        filter(() => this.isBrowser),
        tap(() => this.notificationService.registrarDispositivo())
      ),
    { dispatch: false }
  );

  removerDispositivo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.logoutSuccess),
        filter(() => this.isBrowser),
        tap(() => this.notificationService.removerDispositivo())
      ),
    { dispatch: false }
  );

  getCliente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClienteActions.loginSuccess, ClienteActions.validaPinLoginSucess),
      fetch({
        run: (action) =>
          this.clienteApiService.getInformacoesCliente(action.token).pipe(
            map((cliente) =>
              ClienteActions.getClienteSuccess({
                cliente: cliente.data,
                token: action.token,
                requestConsultaSms: true,
                validarPin: true,
              })
            )
          ),

        onError: (action, error) => {
          return ClienteActions.getClienteFailure({ error });
        },
      })
    )
  );

  getClienteData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClienteActions.getClienteData),
      fetch({
        run: (action) =>
          this.clienteApiService.getInformacoesCliente(action.token).pipe(
            map((cliente) =>
              ClienteActions.getClienteSuccess({
                cliente: cliente.data,
                token: action.token,
                requestConsultaSms: true,
                validarPin: false,
              })
            )
          ),

        onError: (action, error) => {
          return ClienteActions.getClienteFailure({ error });
        },
      })
    )
  );

  getClienteAddCLiente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClienteActions.addClienteSuccess),
      fetch({
        run: (action) =>
          this.clienteApiService.getInformacoesCliente(action.token).pipe(
            map((cliente) =>
              ClienteActions.getClienteSuccess({
                cliente: cliente.data,
                token: action.token,
                requestConsultaSms: true,
                validarPin: false,
              })
            )
          ),

        onError: (action, error) => {
          return ClienteActions.getClienteFailure({ error });
        },
      })
    )
  );

  updateParcelasFacebookPixelAddCLiente$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.addClienteSuccess),
        tap((action) => {
          const cliente = action.cliente as ICliente & { password: string } & {
            endereco: IEndereco;
          };
          this.facebookPixelService.completeRegistration(cliente.endereco.cep);
        })
      ),
    { dispatch: false }
  );

  editCliente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClienteActions.editCliente),
      withLatestFrom(
        this.clienteFacade.cliente$,
        this.parametroFacade.parametros$
      ),
      fetch({
        run: (action, clienteAtual, { pin_ativo }) =>
          this.clienteApiService
            .editDadosCliente(action.editClienteRequest)
            .pipe(
              map((cliente) => {
                const telefonesDiferentes =
                  clienteAtual?.telefone_celular ===
                  action.editClienteRequest.telefone_celular?.replace(
                    /[^0-9]+/g,
                    ''
                  );

                if ((pin_ativo && telefonesDiferentes) || !pin_ativo) {
                  return ClienteActions.editClienteSuccess({
                    cliente,
                    urlToRedirect: action.urlToRedirect,
                  });
                } else {
                  this.clienteFacade
                    .invalidarNumeroCelular(
                      action.editClienteRequest.telefone_celular
                    )
                    .subscribe();

                  return ClienteActions.editClienteSuccess({
                    cliente,
                    urlToRedirect: `validar-telefone/minha-conta?redirectTo=${action.urlToRedirect}`,
                  });
                }
              })
            ),

        onError: (action, error) => {
          return ClienteActions.editClienteFailure({ error });
        },
      })
    )
  );

  addCliente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClienteActions.addCliente),
      withLatestFrom(this.filialFacade.filial$),
      fetch({
        run: (action, filial: IFilial) =>
          this.clienteApiService
            .addCliente(action.addClienteRequest, filial.id)
            .pipe(
              map(({ cliente, token }) =>
                ClienteActions.addClienteSuccess({
                  cliente: { ...action.addClienteRequest, ...cliente },
                  token,
                })
              )
            ),

        onError: (action, error) => {
          return ClienteActions.addClienteFailure({ error });
        },
      })
    )
  );

  redirectToUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.editClienteSuccess),
        tap((action) => this.router.navigateByUrl(action.urlToRedirect))
      ),
    { dispatch: false }
  );

  showSuccessMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ClienteActions.editClienteSuccess,
          ClienteActions.addClienteSuccess
        ),
        tap(() =>
          this.messageService.openSuccessMessage('Dados salvos com sucesso', 2)
        )
      ),
    { dispatch: false }
  );

  verificaSeAceitouTermosDeUso$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.getClienteSuccess),
        tap(() => this.termosUsoFacade.getAprovacaoPendente())
      ),
    { dispatch: false }
  );

  setPinParameter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilialActions.getFilialPorVipCommerceFilialIdSuccess),
        withLatestFrom(
          this.filialFacade.filial$,
          this.parametroFacade.parametros$
        ),
        fetch({
          run: (action, filial: IFilial, parametros: IParametros) =>
            this.clienteApiService.buscarValidacaoPin(filial.id).pipe(
              map((res) => {
                this.parametroFacade.setParametros({
                  ...parametros,
                  pin_ativo: res.pin_ativo,
                });
                return ClienteActions.getPinParameterSuccess();
              })
            ),
          onError: (action, error) => {
            return ClienteActions.getPinParameterFailure(error);
          },
        })
      ),
    { dispatch: false }
  );

  solicitaEnvioSMS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClienteActions.solicitaSMS),
      withLatestFrom(this.filialFacade.filial$),
      fetch({
        run: (action, filial: IFilial) =>
          this.clienteApiService
            .solicitarSMS(filial.id, action.numeroCelular)
            .pipe(
              map(() => {
                return ClienteActions.solicitaSMSSucess({
                  edicaoNumero: action.edicaoNumero,
                });
              })
            ),
        onError: (action, error) => ClienteActions.solicitaSMSFailure(error),
      })
    )
  );

  validarOTP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClienteActions.validarOTP),
      withLatestFrom(this.filialFacade.filial$),
      fetch({
        run: (action, filial: IFilial) =>
          this.clienteApiService.validarOTP(filial.id, action.code).pipe(
            map((res) => {
              return ClienteActions.validarOTPSuccess({
                telefoneCelular: res.telefone_celular,
                msg: action.msg,
                redirectTo: action.redirectTo,
              });
            })
          ),
        onError: (action, error) => ClienteActions.validarOTPFailure(error),
      })
    )
  );

  validarOtpError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.validarOTPFailure),
        tap((action) => {
          if (action.error.error) {
            this.messageService.openErrorMessage(action.error.error, 1.5);
          }
        })
      ),
    { dispatch: false }
  );

  solicitaEnvioSMSLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClienteActions.solicitaSMSLogin),
      withLatestFrom(this.filialFacade.filial$),
      fetch({
        run: (action, filial: IFilial) =>
          this.clienteApiService
            .solicitarSMSLogin(filial.id, action.numeroCelular, action.cpf)
            .pipe(
              map((result) => {
                return ClienteActions.solicitaSMSLoginSucess({
                  consultaSMS: result,
                });
              })
            ),
        onError: (action, error) =>
          ClienteActions.solicitaSMSLoginFailure(error),
      })
    )
  );

  solicitaEnvioSMSLoginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.solicitaSMSLoginFailure),
        tap((action) => {
          this.numeroNaoValidado(action.error.error);
        })
      ),
    { dispatch: false }
  );

  consultaSolicitacaoSMS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClienteActions.solicitaSMSSucess),
      withLatestFrom(this.filialFacade.filial$),
      fetch({
        run: (action, filial: IFilial) =>
          this.clienteApiService.consultaSolicitacaoSMS(filial.id).pipe(
            map((result) => {
              return ClienteActions.getConsultaSMSSucess({
                consultaSMS: result,
                edicaoNumero: action.edicaoNumero,
              });
            })
          ),
        onError: (action, error) => ClienteActions.getConsultaSMSFailure(error),
      })
    )
  );

  redirecionaParaValidacao$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.getConsultaSMSSucess),
        withLatestFrom(this.parametroFacade.parametros$),
        filter(
          () => !this.router.url.split('?')[0].includes('/validar-telefone')
        ),
        tap(([action, parametros]) => {
          const queryParams = {
            editarNumero: true,
          };
          if (
            !action.consultaSMS.telefone_celular_validado &&
            action.edicaoNumero &&
            parametros.pin_ativo
          ) {
            this.router.navigateByUrl('/validar-telefone', {
              state: queryParams,
            });
          }
        })
      ),
    { dispatch: false }
  );

  validaPinLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClienteActions.validaPinLogin),
      withLatestFrom(this.filialFacade.filial$),
      fetch({
        run: (action, filial: IFilial) =>
          this.clienteApiService
            .validarPinLogin(
              filial.id,
              action.codigo,
              action.numeroCelular,
              action.cpf
            )
            .pipe(
              map((result) => {
                return ClienteActions.validaPinLoginSucess({
                  token: result.token,
                  redirectTo: '',
                });
              })
            ),
        onError: (action, error) => ClienteActions.validaPinLoginFailure(error),
      })
    )
  );

  validaPinMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ClienteActions.validaPinLoginFailure,
          ClienteActions.validarOTPFailure,
          ClienteActions.validarOTPSuccess
        ),
        tap((action) => {
          const text =
            action.type === ClienteActions.validarOTPSuccess.type
              ? action.msg
              : action.error.error;

          if (text) {
            this.dialogService.openDialog({
              open: true,
              title: new String(text).split('!')[0],
              subTitle: new String(text).split('!')[1],
              buttonConfirmText:
                action.type === ClienteActions.validarOTPSuccess.type
                  ? 'Acessar a Loja'
                  : 'Tentar novamente',
              disabled: false,
              showCloseButton: true,
            });
            this.dialogService.dialogClick.subscribe(() => {
              this.dialogService.clearDialog();
              if (action.type === ClienteActions.validarOTPSuccess.type)
                this.router.navigateByUrl(action?.redirectTo || '/');
            });

            this.dialogService.closeClick.subscribe(() => {
              this.dialogService.clearDialog();
              if (action.type === ClienteActions.validarOTPSuccess.type)
                this.router.navigateByUrl(action?.redirectTo || '/');
            });
          }
        })
      ),
    { dispatch: false }
  );

  private salvarBiometriaDialog: IDialog = {
    open: true,
    title: 'Login com biometria',
    subTitle: 'Deseja habilitar o login com biometria para esta conta?',
    disabled: false,
    buttonConfirmText: 'Sim',
    buttonCancelText: 'Não',
  };

  private informarBiometriaDisponivelDialog: IDialog = {
    open: true,
    title: 'Login com biometria disponível',
    subTitle:
      'Nesse app temos login por biometria. Caso deseje utilizar, por favor cadastre sua biometria em seu aparelho.',
    disabled: false,
    buttonConfirmText: 'Ok',
  };

  private loginPorCelularErrorDialog: IDialog = {
    open: true,
    title: 'Número de celular não validado!',
    subTitle:
      'Para fazer o login com o número de celular é necessário validá-lo. Por favor, entre no aplicativo utilizando seu CPF e Senha para validar seu número de telefone.',
    buttonConfirmText: 'Voltar para o login',
    disabled: false,
    showCloseButton: true,
  };

  constructor(
    private actions$: Actions,
    private biometricsService: BiometricsService,
    private codigoCompartilhamentoService: CodigoCompartilhamentoService,
    private clienteApiService: ClienteApiService,
    private clienteTokenService: ClienteTokenService,
    private facebookPixelService: FacebookPixelService,
    private dialogService: DialogService,
    private filialFacade: FilialFacade,
    private messageService: MessageService,
    private router: Router,
    private termosUsoFacade: TermosUsoFacade,
    private clienteWsService: ClienteWsService,
    private notificationService: PushNotificationService,
    private parametroFacade: ParametrosFacade,
    private clienteFacade: ClienteFacade,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private layoutUtilsService: LayoutUtilsService,
    @Inject(STORAGE) readonly storage: Storage,
    private cookiesApiService: CookiesApiService
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      this.userLoginLogoutChannel = new BroadcastChannel('login-logout');
    }
  }

  async salvarBiometria(login: { usuario: string; password: string }) {
    const credenciais = await this.biometricsService.getCredentials();
    if (!credenciais || credenciais.username !== login.usuario) {
      if (await this.biometricsService.isAvailable()) {
        await this.solicitarSalvarBiometria(login);
      } else if (await this.biometricsService.isAvailableButNotSet()) {
        this.dialogService.openDialog(this.informarBiometriaDisponivelDialog);
        this.dialogService.dialogClick.subscribe(() =>
          this.dialogService.clearDialog()
        );
      }
    }
  }

  async solicitarSalvarBiometria(login: { usuario: string; password: string }) {
    this.dialogService.openDialog(this.salvarBiometriaDialog);
    this.dialogService.dialogClick.subscribe((salvarBiometria) => {
      if (salvarBiometria) {
        this.biometricsService.setCredentials(login.usuario, login.password);
      } else {
        this.biometricsService.deleteCredentials();
      }
      this.dialogService.clearDialog();
    });
  }

  async numeroNaoValidado(error: string) {
    if (error?.includes('Telefone celular não cadastrado para este CPF')) {
      this.loginPorCelularErrorDialog = {
        ...this.loginPorCelularErrorDialog,
        title: 'Telefone celular não cadastrado!',
        subTitle:
          'Para fazer o login com o número de celular é necessário cadastrá-lo. Por favor, entre no aplicativo utilizando seu CPF e Senha para cadastrar seu número de telefone.',
      };
    } else if (error?.includes('Login por celular não permitido')) {
      this.loginPorCelularErrorDialog = {
        ...this.loginPorCelularErrorDialog,
        title: 'Login por celular não permitido!',
        subTitle:
          'Para fazer o login com o número de celular é necessário que a loja ative. Por favor, aguarde até que está opção esteja ativa.',
      };
    }

    this.dialogService.openDialog(this.loginPorCelularErrorDialog);
    this.dialogService.dialogClick.subscribe(() => {
      this.dialogService.clearDialog();
      this.router.navigateByUrl('/login');
    });
  }
}
