import { LoadingSpinnerModule } from '@vip/ui/loading-spinner';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RippleModule } from '@vip/ui/ripple';
import { SelectableCardModule } from '@vip/ui/selectable-card';
import { TabsModule } from '@vip/ui/tabs';
import { EntregaRetiradaModule } from '@vip/views/entrega-retirada';
import { OpcoesEntregaRetiradaComponent } from './opcoes-entrega-retirada/opcoes-entrega-retirada.component';
import { TabsEntregaRetiradaComponent } from './tabs-entrega-retirada/tabs-entrega-retirada.component';
import { FieldModule } from '@vip/ui/field';
import { RouterModule } from '@angular/router';
import { SelectModule } from '@vip/ui/select';

@NgModule({
  imports: [
    CommonModule,
    TabsModule,
    SelectableCardModule,
    RippleModule,
    FieldModule,
    EntregaRetiradaModule,
    RouterModule,
    SelectModule,
    LoadingSpinnerModule,
  ],
  declarations: [TabsEntregaRetiradaComponent, OpcoesEntregaRetiradaComponent],
  exports: [TabsEntregaRetiradaComponent, OpcoesEntregaRetiradaComponent],
})
export class TabsEntregaRetiradaModule {}
