import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseWsService } from '../common/base-ws-service';
import { GetPaginasInstitucionaisResponse } from './institucional-ws.types';

@Injectable({
  providedIn: 'root',
})
export class InstitucionalWsService {
  private paginasUrl = '/loja/paginas/index';

  constructor(private ws: BaseWsService<InstitucionalWsService>) {}

  getPaginasInstitucionais(
    filialId: number
  ): Observable<GetPaginasInstitucionaisResponse> {
    return this.ws.get<GetPaginasInstitucionaisResponse>(
      'getPaginasInstitucionais',
      `${this.paginasUrl}/${filialId}`,
      { FilialID: filialId.toString() }
    );
  }
}
