import { Injectable } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  network = Network;

  addListener = this.network.addListener;

  status(): Promise<ConnectionStatus> {
    return this.network.getStatus();
  }

  async isNotConnection(): Promise<boolean> {
    const { connected, connectionType } = await this.network.getStatus();
    return !connected || connectionType === 'none';
  }

  removeAllListeners() {
    return this.network.removeAllListeners();
  }
}
