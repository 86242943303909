import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { CardPagamentoComponent } from './card-pagamento/card-pagamento.component';
import { CardBasicoModule } from '@vip/ui/card-basico';
import { ToggleModule } from '@vip/ui/toggle';
import { ImageModule } from '@vip/ui/image';
import { ButtonModule } from '@vip/ui/button';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldModule } from '@vip/ui/field';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ModalModule } from '@vip/ui/modal';
import { CoreModule, PagamentoTextoPipe, ParcelasTextoPipe } from '@vip/core';
import { ModalTrocoComponent } from './modal-troco/modal-troco.component';

@NgModule({
  imports: [
    CommonModule,
    CardBasicoModule,
    ToggleModule,
    ImageModule,
    ButtonModule,
    ReactiveFormsModule,
    FieldModule,
    CurrencyMaskModule,
    ModalModule,
    CoreModule,
  ],
  providers: [ParcelasTextoPipe, PagamentoTextoPipe, CurrencyPipe],
  declarations: [CardPagamentoComponent, ModalTrocoComponent],
  exports: [CardPagamentoComponent, ModalTrocoComponent],
})
export class CardPagamentoModule {}
