<vip-termos-uso
  class="vip-page-content"
  [loading]="(loading$ | async) === true"
  [canGoBack]="canGoBack"
  [openAsModal]="openAsModal"
  [aprovacaoPendente]="aprovacaoPendente"
  [termosUso]="(termosUso$ | async) || null"
  [isDesktopModal]="isDesktopModal"
  (confirm)="handleAccept()"
  (goBack)="goBack()"
></vip-termos-uso>
