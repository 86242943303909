import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'vip-location',
  templateUrl: './location.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationComponent {
  @Input() retiradaLoja = true;
  @Input() endereco!: string;
  @Input() color: 'primary' | 'error' | 'success' | 'secondary' = 'error';
  @Input() isLoading = false;
  @Input() isLojaAutonoma !: boolean;
  @Output() enderecoClick = new EventEmitter();


  get icon() {
    return this.retiradaLoja || this.isLojaAutonoma ? 'icon-store_mall' : 'icon-location_on';
  }
}
