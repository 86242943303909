<div class="ml-3">
  <vip-base-component-skeleton class="flex flex-row" [quantity]="quantity">
    <ng-template #skeletonContent>
      <ng-container
        *vipSkeleton="
          true;
          width: '300px';
          height: '200px';
          radius: '5px';
          className: ['mr-1']
        "
      ></ng-container>
    </ng-template>
  </vip-base-component-skeleton>
</div>
