import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ClienteTokenService } from '@vip/state/cliente';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private clienteTokenService: ClienteTokenService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.clienteTokenService.getToken() == '') {
      this.router.navigate(['/login'], {
        queryParams: { redirectTo: state.url },
      });
      return false;
    } else {
      return true;
    }
  }
}
