import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardFormaPagamentoComponent } from './card-forma-pagamento/card-forma-pagamento.component';
import { SelectableCardModule } from '@vip/ui/selectable-card';
import { ImageModule } from '@vip/ui/image';

@NgModule({
  imports: [CommonModule, SelectableCardModule, ImageModule],
  declarations: [CardFormaPagamentoComponent],
  exports: [CardFormaPagamentoComponent],
})
export class CardFormaPagamentoModule {}
