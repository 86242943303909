import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import * as FiltroActions from './filtro.actions';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { FiltroApiService } from '@vip/api';
import { combineLatest } from 'rxjs';
import { fetch } from '@nrwl/angular';
import { cloneDeep } from 'lodash';
import { IFilter } from '@vip/core';

@Injectable()
export class FiltroEffects {
  abrirFiltro$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FiltroActions.abrirFiltro),
        map(() => {
          this.router.navigateByUrl('/filtro');
        })
      ),
    { dispatch: false }
  );

  getFiltroApiDepartamento$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(FiltroActions.getFiltroApiDepartamento)),
      this.cdFacade.filialECdSelecionado$,
    ]).pipe(
      map(([action, filialECdSelecionado]) => {
        return { ...action, filialECdSelecionado };
      }),
      fetch({
        run: (action) => {
          return this.filtroApiService
            .getFiltroApiDepartamento(
              action.filialECdSelecionado[0].id,
              action.filialECdSelecionado[1].id,
              action.cmId
            )
            .pipe(
              map((response) => {
                const filtros = [
                  ...action.filtrosLocais,
                  ...response.data.filtro,
                ];
                return FiltroActions.getFiltroApiDepartamentoSuccess({
                  filtros,
                });
              })
            );
        },
        onError: (action, error) => {
          return FiltroActions.getFiltroApiDepartamentoFailure({ error });
        },
      })
    );
  });

  getFiltroApiSecao$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(FiltroActions.getFiltroApiSecao)),
      this.cdFacade.filialECdSelecionado$,
    ]).pipe(
      map(([action, filialECdSelecionado]) => {
        return { ...action, filialECdSelecionado };
      }),
      fetch({
        run: (action) => {
          return this.filtroApiService
            .getFiltroApiSecao(
              action.filialECdSelecionado[0].id,
              action.filialECdSelecionado[1].id,
              action.cmId
            )
            .pipe(
              map((response) => {
                const filtros = [
                  ...action.filtrosLocais,
                  ...response.data.filtro,
                ];
                return FiltroActions.getFiltroApiSecaoSuccess({
                  filtros,
                });
              })
            );
        },
        onError: (action, error) => {
          return FiltroActions.getFiltroApiSecaoFailure({ error });
        },
      })
    );
  });

  getFiltroApiFavoritos$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(FiltroActions.getFiltroApiFavoritos)),
      this.cdFacade.filialECdSelecionado$,
    ]).pipe(
      map(([action, filialECdSelecionado]) => {
        return { ...action, filialECdSelecionado };
      }),
      fetch({
        run: (action) => {
          return this.filtroApiService
            .getFiltroApiFavoritos(
              action.filialECdSelecionado[0].id,
              action.filialECdSelecionado[1].id
            )
            .pipe(
              map((response) => {
                const filtros = [...response.data.filtro];
                return FiltroActions.getFiltroApiFavoritosSuccess({
                  filtros,
                });
              })
            );
        },
        onError: (action, error) => {
          return FiltroActions.getFiltroApiFavoritosFailure({ error });
        },
      })
    );
  });

  getFiltrosBusca$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(FiltroActions.getFiltroBusca)),
      this.cdFacade.filialECdSelecionado$,
    ]).pipe(
      map(([action, [filial, cdSelecionado]]) => {
        return { ...action, filial, cdSelecionado };
      }),
      fetch({
        run: (action) => {
          if (!action.filtros) {
            return this.filtroApiService
              .getFiltrosBusca(
                action.filial.id,
                action.cdSelecionado.id,
                action.buscaId,
                action.filtrosLocais
              )
              .pipe(
                map((response) =>
                  FiltroActions.getFiltroBuscaSuccess({
                    filtros: response.data,
                  })
                )
              );
          }

          const filtros = this.setFiltrosLocais(
            action.filtrosLocais ?? '',
            action.filtros
          );

          return FiltroActions.getFiltroBuscaSuccess({
            filtros,
          });
        },
        onError: (action, error) =>
          FiltroActions.getFiltroBuscaFailure({ error }),
      })
    )
  );

  getFiltrosColecao$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(FiltroActions.getFiltroColecao)),
      this.cdFacade.filialECdSelecionado$,
    ]).pipe(
      map(([action, [filial, cdSelecionado]]) => {
        return { ...action, filial, cdSelecionado };
      }),
      fetch({
        run: (action) =>
          this.filtroApiService
            .getFiltrosColecao(
              action.filial.id,
              action.cdSelecionado.id,
              action.colecaoId,
              action.filtrosLocais
            )
            .pipe(
              map((response) =>
                FiltroActions.getFiltroColecaoSuccess({
                  filtros: response.data,
                })
              )
            ),
        onError: (action, error) =>
          FiltroActions.getFiltroColecaoFailure({ error }),
      })
    )
  );

  getFiltroApiMinhasLista$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(FiltroActions.getFiltroMinhasListas)),
      this.cdFacade.filialECdSelecionado$,
    ]).pipe(
      map(([action, filialECdSelecionado]) => {
        return { ...action, filialECdSelecionado };
      }),
      fetch({
        run: (action) => {
          return this.filtroApiService
            .getFiltroApiMinhaLista(
              action.listaId,
              action.filialECdSelecionado[0].id,
              action.filialECdSelecionado[1].id
            )
            .pipe(
              map((response) => {
                const filtros = [...response.data.filtro];
                return FiltroActions.getFiltroMinhasListasSuccess({
                  filtros,
                });
              })
            );
        },
        onError: (action, error) => {
          return FiltroActions.getFiltroMinhasListasFailure({ error });
        },
      })
    );
  });

  getFiltrosOfertas$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(FiltroActions.getFiltrosOfertas)),
      this.cdFacade.filialECdSelecionado$,
    ]).pipe(
      map(([action, [filial, cdSelecionado]]) => {
        return { ...action, filial, cdSelecionado };
      }),
      fetch({
        run: (action) =>
          this.filtroApiService
            .getFiltrosOfertas(action.filial.id, action.cdSelecionado.id)
            .pipe(
              map((response) => {
                const filtros = [...response.data.filtro];
                return FiltroActions.getFiltrosOfertasSuccess({
                  filtros,
                });
              })
            ),
        onError: (action, error) =>
          FiltroActions.getFiltrosOfertasFailure({ error }),
      })
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private cdFacade: CentroDistribuicaoFacade,
    private filtroApiService: FiltroApiService
  ) {}

  setFiltrosLocais(filtrosLocais: string, filtroResponse: IFilter[]) {
    const filtros = cloneDeep(filtroResponse);

    const filtrosLocaisObj: { [K: string]: string } =
      filtrosLocais?.split('&').reduce((a, v) => {
        const filtroSplit = v.split('=');
        return { ...a, [filtroSplit[0]]: filtroSplit[1] };
      }, {}) || {};

    filtros.forEach((filtro) => {
      const keys = Object.keys(filtrosLocaisObj);

      filtro.options = keys.includes(filtro.name)
        ? filtro.options.map((option) => ({
            ...option,
            checked: filtro.multiple
              ? filtrosLocaisObj[filtro.name].includes(option.value.toString())
              : option.value.toString() === filtrosLocaisObj[filtro.name],
          }))
        : filtro.options;
    });

    return filtros;
  }
}
