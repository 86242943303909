import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

export type ScrollerListenerType = 'window' | 'element' | 'selector';
@Component({
  selector: 'vip-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InfiniteScrollComponent implements AfterViewInit {
  @ViewChild('scrollContainer')
  scrollContainer!: ElementRef;

  @Input() scrollListener: ScrollerListenerType = 'window';
  @Input() distance = 10;
  @Input() selector?: string;

  @Output() loadMore = new EventEmitter();

  private getElements() {
    let listenerElement;
    let calculationElement;
    switch (this.scrollListener) {
      case 'element':
        calculationElement = listenerElement =
          this.scrollContainer.nativeElement;
        break;
      case 'selector':
        calculationElement = listenerElement = document.querySelector(
          this.selector || '.vip-page-content'
        );
        break;
      case 'window':
        listenerElement = window;
        calculationElement = document.documentElement;
    }

    return { listenerElement, calculationElement };
  }

  onScroll() {
    const element = this.getElements().calculationElement;
    const pos = Math.abs(
      Math.round(
        element.scrollHeight - element.scrollTop - element.clientHeight
      )
    );

    if (pos < this.distance) {
      this.loadMore.emit();
    }
  }

  ngAfterViewInit(): void {
    this.getElements().listenerElement?.addEventListener(
      'scroll',
      this.onScroll.bind(this),
      { passive: true }
    );
  }
}
