<li
  [class.selected]="selected"
  [class.disabled]="disabled"
  [class.clickable]="clickable"
  (click)="handleClick($event)"
  vipRipple
  [rippleDisabled]="disabled"
  rippleBorderRadius="0px"
>
  <span>
    <ng-content></ng-content>
  </span>
</li>
