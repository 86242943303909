import { Injectable, Inject, PLATFORM_ID, Provider } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  TransferState,
  makeStateKey,
  StateKey,
} from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class TransferStateInterceptor implements HttpInterceptor {
  isBrowser: boolean;

  constructor(
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.method !== 'GET') {
      return next.handle(request);
    }

    const key: StateKey<string> = makeStateKey<string>(request.url);

    if (this.isBrowser) {
      const storedResponse = this.transferState.get<unknown>(key, null);
      if (storedResponse) {
        const response = new HttpResponse({
          body: storedResponse,
          status: 200,
        });
        this.transferState.remove(key);
        return of(response);
      } else {
        return next.handle(request);
      }
    } else {
      return next.handle(request).pipe(
        tap((event) => {
          this.transferState.set(key, (<HttpResponse<unknown>>event).body);
        })
      );
    }
  }
}

export const transferStateInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TransferStateInterceptor,
  multi: true,
};
