import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AtualizarCarrinhoItemRequest } from '@vip/api';
import * as CarrinhoItensActions from './carrinho-itens.actions';
import { carrinhoItensSelectors } from './carrinho-itens.selectors';
import { IProdutoCarrinho } from '@vip/core';

@Injectable()
export class CarrinhoItensFacade {
  loading$ = this.store.pipe(select(carrinhoItensSelectors.isLoading()));
  erro$ = this.store.pipe(select(carrinhoItensSelectors.getError()));
  itens$ = this.store.pipe(
    select(carrinhoItensSelectors.getAllItensCarrinho())
  );
  itensDepartamento$ = this.store.pipe(
    select(carrinhoItensSelectors.getItensCarrinhoAgrupadosPorDepartamento())
  );
  itensAsProdutoCompra$ = this.store.pipe(
    select(carrinhoItensSelectors.getItensAsProdutoCompra())
  );
  combosNoCarrinho$ = this.store.pipe(
    select(carrinhoItensSelectors.getCombosNoCarrinho())
  );
  produtosNoCarrinho$ = this.store.pipe(
    select(carrinhoItensSelectors.getProdutosNoCarrinho())
  );

  constructor(private store: Store) {}

  getItensCarrinho() {
    this.store.dispatch(CarrinhoItensActions.getItensCarrinho());
  }

  setItens(itens: IProdutoCarrinho[]) {
    this.store.dispatch(CarrinhoItensActions.setItens({ itens }));
  }

  setItemCarrinho(
    payload: AtualizarCarrinhoItemRequest,
    exibirMensagemAdicionado: boolean
  ) {
    this.store.dispatch(
      CarrinhoItensActions.setItemCarrinho({
        payload: payload,
        exibirMensagemAdicionado,
      })
    );
  }

  setListaItensCarrinho(
    payload: AtualizarCarrinhoItemRequest[],
    buscaCarrinhoAposConclusao = false
  ) {
    this.store.dispatch(
      CarrinhoItensActions.setListaItensCarrinho({
        payload,
        exibirMensagemAdicionado: false,
        buscaCarrinhoAposConclusao,
      })
    );
  }

  resetItensCarrinho() {
    this.store.dispatch(CarrinhoItensActions.resetItensCarrinho());
  }

  removerItemCarrinho(itemId: number) {
    this.store.dispatch(CarrinhoItensActions.removeItemCarrinho({ itemId }));
  }
}
