import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type OpcoesExibidas = {
  refeicao: boolean;
  credito: boolean;
  privateLabel: boolean;
};
@Component({
  selector: 'vip-card-tipo-pagamento',
  templateUrl: './card-tipo-pagamento.component.html',
  styleUrls: ['./card-tipo-pagamento.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardTipoPagamentoComponent {
  @Input()
  tipoChecked = '';
  @Input()
  showOpcoes!: OpcoesExibidas;

  @Output() tipoPagamento = new EventEmitter<string>();

  tipoPagamentoChange(tipo: string, checked: boolean) {
    this.tipoPagamento.emit(checked ? tipo : '');
    this.tipoChecked = tipo;
  }
}
