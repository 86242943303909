import { ComponentStore } from '@ngrx/component-store';
import { GenericStoreStatus, IGenericState } from './generic-state.interface';

export abstract class GenericComponentStore<T> extends ComponentStore<
  IGenericState<T>
> {
  readonly data$ = this.select(
    (stateContext: IGenericState<T>) => stateContext.data
  );
  readonly loading$ = this.select(
    (stateContext: IGenericState<T>) => stateContext.status === 'loading'
  );
  readonly error$ = this.select(
    (stateContext: IGenericState<T>) => stateContext.error
  );
  readonly loaded$ = this.select(
    (stateContext: IGenericState<T>) =>
      stateContext.status === 'success' || stateContext.status === 'error'
  );

  protected constructor(initialState: T) {
    super({
      data: initialState,
      status: 'pending',
      error: null,
    });
  }

  updateData(data: T) {
    this.patchState({ data, status: 'success' });
  }

  updateStatus(status: GenericStoreStatus) {
    this.patchState({ status });
  }

  updateError(error: string) {
    this.patchState({ error, status: 'error' });
  }
}
