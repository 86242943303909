import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ListaItensApiService } from '@vip/api';
import { LayoutUtilsService } from '@vip/core';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import * as ListaItensActions from './lista-itens.actions';
import { BucketsFacade, BucketsService } from '@vip/state/buckets';
import { CarrinhoFacade } from '@vip/state/carrinho';
import { DialogService } from '@vip/ui/modal';
import { Router } from '@angular/router';
import {
  SubstituirProdutoService,
  SubstituirProdutoActions,
} from '@vip/state/substituir-produto';
import { ListaItensFacade } from './lista-itens.facade';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';
import { MessageService } from '@vip/ui/message';
import { FiltroFacade } from '@vip/state/filtro';
import { mergeTakeOne, SelecaoCdSelectors } from '@vip/state/utils';
import { select, Store } from '@ngrx/store';
import { ListaActions } from '@vip/state/lista';

@Injectable()
export class ListaItensEffects {
  getItensLista$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(ListaItensActions.getItensLista)),
      this.cdFacade.filialECdSelecionado$,
      this.bucketFacade.bucketProduto$,
    ]).pipe(
      map(([action, [filial, cdSelecionado], bucket]) => {
        return { ...action, filial, cdSelecionado, bucket };
      }),
      fetch({
        run: (action) =>
          this.listaItensApiService
            .getItensMinhaLista(
              action.filial.id,
              action.cdSelecionado.id,
              action.listaId,
              action.departamentoId
            )
            .pipe(
              map((response) =>
                ListaItensActions.getItensListaSuccess({
                  itens: response.data.map((item) =>
                    this.bucketsService.addBucketProduto(
                      {
                        ...item.produto,
                        quantidade: item.quantidade,
                        seletor_medida_id: item.seletor_medida_id,
                      },
                      action.bucket
                    )
                  ),
                  paginator: response.paginator ?? { page: 1, total_pages: 1 },
                })
              )
            ),
        onError: (action, error) =>
          ListaItensActions.getItensListaFailure({ error }),
      })
    );
  });

  addItensListaCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListaItensActions.addItensListaCarrinho),
      withLatestFrom(
        this.cdFacade.filialECdSelecionado$,
        this.bucketFacade.bucketProduto$,
        this.store.pipe(
          select(SelecaoCdSelectors.isPermitirAtualizarItemCarrinho)
        )
      ),
      map(([action, [filial, cd], bucket, permitirAtualizarItemCarrinho]) => ({
        ...action,
        filial,
        cd,
        bucket,
        permitirAtualizarItemCarrinho,
      })),
      filter(
        ({ permitirAtualizarItemCarrinho }) => !!permitirAtualizarItemCarrinho
      ),
      fetch({
        run: (action) => {
          return this.listaItensApiService
            .adicionarItensListaNoCarrinho(
              action.filial.id,
              action.cd.id,
              action.listaId
            )
            .pipe(
              map((res) => {
                return ListaItensActions.addItensListaCarrinhoSuccess({
                  carrinho: res.data.carrinho,
                  itensAtualizados: res.data.itemAtualizado,
                  itensNaoAtualizados: res.data.itemNaoAtualizados,
                });
              })
            );
        },
        onError: (action, error) => {
          return ListaItensActions.addItensListaCarrinhoFailure({ error });
        },
      })
    )
  );

  addItensListaCarrinhoFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ListaItensActions.addItensListaCarrinhoFailure),
        tap((action) => {
          this.messageService.openErrorMessage(action.error.error.error, 1.5);
        })
      ),
    { dispatch: false }
  );

  openSubstituirProdutoComItensNaoAtualizados$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ListaItensActions.addItensListaCarrinhoSuccess),
        filter(({ itensNaoAtualizados }) => !!itensNaoAtualizados?.length),
        tap((action) => {
          this.substituirProdutoService.latestListActionSubstituirProduto =
            action;
          this.substituirProdutoService.openSubstituirProdutos(
            action.itensNaoAtualizados
          );
        })
      ),
    { dispatch: false }
  );

  showDialogItensAtualizadosSemPendencia$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ListaItensActions.addItensListaCarrinhoSuccess),
        mergeTakeOne(this.layoutUtilsService.isDesktop$),
        filter(([{ itensNaoAtualizados }]) => !itensNaoAtualizados?.length),
        tap(([, isDesktop]) => {
          this.dialogService.openDialog({
            open: true,
            title: 'Itens adicionados ao carrinho!',
            subTitle:
              'Os itens foram adicionados ao carrinho. Finalize sua compra!',
            disabled: false,
            buttonConfirmText: 'Finalizar compra',
          });

          this.dialogService.dialogClick.subscribe((value) => {
            if (value) {
              if (isDesktop) this.router.navigateByUrl('resumo-compra');
              else this.router.navigateByUrl('carrinho');
            }
            this.dialogService.clearDialog();
          });
        })
      ),
    { dispatch: false }
  );

  atualizaCarrinhoEItensDoCarrinho$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ListaItensActions.addItensListaCarrinhoSuccess),
        tap(({ itensAtualizados }) => {
          this.carrinhoFacade.getCarrinho();
          this.carrinhoItensFacade.setItens(itensAtualizados);
          this.carrinhoItensFacade.getItensCarrinho();
        })
      ),
    { dispatch: false }
  );

  nextPage$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(ListaItensActions.nextPage)),
      this.cdFacade.filialECdSelecionado$,
      this.bucketFacade.bucketProduto$,
      this.listaItensFacade.paginator$,
    ]).pipe(
      distinctUntilChanged((prev, curr) => prev[3]?.page === curr[3]?.page),
      map(([action, [filial, cdSelecionado], bucket, paginator]) => {
        return { ...action, filial, cdSelecionado, bucket, paginator };
      }),
      fetch({
        run: (action) =>
          this.listaItensApiService
            .getItensMinhaLista(
              action.filial.id,
              action.cdSelecionado.id,
              action.listaId,
              action.departamentoId,
              action.paginator.page ?? 1
            )
            .pipe(
              map((response) =>
                ListaItensActions.nextPageSuccess({
                  itens: response.data.map((item) =>
                    this.bucketsService.addBucketProduto(
                      { ...item.produto, quantidade: item.quantidade },
                      action.bucket
                    )
                  ),
                  paginator: response.paginator ?? { page: 1, total_pages: 1 },
                })
              )
            ),
        onError: (action, error) =>
          ListaItensActions.nextPageFailure({ error }),
      })
    );
  });

  editItemLista$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListaItensActions.editItemLista),
      mergeTakeOne(
        this.cdFacade.filialECdSelecionado$,
        this.bucketFacade.bucketProduto$
      ),
      map(([action, [filial, cdSelecionado], bucket]) => {
        return { ...action, filial, cdSelecionado, bucket };
      }),
      fetch({
        run: (action) =>
          this.listaItensApiService
            .atualizarItensMinhaLista(
              {
                minha_lista_id: action.listaId,
                quantidade: action.quantidade,
                produto_id: action.produto_id,
                seletor_medida_id: action.seletor_medida_id,
              },
              action.filial.id,
              action.cdSelecionado.id
            )
            .pipe(
              map((response) => {
                return ListaItensActions.editItemListaSuccess({
                  produto: this.bucketsService.addBucketProduto(
                    {
                      ...response.data.produto,
                      quantidade: response.data.quantidade,
                      seletor_medida_id: response.data.seletor_medida_id,
                    },
                    action.bucket
                  ),
                  listaId: action.listaId,
                  showMessage: action.showMessage,
                });
              })
            ),
        onError: (action, error) => {
          return ListaItensActions.editItemListaFailure({ error });
        },
      })
    )
  );

  editItemListaSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ListaItensActions.editItemListaSuccess),
        filter((action) => action.showMessage),
        tap(() => {
          return this.messageService.openSuccessMessage(
            'Item adicionado à lista!',
            1.5
          );
        })
      ),
    {
      dispatch: false,
    }
  );

  deleteItemListaSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListaItensActions.editItemListaSuccess),
      filter((action) => action.produto.quantidade == 0),
      map((action) => {
        return ListaItensActions.editItemListaDeleteSuccess({
          produto_id: action.produto.produto_id,
          listaId: action.listaId,
        });
      })
    )
  );

  atualizarFiltros$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ListaItensActions.editItemListaDeleteSuccess),
        tap((action) => {
          this.filtroFacade.getFiltroMinhasLista(action.listaId);
        })
      ),
    {
      dispatch: false,
    }
  );

  atualizarLista$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListaItensActions.editItemListaSuccess),
      map(({ listaId }) => ListaActions.getLista({ listaId }))
    )
  );

  abrirModalItensNaoSubstituirProdutos$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SubstituirProdutoActions.naoSubstituir),
        mergeTakeOne(
          this.substituirProdutoService.latestListActionSubstituirProduto$,
          this.layoutUtilsService.isDesktop$
        ),
        filter(([, action]) => {
          return (
            action !== null &&
            action.itensAtualizados !== undefined &&
            action.itensAtualizados.length > 0
          );
        }),
        tap(([, , isDesktop]) => {
          this.dialogService.openDialog({
            open: true,
            title: 'Itens adicionados ao carrinho!',
            subTitle:
              'Os itens foram adicionados ao carrinho. Finalize sua compra!',
            disabled: false,
            buttonConfirmText: 'Finalizar compra',
          });

          this.dialogService.dialogClick.subscribe((value) => {
            if (value) {
              if (isDesktop) this.router.navigateByUrl('resumo-compra');
              else this.router.navigateByUrl('carrinho');
            }
            this.dialogService.clearDialog();
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private cdFacade: CentroDistribuicaoFacade,
    private listaItensApiService: ListaItensApiService,
    private bucketFacade: BucketsFacade,
    private bucketsService: BucketsService,
    private carrinhoFacade: CarrinhoFacade,
    private carrinhoItensFacade: CarrinhoItensFacade,
    private dialogService: DialogService,
    private substituirProdutoService: SubstituirProdutoService,
    private router: Router,
    private listaItensFacade: ListaItensFacade,
    private messageService: MessageService,
    private filtroFacade: FiltroFacade,
    private store: Store,
    private layoutUtilsService: LayoutUtilsService
  ) {}
}
