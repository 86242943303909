import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FilialStateModule } from '@vip/state/filial';
import { ListaEffects } from './+state/lista.effects';
import { ListaFacade } from './+state/lista.facade';
import * as fromLista from './+state/lista.reducer';

@NgModule({
  imports: [
    CommonModule,
    FilialStateModule,
    StoreModule.forFeature(fromLista.LISTA_FEATURE_KEY, fromLista.reducer),
    EffectsModule.forFeature([ListaEffects]),
  ],
  providers: [ListaFacade],
})
export class ListaStateModule {}
