import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  thousands: '.',
  suffix: '',
};
@Component({
  selector: 'vip-modal-troco',
  templateUrl: './modal-troco.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
  ],
})
export class ModalTrocoComponent implements OnDestroy {
  @Input() totalCompra!: number;
  @Input() valorTroco!: number;

  @Output() alterarValorTrocoEvent = new EventEmitter<number>();

  form: UntypedFormGroup;

  validations = {
    required: 'Por favor, informe um valor.',
    min: 'Valor do troco tem que ser maior que o da compra.',
  };

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      troco: [0, [Validators.required]],
    });
  }

  ngOnDestroy(): void {
    if (this.valorTroco < this.totalCompra) this.setValorTrocoAsCompra();
  }

  setValidators(): void {
    this.form.controls.troco.setValidators(Validators.min(this.totalCompra));
    this.form.controls.troco.setValue(this.valorTroco);
  }

  setValorTrocoAsCompra() {
    this.form.controls.troco.setValue(this.totalCompra);
    this.valorTroco = this.totalCompra;
    this.alterarValorTrocoEvent.emit(this.valorTroco);
  }

  alterarValorTroco() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const { value: valor } = this.form.controls.troco;
      this.valorTroco = valor;
      this.alterarValorTrocoEvent.emit(valor);
    }
  }
}
