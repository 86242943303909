import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselCategoryComponent } from './carousel-category/carousel-category.component';
import { SkeletonModule } from '@vip/ui/skeleton';
import { SwiperModule } from 'swiper/angular';
import { CarouselModule } from '@vip/ui/carousel';
import { CategoriaItemModule } from '@vip/ui/categoria-item';

@NgModule({
  imports: [
    CommonModule,
    CarouselModule,
    SkeletonModule,
    CategoriaItemModule,
    SwiperModule,
  ],
  declarations: [CarouselCategoryComponent],
  exports: [CarouselCategoryComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class CarouselCategoryModule {}
