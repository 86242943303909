<div class="list-select">
  <ul class="list-none p-0">
    <ng-container *ngIf="items && items.length > 0 && searchValue.length > 2">
      <div class="flex justify-between items-center header-result">
        <h3 class="font-medium vip-color-first-default text-base">
          Resultado da busca
        </h3>

        <div>
          <vip-button type="normal" size="small" (click)="clearFilter()">
            <span
              class="vip-color-error-main font-roboto text-xs font-medium tracking-custom"
            >
              Limpar
            </span>
            <i class="icon-delete_forever text-xs vip-color-error-main"></i>
          </vip-button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="items && items.length > 0; else notFoundItems">
      <div class="max-h-48 overflow-scroll">
        <ul>
          <li
            *ngFor="let item of items"
            [ngClass]="{
              'bg-blue-100 font-bold text-red-500': item === selectedItem,
              'hover:bg-gray-100': item !== selectedItem
            }"
            class="cursor-pointer p-2 transition duration-200 flex justify-between items-center hover:font-bold hover:text-red-500 hover:bg-blue-100"
            (click)="selectItem(item)"
          >
            <span>{{ item?.nome + ' ' + item?.sobrenome }} </span>
            <span>{{ item?.cpf_formatado }}</span>
          </li>
        </ul>
      </div>
    </ng-container>

    <ng-template #notFoundItems>
      <div
        class="mt-8 mb-8"
        *ngIf="
          !loadingTelevendas &&
          searchValue &&
          searchValue.length > 2 &&
          items &&
          items.length <= 0
        "
      >
        <h2 class="text-xs sm:text-xs md:text-sm text-center">
          {{ getMessage() }}
        </h2>
      </div>
    </ng-template>

    <ng-container *ngIf="items && items.length === 0 && viewButtonRegister">
      <div class="mt-2">
        <vip-button
          class="mt-auto"
          data-cy="button-aplicar-filtro"
          type="raised"
          (click)="abrirModalValidaCEP()"
        >
          Cadastrar Cliente
        </vip-button>
      </div>
    </ng-container>
  </ul>
</div>
