import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ITipoEntrega } from '@vip/core';
import { ModalService } from '@vip/ui/modal';
import { TabGroupDesktopComponent } from '@vip/ui/tabs';
import {
  AlterarHorariosComponent,
  EntregaRetiradaPropertiesBaseDirective,
} from '@vip/views/entrega-retirada';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'vip-tabs-entrega-retirada',
  templateUrl: './tabs-entrega-retirada.component.html',
  styleUrls: ['./tabs-entrega-retirada.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsEntregaRetiradaComponent
  extends EntregaRetiradaPropertiesBaseDirective
  implements OnChanges
{
  @ViewChild(TabGroupDesktopComponent)
  tabGroupDesktopComponent!: TabGroupDesktopComponent;

  @Input()
  tiposEntrega: ITipoEntrega[] = [];

  @Input()
  changeTab$?: Observable<number>;

  @Output()
  tipoEntregaChanged = new EventEmitter<ITipoEntrega>();

  @Output()
  editarEndereco = new EventEmitter<number>();

  mySubscriptionChangeTab?: Subscription;

  constructor(private modalService: ModalService<AlterarHorariosComponent>) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('changeTab$' in changes && changes['changeTab$'].currentValue) {
      if (!this.mySubscriptionChangeTab) {
        this.mySubscriptionChangeTab = this.changeTab$
          ?.pipe(untilDestroyed(this))
          .subscribe((tipoEntrega) => {
            this.tabGroupDesktopComponent._handleClick(tipoEntrega - 1);
          });
      }
    }
  }

  openHorariosModal(): void {
    const modalRef = this.modalService.openModal(AlterarHorariosComponent, {
      bottom: false,
      showCloseButton: true,
    });
    if (modalRef) {
      this.initializeModalHorariosInfos(modalRef);

      this.modalService.closeClick
        .pipe(untilDestroyed(this), take(1))
        .subscribe(() => {
          this.modalService.clearModal();
        });
    }
  }

  getTiposEntregaOrdenados(tiposEntrega: ITipoEntrega[]) {
    return tiposEntrega.sort((a, b) => {
      if (a.alocar == b.alocar) {
        return a.descricao > b.descricao ? 1 : -1;
      } else {
        return Number(b.alocar) - Number(a.alocar);
      }
    });
  }

  onTabChange(tabIndex: number): void {
    if (tabIndex === 0) {
      this.formaEntregaChange.emit(this.formaEntregaEnum.TIPO_ENTREGA);
    } else {
      this.formaEntregaChange.emit(this.formaEntregaEnum.TIPO_RETIRADA);
    }
  }

  private initializeModalHorariosInfos(
    modalRef: ComponentRef<AlterarHorariosComponent>
  ): void {
    const { instance: modalInstance } = modalRef;
    this.horarioSelecionado$
      .pipe(untilDestroyed(this))
      .subscribe((horarioSelecionado) => {
        modalInstance.horarioSelecionado = horarioSelecionado;
      });
    modalInstance.horarioChange
      .pipe(untilDestroyed(this))
      .subscribe((horarioSelecionado) => {
        this.horarioChange.emit(horarioSelecionado);
      });
    modalInstance.confirmClick
      .pipe(untilDestroyed(this), take(1))
      .subscribe(() => {
        this.modalService.clearModal();
      });
    modalInstance.tipoEntrega = this.tipoEntregaSelecionado;
    modalInstance.isDesktop = true;
  }
}
