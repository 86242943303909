import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@vip/ui/button';
import { FieldModule } from '@vip/ui/field';
import { SimpleTextFormComponent } from './simple-text-form/simple-text-form.component';
import { FocusModule } from '@vip/ui/focus';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    FieldModule,
    FormsModule,
    ReactiveFormsModule,
    FocusModule,
  ],
  declarations: [SimpleTextFormComponent],
  exports: [SimpleTextFormComponent],
})
export class SimpleTextFormModule {}
