import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ICodigoPromocional } from '@vip/core';
import * as DescontoActions from './desconto.actions';
import { descontoSelectors } from './desconto.selectors';

@Injectable()
export class DescontoFacade {
  loading$ = this.store.pipe(select(descontoSelectors.isLoading()));
  error$ = this.store.pipe(select(descontoSelectors.getError()));
  descontos$ = this.store.pipe(select(descontoSelectors.getDescontos()));
  totalDesconto$ = this.store.pipe(
    select(descontoSelectors.getTotalDesconto())
  );
  codigoPromocional$ = this.store.pipe(
    select(descontoSelectors.getCodigoPromocional())
  );

  constructor(private store: Store) {}

  getDescontos() {
    this.store.dispatch(DescontoActions.getDescontos());
  }

  validarCodigo(codigo: string): void {
    this.store.dispatch(DescontoActions.validarCodigo({ codigo }));
  }

  removerCodigo(codigo: string): void {
    this.store.dispatch(DescontoActions.removerCodigo({ codigo }));
  }

  setarCodigo(codigoPromocional: ICodigoPromocional): void {
    this.store.dispatch(
      DescontoActions.setarSomenteCodigoPromocional({
        codigoPromocional,
      })
    );
  }

  getDescontosCompra(compraId: number) {
    this.store.dispatch(DescontoActions.getDescontosCompra({ compraId }));
  }

  resetDescontos() {
    this.store.dispatch(DescontoActions.resetDescontos());
  }
}
