import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { CardProdutoDirective } from './card-produto.directive';
import { IProduto } from '@vip/core';

@Component({
  selector: 'vip-card-produto',
  templateUrl: './card-produto.component.html',
  styleUrls: ['./card-produto.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.vertical]': '!isHorizontal',
    '[class.horizontal]': 'isHorizontal',
  },
})
export class CardProdutoComponent
  extends CardProdutoDirective
  implements OnChanges
{
  @Input()
  isHorizontal = false;

  @Input()
  showAddListButton = true;

  @Input()
  size: 'small' | 'medium' = 'small';

  @Input()
  fontSize: 'small' | 'medium' = 'medium';

  @Output()
  visible = new EventEmitter<IProduto>();
  currentUrl = this.router.url;
  @Input() hasCard = false;

  ngOnChanges(): void {
    const urlAtual = window.location.pathname;
    if (
      urlAtual.includes('/minha-conta/minhas-listas/detalhes/') ||
      urlAtual.includes('/minhas-listas/detalhes/')
    ) {
      return;
    }

    this.tipoDeMedidaSeletor();
  }
}
