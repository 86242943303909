import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromLoadingParticipantes from './+state/loading.reducer';
import { StoreModule } from '@ngrx/store';
import { LoadingFacade } from './+state/loading.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromLoadingParticipantes.LOADING_FEATURE_KEY,
      fromLoadingParticipantes.reducer
    ),
  ],
  providers: [LoadingFacade],
})
export class LoadingStateModule {}
