import {
  ICupomCredito,
  ICupomCreditoDTO,
  ICuponsIndicacao,
} from '../interfaces';
import { addHours, subHours } from 'date-fns';

const now = new Date();
const created = subHours(now, 2);
const validade = addHours(now, 1);

export const cupom1Mock = {
  created,
  validade,
  valor: 10,
  valor_desconto: 10,
  motivo: 'Cupom de Indicação',
} as ICuponsIndicacao;

export const cupomCreditoDTO1Mock: ICupomCreditoDTO = {
  created: created.toISOString(),
  validade: validade.toISOString(),
  valor: 10,
};

export const cupomCredito1Mock: ICupomCredito = {
  created,
  validade,
  valor: 10,
};

export const cupomCreditoTotalMock: ICupomCredito = {
  created,
  validade,
  valor: 10,
  total: 20,
  codigo: '10',
};
