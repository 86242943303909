import { Injectable } from '@angular/core';
import { BaseApiService } from '../common/base-api-service';
import { Observable } from 'rxjs';

import {
  AprovarTermosUsoResponse,
  GetAprovacaoPendenteResponse,
  GetTermosUsoResponse,
} from './termos-uso-api.service.types';

@Injectable({
  providedIn: 'root',
})
export class TermosUsoApiService {
  private TermosUsoUrl = `loja/termos_uso`;

  constructor(private api: BaseApiService<TermosUsoApiService>) {}

  getTermosUso(filialId: number): Observable<GetTermosUsoResponse> {
    return this.api.get<GetTermosUsoResponse>(
      'getTermosUso',
      `/filial/${filialId}/${this.TermosUsoUrl}/vigente`
    );
  }

  getAprovacaoPendente(
    filialId: number
  ): Observable<GetAprovacaoPendenteResponse> {
    return this.api.get<GetAprovacaoPendenteResponse>(
      'getAprovacaoPendente',
      `/filial/${filialId}/${this.TermosUsoUrl}/aprovacao_pendente`
    );
  }

  aprovarTermosUso(termoUsoId: number): Observable<AprovarTermosUsoResponse> {
    return this.api.post<null, AprovarTermosUsoResponse>(
      'aprovarTermosUso',
      `/${this.TermosUsoUrl}/${termoUsoId}/aprovar`,
      null
    );
  }
}
