<vip-base-component-skeleton [quantity]="1">
  <ng-template #skeletonContent>
    <div class="w-[541px] h-[500px]" data-cy="vip-opcoes-pagamento-skeleton">
      <div class="flex flex-col">
        <div class="p-3">
          <div
            *vipSkeleton="
              true;
              height: '18px';
              width: '214px';
              className: ['mb-4', 'mt-3']
            "
          ></div>
          <div
            *vipSkeleton="
              true;
              height: '14px';
              width: '251px';
              className: ['mb-2']
            "
          ></div>
          <div class="flex mb-4">
            <ng-container *ngFor="let _ of [].constructor(4)">
              <div
                *vipSkeleton="
                  true;
                  height: '25px';
                  width: '38px';
                  radius: '10px';
                  className: ['mr-3']
                "
              ></div>
            </ng-container>
          </div>
          <div class="grid gap-3 grid-cols-[repeat(2,_40%)] mb-3">
            <ng-container *ngFor="let _ of [].constructor(6)">
              <div
                *vipSkeleton="
                  true;
                  height: '50px';
                  width: '205px';
                  radius: '8px'
                "
              ></div>
            </ng-container>
          </div>
        </div>
        <hr class="vip-divider mb-1" />
        <div class="p-3">
          <div
            *vipSkeleton="
              true;
              height: '18px';
              width: '150px';
              className: ['mb-4']
            "
          ></div>
          <div class="flex mb-3">
            <div
              *vipSkeleton="
                true;
                height: '50px';
                width: '330px';
                radius: '8px';
                className: ['mr-3']
              "
            ></div>
            <div
              *vipSkeleton="true; height: '50px'; width: '72px'; radius: '8px'"
            ></div>
          </div>
          <div *vipSkeleton="true; height: '14px'; width: '295px'"></div>
        </div>
      </div>
    </div>
  </ng-template>
</vip-base-component-skeleton>
