import { ComponentRef, Injectable } from '@angular/core';
import { ComponentInjectorService } from '@vip/core';
import { VideoModalComponent } from '../video-modal.component';

@Injectable()
export class VideoPlayerService {
  public videoModalComponent!: ComponentRef<VideoModalComponent> | null;

  constructor(
    private componentInjector: ComponentInjectorService<VideoModalComponent>
  ) {}

  play(videoId: string, isFlatMode?: boolean) {
    this.videoModalComponent =
      this.componentInjector.createComponentInApplication(VideoModalComponent);
    this.videoModalComponent.instance.videoId = videoId;
    if (isFlatMode) {
      this.videoModalComponent.location.nativeElement.setAttribute(
        'class',
        'flat'
      );
    }
    this.videoModalComponent.instance.closeModal.subscribe(() => {
      this.close();
    });
  }

  close() {
    if (this.videoModalComponent) {
      this.componentInjector.destroyComponentInApplication(
        this.videoModalComponent
      );
      this.videoModalComponent = null;
    }
  }
}
