<div data-cy="carrinho_group_departamento">
  <div
    *ngIf="
      produtosAgrupados?.departamento &&
      !(produtosAgrupados.departamento === 'Combos' && !showCombos)
    "
    class="vip-background-complementary py-1 px-3 vip-color-first-default font-sm font-bold w-full"
    [ngClass]="{ 'vip-background-complementary-rounded': isDesktop }"
  >
    {{ produtosAgrupados.departamento }}
  </div>
  <div
    class="flex flex-col"
    [ngClass]="{ 'mx-3': !isDesktop }"
    *ngFor="
      let produto of produtosAgrupados?.produtos;
      let last = last;
      trackBy: trackByFnCarrinho
    "
  >
    <vip-card-produto-lista
      *ngIf="!produto.combo"
      class="my-3"
      [produto]="produto"
      [showObservacaoProduto]="
        editable && produto.permitir_observacao_na_compra
      "
      [showLayoutReceita]="showLayoutReceita"
      [showTag]="showPromotions"
      [showOferta]="showPromotions"
      [showIconDelete]="showIconDelete"
      [confirmDeletion]="confirmDeletion"
      [showSpin]="showSpin"
      [showUnidade]="true"
      [disabled]="disabled"
      (produtoChanged)="produtoChanged.emit($event)"
      (deleteItemClick)="deleteItemClick.emit($event)"
      [isDesktop]="isDesktop"
      [hiddenSeletor]="hiddenSeletor"
    >
    </vip-card-produto-lista>
    <hr *ngIf="divider && !last" />
    <vip-card-combo
      class="pt-3"
      *ngIf="showCombos && !!produto.combo"
      [combo]="getCombo(produto)"
      [editable]="showIconDelete"
      [showResume]="false"
      [combosNoCarrinho]="combosNoCarrinho"
      [disabled]="disabled"
      (produtoChanged)="produtoChanged.emit($event)"
      (deleteItemClick)="deleteItemClick.emit($event)"
      [isDesktop]="isDesktop"
      [hasProductInfo]="false"
      [comboCategory]="isTablet ? true : false"
      [hasCarrinho]="true"
      [hiddenSeletor]="hiddenSeletor"
      [ngClass]="{ 'pb-3': last }"
    >
    </vip-card-combo>
    <div *ngIf="!last && !produto.combo" class="vip-divider"></div>
  </div>
</div>
