import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OpcoesPagamentoModule } from '@vip/ui/opcoes-pagamento';
import { CardDetalhesCobrancaModule } from '@vip/ui/card-detalhes-cobranca';
import { StepperDesktopModule } from '@vip/ui/stepper-desktop';
import { PagamentoDesktopComponent } from './pagamento-desktop/pagamento-desktop.component';

@NgModule({
  imports: [
    CommonModule,
    StepperDesktopModule,
    OpcoesPagamentoModule,
    CardDetalhesCobrancaModule,
  ],
  declarations: [PagamentoDesktopComponent],
  exports: [PagamentoDesktopComponent],
})
export class PagamentoDesktopModule {}
