import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { fetch } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { IFilial } from '@vip/core';
import { ClienteApiService } from '@vip/api';
import { FilialFacade } from '@vip/state/filial';
import { MessageService } from '@vip/ui/message';
import * as EnderecoActions from './endereco.actions';
import { enderecoSelectors } from './endereco.selectors';
import { CepFacade } from '@vip/state/cep';
import { Router } from '@angular/router';
import { mergeTakeOne } from '@vip/state/utils';
import { LayoutUtilsService } from '@vip/core';
@Injectable()
export class EnderecoEffects {
  getEnderecosDoCliente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnderecoActions.getEnderecos),
      fetch({
        run: () =>
          this.clienteApiService
            .getEnderecosCliente()
            .pipe(
              map((enderecos) =>
                EnderecoActions.getEnderecosSuccess({ enderecos })
              )
            ),
        onError: (action, error) =>
          EnderecoActions.getEnderecosFailure({ error }),
      })
    )
  );

  getEnderecosEntregaDoCliente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnderecoActions.getEnderecosEntrega),
      withLatestFrom(this.filialFacade.filial$),
      fetch({
        run: (action, filial) =>
          this.clienteApiService
            .getEnderecosEntrega(filial.id)
            .pipe(
              map((enderecos) =>
                EnderecoActions.getEnderecosEntregaSuccess({ enderecos })
              )
            ),
        onError: (action, error) =>
          EnderecoActions.getEnderecosEntregaFailure({ error }),
      })
    )
  );

  addEndereco$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnderecoActions.saveEndereco),
      filter((action) => action.endereco.id == null),
      withLatestFrom(this.filialFacade.filial$),
      fetch({
        run: (action, filial: IFilial) =>
          this.clienteApiService
            .addEnderecoCliente(filial.id, action.endereco)
            .pipe(
              map((endereco) =>
                EnderecoActions.saveEnderecoSuccess({
                  endereco,
                  message: 'Endereço cadastrado com sucesso',
                  urlToRedirect: action.urlToRedirect,
                })
              )
            ),
        onError: (action, error) =>
          EnderecoActions.saveEnderecoFailure({ error }),
      })
    )
  );

  editEndereco$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnderecoActions.saveEndereco),
      filter((action) => action.endereco.id != null),
      withLatestFrom(this.filialFacade.filial$),
      fetch({
        run: (action, filial: IFilial) =>
          this.clienteApiService
            .editEnderecoCliente(filial.id, action.endereco)
            .pipe(
              map((endereco) =>
                EnderecoActions.saveEnderecoSuccess({
                  endereco,
                  message: 'Endereço atualizado com sucesso',
                  urlToRedirect: action.urlToRedirect,
                })
              )
            ),
        onError: (action, error) =>
          EnderecoActions.saveEnderecoFailure({ error }),
      })
    )
  );

  getEnderecosAfterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EnderecoActions.saveEnderecoSuccess,
        EnderecoActions.deleteEnderecoSuccess
      ),
      map(() => EnderecoActions.getEnderecos())
    )
  );

  showMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EnderecoActions.saveEnderecoSuccess,
          EnderecoActions.deleteEnderecoSuccess
        ),
        tap((action) =>
          this.messageService.openSuccessMessage(action.message, 2)
        )
      ),
    { dispatch: false }
  );

  goToRedirectUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EnderecoActions.saveEnderecoSuccess,
          EnderecoActions.deleteEnderecoSuccess
        ),
        mergeTakeOne(this.layoutUtilsService.isDesktop$),
        tap(([action, isDesktop]) => {
          if ('urlToRedirect' in action && action.urlToRedirect) {
            this.router.navigate([action.urlToRedirect], {
              queryParamsHandling: 'merge',
            });
          } else if (!isDesktop) {
            this.location.back();
          }
        })
      ),
    { dispatch: false }
  );

  deleteEndereco$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnderecoActions.deleteEndereco),
      fetch({
        run: (action) =>
          this.clienteApiService.deleteEnderecoCliente(action.enderecoId).pipe(
            map(() =>
              EnderecoActions.deleteEnderecoSuccess({
                message: 'Endereço removido',
              })
            )
          ),
        onError: (action, error) =>
          EnderecoActions.deleteEnderecoFailure({ error }),
      })
    )
  );

  autoSelectEnderecoEntrega$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EnderecoActions.getEnderecosSuccess,
          EnderecoActions.getEnderecosEntregaSuccess
        ),
        filter((action) => !!action.enderecos.length),
        withLatestFrom(
          this.store.pipe(select(enderecoSelectors.getEnderecoEntrega()))
        ),
        filter(([, enderecoEntrega]) => !enderecoEntrega),
        tap(([enderecos]) => {
          const enderecosEntrega = enderecos.enderecos;
          const enderecoPrincipal =
            enderecosEntrega.find((endereco) => endereco.principal) ||
            enderecosEntrega[0] ||
            null;
          if (enderecoPrincipal) {
            this.cepFacade.validarCepAtendidoPelaFilial(enderecoPrincipal.cep);
            this.store.dispatch(
              EnderecoActions.selectEnderecoEntrega({
                enderecoEntregaSelecionadoId: enderecoPrincipal.id,
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private clienteApiService: ClienteApiService,
    private filialFacade: FilialFacade,
    private messageService: MessageService,
    private store: Store,
    private cepFacade: CepFacade,
    private router: Router,
    private location: Location,
    private layoutUtilsService: LayoutUtilsService
  ) {}
}
