import { Pipe, PipeTransform } from '@angular/core';
import { NgModel } from '@angular/forms';

@Pipe({ name: 'validationMessages', pure: true })
export class ValidationMessagesPipe implements PipeTransform {
  transform(
    validations: NgModel | { [key: string]: boolean },
    validationsMessages: { [key: string]: string }
  ) {
    const errors =
      (validations instanceof NgModel
        ? validations.control?.errors
        : validations) || {};

    return Object.entries(errors)
      .filter((error: [string, boolean]) => error[1])
      .map((error: [string, boolean]) => validationsMessages[error[0]]);
  }
}
