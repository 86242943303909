<vip-carrinho
  class="vip-page-content"
  [ngClass]="{ 'container-height': layoutUtilsService.isDesktopResponsive() }"
  [produtosCarrinho]="(itensDepartamento$ | async) || []"
  [isDesabilitarBtn]="(isDesabilitarBtn$ | async) ?? false"
  [produtosFavoritos]="(favoritos$ | async) || []"
  [produtosMaisVendidos]="(maisVendidos$ | async) || []"
  [carrinhoVazio]="carrinhoVazio"
  [carrinho]="carrinho$ | async"
  [produtosNoCarrinho]="produtosNoCarrinho$ | async"
  [combosNoCarrinho]="combosNoCarrinho$ | async"
  [isApp]="layoutUtilsService.isMobile()"
  [isDesktop]="(layoutUtilsService.isDesktop$ | async) === true"
  [isDesktopResponsive]="layoutUtilsService.isDesktopResponsive()"
  [isLoading]="(isDesabilitarBtn$ | async) ?? false"
  (showMoreClick)="handleShowMoreClick($event)"
  (produtoChanged)="atualizarCarrinhoService.setItemCarrinho($event)"
  (resumeClick)="handleResumeClick($event)"
  (deleteCarrinhoClick)="deleteCarrinhoClick()"
  (deleteItemClick)="atualizarCarrinhoService.deleteItemCarrinho($event)"
  (addListClick)="addToListContainerService.adicionarNaLista($event)"
  [aviseMeEnviados]="produtosIndisponiveisService.aviseMeEnviados$ | async"
  (aviseMeClick)="
    produtosIndisponiveisService.sendAviseMeProdutoIndisponivel($event)
  "
  [isTablet]="(isTablet$ | async) ?? false"
></vip-carrinho>
