import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'vip-views-modal-error',
  templateUrl: './views-modal-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewsModalErrorComponent {
  @Output() confirm = new EventEmitter();
  @Output() support = new EventEmitter();

  checkConfirmButtonState() {
    this.confirm.emit();
  }

  checkSupportButtonState() {
    this.support.emit();
  }
}
