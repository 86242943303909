import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CarrinhoFacade } from '../+state/carrinho.facade';

@Injectable()
export class CarrinhoGuard implements CanActivate {
  constructor(private carrinhoFacade: CarrinhoFacade, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.carrinhoFacade.carrinho$.pipe(
      map((carrinho) => carrinho.quantidade > 0 || false),
      tap((temItensNoCarrinho) => {
        if (!temItensNoCarrinho) this.router.navigateByUrl('loja');
      })
    );
  }
}
