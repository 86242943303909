export enum GrupoVitrineEnum {
  DEDICADASPORPERFIL = 'dedicadas-por-perfil',
  DEDICADASSEMPERFIL = 'dedicadas-sem-perfil',
  DESTAQUES = 'destaques',
  FAVORITOS = 'favoritos',
  MAISVENDIDOS = 'mais-vendidos',
  NENHUM = 'nenhum',
  OFERTASEXCLUSIVAS = 'ofertas-exclusivas',
  VITRINEESPECIFICA = 'vitrine-especifica',
  PRODUTOSPATROCINADOS = 'produtos-patrocinados',
}
