import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as BucketsActions from './buckets.actions';
import { IGenericState } from '@vip/state/utils';
import { IBucket } from '@vip/core';

export const BUCKETS_FEATURE_KEY = 'buckets';

export interface BucketsState
  extends IGenericState<null>,
    EntityState<IBucket> {}

export const bucketsAdapter: EntityAdapter<IBucket> =
  createEntityAdapter<IBucket>({ selectId: (bucket) => bucket.model });

export const initialState: BucketsState = bucketsAdapter.getInitialState({
  data: null,
  status: 'pending',
  error: null,
});

const bucketsReducer = createReducer(
  initialState,
  on(BucketsActions.loadBucketsSuccess, (state, { buckets }) =>
    bucketsAdapter.setAll(buckets, { ...state, status: 'success' })
  )
);

export function reducer(state: BucketsState, action: Action) {
  return bucketsReducer(state, action);
}
