/* eslint-disable no-useless-escape */
import { Inject, Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { IFilial } from '../../interfaces';
import { IS_APP } from '../../constants';
@Injectable({
  providedIn: 'root',
})
export class HeaderUtilsService {
  constructor(@Inject(IS_APP) private isApp: boolean) {}
  now = Date.now();
  getLogo(
    bucket$: Observable<string | undefined>,
    filial$: Observable<IFilial>
  ): Observable<string> {
    return combineLatest([bucket$, filial$]).pipe(
      map(([s3, filial]) => {
        if (this.isApp) {
          return `${s3}/files/tema/filial-${filial.id}/logo-home-omni.png?${this.now}`;
        } else {
          return `${s3}/files/tema/filial-${filial.id}/header-site-omni.png?${this.now}`;
        }
      })
    );
  }

  getLogoFallback(bucket$: Observable<string | undefined>): Observable<string> {
    return bucket$.pipe(
      map((s3) => {
        if (this.isApp) {
          return `${s3}/files/tema/logo-home-omni.png?${this.now}`;
        } else {
          return `${s3}/files/tema/header-site-omni.png?${this.now}`;
        }
      })
    );
  }
}
