import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { environment, IPaginaInstitucional } from '@vip/core';

@Component({
  selector: 'vip-lista-paginas',
  templateUrl: './lista-paginas.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListaPaginasComponent {
  environment = environment;

  @Input() paginas!: IPaginaInstitucional[];

  @Output() cardClick = new EventEmitter();
}
