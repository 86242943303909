import { ClienteActions } from '@vip/state/cliente';
import { TelevendasWsService } from '@vip/ws';
import { Inject, Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { TelevendasApiService } from '@vip/api';
import * as TelevendasActions from './televendas.actions';
import { filter, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { STORAGE, TelevendasTokenService } from '@vip/core';
import { TelevendasFacade } from './televendas.facade';
@Injectable()
export class TelevendasEffects {
  logar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TelevendasActions.logarTelevendas),
      fetch({
        run: ({ id, password }) => {
          return this.televendasApiService.logar(id, password).pipe(
            map((response) => {
              return TelevendasActions.logarTelevendasSuccess({ ...response });
            })
          );
        },
        onError: (_, error) => {
          return TelevendasActions.logarTelevendasFailure({ error });
        },
      })
    )
  );

  saveInStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TelevendasActions.logarTelevendasSuccess),
        tap(({ token, operador }) => {
          this.televendasTokenService.setToken(token);
          this.televendasTokenService.setOperador(operador);
        })
      ),
    {
      dispatch: false,
    }
  );

  redirectToHome$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TelevendasActions.logarTelevendasSuccess,
          TelevendasActions.logarTelevendasFailure
        ),
        tap(() => {
          this.router.navigateByUrl('/');
        })
      ),
    {
      dispatch: false,
    }
  );

  encerrarSessao$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TelevendasActions.encerrarSessao),
        tap(() => {
          this.storage.clear();
          window.close();
          this.router.navigate(['/']);
          window.location.reload();
        })
      ),
    {
      dispatch: false,
    }
  );

  getFilialTelevendas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TelevendasActions.getFiliaisTelevendas),

      fetch({
        run: (action) =>
          this.televendasApiService.getFilialTelevendas().pipe(
            map((response) => {
              if (response.success) {
                return TelevendasActions.getFiliaisTelevendasSuccess({
                  filiais: response.data,
                });
              }
              return TelevendasActions.getFiliaisTelevendasFailure({
                error: { error: response.error || '' } as HttpErrorResponse,
              });
            })
          ),
        onError: (action, error) =>
          TelevendasActions.getFiliaisTelevendasFailure({ error }),
      })
    )
  );

  logoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClienteActions.logoutSuccess),
      filter(() => this.televendasFacade.isTelevendas),
      map(() => TelevendasActions.getFiliaisTelevendas())
    )
  );

  getClienteTelevendas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TelevendasActions.getClientesTelevendas),
      fetch({
        run: ({ term }) =>
          this.televendasWsService.listarClientesTelevendas(term).pipe(
            map((response) => {
              return TelevendasActions.getClientesTelevendaSuccess({
                clientes: response['clientes'],
                paging: response.paging,
              });
            })
          ),

        onError: (action, error) => {
          return TelevendasActions.getClientesTelevendasFailure({ error });
        },
      })
    )
  );

  logarClienteTelevenda$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TelevendasActions.logarClienteTelevendas),
      fetch({
        run: ({ cliente_id, filial_id }) => {
          return this.televendasApiService
            .logarTelevendas(cliente_id, filial_id)
            .pipe(
              map((response) => {
                return ClienteActions.loginSuccess({
                  token: response.toString(),
                });
              })
            );
        },
        onError: (_, error) => {
          return TelevendasActions.logarTelevendasFailure({ error });
        },
      })
    )
  );

  vincularFilial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TelevendasActions.vincularFilialTelevendas),
      fetch({
        run: ({ id, filial_id }) => {
          return this.televendasWsService.vincularFilial(id, filial_id).pipe(
            map((response) => {
              return TelevendasActions.vincularFilialTelevendasSuccess({
                ...response,
              });
            })
          );
        },
        onError: (_, error) => {
          return TelevendasActions.vincularFilialTelevendasFailure({ error });
        },
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private televendasApiService: TelevendasApiService,
    private televendasTokenService: TelevendasTokenService,
    private televendasWsService: TelevendasWsService,
    private router: Router,
    private televendasFacade: TelevendasFacade,
    @Inject(STORAGE) readonly storage: Storage
  ) {}
}
