<vip-seletor-de-peso
  *ngIf="
    (!hiddenSeletor &&
      possuiUnidadeDiferente &&
      quantity > min &&
      seletorDePesoHabilitado) ||
    (hasDetailProduct && possuiUnidadeDiferente && seletorDePesoHabilitado)
  "
  (unidadeSelecionada)="unidadeSelecionada()"
  (pesoSelecionado)="pesoSelecionado()"
  [hasDetailProduct]="hasDetailProduct"
  [defaultSelection]="defaultSelection"
  [miniCard]="miniCard"
  [isTablet]="isTablet"
></vip-seletor-de-peso>

<div
  class="mb-1 w-full items-center flex justify-center"
  *ngIf="
    volumePrincipal &&
    volumePrincipal.quantidade &&
    quantity >= 1 &&
    !seletorDePesoHabilitado
  "
>
  <vip-button
    class="btn-qtd-volume w-full"
    [ngClass]="{
      'font-medium': size !== 'small',
      'font-bold': size === 'small'
    }"
    [ariaControls]="ariaControls"
    type="normal"
    [size]="size"
    [disabled]="(disableOnMax && quantity >= max) || disabled"
    (click)="handleAddVolumeClick($event)"
  >
    <span
      [ngClass]="{ 'vip-color-first-default font-bold': size === 'small' }"
      data-cy="qtd-volume-text"
    >
      + {{ volumePrincipal.quantidade }}
      {{ unityMeasureAbbreviation.toLowerCase() }}.
    </span>
  </vip-button>
</div>

<section
  class="vip-spin-wrapper flex"
  [class.flex-col-reverse]="size === 'small'"
>
  <div
    class="vip-spin vip-spin--{{ size }} rounded-full"
    [ngClass]="{
      'pointer-events-none': disabled,
      'vip-spin--full': large,
      'vip-spin--opened': quantity > min,
      'vip-spin--no-border': isDesktop && large && !squared,
      'vip-spin--custom': styleSpin,
      'h-[44px]': hasCombo,
      'items-center': hasCombo && quantity > min
    }"
  >
    <div
      class="vip-spin__content"
      [class.opacity-0]="quantity <= min"
      [class.invisible]="quantity <= min"
    >
      <div
        class="vip-spin__slider"
        [class.vip-spin__slider--closed]="quantity <= min"
      >
        <vip-button-icon-rounded
          *ngIf="!large || (large && isDesktop && !squared)"
          class="btn-decrement"
          [class.desktop]="isDesktop"
          [size]="size"
          [type]="large && isDesktop ? 'raised' : 'normal'"
          [disabled]="quantity <= min || disabled"
          [tabindex]="focusable ? 0 : -1"
          [ariaControls]="ariaControls"
          (click)="handleDecrementClick($event)"
          (keydown)="decrementKeyboard($event)"
        >
          <i
            [attr.aria-controls]="ariaControls || ''"
            [class]="iconDecrement"
          ></i>
        </vip-button-icon-rounded>
        <vip-button
          *ngIf="(large && !isDesktop) || squared"
          class="btn-decrement"
          [size]="size"
          type="normal"
          [ariaControls]="ariaControls"
          [disabled]="quantity <= min || disabled"
          (click)="handleDecrementClick($event)"
        >
          <i
            [attr.aria-controls]="ariaControls || ''"
            [class]="iconDecrement"
          ></i>
        </vip-button>

        <ng-container
          *ngIf="
            (possuiUnidadeDiferente &&
              quantity > min &&
              seletorDePesoHabilitado) ||
              (hasDetailProduct &&
                possuiUnidadeDiferente &&
                seletorDePesoHabilitado);
            else semSeletorPeso
          "
        >
          <input
            #quantityInput
            data-cy="spin-quantity"
            class="vip-spin__quantity text-center focus:outline-none"
            [class.vip-color-disabled]="disabled"
            type="text"
            aria-label="Quantidade do produto"
            [value]="
              !!quantityUnity
                ? quantity < min
                  ? min
                  : seletorPeso
                  ? (quantityEvent * quantityUnity
                    | unidadeDiferente: unityMeasureAbbreviation:true)
                  : quantityEvent
                : quantityEvent
            "
            [readonly]="naoPodeEditar"
            (blur)="onBlur($event)"
            (keydown)="onKeyDown($event)"
            (click)="checkUnidadeMedida()"
            (input)="filterInput($event)"
          />
        </ng-container>

        <ng-template #semSeletorPeso>
          <input
            #quantityInput
            data-cy="spin-quantity"
            class="vip-spin__quantity text-center focus:outline-none"
            [class.vip-color-disabled]="disabled"
            type="text"
            aria-label="Quantidade do produto"
            [value]="
              !!quantityUnity &&
              (tipoDeMedida === SELETOR_DE_PESO_ENUM['PESO'] || !tipoDeMedida)
                ? quantity < min
                  ? min
                  : (quantityEvent * quantityUnity
                    | unidadeDiferente: unityMeasureAbbreviation:true)
                : quantityEvent
            "
            [readonly]="naoPodeEditar"
            (blur)="onBlur($event)"
            (keydown)="onKeyDown($event)"
            (click)="checkUnidadeMedida()"
            (input)="filterInput($event)"
          />
        </ng-template>
      </div>
    </div>

    <vip-button-icon-rounded
      *ngIf="
        !large || (large && isDesktop && !squared && quantity > min);
        else largeTemplate
      "
      class="vip-spin__increment"
      [class.btn-increment]="quantity > min && !(large && isDesktop)"
      [class.desktop]="isDesktop"
      [disabled]="(disableOnMax && quantity >= max) || disabled"
      [size]="size"
      [type]="quantity > min && !(large && isDesktop) ? 'normal' : 'raised'"
      [tabindex]="focusable ? 0 : -1"
      (click)="increment($event)"
      (keydown)="incrementKeyboard($event)"
      [ariaControls]="ariaControls"
    >
      <i
        [attr.aria-controls]="ariaControls || ''"
        [class]="quantity <= min ? addIcon : 'icon-add'"
      ></i>
    </vip-button-icon-rounded>

    <ng-template #largeTemplate>
      <vip-button
        *ngIf="quantity <= min"
        class="vip-spin__increment vip-spin__increment--full"
        [size]="size"
        [disabled]="disabled"
        [tabindex]="focusable ? 0 : -1"
        [ariaControls]="ariaControls"
        (click)="increment($event)"
        (keydown)="incrementKeyboard($event)"
      >
        <i
          *ngIf="addIcon && quantity <= min"
          [class]="addIcon"
          data-cy="vip-add-icon-large-button"
        ></i>
        <span [ngClass]="{ 'text-[16px]': hasCombo }">{{ addLabel }}</span>
      </vip-button>
      <vip-button
        *ngIf="quantity > min"
        class="vip-spin__increment btn-increment"
        [ariaControls]="ariaControls"
        type="normal"
        [disabled]="(disableOnMax && quantity >= max) || disabled"
        [size]="size"
        (click)="increment($event)"
      >
        <i [attr.aria-controls]="ariaControls || ''" class="icon-add"></i>
      </vip-button>
    </ng-template>
  </div>
</section>
