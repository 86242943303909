import { Directive } from '@angular/core';
import { FilterTypeEnum, IFilter, IFilterOptions } from '@vip/core';
import { FiltroFacade } from '@vip/state/filtro';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export abstract class FiltroDirective {
  filtroOrdenacao: IFilter;
  filtroQueryParam$ = new BehaviorSubject('');

  filtrosAndOrdenacao$ = new BehaviorSubject<IFilter[]>([]);
  ordenacao$ = new BehaviorSubject<IFilterOptions & { isDefault?: boolean }>({
    label: 'A - Z',
    value: 'produto.descricao:asc',
    checked: true,
    isDefault: true,
  });

  constructor(public filtroFacade: FiltroFacade) {
    this.filtroOrdenacao = { ...this.filtroFacade.filtroOrdenacao };
    this.filtroOrdenacao.type = FilterTypeEnum.EXTERNAL;
  }

  setFiltros(filterName?: string) {
    combineLatest([this.ordenacao$, this.filtroFacade.filtroAtivo$])
      .pipe(
        map(([ordenacaoSelecionada, filtros]) => {
          const filtroOrdenacao: IFilter = JSON.parse(
            JSON.stringify(this.filtroOrdenacao)
          );
          if (filterName === 'busca' && ordenacaoSelecionada.isDefault) {
            ordenacaoSelecionada.checked = false;
          }
          filtroOrdenacao.options = [
            ...filtroOrdenacao.options.map((ordenacao) => {
              ordenacao.checked =
                ordenacaoSelecionada.checked &&
                ordenacao.value === ordenacaoSelecionada.value;
              return Object.assign({}, ordenacao);
            }),
          ];
          return [
            ...filtros.filter((filtro) => filtro.name !== 'orderby'),
            filtroOrdenacao,
          ];
        }),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        ),
        untilDestroyed(this)
      )
      .subscribe((filtros) => {
        this.filtrosAndOrdenacao$.next(filtros);
        this.filtroQueryParam$.next(this.filtroFacade.getQueryParams(filtros));
      });
  }

  externalFilterClick(value: IFilterOptions) {
    this.ordenacao$.next(value);
  }
}
