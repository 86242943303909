<vip-home-ecommerce
  class="vip-page-content"
  [logo]="(logoBusca$ | async) || ''"
  [fallbackImageUrl]="(logoBuscaFallback$ | async) || ''"
  [retiradaLoja]="(isRetirada$ | async) ?? true"
  [isLojaAutonoma]="(isLojaAutonoma$ | async) || false"
  [endereco]="(endereco$ | async) || ''"
  [firstCarousel]="firstCarousel$ | async"
  [secondCarousel]="secondCarousel$ | async"
  [categoriaItens]="(categoriaItens$ | async) || []"
  [favoritos]="(favoritos$ | async) || []"
  [maisVendidos]="(maisVendidos$ | async) || []"
  [produtosPatrocinados]="(produtosPatrocinados$ | async) || []"
  [vitrinesDedicadas]="(vitrinesDedicadas$ | async) || []"
  [vitrinesDedicadasPerfil]="(vitrinesDedicadasPerfil$ | async) || []"
  [ofertas]="(ofertas$ | async) || []"
  [ofertasExclusivas]="(ofertasExclusivas$ | async) || []"
  [aviseMeEnviados]="produtosIndisponiveisService.aviseMeEnviados$ | async"
  [produtosNoCarrinho]="produtosNoCarrinho$ | async"
  [isLoading]="(isInitialLoading$ | async) ?? true"
  [isMobile]="isMobile"
  [isApp]="isApp"
  [isDesktop]="isDesktop"
  (categoriaItemClick)="handleCategoriaItemClick($event)"
  (searchClick)="handleSearchClick()"
  (showMoreClick)="handleShowMoreClick($event)"
  (alterarEnderecoClick)="handleEnderecoClick()"
  (produtoChanged)="atualizarCarrinhoService.setItemCarrinho($event)"
  (aviseMeClick)="
    produtosIndisponiveisService.sendAviseMeProdutoIndisponivel($event)
  "
  (addListClick)="addToListContainerService.adicionarNaLista($event)"
  [adsValue]="adsParamValue"
></vip-home-ecommerce>
