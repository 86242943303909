import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { fetch } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { AplicativoApiService, FilialAplicativoApiService } from '@vip/api';
import {
  CacheIdsEnum,
  environment,
  IAplicativo,
  IS_APP,
  IStorage,
  NetworkService,
  STORAGE,
} from '@vip/core';
import { FilialFacade } from '@vip/state/filial';
import * as AplicativoActions from './aplicativo.actions';
import { ThemeService } from '@vip/ui/theme-provider';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { AppUpdateService } from '@vip/native/app-update';
import { HttpErrorResponse } from '@angular/common/http';
import { addDays } from 'date-fns';

@Injectable()
export class AplicativoEffects {
  isBrowser: boolean;

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AplicativoActions.init),
      fetch({
        run: () => {
          if (environment.idAplicativo) {
            return this.aplicativoApiService
              .getAplicativo(environment.idAplicativo)
              .pipe(
                map((aplicativo) => {
                  if (aplicativo.success) {
                    this.storage.setItem(
                      CacheIdsEnum.APLICATIVO_ID,
                      JSON.stringify({
                        value: {
                          ...aplicativo.data.aplicativo,
                          fromCache: true,
                        },
                        timestamp: addDays(new Date(), 1),
                      })
                    );
                    return AplicativoActions.loadAplicativoSuccess({
                      aplicativo: aplicativo.data.aplicativo,
                    });
                  } else {
                    return this.verificarAplicativoStorage({
                      status: 200,
                      error: aplicativo.error || '',
                    } as HttpErrorResponse);
                  }
                })
              );
          } else {
            const error = new HttpErrorResponse({
              status: 404,
              error: 'ID do aplicativo não encontrado.',
            });
            return AplicativoActions.loadAplicativoFailure(error);
          }
        },

        onError: (action, error) => this.verificarAplicativoStorage(error),
      })
    )
  );

  FromVipcommerceFilialId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AplicativoActions.initFromVipcommerceFilialId),
      fetch({
        run: (action) =>
          this.aplicativoApiService
            .getAplicativoFromVipcommerceFilialId(action.filialId)
            .pipe(
              map((aplicativo) => {
                if (aplicativo.success) {
                  if (this.isApp) {
                    this.storage.setItem(
                      CacheIdsEnum.APLICATIVO_ID,
                      JSON.stringify({
                        value: {
                          ...aplicativo.data.aplicativo,
                          fromCache: true,
                        },
                        timestamp: addDays(new Date(), 1),
                      })
                    );
                  } else if (this.isBrowser) {
                    localStorage.setItem(
                      CacheIdsEnum['APLICATIVO_ID'],
                      JSON.stringify({
                        value: {
                          ...aplicativo.data.aplicativo,
                          fromCache: true,
                        },
                        timestamp: addDays(new Date(), 1),
                      })
                    );
                  }
                  return AplicativoActions.loadAplicativoSuccess({
                    aplicativo: aplicativo.data.aplicativo,
                  });
                } else {
                  return this.verificarAplicativoStorage({
                    status: 200,
                    error: aplicativo.error || '',
                  } as HttpErrorResponse);
                }
              })
            ),

        onError: (action, error) => this.verificarAplicativoStorage(error),
      })
    )
  );

  appComUmaFilialGetFilial$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AplicativoActions.loadAplicativoSuccess),
        filter((aplicativo) => aplicativo.aplicativo.filiais.length === 1),
        tap((action) =>
          this.filialFacade.getFilialPorVipCommerceFilialId(
            action.aplicativo.filiais[0].id
          )
        )
      ),
    { dispatch: false }
  );

  setTheme$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AplicativoActions.loadAplicativoSuccess),
        tap((action) => {
          if (this.isBrowser) {
            this.themeService.setupTheme(action.aplicativo.tema);
          }
        })
      ),
    { dispatch: false }
  );

  verificarAtualizacaoObrigatoriaDisponivel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AplicativoActions.loadAplicativoSuccess),
        tap(async (action) => {
          const versaoMinima = this.appUpdateService.getVersaoMinimaPlataforma(
            action.aplicativo
          );
          if (versaoMinima) {
            const possuiVersaoDisponivel =
              await this.appUpdateService.possuiVersaoObrigatoriaDisponivel(
                versaoMinima
              );
            if (possuiVersaoDisponivel) {
              this.exibirDialogAtualizacaoObrigatoria();
            }
          }
        })
      ),
    { dispatch: false }
  );

  redirectManutencaoOnFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AplicativoActions.loadAplicativoFailure),
        tap(async (action) => {
          const noConnection = await this.networkService.isNotConnection();

          if (noConnection) {
            this.route.navigateByUrl('sem-conexao');
          } else {
            this.route.navigateByUrl('manutencao', {
              state: { rota: 'app', error: action.error.status },
            });
          }
        })
      ),
    { dispatch: false }
  );

  setThemeDefault$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AplicativoActions.loadAplicativoFailure),
        tap(() => {
          if (this.isBrowser) {
            this.themeService.setupTheme();
          }
        })
      ),
    { dispatch: false }
  );

  getStoreConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AplicativoActions.getStoreConfig),
      withLatestFrom(this.filialFacade.filial$),
      fetch({
        run: (action, filial) =>
          this.filialAplicativoApiService.getStoreConfig(filial.id).pipe(
            map((storeConfig) =>
              AplicativoActions.getStoreConfigSuccess({
                storeConfig,
              })
            )
          ),

        onError: (action, error) => {
          return AplicativoActions.getStoreConfigFailure({ error });
        },
      })
    )
  );

  exibirDialogAtualizacaoObrigatoria() {
    this.appUpdateService.openDialog();
  }

  verificarAplicativoStorage(error: HttpErrorResponse) {
    let storageData;
    if (this.isApp) {
      storageData = this.storage.getItem(CacheIdsEnum.APLICATIVO_ID);
    } else if (this.isBrowser) {
      storageData = localStorage.getItem(CacheIdsEnum.APLICATIVO_ID);
    }
    const aplicativoStorage = storageData
      ? (JSON.parse(storageData) as {
          value: IAplicativo;
          timestamp: Date;
        })
      : null;
    if (aplicativoStorage) {
      return AplicativoActions.loadAplicativoSuccess({
        aplicativo: aplicativoStorage.value,
      });
    } else {
      return AplicativoActions.loadAplicativoFailure({
        error,
      });
    }
  }

  constructor(
    private actions$: Actions,
    private aplicativoApiService: AplicativoApiService,
    private filialAplicativoApiService: FilialAplicativoApiService,
    private filialFacade: FilialFacade,
    private themeService: ThemeService,
    private route: Router,
    private networkService: NetworkService,
    private appUpdateService: AppUpdateService,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    @Inject(STORAGE) readonly storage: IStorage,
    @Inject(IS_APP) private isApp: boolean
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }
}
