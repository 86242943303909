import { createAction, props } from '@ngrx/store';
import { IParametros } from '@vip/core';

export const loadParametrosSuccess = createAction(
  '[Filial/API] Load Parametros Success',
  props<{ parametros: IParametros }>()
);

export const setTentativasRealizadas = createAction(
  '[Filial Page] Set Tentativas Realizadas',
  props<{ tentativas: number }>()
);
