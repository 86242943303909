import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';

import { IFilial } from '@vip/core';
import * as FilialActions from './filial.actions';
import { filialSelectors } from './filial.selectors';

@Injectable()
export class FilialFacade {
  filial$ = this.store.pipe(
    select(filialSelectors.getData()),
    filter((filial): filial is IFilial => filial !== null),
    tap((filial) => {
      this.filial = filial;
    })
  );
  isLoaded$ = this.store.pipe(select(filialSelectors.isLoaded()));
  cdPadraoId$ = this.store.pipe(select(filialSelectors.getCdPadraoId()));
  permitirSalvarCartao$ = this.store.pipe(
    select(filialSelectors.permitirSalvarCartao())
  );
  exibePrecoOriginal$ = this.store.pipe(
    select(filialSelectors.exibePrecoOriginal())
  );
  permitirBandeiraAutomatica$ = this.store.pipe(
    select(filialSelectors.permitirBandeiraAutomatica())
  );
  error$ = this.store.pipe(select(filialSelectors.getError()));
  hasError$ = this.store.pipe(select(filialSelectors.hasError()));
  scripts$ = this.store.pipe(select(filialSelectors.getScripts()));

  filial!: IFilial;

  constructor(private store: Store) {}

  getFilialPorVipCommerceFilialId(vipCommerceFilialId: number) {
    this.store.dispatch(
      FilialActions.getFilialPorVipCommerceFilialId({ vipCommerceFilialId })
    );
  }

  getScripts(filialId: number) {
    this.store.dispatch(FilialActions.getFilialScripts({ filialId }));
  }
}
