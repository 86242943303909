import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { PerguntasApiService } from '@vip/api';
import { FilialFacade } from '@vip/state/filial';
import * as PerguntasActions from './perguntas.actions';
import { MessageService } from '@vip/ui/message';
import { select, Store } from '@ngrx/store';
import { perguntasSelectors } from './perguntas.selectors';
import { LayoutUtilsService } from '@vip/core';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';

@Injectable()
export class PerguntasEffects {
  getPerguntas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PerguntasActions.getPerguntas),
      withLatestFrom(this.cdFacade.cd$, this.filialFacade.filial$),
      map(([action, cd, filial]) => {
        return { ...action, cd, filial };
      }),
      fetch({
        run: (action) =>
          this.perguntasApiService
            .getPerguntas(action.filial.id, action.cd && action.cd.id)
            .pipe(
              map((res) =>
                PerguntasActions.getPerguntasSuccess({
                  perguntas: res.data,
                })
              )
            ),
        onError: (action, error) =>
          PerguntasActions.getPerguntasFailure({ error }),
      })
    )
  );

  responderPerguntas$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PerguntasActions.responderPerguntas,
          PerguntasActions.salvarRespostasResumoSuccess
        ),
        tap(() => {
          this.messageService.openSuccessMessage('Respostas salvas', 1.5);
        })
      ),
    { dispatch: false }
  );

  salvarRespostas$ = createEffect(() =>
    this.actions$.pipe(ofType(PerguntasActions.salvarRespostas)).pipe(
      withLatestFrom(
        this.filialFacade.filial$,
        this.store.pipe(select(perguntasSelectors.getRespostas()))
      ),
      map(([action, filial, respostas]) => ({
        ...action,
        filial,
        respostas,
      })),
      filter((action) => !!action.respostas.length),
      fetch({
        run: (action) =>
          this.perguntasApiService
            .salvarRespostas(
              action.filial.id,
              action.respostas.map((resposta) => ({
                ...resposta,
                compra_id: action.compraId,
              }))
            )
            .pipe(
              map((res) =>
                PerguntasActions.salvarRespostasSuccess({
                  success: res.data,
                })
              )
            ),
        onError: (action, error) =>
          PerguntasActions.salvarRespostasFailure({ error }),
      })
    )
  );

  salvarPerguntasResumo$ = createEffect(() =>
    this.actions$.pipe(ofType(PerguntasActions.responderPerguntasResumo)).pipe(
      withLatestFrom(
        this.filialFacade.filial$,
        this.store.pipe(select(perguntasSelectors.getRespostasResumo()))
      ),
      map(([action, filial, respostas]) => ({
        ...action,
        filial,
        respostas,
      })),
      filter((action) => !!action.respostas.length),
      fetch({
        run: (action) => {
          return this.perguntasApiService
            .salvarRespostas(
              action.filial.id,
              action.respostas.map((resposta) => ({
                ...resposta,
                compra_id: action.idCompra,
              }))
            )
            .pipe(
              map((res) => {
                return PerguntasActions.salvarRespostasResumoSuccess({
                  success: res.data,
                });
              })
            );
        },

        onError: (action, error) =>
          PerguntasActions.salvarRespostasResumoFailure({ error }),
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private filialFacade: FilialFacade,

    private messageService: MessageService,
    private perguntasApiService: PerguntasApiService,
    private store: Store,
    private layoutUtilsService: LayoutUtilsService,
    private cdFacade: CentroDistribuicaoFacade
  ) {}
}
