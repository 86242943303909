<vip-button
  *ngIf="!isLoading"
  [disabled]="disabled"
  [type]="type"
  [size]="size"
  [hasIcon]="hasIcon"
  [ariaControls]="ariaControls"
  (btnClick)="handleClick($event)"
>
  <ng-content></ng-content>
</vip-button>
<ng-container
  *vipSkeleton="isLoading; width: '20px'; className: ['p-3']"
></ng-container>
