import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ISwitchOption } from '@vip/core';
@Component({
  selector: 'vip-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchComponent {
  @Input() firstOption!: ISwitchOption;
  @Input() secondOption!: ISwitchOption;
  @Input() thirdOption?: ISwitchOption;
  @Input() optionChecked: ISwitchOption = this.firstOption;

  @Output() changedItemActive = new EventEmitter();

  get thirdOptionIsEnable(): boolean {
    return !!this.thirdOption && !this.thirdOption.disabled;
  }

  checkOption(option: ISwitchOption): void {
    this.optionChecked = option;
    this.changedItemActive.emit(option);
  }
}
