import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TabItemBaseDirective } from '../base/tab-item-base.directive';

@Component({
  selector: 'vip-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabItemComponent extends TabItemBaseDirective {
  @Input()
  isDesktop = false;
}
