<ng-container *ngIf="!isLoading; else cardsTemplate">
  <ng-container *ngIf="isMobile; else swiperCarousel">
    <vip-carousel-card size="xs" data-cy="carousel_categorias">
      <vip-categoria-item
        card
        *ngFor="let item of categoriaItens"
        [attr.data-cy]="
          'carousel_card_item_' + item.classificacao_mercadologica_id
        "
        [item]="item"
        (categoriaItemClick)="categoriaItemClick.emit($event)"
      ></vip-categoria-item>
    </vip-carousel-card>
  </ng-container>
  <ng-template #swiperCarousel>
    <swiper [config]="swiperConfig" [navigation]="true">
      <ng-template
        swiperSlide
        *ngFor="let item of categoriaItens; trackBy: trackByFn"
      >
        <vip-categoria-item
          class="cursor-pointer"
          [attr.data-cy]="
            'carousel_card_item_' + item.classificacao_mercadologica_id
          "
          [item]="item"
          (categoriaItemClick)="categoriaItemClick.emit($event)"
        ></vip-categoria-item>
      </ng-template>
    </swiper>
  </ng-template>
</ng-container>

<ng-template #cardsTemplate>
  <vip-categoria-item-skeleton [quantity]="5"></vip-categoria-item-skeleton>
</ng-template>
