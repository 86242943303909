import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ICepAtendido } from '@vip/core';

export const resetCepStore = createAction('[Cep Page] Reset Cep Store');

export const validarCepAtendidoPelaFilial = createAction(
  '[Cep Page] Validar Cep Atendido Pela Filial',
  props<{ cep: string }>()
);

export const validarCepAtendidoPelaFilialSuccess = createAction(
  '[Cep/API] Validar Cep Atendido Pela Filial Success',
  props<{ cepAtendido: ICepAtendido }>()
);

export const validarCepAtendidoPelaFilialFailure = createAction(
  '[Cep/API] Validar Cep Atendido Pela Filial Failure',
  props<{ error: HttpErrorResponse }>()
);

export const addSolicitacaoCep = createAction(
  '[ValidarCep/Page] Adicionar solicitação CEP',
  props<{ cep: string; email: string }>()
);

export const addSolicitacaoCepSuccess = createAction(
  '[ValidarCep/API] Adicionar solicitação CEP Success',
  props<{ solicitacaoDeCepEnviada: boolean }>()
);

export const addSolicitacaoCepFailure = createAction(
  '[ValidarCep/API] Adicionar solicitação CEP Failure',
  props<{ error: HttpErrorResponse }>()
);
