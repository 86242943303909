<div class="vip-carousel-product-wrapper" [class.desktop]="!isMobile">
  <vip-carousel-header
    [title]="title"
    [showAll]="isProductCardHorizontal ? false : showAll"
    [quantity]="getTotalProducts()"
    [isLoading]="isLoading"
    [applySideMargins]="isMobile"
    [isMobile]="isMobile"
    [isHomeOmni]="isHomeOmni"
    (showMoreClick)="showMoreClick.emit($event)"
  ></vip-carousel-header>

  <ng-container *ngIf="!isLoading; else skeletonTemplate">
    <ng-container *ngIf="isMobile; else swiperCarousel">
      <vip-carousel-card
        [size]="isProductCardHorizontal ? 'lg' : 'md'"
        [showMoreBtnLabel]="isProductCardHorizontal ? '' : showMoreBtnLabel"
        (showMoreClick)="showMoreClick.emit($event)"
      >
        <vip-card-produto
          card
          *ngFor="
            let produto of produtos | slice: 0:qtdLimit;
            trackBy: trackByFn
          "
          [attr.data-cy]="'carousel_card_produto_' + produto.id"
          [produto]="produto"
          [showAddListButton]="showAddListButton"
          [defaultImageUrl]="defaultImageUrl"
          [showUnavailableMessage]="
            aviseMeEnviados?.get(produto.produto_id) || false
          "
          [produtosNoCarrinho]="produtosNoCarrinho"
          [isHorizontal]="isProductCardHorizontal"
          (addListClick)="addListClick.emit($event)"
          (getClickAds)="campanhaPatrocinadoService.clique(produto)"
          (visible)="campanhaPatrocinadoService.visualizacao(produto)"
          (produtoChanged)="produtoChanged.emit($event)"
          (aviseMeClick)="aviseMeClick.emit($event)"
          [adsValueParams]="valueAds"
        ></vip-card-produto>
      </vip-carousel-card>
    </ng-container>
    <ng-template #swiperCarousel>
      <swiper
        slidesPerView="auto"
        [spaceBetween]="20"
        [allowTouchMove]="false"
        [navigation]="true"
        direction="horizontal"
        [slidesOffsetAfter]="15"
        [slidesOffsetBefore]="0"
      >
        <ng-template
          swiperSlide
          *ngFor="let produto of produtos; trackBy: trackByFn"
        >
          <vip-card-produto
            [attr.data-cy]="'carousel_card_produto_' + produto.id"
            [produto]="produto"
            [defaultImageUrl]="defaultImageUrl"
            [showUnavailableMessage]="
              aviseMeEnviados?.get(produto.produto_id) || false
            "
            observeVisibility
            [debounceTime]="200"
            [showAddListButton]="showAddListButton"
            [produtosNoCarrinho]="produtosNoCarrinho"
            (addListClick)="addListClick.emit($event)"
            (produtoChanged)="produtoChanged.emit($event)"
            (aviseMeClick)="aviseMeClick.emit($event)"
            size="medium"
            (getClickAds)="campanhaPatrocinadoService.clique(produto)"
            [adsValueParams]="valueAds"
            (visible)="campanhaPatrocinadoService.visualizacao(produto)"
          ></vip-card-produto>
        </ng-template>
      </swiper>
    </ng-template>
  </ng-container>

  <ng-template #skeletonTemplate>
    <vip-card-produto-skeleton
      [quantity]="5"
      size="medium"
    ></vip-card-produto-skeleton>
  </ng-template>
  <hr *ngIf="showDivider" class="vip-divider mx-3 my-4" />
</div>
