import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { CheckboxBaseDirective } from './checkbox-base.directive';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'vip-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent extends CheckboxBaseDirective {
  @ViewChild('checkboxLabel', { static: true }) checkboxLabel:
    | ElementRef
    | undefined;

  constructor(private changeDetector: ChangeDetectorRef) {
    super();
  }

  focus(): void {
    if (this.checkboxLabel && !this.disabled) {
      this.checkboxLabel.nativeElement.focus();
    }
  }

  protected changeModelValue(value: boolean) {
    this.checkboxValue = value;
    this.changeDetector.detectChanges();
  }
}
