import {
  CARRINHO_ITENS_FEATURE_KEY,
  carrinhoItensAdapter,
  CarrinhoItensState,
} from './carrinho-itens.reducer';
import { SelectorUtil } from '@vip/state/utils';
import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import {
  IProdutoCarrinho,
  IProdutoCombo,
  IProdutoCompra,
  IProdutosCarrinhoGroup,
} from '@vip/core';

const { selectAll, selectEntities } = carrinhoItensAdapter.getSelectors();

export class CarrinhoItensSelectors extends SelectorUtil<CarrinhoItensState> {
  constructor() {
    super(CARRINHO_ITENS_FEATURE_KEY);
  }

  getProdutoCarrinho = (produtoId: number) =>
    createSelector(this.getProdutosNoCarrinho(), (produtos) =>
      produtos.get(produtoId)
    );

  getAllItensCarrinho = () =>
    createSelector(this.createFeatureSelector, (state: CarrinhoItensState) =>
      selectEntities(state)
    );

  getCarrinhoArray = () =>
    createSelector(this.createFeatureSelector, (state: CarrinhoItensState) =>
      selectAll(state)
    );

  getCombosNoCarrinho = () =>
    createSelector(this.getCarrinhoArray(), (itens) =>
      this.mapMercadoriasDoCarrinho(itens, true)
    );

  getProdutosNoCarrinho = () =>
    createSelector(this.getCarrinhoArray(), (itens) =>
      this.mapMercadoriasDoCarrinho(itens, false)
    );

  getItensCarrinhoAgrupadosPorDepartamento = () =>
    createSelector(this.getCarrinhoArray(), (state) => {
      const itens = _.groupBy(
        state,
        (item) => item?.departamento?.descricao || 'Combos'
      );

      return Object.entries(itens)
        .map(
          ([key, value]) =>
            ({ departamento: key, produtos: value } as IProdutosCarrinhoGroup)
        )
        .sort((item) => (item.departamento == 'Combos' ? -1 : 0));
    });

  getItensAsProdutoCompra = () =>
    createSelector(this.getCarrinhoArray(), (itens) => {
      return itens.map(this.convertProdutoCarrinhoToProdutoCompra);
    });

  getItemPendenteCarrinho = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CarrinhoItensState) => state.itemPendenteCarrinho
    );

  mapMercadoriasDoCarrinho(itens: IProdutoCarrinho[], eCombo: boolean) {
    const mercadorias = new Map<
      number,
      {
        item_id: number;
        quantidade: number;
        observacao?: string;
        seletor_medida_id: number | undefined | null;
      }
    >();
    itens?.forEach((item: IProdutoCarrinho) => {
      if (!!item.combo === eCombo)
        mercadorias.set(item.mercadoria_id, {
          item_id: item.item_id,
          quantidade: item.quantidade,
          observacao: item.combo
            ? this.observacoesItensComboToString(item.combo)
            : item.observacao,
          seletor_medida_id: item.seletor_medida_id,
        });
    });
    return mercadorias;
  }

  observacoesItensComboToString(combo: IProdutoCombo): string {
    return JSON.stringify(
      combo.produtos.reduce((observacoes: Record<string, string>, item) => {
        observacoes[item.produto_id] = item.observacao || '';
        return observacoes;
      }, {})
    );
  }

  convertProdutoCarrinhoToProdutoCompra(
    produtoCarrinho: IProdutoCarrinho & { produto_id: number }
  ): IProdutoCompra {
    return {
      produto_id: produtoCarrinho.produto_id,
      quantidade: produtoCarrinho.quantidade,
      preco_venda: produtoCarrinho.preco,
      id: produtoCarrinho.produto_id,
      descricao: produtoCarrinho.descricao,
      preco: produtoCarrinho.preco,
      possui_unidade_diferente: produtoCarrinho.possui_unidade_diferente,
      quantidade_unidade_diferente:
        produtoCarrinho.quantidade_unidade_diferente,
      disponivel: produtoCarrinho.disponivel,
      link: produtoCarrinho.link,
      imagem: produtoCarrinho.imagem,
      unidade_sigla: produtoCarrinho.unidade_sigla,
    };
  }
}

export const carrinhoItensSelectors = new CarrinhoItensSelectors();
