import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  IProduto,
  IsOfertaComDescontoSimplesPipe,
  ProdutoUtilsService,
  TipoOfertaEnum,
} from '@vip/core';
import {
  PrecoValorTamanhoEnum,
  TamanhoProdutoPrecoEnum,
} from '../produto-preco-tamanho.enum';

@UntilDestroy()
@Component({
  selector: 'vip-produto-preco',
  templateUrl: './produto-preco.component.html',
  styleUrls: ['./produto-preco.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProdutoPrecoComponent {
  @Input() produto!: IProduto;
  @Input() tamanho: TamanhoProdutoPrecoEnum = TamanhoProdutoPrecoEnum.GRANDE;
  @Input() tamanhoMargens = 3;
  @Input() faixasParaMostrar = 3;
  @Input() isDesktop = false;
  @Input() combo = false;
  @Input() isTablet = false;
  @Input() hasCard = false;
  @Input() hasZoom = false;
  @Input() hasCombo = false;

  PrecoValorTamanho = PrecoValorTamanhoEnum;
  TamanhoProdutoPreco = TamanhoProdutoPrecoEnum;

  constructor(
    public produtoUtilsService: ProdutoUtilsService,
    private isOfertaComDescontoSimplesPipe: IsOfertaComDescontoSimplesPipe
  ) {}

  get isInlineTag() {
    if (!this.produto?.oferta) return false;
    const tipoOferta = this.produto.oferta.tipo_oferta_id;
    const faixasPrecos = this.produto.oferta.faixas_precos?.length;

    return tipoOferta === TipoOfertaEnum.OFERTA_ATACAREJO && faixasPrecos !== 1;
  }

  get semOfertaOuOfertaDescontoSimples() {
    return (
      !this.produto.em_oferta ||
      this.isOfertaComDescontoSimplesPipe.transform(this.produto.oferta)
    );
  }
}
