import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationComponent } from './location/location.component';
import { SkeletonModule } from '@vip/ui/skeleton';

@NgModule({
  imports: [CommonModule, SkeletonModule],
  declarations: [LocationComponent],
  exports: [LocationComponent],
})
export class LocationModule {}
