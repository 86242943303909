import { ICompraPagamento } from './../interfaces/compra.interface';
import {
  produtoCompraBrinde,
  produtoCompraDepartamento1Mock,
  produtoCompraDepartamento2Mock,
  produtoCompraDepartamento3Mock,
  produtoCompraDepartamento4Mock,
} from './produto-compra.mock';
import {
  tipoEntrega1Mock,
  tipoEntregaCompraGratisMock,
  tipoEntregaCompraMock,
} from './tipo-entrega.mock';
import { endereco1Mock, enderecoCompraMock } from './endereco.mock';
import { CompraStatusEnum } from '../enums/compra-status.enum';
import { formatRFC3339, subDays, subMinutes } from 'date-fns';
import {
  ICompra,
  ICompraDTO,
  IProdutoCarrinho,
  IProdutoCompra,
} from '../interfaces';
import { horarioLabelSemDia21_01 } from './tipo-entrega-horarios.mock';
import {
  formaPagamentoCartaoMock,
  formaPagamentoCarteiraDigitalMock,
  formaPagamentoDinheiroMock,
} from './forma-pagamento.mock';
import { FormaPagamentoEnum, TipoDispositivoEnum } from '../enums';
import { cd2Mock } from './cd.mock';

const now = new Date();
const twoDaysBefore = subDays(now, 2);
const oneMinuteBefore = subMinutes(now, 1);

const nowSTR = formatRFC3339(now, { fractionDigits: 3 });
const twoDaysBeforeSTR = formatRFC3339(twoDaysBefore, { fractionDigits: 3 });

export const produtoCompraMock: IProdutoCompra = {
  produto_id: 19351,
  id: 19351,
  preco: 29.98,
  preco_venda: 29.98,
  unidade_sigla: 'UN',
  descricao: 'Pão De Queijo F Minas 1kg Tradicional, Vs, Sp',
  link: 'Pão De Queijo F Minas 1kg Tradicional, Vs, Sp',
  quantidade: 1,
  imagem: 'https://picsum.photos/100',
  disponivel: true,
  quantidade_unidade_diferente: 1,
  possui_unidade_diferente: false,
};

export const pagamentoCompraCartaoMock: ICompraPagamento = {
  id: 54,
  parcelas: 1,
  valor: 95,
  cc_number: '111111xxxxxx1111',
  juros: 0,
  carteira_digital: false,
  forma_pagamento: {
    ...formaPagamentoCartaoMock,
    logo: 'https://s3.amazonaws.com/vipcommerce-org-2/files/forma_pagamentos/visa.png',
  },
};

export const pagamentoCompraDinheiroMock: ICompraPagamento = {
  id: 54,
  valor: 5,
  cc_number: null,
  juros: 0,
  carteira_digital: false,
  forma_pagamento: {
    ...formaPagamentoDinheiroMock,
    logo: 'https://s3.amazonaws.com/vipcommerce-org-2/files/forma_pagamentos/visa.png',
  },
  parcelas: 0,
};

export const compraDevolucaoEmSeparacaoMock: ICompra = {
  id: 1001162,
  created: twoDaysBefore,
  data_entrega: now,
  data_entregue: null,
  compra_status_id: CompraStatusEnum.EM_SEPARACAO,
  status_cliente: 'Em Separação',
  efetuar_cancelamento: false,
  efetuar_devolucao: false,
  efetuar_troca: false,
  frete: 4.5,
  valor_descontos: 0,
  valor_inicial: 184.57,
  valor_final: 34.48,
  comprar_novamente: true,
  tipo_compra: 'D',
  produtos: [produtoCompraMock],
  total_itens: 1,
  compra_pagamentos: [],
  compra_descontos: [],
  endereco: enderecoCompraMock,
  tipo_entrega: {
    ...tipoEntregaCompraGratisMock,
    id: 1,
  },
  centro_distribuicao: {
    endereco: {
      ...cd2Mock.endereco,
    },
  },
  data_entrega_formatada: 'Qua, 15 de Dezembro de 2021 entre 08:00 e 22:00',
  centro_distribuicao_id: 1,
  filial_id: 1,
  taxa_de_servico: 0,
  valor_itens_adicionados: 0,
  valor_itens_removidos: 0,
  valor_alteracoes: 0,
};

export const compraEmProcessoSemFrete: ICompra = {
  ...compraDevolucaoEmSeparacaoMock,
  id: 1000163,
  created: twoDaysBefore,
  data_entrega: new Date('2020-06-18T00:00:00+00:00'),
  data_entregue: now,
  compra_status_id: CompraStatusEnum.EM_PROCESSO_DE_COMPRA,
  status_cliente: 'Em Processo',
  efetuar_troca: true,
  valor_final: 134.48,
  tipo_compra: 'N',
  taxa_de_servico: 10,
  produtos: [produtoCompraMock],
};

export const compraEmAtendimentoMock: ICompra = {
  ...compraDevolucaoEmSeparacaoMock,
  id: 1000161,
  compra_status_id: CompraStatusEnum.EM_ATENDIMENTO,
  status_cliente: 'Em Atendimento',
  tipo_compra: 'N',
  frete: 0,
  produtos: [produtoCompraMock],
};

export const compraEmAtendimentoCanceladaMock: ICompra = {
  ...compraDevolucaoEmSeparacaoMock,
  id: 1000161,
  compra_status_id: CompraStatusEnum.CANCELADA,
  status_cliente: 'Cancelado',
  tipo_compra: 'N',
  frete: 0,
  produtos: [produtoCompraMock],
};

export const produtosDaCompraParaCarrinhoGenericoMock = {
  bebida_alcoolica: false,
  classificacao_mercadologica_id: 0,
  codigo_barras: '',
  em_oferta: false,
  exibe_preco_original: false,
  oferta: null,
  permitir_observacao_na_compra: false,
  possui_unidade_diferente: false,
  preco_original: 0,
  quantidade_maxima: 0,
  quantidade_minima: 0,
  quantidade_vendida: 0,
  classe_oferta: '',
  descricao_oferta: '',
  mercadoria_id: 0,
  observacao: '',
  tipo: 0,
  brinde: false,
  volume_principal: null,
};
const produtosDaCompraEmAtendimentoMock: IProdutoCompra[] = [
  {
    id: 1,
    produto_id: 51410,
    preco: 12.49,
    preco_venda: 12.49,
    unidade_sigla: 'UN',
    descricao: 'Requeijão Vida Veg 180g Ervas Finas Vegano, Vs, Sp',
    link: 'Requeijão Vida Veg 180g Ervas Finas Vegano, Vs, Sp',
    quantidade: 1,
    imagem: 'https://picsum.photos/100',
    disponivel: true,
    possui_unidade_diferente: false,
    quantidade_unidade_diferente: 1,
    brinde: false,
  },
  {
    id: 2,
    produto_id: 18426,
    preco: 8.65,
    preco_venda: 8.65,
    unidade_sigla: 'UN',
    descricao: 'Pão Nutrivida 400g Integral Vegano Milho, Vs',
    link: 'Pão Nutrivida 400g Integral Vegano Milho, Vs',
    quantidade: 1,
    imagem: 'https://picsum.photos/100',
    disponivel: true,
    possui_unidade_diferente: false,
    quantidade_unidade_diferente: 1,
    brinde: false,
  },
  {
    id: 3,
    produto_id: 7114,
    preco: 79.9,
    preco_venda: 79.9,
    descricao: 'Vodka Absolut 1l Original',
    link: 'Vodka Absolut 1l Original',
    imagem: 'https://picsum.photos/100',
    possui_unidade_diferente: false,
    quantidade: 1,
    unidade_sigla: 'UN',
    quantidade_unidade_diferente: 1,
    disponivel: true,
    brinde: false,
  },
];
export const produtosDaCompraEmAtendimentoMapeadosParaProdutoCarrinhoMock: IProdutoCarrinho[] =
  [
    {
      ...produtosDaCompraEmAtendimentoMock[0],
      ...produtosDaCompraParaCarrinhoGenericoMock,
      item_id: produtosDaCompraEmAtendimentoMock[0].produto_id,
      preco_antigo: produtosDaCompraEmAtendimentoMock[0].preco,
    },
    {
      ...produtosDaCompraEmAtendimentoMock[1],
      ...produtosDaCompraParaCarrinhoGenericoMock,
      item_id: produtosDaCompraEmAtendimentoMock[1].produto_id,
      preco_antigo: produtosDaCompraEmAtendimentoMock[1].preco,
    },
    {
      ...produtosDaCompraEmAtendimentoMock[2],
      ...produtosDaCompraParaCarrinhoGenericoMock,
      item_id: produtosDaCompraEmAtendimentoMock[2].produto_id,
      preco_antigo: produtosDaCompraEmAtendimentoMock[2].preco,
    },
  ];

export const compraEmAtendimentoVariosProdutosMock: ICompra = {
  ...compraDevolucaoEmSeparacaoMock,
  id: 1000161,
  compra_status_id: CompraStatusEnum.EM_ATENDIMENTO,
  status_cliente: 'Em Atendimento',
  efetuar_cancelamento: true,
  efetuar_devolucao: true,
  frete: 0,
  valor_descontos: 16,
  valor_final: 184.57,
  tipo_compra: 'N',
  produtos: produtosDaCompraEmAtendimentoMock,
  compra_pagamentos: [
    {
      parcelas: 2,
      valor: 168.57,
      juros: 0,
      cc_number: '111111xxxxxx1111',
      carteira_digital: false,
      forma_pagamento: {
        id: 2,
        bandeira: 'Master',
        descricao: 'Master Cr\u00e9dito',
        mensagem:
          '\u003Cp\u003E\u003Cspan style=\u0022line-height:20.7999992370605px\u0022\u003EO pagamento com Cart\u0026atilde;o D\u0026eacute;bito dever\u0026aacute; ser\u0026nbsp;realizado no momento da entrega.\u003C/span\u003E\u003C/p\u003E\r\n',
        logo: 'https://picsum.photos/100',
        parcelas_sem_juros: 1,
        parcelas_proprias: 0,
        juros: 0,
        carteira_digital: false,
        pagamento: FormaPagamentoEnum.PRE_PAGO,
      },
    },
  ],
  compra_descontos: [
    {
      valor: 16,
      string: 'Desconto aplicado pelo site mobile',
      motivo: 1,
    },
  ],
};

export const compraEmSeparacaoMock: ICompra = {
  ...compraDevolucaoEmSeparacaoMock,
  id: 1000162,
  tipo_compra: 'N',
  frete: 0,
  produtos: [produtoCompraMock],
};

export const compraEmEntregaComTrackMock: ICompra = {
  ...compraDevolucaoEmSeparacaoMock,
  id: 1000165,
  tipo_compra: 'N',
  compra_status_id: CompraStatusEnum.EM_ENTREGA,
  track: 'https://link.com/link',
  produtos: [produtoCompraMock],
};

export const compraEmEmpacotamentoMock: ICompra = {
  ...compraDevolucaoEmSeparacaoMock,
  id: 1000163,
  compra_status_id: CompraStatusEnum.EM_EMPACOTAMENTO,
  tipo_compra: 'N',
  produtos: [produtoCompraMock],
};

export const compraConcluidaMock = {
  ...compraEmProcessoSemFrete,
  compra_status_id: CompraStatusEnum.FINALIZADA,
  status_cliente: 'Finalizada',
  total_itens: 87,
  compra_pagamentos: [pagamentoCompraCartaoMock, pagamentoCompraDinheiroMock],
};

export const compraConcluidaMock2 = {
  ...compraEmProcessoSemFrete,
  compra_status_id: CompraStatusEnum.FINALIZADA,
  status_cliente: 'Finalizada',
  total_itens: 87,
  compra_pagamentos: [pagamentoCompraCartaoMock],
};

export const compraEmProcessoMock: ICompra = {
  ...compraEmProcessoSemFrete,
  data_entrega: now,
  tipo_entrega: {
    ...tipoEntregaCompraMock,
    horario_selecionado: horarioLabelSemDia21_01,
    desconto: 0,
  },
  portador: {
    cc_cvv: '864',
    cc_expire_month: '03',
    cc_expire_year: '2055',
    installments: 1,
    cpf_portador: '414.779.560-93',
    cc_holder: 'RAQUEL S FREITAS',
    cc_number: '5584 4425 9477 2524',
    criar_token: false,
  },
};

export const compraEmProcessoPortadorMock: ICompra = {
  ...compraEmProcessoSemFrete,
  id: 1000164,
  portador: {
    cc_cvv: '864',
    cc_expire_month: '03',
    cc_expire_year: '2055',
    installments: 1,
    cpf_portador: '414.779.560-93',
    cc_holder: 'RAQUEL S FREITAS',
    cc_number: '5584 4425 9477 2524',
    criar_token: false,
  },
};

export const compraEmAtendimentoDTOMock = {
  ...compraEmAtendimentoMock,
  created: twoDaysBeforeSTR,
  data_entrega: nowSTR,
} as ICompraDTO;

export const compraEmSeparacaoDTOMock = {
  ...compraEmSeparacaoMock,
  created: twoDaysBeforeSTR,
  data_entrega: nowSTR,
} as ICompraDTO;

export const compraEmEmpacotamentoDTO = {
  ...compraEmEmpacotamentoMock,
  created: twoDaysBeforeSTR,
  data_entrega: nowSTR,
} as ICompraDTO;

export const compraConclidaDTOMock = {
  ...compraConcluidaMock,
  created: twoDaysBeforeSTR,
  data_entrega: nowSTR,
  data_entregue: nowSTR,
} as ICompraDTO;

export const compraEmProcessoDTOMock = {
  ...compraEmProcessoMock,
  created: twoDaysBeforeSTR,
  data_entrega: nowSTR,
  data_entregue: nowSTR,
} as ICompraDTO;

export const compraTrocaEmSeparacaoMock = {
  ...compraEmProcessoSemFrete,
  id: 1000162,
  data_entrega: now,
  data_entregue: null,
  compra_status_id: CompraStatusEnum.EM_SEPARACAO,
  status_cliente: 'Em Separação',
  efetuar_troca: false,
  valor_final: 34.48,
  tipo_compra: 'T',
};

export const compraPagamentoMock: ICompra = {
  ...compraDevolucaoEmSeparacaoMock,
  compra_pagamentos: [pagamentoCompraCartaoMock, pagamentoCompraDinheiroMock],
};

export const compraPagamentoCartaoMock: ICompra = {
  ...compraDevolucaoEmSeparacaoMock,
  compra_pagamentos: [
    {
      id: 54,
      parcelas: 1,
      valor: 95,
      cc_number: '111111xxxxxx1111',
      juros: 0,
      carteira_digital: false,
      forma_pagamento: {
        ...formaPagamentoCartaoMock,
        logo: 'https://picsum.photos/100',
      },
    },
  ],
  endereco: endereco1Mock,
  tipo_entrega: tipoEntregaCompraMock,
};

export const compraPagamentoDinheiroMock: ICompra = {
  ...compraDevolucaoEmSeparacaoMock,
  compra_pagamentos: [
    {
      id: 54,
      parcelas: 0,
      valor: 40,
      cc_number: null,
      juros: 0,
      carteira_digital: false,
      forma_pagamento: {
        ...formaPagamentoDinheiroMock,
        logo: 'https://picsum.photos/100',
      },
    },
  ],
  endereco: endereco1Mock,
  tipo_entrega: tipoEntregaCompraMock,
};

export const compraEmProcessoPixMock: ICompra = {
  ...compraEmProcessoSemFrete,
  compra_pagamentos: [
    {
      id: 54,
      parcelas: 0,
      valor: 137.37,
      cc_number: null,
      juros: 0,
      carteira_digital: true,
      forma_pagamento: {
        ...formaPagamentoCarteiraDigitalMock,
        logo: 'https://picsum.photos/100',
      },
      created: oneMinuteBefore,
      qr_code:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA4EAAAOBAQMAAAB1ZDePAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAGUExURQAAAP///6XZn90AAAAJcEhZcwAADsMAAA7DAcdvqGQAAASmSURBVHja7d07ctswEABQaFyo9BF8FB9NOlqO4iOoVOERkiG5woKilVhGXCRvm9DC5yHdzi4olY041MvG4xTPtf4opV7j1+i+1rc24aXWUp5iuPxhEIlEIpFIJBIfFJ9qjreF2detOK3W7mo9T0xaHvHa4XmUSCQSiUQikThQjI32TZzj0GbHxNecFO5i9NiW1/reif3mRCKRSCQSicRvEV+WjVJ8uhJIJBKJRCKRSPxusUaBj0gkEolEIpH4STHFOpebK28ds12X27Xxvi7Xb04kEolEIpFIHCnexKElhdNjpIzv3WOc57x+jJSxa8beDyKRSCQSiUTiQ2L9IM4LXjeTwohd9+7Due04ixtxJBKJRCKRSCQOE6d4nv6IbYJJ2WPE6a44r0lHSxnnKohEIpFIJBKJA8T4MEV0aOOy3qk72udu6KWIiUQikUgkEonEvyDGUHR161K3S+lfLx7zRh+KsZxIJBKJRCKROF6srcC3r9eIi3elyy1P7SWKnol4a0dL8SNf5SMSiUQikUgkjhJjPDVeD78XI+51dUtLM4lEIpFIJBL/HzFF+vClMRF9XW7M77ESiUQikUgkEh8X5/HUBu2+k+RGnGLODw+bYkTKR1/7iUQikUgkEonEUWJ8WGNJ972/ly5l3KgEpon720pgvMd6nXgkEolEIpFIJA4Tu3cfUvZYhnd165I9EolEIpFIJBJHielXGiImJup2uxFd3dvaI5FIJBKJRCJxgJiyxykTLEsrN/Ddbcq4JaaMk0gkEolEIpH4XWJ3Q6+08l9ZCnyXdp73ZXVttb6+Engu1/9Df7RVH5lIJBKJRCKROEac/jkt45E9zrleXYn1a13d+BYUIpFIJBKJxH9VXL/HWlsuV1q5LWJYV5dIJBKJRCKROOT2Wm1iz7y0Hmt/tFhzvD3lhvi8OhqRSCQSiUQicZTYPkxxFTcqgYH3KWNEVAL7NHPzlyaIRCKRSCQSiV8V1+PTRrsOH95HJhKJRCKRSCSOvaE3bZSu5UWBL1UCyzop/ERXt7b/DpFIJBKJRCJxlDiNn5YP09sWZcn1bsTaVt/5Da+0ZoodkUgkEolEInG8GBusk8Ky4NdIR1tvVLtzdJXAKDOuK4FEIpFIJBKJxAFixEb2GI+DurpzEIlEIpFIJBJHin0lMMp/Mb4b09W9lBREIpFIJBKJ/6YYg9N3kuy71mltuV4kcB+K3RfR/cENPSKRSCQSiUTiKHHOD7saWzymutx7Wz0/HvLEGmW9tGPgUZcjEolEIpFIJA4Vu9h4j+HylR7rxsQjkUgkEolEInGYWLciNWsf6erO8ZpPmSYSiUQikUgkEkeJGxEFvrKIden53ojr3/CaH5PYXf+rRCKRSCQSicTh4vU+XXzY3bYrm5XAp5ZbflgJTNljTCxEIpFIJBKJxOFi1Pr2Tdwv84d0dSe89ZGJRCKRSCQSiX9RvK5+uKvbT4zXciuRSCQSiUTi/yimHmsvdrElJqabWH6TyxGJRCKRSCQSHxJTrOty10h33xqwi9FjO/D093YBbwkikUgkEolE4ijxJg4t13usx3pv4pFIJBKJRCKROEasx58oxZnUSwAU3QAAAABJRU5ErkJggg==',
      qr_code_text:
        '000201465165850014br.gov.bcb.pix2563qrcodepix.bb.com.br/pix/v2/vfvddv-e4d5-5454-9393-96beedf3aeba520405467575454.457GNJG78962070503***6304FFAAB',
    },
  ],
};

export const compraEmProcessoPixTempoEsgotadoMock: ICompra = {
  ...compraEmProcessoSemFrete,
  compra_pagamentos: [
    {
      id: 54,
      parcelas: 0,
      valor: 137.37,
      cc_number: null,
      juros: 0,
      carteira_digital: true,
      forma_pagamento: {
        ...formaPagamentoCarteiraDigitalMock,
        logo: 'https://picsum.photos/100',
      },
      created: twoDaysBefore,
      qr_code:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA4EAAAOBAQMAAAB1ZDePAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAGUExURQAAAP///6XZn90AAAAJcEhZcwAADsMAAA7DAcdvqGQAAASmSURBVHja7d07ctswEABQaFyo9BF8FB9NOlqO4iOoVOERkiG5woKilVhGXCRvm9DC5yHdzi4olY041MvG4xTPtf4opV7j1+i+1rc24aXWUp5iuPxhEIlEIpFIJBIfFJ9qjreF2detOK3W7mo9T0xaHvHa4XmUSCQSiUQikThQjI32TZzj0GbHxNecFO5i9NiW1/reif3mRCKRSCQSicRvEV+WjVJ8uhJIJBKJRCKRSPxusUaBj0gkEolEIpH4STHFOpebK28ds12X27Xxvi7Xb04kEolEIpFIHCnexKElhdNjpIzv3WOc57x+jJSxa8beDyKRSCQSiUTiQ2L9IM4LXjeTwohd9+7Due04ixtxJBKJRCKRSCQOE6d4nv6IbYJJ2WPE6a44r0lHSxnnKohEIpFIJBKJA8T4MEV0aOOy3qk72udu6KWIiUQikUgkEonEvyDGUHR161K3S+lfLx7zRh+KsZxIJBKJRCKROF6srcC3r9eIi3elyy1P7SWKnol4a0dL8SNf5SMSiUQikUgkjhJjPDVeD78XI+51dUtLM4lEIpFIJBL/HzFF+vClMRF9XW7M77ESiUQikUgkEh8X5/HUBu2+k+RGnGLODw+bYkTKR1/7iUQikUgkEonEUWJ8WGNJ972/ly5l3KgEpon720pgvMd6nXgkEolEIpFIJA4Tu3cfUvZYhnd165I9EolEIpFIJBJHielXGiImJup2uxFd3dvaI5FIJBKJRCJxgJiyxykTLEsrN/Ddbcq4JaaMk0gkEolEIpH4XWJ3Q6+08l9ZCnyXdp73ZXVttb6+Engu1/9Df7RVH5lIJBKJRCKROEac/jkt45E9zrleXYn1a13d+BYUIpFIJBKJxH9VXL/HWlsuV1q5LWJYV5dIJBKJRCKROOT2Wm1iz7y0Hmt/tFhzvD3lhvi8OhqRSCQSiUQicZTYPkxxFTcqgYH3KWNEVAL7NHPzlyaIRCKRSCQSiV8V1+PTRrsOH95HJhKJRCKRSCSOvaE3bZSu5UWBL1UCyzop/ERXt7b/DpFIJBKJRCJxlDiNn5YP09sWZcn1bsTaVt/5Da+0ZoodkUgkEolEInG8GBusk8Ky4NdIR1tvVLtzdJXAKDOuK4FEIpFIJBKJxAFixEb2GI+DurpzEIlEIpFIJBJHin0lMMp/Mb4b09W9lBREIpFIJBKJ/6YYg9N3kuy71mltuV4kcB+K3RfR/cENPSKRSCQSiUTiKHHOD7saWzymutx7Wz0/HvLEGmW9tGPgUZcjEolEIpFIJA4Vu9h4j+HylR7rxsQjkUgkEolEInGYWLciNWsf6erO8ZpPmSYSiUQikUgkEkeJGxEFvrKIden53ojr3/CaH5PYXf+rRCKRSCQSicTh4vU+XXzY3bYrm5XAp5ZbflgJTNljTCxEIpFIJBKJxOFi1Pr2Tdwv84d0dSe89ZGJRCKRSCQSiX9RvK5+uKvbT4zXciuRSCQSiUTi/yimHmsvdrElJqabWH6TyxGJRCKRSCQSHxJTrOty10h33xqwi9FjO/D093YBbwkikUgkEolE4ijxJg4t13usx3pv4pFIJBKJRCKROEasx58oxZnUSwAU3QAAAABJRU5ErkJggg==',
      qr_code_text:
        '000201465165850014br.gov.bcb.pix2563qrcodepix.bb.com.br/pix/v2/vfvddv-e4d5-5454-9393-96beedf3aeba520405467575454.457GNJG78962070503***6304FFAAB',
    },
  ],
};

export const compraEmProcessoDeeplinkMock: ICompra = {
  ...compraEmProcessoSemFrete,
  compra_pagamentos: [
    {
      id: 54,
      parcelas: 0,
      valor: 137.37,
      cc_number: null,
      juros: 0,
      carteira_digital: true,
      forma_pagamento: {
        ...formaPagamentoCarteiraDigitalMock,
        bandeira: 'picpay',
        descricao: 'Picpay',
        logo: 'https://picsum.photos/100',
      },
      deep_link:
        'https://app.picpay.com/checkout/NjE5YmY1YmZjMTMzNWM0ZGMxNTZlMTNh',
      created: oneMinuteBefore,
      qr_code:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA4EAAAOBAQMAAAB1ZDePAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAGUExURQAAAP///6XZn90AAAAJcEhZcwAADsMAAA7DAcdvqGQAAASmSURBVHja7d07ctswEABQaFyo9BF8FB9NOlqO4iOoVOERkiG5woKilVhGXCRvm9DC5yHdzi4olY041MvG4xTPtf4opV7j1+i+1rc24aXWUp5iuPxhEIlEIpFIJBIfFJ9qjreF2detOK3W7mo9T0xaHvHa4XmUSCQSiUQikThQjI32TZzj0GbHxNecFO5i9NiW1/reif3mRCKRSCQSicRvEV+WjVJ8uhJIJBKJRCKRSPxusUaBj0gkEolEIpH4STHFOpebK28ds12X27Xxvi7Xb04kEolEIpFIHCnexKElhdNjpIzv3WOc57x+jJSxa8beDyKRSCQSiUTiQ2L9IM4LXjeTwohd9+7Due04ixtxJBKJRCKRSCQOE6d4nv6IbYJJ2WPE6a44r0lHSxnnKohEIpFIJBKJA8T4MEV0aOOy3qk72udu6KWIiUQikUgkEonEvyDGUHR161K3S+lfLx7zRh+KsZxIJBKJRCKROF6srcC3r9eIi3elyy1P7SWKnol4a0dL8SNf5SMSiUQikUgkjhJjPDVeD78XI+51dUtLM4lEIpFIJBL/HzFF+vClMRF9XW7M77ESiUQikUgkEh8X5/HUBu2+k+RGnGLODw+bYkTKR1/7iUQikUgkEonEUWJ8WGNJ972/ly5l3KgEpon720pgvMd6nXgkEolEIpFIJA4Tu3cfUvZYhnd165I9EolEIpFIJBJHielXGiImJup2uxFd3dvaI5FIJBKJRCJxgJiyxykTLEsrN/Ddbcq4JaaMk0gkEolEIpH4XWJ3Q6+08l9ZCnyXdp73ZXVttb6+Engu1/9Df7RVH5lIJBKJRCKROEac/jkt45E9zrleXYn1a13d+BYUIpFIJBKJxH9VXL/HWlsuV1q5LWJYV5dIJBKJRCKROOT2Wm1iz7y0Hmt/tFhzvD3lhvi8OhqRSCQSiUQicZTYPkxxFTcqgYH3KWNEVAL7NHPzlyaIRCKRSCQSiV8V1+PTRrsOH95HJhKJRCKRSCSOvaE3bZSu5UWBL1UCyzop/ERXt7b/DpFIJBKJRCJxlDiNn5YP09sWZcn1bsTaVt/5Da+0ZoodkUgkEolEInG8GBusk8Ky4NdIR1tvVLtzdJXAKDOuK4FEIpFIJBKJxAFixEb2GI+DurpzEIlEIpFIJBJHin0lMMp/Mb4b09W9lBREIpFIJBKJ/6YYg9N3kuy71mltuV4kcB+K3RfR/cENPSKRSCQSiUTiKHHOD7saWzymutx7Wz0/HvLEGmW9tGPgUZcjEolEIpFIJA4Vu9h4j+HylR7rxsQjkUgkEolEInGYWLciNWsf6erO8ZpPmSYSiUQikUgkEkeJGxEFvrKIden53ojr3/CaH5PYXf+rRCKRSCQSicTh4vU+XXzY3bYrm5XAp5ZbflgJTNljTCxEIpFIJBKJxOFi1Pr2Tdwv84d0dSe89ZGJRCKRSCQSiX9RvK5+uKvbT4zXciuRSCQSiUTi/yimHmsvdrElJqabWH6TyxGJRCKRSCQSHxJTrOty10h33xqwi9FjO/D093YBbwkikUgkEolE4ijxJg4t13usx3pv4pFIJBKJRCKROEasx58oxZnUSwAU3QAAAABJRU5ErkJggg==',
      qr_code_text:
        '000201465165850014br.gov.bcb.pix2563qrcodepix.bb.com.br/pix/v2/vfvddv-e4d5-5454-9393-96beedf3aeba520405467575454.457GNJG78962070503***6304FFAAB',
    },
  ],
};

export const compraValorFinalZeradoMock: ICompra = {
  ...compraDevolucaoEmSeparacaoMock,
  id: 1000163,
  compra_status_id: CompraStatusEnum.EM_EMPACOTAMENTO,
  frete: 4.5,
  valor_descontos: 50,
  valor_inicial: 33.48,
  valor_final: 0,
  tipo_compra: 'N',
  produtos: [produtoCompraMock],
};

export const novaCompraMock = {
  tipo_entrega_id: 2,
  endereco_id: 358,
  data_original_entrega: '2022-01-27 14:40:00',
  data_entrega: '2022-01-27 14:40:00',
  carrinho_id: 2041,
};

export const finalizarCompraMockDinheiro = {
  id: 2022,
  tipo_fiscal: 'NF',
  compra_pagamentos: [
    {
      valor: 115,
      valor_declarado: 120,
      confirmado: false,
      parcelas: 1,
      forma_pagamento_id: 1,
      carteira_digital: false,
      debito: false,
    },
  ],
  entrega: tipoEntrega1Mock,
  descontos: [],
  cupons: [],
  codigo: null,
  televendas: null,
  loja_autonoma: false,
  tipo_dispositivo: TipoDispositivoEnum.APLICATIVO,
};

export const compraDepartamentosMock = {
  ...compraDevolucaoEmSeparacaoMock,
  produtos: [
    {
      ...produtoCompraDepartamento1Mock,
      ...produtosDaCompraParaCarrinhoGenericoMock,
      preco: 15,
      preco_antigo: 15,
      item_id: produtoCompraDepartamento1Mock.produto_id,
    },
    {
      ...produtoCompraDepartamento2Mock,
      ...produtosDaCompraParaCarrinhoGenericoMock,
      preco: 5,
      preco_antigo: 5,
      item_id: produtoCompraDepartamento2Mock.produto_id,
    },
    {
      ...produtoCompraDepartamento3Mock,
      ...produtosDaCompraParaCarrinhoGenericoMock,
      preco: 3,
      preco_antigo: 3,
      item_id: produtoCompraDepartamento3Mock.produto_id,
    },
    {
      ...produtoCompraDepartamento4Mock,
      ...produtosDaCompraParaCarrinhoGenericoMock,
      preco: 3,
      preco_antigo: 3,
      item_id: produtoCompraDepartamento4Mock.produto_id,
    },
  ],
};

export const compraDepartamentosComBrindeMock = {
  ...compraDevolucaoEmSeparacaoMock,
  produtos: [
    ...compraDepartamentosMock.produtos,
    {
      ...produtosDaCompraParaCarrinhoGenericoMock,
      ...produtoCompraBrinde,
      item_id: produtoCompraBrinde.produto_id,
      preco_antigo: produtosDaCompraParaCarrinhoGenericoMock.preco_original,
    },
  ],
};

export const compraEmProcessoValorFinalZeradoCashbackMock = {
  ...compraDevolucaoEmSeparacaoMock,
  compra_descontos: [],
  id: 1000163,
  created: twoDaysBefore,
  data_entrega: new Date('2020-06-18T00:00:00+00:00'),
  data_entregue: null,
  compra_status_id: CompraStatusEnum.EM_PROCESSO_DE_COMPRA,
  status_cliente: 'Em Processo',
  efetuar_troca: true,
  valor_final: 0,
  valor_cashback: 30,
  tipo_compra: 'N',
  produtos: [produtoCompraMock],
  loja_autonoma: false,
};
