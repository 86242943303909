import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { IProduto, MultipleProdutoStateData } from '@vip/core';
import { ProdutosAndFiltrosDirective } from '@vip/views/lista-produtos';

@Directive()
export abstract class HomeBaseDirective extends ProdutosAndFiltrosDirective {
  @Input() isApp = true;
  @Input() isDesktop = false;
  @Input() isMobile!: boolean;
  @Input() isLoading = true;
  @Input() favoritos: IProduto[] = [];
  @Input() isLoadingVitrineFavoritos = false;
  @Input() maisVendidos: IProduto[] = [];
  @Input() isLoadingVitrineMaisVendidos = false;
  @Input() ofertasExclusivas: IProduto[] = [];
  @Input() isLoadingVitrineOfertasExclusivas = false;
  @Input() ofertas: IProduto[] = [];
  @Input() isLoadingVitrineOfertas = false;
  @Input() vitrinesDedicadas: MultipleProdutoStateData[] = [];
  @Input() vitrinesDedicadasPerfil: MultipleProdutoStateData[] = [];
  @Input() isLoadingVitrinesDedicadas = false;
  @Input() vitrinesEspecificas: MultipleProdutoStateData[] = [];
  @Input() isLoadingVitrinesEspecificas = false;
  @Input() destaques: MultipleProdutoStateData[] = [];
  @Input() isLoadingVitrineDestaques = false;
  @Input() isLoadingVitrineProdutosPatrocinados = false;
  @Input() produtosPatrocinados: any[] = [];

  @Output() showMoreClick = new EventEmitter();
}
