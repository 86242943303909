import { Injectable } from '@angular/core';
import { BaseApiService } from '../common/base-api-service';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import {
  LogarTelevendasRequest,
  LogarTelevendasResponse,
} from './televendas-api.service.types';
import {
  IFilialTelevendas,
  IOperadorTelevendas,
  TelevendasTokenService,
} from '@vip/core';
import { BaseApiResponse } from '../common/base-api-response';

@Injectable({ providedIn: 'root' })
export class TelevendasApiService {
  constructor(
    private api: BaseApiService<TelevendasApiService>,
    private televendasTokenService: TelevendasTokenService
  ) {}

  private getHeaders() {
    return {
      Authorization: `Bearer ${this.televendasTokenService.getToken()}`,
    };
  }

  logar(
    id: string,
    password: string
  ): Observable<{
    token: string;
    operador: IOperadorTelevendas;
  }> {
    return this.api
      .post<LogarTelevendasRequest, LogarTelevendasResponse>(
        'logar',
        '/loja/televendas/operador/logar',
        { id, password }
      )
      .pipe(pluck('data'));
  }

  logarTelevendas(
    cliente_id: string,
    filial_id: string
  ): Observable<{
    token: string;
    operador: IOperadorTelevendas;
  }> {
    const headers = this.getHeaders();
    return this.api
      .post<LogarTelevendasRequest, LogarTelevendasResponse>(
        'logarTelevendas',
        `/loja/televendas/operador/filial/${filial_id}/cliente/logar`,
        { cliente_id },
        headers
      )
      .pipe(pluck('data'));
  }

  getFilialTelevendas(): Observable<BaseApiResponse<IFilialTelevendas[]>> {
    const headers = this.getHeaders();
    return this.api.get(
      'getFilialTelevendas',
      `/loja/televendas/filiais`,
      headers
    );
  }
}
