import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ICarrinho,
  ICarrinhoItemInconsistencia,
  IProduto,
  IProdutoCarrinho,
  IApiPaginator,
} from '@vip/core';

export const getItensLista = createAction(
  '[Lista Itens/Page] Get Intens Lista',
  props<{ listaId: number; departamentoId?: number }>()
);

export const getItensListaSuccess = createAction(
  '[ListaItens/API] Get Intens Lista Success',
  props<{ itens: IProduto[]; paginator: IApiPaginator }>()
);

export const getItensListaFailure = createAction(
  '[ListaItens/API] Get Intens Lista Failure',
  props<{ error: HttpErrorResponse }>()
);

export const resetItensMinhaLista = createAction(
  '[Lista Itens/Page] Reset Itens Minha Lista'
);

export const addItensListaCarrinho = createAction(
  '[Lista/Page] Adicionar itens da lista no carrinho',
  props<{ listaId: number; ignoreSelecionarCDValidation?: boolean }>()
);

export const addItensListaCarrinhoSuccess = createAction(
  '[Lista/Page] Adicionar itens da lista no carrinho Success',
  props<{
    carrinho: ICarrinho;
    itensAtualizados: IProdutoCarrinho[];
    itensNaoAtualizados: ICarrinhoItemInconsistencia[];
  }>()
);

export const addItensListaCarrinhoFailure = createAction(
  '[Lista/Page] Adicionar itens da lista no carrinho Failure',
  props<{ error: HttpErrorResponse }>()
);

export const nextPage = createAction(
  '[Lista Itens/Page] Proxima pagina Itens Minha Lista',
  props<{ listaId: number; departamentoId?: number }>()
);

export const nextPageSuccess = createAction(
  '[Lista Itens/Page] Proxima pagina Itens Minha Lista Success',
  props<{ itens: IProduto[]; paginator: IApiPaginator }>()
);

export const nextPageFailure = createAction(
  '[ListaItens/API] Get Proxima pagina Itens Lista Failure',
  props<{ error: HttpErrorResponse }>()
);

export const editItemLista = createAction(
  '[ListaItens/API] editar item da lista',
  props<{
    produto_id: number;
    quantidade: number;
    listaId: number;
    seletor_medida_id: number;
    showMessage: boolean;
  }>()
);

export const editItemListaSuccess = createAction(
  '[ListaItens/API] editar item da lista success',
  props<{
    produto: IProduto;
    listaId: number;
    showMessage: boolean;
  }>()
);

export const editItemListaDeleteSuccess = createAction(
  '[ListaItens/API] deletar item da lista success',
  props<{ produto_id: number; listaId: number }>()
);

export const editItemListaFailure = createAction(
  '[ListaItens/API] editar item da lista failure',
  props<{ error: HttpErrorResponse }>()
);
