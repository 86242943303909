import {
  DESCONTO_FEATURE_KEY,
  descontoAdapter,
  DescontoState,
} from './desconto.reducer';
import { SelectorUtil } from '@vip/state/utils';
import { createSelector } from '@ngrx/store';

const { selectAll } = descontoAdapter.getSelectors();

export class DescontoSelectors extends SelectorUtil<DescontoState> {
  constructor() {
    super(DESCONTO_FEATURE_KEY);
  }

  getTotalDesconto = () =>
    createSelector(this.getDescontos(), (descontos) => {
      if (descontos.length) {
        return descontos.reduce((acc, curr) => {
          return acc + curr.valor;
        }, 0);
      } else {
        return 0;
      }
    });

  getDescontos = () =>
    createSelector(this.createFeatureSelector, (state: DescontoState) =>
      selectAll(state.data.descontos).map((desconto) => ({
        ...desconto,
        valor: desconto.total || desconto.valor,
      }))
    );

  getCodigoPromocional = () =>
    createSelector(
      this.createFeatureSelector,
      (state: DescontoState) => state.data.codigoPromocional
    );
}

export const descontoSelectors = new DescontoSelectors();
