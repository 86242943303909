import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as TermosUsoActions from './termos-uso.actions';
import { termosUsoSelectors } from './termos-uso.selectors';

@Injectable()
export class TermosUsoFacade {
  loading$ = this.store.pipe(select(termosUsoSelectors.isLoading()));
  error$ = this.store.pipe(select(termosUsoSelectors.getError()));
  termosUso$ = this.store.pipe(select(termosUsoSelectors.getData()));
  aprovacaoPendente$ = this.store.pipe(
    select(termosUsoSelectors.getAprovacaoPendente())
  );

  constructor(private store: Store) {}

  getTermosUso() {
    this.store.dispatch(TermosUsoActions.getTermosUso());
  }

  getAprovacaoPendente() {
    this.store.dispatch(TermosUsoActions.getAprovacaoPendente());
  }

  aprovarTermosUso() {
    this.store.dispatch(TermosUsoActions.aprovarTermosUso());
  }
}
