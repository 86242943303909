import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@vip/core';
import { ButtonModule } from '@vip/ui/button';
import { ImageModule } from '@vip/ui/image';
import { ProdutoPrecoModule } from '@vip/ui/produto-preco';
import { SpinModule } from '@vip/ui/spin';
import { TagsModule } from '@vip/ui/tags';
import { ItemProductComponent } from './item-product/item-product.component';
import { SearchMenuComponent } from './search-menu/search-menu.component';
import { SkeletonModule } from '@vip/ui/skeleton';
import { SearchMenuSkeletonComponent } from './search-menu-skeleton/search-menu-skeleton.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    ImageModule,
    TagsModule,
    SpinModule,
    CoreModule,
    ProdutoPrecoModule,
    SkeletonModule,
  ],
  exports: [SearchMenuComponent],
  declarations: [
    SearchMenuComponent,
    ItemProductComponent,
    SearchMenuSkeletonComponent,
  ],
  providers: [CurrencyPipe],
})
export class SearchMenuModule {}
