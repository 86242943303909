import { createSelector } from '@ngrx/store';
import {
  VITRINE_DEDICADA_FEATURE_KEY,
  VitrineDedicadaState,
} from './vitrine-dedicada.reducer';
import { SelectorUtil } from '@vip/state/utils';

class BannerSelectors extends SelectorUtil<VitrineDedicadaState> {
  constructor() {
    super(VITRINE_DEDICADA_FEATURE_KEY);
  }

  getVitrines = () =>
    createSelector(
      this.createFeatureSelector,
      (state: VitrineDedicadaState) => state.data.vitrines
    );

  getVitrinesSemPerfil = () =>
    createSelector(this.createFeatureSelector, (state: VitrineDedicadaState) =>
      state.data.vitrines.filter((vitrine) => !vitrine.perfil_ids.length)
    );

  getVitrinesComPerfil = () =>
    createSelector(this.createFeatureSelector, (state: VitrineDedicadaState) =>
      state.data.vitrines.filter((vitrine) => vitrine.perfil_ids.length)
    );

  getVitrineById = (vitrineId: string) =>
    createSelector(this.createFeatureSelector, (state: VitrineDedicadaState) =>
      state.data.vitrines.find((vitrine) => vitrine.id === vitrineId)
    );
}

export const vitrineDedicadaSelectors = new BannerSelectors();
