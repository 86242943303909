import { DialogService } from '@vip/ui/modal';
import { Injectable, NgZone } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Router } from '@angular/router';
import { CarrinhoFacade } from '@vip/state/carrinho';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';
import { ClienteFacade } from '@vip/state/cliente';
import { first } from 'rxjs/operators';

export interface IframeOptions {
  isLoginRequired?: boolean;
  iframeId?: number;
  navigateHomeOnClose?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class IframeService {
  iframeDefaultOptions: IframeOptions = {
    isLoginRequired: false,
    iframeId: undefined,
    navigateHomeOnClose: true,
  };

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private carrinhoFacade: CarrinhoFacade,
    private carrinhoItensFacade: CarrinhoItensFacade,
    private clienteFacade: ClienteFacade,
    private zone: NgZone
  ) {}
  navigateHome = false;

  async open(url: string, options?: IframeOptions) {
    const isLogged = await this.clienteFacade.isLogged$
      .pipe(first())
      .toPromise();
    options = { ...this.iframeDefaultOptions, ...options };

    if (options.isLoginRequired && options.iframeId && !isLogged) {
      this.showDialog(options.iframeId);
    } else {
      if (options.navigateHomeOnClose) {
        this.navigateHome = true;
        this.navigateHomeOnClose();
      } else {
        this.navigateHome = false;
      }

      if (options.iframeId) {
        this.router.navigateByUrl(`iframe/${options.iframeId}`);
      } else {
        await Browser.open({ url });
      }
    }
  }

  showDialog(iframeId: number) {
    this.dialogService.openDialog({
      open: true,
      title: 'Você precisa estar logado',
      subTitle:
        'Por favor, faça login com o seu usuário para entrar nessa tela.',
      disabled: false,
      buttonConfirmText: 'Ok',
    });

    this.dialogService.dialogClick.subscribe((value) => {
      if (value)
        this.router.navigate(['/login'], {
          queryParams: { redirectTo: `iframe/${iframeId}` },
        });
      this.dialogService.clearDialog();
    });
  }

  navigateHomeOnClose() {
    Browser.addListener('browserFinished', () => {
      this.redirectToHome();
      this.updateCart();
    });
  }

  updateCart() {
    this.zone.run(() => {
      this.carrinhoFacade.getCarrinho();
      this.carrinhoItensFacade.getItensCarrinho();
    });
  }

  redirectToHome() {
    this.zone.run(() => {
      if (this.navigateHome) {
        Browser.removeAllListeners();
        this.router.navigateByUrl('/');
        this.navigateHome = false;
      }
    });
  }

  close() {
    Browser.removeAllListeners();
    this.updateCart();
    return Browser.close();
  }
}
