<div>
  <form [formGroup]="form" class="grid text-center">
    <span class="vip-modal-title">
      Vai precisar de troco?
    </span>
    <span class="vip-modal-sub-title">
      Atenção: O pagamento com Dinheiro deverá ser realizado no momento da
      entrega.
    </span>
    <vip-field
      class="text-left mb-2"
      label="TROCO PARA*"
      [message]="validations"
      [error]="
        form.controls.troco.invalid &&
        (form.controls.troco.touched || form.controls.troco.dirty)
      "
      [validations]="form.controls.troco.errors"
    >
      <input
        id="input-troco"
        type="text"
        currencyMask
        formControlName="troco"
        inputmode="decimal"
      />
    </vip-field>
    <vip-button (btnClick)="alterarValorTroco()" class="mb-1 mt-2">
      Confirmar
    </vip-button>
    <vip-button (btnClick)="setValorTrocoAsCompra()" class="my-1" type="normal">
      Não quero troco
    </vip-button>
  </form>
</div>
