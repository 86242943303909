import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalTrocoComponent } from '../modal-troco/modal-troco.component';
import { Injectable, EventEmitter } from '@angular/core';
import { ModalService } from '@vip/ui/modal';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ModalTrocoService {
  valorTrocoEventEmitter = new EventEmitter<number>();

  constructor(private modalService: ModalService<ModalTrocoComponent>) {}

  selecionarTroco(totalCompra: number, valorTroco?: number) {
    const modalRef = this.modalService.openModal(ModalTrocoComponent);
    if (modalRef) {
      const componentModalTroco = modalRef.instance as ModalTrocoComponent;
      componentModalTroco.totalCompra = totalCompra;
      componentModalTroco.valorTroco = valorTroco || 0;
      componentModalTroco.setValidators();
      componentModalTroco.alterarValorTrocoEvent
        .pipe(untilDestroyed(this))
        .subscribe((valorTroco) => {
          this.valorTrocoEventEmitter.emit(valorTroco);
          this.modalService.clearModal();
        });
    }
  }
}
