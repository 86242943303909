import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { ModalService } from '@vip/ui/modal';
import { ConviteListaContainerComponent } from '../convite-lista-container/convite-lista-container.component';
import { Observable } from 'rxjs';
import { LayoutUtilsService } from '@vip/core';
import { map, take } from 'rxjs/operators';
import {
  ClienteFacade,
  ClienteTokenService,
  CodigoCompartilhamentoService,
} from '@vip/state/cliente';

@Injectable({
  providedIn: 'root',
})
export class ConviteListaGuard implements CanActivate {
  isDesktopResponsive = this.layoutUtilService.isDesktopWithoutScreenWidth();
  constructor(
    private modalService: ModalService<ConviteListaContainerComponent>,
    private router: Router,
    private layoutUtilService: LayoutUtilsService,
    private clienteTokenService: ClienteTokenService,
    private codigoCompartilhamentoService: CodigoCompartilhamentoService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.layoutUtilService.isDesktop$.pipe(
      map((isDesktop) => {
        if (isDesktop || this.isDesktopResponsive) {
          if (this.clienteTokenService.getToken() !== '') {
            this.router.navigateByUrl('/');
            const modalInstance = this.modalService.openModal(
              ConviteListaContainerComponent,
              {
                showCloseButton: false,
              }
            );
            if (modalInstance) {
              modalInstance.instance.params = route.params;
              modalInstance.instance.acessarListaDesktop
                .pipe(take(1))
                .subscribe((listaId) => {
                  this.router.navigateByUrl(
                    `minha-conta/minhas-listas/detalhes/${listaId}`
                  );
                  this.modalService.clearModal();
                });
              modalInstance.instance.participarListaDesktop
                .pipe(take(1))
                .subscribe(() => this.modalService.clearModal());

              this.modalService.closeClick.pipe(take(1)).subscribe(() => {
                this.codigoCompartilhamentoService.clearCodigo();
              });
            }
          } else {
            this.codigoCompartilhamentoService.setCodigo(route.params.slug);
            this.router.navigateByUrl(`/login?redirectTo=${state.url}`, {
              state: { params: route.params.slug },
            });
          }
          return true;
        } else {
          return true;
        }
      })
    );
  }
}
