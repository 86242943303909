import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromPerguntas from './+state/perguntas.reducer';
import { PerguntasEffects } from './+state/perguntas.effects';
import { PerguntasFacade } from './+state/perguntas.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromPerguntas.PERGUNTAS_FEATURE_KEY,
      fromPerguntas.reducer
    ),
    EffectsModule.forFeature([PerguntasEffects]),
  ],
  providers: [PerguntasFacade],
})
export class PerguntasStateModule {}
