import { Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenTitleComponent } from './screen-title/screen-title.component';
import { ButtonModule } from '@vip/ui/button';
import { CoreModule } from '@vip/core';
import { SkeletonModule } from '@vip/ui/skeleton';

@NgModule({
  imports: [CommonModule, ButtonModule, CoreModule, SkeletonModule],
  declarations: [ScreenTitleComponent],
  exports: [ScreenTitleComponent],
})
export class ScreenTitleModule {
  @Input() isDesktop = false;
}
