<div
  [ngClass]="{
    'text-center': !layoutUtilsService.isDesktop(),
    'grid gap-4 grid-cols-1': true
  }"
  [formGroup]="form"
>
  <span class="vip-validar-cep-title text-3xl">Qual o seu CEP?</span>
  <span class="vip-validar-cep-sub-title text"
    >Precisamos validar seu CEP para saber se o nosso serviço atende a sua
    região.</span
  >
  <vip-field
    [error]="cepInvalido || (validated && form.controls.cep.invalid)"
    [message]="'CEP inválido'"
    [validations]="form.controls.cep.errors"
    label="Digite seu CEP*"
    class="text-left"
  >
    <input
      id="cep"
      type="tel"
      formControlName="cep"
      [mask]="masks.cep"
      autocomplete="off"
      data-cy="solicitar-cep-input"
    />
  </vip-field>

  <div
    [ngClass]="{
      'flex justify-end space-x-4': isDesktop,
      'justify-center': isMobile
    }"
  >
    <vip-button
      data-cy="solicitar-cep-validar"
      (btnClick)="onConfirmSolicitarCep()"
      [disabled]="loading"
    >
      <span> Verificar Disponibilidade </span>
    </vip-button>
  </div>
</div>
