import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFilter, IFilterOptions } from '@vip/core';

@Component({
  selector: 'vip-filtro-desktop-item',
  templateUrl: './filtro-desktop-item.component.html',
  styles: [
    `
      vip-radio-list-item {
        margin-left: -5px;
      }
    `,
  ],
})
export class FiltroDesktopItemComponent {
  @Input()
  filterItem?: IFilter;

  @Input()
  qtdMaxVisibleItens = 5;

  @Output()
  filterItemChange = new EventEmitter();

  expanded = false;

  toggleExpanded() {
    this.expanded = !this.expanded;
  }

  getVisibleOptions() {
    return this.filterItem
      ? this.filterItem?.options.filter((option) => !option.hidden)
      : [];
  }

  getFilterOptions() {
    if (!this.filterItem) {
      return [];
    }
    const { options } = this.filterItem;
    const filterOptions = [...options].sort((a, b) => {
      if (a.hidden === b.hidden) {
        return a.value > b.value ? 1 : -1;
      } else {
        return a.hidden ? 1 : -1;
      }
    });
    if (
      filterOptions &&
      filterOptions.length > this.qtdMaxVisibleItens &&
      !this.expanded
    ) {
      return this.filterItem?.multiple
        ? filterOptions.slice(0, this.qtdMaxVisibleItens)
        : filterOptions;
    }
    return filterOptions;
  }

  getFilterOptionLabel(filterOption: IFilterOptions) {
    const label = filterOption.label;
    const count = filterOption.count;
    return count ? `${label} (${count})` : label;
  }
}
