import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'vip-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  private _value = 0;

  @Input()
  get value(): number {
    return this._value;
  }

  set value(value: number) {
    this._value = Math.max(0, Math.min(1, value));
  }
}
