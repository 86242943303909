import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromIngredientesReceita from './+state/ingredientes-receita.reducer';
import { IngredientesReceitaEffects } from './+state/ingredientes-receita.effects';
import { IngredientesReceitaFacade } from './+state/ingredientes-receita.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromIngredientesReceita.INGREDIENTES_RECEITA_FEATURE_KEY,
      fromIngredientesReceita.reducer
    ),
    EffectsModule.forFeature([IngredientesReceitaEffects]),
  ],
  providers: [IngredientesReceitaFacade],
})
export class IngredientesReceitaStateModule {}
