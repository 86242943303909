<div class="flex flex-col h-full">
  <vip-screen-title
    data-cy="alterar-entrega-retirada-title"
    [title]="
      isRetirada ? 'Alterar Tipo de Retirada' : 'Alterar Tipo de Entrega'
    "
    [customActions]="true"
    [isLoading]="isLoading"
    [showActions]="true"
  >
    <vip-button-icon-rounded
      class="z-10"
      [isLoading]="isLoading"
      type="outline"
      data-cy="voltar"
      (btnClick)="goBackClick.emit()"
    >
      <i class="icon-arrow_back vip-color-secondary-main"></i
    ></vip-button-icon-rounded>
  </vip-screen-title>

  <div class="p-3">
    <span
      *ngIf="!isLoading"
      class="block mb-3 vip-color-first-default font-bold text-lg"
      >Escolha o tipo de {{ isRetirada ? 'retirada' : 'entrega' }}</span
    >

    <ng-container
      *vipSkeleton="isLoading; width: '60%'; className: ['mb-3']"
    ></ng-container>

    <vip-selectable-card-skeleton
      *ngIf="isLoading"
      [quantity]="6"
    ></vip-selectable-card-skeleton>

    <div *ngIf="!isLoading && tiposEntrega">
      <div *ngFor="let tipoEntrega of getTiposEntregaOrdenados(tiposEntrega)">
        <vip-selectable-card
          class="mb-3 block"
          *ngIf="tipoEntrega.retirada_loja === isRetirada"
          [showIcon]="tipoSelecionado?.id === tipoEntrega.id"
          [checked]="tipoSelecionado?.id === tipoEntrega.id"
          (changed)="tipoEntregaChange.emit(tipoEntrega)"
          [disabled]="!!tipoEntrega.bloqueios?.length"
        >
          <div title class="flex justify-between">
            <div class="font-bold">{{ tipoEntrega.descricao }}</div>
            <div>
              <span
                class="line-through vip-color-third-default"
                *ngIf="tipoEntrega.desconto"
              >
                {{ tipoEntrega.frete | currency }}</span
              >
              <span
                class="font-bold vip-color-first-default"
                data-cy="tipo-entrega-frete"
              >
                {{ tipoEntrega.frete | valorFrete: tipoEntrega.desconto }}
              </span>
            </div>
          </div>
          <div body *ngIf="!tipoEntrega.bloqueios?.length">
            <div
              class="vip-color-secondary-default text-xs mt-1"
              data-cy="tipo-entrega-resumo"
            >
              {{
                tipoEntrega.resumo.trim() ||
                  'Escolha a melhor faixa de horários para receber sua compra.'
              }}
            </div>

            <div
              class="vip-color-error-main text-xs mt-1"
              data-cy="tipo-entrega-limite"
            >
              {{
                tipoEntrega.quantidade_maxima_itens
                  ? 'Limite de ' +
                    tipoEntrega.quantidade_maxima_itens +
                    ' itens'
                  : ''
              }}
            </div>
          </div>
          <div body *ngIf="tipoEntrega.bloqueios?.length">
            <hr class="vip-divider my-2" />
            <div
              *ngFor="let bloqueio of tipoEntrega.bloqueios"
              class="vip-color-error-main"
            >
              {{ bloqueio.mensagem }}
            </div>
          </div>
        </vip-selectable-card>
      </div>
    </div>
  </div>
</div>
