import { ISelectOptions } from '../interfaces/select-options.interface';

export const RessarcimentoOptions: ISelectOptions[] = [
  {
    value: 'C',
    text: 'Cupom de Desconto',
  },
  {
    value: 'D',
    text: 'Estorno do valor pago',
  },
];
