import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import * as TipoEntregaActions from '../+state/tipo-entrega.actions';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TipoEntregaUtilsService {
  private getTiposEntregasEndereco$ = this.actions$.pipe(
    ofType(TipoEntregaActions.getTiposEntregasEndereco)
  );

  private getTiposEntregasEnderecoSuccess$ = this.actions$.pipe(
    ofType(TipoEntregaActions.getTiposEntregasEnderecoSuccess)
  );

  private getTiposEntregasEnderecoFailure$ = this.actions$.pipe(
    ofType(TipoEntregaActions.getTiposEntregasEnderecoFailure)
  );

  private subscriptionsTiposEntregasEndereco?: Subscription;

  private isLoadingTiposEntregasEnderecoRequestSubject =
    new BehaviorSubject<boolean>(false);

  isLoadingTiposEntregasEnderecoRequest$ =
    this.isLoadingTiposEntregasEnderecoRequestSubject.asObservable() as Observable<boolean>;

  constructor(private actions$: Actions) {}

  listenTiposEntregasEnderecoLoading() {
    if (this.subscriptionsTiposEntregasEndereco) return;

    this.getTiposEntregasEndereco$.subscribe(() =>
      this.isLoadingTiposEntregasEnderecoRequestSubject.next(true)
    );

    this.getTiposEntregasEnderecoSuccess$.subscribe(() =>
      this.isLoadingTiposEntregasEnderecoRequestSubject.next(false)
    );
    this.getTiposEntregasEnderecoFailure$.subscribe(() =>
      this.isLoadingTiposEntregasEnderecoRequestSubject.next(false)
    );
  }
}
