import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddEditEnderecoRequest } from '@vip/api';
import { HeaderUtilsService, StepStatusEnum } from '@vip/core';
import { BucketsFacade } from '@vip/state/buckets';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { CepFacade } from '@vip/state/cep';
import { EnderecoFacade } from '@vip/state/endereco';
import { FilialFacade } from '@vip/state/filial';
import { LayoutStore } from '@vip/state/layout';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'vip-alterar-endereco-container',
  templateUrl: './alterar-endereco-container.component.html',
  styleUrls: ['./alterar-endereco-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlterarEnderecoContainerComponent implements AfterViewInit {
  steps = [
    { title: 'Entrega', link: '', status: StepStatusEnum.ACTIVE },
    { title: 'Pagamento', link: '', status: StepStatusEnum.PENDING },
    { title: 'Revisão', link: '', status: StepStatusEnum.PENDING },
  ];

  filial$ = this.cdFacade.filialECdSelecionado$.pipe(map(([filial]) => filial));
  enderecoSelecionado$ = this.enderecoFacade.enderecoSelecionado$;
  possuiEnderecoCadastrado$ = this.enderecoFacade.possuiEnderecoCadastrado$;
  newEndereco$ = this.cepFacade.enderecoDoCep$;
  loadingEndereco$ = this.enderecoFacade.loading$;
  loadingCep$ = this.cepFacade.loading$;
  cepAtendido$ = this.cepFacade.cepAtendido$;
  filialId = 0;
  filial = this.filialFacade.filial$
    .pipe(untilDestroyed(this))
    .subscribe((filial) => {
      this.filialId = filial.id;
    });
  logo$ = this.headerUtilsService.getLogo(
    this.bucketsFacade.bucketS3$,
    this.filial$
  );
  logoFallback$ = this.headerUtilsService.getLogoFallback(
    this.bucketsFacade.bucketS3$
  );
  isNew = false;
  isFromResumoCompra = false;
  acao: 'novo' | 'editar' | null = null;
  manualTitle: 'Novo Endereço' | 'Editar Endereço' | null = null;

  @Input() fallbackImageUrl?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cepFacade: CepFacade,
    private enderecoFacade: EnderecoFacade,
    private router: Router,
    private cdFacade: CentroDistribuicaoFacade,
    private filialFacade: FilialFacade,
    private bucketsFacade: BucketsFacade,
    private layoutStore: LayoutStore,
    private headerUtilsService: HeaderUtilsService
  ) {
    this.acao = this.activatedRoute.snapshot.params.acao;
    this.isFromResumoCompra =
      this.activatedRoute.snapshot.queryParams.isFromResumoCompra;
    this.isNew = this.acao === 'novo' || this.acao === 'editar';
    this.setManualTitle();
  }

  setManualTitle(): void {
    if (this.acao === 'novo') this.manualTitle = 'Novo Endereço';
    if (this.acao === 'editar') this.manualTitle = 'Editar Endereço';
  }

  ngAfterViewInit(): void {
    if (this.isFromResumoCompra) {
      this.layoutStore.setHeaderVisibility(false);
    }
  }

  changeCep(cep: string) {
    this.cepFacade.validarCepAtendidoPelaFilial(cep);
    this.isNew = false;
  }

  saveEndereco(endereco: AddEditEnderecoRequest) {
    this.enderecoFacade.selectEndereco(null);
    this.enderecoFacade.saveEndereco(endereco, '/entrega-retirada');
  }

  onCloseClick(): void {
    this.enderecoFacade.selectEndereco(null);
    this.router.navigate(['/entrega-retirada'], {
      queryParamsHandling: 'merge',
    });
  }
}
