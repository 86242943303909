import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { RegexPatters } from '@vip/core';

@Component({
  selector: 'vip-cep-nao-atendido',
  templateUrl: './cep-nao-atendido.component.html',
  styleUrls: ['../../validar-cep/validar-cep.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CepNaoAtendidoComponent {
  @Output()
  confirm = new EventEmitter();
  @Input()
  loading = false;
  validated = false;
  form = this.fb.group({
    email: [
      '',
      [Validators.required, Validators.pattern(new RegExp(RegexPatters.EMAIL))],
    ],
  });

  constructor(private fb: UntypedFormBuilder) {}

  onConfirmCepNaoAtendido() {
    this.validated = true;
    if (!this.form.invalid) {
      this.confirm.emit(this.form.controls.email.value);
    }
  }
}
