import { createSelector } from '@ngrx/store';
import { SelectorUtil } from '@vip/state/utils';
import {
  TIPO_ENTREGAS_FEATURE_KEY,
  tipoEntregaAdapter,
  TipoEntregaState,
} from './tipo-entrega.reducer';

const { selectEntities, selectAll } = tipoEntregaAdapter.getSelectors();

export class TipoEntregaSelectors extends SelectorUtil<TipoEntregaState> {
  constructor() {
    super(TIPO_ENTREGAS_FEATURE_KEY);
  }

  isEntrega = () =>
    createSelector(
      this.createFeatureSelector,
      (state: TipoEntregaState) => state.isEntrega
    );

  tipoEntregaPadrao = () =>
    createSelector(
      this.createFeatureSelector,
      (state: TipoEntregaState) => state.tipoEntregaPadrao
    );

  getAllTiposEntregas = () =>
    createSelector(this.createFeatureSelector, (state: TipoEntregaState) =>
      selectEntities(state)
    );

  getAllTiposEntregasArray = () =>
    createSelector(this.createFeatureSelector, (state: TipoEntregaState) =>
      selectAll(state).filter(
        (tipoEntrega) => !tipoEntrega.exclusivo_televendas
      )
    );

  getAllTiposEntregasTelevendasArray = () =>
    createSelector(this.createFeatureSelector, (state: TipoEntregaState) =>
      selectAll(state).filter((tipoEntrega) => tipoEntrega)
    );

  getTipoEntregaSelecionadoId = () =>
    createSelector(
      this.createFeatureSelector,
      (state: TipoEntregaState) => state.tipoEntregaSelecionadaId
    );

  getTipoEntregaSelecionada = () =>
    createSelector(
      this.getAllTiposEntregas(),
      this.getTipoEntregaSelecionadoId(),
      (entities, selectedId) =>
        selectedId && entities[selectedId] ? entities[selectedId] : null
    );

  getHorarioSelecionado = () =>
    createSelector(
      this.createFeatureSelector,
      (state: TipoEntregaState) => state.horarioSelecionado
    );
}

export const tipoEntregaSelectors = new TipoEntregaSelectors();
