<div
  class="flex flex-col items-center border border-[#EBECEF] bg-white rounded w-[118px] max-w-[120px] h-max cursor-pointer"
  (click)="redirectToProductCombo(produtoCombo)"
>
  <vip-image
    class="h-20 w-20 object-cover pt-[3px] mb-2"
    [alt]="produtoCombo.descricao"
    [imageUrl]="
      produtoCombo.imagem
        ? replaceImageSize(produtoCombo.imagem, '144x144')
        : ''
    "
  ></vip-image>

  <div
    class="h-[42px] max-h-[42px] w-[100px] max-w-[100px] flex px-2 items-start justify-center text-center"
  >
    <p
      class="text-[12px] h-[42px] max-h-[42px] leading-[14.06px] overflow-hidden"
    >
      {{ produtoCombo.descricao }}
    </p>
  </div>

  <div class="w-full flex justify-center items-center">
    <div
      class="bg-tag-peso-unidade leading-[16.41px] rounded-full px-[8px] py-[2px] mt-2 mb-2"
    >
      <span class="text-[14px] font-bold bg-color-tag">
        {{ produtoCombo.oferta?.quantidade_minima }}
        {{
          unidadeMedidaCombo(
            produtoCombo.unidade_sigla,
            produtoCombo.oferta?.quantidade_minima
          )
        }}
      </span>
    </div>
  </div>

  <div class="h-[40px] max-h-[40px] mx-[16px] flex flex-col items-start">
    <p
      class="text-[14px] leading-[16.41px] line-through vip-color-third-default"
    >
      {{ currencyComboProdutoView(produtoCombo.oferta?.preco_antigo) }}/<span
        class="lowercase"
        >{{ produtoCombo.unidade_sigla }}</span
      >
    </p>
    <p class="text-[16px] leading-[18.75px] font-bold text-black">
      {{ currencyComboProdutoView(produtoCombo.oferta?.preco_oferta) }}/<span
        class="lowercase"
        >{{ produtoCombo.unidade_sigla }}</span
      >
    </p>
  </div>
</div>
