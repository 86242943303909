import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as EnderecoActions from './endereco.actions';
import { IEndereco } from '@vip/core';
import { IGenericState } from '@vip/state/utils';

export const ENDERECO_FEATURE_KEY = 'endereco';

interface EnderecoData {
  enderecos: EntityState<IEndereco>;
  enderecosEntrega: EntityState<IEndereco>;
  enderecoSelecionadoId: number | null;
  enderecoEntregaSelecionadoId: number | null;
}
export type EnderecoState = IGenericState<EnderecoData>;

export const enderecoAdapter: EntityAdapter<IEndereco> =
  createEntityAdapter<IEndereco>();

export const enderecoEntregaAdapter: EntityAdapter<IEndereco> =
  createEntityAdapter<IEndereco>();

export const initialState: EnderecoState = {
  data: {
    enderecos: enderecoAdapter.getInitialState(),
    enderecosEntrega: enderecoEntregaAdapter.getInitialState(),
    enderecoSelecionadoId: null,
    enderecoEntregaSelecionadoId: null,
  },
  status: 'pending',
  error: null,
};

const enderecoReducer = createReducer(
  initialState,
  on(EnderecoActions.select, (state, { enderecoSelecionadoId }) => ({
    ...state,
    data: {
      ...state.data,
      enderecoSelecionadoId,
    },
  })),
  on(
    EnderecoActions.selectEnderecoEntrega,
    (state, { enderecoEntregaSelecionadoId }) => ({
      ...state,
      data: {
        ...state.data,
        enderecoEntregaSelecionadoId,
      },
    })
  ),
  on(
    EnderecoActions.getEnderecos,
    EnderecoActions.getEnderecosEntrega,
    EnderecoActions.saveEndereco,
    EnderecoActions.deleteEndereco,
    (state) => ({
      ...state,
      status: 'loading',
      error: null,
    })
  ),
  on(EnderecoActions.getEnderecosSuccess, (state, { enderecos }) => ({
    ...state,
    data: {
      ...state.data,
      enderecos: enderecoAdapter.setAll(enderecos, state.data.enderecos),
    },
    status: 'success',
    error: null,
  })),
  on(EnderecoActions.getEnderecosEntregaSuccess, (state, { enderecos }) => ({
    ...state,
    data: {
      ...state.data,
      enderecosEntrega: enderecoEntregaAdapter.setAll(
        enderecos,
        state.data.enderecosEntrega
      ),
    },
    status: 'success',
    error: null,
  })),
  on(EnderecoActions.saveEnderecoSuccess, (state, { endereco }) => ({
    ...state,
    data: {
      ...state.data,
      enderecos: enderecoAdapter.setOne(endereco, state.data.enderecos),
      enderecoSelecionadoId: endereco.id,
      enderecoEntregaSelecionadoId: endereco.id,
    },
    status: 'success',
    error: null,
  })),
  on(EnderecoActions.setEnderecosCep, (state, { endereco }) => ({
    ...state,
    data: {
      ...state.data,
      enderecoEntregaSelecionadoId: endereco.id,
      enderecosEntrega: enderecoEntregaAdapter.setOne(
        endereco,
        state.data.enderecosEntrega
      ),
    },
    status: 'success',
    error: null,
  })),
  on(
    EnderecoActions.getEnderecosFailure,
    EnderecoActions.getEnderecosEntregaFailure,
    EnderecoActions.saveEnderecoFailure,
    EnderecoActions.deleteEnderecoFailure,
    (state, { error }) => ({
      ...state,
      status: 'error',
      error: error.error.error,
    })
  )
);

export function reducer(state: EnderecoState | undefined, action: Action) {
  return enderecoReducer(state, action);
}
