import { Pipe, PipeTransform } from '@angular/core';
import { IFaixaPrecos, IProduto } from '../../interfaces';

@Pipe({ name: 'unidadePrecoPipe' })
export class UnidadePrecoPipe implements PipeTransform {
  transform(
    produto: IProduto,
    isAntigo = false,
    faixaPreco?: IFaixaPrecos,
    verificarFlagPrecoOriginal = false
  ): number {
    if (verificarFlagPrecoOriginal && produto.exibe_preco_original) {
      const { preco_original } =
        produto.em_oferta && produto.oferta !== null ? produto.oferta : produto;
      return preco_original;
    }
    if (produto.unidade_fracao) {
      if (faixaPreco) return faixaPreco.preco_oferta_fracao;
      if (isAntigo)
        return (
          produto.oferta?.preco_antigo_fracao || produto.unidade_fracao.preco
        );
      return (
        produto.oferta?.preco_oferta_fracao || produto.unidade_fracao.preco
      );
    }
    return produto.em_oferta && produto.oferta
      ? produto.oferta.preco_original
      : produto.preco_original;
  }
}
