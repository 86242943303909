import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromCartao from './+state/cartao.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CartaoEffects } from './+state/cartao.effects';
import { CartaoFacade } from './+state/cartao.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCartao.CARTAO_FEATURE_KEY, fromCartao.reducer),
    EffectsModule.forFeature([CartaoEffects]),
  ],
  providers: [CartaoFacade],
})
export class CartaoStateModule {}
