import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import {
  NavigateBackDirective,
  ObserveVisibilityDirective,
} from './directives';
import {
  GetUrlGoogleSafe,
  IsOfertaComDescontoSimplesPipe,
  IsOnlyOneEnablePipe,
  MostrarPrecoUnidadeProdutoPipe,
  OfferTitlePipe,
  ParcelasTextoPipe,
  QtdItensProdutosPipe,
  QuantidadeMaximaProdutoPipe,
  QuantidadeProdutoPipe,
  QuantidadeUnidadeDiferenteProdutoPipe,
  ReplaceVariablesPipe,
  UnidadeDiferentePipe,
  UnidadePrecoPipe,
  UnidadeQuantidadePipe,
  UnidadeSiglaPipe,
  ValidatePipe,
  ValidationMessagesPipe,
  ValorFretePipe,
  PagamentoTextoPipe,
  DesktopTextPipe,
  MenuInfosPipe,
  ShowCashbackMenuOptionPipe,
} from './pipes';

@NgModule({
  imports: [CommonModule],
  providers: [CurrencyPipe],
  declarations: [
    DesktopTextPipe,
    IsOnlyOneEnablePipe,
    IsOfertaComDescontoSimplesPipe,
    MostrarPrecoUnidadeProdutoPipe,
    NavigateBackDirective,
    ObserveVisibilityDirective,
    OfferTitlePipe,
    ParcelasTextoPipe,
    PagamentoTextoPipe,
    QtdItensProdutosPipe,
    QuantidadeMaximaProdutoPipe,
    QuantidadeProdutoPipe,
    QuantidadeUnidadeDiferenteProdutoPipe,
    ReplaceVariablesPipe,
    UnidadeDiferentePipe,
    UnidadePrecoPipe,
    UnidadeQuantidadePipe,
    UnidadeSiglaPipe,
    ValidatePipe,
    ValidationMessagesPipe,
    ValorFretePipe,
    GetUrlGoogleSafe,
    MenuInfosPipe,
    ShowCashbackMenuOptionPipe,
  ],
  exports: [
    DesktopTextPipe,
    IsOnlyOneEnablePipe,
    IsOfertaComDescontoSimplesPipe,
    MostrarPrecoUnidadeProdutoPipe,
    NavigateBackDirective,
    ObserveVisibilityDirective,
    OfferTitlePipe,
    ParcelasTextoPipe,
    PagamentoTextoPipe,
    QtdItensProdutosPipe,
    QuantidadeMaximaProdutoPipe,
    QuantidadeProdutoPipe,
    QuantidadeUnidadeDiferenteProdutoPipe,
    ReplaceVariablesPipe,
    UnidadeDiferentePipe,
    UnidadePrecoPipe,
    UnidadeQuantidadePipe,
    UnidadeSiglaPipe,
    ValidatePipe,
    ValidationMessagesPipe,
    ValorFretePipe,
    GetUrlGoogleSafe,
    MenuInfosPipe,
    ShowCashbackMenuOptionPipe,
  ],
})
export class CoreModule {}
