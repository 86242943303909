import { Directive, EventEmitter, Input, Output } from '@angular/core';
import {
  IFilter,
  IFilterOptions,
  IProduto,
  SeletorDePesoEnum,
} from '@vip/core';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';
import { ISpinEventComProduto } from '@vip/ui/spin';

@Directive()
export abstract class ProdutosAndFiltrosDirective {
  combosNoCarrinho$ = this.carrinhoItensFacade.combosNoCarrinho$;
  @Input() products: IProduto[] = [];
  @Input() filters: IFilter[] = [];
  @Input() aviseMeEnviados: Map<number, boolean> | null = new Map<
    number,
    boolean
  >();
  @Input() produtosNoCarrinho?: Map<
    number,
    {
      item_id: number;
      quantidade: number;
      observacao?: string;
      seletor_medida_id?: number | null | undefined;
    }
  > | null = null;
  @Input() loaded!: boolean;
  @Input() loading: boolean | null = null;

  @Output() addListClick = new EventEmitter<IProduto>();
  @Output()
  externalFilterClick: EventEmitter<IFilterOptions> = new EventEmitter();
  @Output() filterClick: EventEmitter<Event> = new EventEmitter();
  @Output() loadMore = new EventEmitter<void>();
  @Output() produtoChanged = new EventEmitter<ISpinEventComProduto>();
  @Output() aviseMeClick = new EventEmitter<number>();
  produtosNoCarrinho$ = this.carrinhoItensFacade.produtosNoCarrinho$;
  seletorDePesoMedida: number | null | undefined = SeletorDePesoEnum.UNIDADE;

  constructor(private carrinhoItensFacade: CarrinhoItensFacade) {}

  trackByIdEQuantidade(_: number, product: IProduto) {
    return product.id + '-' + product.quantidade;
  }

  updateProductsWithCartInfo() {
    const urlAtual = window.location.pathname;
    if (
      urlAtual.includes('/minha-conta/minhas-listas/detalhes/') ||
      urlAtual.includes('/minhas-listas/detalhes/')
    ) {
      return;
    }
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.produtosNoCarrinho$.subscribe((response) => {
      const productMap = response as Map<number, any>;
      this.products = this.products.map((product) => {
        if (productMap.has(product.produto_id)) {
          const seletorMedidaId = productMap.get(
            product.produto_id
          ).seletor_medida_id;
          return { ...product, seletor_medida_id: seletorMedidaId };
        }
        this.seletorDePesoMedida = product.seletor_medida_id;
        return product;
      });
    });
  }
}
