import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CompraEmProcessoActions from './compra-em-processo.actions';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { BucketsFacade, BucketsService } from '@vip/state/buckets';
import { ClienteActions, ClienteFacade } from '@vip/state/cliente';
import { CompraApiService } from '@vip/api';
import { FilialFacade } from '@vip/state/filial';
import { fetch } from '@nrwl/angular';
import { TipoEntregaActions, TipoEntregaFacade } from '@vip/state/tipo-entrega';
import { EnderecoFacade } from '@vip/state/endereco';
import { CompraWsService } from '@vip/ws';
import { Router } from '@angular/router';
import { DialogService, ModalService } from '@vip/ui/modal';
import { PerguntasFacade } from '@vip/state/perguntas';
import { CarrinhoActions, CarrinhoFacade } from '@vip/state/carrinho';
import {
  CentroDistribuicaoActions,
  CentroDistribuicaoFacade,
} from '@vip/state/centro-distribuicao';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';
import {
  CompraStatusEnum,
  FacebookPixelService,
  ICompra,
  IEndereco,
  IPortador,
  ITipoEntrega,
  LayoutUtilsService,
} from '@vip/core';
import { CupomActions, CupomFacade } from '@vip/state/cupom';
import {
  FormaPagamentosActions,
  FormaPagamentosFacade,
} from '@vip/state/forma-pagamento';
import { DescontoActions, DescontoFacade } from '@vip/state/desconto';
import { FidelidadeFacade } from '@vip/state/fidelidade';
import { CompraEmProcessoFacade } from './compra-em-processo.facade';
import { MessageService } from '@vip/ui/message';
import { HttpErrorResponse } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { compraEmProcessoSelectors } from './compra-em-processo.selectors';
import { mergeTakeOne } from '@vip/state/utils';
import { ParametrosActions, ParametrosFacade } from '@vip/state/parametros';
import { combineLatest } from 'rxjs';
import { LoadingFacade } from '@vip/state/loading';

@Injectable()
export class CompraEmProcessoEffects {
  getCompraEmProcesso$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CompraEmProcessoActions.getCompraEmProcesso,
          CarrinhoActions.manterCarrinhoClienteSuccess,
          ClienteActions.getClienteSuccess
        ),
        withLatestFrom(
          this.filialFacade.filial$,
          this.bucketsFacade.bucketProduto$,
          this.bucketsFacade.bucketS3$,
          this.clienteFacade.isLogged$
        ),
        map(([action, filial, bucketProdutos, bucketS3, isLogged]) => ({
          ...action,
          filial,
          bucketProdutos,
          bucketS3,
          isLogged,
        }))
      ),
    { dispatch: false }
  );

  getCompraEmProcessoSemCliente$ = createEffect(() =>
    this.getCompraEmProcesso$.pipe(
      filter((action) => !action.isLogged),
      map(() => CompraEmProcessoActions.getCompraEmProcessoEmpty())
    )
  );

  getCompraEmProcessoComCliente$ = createEffect(() =>
    this.getCompraEmProcesso$.pipe(
      filter((action) => action.isLogged),
      fetch({
        run: (action) =>
          this.compraApiService.getCompraEmProcesso(action.filial.id).pipe(
            map((res) => {
              if (res) {
                this.descontoFacade.getDescontosCompra(res.data.id);
                this.fidelidadeFacade.getDescontosFidelidadeCompra(res.data.id);
                return CompraEmProcessoActions.getCompraEmProcessoSuccess({
                  compra: {
                    ...res.data,
                    produtos: res.data.produtos.map((item) =>
                      this.bucketsService.addBucketProduto(
                        item,
                        action.bucketProdutos
                      )
                    ),
                    compra_pagamentos: res.data.compra_pagamentos.map((item) =>
                      this.bucketsService.addBucketCompraPagamento(
                        item,
                        action.bucketS3
                      )
                    ),
                  },
                });
              } else {
                return CompraEmProcessoActions.getCompraEmProcessoEmpty();
              }
            })
          ),
        onError: (action, error) =>
          CompraEmProcessoActions.getCompraEmProcessoFailure({ error }),
      })
    )
  );

  setEnderecoComCompraEmProcesso$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraEmProcessoActions.getCompraEmProcessoSuccess),
      tap((action) => {
        this.enderecoFacade.selectEnderecoEntrega(action.compra.endereco.id);
      }),
      map((action) =>
        CompraEmProcessoActions.selectTipoEntrega({ compra: action.compra })
      )
    )
  );

  setEntregaRetiradaComCompraEmProcesso$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraEmProcessoActions.selectTipoEntrega),
        tap((action) => {
          this.tipoEntregaFacade.getTipoEntrega(
            action.compra.tipo_entrega.id,
            action.compra.endereco.id,
            action.compra.total_itens
          );
          if (action.compra.tipo_entrega.horario_selecionado)
            this.tipoEntregaFacade.selectHorarioTipoEntrega(
              action.compra.tipo_entrega.horario_selecionado
            );
          this.tipoEntregaFacade.setFormaEntrega(
            !action.compra.tipo_entrega.retirada
          );
        })
      ),
    { dispatch: false }
  );

  criarCompra$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraEmProcessoActions.criarCompra),
        withLatestFrom(
          this.centroDistribuicaoFacade.filialECdSelecionado$,
          this.carrinhoFacade.carrinho$,
          this.enderecoFacade.enderecoEntrega$.pipe(
            filter(
              (enderecoEntrega): enderecoEntrega is IEndereco =>
                enderecoEntrega !== undefined
            )
          ),
          this.tipoEntregaFacade.tipoEntregaEHorarioSelecionados$,
          combineLatest([
            this.perguntasFacade.temPerguntaObrigatoriaSemResposta$,
            this.compraEmProcessoFacade.taxaServico$,
          ])
        ),
        map(
          ([
            action,
            [filial, cdSelecionado],
            carrinho,
            endereco,
            [tipoEntrega, horario],
            [temPerguntaObrigatoriaSemResposta, taxaServico],
          ]) => ({
            ...action,
            filial,
            cdSelecionado,
            carrinho,
            endereco,
            tipoEntrega,
            horario,
            temPerguntaObrigatoriaSemResposta,
            taxaServico,
          })
        )
      ),
    { dispatch: false }
  );

  criarCompraComTodosOsDados$ = createEffect(() =>
    this.criarCompra$.pipe(
      mergeTakeOne(this.layoutUtilsService.isDesktop$),
      filter(([action, isDesktop]) => {
        return (
          !!action.endereco &&
          (!action.temPerguntaObrigatoriaSemResposta || isDesktop) &&
          (action.cdSelecionado.loja_autonoma ||
            (!!action.tipoEntrega && !!action.horario))
        );
      }),
      map(([action, isDesktop]) => ({ ...action, isDesktop })),
      tap((action) => {
        this.loadingFacade.setLoading('Criando compra');
      }),
      fetch({
        run: (action) =>
          this.compraApiService
            .criarCompra(action.filial.id, action.cdSelecionado.id, {
              carrinho_id: action.carrinho.carrinho_id,
              data_entrega: action.horario ? action.horario.key : null,
              data_original_entrega: action.horario ? action.horario.key : null,
              endereco_id: action.endereco.id,
              tipo_entrega_id: action.tipoEntrega
                ? action.tipoEntrega.id
                : null,
              taxa_de_servico: action.taxaServico
                ? action.taxaServico.valor_taxa
                : null,
            })
            .pipe(
              map((res) => {
                this.loadingFacade.disableLoading();
                return CompraEmProcessoActions.criarCompraSuccess({
                  compra: res.data,
                  id: res.data.id,
                });
              })
            ),
        onError: (action, error) =>
          CompraEmProcessoActions.criarCompraFailure({ error }),
      })
    )
  );

  criarCompraSemSelecionarTipoEntrega$ = createEffect(() =>
    this.criarCompra$.pipe(
      filter(
        (action) =>
          !action.cdSelecionado.loja_autonoma &&
          (!action.endereco || !action.tipoEntrega || !action.horario)
      ),
      mergeTakeOne(this.layoutUtilsService.isDesktop$),
      map(([, isDesktop]) => {
        this.dialogService.openDialog(
          {
            open: true,
            title: 'Você não escolheu e nem agendou sua entrega!',
            subTitle:
              'Para prosseguir com o pagamento é necessário que você escolha um tipo de entrega e agende o melhor dia e horário para receber a sua compra.',
            disabled: false,
            buttonConfirmText: 'Escolher e agendar entrega',
          },
          true
        );
        this.dialogService.dialogClick.subscribe((direcionar) => {
          if (direcionar && !isDesktop)
            this.router.navigateByUrl(
              'entrega-retirada/alterar-entrega-retirada'
            );
          this.dialogService.clearDialog();
        });

        return CompraEmProcessoActions.criarCompraEmpty();
      })
    )
  );

  criarCompraSemResponderPerguntas$ = createEffect(() =>
    this.criarCompra$.pipe(
      mergeTakeOne(this.layoutUtilsService.isDesktop$),
      filter(
        ([action, isDesktop]) =>
          !isDesktop &&
          action.temPerguntaObrigatoriaSemResposta &&
          (action.cdSelecionado.loja_autonoma ||
            (!!action.endereco && !!action.tipoEntrega && !!action.horario))
      ),
      map(([action, isDesktop]) => {
        if (!isDesktop) {
          this.dialogService.openDialog(
            {
              open: true,
              title: 'Você deixou de responder uma pergunta obrigatória!',
              subTitle:
                'Para realizar o pagamento da compra, você precisa responder todas as perguntas marcadas como obrigatórias.',
              disabled: false,
              buttonConfirmText: 'Responder Perguntas',
            },
            true
          );
          this.dialogService.dialogClick.subscribe((direcionar) => {
            if (direcionar)
              this.router.navigateByUrl('resumo-compra/perguntas/pagamento');
            this.dialogService.clearDialog();
          });
        }

        return CompraEmProcessoActions.criarCompraEmpty();
      })
    )
  );

  direcionarParaPagamento$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraEmProcessoActions.criarCompraSuccess),
        tap(() => this.router.navigateByUrl('pagamento'))
      ),
    { dispatch: false }
  );

  disparaEventoInitiateCheckout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraEmProcessoActions.criarCompraSuccess),
        tap((action) =>
          this.facebookPixelService.initiateCheckout(action.compra)
        )
      ),
    { dispatch: false }
  );

  pegarDescontosFidelidade$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraEmProcessoActions.criarCompraSuccess),
        tap((action) =>
          this.fidelidadeFacade.getDescontosFidelidadeCompra(action.compra.id)
        )
      ),
    { dispatch: false }
  );

  salvaRespostasDaCompra$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CompraEmProcessoActions.criarCompraSuccess,
          CompraEmProcessoActions.finalizarCompraEmProcessoSuccess
        ),
        tap((action) => {
          if (action.id) this.perguntasFacade.salvarRespostas(action.id);
        })
      ),
    { dispatch: false }
  );

  resetaPularPergunta$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraEmProcessoActions.criarCompraSuccess),
        tap((action) => this.perguntasFacade.pularPerguntas(false))
      ),
    { dispatch: false }
  );

  exibirDialogPrimeiraCompra$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraEmProcessoActions.criarCompraFailure),
        tap(({ error }) => {
          this.dialogService.openDialog({
            open: true,
            title: 'Não foi possível efetuar a compra',
            subTitle: error.error.error,
            disabled: false,
            buttonConfirmText: 'Ir para a Home',
          });

          this.dialogService.dialogClick.subscribe((value) => {
            if (value) {
              this.router.navigateByUrl('/home');
            }
            this.dialogService.clearDialog();
          });
        })
      ),
    { dispatch: false }
  );

  continuarComprandoComCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraEmProcessoActions.recriarCarrinhoContinuarComprando),
      withLatestFrom(
        this.centroDistribuicaoFacade.filialECdSelecionado$,
        this.carrinhoFacade.carrinho$
      ),
      map(([action, [filial, cd], carrinho]) => ({
        ...action,
        filial,
        cd,
        carrinho,
      })),
      filter((action) => {
        return !!action.carrinho?.carrinho_id;
      }),
      fetch({
        run: (action) =>
          CompraEmProcessoActions.continuarComprando({
            compraId: action.compraId,
            filialId: action.filial.id,
            cdId: action.cd.id,
          }),
        onError: (_action, error) => {
          return CompraEmProcessoActions.continuarComprandoFailure({ error });
        },
      })
    )
  );

  continuarComprandoSemCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraEmProcessoActions.recriarCarrinhoContinuarComprando),
      withLatestFrom(
        this.centroDistribuicaoFacade.filialECdSelecionado$,
        this.carrinhoFacade.carrinho$
      ),
      map(([action, [filial, cd], carrinho]) => ({
        ...action,
        filial,
        cd,
        carrinho,
      })),
      filter((action) => {
        return !action.carrinho?.carrinho_id;
      }),
      fetch({
        run: (action) => {
          return this.compraApiService
            .repetirCompra(action.filial.id, action.cd.id, action.compraId)
            .pipe(
              map((res) => {
                this.carrinhoFacade.setCarrinho(res.data.carrinho);
                return CompraEmProcessoActions.continuarComprando({
                  compraId: action.compraId,
                  filialId: action.filial.id,
                  cdId: action.cd.id,
                });
              })
            );
        },
        onError: (_action, error) => {
          return CompraEmProcessoActions.continuarComprandoFailure({ error });
        },
      })
    )
  );

  continuarComprando$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraEmProcessoActions.continuarComprando),
      fetch({
        run: (action) => {
          const { compraId, filialId, cdId } = action;

          return this.compraApiService
            .continuarComprando(filialId, compraId)
            .pipe(
              map(() =>
                CompraEmProcessoActions.continuarComprandoSuccess({
                  compraId,
                  cdId,
                  filialId,
                })
              )
            );
        },
        onError: (action, error) =>
          CompraEmProcessoActions.continuarComprandoFailure({ error }),
      })
    )
  );

  continuarComprandoSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraEmProcessoActions.continuarComprandoSuccess),
        withLatestFrom(this.layoutUtilsService.isDesktop$),
        tap(([action, isDesktop]) => {
          this.compraEmProcessoFacade.getTaxaServico();
          this.carrinhoFacade.getCarrinho();
          this.carrinhoItensFacade.getItensCarrinho();
          if (!isDesktop) {
            this.router.navigateByUrl('resumo-compra');
          } else {
            this.router.navigateByUrl('loja');
          }
        })
      ),
    { dispatch: false }
  );

  migrarCentroDistribuicao$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CentroDistribuicaoActions.migrarCentroDistribuicao),
      mergeTakeOne(
        this.store.pipe(
          select(compraEmProcessoSelectors.getCompraEmProcesso(this.store))
        )
      ),
      map(([action, compra]) => ({
        ...action,
        compra,
      })),
      filter((action) => {
        return !!action.compra;
      }),
      fetch({
        run: (action) => {
          const compraId = action.compra?.id || 0;
          const filialId = action.compra?.filial_id || 0;

          return this.compraApiService
            .continuarComprando(filialId, compraId)
            .pipe(
              map(() => CompraEmProcessoActions.getCompraEmProcessoEmpty())
            );
        },
        onError: (action, error) =>
          CompraEmProcessoActions.continuarComprandoFailure({ error }),
      })
    )
  );

  setTentativaPagamentoPre$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraEmProcessoActions.finalizarCompraEmProcessoFailure),
        mergeTakeOne(this.parametroFacade.tentativasRealizadas$),
        filter(
          ([{ compra_pagamentos }]) =>
            compra_pagamentos !== undefined &&
            !!compra_pagamentos.find((pagamento) => pagamento.online)
        ),
        tap(([, tentativas]) => {
          this.store.dispatch(
            ParametrosActions.setTentativasRealizadas({
              tentativas: Number(tentativas) + 1,
            })
          );
        })
      ),
    { dispatch: false }
  );

  finalizarCompraEmProcesso$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraEmProcessoActions.finalizarCompraEmProcesso),
      fetch({
        run: (action) => {
          return this.compraWSService
            .finalizarCompra(action.filialId, action.payload)
            .pipe(
              map((data) => {
                if (data.error) {
                  this.messageService.openErrorMessage(data.message, 2);
                  return CompraEmProcessoActions.finalizarCompraEmProcessoFailure(
                    {
                      error: new HttpErrorResponse({
                        status: 400,
                        error: { error: data.message },
                      }),
                      compra_pagamentos: action.payload.compra_pagamentos,
                    }
                  );
                } else {
                  return CompraEmProcessoActions.finalizarCompraEmProcessoSuccess(
                    {
                      pedido: data.pedido,
                      current_date: data.current_date,
                      id: data.pedido.id,
                    }
                  );
                }
              })
            );
        },
        onError: (action, error) =>
          CompraEmProcessoActions.finalizarCompraEmProcessoFailure({ error }),
      })
    )
  );

  getTaxaServico$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraEmProcessoActions.getTaxaServico),
      mergeTakeOne(
        this.centroDistribuicaoFacade.filialECdSelecionado$,
        this.carrinhoFacade.carrinho$,
        this.store.pipe(
          select(compraEmProcessoSelectors.getCompraEmProcesso(this.store))
        )
      ),
      fetch({
        run: (action, [filial, cd], carrinho, compraEmProcesso) =>
          this.compraApiService
            .getTaxaServico(
              carrinho?.preco ?? compraEmProcesso?.valor_inicial,
              filial.id,
              cd.id
            )
            .pipe(
              map((res) =>
                CompraEmProcessoActions.getTaxaServicoSuccess({
                  taxaServico: res,
                })
              )
            ),
        onError: (action, error) => {
          return CompraEmProcessoActions.getTaxaServicoFailure({
            error,
          });
        },
      })
    )
  );

  atualizaTaxaServico$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoActions.getCarrinhoSuccess),
        filter((action) => action.carrinho && action.carrinho.quantidade > 0),
        tap((action) => this.compraEmProcessoFacade.getTaxaServico())
      ),
    { dispatch: false }
  );

  redirectFinalizarCompra$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraEmProcessoActions.finalizarCompraEmProcessoSuccess),
        tap((action) => {
          this.carrinhoFacade.getCarrinho();
          if (action.pedido.efetuar_pagamento) {
            this.router.navigateByUrl('pagamento/carteira-digital');
          } else if (
            action.pedido.compra_status_id !==
            CompraStatusEnum.EM_PROCESSO_DE_COMPRA
          ) {
            this.redirecionarParaResumo(action.pedido.id);
          }
        })
      ),
    { dispatch: false }
  );

  fecharModalCaptcha$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CompraEmProcessoActions.finalizarCompraEmProcessoSuccess,
          CompraEmProcessoActions.finalizarCompraEmProcessoFailure
        ),
        tap(() => {
          this.modalService.clearModal();
        })
      ),
    { dispatch: false }
  );

  cancelarPagamentoDigital$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraEmProcessoActions.cancelarPagamentoDigital),
      fetch({
        run: (action) => {
          return this.compraWSService
            .cancelarPagamentoDigital(
              action.filialId,
              action.compraId,
              action.compraPagamentoId
            )
            .pipe(
              map((data) => {
                return CompraEmProcessoActions.cancelarPagamentoDigitalSuccess({
                  message: data.message,
                });
              })
            );
        },
        onError: (action, error) => {
          if (error?.error?.error) {
            this.messageService.openErrorMessage(error.error.error, 2);
          }
          return CompraEmProcessoActions.cancelarPagamentoDigitalFailure({
            error,
          });
        },
      })
    )
  );

  cancelarPagamentoDigitalSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraEmProcessoActions.cancelarPagamentoDigitalSuccess),
        tap(() => {
          this.router.navigateByUrl('pagamento');
        })
      ),
    { dispatch: false }
  );

  verificarPagamentoDigital$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraEmProcessoActions.verificarPagamentoDigital),
      fetch({
        run: (action) => {
          return this.compraWSService
            .verificarPagamentoDigital(
              action.filialId,
              action.compraId,
              action.compraPagamentoId
            )
            .pipe(
              map((data) => {
                return CompraEmProcessoActions.verificarPagamentoDigitalSuccess(
                  {
                    message: data.message,
                    success: data.success,
                    compraId: action.compraId,
                  }
                );
              })
            );
        },
        onError: (action, error) => {
          if (error?.error?.error) {
            this.messageService.openErrorMessage(error.error.error, 2);
          }
          return CompraEmProcessoActions.verificarPagamentoDigitalFailure({
            error,
          });
        },
      })
    )
  );

  verificarPagamentoDigitalSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraEmProcessoActions.verificarPagamentoDigitalSuccess),
      filter(({ success }) => success),
      map(({ compraId }) => {
        return CompraEmProcessoActions.pagamentoDigitalEfetuado({ compraId });
      })
    )
  );

  pagamentoDigitalEfetuado$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraEmProcessoActions.pagamentoDigitalEfetuado),
        tap((action) => {
          this.redirecionarParaResumo(action.compraId);
        })
      ),
    { dispatch: false }
  );

  resetarStatesCompra$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CompraEmProcessoActions.continuarComprandoSuccess,
          CompraEmProcessoActions.finalizarCompraEmProcessoSuccess,
          CompraEmProcessoActions.verificarPagamentoDigitalSuccess
        ),
        filter(
          (action) =>
            (action.type !==
              '[CompraEmProcesso/API] Finalizar Compra Em Processo Success' ||
              (!action.pedido.efetuar_pagamento &&
                action.pedido.compra_status_id !==
                  CompraStatusEnum.EM_PROCESSO_DE_COMPRA)) &&
            (action.type !==
              '[CompraEmProcesso/API] Verificar Pagamento Digital Success' ||
              action.success)
        ),
        tap(() => {
          this.compraEmProcessoFacade.resetCompraEmProcesso();
          this.cupomFacade.resetCupons();
          this.descontoFacade.resetDescontos();
          this.formaPagamentoFacade.resetFormaPagamentoSelecionada();
          this.tipoEntregaFacade.resetTiposEntregaSelecionada();
          this.fidelidadeFacade.resetDescontosFidelidadeCompra();
          this.perguntasFacade.limparRespostas();
        })
      ),
    { dispatch: false }
  );

  updateParcelas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraEmProcessoActions.updateParcelasCompraEmProcesso),
      mergeTakeOne(
        this.store.pipe(
          select(compraEmProcessoSelectors.getCompraEmProcesso(this.store)),
          filter(
            (
              compraEmProcesso
            ): compraEmProcesso is ICompra & { portador: IPortador } =>
              !!compraEmProcesso && !!compraEmProcesso.portador
          )
        )
      ),
      map(([{ parcelas }, compraEmProcesso]) => {
        const portador = compraEmProcesso.portador;
        return CompraEmProcessoActions.setPortadorCompraEmProcesso({
          portador: { ...portador, installments: parcelas },
        });
      })
    )
  );

  updateCVV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraEmProcessoActions.updateCVVCompraEmProcesso),
      mergeTakeOne(
        this.store.pipe(
          select(compraEmProcessoSelectors.getCompraEmProcesso(this.store)),
          filter(
            (
              compraEmProcesso
            ): compraEmProcesso is ICompra & { portador: IPortador } =>
              !!compraEmProcesso && !!compraEmProcesso.portador
          )
        )
      ),
      map(([{ cvv }, compraEmProcesso]) => {
        const portador = compraEmProcesso.portador;

        return CompraEmProcessoActions.setPortadorCompraEmProcesso({
          portador: { ...portador, cc_cvv: cvv },
        });
      })
    )
  );

  setarTipoEntregaLojaAutonoma$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraEmProcessoActions.criarCompraSuccess),
      withLatestFrom(this.centroDistribuicaoFacade.isLojaAutonoma$),
      filter(([, isLojaAutonoma]) => !!isLojaAutonoma),
      fetch({
        run: (action, lojaAutonoma) => {
          return TipoEntregaActions.getTipoEntregaSuccess({
            tiposEntregas: [
              action.compra.tipo_entrega as unknown as ITipoEntrega,
            ],
          });
        },
      })
    )
  );

  resetPermiteSelecaoManualAposConcluirCompra$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraEmProcessoActions.finalizarCompraEmProcessoSuccess),
        tap(() => {
          this.formaPagamentoFacade.resetPermiteSelecaoManual();
        })
      ),
    { dispatch: false }
  );

  $resetPortador = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FormaPagamentosActions.resetFormaPagamentoSelecionada),
        mergeTakeOne(
          this.store.pipe(
            select(compraEmProcessoSelectors.getCompraEmProcesso(this.store))
          )
        ),
        tap(([action, compraEmProcesso]) => {
          if (compraEmProcesso?.portador) {
            this.compraEmProcessoFacade.setPortador();
          }
        })
      ),
    { dispatch: false }
  );

  $atualizarCashbackAposAplicarDesconto = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DescontoActions.validarCodigoSuccessValido,
          DescontoActions.validarCodigoFreteSuccessValido
        ),
        mergeTakeOne(
          this.cupomFacade.totalCuponsSelecionados$,
          this.fidelidadeFacade.totalDescontoFidelidade$,
          this.compraEmProcessoFacade.compraEmProcesso$
        ),
        map(([action, valorCupom, valorFidelidade, compraEmProcesso]) => {
          const valorDescontoAlterado =
            action?.descontosValidados.reduce(
              (acc, desconto) => acc + desconto.valor,
              0
            ) || 0;
          const totalDescontos =
            valorDescontoAlterado + valorCupom + valorFidelidade;
          const valorCompra = compraEmProcesso?.valor_final || 0;
          this.aplicarCashbackNaCompra(totalDescontos, valorCompra, true);
        })
      ),
    { dispatch: false }
  );

  $atualizarCashbackAposAplicarCupom = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CupomActions.setCuponsSelecionados),
        mergeTakeOne(
          this.descontoFacade.totalDesconto$,
          this.fidelidadeFacade.totalDescontoFidelidade$,
          this.compraEmProcessoFacade.compraEmProcesso$
        ),
        map(([action, valorDesconto, valorFidelidade, compraEmProcesso]) => {
          const valorCupomAlterado =
            action.selecionados.reduce((acc, cupom) => acc + cupom.valor, 0) ||
            0;
          const totalDescontos =
            valorDesconto + valorCupomAlterado + valorFidelidade;
          const valorCompra = compraEmProcesso?.valor_final || 0;
          this.aplicarCashbackNaCompra(totalDescontos, valorCompra, true);
        })
      ),
    { dispatch: false }
  );

  $setCashbackCompraEmProcesso = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraEmProcessoActions.setCashbackCompraEmProcesso),
        mergeTakeOne(
          this.cupomFacade.totalCuponsSelecionados$,
          this.descontoFacade.totalDesconto$,
          this.fidelidadeFacade.totalDescontoFidelidade$,
          this.compraEmProcessoFacade.compraEmProcesso$
        ),
        map(
          ([
            action,
            valorCupom,
            valorDesconto,
            valorFidelidade,
            compraEmProcesso,
          ]) => {
            const totalDescontos = valorCupom + valorDesconto + valorFidelidade;
            const valorCompra = compraEmProcesso?.valor_final || 0;
            this.aplicarCashbackNaCompra(
              totalDescontos,
              valorCompra,
              false,
              action.valorCashback,
              action.valorCashback > 0
            );
          }
        )
      ),
    { dispatch: false }
  );

  getCompraView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraEmProcessoActions.getCompraView),
      withLatestFrom(this.filialFacade.filial$),
      map(([action, filial]) => ({ ...action, filial })),
      fetch({
        run: (action) =>
          this.compraWSService
            .viewCompra(action.filial.id, action.compraId)
            .pipe(
              map((res) =>
                CompraEmProcessoActions.getCompraViewSuccess({
                  compra: res.pedido,
                })
              )
            ),
        onError: (action, error) =>
          CompraEmProcessoActions.getCompraViewFailure({ error }),
      })
    )
  );

  aplicarCashbackNaCompra(
    valorDescontosSemCashback: number,
    valorCompra: number,
    valorAjustado: boolean,
    novoValorCashbackUtilizado?: number,
    exibirMensagem?: boolean
  ) {
    this.compraEmProcessoFacade.cashbackUtilizado$
      .pipe(take(1))
      .subscribe((cashbackUtilizado) => {
        const valorCompraComDescontos = valorCompra - valorDescontosSemCashback;
        let message = 'O valor do cashback foi aplicado na compra.';
        novoValorCashbackUtilizado =
          novoValorCashbackUtilizado ?? cashbackUtilizado;
        if (valorCompraComDescontos < novoValorCashbackUtilizado) {
          novoValorCashbackUtilizado = valorCompraComDescontos;
          if (valorAjustado) {
            message = 'O valor do cashback foi ajustado na compra.';
            exibirMensagem = true;
          }
        }
        this.compraEmProcessoFacade.setCashbackSuccess(
          novoValorCashbackUtilizado
        );
        if (cashbackUtilizado == 0 && novoValorCashbackUtilizado <= 0) return;
        if (exibirMensagem) {
          this.messageService.openSuccessMessage(message, 3);
        }
      });
  }

  redirecionarParaResumo(compraId: number) {
    this.carrinhoFacade.resetCarrinho();
    this.carrinhoItensFacade.resetItensCarrinho();
    this.router.navigateByUrl(`compra-finalizada/${compraId}`);
  }

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private bucketsFacade: BucketsFacade,
    private facebookPixelService: FacebookPixelService,
    private bucketsService: BucketsService,
    private carrinhoFacade: CarrinhoFacade,
    private carrinhoItensFacade: CarrinhoItensFacade,
    private centroDistribuicaoFacade: CentroDistribuicaoFacade,
    private clienteFacade: ClienteFacade,
    private compraApiService: CompraApiService,
    private compraWSService: CompraWsService,
    private dialogService: DialogService,
    private enderecoFacade: EnderecoFacade,
    private filialFacade: FilialFacade,
    private perguntasFacade: PerguntasFacade,
    private router: Router,
    private tipoEntregaFacade: TipoEntregaFacade,
    private cupomFacade: CupomFacade,
    private formaPagamentoFacade: FormaPagamentosFacade,
    private descontoFacade: DescontoFacade,
    private fidelidadeFacade: FidelidadeFacade,
    private compraEmProcessoFacade: CompraEmProcessoFacade,
    private messageService: MessageService,
    private parametroFacade: ParametrosFacade,
    private modalService: ModalService<unknown>,
    private layoutUtilsService: LayoutUtilsService,
    private loadingFacade: LoadingFacade
  ) {}
}
