import { Action, createReducer, on } from '@ngrx/store';
import * as ClienteActions from './cliente.actions';
import { ICliente } from '@vip/core';
import { GenericStoreStatusEnum, IGenericState } from '@vip/state/utils';
import { ConsultaSolicitacaoSMS } from '@vip/api';

export const CLIENTE_FEATURE_KEY = 'cliente';

export interface ClienteData {
  cliente: ICliente | null;
  token: string | null;
  solicitacaoPin: ConsultaSolicitacaoSMS | null;
}

export type ClienteState = IGenericState<ClienteData>;

export const initialState: ClienteState = {
  data: {
    cliente: null,
    token: null,
    solicitacaoPin: null,
  },
  status: 'pending',
  error: null,
};

const clienteReducer = createReducer(
  initialState,
  on(
    ClienteActions.login,
    ClienteActions.logout,
    ClienteActions.editCliente,
    ClienteActions.addCliente,
    ClienteActions.solicitaSMS,
    ClienteActions.validaPinLogin,
    ClienteActions.validarOTP,
    ClienteActions.getClienteData,
    (state) => ({
      ...state,
      status: GenericStoreStatusEnum.LOADING,
      error: null,
    })
  ),

  on(
    ClienteActions.loginSuccess,
    ClienteActions.validaPinLoginSucess,
    (state, { token }) => ({
      ...state,
      data: {
        ...state.data,
        token: token,
      },
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
    })
  ),
  on(ClienteActions.getClienteSuccess, (state, { cliente, token }) => ({
    ...state,
    data: {
      ...state.data,
      cliente: {
        ...cliente,
      },
      token,
    },
    status: GenericStoreStatusEnum.SUCCESS,
    error: null,
  })),
  on(ClienteActions.editClienteSuccess, (state, { cliente }) => ({
    ...state,
    data: {
      ...state.data,
      cliente: {
        ...cliente,
      },
    },
    status: GenericStoreStatusEnum.SUCCESS,
    error: null,
  })),
  on(ClienteActions.validarOTPSuccess, (state, { telefoneCelular }) => ({
    ...state,
    data: {
      ...state.data,
      cliente: {
        ...(state.data.cliente as ICliente),
        telefone_celular: telefoneCelular,
        telefone_celular_formatado: telefoneCelular.replace(
          /^(\d{2})(\d{5})(\d{4}).*/,
          '($1) $2-$3'
        ),
      },
    },
    status: GenericStoreStatusEnum.SUCCESS,
    error: null,
  })),
  on(ClienteActions.logoutSuccess, (state) => ({
    ...state,
    ...initialState,
  })),
  on(
    ClienteActions.loginFailure,
    ClienteActions.getClienteFailure,
    ClienteActions.editClienteFailure,
    ClienteActions.addClienteFailure,
    ClienteActions.solicitaSMSFailure,
    ClienteActions.solicitaSMSLoginFailure,
    ClienteActions.getConsultaSMSFailure,
    ClienteActions.validarOTPFailure,
    ClienteActions.validaPinLoginFailure,
    ClienteActions.getPinParameterFailure,
    (state, { error }) => ({
      ...state,
      status: GenericStoreStatusEnum.ERROR,
      error: error.error,
    })
  ),
  on(ClienteActions.solicitaSMSLogin, (state) => ({
    ...state,
    data: {
      ...state.data,
      solicitacaoPin: null,
    },
    status: GenericStoreStatusEnum.LOADING,
    error: null,
  })),
  on(
    ClienteActions.getConsultaSMSSucess,
    ClienteActions.solicitaSMSLoginSucess,
    (state, { consultaSMS }) => ({
      ...state,
      status: GenericStoreStatusEnum.SUCCESS,
      data: {
        ...state.data,
        solicitacaoPin: { ...consultaSMS },
      },
      error: null,
    })
  )
);

export function reducer(state: ClienteState | undefined, action: Action) {
  return clienteReducer(state, action);
}
