import { Action, createReducer, on } from '@ngrx/store';
import { ICarrinho, IProdutoCarrinho } from '@vip/core';
import * as CarrinhoActions from './carrinho.actions';
import { GenericStoreStatusEnum, IGenericState } from '@vip/state/utils';

export const CARRINHO_FEATURE_KEY = 'carrinho';

export interface CarrinhoState extends IGenericState<ICarrinho | null> {
  itensCarrinhoAntigo: IProdutoCarrinho[] | null;
  carrinhoEmOutroCD: ICarrinho | null;
}

export const initialState: CarrinhoState = {
  data: null,
  itensCarrinhoAntigo: null,
  carrinhoEmOutroCD: null,
  status: GenericStoreStatusEnum.PENDING,
  error: null,
};

const reducer = createReducer(
  initialState,
  on(
    CarrinhoActions.getCarrinho,
    CarrinhoActions.removeCarrinho,
    CarrinhoActions.verificaItensDesativados,
    (state) => ({
      ...state,
      status: GenericStoreStatusEnum.LOADING,
      error: null,
    })
  ),
  on(
    CarrinhoActions.getCarrinhoSuccess,
    CarrinhoActions.setCarrinho,
    (state, { carrinho }) => ({
      ...state,
      data: { ...carrinho },
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
    })
  ),
  on(
    CarrinhoActions.getCarrinhoFailure,
    CarrinhoActions.verificaItensDesativadosFailure,
    (state, { error }) => ({
      ...state,
      status: GenericStoreStatusEnum.ERROR,
      error: error.message,
    })
  ),
  on(CarrinhoActions.getCarrinhoFailure, (state, { error }) => ({
    ...state,
    status: GenericStoreStatusEnum.ERROR,
    itensCarrinhoAntigo: error.error?.error?.itens,
    carrinhoEmOutroCD: error.error?.error,
    error: error.message,
  })),
  on(CarrinhoActions.setCarrinhoOutroCD, (state, { carrinho }) => ({
    ...state,
    status: GenericStoreStatusEnum.ERROR,
    itensCarrinhoAntigo: carrinho.itens || null,
    carrinhoEmOutroCD: carrinho,
  })),
  on(
    CarrinhoActions.removeCarrinhoSuccess,
    CarrinhoActions.resetCarrinho,
    () => ({
      ...initialState,
    })
  ),
  on(
    CarrinhoActions.situacaoCarrinhoSessaoClienteSuccess,
    (state, { situacao }) => ({
      ...state,
      itensCarrinhoAntigo: situacao.itensCarrinhoAntigo,
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
    })
  ),
  on(
    CarrinhoActions.manterCarrinhoAnonimoSuccess,
    CarrinhoActions.manterCarrinhoClienteSuccess,
    (state) => ({
      ...state,
      itensCarrinhoAntigo: null,
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
    })
  ),
  on(CarrinhoActions.verificaItensDesativadosSuccess, (state) => ({
    ...state,
    status: GenericStoreStatusEnum.SUCCESS,
    error: null,
  }))
);

export function carrinhoReducer(
  state: CarrinhoState | undefined,
  action: Action
) {
  return reducer(state, action);
}
