import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LayoutUtilsService } from '@vip/core';

@Injectable()
export class SiteLojaGuard implements CanActivate {
  constructor(
    private router: Router,
    private layoutUtilsServices: LayoutUtilsService
  ) {}

  canActivate(): boolean {
    if (!this.layoutUtilsServices.isDesktopWithoutScreenWidth()) {
      return true;
    }
    this.router.navigateByUrl('/');
    return false;
  }
}
