import { createSelector, Store } from '@ngrx/store';
import { SelectorUtil } from '@vip/state/utils';

import {
  CompraEmProcessoProviderState,
  compraEmProcessoProviderAdapter,
  COMPRA_EM_PROCESSO_PROVIDER_FEATURE_KEY,
} from './compra-em-processo-provider.reducer';
import { ICompra } from '@vip/core';

const { selectEntities } = compraEmProcessoProviderAdapter.getSelectors();

class CompraEmProcessoProviderSelectors extends SelectorUtil<CompraEmProcessoProviderState> {
  constructor() {
    super(COMPRA_EM_PROCESSO_PROVIDER_FEATURE_KEY);
  }

  getAllCompras = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CompraEmProcessoProviderState) => selectEntities(state)
    );
  getCompraEmProcessoId = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CompraEmProcessoProviderState) => state.compraEmProcessoId
    );

  getCompraEmProcesso = () =>
    createSelector(
      this.getAllCompras(),
      this.getCompraEmProcessoId(),
      this.isPending(),
      (entities, selectedId) => {
        if (selectedId && entities) return entities[selectedId];
        return null;
      }
    );
}

export const compraEmProcessoProviderSelectors =
  new CompraEmProcessoProviderSelectors();
