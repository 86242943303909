import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListaProdutosSelecionadosComponent } from './lista-produtos-selecionados/lista-produtos-selecionados.component';
import { CardProdutoModule } from '@vip/ui/card-produto';
import { ListaProdutosSelecionadosSkeletonComponent } from './lista-produtos-selecionados-skeleton/lista-produtos-selecionados-skeleton.component';
import { SkeletonModule } from '@vip/ui/skeleton';
import { CardComboModule } from '@vip/ui/card-combo';
@NgModule({
  imports: [CommonModule, CardProdutoModule, SkeletonModule, CardComboModule],
  declarations: [
    ListaProdutosSelecionadosComponent,
    ListaProdutosSelecionadosSkeletonComponent,
  ],
  exports: [
    ListaProdutosSelecionadosComponent,
    ListaProdutosSelecionadosSkeletonComponent,
  ],
})
export class ListaProdutosSelecionadosModule {}
