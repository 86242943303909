<p
  *ngIf="!isLoading"
  class="vip-color-first-default text-xs mb-1"
  data-cy="vip-location-tipo-entrega"
>
  {{ retiradaLoja || isLojaAutonoma ? 'Retirar na Loja:' : 'Entregar no endereço:' }}
</p>
<ng-container
  *vipSkeleton="isLoading; width: '120px'; height: '14px'; className: ['mb-1']"
></ng-container>

<div
  *ngIf="!isLoading"
  class="flex flex-row items-center cursor-pointer"
  (click)="enderecoClick.emit()"
  data-cy="vip-location-endereco"
>
  <i
    class="{{ icon }} vip-color-{{ color }}-main text-xl leading-none mr-1"
  ></i>
  <span class="vip-color-first-default text-sm font-bold">{{ endereco }}</span>
  <i
    class="icon-expand_more vip-color-{{ color }}-main text-3xl leading-none"
  ></i>
</div>
<ng-container
  *vipSkeleton="
    isLoading;
    width: '206px';
    height: '24px';
    className: ['vip-skeleton-location-endereco']
  "
></ng-container>
