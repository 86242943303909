import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { RadioButtonComponent } from '../radio-button/radio-button.component';
import { Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'vip-radio-pill',
  templateUrl: './radio-pill.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: RadioButtonComponent, useExisting: RadioPillComponent },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class RadioPillComponent extends RadioButtonComponent {
  @Input() icon = '';
  @Input() color: 'primary' | 'secondary' = 'primary';
  @Input() label!: string;
  @Input() count = '';
  @Input() value!: string;
  @Output() changed = new EventEmitter();

  constructor(changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }
}
