import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  CoreModule,
  IsOfertaComDescontoSimplesPipe,
  MostrarPrecoUnidadeProdutoPipe,
  ProdutoUtilsService,
} from '@vip/core';
import { FilialStateModule } from '@vip/state/filial';

import { ProdutoPrecoComponent } from './produto-preco/produto-preco.component';
import { ProdutoPrecoOfertaTagComponent } from './produto-preco-oferta-tag/produto-preco-oferta-tag.component';
import { ProdutoPrecoValorComponent } from './produto-preco-valor/produto-preco-valor.component';
import { ProdutoPrecoPipe } from './produto-preco.pipe';
import { ProdutoPrecoUnidadeComponent } from './produto-preco-unidade/produto-preco-unidade.component';

@NgModule({
  imports: [CommonModule, CoreModule, RouterModule],
  declarations: [
    ProdutoPrecoComponent,
    ProdutoPrecoOfertaTagComponent,
    ProdutoPrecoUnidadeComponent,
    ProdutoPrecoValorComponent,
    ProdutoPrecoPipe,
  ],
  exports: [
    ProdutoPrecoComponent,
    ProdutoPrecoPipe,
    ProdutoPrecoOfertaTagComponent,
    ProdutoPrecoUnidadeComponent,
    ProdutoPrecoValorComponent,
  ],
  providers: [
    ProdutoUtilsService,
    MostrarPrecoUnidadeProdutoPipe,
    IsOfertaComDescontoSimplesPipe,
  ],
})
export class ProdutoPrecoModule {}
