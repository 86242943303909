import { Pipe, PipeTransform } from '@angular/core';
import { IProduto } from '../../interfaces';

@Pipe({ name: 'unidadeSigla' })
export class UnidadeSiglaPipe implements PipeTransform {
  transform(produto: IProduto): string {
    return produto.unidade_fracao && !produto.exibe_preco_original
      ? produto.unidade_fracao.fracao + produto.unidade_fracao.sigla
      : produto.unidade_sigla.toLowerCase();
  }
}

