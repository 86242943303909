<div
  class="tab-item"
  (click)="onTabClick()"
  [ngClass]="{
    'rounded-full': rounded,
    selected: selected,
    'no-border': noBorder
  }"
  vipRipple
  [rippleColor]="rippleColor"
  rippleBorderRadius="0px"
>
  <i
    *ngIf="icon"
    class="mr-1 {{ iconColor ? iconColor : color }}"
    [ngClass]="[icon]"
  ></i>
  <span
    class="label {{ color }}"
    [class.text-sm]="isDesktop && !selected"
    [class.text-base]="!isDesktop || selected"
  >
    {{ label }} <b>{{ count }}</b>
  </span>
</div>
