import { Injectable } from '@angular/core';
import { BaseApiService } from '../common/base-api-service';

import { Observable } from 'rxjs';
import { pluck, share } from 'rxjs/operators';
import {
  HorariosFormatadosCalendario,
  IHorariosFormatados,
  ITipoEntrega,
  TipoClienteEnum,
} from '@vip/core';

@Injectable({
  providedIn: 'root',
})
export class TipoEntregaApiService {
  constructor(private api: BaseApiService<TipoEntregaApiService>) {}

  realizaEntrega(filiaId: number): Observable<boolean> {
    return this.api
      .get<boolean>(
        'realizaEntrega',
        `/filial/${filiaId}/loja/tipo_entregas/realiza_entrega`
      )
      .pipe(pluck('data'));
  }

  getFretesPorCep(filialId: number, cep: string): Observable<ITipoEntrega[]> {
    return this.api
      .get<ITipoEntrega[]>(
        'getFretesPorCep',
        `/filial/${filialId}/loja/tipo_entregas/calcular_frete_cep/cep/${cep}`
      )
      .pipe(pluck('data'));
  }

  getTipoEntregaPadrao(
    filialId: number,
    centroDistribuicaoId: number,
    enderecoId: number,
    totalItens: number,
    compraId: number
  ): Observable<ITipoEntrega[]> {
    return this.api
      .get<ITipoEntrega[]>(
        'getTipoEntregaPadrao',
        `${this.api.filialAndCdUrl(
          filialId,
          centroDistribuicaoId
        )}/compras/checkout/calcular_frete/endereco/${enderecoId}/total_itens/${totalItens}/compra/${compraId}/tipo_entrega_padrao`
      )
      .pipe(pluck('data'));
  }

  getTiposEntregasEndereco(
    filialId: number,
    centroDistribuicaoId: number,
    enderecoId: number,
    totalItens: number,
    pesoTotal: number,
    tipoCiente: TipoClienteEnum
  ): Observable<ITipoEntrega[]> {
    return this.api
      .get<ITipoEntrega[]>(
        'getTiposEntregasEndereco',
        `${this.api.filialAndCdUrl(
          filialId,
          centroDistribuicaoId
        )}/compras/checkout/calcular_frete/endereco/${enderecoId}/total_itens/${totalItens}?peso_itens=${pesoTotal}&tipo_cliente=${tipoCiente}`
      )
      .pipe(share(), pluck('data'));
  }

  getTipoEntrega(
    filialId: number,
    centroDistribuicaoId: number,
    enderecoId: number,
    totalItens: number,
    tipoEntregaId: number
  ): Observable<ITipoEntrega[]> {
    return this.api
      .get<ITipoEntrega[]>(
        'getTipoEntrega',
        `${this.api.filialAndCdUrl(
          filialId,
          centroDistribuicaoId
        )}/compras/checkout/calcular_frete/endereco/${enderecoId}/total_itens/${totalItens}/tipo_entrega/${tipoEntregaId}`
      )
      .pipe(pluck('data'));
  }

  getHorarios(
    filialId: number,
    centroDistribuicaoId: number,
    tipoEntregaId: string
  ): Observable<{
    horarios_formatados: IHorariosFormatados[];
    horarios_formatados_calendario: HorariosFormatadosCalendario[];
  }> {
    return this.api
      .get(
        'getHorarios',
        `/filial/${filialId}/centro_distribuicao/${centroDistribuicaoId}/loja/tipo_entregas/${tipoEntregaId}/agenda`
      )
      .pipe(pluck('data'));
  }
}
