import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, I18nPluralPipe } from '@angular/common';
import { CardProdutoComponent } from './card-produto/card-produto.component';
import { ImageModule } from '@vip/ui/image';
import { SpinModule } from '@vip/ui/spin';
import { RippleModule } from '@vip/ui/ripple';
import { ButtonModule } from '@vip/ui/button';
import { TagsModule } from '@vip/ui/tags';
import {
  CoreModule,
  MostrarPrecoUnidadeProdutoPipe,
  ProdutoUtilsService,
  QuantidadeMaximaProdutoPipe,
  QuantidadeProdutoPipe,
  QuantidadeUnidadeDiferenteProdutoPipe,
  UnidadeDiferentePipe,
  UnidadePrecoPipe,
  UnidadeSiglaPipe,
} from '@vip/core';
import { TagCollectionModule } from '@vip/ui/tag-collection';
import { CardProdutoMiniComponent } from './card-produto-mini/card-produto-mini.component';
import { CardProdutoListaComponent } from './card-produto-lista/card-produto-lista.component';
import { ModalModule } from '@vip/ui/modal';
import { CardProdutoObservacaoComponent } from './card-produto-observacao/card-produto-observacao.component';
import { CardProdutoComboComponent } from './card-produto-combo/card-produto-combo.component';
import { RouterModule } from '@angular/router';
import { ProdutoPrecoModule } from '@vip/ui/produto-preco';
import { CardProdutoSubstituirComponent } from './card-produto-substituir/card-produto-substituir.component';
import { SimpleTextFormModule } from '@vip/ui/simple-text-form';
import { CardProdutoSkeletonComponent } from './card-produto-skeleton/card-produto-skeleton.component';
import { SkeletonModule } from '@vip/ui/skeleton';
import { CardProdutoSubstituirSkeletonComponent } from './card-produto-substituir-skeleton/card-produto-substituir-skeleton.component';
import { CardProdutoListaSkeletonComponent } from './card-produto-lista-skeleton/card-produto-lista-skeleton.component';
import { CardProdutoListaWideComponent } from './card-produto-lista-wide/card-produto-lista-wide.component';
import { SeletorDePesoModule } from '@vip/ui/seletor-de-peso';

@NgModule({
  imports: [
    CommonModule,
    ImageModule,
    SpinModule,
    ButtonModule,
    CoreModule,
    ModalModule,
    ProdutoPrecoModule,
    RippleModule,
    RouterModule,
    SimpleTextFormModule,
    TagsModule,
    TagCollectionModule,
    SkeletonModule,
    SeletorDePesoModule,
  ],
  declarations: [
    CardProdutoComponent,
    CardProdutoMiniComponent,
    CardProdutoListaComponent,
    CardProdutoObservacaoComponent,
    CardProdutoComboComponent,
    CardProdutoSubstituirComponent,
    CardProdutoSkeletonComponent,
    CardProdutoSubstituirSkeletonComponent,
    CardProdutoListaSkeletonComponent,
    CardProdutoListaWideComponent,
  ],
  exports: [
    CardProdutoComponent,
    CardProdutoMiniComponent,
    CardProdutoListaComponent,
    CardProdutoObservacaoComponent,
    CardProdutoComboComponent,
    CardProdutoSubstituirComponent,
    CardProdutoSkeletonComponent,
    CardProdutoSubstituirSkeletonComponent,
    CardProdutoListaSkeletonComponent,
    CardProdutoListaWideComponent,
  ],
  providers: [
    CurrencyPipe,
    I18nPluralPipe,
    MostrarPrecoUnidadeProdutoPipe,
    ProdutoUtilsService,
    QuantidadeUnidadeDiferenteProdutoPipe,
    QuantidadeMaximaProdutoPipe,
    QuantidadeProdutoPipe,
    UnidadeDiferentePipe,
    UnidadePrecoPipe,
    UnidadeSiglaPipe,
  ],
})
export class CardProdutoModule {}
