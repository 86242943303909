import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagCollectionComponent } from './tag-collection/tag-collection.component';
import { TagCollectionGroupComponent } from './tag-collection-group/tag-collection-group.component';

import { ImageModule } from '@vip/ui/image';

@NgModule({
  imports: [CommonModule, ImageModule],
  declarations: [TagCollectionComponent, TagCollectionGroupComponent],
  exports: [TagCollectionComponent, TagCollectionGroupComponent],
})
export class TagCollectionModule {}
