import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreadcrumbModule } from '@vip/ui/breadcrumb';
import { ButtonModule } from '@vip/ui/button';
import { CardProdutoModule } from '@vip/ui/card-produto';
import { CarouselModule } from '@vip/ui/carousel';
import { DropdownModule } from '@vip/ui/dropdown';
import { PaginationModule } from '@vip/ui/pagination';
import { TabsModule } from '@vip/ui/tabs';
import { GridCardModule } from '@vip/ui/grid-card';
import { FiltroDesktopModule } from '@vip/views/filtro-desktop';
import { ListaProdutosDesktopComponent } from './lista-produtos-desktop/lista-produtos-desktop.component';
import { LoadingSpinnerModule } from '@vip/ui/loading-spinner';
import { NotFoundModalModule } from '@vip/ui/not-found-modal';
import { EmptyModule } from '@vip/ui/empty';

@NgModule({
  imports: [
    CommonModule,
    FiltroDesktopModule,
    CardProdutoModule,
    BreadcrumbModule,
    CarouselModule,
    ButtonModule,
    DropdownModule,
    TabsModule,
    PaginationModule,
    GridCardModule,
    LoadingSpinnerModule,
    NotFoundModalModule,
    EmptyModule,
  ],
  declarations: [ListaProdutosDesktopComponent],
  exports: [ListaProdutosDesktopComponent],
})
export class ListaProdutosDesktopModule {}
