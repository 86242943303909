import { Injectable } from '@angular/core';
import { BaseWsService } from '../common/base-ws-service';
import {
  ICancelarPagamentoResponse,
  IFinalizarCompraPayload,
  IFinalizarCompraResponse,
  IPagamentoDigitalPayload,
  IVerificarPagamentoResponse,
  IViewCompraResponse,
} from './compra-ws.types';
import { map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CompraWsService {
  private comprasUrl = '/loja/compras';

  constructor(private ws: BaseWsService<CompraWsService>) {}

  finalizarCompra(filialId: number, novaCompra: IFinalizarCompraPayload) {
    return this.ws.post<IFinalizarCompraPayload, IFinalizarCompraResponse>(
      'finalizarCompra',
      `${this.comprasUrl}/processo/${filialId}`,
      novaCompra,
      { FilialID: filialId.toString() }
    );
  }

  cancelarPagamentoDigital(
    filialId: number,
    compraId: number,
    compraPagamentoId: number
  ) {
    return this.ws
      .post<IPagamentoDigitalPayload, ICancelarPagamentoResponse>(
        'cancelarPagamentoDigital',
        `${this.comprasUrl}/cancelar-pagamento/${filialId}/${compraId}`,
        { compra_id: compraId, id: compraPagamentoId },
        { FilialID: filialId.toString() }
      )
      .pipe(
        map((res) => {
          if (res.error || !res.success) {
            throw new HttpErrorResponse({
              status: 400,
              error: { error: res.message },
            });
          }
          return res;
        })
      );
  }

  verificarPagamentoDigital(
    filialId: number,
    compraId: number,
    compraPagamentoId: number
  ) {
    return this.ws
      .post<IPagamentoDigitalPayload, IVerificarPagamentoResponse>(
        'verificarPagamentoDigital',
        `${this.comprasUrl}/verificar-pagamento/${filialId}/${compraId}`,
        { compra_id: compraId, id: compraPagamentoId },
        { FilialID: filialId.toString() }
      )
      .pipe(
        map((res) => {
          if (res.error) {
            throw new HttpErrorResponse({
              status: 400,
              error: { error: res.message },
            });
          }
          return res;
        })
      );
  }

  viewCompra(filialId: number, compraId: number) {
    return this.ws.get<IViewCompraResponse>(
      'viewCompra',
      `${this.comprasUrl}/view/${filialId}/${compraId}`
    );
  }
}
