import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IVitrinesDedicadas } from '@vip/core';

export const loadVitrine = createAction(
  '[Vitrine Dedicada/API] Load Vitrine Dedicada'
);

export const loadVitrineSuccess = createAction(
  '[Vitrine Dedicada/API] Load Vitrine Dedicada Success',
  props<{ vitrines: Array<IVitrinesDedicadas> }>()
);

export const loadVitrineError = createAction(
  '[Vitrine Dedicada/API] Load Vitrine Dedicada Failure',
  props<{ error: HttpErrorResponse }>()
);
