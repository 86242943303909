import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IFilial, IFilialScripts } from '@vip/core';
import { BaseApiService } from '../common/base-api-service';
import { BaseApiResponse } from '../common/base-api-response';

@Injectable({
  providedIn: 'root',
})
export class FilialApiService {
  private filialUrl = '/loja/filial';

  constructor(private api: BaseApiService<FilialApiService>) {}

  getFilial(id: number): Observable<BaseApiResponse<IFilial>> {
    return this.api.get('getFilial', `/loja/filiais/${id}`);
  }

  public getFilialScripts(
    id: number
  ): Observable<BaseApiResponse<IFilialScripts[]>> {
    return this.api.get('getFilialScripts', `/loja/filiais/${id}/scripts`);
  }
}
