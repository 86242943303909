import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PerguntasStateModule } from '@vip/state/perguntas';
import * as fromCompraEmProcesso from './+state/compra-em-processo.reducer';
import { CompraEmProcessoEffects } from './+state/compra-em-processo.effects';
import { CompraEmProcessoFacade } from './+state/compra-em-processo.facade';
import { CompraEmProcessoGuard } from './guards/compra-em-processo.guard';
import { CompraEmProcessoService } from './services/compra-em-processo.service';
import { OpcaoFaturamentoStateModule } from '@vip/state/opcao-faturamento';
import { TipoEntregaStateModule } from '@vip/state/tipo-entrega';
import { CupomStateModule } from '@vip/state/cupom';
import { CarrinhoStateModule } from '@vip/state/carrinho';
import { DescontoStateModule } from '@vip/state/desconto';
import { FormaPagamentoStateModule } from '@vip/state/forma-pagamento';
import { FidelidadeStateModule } from '@vip/state/fidelidade';
import { ModalPerguntasModule } from '@vip/ui/modal-perguntas';

@NgModule({
  imports: [
    CommonModule,
    OpcaoFaturamentoStateModule,
    TipoEntregaStateModule,
    CupomStateModule,
    PerguntasStateModule,
    FormaPagamentoStateModule,
    CarrinhoStateModule,
    DescontoStateModule,
    FidelidadeStateModule,
    StoreModule.forFeature(
      fromCompraEmProcesso.COMPRA_EM_PROCESSO_FEATURE_KEY,
      fromCompraEmProcesso.reducer
    ),
    EffectsModule.forFeature([CompraEmProcessoEffects]),
    ModalPerguntasModule,
  ],
  providers: [
    CompraEmProcessoFacade,
    CompraEmProcessoGuard,
    CompraEmProcessoService,
  ],
})
export class CompraEmProcessoStateModule {}
