import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IList, INovaLista } from '@vip/core';

export const loadListas = createAction('[Lista/Page] Get Listas');

export const loadListaSuccess = createAction(
  '[Lista/Page] Load Lista Success',
  props<{
    minhasListas: IList[];
    compartilhadas: IList[];
    minhasListasECompartilhadas: IList[];
  }>()
);

export const loadListaFailure = createAction(
  '[Lista/Page] Load Lista Failure',
  props<{ error: HttpErrorResponse }>()
);

export const criarLista = createAction(
  '[Lista/Page] Criar Lista',
  props<{ novaLista: INovaLista }>()
);

export const criarListaSuccess = createAction(
  '[Lista/Page] Criar Lista Success',
  props<{ lista: IList }>()
);

export const criarListaFailure = createAction(
  '[Lista/Page] Criar Lista Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getLista = createAction(
  '[Lista/Page] Get Lista',
  props<{ listaId: number }>()
);

export const getListaSuccess = createAction(
  '[Lista/API] Get Lista Success',
  props<{ lista: IList }>()
);

export const getListaFailure = createAction(
  '[Lista/API] Get Lista Failure',
  props<{ error: HttpErrorResponse }>()
);

export const selecionarLista = createAction(
  '[Lista/Page] Selecioanr Lista',
  props<{ listaId: number }>()
);

export const limparListaSelecionada = createAction(
  '[Lista/Page] Limpar Lista Selecionada'
);

export const editarLista = createAction(
  '[Lista/Page] Editar Lista',
  props<{ lista: IList }>()
);

export const editarListaSuccess = createAction(
  '[Lista/Page] Editar Lista Success',
  props<{ lista: IList }>()
);

export const editarListaFailure = createAction(
  '[Lista/Page] Editar Lista Failure',
  props<{ error: HttpErrorResponse }>()
);

export const excluirLista = createAction(
  '[Lista/Page] Excluir Lista',
  props<{ listaId: number }>()
);

export const excluirListaSuccess = createAction(
  '[Lista/Page] Excluir Lista Success',
  props<{ listaId: number }>()
);

export const excluirListaFailure = createAction(
  '[Lista/Page] Excluir Lista Failure',
  props<{ error: HttpErrorResponse }>()
);
