import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromCentroDistribuicao from './+state/centro-distribuicao.reducer';
import { CentroDistribuicaoEffect } from './+state/centro-distribuicao.effects';
import { CentroDistribuicaoFacade } from './+state/centro-distribuicao.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCentroDistribuicao.CENTRO_DISTRIBUICAO_FEATURE_KEY,
      fromCentroDistribuicao.reducer
    ),
    EffectsModule.forFeature([CentroDistribuicaoEffect]),
  ],
  providers: [CentroDistribuicaoFacade],
})
export class CentroDistribuicaoStateModule {}
