<input
  [checked]="checkboxValue"
  [disabled]="disabled"
  [value]="checkboxValue"
  type="checkbox"
/>

<label
  #checkboxLabel
  [class.text-sm]="labelSize === 'md'"
  [class.text-xs]="labelSize === 'sm'"
  [class.text-md]="labelSize === 'lg'"
  (click)="checkOption(checkboxValue)"
  [tabindex]="disabled ? -1 : 0"
  vipRipple
  rippleColor="color-primary-main"
  [ngClass]="{ rounded }"
>
  {{ label }}
</label>
