import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as ComboActions from './combo.actions';
import { GenericStoreStatusEnum, IGenericState } from '@vip/state/utils';
import { IProdutoCombo } from '@vip/core';

export const COMBO_FEATURE_KEY = 'combo';
export interface ComboState
  extends IGenericState<{ produtos: EntityState<number> } | null>,
    EntityState<number> {
  combos: number[];
  produtos: IProdutoCombo[] | null;
}

export interface ComboPartialState {
  readonly [COMBO_FEATURE_KEY]: ComboState;
}

export const comboAdapter: EntityAdapter<number> = createEntityAdapter<number>({
  selectId: (combo) => combo,
});

export const initialComboState: ComboState = comboAdapter.getInitialState({
  comboSelecionadoId: null,
  data: null,
  combos: [],
  produtos: null,
  status: 'pending',
  error: null,
});

const reducer = createReducer(
  initialComboState,
  on(
    ComboActions.getCombo,
    ComboActions.getCombosDepartamentos,
    ComboActions.getCombosProdutosByArray,
    (state) => ({
      ...state,
      status: GenericStoreStatusEnum.LOADING,
      error: null,
    })
  ),
  on(ComboActions.getCombosDepartamentosSuccess, (state, { combos }) => ({
    ...state,
    combos,
    status: GenericStoreStatusEnum.SUCCESS,
    error: null,
  })),
  on(ComboActions.getComboSuccess, (state, { combos }) => ({
    ...state,
    combos,
    status: GenericStoreStatusEnum.SUCCESS,
    error: null,
  })),
  on(ComboActions.getComboProdutosSuccess, (state, { produtos }) => ({
    ...state,
    produtos,
    status: GenericStoreStatusEnum.SUCCESS,
    error: null,
  })),
  on(
    ComboActions.getComboFailure,
    ComboActions.getComboProdutosFailure,
    ComboActions.getCombosDepartamentosFailure,
    (state, { error }) => ({
      ...state,
      status: GenericStoreStatusEnum.ERROR,
      error: error.message || null,
    })
  )
);

export function comboReducer(state: ComboState | undefined, action: Action) {
  return reducer(state, action);
}
