import { Pipe, PipeTransform } from '@angular/core';
import { IProduto } from '../../interfaces';

@Pipe({ name: 'mostraPrecoUnidadeProduto' })
export class MostrarPrecoUnidadeProdutoPipe implements PipeTransform {
  transform(produto: IProduto, validarSomenteFracao = false): boolean {
    if (validarSomenteFracao) {
      return this.isProdutoComUnidadeFracao(produto);
    }
    return (
      this.isProdutoComUnidadeDiferente(produto) ||
      this.isProdutoComUnidadeFracao(produto)
    );
  }

  private isProdutoComUnidadeDiferente(produto: IProduto): boolean {
    if (!produto.possui_unidade_diferente) return false;
    return produto.oferta?.exibe_preco_original || produto.exibe_preco_original;
  }

  private isProdutoComUnidadeFracao(produto: IProduto): boolean {
    return !!produto.unidade_fracao;
  }
}
