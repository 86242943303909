import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { IProduto, LayoutUtilsService } from '@vip/core';
import {
  ProdutosBuscaRapidaStore,
  ProdutosSimilaresCompareStore,
} from '@vip/state/produto';
import {
  SubstituirProdutoFacade,
  SubstituirProdutoService,
} from '@vip/state/substituir-produto';
import { ISpinEventComProduto } from '@vip/ui/spin';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { findIndex } from 'lodash';
import { SelecaoCdFacade } from '@vip/state/selecao-cd';

@Component({
  selector: 'vip-substituir-produto-container',
  templateUrl: './substituir-produto-container.component.html',
  styleUrls: ['./substituir-produto-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [ProdutosSimilaresCompareStore, ProdutosBuscaRapidaStore],
})
export class SubstituirProdutoContainerComponent {
  @Input() isModal = false;
  produtosSimilaresESubstituto$: Observable<IProduto[]>;
  produtosIndisponiveis$ = this.substituirProdutoFacade.produtosIndisponiveis$;
  produtoIndisponivelSelecionado$ =
    this.substituirProdutoFacade.produtoIndisponivelSelecionado$;
  produtoSubstituto$ =
    this.substituirProdutoFacade.produtoSubstitutoSelecionado$;

  produtoAtualId!: number;
  termoPesquisado$ = new BehaviorSubject('');

  isBusca = false;

  constructor(
    private similaresCompareStore: ProdutosSimilaresCompareStore,
    private buscaRapidaStore: ProdutosBuscaRapidaStore,
    private substituirProdutoFacade: SubstituirProdutoFacade,
    private substituirProdutoService: SubstituirProdutoService,
    private location: Location,
    private layoutUtilsService: LayoutUtilsService,
    private selecaoCdFacade: SelecaoCdFacade
  ) {
    this.produtosSimilaresESubstituto$ = combineLatest([
      this.buscaRapidaStore.produtos$,
      this.similaresCompareStore.produtos$,
      this.substituirProdutoFacade.produtoSubstitutoSelecionado$,
    ]).pipe(
      map(([busca, similares, substituto]) => {
        const produtos = this.isBusca ? [...busca] : [...similares];
        if (substituto) {
          const indexDoSubstituto = findIndex(produtos, {
            produto_id: substituto?.produto_id,
          });
          indexDoSubstituto != -1
            ? (produtos[indexDoSubstituto] = substituto)
            : produtos.unshift(substituto);
        }
        return produtos;
      })
    );
  }

  buscarNovosProdutosSimilares(event: { produto_id: number }): void {
    this.produtoAtualId = event.produto_id;
    this.substituirProdutoFacade.setIdProdutoIndisponivel(event.produto_id);
    this.similaresCompareStore.getSimilaresCompareDisponiveis({
      produtoId: this.produtoAtualId,
    });
    this.isBusca = false;
    this.termoPesquisado$.next('');
  }

  substituirProdutos(): void {
    this.substituirProdutoFacade.adicionarItensAoCarrinho();
  }

  goBackHandler(finalizado: boolean): void {
    if (!finalizado) {
      this.substituirProdutoFacade.resetProdutos();
    }
    this.substituirProdutoService.finalizarSubstituirProdutos();
    if (!this.layoutUtilsService.isDesktop()) this.location.back();
  }

  cancelarBusca(): void {
    this.isBusca = false;
    this.buscaRapidaStore.resetState();
  }

  buscarProdutoSubstituto(event: { produto_id: number }): void {
    this.substituirProdutoFacade.setIdProdutoIndisponivel(event.produto_id);
  }

  onSearch(termo: string): void {
    if (termo) {
      this.isBusca = true;
      this.buscaRapidaStore.getProdutosByBuscaRapida({ termo });
    } else {
      this.cancelarBusca();
    }
  }

  handleSubstitutoChange(event: {
    spinEvent: ISpinEventComProduto;
    produto: IProduto;
  }): void {
    this.substituirProdutoFacade.definirProdutoSubstituto(
      this.produtoAtualId,
      event.spinEvent.quantity,
      event.produto
    );
  }

  permitirAtualizarCarrinho(): void {
    this.selecaoCdFacade.permitirAtualizarCarrinho(true);
  }

  naoSubstituirProdutos(): void {
    this.substituirProdutoFacade.naoSubstituirProdutos();
  }
}
