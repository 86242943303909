import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'getURLGoogleSafe' })
export class GetUrlGoogleSafe implements PipeTransform {
  readonly googleURL = 'https://www.google.com/safebrowsing/diagnostic?site=';

  transform(url?: string | null) {
    return `${this.googleURL}${url}`;
  }
}
