import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnChanges,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  IApplicationMenuItem,
  IBreadCrumb,
  IPaginaInstitucional,
  LayoutUtilsService,
} from '@vip/core';
import { InstitucionalFacade } from '@vip/state/institucional';
import { ListaInstitucionalBaseDirective } from '../lista-institucional-container/lista-intitucional-base.directive';
import { NavigationEnd, Router } from '@angular/router';
import { IframeService } from '@vip/native/iframe';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
@UntilDestroy()
@Component({
  selector: 'vip-institucional-menu-container',
  templateUrl: './institucional-menu-container.component.html',
  styleUrls: ['./institucional-menu-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InstitucionalMenuContainerComponent
  extends ListaInstitucionalBaseDirective
  implements OnInit, OnChanges
{
  isDesktop$ = this.layoutUtilsService.isDesktop$;
  paginasSite$ = this.institucionalFacade.paginasSite$;
  isDesktop = false;
  menuItens: IApplicationMenuItem[] = [];
  activeMenuTitle = '';
  subtitle = '';
  breadcrumbs: Array<IBreadCrumb> = [];

  constructor(
    private layoutUtilsService: LayoutUtilsService,
    private institucionalFacade: InstitucionalFacade,
    private changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public iframeService: IframeService,
    public ngZone: NgZone
  ) {
    super(router, iframeService, ngZone);
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.parseMenuItemUrl();
        this.changeDetectorRef.detectChanges();
      });

    combineLatest([this.paginasSite$, this.isDesktop$])
      .pipe(untilDestroyed(this))
      .subscribe(([paginas, isDesktop]) => {
        this.isDesktop = isDesktop;
        const key = this.getKeyUrl();

        if (paginas.length > 0 && isDesktop) {
          this.menuItensTradutor(paginas);
          if (key === 'institucional') {
            if (this.menuItens[0])
              this.onMenuItemClick(this.menuItens[0], isDesktop);
            this.activeMenuTitle =
              this.menuItens[0]?.title || this.menuItens[0]?.data.subtitulo;
          } else {
            const itemMenuSelecionado = this.menuItens.find(
              (menuItem) => menuItem.key === key
            );

            this.onMenuItemClick(
              itemMenuSelecionado ?? this.menuItens[0],
              isDesktop
            );
            this.activeMenuTitle =
              itemMenuSelecionado?.title || itemMenuSelecionado?.data.subtitulo;
          }
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnChanges(): void {
    this.parseMenuItemUrl();
    this.updateBreadcrumbMap();
  }

  private getKeyUrl() {
    const url = this.router.url;
    const match = url.match(/\/([^/]+)$/);
    const key = match ? match[1] : null;
    return key;
  }

  private menuItensTradutor(paginas: IPaginaInstitucional[]) {
    this.menuItens = paginas?.map((value) => ({
      title: value.subtitulo,
      key: value.slug,
      value: value.slug,
      data: value,
    }));
  }
  updateBreadcrumbMap() {
    this.breadcrumbs = [
      { label: 'Home', link: '/' },
      { label: 'Institucional', link: '/institucional' },
      { label: this.subtitle, link: '' },
    ];
  }

  onMenuItemClick(item: IApplicationMenuItem, isDesktop?: boolean): void {
    if (item.data.tipo === 'externa') {
      this.redirectToPage(item.data, isDesktop);
      return;
    }

    this.activeMenuTitle = item.title || item.data.subtitulo;
    this.subtitle = item.data.subtitulo;
    this.updateBreadcrumbMap();
    this.redirectToPage(item.data, isDesktop);
  }

  private parseMenuItemUrl() {
    const key = this.getKeyUrl();
    const itemMenuSelecionado = this.getItemMenuSelecionado(key);

    if (itemMenuSelecionado)
      this.onMenuItemClick(itemMenuSelecionado, this.isDesktop);
  }

  private getItemMenuSelecionado(key: string | number | null) {
    if (key === 'institucional') return this.menuItens[0];
    const itemMenuSelecionado = this.menuItens.find(
      (menuItem) =>
        menuItem.key === key || menuItem.data.injecao_iframe_id == key
    );
    return itemMenuSelecionado || this.menuItens[0];
  }
}
