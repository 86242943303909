import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarrinhoContainerComponent } from './carrinho-container/carrinho-container.component';
import { CarrinhoModule } from '@vip/views/carrinho';
import { RouterModule } from '@angular/router';
import { CarrinhoStateModule } from '@vip/state/carrinho';
import { CarrinhoItensStateModule } from '@vip/state/carrinho-itens';
import { ClienteStateModule } from '@vip/state/cliente';
import { ParametrosStateModule } from '@vip/state/parametros';
import { AtualizarCarrinhoService } from './services/atualizar-carrinho-service';
import { CoreModule, Ga4UtilsService } from '@vip/core';
import { AnalyticsStateModule } from '@vip/state/analytics';
@NgModule({
  imports: [
    CommonModule,
    CarrinhoModule,
    CarrinhoStateModule,
    CarrinhoItensStateModule,
    AnalyticsStateModule,
    ClienteStateModule,
    ParametrosStateModule,
    RouterModule.forChild([
      {
        path: '',
        component: CarrinhoContainerComponent,
      },
    ]),
    CoreModule,
  ],
  declarations: [CarrinhoContainerComponent],
  providers: [AtualizarCarrinhoService, Ga4UtilsService],
})
export class CarrinhoContainerModule {}
