import { Action, createReducer, on } from '@ngrx/store';

import * as CompraActions from './compra.actions';
import { IApiPaginator, ICompra, IPerguntaSobreCompra } from '@vip/core';
import { GenericStoreStatusEnum, IGenericState } from '@vip/state/utils';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const COMPRA_FEATURE_KEY = 'compra';

export interface CompraStateData {
  compras: ICompra[] | null;
  perguntas: IPerguntaSobreCompra[] | null;
  prazoTroca: number | null;
  prazoDevolucao: number | null;
  paginator: IApiPaginator | null;
}

export interface CompraState
  extends IGenericState<CompraStateData>,
    EntityState<ICompra> {
  compraSelecionadaId: number | null;
  compraEmProcessoId?: number | null;
}

export const compraAdapter: EntityAdapter<ICompra> =
  createEntityAdapter<ICompra>();

export const initialState: CompraState = compraAdapter.getInitialState({
  data: {
    compras: null,
    prazoTroca: null,
    prazoDevolucao: null,
    perguntas: null,
    paginator: null,
  },
  compraSelecionadaId: null,
  status: 'pending',
  error: null,
});

const compraReducer = createReducer(
  initialState,
  on(CompraActions.select, (state, { compraSelecionadaId }) => ({
    ...state,
    compraSelecionadaId,
  })),
  on(
    CompraActions.getCompras,
    CompraActions.getCompra,
    CompraActions.getComprasEmAberto,
    CompraActions.getTrocaDevolucao,
    CompraActions.getPrazoTrocaDevolucao,
    CompraActions.getComprasTrocasDevolucao,
    CompraActions.getPerguntasCompraComResposta,
    (state) => ({
      ...state,
      status: GenericStoreStatusEnum.LOADING,
      error: null,
    })
  ),
  on(
    CompraActions.getComprasEmAbertoSuccess,
    CompraActions.getComprasTrocasDevolucaoSuccess,
    CompraActions.getTrocaDevolucaoSuccess,
    (state, { compras }) =>
      compraAdapter.upsertMany(compras, {
        ...state,
        status: GenericStoreStatusEnum.SUCCESS,
        error: null,
      })
  ),
  on(CompraActions.getComprasSuccess, (state, { compras, paginator }) => {
    if (paginator && paginator.page === 1) {
      return compraAdapter.setAll(compras, state);
    }
    return compraAdapter.upsertMany(compras, state);
  }),
  on(CompraActions.getCompraSuccess, (state, { compra }) =>
    compraAdapter.upsertOne(compra, {
      ...state,
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
    })
  ),
  on(
    CompraActions.getPerguntasCompraComRespostaSuccess,
    (state, { perguntas }) => ({
      ...state,
      data: {
        ...state.data,
        perguntas: perguntas,
      },
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
    })
  ),
  on(
    CompraActions.getPrazoTrocaDevolucaoSuccess,
    (state, { prazoTroca, prazoDevolucao }) => ({
      ...state,
      data: {
        ...state.data,
        prazoDevolucao: prazoDevolucao,
        prazoTroca: prazoTroca,
      },
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
    })
  ),
  on(
    CompraActions.getComprasEmAbertoFailure,
    CompraActions.getComprasFailure,
    CompraActions.getCompraFailure,
    CompraActions.getTrocaDevolucaoFailure,
    CompraActions.getPrazoTrocaDevolucaoFailure,
    CompraActions.getComprasTrocasDevolucaoFailure,
    CompraActions.getPerguntasCompraComRespostaFailure,
    CompraActions.cancelarCompraFailure,
    CompraActions.repetirCompraFailure,
    (state, { error }) => ({
      ...state,
      status: GenericStoreStatusEnum.ERROR,
      error: error.error ? error.error.error : error,
    })
  ),
  on(CompraActions.getComprasSuccess, (state, { paginator }) => ({
    ...state,
    data: {
      ...state.data,
      paginator: paginator || null,
    },
    status: GenericStoreStatusEnum.SUCCESS,
    error: null,
  }))
);

export function reducer(state: CompraState | undefined, action: Action) {
  return compraReducer(state, action);
}
