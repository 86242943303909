import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardResumoProdutosComponent } from './card-resumo-produtos/card-resumo-produtos.component';
import { CardBasicoModule } from '@vip/ui/card-basico';
import { CardResumoProdutosItemComponent } from './card-resumo-produtos-item/card-resumo-produtos-item.component';
import { ImageModule } from '@vip/ui/image';
import { CoreModule } from '@vip/core';

@NgModule({
  imports: [CommonModule, CardBasicoModule, ImageModule, CoreModule],
  declarations: [CardResumoProdutosComponent, CardResumoProdutosItemComponent],
  exports: [CardResumoProdutosComponent, CardResumoProdutosItemComponent],
})
export class CardResumoProdutosModule {}
