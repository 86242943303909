import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IGenericState } from './generic-state.interface';

export class SelectorUtil<T extends IGenericState<T['data']>> {
  constructor(private stateFeatureKey: string) {
    this.getGenericState();
  }

  createFeatureSelector: any;

  getGenericState = () => {
    this.createFeatureSelector = createFeatureSelector<T>(this.stateFeatureKey);
  };

  /* eslint-disable @typescript-eslint/ban-types */
  //TODO object não deveria ser usado nessas funções por ser um typo não recomendado
  isLoading = () =>
    createSelector<object, [T], boolean>(
      this.createFeatureSelector,
      (state: T) => state.status === 'loading'
    );

  isLoaded = () =>
    createSelector<object, [T], boolean>(
      this.createFeatureSelector,
      (state: T) => state.status === 'success' || state.status === 'error'
    );

  isPending = () =>
    createSelector<object, [T], boolean>(
      this.createFeatureSelector,
      (state: T) => state.status === 'pending'
    );

  hasError = () =>
    createSelector<object, [T], boolean>(
      this.createFeatureSelector,
      (state: T) => state.status === 'error'
    );

  getError = () =>
    createSelector<object, [T], string | null | undefined>(
      this.createFeatureSelector,
      (state: T) => state.error
    );

  getData = () =>
    createSelector<object, [T], T['data']>(
      this.createFeatureSelector,
      (state: T) => state.data
    );
}
