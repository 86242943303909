import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IClassificacaoMercadologica } from '@vip/core';
import { ImageSizesEnum } from '@vip/ui/image';

@Component({
  selector: 'vip-categoria-item',
  templateUrl: './categoria-item.component.html',
  styleUrls: ['./categoria-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriaItemComponent {
  @Input() item!: IClassificacaoMercadologica;

  @Output() categoriaItemClick =
    new EventEmitter<IClassificacaoMercadologica>();

  imageSizeEnum = ImageSizesEnum;
}
