import {
  ComponentRef,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { BaseSkeletonComponent } from './base-skeleton.component';

@Directive({ selector: '[vipSkeleton]' })
export class SkeletonDirective {
  @Input('vipSkeleton') isLoading = false;
  @Input('vipSkeletonRepeat') repeat = 1;
  @Input('vipSkeletonWidth') width!: string;
  @Input('vipSkeletonHeight') height!: string;
  @Input('vipSkeletonRadius') radius!: string;
  @Input('vipSkeletonClassName') className: string[] = [];

  constructor(
    private template: TemplateRef<BaseSkeletonComponent>,
    private vcr: ViewContainerRef
  ) {}

  private baseSkeletonComponent!: ComponentRef<BaseSkeletonComponent>;

  ngOnChanges() {
    this.vcr.clear();
    if (this.isLoading) {
      Array.from({ length: this.repeat }).forEach(() => {
        this.baseSkeletonComponent = this.vcr.createComponent(
          BaseSkeletonComponent
        );
        Object.assign(this.baseSkeletonComponent.instance, {
          width: this.width,
          height: this.height,
          radius: this.radius,
          className: this.className,
        });
      });
    } else {
      this.vcr.createEmbeddedView(this.template);
    }
  }
}
