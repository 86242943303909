import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { BannerLocalEnum, ICarousel } from '@vip/core';

export const loadBanner = createAction(
  '[Banner/API] Load Banner',
  props<{ local: BannerLocalEnum; stateKey: string; filtro?: string }>()
);

export const loadBannerSuccess = createAction(
  '[Banner/API] Load Banner Success',
  props<{ banners: ICarousel; stateKey: string }>()
);

export const loadBannerError = createAction(
  '[Banner/API] Load Banner Error',
  props<{ error: HttpErrorResponse; stateKey: string }>()
);

export const addClique = createAction(
  '[Banner/API] Add clique',
  props<{ id: number }>()
);

export const addCliqueHomeOmni = createAction(
  '[Banner/API] Add clique Home Omni',
  props<{ id: number }>()
);
