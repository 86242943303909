<form
  *ngIf="form"
  [formGroup]="form"
  class="grid"
  autocomplete="off"
  [ngClass]="{ 'modal-template': isModalCompletarCadastro }"
>
  <vip-field
    [error]="form.controls.cep.touched && form.controls.cep.invalid"
    [message]="validations.cep"
    [validations]="form.controls.cep.errors"
    [label]="'CEP*'"
  >
    <input id="cep" type="tel" [mask]="maskCep" formControlName="cep" />
  </vip-field>
  <vip-field
    [error]="
      form.controls.logradouro.touched && form.controls.logradouro.invalid
    "
    [message]="validations.default"
    [validations]="form.controls.logradouro.errors"
    [label]="'RUA*'"
  >
    <input id="logradouro" type="text" formControlName="logradouro" />
  </vip-field>

  <div
    [ngClass]="{
      'address-fields': isDesktop && !isModalCompletarCadastro,
      contents: isModalCompletarCadastro
    }"
  >
    <vip-field
      [error]="form.controls.numero.touched && form.controls.numero.invalid"
      [message]="validations.numero"
      [validations]="form.controls.numero.errors"
      [label]="'NÚMERO*'"
      [ngStyle]="{
        'margin-bottom': isModalCompletarCadastro
          ? '0'
          : form.controls.numero.errors?.pattern
          ? '2.25rem'
          : '1.25rem'
      }"
    >
      <input id="numero" type="tel" formControlName="numero" />
    </vip-field>
    <vip-field
      [error]="
        form.controls.complemento.touched && form.controls.complemento.invalid
      "
      [message]="validations.default"
      [validations]="form.controls.complemento.errors"
      [label]="'COMPLEMENTO'"
    >
      <input id="complemento" type="text" formControlName="complemento" />
    </vip-field>
  </div>

  <vip-field
    [error]="form.controls.bairro.touched && form.controls.bairro.invalid"
    [message]="validations.default"
    [validations]="form.controls.bairro.errors"
    [label]="'BAIRRO*'"
  >
    <input id="bairro" type="text" formControlName="bairro" />
  </vip-field>

  <div
    [ngClass]="{
      'city-state-group': isDesktop && !isModalCompletarCadastro,
      contents: isModalCompletarCadastro
    }"
  >
    <vip-field
      [error]="form.controls.cidade.touched && form.controls.cidade.invalid"
      [message]="validations.default"
      [validations]="form.controls.cidade.errors"
      [label]="'CIDADE*'"
    >
      <input id="cidade" type="text" formControlName="cidade" />
    </vip-field>

    <vip-select
      id="sigla"
      [label]="'ESTADO*'"
      [error]="form.controls.sigla.touched && form.controls.sigla.invalid"
      [message]="validations.default"
      [validations]="form.controls.sigla.errors"
      formControlName="sigla"
      [options]="estadoOptions"
    >
    </vip-select>
  </div>

  <vip-field
    [error]="form.controls.titulo.touched && form.controls.titulo.invalid"
    [message]="validations.default"
    [validations]="form.controls.titulo.errors"
    label="APELIDO PARA O ENDEREÇO*"
  >
    <input id="titulo" type="text" formControlName="titulo" />
  </vip-field>

  <vip-field
    [error]="
      form.controls.referencia.touched && form.controls.referencia.invalid
    "
    [message]="validations.default"
    [validations]="form.controls.referencia.errors"
    [label]="'PONTO DE REFERÊNCIA'"
  >
    <input id="referencia" type="text" formControlName="referencia" />
  </vip-field>

  <vip-checkbox
    *ngIf="!novoUsuario"
    id="principal"
    formControlName="principal"
    [label]="'Usar como endereço principal'"
  ></vip-checkbox>
</form>
