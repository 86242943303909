import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ProdutosFavoritosStore,
  ProdutosIndisponiveisService,
  ProdutosIndisponiveisStore,
  ProdutosMaisVendidosStore,
  ProdutosOfertasExclusivasStore,
  ProdutosOfertasStore,
  ProdutosVitrineDedicadaStore,
  ProdutosVitrinesPatrocinadosStore,
} from '@vip/state/produto';
import { ClassificacaoMercadologicaFacade } from '@vip/state/classificacao-mercadologica';
import { IClassificacaoMercadologica, LayoutUtilsService } from '@vip/core';
import { TipoEntregaFacade } from '@vip/state/tipo-entrega';
import { EnderecoFacade } from '@vip/state/endereco';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { BannerFacade } from '@vip/state/banner';
import { ClienteFacade } from '@vip/state/cliente';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddToListContainerService } from '@vip/container/add-to-list';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';
import { AtualizarCarrinhoService } from '@vip/container/carrinho';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { BucketsFacade } from '@vip/state/buckets';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { mergeTakeOne } from '@vip/state/utils';
import { environment } from '@vip/core';
import { CepFacade } from '@vip/state/cep';
import { VitrineDedicadaFacade } from '@vip/state/vitrine-dedicada';
import { FilialFacade } from '@vip/state/filial';
import { ParametrosFacade } from '@vip/state/parametros';

@UntilDestroy()
@Component({
  selector: 'vip-home-ecommerce-container',
  templateUrl: './home-ecommerce-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProdutosFavoritosStore,
    ProdutosMaisVendidosStore,
    ProdutosVitrinesPatrocinadosStore,
    ProdutosOfertasStore,
    ProdutosOfertasExclusivasStore,
    ProdutosIndisponiveisStore,
    ProdutosIndisponiveisService,
    ProdutosVitrineDedicadaStore,
  ],
})
export class HomeEcommerceContainerComponent implements OnInit {
  clienteLogado = false;

  isRetirada$ = new BehaviorSubject(true);
  enderecoRetirada$ = this.cdFacade.enderecoRetiradaCdSelecionado$;
  isLojaAutonoma$ = this.cdFacade.isLojaAutonoma$;
  enderecoEntrega$ = this.enderecoFacade.enderecoEntrega$;

  ofertasExclusivas$ = this.produtosOfertasExclusivasStore.produtos$;
  ofertas$ = this.produtosOfertasStore.produtos$;
  favoritos$ = this.produtosFavoritosStore.produtos$;
  maisVendidos$ = this.produtosMaisVendidosStore.produtos$;
  vitrinesDedicadas$ =
    this.produtosVitrineDedicadaStore.listaVitrinesSemPerfil$;
  vitrinesDedicadasPerfil$ =
    this.produtosVitrineDedicadaStore.listaVitrinesComPerfil$;
  categoriaItens$ = this.classificacaoMercadologicaFacade.arvore$;
  firstCarousel$ = this.bannerFacade.bannersVitrine$;
  secondCarousel$ = this.bannerFacade.bannersVitrineEmBaixo$;
  produtosNoCarrinho$ = this.carrinhoItensFacade.produtosNoCarrinho$;
  produtosPatrocinados$ = this.produtosPatrocinadostore.produtos$;
  filialId = 0;
  filial = this.filialFacade.filial$
    .pipe(untilDestroyed(this))
    .subscribe((filial) => {
      this.filialId = filial.id;
    });
  logoBusca$ = this.bucketsFacade.bucketS3$.pipe(
    map(
      (s3) =>
        `${s3}/files/tema/filial-${this.filialId}/logo-busca.png?${Date.now()}`
    )
  );
  logoBuscaFallback$ = this.bucketsFacade.bucketS3$.pipe(
    map((s3) => `${s3}/files/tema/logo-busca.png?${Date.now()}`)
  );

  isInitialLoading$ = combineLatest([
    this.produtosOfertasExclusivasStore.loading$.pipe(distinctUntilChanged()),
    this.produtosOfertasStore.loading$.pipe(distinctUntilChanged()),
    this.produtosFavoritosStore.loading$.pipe(distinctUntilChanged()),
    this.produtosMaisVendidosStore.loading$.pipe(distinctUntilChanged()),
    this.classificacaoMercadologicaFacade.loading$.pipe(distinctUntilChanged()),
    this.produtosVitrineDedicadaStore.loading$.pipe(distinctUntilChanged()),
  ]).pipe(
    map((loadings) => {
      return loadings.some((loading: boolean) => loading);
    })
  );
  isMobile: boolean;
  isDesktop: boolean;
  isApp: boolean;
  isLojaAutonoma = false;
  adsParamValue: boolean | null = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private produtosFavoritosStore: ProdutosFavoritosStore,
    private produtosMaisVendidosStore: ProdutosMaisVendidosStore,
    private produtosOfertasStore: ProdutosOfertasStore,
    private produtosOfertasExclusivasStore: ProdutosOfertasExclusivasStore,
    private classificacaoMercadologicaFacade: ClassificacaoMercadologicaFacade,
    private bannerFacade: BannerFacade,
    private clienteFacade: ClienteFacade,
    private carrinhoItensFacade: CarrinhoItensFacade,
    public tipoEntregaFacade: TipoEntregaFacade,
    private enderecoFacade: EnderecoFacade,
    private cdFacade: CentroDistribuicaoFacade,
    public atualizarCarrinhoService: AtualizarCarrinhoService,
    public produtosIndisponiveisService: ProdutosIndisponiveisService,
    public addToListContainerService: AddToListContainerService,
    private bucketsFacade: BucketsFacade,
    private cepFacade: CepFacade,
    private vitrineDedicadaFacade: VitrineDedicadaFacade,
    private filialFacade: FilialFacade,
    private produtosVitrineDedicadaStore: ProdutosVitrineDedicadaStore,
    private layoutUtilsService: LayoutUtilsService,
    private parametrosFacade: ParametrosFacade,
    private cd: ChangeDetectorRef,
    private produtosPatrocinadostore: ProdutosVitrinesPatrocinadosStore
  ) {
    this.isMobile = this.layoutUtilsService.isMobile();
    this.isApp = environment.isApp;
    this.isDesktop = this.layoutUtilsService.isDesktop();
    this.isLojaAutonoma$
      .pipe(untilDestroyed(this))
      .subscribe(
        (isLojaAutonoma) => (this.isLojaAutonoma = isLojaAutonoma || false)
      );
  }

  ngOnInit() {
    this.produtosMaisVendidosStore.getMaisVendidos();
    this.produtosOfertasStore.getVitrine();
    this.classificacaoMercadologicaFacade.loadArvore();
    this.cdFacade.getCentroDistribuicaoRetirada();
    this.bannerFacade.getBannersVitrineSuperior();
    this.vitrineDedicadaFacade.getVitrines();
    this.getProdutosVitrines();
    this.produtosPatrocinadostore.getMaisPatrocinados();

    this.firstCarousel$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        )
      )
      .subscribe((res) => {
        if (res != null) {
          this.bannerFacade.getBannersVitrineInferior();
        }
      });

    this.verificaClienteLogado();
    this.setEntregaOuRetirada();

    this.parametrosFacade.adsParams$
      .pipe(
        filter((value) => value !== undefined),
        untilDestroyed(this)
      )
      .subscribe((facadeValue) => {
        this.adsParamValue = facadeValue;
        this.cd.detectChanges();
      });
  }

  get endereco$() {
    return this.isRetirada$.pipe(
      withLatestFrom(
        this.enderecoRetirada$,
        this.enderecoEntrega$,
        this.isLojaAutonoma$,
        this.clienteFacade.isLogged$,
        this.cepFacade.enderecoDoCepFormatado$
      ),
      map(
        ([
          isRetirada,
          enderecoRetirada,
          enderecoEntrega,
          isLojaAutonoma,
          isLogged,
          enderecoDoCepFormatado,
        ]) => {
          if (isRetirada || isLojaAutonoma) {
            return enderecoRetirada?.endereco_formatado;
          }
          if (!isLogged) {
            return enderecoDoCepFormatado;
          }
          return enderecoEntrega?.endereco_formatado;
        }
      )
    );
  }

  handleShowMoreClick(event: string) {
    this.router.navigateByUrl(event);
  }

  handleSearchClick() {
    this.router.navigateByUrl('/busca');
  }

  handleCategoriaItemClick(departamento: IClassificacaoMercadologica) {
    this.router.navigateByUrl(`departamentos${departamento.link}`);
  }

  handleEnderecoClick() {
    this.router.navigateByUrl('/entrega-retirada');
  }

  verificaClienteLogado() {
    this.clienteFacade.cliente$
      .pipe(untilDestroyed(this))
      .subscribe((cliente) => {
        this.clienteLogado = cliente !== null;
        if (this.clienteLogado) {
          this.produtosFavoritosStore.getFavoritos();
          this.produtosOfertasExclusivasStore.getOfertasExclusivas();
          this.setEntregaOuRetirada();
          this.enderecoFacade.getEnderecosEntrega();
          this.verificaEnderecoAtendido();
        }
      });
  }

  setEntregaOuRetirada() {
    this.tipoEntregaFacade.isEntrega$
      .pipe(
        untilDestroyed(this),
        mergeTakeOne(
          this.clienteFacade.isLogged$,
          this.cepFacade.enderecoDoCepFormatado$
        )
      )
      .subscribe(([isEntrega, isLogged, enderecoDoCepFormatado]) => {
        this.isRetirada$.next(
          !isEntrega || (!isLogged && !enderecoDoCepFormatado)
        );
      });
  }

  private verificaEnderecoAtendido(): void {
    this.cepFacade.getData$
      .pipe(
        untilDestroyed(this),
        mergeTakeOne(this.cepFacade.isLoaded$),
        filter(([data, isLoaded]) => isLoaded && data.cepAtendido !== null)
      )
      .subscribe(([{ cepAtendido, apenasRetirada }]) => {
        if (!cepAtendido || apenasRetirada === true) {
          this.tipoEntregaFacade.setFormaEntrega(false);
        }
      });

    this.enderecoFacade.enderecosEntregaArray$
      .pipe(
        untilDestroyed(this),
        withLatestFrom(this.enderecoFacade.loaded$),
        filter(([_enderecosEntrega, loaded]) => !!loaded),
        take(1)
      )
      .subscribe(([enderecosEntrega]) => {
        if (enderecosEntrega.length === 0) {
          this.tipoEntregaFacade.setFormaEntrega(false);
        }
      });
  }

  getProdutosVitrines() {
    this.vitrineDedicadaFacade.isLoaded$
      .pipe(
        untilDestroyed(this),
        filter((load) => load),
        switchMap(() => this.vitrineDedicadaFacade.vitrines$),
        take(1)
      )
      .subscribe((vitrines) => {
        this.produtosVitrineDedicadaStore.getVitrines({
          vitrines,
        });
      });
  }
}
