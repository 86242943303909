import { Router } from '@angular/router';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { LayoutUtilsService } from '@vip/core';

@Component({
  selector: 'vip-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.desktop]': 'isDesktop',
    '[class.mobile]': '!isDesktop',
  },
})
export class TagComponent {
  @Input() label = '';

  @Input() value = '';

  @Input() color = '';

  @Input() textColor = '';

  @Input() tag?: string;

  @Input() classeOferta?: string;

  @Input() type: 'default' | 'full-rounded' | 'small' | 'ex-small' = 'default';

  @Input() disableClick = false;

  @Input() isTextSmall = false;

  @Input() hasCombo = false;

  @Output() tagClick: EventEmitter<void> = new EventEmitter<void>();

  isDesktop = this.layoutUtilsService.isDesktop();

  constructor(
    private router: Router,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  handleClick(event: Event) {
    event.stopPropagation();
    if (this.tag && !this.disableClick) {
      this.tagClick.emit();
      this.router.navigateByUrl(`oferta/${this.tag}`);
    }
  }
}
