import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltroModule } from '@vip/views/filtro';
import { FiltroStateModule } from '@vip/state/filtro';
import { FiltroContainerComponent } from './filtro-container/filtro-container.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FiltroModule,
    FiltroStateModule,
    RouterModule.forChild([
      {
        path: '',
        component: FiltroContainerComponent,
      },
    ]),
  ],
  declarations: [FiltroContainerComponent],
})
export class FiltroContainerModule {}
