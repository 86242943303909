import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select/select.component';
import { DropdownMenuModule } from '@vip/ui/dropdown-menu';
import { FieldModule } from '@vip/ui/field';
import { RippleModule } from '@vip/ui/ripple';

@NgModule({
  imports: [CommonModule, DropdownMenuModule, FieldModule, RippleModule],
  declarations: [SelectComponent],
  exports: [SelectComponent, DropdownMenuModule, FieldModule],
})
export class SelectModule {}
