<vip-modal
  (afterClose)="onAfterCloseModal()"
  [open]="abrirModalProcurarProduto"
>
  <div class="grid grid-cols-1" *ngIf="filial$ | async as filial">
    <div class="flex flex-col">
      <div class="flex flex-col flex-grow vip-nao-encontrado-form">
        <div class="flex flex-col flex-grow">
          <h2 class="vip-column-title vip-color-primary-main my-2">
            Não encontrou o que procurava?
          </h2>
          <span class="flex vip-color-first-default text-sm text-start">
            Use o formulário abaixo para nos dar mais detalhes sobre o produto.
            Assim podemos ampliar a variedade, para que você possa sempre
            encontrar o que precisa.
          </span>
          <form class="mt-2" [formGroup]="form">
            <div class="my-4">
              <vip-field
                [error]="
                  form.controls['produto'].touched &&
                  form.controls['produto'].invalid
                "
                [message]="validations.produto"
                [validations]="form.controls['produto'].errors"
                label="PRODUTO*"
              >
                <input
                  required
                  formControlName="produto"
                  data-cy="input-produto"
                />
              </vip-field>
            </div>

            <div class="my-4">
              <vip-field label="CÓDIGO DE BARRAS">
                <input
                  formControlName="codigo_barras"
                  maxlength="15"
                  data-cy="input-codigo_barras"
                />
              </vip-field>
            </div>
            <div class="my-4">
              <vip-field label="OBSERVAÇÕES">
                <textarea
                  data-cy="input-observacoes"
                  formControlName="observacao"
                  rows="4"
                >
                </textarea>
              </vip-field>
            </div>
          </form>
        </div>
        <div class="vip-modal-buttons">
          <vip-button data-cy="btn-send" (btnClick)="submit(filial.id)">
            Enviar
          </vip-button>
        </div>
      </div>
    </div>
  </div>
</vip-modal>
