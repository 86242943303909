import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AddEditEnderecoRequest } from '@vip/api';
import { EnderecoFacade } from '@vip/state/endereco';
import { CepFacade } from '@vip/state/cep';
import { IEndereco, LayoutUtilsService } from '@vip/core';
import { DialogService, ModalService } from '@vip/ui/modal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { EnderecoComponent } from '@vip/views/endereco';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EnderecoBaseDirective } from './endereco-base.directive';

@UntilDestroy()
@Component({
  selector: 'vip-endereco-container',
  templateUrl: './endereco-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnderecoContainerComponent
  extends EnderecoBaseDirective
  implements OnInit, OnDestroy
{
  enderecos$: Observable<IEndereco[]>;
  possuiEnderecoPrincipalCadastrado$ =
    this.enderecoFacade.possuiEnderecoPrincipalCadastrado$;
  isDesktop = this.layoutUtilsService.isDesktop();
  enderecoPrincipalAtivo = true;

  constructor(
    cepFacade: CepFacade,
    enderecoFacade: EnderecoFacade,
    private activatedRoute: ActivatedRoute,
    private layoutUtilsService: LayoutUtilsService,
    private dialogService: DialogService,
    private router: Router,
    private modalService: ModalService<unknown>
  ) {
    super(enderecoFacade, cepFacade);
    this.enderecos$ = this.enderecoFacade.enderecosArray$;
  }

  ngOnInit() {
    this.possuiEnderecoPrincipalCadastrado$
      .pipe(untilDestroyed(this))
      .subscribe((enderecoPrincipal) => {
        this.enderecoPrincipalAtivo = enderecoPrincipal;
      });
    this.enderecoFacade.getEnderecos();
    this.cepFacade.cepReset();
  }

  ngOnDestroy() {
    this.cepFacade.cepReset();
    this.dialogService.clearDialog();
  }

  handleEditarEndereco(enderecoId: number) {
    this.enderecoFacade.selectEndereco(enderecoId);

    const modalRef = this.modalService.openModal(EnderecoComponent, {
      showCloseButton: false,
    });

    if (!modalRef) return;

    const instance = modalRef.instance as EnderecoComponent;
    instance.isModalDesktop = true;

    this.enderecos$
      .pipe(
        untilDestroyed(this),
        map((enderecos) => {
          const endereco = enderecos.find((e) => e.id === enderecoId);

          return {
            hasMultipleEnderecos: enderecos.length >= 2,
            naoPossuiEndereco: enderecos.length <= 0,
            isEnderecoPrincipal: endereco ? endereco.principal : false,
          };
        })
      )
      .subscribe(
        ({ hasMultipleEnderecos, isEnderecoPrincipal, naoPossuiEndereco }) => {
          instance.possuiEnderecoCadastrado = hasMultipleEnderecos;
          instance.naoPossuiEndereco = naoPossuiEndereco;
          instance.isEnderecoPrincipal = isEnderecoPrincipal;
          instance.possuiEnderecoPrincipalCadastrado =
            this.enderecoPrincipalAtivo;
          instance.showDeleteButton =
            hasMultipleEnderecos && !isEnderecoPrincipal;
        }
      );

    instance.isNew = false;

    this.enderecoFacade.enderecoSelecionado$
      .pipe(untilDestroyed(this))
      .subscribe((endereco) => {
        instance.endereco = endereco;
      });

    instance.closeClicked.pipe(untilDestroyed(this)).subscribe(() => {
      this.modalService.clearModal();
    });

    instance.confirm.pipe(untilDestroyed(this)).subscribe((endereco) => {
      this.enderecoFacade.saveEndereco(endereco);
      this.modalService.clearModal().then(() => {
        this.enderecoFacade.getEnderecosEntrega();
      });
    });

    instance.deleteClick.pipe(untilDestroyed(this)).subscribe((endereco) => {
      this.deleteEndereco(enderecoId);
      this.modalService.clearModal();
    });
  }

  handleAdicionarEndereco() {
    const modalRef = this.modalService.openModal(EnderecoComponent, {
      showCloseButton: false,
    });

    if (!modalRef) return;

    const instance = modalRef.instance as EnderecoComponent;
    this.enderecos$.pipe(untilDestroyed(this)).subscribe((enderecos) => {
      instance.naoPossuiEndereco = enderecos.length <= 0;
    });

    this.possuiEnderecoPrincipalCadastrado$
      .pipe(untilDestroyed(this))
      .subscribe((possuiEndereco) => {
        instance.possuiEnderecoPrincipalCadastrado = possuiEndereco;
      });
    instance.novoEndereco = true;
    this.initModalAdicionarInstance(instance);

    instance.closeClicked.pipe(untilDestroyed(this)).subscribe(() => {
      this.modalService.clearModal();
    });

    instance.confirm.pipe(untilDestroyed(this)).subscribe((endereco) => {
      this.enderecoFacade.saveEndereco(endereco);
      this.modalService.clearModal().then(() => {
        this.enderecoFacade.getEnderecosEntrega();
      });
    });
  }

  private initModalAdicionarInstance(instance: EnderecoComponent) {
    instance.isModalDesktop = true;
    instance.showDeleteButton = false;

    this.cepFacade.cepReset();

    this.cepFacade.enderecoDoCep$
      .pipe(untilDestroyed(this))
      .subscribe((endereco) => {
        instance.endereco = endereco || null;
        instance.isNew = true;
      });

    this.cepFacade.cepAtendido$
      .pipe(untilDestroyed(this))
      .subscribe((cepAtendido) => {
        instance.cepAtendido = cepAtendido ?? false;
        instance.cepValido = cepAtendido ?? false;
      });

    instance.changeCep.pipe(untilDestroyed(this)).subscribe((cep) => {
      this.cepFacade.validarCepAtendidoPelaFilial(cep);
    });
  }

  onSaveEndereco(endereco: AddEditEnderecoRequest) {
    this.saveEndereco(
      endereco,
      this.activatedRoute.snapshot.queryParams['completar-cadastro']
    );
  }

  deleteEndereco(enderecoId: number) {
    this.dialogService.openDialog({
      open: true,
      title: 'Tem certeza que deseja excluir este endereço?',
      disabled: false,
      buttonConfirmText: 'Sim',
      buttonCancelText: 'Não',
    });

    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) this.enderecoFacade.deleteEndereco(enderecoId);
        this.dialogService.clearDialog().then(() => {
          this.enderecoFacade.getEnderecosEntrega();
        });
        this.router.navigate(['/minha-conta', 'endereco']);
      });
  }
}
