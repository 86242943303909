import { Action, createReducer, on } from '@ngrx/store';
import { IFilial, IFilialScripts } from '@vip/core';
import { GenericStoreStatusEnum, IGenericState } from '@vip/state/utils';
import * as FilialActions from './filial.actions';

export const FILIAL_FEATURE_KEY = 'filial';

export type FilialState = IGenericState<IFilial | null> & {
  scripts: Array<IFilialScripts>;
};

export const initialState: FilialState = {
  status: 'pending',
  data: null,
  error: null,
  scripts: [],
};

const filialReducer = createReducer(
  initialState,
  on(
    FilialActions.getFilialPorVipCommerceFilialId,
    FilialActions.getFilialScripts,
    (state) => ({
      ...state,
      status: GenericStoreStatusEnum.LOADING,
      error: null,
    })
  ),
  on(
    FilialActions.getFilialPorVipCommerceFilialIdSuccess,
    (state, { filial }) => ({
      ...state,
      data: { ...filial },
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
    })
  ),
  on(
    FilialActions.getFilialPorVipCommerceFilialIdFailure,
    FilialActions.getFilialScriptsFailure,
    (state, { error }) => ({
      ...state,
      status: GenericStoreStatusEnum.ERROR,
      error: error.error,
    })
  ),
  on(FilialActions.getFilialScriptsSuccess, (state, { scripts }) => ({
    ...state,
    scripts,
    status: GenericStoreStatusEnum.SUCCESS,
    error: null,
  }))
);

export function reducer(state: FilialState | undefined, action: Action) {
  return filialReducer(state, action);
}
