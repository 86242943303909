import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CampanhaPatrocinadoService } from '@vip/api';
import { IProduto, LayoutUtilsService } from '@vip/core';
import Swiper, { Navigation } from 'swiper';

@Component({
  selector: 'vip-carousel-product',
  styleUrls: ['./carousel-product.component.scss'],
  templateUrl: './carousel-product.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CarouselProductComponent implements OnChanges {
  @Input() qtdLimit = 10;
  @Input() title!: string;
  @Input() produtos!: IProduto[];
  @Input() defaultImageUrl!: string;
  @Input() showMoreBtnLabel!: string;
  @Input() showAll = false;
  @Input() showAddListButton = true;
  @Input() aviseMeEnviados: Map<number, boolean> | null = new Map<
    number,
    boolean
  >();
  @Input() produtosNoCarrinho?: Map<
    number,
    { item_id: number; quantidade: number; observacao?: string }
  > | null = null;
  @Input() isLoading = false;

  @Input() isMobile!: boolean;

  @Input() showDivider = false;

  @Input() isHomeOmni = false;

  @Input() adsValue: boolean | null = false;

  @Output() showMoreClick = new EventEmitter();

  @Output() addListClick = new EventEmitter();

  @Output() produtoChanged = new EventEmitter();

  @Output()
  aviseMeClick = new EventEmitter<number>();

  swiper = Swiper;
  swiperConfig = {
    delay: 5000,
    disableOnInteraction: false,
  };

  valueAds: boolean | null = false;

  constructor(
    private layoutUtilsService: LayoutUtilsService,
    public campanhaPatrocinadoService: CampanhaPatrocinadoService
  ) {
    this.swiper.use([Navigation]);
    this.isMobile = this.layoutUtilsService.isMobile();
  }

  get isProductCardHorizontal(): boolean {
    return this.produtos?.length === 1;
  }

  getTotalProducts() {
    return this.produtos?.length - this.qtdLimit >= 1
      ? this.produtos.length - this.qtdLimit
      : 0;
  }

  trackByFn(_: number, product: IProduto) {
    return product.id + '-' + product.quantidade;
  }

  ngOnChanges(): void {
    this.valueAds = this.adsValue;
  }
}
