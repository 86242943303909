import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import * as NotificacaoActions from './notificacao.actions';
import { FilialFacade } from '@vip/state/filial';
import { combineLatest } from 'rxjs';
import { IFilial } from '@vip/core';
import { NotificacaoWsService } from '@vip/ws';
import { map } from 'rxjs/operators';
import { NotificationUtilsService } from '@vip/native/push-notification';

@Injectable()
export class NotificacaoEffects {
  getNotificacoesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificacaoActions.getNotificacoes),
      (action) => combineLatest([action, this.filialFacade.filial$]),
      fetch({
        run: (action, filial: IFilial) => {
          return this.notificacaoWsService.getNotificacoes(filial.id).pipe(
            map((notificacoes) =>
              NotificacaoActions.getNotificacoesSuccess({
                notificacoes: notificacoes.notificacoes,
              })
            )
          );
        },

        onError: (action, error) => {
          return NotificacaoActions.getNotificacoesFailure({ error });
        },
      })
    )
  );

  registrarNotificacaoVisualizada$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificacaoActions.setNotificationRead),
      fetch({
        run: (action) => {
          return this.notificationUtilsService
            .setNotificationRead(action.id)
            .pipe(
              map(() =>
                NotificacaoActions.setNotificationReadSuccess({ id: action.id })
              )
            );
        },

        onError: (action, error) => {
          return NotificacaoActions.setNotificationReadFailure({ error });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private filialFacade: FilialFacade,
    private notificacaoWsService: NotificacaoWsService,
    private notificationUtilsService: NotificationUtilsService
  ) {}
}
