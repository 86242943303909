import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

const LABEL_SIZE_DEFAULT = 'md';

@Directive()
export abstract class CheckboxBaseDirective implements ControlValueAccessor {
  propagateChange: any;
  onTouched: unknown;
  /** Tamanho do texto de exibição do *checkbox*. */
  @Input() labelSize: string = LABEL_SIZE_DEFAULT;

  /** Define o valor do *checkbox*. */
  @Input() checkboxValue = false;
  @Output() checkboxValueChange = new EventEmitter();

  /** Texto de exibição do *checkbox*. */
  @Input() label?: string;

  /** Evento disparado quando o valor do *checkbox* for alterado.*/
  @Output() changeCheckout: EventEmitter<any> = new EventEmitter<any>();
  private _disabled = false;

  get disabled(): boolean {
    return this._disabled;
  }

  /** Define o estado do *checkbox* como desabilitado. */
  @Input() set disabled(value: boolean) {
    this._disabled = value;
  }

  @Input() size = 'md';

  @Input() outline = false;

  @Input() rounded = false;

  changeValue() {
    if (this.propagateChange) {
      this.propagateChange(this.checkboxValue);
    }
    this.checkboxValueChange.emit(this.checkboxValue);
    this.changeCheckout.emit(this.checkboxValue);
  }

  checkOption(value: boolean | null) {
    if (!this.disabled) {
      this.changeModelValue(!value);
      this.changeValue();
    }
  }

  // Função implementada do ControlValueAccessor
  // Usada para interceptar os estados de habilitado via forms api
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: boolean) {
    if (value !== this.checkboxValue) {
      this.changeModelValue(value);
    }
  }

  protected abstract changeModelValue(value: boolean): unknown;
}
