<footer
  [ngClass]="isDesktop ? 'classe-para-desktop' : 'classe-para-mobile'"
  class="vip-color-first-default text-sm"
>
  <section
    class="flex flex-col sm:flex-row lg:flex-row justify-between flex-wrap"
  >
    <article
      class="w-full pb-0 sm:mb-0 sm:pb-0 lg:mb-0 lg:pb-0 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-2/12"
      *ngFor="let column of colunasPaginas"
    >
      <div class="w-full">
        <ng-container
          *ngIf="
            shouldShowAccordion(isDesktop);
            then accordionTemplate;
            else normalTemplate
          "
        ></ng-container>
        <ng-template #accordionTemplate>
          <div class="w-full">
            <vip-accordion [collapsing]="collapsing">
              <vip-collapse [expanded]="false">
                <ng-template vipCollapseHeader>
                  <b>{{ column.titulo }}</b>
                </ng-template>
                <ng-template vipCollapseContent>
                  <ul
                    *ngIf="
                      column.titulo === 'Quem somos' ||
                        column.titulo === 'Forma de Pagamento';
                      else institucionalTemplate
                    "
                  >
                    <ng-container *ngFor="let pagina of column.paginas">
                      <li class="pt-3">
                        {{ pagina?.descricao }}
                      </li>
                      <a
                        class="underline custom-link whitespace-nowrap"
                        routerLink="/institucional/pagina/{{ pagina?.slug }}"
                      >
                        Clique aqui e saiba mais
                      </a>
                    </ng-container>
                  </ul>
                </ng-template>
              </vip-collapse>
            </vip-accordion>
            <ng-template #institucionalTemplate>
              <ul class="pt-3">
                <li class="mb-1" *ngFor="let pagina of column.paginas">
                  <ng-container
                    *ngIf="
                      pagina?.tipo === 'interna' || pagina?.tipo === 'iframe';
                      else externa
                    "
                  >
                    <a
                      [attr.data-cy]="
                        'vip-link-pagina-institucional-' + pagina?.slug
                      "
                      (click)="redirectToPage(pagina, isDesktop)"
                    >
                      {{ pagina?.subtitulo }}
                    </a>
                  </ng-container>
                  <ng-template #externa>
                    <a
                      [href]="pagina?.link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ pagina?.subtitulo }}
                    </a>
                  </ng-template>
                </li>
              </ul>
            </ng-template>
            <hr class="border-t block md:hidden lg:hidden footer-divider" />
          </div>
        </ng-template>

        <ng-template #normalTemplate>
          <div class="w-full">
            <ul
              *ngIf="
                column.titulo === 'Quem somos' ||
                  column.titulo === 'Forma de Pagamento';
                else institucionalTemplate
              "
            >
              <li class="mb-3 lg:mb-2">
                <b>{{ column.titulo }}</b>
              </li>
              <ng-container *ngFor="let pagina of column.paginas">
                <li>
                  {{ pagina?.descricao }}
                </li>
                <a
                  class="underline custom-link whitespace-nowrap"
                  routerLink="/institucional/pagina/{{ pagina?.slug }}"
                >
                  Clique aqui e saiba mais
                </a>
              </ng-container>
            </ul>
            <ng-template #institucionalTemplate>
              <ul>
                <li class="mb-2">
                  <b>{{ column.titulo }}</b>
                </li>
                <ng-container *ngIf="column.paginas.length">
                  <li class="mb-1" *ngFor="let pagina of column.paginas">
                    <a
                      *ngIf="pagina"
                      [attr.data-cy]="
                        'vip-link-pagina-institucional-' + pagina?.slug
                      "
                      class="cursor-pointer"
                      (click)="redirectToPage(pagina, isDesktop)"
                    >
                      {{ pagina?.subtitulo }}
                    </a>
                  </li>
                </ng-container>
              </ul>
            </ng-template>
          </div>
        </ng-template>
        <hr class="border-t block md:hidden lg:hidden footer-divider" />
      </div>
    </article>
    <article
      *ngIf="!isRelacionamentoEmpty"
      class="vip-relacionamento-cliente-wrapper"
    >
      <ul>
        <li class="mb-2 mt-3 lg:mt-0">
          <b>Relacionamento com o Cliente</b>
        </li>
        <li
          class="mb-1"
          *ngFor="let obj of relacionamentoClienteViewModel | keyvalue"
        >
          <a
            *ngIf="obj.value"
            (click)="handleClickContato(obj.key)"
            [attr.data-cy]="'vip-contato-link-' + obj.key"
            [ngClass]="{
              'cursor-pointer': obj.key === 'whatsapp'
            }"
          >
            <i
              class="mr-2 text-xl leading-none secondary {{
                ICONE_CONTATO[obj.key]
              }}"
            ></i>
            <ng-container *ngIf="obj.key !== 'email'; else emailTemplate">
              <span>{{ obj.value }}</span>
            </ng-container>
            <ng-template #emailTemplate>
              <div class="vip-email-wrapper">
                <span [title]="obj.value">{{ obj.value }}</span>
              </div>
            </ng-template>
          </a>
        </li>
      </ul>
    </article>
    <hr
      [hidden]="isRelacionamentoEmpty"
      class="mb-3 border-t block md:hidden lg:hidden footer-divider"
    />
    <div class="w-full sm:mb-0 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-3/12">
      <div class="w-full items-center">
        <div *ngIf="hasRedesSociais" [class.pt-0]="package || appleId">
          <b class="block">Redes Sociais</b>
          <div class="flex gap-4 pt-2">
            <a
              *ngIf="redesSociais?.url_facebook"
              target="_blank"
              rel="noopener noreferrer"
              class="social-link"
              [href]="redesSociais?.url_facebook"
            >
              <div
                style="mask:url('{{ imagesPath }}facebook-negative.svg');"
                class="w-full h-auto social-svg"
                alt="Facebook"
              ></div>
            </a>
            <a
              *ngIf="redesSociais?.url_twitter"
              target="_blank"
              rel="noopener noreferrer"
              class="social-link"
              [href]="redesSociais?.url_twitter"
            >
              <div
                style="mask:url('{{ imagesPath }}twitter-negative.svg');"
                class="w-full h-auto social-svg"
                alt="Twitter"
              ></div>
            </a>
            <a
              *ngIf="redesSociais?.url_instagram"
              target="_blank"
              rel="noopener noreferrer"
              class="social-link"
              [href]="redesSociais?.url_instagram"
            >
              <div
                style="mask:url('{{ imagesPath }}instagram-negative.svg');"
                class="w-full h-auto social-svg"
                alt="Instagram"
              ></div>
            </a>
            <a
              *ngIf="redesSociais?.url_youtube"
              target="_blank"
              rel="noopener noreferrer"
              class="social-link"
              [href]="redesSociais?.url_youtube"
            >
              <div
                style="mask:url('{{ imagesPath }}youtube-negative.svg');"
                class="w-full h-auto social-svg"
                alt="Youtube"
              ></div>
            </a>
          </div>
        </div>
        <hr
          [hidden]="package || appleId"
          class="mt-3 border-t block md:hidden lg:hidden footer-divider"
        />

        <div *ngIf="package || appleId" class="pt-3">
          <b class="block">Baixe o App</b>
          <div class="flex gap-4 pt-2">
            <a
              *ngIf="appleId"
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/br/app/{{ appleId }}"
            >
              <vip-image
                imageUrl="{{ imagesPath }}appstore.png"
                classNames="w-full h-auto"
                alt="Baixe o app no Appstore"
              >
              </vip-image>
            </a>
            <a
              *ngIf="package"
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id={{
                package
              }}&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            >
              <vip-image
                imageUrl="{{ imagesPath }}googleplay.png"
                classNames="w-full h-auto"
                alt="Baixe o app no Google Play"
              >
              </vip-image>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div
    class="mt-6 lg:mt-10"
    data-cy="texto-footer"
    *ngIf="textoFooter"
    [innerHTML]="sanitizer.bypassSecurityTrustHtml(textoFooter)"
  ></div>
  <div
    class="flex flex-col pt-4 lg:mt-7 lg:mb-10 sm:flex-row flex-wrap items-center justify-center gap-y-4"
  >
    <div
      class="mr-0 lg:mr-10"
      [class.mr-10]="hasRedesSociais || package || appleId"
    >
      <a
        class="flex gap-4"
        href="https://vipcommerce.com.br"
        target="_blank"
        rel="noopener noreferrer"
      >
        <vip-image
          imageUrl="{{ imagesPath }}logo-vipcommerce.png"
          classNames="w-full h-auto"
          alt=""
        >
        </vip-image>
        <span class="underline">
          Desenvolvido por<br />
          <b>VipCommerce Sistemas</b>
        </span>
      </a>
    </div>
    <div class="mr-0 lg:mr-10" *ngIf="participaSupermercadoOnline === '1'">
      <a
        class="flex gap-4"
        href="https://supermercadosonline.com.br"
        target="_blank"
        rel="noopener noreferrer"
      >
        <vip-image
          imageUrl="{{ imagesPath }}logo-supermercado-online.png"
          alt=""
          classNames="w-full h-auto"
        >
        </vip-image>
        <span class="underline">
          Você encontra essa loja em<br class="whitespace-nowrap" />
          <b>supermercadosonline.com.br</b>
        </span>
      </a>
    </div>
    <div class="flex flex-wrap">
      <div *ngIf="link_ebit" class="mr-6 lg:mr-10">
        <a
          *ngIf="link_ebit"
          [href]="link_ebit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <vip-image
            imageUrl="{{ imagesPath }}selo-ebit.png"
            classNames="w-full h-auto"
            alt="Avaliado pelos consumidores ebit"
          >
          </vip-image>
        </a>
      </div>
      <div *ngIf="url_google" class="flex justify-center">
        <a
          class="py-2 pr-7"
          *ngIf="url_google"
          [href]="url_google"
          target="_blank"
          rel="noopener noreferrer"
        >
          <vip-image
            imageUrl="{{ imagesPath }}selo-google-safe.png"
            classNames="w-full h-auto"
            alt="Google Safe Browsing"
          >
          </vip-image>
        </a>
      </div>
    </div>
  </div>
</footer>
