import { LayoutUtilsService } from '@vip/core';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@vip/ui/modal';
import { CepFacade } from '@vip/state/cep';
import { ValidarCepContainerComponent } from './validar-cep-container/validar-cep-container.component';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ValidarCepContainerService {
  cepValidado = new EventEmitter<boolean>();

  constructor(
    private cepFacade: CepFacade,
    private modalService: ModalService<ValidarCepContainerComponent>,
    private router: Router,
    protected layoutUtilsService: LayoutUtilsService
  ) {}

  validarCep(redirectTo?: string, disabled: boolean = false) {
    this.cepFacade.cepReset();
    const modalRef = this.modalService.openModal(ValidarCepContainerComponent, {
      bottom: true,
      disabled: disabled,
      showCloseButton: !disabled,
    });
    if (modalRef) {
      modalRef.instance.solicitacaoEnviadaSuccess
        .pipe(take(1))
        .subscribe(() => {
          this.modalService.clearModal();
          this.router.navigateByUrl('/');
        });
      modalRef.instance.validateSuccess.pipe(take(1)).subscribe((isEntrega) => {
        this.modalService.clearModal().finally(() => {
          this.cepValidado.emit(isEntrega);
          if (redirectTo) {
            this.router.navigateByUrl(redirectTo);
          }
        });
      });

      this.modalService.closeClick.pipe(take(1)).subscribe(() => {
        this.cepFacade.cepReset();
      });
    }
    return this.cepValidado;
  }
}
