import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import {
  AddClienteRequest,
  AddEditEnderecoRequest,
  AlterarSenhaRequest,
  ConsultaSolicitacaoSMSResponse,
  EditClienteRequest,
  GetInformacoesClienteResponse,
  LoginRequest,
  LoginResponse,
  RecuperarSenhaRequest,
  ValidacaoPinResponse,
  ValidarOTPResponse,
} from './cliente-api.service.types';
import { ClienteApiServiceMessages } from './cliente-api.service.messages';
import { BaseApiService } from '../common/base-api-service';
import { ICliente, IClienteDTO, IEndereco } from '@vip/core';
import { parseISO } from 'date-fns';
import { BaseApiResponse } from '../common/base-api-response';

@Injectable({
  providedIn: 'root',
})
export class ClienteApiService {
  private clienteUrl = 'loja/cliente';
  private clientesUrl = 'loja/clientes';
  private enderecoUrl = 'loja/clientes/enderecos';
  private enderecoEntregaUrl = 'loja/clientes/enderecos/getEnderecosEntrega';

  constructor(
    private api: BaseApiService<ClienteApiService>,
    messages: ClienteApiServiceMessages
  ) {
    api.setMessages(messages);
  }

  getEnderecosCliente(): Observable<Array<IEndereco>> {
    return this.api
      .get('getEnderecosCliente', `/${this.enderecoUrl}`)
      .pipe(pluck('data'));
  }

  getEnderecosEntrega(filialId: number): Observable<Array<IEndereco>> {
    return this.api
      .get(
        'getEnderecosEntrega',
        `/filial/${filialId}/${this.enderecoEntregaUrl}`
      )
      .pipe(pluck('data'));
  }

  addEnderecoCliente(
    filialId: number,
    endereco: AddEditEnderecoRequest
  ): Observable<IEndereco> {
    return this.api
      .post<AddEditEnderecoRequest, IEndereco>(
        'addEnderecoCliente',
        `/filial/${filialId}/${this.enderecoUrl}/adicionar`,
        endereco
      )
      .pipe(pluck('data'));
  }

  editEnderecoCliente(
    filialId: number,
    endereco: AddEditEnderecoRequest
  ): Observable<IEndereco> {
    return this.api
      .post<AddEditEnderecoRequest, IEndereco>(
        'editEnderecoCliente',
        `/${this.enderecoUrl}/${endereco.id}`,
        endereco
      )
      .pipe(pluck('data'));
  }

  deleteEnderecoCliente(enderecoId: number) {
    return this.api
      .delete('deleteEnderecoCliente', `/${this.enderecoUrl}/${enderecoId}`)
      .pipe(pluck('success'));
  }

  validarCliente(
    addClienteRequest: AddClienteRequest,
    filialId: number
  ): Observable<Array<{ field: string; message: string }>> {
    return this.api
      .post<AddClienteRequest, IClienteDTO>(
        'addCliente',
        `/filial/${filialId}/${this.clientesUrl}/validar/pre-cadastro`,
        addClienteRequest
      )
      .pipe(pluck('errors'));
  }

  addCliente(
    addClienteRequest: AddClienteRequest,
    filialId: number
  ): Observable<{ cliente: ICliente; token: string }> {
    return this.api
      .post<AddClienteRequest, { cliente: IClienteDTO; token: string }>(
        'addCliente',
        `/filial/${filialId}/${this.clientesUrl}/add`,
        addClienteRequest
      )
      .pipe(
        map((res) => {
          return {
            cliente: {
              ...res.cliente,
              data_nascimento: parseISO(res.cliente.data_nascimento),
            },
            token: res.token,
          };
        })
      );
  }

  editDadosCliente(cliente: EditClienteRequest): Observable<ICliente> {
    return this.api
      .put<
        EditClienteRequest,
        {
          cliente: IClienteDTO;
          errors: string[];
          message: string;
          success: boolean;
        }
      >('editDadosCliente', `/${this.clientesUrl}/edit`, '', cliente)
      .pipe(
        pluck('cliente'),
        map((clienteDTO: IClienteDTO) => {
          return {
            ...clienteDTO,
            data_nascimento: parseISO(clienteDTO.data_nascimento),
          };
        })
      );
  }

  getInformacoesCliente(
    token: string
  ): Observable<GetInformacoesClienteResponse> {
    return this.api.get<GetInformacoesClienteResponse>(
      'getInformacoesCliente',
      `/${this.clienteUrl}/index`,
      { Authorization: `Bearer ${token}` }
    );
  }

  login(filialId: number, params?: LoginRequest): Observable<LoginResponse> {
    return this.api.post<LoginRequest, LoginResponse>(
      'login',
      `/filial/${filialId}/loja/logar`,
      params
    );
  }

  alterarSenha(params?: AlterarSenhaRequest): Observable<LoginResponse> {
    return this.api.post<AlterarSenhaRequest, LoginResponse>(
      'alterarSenha',
      `/loja/clientes/alterar-senha`,
      params
    );
  }

  recuperarSenha(
    filialId: number,
    params: RecuperarSenhaRequest
  ): Observable<{ message: string; cliente: ICliente }> {
    return this.api.post<
      RecuperarSenhaRequest,
      { message: string; cliente: ICliente }
    >(
      'recuperarSenha',
      `/filial/${filialId}/loja/clientes/recuperar-senha`,
      params
    );
  }

  solicitarExclusao(
    filialId: number,
    observacao?: string
  ): Observable<BaseApiResponse<string>> {
    return this.api.post(
      'solicitarExclusao',
      `/loja/clientes/solicitacoes/filial/${filialId}`,
      { observacao }
    );
  }

  cadastroIncompleto(): Observable<BaseApiResponse<boolean>> {
    return this.api.get(
      'cadastroIncompleto',
      `/loja/clientes/cadastro_incompleto`
    );
  }

  buscarValidacaoPin(filialId: number): Observable<ValidacaoPinResponse> {
    return this.api
      .get<ValidacaoPinResponse>(
        'buscarValidacaoPin',
        `/filial/${filialId}/loja/clientes/pin_ativo`
      )
      .pipe(pluck('data'));
  }

  solicitarSMS(filialId: number, numeroCelular: string) {
    return this.api.post(
      'solicitarSMS',
      `/filial/${filialId}/loja/clientes/solicitar_sms_pin`,
      { telefone_celular: numeroCelular }
    );
  }

  solicitarSMSLogin(
    filialId: number,
    numeroCelular: string,
    cpf: string
  ): Observable<ConsultaSolicitacaoSMSResponse> {
    return this.api
      .post(
        'solicitarSMS',
        `/filial/${filialId}/loja/solicitar_pin_para_login`,
        {
          cpf,
          telefone_celular: numeroCelular,
        }
      )
      .pipe(pluck('data'));
  }

  consultaSolicitacaoSMS(
    filialId: number
  ): Observable<ConsultaSolicitacaoSMSResponse> {
    return this.api
      .get<ConsultaSolicitacaoSMSResponse>(
        'consultaSolicitacaoSMS',
        `/filial/${filialId}/loja/clientes/otp`
      )
      .pipe(pluck('data'));
  }

  validarOTP(filialId: number, code: string): Observable<ValidarOTPResponse> {
    return this.api
      .post('validarOTP', `/filial/${filialId}/loja/clientes/otp/validar`, {
        token: code,
      })
      .pipe(pluck('data'));
  }

  validarPinLogin(
    filialId: number,
    codigo: string,
    numeroCelular: string,
    cpf: string
  ): Observable<{ token: string }> {
    return this.api
      .post('validarOTP', `/filial/${filialId}/loja/validar_pin_para_login`, {
        codigo,
        cpf,
        telefone_celular: numeroCelular,
      })
      .pipe(pluck('data'));
  }

  invalidarUsuarioOTP(filialId: number, numeroCelular: string) {
    return this.api.post(
      'invalidarUsuarioOTP',
      `/filial/${filialId}/loja/clientes/otp/invalidar`,
      { telefone_celular: numeroCelular }
    );
  }

  async fetchWithRetry(url: string, retries = 3, delay = 1000): Promise<any> {
    for (let i = 0; i < retries; i++) {
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Erro ao buscar UTC');
        return response;
      } catch (error) {
        if (i === retries - 1) throw error;
        await new Promise((res) => setTimeout(res, delay));
      }
    }
  }
}
