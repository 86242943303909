<div class="grid gap-4 grid-cols-1">
  <p
    class="vip-color-primary-main"
    [ngClass]="{
      'vip-modal-title': isDesktop,
      'text-3xl text-center': !isDesktop
    }"
  >
    {{ title }}
  </p>
  <form [formGroup]="formGroup">
    <vip-field
      [error]="
        formGroup.controls.field.touched && formGroup.controls.field.invalid
      "
      [message]="validations.field"
      [validations]="formGroup.controls.field.errors"
    >
      <input
        *ngIf="fieldRows === 1"
        [vipAutofocus]="autofocus"
        id="field"
        formControlName="field"
        autocomplete="off"
        [placeholder]="fieldPlaceholder"
        [value]="fieldValue"
      />
      <textarea
        *ngIf="fieldRows > 1"
        [vipAutofocus]="autofocus"
        id="field"
        formControlName="field"
        [rows]="fieldRows"
        [value]="fieldValue"
        [placeholder]="fieldPlaceholder"
        [attr.maxlength]="fieldMaxLength"
      ></textarea>
    </vip-field>
  </form>
  <div
    [ngClass]="{
      'vip-modal-buttons': isDesktop
    }"
  >
    <vip-button (btnClick)="confirmarClick()" data-cy="btn-confirmar">
      Confirmar
    </vip-button>
  </div>
</div>
