import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ConfirmacaoSaidaComponent } from '@vip/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class ConfirmacaoSaidaPagamentoGuard
  implements CanDeactivate<ConfirmacaoSaidaComponent>
{
  canDeactivate(
    component: ConfirmacaoSaidaComponent | null
  ): Observable<boolean> {
    return component?.canDeactivate() ?? of(true);
  }
}
