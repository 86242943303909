<hr class="vip-divider-vertical rounded-full mr-2" />

<div class="flex items-center vip-icon-wrapper mr-2">
  <i class="text-3xl {{ icon }}"></i>
</div>

<div
  class="flex flex-col w-full"
  [ngClass]="{ 'justify-center': !title || !isDesktop }"
>
  <span class="font-medium text-xs" [ngClass]="{ 'mb-1': title && subtitle }">{{
    title || subtitle
  }}</span>
  <span *ngIf="title" class="vip-subtitle text-tiny overflow-hidden">{{
    subtitle
  }}</span>
</div>

<div
  class="flex items-center cursor-pointer"
  [ngClass]="{ 'justify-center': !isDesktop }"
  (click)="close($event)"
  data-cy="vip-close"
>
  <i
    class="icon-close vip-color text-sm vip-color-complementary-second-main opacity-40"
  ></i>
</div>
