import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperDesktopComponent } from './stepper-desktop/stepper-desktop.component';
import { ImageModule } from '@vip/ui/image';
import { RouterModule } from '@angular/router';
import { StepperVerticalComponent } from './stepper-vertical/stepper-vertical.component';
import { StepComponent } from './step/step.component';
import { ButtonModule } from '@vip/ui/button';
import { CoreModule } from '@vip/core';

@NgModule({
  imports: [CommonModule, ImageModule, RouterModule, ButtonModule, CoreModule],
  declarations: [
    StepperDesktopComponent,
    StepperVerticalComponent,
    StepComponent,
  ],
  exports: [StepperDesktopComponent, StepperVerticalComponent, StepComponent],
})
export class StepperDesktopModule {}
