import { ICampanhaIndicacao } from '../interfaces';

const dataInicio = new Date(new Date().setHours(new Date().getHours() - 2));
const dataFim = new Date(new Date().setHours(new Date().getHours() + 1));

export const campanha1Mock: ICampanhaIndicacao = {
  titulo: 'Compartilhe seu código com seus amigos e ganhe descontos!',
  codigo: 'VHL7K2EUUC',
  url_codigo: 'VHL7K2EUUC',
  data_limite: dataInicio,
  data_final: dataFim,
  valor_desconto_indicado: 10,
  mensagem:
    'Seus amigos irão ganhar R$ 15,00 de desconto na primeira compra e você R$ 10,00 na sua próxima compra. Aproveite!',
};
