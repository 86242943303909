import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import {
  ICarrinho,
  IMAGES_PATH,
  IProduto,
  IProdutosCarrinhoGroup,
  LayoutUtilsService,
} from '@vip/core';
import { DialogService, IDialog } from '@vip/ui/modal';
import { CompraEmProcessoService } from '@vip/state/compra-em-processo';
@Component({
  selector: 'vip-carrinho',
  templateUrl: './carrinho.component.html',
  styleUrls: ['./carrinho.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrinhoComponent {
  @Input() produtosCarrinho: IProdutosCarrinhoGroup[] = [];
  @Input() isDesabilitarBtn = false;
  @Input() produtosFavoritos: IProduto[] = [];
  @Input() produtosMaisVendidos: IProduto[] = [];
  @Input() carrinhoVazio = true;
  @Input() carrinho?: ICarrinho | null = null;
  @Input() produtosNoCarrinho?: Map<
    number,
    { item_id: number; quantidade: number; observacao?: string }
  > | null = null;
  @Input() combosNoCarrinho?: Map<
    number,
    { item_id: number; quantidade: number; observacao?: string }
  > | null = null;
  @Input() isLoading = false;
  @Input() isDesktop = false;
  @Input() isDesktopResponsive = false;
  @Input() isApp = false;
  @Input() leftClose = '';
  @Input() isTablet = false;
  @Output() showMoreClick = new EventEmitter();
  @Output() addListClick = new EventEmitter();
  @Output() produtoChanged = new EventEmitter();
  @Output() resumeClick = new EventEmitter();
  @Output() deleteItemClick = new EventEmitter();
  @Output() deleteCarrinhoClick = new EventEmitter();
  @Output() aviseMeClick = new EventEmitter<number>();
  @Output() closeClicked = new EventEmitter<void>();

  @Input() aviseMeEnviados: Map<number, boolean> | null = new Map<
    number,
    boolean
  >();

  imageUrl!: string;
  showIconDelete = true;

  constructor(
    @Inject(IMAGES_PATH) private imagesPath: string,
    private dialogService: DialogService,
    public layoutUtilsService: LayoutUtilsService,
    private compraEmProcessoService: CompraEmProcessoService
  ) {
    this.imageUrl = `${this.imagesPath}`;
  }

  trackByFnDepartamento(_: number, carrinho: IProdutosCarrinhoGroup) {
    return carrinho.departamento;
  }

  handleDeleteCarrinhoClick() {
    if (this.compraEmProcessoService.temCompraEmProcesso) return;
    let dialog: IDialog;

    if (this.isDesktop) {
      dialog = {
        open: true,
        title: 'Limpar produtos do carrinho',
        subTitle:
          'Tem certeza que deseja remover todos os produtos do seu carrinho?',
        buttonCancelText: 'Cancelar',
        buttonConfirmText: 'Limpar carrinho',
        disabled: false,
      };
    } else {
      dialog = {
        open: true,
        title: 'Deseja remover todos os itens?',
        buttonCancelText: 'Cancelar',
        buttonConfirmText: 'Limpar carrinho',
        disabled: false,
      };
    }

    this.dialogService.openDialog(dialog);
    //eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.dialogService.dialogClick.subscribe((event) => {
      if (event) {
        this.deleteCarrinhoClick.emit();
      }
      this.dialogService.clearDialog();
    });
  }

  get isValido() {
    return this.carrinho
      ? this.carrinho.preco > this.carrinho.valor_minimo
      : false;
  }

  handleResumeClick() {
    if (!this.isDesabilitarBtn) {
      this.resumeClick.emit(this.isValido);
    }
  }

  handleCloseClick() {
    this.closeClicked.emit();
  }
}
