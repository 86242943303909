import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermosUsoComponent } from './termos-uso/termos-uso.component';
import { CoreModule } from '@vip/core';
import { ScreenTitleModule } from '@vip/ui/screen-title';
import { ButtonModule } from '@vip/ui/button';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from '@vip/ui/checkbox';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ScreenTitleModule,
    ButtonModule,
    CheckboxModule,
    FormsModule,
  ],
  exports: [TermosUsoComponent],
  declarations: [TermosUsoComponent],
})
export class TermosUsoModule {}
