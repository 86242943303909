import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as VitrineDedicadaActions from './vitrine-dedicada.actions';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { fetch } from '@nrwl/angular';
import { VitrineDedicadaApiService } from '@vip/api';

@Injectable()
export class VitrineDedicadaEffects {
  loadVitrine$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(VitrineDedicadaActions.loadVitrine)),
      this.cdFacade.filialECdSelecionado$,
    ]).pipe(
      map(([action, filialECdSelecionado]) => {
        return { ...action, filialECdSelecionado };
      }),
      fetch({
        run: (action) => {
          return this.vitrineApiService
            .getVitrines(
              action.filialECdSelecionado[0].id,
              action.filialECdSelecionado[1].id
            )
            .pipe(
              map((res) => {
                return VitrineDedicadaActions.loadVitrineSuccess({
                  vitrines: [...res],
                });
              })
            );
        },
        onError: (action, error) =>
          VitrineDedicadaActions.loadVitrineError({ error }),
      })
    );
  });

  constructor(
    private actions$: Actions,
    private cdFacade: CentroDistribuicaoFacade,
    private vitrineApiService: VitrineDedicadaApiService
  ) {}
}
