import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { BucketsFacade } from '@vip/state/buckets';
import { ProdutoApiService } from '@vip/api';
import { MultipleProdutoStore } from './produto.store';
import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import {
  IApiPaginator,
  IProduto,
  IVitrinesDedicadas,
  MultipleProdutoStateData,
} from '@vip/core';
import { GenericStoreStatusEnum } from '@vip/state/utils';

@Injectable()
export class ProdutosVitrineDedicadaStore extends MultipleProdutoStore {
  readonly listaVitrinesSemPerfil$ = this.select(
    this.listaVitrinesArray$,
    (lista: MultipleProdutoStateData[]) =>
      lista.filter((vitrine) => !vitrine.perfil)
  );

  readonly listaVitrinesComPerfil$ = this.select(
    this.listaVitrinesArray$,
    (lista: MultipleProdutoStateData[]) =>
      lista.filter((vitrine) => vitrine.perfil)
  );

  readonly getVitrines = this.effect(
    (payload$: Observable<{ vitrines: IVitrinesDedicadas[] }>) => {
      return combineLatest([
        payload$,
        this.cdFacade.filialECdSelecionado$,
        this.bucketsFacade.bucketProduto$.pipe(
          filter((bucket) => bucket !== '')
        ),
      ]).pipe(
        map(([payload, [filial, cdSelecionado], bucket]) => {
          return { ...payload, filial, cdSelecionado, bucket };
        }),
        mergeMap((payload) => {
          this.updateStatus(GenericStoreStatusEnum.LOADING);
          const vitrines = payload.vitrines.map((vitrine) => {
            return this.produtoApiService.getVitrinesDedicadas(
              payload.filial.id,
              payload.cdSelecionado.id,
              vitrine.id
            );
          });

          if (vitrines.length === 0) {
            this.updateStatus(GenericStoreStatusEnum.SUCCESS);
          }
          return forkJoin([...vitrines]).pipe(
            tapResponse(
              (response) => {
                this.updateStatus(GenericStoreStatusEnum.SUCCESS);
                response.forEach((lista, index) => {
                  this.updateProdutosAndPaginator({
                    id: payload.vitrines[index].id,
                    title: payload.vitrines[index].descricao,
                    perfil: payload.vitrines[index].perfil_ids.length > 0,
                    produtos: lista.data,
                    paginator: lista.paginator,
                    bucket: payload.bucket,
                  });
                });
              },
              (error) =>
                this.updateError((error as HttpErrorResponse).error.error)
            )
          );
        })
      );
    }
  );

  readonly getVitrineById = this.effect(
    (payload$: Observable<{ vitrine: IVitrinesDedicadas }>) => {
      return payload$.pipe(
        switchMap(({ vitrine }) =>
          combineLatest([
            of(vitrine),
            this.cdFacade.filialECdSelecionado$,
            this.bucketsFacade.bucketProduto$.pipe(
              filter((bucket) => bucket !== '')
            ),
          ])
        ),
        map(([vitrine, [filial, cdSelecionado], bucket]) => {
          return { vitrine, filial, cdSelecionado, bucket };
        }),
        switchMap((payload) => {
          this.updateStatus(GenericStoreStatusEnum.LOADING);

          return this.produtoApiService
            .getVitrinesDedicadas(
              payload.filial.id,
              payload.cdSelecionado.id,
              payload.vitrine.id
            )
            .pipe(
              tapResponse(
                (response) => {
                  this.updateProdutosAndPaginator({
                    id: payload.vitrine.id,
                    title: payload.vitrine.descricao,
                    perfil: payload.vitrine.perfil_ids.length > 0,
                    produtos: response.data,
                    paginator: response.paginator,
                    bucket: payload.bucket,
                  });
                },
                (error) =>
                  this.updateError((error as HttpErrorResponse).error.error)
              )
            );
        })
      );
    }
  );

  constructor(
    private produtoApiService: ProdutoApiService,
    private bucketsFacade: BucketsFacade,
    private cdFacade: CentroDistribuicaoFacade
  ) {
    super();
  }

  readonly updateProdutosAndPaginator = (data: {
    id: string;
    title: string;
    produtos: IProduto[];
    perfil?: boolean;
    paginator?: IApiPaginator;
    bucket?: string;
  }) =>
    this.addListaProdutos([
      {
        id: data.id,
        title: data.title,
        perfil: data.perfil,
        produtos: data.produtos.map((produto) => {
          return this.mapProdutos(produto, data.bucket);
        }),
        paginator: data.paginator || this.initialPaginator,
      },
    ]);
}
