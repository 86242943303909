<div
  class="vip-menu-item"
  vipRipple
  rippleBorderRadius="0px"
  [ngClass]="type"
  [ngClass]="{
    'vip-menu-item-no-padding-left': noPaddingLeft,
    desktop: isDesktop,
    mobile: !isDesktop
  }"
>
  <button
    class="focus:outline-none"
    [class.vip-menu-item-active]="active"
    [class.with-subtitle]="subtitle"
    [style.color]="active ? color : 'var(--vip-color-first-default-main)'"
    (click)="onMenuClick()"
  >
    <i
      *ngIf="icon && type !== 'dropdown'; else showImagem"
      [style.color]="iconColor"
      [class]="icon + ' vip-menu-item-icon'"
      [class.with-subtitle]="subtitle"
    ></i>
    <ng-template #showImagem>
      <img
        class="vip-menu-item-image"
        *ngIf="image && type !== 'dropdown'"
        [src]="image"
      />
    </ng-template>
    <div
      class="vip-menu-item-text text-left truncate"
      [ngClass]="{
        'my-2': !icon
      }"
    >
      <div
        [ngClass]="{
          'ml-4': isDesktop
        }"
        class="vip-menu-item-title truncate"
      >
        {{ title }}
      </div>
      <div
        class="vip-menu-item-subtitle truncate"
        *ngIf="subtitle && type === 'mobile'"
      >
        {{ subtitle }}
      </div>
    </div>
    <div class="vip-menu-item-info" *ngIf="info">{{ info }}</div>
  </button>
  <hr *ngIf="showDivider" class="vip-divider" />
</div>
