import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TabItemBaseDirective } from '../base/tab-item-base.directive';

@Component({
  selector: 'vip-tab-sub-item',
  templateUrl: './tab-sub-item.component.html',
  styleUrls: ['./tab-sub-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.selected]': 'selected',
  },
})
export class TabSubItemComponent extends TabItemBaseDirective {}
