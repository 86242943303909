import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { ICompra } from '@vip/core';
import { CartoesWsService } from '@vip/ws';
import { filter, map } from 'rxjs/operators';
import * as CartaoActions from './cartao.actions';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { CompraEmProcessoFacade } from '@vip/state/compra-em-processo';
import { BucketsFacade, BucketsService } from '@vip/state/buckets';
import { mergeTakeOne } from '@vip/state/utils';

@Injectable()
export class CartaoEffects {
  getCartoes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartaoActions.getCartoes),
      mergeTakeOne(
        this.cdFacade.filialECdSelecionado$,
        this.compraEmProcessoFacade.compraEmProcesso$,
        this.bucketFacade.bucketS3$
      ),
      map(([action, [filial, cdSelecionado], compraEmProcesso, bucketS3]) => ({
        ...action,
        filial,
        cdSelecionado,
        compraEmProcesso,
        bucketS3,
      })),
      filter(
        ({ compraEmProcesso }) =>
          compraEmProcesso !== null && compraEmProcesso !== undefined
      ),
      fetch({
        run: ({ filial, cdSelecionado, compraEmProcesso, bucketS3: bucket }) =>
          this.cartoesWsService
            .getCartoesSalvos(
              filial.id,
              cdSelecionado.id,
              (compraEmProcesso as ICompra).tipo_entrega.id
            )
            .pipe(
              map(({ cartoes }) => {
                return CartaoActions.getCartoesSuccess({
                  cartoes: cartoes.map((cartao) =>
                    this.bucketsService.addBucketCartao(cartao, bucket)
                  ),
                });
              })
            ),

        onError: (_, error) => {
          return CartaoActions.getCartoesFailure({ error });
        },
      })
    )
  );

  deletarCartao$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartaoActions.deletarCartao),
      fetch({
        run: (action) =>
          this.cartoesWsService.deletarCartao(action.id).pipe(
            map(() => {
              return CartaoActions.deletarCartaoSuccess({ id: action.id });
            })
          ),

        onError: (_, error) => {
          return CartaoActions.deletarCartaoFailure({ error });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private cdFacade: CentroDistribuicaoFacade,
    private cartoesWsService: CartoesWsService,
    private bucketFacade: BucketsFacade,
    private compraEmProcessoFacade: CompraEmProcessoFacade,
    private bucketsService: BucketsService
  ) {}
}
