import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeusDadosContainerComponent } from './meus-dados-container/meus-dados-container.component';
import { MeusDadosViewModule } from '@vip/views/meus-dados';
import { ClienteStateModule } from '@vip/state/cliente';
import { RouterModule } from '@angular/router';
import { FilialStateModule } from '@vip/state/filial';
import { CepStateModule } from '@vip/state/cep';

@NgModule({
  imports: [
    CommonModule,
    MeusDadosViewModule,
    ClienteStateModule,
    FilialStateModule,
    RouterModule.forChild([
      {
        path: '',
        component: MeusDadosContainerComponent,
      },
    ]),
    CepStateModule,
  ],
  declarations: [MeusDadosContainerComponent],
  exports: [MeusDadosContainerComponent],
})
export class MeusDadosContainerModule {}
