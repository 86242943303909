export type SearchEventType =
  | 'click'
  | 'enter'
  | 'debounce'
  | 'input'
  | 'keyup';

export class SearchEvent extends Event {
  constructor(
    type: SearchEventType,
    public value: string,
    public originalEvent?: Event
  ) {
    super('SearchEvent.' + type, {
      bubbles: originalEvent?.bubbles,
      cancelable: originalEvent?.cancelable,
      composed: originalEvent?.composed,
    });
  }
}
