<div
  *ngIf="item"
  (click)="categoriaItemClick.emit(item)"
  class="categoria-item flex flex-col items-center pt-3 px-2 pb-2 rounded"
>
  <vip-image [imageUrl]="item.imagem" [size]="imageSizeEnum.sm"></vip-image>
  <div class="flex justify-center items-center categoria-item-desc-box mt-2">
    <p class="categoria-item-desc vip-color-first-default text-xs text-center">
      {{ item.descricao }}
    </p>
  </div>
</div>
