import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { IFilial, ITermosUso, LayoutUtilsService } from '@vip/core';
import { TermosUsoApiService } from '@vip/api';
import { FilialFacade } from '@vip/state/filial';
import * as TermosUso from './termos-uso.actions';
import { termosUsoSelectors } from './termos-uso.selectors';
import { Router } from '@angular/router';
import { TermosUsoModalService } from '../termos-uso-modal-service/termos-uso-modal.service';
import { mergeTakeOne } from '@vip/state/utils';

@Injectable()
export class TermosUsoEffects {
  getTermosUso$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TermosUso.getTermosUso),
      (action) => combineLatest([action, this.filialFacade.filial$]),
      distinctUntilChanged(
        ([, oldFilial], [, newFilial]) => oldFilial.id === newFilial.id
      ),
      fetch({
        run: (action, filial: IFilial) =>
          this.termosUsoService.getTermosUso(filial.id).pipe(
            map((termosUso) =>
              TermosUso.getTermosUsoSuccess({
                TermosUso: termosUso.data,
              })
            )
          ),
        onError: (action, error) => {
          return TermosUso.getTermosUsoFailure({ error });
        },
      })
    )
  );

  aprovarTermosUso$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TermosUso.aprovarTermosUso),
      withLatestFrom(
        this.store$.pipe(
          select(termosUsoSelectors.getData()),
          filter((termosUso): termosUso is ITermosUso => termosUso !== null)
        )
      ),
      fetch({
        run: (action, termoDeUso: ITermosUso) =>
          this.termosUsoService.aprovarTermosUso(termoDeUso.id).pipe(
            map((aprovarTermosUso) =>
              TermosUso.aprovarTermosUsoSuccess({
                aprovarTermosUso: aprovarTermosUso.data,
              })
            )
          ),
        onError: (action, error) => {
          return TermosUso.aprovarTermosUsoFailure({ error });
        },
      })
    )
  );

  getAprovacaoPendente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TermosUso.getAprovacaoPendente),
      (action) => combineLatest([action, this.filialFacade.filial$]),
      fetch({
        run: (action, filial: IFilial) =>
          this.termosUsoService.getAprovacaoPendente(filial.id).pipe(
            map((aprovacaoPendente) =>
              TermosUso.getAprovacaoPendenteSuccess({
                aprovacaoPendente: aprovacaoPendente.data,
              })
            )
          ),
        onError: (action, error) => {
          return TermosUso.getTermosUsoFailure({ error });
        },
      })
    )
  );

  redirectAprovarTermoDeUso$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TermosUso.getAprovacaoPendenteSuccess),
        mergeTakeOne(this.layoutUtilsService.isDesktop$),
        filter(([aprovacaoPendente]) => !!aprovacaoPendente.aprovacaoPendente),
        tap(([_, isDesktop]) => {
          if (isDesktop || this.layoutUtilsService.isDesktopResponsive())
            this.termosUsoModalService.openModalTermosUso();
          else
            this.router.navigate(['/termos-uso'], {
              queryParams: { page: 'atualizacao' },
            });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private termosUsoService: TermosUsoApiService,
    private filialFacade: FilialFacade,
    private router: Router,
    private store$: Store,
    private termosUsoModalService: TermosUsoModalService,
    private layoutUtilsService: LayoutUtilsService
  ) {}
}
