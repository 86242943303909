import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IProdutoCombo } from '@vip/core';
import { ISpinEvent } from '@vip/ui/spin';

@Component({
  selector: 'vip-card-combo-mobile',
  templateUrl: './card-combo-mobile.component.html',
  styleUrls: ['./card-combo-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComboMobileComponent {
  @Input() combo!: IProdutoCombo;
  @Input() isDesktop = false;
  @Input() isTablet = false;
  @Input() hasProductInfo = false;
  @Input() valorDiferenca?: string;
  @Input() editable = false;
  @Input() loading = false;
  @Input() showSpinLarge = false;
  @Input() quantidade = 0;
  @Input() hasCombo = false;
  @Input() quantidadeMaximaSpinner = 0;
  @Output() handleSpin = new EventEmitter();

  handleSpinAlterado(event: ISpinEvent) {
    this.handleSpin.emit(event);
  }
}
