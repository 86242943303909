import { IApplicationMenuItem } from './application-menu-items.types';

export const APPLICATION_MENU_ITENS: IApplicationMenuItem[] = [
  {
    key: 'home',
    icon: 'home',
    title: 'Início',
    subtitle: 'Acesse nossa loja',
    value: 'home',
  },
  {
    key: 'selecionar_cliente',
    icon: 'account_circle',
    title: 'Selecionar Cliente',
    subtitle: 'Dados cadastrais, Cupons e Endereços',
    value: 'selecionar_cliente',
    availableWhenLoggedOut: true,
  },
  {
    key: 'encerrar_sessao',
    icon: 'backspace',
    title: 'Encerrar Sessão',
    subtitle: 'Encerrar essa sessão de televendas',
    value: 'encerrar_sessao',
    availableWhenLoggedOut: true,
  },
  {
    key: 'shop',
    icon: 'shopping_basket',
    title: 'Loja',
    value: 'loja',
  },
  {
    key: 'shopping_cart',
    icon: 'shopping_cart',
    title: 'Carrinho',
    value: 'carrinho',
    center: true,
  },
  {
    key: 'cashback',
    icon: 'cashback',
    title: 'Cashback',
    subtitle: '{{cashback_update_value}}',
    info: '{{cashback_value}}',
    value: 'cashback',
  },
  {
    key: 'profile',
    icon: 'account_circle',
    title: 'Meu Perfil',
    subtitle: 'Dados cadastrais, Cupons e Endereços',
    value: 'minha-conta',
  },
  {
    key: 'purchases',
    icon: 'inbox',
    title: 'Minhas Compras',
    subtitle: 'Histórico de Compras e Trocas e Devoluções',
    value: 'minha-conta/historico-de-compras',
  },
  {
    key: 'lists',
    icon: 'list_alt',
    title: 'Minhas Listas',
    subtitle: 'Acesso à suas Listas de Compras',
    value: 'minhas-listas',
  },
  {
    key: 'favorites',
    icon: 'favorite_border',
    title: 'Favoritos',
    subtitle: 'Seus produtos comprados recentemente',
    value: 'favoritos',
  },
  {
    key: 'departments',
    icon: 'grocery',
    title: 'Departamentos',
    subtitle: 'Produtos separados por departamentos',
    value: 'departamentos',
    availableWhenLoggedOut: true,
  },
  {
    key: 'combos',
    icon: 'fastfood',
    title: 'Combos',
    subtitle: 'Combos de produtos',
    value: 'combos',
    availableWhenLoggedOut: true,
  },
  {
    key: 'promotions',
    icon: 'sell',
    title: 'Ofertas',
    subtitle: 'Descontos imperdíveis para você',
    value: 'ofertas',
    availableWhenLoggedOut: true,
  },
  {
    key: 'collections',
    icon: 'spa',
    title: 'Coleções',
    subtitle: 'Produtos veganos, fitness e muito mais',
    value: 'colecoes',
    availableWhenLoggedOut: true,
  },
  {
    key: 'recipes',
    icon: 'local_dining',
    title: 'Receitas',
    subtitle: 'Aprenda a fazer as melhores receitas',
    value: 'receitas',
    availableWhenLoggedOut: true,
  },
  {
    key: 'invite',
    icon: 'people',
    title: 'Convide Amigos',
    subtitle: 'Convide amigos e ganhe recompensas',
    value: 'convide-amigos',
  },
  {
    key: 'contact',
    icon: 'message',
    title: 'Fale Conosco',
    subtitle: 'Entre em contato com nosso time',
    value: 'fale-conosco',
    availableWhenLoggedOut: true,
  },
  {
    key: 'institutional',
    icon: 'info',
    title: 'Institucional',
    subtitle: 'Saiba um pouco mais sobre nós',
    value: 'institucional',
    availableWhenLoggedOut: true,
  },
  {
    key: 'policy',
    icon: 'insert_drive_file',
    title: 'Termos de Uso',
    subtitle: 'Confira nossos termos de uso',
    value: 'termos-uso',
    availableWhenLoggedOut: true,
  },
  {
    key: 'menu',
    title: 'Menu',
    icon: 'widgets',
    value: 'menu',
  },
  {
    key: 'search',
    title: 'Buscar',
    icon: 'search',
    value: 'search',
  },
  {
    key: 'logout',
    icon: 'close',
    title: 'Sair',
    subtitle: 'Desconecte da sua conta',
    color: 'red',
    value: 'logout',
  },
];

export const SITE_MENU_ITENS: IApplicationMenuItem[] = [
  {
    key: 'cashback',
    icon: 'cashback',
    title: 'Cashback',
    value: 'cashback',
    showDivider: true,
  },
  {
    key: 'profile',
    icon: 'account_circle',
    title: 'Meu Perfil',
    value: 'perfil',
  },
  {
    key: 'endereco',
    icon: 'home',
    title: 'Meus Endereços',
    value: 'endereco',
  },
  {
    key: 'notifications',
    icon: 'notifications',
    title: 'Notificações',
    value: 'notifications',
    showDivider: true,
  },
  {
    key: 'purchases',
    icon: 'inbox',
    title: 'Minhas Compras',
    value: 'historico-de-compras',
  },
  {
    key: 'lists',
    icon: 'list_alt',
    title: 'Minhas Listas',
    value: 'minhas-listas',
  },
  {
    key: 'favorites',
    icon: 'favorite_border',
    title: 'Favoritos',
    value: 'favoritos',
    showDivider: true,
  },
  {
    key: 'invite',
    icon: 'people',
    title: 'Convide Amigos',
    value: 'convide-amigos',
    showDivider: true,
  },
];

export const SITE_MENU_ITENS_PERFIL: IApplicationMenuItem[] = [
  {
    key: 'cashback',
    icon: 'cashback',
    title: 'Cashback',
    info: '{{cashback_value}}',
    value: 'cashback',
    showDivider: true,
  },
  {
    key: 'profile',
    icon: 'account_circle',
    title: 'Meu Perfil',
    value: 'perfil',
  },
  {
    key: 'endereco',
    icon: 'home',
    title: 'Meus Endereços',
    value: 'endereco',
  },
  {
    key: 'notifications',
    icon: 'notifications',
    title: 'Notificações',
    value: 'notifications',
    showDivider: true,
  },
  {
    key: 'purchases',
    icon: 'shopping_cart',
    title: 'Minhas Compras',
    value: 'historico-de-compras',
  },
  {
    key: 'lists',
    icon: 'list_alt',
    title: 'Minhas Listas',
    value: 'minhas-listas',
    hiddenTitleContainer: true,
    showBorder: true,
  },
  {
    key: 'favorites',
    icon: 'favorite_border',
    title: 'Favoritos',
    value: 'favoritos',
    showDivider: true,
  },
  {
    key: 'invite',
    icon: 'people',
    title: 'Convide Amigos',
    value: 'convide-amigos',
    showDivider: true,
  },
  {
    key: 'sair',
    icon: 'close',
    title: 'Sair',
    color: 'red',
    value: 'sair',
  },
];
