import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  HostListener,
  NgZone,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SolicitarProdutoRequest } from '@vip/api';
import { AddToListContainerService } from '@vip/container/add-to-list';
import { AtualizarCarrinhoService } from '@vip/container/carrinho';
import {
  EnderecoEntregaRetiradaContainerComponent,
  EntregaRetiradaBaseDirective,
} from '@vip/container/entrega-retirada';
import { ValidarCepContainerService } from '@vip/container/validar-cep';
import {
  EntregaRetiradaUtilsService,
  FormaEntregaEnum,
  HeaderUtilsService,
  ICarrinho,
  ICentroDistribuicao,
  ICliente,
  IEndereco,
  IFilial,
  IProduto,
  IRelacionamentoCliente,
  LayoutUtilsService,
  MenuOptions,
  SITE_MENU_ITENS,
  ScrollService,
} from '@vip/core';
import { AplicativoFacade } from '@vip/state/aplicativo';
import { AuthService } from '@vip/state/auth';
import { BreadcrumbStore } from '@vip/state/breadcrumb';
import { BucketsFacade } from '@vip/state/buckets';
import { BuscaStore } from '@vip/state/busca';
import { CarrinhoFacade } from '@vip/state/carrinho';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { CepFacade } from '@vip/state/cep';
import { ClassificacaoMercadologicaFacade } from '@vip/state/classificacao-mercadologica';
import { ClienteFacade, FaviconService } from '@vip/state/cliente';
import { CompraEmProcessoFacade } from '@vip/state/compra-em-processo';
import { EnderecoFacade } from '@vip/state/endereco';
import { FilialFacade } from '@vip/state/filial';
import { InstitucionalFacade } from '@vip/state/institucional';
import { LayoutStore } from '@vip/state/layout';
import {
  ProdutoFacade,
  ProdutosBuscaRapidaStore,
  ProdutosFavoritosStore,
  ProdutosIndisponiveisService,
  ProdutosIndisponiveisStore,
  ProdutosMaisVendidosStore,
} from '@vip/state/produto';
import { TipoEntregaFacade } from '@vip/state/tipo-entrega';
import { DialogService, IDialog, ModalService } from '@vip/ui/modal';
import { SearchEvent } from '@vip/ui/search';
import { ISpinEventComProduto } from '@vip/ui/spin';
import {
  Observable,
  Subscription,
  combineLatest,
  of,
  queueScheduler,
} from 'rxjs';
import {
  map,
  observeOn,
  take,
  delay,
  filter,
  distinctUntilChanged,
} from 'rxjs/operators';
import {
  layoutDesktopContainerMensagemDeErro,
  layoutDesktopContainerMensagemDeSucesso,
} from './layout-desktop-container-dialog-consts';

import { EnderecoComponent } from '@vip/views/endereco';
import { mergeTakeOne } from '@vip/state/utils';
import { CashbackFacade } from '@vip/state/cashback';
import { NotificacaoFacade } from '@vip/state/notificacao';
import { SubstituirProdutoService } from '@vip/state/substituir-produto';
import { SubstituirProdutoContainerComponent } from '@vip/container/substituir-produto';
import { TermosUsoModalService } from '@vip/state/termos-uso';
import { TermosUsoContainerComponent } from '@vip/container/termos-uso';
import { ModalCompletarCadastroComponent } from '@vip/container/modal-completar-cadastro';
import { TelevendasFacade } from '@vip/state/televendas';
import { CacheUtilsService } from '@vip/utils/cache';

@UntilDestroy()
@Component({
  selector: 'vip-layout-desktop-container',
  templateUrl: './layout-desktop-container.component.html',
  styleUrls: ['./layout-desktop-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProdutosBuscaRapidaStore,
    ProdutosFavoritosStore,
    ProdutosMaisVendidosStore,
    ProdutosIndisponiveisStore,
    ProdutosIndisponiveisService,
  ],
})
export class LayoutDesktopContainerComponent
  extends EntregaRetiradaBaseDirective
  implements OnInit, AfterViewInit
{
  showHeader = true;
  showFooter = true;
  redesSociais = {};
  scrolling$ = ScrollService.scrolling$;
  fullscreenIframe = false;

  favoritos$ = this.produtosFavoritosStore.produtos$;
  maisVendidos$ = this.produtosMaisVendidosStore.produtos$;
  carrinho$ = this.carrinhoFacade.carrinho$;
  carrinho!: ICarrinho;
  combosNoCarrinho$ = this.carrinhoItensFacade.combosNoCarrinho$;
  itensDepartamento$ = this.carrinhoItensFacade.itensDepartamento$;
  qtdItensCarrinho$ = this.carrinhoFacade.qtdItensCarrinho$;
  carrinhoVazio = false;

  readonly dialogCadastroIncompleto: IDialog = {
    open: true,
    title: 'Cadastro incompleto',
    subTitle:
      'Para finalizar sua compra, precisamos completar algumas informações do seu cadastro.',
    disabled: false,
    buttonConfirmText: 'Completar cadastro',
  };

  readonly dialogFazerLogin: IDialog = {
    open: true,
    title: 'Faça login ou crie uma conta!',
    subTitle:
      'Para finalizar a sua compra, precisamos que você faça o login com seu usuário ou crie uma conta.',
    disabled: false,
    buttonConfirmText: 'Criar uma conta',
    buttonCancelText: 'Fazer login',
  };

  filial?: IFilial;
  minhaContaOptions = SITE_MENU_ITENS;

  disableEntregaRetirada = false;
  options: MenuOptions = [
    {
      internal: 'true',
      label: 'Mais Vendidos',
      link: '/mais-vendidos',
    },
    {
      internal: 'true',
      label: 'Ofertas',
      link: '/ofertas',
    },
    {
      internal: 'true',
      label: 'Coleções',
      link: '/colecoes',
    },
    {
      internal: 'true',
      label: 'Receitas',
      link: '/receitas',
    },
  ];
  /**
   * End Mocked Values
   */
  departamentos$ = this.classificacaoMercadologicaFacade.arvore$;
  cliente$ = this.clienteFacade.cliente$;
  loading$ = this.clienteFacade.loading$;
  filial$ = this.cdFacade.filialECdSelecionado$.pipe(map(([filial]) => filial));
  paginasSite$ = this.institucionalFacade.paginasSite$;
  aplicativo$ = this.aplicativoFacade.aplicativo$;
  termos$ = this.buscaStore.termos$;
  produtos$ = this.buscaRapidaStore.produtos$;
  produtosNoCarrinho$ = this.carrinhoItensFacade.produtosNoCarrinho$;
  relacionamentoCliente$: Observable<IRelacionamentoCliente>;
  headerDesktopIsVisible$ = this.layoutStore.headerDesktopIsVisible$;
  footerDesktopIsVisible$ = this.layoutStore.footerDesktopIsVisible$;
  footerResponsivoIsVisible$ = this.layoutStore.footerResponsivoIsVisible$;
  exibeCashback$ = this.cashbackFacade.exibeCashback$;
  saldoCashback$ = this.cashbackFacade.saldoCashback$;
  saldoCashbackError$ = this.cashbackFacade.saldoCashbackError$;
  notificationsCount$ = this.notificacaoFacade.quantidadeNaoLidas$;
  isDesktop$ = this.layoutUtilsService.isDesktop$;
  isDesktopResponsive = this.layoutUtilsService.isDesktop();
  responsive = this.layoutUtilsService.isDesktopResponsive();
  logo$ = this.headerUtilsService.getLogo(
    this.bucketsFacade.bucketS3$,
    this.filial$
  );
  logoFallback$ = this.headerUtilsService.getLogoFallback(
    this.bucketsFacade.bucketS3$
  );
  loadingBuscaRapida$ = this.buscaRapidaStore.loading$;
  modalSubstituirProdutos?: ComponentRef<unknown>;
  logoBusca$ = this.bucketsFacade.bucketS3$.pipe(
    map((s3) => {
      return `${s3}/files/tema/filial-${
        this.filialId
      }/header-site-omni.png?${Date.now()}`;
    })
  );

  faviconBusca$!: Observable<string | null>;
  favicon = this.faviconService.getFavicon();
  logoBuscaFallback$ = this.bucketsFacade.bucketS3$.pipe(
    map((s3) => `${s3}/files/tema/logo-busca.png?${Date.now()}`)
  );
  logosBuscas$ = combineLatest([this.logoBusca$, this.logoBuscaFallback$]).pipe(
    map((value) => {
      const [logoBusca, logoBuscaFallback] = value;
      return logoBusca || logoBuscaFallback;
    })
  );
  isTelevendas = this.televendasFacade.isTelevendas;
  novoEnderecoCdSelect = false;
  enderecoChangeModalPrimeiroEndereco = false;
  modalPrimeiroProduto!: EnderecoEntregaRetiradaContainerComponent;
  enderecoSelecionadoSelect!: any;

  constructor(
    enderecoFacade: EnderecoFacade,
    cdFacade: CentroDistribuicaoFacade,
    cepFacade: CepFacade,
    clienteFacade: ClienteFacade,
    tipoEntregaFacade: TipoEntregaFacade,
    changeDetector: ChangeDetectorRef,
    dialogService: DialogService,
    router: Router,
    navController: NavController,
    activatedRoute: ActivatedRoute,
    validarCepContainerService: ValidarCepContainerService,
    filialFacade: FilialFacade,
    carrinhoFacade: CarrinhoFacade,
    compraEmProcessoFacade: CompraEmProcessoFacade,
    modalService: ModalService<unknown>,
    private authService: AuthService,
    private faviconService: FaviconService,
    private bucketsFacade: BucketsFacade,
    private buscaStore: BuscaStore,
    private buscaRapidaStore: ProdutosBuscaRapidaStore,
    private carrinhoItensFacade: CarrinhoItensFacade,
    private layoutUtilsService: LayoutUtilsService,
    televendasFacade: TelevendasFacade,
    private institucionalFacade: InstitucionalFacade,
    private aplicativoFacade: AplicativoFacade,
    private layoutStore: LayoutStore,
    private produtosFavoritosStore: ProdutosFavoritosStore,
    private produtosMaisVendidosStore: ProdutosMaisVendidosStore,
    public atualizarCarrinhoService: AtualizarCarrinhoService,
    public produtosIndisponiveisService: ProdutosIndisponiveisService,
    public addToListContainerService: AddToListContainerService,
    private classificacaoMercadologicaFacade: ClassificacaoMercadologicaFacade,
    private ngZone: NgZone,
    private produtoFacade: ProdutoFacade,
    private breadCrumbStore: BreadcrumbStore,
    private entregaRetiradaUtilsService: EntregaRetiradaUtilsService,
    private headerUtilsService: HeaderUtilsService,
    private cashbackFacade: CashbackFacade,
    private notificacaoFacade: NotificacaoFacade,
    private substituirProdutoService: SubstituirProdutoService,
    private termosUsoModalService: TermosUsoModalService,
    private cacheUtilsService: CacheUtilsService
  ) {
    super(
      enderecoFacade,
      cdFacade,
      cepFacade,
      clienteFacade,
      tipoEntregaFacade,
      filialFacade,
      compraEmProcessoFacade,
      carrinhoFacade,
      changeDetector,
      dialogService,
      router,
      navController,
      activatedRoute,
      validarCepContainerService,
      true,
      modalService,
      televendasFacade,
      undefined,
      undefined,
      layoutUtilsService.isDesktopResponsive()
    );
    this.relacionamentoCliente$ = this.getRelacionamentoClienteData();
    this.setFavicon();
  }
  @HostListener('window:scroll', [])
  onScroll = ScrollService.onScroll;
  private setFavicon() {
    if (this.faviconService.getFavicon()) {
      this.faviconBusca$ = of(this.faviconService.getFavicon());
    } else {
      this.faviconBusca$ = this.bucketsFacade.bucketS3$.pipe(
        map((s3) => {
          const favicon = `${s3}/files/tema/filial-${
            this.filialId
          }/favicon-site-omni.png?${Date.now()}`;
          this.faviconService.setFavicon(favicon);
          return favicon;
        })
      );
    }
  }

  ngAfterViewInit(): void {
    this.entregaRetiradaUtilsService.openModalSolicitarCep$
      .pipe(
        take(1),
        untilDestroyed(this),
        delay(1),
        filter((showSolicitar) => showSolicitar !== null)
      )
      .subscribe((showSolicitar) => {
        this.selecaoCdObrigatoria = true;
        if (showSolicitar) this.solicitarCep();
        this.changeDetector.detectChanges();
      });

    if (this.isTelevendas) {
      this.televendasFacade.getFiliaisTelevendas();
    }
  }

  ngOnInit(): void {
    this.aplicativo$.pipe(untilDestroyed(this)).subscribe((aplicativo) => {
      if (
        aplicativo?.tema?.header?.links !== undefined &&
        aplicativo?.tema?.header?.links.length > 0
      ) {
        this.options = aplicativo?.tema?.header?.links;
      }
    });
    this.isLogged$
      .pipe(
        untilDestroyed(this),
        filter((isLogged) => isLogged)
      )
      .subscribe(() => {
        this.notificacaoFacade.getNotificacoes();
        this.cashbackFacade.getSaldoCashback();
        this.enderecoFacade.getEnderecosEntrega();
      });
    const [children] = this.activatedRoute.snapshot.children;
    const { data, pathFromRoot } = children;
    this.breadCrumbStore.init({ data, pathFromRoot });
    this.carrinhoVazio = false;

    this.classificacaoMercadologicaFacade.loadArvore();
    const { naoExibirHeader, naoExibirFooter } =
      this.activatedRoute.snapshot.queryParams;
    this.showFooter = !naoExibirFooter;
    this.showHeader = !naoExibirHeader;
    this.institucionalFacade.getPaginasInstitucionais();
    this.aplicativoFacade.getStoreConfig();
    this.filial$.pipe(untilDestroyed(this)).subscribe((filial) => {
      this.redesSociais = {
        url_facebook: filial.url_facebook,
        url_twitter: filial.url_twitter,
        url_youtube: filial.url_youtube,
        url_instagram: filial.url_instagram,
      };
      this.filial = filial;
    });

    this.carrinhoFacade.carrinho$
      //TODO VIP-17401 workaround
      .pipe(observeOn(queueScheduler), untilDestroyed(this))
      .subscribe(this.handleCarrinhoSubscribe);

    this.cdFacade.getCentroDistribuicaoRetirada();
    this.verificaFilialRealizaEntrega();
    this.selecionarCdAoInicializar();
    this.setCdEntregaEnderecoInicial();
    this.initListenerOpenModalPrimeiroProduto();
    this.getIframeFullScreen();
    this.initListenerOpenModalSubstituirProduto();
    this.initListenerOpenModalTermosUso();
    this.initListenerSelecionarTipoEntrega();
  }

  handleCarrinhoSubscribe = (carrinho: ICarrinho): void => {
    if (!carrinho) return;
    this.carrinho = carrinho;
    if (!carrinho.quantidade || carrinho.quantidade == 0) {
      this.produtosFavoritosStore.getFavoritos();
      this.produtosMaisVendidosStore.getMaisVendidos();
      this.carrinhoItensFacade.resetItensCarrinho();
      this.carrinhoVazio = true;
    }
  };

  handleClickSearchByTerm(termo: string): void {
    this.ngZone.run(() => {
      this.router.navigate(['busca'], {
        queryParams: {
          termo,
        },
      });
    });
  }

  handleClickProduct(product: IProduto): void {
    this.ngZone.run(() => {
      this.router.navigateByUrl(
        `/produto/${product.produto_id}/${product.link}`,
        {
          state: { product },
        }
      );
    });
  }

  handleClickSpinChange(event: ISpinEventComProduto): void {
    this.atualizarCarrinhoService.setItemCarrinho(event);
  }

  handleSearchEvent(event: SearchEvent): void {
    if (event.type === 'SearchEvent.enter') {
      this.handleClickSearchByTerm(event.value);
    } else {
      this.buscaStore.getSuggestionsByTermo({ termo: event.value });
      this.buscaRapidaStore.getProdutosByBuscaRapida({ termo: event.value });
    }
  }

  handleLogout(): void {
    this.onFormaEntregaChange();
    this.authService.logout();
  }

  handleGoLogin(): void {
    this.ngZone.run(() => {
      this.router.navigateByUrl('/login');
    });
  }

  handleSearchClick(): void {
    if (!this.layoutUtilsService.isDesktop())
      this.ngZone.run(() => {
        this.router.navigateByUrl('/busca');
      });
  }

  handleClickMenu(): void {
    this.ngZone.run(() => {
      this.router.navigateByUrl('/menu');
    });
  }

  showMoreClick(event: string): void {
    this.ngZone.run(() => {
      this.router.navigateByUrl(event);
    });
  }

  deleteCarrinhoClick(): void {
    this.carrinhoFacade.removerCarrinho(this.carrinho.carrinho_id);
  }

  resumeClick(event: boolean) {
    if (event) {
      this.isLogged$
        .pipe(untilDestroyed(this), take(1))
        .subscribe(async (isLogged) => {
          if (isLogged) {
            this.clienteFacade
              .cadastroIncompleto()
              .pipe(untilDestroyed(this), mergeTakeOne(this.isDesktop$))
              .subscribe(([incompleto, isDesktop]) => {
                if (incompleto.data) {
                  this.dialogService.openDialog(this.dialogCadastroIncompleto);
                  this.dialogService.dialogClick
                    .pipe(untilDestroyed(this))
                    .subscribe((value) => {
                      if (value) {
                        if (!isDesktop) {
                          this.ngZone.run(() => {
                            this.router.navigateByUrl(
                              '/minha-conta/completar-cadastro'
                            );
                          });
                        } else {
                          const modal = this.modalService.openModal(
                            ModalCompletarCadastroComponent,
                            {
                              large: true,
                            }
                          );
                          if (modal) {
                            (
                              modal.instance as ModalCompletarCadastroComponent
                            ).closeModal
                              .pipe(take(1), untilDestroyed(this))
                              .subscribe(() => {
                                this.modalService.clearModal();
                              });
                          }
                        }
                      }
                      this.dialogService.clearDialog();
                    });
                } else {
                  this.ngZone.run(() => {
                    this.router.navigateByUrl(
                      isDesktop ? '/resumo-compra' : '/carrinho'
                    );
                  });
                }
              });
          } else {
            const aplicativoEFilialIsValid =
              await this.cacheUtilsService.checkAplicativoFilialCache();
            if (!aplicativoEFilialIsValid) return;
            this.dialogService.openDialog(this.dialogFazerLogin);
            this.dialogService.dialogClick
              .pipe(untilDestroyed(this), mergeTakeOne(this.isDesktop$))
              .subscribe(([value, isDesktop]) => {
                if (value) {
                  this.ngZone.run(() => {
                    this.router.navigateByUrl('/cadastrar');
                  });
                } else {
                  this.ngZone.run(() => {
                    this.router.navigate(['/login'], {
                      queryParams: {
                        redirectTo: isDesktop ? '/resumo-compra' : '/carrinho',
                      },
                    });
                  });
                }

                this.dialogService.clearDialog();
              });
          }
        });
    } else {
      this.ngZone.run(() => {
        this.router.navigateByUrl('/loja');
      });
    }
  }

  openNovoEnderecoModal() {
    const modalRef = this.modalService.openModal(EnderecoComponent, {
      showCloseButton: this.layoutUtilsService.isDesktopResponsive(),
      bottom: false,
    });

    if (!modalRef) return;

    const instance = modalRef.instance as EnderecoComponent;
    const modalUpdaterSubscriptions = this.initModalAdicionarUpdater(instance);

    instance.closeClicked.pipe(untilDestroyed(this)).subscribe(() => {
      this.modalService.clearModal();
    });

    instance.confirm.pipe(untilDestroyed(this)).subscribe((endereco) => {
      this.enderecoFacade.saveEndereco(endereco);

      this.modalService.clearModal().then(() => {
        this.novoEnderecoCdSelect = true;
        modalUpdaterSubscriptions.unsubscribe();
        this.tipoEntregaFacade.setFormaEntrega(true);
        this.enderecoFacade.saveEnderecoSuccess$
          .pipe(untilDestroyed(this), take(1))
          .subscribe(({ endereco }) => {
            this.enderecoSelecionadoSelect = endereco;
            this.enderecoFacade.getEnderecosEntrega();
            this.enderecoChangeModalPrimeiroEndereco = true;
            this.entregaRetiradaUtilsService.openModalEntregaRetirada();
          });
      });
    });
  }

  initModalAdicionarUpdater(instance: EnderecoComponent): Subscription {
    instance.isModalDesktop = true;
    instance.showDeleteButton = false;
    const mySubscriptions = new Subscription();
    this.cepFacade.cepReset();

    mySubscriptions.add(
      this.cepFacade.enderecoDoCep$
        .pipe(untilDestroyed(this))
        .subscribe((endereco) => {
          instance.endereco = endereco || null;
          instance.isNew = true;
        })
    );

    mySubscriptions.add(
      this.cepFacade.cepAtendido$
        .pipe(untilDestroyed(this))
        .subscribe((cepAtendido) => {
          instance.cepAtendido = cepAtendido ?? false;
          instance.cepValido = cepAtendido ?? false;
        })
    );

    if (instance.changeCep) {
      mySubscriptions.add(
        instance.changeCep.pipe(untilDestroyed(this)).subscribe((cep) => {
          this.cepFacade.validarCepAtendidoPelaFilial(cep);
        })
      );
    }
    return mySubscriptions;
  }

  openAlterarEnderecoModal() {
    this.validarCepContainerService.validarCep();
  }

  private getRelacionamentoClienteData(): Observable<IRelacionamentoCliente> {
    return this.cdFacade.filialECdSelecionado$.pipe(
      map(([filial, cd]) => {
        const { relacionamento_cliente: cdRelacionamentoCliente } = cd;
        return {
          email: cdRelacionamentoCliente.email || filial.email_sav || '',
          telefone:
            cdRelacionamentoCliente.telefone || filial.telefone_sav || '',
          whatsapp:
            cdRelacionamentoCliente.whatsapp || filial.telefone_whatsapp || '',
          mensagem_whatsapp: cdRelacionamentoCliente.mensagem_whatsapp || '',
        };
      })
    );
  }

  async enviarFormSolicitarProduto(
    event: SolicitarProdutoRequest
  ): Promise<void> {
    if (this.filial) {
      await this.produtoFacade
        .solicitarProduto(this.filial.id, event)
        .toPromise()
        .then(({ success }) => {
          if (success) {
            this.showDialogMessage(true);
          }
        })
        .catch(() => {
          this.showDialogMessage(false);
        });
    }
  }

  showDialogMessage(success: boolean): void {
    this.dialogService.openDialog(
      success
        ? layoutDesktopContainerMensagemDeSucesso
        : layoutDesktopContainerMensagemDeErro
    );
    //eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.dialogService.dialogClick.subscribe(() => {
      this.dialogService.clearDialog();
    });
  }

  private initListenerOpenModalPrimeiroProduto() {
    let subscription: Subscription;

    this.entregaRetiradaUtilsService.openModalEntregaRetirada$
      .pipe(
        untilDestroyed(this),
        mergeTakeOne(
          this.cdsRetirada$,
          this.cdsEntrega$ ? this.cdsEntrega$ : of([]),
          this.enderecos$
        ),
        map(([, cdsRetirada, cdsEntrega, enderecos]) => ({
          cdsRetirada,
          cdsEntrega,
          enderecos,
        }))
      )
      .subscribe(({ cdsRetirada, cdsEntrega, enderecos }) => {
        const modal = this.openModalInformarNovoEndereco(
          cdsRetirada,
          cdsEntrega,
          true
        );
        if (modal) {
          this.modalPrimeiroProduto = modal;
          subscription = this.initModalUpdater(modal, enderecos);
        }
      });
    this.modalEntregaRetiradaClose$
      .pipe(
        untilDestroyed(this),
        filter(() => !this.openInformarCep)
      )
      .subscribe(() => {
        if (subscription) subscription.unsubscribe();
        this.entregaRetiradaUtilsService.closeModalEntregaRetirada();
      });
  }

  getIframeFullScreen() {
    this.fullscreenIframe = this.router.url.slice(1).split('/')[0] === 'iframe';
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.fullscreenIframe =
            event.urlAfterRedirects.slice(1).split('/')[0] === 'iframe';
        }
      });
  }

  private initModalUpdater(
    modal: EnderecoEntregaRetiradaContainerComponent,
    enderecos: IEndereco[]
  ) {
    modal.enderecos = enderecos;
    modal.formaEntrega = this.formaEntrega;
    modal.isPrimeiroProduto = true;
    modal.cdSelecionado = this.cdSelecionado;
    modal.novoEnderecoCdSelect = this.novoEnderecoCdSelect;
    let enderecoChange = this.enderecoChangeModalPrimeiroEndereco;
    const mySubscriptions = new Subscription();

    this.enderecoFacade.getEnderecosEntrega();

    if (this.novoEnderecoCdSelect) {
      this.alterarEnderecoEntrega(this.enderecoSelecionadoSelect);

      mySubscriptions.add(
        this.enderecos$
          .pipe(untilDestroyed(this))
          .subscribe((enderecoFromObservable) => {
            modal.enderecos = enderecoFromObservable;
          })
      );

      mySubscriptions.add(
        this.enderecoFacade.enderecosArray$
          .pipe(untilDestroyed(this))
          .subscribe((enderecoFromObservable) => {
            modal.enderecos = enderecoFromObservable;
          })
      );
    }

    mySubscriptions.add(
      this.cdsRetirada$
        .pipe(untilDestroyed(this), take(1))
        .subscribe((cdsRetiradaFromObservable) => {
          modal.cdsRetirada = cdsRetiradaFromObservable;
        })
    );

    if (this.cdsEntrega$) {
      this.cdsEntrega$
        .pipe(untilDestroyed(this), take(1))
        .subscribe((cdsEntregaFromObservable) => {
          modal.cdsEntrega = cdsEntregaFromObservable;

          if (cdsEntregaFromObservable.length === 1 && enderecoChange) {
            if (this.novoEnderecoCdSelect) {
              const [newCd] = cdsEntregaFromObservable;
              combineLatest([
                this.carrinhoFacade.qtdItensCarrinho$,
                this.compraEmProcessoFacade.compraEmProcesso$,
              ])
                .pipe(untilDestroyed(this), take(1))
                .subscribe(([qtdItensCarrinho, compraEmProcesso]) => {
                  this.modalService.clearModal().finally(() => {
                    this.alterarCd(
                      newCd,
                      qtdItensCarrinho > 0,
                      !!compraEmProcesso
                    );
                  });
                  this.cancelDialogConfirmarAlterarCDEmitter
                    .asObservable()
                    .pipe(untilDestroyed(this), take(1))
                    .subscribe(() => {
                      this.tipoEntregaFacade.setFormaEntrega(false);
                    });
                });
            }

            this.confirmNovoEndereco
              .pipe(untilDestroyed(this), take(1))
              .subscribe(() => {
                enderecoChange = false;
                this.novoEnderecoCdSelect = false;
                this.modalService.clearModal();
              });

            if (
              !this.exibiuConfirmacaoAlteracaoEndereco &&
              !this.novoEnderecoCdSelect
            ) {
              enderecoChange = false;
              this.novoEnderecoCdSelect = false;
              this.modalService.clearModal();
            }
          }
          enderecoChange = false;
          this.enderecoChangeModalPrimeiroEndereco = false;
          this.exibiuConfirmacaoAlteracaoEndereco = false;
        });

      mySubscriptions.add(
        this.cdsEntregaInitiateValue
          .asObservable()
          .pipe(untilDestroyed(this), take(1))
          .subscribe(() => {
            this.cdsEntrega$
              ?.pipe(untilDestroyed(this), take(1))
              .subscribe((cdsEntregaFromObservable) => {
                modal.cdsEntrega = cdsEntregaFromObservable;

                if (cdsEntregaFromObservable.length === 1 && enderecoChange) {
                  this.confirmNovoEndereco
                    .pipe(untilDestroyed(this), take(1))
                    .subscribe(() => {
                      enderecoChange = false;
                      this.novoEnderecoCdSelect = false;
                      this.modalService.clearModal();
                    });

                  if (!this.exibiuConfirmacaoAlteracaoEndereco) {
                    enderecoChange = false;
                    this.novoEnderecoCdSelect = false;
                    this.modalService.clearModal();
                  }
                }
                enderecoChange = false;
                this.enderecoChangeModalPrimeiroEndereco = false;
                this.exibiuConfirmacaoAlteracaoEndereco = false;
              });
          })
      );
    }

    this.isEntrega$
      .pipe(untilDestroyed(this), take(1))
      .subscribe((isEntrega) => {
        modal.formaEntregaFromState = isEntrega
          ? FormaEntregaEnum.TIPO_ENTREGA
          : FormaEntregaEnum.TIPO_RETIRADA;
        modal.formaEntrega = modal.formaEntregaFromState;
      });

    modal.cdChange.pipe(untilDestroyed(this), take(1)).subscribe(() => {
      this.modalService.clearModal().then(() => {
        this.novoEnderecoCdSelect = false;
        this.enderecoChangeModalPrimeiroEndereco = false;
      });
    });

    mySubscriptions.add(
      modal.enderecoEntregaChange
        .pipe(untilDestroyed(this), take(1))
        .subscribe(() => {
          enderecoChange = true;
          this.enderecoChangeModalPrimeiroEndereco = true;
        })
    );

    if (this.modalService.closeClick?.asObservable) {
      this.modalService.closeClick
        .asObservable()
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => {
          this.novoEnderecoCdSelect = false;
          this.enderecoChangeModalPrimeiroEndereco = false;
        });
    }

    return mySubscriptions;
  }

  private validarFecharModalSomente1CD(
    cdsEntrega: Array<ICentroDistribuicao>,
    enderecoChange: boolean
  ) {
    if (cdsEntrega.length === 1 && enderecoChange) {
      this.confirmNovoEndereco
        .pipe(untilDestroyed(this))
        .subscribe(() => this.modalService.clearModal());
      if (!this.exibiuConfirmacaoAlteracaoEndereco)
        this.modalService.clearModal();
    }
  }

  private initListenerOpenModalSubstituirProduto() {
    this.substituirProdutoService.openModalSubstituirProdutos$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.modalSubstituirProdutos) {
          return;
        }

        this.modalSubstituirProdutos = this.modalService.openModal(
          SubstituirProdutoContainerComponent
        );
        if (this.modalSubstituirProdutos) {
          const { instance }: any = this.modalSubstituirProdutos;
          instance.isModal = true;
          this.substituirProdutoService.finalizouSubstituirProduto$
            .pipe(
              untilDestroyed(this),
              filter((finalizou) => finalizou),
              take(1)
            )
            .subscribe(() => {
              this.modalService.clearModal();
              this.modalSubstituirProdutos = undefined;
            });

          this.modalService.closeClick
            .pipe(untilDestroyed(this), take(1))
            .subscribe(() => {
              instance.goBackHandler(false);
            });
        }
      });
  }

  private initListenerOpenModalTermosUso() {
    this.termosUsoModalService.openModalTermosUso$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.modalService.isOpened()) return;
        const modal = this.modalService.openModal(TermosUsoContainerComponent, {
          showCloseButton: false,
          disabled: true,
        });
        if (modal) {
          const { instance }: any = modal;
          instance.modalPage = 'atualizacao';
          instance.handleAcceptOutput
            .pipe(untilDestroyed(this), take(1))
            .subscribe(() => {
              this.modalService.clearModal();
            });
        }
      });
  }

  private initListenerSelecionarTipoEntrega(): void {
    this.clienteFacade.cliente$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        ),
        filter((cliente) => cliente === null)
      )
      .subscribe((cliente) => {
        this.setEntregaOuRetirada(cliente);
      });
  }

  private setEntregaOuRetirada(cliente: ICliente | null) {
    this.tipoEntregaFacade.isEntrega$
      .pipe(
        untilDestroyed(this),
        mergeTakeOne(this.cepFacade.enderecoDoCepFormatado$)
      )
      .subscribe(([isEntrega, enderecoDoCepFormatado]) => {
        const isLogged = cliente !== null;
        const isRetirada = !isEntrega || (!isLogged && !enderecoDoCepFormatado);
        this.tipoEntregaFacade.setFormaEntrega(!isRetirada);
      });
  }

  encerrarSessaoTelevendas() {
    this.televendasFacade.encerrarSessao();
  }
}
