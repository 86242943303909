<div class="flex items-center mb-3">
  <i class="vip-color-secondary-main mr-2 text-xl icon-shopping_basket"></i>
  <p class="text-base">Produtos</p>
  <span class="text-xs ml-auto vip-color-secondary-default">{{
    produtos | qtdItensProdutos
  }}</span>
</div>
<ng-container *ngFor="let produtoCompra of produtos; let last = last">
  <vip-card-resumo-produtos-item
    [produtoCompra]="produtoCompra"
  ></vip-card-resumo-produtos-item>
  <hr *ngIf="!last" class="vip-divider my-1" />
</ng-container>
