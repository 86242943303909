import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromSelecaoCd from './+state/selecao-cd/selecao-cd.reducer';
import { SelecaoCdEffects } from './+state/selecao-cd/selecao-cd.effects';
import { SelecaoCdFacade } from './+state/selecao-cd/selecao-cd.facade';
import { EntregaRetiradaUtilsService } from '@vip/core';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromSelecaoCd.SELECAO_CD_FEATURE_KEY,
      fromSelecaoCd.selecaoCdReducer
    ),
    EffectsModule.forFeature([SelecaoCdEffects]),
  ],
  providers: [SelecaoCdFacade, EntregaRetiradaUtilsService],
})
export class SelecaoCdStateModule {}
