import {
  CARTAO_FEATURE_KEY,
  cartaoAdapter,
  CartaoState,
} from './cartao.reducer';
import { SelectorUtil } from '@vip/state/utils';
import { createSelector } from '@ngrx/store';

const { selectAll } = cartaoAdapter.getSelectors();

export class CartaoSelectors extends SelectorUtil<CartaoState> {
  constructor() {
    super(CARTAO_FEATURE_KEY);
  }
  getCartoes = () =>
    createSelector(this.createFeatureSelector, (state: CartaoState) =>
      selectAll(state.data)
    );

  getCartaoSelecionadoId = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CartaoState) => state.cartaoSelecionadoId
    );
}

export const cartaoSelectors = new CartaoSelectors();
