import { Observable } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ICentroDistribuicao, IEndereco } from '@vip/core';

import { EntregaRetiradaPropertiesBaseDirective } from '@vip/views/entrega-retirada';

@Component({
  selector: 'vip-endereco-entrega-retirada-container',
  templateUrl: './endereco-entrega-retirada-container.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EnderecoEntregaRetiradaContainerComponent extends EntregaRetiradaPropertiesBaseDirective {
  informarCepClicked = new EventEmitter();
  @Input() cdSelecionado: ICentroDistribuicao | null = null;
  @Input() enderecoSelecionado$!: Observable<IEndereco | null>;
  @Input() enderecoEntrega$!: Observable<IEndereco | null | undefined>;
  @Input() novoEndereco?: IEndereco | null;
  @Input()
  isPrimeiroProduto = false;
  @Input() novoEnderecoCdSelect = false;

  @Output() cdChange = new EventEmitter();
  @Output() refreshNovoCd = new EventEmitter();

  informarCepClick() {
    this.informarCepClicked.emit();
  }

  aoAlterarCdSelecionado($event: {
    cd: ICentroDistribuicao | null;
    isFromRetirada: boolean;
  }) {
    this.refreshNovoCd.emit($event);
    this.cdChange.emit($event);
    this.cdSelecionado = $event.cd;
  }

  aoAlterarFormaEntrega($event: number): void {
    if (!this.isPrimeiroProduto) this.cdSelecionado = null;
    this.formaEntregaChange.emit($event);
  }

  aoAlterarEnderecoDeEntrega($event: IEndereco): void {
    this.novoEndereco = $event;
    this.enderecoEntregaChange.emit($event);
  }
}
