import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselProductComponent } from './carousel-product/carousel-product.component';
import { CarouselHeaderComponent } from './carousel-header/carousel-header.component';
import { CarouselModule } from '@vip/ui/carousel';
import { CardProdutoModule } from '@vip/ui/card-produto';
import { SkeletonModule } from '@vip/ui/skeleton';
import { SwiperModule } from 'swiper/angular';
import { CoreModule, ObserveVisibilityDirective } from '@vip/core';

@NgModule({
  imports: [
    CommonModule,
    CarouselModule,
    CardProdutoModule,
    SkeletonModule,
    SwiperModule,
  ],
  declarations: [CarouselProductComponent, CarouselHeaderComponent],
  exports: [CarouselProductComponent, CarouselHeaderComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class CarouselProductModule {}
