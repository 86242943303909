<div class="flex flex-col h-full w-full" [ngClass]="{ absolute: !isDesktop }">
  <vip-screen-title
    *ngIf="!isDesktop || isDesktopResponsive"
    title="Novo Cartão"
    leftIcon="icon-arrow_back"
    leftIconColor="secondary"
    (goBackClicked)="backClick.emit()"
    [showActions]="true"
  >
  </vip-screen-title>
  <div class="flex flex-col flex-grow">
    <div class="flex flex-col flex-grow m-3">
      <div
        class="flex flex-col items-center mb-4"
        [ngClass]="{ 'items-baseline': isDesktop, 'items-center': !isDesktop }"
        *ngIf="!privateLabelBandeiraAutomatica"
      >
        <p
          class="vip-color-secondary-default mb-2 tracking-tight"
          [ngClass]="{ 'text-sm': !isDesktop, 'text-xs': isDesktop }"
        >
          Algumas bandeiras de cartão que aceitamos.
        </p>
        <div
          class="flex w-full"
          [ngClass]="{
            'justify-center': !isDesktop,
            'justify-start': isDesktop
          }"
        >
          <div
            class="vip-credit-card"
            *ngFor="let formaPagamento of formasPagamentosPrePagas.slice(0, 5)"
          >
            <vip-image
              [alt]="formaPagamento.descricao"
              [imageUrl]="formaPagamento.logomarca"
            ></vip-image>
          </div>
        </div>
      </div>
      <form [formGroup]="formGroup">
        <div [ngClass]="{ 'flex flex-col': isDesktop }">
          <div
            class="grid gap-3"
            [ngClass]="{
              'grid-cols-1': !isDesktop,
              'vip-grid-cols-desktop': isDesktop
            }"
          >
            <div class="relative">
              <input type="hidden" formControlName="cc_number" />
              <vip-field
                label="NÚMERO DO CARTÃO*"
                [error]="
                  formGroup.controls.cc_number.touched &&
                  formGroup.controls.cc_number.invalid
                "
                [message]="validations.cc_number"
                [validations]="formGroup.controls.cc_number.errors"
              >
                <input
                  #ccNumberInput
                  id="cc_number"
                  type="tel"
                  [dropSpecialCharacters]="false"
                  [mask]="mask"
                  (keyup)="handleBuscarBandeira($event)"
                  (input)="
                    handleBuscarBandeira($event); ccNumber = ccNumberInput.value
                  "
                  (blur)="onBlurCcNumber()"
                  [value]="ccNumber"
                />
              </vip-field>
              <div
                *ngIf="formaPagamentoSelecionada"
                class="vip-cartao-bandeira-wrapper input-numero-cartao"
              >
                <vip-image
                  id="cartao-bandeira"
                  [alt]="formaPagamentoSelecionada.descricao"
                  [imageUrl]="formaPagamentoSelecionada.logomarca"
                ></vip-image>
              </div>
            </div>
            <div
              *ngIf="
                (!permitirBandeiraAutomatica &&
                  !privateLabelBandeiraAutomatica) ||
                permiteSelecaoManual ||
                bandeiraNaoAtendida
              "
              class="relative"
            >
              <vip-select
                id="cc_brand"
                [ngClass]="{
                  'relative-on-mobile': !layoutUtilsService.isDesktop(),
                  'absolute z-40': isDesktop
                }"
                [label]="'BANDEIRA DO CARTÃO*'"
                formControlName="cc_brand"
                [message]="validations.default"
                [options]="formaPagamentosOptions"
                [error]="
                  formGroup.controls.cc_brand.touched &&
                  formGroup.controls.cc_brand.invalid
                "
                [validations]="formGroup.controls.cc_brand.errors"
                [value]="formGroup.controls.cc_brand.value"
                (itemSelected)="aoAlterarBandeiraCartao($event.value)"
              ></vip-select>
              <div
                *ngIf="formaPagamentoSelecionada"
                class="vip-cartao-bandeira-wrapper"
              >
                <vip-image
                  id="cartao-bandeira"
                  [alt]="formaPagamentoSelecionada.descricao"
                  [imageUrl]="formaPagamentoSelecionada.logomarca"
                ></vip-image>
              </div>
            </div>
            <div *ngIf="permiteSelecaoManual && !isDesktop">
              <span class="text-xs vip-color-error-main flex"
                >Erro ao tentar identificar a bandeira do seu cartão. Por favor,
                selecione-a manualmente no campo acima.</span
              >
            </div>
            <div
              [ngClass]="{
                'grid gap-4 grid-cols-1': !isDesktop,
                contents: isDesktop
              }"
            >
              <vip-field
                *ngIf="
                  this.formaPagamentoSelecionada?.bandeira !==
                    'ticket_alimentacao' &&
                  this.formaPagamentoSelecionada?.bandeira !==
                    'ticket_restaurante'
                "
                label="VALIDADE*"
                [error]="
                  formGroup.controls.cc_expire_date.touched &&
                  formGroup.controls.cc_expire_date.invalid
                "
                [message]="validations.cc_expire_date"
                [validations]="formGroup.controls.cc_expire_date.errors"
              >
                <input
                  id="cc_expire_date"
                  type="tel"
                  formControlName="cc_expire_date"
                  [dropSpecialCharacters]="false"
                  placeholder="00/0000"
                  mask="00/0000||00/00"
                  (input)="verificaFormValido()"
                />
              </vip-field>
              <div
                *ngIf="
                  permiteSelecaoManual &&
                  isDesktop &&
                  !formaPagamentoSelecionada
                "
              >
                <span class="text-xs vip-color-error-main flex"
                  >Erro ao tentar identificar a bandeira do seu cartão. Por
                  favor, selecione-a manualmente no campo acima.</span
                >
              </div>
              <vip-field
                label="CVV*"
                [error]="
                  formGroup.controls.cc_cvv.touched &&
                  formGroup.controls.cc_cvv.invalid
                "
                [message]="validations.cc_cvv"
                [validations]="formGroup.controls.cc_cvv.errors"
              >
                <input
                  id="cc_cvv"
                  type="tel"
                  formControlName="cc_cvv"
                  [dropSpecialCharacters]="false"
                  mask="000||0000"
                  (input)="verificaFormValido()"
                />
              </vip-field>
            </div>
            <vip-select
              *ngIf="
                isDesktop &&
                formaPagamentoSelecionada &&
                parcelas &&
                parcelas.length > 0
              "
              data-cy="select-parcelas"
              id="installments"
              class="text-left"
              formControlName="installments"
              [label]="'NÚMERO DE PARCELAS'"
              [message]="{ required: 'Campo obrigatório' }"
              [options]="parcelas || []"
              [value]="parcelaSelecionada"
              [showEmpty]="false"
              (itemSelected)="parcelaChange.emit($event)"
            >
            </vip-select>
            <vip-field
              label="NOME IMPRESSO NO CARTÃO*"
              [error]="
                formGroup.controls.cc_holder.touched &&
                formGroup.controls.cc_holder.invalid
              "
              [message]="validations.default"
              [validations]="formGroup.controls.cc_holder.errors"
            >
              <input
                id="cc_holder"
                type="text"
                formControlName="cc_holder"
                (input)="verificaFormValido()"
              />
            </vip-field>
            <vip-field
              label="CPF DO TITULAR*"
              [error]="
                formGroup.controls.cpf_portador.touched &&
                formGroup.controls.cpf_portador.invalid
              "
              [message]="validations.cpf_portador"
              [validations]="formGroup.controls.cpf_portador.errors"
            >
              <input
                id="cpf_portador"
                type="tel"
                formControlName="cpf_portador"
                [dropSpecialCharacters]="false"
                [mask]="masks.cpf"
                (input)="verificaFormValido()"
              />
            </vip-field>
          </div>
          <vip-checkbox
            class="mt-3"
            *ngIf="
              permitirSalvarCartao &&
              formaPagamentoSelecionada?.adquirente?.permitir_cofre_cartoes
            "
            formControlName="criar_token"
            label="Deseja salvar esse cartão para compras futuras?"
            data-cy="checkbox-salvar-cartao"
          ></vip-checkbox>
        </div>
      </form>
    </div>
    <div *ngIf="!isDesktop || isDesktopResponsive" class="py-4 px-3 flex">
      <vip-button
        class="w-full"
        data-cy="btn-confimar-compra"
        (btnClick)="submit()"
      >
        Confirmar
      </vip-button>
    </div>
  </div>
</div>
