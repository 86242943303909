<ng-container [ngSwitch]="type">
  <vip-solicitar-cep
    *ngSwitchCase="'solicitarCep'"
    [cepInvalido]="cepInvalido"
    [loading]="loading"
    (confirm)="confirmSolicitarCep.emit($event)"
  ></vip-solicitar-cep>
  <vip-cep-nao-atendido
    *ngSwitchCase="'cepNaoAtendido'"
    [loading]="loading"
    (confirm)="confirmCepNaoAtendido.emit($event)"
  ></vip-cep-nao-atendido>
  <vip-somente-retirada
    *ngSwitchCase="'apenasRetirada'"
    (confirm)="confirmSomenteRetirada.emit($event)"
  ></vip-somente-retirada>
  <vip-solicitacao-cadastrada
    *ngSwitchCase="'solicitacaoCadastrada'"
    (confirm)="confirmSolicitacaoCadastrada.emit($event)"
  ></vip-solicitacao-cadastrada>
</ng-container>
