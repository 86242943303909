import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { ImageModule } from '@vip/ui/image';
import { SearchModule } from '@vip/ui/search';
import { SkeletonModule } from '@vip/ui/skeleton';

@NgModule({
  imports: [CommonModule, ImageModule, SearchModule, SkeletonModule],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
