<vip-field
  icon="expand_more"
  [iconDirection]="'right'"
  [label]="label"
  [error]="error"
  [message]="message"
  [showMessage]="showMessage"
  [validations]="validations"
  (click)="!disabled ? toggle() : null"
  vipRipple
>
  <input
    #input
    [disabled]="disabled"
    [value]="inputString"
    [placeholder]="placeholder"
    (keydown)="handleKeyDown($event)"
    (blur)="handleInputBlur()"
    inputmode="none"
  />
</vip-field>
<div #dropDown>
  <div
    class="vip-common-backdrop show transparent"
    *ngIf="open"
    (click)="close()"
  ></div>

  <vip-dropdown-menu
    [open]="open"
    [class.pull-right]="invertX"
    [class.open-up]="invertY"
    [animationDirection]="animationY"
    [isDesktop]="isDesktop"
  >
    <vip-dropdown-item
      *ngIf="showEmpty"
      [attr.data-cy]="'select_options_empty'"
      (itemClick)="handleMenuItemClick($event)"
      >(Selecione)</vip-dropdown-item
    >
    <vip-dropdown-item
      [attr.data-cy]="'select_options_' + option.value"
      *ngFor="let option of options"
      [value]="option.value"
      [disabled]="option.disabled"
      [selected]="option.selected"
      (itemClick)="handleMenuItemClick($event)"
    >
      <i *ngIf="option.icon" [class]="option.icon"></i
      >{{ option.text }}</vip-dropdown-item
    >
  </vip-dropdown-menu>
</div>
