import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IEndereco } from '@vip/core';

@Component({
  selector: 'vip-meus-enderecos',
  templateUrl: './meus-enderecos.component.html',
  styleUrls: ['./meus-enderecos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeusEnderecosComponent {
  @Input()
  enderecos: IEndereco[] = [];

  @Output()
  editarEndereco: EventEmitter<IEndereco> = new EventEmitter();

  @Output()
  adicionarEndereco: EventEmitter<IEndereco> = new EventEmitter();
}
