import { ICupomCredito, ITipoDesconto } from '../interfaces';
import {
  motivoPromocaoMock,
  motivoRessarcimentoMock,
} from './cupom-motivo.mock';
import { addHours, subHours } from 'date-fns';

const now = new Date();

const created = subHours(now, 2);
const validade = addHours(now, 1);
const validadeExpired = subHours(now, 3);

export const tipoDescontoCodigoMock = {
  descricao: 'Desconto por código promocional',
  id: 7,
} as ITipoDesconto;

export const cupomPromocao1Mock = {
  id: 1,
  compra_id: 10005,
  created,
  validade,
  valor: 50.0,
  total: 50.0,
  desconto: 'V',
  cupom_motivo: motivoPromocaoMock,
} as ICupomCredito;

export const cupomPromocao2Mock = {
  id: 2,
  compra_id: 10005,
  created,
  validade: validadeExpired,
  valor: 72.0,
  total: 72.0,
  desconto: 'V',
  cupom_motivo: motivoPromocaoMock,
} as ICupomCredito;

export const cupomRessarcimento1Mock = {
  id: 3,
  compra_id: 10006,
  created,
  validade,
  valor: 10.0,
  total: 10.0,
  desconto: 'V',
  cupom_motivo: motivoRessarcimentoMock,
} as ICupomCredito;

export const cupomRessarcimento2Mock = {
  id: 4,
  compra_id: 10007,
  created,
  validade,
  valor: 75.3,
  total: 75.3,
  desconto: 'V',
  cupom_motivo: motivoRessarcimentoMock,
} as ICupomCredito;

export const cupomDescontoCodigoPorcentagemMock = {
  id: 5,
  created,
  validade,
  valor: 10,
  total: 4.31,
  desconto: 'P',
  codigo: 'DESCONTO10',
  tipo_desconto: tipoDescontoCodigoMock,
} as ICupomCredito;

export const cupomDescontoCodigoValorMock = {
  id: 6,
  created,
  validade,
  valor: 15,
  total: 15,
  desconto: 'V',
  codigo: 'LOJA',
  tipo_desconto: tipoDescontoCodigoMock,
} as ICupomCredito;
