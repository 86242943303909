import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IGenericState } from './generic-state.interface';
import { AtualizarCarrinhoItemRequest } from '@vip/api';

interface SelecaoCdState extends IGenericState<[]> {
  itemParaAtualizarCarrinho: {
    item: AtualizarCarrinhoItemRequest | AtualizarCarrinhoItemRequest[] | null;
    exibirMensagemAdicionado: boolean;
  };
  permitirAtualizarItemCarrinho: boolean | null;
  compraIdArmazenado: number | null;
  listaIdArmazenado: number | null;
  listaIngredientesIdArmazenado: number | null;
}

export const getSelecaoCdState =
  createFeatureSelector<SelecaoCdState>('selecao-cd');

export const getSelecaoCdError = createSelector(
  getSelecaoCdState,
  (state: SelecaoCdState) => state.error
);

export const isPermitirAtualizarItemCarrinho = createSelector(
  getSelecaoCdState,
  (state: SelecaoCdState) => state?.permitirAtualizarItemCarrinho
);

export const getItemParaAtualizarCarrinho = createSelector(
  getSelecaoCdState,
  (state: SelecaoCdState) => state.itemParaAtualizarCarrinho
);

export const getcompraIdArmazenado = createSelector(
  getSelecaoCdState,
  (state: SelecaoCdState) => state.compraIdArmazenado
);

export const getlistaIdArmazenado = createSelector(
  getSelecaoCdState,
  (state: SelecaoCdState) => state.listaIdArmazenado
);

export const getlistaIngredientesIdArmazenado = createSelector(
  getSelecaoCdState,
  (state: SelecaoCdState) => state.listaIngredientesIdArmazenado
);
