import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollableService {
  slider!: HTMLElement;
  isDown = false;
  disableClick = false;
  startX = 0;
  scrollLeft = 0;

  disableIsDown(): void {
    this.isDown = false;
    setTimeout(() => {
      this.disableClick = false;
    }, 50);
  }

  onMouseDown(event: MouseEvent): void {
    event.preventDefault();
    this.isDown = true;
    this.startX = event.pageX - this.slider.offsetLeft;
    this.scrollLeft = this.slider.scrollLeft;
  }

  onMouseMove(event: MouseEvent): void {
    event.preventDefault();
    if (this.isDown) {
      this.disableClick = true;
      const x = event.pageX - this.slider.offsetLeft;
      const walk = x - this.startX;

      this.slider.scrollLeft = this.scrollLeft - walk;
    }
  }
}
