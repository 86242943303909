import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ProdutoApiService } from '@vip/api';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { BucketsFacade } from '@vip/state/buckets';
import { ProdutoStore } from './produto.store';
import { ProdutoFacade } from './produto.facade';

@Injectable()
export class ProdutosSecaoStore extends ProdutoStore {
  readonly getProdutosBySecao = this.effect(
    (payload$: Observable<{ cmId: number; filtro: string }>) => {
      return combineLatest([
        payload$,
        this.cdFacade.filialECdSelecionado$,
        this.bucketsFacade.bucketProduto$,
        this.page$.pipe(filter((page) => page !== 0)),
      ]).pipe(
        map(([payload, filialECdSelecionado, bucket, page]) => {
          return { ...payload, filialECdSelecionado, bucket, page };
        }),
        switchMap((payload) => {
          this.updateStatus('loading');
          return this.produtoApiService
            .getProdutosBySecao(
              payload.filialECdSelecionado[0].id,
              payload.filialECdSelecionado[1].id,
              payload.cmId,
              payload.filtro,
              payload.page
            )
            .pipe(
              tapResponse(
                (response) => {
                  this.updateProdutosAndPaginator(
                    {
                      produtos: response.data,
                      paginator: response.paginator,
                    },
                    payload.bucket
                  );
                  this.produtoFacade.enviaImpressao(response.data);
                },
                (error) =>
                  this.updateError((error as HttpErrorResponse).error.error)
              )
            );
        })
      );
    }
  );

  constructor(
    private produtoApiService: ProdutoApiService,
    private bucketsFacade: BucketsFacade,
    private cdFacade: CentroDistribuicaoFacade,
    private produtoFacade: ProdutoFacade
  ) {
    super();
  }
}
