import { Action, createReducer, on } from '@ngrx/store';
import { IConfiguracoesOmnichannel } from '@vip/core';

import * as ConfiguracaoOmnichannelActions from './configuracao-omnichannel.actions';
import { GenericStoreStatusEnum, IGenericState } from '@vip/state/utils';

export const CONFIGURACAO_OMNICHANNEL_FEATURE_KEY = 'configuracao-omnichannel';

export type OmnichannelState = IGenericState<
  IConfiguracoesOmnichannel[] | null
>;

export const initialState: OmnichannelState = {
  status: GenericStoreStatusEnum.PENDING,
  data: null,
  error: null,
};

const configuracaoOmnichannelReducer = createReducer(
  initialState,
  on(ConfiguracaoOmnichannelActions.loadConfiguracaoOmnichannel, (state) => ({
    ...state,
    status: GenericStoreStatusEnum.LOADING,
    loaded: false,
    error: null,
  })),
  on(
    ConfiguracaoOmnichannelActions.loadConfiguracaoOmnichannelSuccess,
    (state, { configuracaoOmnichannel }) => ({
      data: [...configuracaoOmnichannel],
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
    })
  ),
  on(
    ConfiguracaoOmnichannelActions.loadConfiguracaoOmnichannelFailure,
    (state, { error }) => ({
      ...initialState,
      status: GenericStoreStatusEnum.ERROR,
      error: error.error ? error.error.error : error,
    })
  )
);

export function reducer(state: OmnichannelState | undefined, action: Action) {
  return configuracaoOmnichannelReducer(state, action);
}
