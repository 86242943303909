import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromConfiguracaoOmnichannel from './+state/configuracao-omnichannel.reducer';
import { ConfiguracaoOmnichannelEffects } from './+state/configuracao-omnichannel.effects';
import { ConfiguracaoOmnichannelFacade } from './+state/configuracao-omnichannel.facade';
import { HomeOmniGuard } from './home-omni.guard';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromConfiguracaoOmnichannel.CONFIGURACAO_OMNICHANNEL_FEATURE_KEY,
      fromConfiguracaoOmnichannel.reducer
    ),
    EffectsModule.forFeature([ConfiguracaoOmnichannelEffects]),
  ],
  providers: [ConfiguracaoOmnichannelFacade, HomeOmniGuard],
})
export class HomeOmnichannelStateModule {}
