<div class="vip-carousel-card-container">
  <div
    #contentHeader
    class="vip-carousel-card-header flex space-items scrollable justify-center"
    (mouseup)="scrollableService.disableIsDown()"
    (mouseleave)="scrollableService.disableIsDown()"
    (mousedown)="scrollableService.onMouseDown($event)"
    (mousemove)="scrollableService.onMouseMove($event)"
  >
    <ng-content select="[card]"></ng-content>
    <div
      *ngIf="showMoreBtnLabel"
      (click)="showMoreClick.emit($event)"
      data-cy="vip-carousel-show-more-btn"
      class="showMore flex flex-col items-center justify-center"
    >
      <button class="showMoreBtn rounded-full text-3xl leading-none shadow">
        <i class="icon-chevron_right"></i>
      </button>
      <span class="vip-color-first-default text-xs font-medium">
        {{ showMoreBtnLabel }}
      </span>
    </div>
  </div>
</div>
