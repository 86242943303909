import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { DialogService } from '@vip/ui/modal';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TipoEntregaFacade } from '../+state/tipo-entrega.facade';
import { ValidaEntregaService } from '../service/valida-entrega.service';
import { LayoutUtilsService } from '@vip/core';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';

@Injectable()
export class ValidaHorarioSelecionadoGuard implements CanActivate {
  tipoEntregaIdSelecionado$ = this.tipoEntregaFacade.tipoEntregaIdSelecionado$;
  horarioSelecionado$ = this.tipoEntregaFacade.horarioSelecionado$;
  tiposEntregas$ = this.tipoEntregaFacade.tiposEntregas$;
  constructor(
    private tipoEntregaFacade: TipoEntregaFacade,
    private validaEntregaService: ValidaEntregaService,
    private router: Router,
    private dialogService: DialogService,
    private layoutUtilsService: LayoutUtilsService,
    private cdFacade: CentroDistribuicaoFacade
  ) {}

  canActivate(): Observable<boolean> {
    if (
      this.dialogService.dialogComponent ||
      this.layoutUtilsService.isDesktop()
    ) {
      return of(true);
    }
    return combineLatest([
      this.tipoEntregaIdSelecionado$.pipe(take(1)),
      this.tiposEntregas$.pipe(take(1)),
      this.horarioSelecionado$.pipe(take(1)),
      this.cdFacade.isLojaAutonoma$.pipe(take(1)),
    ]).pipe(
      map(
        ([
          tipoEntregaIdSelecionado,
          tipoEntregas,
          horarioSelecionado,
          isLojaAutonoma,
        ]) => ({
          tipoEntregaIdSelecionado,
          tipoEntregas,
          horarioSelecionado,
          isLojaAutonoma,
        })
      ),
      map(
        ({
          tipoEntregaIdSelecionado,
          tipoEntregas,
          horarioSelecionado,
          isLojaAutonoma,
        }) => {
          if (tipoEntregaIdSelecionado && isLojaAutonoma) return true;

          if (tipoEntregaIdSelecionado && horarioSelecionado) {
            let valido = false;
            tipoEntregas.forEach((entrega) => {
              if (entrega.id == tipoEntregaIdSelecionado) {
                if (
                  this.validaEntregaService.validarHorarioSelecionadoDisponivel(
                    entrega.horarios_formatados,
                    horarioSelecionado
                  )
                ) {
                  valido = true;
                }
              }
            });
            if (!valido) {
              this.showDialogHorarioInvalido();
            }
            return valido;
          }
          return false;
        }
      )
    );
  }

  private showDialogHorarioInvalido(): void {
    this.dialogService.openDialog({
      open: true,
      disabled: true,
      showCloseButton: false,
      title: 'Horário expirado!',
      subTitle:
        'Você excedeu o tempo limite para finalizar a compra. Por favor, selecione uma nova data e horário que deseja receber a sua compra.',
      buttonConfirmText: 'Selecionar tipo de entrega',
    });

    const confirmSubscription = this.dialogService.dialogClick
      .pipe(take(1))
      .subscribe((confirm) => {
        if (confirm)
          this.router.navigateByUrl('/entrega-retirada/alterar-horario');
        this.dialogService.clearDialog();
      });

    this.dialogService.closeClick.pipe(take(1)).subscribe(() => {
      confirmSubscription.unsubscribe();
    });
  }
}
