import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '@vip/core';

@Injectable({
  providedIn: 'root',
})
export class ApiOrganizacaoService extends HttpClient {
  _defaultHeaders: HttpHeaders;
  baseUrl = this.environmentService.getApiOrganizacoesUrl();

  constructor(
    _httpHandler: HttpHandler,
    private environmentService: EnvironmentService
  ) {
    super(_httpHandler);
    this._defaultHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  public override get<T>(
    url: string,
    options?: Record<string, unknown>
  ): Observable<T> {
    return super.get<T>(this.baseUrl + url, {
      ...options,
      headers: this._defaultHeaders,
    });
  }

  public override post<T>(
    url: string,
    body: unknown,
    options?: Record<string, unknown>
  ): Observable<T> {
    return super.post<T>(this.baseUrl + url, body, {
      ...options,
      headers: this._defaultHeaders,
    });
  }

  public override put<T>(
    url: string,
    body: unknown,
    options?: Record<string, unknown>
  ): Observable<T> {
    return super.put<T>(this.baseUrl + url, body, {
      ...options,
      headers: this._defaultHeaders,
    });
  }

  public override delete<T>(
    url: string,
    options?: Record<string, unknown>
  ): Observable<T> {
    return super.delete<T>(this.baseUrl + url, {
      ...options,
      headers: this._defaultHeaders,
    });
  }

  async getFilialByFilialDomain(dominio: string) {
    const response = await this.get<{ data: any }>(
      `/filiais/dominio/${dominio}`
    ).toPromise();
    const vipcommerceFilial = response.data;
    this.environmentService.setVipcommerceFilialId(vipcommerceFilial.id);
    this.environmentService.setOrgId(vipcommerceFilial.organizacao.id);
    this.environmentService.setDomainKey(
      vipcommerceFilial.organizacao.enderecoServidor
    );
  }
}
