import { Action, createReducer, on } from '@ngrx/store';

import { ICupomCredito } from '@vip/core';
import * as CupomActions from './cupom.actions';
import { IGenericState } from '@vip/state/utils';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const CUPOM_FEATURE_KEY = 'cupom';

export interface CupomState
  extends IGenericState<ICupomCredito[]>,
    EntityState<ICupomCredito> {
  selecionados: ICupomCredito[];
}

export const cupomAdapter: EntityAdapter<ICupomCredito> =
  createEntityAdapter<ICupomCredito>();

export const initialState: CupomState = cupomAdapter.getInitialState({
  data: [],
  status: 'pending',
  error: null,
  selecionados: [],
});

const cupomReducer = createReducer(
  initialState,
  on(CupomActions.resetCupons, () => ({
    ...initialState,
  })),
  on(CupomActions.loadCupons, CupomActions.loadCuponsDisponiveis, (state) => ({
    ...state,
    data: [],
    status: 'loading',
    error: null,
  })),
  on(
    CupomActions.loadCupomSuccess,
    CupomActions.loadCuponsDisponiveisSuccess,
    (state, { meusCupons }) => ({
      ...state,
      data: [...meusCupons],
      status: 'success',
      error: null,
    })
  ),
  on(
    CupomActions.loadCupomFailure,
    CupomActions.loadCuponsDisponiveisFailure,
    (state, { error }) => ({
      ...state,
      status: 'error',
      error: error.message,
    })
  ),
  on(CupomActions.setCuponsSelecionados, (state, { selecionados }) => ({
    ...state,
    selecionados,
  })),
  on(CupomActions.removeCupomSelecionado, (state, { cupom }) => ({
    ...state,
    selecionados: state.selecionados.filter(
      (selecionado) => selecionado.id !== cupom.id
    ),
  }))
);

export function reducer(state: CupomState | undefined, action: Action) {
  return cupomReducer(state, action);
}
