import { Injectable } from '@angular/core';
import {
  AddIngredientesCarrinhoResponse,
  GetIngredientesResponse,
  GetReceitaResponse,
  GetReceitasResponse,
} from './receitas.service.types';
import { BaseApiService } from '../common/base-api-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReceitasService {
  private receitaUrl = `loja/receitas`;

  constructor(private api: BaseApiService<ReceitasService>) {}

  getReceita(receitaId: number): Observable<GetReceitaResponse> {
    return this.api.get<GetReceitaResponse>(
      'getReceita',
      `/${this.receitaUrl}/${receitaId}`
    );
  }

  getReceitas(pagina: number): Observable<GetReceitasResponse> {
    return this.api.get<GetReceitasResponse>(
      'getReceitas',
      `/${this.receitaUrl}?page=${pagina}`
    );
  }

  getIngredientes(
    filialId: number,
    cdId: number,
    listaId: number
  ): Observable<GetIngredientesResponse> {
    return this.api.get<GetIngredientesResponse>(
      'getIngredientes',
      `${this.api.filialAndCdUrl(
        filialId,
        cdId
      )}/listas/ingredientes/${listaId}/itens`
    );
  }

  adicionarIngredientesNoCarrinho(
    filialId: number,
    cdId: number,
    listaId: number
  ): Observable<AddIngredientesCarrinhoResponse> {
    return this.api.post<
      { lista_ingrediente_id: number },
      AddIngredientesCarrinhoResponse
    >(
      'adicionarIngredientesNoCarrinho',
      `${this.api.filialAndCdUrl(
        filialId,
        cdId
      )}/carrinhos/incluir_lista_ingredientes`,
      { lista_ingrediente_id: listaId }
    );
  }
}
