import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IEndereco } from '@vip/core';

@Component({
  selector: 'vip-card-endereco',
  templateUrl: './card-endereco.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardEnderecoComponent {
  @Input()
  endereco!: IEndereco;

  @Output()
  editClick = new EventEmitter<void>();

  enderecoFormatado() {
    return `${this.endereco.logradouro} - ${this.endereco.numero} - ${this.endereco.bairro} - ${this.endereco.cep} - ${this.endereco.cidade}/${this.endereco.sigla}`;
  }
}
