<img
  *ngIf="!hiddenImage"
  [src]="imageUrl"
  [alt]="alt"
  [hidden]="!imageLoadComplete || isLoading"
  [attr.aria-label]="ariaLabel"
  (load)="loadImg()"
  (error)="handleError()"
  [ngClass]="{
    'vip-img-custom-size': customSize,
    'w-[22.82px] h-[22.73px]': hasCombo,
    'w-[50.71px] h-[50px]': hasComboAccordion
  }"
  class="{{ classNames }}"
/>
<div
  class="relative flex flex-grow h-full"
  *ngIf="!imageLoadComplete || isLoading"
>
  <ng-container
    *vipSkeleton="
      true;
      width: '100%';
      height: '100%';
      radius: '0px';
      className: ['absolute', 'set-sizes-in-parent']
    "
  ></ng-container>
</div>
