export type GenericStoreStatus =
  | 'pending'
  | 'loading'
  | 'success'
  | 'error'
  | 'finished';

export interface IGenericState<T> {
  data: T;
  status: GenericStoreStatus;
  error: string | null;
}

export enum GenericStoreStatusEnum {
  PENDING = 'pending',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
  FINISHED = 'finished',
}
