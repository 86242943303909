import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { ScrollableService } from '@vip/core';

import { TabBaseDirective } from '../base/tab-base.directive';

@Component({
  selector: 'vip-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TabGroupComponent
  extends TabBaseDirective
  implements AfterViewInit
{
  @Input() rounded = false;
  @Input() scrollable = false;
  @Input() isDesktop = false;

  constructor(
    public renderer: Renderer2,
    public scrollableService: ScrollableService,
    public cd: ChangeDetectorRef
  ) {
    super(scrollableService, renderer, cd);
  }

  ngAfterViewInit(): void {
    this.initTabGroup();
  }
}
