<div
  class="vip-tag-collection flex flex-wrap justify-center items-center gap-1 overflow-hidden"
  [class.medium]="size === 'medium'"
>
  <vip-tag-collection
    *ngFor="let collection of collections"
    [label]="collection.descricao_resumida"
    [icon]="collection.icone"
    [id]="collection.id"
    [slug]="collection.slug"
    [size]="size"
  >
  </vip-tag-collection>
</div>
