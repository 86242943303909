import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as AplicativoActions from './aplicativo.actions';
import { aplicativoSelectors } from './aplicativo.selectors';

@Injectable()
export class AplicativoFacade {
  loaded$ = this.store.pipe(select(aplicativoSelectors.isLoaded()));
  error$ = this.store.pipe(select(aplicativoSelectors.getError()));
  hasError$ = this.store.pipe(select(aplicativoSelectors.hasError()));
  aplicativo$ = this.store.pipe(select(aplicativoSelectors.getData()));
  emRevisao$ = this.store.pipe(select(aplicativoSelectors.emRevisao()));

  constructor(private store: Store) {}

  init() {
    this.store.dispatch(AplicativoActions.init());
  }

  initFromVipcommerceFilialId(filialId: number) {
    this.store.dispatch(
      AplicativoActions.initFromVipcommerceFilialId({ filialId })
    );
  }

  getStoreConfig() {
    this.store.dispatch(AplicativoActions.getStoreConfig());
  }
}
