import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { IBreadCrumb } from '@vip/core';

@Component({
  selector: 'vip-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
  @Input()
  breadcrumbs = new Array<IBreadCrumb>();

  /** Evento disparado ao clicar em link de breadcrumb*/
  @Output() breadCrumbClick: EventEmitter<string> = new EventEmitter<string>();

  handleClick(last: boolean, link: string) {
    if (!last) {
      this.breadCrumbClick.emit(link);
    }
  }
}
