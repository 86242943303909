import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TelevendasFacade } from '@vip/state/televendas';

@UntilDestroy()
@Component({
  selector: 'vip-televendas',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelevendasComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private televendasFacade: TelevendasFacade
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        const { token, id } = params;
        if (token && id) this.televendasFacade.logar(id, token);
      });
  }
}
