import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { RippleModule } from '@vip/ui/ripple';
import { ButtonIconRoundedComponent } from './button-icon-rounded/button-icon-rounded.component';
import { SkeletonModule } from '@vip/ui/skeleton';

@NgModule({
  imports: [CommonModule, RippleModule, SkeletonModule],
  declarations: [ButtonComponent, ButtonIconRoundedComponent],
  exports: [ButtonComponent, ButtonIconRoundedComponent],
})
export class ButtonModule {}
