import { IProdutoCompra } from '../interfaces';

export const produtoCompra1Mock: IProdutoCompra = {
  produto_id: 100,
  id: 100,
  descricao: 'Carregador Usb Multilaser Cb102 Preto',
  preco: 15.2,
  preco_venda: 15,
  preco_total: 15,
  quantidade: 3,
  quantidade_unidade_diferente: 1,
  possui_unidade_diferente: false,
  unidade_sigla: 'UN',
  disponivel: true,
  link: 'https://picsum.photos/100',
  imagem: 'https://picsum.photos/100',
  departamento: {
    descricao: 'Teste departamento',
    id: 213,
  },
};

export const produtoCompra2Mock: IProdutoCompra = {
  produto_id: 101,
  id: 101,
  descricao: 'Carregador Usb Multilaser Cb102 Branco',
  preco: 5.2,
  preco_venda: 5,
  desconto: 5,
  quantidade: 2,
  quantidade_unidade_diferente: 1,
  possui_unidade_diferente: false,
  unidade_sigla: 'UN',
  disponivel: true,
  link: 'https://picsum.photos/100',
  imagem: 'https://picsum.photos/100',
};

export const produtoCompra3Mock: IProdutoCompra = {
  produto_id: 102,
  id: 102,
  descricao: 'Carregador Usb Multilaser Cb102 Azul',
  preco: 3.9,
  preco_venda: 3,
  quantidade: 10,
  quantidade_unidade_diferente: 1,
  possui_unidade_diferente: false,
  unidade_sigla: 'UN',
  disponivel: true,
  link: 'https://picsum.photos/100',
  imagem: 'https://picsum.photos/100',
};

export const produtoCompra4Mock: IProdutoCompra = {
  produto_id: 103,
  id: 103,
  descricao: 'Carregador Usb Multilaser Cb102 Verde',
  preco: 3.9,
  preco_venda: 3,
  quantidade: 1,
  quantidade_unidade_diferente: 1,
  possui_unidade_diferente: false,
  unidade_sigla: 'UN',
  disponivel: true,
  link: 'https://picsum.photos/100',
  imagem: 'https://picsum.photos/100',
};

export const produtoCompraUnidadeDiferenteMock: IProdutoCompra = {
  produto_id: 104,
  id: 104,
  descricao: 'Carregador Usb Multilaser Cb102 Laranja',
  preco: 3.9,
  preco_venda: 3,
  quantidade: 4,
  preco_total: 6,
  quantidade_unidade_diferente: 0.5,
  possui_unidade_diferente: true,
  unidade_sigla: 'KG',
  disponivel: true,
  link: 'https://picsum.photos/100',
  imagem: 'https://picsum.photos/100',
};

export const produtoCompraIndisponivelMock: IProdutoCompra = {
  produto_id: 104,
  id: 104,
  descricao: 'Carregador Usb Multilaser Cb102 Laranja',
  preco: 3.9,
  preco_venda: 3,
  quantidade: 4,
  quantidade_unidade_diferente: 1,
  possui_unidade_diferente: false,
  unidade_sigla: 'UN',
  disponivel: false,
  link: 'https://picsum.photos/100',
  imagem: 'https://picsum.photos/100',
};

export const produtosCompra1Mock = [
  produtoCompra1Mock,
  produtoCompra2Mock,
  produtoCompra3Mock,
  produtoCompra4Mock,
];

export const produtoCompraComDesconto1Mock: IProdutoCompra = {
  produto_id: 101,
  id: 101,
  descricao: 'Carregador Usb Multilaser Cb102 Branco',
  preco: 5.2,
  preco_venda: 5,
  desconto: 4,
  quantidade: 2,
  quantidade_unidade_diferente: 1,
  possui_unidade_diferente: false,
  unidade_sigla: 'UN',
  disponivel: true,
  link: 'https://picsum.photos/100',
  imagem: 'https://picsum.photos/100',
};

export const produtoCompraComDesconto2Mock: IProdutoCompra = {
  produto_id: 102,
  id: 102,
  descricao: 'Carregador Usb Multilaser Cb102 Azul',
  preco: 3.9,
  desconto: 2.8,
  preco_venda: 3,
  quantidade: 10,
  quantidade_unidade_diferente: 1,
  possui_unidade_diferente: false,
  unidade_sigla: 'UN',
  disponivel: true,
  link: 'https://picsum.photos/100',
  imagem: 'https://picsum.photos/100',
};

export const produtoCompraComDesconto3Mock: IProdutoCompra = {
  produto_id: 103,
  id: 103,
  descricao: 'Carregador Usb Multilaser Cb102 Verde',
  preco: 3.9,
  preco_venda: 3,
  desconto: 2.9,
  quantidade: 1,
  quantidade_unidade_diferente: 1,
  possui_unidade_diferente: false,
  unidade_sigla: 'UN',
  disponivel: true,
  link: 'https://picsum.photos/100',
  imagem: 'https://picsum.photos/100',
};

export const produtoCompraUnidadeDiferenteComDescontoMock: IProdutoCompra = {
  produto_id: 104,
  id: 104,
  descricao: 'Carregador Usb Multilaser Cb102 Laranja',
  preco: 3.9,
  preco_venda: 3,
  desconto: 2.5,
  quantidade: 4,
  preco_total: 6,
  quantidade_unidade_diferente: 0.5,
  possui_unidade_diferente: true,
  unidade_sigla: 'KG',
  disponivel: true,
  link: 'https://picsum.photos/100',
  imagem: 'https://picsum.photos/100',
};

export const produtosCompraDesconto1Mock = [
  produtoCompraComDesconto1Mock,
  produtoCompraComDesconto2Mock,
  produtoCompraComDesconto3Mock,
  produtoCompraUnidadeDiferenteComDescontoMock,
];

export const produtoCompraDepartamento1Mock: IProdutoCompra = {
  ...produtoCompra1Mock,
  departamento: {
    id: 1,
    descricao: 'Bebidas',
  },
};

export const produtoCompraDepartamento2Mock: IProdutoCompra = {
  ...produtoCompra2Mock,
  departamento: {
    id: 2,
    descricao: 'Peixaria',
  },
};

export const produtoCompraDepartamento3Mock: IProdutoCompra = {
  ...produtoCompra3Mock,
  departamento: {
    id: 3,
    descricao: 'Mercearia',
  },
};
export const produtoCompraDepartamento4Mock: IProdutoCompra = {
  ...produtoCompra4Mock,
  departamento: {
    id: 3,
    descricao: 'Mercearia',
  },
};

export const produtoCompraBrinde: IProdutoCompra = {
  ...produtoCompraDepartamento1Mock,
  preco: 0,
  preco_total: 0,
  preco_venda: 0,
  brinde: true,
};
