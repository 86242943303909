import { createSelector } from '@ngrx/store';
import { SelectorUtil } from '@vip/state/utils';
import {
  PERGUNTAS_FEATURE_KEY,
  perguntasAdapter,
  PerguntasState,
} from './perguntas.reducer';
import {
  IClientePerguntaResposta,
  IPerguntaSobreCompra,
  PerguntaInsercao,
} from '@vip/core';

const { selectAll } = perguntasAdapter.getSelectors();

class PerguntasSelectors extends SelectorUtil<PerguntasState> {
  constructor() {
    super(PERGUNTAS_FEATURE_KEY);
  }

  getPerguntasArray = () =>
    createSelector(this.createFeatureSelector, (state: PerguntasState) =>
      selectAll(state.data)
    );

  getPerguntasDoPagamentoArray = () =>
    createSelector(
      this.getPerguntasArray(),
      (perguntas) =>
        perguntas?.filter(
          (pergunta) => pergunta.insercao === PerguntaInsercao.PAGAMENTO
        ) || []
    );

  getPerguntasDoResumoArray = () =>
    createSelector(
      this.getPerguntasArray(),
      (perguntas) =>
        perguntas?.filter(
          (pergunta) => pergunta.insercao === PerguntaInsercao.RESUMO
        ) || []
    );

  getRespostas = () =>
    createSelector(
      this.getPerguntasArray(),
      (perguntas: IPerguntaSobreCompra[]) => {
        const respostas: IClientePerguntaResposta[] = [];
        perguntas.forEach((pergunta) => {
          if (pergunta.cliente_pergunta_resposta)
            respostas.push(pergunta.cliente_pergunta_resposta);
        });
        return respostas;
      }
    );

  getRespostasResumo = () =>
    createSelector(
      this.getPerguntasDoResumoArray(),
      (perguntas: IPerguntaSobreCompra[]) => {
        const respostas: IClientePerguntaResposta[] = [];
        perguntas.forEach((pergunta) => {
          if (pergunta.cliente_pergunta_resposta)
            respostas.push(pergunta.cliente_pergunta_resposta);
        });
        return respostas;
      }
    );

  temRespostas = () =>
    createSelector(
      this.getPerguntasArray(),
      (perguntas) =>
        !!perguntas.find((pergunta) => pergunta.cliente_pergunta_resposta)
    );

  temRespostasResumo = () =>
    createSelector(
      this.getPerguntasDoResumoArray(),
      (perguntas) =>
        !!perguntas.find((pergunta) => pergunta.cliente_pergunta_resposta)
    );

  temPerguntaObrigatoriaSemResposta = () =>
    createSelector(
      this.getPerguntasArray(),
      (perguntas) =>
        !!perguntas.find(
          (pergunta) =>
            pergunta.obrigatoria && !pergunta.cliente_pergunta_resposta
        )
    );
  getPulouPerguntas = () =>
    createSelector(
      this.createFeatureSelector,
      (state: PerguntasState) => state.pulouPerguntas
    );
}

export const perguntasSelectors = new PerguntasSelectors();
