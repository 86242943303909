import { LayoutUtilsService } from '@vip/core';
import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CurrencyPipe } from '@angular/common';

@Directive()
export abstract class CardCashbackDirective {
  @Input() date = new Date();
  @Input() isDesktop = false;

  @Output() alterarValorCashbackClick = new EventEmitter<number>();

  validations = {
    required: 'Por favor, informe um valor.',
    max: 'O cashback deve ser menor ou igual ao valor disponível.',
    min: 'Valor mínimo de R$ 0,01.',
  };

  valorCashback = 0;
  isToggleEnabled = false;
  checkedCashback = false;
  modal = false;
  showInput = false;
  form: UntypedFormGroup;

  private _editable = false;
  private _descontos?: number;
  private _cashbackAplicado?: number;
  private _saldoCashback?: number;
  private _totalCompra?: number;
  private _valorMinimoCashback = 0.01;
  private _valorCashbackDesativado?: number;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected currencyPipe: CurrencyPipe,
    protected layoutUtilsService: LayoutUtilsService,
    protected cd: ChangeDetectorRef
  ) {
    this.form = this.formBuilder.group({
      saldo: [
        this.saldoCashback,
        [Validators.required, Validators.min(this.valorMinimoCashback)],
      ],
    });
  }

  @Input()
  set descontos(value: number | undefined) {
    this._descontos = value;
  }

  get descontos(): number | undefined {
    return this._descontos;
  }

  @Input()
  set cashbackAplicado(value: number | undefined) {
    this._cashbackAplicado = value;
    this.valorCashback = value || 0;
    if (this.valorCashback > 0) {
      this.checkedCashback = true;
    } else {
      this.checkedCashback = false;
    }
  }

  get cashbackAplicado(): number | undefined {
    return this._cashbackAplicado;
  }
  @Input()
  set editable(value: boolean) {
    this._editable = value;
  }

  get editable(): boolean {
    return this._editable;
  }

  @Input() set saldoCashback(value: number | undefined) {
    this._saldoCashback = value;
    this.atualizaValorMaximo();
  }

  get saldoCashback(): number | undefined {
    return this._saldoCashback;
  }

  @Input() set totalCompra(value: number | undefined) {
    this._totalCompra = value;
  }

  get totalCompra(): number | undefined {
    return this._totalCompra;
  }

  @Input()
  set valorMinimoCashback(value: number) {
    this._valorMinimoCashback = value;
    this.validations.min = `Valor mínimo de ${this.currencyPipe.transform(
      this._valorMinimoCashback
    )}.`;
    this.form.controls['saldo'].addValidators(Validators.min(value));
  }

  get valorMinimoCashback(): number {
    return this._valorMinimoCashback;
  }

  openModal(): void {
    this.modal = true;
    this.form.controls.saldo.setValue(this.valorCashback);
  }

  fecharModal(): void {
    this.modal = false;
    if (this.valorCashback === 0) {
      this.toggle();
    }
  }

  alterarValorCashback() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const valor = this.form.controls['saldo'].value;
      if (this._totalCompra && this._totalCompra > 0) {
        this.valorCashback = valor;
        this.alterarValorCashbackClick.emit(valor);
      } else {
        this.valorCashback = 0;
      }
      this.isToggleEnabled = false;
      this.showInput = false;
      this.fecharModal();
    }
  }

  toggle(): void {
    if (!this.editable || !this.saldoCashback) return;

    this.checkedCashback = !this.checkedCashback;
    this.isToggleEnabled = this.checkedCashback;

    if (this.checkedCashback) {
      this.handleCashbackActivation();
      this.alterarValorCashback();
    } else {
      this.showInput = false;
      this.handleCashbackDeactivation();
    }
  }

  editableCashBack(): void {
    if (this.editable) {
      this.isToggleEnabled = true;
      this.showInput = true;
      this.form.controls['saldo'].setValue(this.valorCashback);
    } else {
      this.isToggleEnabled = false;
      this.showInput = false;
    }
  }

  atualizaValorMaximo() {
    if (this.saldoCashback) {
      this.form.controls['saldo'].addValidators([
        Validators.max(this.saldoCashback),
      ]);
      this.form.controls['saldo'].updateValueAndValidity();
      this.form.controls['saldo'].markAsTouched();
      this.cd.detectChanges();
    }
  }
  private handleCashbackActivation(): void {
    if (this._valorCashbackDesativado) {
      this.setCashbackValue(this._valorCashbackDesativado);
      this._valorCashbackDesativado = 0;
    } else {
      this.openModal();
    }
  }

  private handleCashbackDeactivation(): void {
    this._valorCashbackDesativado = this.valorCashback;
    this.setCashbackValue(0);
    this.resetForm();
    this.alterarValorCashbackClick.emit(0);
  }

  private setCashbackValue(value: number): void {
    this.valorCashback = value;
    this.form.controls['saldo'].setValue(value);
  }

  private resetForm(): void {
    this.form.patchValue({ saldo: 0 });
    this.form.markAsUntouched();
    this.form.markAsPristine();
  }
}
