import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManutencaoComponent } from './manutencao/manutencao.component';
import { ImageModule } from '@vip/ui/image';

@NgModule({
  imports: [CommonModule, ImageModule],
  declarations: [ManutencaoComponent],
  exports: [ManutencaoComponent],
})
export class ManutencaoModule {}
