<div class="vip-color-first-default flex flex-col">
  <vip-screen-title
    title="Institucional"
    leftIcon="icon-arrow_back"
    leftIconColor="secondary"
  >
  </vip-screen-title>

  <div *ngIf="paginas?.length">
    <div class="mx-3" *ngFor="let pagina of paginas">
      <div
        vipRipple
        *ngIf="
          (environment.isApp && pagina.exibir_aplicativo) || !environment.isApp
        "
      >
        <div
          class="flex flex-row items-center justify-between py-3 cursor-pointer"
          [attr.data-cy]="'list_pagina_' + pagina.id"
          (click)="cardClick.emit(pagina)"
        >
          <span class="text-sm"> {{ pagina.titulo }}</span>
          <i class="text-3xl leading-none icon-chevron_right"></i>
        </div>
        <hr class="vip-divider" />
      </div>
    </div>
  </div>
</div>
