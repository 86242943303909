import { ITipoEntregaCompra } from '../interfaces';
import { ITipoEntrega } from './../interfaces/tipo-entrega.interface';
import {
  horarioLabelComDiaSemIntervalos21_01,
  horarioLabelSemDia21_01,
  horariosLabelComDia,
  horariosLabelSemDia,
} from './tipo-entrega-horarios.mock';

export const tipoEntrega1Mock: ITipoEntrega = {
  alocar: true,
  desconto: 0,
  descricao: 'Entrega CD1 ',
  exclusivo_televendas: false,
  exibir_calendario: false,
  frete: 4.5,
  id: 1,
  prazo_entrega_minimo: 0,
  quantidade_maxima_itens: 2,
  resumo: 'Entrega CD1 semi-intevalos',
  retirada_loja: false,
  taxa: 2,
  tipo_intervalo: 'sem-intervalos',
  horarios_formatados: [horarioLabelSemDia21_01],
  data_entrega: '2022-01-20 14:25:00',
  tipo_entrega_id: '9645c61b-b318-48a8-b6a3-2e99b38933432',
  intervalo: 5,
};

export const tipoEntrega2Mock: ITipoEntrega = {
  alocar: true,
  desconto: 0,
  descricao: 'Entrega de graça ',
  exclusivo_televendas: false,
  exibir_calendario: false,
  frete: 0,
  id: 2,
  prazo_entrega_minimo: 0,
  quantidade_maxima_itens: 1,
  resumo: 'Entrega CD1 semi-intevalos',
  retirada_loja: false,
  taxa: 0,
  tipo_intervalo: 'sem-intervalos',
  horarios_formatados: [horarioLabelSemDia21_01],
};

export const tipoEntrega3Mock: ITipoEntrega = {
  alocar: true,
  desconto: 0,
  descricao: 'Retirada ',
  exclusivo_televendas: false,
  exibir_calendario: false,
  frete: 4.5,
  id: 3,
  prazo_entrega_minimo: 0,
  resumo: 'Entrega CD1 semi-intevalos',
  retirada_loja: true,
  taxa: 0,
  tipo_intervalo: 'sem-intervalos',
  horarios_formatados: [horarioLabelSemDia21_01],
};

export const tipoEntrega4Mock: ITipoEntrega = {
  alocar: true,
  desconto: 0,
  descricao: 'Retirada ',
  exclusivo_televendas: false,
  exibir_calendario: false,
  frete: 4.5,
  id: 3,
  prazo_entrega_minimo: 0,
  resumo: 'Entrega CD1 semi-intevalos',
  retirada_loja: true,
  taxa: 0,
  tipo_intervalo: 'sem-intervalos',
  horarios_formatados: [horarioLabelSemDia21_01],
  endereco_retirada: 'Endereço de retirada do tipo de entrega',
};

export const tipoEntrega5Mock: ITipoEntrega = {
  alocar: false,
  desconto: 0,
  descricao: 'Retirada ',
  exclusivo_televendas: false,
  exibir_calendario: false,
  frete: 4.5,
  id: 3,
  quantidade_maxima_itens: 0,
  prazo_entrega_minimo: 0,
  resumo: 'Entrega CD1 semi-intevalos',
  retirada_loja: true,
  taxa: 0,
  tipo_intervalo: 'sem-intervalos',
  horarios_formatados: [horarioLabelSemDia21_01],
  endereco_retirada: 'Endereço de retirada do tipo de entrega',
};

export const tipoEntregaComEnderecoMock: ITipoEntrega = {
  id: 6,
  descricao: 'Retirada Todos CDs',
  resumo: 'Retirada Todos CDs',
  quantidade_maxima_itens: 10,
  tipo_intervalo: 'intervalos-fixos',
  prazo_entrega_minimo: 0,
  taxa: 0,
  retirada_loja: true,
  endereco_centro_distribuicao:
    'Logradouro nr. 100 \r\nBairro - Cidade/Estado\r\nCEP: 00000-000',
  endereco_retirada:
    'Logradouro nr. 100 \r\nBairro - Cidade/Estado\r\nCEP: 00000-000',
  exibir_calendario: false,
  exclusivo_televendas: true,
  frete: 0,
  desconto: 0,
  alocar: true,
  horarios_formatados: [horarioLabelSemDia21_01],
};

export const tipoEntregaCompraMock: ITipoEntregaCompra = {
  descricao: 'Entrega CD1 ',
  frete: 4.5,
  desconto: 1,
  resumo: 'Entrega CD1 semi-intevalos',
  retirada: false,
  id: 1,
};

export const tipoEntregaCompraGratisMock: ITipoEntregaCompra = {
  descricao: 'Entrega CD1 ',
  frete: 0,
  desconto: 0,
  resumo: 'Entrega CD1 semi-intevalos',
  retirada: false,
  id: 2,
};

export const tipoEntregaComHorariosMock: ITipoEntrega = {
  alocar: true,
  desconto: 0,
  descricao: 'Entrega CD1 ',
  exclusivo_televendas: false,
  exibir_calendario: false,
  frete: 4.5,
  id: 1,
  prazo_entrega_minimo: 0,
  quantidade_maxima_itens: 2,
  resumo: 'Entrega CD1 semi-intevalos',
  retirada_loja: false,
  taxa: 2,
  tipo_intervalo: 'sem-intervalos',
  horarios_formatados: horariosLabelComDia,
};

export const tipoEntregaIntervalosMoveis: ITipoEntrega = {
  id: 2,
  descricao: 'Entrega CD 2',
  resumo: 'Entrega CD 2 Intervalos Moveis',
  tipo_intervalo: 'intervalos-moveis',
  prazo_entrega_minimo: 4,
  taxa: 2,
  retirada_loja: false,
  exibir_calendario: true,
  exclusivo_televendas: false,
  frete: 4.5,
  desconto: 0,
  alocar: true,
  horarios_formatados: horariosLabelSemDia,
  horarios_formatados_calendario: [
    {
      data: '2022-01-21',
      horarios: [
        {
          key: '2022-01-21 14:25:00',
          date: '2022-01-21',
          intervalo: 5,
          label: 'De 09:25 às 14:25',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 09:25 às 14:25',
        },
      ],
    },
    {
      data: '2022-01-24',
      horarios: [
        {
          key: '2022-01-24 17:25:00',
          date: '2022-01-24',
          intervalo: 5,
          label: 'De 12:25 às 17:25',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 09:25 às 14:25',
        },
      ],
    },
    {
      data: '2022-01-25',
      horarios: [
        {
          key: '2022-01-25 13:25:00',
          date: '2022-01-25',
          intervalo: 5,
          label: 'De 08:25 às 13:25',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 09:25 às 14:25',
        },
      ],
    },
    {
      data: '2022-01-26',
      horarios: [
        {
          key: '2022-01-26 14:25:00',
          date: '2022-01-26',
          intervalo: 5,
          label: 'De 09:25 às 14:25',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 09:25 às 14:25',
        },
      ],
    },
    {
      data: '2022-01-27',
      horarios: [
        {
          key: '2022-01-27 15:25:00',
          date: '2022-01-27',
          intervalo: 5,
          label: 'De 10:25 às 15:25',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 09:25 às 14:25',
        },
      ],
    },
  ],
};

export const tipoEntregaIntervalosFixos2: ITipoEntrega = {
  id: 2,
  descricao: 'Entrega CD 2',
  resumo: 'Entrega CD 2 Intervalos Moveis',
  tipo_intervalo: 'intervalos-fixos',
  prazo_entrega_minimo: 4,
  taxa: 2,
  retirada_loja: false,
  exibir_calendario: false,
  exclusivo_televendas: false,
  frete: 4.5,
  desconto: 0,
  alocar: true,
  horarios_formatados: horariosLabelSemDia,
  horarios_formatados_calendario: [
    {
      data: '2022-01-21',
      horarios: [
        {
          key: '2022-01-21 14:25:00',
          date: '2022-01-21',
          intervalo: 5,
          label: 'De 09:25 às 14:25',
          labelMobile: 'Sex, 02/06/2023 - 12:01 às 14:00',
          labelMobileCalendario: 'De 09:25 às 14:25',
        },
      ],
    },
    {
      data: '2022-01-24',
      horarios: [
        {
          key: '2022-01-24 17:25:00',
          date: '2022-01-24',
          intervalo: 5,
          label: 'De 12:25 às 17:25',
          labelMobile: 'Sex, 02/06/2023 - 12:01 às 14:00',
          labelMobileCalendario: 'De 09:25 às 14:25',
        },
      ],
    },
    {
      data: '2022-01-25',
      horarios: [
        {
          key: '2022-01-25 13:25:00',
          date: '2022-01-25',
          intervalo: 5,
          label: 'De 08:25 às 13:25',
          labelMobile: 'Sex, 02/06/2023 - 12:01 às 14:00',
          labelMobileCalendario: 'De 09:25 às 14:25',
        },
      ],
    },
    {
      data: '2022-01-26',
      horarios: [
        {
          key: '2022-01-26 14:25:00',
          date: '2022-01-26',
          intervalo: 5,
          label: 'De 09:25 às 14:25',
          labelMobile: 'Sex, 02/06/2023 - 12:01 às 14:00',
          labelMobileCalendario: 'De 09:25 às 14:25',
        },
      ],
    },
    {
      data: '2022-01-27',
      horarios: [
        {
          key: '2022-01-27 15:25:00',
          date: '2022-01-27',
          intervalo: 5,
          label: 'De 10:25 às 15:25',
          labelMobile: 'Sex, 02/06/2023 - 12:01 às 14:00',
          labelMobileCalendario: 'De 09:25 às 14:25',
        },
      ],
    },
  ],
};

export const tipoEntregaIntervalosFixos: ITipoEntrega = {
  id: 3,
  descricao: 'Entrega Todos os CDs',
  resumo: 'Entrega Todos os CDs Intervalos Fixos',
  tipo_intervalo: 'intervalos-fixos',
  prazo_entrega_minimo: 2,
  taxa: 4,
  retirada_loja: false,
  exibir_calendario: true,
  exclusivo_televendas: false,
  frete: 6.5,
  desconto: 0,
  alocar: true,
  horarios_formatados: horariosLabelSemDia,
  horarios_formatados_calendario: [
    {
      data: '2022-01-21',
      horarios: [
        {
          key: '2022-01-21 19:00:00',
          date: '2022-01-21',
          intervalo: 6,
          label: 'De 12:01 às 19:00',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 12:01 às 19:00',
        },
      ],
    },
    {
      data: '2022-01-22',
      horarios: [
        {
          key: '2022-01-22 12:00:00',
          date: '2022-01-22',
          intervalo: 4,
          label: 'De 08:00 às 12:00',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 12:01 às 19:00',
        },
        {
          key: '2022-01-22 14:00:00',
          date: '2022-01-22',
          intervalo: 7,
          label: 'De 12:01 às 14:00',
          labelMobile: 'De 12:01 às 14:00',
          labelMobileCalendario: 'De 12:01 às 19:00',
        },
        {
          key: '2022-01-22 16:00:00',
          date: '2022-01-22',
          intervalo: 7,
          label: 'De 14:01 às 16:00',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 12:01 às 19:00',
        },
        {
          key: '2022-01-22 18:00:00',
          date: '2022-01-22',
          intervalo: 7,
          label: 'De 16:01 às 18:00',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 12:01 às 19:00',
        },
      ],
    },
    {
      data: '2022-01-24',
      horarios: [
        {
          key: '2022-01-24 12:00:00',
          date: '2022-01-24',
          intervalo: 4,
          label: 'De 08:00 às 12:00',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 12:01 às 19:00',
        },
      ],
    },
  ],
};

export const tipoEntregaSemIntervalos: ITipoEntrega = {
  id: 10,
  descricao: 'Campanha Frete filial 2',
  resumo: 'Tipo específico campnha frete filial 2',
  tipo_intervalo: 'sem-intervalos',
  prazo_entrega_minimo: 1,
  taxa: 2,
  retirada_loja: false,
  exibir_calendario: false,
  exclusivo_televendas: false,
  frete: 4.5,
  desconto: 0,
  alocar: true,
  horarios_formatados: [horarioLabelComDiaSemIntervalos21_01],
};

export const tipoRetiradaIntervalosFixos: ITipoEntrega = {
  id: 6,
  descricao: 'Retirada Todos CDs',
  resumo: 'Retirada Todos CDs',
  tipo_intervalo: 'intervalos-fixos',
  prazo_entrega_minimo: 0,
  taxa: 0,
  retirada_loja: true,
  endereco_centro_distribuicao:
    'Logradouro nr. 100 \r\nBairro - Cidade/Estado\r\nCEP: 00000-000',
  endereco_retirada:
    'Logradouro nr. 100 \r\nBairro - Cidade/Estado\r\nCEP: 00000-000',
  exibir_calendario: false,
  exclusivo_televendas: false,
  frete: 0,
  desconto: 0,
  alocar: true,
  horarios_formatados: horariosLabelComDia,
};

export const tipoRetiradaIntervalosMoveis: ITipoEntrega = {
  id: 2,
  descricao: 'Entrega CD 2',
  resumo: 'Retirada CD 2 Intervalos Moveis',
  tipo_intervalo: 'intervalos-moveis',
  prazo_entrega_minimo: 4,
  taxa: 2,
  retirada_loja: true,
  endereco_centro_distribuicao:
    'Logradouro nr. 100 \r\nBairro - Cidade/Estado\r\nCEP: 00000-000',
  endereco_retirada:
    'Logradouro nr. 100 \r\nBairro - Cidade/Estado\r\nCEP: 00000-000',
  exibir_calendario: true,
  exclusivo_televendas: false,
  frete: 2,
  desconto: 0,
  alocar: true,
  horarios_formatados: horariosLabelSemDia,
  horarios_formatados_calendario: [
    {
      data: '2022-01-24',
      horarios: [
        {
          key: '2022-01-24 13:40:00',
          date: '2022-01-24',
          intervalo: 5,
          label: 'De 08:40 às 13:40',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 08:40 às 13:40',
        },
        {
          key: '2022-01-24 13:40:00',
          date: '2022-01-24',
          intervalo: 6,
          label: 'De 14:40 às 17:40',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 08:40 às 13:40',
        },
      ],
    },
    {
      data: '2022-01-25',
      horarios: [
        {
          key: '2022-01-25 14:40:00',
          date: '2022-01-25',
          intervalo: 5,
          label: 'De 09:40 às 14:40',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 08:40 às 13:40',
        },
      ],
    },
    {
      data: '2022-01-26',
      horarios: [
        {
          key: '2022-01-26 15:40:00',
          date: '2022-01-26',
          intervalo: 5,
          label: 'De 10:40 às 15:40',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 08:40 às 13:40',
        },
      ],
    },
    {
      data: '2022-01-27',
      horarios: [
        {
          key: '2022-01-27 16:40:00',
          date: '2022-01-27',
          intervalo: 5,
          label: 'De 11:40 às 16:40',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 08:40 às 13:40',
        },
      ],
    },
    {
      data: '2022-01-28',
      horarios: [
        {
          key: '2022-01-28 17:40:00',
          date: '2022-01-28',
          intervalo: 5,
          label: 'De 12:40 às 17:40',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 08:40 às 13:40',
        },
      ],
    },
  ],
};

export const tipoRetiradaExibirCalendarioFalseMock: ITipoEntrega = {
  id: 2,
  descricao: 'Entrega CD 2',
  resumo: 'Retirada CD 2 Intervalos Moveis',
  tipo_intervalo: 'intervalos-moveis',
  prazo_entrega_minimo: 4,
  taxa: 2,
  retirada_loja: true,
  endereco_centro_distribuicao:
    'Logradouro nr. 100 \r\nBairro - Cidade/Estado\r\nCEP: 00000-000',
  endereco_retirada:
    'Logradouro nr. 100 \r\nBairro - Cidade/Estado\r\nCEP: 00000-000',
  exibir_calendario: true,
  exclusivo_televendas: false,
  frete: 2,
  desconto: 0,
  alocar: true,
  horarios_formatados: horariosLabelSemDia,
  horarios_formatados_calendario: [
    {
      data: '2022-01-24',
      horarios: [
        {
          key: '2022-01-24 13:40:00',
          date: '2022-01-24',
          intervalo: 5,
          label: 'De 08:40 às 13:40',
          labelMobile: 'De 10:25 às 12:25',
          labelCalendario: 'De 07:00 às 23:00',
          labelMobileCalendario: 'De 07:00 às 23:00',
        },
        {
          key: '2022-01-24 13:40:00',
          date: '2022-01-24',
          intervalo: 6,
          label: 'De 14:40 às 17:40',
          labelMobile: 'De 10:25 às 12:25',
          labelCalendario: 'De 07:00 às 23:00',
          labelMobileCalendario: 'De 07:00 às 23:00',
        },
      ],
    },
    {
      data: '2022-01-25',
      horarios: [
        {
          key: '2022-01-25 14:40:00',
          date: '2022-01-25',
          intervalo: 5,
          label: 'De 09:40 às 14:40',
          labelMobile: 'De 10:25 às 12:25',
          labelCalendario: 'De 07:00 às 23:00',
          labelMobileCalendario: 'De 07:00 às 23:00',
        },
      ],
    },
    {
      data: '2022-01-26',
      horarios: [
        {
          key: '2022-01-26 15:40:00',
          date: '2022-01-26',
          intervalo: 5,
          label: 'De 10:40 às 15:40',
          labelMobile: 'De 10:25 às 12:25',
          labelCalendario: 'De 07:00 às 23:00',
          labelMobileCalendario: 'De 07:00 às 23:00',
        },
      ],
    },
    {
      data: '2022-01-27',
      horarios: [
        {
          key: '2022-01-27 16:40:00',
          date: '2022-01-27',
          intervalo: 5,
          label: 'De 11:40 às 16:40',
          labelMobile: 'De 11:40 às 16:40',
          labelCalendario: 'De 07:00 às 23:00',
          labelMobileCalendario: 'De 07:00 às 23:00',
        },
      ],
    },
    {
      data: '2022-01-28',
      horarios: [
        {
          key: '2022-01-28 17:40:00',
          date: '2022-01-28',
          intervalo: 5,
          label: 'De 12:40 às 17:40',
          labelMobile: 'De 12:40 às 17:40',
          labelCalendario: 'De 07:00 às 23:00',
          labelMobileCalendario: 'De 07:00 às 23:00',
        },
      ],
    },
  ],
};

export const tipoRetiradaSemIntervalos: ITipoEntrega = {
  id: 3,
  descricao: 'Retirada Todos os CDs',
  resumo: 'Retirada Todos os CDs Intervalos Fixos',
  tipo_intervalo: 'intervalos-fixos',
  prazo_entrega_minimo: 2,
  taxa: 4,
  retirada_loja: true,
  endereco_centro_distribuicao:
    'Logradouro nr. 100 \r\nBairro - Cidade/Estado\r\nCEP: 00000-000',
  endereco_retirada:
    'Logradouro nr. 100 \r\nBairro - Cidade/Estado\r\nCEP: 00000-000',
  exibir_calendario: true,
  exclusivo_televendas: false,
  frete: 4,
  desconto: 0,
  alocar: true,
  horarios_formatados: horariosLabelSemDia,
  horarios_formatados_calendario: [
    {
      data: '2022-01-21',
      horarios: [
        {
          key: '2022-01-21 19:00:00',
          date: '2022-01-21',
          intervalo: 6,
          label: 'De 12:01 às 19:00',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 12:01 às 19:00',
        },
      ],
    },
    {
      data: '2022-01-22',
      horarios: [
        {
          key: '2022-01-22 12:00:00',
          date: '2022-01-22',
          intervalo: 4,
          label: 'De 08:00 às 12:00',
          labelMobile: 'De 08:00 às 12:00',
          labelMobileCalendario: 'De 12:01 às 19:00',
        },
        {
          key: '2022-01-22 20:00:00',
          date: '2022-01-22',
          intervalo: 7,
          label: 'De 12:01 às 20:00',
          labelMobile: 'De 10:25 às 12:25',
          labelMobileCalendario: 'De 12:01 às 19:00',
        },
      ],
    },
    {
      data: '2022-01-24',
      horarios: [
        {
          key: '2022-01-24 12:00:00',
          date: '2022-01-24',
          intervalo: 4,
          label: 'De 08:00 às 12:00',
          labelMobile: 'De 08:00 às 12:00',
          labelMobileCalendario: 'De 12:01 às 19:00',
        },
      ],
    },
  ],
};

export const HorariosMock = {
  horarios_formatados: horariosLabelSemDia,
  horarios_formatados_calendario: [
    {
      data: '2022-01-24',
      horarios: [
        {
          key: '2022-01-24 13:40:00',
          date: '2022-01-24',
          intervalo: 5,
          label: 'De 08:40 às 13:40',
          labelMobile: 'De 08:40 às 13:40',
          labelCalendario: 'De 07:00 às 23:00',
        },
        {
          key: '2022-01-24 13:40:00',
          date: '2022-01-24',
          intervalo: 6,
          label: 'De 14:40 às 17:40',
          labelMobile: 'De 14:40 às 17:40',
          labelCalendario: 'De 07:00 às 23:00',
        },
      ],
    },
    {
      data: '2022-01-25',
      horarios: [
        {
          key: '2022-01-25 14:40:00',
          date: '2022-01-25',
          intervalo: 5,
          label: 'De 09:40 às 14:40',
          labelMobile: 'De 09:40 às 14:40',
          labelCalendario: 'De 07:00 às 23:00',
        },
      ],
    },
    {
      data: '2022-01-26',
      horarios: [
        {
          key: '2022-01-26 15:40:00',
          date: '2022-01-26',
          intervalo: 5,
          label: 'De 10:40 às 15:40',
          labelMobile: 'De 10:40 às 15:40',
          labelCalendario: 'De 07:00 às 23:00',
        },
      ],
    },
    {
      data: '2022-01-27',
      horarios: [
        {
          key: '2022-01-27 16:40:00',
          date: '2022-01-27',
          intervalo: 5,
          label: 'De 11:40 às 16:40',
          labelMobile: 'De 11:40 às 16:40',
          labelCalendario: 'De 07:00 às 23:00',
        },
      ],
    },
    {
      data: '2022-01-28',
      horarios: [
        {
          key: '2022-01-28 17:40:00',
          date: '2022-01-28',
          intervalo: 5,
          label: 'De 12:40 às 17:40',
          labelMobile: 'De 12:40 às 17:40',
          labelCalendario: 'De 07:00 às 23:00',
        },
      ],
    },
  ],
};
