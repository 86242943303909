import { Action, createReducer, on } from '@ngrx/store';

import * as TipoEntregaActions from './tipo-entrega.actions';
import { IHorariosFormatados, ITipoEntrega } from '@vip/core';
import { GenericStoreStatusEnum, IGenericState } from '@vip/state/utils';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const TIPO_ENTREGAS_FEATURE_KEY = 'tipo-entregas';

export interface TipoEntregaState
  extends IGenericState<ITipoEntrega[]>,
    EntityState<ITipoEntrega> {
  isEntrega: boolean;
  tipoEntregaPadrao: ITipoEntrega[] | null;
  tipoEntregaSelecionadaId: number | null;
  horarioSelecionado: IHorariosFormatados | null;
}

export const tipoEntregaAdapter: EntityAdapter<ITipoEntrega> =
  createEntityAdapter<ITipoEntrega>();

export const initialState: TipoEntregaState =
  tipoEntregaAdapter.getInitialState({
    data: [],
    isEntrega: true,
    status: GenericStoreStatusEnum.PENDING,
    error: null,
    tipoEntregaPadrao: [],
    tipoEntregaSelecionadaId: null,
    horarioSelecionado: null,
  });

const tipoEntregaReducer = createReducer(
  initialState,
  on(TipoEntregaActions.resetTiposEntregaStore, () => ({ ...initialState })),
  on(TipoEntregaActions.resetTipoEntregaSelecionada, (state) => ({
    ...state,
    tipoEntregaSelecionadaId: null,
    horarioSelecionado: null,
  })),
  on(TipoEntregaActions.setFormaEntrega, (state, { isEntrega }) => ({
    ...state,
    isEntrega,
  })),
  on(
    TipoEntregaActions.getFretesPorCep,
    TipoEntregaActions.getTipoEntregaPadrao,
    TipoEntregaActions.getTiposEntregasEndereco,
    TipoEntregaActions.getHorarios,
    (state) => ({
      ...state,
      error: null,
      status: GenericStoreStatusEnum.LOADING,
    })
  ),
  on(
    TipoEntregaActions.getTiposEntregasEnderecoSuccess,
    TipoEntregaActions.getTipoEntregaPagamentoSuccess,
    TipoEntregaActions.getTipoEntregaSuccess,
    (state, { tiposEntregas }) =>
      tipoEntregaAdapter.upsertMany(tiposEntregas, {
        ...state,
        status: GenericStoreStatusEnum.SUCCESS,
      })
  ),
  on(
    TipoEntregaActions.getTipoEntregaPadraoSuccess,
    (state, { tipoEntrega }) => ({
      ...state,
      tipoEntregaPadrao: tipoEntrega,
      status: GenericStoreStatusEnum.SUCCESS,
    })
  ),
  on(
    TipoEntregaActions.getFretesPorCepFailure,
    TipoEntregaActions.getTipoEntregaPadraoFailure,
    TipoEntregaActions.getTiposEntregasEnderecoFailure,
    TipoEntregaActions.getTipoEntregaFailure,
    TipoEntregaActions.getTipoEntregaPagamentoFailure,
    (state, { error }) => ({
      ...state,
      data: initialState.data,
      status: GenericStoreStatusEnum.ERROR,
      error: error.message,
    })
  ),
  on(TipoEntregaActions.getHorariosFailure, (state, { error }) => ({
    ...state,
    status: GenericStoreStatusEnum.ERROR,
    error: error.message,
  })),
  on(
    TipoEntregaActions.selectTipoEntrega,
    TipoEntregaActions.getTipoEntrega,
    (state, { tipoEntregaId }) => ({
      ...state,
      tipoEntregaSelecionadaId: tipoEntregaId,
      horarioSelecionado: null,
    })
  ),
  on(
    TipoEntregaActions.selectHorarioTipoEntrega,
    (state, { horarioTipoEntrega }) => ({
      ...state,
      horarioSelecionado: horarioTipoEntrega,
    })
  ),
  on(TipoEntregaActions.getHorariosSuccess, (state, { horarios }) => {
    const tipoEntrega = tipoEntregaAdapter.updateOne(
      {
        id: state.tipoEntregaSelecionadaId ?? 0,
        changes: {
          horarios_formatados: horarios.horarios_formatados,
          horarios_formatados_calendario:
            horarios.horarios_formatados_calendario,
        },
      },
      state
    );

    return {
      ...tipoEntrega,
      status: GenericStoreStatusEnum.SUCCESS,
    };
  }),
  on(TipoEntregaActions.getFretesPorCepSuccess, (state, { tiposEntregas }) =>
    tipoEntregaAdapter.setAll(tiposEntregas, {
      ...state,
      status: GenericStoreStatusEnum.SUCCESS,
    })
  )
);

export function reducer(state: TipoEntregaState, action: Action) {
  return tipoEntregaReducer(state, action);
}
