import { Action, createReducer, on } from '@ngrx/store';
import * as CashbackActions from './cashback.actions';
import { ITransacaoCashback, ITransacaoCashbackAPI } from '@vip/core';

export const CASHBACK_FEATURE_KEY = 'cashback';

export interface CashbackState {
  saldoCashback: number;
  extrato: ITransacaoCashback[];
  ultimaAtualizacao: Date | null;
  saldoError: string | null;
  extratoError: string | null;
  alterarSenhaError: string | null;
  exibeCashback: boolean;
  extratoCompleto: boolean;
  valorMinimo: number;
  alterarSenhaAtivo: boolean;
  status: 'pending' | 'loading' | 'success' | 'error';
}

export const initialState: CashbackState = {
  saldoCashback: 0,
  extrato: [],
  ultimaAtualizacao: null,
  saldoError: null,
  extratoError: null,
  alterarSenhaError: null,
  exibeCashback: false,
  extratoCompleto: false,
  valorMinimo: 0.01,
  alterarSenhaAtivo: true,
  status: 'pending',
};

const cashbackReducer = createReducer(
  initialState,
  on(CashbackActions.getSaldoCashback, (state) => ({
    ...state,
    status: 'loading',
    saldoError: null,
  })),
  on(
    CashbackActions.getExtratoCashback,
    CashbackActions.carregarMaisExtratoCashback,
    (state) => ({
      ...state,
      status: 'loading',
      extratoError: null,
    })
  ),
  on(CashbackActions.alterarSenhaCashback, (state) => ({
    ...state,
    status: 'loading',
    alterarSenhaError: null,
  })),
  on(
    CashbackActions.getSaldoCashbackSuccess,
    (state, { saldo, exibeCashback }) => ({
      ...state,
      saldoCashback: saldo?.saldo || 0,
      ultimaAtualizacao: new Date(),
      status: 'success',
      exibeCashback,
      saldoError: null,
    })
  ),
  on(CashbackActions.getExtratoCashbackSuccess, (state, { extrato }) => ({
    ...state,
    status: 'success',
    extrato: mapExtratoCashback(extrato),
    extratoCompleto: false,
    extratoError: null,
  })),
  on(
    CashbackActions.carregarMaisExtratoCashbackSuccess,
    (state, { extrato }) => ({
      ...state,
      status: 'success',
      extrato: [...state.extrato, ...mapExtratoCashback(extrato)],
      extratoCompleto: !extrato.length,
      extratoError: null,
    })
  ),
  on(CashbackActions.setCashbackConfigs, (state, { isIdever }) => ({
    ...state,
    valorMinimo: isIdever ? 1 : 0.01,
    alterarSenhaAtivo: !isIdever,
  })),
  on(CashbackActions.alterarSenhaCashbackSuccess, (state) => ({
    ...state,
    status: 'success',
    alterarSenhaError: null,
  })),
  on(CashbackActions.getSaldoCashbackFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    saldoError: error,
  })),
  on(CashbackActions.getExtratoCashbackFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    extratoError: error,
  }))
);

export const mapExtratoCashback = (extrato: ITransacaoCashbackAPI[]) => {
  if (!extrato) return [];
  return extrato.map((item) => ({
    id: item.des_nsu || item.transactionId,
    descricao: item.des_obs || item.description,
    tipo_transacao: item.tipo_transacao || item.operation,
    data: item.dat_transacao || item.eventDate,
    valor: item.value || item.vlr_extrato,
  }));
};

export function reducer(state: CashbackState | undefined, action: Action) {
  return cashbackReducer(state, action);
}
