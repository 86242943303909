import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseWsService } from '../common/base-ws-service';
import {
  IRemoverDispositivoPayload,
  LogoutResponse,
  IRegistrationPayload,
  IRegistrationRespose,
} from './cliente-ws.types';

@Injectable({
  providedIn: 'root',
})
export class ClienteWsService {
  private clienteUrl = '/loja/clientes';

  constructor(private ws: BaseWsService<ClienteWsService>) {}

  logout(): Observable<LogoutResponse> {
    return this.ws.post<Record<string, never>, LogoutResponse>(
      'logout',
      `${this.clienteUrl}/logout`,
      {}
    );
  }

  registrarDispositivo(token: string, plataforma: string, filialId: number) {
    return this.ws
      .post<IRegistrationPayload, IRegistrationRespose>(
        'registrarDispositivo',
        `${this.clienteUrl}/registrar-dispositivo`,
        {
          registrationId: token,
          plataforma,
        },
        { FilialID: filialId.toString() }
      )
      .pipe(
        map((res) => {
          if (res.error || !res.success) {
            throw new HttpErrorResponse({
              status: 400,
              error: { error: res.message },
            });
          }
          return res;
        })
      );
  }

  removerDispositivo(token: string, filialId: number) {
    return this.ws
      .post<IRemoverDispositivoPayload, IRegistrationRespose>(
        'removerDispositivo',
        `${this.clienteUrl}/remover-dispositivo`,
        {
          registrationId: token,
        },
        { FilialID: filialId.toString() }
      )
      .pipe(
        map((res) => {
          if (res.error || !res.success) {
            throw new HttpErrorResponse({
              status: 400,
              error: { error: res.message },
            });
          }
          return res;
        })
      );
  }
}
