/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { fetch } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { mergeTakeOne } from '@vip/state/utils';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { tipoEntregaSelectors } from './tipo-entrega.selectors';
import { TipoEntregaApiService } from '@vip/api';
import { IFilial, LayoutUtilsService, TipoClienteEnum } from '@vip/core';

import { CarrinhoFacade } from '@vip/state/carrinho';
import {
  CentroDistribuicaoFacade,
  CentroDistribuicaoActions,
} from '@vip/state/centro-distribuicao';
import { EnderecoFacade } from '@vip/state/endereco';
import { FilialFacade } from '@vip/state/filial';

import * as TipoEntregaActions from './tipo-entrega.actions';
import * as EnderecoActions from '@vip/state/endereco';
import { CompraEmProcessoProviderFacade } from '@vip/state/compra-em-processo-provider';
import { MessageService } from '@vip/ui/message';
import { TipoEntregaFacade } from './tipo-entrega.facade';
import { ClienteFacade } from '@vip/state/cliente';
import { LoadingFacade } from '@vip/state/loading';

@Injectable()
export class TipoEntregaEffects {
  getFretesPorCep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TipoEntregaActions.getFretesPorCep),
      withLatestFrom(this.filialFacade.filial$),
      fetch({
        run: (action, filial: IFilial) =>
          this.tipoEntregaApiService
            .getFretesPorCep(filial.id, action.cep)
            .pipe(
              map((tiposEntregas) =>
                TipoEntregaActions.getFretesPorCepSuccess({
                  tiposEntregas: tiposEntregas,
                })
              )
            ),

        onError: (action, error) =>
          TipoEntregaActions.getFretesPorCepFailure({ error }),
      })
    )
  );

  getTipoEntregaPadrao$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(TipoEntregaActions.getTipoEntregaPadrao)),
      this.cdFacade.filialECdSelecionado$,
    ]).pipe(
      map(([action, [filial, cdSelecionado]]) => {
        return { ...action, filial, cdSelecionado };
      }),
      fetch({
        run: (action) =>
          this.tipoEntregaApiService
            .getTipoEntregaPadrao(
              action.filial.id,
              action.cdSelecionado.id,
              action.enderecoId,
              action.totalItens,
              action.compraId
            )
            .pipe(
              map((tipoEntregas) =>
                TipoEntregaActions.getTipoEntregaPadraoSuccess({
                  tipoEntrega: tipoEntregas,
                })
              )
            ),

        onError: (action, error) =>
          TipoEntregaActions.getTipoEntregaPadraoFailure({ error }),
      })
    );
  });

  getTipoEntrega$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TipoEntregaActions.getTipoEntrega),
      mergeTakeOne(
        this.store.pipe(
          select(tipoEntregaSelectors.getTipoEntregaSelecionada())
        ),
        this.cdFacade.filialECdSelecionado$
      ),
      filter(([, entregaSelecionada]) => !entregaSelecionada),
      map(([action, , [filial, cdSelecionado]]) => {
        return { ...action, filial, cdSelecionado };
      }),
      switchMap((action) => {
        return this.tipoEntregaApiService
          .getTipoEntrega(
            action.filial.id,
            action.cdSelecionado.id,
            action.enderecoId,
            action.totalItens,
            action.tipoEntregaId
          )
          .pipe(
            map((tiposEntregas) =>
              TipoEntregaActions.getTipoEntregaSuccess({
                tiposEntregas,
              })
            )
          );
      }),
      catchError(async ({ error }) =>
        TipoEntregaActions.getTipoEntregaFailure({ error })
      )
    )
  );

  selectTipoEntrega$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TipoEntregaActions.selectTipoEntrega),
      withLatestFrom(
        this.tipoEntregaFacade.tipoEntregaSelecionado$,
        this.cdFacade.filialECdSelecionado$
      ),
      filter(([_action, tipoEntregaSelecionado]) => {
        return tipoEntregaSelecionado?.horarios_formatados?.length == 0;
      }),
      map(([action, tipoEntregaSelecionado, [filial, CDSelecionado]]) =>
        TipoEntregaActions.getHorarios({
          fillialId: filial.id,
          centroDistribuicaoId: CDSelecionado.id,
          tipoEntregaId: tipoEntregaSelecionado?.tipo_entrega_id ?? '',
          tipoEntregaSelecionado: tipoEntregaSelecionado,
        })
      )
    )
  );

  gethorarios$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TipoEntregaActions.getHorarios),
      fetch({
        run: (action) => {
          return this.tipoEntregaApiService
            .getHorarios(
              action.fillialId,
              action.centroDistribuicaoId,
              action.tipoEntregaId
            )
            .pipe(
              map((horarios) =>
                TipoEntregaActions.getHorariosSuccess({ horarios })
              )
            );
        },
        onError: (action, error) =>
          TipoEntregaActions.getHorariosFailure({ error }),
      })
    )
  );

  showHorariosMessageError = createEffect(
    () => () =>
      this.actions$.pipe(
        ofType(TipoEntregaActions.getHorariosFailure),
        withLatestFrom(this.compraEmProcessoFacade.compraEmProcesso$),
        tap(([, compraEmprocesso]) => {
          this.messageService.openErrorMessage(
            'Serviço indisponível no momento. Por favor, tente novamente mais tarde.',
            2
          );
          if (!compraEmprocesso) {
            this.router.navigateByUrl('/resumo-compra');
          }
        })
      ),
    { dispatch: false }
  );

  showTipoEntregaEnderecosErrorMessage = createEffect(
    () => () =>
      this.actions$.pipe(
        ofType(TipoEntregaActions.getTiposEntregasEnderecoFailure),
        withLatestFrom(this.compraEmProcessoFacade.compraEmProcesso$),
        tap(([, compraEmprocesso]) => {
          this.messageService.openErrorMessage(
            'Não foi possível carregar as informações do tipo de entrega. Por favor, selecione outro tipo de entrega',
            2
          );
          if (!compraEmprocesso) {
            this.router.navigateByUrl('/resumo-compra');
          }
        })
      ),
    { dispatch: false }
  );

  getTiposEntregasEndereco$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TipoEntregaActions.getTiposEntregasEndereco),
      mergeTakeOne(
        this.cdFacade.filialECdSelecionado$,
        this.enderecoFacade.enderecoEntrega$.pipe(
          filter((endereco) => endereco !== null)
        ),
        this.carrinhoFacade.carrinho$.pipe(
          filter((carrinho) => carrinho.quantidade > 0)
        ),
        this.clienteFacade.cliente$,
        this.layoutUtilsService.isDesktop$
      ),
      map(
        ([
          action,
          [filial, cdSelecionado],
          endereco,
          carrinho,
          cliente,
          isDesktop,
        ]) => {
          return {
            ...action,
            filial,
            cdSelecionado,
            endereco,
            carrinho,
            cliente,
            isDesktop,
          };
        }
      ),
      filter(({ endereco }) => !!endereco),
      fetch({
        run: (action) => {
          if (action.isDesktop)
            this.loadingFacade.setLoading('Carregando formas de pagamento');
          return this.tipoEntregaApiService
            .getTiposEntregasEndereco(
              action.filial.id,
              action.cdSelecionado.id,
              action.endereco!.id,
              action.carrinho.quantidade,
              action.carrinho.peso_total || 0,
              action.cliente?.tipo_cliente || TipoClienteEnum.AMBOS
            )
            .pipe(
              map((tiposEntregas) =>
                TipoEntregaActions.getTiposEntregasEnderecoSuccess({
                  tiposEntregas: tiposEntregas,
                })
              )
            );
        },

        onError: (action, error) =>
          TipoEntregaActions.getTiposEntregasEnderecoFailure({ error }),
      })
    )
  );

  disableLoading = createEffect(
    () => () =>
      this.actions$.pipe(
        ofType(
          TipoEntregaActions.getTiposEntregasEnderecoSuccess,
          TipoEntregaActions.getTiposEntregasEnderecoFailure
        ),
        tap(() => {
          this.loadingFacade.disableLoading();
        })
      ),
    { dispatch: false }
  );

  getTipoEntregaPagamento$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TipoEntregaActions.getTipoEntregaPagamento),
      mergeTakeOne(
        this.cdFacade.filialECdSelecionado$,
        this.tipoEntregaFacade.tipoEntregaSelecionado$,
        this.enderecoFacade.enderecoEntrega$,
        this.carrinhoFacade.qtdItensCarrinho$
      ),
      map(
        ([
          action,
          [filial, cdSelecionado],
          tipoEntregaSelecionado,
          endereco,
          qtdItensCarrinho,
        ]) => {
          return {
            ...action,
            filial,
            cdSelecionado,
            tipoEntregaSelecionado,
            endereco,
            qtdItensCarrinho,
          };
        }
      ),
      filter(({ endereco }) => !!endereco),
      fetch({
        run: (action) => {
          return this.tipoEntregaApiService
            .getTipoEntrega(
              action.filial.id,
              action.cdSelecionado.id,
              action.endereco!.id,
              action.qtdItensCarrinho,
              action.tipoEntregaSelecionado?.id || 0
            )
            .pipe(
              map((tiposEntregas) =>
                TipoEntregaActions.getTipoEntregaPagamentoSuccess({
                  tiposEntregas: tiposEntregas,
                })
              )
            );
        },

        onError: (action, error) =>
          TipoEntregaActions.getTipoEntregaPagamentoFailure({ error }),
      })
    )
  );

  resetTipoEntregaSelecionada$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EnderecoActions.selectEnderecoEntrega,
        TipoEntregaActions.setFormaEntrega,
        CentroDistribuicaoActions.migrarCentroDistribuicaoSuccess
      ),
      withLatestFrom(this.compraEmProcessoFacade.compraEmProcesso$),
      filter(([, compraEmProcesso]) => !compraEmProcesso),
      map(() => TipoEntregaActions.resetTipoEntregaSelecionada())
    )
  );

  selectTipoEntregaLojaAutonoma$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TipoEntregaActions.getTipoEntregaSuccess),
      withLatestFrom(this.cdFacade.isLojaAutonoma$),
      filter(
        ([action, isLojaAutonoma]) =>
          !!isLojaAutonoma && !!action.tiposEntregas.length
      ),
      map(([action]) =>
        TipoEntregaActions.selectTipoEntrega({
          tipoEntregaId: action.tiposEntregas[0].id,
        })
      )
    )
  );

  alterarParaRetiradaAposApagarEndereco$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EnderecoActions.deleteEnderecoSuccess),
        tap((action) => {
          this.tipoEntregaFacade.setFormaEntrega(false);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private tipoEntregaApiService: TipoEntregaApiService,
    private filialFacade: FilialFacade,
    private cdFacade: CentroDistribuicaoFacade,
    private enderecoFacade: EnderecoFacade,
    private carrinhoFacade: CarrinhoFacade,
    private compraEmProcessoFacade: CompraEmProcessoProviderFacade,
    private messageService: MessageService,
    private tipoEntregaFacade: TipoEntregaFacade,
    private clienteFacade: ClienteFacade,
    private store: Store,
    private router: Router,
    private loadingFacade: LoadingFacade,
    private layoutUtilsService: LayoutUtilsService
  ) {}
}
