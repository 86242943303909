<ng-container *ngIf="getScreenWidth > 1024; else mobile">
  <vip-header-desktop-sub-menu
    [logo]="logo"
    [fallbackImageUrl]="fallbackImageUrl"
    [cliente]="cliente"
    [loading]="loading"
    [isTelevendas]="isTelevendas"
    [cdSelecionado]="cdSelecionado"
    [qtdItensCarrinho]="qtdItensCarrinho"
    [minhaContaOptions]="minhaContaOptions"
    [disableEntregaRetirada]="disableEntregaRetirada"
    [isFocused]="isFocused"
    [termos]="termos"
    [produtos]="produtos"
    [produtosNoCarrinho]="produtosNoCarrinho"
    [produtosCarrinho]="produtosCarrinho"
    [produtosFavoritos]="produtosFavoritos"
    [produtosMaisVendidos]="produtosMaisVendidos"
    [carrinhoVazio]="carrinhoVazio"
    [carrinho]="carrinho"
    [produtosNoCarrinho]="produtosNoCarrinho"
    [combosNoCarrinho]="combosNoCarrinho"
    [enderecos]="enderecos"
    [cdsEntrega]="cdsEntrega"
    [cdsRetirada]="cdsRetirada"
    [realizaEntrega]="realizaEntrega"
    [enderecoSelecionado]="enderecoSelecionado"
    [cdSelecionado]="cdSelecionado"
    [formaEntregaFromState]="formaEntregaFromState"
    [possuiLojaAutonoma]="possuiLojaAutonoma"
    [isLojaAutonoma]="isLojaAutonoma"
    [isFromResumoCompra]="isFromResumoCompra"
    [novoCd]="novoCd"
    [novaFormaEntrega]="novaFormaEntrega"
    [cdSelecionadoProperty]="cdSelecionadoProperty"
    [closeDropdownEntregaRetirada$]="closeDropdownEntregaRetirada$"
    [searchValue]="searchValue"
    [exibeCashback]="exibeCashback"
    [saldoCashback]="saldoCashback"
    [saldoCashbackError]="saldoCashbackError"
    [quantidadeNaoLida]="quantidadeNaoLida"
    [scrollingShadow]="scrollingShadow"
    [loadingBuscaRapida]="loadingBuscaRapida"
    (enderecoEntregaChange)="enderecoEntregaChange.emit($event)"
    (cdChange)="cdChange.emit($event)"
    (entregaChange)="entregaChange.emit($event)"
    (formaEntregaChange)="formaEntregaChange.emit($event)"
    (enviarFormSolicitarProduto)="enviarFormSolicitarProduto.emit($event)"
    (cadastrarEndereco)="cadastrarEndereco.emit($event)"
    (alterarEndereco)="alterarEndereco.emit($event)"
    (goToLoginPage)="goToLoginPage.emit($event)"
    (goToSelectClienteTelevendasPage)="
      goToSelectClienteTelevendasPage.emit($event)
    "
    (searchClick)="isFocused = true"
    (search)="
      $event.value && $event.value.length >= 3
        ? handleQuickSearchEvent($event)
        : $event.preventDefault()
    "
    (goLogout)="goLogout.emit()"
    (clickSearchByTerm)="clickSearchByTerm.emit($event)"
    (clickProduct)="clickProduct.emit($event)"
    (clickSpinChange)="clickSpinChange.emit($event)"
    (showMoreClick)="showMoreClick.emit()"
    (produtoChanged)="produtoChanged.emit($event)"
    (resumeClick)="resumeClick.emit($event)"
    (deleteCarrinhoClick)="deleteCarrinhoClick.emit()"
    (deleteItemClick)="deleteItemClick.emit($event)"
    (addListClick)="addListClick.emit($event)"
    (aviseMeClick)="aviseMeClick.emit($event)"
    [aviseMeEnviados]="aviseMeEnviados"
    (informarCepClick)="informarCepClick.emit()"
    (encerrarSessaoTelevandas)="encerrarSessaoTelevandas.emit()"
    [filiaisTelevendas]="_filiaisTelevenda"
    (searchClickTelevendas)="searchClickTelevendas.emit($event)"
    [clientesTelevenda]="clientesTelevenda"
    (itemSelected)="itemSelected.emit($event)"
    (selectedFilial)="selectedFilial.emit($event)"
    (openModalCepRef)="openModalCepRef.emit($event)"
    (openDesktopCepModal)="openDesktopCepModal.emit($event)"
    [loadingTelevendas]="loadingTelevendas"
  ></vip-header-desktop-sub-menu>
  <vip-header-desktop-menu-principal
    [options]="options || []"
    [departamentos]="departamentos"
  ></vip-header-desktop-menu-principal>
</ng-container>
<ng-template #mobile>
  <vip-header-sub-menu-mobile
    [logo]="logo"
    [cliente]="cliente"
    [fallbackImageUrl]="fallbackImageUrl"
    [isTelevendas]="isTelevendas"
    [cdSelecionado]="cdSelecionado"
    [qtdItensCarrinho]="qtdItensCarrinho"
    [enderecos]="enderecos"
    [cdsEntrega]="cdsEntrega"
    [cdsRetirada]="cdsRetirada"
    [realizaEntrega]="realizaEntrega"
    [enderecoSelecionado]="enderecoSelecionado"
    [cdSelecionado]="cdSelecionado"
    [formaEntregaFromState]="formaEntregaFromState"
    [possuiLojaAutonoma]="possuiLojaAutonoma"
    [isLojaAutonoma]="isLojaAutonoma"
    [isFromResumoCompra]="isFromResumoCompra"
    [disableEntregaRetirada]="disableEntregaRetirada"
    [closeDropdownEntregaRetirada$]="closeDropdownEntregaRetirada$"
    [searchValue]="searchValue"
    [carrinho]="carrinho"
    [isDesktop]="isDesktop"
    [scrollingShadow]="scrollingShadow"
    (formaEntregaChange)="formaEntregaChange.emit($event)"
    (enderecoEntregaChange)="enderecoEntregaChange.emit($event)"
    (cdChange)="cdChange.emit($event)"
    (entregaChange)="entregaChange.emit($event)"
    (cadastrarEndereco)="cadastrarEndereco.emit($event)"
    (search)="
      $event.value && $event.value.length >= 3
        ? handleMobileSearchEvent($event)
        : $event.preventDefault()
    "
    (clickMenu)="clickMenu.emit()"
    (resumeClick)="resumeClick.emit($event)"
    (informarCepClick)="informarCepClick.emit()"
    (alterarEndereco)="alterarEndereco.emit($event)"
    (encerrarSessaoTelevandas)="encerrarSessaoTelevandas.emit()"
    [loadingTelevendas]="loadingTelevendas"
  ></vip-header-sub-menu-mobile>
</ng-template>
