<div
  *ngIf="!isLoading; else skeletonTemplate"
  class="input-container"
  [ngClass]="classes"
  #contentWrapper
>
  <ng-content></ng-content>
  <i
    *ngIf="icon && icon !== ''"
    [ngClass]="'icon icon-' + icon"
    (click)="handleIconClick($event)"
    data-cy="icon-field"
  ></i>
  <label *ngIf="label !== ''">{{ label }}</label>
  <div class="messages-container" [ngClass]="messageClasses">
    <div class="">
      <div class="message" *ngFor="let message of messageList">
        {{ message }}
      </div>
    </div>
  </div>
</div>
<ng-template #skeletonTemplate>
  <ng-container
    *vipSkeleton="
      isLoading;
      width: '100%';
      height: '45px';
      className: skeletonClasses
    "
  ></ng-container>
</ng-template>
