<div class="card-resumo-produtos-item-row" *ngIf="produtoCompra">
  <vip-image
    class="my-auto"
    [alt]="produtoCompra.descricao"
    [imageUrl]="produtoCompra.imagem || ''"
  ></vip-image>
  <span
    [attr.data-cy]="'produto-compra-descricao'"
    class="text-xs my-auto mx-2 flex-1"
  >
    {{ produtoCompra.descricao }}
  </span>
  <div class="my-auto text-xs whitespace-nowrap">
    <div class="text-right" *ngIf="!produtoCompra.brinde">
      <span [attr.data-cy]="'produto-compra-quantidade'">{{
        produtoCompra.quantidade
      }}</span>
      <span class="opacity-50"> x </span>
      <span [attr.data-cy]="'produto-compra'">{{
        produtoCompra.desconto ||
          produtoCompra.preco_venda ||
          produtoCompra.preco | currency: 'BRL'
      }}</span>
    </div>
    <div class="text-right" *ngIf="produtoCompra.brinde">
      <span>Brinde</span>
    </div>
    <div
      class="flex items-center justify-end mt-1 item-desconto"
      *ngIf="produtoCompra.desconto"
    >
      <span class="line-through opacity-50">
        {{ produtoCompra.preco_venda || produtoCompra.preco | currency: 'BRL' }}
      </span>
      <i
        class="ml-1 text-base font-bold icon-discount_price vip-color-success-main"
      ></i>
    </div>
  </div>
</div>
