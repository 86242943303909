import { ComponentRef, Injectable } from '@angular/core';
import { ComponentInjectorService } from '@vip/core';
import { MessageComponent } from '../components/message/message.component';
import { IMessage, PositionType } from './message.interface';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  lastTotalOpenMessages = 1;
  latestMessage: { data: IMessage; message: string } | null = null;
  readonly ANIMATION_TIME = 500;
  readonly errorMessageData: IMessage = {
    type: 'error',
    icon: 'icon-error_filled',
    position: 'top',
    title: 'Tem algo de errado!',
  };
  readonly successMessageData: IMessage = {
    icon: 'icon-check_circle_filled',
    position: 'top',
    type: 'success',
  };
  readonly infoMessageData: IMessage = {
    icon: 'icon-info_filled',
    position: 'top',
    type: 'info',
  };

  constructor(
    private componentInjector: ComponentInjectorService<MessageComponent>
  ) {}

  openMessage(
    messageData: IMessage,
    message: string,
    duration: number
  ): Promise<ComponentRef<MessageComponent>> {
    return new Promise<ComponentRef<MessageComponent>>((resolve) => {
      let totalOpenMessages = this.totalOpenMessages();

      const compareObj = { data: messageData, message };

      if (!this.latestMessage) {
        this.latestMessage = compareObj;
      } else if (
        JSON.stringify(this.latestMessage) === JSON.stringify(compareObj)
      ) {
        return;
      }

      const messageComponent =
        this.componentInjector.createComponentInApplication(
          MessageComponent,
          message
        );
      messageComponent.instance.icon = messageData.icon;
      messageComponent.instance.type = messageData.type;
      messageComponent.instance.position = messageData.position;
      messageComponent.instance.title = messageData.title;
      messageComponent.instance.subtitle = message || messageData.subtitle;

      if (this.lastTotalOpenMessages > totalOpenMessages) {
        totalOpenMessages = 0;
      }
      this.lastTotalOpenMessages = totalOpenMessages;
      messageComponent.instance.totalOpenMessages = totalOpenMessages;
      messageComponent.changeDetectorRef.detectChanges();

      setTimeout(() => {
        this.clearMessage(messageComponent);
      }, (duration + 1) * 1000);

      messageComponent.instance.messageClick.pipe(take(1)).subscribe(() => {
        this.clearMessage(messageComponent);
      });

      messageComponent.instance.closeClick.pipe(take(1)).subscribe(() => {
        this.clearMessage(messageComponent);
      });

      resolve(messageComponent);
    });
  }

  openErrorMessage(
    message: string,
    duration = 6.0,
    position: PositionType = 'top',
    title?: string
  ) {
    this.errorMessageData.position = position;
    this.openMessage(
      title ? { ...this.errorMessageData, title } : this.errorMessageData,
      message,
      duration
    );
  }

  openSuccessMessage(message: string, duration = 6.0, title?: string) {
    this.openMessage({ ...this.successMessageData, title }, message, duration);
  }

  openInfoMessage(
    message: string,
    duration = 6.0,
    title?: string
  ): Promise<ComponentRef<MessageComponent>> {
    return this.openMessage(
      { ...this.infoMessageData, title },
      message,
      duration
    );
  }

  clearMessage(messageComponent: ComponentRef<MessageComponent>) {
    if (messageComponent) {
      this.latestMessage = null;
      messageComponent.instance.isLeaveAnimation = true;
      setTimeout(() => {
        this.componentInjector.destroyComponentInApplication(messageComponent);
      }, this.ANIMATION_TIME);
    }
  }

  totalOpenMessages() {
    return document.body.querySelectorAll('vip-message').length;
  }
}
