import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromDesconto from './+state/desconto.reducer';
import { DescontoEffects } from './+state/desconto.effects';
import { DescontoFacade } from './+state/desconto.facade';
import { FormaPagamentoStateModule } from '@vip/state/forma-pagamento';

@NgModule({
  imports: [
    CommonModule,
    FormaPagamentoStateModule,
    StoreModule.forFeature(
      fromDesconto.DESCONTO_FEATURE_KEY,
      fromDesconto.reducer
    ),
    EffectsModule.forFeature([DescontoEffects]),
  ],
  providers: [DescontoFacade],
})
export class DescontoStateModule {}
