import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { ModalDirective } from '../modal/modal.diretive';

@Component({
  selector: 'vip-dialog',
  templateUrl: './dialog.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DialogComponent extends ModalDirective {
  @Input() title = '';

  @Input() subTitle = '';

  @Input() buttonConfirmText = '';

  @Input() buttonConfirmIcon = '';

  @Input() buttonCancelText = '';

  @Input() buttonCancelIcon = '';

  @Input() showCloseButton = true;

  @Output()
  dialogClick = new EventEmitter();

  @Output()
  closeClick = new EventEmitter();

  public handleDialogClick(action: boolean) {
    // ** Emit boolean true se click em Botão Confirm false se click em Botão Cancel
    this.dialogClick.emit(action);
  }
}
