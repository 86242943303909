import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddToListModule } from '@vip/views/add-to-list';
import { AddToListContainerComponent } from './add-to-list/add-to-list.component';
import { AddToListContainerService } from './add-to-list.service';
import { ListaStateModule } from '@vip/state/lista';
import { ListaItensStateModule } from '@vip/state/lista-itens';

import { FiltroStateModule } from '@vip/state/filtro';
import { AnalyticsStateModule } from '@vip/state/analytics';

@NgModule({
  imports: [
    AddToListModule,
    CommonModule,
    ListaStateModule,
    ListaItensStateModule,
    AnalyticsStateModule,
    FiltroStateModule,
  ],
  declarations: [AddToListContainerComponent],
  exports: [AddToListContainerComponent],
  providers: [AddToListContainerService],
})
export class AddToListContainerModule {}
