import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as ClassificacaoMercadologicaActions from './classificacao-mercadologica.actions';
import { classificacaoMercadologicaSelectors } from './classificacao-mercadologica.selectors';

@Injectable()
export class ClassificacaoMercadologicaFacade {
  arvore$ = this.store.pipe(
    select(classificacaoMercadologicaSelectors.loadArvore())
  );
  loaded$ = this.store.pipe(
    select(classificacaoMercadologicaSelectors.isLoaded())
  );
  loading$ = this.store.pipe(
    select(classificacaoMercadologicaSelectors.isLoading())
  );
  error$ = this.store.pipe(
    select(classificacaoMercadologicaSelectors.getError())
  );

  constructor(private store: Store) {}

  loadArvore() {
    this.store.dispatch(ClassificacaoMercadologicaActions.loadArvore());
  }
}
