<vip-base-component-skeleton [quantity]="1">
  <ng-template #skeletonContent>
    <div class="flex flex-col items-center">
      <div
        class="shadow rounded w-[320px] mb-2 py-4"
        data-cy="vip-detalhes-cobranca-skeleton"
      >
        <div class="flex items-center">
          <div
            *vipSkeleton="
              true;
              height: '24px';
              width: '24px';
              className: ['mr-2', 'ml-4']
            "
          ></div>
          <div *vipSkeleton="true; height: '21px'; width: '176px'"></div>
        </div>
        <hr class="vip-divider my-4" />
        <div class="flex justify-between px-4 pb-4">
          <div *vipSkeleton="true; height: '18px'; width: '56px'"></div>
          <div *vipSkeleton="true; height: '18px'; width: '70px'"></div>
        </div>
        <div class="flex justify-between px-4 pb-4">
          <div *vipSkeleton="true; height: '18px'; width: '96px'"></div>
          <div *vipSkeleton="true; height: '18px'; width: '42px'"></div>
        </div>
        <div class="flex justify-between px-4 pb-4">
          <div *vipSkeleton="true; height: '18px'; width: '59px'"></div>
          <div *vipSkeleton="true; height: '18px'; width: '70px'"></div>
        </div>
        <hr class="vip-divider mx-4 mb-4" />
        <div class="flex justify-between px-4 mb-4">
          <div *vipSkeleton="true; height: '18px'; width: '35px'"></div>
          <div *vipSkeleton="true; height: '18px'; width: '73px'"></div>
        </div>
        <div
          *vipSkeleton="
            true;
            height: '50px';
            width: '280px';
            radius: '5px';
            className: ['ml-4']
          "
        ></div>
      </div>
      <div
        *vipSkeleton="true; height: '50px'; width: '280px'; radius: '5px'"
      ></div>
    </div>
  </ng-template>
</vip-base-component-skeleton>
