import { ImageModule } from '@vip/ui/image';
import { SelectableCardModule } from '@vip/ui/selectable-card';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartaoCreditoDesktopComponent } from './cartao-credito-desktop/cartao-credito-desktop.component';
import { FieldModule } from '@vip/ui/field';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectModule } from '@vip/ui/select';
import { ButtonModule } from '@vip/ui/button';

@NgModule({
  imports: [
    CommonModule,
    SelectableCardModule,
    ImageModule,
    FieldModule,
    FormsModule,
    ReactiveFormsModule,
    SelectModule,
    ButtonModule,
  ],
  declarations: [CartaoCreditoDesktopComponent],
  exports: [CartaoCreditoDesktopComponent],
})
export class CartaoCreditoDesktopModule {}
