import { createSelector } from '@ngrx/store';
import { SelectorUtil } from '@vip/state/utils';
import {
  FORMA_PAGAMENTOS_FEATURE_KEY,
  formaPagamentosAdapter,
  FormaPagamentosState,
} from './forma-pagamento.reducer';

const { selectEntities, selectAll } = formaPagamentosAdapter.getSelectors();

class FormaPagamentosSelectors extends SelectorUtil<FormaPagamentosState> {
  constructor() {
    super(FORMA_PAGAMENTOS_FEATURE_KEY);
  }

  getAllFormaPagamentos = () =>
    createSelector(this.createFeatureSelector, (state: FormaPagamentosState) =>
      selectEntities(state)
    );

  getFormaPagamentoSelecionadoId = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FormaPagamentosState) => state.formaPagamentoSelecionadaId
    );

  getFormaPagamentos = () =>
    createSelector(this.createFeatureSelector, (state: FormaPagamentosState) =>
      selectAll(state)
    );

  getRemoverPrePagos = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FormaPagamentosState) => state.removerPrepagos
    );

  getValorTroco = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FormaPagamentosState) => state.valorTroco || 0
    );

  getFormaPagamentoSelecionada = () =>
    createSelector(
      this.getAllFormaPagamentos(),
      this.getFormaPagamentoSelecionadoId(),
      (entities, selectedId) => selectedId && entities[selectedId]
    );

  getPermiteSelecaoManual = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FormaPagamentosState) => state.permiteSelecaoManual
    );

  getBandeiraNaoAtendida = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FormaPagamentosState) => !!state.bandeiraNaoAtendida
    );

  getPagamentosOnline = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FormaPagamentosState) => state.pagamentosOnline
    );

  getPagamentosEntrega = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FormaPagamentosState) => state.pagamentoEntrega
    );

  getPagamentos = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FormaPagamentosState) => state.pagamentos
    );

  getIsLoaded = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FormaPagamentosState) =>
        state.status === 'success' || state.status === 'error'
    );
}

export const formaPagamentosSelectors = new FormaPagamentosSelectors();
