import { Pipe, PipeTransform } from '@angular/core';
import { isPast } from 'date-fns';

@Pipe({ name: 'expiredDueDate', pure: false })
export class ExpiredDueDatePipe implements PipeTransform {
  transform(validade?: Date): boolean {
    if (!validade) return false;
    const result = isPast(validade);
    return result;
  }
}
