import { ModalService } from '@vip/ui/modal';
import { Injectable } from '@angular/core';
import { NavigationBehaviorOptions, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { AtualizarCarrinhoItemRequest } from '@vip/api';
import { CarrinhoActions, CarrinhoFacade } from '@vip/state/carrinho';
import { CarrinhoItensActions } from '@vip/state/carrinho-itens';
import {
  CentroDistribuicaoActions,
  CentroDistribuicaoFacade,
} from '@vip/state/centro-distribuicao';
import { ClienteActions, ClienteFacade } from '@vip/state/cliente';
import { CompraActions } from '@vip/state/compra';
import { ListaItensActions } from '@vip/state/lista-itens';
import { IngredientesReceitaActions } from '@vip/state/ingredientes-receita';
import { ParametrosFacade } from '@vip/state/parametros';
import { mergeTakeOne, SelecaoCdSelectors, selectUrl } from '@vip/state/utils';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  take,
  tap,
} from 'rxjs/operators';
import * as SelecaoCdActions from './selecao-cd.actions';
import { EntregaRetiradaUtilsService, LayoutUtilsService } from '@vip/core';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

const URL_ENTREGA_RETIRADA = '/entrega-retirada';
type AtualizarItemFilterType = {
  type: string;
  qtdItens: number;
  isLogged: boolean;
  permitirCarrinhoAnonimo: boolean;
  atualizouNaoLogado: boolean | undefined;
};

type HandleSelecionarCDParamsType = {
  routerOptions: {
    url: string;
    extras?: NavigationBehaviorOptions;
  };
  action: ActionCreator<string, any>;
  propsAction: { [key: string]: any };
};
@Injectable()
export class SelecaoCdEffects {
  atualizouNaoLogado?: boolean;

  handleSelecionarCdPorAddItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CarrinhoItensActions.setItemCarrinho,
        CarrinhoItensActions.setListaItensCarrinho
      ),
      mergeTakeOne(
        this.carrinhoFacade.qtdItensCarrinho$,
        this.cdFacade.filialECdSelecionado$,
        this.clienteFacade.isLogged$,
        this.parametrosFacade.permitirCarrinhoAnonimo$,
        this.store.pipe(
          select(SelecaoCdSelectors.isPermitirAtualizarItemCarrinho)
        )
      ),
      map(
        ([
          action,
          qtdItens,
          [filial, cd],
          isLogged,
          permitirCarrinhoAnonimo,
          permitirAtualizarItemCarrinho,
        ]) => ({
          ...action,
          qtdItens,
          filial,
          cd,
          isLogged,
          permitirCarrinhoAnonimo,
          permitirAtualizarItemCarrinho,
        })
      ),
      filter(
        ({ permitirAtualizarItemCarrinho }) => !permitirAtualizarItemCarrinho
      ),
      fetch({
        run: ({
          payload,
          qtdItens,
          cd,
          isLogged,
          permitirCarrinhoAnonimo,
          exibirMensagemAdicionado,
        }) => {
          this.modalService.clearModal().then(() => {
            let callAction = null;
            if (
              SelecaoCdEffects.deveSelecionarCD(
                qtdItens,
                cd.loja_autonoma,
                isLogged,
                permitirCarrinhoAnonimo
              )
            ) {
              callAction = this.handleSelecionarCD({
                routerOptions: {
                  url: '/entrega-retirada',
                  extras: {
                    state: {
                      data: {
                        headerDesktopIsVisible: true,
                      },
                    },
                  },
                },
                action: SelecaoCdActions.setItemParaAtualizarCarrinho,
                propsAction: {
                  item: payload,
                  exibirMensagemAdicionado: !!exibirMensagemAdicionado,
                },
              });
            } else {
              callAction = SelecaoCdActions.setItemParaAtualizarCarrinho({
                item: payload,
                exibirMensagemAdicionado: !!exibirMensagemAdicionado,
              });
            }
            this.store.dispatch(callAction);
          });
        },
      })
    )
  );

  atualizarPosSelecionarCd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CentroDistribuicaoActions.confirmarCentrosDistribuicao,
        CentroDistribuicaoActions.migrarCentroDistribuicaoSuccess,
        ClienteActions.loginSuccess
      ),
      mergeTakeOne(
        this.store.pipe(
          select(SelecaoCdSelectors.getItemParaAtualizarCarrinho)
        ),
        this.store.pipe(select(SelecaoCdSelectors.getcompraIdArmazenado))
      ),
      map(([action, itemParaAtualizarCarrinho, compraIdArmazenado]) => ({
        ...action,
        itemParaAtualizarCarrinho,
        compraIdArmazenado,
      })),
      filter(
        ({ itemParaAtualizarCarrinho, compraIdArmazenado }) =>
          itemParaAtualizarCarrinho.item !== null && !compraIdArmazenado
      ),
      fetch({
        run: ({ itemParaAtualizarCarrinho, type }) => {
          const { item, exibirMensagemAdicionado } = itemParaAtualizarCarrinho;

          if (type === ClienteActions.loginSuccess.type) {
            this.store.dispatch(
              SelecaoCdActions.permitirAtualizarCarrinho({
                permitirAtualizarItemCarrinho: true,
              })
            );
          }

          if (Array.isArray(item)) {
            return CarrinhoItensActions.setListaItensCarrinho({
              payload: item,
              exibirMensagemAdicionado,
            });
          }
          return CarrinhoItensActions.setItemCarrinho({
            payload: item as AtualizarCarrinhoItemRequest,
            exibirMensagemAdicionado,
          });
        },
      })
    )
  );

  atualizarCarrinhoAnonimo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SelecaoCdActions.setItemParaAtualizarCarrinho),
      mergeTakeOne(
        this.store.pipe(
          select(SelecaoCdSelectors.getItemParaAtualizarCarrinho)
        ),
        this.store.pipe(select(SelecaoCdSelectors.getcompraIdArmazenado)),
        this.carrinhoFacade.qtdItensCarrinho$,
        this.clienteFacade.isLogged$,
        this.parametrosFacade.permitirCarrinhoAnonimo$,
        this.cdFacade.filialECdSelecionado$
      ),
      map(
        ([
          action,
          itemParaAtualizarCarrinho,
          compraIdArmazenado,
          qtdItens,
          isLogged,
          permitirCarrinhoAnonimo,
          [, cd],
        ]) => ({
          ...action,
          itemParaAtualizarCarrinho,
          compraIdArmazenado,
          qtdItens,
          isLogged,
          permitirCarrinhoAnonimo,
          cd,
        })
      ),
      filter(
        ({
          compraIdArmazenado,
          qtdItens,
          isLogged,
          permitirCarrinhoAnonimo,
          itemParaAtualizarCarrinho,
          cd,
        }) =>
          (qtdItens === 0 &&
            !isLogged &&
            permitirCarrinhoAnonimo &&
            itemParaAtualizarCarrinho.item !== null &&
            !compraIdArmazenado) ||
          (cd.loja_autonoma && itemParaAtualizarCarrinho.item !== null)
      ),
      fetch({
        run: ({ itemParaAtualizarCarrinho }) => {
          const { item, exibirMensagemAdicionado } = itemParaAtualizarCarrinho;

          if (Array.isArray(item)) {
            return CarrinhoItensActions.setListaItensCarrinho({
              payload: item,
              exibirMensagemAdicionado,
            });
          }
          return CarrinhoItensActions.setItemCarrinho({
            payload: item as AtualizarCarrinhoItemRequest,
            exibirMensagemAdicionado,
          });
        },
      })
    )
  );

  limparAposAtualizar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CarrinhoItensActions.setItemCarrinhoSuccess,
        CarrinhoItensActions.setListaItensCarrinhoSuccess
      ),
      fetch({
        run: () =>
          SelecaoCdActions.setItemParaAtualizarCarrinho({
            item: null,
            exibirMensagemAdicionado: false,
          }),
      })
    )
  );

  permitirAtualizarCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrinhoItensActions.getItensCarrinhoSuccess),
      mergeTakeOne(this.store.pipe(select(selectUrl))),
      map(([action, url]) => ({
        ...action,
        url,
      })),
      filter((action) => !!action.itens && action.url !== URL_ENTREGA_RETIRADA),
      fetch({
        run: (action) => {
          return SelecaoCdActions.permitirAtualizarCarrinho({
            permitirAtualizarItemCarrinho: action.itens.length > 0,
          });
        },
      })
    )
  );

  limparPermitirAtualizarCarrinhoPorRemocao$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CarrinhoItensActions.resetItensCarrinho,
        CarrinhoActions.removeCarrinho
      ),
      mergeTakeOne(this.store.pipe(select(selectUrl))),
      map(([action, url]) => ({
        ...action,
        url,
      })),
      filter(({ url }) => url !== URL_ENTREGA_RETIRADA),
      fetch({
        run: () => {
          return SelecaoCdActions.permitirAtualizarCarrinho({
            permitirAtualizarItemCarrinho: false,
          });
        },
      })
    )
  );

  limparPermitirAtualizarCarrinhoPorBuscaCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrinhoActions.getCarrinhoSuccess),
      mergeTakeOne(this.store.pipe(select(selectUrl))),
      map(([action, url]) => ({
        ...action,
        url,
      })),
      filter(({ carrinho, url }) => !!carrinho && url !== URL_ENTREGA_RETIRADA),
      fetch({
        run: ({ carrinho }) => {
          return SelecaoCdActions.permitirAtualizarCarrinho({
            permitirAtualizarItemCarrinho:
              !!carrinho.quantidade && carrinho.quantidade !== 0,
          });
        },
      })
    )
  );

  limparPermitirAtualizarCarrinhoPorLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClienteActions.loginSuccess),
      fetch({
        run: () => {
          return SelecaoCdActions.permitirAtualizarCarrinho({
            permitirAtualizarItemCarrinho: false,
          });
        },
      })
    )
  );

  handleRepetirCompra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraActions.repetirCompra),
      mergeTakeOne(
        this.carrinhoFacade.qtdItensCarrinho$,
        this.cdFacade.filialECdSelecionado$,
        this.clienteFacade.isLogged$,
        this.parametrosFacade.permitirCarrinhoAnonimo$,
        this.store.pipe(
          select(SelecaoCdSelectors.isPermitirAtualizarItemCarrinho)
        )
      ),
      map(
        ([
          action,
          qtdItens,
          [filial, cd],
          isLogged,
          permitirCarrinhoAnonimo,
          permitirAtualizarItemCarrinho,
        ]) => ({
          ...action,
          qtdItens,
          filial,
          cd,
          isLogged,
          permitirCarrinhoAnonimo,
          permitirAtualizarItemCarrinho,
        })
      ),
      filter(
        ({ permitirAtualizarItemCarrinho }) => !permitirAtualizarItemCarrinho
      ),
      fetch({
        run: ({
          compraId: compraIdArmazenado,
          qtdItens,
          cd,
          isLogged,
          permitirCarrinhoAnonimo,
        }) => {
          if (
            SelecaoCdEffects.deveSelecionarCD(
              qtdItens,
              cd.loja_autonoma,
              isLogged,
              permitirCarrinhoAnonimo
            )
          ) {
            return this.handleSelecionarCD({
              routerOptions: {
                url: '/entrega-retirada',
              },
              action: SelecaoCdActions.setCompraIdArmazenado,
              propsAction: {
                compraIdArmazenado,
              },
            });
          }
          return SelecaoCdActions.setCompraIdArmazenado({ compraIdArmazenado });
        },
      })
    )
  );

  repetirCompra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CentroDistribuicaoActions.confirmarCentrosDistribuicao,
        CentroDistribuicaoActions.migrarCentroDistribuicaoSuccess
      ),
      mergeTakeOne(
        this.store.pipe(select(SelecaoCdSelectors.getcompraIdArmazenado))
      ),
      map(([action, compraIdArmazenado]) => ({
        ...action,
        compraIdArmazenado,
      })),
      filter(({ compraIdArmazenado }) => !!compraIdArmazenado),
      fetch({
        run: ({ compraIdArmazenado }) => {
          return CompraActions.repetirCompra({
            compraId: compraIdArmazenado as number,
          });
        },
      })
    )
  );

  repetirCompraLojaAutonoma$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SelecaoCdActions.setCompraIdArmazenado),
      mergeTakeOne(this.cdFacade.filialECdSelecionado$),
      map(([action, [, cd]]) => ({
        ...action,
        cd,
      })),
      distinctUntilChanged(
        (current, previous) =>
          current.compraIdArmazenado === previous.compraIdArmazenado
      ),
      filter(
        ({ compraIdArmazenado, cd }) => !!compraIdArmazenado && cd.loja_autonoma
      ),
      fetch({
        run: ({ compraIdArmazenado }) => {
          return CompraActions.repetirCompra({
            compraId: compraIdArmazenado as number,
          });
        },
      })
    )
  );

  limparCompraId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraActions.repetirCompraSuccess),
      fetch({
        run: () =>
          SelecaoCdActions.setCompraIdArmazenado({ compraIdArmazenado: null }),
      })
    )
  );

  handleAddItensListaCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListaItensActions.addItensListaCarrinho),
      mergeTakeOne(
        this.carrinhoFacade.qtdItensCarrinho$,
        this.cdFacade.filialECdSelecionado$,
        this.clienteFacade.isLogged$,
        this.parametrosFacade.permitirCarrinhoAnonimo$,
        this.store.pipe(
          select(SelecaoCdSelectors.isPermitirAtualizarItemCarrinho)
        )
      ),
      map(
        ([
          action,
          qtdItens,
          [filial, cd],
          isLogged,
          permitirCarrinhoAnonimo,
          permitirAtualizarItemCarrinho,
        ]) => ({
          ...action,
          qtdItens,
          filial,
          cd,
          isLogged,
          permitirCarrinhoAnonimo,
          permitirAtualizarItemCarrinho,
        })
      ),
      fetch({
        run: ({
          listaId: listaIdArmazenado,
          ignoreSelecionarCDValidation,
          qtdItens,
          cd,
          isLogged,
          permitirCarrinhoAnonimo,
        }) => {
          if (
            !ignoreSelecionarCDValidation &&
            SelecaoCdEffects.deveSelecionarCD(
              qtdItens,
              cd.loja_autonoma,
              isLogged,
              permitirCarrinhoAnonimo
            )
          ) {
            return this.handleSelecionarCD({
              routerOptions: {
                url: '/entrega-retirada',
              },
              action: SelecaoCdActions.setListaIdArmazenado,
              propsAction: {
                listaIdArmazenado,
              },
            });
          }
          return SelecaoCdActions.setListaIdArmazenado({ listaIdArmazenado });
        },
      })
    )
  );

  addItensListaLojaAutonoma$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SelecaoCdActions.setListaIdArmazenado),
      mergeTakeOne(this.cdFacade.filialECdSelecionado$),
      map(([action, [filial, cd]]) => ({
        ...action,
        filial,
        cd,
      })),
      distinctUntilChanged(
        (current, previous) =>
          current.listaIdArmazenado === previous.listaIdArmazenado
      ),
      filter(
        ({ listaIdArmazenado, cd }) => !!listaIdArmazenado && cd.loja_autonoma
      ),
      fetch({
        run: ({ listaIdArmazenado }) => {
          return ListaItensActions.addItensListaCarrinho({
            listaId: listaIdArmazenado as number,
          });
        },
      })
    )
  );

  limparListaId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListaItensActions.addItensListaCarrinhoSuccess),
      fetch({
        run: () =>
          SelecaoCdActions.setListaIdArmazenado({ listaIdArmazenado: null }),
      })
    )
  );

  handleAddIngredientesCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IngredientesReceitaActions.addIngredientesCarrinho),
      mergeTakeOne(
        this.carrinhoFacade.qtdItensCarrinho$,
        this.cdFacade.filialECdSelecionado$,
        this.clienteFacade.isLogged$,
        this.parametrosFacade.permitirCarrinhoAnonimo$
      ),
      map(
        ([
          action,
          qtdItens,
          [filial, cd],
          isLogged,
          permitirCarrinhoAnonimo,
        ]) => ({
          ...action,
          qtdItens,
          filial,
          cd,
          isLogged,
          permitirCarrinhoAnonimo,
        })
      ),
      fetch({
        run: ({
          listaId: listaIngredientesIdArmazenado,
          ignoreSelecionarCDValidation,
          qtdItens,
          cd,
          isLogged,
          permitirCarrinhoAnonimo,
        }) => {
          if (
            !ignoreSelecionarCDValidation &&
            SelecaoCdEffects.deveSelecionarCD(
              qtdItens,
              cd.loja_autonoma,
              isLogged,
              permitirCarrinhoAnonimo
            )
          ) {
            return this.handleSelecionarCD({
              routerOptions: {
                url: '/entrega-retirada',
              },
              action: SelecaoCdActions.setListaIngredientesIdArmazenado,
              propsAction: {
                listaIngredientesIdArmazenado,
              },
            });
          }
          return SelecaoCdActions.setListaIngredientesIdArmazenado({
            listaIngredientesIdArmazenado,
          });
        },
      })
    )
  );

  addIngredientesCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CentroDistribuicaoActions.confirmarCentrosDistribuicao,
        CentroDistribuicaoActions.migrarCentroDistribuicaoSuccess
      ),
      mergeTakeOne(
        this.store.pipe(
          select(SelecaoCdSelectors.getlistaIngredientesIdArmazenado)
        )
      ),
      map(([action, listaIngredientesIdArmazenado]) => ({
        ...action,
        listaIngredientesIdArmazenado,
      })),
      filter(
        ({ listaIngredientesIdArmazenado }) => !!listaIngredientesIdArmazenado
      ),
      fetch({
        run: ({ listaIngredientesIdArmazenado }) => {
          return IngredientesReceitaActions.addIngredientesCarrinho({
            listaId: listaIngredientesIdArmazenado as number,
            ignoreSelecionarCDValidation: true,
          });
        },
      })
    )
  );

  addItensListasCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CentroDistribuicaoActions.confirmarCentrosDistribuicao,
        CentroDistribuicaoActions.migrarCentroDistribuicaoSuccess
      ),
      mergeTakeOne(
        this.store.pipe(select(SelecaoCdSelectors.getlistaIdArmazenado))
      ),
      map(([action, listaIdArmazenado]) => ({
        ...action,
        listaIdArmazenado,
      })),
      filter(({ listaIdArmazenado }) => !!listaIdArmazenado),
      fetch({
        run: ({ listaIdArmazenado }) => {
          return ListaItensActions.addItensListaCarrinho({
            listaId: listaIdArmazenado as number,
            ignoreSelecionarCDValidation: true,
          });
        },
      })
    )
  );

  addIngredientesCarrinhoAnonimo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SelecaoCdActions.setListaIngredientesIdArmazenado),
      mergeTakeOne(
        this.clienteFacade.isLogged$,
        this.parametrosFacade.permitirCarrinhoAnonimo$,
        this.store.pipe(
          select(SelecaoCdSelectors.isPermitirAtualizarItemCarrinho)
        ),
        this.cdFacade.filialECdSelecionado$
      ),
      map(
        ([
          action,
          isLogged,
          permitirCarrinhoAnonimo,
          isPermitirAtualizarItemCarrinho,
          [, cd],
        ]) => ({
          ...action,
          isLogged,
          permitirCarrinhoAnonimo,
          isPermitirAtualizarItemCarrinho,
          cd,
        })
      ),
      distinctUntilChanged(
        (current, previous) =>
          current.listaIngredientesIdArmazenado ===
          previous.listaIngredientesIdArmazenado
      ),
      filter(
        ({
          isLogged,
          permitirCarrinhoAnonimo,
          isPermitirAtualizarItemCarrinho,
          cd,
          listaIngredientesIdArmazenado,
        }) =>
          (!isLogged &&
            permitirCarrinhoAnonimo &&
            !!isPermitirAtualizarItemCarrinho &&
            !this.atualizouNaoLogado) ||
          (cd.loja_autonoma && !!listaIngredientesIdArmazenado)
      ),
      fetch({
        run: ({ listaIngredientesIdArmazenado }) => {
          this.atualizouNaoLogado = true;
          return IngredientesReceitaActions.addIngredientesCarrinho({
            listaId: listaIngredientesIdArmazenado as number,
          });
        },
      })
    )
  );

  limparListaIngredientesId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IngredientesReceitaActions.addIngredientesCarrinhoSuccess),
      fetch({
        run: () => {
          this.atualizouNaoLogado = undefined;
          return SelecaoCdActions.setListaIngredientesIdArmazenado({
            listaIngredientesIdArmazenado: null,
          });
        },
      })
    )
  );

  redirecionarLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SelecaoCdActions.redirecionarParaLogin),
      delay(50),
      tap(() => {
        if (this.layoutUtilsService.isDesktop()) {
          this.entregaRetiradaUtilsService.closeModalEntregaRetirada$
            .pipe(take(1))
            .subscribe(() => this.router.navigateByUrl('/login'));
        } else {
          this.router.navigateByUrl('/login');
        }
      }),
      fetch({
        run: () => {
          return SelecaoCdActions.permitirAtualizarCarrinho({
            permitirAtualizarItemCarrinho: false,
          });
        },
      })
    )
  );

  usuarioNaoAutorizado$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrinhoItensActions.usuarioNaoAutorizado),
      fetch({
        run: () => {
          return SelecaoCdActions.redirecionarParaLogin();
        },
      })
    )
  );

  static deveSelecionarCD(
    qtdItens: number,
    lojaAutonoma: boolean,
    isLogged: boolean,
    permitirCarrinhoAnonimo: boolean
  ): boolean {
    return (
      (!permitirCarrinhoAnonimo || isLogged) && !lojaAutonoma && qtdItens === 0
    );
  }

  static atualizarItemFilter({
    type,
    qtdItens,
    isLogged,
    permitirCarrinhoAnonimo,
    atualizouNaoLogado,
  }: AtualizarItemFilterType) {
    const loggedOutCondition =
      type === SelecaoCdActions.setItemParaAtualizarCarrinho.type &&
      qtdItens === 0 &&
      !isLogged &&
      permitirCarrinhoAnonimo;

    if (atualizouNaoLogado) {
      atualizouNaoLogado = undefined;
      return false;
    }
    atualizouNaoLogado = loggedOutCondition;
    return loggedOutCondition;
  }

  private handleSelecionarCD(
    params: HandleSelecionarCDParamsType
  ): { [keys: string]: [value: any] } & TypedAction<any> {
    const { routerOptions, action, propsAction } = params;
    const { url, extras } = routerOptions;
    if (this.layoutUtilsService.isDesktop()) {
      this.entregaRetiradaUtilsService.openModalEntregaRetirada();
      return action({ ...propsAction });
    }
    this.router.navigateByUrl(url, extras);
    return action({ ...propsAction });
  }

  constructor(
    private readonly actions$: Actions,
    private store: Store,
    private cdFacade: CentroDistribuicaoFacade,
    private carrinhoFacade: CarrinhoFacade,
    private clienteFacade: ClienteFacade,
    private parametrosFacade: ParametrosFacade,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private entregaRetiradaUtilsService: EntregaRetiradaUtilsService,
    private modalService: ModalService<unknown>
  ) {}
}
