import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IApiPaginator, LayoutUtilsService } from '@vip/core';

@Component({
  selector: 'vip-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnChanges {
  pages: number[] = [];

  @Input()
  paginator!: IApiPaginator;

  @Output()
  pageClick: EventEmitter<number> = new EventEmitter<number>();

  private readonly totalVisible = this.layoutUtilsService.isDesktop() ? 10 : 5;
  private readonly startPage = 1;

  get page(): number {
    return this.paginator.page;
  }

  get totalPages(): number {
    return this.paginator.total_pages;
  }

  constructor(private layoutUtilsService: LayoutUtilsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['paginator'].currentValue) {
      this.pages = this.generatePaginator();
    }
  }

  handlePageClick(pageNumber: number): void {
    this.pageClick.emit(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  private generatePaginator(): number[] {
    const ELLIPSIS = 0;
    if (
      this.totalPages <= 0 ||
      isNaN(this.totalPages) ||
      this.totalPages > Number.MAX_SAFE_INTEGER
    )
      return [];

    if (this.totalPages <= this.totalVisible) {
      return this.createRange(this.totalPages, this.startPage);
    }

    const even = this.totalVisible % 2 === 0;
    const middle = even
      ? this.totalVisible / 2
      : Math.floor(this.totalVisible / 2);
    const left = even ? middle : middle + 1;
    const right = this.totalPages - middle;

    if (left - this.page >= 0) {
      return [
        ...this.createRange(Math.max(1, this.totalVisible - 1), this.startPage),
        ELLIPSIS,
        this.totalPages,
      ];
    } else if (this.page - right >= (even ? 1 : 0)) {
      const rangeLength = this.totalVisible - 1;
      const rangeStart = this.totalPages - rangeLength + this.startPage;
      return [
        this.startPage,
        ELLIPSIS,
        ...this.createRange(rangeLength, rangeStart),
      ];
    } else {
      const rangeLength = Math.max(1, this.totalVisible - 3);
      const rangeStart =
        rangeLength === 1
          ? this.page
          : this.page - Math.ceil(rangeLength / 2) + this.startPage;
      return [
        this.startPage,
        ELLIPSIS,
        ...this.createRange(rangeLength, rangeStart),
        ELLIPSIS,
        this.totalPages,
      ];
    }
  }

  private createRange(length: number, start = 0): number[] {
    return Array.from({ length }, (v, k) => start + k);
  }
}
