import { Route } from '@angular/router';
import { ManutencaoGuard } from '@vip/container/manutencao';

export const SiteRootRoutes: Route[] = [
  {
    path: 'manutencao',
    canActivate: [ManutencaoGuard],
    loadChildren: async () =>
      (await import('@vip/container/manutencao')).ManutencaoContainerModule,
  },
  {
    path: 'sem-conexao',
    loadChildren: async () =>
      (await import('@vip/container/sem-conexao')).SemConexaoContainerModule,
  },
  {
    path: 'televendas/usuario',
    loadChildren: async () =>
      (await import('@vip/container/televendas')).TelevendasContainerModule,
  },
  { path: '**', redirectTo: 'pagina-nao-encontrada' },
];
