import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IPerguntaSobreCompra, PerguntaTipo } from '@vip/core';
import { PerguntasService } from '@vip/views/perguntas';

@Component({
  selector: 'vip-modal-perguntas',
  templateUrl: './modal-perguntas.component.html',
  styleUrls: ['./modal-perguntas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalPerguntasComponent {
  readonly PERGUNTA_TIPO = PerguntaTipo;
  private _perguntas: IPerguntaSobreCompra[] = [];
  showButton = true;
  formGroup?: UntypedFormGroup;
  @Input() exibeNaCompra = true;
  @Input()
  temPerguntaObrigatoria = false;
  @Input()
  set perguntas(value: IPerguntaSobreCompra[]) {
    this.formGroup = this.perguntasService.generateFormGroupByPerguntas(value);
    this._perguntas = value;
  }

  get perguntas(): IPerguntaSobreCompra[] {
    return this._perguntas;
  }

  @Output()
  salvarPerguntas = new EventEmitter();
  @Output()
  pularPerguntas = new EventEmitter();

  constructor(private perguntasService: PerguntasService) {}

  responderPerguntas(): void {
    this.formGroup?.markAllAsTouched();

    if (this.exibeNaCompra && this.formGroup?.valid) {
      this.showButton = false;
      this.salvarPerguntas.emit(this.formGroup.value);
    } else if (!this.exibeNaCompra && this.formGroup) {
      Object.keys(this.formGroup.value).some(
        (key) => this.formGroup?.value[key]
      )
        ? this.salvarPerguntas.emit(this.formGroup.value)
        : this.formGroup.setErrors({ empty: true });
    }
  }
}
