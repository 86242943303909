import { DialogService } from '@vip/ui/modal';
import { Injectable } from '@angular/core';
import { fetch } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  distinctUntilChanged,
  filter,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { BucketsFacade, BucketsService } from '@vip/state/buckets';
import { CarrinhoService } from '@vip/api';
import { mergeTakeOne } from '@vip/state/utils';
import {
  CentroDistribuicaoActions,
  CentroDistribuicaoFacade,
} from '@vip/state/centro-distribuicao';
import { ClienteActions, ClienteFacade } from '@vip/state/cliente';
import { MessageService } from '@vip/ui/message';
import { ParametrosFacade } from '@vip/state/parametros';
import * as CarrinhoActions from './carrinho.actions';
import { ManterCarrinhoService } from '../services/manter-carrinho.service';
import { carrinhoSelectors } from './carrinho.selectors';
import { CarrinhoFacade } from './carrinho.facade';
import { Router } from '@angular/router';
import { IProduto, IProdutoCarrinho } from '@vip/core';

@Injectable()
export class CarrinhoEffects {
  getCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CarrinhoActions.getAllCarrinho,
        CarrinhoActions.removeCarrinhoSuccess
      ),
      mergeTakeOne(
        this.cdFacade.filialECdSelecionado$,
        this.parametrosFacade.permitirCarrinhoAnonimo$,
        this.clienteFacade.isLogged$,
        this.bucketsFacade.bucketProduto$
      ),
      map(
        ([
          action,
          [filial, cdSelecionado],
          permitirCarrinhoAnonimo,
          isLogged,
          bucket,
        ]) => {
          return {
            ...action,
            filial,
            cdSelecionado,
            permitirCarrinhoAnonimo,
            isLogged,
            bucket,
          };
        }
      ),
      fetch({
        run: ({
          isLogged,
          permitirCarrinhoAnonimo,
          filial,
          cdSelecionado,
          bucket,
        }) => {
          if (isLogged || permitirCarrinhoAnonimo) {
            return this.carrinhoService
              .getCarrinho(filial.id, cdSelecionado.id)
              .pipe(
                map((carrinho) => {
                  const itens =
                    carrinho.data?.itens?.map((item: IProdutoCarrinho) => {
                      if (item.combo) {
                        item.combo.produtos = item.combo.produtos.map(
                          (response) => {
                            return {
                              ...response,
                              imagem: response.imagem
                                ? `${bucket}/${response.imagem}`
                                : response.imagem,
                            };
                          }
                        );
                      }
                      return this.bucketsService.addBucketProduto(item, bucket);
                    }) || [];
                  return CarrinhoActions.getCarrinhoSuccess({
                    carrinho: { ...carrinho.data, itens },
                  });
                })
              );
          } else {
            return CarrinhoActions.resetCarrinho();
          }
        },
        onError: (action, error) =>
          CarrinhoActions.getCarrinhoFailure({ error }),
      })
    )
  );

  getCarrinhoEnviaDadosGa4$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrinhoActions.getCarrinho),
      mergeTakeOne(
        this.cdFacade.filialECdSelecionado$,
        this.parametrosFacade.permitirCarrinhoAnonimo$,
        this.clienteFacade.isLogged$,
        this.bucketsFacade.bucketProduto$
      ),
      map(
        ([
          action,
          [filial, cdSelecionado],
          permitirCarrinhoAnonimo,
          isLogged,
          bucket,
        ]) => {
          return {
            ...action,
            filial,
            cdSelecionado,
            permitirCarrinhoAnonimo,
            isLogged,
            bucket,
          };
        }
      ),
      fetch({
        run: ({
          isLogged,
          permitirCarrinhoAnonimo,
          filial,
          cdSelecionado,
          enviaGa4,
          bucket,
        }) => {
          if (isLogged || permitirCarrinhoAnonimo) {
            return this.carrinhoService
              .getCarrinho(filial.id, cdSelecionado.id)
              .pipe(
                map((carrinho) => {
                  const itens =
                    carrinho.data?.itens?.map((item: IProdutoCarrinho) => {
                      if (item.combo) {
                        item.combo.produtos = item.combo.produtos.map(
                          (response) => {
                            return {
                              ...response,
                              imagem: response.imagem
                                ? `${bucket}/${response.imagem}`
                                : response.imagem,
                            };
                          }
                        );
                      }
                      return this.bucketsService.addBucketProduto(item, bucket);
                    }) || [];
                  return CarrinhoActions.getCarrinhoSuccess({
                    carrinho: { ...carrinho.data, itens },
                    enviaGa4: enviaGa4,
                  });
                })
              );
          } else {
            return CarrinhoActions.resetCarrinho();
          }
        },
        onError: (action, error) =>
          CarrinhoActions.getCarrinhoFailure({ error }),
      })
    )
  );

  removeCarrinho$ = createEffect(() => {
    return this.actions$.pipe(ofType(CarrinhoActions.removeCarrinho)).pipe(
      fetch({
        run: (action) => {
          return this.carrinhoService
            .removeCarrinho(action.carrinhoId)
            .pipe(map(() => CarrinhoActions.removeCarrinhoSuccess()));
        },
        onError: (action, error) => {
          return CarrinhoActions.removeCarrinhoFailure({ error });
        },
      })
    );
  });

  getCarrinhoAposLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.getClienteSuccess),
        mergeTakeOne(
          this.cdFacade.filialECdSelecionado$,
          this.parametrosFacade.permitirCarrinhoAnonimo$,
          this.store.pipe(select(carrinhoSelectors.getData()))
        ),
        map(
          ([
            action,
            [filial, cdSelecionado],
            permitirCarrinhoAnonimo,
            carrinho,
          ]) => {
            return {
              ...action,
              filial,
              cdSelecionado,
              permitirCarrinhoAnonimo,
              carrinho,
            };
          }
        )
      ),
    { dispatch: false }
  );

  limparCarrinhoLojaAutonomaAposLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.logout),
        mergeTakeOne(this.store.pipe(select(carrinhoSelectors.getData()))),
        tap(([_action, carrinho]) => {
          if (carrinho?.loja_autonoma) {
            this.carrinhoFacade.removerCarrinho(carrinho.carrinho_id);
          }
        })
      ),
    { dispatch: false }
  );

  getCarrinhoAposLoginSemCarrinhoAnonimo$ = createEffect(() =>
    this.getCarrinhoAposLogin$.pipe(
      filter(
        (action) =>
          !action.permitirCarrinhoAnonimo ||
          (action.permitirCarrinhoAnonimo && !action.carrinho?.quantidade)
      ),
      map(() => CarrinhoActions.getAllCarrinho())
    )
  );

  getCarrinhoFailureCarrinhoEmOutroCD$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoActions.getCarrinhoFailure),
        filter(
          (action) =>
            action.error.error?.error?.carrinho_id &&
            !action.error.error?.error?.loja_autonoma
        ),
        tap((action) =>
          this.manterCarrinhoService.openDialogManterCarrinhoDeOutroCD()
        )
      ),
    { dispatch: false }
  );

  getCarrinhoFailureCarrinhoEmLojaAutonoma$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoActions.getCarrinhoFailure),
        filter(
          (action) =>
            action.error.error?.error?.carrinho_id &&
            action.error.error?.error?.loja_autonoma
        ),
        tap((action) =>
          this.carrinhoFacade.removerCarrinho(
            action.error.error?.error?.carrinho_id
          )
        )
      ),
    { dispatch: false }
  );

  situacaoCarrinhoSessaoCliente$ = createEffect(() =>
    this.getCarrinhoAposLogin$.pipe(
      filter(
        (action) =>
          action.permitirCarrinhoAnonimo && !!action.carrinho?.quantidade
      ),
      fetch({
        run: (action) =>
          this.carrinhoService
            .situacaoCarrinhoSessaoCliente(
              action.filial.id,
              action.cdSelecionado.id
            )
            .pipe(
              map((res) =>
                CarrinhoActions.situacaoCarrinhoSessaoClienteSuccess({
                  situacao: res.data,
                  isLojaAutonoma: action.cdSelecionado.loja_autonoma,
                })
              )
            ),
        onError: (action, error) =>
          CarrinhoActions.situacaoCarrinhoSessaoClienteFailure({ error }),
      })
    )
  );

  comCarrinhoNoClienteSemLojaAutonoma$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoActions.situacaoCarrinhoSessaoClienteSuccess),
        distinctUntilChanged((prev, curr) => {
          return JSON.stringify(curr) === JSON.stringify(prev);
        }),
        filter(
          (action) =>
            action.situacao.possui_carrinho_cliente && !action.isLojaAutonoma
        ),
        tap((action) =>
          this.manterCarrinhoService.openDialogManterCarrinho(
            action.situacao.bebida_alcoolica_menor_idade_removida
          )
        )
      ),
    { dispatch: false }
  );

  comCarrinhoLojaAutonoma$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrinhoActions.situacaoCarrinhoSessaoClienteSuccess),
      filter(
        (action) =>
          action.situacao.possui_carrinho_cliente &&
          !action.situacao.pedido_em_processo &&
          action.isLojaAutonoma
      ),
      map(() => CarrinhoActions.manterCarrinhoAnonimo())
    )
  );

  comCarrinhoLojaAutonomaPedidoEmProcesso$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoActions.situacaoCarrinhoSessaoClienteSuccess),
        filter(
          (action) =>
            action.situacao.possui_carrinho_cliente &&
            action.situacao.pedido_em_processo &&
            action.isLojaAutonoma
        ),
        tap(() => this.openDialogManterCompraEmProcesso())
      ),
    { dispatch: false }
  );

  semCarrinhoNoCliente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrinhoActions.situacaoCarrinhoSessaoClienteSuccess),
      filter((action) => !action.situacao.possui_carrinho_cliente),
      map(() => CarrinhoActions.manterCarrinhoAnonimo())
    )
  );

  semCarrinhoNoClienteRemoveuProdutos18Mais$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoActions.situacaoCarrinhoSessaoClienteSuccess),
        filter(
          (action) =>
            !action.situacao.possui_carrinho_cliente &&
            action.situacao.bebida_alcoolica_menor_idade_removida
        ),
        tap(() =>
          this.manterCarrinhoService.openDialogProdutos18MaisRemovidos()
        )
      ),
    { dispatch: false }
  );
  manterCarrinhoCliente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrinhoActions.manterCarrinhoCliente),
      withLatestFrom(this.cdFacade.filialECdSelecionado$),
      map(([action, [filial, cdSelecionado]]) => {
        return { ...action, filial, cdSelecionado };
      }),
      fetch({
        run: (action) =>
          this.carrinhoService.manterCarrinhoCliente(action.filial.id).pipe(
            map((res) =>
              CarrinhoActions.manterCarrinhoClienteSuccess({
                cd: res.data.cd,
              })
            )
          ),
        onError: (action, error) =>
          CarrinhoActions.manterCarrinhoClienteFailure({ error }),
      })
    )
  );

  setCdSelecionadoComCdCarrinhoMantido$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoActions.manterCarrinhoClienteSuccess),
        tap((action) =>
          this.cdFacade.setCentroDistribuicaoSelecionado(action.cd)
        )
      ),
    { dispatch: false }
  );

  manterCarrinhoAnonimo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrinhoActions.manterCarrinhoAnonimo),
      withLatestFrom(this.cdFacade.filialECdSelecionado$),
      map(([action, [filial, cdSelecionado]]) => {
        return { ...action, filial, cdSelecionado };
      }),
      fetch({
        run: (action) => {
          return this.carrinhoService
            .manterCarrinhoSessao(action.filial.id, action.cdSelecionado.id)
            .pipe(
              map((res) =>
                CarrinhoActions.manterCarrinhoAnonimoSuccess({
                  pedidoCancelado: res.data.pedido_cancelado,
                })
              )
            );
        },
        onError: (action, error) =>
          CarrinhoActions.manterCarrinhoAnonimoFailure({ error }),
      })
    )
  );

  exibeCompraCancelada$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoActions.manterCarrinhoAnonimoSuccess),
        filter((action) => !!action.pedidoCancelado),
        tap((action) =>
          this.messageService.openSuccessMessage(
            `A compra em processo Nº ${action.pedidoCancelado} foi cancelada.
            Você pode realizá-la novamente acessando o histórico de compras.`,
            1.5
          )
        )
      ),
    { dispatch: false }
  );

  getTodasInfosDoCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CarrinhoActions.manterCarrinhoAnonimoSuccess,
        CarrinhoActions.manterCarrinhoClienteSuccess
      ),
      tap(() => this.router.navigateByUrl('/home')),
      map(() => CarrinhoActions.getAllCarrinho())
    )
  );

  getTodasInfosDoCarroAoMigrarCD$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CentroDistribuicaoActions.migrarCentroDistribuicaoSuccess,
        CentroDistribuicaoActions.setCentroDistribuicaoDoCarrinhoSuccess
      ),
      filter((action) => !action.cd.loja_autonoma),
      map(() => CarrinhoActions.getAllCarrinho())
    )
  );

  resetarCarrinhoMigrarCdLojaAutonoma$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CentroDistribuicaoActions.migrarCentroDistribuicaoSuccess),
      filter((action) => action.cd.loja_autonoma),
      map(() => CarrinhoActions.resetCarrinho())
    )
  );

  verificarItensDesativados$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CarrinhoActions.getCarrinhoSuccess,
        CarrinhoActions.verificaItensDesativados
      ),
      mergeTakeOne(this.cdFacade.filialECdSelecionado$),
      filter(
        ([action]) =>
          (action.type === '[Carrinho/API] Busca Carrinho de clienteSuccess' &&
            action.carrinho?.quantidade > 0) ||
          action.type !== '[Carrinho/API] Busca Carrinho de clienteSuccess'
      ),
      map(([action, [filial, cdSelecionado]]) => ({
        ...action,
        filial,
        cdSelecionado,
      })),
      fetch({
        run: (action) =>
          this.carrinhoService
            .verificaItensDesativados(action.filial.id, action.cdSelecionado.id)
            .pipe(
              map((res) =>
                CarrinhoActions.verificaItensDesativadosSuccess({
                  message: res.mensagem,
                })
              )
            ),
        onError: (action, error) =>
          CarrinhoActions.verificaItensDesativadosFailure({ error }),
      })
    )
  );

  itensDesativadosRemovidos$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoActions.verificaItensDesativadosSuccess),
        tap(({ message }) => {
          if (message !== null) {
            this.openDialogItensDesativadosRemovidos(message);
            this.carrinhoFacade.getAllCarrinho();
          }
        })
      ),
    { dispatch: false }
  );

  private openDialogManterCompraEmProcesso() {
    this.dialogService.openDialog({
      open: true,
      title: 'Compra não finalizada',
      subTitle:
        'Você possui uma outra compra em processo, deseja continuar ou cancelar ela?',
      disabled: true,
      buttonConfirmText: 'Manter compra em processo',
      buttonCancelText: 'Cancelar compra em processo',
    });
    this.dialogService.dialogClick.subscribe((manterCarrinho) => {
      if (manterCarrinho) {
        this.carrinhoFacade.manterCarrinhoCliente();
      } else {
        this.carrinhoFacade.manterCarrinhoAnonimo();
      }
      this.dialogService.clearDialog();
    });
  }

  private openDialogItensDesativadosRemovidos(message: string) {
    const title = message.includes('O produto')
      ? 'Um item foi removido do seu carrinho!'
      : 'Alguns itens foram removidos do carrinho!';
    this.dialogService.openDialog({
      open: true,
      title,
      subTitle: message,
      disabled: false,
      buttonConfirmText: 'Ok',
    });
    this.dialogService.dialogClick.subscribe(() => {
      this.dialogService.clearDialog();
    });
  }

  constructor(
    private actions$: Actions,
    private carrinhoService: CarrinhoService,
    private carrinhoFacade: CarrinhoFacade,
    private cdFacade: CentroDistribuicaoFacade,
    private manterCarrinhoService: ManterCarrinhoService,
    private messageService: MessageService,
    private parametrosFacade: ParametrosFacade,
    private clienteFacade: ClienteFacade,
    private dialogService: DialogService,
    private bucketsFacade: BucketsFacade,
    private bucketsService: BucketsService,
    private router: Router,
    private store: Store
  ) {}
}
