import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  IApiPaginator,
  ICarrinhoItemInconsistencia,
  ICompra,
  IPerguntaSobreCompra,
  IProdutoCarrinho,
} from '@vip/core';

export const select = createAction(
  '[Select Effects] Select Compra',
  props<{ compraSelecionadaId: number | null }>()
);

export const getCompras = createAction('[Compras/API] Get Compras');

export const getComprasSuccess = createAction(
  '[Compras/API] Get Compras Success',
  props<{ compras: ICompra[]; paginator?: IApiPaginator }>()
);

export const getComprasFailure = createAction(
  '[Compras/API] Get Compras Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getTrocaDevolucao = createAction(
  '[TrocaDevolucaos/API] Get Troca e Devoluções'
);

export const getTrocaDevolucaoSuccess = createAction(
  '[TrocaDevolucaos/API] Get Troca e Devoluções Success',
  props<{ compras: ICompra[] }>()
);

export const getTrocaDevolucaoFailure = createAction(
  '[TrocaDevolucaos/API] Get Troca e Devoluções Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getComprasEmAberto = createAction(
  '[Compras/API] Get Compras Em Aberto'
);

export const getComprasEmAbertoSuccess = createAction(
  '[Compras/API] Get Compras Em Aberto Success',
  props<{ compras: ICompra[] }>()
);

export const getComprasEmAbertoFailure = createAction(
  '[Compras/API] Get Compras Em Aberto Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getPrazoTrocaDevolucao = createAction(
  '[Compras/API] Get Prazo Troca Devolucao'
);

export const getPrazoTrocaDevolucaoSuccess = createAction(
  '[TrocaDevolucaos/API] Get Prazo Troca Devolucao Success',
  props<{ prazoTroca: number; prazoDevolucao: number }>()
);

export const getPrazoTrocaDevolucaoFailure = createAction(
  '[Compras/API] Get Prazo Troca Devolucao Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getComprasTrocasDevolucao = createAction(
  '[Compras/API] Get Pedidos em Aberto'
);

export const getComprasTrocasDevolucaoSuccess = createAction(
  '[Compras/API] Get Pedidos em Aberto Success',
  props<{ compras: ICompra[] }>()
);

export const getComprasTrocasDevolucaoFailure = createAction(
  '[Compras/API] Get Pedidos em Aberto Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getCompra = createAction(
  '[Compras/API] Get Compra',
  props<{ compraId: number }>()
);

export const getCompraSuccess = createAction(
  '[Compras/API] Get Compra Success',
  props<{ compra: ICompra }>()
);

export const getCompraFailure = createAction(
  '[Compras/API] Get Compra Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getPerguntasCompraComResposta = createAction(
  '[Compras/API] Get Perguntas Compra Com Resposta',
  props<{ compraId: number }>()
);

export const getPerguntasCompraComRespostaSuccess = createAction(
  '[Compras/API] Get Perguntas Compra Success Com Resposta',
  props<{ perguntas: IPerguntaSobreCompra[] }>()
);

export const getPerguntasCompraComRespostaFailure = createAction(
  '[Compras/API] Get Perguntas Compra Failure Com Resposta',
  props<{ error: HttpErrorResponse }>()
);

export const cancelarCompra = createAction(
  '[Compra/Page] Cancelar compra',
  props<{ compraId: number; compra_status_id: number; observacao: string }>()
);

export const cancelarCompraSuccess = createAction(
  '[Compras/API] Cancelar Compra Success',
  props<{ compraId: number; compra_status_id: number }>()
);

export const cancelarCompraFailure = createAction(
  '[Compras/API] Cancelar Compra Failure',
  props<{ error: HttpErrorResponse }>()
);

export const repetirCompra = createAction(
  '[Compras/Page] Repetir Compra',
  props<{ compraId: number }>()
);

export const repetirCompraSubstituirProdutos = createAction(
  '[Compras/API] Repetir Compra Substituir Produtos',
  props<{
    itemNaoAtualizados: ICarrinhoItemInconsistencia[];
    itemAtualizado: IProdutoCarrinho[];
  }>()
);

export const repetirCompraSuccess = createAction(
  '[Compras/API] Repetir Compra Success'
);

export const repetirCompraFailure = createAction(
  '[Compras/API] Repetir Compra Failure',
  props<{ error: HttpErrorResponse }>()
);

export const setIdRepetirCompra = createAction(
  '[Carrinho/Page] set item para atualizar carrinho de cliente',
  props<{
    compraId: number;
  }>()
);

export const nextPage = createAction('[Compras/API] Next Page Compra');
