import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { IGenericState } from '@vip/state/utils';
import { AtualizarCarrinhoItemRequest } from '@vip/api';

import * as SelecaoCdActions from './selecao-cd.actions';

export const SELECAO_CD_FEATURE_KEY = 'selecao-cd';

export interface SelecaoCdState extends IGenericState<[]> {
  itemParaAtualizarCarrinho: {
    item: AtualizarCarrinhoItemRequest | AtualizarCarrinhoItemRequest[] | null;
    exibirMensagemAdicionado: boolean;
  };
  permitirAtualizarItemCarrinho: boolean | null;
  compraIdArmazenado: number | null;
  listaIdArmazenado: number | null;
  listaIngredientesIdArmazenado: number | null;
}

export interface SelecaoCdPartialState {
  readonly [SELECAO_CD_FEATURE_KEY]: SelecaoCdState;
}

export const selecaoCdAdapter: EntityAdapter<[]> = createEntityAdapter<[]>();

export const initialSelecaoCdState: SelecaoCdState =
  selecaoCdAdapter.getInitialState({
    data: [],
    status: 'pending',
    error: null,
    itemParaAtualizarCarrinho: { item: null, exibirMensagemAdicionado: false },
    permitirAtualizarItemCarrinho: null,
    compraIdArmazenado: null,
    listaIdArmazenado: null,
    listaIngredientesIdArmazenado: null,
  });

const reducer = createReducer(
  initialSelecaoCdState,
  on(SelecaoCdActions.init, (state) => ({
    ...initialSelecaoCdState,
    status: 'success',
  })),
  on(SelecaoCdActions.setItemParaAtualizarCarrinho, (state, action) => ({
    ...state,
    itemParaAtualizarCarrinho: {
      item: action.item,
      exibirMensagemAdicionado: action.exibirMensagemAdicionado,
    },
    permitirAtualizarItemCarrinho: true,
  })),
  on(
    SelecaoCdActions.permitirAtualizarCarrinho,
    (state, { permitirAtualizarItemCarrinho }) => ({
      ...state,
      permitirAtualizarItemCarrinho: permitirAtualizarItemCarrinho,
    })
  ),
  on(
    SelecaoCdActions.setCompraIdArmazenado,
    (state, { compraIdArmazenado }) => ({
      ...state,
      compraIdArmazenado,
      permitirAtualizarItemCarrinho: compraIdArmazenado !== null,
    })
  ),
  on(SelecaoCdActions.setListaIdArmazenado, (state, { listaIdArmazenado }) => ({
    ...state,
    listaIdArmazenado,
    permitirAtualizarItemCarrinho: listaIdArmazenado !== null,
  })),
  on(
    SelecaoCdActions.setListaIngredientesIdArmazenado,
    (state, { listaIngredientesIdArmazenado }) => ({
      ...state,
      listaIngredientesIdArmazenado,
      permitirAtualizarItemCarrinho: listaIngredientesIdArmazenado !== null,
    })
  )
);

export function selecaoCdReducer(
  state: SelecaoCdState | undefined,
  action: Action
) {
  return reducer(state, action);
}
