import {
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Location } from '@angular/common';

@Directive({
  selector: '[goBack]',
})
export class NavigateBackDirective implements OnInit, OnDestroy {
  private _clickSubscription!: () => unknown;

  constructor(
    private _elRef: ElementRef,
    private _renderer: Renderer2,
    private _location: Location
  ) {}

  ngOnInit() {
    this._addClickHandler();
  }

  ngOnDestroy() {
    this._removeClickHandler();
  }

  private _addClickHandler() {
    this._clickSubscription = this._renderer.listen(
      this._elRef.nativeElement,
      'click',
      this._handleClickEvent
    );
  }

  private _removeClickHandler() {
    this._clickSubscription && this._clickSubscription();
  }

  private _handleClickEvent = (): boolean => {
    this._location.back();
    return false;
  };
}
