import { Injectable } from '@angular/core';
import { ICupomCredito, ICupomCreditoDTO } from '@vip/core';
import { parseISO } from 'date-fns';
import { map } from 'rxjs/operators';
import { BaseApiService } from '../common/base-api-service';
import {
  GetCupomResponse,
  GetCupomResponseDTO,
} from './cupom-api.service.types';

@Injectable({
  providedIn: 'root',
})
export class CupomApiService {
  constructor(private api: BaseApiService<CupomApiService>) {}

  getMeusCupons() {
    return this.api
      .get<GetCupomResponseDTO>('getMeusCupons', '/loja/clientes/cupom_credito')
      .pipe(
        map((cuponsDTO: GetCupomResponseDTO) => {
          const cupons: GetCupomResponse = {
            ...cuponsDTO,
            data: cuponsDTO.data.map((cupomDTO: ICupomCreditoDTO) => {
              const cupom: ICupomCredito = {
                ...cupomDTO,
                validade: parseISO(cupomDTO.validade),
                created: parseISO(cupomDTO.created),
              };
              return cupom;
            }),
          };
          return cupons;
        })
      );
  }

  getMeusCuponsDisponiveis() {
    return this.api
      .get<GetCupomResponseDTO>(
        'getMeusCuponsDisponiveis',
        '/loja/clientes/cupom_credito/disponiveis'
      )
      .pipe(
        map((cuponsDTO: GetCupomResponseDTO) => {
          const cupons: GetCupomResponse = {
            ...cuponsDTO,
            data: cuponsDTO.data.map((cupomDTO: ICupomCreditoDTO) => {
              const cupom: ICupomCredito = {
                ...cupomDTO,
                validade: parseISO(cupomDTO.validade),
                created: parseISO(cupomDTO.created),
              };
              return cupom;
            }),
          };
          return cupons;
        })
      );
  }
}
