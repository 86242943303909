import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'vip-carousel-header',
  templateUrl: './carousel-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselHeaderComponent {
  @Input() title!: string;
  @Input() quantity!: number;
  @Input() showAll = false;
  @Input() isLoading = false;
  @Input() applySideMargins = true;
  @Input() isMobile = true;
  @Input() isHomeOmni = false;
  @Output() showMoreClick = new EventEmitter();
}
