import { Action, createReducer, on } from '@ngrx/store';

import * as CepActions from './cep.actions';
import { IEnderecoDoCep } from '@vip/core';
import { IGenericState } from '@vip/state/utils';

export const CEP_FEATURE_KEY = 'cep';

export interface CepStateData {
  cep: string;
  cepAtendido: boolean | null;
  apenasRetirada: boolean | null;
  solicitacaoDeCepEnviada: boolean | null;
  enderecoDoCep?: IEnderecoDoCep;
}

export type CepState = IGenericState<CepStateData>;

export const initialState: CepState = {
  data: {
    cep: '',
    cepAtendido: null,
    apenasRetirada: null,
    solicitacaoDeCepEnviada: null,
  },
  status: 'pending',
  error: null,
};

const cepReducer = createReducer(
  initialState,
  on(CepActions.resetCepStore, () => ({ ...initialState })),
  on(
    CepActions.validarCepAtendidoPelaFilial,
    CepActions.addSolicitacaoCep,
    (state, { cep }) => ({
      ...state,
      data: { ...initialState.data, cep },
      error: null,
      status: 'loading',
    })
  ),
  on(
    CepActions.validarCepAtendidoPelaFilialSuccess,
    (state, { cepAtendido }) => ({
      ...state,
      data: {
        ...state.data,
        ...cepAtendido,
        enderecoDoCep: cepAtendido.endereco,
      },
      status: 'success',
      error: null,
    })
  ),
  on(
    CepActions.addSolicitacaoCepSuccess,
    (state, { solicitacaoDeCepEnviada }) => ({
      ...state,
      data: { ...state.data, solicitacaoDeCepEnviada },
      status: 'success',
      error: null,
    })
  ),
  on(CepActions.addSolicitacaoCepFailure, (state, { error }) => ({
    ...state,
    data: {
      ...initialState.data,
      cep: state.data.cep,
      solicitacaoDeCepEnviada: false,
    },
    status: 'error',
    error: error.error.error,
  })),
  on(CepActions.validarCepAtendidoPelaFilialFailure, (state, { error }) => ({
    ...state,
    data: { ...initialState.data, cep: state.data.cep, cepAtendido: false },
    status: 'error',
    error: error.message,
  }))
);

export function reducer(state: CepState, action: Action) {
  return cepReducer(state, action);
}
