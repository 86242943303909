import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { GenericStoreStatusEnum, IGenericState } from '@vip/state/utils';
import * as FormaPagamentosActions from './forma-pagamento.actions';
import { IFormaPagamento, IPagamento, IPagamentosViewModel } from '@vip/core';

export const FORMA_PAGAMENTOS_FEATURE_KEY = 'FormaPagamentos';

export interface FormaPagamentosState
  extends IGenericState<IFormaPagamento[]>,
    EntityState<IFormaPagamento> {
  removerPrepagos: boolean;
  formaPagamentoSelecionadaId: number | null;
  valorTroco: number;
  permiteSelecaoManual: boolean;
  bandeiraNaoAtendida?: boolean;
  pagamentosOnline: IPagamento | null;
  pagamentoEntrega: IPagamento | null;
  pagamentos: IPagamentosViewModel | null;
}

export const formaPagamentosAdapter: EntityAdapter<IFormaPagamento> =
  createEntityAdapter<IFormaPagamento>();

export const initialState: FormaPagamentosState =
  formaPagamentosAdapter.getInitialState({
    data: [],
    removerPrepagos: false,
    formaPagamentoSelecionadaId: null,
    status: GenericStoreStatusEnum.PENDING,
    valorTroco: 0,
    error: null,
    permiteSelecaoManual: false,
    pagamentosOnline: null,
    pagamentoEntrega: null,
    pagamentos: null,
  });

const FormaPagamentosReducer = createReducer(
  initialState,
  on(
    FormaPagamentosActions.getFormaPagamentos,
    FormaPagamentosActions.buscarBandeiraCartao,
    (state, action) => ({
      ...state,
      status:
        'dontChangeStateStatus' in action && action.dontChangeStateStatus
          ? state.status
          : GenericStoreStatusEnum.LOADING,
      error: null,
    })
  ),
  on(
    FormaPagamentosActions.getFormaPagamentosSuccess,
    (
      state,
      {
        formaPagamentos,
        removerPrepagos,
        pagamentosOnline,
        pagamentoEntrega,
        pagamentos,
      }
    ) =>
      formaPagamentosAdapter.setAll(formaPagamentos, {
        ...state,
        status: GenericStoreStatusEnum.SUCCESS,
        data: formaPagamentos,
        removerPrepagos: removerPrepagos,
        pagamentosOnline,
        pagamentoEntrega,
        pagamentos,
      })
  ),
  on(
    FormaPagamentosActions.selectFormaPagamento,
    (state, { formaPagamentoId }) => ({
      ...state,
      formaPagamentoSelecionadaId: formaPagamentoId,
    })
  ),
  on(FormaPagamentosActions.resetFormaPagamentoSelecionada, (state) => ({
    ...state,
    formaPagamentoSelecionadaId: null,
    valorTroco: 0,
  })),
  on(FormaPagamentosActions.setValorTroco, (state, { valorTroco }) => ({
    ...state,
    valorTroco: valorTroco,
  })),
  on(FormaPagamentosActions.getFormaPagamentosFailure, (state, { error }) => ({
    ...state,
    status: GenericStoreStatusEnum.ERROR,
    error: error.error ? error.error.error.message : error.message,
  })),
  on(
    FormaPagamentosActions.buscarBandeiraCartaoSuccess,
    (state, { formaPagamento, permiteSelecaoManual }) => ({
      ...state,
      status: GenericStoreStatusEnum.SUCCESS,
      formaPagamentoSelecionadaId: permiteSelecaoManual
        ? state.formaPagamentoSelecionadaId
        : formaPagamento?.id,
      permiteSelecaoManual,
      bandeiraNaoAtendida: false,
    })
  ),
  on(
    FormaPagamentosActions.buscarBandeiraCartaoFailure,
    (state, { error, bandeiraNaoAtendida }) => ({
      ...state,
      status: GenericStoreStatusEnum.ERROR,
      error: error.error ? error.error.error.message : error.message,
      bandeiraNaoAtendida,
    })
  ),
  on(FormaPagamentosActions.resetBandeiraNaoAtendidaCartao, (state) => ({
    ...state,
    bandeiraNaoAtendida: undefined,
  })),
  on(FormaPagamentosActions.resetPermiteSelecaoManual, (state) => ({
    ...state,
    permiteSelecaoManual: false,
  }))
);

export function reducer(
  state: FormaPagamentosState | undefined,
  action: Action
) {
  return FormaPagamentosReducer(state, action);
}
