import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BaseComponentSkeletonComponent } from '@vip/ui/skeleton';

@Component({
  selector: 'vip-lista-produtos-selecionados-skeleton',
  templateUrl: './lista-produtos-selecionados-skeleton.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListaProdutosSelecionadosSkeletonComponent extends BaseComponentSkeletonComponent {
  @Input() layoutReceita = false;
}
