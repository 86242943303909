import { Injectable } from '@angular/core';
import { ICliente } from '@vip/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CompletarCadastroHelperService {
  private saveDadosSubject = new Subject<
    ICliente & {
      password: string;
    }
  >();
  saveDados$ = this.saveDadosSubject.asObservable();
  subscribed = false;

  set saveDados(
    value: ICliente & {
      password: string;
    }
  ) {
    this.saveDadosSubject.next(value);
  }
}
