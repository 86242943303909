<vip-alterar-horarios
  class="vip-page-content"
  [ngClass]="{ 'pt-1': isDesktopResponsive }"
  [isLoading]="!!(isLoading$ | async)"
  [tipoEntrega]="tipoEntregaSelecionado$ | async"
  [isDesktop]="(isDesktop | async) ?? false"
  [isDesktopWithoutScreenWidth]="isDesktopWithoutScreenWidth"
  [horarioSelecionado]="(horarioSelecionado$ | async) || null"
  (horarioChange)="handleHorarioChange($event)"
  (confirmClick)="confirm()"
></vip-alterar-horarios>
 