import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ICentroDistribuicao,
  IEndereco,
  IHorariosFormatados,
  ISelectOptions,
  ITipoEntrega,
  TipoIntervaloEnum,
} from '@vip/core';
import {
  DayOfWeekWithFullDatePipe,
  EntregaRetiradaPropertiesBaseDirective,
} from '@vip/views/entrega-retirada';
import { groupBy } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, skip } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'vip-opcoes-entrega-retirada',
  templateUrl: './opcoes-entrega-retirada.component.html',
  styleUrls: ['./opcoes-entrega-retirada.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpcoesEntregaRetiradaComponent
  extends EntregaRetiradaPropertiesBaseDirective
  implements OnChanges, OnInit
{
  @Input()
  tiposEntrega: ITipoEntrega[] = [];

  @Input()
  isEditar = true;

  @Input() loadingValidaPrazo: boolean | null = false;
  @Input() hasTipoRetiradaPrazoEntrega: boolean | null = false;

  @Input()
  isEntrega: boolean | undefined = true;
  dayOfWeekWithFullDatePipe = new DayOfWeekWithFullDatePipe();
  @Input()
  override set tipoEntregaSelecionado(value: ITipoEntrega | null) {
    this._tipoEntregaSelecionado = value;
    if (!value) return;
    const horarioPorDia = this.getHorariosPorDia(value.horarios_formatados);
    this.diasEHorariosDisponiveis = [];
    horarioPorDia.forEach((dia) => {
      const day: string | null = this.dayOfWeekWithFullDatePipe.transform(
        dia.data
      );
      dia.horarios.forEach((horario) => {
        const horarios = this.getHorario(value, horario.labelMobileCalendario);
        const response = `${day} - ${horarios[0]} às ${horarios[1]}`;
        this.diasEHorariosDisponiveis.push({
          value: JSON.stringify(horario),
          text: response || '',
        });
      });
    });

    if (this.tipoEntregaSelecionado) {
      this.verificarErrosTipoEntregaSubject.next({
        [this.tipoEntregaSelecionado.id]:
          this.tipoEntregaSelecionado.horarios_formatados.length === 0,
      });
    }
  }
  override get tipoEntregaSelecionado(): ITipoEntrega | null {
    return this._tipoEntregaSelecionado;
  }
  @Output()
  openHorariosModal = new EventEmitter<void>();

  @Output()
  tipoEntregaChanged = new EventEmitter<ITipoEntrega>();

  @Output()
  editarEndereco = new EventEmitter<number>();

  @Input() hasValidaPrazo: boolean | undefined = false;
  @Input() expandedTiposEntregas = false;

  expandedEndereco = false;
  expandedLojas = false;
  enderecosExibidos: IEndereco[] = [];
  cdsExibidos: ICentroDistribuicao[] = [];
  tiposEntregaExibidos: ITipoEntrega[] = [];
  horarioSelecionado?: IHorariosFormatados;
  horarioSelecionadoString?: string;
  diasEHorariosDisponiveis: ISelectOptions[] = [];
  errosTiposEntrega = new Map<number, boolean>();
  verificarErrosTipoEntregaSubject!: Subject<{ [key: number]: boolean }>;
  verificarErrosTipoEntrega$!: Observable<{ [key: number]: boolean }>;

  get selectOptions(): ISelectOptions[] {
    return [
      {
        value: JSON.stringify(this.horarioSelecionado) ?? 0,
        text: this.horarioSelecionado?.label || '',
        selected: !!this.horarioSelecionado,
      },
    ];
  }

  constructor(private router: Router, private ngZone: NgZone) {
    super();
    this.initErrosSubject();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enderecos'] && changes['enderecos'].currentValue.length) {
      const indexSelecionado = this.enderecos.findIndex(
        (endereco) => endereco.id === this.enderecoSelecionado?.id
      );

      if (indexSelecionado > 0) {
        this.verMaisEnderecosClick();
        return;
      }
      this.verMenosEnderecosClick();
    }

    if (changes['cdsEntrega'] && changes['cdsEntrega'].currentValue) {
      this.isEntrega = true;
      const indexSelecionado = this.cdsEntrega.findIndex(
        (cds) => cds.id === this.cdSelecionado?.id
      );

      if (indexSelecionado > 0) {
        this.verMaisCdsClick();
        return;
      }
      this.verMenosCdsClick();
    }

    if (changes['cdsRetirada'] && changes['cdsRetirada'].currentValue.length) {
      this.isEntrega = false;

      const indexSelecionado = this.cdsRetirada.findIndex(
        (cds) => cds.id === this.cdSelecionado?.id && this.retiradaLoja
      );

      if (indexSelecionado > 0) {
        this.verMaisCdsClick();
        return;
      }
      this.verMenosCdsClick();
    }

    if (
      changes['tiposEntrega'] &&
      changes['tiposEntrega'].currentValue.length
    ) {
      const indexSelecionado = this.tiposEntrega.findIndex(
        (cds) => cds.id === this.tipoEntregaSelecionado?.id
      );

      if (indexSelecionado > 0) {
        this.verMaisTiposEntregasClick();
        return;
      }
      this.verMenosTiposEntregasClick();
    }

    if (
      changes['tipoEntregaSelecionado'] &&
      changes['tipoEntregaSelecionado'].currentValue !==
        changes['tipoEntregaSelecionado'].previousValue
    ) {
      const { currentValue } = changes['tipoEntregaSelecionado'];
      this.tipoEntregaChanged.emit(currentValue);
    }
  }

  ngOnInit(): void {
    this.horarioSelecionado$
      .pipe(untilDestroyed(this))
      .subscribe((horarioSelecionado) => {
        this.horarioSelecionado = horarioSelecionado;
        this.horarioSelecionadoString = JSON.stringify(horarioSelecionado);
      });

    this.tipoEntregaChanged
      .asObservable()
      .pipe(
        filter((tipoEntrega) => tipoEntrega !== null),
        distinctUntilChanged((prev, curr) => prev.id === curr.id),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.initVerificarErrosTipoEntrega();
      });
  }

  private initVerificarErrosTipoEntrega(): void {
    this.verificarErrosTipoEntrega$
      .pipe(untilDestroyed(this), skip(1))
      .subscribe((erroMap) => {
        const [tipoEntregaId] = Object.keys(erroMap);
        const [erro] = Object.values(erroMap);
        this.errosTiposEntrega.set(Number(tipoEntregaId), erro);
        this.initErrosSubject();
      });
  }

  private initErrosSubject(): void {
    this.verificarErrosTipoEntregaSubject = new Subject<{
      [key: number]: boolean;
    }>();
    this.verificarErrosTipoEntrega$ =
      this.verificarErrosTipoEntregaSubject.asObservable();
  }

  verMenosEnderecosClick(): void {
    this.enderecosExibidos = [this.enderecos[0]];
    this.expandedEndereco = false;
  }

  verMaisEnderecosClick(): void {
    this.enderecosExibidos = [...this.enderecos];
    this.expandedEndereco = true;
  }

  verMenosCdsClick(): void {
    this.expandedLojas = false;
    this.cdsExibidos = this.definirCdsExibidosVerMenosClick();
  }

  verMaisCdsClick(): void {
    this.expandedLojas = true;
    this.cdsExibidos = this.isEntrega
      ? [...this.cdsEntrega]
      : [...this.cdsRetirada];
  }

  verMenosTiposEntregasClick(): void {
    this.expandedTiposEntregas = false;
    const tipoEntregaExibido = this.tiposEntrega.find((tipo) => {
      return this.isEntrega
        ? tipo.retirada_loja === false
        : tipo.retirada_loja === true;
    });
    this.tiposEntregaExibidos = tipoEntregaExibido ? [tipoEntregaExibido] : [];
  }

  verMaisTiposEntregasClick(): void {
    this.expandedTiposEntregas = true;
    this.tiposEntregaExibidos = [
      ...this.tiposEntrega.filter((tipo) => {
        return this.isEntrega ? !tipo.retirada_loja : tipo.retirada_loja;
      }),
    ];
  }

  get tiposSelecionado(): Array<ITipoEntrega> {
    return [
      ...this.tiposEntrega.filter((tipo) => {
        return this.isEntrega ? !tipo.retirada_loja : tipo.retirada_loja;
      }),
    ];
  }
  handleNovoEnderecoClick(event: Event): void {
    event.stopImmediatePropagation();

    this.router.navigate(['/entrega-retirada/endereco/novo'], {
      queryParamsHandling: 'merge',
    });
  }

  handleEditarEnderecoClick(event: Event, id: number): void {
    this.editarEndereco.emit(id);

    event.stopImmediatePropagation();
    this.ngZone.run(() => {
      this.router.navigate(['/entrega-retirada/endereco/editar'], {
        queryParamsHandling: 'merge',
      });
    });
  }

  private definirCdsExibidosVerMenosClick(): Array<ICentroDistribuicao> {
    const result = [];
    if (this.isEntrega && this.cdsEntrega.length && this.cdsEntrega[0]) {
      result.push(this.cdsEntrega[0]);
    }
    if (!this.isEntrega && this.cdsRetirada.length && this.cdsRetirada[0]) {
      result.push(this.cdsRetirada[0]);
    }
    return result;
  }

  getHorariosPorDia(horarios: IHorariosFormatados[]) {
    const dias = groupBy(horarios, (horario) => horario.date);

    return Object.keys(dias).map((dia) => ({
      data: dia,
      horarios: dias[dia],
    }));
  }

  getHorario(tipoEntregaSelecionado: ITipoEntrega, label: string) {
    let labelEntrega: string[] = [];

    if (
      tipoEntregaSelecionado.tipo_intervalo === TipoIntervaloEnum.SEM_INTERVALOS
    ) {
      labelEntrega = label.split('Até às ');
    } else {
      const intervalo = label.split('De ')[1];
      labelEntrega = intervalo.split(' às ');
    }

    return labelEntrega;
  }

  aoMudarHorario($eventValue: any) {
    if (
      (typeof $eventValue === 'string' || $eventValue === undefined) &&
      $eventValue !== this.horarioSelecionadoString
    ) {
      let value = $eventValue;
      if ($eventValue && typeof $eventValue === 'string')
        value = JSON.parse($eventValue);

      if ($eventValue === undefined) value = null;
      this.horarioChange.emit(value);
    }
  }

  getValorTipoEntrega(tipoEntrega: ITipoEntrega) {
    const desconto = tipoEntrega.desconto ?? 0;
    return tipoEntrega.frete - desconto;
  }
}
