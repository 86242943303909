import { Injectable } from '@angular/core';
import { IMensagemFaleConosco } from '@vip/core';
import { Observable } from 'rxjs';
import { BaseWsService } from '../common/base-ws-service';
import { GetAssuntosResponse } from './fale-conosco-ws.types';

@Injectable({
  providedIn: 'root',
})
export class FaleConoscoWsService {
  constructor(private ws: BaseWsService<FaleConoscoWsService>) {}

  getAssuntos(): Observable<GetAssuntosResponse> {
    return this.ws.get<GetAssuntosResponse>(
      'getAssuntos',
      `/loja/assuntos/listagem`
    );
  }

  enviarMensagem(mensagem: IMensagemFaleConosco): Observable<void> {
    return this.ws.post<IMensagemFaleConosco, void>(
      'enviarMensagem',
      '/loja/painel-mensagens/add',
      mensagem
    );
  }
}
