import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IBucket,
  IConfiguracoesOmnichannel,
  IFidelidade,
  IFilial,
  IParametros,
  TipoDispositivoEnum,
} from '@vip/core';
import { BaseApiService } from '../common/base-api-service';
import { BaseApiResponse } from '../common/base-api-response';

@Injectable({
  providedIn: 'root',
})
export class OmnichannelApiService {
  private lojaUrl = '/loja';

  constructor(private api: BaseApiService<OmnichannelApiService>) {}

  getFilialPorVipCommerceFilialId(id: number): Observable<
    BaseApiResponse<{
      fidelidade: IFidelidade;
      filial: IFilial;
      localizacaoArquivos: IBucket[];
      parametros: IParametros;
      possui_loja_autonoma: boolean;
    }>
  > {
    return this.api.get(
      'getFilialPorVipCommerceFilialId',
      `${this.lojaUrl}/omnichannel/${id}`
    );
  }

  getConfiguracoesPorFilialIdECdId(
    filialId: number,
    cdId: number,
    dispositivo: TipoDispositivoEnum
  ): Observable<BaseApiResponse<IConfiguracoesOmnichannel[]>> {
    return this.api.get(
      'getConfiguracoesPorFilialIdECdId',
      `/filial/${filialId}/centro_distribuicao/${cdId}/loja/omnichannel/home/dispositivo/${dispositivo}`
    );
  }
}
