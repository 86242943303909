import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseWsService } from '../common/base-ws-service';
import {
  RedefinirSenhaRequest,
  verificaChaveAcessoResponse,
} from './redefinir-senha-ws.types';

@Injectable({
  providedIn: 'root',
})
export class RedefinirSenhaWsService {
  private redefinirSenhaUrl = '/loja/clientes/redefinir-senha';

  constructor(private ws: BaseWsService<RedefinirSenhaWsService>) {}

  verificaChaveAcesso(chave: string): Observable<verificaChaveAcessoResponse> {
    return this.ws.get<verificaChaveAcessoResponse>(
      'verificaChaveAcesso',
      `${this.redefinirSenhaUrl}/${chave}`
    );
  }

  redefinirSenha(
    params: RedefinirSenhaRequest,
    chave: string
  ): Observable<verificaChaveAcessoResponse> {
    return this.ws.post<RedefinirSenhaRequest, verificaChaveAcessoResponse>(
      'verificaChaveAcesso',
      `${this.redefinirSenhaUrl}/${chave}`,
      params
    );
  }
}
