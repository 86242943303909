import { ButtonModule } from '@vip/ui/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhatsAppComponent } from './whats-app/whats-app.component';

@NgModule({
  imports: [CommonModule, ButtonModule],
  declarations: [WhatsAppComponent],
  exports: [WhatsAppComponent],
})
export class WhatsAppModule {}
