import { Action, createReducer, on } from '@ngrx/store';
import { IClassificacaoMercadologica } from '@vip/core';

import * as ClassificacaoMercadologicaActions from './classificacao-mercadologica.actions';
import { IGenericState } from '@vip/state/utils';

export const CLASSIFICACAO_MERCADOLOGICA_FEATURE_KEY =
  'classificacao-mercadologica';

export interface ClassificacaoMercadologicaStateData {
  arvore: IClassificacaoMercadologica[];
}

export type ClassificacaoMercadologicaState =
  IGenericState<ClassificacaoMercadologicaStateData>;

export const initialState: ClassificacaoMercadologicaState = {
  error: null,
  status: 'pending',
  data: {
    arvore: [],
  },
};

const classificacaoMercadologicaReducer = createReducer(
  initialState,
  on(ClassificacaoMercadologicaActions.loadArvore, (state) => ({
    ...state,
    status: 'loading',
    error: null,
  })),
  on(
    ClassificacaoMercadologicaActions.loadArvoreSuccess,
    (state, { arvore }) => ({
      ...state,
      data: {
        ...state.data,
        arvore,
      },
      status: 'success',
      error: null,
    })
  ),
  on(
    ClassificacaoMercadologicaActions.loadArvoreFailure,
    (state, { error }) => ({
      ...state,
      status: 'error',
      error: error.error ? error.error.error : error,
    })
  )
);

export function reducer(
  state: ClassificacaoMercadologicaState | undefined,
  action: Action
) {
  return classificacaoMercadologicaReducer(state, action);
}
