import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'vip-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent {
  private _color = 'primary';
  private _iconColor!: string;
  private _showDivider: boolean | string = true;
  private _active: boolean | string = false;

  @Input() icon = '';
  @Input() title = '';
  @Input() subtitle = '';
  @Input() info = '';
  @Input() image? = '';
  @Input() type: 'mobile' | 'desktop' | 'dropdown' = 'mobile';
  @Input() noPaddingLeft = false;
  @Input() isDesktop = false;

  @Input()
  get active(): boolean | string {
    return this._active;
  }
  set active(active) {
    this._active = this.getBooleanValue(active);
  }

  @Input()
  get showDivider(): boolean | string {
    return this._showDivider;
  }
  set showDivider(showDivider) {
    this._showDivider = this.getBooleanValue(showDivider);
  }

  @Input()
  get color(): string {
    return this.getColor(this._color);
  }
  set color(color) {
    this._color = color;
  }

  @Input()
  get iconColor(): string {
    return this.getColor(this._iconColor || this.color);
  }
  set iconColor(iconColor) {
    this._iconColor = iconColor;
  }

  @Output() menuClick = new EventEmitter();

  onMenuClick(): void {
    this.menuClick.emit(this);
  }

  getColor(color: string) {
    if (color === 'primary' || color === 'secondary')
      return `var(--vip-color-${color}-main)`;
    return color;
  }

  getBooleanValue(value: boolean | string): boolean {
    return value != null && `${value}` !== 'false';
  }
}
