import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Optional,
  Renderer2,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LayoutUtilsService, ScrollService } from '@vip/core';
import { MessageBaseDirective } from '../../directives/message-base.directive';
import { Platform } from '@ionic/angular';

@UntilDestroy()
@Component({
  selector: 'vip-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.info]': 'type === "info"',
    '[class.error]': 'type === "error"',
    '[class.success]': 'type === "success"',
    '[class.scrolling]': 'scrolling',
    '[class.mobile]': '!isDesktop',
    '[class.less-space-from-top]': 'isSmallestHeader && !scrolling',
    '[class.slide-in-right]': '!isLeaveAnimation && isDesktop',
    '[class.slide-in-top]': '!isLeaveAnimation && !isDesktop',
    '[class.slide-out-top]': 'isLeaveAnimation && !isDesktop && isTopAnimation',
    '[class.slide-out-left]':
      'isLeaveAnimation && !isDesktop && isLeftAnimation',
    '[class.slide-out-right]':
      'isLeaveAnimation && (isDesktop || isRightAnimation)',
    '[class.is-ios]': 'isIos',
  },
})
export class MessageComponent extends MessageBaseDirective {
  isSmallestHeader = false;
  isTopAnimation = false;
  isLeftAnimation = false;
  isRightAnimation = false;

  private _isLeaveAnimation = false;
  private swipedTop = false;
  private swipedLeft = false;
  private swipedRight = false;
  private touchStartX = 0;
  private touchEndX = 0;
  private touchStartY = 0;
  private touchEndY = 0;

  set isLeaveAnimation(value: boolean) {
    if (value && !this.isDesktop)
      if (
        !this.isTopAnimation &&
        !this.isLeaveAnimation &&
        !this.isRightAnimation
      )
        this.isLeftAnimation = true;

    this._isLeaveAnimation = value;
  }

  public get isLeaveAnimation(): boolean {
    return this._isLeaveAnimation;
  }

  constructor(
    elRef: ElementRef,
    renderer: Renderer2,
    private layoutUtilsService: LayoutUtilsService
  ) {
    super(elRef, renderer);
    ScrollService.scrolling$
      .pipe(untilDestroyed(this))
      .subscribe((scrolling) => {
        this.scrolling = scrolling;
      });
    ScrollService.isSmallestHeader$
      .pipe(untilDestroyed(this))
      .subscribe((isSmallestHeader) => {
        this.isSmallestHeader = isSmallestHeader;
      });
    this.layoutUtilsService.isDesktop$
      .pipe(untilDestroyed(this))
      .subscribe((isDesktop) => {
        this.isDesktop = isDesktop;
      });
    this.isIos = this.layoutUtilsService.isIos();
  }

  @HostListener('touchstart', ['$event'])
  onMouseDown(event: TouchEvent) {
    this.getValuesFromTouch(event);
  }

  @HostListener('touchend', ['$event'])
  onMouseMove(event: TouchEvent) {
    this.getValuesFromTouch(event, 'end');
    this.addLeaveAnimation();
  }

  close(event: MouseEvent): void {
    event.preventDefault();
    this.closeClick.emit();
  }

  private getValuesFromTouch(
    event: TouchEvent,
    type: 'start' | 'end' = 'start'
  ): void {
    const touches = event.changedTouches[0];
    const { screenX, screenY } = touches;

    if (type === 'start') {
      this.touchStartX = screenX;
      this.touchStartY = screenY;
      return;
    }
    this.touchEndX = screenX;
    this.touchEndY = screenY;
  }

  private addLeaveAnimation(): void {
    this.isTopAnimation = false;
    this.isLeftAnimation = false;
    this.isRightAnimation = false;

    let differenceXLeft = 0;
    let differenceXRigth = 0;
    let differenceY = 0;

    if (this.touchStartY > this.touchEndY) {
      differenceY = this.touchStartY - this.touchEndY;
      this.swipedTop = true;
    }

    if (this.touchStartX > this.touchEndX) {
      differenceXLeft = this.touchStartX - this.touchEndX;
      this.swipedLeft = true;
    }

    if (this.touchStartX < this.touchEndX) {
      differenceXRigth = this.touchEndX - this.touchStartX;
      this.swipedRight = true;
    }

    if (
      this.swipedTop &&
      differenceY > differenceXLeft &&
      differenceY > differenceXRigth
    )
      this.isTopAnimation = true;
    if (
      this.swipedLeft &&
      differenceXLeft > differenceY &&
      differenceXLeft > differenceXRigth
    )
      this.isLeftAnimation = true;
    if (
      this.swipedRight &&
      differenceXRigth > differenceY &&
      differenceXRigth > differenceXLeft
    )
      this.isRightAnimation = true;

    this.closeClick.emit();
  }
}
