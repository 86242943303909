import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'vip-base-skeleton',
  template: '<div data-cy="vip-skeleton" class="pulse"></div>',
  styleUrls: ['./base-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseSkeletonComponent implements OnInit {
  isBrowser: boolean;

  width!: string;
  height!: string;
  radius!: string;
  className: string[] = [];

  constructor(
    private host: ElementRef<HTMLElement>,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.setProperties(this.host.nativeElement);
    }
  }

  private setProperties(host: HTMLElement) {
    host.classList.add(...this.className);

    host.style.setProperty('--skeleton-width', this.width ?? '100%');
    host.style.setProperty('--skeleton-height', this.height ?? '20px');
    host.style.setProperty('--skeleton-radius', this.radius ?? '50px');
  }
}
