import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { CarouselBaseDirective } from './carousel-base.directive';
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  Swiper,
  SwiperOptions,
} from 'swiper';
import {
  BannerTypes,
  ISlide,
  LayoutUtilsService,
  ScrollableService,
} from '@vip/core';

SwiperCore.use([Navigation, Autoplay, Pagination]);

@Component({
  selector: 'vip-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CarouselComponent
  extends CarouselBaseDirective
  implements AfterViewInit
{
  @Input()
  isHomeOmni = false;

  @Input()
  removeBlockMargins = false;

  @Input() isMobile!: boolean;

  @Input()
  wide = false;

  private _type: BannerTypes = 'sm';
  get type() {
    return this._type;
  }

  /**
   * Carousel type Banner. Determina o tipo de carousel a ser utilizado
   *
   * @default sm
   *
   * @param value - tipo de carousel a ser utilizado
   *
   * @type - {'lg' | 'md' | 'sm' | 'xs' | 'vt'}
   */

  @Input() set type(value: BannerTypes) {
    this._type = value;
    this.swiperConfig = this.getSwiperConfig(this.type);
  }

  swiperConfig: SwiperOptions = this.getSwiperConfig(this.type);
  getScreenWidth: any;

  constructor(
    public cd: ChangeDetectorRef,
    public scrollableService: ScrollableService,
    private layoutUtilsService: LayoutUtilsService
  ) {
    super(cd, scrollableService);

    this.isMobile = this.layoutUtilsService.isMobile();
  }

  get width(): string {
    const percent = 100 / this.bannersPerPage;
    return percent.toString() + '%';
  }

  ngAfterViewInit(): void {
    this.scrollableService.slider = this.contentHeader?.nativeElement;
    this.getScreenWidth = window.innerWidth;
  }

  swiperOn() {
    if (!this.layoutUtilsService.isMobile()) {
      return this.getScreenWidth < 800 ? true : false;
    }
    return true;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    this.getScreenWidth = event.target.innerWidth;
    this.isMobile = this.layoutUtilsService.isMobile();
  }

  getSwiperConfig(type: BannerTypes): SwiperOptions {
    const swiperconfigs = {
      lg: {
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        loop: true,
        pagination: true,
      },
      md: {
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        loop: true,
        pagination: true,
      },
      sm: {
        spaceBetween: 10,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
      },
      xs: {
        slidesPerView: 6,
        spaceBetween: 25,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
        centerInsufficientSlides: true,
        breakpoints: {
          1450: {
            slidesPerView: 6,
            slidesOffsetBefore: 160,
          },
          1385: {
            slidesPerView: 6,
            slidesOffsetBefore: 100,
          },
          1150: {
            slidesPerView: 6,
            slidesOffsetBefore: 0,
          },
          1100: {
            slidesPerView: 5,
            slidesOffsetBefore: 0,
          },
          1000: {
            slidesPerView: 5,
            slidesOffsetBefore: 70,
          },
          900: {
            slidesPerView: 5,
            slidesOffsetBefore: 50,
          },
          870: {
            slidesPerView: 3,
            slidesOffsetBefore: 230,
          },
          820: {
            slidesPerView: 3,
            slidesOffsetBefore: 180,
          },
          750: {
            slidesPerView: 3,
            slidesOffsetBefore: 140,
          },
          720: {
            slidesPerView: 3,
            slidesOffsetBefore: 100,
          },
          670: {
            slidesPerView: 3,
            slidesOffsetBefore: 50,
          },
          565: {
            slidesPerView: 3,
            slidesOffsetBefore: 0,
          },
          500: {
            slidesPerView: 2,
            slidesOffsetBefore: 120,
          },
          435: {
            slidesPerView: 2,
            slidesOffsetBefore: 90,
          },
          315: {
            slidesPerView: 2,
            slidesOffsetBefore: 0,
          },
          235: {
            slidesPerView: 1,
            slidesOffsetBefore: 80,
          },
          0: {
            slidesPerView: 1,
            slidesOffsetBefore: 0,
          },
        },
      },
      vt: {
        spaceBetween: 10,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
      },
    };

    return swiperconfigs[type] || swiperconfigs.sm;
  }

  get slidesPerViewForSkeleton() {
    const slidesPerView = {
      lg: 1,
      md: 1,
      sm: 4,
      xs: 6,
      vt: 1,
    };

    return slidesPerView[this.type];
  }

  getSlideTime(slide: ISlide) {
    return slide?.seconds > 1 ? String(slide.seconds * 1000) : String(5000);
  }

  onSwiper(swiper: Swiper) {
    if (this.getSwiperConfig(this.type).autoplay)
      swiper.autoplay.running = true;
  }
}
