import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BaseComponentSkeletonComponent } from '@vip/ui/skeleton';

@Component({
  selector: 'vip-card-produto-skeleton',
  templateUrl: './card-produto-skeleton.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardProdutoSkeletonComponent extends BaseComponentSkeletonComponent {
  @Input() size: 'small' | 'medium' = 'small';

  get imageSize() {
    return this.size == 'small' ? '130px' : '170px';
  }

  get spinSize() {
    return this.size == 'small' ? '35px' : '44px';
  }

  get titleSize() {
    return this.size == 'small' ? '15px' : '30px';
  }

  get priceSize() {
    return this.size == 'small' ? '33px' : '60px';
  }
}
