<section class="my-3">
  <div
    *ngFor="let item of items; index as i; let last = last"
    [class.disabled]="item.disabled"
  >
    <header (click)="item.disabled ? {} : toggleState(i)">
      <ng-container
        *ngTemplateOutlet="item?.header?.templateRef || null"
      ></ng-container>
      <button class="accordion__toggle-btn" [disabled]="item.disabled">
        <i *ngIf="expanded.has(i)" class="icon-minimize"></i>
        <i *ngIf="!expanded.has(i)" class="icon-add"></i>
      </button>
    </header>
    <div [@contentExpansion]="expanded.has(i) ? 'expanded' : 'collapsed'">
      <ng-container
        *ngTemplateOutlet="item?.content?.templateRef || null"
      ></ng-container>
    </div>
    <hr class="vip-divider mt-3 mb-3" [class.hidden]="last" />
  </div>
</section>
