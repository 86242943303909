<button
  [disabled]="disabled"
  class="vip-button vip-button-{{ size }} vip-button-{{ type }}"
  [ngClass]="{
    'vip-button-disabled': disabled,
    'vip-button-icon': hasIcon,
    rounded: rounded,
    'vip-button-custom-size': customSize,
    'vip-button-custom-desktop': isDesktop,
    'vip-button-none-border-desktop': btnBordNoneDesktop,
    'p-0': noPadding,
    'combo-color-button': hasComboResume,
    'ver-combo-button': verCombo
  }"
  [style]="customRounded"
  (click)="handleClick($event)"
  vipRipple
  [rippleColor]="rippleColor"
  [rippleBorderRadius]="rippleBorderRadius"
  [attr.aria-haspopup]="ariaHaspopup"
  [attr.aria-expanded]="ariaExpanded"
  [attr.aria-disabled]="disabled"
  [attr.aria-controls]="ariaControls || ''"
>
  <ng-content></ng-content>
</button>
