import { Component, Input } from '@angular/core';

@Component({
  selector: 'vip-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input()
  number = '00';

  @Input()
  isHeader = false;

  @Input()
  position: 'center' | 'before' | 'end' = 'center';

  public get classes(): string[] {
    return [`vip-badge vip-badge-top vip-badge-${this.position}`];
  }

  public get formattedNumber(): string {
    let number = this.number ? this.number : '00';
    number = number.length < 2 ? `0${number}` : number;
    return number ? number : '00';
  }
}
