import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { OpcaoFaturamentoEnum } from '@vip/core';

@Component({
  selector: 'vip-card-opcao-faturamento',
  templateUrl: './card-opcao-faturamento.component.html',
  styleUrls: ['./card-opcao-faturamento.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CardOpcaoFaturamentoComponent {
  opcaoFaturamentoEnum = OpcaoFaturamentoEnum;

  @Input()
  opcoesFaturamento: string | null = null;

  @Input()
  opcaoFaturamento: string | null = null;

  @Output() changed = new EventEmitter();
}
