import { CardProdutoModule } from '@vip/ui/card-produto';
import { SwiperModule } from 'swiper/angular';
import { TabsModule } from '@vip/ui/tabs';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListagemCarrinhoDesktopComponent } from './listagem-carrinho-desktop/listagem-carrinho-desktop.component';

@NgModule({
  imports: [CommonModule, TabsModule, SwiperModule, CardProdutoModule],
  declarations: [ListagemCarrinhoDesktopComponent],
  exports: [ListagemCarrinhoDesktopComponent],
})
export class ListagemCarrinhoDesktopModule {}
