import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { IGenericState } from '@vip/state/utils';
import { ICompra, IPortador } from '@vip/core';

export const COMPRA_EM_PROCESSO_PROVIDER_FEATURE_KEY = 'compra-em-processo';

export interface CompraEmProcessoProviderState
  extends IGenericState<ICompra | null>,
    EntityState<ICompra> {
  compraEmProcessoId: number | null;
}

export const compraEmProcessoProviderAdapter: EntityAdapter<ICompra> =
  createEntityAdapter<ICompra>();

export const initialState: CompraEmProcessoProviderState =
  compraEmProcessoProviderAdapter.getInitialState({
    data: null,
    status: 'pending',
    compraEmProcessoId: null,
    error: null,
  });
const compraEmProcessoProviderReducer = createReducer(initialState);

export function reducer(
  state: CompraEmProcessoProviderState | undefined,
  action: Action
) {
  return compraEmProcessoProviderReducer(state, action);
}
