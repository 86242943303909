import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from './accordion/accordion.component';
import { CollapseComponent } from './collapse.component';
import { CollapseContentDirective } from './collapse-content.directive';
import { CollapseHeaderDirective } from './collapse-header.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    AccordionComponent,
    CollapseComponent,
    CollapseContentDirective,
    CollapseHeaderDirective,
  ],
  exports: [
    AccordionComponent,
    CollapseComponent,
    CollapseContentDirective,
    CollapseHeaderDirective,
  ],
})
export class AccordionModule {}
