import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replaceVariables', pure: true })
export class ReplaceVariablesPipe implements PipeTransform {
  transform(text: string = '', variables: { [key: string]: string | number }) {
    if (!text) return '';
    let new_text = text;
    Object.entries(variables).forEach(([key, value]) => {
      new_text = new_text.replace(`{{${key}}}`, String(value));
    });
    return new_text;
  }
}
