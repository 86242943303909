import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { RadioButtonComponent } from '../radio-button/radio-button.component';
import { Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'vip-radio-list-item',
  templateUrl: './radio-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./radio-list-item.component.scss'],
  providers: [
    { provide: RadioButtonComponent, useExisting: RadioListItemComponent },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class RadioListItemComponent extends RadioButtonComponent {
  @Input() icon = '';
  @Input() color: 'primary' | 'secondary' = 'primary';
  @Input() label!: string;
  @Input() count = '';
  @Input() value!: string;
  @Input() invisible = false;
  @Output() changed = new EventEmitter();

  constructor(changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }

  public get rippleColor() {
    return 'vip-color-complementary-second-main';
  }
}
