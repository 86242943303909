import { Pipe, PipeTransform } from '@angular/core';
import { IProduto } from '../../interfaces';

@Pipe({ name: 'quantidadeProduto' })
export class QuantidadeProdutoPipe implements PipeTransform {
  transform(
    produto: IProduto,
    produtosNoCarrinho:
      | Map<
          number,
          { item_id: number; quantidade: number; observacao?: string }
        >
      | null
      | undefined
  ): number {
    return (
      produtosNoCarrinho?.get(produto.produto_id)?.quantidade ||
      produto.quantidade ||
      0
    );
  }
}
