import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { InfiniteScrollDirective } from './infinite-scroll-directive/infinite-scroll.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [InfiniteScrollComponent, InfiniteScrollDirective],
  exports: [InfiniteScrollComponent, InfiniteScrollDirective],
})
export class InfiniteScrollModule {}
