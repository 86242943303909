<vip-field
  #field
  [icon]="
    !isTelevendas
      ? currentValue
        ? 'close search-icon-color'
        : 'search search-icon-color'
      : ''
  "
  iconDirection="right"
  [isLoading]="isLoading"
  (iconClick)="currentValue && clear()"
>
  <input
    #input
    class="search-input custom-placeholder disabled:opacity-60"
    [ngClass]="{
      retangleInput: typeInput === 'retangle',
      'input-background': isDesktop
    }"
    autocomplete="off"
    [placeholder]="placeholder"
    [(ngModel)]="currentValue"
    (keydown)="handleKeydown($event)"
    (input)="handleInput($event)"
    (focus)="focusOnInitChange.emit(true)"
    (blur)="focusOnInitChange.emit(false)"
    [attr.disabled]="disabled ? true : null"
  />
</vip-field>
