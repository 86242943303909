import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  FormaEntregaEnum,
  ICentroDistribuicao,
  IEndereco,
  IHorariosFormatados,
  ISwitchOption,
  ITipoEntrega,
  environment,
} from '@vip/core';
import { Observable } from 'rxjs';

@UntilDestroy()
@Directive()
export abstract class EntregaRetiradaPropertiesBaseDirective {
  @Input()
  enderecos: IEndereco[] = [];

  private _cdsEntrega: ICentroDistribuicao[] = [];
  @Input()
  get cdsEntrega() {
    return this._cdsEntrega;
  }
  set cdsEntrega(cdsEntrega) {
    this._cdsEntrega = cdsEntrega;
    this.populateTiposEntregasOptions();
  }

  private _cdsRetirada: ICentroDistribuicao[] = [];
  @Input()
  get cdsRetirada() {
    return this._cdsRetirada;
  }
  set cdsRetirada(cdsRetirada) {
    this._cdsRetirada = cdsRetirada;
    this.populateTiposEntregasOptions();
  }

  private _formaEntrega!: number;
  @Input()
  get formaEntrega() {
    return this._formaEntrega;
  }
  set formaEntrega(formaEntrega) {
    if (this.tiposEntregasOptions && formaEntrega) {
      this.tiposEntregasOptions['checked'] =
        this.tiposEntregasOptions[formaEntrega];
    }
    this._formaEntrega = formaEntrega;
  }

  @Input()
  enderecoSelecionado?: IEndereco | null;

  @Input()
  realizaEntrega = true;

  @Input()
  possuiLojaAutonoma = false;

  @Input()
  isLojaAutonoma = false;

  @Input()
  isFromResumoCompra = false;

  @Input()
  formaEntregaFromState!: number;

  @Input()
  cdSelecionado!: ICentroDistribuicao | null;

  @Input()
  novoCd?: ICentroDistribuicao | null;

  @Input()
  novaFormaEntrega?: number | null;

  @Input()
  cdSelecionadoProperty!: ICentroDistribuicao | null;

  @Input()
  horarioSelecionado$!: Observable<IHorariosFormatados>;

  _tipoEntregaSelecionado: ITipoEntrega | null = null;
  @Input()
  set tipoEntregaSelecionado(value: ITipoEntrega | null | undefined) {
    this._tipoEntregaSelecionado = value || null;
  }
  get tipoEntregaSelecionado(): ITipoEntrega | null {
    return this._tipoEntregaSelecionado;
  }

  @Input()
  exibirTiposEntrega?: boolean | null;

  @Input()
  exibirTiposRetirada?: boolean | null;

  formaEntregaEnum = FormaEntregaEnum;

  tiposEntregasOptions?: { [key: string | number]: ISwitchOption };

  get retiradaLoja(): boolean {
    return this.formaEntregaFromState === FormaEntregaEnum.TIPO_RETIRADA;
  }

  @Output()
  formaEntregaChange = new EventEmitter<number>();

  @Output()
  enderecoEntregaChange: EventEmitter<IEndereco> = new EventEmitter();

  @Output()
  cdChange: EventEmitter<{ cd: ICentroDistribuicao; isFromRetirada: boolean }> =
    new EventEmitter();

  @Output()
  entregaChange: EventEmitter<{ isEntrega: boolean; modifyState: boolean }> =
    new EventEmitter();

  @Output()
  horarioChange = new EventEmitter<IHorariosFormatados>();

  @Output()
  confirmAlterarHorarioClick = new EventEmitter<void>();

  populateTiposEntregasOptions(): void {
    this.tiposEntregasOptions = {
      [FormaEntregaEnum.TIPO_ENTREGA]: {
        label: environment.isApp ? 'Entrega' : 'Receber em Casa',
        icon: 'icon-local_shipping',
        key: FormaEntregaEnum.TIPO_ENTREGA,
        disabled: false,
      },
      [FormaEntregaEnum.TIPO_RETIRADA]: {
        label: environment.isApp ? 'Retirada' : 'Retirar na Loja',
        icon: 'icon-directions_walk',
        key: FormaEntregaEnum.TIPO_RETIRADA,
        disabled: this.cdsRetirada.length === 0,
      },
    };

    if (
      this.possuiLojaAutonoma &&
      !this.isFromResumoCompra &&
      environment.isApp
    ) {
      this.tiposEntregasOptions[FormaEntregaEnum.TIPO_AUTONOMA] = {
        label: 'Loja Autônoma',
        icon: 'icon-qr_code_scanner',
        key: FormaEntregaEnum.TIPO_AUTONOMA,
        disabled: false,
      };
    }
    this.formaEntrega = this.formaEntrega || this.formaEntregaFromState;
  }
}
