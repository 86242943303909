<div class="flex flex-col text-center">
  <ng-container *ngIf="!captchaValidado; else finalizandoCompra">
    <h2 class="vip-modal-title">Verificação de segurança</h2>
    <re-captcha class="my-3 mx-auto" (resolved)="resolved($event)"></re-captcha>
  </ng-container>
  <ng-template #finalizandoCompra>
    <h2 class="vip-modal-title">Por favor, aguarde...</h2>
    <vip-loading-spinner></vip-loading-spinner>
  </ng-template>
</div>
