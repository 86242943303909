import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeEcommerceContainerComponent } from './home-ecommerce-container/home-ecommerce-container.component';
import { RouterModule } from '@angular/router';
import { HomeEcommerceModule } from '@vip/views/home-ecommerce';
import { ClassificacaoMercadologicaStateModule } from '@vip/state/classificacao-mercadologica';
import { EnderecoStateModule } from '@vip/state/endereco';
import { BannerStateModule } from '@vip/state/banner';
import { CoreModule } from '@vip/core';
import { AtualizarCarrinhoService } from '@vip/container/carrinho';
import { CentroDistribuicaoStateModule } from '@vip/state/centro-distribuicao';
import { VitrineDedicadaStateModule } from '@vip/state/vitrine-dedicada';
import { ProdutoStateModule } from '@vip/state/produto';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: HomeEcommerceContainerComponent,
      },
    ]),
    HomeEcommerceModule,
    ClassificacaoMercadologicaStateModule,
    EnderecoStateModule,
    BannerStateModule,
    CoreModule,
    CentroDistribuicaoStateModule,
    VitrineDedicadaStateModule,
    ProdutoStateModule,
  ],
  declarations: [HomeEcommerceContainerComponent],
  providers: [AtualizarCarrinhoService],
})
export class HomeEcommerceContainerModule {}
