import { Inject, Injectable } from '@angular/core';
import {
  STORAGE,
  IStorage,
  LayoutUtilsService,
  DEFAULT_COOKIE_EXPIRATION_TIME,
  FAVICON,
} from '@vip/core';

@Injectable({
  providedIn: 'root',
})
export class FaviconService {
  private faviconCache: string | null = null;
  readonly defaultExpirationTime = DEFAULT_COOKIE_EXPIRATION_TIME;
  private isSite = this.layoutUtilsService.isDesktopWithoutScreenWidth();

  constructor(
    @Inject(STORAGE) readonly storage: IStorage,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  getFavicon(): string | null {
    if (this.faviconCache) {
      return this.faviconCache;
    }
    this.faviconCache = this.storage.getItem(FAVICON) || '';
    return this.faviconCache;
  }

  setFavicon(value: string) {
    if (this.isSite) {
      this.storage.setItem(FAVICON, value, this.defaultExpirationTime, '/');
      return;
    }
    this.storage.setItem(FAVICON, value);
  }

  clearFavicon() {
    this.faviconCache = null;
    if (this.isSite) {
      this.storage.removeItem(FAVICON, '/');
      return;
    }
    this.storage.removeItem(FAVICON);
  }
}
