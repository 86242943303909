<vip-substituir-produto
  class="vip-page-content"
  [ngClass]="{ 'modal-substituir-produto-container': isModal }"
  [isModal]="isModal"
  [produtosIndisponiveis]="(produtosIndisponiveis$ | async) ?? []"
  [produtoIndisponivelSelecionado]="produtoIndisponivelSelecionado$ | async"
  [produtosSimilares]="(produtosSimilaresESubstituto$ | async) ?? []"
  [produtoSubstituto]="(produtoSubstituto$ | async) ?? undefined"
  [termoPesquisado]="(termoPesquisado$ | async) || ''"
  (productChange)="buscarNovosProdutosSimilares($event)"
  (productBack)="buscarProdutoSubstituto($event)"
  (replaceProducts)="substituirProdutos()"
  (substitutoChange)="handleSubstitutoChange($event)"
  (searchClear)="cancelarBusca()"
  (search)="onSearch($event)"
  (goBack)="goBackHandler($event)"
  (initSubstituirProduto)="permitirAtualizarCarrinho()"
  (naoSubstituirProdutos)="naoSubstituirProdutos()"
></vip-substituir-produto>
