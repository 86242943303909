import { ComponentRef, Injectable } from '@angular/core';
import { ComponentInjectorService } from '@vip/core';
import { PageComponent } from '../page.component';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  public pageComponent?: ComponentRef<PageComponent>;

  constructor(
    private componentInjector: ComponentInjectorService<PageComponent>
  ) {}

  openPage(page: { title: string; text: string }) {
    if (!this.pageComponent)
      this.pageComponent =
        this.componentInjector.createComponentInApplication(PageComponent);

    Object.assign(this.pageComponent.instance, { ...page });
  }

  closePage() {
    if (this.pageComponent)
      this.componentInjector.destroyComponentInApplication(this.pageComponent);

    this.pageComponent = undefined;
  }
}
