import { createSelector } from '@ngrx/store';
import { SelectorUtil } from '@vip/state/utils';
import { SESSAO_FEATURE_KEY, SessaoState } from './sessao.reducer';

class SessaoSelectors extends SelectorUtil<SessaoState> {
  constructor() {
    super(SESSAO_FEATURE_KEY);
  }

  getSessaoId = () =>
    createSelector(
      this.createFeatureSelector,
      (state: SessaoState) => state.data?.sessaoId
    );
}

export const sessaoSelectors = new SessaoSelectors();
