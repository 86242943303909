<ng-container *ngIf="isCard; else tileTemplate">
  <vip-selectable-card
    (changed)="changed.emit(endereco)"
    [checked]="selecionado"
    [disableAutoToggle]="true"
    *ngIf="endereco"
    data-cy="endereco-card"
  >
    <div title class="font-medium text-sm">
      {{ endereco.titulo }}
    </div>
    <div body class="vip-color-secondary-default text-sm">
      {{ endereco.endereco_formatado }}
    </div>
  </vip-selectable-card>

  <vip-selectable-card
    (changed)="changed.emit(cd)"
    [checked]="selecionado"
    [disableAutoToggle]="true"
    *ngIf="cd"
    data-cy="cd-card"
  >
    <div title class="font-medium text-sm">
      {{ cd.nome_site }}
    </div>
    <div body class="vip-color-secondary-default text-sm">
      <ng-container
        *ngIf="cd.tipo_exibicao_retirada === tipoExibicaoRetirada.ENDERECO"
      >
        {{ cd.endereco.logradouro }}, {{ cd.endereco.numero }},
        <ng-container *ngIf="cd.endereco.complemento">
          {{ cd.endereco.complemento }} -
        </ng-container>
        {{ cd.endereco.bairro }} - {{ cd.endereco.cidade }} -
        {{ cd.endereco.estado }}
      </ng-container>
      <ng-container
        *ngIf="cd.tipo_exibicao_retirada === tipoExibicaoRetirada.OBSERVACAO"
      >
        {{ cd.observacao_retirada }}
      </ng-container>
    </div>
  </vip-selectable-card>
</ng-container>
<ng-template #tileTemplate>
  <vip-selectable-tile
    *ngIf="endereco"
    (changed)="changed.emit(endereco)"
    [checked]="selecionado"
    [disableAutoToggle]="true"
    icon="icon-location_on"
    data-cy="endereco-tile"
  >
    <div title>
      {{ endereco.endereco_formatado }}
    </div>
  </vip-selectable-tile>

  <vip-selectable-tile
    *ngIf="cd"
    (changed)="changed.emit(cd)"
    [checked]="selecionado"
    [changeTextColor]="showBody"
    [disableAutoToggle]="true"
    [icon]="isIconLocation ? 'icon-location_on' : 'icon-store_mall'"
    data-cy="cd-tile"
  >
    <div title>
      {{ cd.nome_site }}
    </div>
    <div body *ngIf="showBody" data-cy="tile-body">
      <ng-container
        *ngIf="cd.tipo_exibicao_retirada === tipoExibicaoRetirada.ENDERECO"
      >
        {{ cd.endereco.logradouro }}, {{ cd.endereco.numero }},
        <ng-container *ngIf="cd.endereco.complemento">
          {{ cd.endereco.complemento }} -
        </ng-container>
        {{ cd.endereco.bairro }} - {{ cd.endereco.cidade }} -
        {{ cd.endereco.estado }}
      </ng-container>
      <ng-container
        *ngIf="cd.tipo_exibicao_retirada === tipoExibicaoRetirada.OBSERVACAO"
      >
        {{ cd.observacao_retirada }}
      </ng-container>
    </div>
  </vip-selectable-tile>
</ng-template>
