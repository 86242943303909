<div
  *ngFor="let produto of produtos; let last = last"
  class="flex flex-row mt-2 pb-2 border-gray-300 border-solid overflow-hidden"
  [ngClass]="{ 'border-b-1': !last }"
  data-cy="produto"
>
  <vip-image
    [alt]="produto.descricao"
    [imageUrl]="produto.imagem || ''"
    [size]="imageSizesEnum.md"
    class="mr-3 flex justify-center flex-shrink-0"
  ></vip-image>

  <div class="flex flex-col w-full gap-1 text-xs">
    <div class="flex flex-row gap-2 items-center">
      <span class="font-bold text-sm mb-0">
        {{ produto.preco_venda | currency: 'BRL' }}
      </span>
      <span class="mb-0 text-secondary-default-main">
        {{ produto.preco | currency: 'BRL' }} |
        {{ produto.unidade_sigla | lowercase }}
      </span>
    </div>

    <span class="mb-1 mr-3 vip-color-first-default mb-0">
      {{ produto.descricao }}
    </span>

    <span class="font-medium mb-1 mr-3 text-secondary-default-main mb-0">
      {{ produto.quantidade }}
      {{ produto.unidade_sigla.toUpperCase() !== 'UN' ? 'x' : '' }}
      {{
        produto.quantidade_unidade_diferente
          | unidadeDiferente: produto.unidade_sigla
      }}
    </span>
  </div>
</div>
