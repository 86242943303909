import { LOCALE_ID, NgModule } from '@angular/core';
import {
  CommonModule,
  CurrencyPipe,
  registerLocaleData,
  TitleCasePipe,
} from '@angular/common';

import { ScreenTitleModule } from '@vip/ui/screen-title';
import { ButtonModule } from '@vip/ui/button';
import { SelectableCardModule } from '@vip/ui/selectable-card';
import { EntregaRetiradaComponent } from './entrega-retirada/entrega-retirada.component';
import { AlterarEntregaRetiradaComponent } from './alterar-entrega-retirada/alterar-entrega-retirada.component';
import { AlterarHorariosComponent } from './alterar-horarios/alterar-horarios.component';
import { CoreModule } from '@vip/core';
import localePt from '@angular/common/locales/pt';
import { RouterModule } from '@angular/router';
import { DayOfWeekWithFullDatePipe } from './alterar-horarios/pipes/day-of-week-with-fulldate.pipe';
import { SkeletonModule } from '@vip/ui/skeleton';
import { SwitchModule } from '@vip/ui/switch';
import { CardEnderecoEntregaModule } from '@vip/ui/card-endereco-entrega';
import { AlterarHorariosSkeletonComponent } from './alterar-horarios-skeleton/alterar-horarios-skeleton.component';
import { EnderecoEntregaRetiradaComponent } from './endereco-entrega-retirada/endereco-entrega-retirada.component';

registerLocaleData(localePt, 'pt');

@NgModule({
  imports: [
    CommonModule,
    ScreenTitleModule,
    ButtonModule,
    SelectableCardModule,
    CoreModule,
    RouterModule,
    SkeletonModule,
    SwitchModule,
    CardEnderecoEntregaModule,
  ],
  declarations: [
    EntregaRetiradaComponent,
    AlterarEntregaRetiradaComponent,
    AlterarHorariosComponent,
    DayOfWeekWithFullDatePipe,
    AlterarHorariosSkeletonComponent,
    EnderecoEntregaRetiradaComponent,
  ],
  exports: [
    EntregaRetiradaComponent,
    AlterarEntregaRetiradaComponent,
    AlterarHorariosComponent,
    EnderecoEntregaRetiradaComponent,
    DayOfWeekWithFullDatePipe,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    CurrencyPipe,
    TitleCasePipe,
  ],
})
export class EntregaRetiradaModule {}
