import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ClienteStateModule } from '@vip/state/cliente';
import * as fromSessao from './+state/sessao.reducer';
import { SessaoEffects } from './+state/sessao.effects';
import { SessaoFacade } from './+state/sessao.facade';
import { ParametrosStateModule } from '@vip/state/parametros';

@NgModule({
  imports: [
    CommonModule,
    ClienteStateModule,
    ParametrosStateModule,
    StoreModule.forFeature(fromSessao.SESSAO_FEATURE_KEY, fromSessao.reducer),
    EffectsModule.forFeature([SessaoEffects]),
  ],
  providers: [SessaoFacade],
})
export class SessaoStateModule {}
