import { CARRINHO_FEATURE_KEY, CarrinhoState } from './carrinho.reducer';
import { SelectorUtil } from '@vip/state/utils';
import { createSelector } from '@ngrx/store';
import { groupBy } from 'lodash';
import { IProdutosCarrinhoGroup } from '@vip/core';

export class CarrinhoSelectors extends SelectorUtil<CarrinhoState> {
  constructor() {
    super(CARRINHO_FEATURE_KEY);
  }

  getItensCarrinhoAntigo = () =>
    createSelector(this.createFeatureSelector, (state: CarrinhoState) => {
      const itens = groupBy(
        state.itensCarrinhoAntigo,
        (item) => item?.departamento?.descricao || 'Combos'
      );

      return Object.entries(itens)
        .map(
          ([key, value]) =>
            ({ departamento: key, produtos: value } as IProdutosCarrinhoGroup)
        )
        .sort((item) => (item.departamento == 'Combos' ? -1 : 0));
    });

  getCarrinhoEmOutroCD = () =>
    createSelector(this.createFeatureSelector, (state: CarrinhoState) => {
      return state.carrinhoEmOutroCD;
    });
}

export const carrinhoSelectors = new CarrinhoSelectors();
