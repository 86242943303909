import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { IApiPaginator, ICarousel, IMAGES_PATH, IProduto } from '@vip/core';
import { ProdutosAndFiltrosDirective } from '../produtos-and-filtro/produtos-and-filtros.directive';
import { CampanhaPatrocinadoService } from '@vip/api';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';

@Component({
  selector: 'vip-lista-produtos',
  templateUrl: './lista-produtos.component.html',
  styleUrls: ['./lista-produtos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ListaProdutosComponent
  extends ProdutosAndFiltrosDirective
  implements OnChanges
{
  @Input() title!: string;
  @Input() carousel: ICarousel | null = null;

  @Input() emptyText = 'Nenhum produto encontrado';

  @Input() imageUrl!: string;

  @Input() scrollSelector?: string;

  @Input()
  responsive = false;

  @Input()
  paginator?: IApiPaginator;

  @Input() adsValue: boolean | null = false;

  @Input() distanceScroll = 10;

  @Output()
  pageClick = new EventEmitter<number>();

  @Output()
  visible = new EventEmitter<IProduto>();

  valueAds: boolean | null = false;

  constructor(
    @Inject(IMAGES_PATH) private imagesPath: string,
    private changeDetector: ChangeDetectorRef,
    private campanhaPatrocinadoService: CampanhaPatrocinadoService,
    carrinhoItensFacade: CarrinhoItensFacade
  ) {
    super(carrinhoItensFacade);
    this.imageUrl = `${this.imagesPath}shelf.svg`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loaded) {
      this.loaded = changes.loaded.currentValue;
      this.changeDetector.detectChanges();
    } else if (changes.products) {
      this.products = changes.products.currentValue;
      this.changeDetector.detectChanges();
    }

    this.valueAds = this.adsValue;
    this.updateProductsWithCartInfo();
  }

  getClique(event: IProduto) {
    this.campanhaPatrocinadoService.clique(event);
  }
}
