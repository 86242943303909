import { FILIAL_FEATURE_KEY, FilialState } from './filial.reducer';
import { SelectorUtil } from '@vip/state/utils';
import { createSelector } from '@ngrx/store';

export class FilialSelectors extends SelectorUtil<FilialState> {
  constructor() {
    super(FILIAL_FEATURE_KEY);
  }

  getCdPadraoId = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FilialState) => state.data?.centro_distribuicao_padrao_id
    );

  permitirSalvarCartao = () =>
    createSelector(
      this.getData(),
      (filial) => filial?.permitir_cofre_cartoes || false
    );

  exibePrecoOriginal = () =>
    createSelector(
      this.getData(),
      (filial) => filial?.exibe_preco_original || false
    );

  permitirBandeiraAutomatica = () =>
    createSelector(
      this.getData(),
      (filial) => filial?.permitir_bandeira_automatica || false
    );

  getScripts = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FilialState) => state.scripts
    );
}

export const filialSelectors = new FilialSelectors();
