import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MinhaContaMenuComponent } from './minha-conta-menu/minha-conta-menu.component';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@vip/core';

@NgModule({
  imports: [CommonModule, RouterModule, CoreModule],
  declarations: [MinhaContaMenuComponent],
  exports: [MinhaContaMenuComponent],
})
export class MinhaContaMenuModule {}
