import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookiesComponent } from './cookies/cookies.component';
import { ButtonModule } from '@vip/ui/button';

@NgModule({
  imports: [CommonModule, ButtonModule],
  declarations: [CookiesComponent],
  exports: [CookiesComponent],
})
export class CookiesModule {}
