import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ICarousel, IPaginaInstitucional, LayoutUtilsService } from '@vip/core';
import { InstitucionalFacade } from '@vip/state/institucional';
import { BannerFacade } from '@vip/state/banner';

@UntilDestroy()
@Component({
  selector: 'vip-institucional-container',
  templateUrl: './institucional-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstitucionalContainerComponent implements OnInit {
  paginas$ = this.institucionalFacade.paginas$;
  banners$!: Observable<ICarousel | null>;
  pagina!: IPaginaInstitucional;
  isMobile = this.layoutUtilsService.isMobile();

  constructor(
    private activatedRoute: ActivatedRoute,
    private institucionalFacade: InstitucionalFacade,
    private bannerFacade: BannerFacade,
    private changeDetector: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router
  ) {
    this.institucionalFacade.getPaginasInstitucionais();
  }

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        if (params.slug) {
          this.paginas$.pipe(untilDestroyed(this)).subscribe((_paginas) => {
            this.pagina = _paginas.find(
              (_pag) => _pag.slug == params.slug
            ) as IPaginaInstitucional;
            this.changeDetector.detectChanges();
          });

          this.getBanners(params.slug);
        }
      });
  }

  private getBanners(slug: string) {
    this.bannerFacade.getBannersInstitucionais(slug);
    this.banners$ = this.bannerFacade.bannersInstitucionais$;
  }
}
