<ng-container *ngIf="faixa; else ofertaNormal">
  <span
    class="vip-oferta-tag vip-oferta-tag--min-width"
    data-cy="tag-oferta"
    [ngClass]="[oferta.classe_oferta]"
    [class.is-card]="isCard"
    [class.text-tiny]="
      tamanho === TamanhoProdutoPreco.PEQUENO ||
      tamanho === TamanhoProdutoPreco.GRANDE
    "
    [class.text-xs]="tamanho === TamanhoProdutoPreco.MEDIO"
    routerLink="/oferta/{{ oferta.tag }}"
    *ngIf="mostrarTextoDeUnidades; else naoMostrarTextoDeUnidades"
  >
    {{
      isCard
        ? (faixa.quantidade | i18nPlural: unidadesCardPluralMapping)
        : (faixa.quantidade | i18nPlural: unidadesPluralMapping)
    }}
  </span>
  <ng-template #naoMostrarTextoDeUnidades>
    <span
      class="vip-oferta-tag vip-oferta-tag--reverse"
      data-cy="tag-oferta"
      [ngClass]="[oferta.classe_oferta]"
      [class.is-card]="isCard"
      [class.text-tiny]="
        tamanho === TamanhoProdutoPreco.PEQUENO ||
        tamanho === TamanhoProdutoPreco.GRANDE
      "
      [class.text-xs]="tamanho === TamanhoProdutoPreco.MEDIO"
      routerLink="/oferta/{{ oferta.tag }}"
      >{{ faixa.quantidade }} por</span
    >
  </ng-template>
</ng-container>
<ng-template #ofertaNormal>
  <div
    class="vip-oferta-tag"
    [class.vip-oferta-tag--reverse]="!mostrarTextoDeUnidades"
    data-cy="tag-oferta"
    [class.is-card]="isCard"
    [ngClass]="[oferta.classe_oferta]"
    routerLink="/oferta/{{ oferta.tag }}"
    [class.text-tiny]="tamanho === TamanhoProdutoPreco.PEQUENO"
    [class.text-xs]="tamanho === TamanhoProdutoPreco.MEDIO"
  >
    {{
      oferta.classe_oferta === 'oferta-atacarejo'
        ? (oferta | offerTitle: false)
        : (oferta | offerTitle: true)
    }}
  </div>
  <div
    class="flex items-center ml-2"
    [ngClass]="isCard ? 'flex-row-reverse justify-end' : ''"
    *ngIf="descontoSimples"
  >
    <span
      class="line-through vip-color-third-default {{
        !isCard
          ? 'text-lg'
          : tamanho === TamanhoProdutoPreco.PEQUENO
          ? 'text-xs'
          : 'text-sm'
      }}"
      data-cy="preco-antigo"
    >
      {{ oferta.preco_antigo | currency: 'BRL' }}
    </span>
  </div>
</ng-template>
