import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[vipDropdownContent]',
})
export class DropdownContentDirective implements OnInit {
  nativeElement: HTMLElement;
  @Input() addClass = true;
  @Input() borderInfinite = false;

  constructor(
    public element: ElementRef,
    _elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) {
    this.nativeElement = _elementRef.nativeElement;
  }

  ngOnInit(): void {
    if (this.addClass) {
      this.renderer.addClass(this.nativeElement, 'vip-dropdown');
      if (this.borderInfinite)
        this.renderer.addClass(this.nativeElement, 'vip-infinite');
    }
  }
}

@Directive({
  selector: '[vipDropdownButton]',
})
export class DropdownButtonDirective {
  nativeElement: HTMLElement;

  constructor(_elementRef: ElementRef) {
    this.nativeElement = _elementRef.nativeElement;
  }
}

@Directive({
  selector: '[vipDropdown]',
})
export class DropdownDirective implements AfterViewInit {
  constructor(
    private element: ElementRef,
    @Inject(DOCUMENT) private _document: any
  ) {}

  @ContentChild(DropdownContentDirective)
  dropdownContent!: DropdownContentDirective;
  @ContentChild(DropdownButtonDirective)
  dropdownButton!: DropdownButtonDirective;
  @Input()
  dontTranslate = false;
  @Input()
  disableDropdown = false;
  @Output()
  openChange = new EventEmitter<boolean>();

  public open = false;

  private _shouldClose = true;

  public getNativeElement(): HTMLElement {
    return this.element.nativeElement;
  }

  ngAfterViewInit(): void {
    if (this.dropdownContent && this.dropdownButton) {
      this.element.nativeElement.style.position = 'relative';
      this.dropdownContent.nativeElement.style.display = 'none';
      this.dropdownContent.nativeElement.style.position = 'absolute';
      this.toggleAria();
    }
  }

  @HostListener('document:mousedown', ['$event']) setShouldClose(event: Event) {
    if (this.disableDropdown) return;
    this._shouldClose = !this.element.nativeElement.contains(event.target);
  }

  @HostListener('document:click', ['$event']) toggleOpen(event: Event) {
    if (this.disableDropdown) return;
    if (this.dropdownButton.nativeElement.contains(event.target as Node)) {
      this.toggle();
    } else if (this._shouldClose) this.close();
  }

  // TODO: fazer verificação para vertical também
  setupDropdownPosition() {
    const dropDownContent = this.dropdownContent.nativeElement;
    const dropDownRect =
      this.dropdownContent.nativeElement.getBoundingClientRect();

    const screenWidth = this._document.documentElement.clientWidth;
    const dropDownContentWidth = dropDownContent.clientWidth;
    const dropDownLeft = dropDownRect.x;
    const fitInScreen = screenWidth > dropDownContentWidth;

    const fitRight = screenWidth - dropDownLeft > dropDownContentWidth;

    if (!fitRight && fitInScreen) dropDownContent.style.right = '0';
    if (!this.dontTranslate)
      dropDownContent.style.transform = `translateY(5px)`;
  }

  toggle() {
    this.open = !this.open;
    this.toggleDisplay();
    this.toggleAria();
  }

  close() {
    this.open = false;
    this.toggleDisplay();
    this.toggleAria();
  }

  toggleDisplay() {
    this.dropdownContent.nativeElement.style.display = this.open
      ? 'block'
      : 'none';
    this.openChange.emit(this.open);
    if (this.open) this.setupDropdownPosition();
  }

  toggleAria() {
    this.dropdownButton.nativeElement.setAttribute(
      'aria-expanded',
      this.open.toString()
    );
  }
}
