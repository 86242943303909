import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormaPagamentoEnum, ICompraPagamento } from '@vip/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageSizesEnum } from '@vip/ui/image';
import { ModalTrocoService } from '../modal-troco/modal-troco.service';

@Component({
  selector: 'vip-card-pagamento',
  templateUrl: './card-pagamento.component.html',
  styleUrls: ['./card-pagamento.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardPagamentoComponent {
  @Input() pagamentos: ICompraPagamento[] = [];
  @Input() totalCompra!: number;
  @Input() editable = false;
  @Input() valorTroco = 0;

  @Output() alterarPagamentoClick = new EventEmitter();
  @Output() alterarParcelasClick = new EventEmitter();
  ImageSizesEnum = ImageSizesEnum;

  readonly FORMA_PAGAMENTO_ENUM = { ...FormaPagamentoEnum };

  constructor(
    public sanitizer: DomSanitizer,
    private modalTrocoService: ModalTrocoService
  ) {}

  get cardHabiltado(): boolean {
    return !!this.totalCompra && this.editable;
  }

  get cardClickHabilitado() {
    return this.cardHabiltado && this.pagamentos.length === 0;
  }

  clickCard(event: MouseEvent) {
    if (this.cardClickHabilitado) this.alterarPagamentoClick.emit(event);
  }

  trocoClick() {
    this.modalTrocoService.selecionarTroco(this.totalCompra, this.valorTroco);
  }
}
