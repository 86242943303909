import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CupomFacade } from '@vip/state/cupom';
import { Observable } from 'rxjs';
import {
  ICodigoPromocional,
  ICupomCredito,
  LayoutUtilsService,
} from '@vip/core';
import { MessageService } from '@vip/ui/message';
import { DescontoFacade } from '@vip/state/desconto';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { DialogService } from '@vip/ui/modal';
import { LayoutStore } from '@vip/state/layout';

@UntilDestroy()
@Component({
  selector: 'vip-pagamento-cupons-container',
  templateUrl: './pagamento-cupons-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagamentoCuponsContainerComponent implements OnInit {
  cupons$: Observable<ICupomCredito[]>;
  cuponsSelecionados$: Observable<ICupomCredito[]>;
  codigoPromocional$: Observable<ICodigoPromocional>;

  constructor(
    private cupomFacade: CupomFacade,
    private messageService: MessageService,
    public descontoFacade: DescontoFacade,
    private dialogService: DialogService,
    private layoutStore: LayoutStore,
    private layoutUtilsService: LayoutUtilsService
  ) {
    this.cupons$ = this.cupomFacade.meusCupons$;
    this.cuponsSelecionados$ = this.cupomFacade.cuponsSelecionados$;
    this.codigoPromocional$ = this.descontoFacade.codigoPromocional$;
  }

  ngOnInit(): void {
    this.cupomFacade.getCuponsDisponiveis();
    if (this.layoutUtilsService.isDesktopResponsive())
      this.layoutStore.setHeaderVisibility(false);
  }

  onChangeCuponsSelecionados(event: {
    cupons: ICupomCredito[];
    adicionado: boolean;
  }) {
    this.cupomFacade.setCuponsSelecionados(event.cupons);

    if (event.adicionado) {
      this.messageService.openSuccessMessage('Cupom ativado com sucesso!', 1.5);
    }
  }

  validarCodigo(novoCodigo: string): void {
    this.codigoPromocional$
      .pipe(take(1), untilDestroyed(this))
      .subscribe((codigoPromocional) => {
        const codigoAplicado = codigoPromocional?.codigo;
        novoCodigo = novoCodigo.trim().toUpperCase();
        if (codigoAplicado && codigoAplicado !== novoCodigo) {
          this.exibirDialogCodigoPromocionalJaAplicado(
            codigoAplicado,
            novoCodigo
          );
        } else {
          this.descontoFacade.validarCodigo(novoCodigo);
        }
      });
  }

  exibirDialogCodigoPromocionalJaAplicado(
    codigoAplicado: string,
    novoCodigo: string
  ): void {
    this.dialogService.openDialog({
      open: true,
      title: 'Você já está utilizando um código promocional!',
      subTitle: `Só é possível utilizar um código promocional por compra. Deseja trocar o código <b>${codigoAplicado}</b> pelo <b>${novoCodigo}</b>?`,
      disabled: false,
      buttonConfirmText: 'Trocar código promocional',
      buttonCancelText: 'Manter código promocional atual',
    });
    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe((trocar) => {
        if (trocar) {
          this.descontoFacade.validarCodigo(novoCodigo);
        }
        this.dialogService.clearDialog();
      });
  }
}
