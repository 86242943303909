import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IClassificacaoMercadologica } from '@vip/core';

export const loadArvore = createAction(
  '[ClassificacaoMercadologica/Page] Load Arvore'
);

export const loadArvoreSuccess = createAction(
  '[ClassificacaoMercadologica/Page] Load Arvore Success',
  props<{ arvore: IClassificacaoMercadologica[] }>()
);

export const loadArvoreFailure = createAction(
  '[ClassificacaoMercadologica/Page] Load Arvore Failure',
  props<{ error: HttpErrorResponse }>()
);
