<vip-alterar-entrega-retirada
  class="vip-page-content"
  [ngClass]="{ 'pt-1': isDesktopResponsive }"
  [isLoading]="!!(isLoading$ | async)"
  [qtdItensCarrinho]="(qtdItensCarrinho$ | async) || 0"
  [isRetirada]="(isEntrega$ | async) === false"
  [tiposEntrega]="tipoEntregas$ | async"
  [tipoSelecionado]="(tipoSelecionado$ | async) || null"
  (tipoEntregaChange)="handleTipoEntregaChange($event)"
  (goBackClick)="voltar()"
></vip-alterar-entrega-retirada>
