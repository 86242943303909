<div class="vip-dropdown-secoes" [class.categorias-layout]="hasCategories">
  <ng-container *ngIf="hasCategories; else secaoTemplate">
    <div
      class="secao-container"
      [ngClass]="{
        'count-2': itemsQuantity === 2,
        'count-3': itemsQuantity >= 3
      }"
    >
      <div
        *ngFor="let secao of items; trackBy: trackByFn"
        class="secao-wrapper"
      >
        <b
          class="submenu-item"
          (click)="handleButtonClick(secao)"
          [attr.data-cy]="'secao-item-' + secao.classificacao_mercadologica_id"
        >
          {{ secao.descricao }}
        </b>
        <ul>
          <li
            *ngFor="let categoria of secao.children; trackBy: trackByFn"
            class="submenu-item mt-2"
            (click)="handleButtonClick(categoria)"
            [attr.data-cy]="
              'categoria-item-' + categoria.classificacao_mercadologica_id
            "
            #itemsDropdown
          >
            <span class="flex-1">{{ categoria.descricao }}</span>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
  <ng-template #secaoTemplate>
    <ul [class.max-height]="hasShowMoreButton" data-cy="secoes-container">
      <li
        *ngFor="let secao of items | slice: 0:showQtd; let last = last"
        class="submenu-item flex"
        [class.mb-3]="!last"
        (click)="handleButtonClick(secao)"
        [attr.data-cy]="'secao-item-' + secao.classificacao_mercadologica_id"
      >
        <span class="block flex-1">{{ secao.descricao }}</span>
      </li>
    </ul>
    <p
      [ngClass]="hasShowMoreButton ? 'block' : 'hidden'"
      class="show-more-button"
      (click)="handleShowMore()"
    >
      ver mais
    </p>
  </ng-template>
</div>
