<ng-container *ngIf="isDesktop$ | async as isDesktop; else appTemplate">
  <div class="mt-4">
    <vip-menu-content
      title="Institucional"
      [menuItens]="menuItens"
      [activeMenuTitle]="activeMenuTitle"
      [subtitle]="subtitle"
      [breadcrumbs]="breadcrumbs"
      [noPaddingLeft]="true"
      (itemMenuClick)="onMenuItemClick($event, isDesktop)"
    >
      <router-outlet></router-outlet>
    </vip-menu-content>
  </div>
</ng-container>

<ng-template #appTemplate>
  <div class="vip-page-content justify-start">
    <router-outlet></router-outlet>
  </div>
</ng-template>
