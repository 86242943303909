import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
  HorariosFormatadosCalendario,
  IHorariosFormatados,
  ITipoEntrega,
} from '@vip/core';

export const resetTiposEntregaStore = createAction(
  '[Simular Frete Page] Reset Tipos Entrega Store'
);

export const resetTipoEntregaSelecionada = createAction(
  '[Resumo Compra Page] Reset Tipo Entrega Selecionada'
);

export const setFormaEntrega = createAction(
  '[Tipo Entrega/Page] Set Forma Entrega',
  props<{ isEntrega: boolean }>()
);

export const getFretesPorCep = createAction(
  '[Simular Frete Page] Listar tipos de entrega disponiveis por cep',
  props<{ cep: string }>()
);

export const getHorarios = createAction(
  '[Tipo Entrega/Page] Busca os horários',
  props<{
    fillialId: number;
    centroDistribuicaoId: number;
    tipoEntregaId: string;
    tipoEntregaSelecionado: ITipoEntrega | null | undefined;
  }>()
);

export const getHorariosSuccess = createAction(
  '[Tipo Entrega/Page] Busca os horários Success',
  props<{
    horarios: {
      horarios_formatados: IHorariosFormatados[];
      horarios_formatados_calendario: HorariosFormatadosCalendario[];
    };
  }>()
);

export const getHorariosFailure = createAction(
  '[Tipo Entrega/Page] Busca os horários Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getFretesPorCepSuccess = createAction(
  '[TipoEntrega/API] Listar tipos de entrega disponiveis por cep Success',
  props<{ tiposEntregas: ITipoEntrega[] }>()
);

export const getFretesPorCepFailure = createAction(
  '[TipoEntrega/API] Listar tipos de entrega disponiveis por cep Error',
  props<{ error: HttpErrorResponse }>()
);

export const getTipoEntregaPadrao = createAction(
  '[Troca Devolucao Page] Pegar tipo de entrega padrao',
  props<{ enderecoId: number; totalItens: number; compraId: number }>()
);

export const getTipoEntregaPadraoSuccess = createAction(
  '[TipoEntrega/API] Pegar tipo de entrega padrao Success',
  props<{ tipoEntrega: ITipoEntrega[] }>()
);

export const getTipoEntregaPadraoFailure = createAction(
  '[TipoEntrega/API] Pegar tipo de entrega padrao Error',
  props<{ error: HttpErrorResponse }>()
);

export const getTiposEntregasEndereco = createAction(
  '[TipoEntrega/API] Listar tipos de entrega disponiveis por endereco',
  props<{ showLoading: boolean }>()
);

export const getTiposEntregasEnderecoSuccess = createAction(
  '[TipoEntrega/API] Listar tipos de entrega disponiveis por endereco Success',
  props<{ tiposEntregas: ITipoEntrega[] }>()
);

export const getTiposEntregasEnderecoFailure = createAction(
  '[TipoEntrega/API] Listar tipos de entrega disponiveis por endereco Error',
  props<{ error: HttpErrorResponse }>()
);

export const getTipoEntregaPagamento = createAction(
  '[TipoEntrega/API] Busca o  tipo de entrega selecionado na tela de pagamento',
  props<{ showLoading: boolean }>()
);

export const getTipoEntregaPagamentoSuccess = createAction(
  '[TipoEntrega/API] Listar tipos de entrega disponiveis por endereco na tela de pagamento Success',
  props<{ tiposEntregas: ITipoEntrega[] }>()
);

export const getTipoEntregaPagamentoFailure = createAction(
  '[TipoEntrega/API] Listar tipos de entrega disponiveis por endereco na tela de pagamento Error',
  props<{ error: HttpErrorResponse }>()
);

export const selectTipoEntrega = createAction(
  '[TipoEntrega] Select Tipo Entrega',
  props<{ tipoEntregaId: number | null }>()
);

export const selectHorarioTipoEntrega = createAction(
  '[TipoEntrega] Select Horario Tipo Entrega',
  props<{ horarioTipoEntrega: IHorariosFormatados }>()
);

export const getTipoEntrega = createAction(
  '[TipoEntrega] Get Tipo Entrega',
  props<{ tipoEntregaId: number; enderecoId: number; totalItens: number }>()
);

export const getTipoEntregaSuccess = createAction(
  '[TipoEntrega/API] Pegar Tipo Entrega Success',
  props<{ tiposEntregas: ITipoEntrega[] }>()
);

export const getTipoEntregaFailure = createAction(
  '[TipoEntrega/API] Pegar Tipo Entrega Error',
  props<{ error: HttpErrorResponse }>()
);
