import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { fidelidadeSelectors } from './fidelidade.selectors';
import * as FidelidadeActions from './fidelidade.actions';

@Injectable()
export class FidelidadeFacade {
  loading$ = this.store.pipe(select(fidelidadeSelectors.isLoading()));
  isLoaded$ = this.store.pipe(select(fidelidadeSelectors.isLoaded()));
  error$ = this.store.pipe(select(fidelidadeSelectors.getError()));
  isFidelidadeAtivo$ = this.store.pipe(
    select(fidelidadeSelectors.getIsFidelidadeAtivo())
  );

  descontoFidelidade$ = this.store.pipe(
    select(fidelidadeSelectors.getDescontosFidelidade())
  );

  produtosDescontoFidelidade$ = this.store.pipe(
    select(fidelidadeSelectors.getProdutosDescontosFidelidadeAsProdutoCompra())
  );
  totalDescontoFidelidade$ = this.store.pipe(
    select(fidelidadeSelectors.getTotalDescontosFidelidade())
  );

  fidelidadeConfiguracoes$ = this.store.pipe(
    select(fidelidadeSelectors.getFidelidadeConfiguracoes())
  );

  constructor(private readonly store: Store) {}

  getDescontosFidelidadeCompra(compraId: number) {
    this.store.dispatch(
      FidelidadeActions.getDescontosFidelidadeCompra({ compraId })
    );
  }

  resetDescontosFidelidadeCompra() {
    this.store.dispatch(FidelidadeActions.resetFidelidadeCompra());
  }
}
