import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { AppUpdateService } from '@vip/native/app-update';
import { MessageService } from '@vip/ui/message';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '@vip/core';

export type IError = {
  error?: string | { message: string };
  errors?: [];
};

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  environment = environment;
  constructor(
    private messageService: MessageService,
    private appUpdateService: AppUpdateService,
    private router: Router
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        error: (error: HttpErrorResponse) => this.errorHandler(error),
      })
    );
  }

  private errorHandler(error: HttpErrorResponse) {
    const errorMapping: { [key: number]: string } = {
      0: 'Erro de conexão à Internet. Favor tentar novamente.',
      400: 'A requisição não pode ser concluida.',
      401: 'Você deve estar logado para acessar.',
      403: 'Não permitido.',
      404: 'Não encontrado.',
      409: 'Existem dados conflitantes com esse registro.',
      422: 'Dados inválidos.',
      500: 'Erro interno do servidor.',
    };

    if (error.status === 404 && !this.environment.isApp) {
      this.router.navigate(['/pagina-nao-encontrada']);
      return;
    }

    if (error.url) {
      const regex = /https:\/\/ws\./;
      if (error.status === 409 && regex.test(error.url)) {
        this.shouldDisplayVersao();
      }
    }

    if (this.shouldDisplayErrorMessage(error)) {
      const errorMessage =
        ((error.status !== 500 || 0) && this.getErrorMessage(error.error)) ||
        errorMapping[error.status] ||
        'Erro desconhecido.';

      this.messageService.openErrorMessage(errorMessage, 2);
    }
  }

  private shouldDisplayErrorMessage(error: HttpErrorResponse) {
    if (error.status === 400) {
      return typeof error.error === 'string' || !!error.error?.message;
    }
    return true;
  }

  private shouldDisplayVersao() {
    this.appUpdateService.openDialog();
  }

  private getErrorMessage(error: IError): string {
    if (error?.errors?.length) {
      return error.errors
        .map((err: { message: string }) => err.message)
        .join(', ');
    }
    return (
      (typeof error.error != 'string' ? error.error?.message : error.error) ||
      ''
    );
  }
}

export const errorInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
