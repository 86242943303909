import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';

import { DropdownItemComponent } from '../dropdown-item/dropdown-item.component';
import { DropdownItemEvent } from '../../types/dropdown-item.types';
import { DropdownAnimation } from '../../types/dropdown-menu.types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'vip-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: [
    './dropdown-menu.component.scss',
    '../../../../../utils/styles/backdrop.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.open]': 'open',
  },
})
export class DropdownMenuComponent implements AfterContentInit {
  @Input()
  open?: boolean;

  @Input()
  backdrop?: boolean;

  @Input()
  animationDirection?: DropdownAnimation = 'bottom-right-to-top-left';

  @Input() isDesktop = false;

  @Output()
  itemClick: EventEmitter<DropdownItemEvent> = new EventEmitter();

  @ContentChildren(DropdownItemComponent)
  itens!: QueryList<DropdownItemComponent>;
  @ViewChildren(DropdownItemComponent)
  viewChildren!: QueryList<DropdownItemComponent>;

  ngAfterContentInit(): void {
    this.itens.toArray().forEach((it) => {
      it.itemClick
        .pipe(untilDestroyed(this))
        .subscribe((event: DropdownItemEvent) => {
          this.itemClick.emit(event);
        });
    });
  }
}
