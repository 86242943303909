import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { FilialStateModule } from '@vip/state/filial';
import * as fromTermosUso from './+state/termos-uso.reducer';
import { TermosUsoEffects } from './+state/termos-uso.effects';
import { TermosUsoFacade } from './+state/termos-uso.facade';

@NgModule({
  imports: [
    CommonModule,
    FilialStateModule,
    StoreModule.forFeature(
      fromTermosUso.TERMOS_USO_FEATURE_KEY,
      fromTermosUso.reducer
    ),
    EffectsModule.forFeature([TermosUsoEffects]),
  ],
  providers: [TermosUsoFacade],
})
export class TermosUsoStateModule {}
