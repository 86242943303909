import { Inject, Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { IGa4Item } from '../../interfaces/ga4.interface';
import { IS_APP } from '../../constants';
import { ICompra, IFormaPagamento } from '../../interfaces';
@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {
  purchase(value: number) {
    if ('fbq' in window) {
      (<any>window).fbq('track', 'Purchase', { currency: 'BRL', value });
    }
  }

  initiateCheckout(compra: ICompra) {
    if ('fbq' in window) {
      (<any>window).fbq('track', 'InitiateCheckout', {
        data_entrega: compra.data_entrega,
        frete: compra.frete,
        descontos: compra.valor_descontos,
        value: compra.valor_final,
        currency: 'BRL',
        entrega: compra.tipo_entrega.descricao,
      });
    }
  }

  addPaymentInfo(formaPagamento?: IFormaPagamento, parcelas = 1) {
    if ('fbq' in window && formaPagamento) {
      (<any>window).fbq('track', 'AddPaymentInfo', {
        descricao: formaPagamento.descricao,
        parcelas: parcelas,
        debito: formaPagamento.tipo_pagamento === 'debito' ? 'Sim' : 'Não',
      });
    }
  }

  lead(cep: string) {
    if ('fbq' in window) {
      (<any>window).fbq('track', 'Lead', { cep });
    }
  }

  completeRegistration(cep: string) {
    if ('fbq' in window) {
      (<any>window).fbq('track', 'CompleteRegistration', { cep });
    }
  }

  search(termo: string) {
    if ('fbq' in window) {
      (<any>window).fbq('track', 'Search', { termo });
    }
  }
  addToCart(label: string) {
    if ('fbq' in window) {
      (<any>window).fbq('track', 'AddToCart', { label });
    }
  }
}
