import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardEnderecoComponent } from './card-endereco/card-endereco.component';

@NgModule({
  imports: [CommonModule],
  declarations: [CardEnderecoComponent],
  exports: [CardEnderecoComponent],
})
export class CardEnderecoModule {}
