import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as ParametrosActions from './parametros.actions';
import { parametrosSelectors } from './parametros.selectors';
import { IParametros } from '@vip/core';
import { filter } from 'rxjs/operators';

@Injectable()
export class ParametrosFacade {
  exigeAutenticacao$ = this.store.pipe(
    select(parametrosSelectors.getExigeAutenticacao()),
    filter(
      (exigeAutenticacao): exigeAutenticacao is boolean =>
        exigeAutenticacao !== undefined
    )
  );

  selecaoCdObrigatorio$ = this.store.pipe(
    select(parametrosSelectors.getSelecaoCdObrigatorio()),
    filter(
      (selecaoCdObrigatorio): selecaoCdObrigatorio is boolean =>
        selecaoCdObrigatorio !== undefined
    )
  );

  multiplosCds$ = this.store.pipe(
    select(parametrosSelectors.getMultiplosCds()),
    filter(
      (multiplosCds): multiplosCds is boolean => multiplosCds !== undefined
    )
  );

  permitirCarrinhoAnonimo$ = this.store.pipe(
    select(parametrosSelectors.getPermitirCarrinhoAnonimo()),
    filter(
      (permitirCarrinhoAnonimo): permitirCarrinhoAnonimo is boolean =>
        permitirCarrinhoAnonimo !== undefined
    )
  );

  limiteTentativasPrePago$ = this.store.pipe(
    select(parametrosSelectors.getLimiteTentativasPrePago())
  );

  tentativasRealizadas$ = this.store.pipe(
    select(parametrosSelectors.getTentativasRealizadas())
  );

  parametros$ = this.store.pipe(
    select(parametrosSelectors.getParametros()),
    filter((parametros): parametros is IParametros => parametros !== null)
  );

  pinAtivo$ = this.store.pipe(
    select(parametrosSelectors.getPinAtivo()),
    filter((pinAtivo): pinAtivo is boolean => pinAtivo !== undefined)
  );

  adsParams$ = this.store.pipe(
    select(parametrosSelectors.getParamsAds()),
    filter((parametros): parametros is boolean => parametros !== null)
  );

  constructor(private store: Store) {}

  setParametros(parametros: IParametros) {
    this.store.dispatch(
      ParametrosActions.loadParametrosSuccess({ parametros })
    );
  }
}
