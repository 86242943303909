<div class="ml-3">
  <vip-base-component-skeleton
    [quantity]="quantity"
    [listDirection]="listDirection"
    class="flex flex-row"
  >
    <ng-template #skeletonContent let-odd>
      <div
        class="border rounded p-3 flex flex-col"
        [ngClass]="{
          'mr-1': listDirection === 'horizontal' && size === 'small',
          'mr-4': listDirection === 'horizontal' && size === 'medium',
          'mb-2': listDirection === 'vertical',
          'mr-2': listDirection === 'vertical' && !odd
        }"
      >
        <ng-container
          *vipSkeleton="
            true;
            width: imageSize;
            height: imageSize;
            radius: '5px'
          "
        ></ng-container>
        <div class="w-full mt-3 flex justify-end">
          <ng-container
            *vipSkeleton="
              true;
              width: spinSize;
              height: spinSize;
              radius: '50px';
              className: ['mb-3']
            "
          ></ng-container>
        </div>
        <div class="w-full flex flex-col">
          <ng-container
            *vipSkeleton="
              true;
              width: '100%';
              height: titleSize;
              className: ['mb-1']
            "
          ></ng-container>
          <ng-container
            *vipSkeleton="true; width: '80px'; height: '33px'"
          ></ng-container>
        </div>
      </div>
    </ng-template>
  </vip-base-component-skeleton>
</div>
