import { IGetDescontosFidelidadeResponse } from './fidelidade-ws.types';
import { Injectable } from '@angular/core';

import { BaseWsService } from '../common/base-ws-service';

@Injectable({
  providedIn: 'root',
})
export class FidelidadeWsService {
  constructor(private ws: BaseWsService<FidelidadeWsService>) {}

  getOfertasFidelidade(filialId: number, compraId: number) {
    return this.ws.get<IGetDescontosFidelidadeResponse>(
      'getOfertasFidelidade',
      `/loja/compras/verificarOfertas/${filialId}/${compraId}`
    );
  }
}
