import { Pipe, PipeTransform } from '@angular/core';
import { IFilter, IFilterOptions } from '@vip/core';

@Pipe({
  name: 'filtroOptionIsHidden',
  pure: true,
})
export class FiltroOptionIsHidden implements PipeTransform {
  transform(
    option: IFilterOptions,
    args: { type: string; _filters: IFilter[] }
  ) {
    let isHidden = false;
    args._filters.forEach((filtro) => {
      if (args.type === 'departamento' && filtro.name !== 'departamento') {
        const secoesSelecionadas = args._filters
          .find((filtro) => filtro.name === 'secao')
          ?.options.map((option) => (option.checked === true ? option : null))
          .filter((option) => option);
        if (
          secoesSelecionadas?.length &&
          !secoesSelecionadas?.find(
            (secao) => secao?.departamento == parseInt(option.value)
          )
        ) {
          isHidden = true;
        }
      }
      if (args.type === 'secao' && filtro.name !== 'secao') {
        const departamentoSelecionado = args._filters
          .find((filtro) => filtro.name === 'departamento')
          ?.options.map((option) => (option.checked === true ? option : null))
          .filter((option) => option);

        if (
          departamentoSelecionado?.length &&
          departamentoSelecionado[0]?.value != '0'
        ) {
          if (departamentoSelecionado[0]?.value !== option.departamento) {
            isHidden = true;
          }
        }
      }
    });
    return isHidden;
  }
}
