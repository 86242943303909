import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  ICompraDesconto,
  IProdutoCarrinho,
  IProdutoCompra,
  ITaxaServico,
  MotivoDescontoEnum,
} from '@vip/core';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'vip-card-detalhes-cobranca',
  templateUrl: './card-detalhes-cobranca.component.html',
  styleUrls: ['./card-detalhes-cobranca.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardDetalhesCobrancaComponent {
  math = Math;

  @Input() valorCompra!: number;
  @Input() valorAlteracoes = 0;
  @Input() taxaEntrega?: number = 0;
  @Input() taxaEntregaAnterior?: number;
  @Input()
  set taxaServico(value: ITaxaServico | null) {
    this._taxaServico = value;
    if (value) this.valorTaxaServico = this.calcularValorTaxaServico(value);
  }
  get taxaServico(): ITaxaServico | null {
    return this._taxaServico;
  }
  @Input() valorDescontos?: number;
  @Input() descontos?: ICompraDesconto[];
  @Input() valorAcrescimos?: number;
  @Input() accordion = false;
  @Input() expanded = true;
  @Input() isDesktop = false;
  @Input() editable = false;
  @Input() saldoCashback?: number;
  @Input() totalCompra?: number;
  @Input() comprar_novamente?: boolean;
  @Input() valorMinimoCashback = 0.01;
  @Input() showContinuarComprando = false;
  @Input() showMessageValorMinimo = false;
  @Input() showIcon = true;
  @Input() title = '';
  @Input() confirmButtonText = '';
  @Input() messageValorMinimo = '';
  @Input() showCashback = true;
  @Input()
  disablePagamentoButton = false;
  @Input()
  cardBasicBorder = true;
  @Input()
  isLoading = false;
  @Input()
  hideConfirmButton = false;
  @Input()
  isCompraLayout = false;
  @Input()
  disableRastrearCompra = false;

  @Input() produtos: IProdutoCompra[] | null = null;

  @Output() irParaPagamento = new EventEmitter<void>();
  @Output() continuarComprando = new EventEmitter<void>();
  @Output() novaCompra = new EventEmitter<void>();
  @Output() rastrearCompra = new EventEmitter<void>();
  @Output() changedValorUtilizadoCashback = new EventEmitter<number>();
  @Output() repetirCompra = new EventEmitter<void>();

  constructor(private currencyPipe: CurrencyPipe) {}
  disclaimer = false;
  _taxaServico: ITaxaServico | null = null;
  valorTaxaServico: string | null = '';

  get total() {
    if (this.valorDescontos) {
      const total = this.getSubTotal - this.valorDescontos;
      return total > 0 ? total : 0;
    }
    return this.getSubTotal;
  }

  get getSubTotal() {
    return (
      (this.valorCompra || 0) +
      (this.taxaEntrega || 0) +
      (this.taxaServico?.valor_taxa || 0) +
      (this.valorAcrescimos || 0) +
      (this.valorAlteracoes || 0)
    );
  }

  get descontosSimples(): ICompraDesconto | undefined {
    const somaDescontos = this.descontos?.reduce((somaDescontos, desconto) => {
      return this.isDescontosSimples(desconto.motivo)
        ? somaDescontos + desconto.valor
        : somaDescontos;
    }, 0);
    const desconto: ICompraDesconto = {
      motivo: MotivoDescontoEnum.MOTIVO_PROMOCAO,
      valor: somaDescontos || 0,
      string: '',
    };
    return desconto.valor > 0 ? desconto : undefined;
  }

  get cashback(): ICompraDesconto | undefined {
    return this.descontos?.find((desconto) => this.isCashback(desconto.motivo));
  }

  get fidelidade(): ICompraDesconto | undefined {
    return this.descontos?.find((desconto) =>
      this.isFidelidade(desconto.motivo)
    );
  }

  isCashback(motivo: number) {
    return motivo === MotivoDescontoEnum.MOTIVO_CASHBACK;
  }

  isFidelidade(motivo: number) {
    return motivo === MotivoDescontoEnum.MOTIVO_FIDELIDADE;
  }

  isDescontosSimples(motivo: number) {
    return !this.isCashback(motivo) && !this.isFidelidade(motivo);
  }

  descontoNome(desconto: ICompraDesconto) {
    switch (desconto.motivo) {
      case MotivoDescontoEnum.MOTIVO_CASHBACK:
        return 'Saldo Cashback';
      case MotivoDescontoEnum.MOTIVO_FIDELIDADE:
        return 'Desconto por Fidelidade';
      default:
        return 'Descontos';
    }
  }

  clickDisclaimer() {
    this.disclaimer = !this.disclaimer;
  }

  calcularValorTaxaServico(taxaServico: ITaxaServico) {
    return typeof taxaServico.valor_taxa == 'number' &&
      taxaServico.valor_taxa > 0
      ? this.currencyPipe.transform(taxaServico.valor_taxa)
      : 'Grátis';
  }

  handleAlterarCashback(valorCashback: number): void {
    this.changedValorUtilizadoCashback.emit(valorCashback);
  }
}
