import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltroComponent } from './filtro/filtro.component';
import { ButtonModule } from '@vip/ui/button';
import { ScreenTitleModule } from '@vip/ui/screen-title';
import { CheckboxModule } from '@vip/ui/checkbox';
import { RadioModule } from '@vip/ui/radio';
import { FiltroOptionIsHidden } from './filtro/filtro-option-is-hidden.pipe';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    ScreenTitleModule,
    CheckboxModule,
    RadioModule,
  ],
  declarations: [FiltroComponent, FiltroOptionIsHidden],
  exports: [FiltroComponent],
})
export class FiltroModule {}
