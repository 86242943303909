import { IAplicativo } from '../interfaces';

const filialFromOrganizacaoServiceMock = {
  id: 1,
  nome: 'Filial 1',
};
export const aplicativoSemFilialMock: IAplicativo = {
  id: 1,
  nome: 'Aplicativo 1',
  filiais: [],
};

export const aplicativoComUmaFilialMock: IAplicativo = {
  id: 1,
  nome: 'Aplicativo 1',
  filiais: [filialFromOrganizacaoServiceMock],
};

export const aplicativoComDuasFiliaisMock: IAplicativo = {
  id: 1,
  nome: 'Aplicativo 1',
  filiais: [filialFromOrganizacaoServiceMock, filialFromOrganizacaoServiceMock],
};

export const aplicativoEmRevisaoMock: IAplicativo = {
  id: 1,
  nome: 'Aplicativo 1',
  revisao: true,
  filiais: [filialFromOrganizacaoServiceMock],
};
