import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParametrosFacade } from '@vip/state/parametros';
import { ClienteTokenService } from '@vip/state/cliente';

@Injectable()
export class ExigeAutenticacaoGuard implements CanActivate {
  constructor(
    private clienteTokenService: ClienteTokenService,
    private parametrosFacade: ParametrosFacade,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    if (this.clienteTokenService.getToken()) {
      return of(true);
    } else {
      return this.parametrosFacade.exigeAutenticacao$.pipe(
        map((exigeAutenticacao) => {
          if (exigeAutenticacao) {
            this.router.navigateByUrl('/login');
          }
          return !exigeAutenticacao;
        })
      );
    }
  }
}
