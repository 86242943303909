import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManutencaoContainerComponent } from './manutencao-container/manutencao-container.component';
import { ManutencaoModule } from '@vip/views/manutencao';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    ManutencaoModule,
    RouterModule.forChild([
      {
        path: '',
        component: ManutencaoContainerComponent,
      },
    ]),
  ],
  declarations: [ManutencaoContainerComponent],
  exports: [ManutencaoContainerComponent],
})
export class ManutencaoContainerModule {}
