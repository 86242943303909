import { BreadcrumbModule } from '@vip/ui/breadcrumb';
import { ListagemCarrinhoDesktopModule } from '@vip/views/listagem-carrinho-desktop';
import { LOCALE_ID, NgModule } from '@angular/core';
import {
  CommonModule,
  CurrencyPipe,
  registerLocaleData,
} from '@angular/common';
import { ResumoCompraComponent } from './resumo-compra/resumo-compra.component';
import { CardProdutoModule } from '@vip/ui/card-produto';
import { ScreenTitleModule } from '@vip/ui/screen-title';
import { CardResumoProdutosModule } from '@vip/ui/card-resumo-produtos';
import { CardDetalhesCobrancaModule } from '@vip/ui/card-detalhes-cobranca';
import { ModalResumoProdutosComponent } from './modal-resumo-produtos/modal-resumo-produtos.component';
import { CardEntregaRetiradaModule } from '@vip/ui/card-entrega-retirada';
import { CoreModule } from '@vip/core';
import { SelectableCardModule } from '@vip/ui/selectable-card';
import localePt from '@angular/common/locales/pt';
import { ButtonModule } from '@vip/ui/button';
import { CardPerguntasSobreCompraModule } from '@vip/ui/card-perguntas-sobre-compra';

registerLocaleData(localePt, 'pt');

@NgModule({
  imports: [
    CommonModule,
    CardProdutoModule,
    CardDetalhesCobrancaModule,
    ScreenTitleModule,
    CardResumoProdutosModule,
    CardEntregaRetiradaModule,
    CoreModule,
    SelectableCardModule,
    ListagemCarrinhoDesktopModule,
    ButtonModule,
    CardPerguntasSobreCompraModule,
    BreadcrumbModule,
  ],
  declarations: [ResumoCompraComponent, ModalResumoProdutosComponent],
  exports: [ResumoCompraComponent, ModalResumoProdutosComponent],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }, CurrencyPipe],
})
export class ResumoCompraModule {}
