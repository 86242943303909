<section *ngIf="endereco" class="p-3 border rounded">
  <h2 class="vip-color-first-default text-base font-medium mb-2">
    {{ endereco.titulo }}
  </h2>
  <p class="vip-color-secondary-default text-sm">
    {{ enderecoFormatado() }}
  </p>

  <hr class="vip-divider my-2" />
  <div
    class="flex"
    [ngClass]="{
      'justify-end': !endereco.principal,
      'justify-between': endereco.principal
    }"
  >
    <span
      *ngIf="endereco.principal"
      class="vip-color-secondary-default font-medium text-base"
      data-cy="vip-endereco-principal-label"
      >Principal</span
    >
    <div
      class="flex cursor-pointer vip-color-secondary-main"
      data-cy="vip-btn-edit-endereco"
      (click)="editClick.emit()"
    >
      <span class="text-sm underline font-medium">Editar</span>
      <i class="icon-chevron_right"></i>
    </div>
  </div>
</section>
