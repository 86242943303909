<div class="grid gap-4 grid-cols-1 text-center">
  <span class="vip-validar-cep-title text-3xl"
    >Solicitacação já cadastrada</span
  >
  <span class="vip-validar-cep-sub-title text"
    >Já cadastramos um aviso para este email. Você será avisado(a) quando houver
    disponibilidade de entrega para o CEP informado</span
  >
  <vip-button
    data-cy="solicitar-cep-validar"
    (btnClick)="onConfirm()"
    [disabled]="loading"
  >
    <span> Ok, entendi </span>
  </vip-button>
</div>
