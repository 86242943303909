<!-- Preço antigo + tag da oferta + tag preço unidade fração ou preco unidade diferente -->
<div *ngIf="produto.oferta">
  <div *ngIf="!(produto.oferta | isOfertaComDescontoSimples)">
    <div
      class="flex items-baseline mb-1"
      [ngClass]="isTablet ? 'justify-center' : 'mb-1'"
      [ngClass]="hasZoom ? 'items-baseline mb-2' : null"
    >
      <vip-produto-preco-valor
        *ngIf="!hasCombo"
        [marginClassUnidade]="
          tamanho !== TamanhoProdutoPreco.GRANDE ? '' : 'ml-1'
        "
        [isNegrito]="false"
        [tamanho]="tamanho"
        [preco]="
          produto.oferta.classe_oferta === 'oferta-atacarejo'
            ? produto.oferta.preco_oferta
            : produto.oferta.preco_antigo
        "
        [quantidadeUnidade]="produto.quantidade_unidade_diferente"
        [siglaUnidade]="produto.unidade_sigla"
        [ngClass]="'mr-' + tamanhoMargens"
      >
      </vip-produto-preco-valor>
      <vip-produto-preco-unidade
        *ngIf="produto | mostraPrecoUnidadeProduto"
        [preco]="produto | unidadePrecoPipe: true"
        [siglaUnidade]="produto | unidadeSigla"
        [isTag]="tamanho === TamanhoProdutoPreco.GRANDE"
        [textSmallGrey]="isDesktop"
      >
      </vip-produto-preco-unidade>
    </div>
  </div>
  <div [ngClass]="{ 'flex w-full justify-center': isTablet }">
    <vip-produto-preco-oferta-tag
      *ngIf="
        !isInlineTag &&
        produto.oferta?.classe_oferta !== 'preco-vip' &&
        produto.oferta?.classe_oferta !== 'oferta-preto' &&
        produto.oferta?.classe_oferta !== 'oferta-exclusiva' &&
        produto.oferta?.classe_oferta !== 'oferta-vermelho' &&
        produto.oferta?.classe_oferta !== 'oferta-laranja' &&
        !hasCombo
      "
      [descontoSimples]="produto.oferta | isOfertaComDescontoSimples"
      [oferta]="produto.oferta"
      [isCard]="tamanho !== TamanhoProdutoPreco.GRANDE"
      [tamanho]="tamanho"
      [ngClass]="{
        'mb-2.5 mt-3':
          isDesktop && (produto.oferta | isOfertaComDescontoSimples),
        'my-2': isDesktop && !(produto.oferta | isOfertaComDescontoSimples),
        'mb-2': !isDesktop && !hasCard,
        'mb-1': hasCard
      }"
      [mostrarTextoDeUnidades]="!isDesktop"
    >
    </vip-produto-preco-oferta-tag>
  </div>
</div>

<!-- Preço de venda + tag preço unidade fração ou preco unidade diferente -->
<div
  class="flex"
  [ngClass]="
    tamanho !== TamanhoProdutoPreco.GRANDE && semOfertaOuOfertaDescontoSimples
      ? 'flex-col'
      : 'items-center'
  "
  [ngClass]="{ 'flex-col': isDesktop }"
  [ngClass]="isTablet ? 'justify-center m-0' : null"
  [ngClass]="hasCard ? 'flex-col' : null"
  *ngIf="!isInlineTag; else inlineTagTemplate"
>
  <div
    *ngIf="
      hasCard &&
      produto.oferta?.classe_oferta === 'preco-vip' &&
      produto.oferta?.preco_oferta_fracao !== null
    "
    [ngClass]="{
      'mb-1': hasCard && produto.oferta?.classe_oferta === 'preco-vip',
      '': !(hasCard && produto.oferta?.classe_oferta === 'preco-vip')
    }"
  >
    <vip-produto-preco-unidade
      *ngIf="
        produto | mostraPrecoUnidadeProduto: !semOfertaOuOfertaDescontoSimples
      "
      [preco]="produto | unidadePrecoPipe: false:undefined:true"
      [siglaUnidade]="produto | unidadeSigla"
      [isTag]="tamanho === TamanhoProdutoPreco.GRANDE"
      [class.mt-1]="semOfertaOuOfertaDescontoSimples"
      [textSmallGrey]="isDesktop"
    >
    </vip-produto-preco-unidade>
  </div>

  <div
    class="flex"
    [ngClass]="isTablet ? 'gap-2 items-baseline' : null"
    [ngClass]="
      hasCard
        ? 'flex-col align-start'
        : hasZoom
        ? 'items-baseline'
        : 'items-center'
    "
  >
    <vip-produto-preco-valor
      [marginClassUnidade]="
        tamanho === TamanhoProdutoPreco.GRANDE ? 'ml-1' : ''
      "
      [tamanho]="tamanho"
      [preco]="
        hasCombo && hasCard
          ? produto.oferta?.preco_antigo
          : (produto | produtoPreco)
      "
      [quantidadeUnidade]="produto.quantidade_unidade_diferente"
      [siglaUnidade]="produto.unidade_sigla"
      class="{{ !isTablet ? 'mr-' + tamanhoMargens : null }}"
    >
    </vip-produto-preco-valor>
    <div *ngIf="!hasCard" [ngClass]="isTablet ? 'ml-2' : null">
      <vip-produto-preco-unidade
        *ngIf="
          produto | mostraPrecoUnidadeProduto: !semOfertaOuOfertaDescontoSimples
        "
        [preco]="produto | unidadePrecoPipe: false:undefined:true"
        [siglaUnidade]="produto | unidadeSigla"
        [isTag]="tamanho === TamanhoProdutoPreco.GRANDE"
        [class.mt-1]="semOfertaOuOfertaDescontoSimples"
        [textSmallGrey]="isDesktop"
      >
      </vip-produto-preco-unidade>
    </div>
  </div>
</div>

<div *ngIf="hasCombo" class="w-full flex justify-center items-center pt-[5px]">
  <p
    class="p-1 leading-[10px] w-[100%] font-medium text-[10px] color-tag-tex-combo text-center rounded bg-tag-combo"
  >
    Visualizar Combo
  </p>
</div>

<div
  *ngIf="
    produto.oferta &&
    (produto.oferta?.classe_oferta === 'preco-vip' ||
      produto.oferta?.classe_oferta === 'oferta-preto' ||
      produto.oferta?.classe_oferta === 'oferta-vermelho' ||
      produto.oferta?.classe_oferta === 'oferta-laranja' ||
      produto.oferta?.classe_oferta === 'oferta-exclusiva')
  "
  class="mt-1"
>
  <vip-produto-preco-oferta-tag
    *ngIf="!isInlineTag"
    [descontoSimples]="produto.oferta | isOfertaComDescontoSimples"
    [oferta]="produto.oferta"
    [isCard]="tamanho !== TamanhoProdutoPreco.GRANDE"
    [tamanho]="tamanho"
    [ngClass]="{
      'mb-2.5 mt-3': isDesktop && (produto.oferta | isOfertaComDescontoSimples),
      'my-2': isDesktop && !(produto.oferta | isOfertaComDescontoSimples),
      'pb-2': !isDesktop
    }"
    [mostrarTextoDeUnidades]="!isDesktop"
  >
  </vip-produto-preco-oferta-tag>
</div>

<!-- Preço de venda nas faixas da oferta atacarejo -->
<ng-template #inlineTagTemplate>
  <div
    *ngFor="
      let faixa of produto.oferta?.faixas_precos | slice: 1:faixasParaMostrar;
      let last = last;
      let first = first
    "
    [ngClass]="{
      'pb-2': !last && !hasCard,
      'pb-1': hasCard && !last,
      'mt-3': isDesktop && first,
      'flex items-center': !isDesktop,
      'atacarejo-grid-precos': isDesktop
    }"
  >
    <vip-produto-preco-oferta-tag
      [isCard]="tamanho !== TamanhoProdutoPreco.GRANDE"
      [oferta]="produto.oferta!"
      [faixa]="faixa"
      [tamanho]="tamanho"
      [mostrarTextoDeUnidades]="!isDesktop"
      [ngClass]="{ 'mr-2': !isDesktop }"
    >
    </vip-produto-preco-oferta-tag>
    <span
      *ngIf="tamanho === TamanhoProdutoPreco.GRANDE && !isDesktop"
      class="text-base mr-1 vip-color-first-default"
    >
      por
    </span>
    <vip-produto-preco-valor
      [marginClassUnidade]="
        tamanho !== TamanhoProdutoPreco.GRANDE ? '' : 'ml-1'
      "
      [preco]="faixa.valor"
      [quantidadeUnidade]="produto.quantidade_unidade_diferente"
      [siglaUnidade]="produto.unidade_sigla"
      [tamanho]="tamanho"
    >
    </vip-produto-preco-valor>
    <span *ngIf="isDesktop"></span>
    <vip-produto-preco-unidade
      *ngIf="
        (produto | mostraPrecoUnidadeProduto: true) &&
        tamanho === TamanhoProdutoPreco.GRANDE
      "
      [ngClass]="{ 'ml-3': !isDesktop }"
      [preco]="produto | unidadePrecoPipe: false:faixa"
      [siglaUnidade]="produto | unidadeSigla"
      [textSmallGrey]="isDesktop"
      [noMargin]="true"
    >
    </vip-produto-preco-unidade>
  </div>
</ng-template>
