import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IMAGES_PATH, IProduto } from '@vip/core';
import { SearchComponent } from '@vip/ui/search';

import { ISpinEventComProduto } from '@vip/ui/spin';

type TemplateName = 'substituir' | 'indisponiveis';

@Component({
  selector: 'vip-substituir-produto',
  templateUrl: './substituir-produto.component.html',
  styleUrls: ['./substituir-produto.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubstituirProdutoComponent implements OnInit {
  @ViewChild(SearchComponent) searchInput: SearchComponent | undefined;
  @ViewChild('listaSubstituicao') listaSubstituicao?: ElementRef;

  @Input()
  produtosIndisponiveis: IProduto[] = [];
  @Input() produtoIndisponivelSelecionado?: IProduto | null = null;
  @Input() produtoSubstituto?: IProduto;
  @Input() termoPesquisado = '';
  @Input() produtosSimilares: IProduto[] = [];
  @Input() isLoading = false;
  @Input() isModal = false;

  @Output() productChange = new EventEmitter<Pick<IProduto, 'produto_id'>>();
  @Output() productBack = new EventEmitter<Pick<IProduto, 'produto_id'>>();
  @Output() replaceProducts = new EventEmitter<ISpinEventComProduto[]>();
  @Output() goBack = new EventEmitter<boolean>();

  @Output() search = new EventEmitter();
  @Output() searchClear = new EventEmitter();
  @Output() initSubstituirProduto = new EventEmitter<void>();

  @Output() substitutoChange = new EventEmitter<{
    spinEvent: ISpinEventComProduto;
    produto: IProduto;
  }>();

  @Output() naoSubstituirProdutos = new EventEmitter();

  indexProdutoSelecionado = 0;

  templateName: TemplateName = 'indisponiveis';

  isSearchFocused = false;

  imageUrl!: string;

  constructor(@Inject(IMAGES_PATH) private imagesPath: string) {
    this.imageUrl = `${this.imagesPath}shelf.svg`;
  }

  ngOnInit(): void {
    this.productChange.emit({
      produto_id:
        this.produtosIndisponiveis[this.indexProdutoSelecionado].produto_id,
    });
    this.templateName =
      this.produtosIndisponiveis.length > 1 ? 'indisponiveis' : 'substituir';
  }

  get isSubstituirTemplate(): boolean {
    return this.templateName === 'substituir';
  }

  get isOnlyOneUnavailable(): boolean {
    return this.produtosIndisponiveis.length === 1;
  }

  get isLastItemToReplace(): boolean {
    return (
      this.indexProdutoSelecionado === this.produtosIndisponiveis.length - 1
    );
  }

  confirmProduct(): void {
    if (this.isLastItemToReplace) {
      this.sendProductsEvents();
      this.goBack.emit(true);
    } else {
      this.goNextProduct();
      if (this.templateName === 'substituir')
        this.listaSubstituicao?.nativeElement.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    }
  }

  skipProduct(): void {
    if (!this.isSubstituirTemplate) {
      this.goBack.emit(true);
      this.naoSubstituirProdutos.emit();
      return;
    }
    this.confirmProduct();
  }

  backProduct(): void {
    this.goPreviousProduct();
  }

  handleProdutoChanged(spinEvent: ISpinEventComProduto, produto: IProduto) {
    this.substitutoChange.emit({
      spinEvent,
      produto,
    });
  }

  clearSearch() {
    this.termoPesquisado = '';
    this.searchInput?.clear();
    this.searchClear.emit();
  }

  startSubstituir(): void {
    this.templateName = 'substituir';
    this.initSubstituirProduto.emit();
  }

  private goNextProduct(): void {
    this.clearSearch();
    this.productChange.emit({
      produto_id:
        this.produtosIndisponiveis[this.indexProdutoSelecionado + 1].produto_id,
    });
    this.indexProdutoSelecionado++;
  }

  private goPreviousProduct(): void {
    this.clearSearch();
    if (this.indexProdutoSelecionado > 0) {
      this.productChange.emit({
        produto_id:
          this.produtosIndisponiveis[this.indexProdutoSelecionado - 1]
            .produto_id,
      });
      this.indexProdutoSelecionado--;
    } else if (this.indexProdutoSelecionado === 0) {
      this.templateName = 'indisponiveis';
    }
  }

  private sendProductsEvents(): void {
    this.replaceProducts.emit();
  }
}
