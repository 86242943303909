import { Route } from '@angular/router';
import { AuthGuard } from '@vip/state/auth';
import { MinhaContaContainerComponent } from '@vip/container/minha-conta';
import {
  CadastroIncompletoGuard,
  ValidaPinClienteGuard,
} from '@vip/state/cliente';

export const SiteMinhaContaRoutes: Route[] = [
  {
    path: 'minha-conta',
    canActivate: [AuthGuard, CadastroIncompletoGuard, ValidaPinClienteGuard],
    loadChildren: async () =>
      (await import('@vip/container/minha-conta')).MinhaContaContainerModule,
  },
];
