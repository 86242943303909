<div
  class="vip-tag-collection flex items-center rounded-full cursor-pointer"
  [ngClass]="{ 'with-label': label }"
  [class.medium]="size === 'medium'"
  (click)="redirectToCollection($event)"
  data-cy="vip-tag-collection"
>
  <div *ngIf="icon" class="icon flex items-center justify-cotent-center">
    <vip-image
      [imageUrl]="icon"
      [defaultImageUrl]="defaultImageUrl"
      [size]="size === 'small' ? imageSizeEnum.sm : imageSizeEnum.md"
    ></vip-image>
  </div>
  <p class="vip-color-primary-main font-medium text-tiny label" *ngIf="label">
    {{ label }}
  </p>
</div>
