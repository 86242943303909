import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromListaItens from './+state/lista-itens.reducer';
import { ListaItensEffects } from './+state/lista-itens.effects';
import { ListaItensFacade } from './+state/lista-itens.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromListaItens.LISTA_ITENS_FEATURE_KEY,
      fromListaItens.reducer
    ),
    EffectsModule.forFeature([ListaItensEffects]),
  ],
  providers: [ListaItensFacade],
})
export class ListaItensStateModule {}
