import { Action, createReducer, on } from '@ngrx/store';
import { IGenericState } from '@vip/state/utils';

import * as SessaoActions from './sessao.actions';

export const SESSAO_FEATURE_KEY = 'sessao';

export type SessaoState = IGenericState<{ sessaoId: string } | null>;

export const initialState: SessaoState = {
  data: null,
  status: 'pending',
  error: '',
};

const sessaoReducer = createReducer(
  initialState,
  on(SessaoActions.init, (state) => ({
    ...state,
    status: 'loading',
    error: '',
  })),
  on(SessaoActions.loadSessaoSuccess, (state, { sessaoId }) => ({
    ...state,
    data: { sessaoId },
    status: 'success',
    error: '',
  })),
  on(SessaoActions.loadSessaoFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error.error.error,
  }))
);

export function reducer(state: SessaoState | undefined, action: Action) {
  return sessaoReducer(state, action);
}
