import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardOpcaoFaturamentoComponent } from './card-opcao-faturamento/card-opcao-faturamento.component';
import { CardBasicoModule } from '@vip/ui/card-basico';
import { RadioModule } from '@vip/ui/radio';

@NgModule({
  imports: [CommonModule, CardBasicoModule, RadioModule],
  declarations: [CardOpcaoFaturamentoComponent],
  exports: [CardOpcaoFaturamentoComponent],
})
export class CardOpcaoFaturamentoModule {}
