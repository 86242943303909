<div class="flex flex-col w-full" [class.sticky]="isDesktopResponsive">
  <vip-screen-title
    [isShowDesktop]="true"
    title="Carrinho"
    [rightIcon]="produtosCarrinho.length > 0 ? 'icon-delete_outline' : ''"
    [rightText]="produtosCarrinho.length > 0"
    [isLoading]="isLoading"
    rightIconColor="error"
    [leftClose]="leftClose"
    (closeClicked)="handleCloseClick()"
    (rightIconClick)="handleDeleteCarrinhoClick()"
    [isDesktop]="isDesktop"
    [showActions]="true"
  >
  </vip-screen-title>

  <ng-container *ngIf="!isLoading; else skeletonTemplate">
    <vip-empty
      *ngIf="produtosCarrinho.length === 0; else produtosCarrinhosTemp"
      image="{{ imageUrl }}shopping-cart.svg"
      text="Seu carrinho está vazio."
      [subtitle]="
        layoutUtilsService.isDesktop()
          ? 'Navegue pelo nosso site, faça buscas e adicione produtos ao carrinho!'
          : ''
      "
    >
    </vip-empty>
  </ng-container>
  <ng-template #skeletonTemplate>
    <vip-lista-produtos-selecionados-skeleton>
    </vip-lista-produtos-selecionados-skeleton>
  </ng-template>

  <div
    class="flex-grow flex flex-col"
    [class.margin]="produtosCarrinho.length !== 0"
    [class.invalido]="!isValido"
    [ngClass]="{ 'desktop-margin': isDesktop }"
  >
    <ng-template #produtosCarrinhosTemp>
      <div
        [ngClass]="{
          'desktop-cart-max-height overflow-y-auto custom-scrollbar': isDesktop
        }"
      >
        <vip-lista-produtos-selecionados
          *ngFor="let item of produtosCarrinho; trackBy: trackByFnDepartamento"
          [produtosAgrupados]="item"
          [showIconDelete]="showIconDelete"
          [showSpin]="showIconDelete"
          [combosNoCarrinho]="combosNoCarrinho"
          (produtoChanged)="produtoChanged.emit($event)"
          (deleteItemClick)="deleteItemClick.emit($event)"
          [isDesktop]="isDesktop"
          [hiddenSeletor]="true"
          [isTablet]="isTablet"
        ></vip-lista-produtos-selecionados>
      </div>
    </ng-template>

    <vip-carousel-product
      *ngIf="produtosFavoritos && produtosFavoritos.length > 0 && carrinhoVazio"
      class="vip-background-complementary pt-4 pb-2"
      data-cy="carousel_produtos_favoritos"
      title="Favoritos"
      showMoreBtnLabel="Mais Produtos"
      [produtos]="produtosFavoritos"
      [produtosNoCarrinho]="produtosNoCarrinho"
      [isLoading]="isLoading"
      [isMobile]="isDesktopResponsive || isApp"
      (showMoreClick)="showMoreClick.emit('favoritos')"
      (addListClick)="addListClick.emit($event)"
      (produtoChanged)="produtoChanged.emit($event)"
      (aviseMeClick)="aviseMeClick.emit($event)"
      [aviseMeEnviados]="aviseMeEnviados"
    >
    </vip-carousel-product>

    <vip-carousel-product
      *ngIf="
        produtosMaisVendidos && produtosMaisVendidos.length > 0 && carrinhoVazio
      "
      data-cy="carousel_produtos_mais_vendidos"
      class="py-4"
      title="Mais Vendidos"
      showMoreBtnLabel="Mais Produtos"
      [ngClass]="{ 'vip-background-complementary pb-2': !produtosFavoritos }"
      [produtos]="produtosMaisVendidos"
      [produtosNoCarrinho]="produtosNoCarrinho"
      [isMobile]="isDesktopResponsive || isApp"
      (showMoreClick)="showMoreClick.emit('mais-vendidos')"
      (addListClick)="addListClick.emit($event)"
      (produtoChanged)="produtoChanged.emit($event)"
      (aviseMeClick)="aviseMeClick.emit($event)"
      [aviseMeEnviados]="aviseMeEnviados"
      [isLoading]="isLoading"
    >
    </vip-carousel-product>
  </div>

  <div
    class="vip-carrinho-bottom"
    *ngIf="!isLoading && produtosCarrinho.length !== 0"
    [ngClass]="{
      'vip-carrinho-bottom-desktop': isDesktop,
      'vip-carrinho-bottom-responsive': isDesktopResponsive
    }"
  >
    <vip-full-color-message
      *ngIf="!isValido && carrinho"
      type="error"
      icon="icon-error_outline"
      position="neutral"
      [ngClass]="{ fixed: isDesktop }"
    >
      Faltam
      {{ carrinho.valor_minimo - carrinho.preco | currency: 'BRL' }} para a
      compra mínima de
      {{ carrinho.valor_minimo | currency: 'BRL' }}
    </vip-full-color-message>

    <vip-resume
      [class.mt-10]="!isValido"
      class="block"
      [ngClass]="{ 'px-3': !isDesktop }"
      [label]="isValido ? 'Finalizar Compra' : 'Continuar Comprando'"
      [color]="isValido ? 'primary' : 'error'"
      [itens]="carrinho?.quantidade || 0"
      [valor]="carrinho?.preco || 0"
      (resumeClick)="handleResumeClick()"
    >
    </vip-resume>
  </div>
</div>
