import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';

export interface IRippleData {
  x: number;
  y: number;
  radius: number;
  diameter: number;
}

type RippleEvent = (MouseEvent | TouchEvent | KeyboardEvent) & {
  rippleStop?: boolean;
};

@Directive({
  selector: '[vipRipple]',
})
export class RippleDirective implements OnInit, OnChanges {
  constructor(private elementRef: ElementRef) {}

  @Input() rippleColor?: string;

  @Input() rippleTime = 300;

  @Input() rippleOpacity = 0.15;

  @Input() rippleBorderRadius?: string;

  @Input() rippleDisabled?: boolean;

  private content!: HTMLElement;
  private rippleContainer!: HTMLElement;

  ngOnInit(): void {
    this.content = this.elementRef.nativeElement;
    this.content.style.position = 'relative';

    this.createRippleContainer();

    this.content.addEventListener('mousedown', (e: RippleEvent) => {
      if (this.rippleDisabled || e.rippleStop) return;
      e.rippleStop = true;
      const rippleData = this.getClickPosition(this.content, e);
      this.rippleContainer = this.createRipple(rippleData);
    });
    this.content.addEventListener('mouseup', this.removeRipple.bind(this));
    this.content.addEventListener('mouseleave', this.removeRipple.bind(this));
  }

  private createRippleContainer() {
    this.content.insertAdjacentHTML(
      'afterbegin',
      `<span class="vip-ripple-container"></span>`
    );
    this.setupRippleContainer();
  }

  private setupRippleContainer() {
    this.rippleContainer = this.content?.querySelector(
      '.vip-ripple-container'
    ) as HTMLElement;
    if (!this.rippleContainer) return;
    this.rippleContainer.style.width = '100%';
    this.rippleContainer.style.height = '100%';
    this.rippleContainer.style.position = 'absolute';
    this.rippleContainer.style.overflow = 'hidden';
    this.rippleContainer.style.zIndex = '9999999999';
    this.rippleContainer.style.pointerEvents = 'none';
    this.rippleContainer.style.outline = 'none';
    this.rippleContainer.style.left = '0';
    this.rippleContainer.style.top = '0';
    this.rippleContainer.style.borderRadius = this.rippleBorderRadius || '5px';
  }

  private createRipple(rippleData: IRippleData) {
    this.rippleContainer.innerHTML = '<span class="vip-ripple"></span>';

    const ripple: HTMLElement = <HTMLElement>(
      this.content.querySelector('.vip-ripple')
    );
    ripple.style.transition = 'all 0s;';
    ripple.style.transform = 'scale(0)';

    setTimeout(() => {
      ripple.style.transform = 'scale(1)';
      ripple.style.transition = ` transform ${this.rippleTime / 1000}s ease-in`;
      ripple.style.backgroundColor = `var(--vip-${
        this.rippleColor || 'color-first-default-main'
      })`;
      ripple.style.left = `${rippleData.x}px`;
      ripple.style.top = `${rippleData.y}px`;
      ripple.style.width = `${rippleData.diameter}px`;
      ripple.style.height = `${rippleData.diameter}px`;
      ripple.style.borderRadius = `100%`;
      ripple.style.position = `absolute`;
      ripple.style.outline = `none`;
      ripple.style.opacity = this.rippleOpacity.toString();
    });

    return this.content.querySelector('.vip-ripple-container') as HTMLElement;
  }

  private removeRipple() {
    setTimeout(() => {
      this.rippleContainer.innerHTML = '';
    }, this.rippleTime);
  }

  private getClickPosition(content: HTMLElement, e: RippleEvent): IRippleData {
    const parentWidth = content.getBoundingClientRect().width;
    const parentHeight = content.getBoundingClientRect().height;
    const parentX = content.getBoundingClientRect().x;
    const parentY = content.getBoundingClientRect().y;
    const diameter = Math.max(parentHeight, parentWidth) * 3;
    const radius = diameter / 2;

    const clientX =
      (<TouchEvent>e).touches?.[0]?.clientX || (<MouseEvent>e).clientX;
    const clientY =
      (<TouchEvent>e).touches?.[0]?.clientY || (<MouseEvent>e).clientY;

    const x = clientX - parentX - radius;
    const y = clientY - parentY - radius;

    return { x, y, diameter, radius };
  }

  ngOnChanges() {
    this.setupRippleContainer();
  }
}
