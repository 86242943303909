import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ThemeService } from '@vip/ui/theme-provider';

@Directive()
export abstract class ButtonBaseDirective {
  @Input()
  isLoading = false;

  @Input()
  hasIcon = false;

  @Input()
  disabled: boolean | null = false;

  @Input()
  size: 'ex-small' | 'small' | 'medium' | 'large' = 'medium';

  @Input()
  type: 'raised' | 'outline' | 'mini-fab' | 'normal' | 'underline' = 'raised';

  @Input()
  noPadding = false;

  @Input()
  ariaExpanded?: boolean;

  @Input()
  ariaHaspopup?: boolean;

  @Input()
  ariaControls?: string;

  @Input()
  rounded = true;

  @Input()
  customSize = false;

  @Input()
  customRounded?: string;

  @Input()
  isDesktop = false;

  @Input()
  btnBordNoneDesktop = false;

  @Input()
  hasComboResume = false;

  @Input()
  verCombo = false;

  public rippleBorderRadius = '5px';

  @Output()
  btnClick = new EventEmitter();

  constructor(private themeService: ThemeService) {}

  get rippleColor() {
    const color = this.themeService.theme.component.button.color.main
      ? 'component-button-color'
      : 'color-primary';
    return this.type == 'raised' ? `${color}-contrast` : `${color}-main`;
  }

  public handleClick(event: Event) {
    if (!this.disabled) {
      this.btnClick.emit(event);
    }
  }
}
