<div
  (click)="onClick(this)"
  [class.opacity-50]="disabled"
  [class.pointer-events-none]="disabled"
  vipRipple
  rippleColor="color-primary-main"
  rippleBorderRadius="100px"
>
  <span class="vip-radio-button-input">
    <span
      class="rounded-full vip-radio-circle vip-radio-outer"
      [class.vip-radio-checked]="checked"
      [ngClass]="checked ? color : ''"
      ><span
        class="rounded-full vip-radio-circle vip-radio-inner"
        [class.vip-radio-checked]="checked"
        [ngClass]="checked ? color : ''"
      ></span
    ></span>

    <input
      [checked]="checked"
      [disabled]="disabled"
      [tabindex]="disabled ? -1 : 0"
      class="vip-radio-button-hidden"
      [value]="value"
      type="radio"
    />
  </span>
  <label class="vip-radio-button-label truncate">
    <ng-content></ng-content>
  </label>
</div>
