import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@vip/ui/button';
import { EmptyModule } from '@vip/ui/empty';
import { ModalModule } from '@vip/ui/modal';
import { SimpleTextFormModule } from '@vip/ui/simple-text-form';
import { AddToListComponent } from './add-to-list/add-to-list.component';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    EmptyModule,
    ModalModule,
    SimpleTextFormModule,
  ],
  declarations: [AddToListComponent],
  exports: [AddToListComponent],
})
export class AddToListModule {}
