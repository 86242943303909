import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageComponent } from './image/image.component';
import { SkeletonModule } from '@vip/ui/skeleton';

@NgModule({
  imports: [CommonModule, SkeletonModule],
  declarations: [ImageComponent],
  exports: [ImageComponent],
})
export class ImageModule {}
