import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ICarousel,
  IPaginaInstitucional,
  ITipoEntrega,
  MaskPatterns,
} from '@vip/core';

@Component({
  selector: 'vip-simular-frete',
  templateUrl: './simular-frete.component.html',
  styleUrls: ['./simular-frete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SimularFreteComponent implements OnChanges {
  readonly masks = {
    cep: MaskPatterns.CEP,
  };
  quantidadeItensMaps: { [k: string]: string } = {
    '=1': 'Limite de # item',
    other: 'Limite de # itens',
  };

  @Input() pagina!: IPaginaInstitucional;
  @Input() cepInvalido = false;
  @Input() loading = false;
  @Input() carousel: ICarousel | null = null;
  @Input() isDesktop = false;
  @Input() isMobile = false;
  @Input() isDesktopDevice = false;

  @Output() confirm = new EventEmitter();
  @Output() cdChange = new EventEmitter();
  @Output() btnLimpar = new EventEmitter();
  @Output() btnFiltrarTiposEntrega = new EventEmitter();

  hasFilters = false;
  form = this.fb.group({
    cep: ['', [Validators.required]],
  });
  formFilter = this.fb.group({
    entrega: [false],
    retirada: [false],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private ref: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    private el: ElementRef
  ) {}

  private _tiposEntregas!: ITipoEntrega[];

  ngOnChanges(): void {
    if (this.pagina) {
      this.pagina?.conteudo?.replace(
        /src="\/taxas\/consultar_frete"/,
        'src=""'
      );
    }
  }
  get tiposEntregas() {
    return this._tiposEntregas;
  }

  @Input()
  set tiposEntregas(value: ITipoEntrega[]) {
    this._tiposEntregas = value;

    this.hasFilters =
      value.some((e) => !e.retirada_loja) || this.formFilter.value.retirada;
  }

  onConfirmSolicitarCep() {
    this.clearControls();

    this.form.markAllAsTouched();
    this.ref.markForCheck();
    if (!this.form.invalid) {
      this.confirm.emit(this.form.controls.cep.value);
    }
  }

  handleBtnFiltrarTiposEntrega() {
    this.btnFiltrarTiposEntrega.emit(this.formFilter.value);
  }

  handleBtnLimpar() {
    this.form.reset();
    this.el.nativeElement.querySelector('[formcontrolname="cep"]').focus();
    this.clearControls();
    this.btnLimpar.emit();
  }

  clearControls() {
    this.hasFilters = false;
    this.formFilter.controls.entrega.setValue(false);
    this.formFilter.controls.retirada.setValue(false);
  }
}
