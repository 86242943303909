import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormEnderecoDirective } from '../form-endereco/form-endereco.directive';
import { FormEnderecoComponent } from '../form-endereco/form-endereco.component';
import { UntypedFormBuilder } from '@angular/forms';
import { LayoutUtilsService } from '@vip/core';
import { AddEditEnderecoRequest } from '@vip/api';

@Component({
  selector: 'vip-endereco',
  templateUrl: './endereco.component.html',
  styleUrls: ['./endereco.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnderecoComponent extends FormEnderecoDirective {
  @Input()
  loading = false;

  @Input() novoEndereco = false;

  @Input() possuiEnderecoCadastrado = true;

  @Input() possuiEnderecoPrincipalCadastrado = true;

  @Input()
  isEnderecoPrincipal = false;

  @Input()
  isLoading = false;

  @Input()
  isModalDesktop = false;

  @Input()
  entregaRetiradaTemplate = false;

  @Input()
  showDeleteButton = false;

  @Input()
  manualTitle: 'Novo Endereço' | 'Editar Endereço' | null = null;

  @Input()
  isModalCompletarCadastro = false;

  @Output()
  delete = new EventEmitter();

  @Output()
  confirm = new EventEmitter();

  @Output() closeClicked = new EventEmitter<void>();

  @Output()
  deleteClick = new EventEmitter();

  @ViewChild('formEnderecoComponent')
  formEnderecoComponent!: FormEnderecoComponent;

  formToSend?: AddEditEnderecoRequest;

  constructor(
    fb: UntypedFormBuilder,
    public layoutUtilsService: LayoutUtilsService
  ) {
    super(fb);
  }

  get isDesktop(): boolean {
    return this.layoutUtilsService.isDesktop();
  }

  get title(): string {
    return this.isNew ? 'Novo Endereço' : 'Editar Endereço';
  }

  onSave(dontConfirm = false) {
    this.formEnderecoComponent.submit();
    if (this.form.valid) {
      if (!this.possuiEnderecoPrincipalCadastrado) {
        this.form.get('principal')?.setValue(true);
      }
      this.formToSend = { ...this.endereco, ...this.form.getRawValue() };
      if (!dontConfirm) this.confirm.emit(this.formToSend);
    }
  }

  onCancel() {
    this.closeClicked.emit();
  }

  onDelete() {
    if (!this.isEnderecoPrincipal || this.isDesktop) {
      this.delete.emit(this.endereco);
    } else {
      this.deleteClick.emit(this.endereco);
    }
  }
}
