<vip-card-basico
  [disableRipple]="!cardClickHabilitado"
  icon="icon-credit_card"
  title="Formas de Pagamento"
  (click)="clickCard($event)"
>
  <div
    *ngFor="let pagamento of pagamentos; let last = last"
    class="vip-pagamento flex flex-col"
  >
    <div class="flex items-center flex-1">
      <div class="vip-credit-card flex items-center justify-center shadow">
        <vip-image
          [alt]="pagamento.forma_pagamento.descricao"
          [imageUrl]="pagamento.forma_pagamento.logo"
          [size]="ImageSizesEnum.sm"
        ></vip-image>
      </div>
      <div class="vip-credit-card-infos flex-1">
        <p
          id="cartao-titulo"
          class="text-sm font-medium vip-color-first-default mb-1"
        >
          {{ pagamento?.forma_pagamento?.descricao }}
        </p>
        <p class="text-sm vip-color-secondary-default">
          {{ pagamento | pagamentoTextoPipe }}
        </p>
      </div>

      <vip-button
        class="vip-alterar-btn"
        type="normal"
        size="small"
        *ngIf="editable && pagamentos.length"
        (btnClick)="alterarPagamentoClick.emit(pagamento)"
      >
        <span class="text-xs vip-color-secondary-main">Alterar</span>
      </vip-button>
    </div>

    <div
      *ngIf="
        pagamento.parcelas >= 1 &&
        pagamento.forma_pagamento.pagamento === FORMA_PAGAMENTO_ENUM.PRE_PAGO &&
        !pagamento.forma_pagamento.carteira_digital
      "
    >
      <hr class="vip-divider my-2" />
      <div class="flex items-center justify-between">
        <div class="vip-parcelas-wrapper">
          <p
            class="text-xs font-medium vip-color-first-default"
            [class.vip-color-secondary-main]="true"
          >
            Número de parcelas
          </p>
          <p
            data-cy="vip-parcelas-text"
            class="text-xs vip-color-secondary-default"
          >
            {{ pagamento | parcelasTextoPipe }}
          </p>
        </div>
        <vip-button
          *ngIf="editable && pagamentos.length"
          class="vip-alterar-btn"
          type="normal"
          size="small"
          (btnClick)="alterarParcelasClick.emit(pagamento.parcelas)"
        >
          <span class="text-xs vip-color-secondary-main">Alterar</span>
        </vip-button>
      </div>
    </div>

    <div *ngIf="editable && pagamento.forma_pagamento.id === 1">
      <hr class="vip-divider my-2" />
      <div class="flex items-center justify-between">
        <div class="vip-troco-wrapper">
          <p
            class="text-xs font-medium vip-color-first-default"
            [class.vip-color-secondary-main]="true"
          >
            Troco para
          </p>
          <p
            id="saldo-troco"
            class="text-xs font-medium vip-color-success-main"
          >
            {{ valorTroco | currency: 'BRL' }}
          </p>
        </div>
        <vip-button
          class="vip-alterar-btn"
          type="normal"
          size="small"
          (btnClick)="trocoClick()"
        >
          <span
            *ngIf="editable && pagamentos.length"
            class="text-xs vip-color-secondary-main"
          >
            Alterar
          </span>
        </vip-button>
      </div>
    </div>

    <div
      *ngIf="editable && pagamento.forma_pagamento.mensagem"
      class="flex flex-col"
    >
      <hr class="vip-divider my-2" />
      <span
        class="text-xs vip-color-secondary-default"
        [innerHtml]="
          sanitizer.bypassSecurityTrustHtml(pagamento.forma_pagamento.mensagem)
        "
      ></span>
    </div>

    <hr class="vip-divider my-2" *ngIf="!last" />
  </div>
  <div
    class="text-xs"
    [class.vip-color-first-default]="cardHabiltado"
    [class.vip-color-disabled]="!cardHabiltado"
    *ngIf="pagamentos.length === 0"
    data-cy="card_escolher_pagamento"
  >
    Escolher uma forma de pagamento
  </div>
</vip-card-basico>
