import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilialFacade } from '../+state/filial.facade';

@Injectable()
export class FilialEmManutencaoGuard implements CanActivate {
  constructor(private router: Router, private filialFacade: FilialFacade) {}

  canActivate(): Observable<boolean> {
    return this.filialFacade.filial$.pipe(
      map((filial) => {
        if (filial.em_manutencao) {
          this.router.navigateByUrl('manutencao');
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
