<h1 class="text-xl font-bold vip-color-primary-main mb-4">
  Complete o seu cadastro
</h1>

<p class="text-lg font-bold vip-color-secondary-default mb-4">
  Informações pessoais
</p>

<vip-meus-dados
  *ngIf="cliente$ | async; let cliente"
  class="bg-transparent vip-page-content"
  [ngClass]="{ 'mb-4': !showEnderecosForm }"
  [cliente]="cliente"
  [exibeSexo]="(filial$ | async)?.exibe_sexo || false"
  [tipoDeEdicao]="tipoDeEdicao"
  [isDesktop]="(isDesktop$ | async) ?? false"
  [isModal]="true"
  (saveMeusDados)="saveMeusDados($event)"
></vip-meus-dados>

<ng-container *ngIf="showEnderecosForm">
  <hr class="vip-divider my-4" />

  <p class="text-lg font-bold vip-color-secondary-default mb-4">
    Endereço de entrega
  </p>

  <vip-endereco
    class="mb-4"
    [endereco]="
      (enderecoSelecionado$ | async) || (newEndereco$ | async) || null
    "
    [cepAtendido]="(cepAtendido$ | async) === true"
    [cepValido]="(cepAtendido$ | async) === true"
    [isNew]="(enderecoSelecionado$ | async) === null"
    [possuiEnderecoCadastrado]="(possuiEnderecoCadastrado$ | async) === true"
    [loading]="(loadingCep$ | async) || (loadingEndereco$ | async) === true"
    [isModalCompletarCadastro]="true"
    (changeCep)="changeCep($event)"
    (confirm)="saveEndereco($event, true)"
  ></vip-endereco>
</ng-container>

<div class="vip-modal-buttons">
  <vip-button
    data-cy="btn-continuar-comprando"
    type="outline"
    (btnClick)="onCancel()"
    >Continuar comprando</vip-button
  >
  <vip-button data-cy="btn-confirmar" (btnClick)="onConfirm()"
    >Confirmar</vip-button
  >
</div>
