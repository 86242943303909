import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';

import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';

import * as ComboActions from './combo.actions';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CombosApiService } from '@vip/api';
import { BucketsFacade, BucketsService } from '@vip/state/buckets';
import { IProdutoCombo } from '@vip/core';

@Injectable()
export class ComboEffects {
  getCombos$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(ComboActions.getCombo)),
      this.cdFacade.filialECdSelecionado$,
    ]).pipe(
      map(([action, [filial, cdSelecionado]]) => {
        return { ...action, filial, cdSelecionado };
      }),
      fetch({
        run: (action) => {
          return this.combosApiService
            .getCombos(action.filial.id, action.cdSelecionado.id)
            .pipe(
              map(({ data }) =>
                ComboActions.getComboSuccess({
                  combos: data.combos,
                  filtro: data.filtros,
                  possuiFiltro: false,
                })
              )
            );
        },
        onError: (action, error) => {
          return ComboActions.getComboFailure({ error });
        },
      })
    );
  });

  getCombosDepartamentos$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(ComboActions.getCombosDepartamentos)),
      this.cdFacade.filialECdSelecionado$,
    ]).pipe(
      map(([action, [filial, cdSelecionado]]) => ({
        ...action,
        filial,
        cdSelecionado,
      })),
      fetch({
        run: (action) => {
          return this.combosApiService
            .getCombosDepartamento(
              action.filial.id,
              action.cdSelecionado.id,
              action.queryParams
            )
            .pipe(
              map(({ combos, filtros }) =>
                ComboActions.getComboSuccess({
                  combos,
                  filtro: filtros,
                  possuiFiltro: true,
                })
              )
            );
        },
        onError: (action, error) => {
          return ComboActions.getCombosDepartamentosFailure({ error });
        },
      })
    );
  });

  getCombosProdutos$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(ComboActions.getCombosProdutosByArray)),
      this.cdFacade.filialECdSelecionado$,
      this.bucketsFacade.bucketProduto$,
    ]).pipe(
      filter(([action]) => action.combosId && action.combosId.length > 0),
      map(([action, [filial, cdSelecionado], bucket]) => ({
        ...action,
        filial,
        cdSelecionado,
        combosId: action.combosId.map((comboId) => ({
          filialId: filial.id,
          cdId: cdSelecionado.id,
          comboId,
        })),
        bucket,
      })),
      fetch({
        run: (action) => {
          return this.combosApiService
            .getCombosProdutosByArray(action.combosId, action.queryParams)
            .pipe(
              map((produtos: IProdutoCombo[]) => {
                const produtoModificado = this.bucketsService.addBucketCombo(
                  produtos,
                  action.bucket
                );
                return ComboActions.getComboProdutosSuccess({
                  produtos: produtoModificado,
                });
              })
            );
        },
        onError: (action, error) => {
          return ComboActions.getComboProdutosFailure({ error });
        },
      })
    );
  });

  constructor(
    private actions$: Actions,
    private cdFacade: CentroDistribuicaoFacade,
    private combosApiService: CombosApiService,
    private bucketsFacade: BucketsFacade,
    private bucketsService: BucketsService
  ) {}
}
