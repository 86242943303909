<div class="flex flex-col w-full h-full" *ngIf="lista">
  <vip-screen-title
    title="Convite de Lista"
    leftIcon="icon-arrow_back"
    leftIconColor="secondary"
  >
  </vip-screen-title>

  <div class="flex flex-col flex-grow">
    <ng-container *ngIf="!isDesktop; else templateDesktop">
      <div class="flex-grow flex flex-col m-3 mt-4 text-center">
        <div *ngIf="!estaParticipando">
          <p class="vip-color-first-default text-lg font-medium">
            Você recebeu um convite de {{ lista.cliente?.nome }} para participar
            de uma lista!
          </p>
          <p class="vip-color-secondary-default text-sm mt-4">
            Clique no botão abaixo para começar a participar da lista!
          </p>
        </div>
        <div *ngIf="estaParticipando">
          <p class="vip-color-first-default text-lg font-medium">
            Convite aceito com sucesso!
          </p>
          <p class="vip-color-secondary-default text-sm mt-4">
            Você já está participando da lista
            <strong>{{ lista.nome }}</strong> de {{ lista.cliente?.nome }}.
          </p>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-template #templateDesktop>
    <div class="flex flex-col flex-grow">
      <div class="flex-grow flex flex-col mt-4 text-center">
        <div *ngIf="!estaParticipando">
          <p class="vip-compartilhar-lista font-medium">
            Convite para lista compartilhada
          </p>
          <p class="vip-color-secondary-default text-sm mt-4">
            Você recebeu um convite de {{ lista.cliente?.nome }} para <br />
            fazer parte da lista {{ lista.nome }}
          </p>
          <p class="vip-color-secondary-default text-sm mt-4">
            Clique no botão abaixo para começar a <br />
            participar da lista!
          </p>
        </div>
        <div *ngIf="estaParticipando">
          <p class="vip-color-first-default text-lg font-medium">
            Convite aceito com sucesso!
          </p>
          <p class="vip-color-secondary-default text-sm mt-4">
            Você já está participando da lista
            <strong>{{ lista.nome }}</strong> de {{ lista.cliente?.nome }}.
          </p>
        </div>
      </div>
    </div>
  </ng-template>

  <vip-button
    class="m-3"
    data-cy="btn-convite-lista"
    (click)="handleConviteClick()"
  >
    {{
      estaParticipando
        ? 'Acessar Lista'
        : !isDesktop
        ? 'Participar da Lista'
        : 'Aceitar'
    }}
  </vip-button>
</div>
