import { Injectable } from '@angular/core';
import { BaseApiService } from '../common/base-api-service';
import { Observable } from 'rxjs';
import { GetVitrineDedicadaResponse } from './vitrine-dedidaca.service.types';
import { pluck } from 'rxjs/operators';
import { IVitrinesDedicadas } from '@vip/core';

@Injectable({ providedIn: 'root' })
export class VitrineDedicadaApiService {
  constructor(private api: BaseApiService<VitrineDedicadaApiService>) {}

  getVitrines(
    filialId: number,
    cdId: number
  ): Observable<IVitrinesDedicadas[]> {
    return this.api
      .get<GetVitrineDedicadaResponse>(
        'getVitrines',
        `/filial/${filialId}/centro_distribuicao/${cdId}/loja/vitrines/dedicadas`
      )
      .pipe(pluck('data'));
  }
}
