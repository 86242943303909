import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as CompraActions from './compra.actions';
import { compraSelectors } from './compra.selectors';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class CompraFacade {
  loading$ = this.store.pipe(select(compraSelectors.isLoading()));
  hasError$ = this.store.pipe(select(compraSelectors.hasError()));
  error$ = this.store.pipe(select(compraSelectors.getError()));
  compras$ = this.store.pipe(select(compraSelectors.getCompras()));
  compra$ = this.store.pipe(select(compraSelectors.getCompraSelecionada()));
  compraSelecionadaId$ = this.store.pipe(
    select(compraSelectors.getCompraSelecionadaId())
  );
  perguntas$ = this.store.pipe(select(compraSelectors.getPerguntasCompra()));
  prazoTroca$ = this.store.pipe(select(compraSelectors.getPrazoTroca()));
  taxaServico$ = this.store.pipe(select(compraSelectors.getTaxaServico()));
  prazoDevolucao$ = this.store.pipe(
    select(compraSelectors.getPrazoDevolucao())
  );
  trocaDevolucao$ = this.store.pipe(
    select(compraSelectors.getTrocaDevolucao())
  );
  comprasEmAberto$ = this.store.pipe(
    select(compraSelectors.getComprasEmAberto())
  );
  comprasTrocasDevolucao$ = this.store.pipe(
    select(compraSelectors.getComprasTrocasDevolucao())
  );
  produtosCompraSelecionada$ = this.store.pipe(
    select(
      compraSelectors.getProdutosCompraSelecionadaAgrupadosPorDepartamento()
    )
  );
  temRespostas$ = this.store.pipe(select(compraSelectors.temRespostas()));
  getComprasSuccess$ = this.actions$.pipe(
    ofType(CompraActions.getComprasSuccess)
  );
  getCompraError$ = this.actions$.pipe(ofType(CompraActions.getComprasFailure));
  getTrocaDevolucaoSuccess$ = this.actions$.pipe(
    ofType(CompraActions.getTrocaDevolucaoSuccess)
  );
  getTrocaDevolucaoFailure$ = this.actions$.pipe(
    ofType(CompraActions.getTrocaDevolucaoFailure)
  );
  paginator$ = this.store.pipe(select(compraSelectors.getPaginator()));

  constructor(private store: Store, private readonly actions$: Actions) {}

  getComprasEmAberto() {
    this.store.dispatch(CompraActions.getComprasEmAberto());
  }

  getCompras() {
    this.store.dispatch(CompraActions.getCompras());
  }

  getCompra(compraId: number) {
    this.store.dispatch(CompraActions.getCompra({ compraId: compraId }));
  }

  getTrocaDevolucao() {
    this.store.dispatch(CompraActions.getTrocaDevolucao());
  }

  getPrazoTrocaDevolucao() {
    this.store.dispatch(CompraActions.getPrazoTrocaDevolucao());
  }

  getComprasTrocasDevolucao() {
    this.store.dispatch(CompraActions.getComprasTrocasDevolucao());
  }

  selectCompra(id: number | null) {
    this.store.dispatch(CompraActions.select({ compraSelecionadaId: id }));
  }

  getPerguntasCompraComResposta(compraId: number) {
    this.store.dispatch(
      CompraActions.getPerguntasCompraComResposta({ compraId })
    );
  }

  cancelarCompra(
    compraId: number,
    compra_status_id: number,
    observacao: string
  ) {
    this.store.dispatch(
      CompraActions.cancelarCompra({ compraId, compra_status_id, observacao })
    );
  }

  repetirCompra(compraId: number) {
    this.store.dispatch(CompraActions.repetirCompra({ compraId }));
  }

  repetirCompraSuccess() {
    this.store.dispatch(CompraActions.repetirCompraSuccess());
  }

  nextPage() {
    this.store.dispatch(CompraActions.nextPage());
  }
}
