import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromNotificacao from './+state/notificacao.reducer';
import { EffectsModule } from '@ngrx/effects';
import { NotificacaoEffects } from './+state/notificacao.effects';
import { NotificacaoFacade } from './+state/notificacao.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromNotificacao.NOTIFICACAO_FEATURE_KEY,
      fromNotificacao.reducer
    ),
    EffectsModule.forFeature([NotificacaoEffects]),
  ],
  providers: [NotificacaoFacade],
})
export class NotificacaoStateModule {}
