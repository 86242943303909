import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { DropdownItemEvent } from '../../types/dropdown-item.types';

@Component({
  selector: 'vip-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
})
export class DropdownItemComponent {
  constructor(private element: ElementRef) {}

  @Input()
  value?: string | number;
  @Input()
  selected?: boolean;
  @Input()
  disabled?: boolean;
  @Output()
  itemClick: EventEmitter<DropdownItemEvent> = new EventEmitter();

  get clickable() {
    return !this.disabled && this.itemClick.observers.length > 0;
  }

  get text(): string {
    return this.element.nativeElement.textContent?.trim() || '';
  }

  handleClick = (event: Event) => {
    if (!this.clickable) return;
    this.itemClick.emit(
      new DropdownItemEvent('click', this.value, this.text, event)
    );
  };
}
