import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { GenericStoreStatusEnum, mergeTakeOne } from '@vip/state/utils';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { BucketsFacade } from '@vip/state/buckets';
import { ProdutoApiService } from '@vip/api';
import { MultipleProdutoStore } from './produto.store';
import { ProdutoFacade } from './produto.facade';

@Injectable()
export class ProdutosVitrinesEspecificasStore extends MultipleProdutoStore {
  readonly getVitrineById = this.effect(
    (
      payload$: Observable<{ vitrineId: string; vitrinesId: Array<string> }>
    ) => {
      return payload$.pipe(
        mergeTakeOne(
          this.cdFacade.filialECdSelecionado$,
          this.bucketsFacade.bucketProduto$.pipe(
            filter((bucket) => bucket !== '')
          )
        ),
        map(([{ vitrineId, vitrinesId }, [filial, cdSelecionado], bucket]) => {
          return { vitrineId, vitrinesId, filial, cdSelecionado, bucket };
        }),
        mergeMap((payload) => {
          this.updateStatus(GenericStoreStatusEnum.LOADING);
          return this.produtoApiService
            .getVitrinesEspecificas(
              payload.filial.id,
              payload.cdSelecionado.id,
              payload.vitrinesId
            )
            .pipe(
              tapResponse(
                (response) => {
                  this.updateProdutosAndPaginator({
                    id: payload.vitrineId,
                    title: '',
                    produtos: response.data,
                    paginator: response.paginator,
                    bucket: payload.bucket,
                  });
                  this.produtoFacade.enviaImpressao(response.data);
                },
                (error) =>
                  this.updateError((error as HttpErrorResponse).error.error)
              )
            );
        })
      );
    }
  );

  constructor(
    private produtoApiService: ProdutoApiService,
    private bucketsFacade: BucketsFacade,
    private cdFacade: CentroDistribuicaoFacade,
    private produtoFacade: ProdutoFacade
  ) {
    super();
  }
}
