import { Injectable } from '@angular/core';
import { AtualizarCarrinhoItemRequest } from '@vip/api';
import { ISpinEventComProduto } from '@vip/ui/spin';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';
import { CompraEmProcessoService } from '@vip/state/compra-em-processo';
import { CarrinhoFacade } from '@vip/state/carrinho';
import { ClienteFacade } from '@vip/state/cliente';
import { ParametrosFacade } from '@vip/state/parametros';

@Injectable()
export class AtualizarCarrinhoService {
  qtdItensCarrinho$ = this.carrinhoFacade.qtdItensCarrinho$;
  isLogged$ = this.clienteFacade.isLogged$;
  permitirCarrinhoAnonimo$ = this.parametrosFacade.permitirCarrinhoAnonimo$;
  constructor(
    private carrinhoItensFacade: CarrinhoItensFacade,
    private compraEmProcessoService: CompraEmProcessoService,
    private carrinhoFacade: CarrinhoFacade,
    private clienteFacade: ClienteFacade,
    private parametrosFacade: ParametrosFacade
  ) {}

  setItemCarrinho(spinEvent: ISpinEventComProduto) {
    if (this.compraEmProcessoService.temCompraEmProcesso) return;
    if (spinEvent.produto) {
      const requestCarrinho: AtualizarCarrinhoItemRequest = {
        mercadoria_id:
          spinEvent.produto.produto_id ||
          (spinEvent.produto as any).mercadoria_id ||
          spinEvent.produto.oferta_id ||
          0,
        quantidade: spinEvent.quantity,
        observacao: spinEvent.produto.observacao,
        tipo: spinEvent.produto.tipo || 1,
        item_id: spinEvent.produto.item_id as number,
        seletor_medida_id: spinEvent.produto.seletor_medida_id as number,
      };

      if (!spinEvent.produto.habilitar_seletor_unidade_peso)
        delete requestCarrinho.seletor_medida_id;

      if (spinEvent.quantity <= 0) {
        if (spinEvent.produto.item_id)
          this.deleteItemCarrinho(
            parseInt(spinEvent.produto.item_id.toString())
          );
      } else {
        const exibirMensagemAdicionado =
          spinEvent.event.type !== 'decrementClick' &&
          spinEvent.event.type !== 'changeObservacao';
        this.atualizaItemCarrinho(requestCarrinho, exibirMensagemAdicionado);
      }
    }
  }

  setListaItensCarrinho(spinEvents: ISpinEventComProduto[]) {
    if (this.compraEmProcessoService.temCompraEmProcesso) return;
    const requestCarrinho: AtualizarCarrinhoItemRequest[] = spinEvents.reduce(
      (result: AtualizarCarrinhoItemRequest[], spinEvent) => {
        if (spinEvent.produto) {
          result.push({
            mercadoria_id:
              spinEvent.produto.produto_id || spinEvent.produto.oferta_id || 0,
            quantidade: spinEvent.quantity,
            tipo: spinEvent.produto.tipo || 1,
          });
        }
        return result;
      },
      []
    );
    this.atualizaListaItensCarrinho(requestCarrinho);
  }

  deleteItemCarrinho(itemId: number) {
    this.carrinhoItensFacade.removerItemCarrinho(itemId);
  }

  atualizaItemCarrinho(
    requestCarrinho: AtualizarCarrinhoItemRequest,
    exibirMensagemAdicionado: boolean
  ) {
    this.carrinhoItensFacade.setItemCarrinho(
      requestCarrinho,
      exibirMensagemAdicionado
    );
  }

  atualizaListaItensCarrinho(requestCarrinho: AtualizarCarrinhoItemRequest[]) {
    this.carrinhoItensFacade.setListaItensCarrinho(requestCarrinho);
  }
}
