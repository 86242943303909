import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ImageSizesEnum } from '@vip/ui/image';
import { Router } from '@angular/router';

@Component({
  selector: 'vip-tag-collection',
  templateUrl: './tag-collection.component.html',
  styleUrls: ['./tag-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagCollectionComponent {
  @Input() label!: string;
  @Input() icon!: string;
  @Input() defaultImageUrl!: string;
  @Input() id!: number;
  @Input() slug!: string;
  @Input()
  size: 'small' | 'medium' | 'large' = 'small';
  imageSizeEnum = ImageSizesEnum;

  constructor(private router: Router) {}

  redirectToCollection(event: Event) {
    event.stopPropagation();
    if (this.id && this.slug)
      this.router.navigateByUrl(`colecoes/${this.id}/${this.slug}`);
  }
}
