import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselFullscreenComponent } from './carousel-fullscreen/carousel-fullscreen.component';
import { ScrollableService } from '@vip/core';
import { CarouselComponent } from './carousel/carousel.component';
import { CarouselCardComponent } from './carousel-card/carousel-card.component';

import { CardProdutoModule } from '@vip/ui/card-produto';
import { ReceitaCardModule } from '@vip/ui/receita-card';
import { VideoPlayerModule } from '@vip/ui/video-player';
import { CarouselMultimediaComponent } from './carousel-multimedia/carousel-multimedia.component';
import { TagCollectionModule } from '@vip/ui/tag-collection';
import { TagsModule } from '@vip/ui/tags';
import { BannerImagemModule } from '@vip/ui/banner-imagem';
import { ImageModule } from '@vip/ui/image';
import { SkeletonModule } from '@vip/ui/skeleton';
import { SwiperModule } from 'swiper/angular';
import { CarouselTagsComponent } from './carousel-tags/carousel-tags.component';

@NgModule({
  declarations: [
    CarouselComponent,
    CarouselFullscreenComponent,
    CarouselCardComponent,
    CarouselMultimediaComponent,
    CarouselTagsComponent,
  ],
  imports: [
    CommonModule,
    CardProdutoModule,
    ReceitaCardModule,
    VideoPlayerModule,
    TagCollectionModule,
    TagsModule,
    BannerImagemModule,
    ImageModule,
    SkeletonModule,
    SwiperModule,
  ],
  exports: [
    CarouselComponent,
    CarouselFullscreenComponent,
    CarouselCardComponent,
    CarouselMultimediaComponent,
    CarouselTagsComponent,
  ],
  providers: [ScrollableService],
  schemas: [NO_ERRORS_SCHEMA],
})
export class CarouselModule {}
