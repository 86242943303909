import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../common/base-api-service';
import {
  GetBuscaRapidaResponse,
  GetBuscaResponse,
  GetSuggestionsResponse,
} from './busca-api.service.types';

@Injectable({
  providedIn: 'root',
})
export class BuscaApiService {
  constructor(private api: BaseApiService<BuscaApiService>) {}

  searchProdutosByTermo(
    filialId: number,
    cdId: number,
    termo: string,
    paginator: string,
    filtro?: string
  ): Observable<GetBuscaResponse> {
    termo = this.replaceTermoBusca(termo);
    return this.api.get<GetBuscaResponse>(
      'searchProdutosByTermo',
      `${this.api.filialAndCdUrl(
        filialId,
        cdId
      )}/buscas/produtos/termo/${termo}?${paginator}&${filtro}`
    );
  }

  quickSearchProdutosByTermo(
    filialId: number,
    cdId: number,
    termo: string
  ): Observable<GetBuscaRapidaResponse> {
    termo = this.replaceTermoBusca(termo);
    return this.api.get<GetBuscaRapidaResponse>(
      'quickSearchProdutosByTermo',
      `${this.api.filialAndCdUrl(
        filialId,
        cdId
      )}/buscas/produtos/termo/${termo}/rapida`
    );
  }

  getSuggestionsByTermo(
    filialId: number,
    termo: string
  ): Observable<GetSuggestionsResponse> {
    termo = this.replaceTermoBusca(termo);
    return this.api.get<GetSuggestionsResponse>(
      'getSuggestionsByTermo',
      `/filial/${filialId}/loja/buscas/semelhantes/termo/${termo}`
    );
  }

  private replaceTermoBusca(query: string) {
    return query.trim().replace(/[\\&/\s%?=]/g, '+');
  }
}
