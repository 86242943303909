<div
  class="text-xs font-medium grid grid-flow-col cursor-pointer vip-switch"
  [class.disabled]="
    (thirdOption
      ? [firstOption, secondOption, thirdOption]
      : [firstOption, secondOption]
    ) | isOnlyOneEnable
  "
>
  <div
    vipRipple
    rippleColor="color-primary-main"
    rippleBorderRadius="100px"
    class="w-full flex items-center justify-center"
    data-cy="vip-first-option"
    [rippleDisabled]="firstOption.disabled"
    [class.active]="firstOption === optionChecked"
    [class.inactive]="firstOption !== optionChecked"
    [class.disabled]="firstOption.disabled"
    (click)="
      firstOption.disabled ? $event.preventDefault() : checkOption(firstOption)
    "
  >
    <i class="text-sm" [class]="firstOption.icon"></i>
    <span> {{ firstOption.label }} </span>
  </div>
  <div
    vipRipple
    rippleColor="color-primary-main"
    rippleBorderRadius="100px"
    class="w-full flex items-center justify-center"
    data-cy="vip-second-option"
    [rippleDisabled]="secondOption.disabled"
    [class.active]="secondOption === optionChecked"
    [class.inactive]="secondOption !== optionChecked"
    [class.disabled]="secondOption.disabled"
    (click)="
      secondOption.disabled
        ? $event.preventDefault()
        : checkOption(secondOption)
    "
  >
    <i class="text-sm" [class]="secondOption.icon"></i>
    <span> {{ secondOption.label }} </span>
  </div>
  <div
    *ngIf="thirdOption"
    vipRipple
    rippleColor="color-primary-main"
    rippleBorderRadius="100px"
    class="w-full flex items-center justify-center"
    data-cy="vip-third-option"
    [rippleDisabled]="thirdOption.disabled"
    [class.active]="thirdOption === optionChecked"
    [class.inactive]="thirdOption !== optionChecked"
    [class.disabled]="thirdOption.disabled"
    (click)="
      thirdOption.disabled ? $event.preventDefault() : checkOption(thirdOption)
    "
  >
    <i class="text-sm" [class]="thirdOption.icon"></i>
    <span> {{ thirdOption.label }} </span>
  </div>
</div>
