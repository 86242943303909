<div
  class="lgpd--cookie"
  [ngClass]="{ 'lgpd--cookie__opened': !autorizadoUsarCookie }"
>
  <p>
    Nós utilizamos <strong>cookies</strong> para melhorar sua experiência de
    navegação, personalizar conteúdo e anúncios, e para analisar nosso tráfego.
    Ao continuar navegando em nosso site, você concorda com a nossa
    <a
      class="lgpd-politica-privacidade"
      href="/institucional/pagina/politica-de-privacidade"
      target="_blank"
      >Política de Privacidade</a
    >.
  </p>
  <vip-button class="md:w-1/3 sm:w-full" (click)="aceitarUsoCookie()"
    >continuar e fechar</vip-button
  >
</div>
