import { ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { Component } from '@angular/core';
import { ButtonBaseDirective } from './button-base.directive';

@Component({
  selector: 'vip-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent extends ButtonBaseDirective {}
