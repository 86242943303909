import { SelectableCardModule } from '@vip/ui/selectable-card';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardEnderecoEntregaComponent } from './card-endereco-entrega/card-endereco-entrega.component';
import { SelectableTileModule } from '@vip/ui/selectable-tile';

@NgModule({
  imports: [CommonModule, SelectableCardModule, SelectableTileModule],
  declarations: [CardEnderecoEntregaComponent],
  exports: [CardEnderecoEntregaComponent],
})
export class CardEnderecoEntregaModule {}
