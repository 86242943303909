import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import * as CepActions from './cep.actions';
import { cepSelectors } from './cep.selectors';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class CepFacade {
  loading$ = this.store.pipe(select(cepSelectors.isLoading()));
  isLoaded$ = this.store.pipe(select(cepSelectors.isLoaded()));
  error$ = this.store.pipe(select(cepSelectors.getError()));

  cep$ = this.store.pipe(select(cepSelectors.cep()));
  cepAtendido$ = this.store.pipe(select(cepSelectors.cepAtendido()));
  apenasRetirada$ = this.store.pipe(select(cepSelectors.apenasRetirada()));
  solicitacaoDeCepEnviada$ = this.store.pipe(
    select(cepSelectors.solicitacaoDeCepEnviada())
  );

  enderecoDoCep$ = this.store.pipe(select(cepSelectors.enderecoDoCep()));
  enderecoDoCepFormatado$ = this.store.pipe(
    select(cepSelectors.enderecoDoCepFormatado())
  );
  getData$ = this.store.pipe(select(cepSelectors.getData()));
  cepAtendidoResponseSuccess$ = this.actions$.pipe(
    ofType(CepActions.validarCepAtendidoPelaFilialSuccess)
  );

  constructor(private store: Store, private actions$: Actions) {}

  validarCepAtendidoPelaFilial(cep: string) {
    this.store.dispatch(CepActions.validarCepAtendidoPelaFilial({ cep }));
  }

  addSolicitacaoCep(cep: string, email: string) {
    this.store.dispatch(CepActions.addSolicitacaoCep({ cep, email }));
  }

  cepReset() {
    this.store.dispatch(CepActions.resetCepStore());
  }
}
