import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ValidationMessagesPipe } from '@vip/core';

@Component({
  selector: 'vip-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FieldComponent implements OnInit, AfterViewInit {
  constructor(
    private validationMessagePipe: ValidationMessagesPipe,
    private ref: ChangeDetectorRef
  ) {}

  @Input()
  message?: string | string[] | { [key: string]: string } = [];

  @Input()
  validations?: { [key: string]: boolean } | null;

  @Input()
  error? = false;

  @Input()
  showMessage? = false;

  @Input()
  icon? = '';

  @Input()
  iconDirection?: 'left' | 'right';

  @Input()
  label? = '';

  @Input()
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.initInputListeners();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  @Output()
  iconClick: EventEmitter<Event> = new EventEmitter();

  @ViewChild('contentWrapper') content!: ElementRef;

  hasFocus!: boolean;
  isTextArea = false;

  public hasIconClickObservers = false;
  private previousMessageList: string[] = [];
  private _isLoading = false;

  get classes() {
    const classIconClickable = this.hasIconClickObservers
      ? 'icon_clickable'
      : '';
    const classIconDirection =
      'icon_direction-' + (this.iconDirection || 'left');

    return (
      (this.label && this.label != '' ? 'with-label ' : 'without-label ') +
      (this.icon && this.icon != ''
        ? `with-icon ${classIconDirection} ${classIconClickable} `
        : 'without-icon ') +
      (this.error ? 'error ' : '') +
      (this.isTextArea ? 'with-textarea ' : '') +
      (this.hasFocus ? 'focused ' : '')
    );
  }

  get messageClasses() {
    return `${this.error || this.showMessage ? '' : 'hide'} ${
      this.error ? 'error' : ''
    }`;
  }

  get messageList() {
    let result: string[] = this.previousMessageList || [];
    if (Array.isArray(this.message)) {
      this.previousMessageList = this.message;
      result = this.previousMessageList;
    } else if (typeof this.message == 'string') {
      this.previousMessageList = [this.message];
      result = this.previousMessageList;
    } else if (this.validations && this.message) {
      this.previousMessageList = this.validationMessagePipe.transform(
        this.validations,
        this.message
      );

      /**
       * Este trecho tem por objetivo não esvaziar as mensagens de validação
       * de uma única vez, permitindo ativar a animação de ocultar as
       * mensagens quando o ultimo erro de validação for resolvido
       */
      if (
        Object.entries(this.validations).some((validation) => validation[1])
      ) {
        result = this.previousMessageList;
      }
    }

    return result;
  }

  get skeletonClasses(): string[] {
    const styleClasses = [
      'vip-skeleton-field',
      'absolute',
      'set-sizes-in-parent',
    ];
    if (this.isTextArea) {
      styleClasses.push('with-text-area');
    }
    return styleClasses;
  }

  public isDisabled() {
    return this.content.nativeElement.querySelector('input:disabled') || false;
  }

  public handleIconClick(event: Event) {
    if (!this.isDisabled()) this.iconClick.emit(event);
  }

  public ngOnInit() {
    this.hasIconClickObservers = this.iconClick.observers.length > 0;
  }

  public setDefaultPlaceholder(input: HTMLInputElement) {
    if (!input.placeholder || input.placeholder === 'undefined') {
      input.placeholder = ' ';
    }
  }

  public ngAfterViewInit() {
    this.initInputListeners();
  }

  private setFocus(value: boolean) {
    this.hasFocus = value;
    this.ref.detectChanges();
  }

  private initInputListeners(): void {
    if (!this.content) {
      return;
    }

    const input = <HTMLInputElement>(
      (this.content.nativeElement.querySelector('input') ||
        this.content.nativeElement.querySelector('textarea'))
    );

    if (input.tagName == 'TEXTAREA') {
      this.isTextArea = true;
      input.addEventListener('focus', this.setFocus.bind(this, true));
      input.addEventListener('focusout', this.setFocus.bind(this, false));
      this.ref.detectChanges();
    }

    this.setDefaultPlaceholder(input);
  }
}
