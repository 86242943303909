import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CarrinhoActions, CarrinhoFacade } from '@vip/state/carrinho';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import * as AnalyticsActions from './analytics.actions';
import { FormaPagamentosFacade } from '@vip/state/forma-pagamento';
import { ClassificacaoMercadologicaFacade } from '@vip/state/classificacao-mercadologica';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import {
  Ga4UtilsService,
  FacebookPixelService,
  IGa4Item,
  IClassificacaoMercadologica,
  IComboItemCarrinho,
  IProdutoCarrinho,
  TipoOfertaEnum,
} from '@vip/core';
import { AnalyticsFacade } from './analytics.facade';
import { ClienteActions } from '@vip/state/cliente';

@Injectable()
export class AnalyticsEffects {
  adicionarAListaDeCompras$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.addToWishList),
        withLatestFrom(this.classificacaoMercadologicaFacade.arvore$),
        tap(([action, arvore]) => {
          const value = this.formatarNumero(action.product.preco);
          const itens = [
            {
              item_id: action.product.produto_id,
              item_name: action.product.descricao,
              price: value,
              quantity: 1,
            },
          ];
          this.ga4UtilsService.addToWishList(value, itens);
        })
      ),
    { dispatch: false }
  );

  visualizarPaginaDoProduto$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.viewItem),
        tap((action) => {
          let discount = 0;
          let price = action.product.preco;

          if (action.product.oferta) {
            price = action.product.oferta.preco_oferta;
            discount =
              action.product.oferta.preco_antigo -
              action.product.oferta.preco_oferta;
          }

          const itensGa4: IGa4Item[] = [
            {
              item_id: action.product.produto_id,
              item_name: action.product.descricao,
              price: this.formatarNumero(price),
              discount: this.formatarNumero(discount),
              quantity: 1,
            },
          ];
          this.ga4UtilsService.viewProductPage(
            this.formatarNumero(action.product.preco),
            itensGa4
          );
        })
      ),
    { dispatch: false }
  );

  pesquisarTermo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.search),
        tap((action) => {
          this.ga4UtilsService.searchProduct(action.searchTerm);
          this.facebookPixelService.search(action.searchTerm);
        })
      ),
    { dispatch: false }
  );

  viewCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.viewCart),
        withLatestFrom(
          this.carrinhoFacade.carrinho$,
          this.classificacaoMercadologicaFacade.arvore$
        ),
        filter(
          ([action, carrinho]) =>
            carrinho.itens !== undefined && carrinho.itens.length > 0
        ),
        tap(([_, carrinho, arvore]) => {
          const itensGa: IGa4Item[] = [];
          carrinho.itens?.map((item) => {
            if (item) {
              let objeto;

              if (item.combo && item !== null) {
                item.combo.produtos.forEach((item) => {
                  objeto = this.montaItem(item, arvore);
                  itensGa.push(objeto[1]);
                });
              } else {
                objeto = this.montaItem(item, arvore);
                itensGa.push(objeto[1]);
              }
            }
          });
          this.ga4UtilsService.viewCart(
            this.formatarNumero(carrinho.preco),
            itensGa
          );
        })
      ),
    { dispatch: false }
  );

  addItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.addToCart),
        withLatestFrom(this.classificacaoMercadologicaFacade.arvore$),
        tap(([action, arvore]) => {
          const itensGa: IGa4Item[] = [];
          if (action.item) {
            let objeto;
            if (action.item.combo && action.item !== null) {
              action.item.combo.produtos.forEach((item) => {
                objeto = this.montaItem(item, arvore);
                itensGa.push(objeto[1]);
                this.ga4UtilsService.addToCart(objeto[0] as number, itensGa);
              });
            } else {
              objeto = this.montaItem(action.item, arvore);
              itensGa.push(objeto[1]);
              this.ga4UtilsService.addToCart(objeto[0] as number, itensGa);
            }
          }
        })
      ),
    { dispatch: false }
  );

  removeItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.removeFromCart),
        withLatestFrom(
          this.carrinhoFacade.carrinho$,
          this.classificacaoMercadologicaFacade.arvore$
        ),
        tap(([action, carrinho, arvore]) => {
          const itensGa: IGa4Item[] = [];
          action.itens?.forEach((item) => {
            if (item) {
              let objeto;
              if (item.combo && item !== null) {
                item.combo.produtos.forEach((item) => {
                  objeto = this.montaItem(item, arvore);
                  itensGa.push(objeto[1]);
                  this.ga4UtilsService.removeFromCart(
                    objeto[0] as number,
                    itensGa
                  );
                });
              } else {
                objeto = this.montaItem(item, arvore);
                itensGa.push(objeto[1]);
                this.ga4UtilsService.removeFromCart(
                  objeto[0] as number,
                  itensGa
                );
              }
            }
          });
        })
      ),
    { dispatch: false }
  );

  verificaEnvioGa4$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoActions.getCarrinhoSuccess),
        filter((action) => !!action.enviaGa4),
        tap((action) => {
          if (action.carrinho.itens !== undefined) {
            action.carrinho.itens.forEach((item) => {
              const newItem = { ...item, combo: undefined };
              this.analyticsFacade.addToCarrinho(newItem);
            });
          }
        })
      ),
    { dispatch: false }
  );

  removeCarrinhoGa4$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoActions.removeCarrinhoSuccess),
        withLatestFrom(this.carrinhoFacade.carrinho$),
        tap(([action, carrinho]) => {
          if (carrinho.itens !== undefined) {
            this.analyticsFacade.removerDoCarrinho(carrinho.itens);
          }
        })
      ),
    { dispatch: false }
  );

  beginCheckout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.beginCheckout),
        withLatestFrom(
          this.carrinhoFacade.carrinho$,
          this.classificacaoMercadologicaFacade.arvore$
        ),
        tap(([action, carrinho, arvore]) => {
          const itensGa: IGa4Item[] = [];
          if (carrinho?.itens?.length) {
            let objeto;
            carrinho.itens.forEach((item) => {
              if (item.combo && item !== null) {
                item.combo.produtos.map((itemCombo) => {
                  objeto = this.montaItem(itemCombo, arvore);
                  itensGa.push(objeto[1]);
                });
              } else {
                objeto = this.montaItem(item, arvore);
                itensGa.push(objeto[1]);
              }
            });

            this.ga4UtilsService.beginCheckout(carrinho.preco, itensGa);
          }
        })
      ),
    { dispatch: false }
  );

  addShippingInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.addShippingInfo),
        tap(({ compra }) => {
          const items: Array<IGa4Item> = compra.produtos.map((produto) => {
            return {
              item_id: produto.produto_id,
              item_name: produto.descricao,
              item_category: produto.departamento?.descricao || '',
              price: this.formatarNumero(produto.preco_venda),
              quantity: produto.quantidade,
            };
          });
          this.ga4UtilsService.addShippingInfo(
            this.formatarNumero(compra.valor_inicial),
            compra.tipo_entrega.horario_selecionado?.key || '',
            items
          );
        })
      ),
    { dispatch: false }
  );

  addPaymentInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.addPaymentInfo),
        withLatestFrom(this.formaPagamentoFacade.formaPagamentos$),
        tap(([action, formaPagamentos]) => {
          const tipoPagamento = formaPagamentos.find(
            (pagamento) => pagamento.id === action.tipoPagamentoId
          );

          const items: Array<IGa4Item> = action.compraEmProcesso?.produtos.map(
            (produto) => {
              return {
                item_id: produto.produto_id,
                item_name: produto.descricao,
                item_category: produto.departamento?.descricao || '',
                price: this.formatarNumero(produto.preco_venda),
                quantity: produto.quantidade,
              };
            }
          );

          this.ga4UtilsService.addPaymentInfo(
            this.formatarNumero(action.compraEmProcesso?.valor_final) || 0,
            tipoPagamento?.descricao || '',
            items
          );
        })
      ),
    { dispatch: false }
  );

  purchase$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.purchase),
        tap(({ pedido }) => {
          const items: Array<IGa4Item> = pedido?.produtos.map((produto) => {
            return {
              item_id: produto.produto_id,
              item_name: produto.descricao,
              item_category: produto.departamento?.descricao || '',
              price: this.formatarNumero(produto.preco_venda),
              quantity: produto.quantidade,
            };
          });

          this.facebookPixelService.purchase(pedido.valor_inicial);
          this.ga4UtilsService.purchase(
            this.formatarNumero(pedido.valor_inicial),
            pedido.frete,
            pedido.id.toString(),
            items
          );
        })
      ),
    { dispatch: false }
  );

  cadastro$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.signUp),
        tap((action) => {
          this.ga4UtilsService.loginSignup('sign_up');
        })
      ),
    { dispatch: false }
  );

  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.login),
        tap((action) => {
          this.ga4UtilsService.loginSignup('login');
        })
      ),
    { dispatch: false }
  );

  addClienteEnviaGa4 = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.addClienteSuccess),
        tap(() => this.analyticsFacade.cadastrar())
      ),
    { dispatch: false }
  );

  loginEnviaGa4 = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClienteActions.loginSuccess),
        tap(() => this.analyticsFacade.login())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private carrinhoFacade: CarrinhoFacade,
    private ga4UtilsService: Ga4UtilsService,
    private facebookPixelService: FacebookPixelService,
    private formaPagamentoFacade: FormaPagamentosFacade,
    private analyticsFacade: AnalyticsFacade,
    private classificacaoMercadologicaFacade: ClassificacaoMercadologicaFacade,
    protected $gaService: GoogleAnalyticsService
  ) {}

  getDepartamento(
    item: IProdutoCarrinho | IComboItemCarrinho,
    arvore: IClassificacaoMercadologica[]
  ): string {
    if (item.departamento) {
      return item.departamento.descricao;
    }

    const cm = item.produto_carrinho?.classificacao_mercadologica;

    if (cm?.parent?.descricao) {
      return cm?.parent?.descricao;
    }

    const depart = arvore.find(
      (departamento) =>
        departamento.classificacao_mercadologica_id ===
        (item.classificacao_mercadologica_id ?? cm?.parent_id)
    );
    return depart?.descricao ?? '';
  }

  montaItem(
    item: IProdutoCarrinho | IComboItemCarrinho,
    arvore: IClassificacaoMercadologica[],
    isViewCartEvent: boolean = false,
    isBeginCheckout: boolean = false
  ): [number, IGa4Item] {
    let quantity = item.quantidade ?? item.quantidade_minima;
    let discount = 0;
    let price = item.preco ?? item.preco_venda;

    if (item.oferta) {
      price = item.oferta.preco_oferta;
      discount = item.oferta.preco_antigo - item.oferta.preco_oferta;
      quantity =
        item.oferta.tipo_oferta_id === TipoOfertaEnum.COMBO_DE_PRODUTOS
          ? item.oferta.quantidade_minima
          : quantity;
    }

    const itensGa4: IGa4Item = {
      item_id: item.produto_id ?? item.mercadoria_id,
      item_name: item.descricao,
      price: this.formatarNumero(price, 2),
      quantity,
    };

    if (!isViewCartEvent) {
      if (!isBeginCheckout) {
        itensGa4.discount = this.formatarNumero(discount, 2);
      }
      itensGa4.item_category =
        item.oferta &&
        item.oferta.tipo_oferta_id === TipoOfertaEnum.COMBO_DE_PRODUTOS
          ? ''
          : this.getDepartamento(item, arvore);
    }

    return [this.formatarNumero(price * quantity), itensGa4];
  }

  formatarNumero(numero: number, casasDecimais = 2) {
    return Number(Number(numero).toFixed(casasDecimais));
  }
}
