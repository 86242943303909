<div class="flex flex-col w-full h-full absolute">
  <vip-screen-title
    title="Pagamento"
    [customActions]="true"
    [showActions]="true"
  >
    <vip-button-icon-rounded
      (btnClick)="
        voltarClick.emit({
          compraId: compraEmProcesso?.id,
          filialId: compraEmProcesso?.filial_id,
          cdId: compraEmProcesso?.centro_distribuicao_id
        })
      "
      [disabled]="loading"
      class="z-10"
      data-cy="voltar"
      type="outline"
    >
      <i class="icon-arrow_back vip-color-secondary-main"></i>
    </vip-button-icon-rounded>
  </vip-screen-title>
  <div class="flex flex-col flex-grow">
    <div class="flex flex-col flex-grow p-3">
      <vip-card-resumo-produtos
        (verProdutosClick)="verItens()"
        *ngIf="produtosComDesconto.length > 0"
        [limit]="limitProdutos"
        [produtos]="produtosComDesconto"
        class="flex mb-3"
        title="Produtos com Desconto"
      >
      </vip-card-resumo-produtos>

      <vip-card-pagamento
        (alterarPagamentoClick)="
          handleAlterarPagamento(); alterouParcelas = false
        "
        (alterarParcelasClick)="
          openModalParcelas($event); alterouParcelas = true
        "
        [editable]="true"
        [pagamentos]="pagamentos"
        [totalCompra]="totalCompra || 0"
        [valorTroco]="valorTroco || 0"
        class="flex mb-3"
      ></vip-card-pagamento>

      <vip-card-cashback
        (alterarValorCashbackClick)="handleAlterarCashback($event)"
        *ngIf="!!saldoCashback"
        [editable]="true"
        [saldoCashback]="saldoCashback || undefined"
        [cashbackAplicado]="compraEmProcesso?.valor_cashback || 0"
        [totalCompra]="totalCompra"
        [valorMinimoCashback]="valorMinimoCashback"
        class="flex mb-3"
      ></vip-card-cashback>

      <vip-card-cupons-compra
        (escolherClick)="escolherCupons()"
        (removerClick)="removerCupomSelecionado.emit($event)"
        (removerCodigoPromocionalClick)="
          removerCodigoPromocionalClick.emit($event)
        "
        [codigoPromocional]="codigoPromocional"
        [cupons]="cuponsSelecionados"
        [editable]="true"
        class="flex mb-3"
      ></vip-card-cupons-compra>

      <vip-card-opcao-faturamento
        (changed)="changedOpcaoFaturamento.emit($event)"
        *ngIf="exibirOpcoesFaturamento"
        [opcaoFaturamento]="opcaoFaturamento"
        [opcoesFaturamento]="opcoesFaturamento"
        class="flex"
      ></vip-card-opcao-faturamento>
    </div>
    <vip-card-detalhes-cobranca
      [descontos]="descontos"
      [accordion]="true"
      [taxaEntrega]="taxaEntrega"
      [taxaEntregaAnterior]="getTaxaEntregaSemDescontoCupom()"
      [taxaServico]="taxaServico"
      [valorAcrescimos]="valorAcrescimos"
      [valorCompra]="compraEmProcesso?.valor_inicial || 0"
      [valorDescontos]="valorDescontos"
    >
    </vip-card-detalhes-cobranca>

    <div class="py-4 px-3">
      <vip-button
        (btnClick)="finalizarCompra()"
        [disabled]="loading || excedeuLimiteTentativas"
        class="w-full"
        data-cy="btn-finalizar-compra"
      >
        Finalizar Compra
      </vip-button>
    </div>
  </div>
</div>
