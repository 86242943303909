import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ISpinEvent } from './spinEvent';
import { DialogService, IDialog } from '@vip/ui/modal';
import { MessageService } from '@vip/ui/message';
import { Subject } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  IProduto,
  IProdutoCombo,
  IProdutoVolume,
  LayoutUtilsService,
  SeletorDePesoEnum,
} from '@vip/core';
import { CompraEmProcessoService } from '@vip/state/compra-em-processo';
@UntilDestroy()
@Component({
  selector: 'vip-spin',
  templateUrl: './spin.component.html',
  styleUrls: ['./spin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SpinComponent implements OnInit, OnChanges {
  @ViewChild('quantityInput') quantityInput!: ElementRef<HTMLInputElement>;

  private _disabled = false;
  private _max = 999;

  readonly min = 0;
  protected readonly SELETOR_DE_PESO_ENUM = SeletorDePesoEnum;
  quantityEvent = this.min;
  _quantity!: number;
  isDesktop = this.layoutUtilsService.isDesktop();
  seletorPeso = false;
  defaultSelection: 'unit' | 'weight' = 'unit';
  userChangedSelection = false;
  isFocused = false;
  naoPodeEditar = false;
  deleteAll = false;
  isDeleting = false;
  valorAtribuido: number | null = null;

  @Input() large = false;
  @Input() disableOnMax = true;
  @Input() size: 'ex-small' | 'small' | 'medium' = 'medium';
  @Input() addIcon = 'icon-add';
  @Input() addLabel = 'Adicionar';
  @Input() quantityUnity!: number;

  @Input() styleSpin = false;
  @Input() isTablet: boolean | null = false;
  @Input() seletorDeMedidaLista: number | null | undefined =
    SeletorDePesoEnum.UNIDADE;

  @Input() confimDeletion = false;
  @Input() titleModalConfimDeletion = 'Deseja realmente remover o item?';
  @Input() subTitleModalConfimDeletion!: string;
  @Input() unityMeasureAbbreviation = 'kg';
  @Input() debounceTime = 300;
  @Input() volumePrincipal: IProdutoVolume | null = null;
  @Input() focusable = false;
  @Input() tabIndex = 0;
  @Input() ariaControls?: string;
  @Input() squared = false;
  @Input() possuiUnidadeDiferente = false;
  @Input() hasDetailProduct = false;
  @Input() seletorDePesoHabilitado: boolean | null | undefined = false;
  @Input() tipoDeMedida: number | null | undefined = null;
  @Input() seletorMedidaID: number | null | undefined = null;
  @Input() miniCard: boolean | null = null;
  @Input() hiddenSeletor = false;
  @Input() produto: IProduto | IProdutoCombo | null = null;
  @Input() hasCombo = false;
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(disabled: boolean | string) {
    this._disabled = this.getBooleanValue(disabled);
  }

  @Input()
  set quantity(quantity: number) {
    this.quantityEvent = quantity;
    this._quantity = quantity;
  }

  get quantity(): number {
    return this._quantity;
  }

  @Input()
  get max() {
    return this._max;
  }

  set max(max: any) {
    this._max = parseInt(max);
  }

  @Output() changed: EventEmitter<ISpinEvent> = new EventEmitter();
  @Output() openedConfirmDeleteModal: EventEmitter<boolean> =
    new EventEmitter();
  @Output() adsClick: EventEmitter<ISpinEvent> = new EventEmitter();
  private clicks = new Subject<ISpinEvent>();

  constructor(
    private dialogService: DialogService,
    private messageService: MessageService,
    private layoutUtilsService: LayoutUtilsService,
    private cdr: ChangeDetectorRef,
    private compraEmProcessoService: CompraEmProcessoService
  ) {}

  ngOnInit() {
    this.clicks
      .pipe(debounceTime(this.debounceTime), untilDestroyed(this))
      .subscribe((event) => this.changed.emit(event));
    this.changeSeletorCarrinho();
  }

  ngOnChanges(): void {
    if (this.seletorMedidaID) {
      if (this.seletorMedidaID === 2) {
        this.unidadeSelecionada();
        this.cdr.markForCheck();
      }
    }
    this.changeSeletorCarrinho();
  }

  changeSeletorCarrinho() {
    if (this.tipoDeMedida != null && !this.userChangedSelection) {
      this.defaultSelection = this.tipoDeMedida === 1 ? 'weight' : 'unit';
      this.seletorPeso = this.defaultSelection === 'weight';
    }
  }

  increment(event: Event, dontAutoIncrement = false) {
    this.adsClick.emit();
    event.stopPropagation();
    if (!this.max || this.quantityEvent < this.max) {
      if (!dontAutoIncrement) {
        this.valorAtribuido !== null
          ? this.valorAtribuido
          : this.quantityEvent++;
      }
      this.clicks.next({
        event: event,
        quantity: this.quantityEvent,
        seletor_medida_id:
          this.defaultSelection === 'weight'
            ? SeletorDePesoEnum.PESO
            : this.defaultSelection === 'unit'
            ? SeletorDePesoEnum.UNIDADE
            : null,
      });
    } else {
      if (!this.hasCombo) {
        this.showMaxReachedMessage();
      } else {
        this.showMaxReachedMessageCombo();
      }
    }
  }

  incrementKeyboard(event: KeyboardEvent): void {
    if (event.key !== 'Enter') return;
    if (!this.max || this.quantityEvent < this.max) {
      this.valorAtribuido !== null ? this.valorAtribuido : this.quantityEvent++;
      this.clicks.next({
        event: event,
        quantity: this.quantityEvent,
        seletor_medida_id:
          this.defaultSelection === 'weight'
            ? SeletorDePesoEnum.PESO
            : this.defaultSelection === 'unit'
            ? SeletorDePesoEnum.UNIDADE
            : null,
      });
    } else {
      if (!this.hasCombo) {
        this.showMaxReachedMessage();
      } else {
        this.showMaxReachedMessageCombo();
      }
    }
  }

  decrement(event: Event) {
    event.stopPropagation();
    if (this.quantity <= this.min) return;
    this.quantityEvent =
      this.quantityEvent < 0
        ? 0
        : this.deleteAll
        ? this.quantityEvent - this.quantityEvent
        : this.quantityEvent - 1;
    this.clicks.next({
      event: event,
      quantity: this.quantityEvent,
      seletor_medida_id:
        this.defaultSelection === 'weight'
          ? SeletorDePesoEnum.PESO
          : this.defaultSelection === 'unit'
          ? SeletorDePesoEnum.UNIDADE
          : null,
    });
    this.deleteAll = false;
  }

  handleDecrementClick(event: Event) {
    event.stopPropagation();
    if (this.compraEmProcessoService.temCompraEmProcesso) return;
    const newEvent = new Event('decrementClick');
    if (this.isLastItem() && this.confimDeletion) {
      this.confirmDeleteItemClick(newEvent);
    } else {
      this.decrement(newEvent);
    }
  }

  decrementKeyboard(event: KeyboardEvent) {
    if (event.key !== 'Enter') return;

    this.handleDecrementClick(event);
  }

  handleAddVolumeClick(event: Event): void {
    const quantidade = this.volumePrincipal?.quantidade || 0;
    if (this.quantityEvent + quantidade < this.max) {
      this.quantityEvent += quantidade;
      this.increment(event, true);
    } else {
      this.showMaxReachedMessage();
    }
  }

  confirmDeleteItemClick(event: Event): void {
    event.stopPropagation();

    const dialog: IDialog = {
      open: true,
      title: this.titleModalConfimDeletion,
      subTitle: this.subTitleModalConfimDeletion,
      buttonCancelText: 'Cancelar',
      buttonConfirmText: 'Remover item',
      disabled: false,
    };

    this.dialogService.openDialog(dialog);
    this.openedConfirmDeleteModal.emit(true);

    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          if (this.seletorPeso) {
            this.unidadeSelecionada();
          }
          this.handleItemDeletion(event, 0);
        }

        this.dialogService.clearDialog().finally(() => {
          this.unidadeSelecionada();
          this.openedConfirmDeleteModal.emit(false);
          if (this.isDeleting && !isConfirmed) {
            this.handleItemDeletion(event, this.quantity);
          }
          this.isDeleting = false;
          this.valorAtribuido = null;
        });
      });

    this.dialogService.closeClick.pipe(untilDestroyed(this)).subscribe(() => {
      this.openedConfirmDeleteModal.emit(false);
      if (this.isDeleting) {
        this.handleItemDeletion(event, this.quantity);
      }
      this.isDeleting = false;
      this.valorAtribuido = null;
    });
  }

  private handleItemDeletion(event: Event, newQuantity: number): void {
    if (newQuantity === 0) {
      this.decrement(event);
    }

    this.quantityEvent = newQuantity;
    setTimeout(() => {
      if (this.quantityInput) {
        this.quantityInput.nativeElement.value = this.quantityEvent.toString();
      }
      this.cdr.detectChanges();
    }, 0);
  }

  getBooleanValue(value: boolean | string): boolean {
    return value != null && `${value}` !== 'false';
  }

  showMaxReachedMessage() {
    this.messageService.openInfoMessage(
      `A quantidade máxima permitida do produto é de ${this.produto?.quantidade_maxima} unidades.`,
      1.5,
      'Quantidade máxima atingida!'
    );
  }

  showMaxReachedMessageCombo() {
    this.messageService.openInfoMessage(
      `O limite máximo disponível foi atingido`,
      1.5
    );
  }

  get iconDecrement(): string {
    if (this.isLastItem()) {
      return !this.disabled
        ? 'icon-delete_outline vip-color-error-main'
        : 'icon-delete_outline';
    }
    return 'icon-minimize';
  }

  isLastItem(): boolean {
    return this.quantityEvent <= this.min + 1;
  }

  changedItemDefault(defaultSelection: string) {
    const event = new Event(defaultSelection);
    if (this.quantityEvent !== 0) {
      this.changed.emit({
        event: event,
        quantity: this.quantityEvent,
        seletor_medida_id:
          defaultSelection === 'unidadeSelecionada'
            ? SeletorDePesoEnum.UNIDADE
            : SeletorDePesoEnum.PESO,
      });
    }
  }

  unidadeSelecionada() {
    this.userChangedSelection = true;
    this.naoPodeEditar = false;
    this.defaultSelection = 'unit';
    this.seletorPeso = false;
    this.changedItemDefault('unidadeSelecionada');
    this.cdr.markForCheck();
  }

  pesoSelecionado() {
    this.userChangedSelection = true;
    this.naoPodeEditar = true;
    this.defaultSelection = 'weight';
    this.seletorPeso = true;
    this.changedItemDefault('pesoSelecionado');
    this.cdr.markForCheck();
  }

  checkUnidadeMedida(): void {
    if (this.produto && !this.produto.combo) {
      if (
        this.produto.possui_unidade_diferente &&
        this.produto.habilitar_seletor_unidade_peso &&
        this.defaultSelection === 'weight'
      ) {
        this.naoPodeEditar = true;
        this.openInfoMessage();
      } else if (
        this.produto.possui_unidade_diferente &&
        !this.produto.habilitar_seletor_unidade_peso &&
        this.produto.unidade_sigla !== 'UN'
      ) {
        this.naoPodeEditar = true;
        this.openInfoMessage();
      }
    } else {
      this.naoPodeEditar = false;
    }

    this.cdr.markForCheck();
  }

  openInfoMessage() {
    this.messageService.openInfoMessage(
      'A edição livre da quantidade só é permitida para a compra em unidade. Para a compra no peso, utilize os botões de + e -',
      1.5,
      'Ação não permitida!'
    );
  }

  onBlur(event: FocusEvent): void {
    if (this.defaultSelection !== 'weight' && !this.naoPodeEditar) {
      this.verificaInput(event);
      this.valorAtribuido = null;
      return;
    } else {
      this.valorAtribuido = null;
      return;
    }
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.verificaInput(event);
      this.valorAtribuido = null;
      return;
    }
  }

  verificaInput(event: KeyboardEvent | FocusEvent): void {
    if (this.produto) {
      const maxQuantity = Number(this.produto.quantidade_maxima);
      const inputElement = event.target as HTMLInputElement | null;
      const oldValue = this.quantityEvent;

      if (!inputElement) {
        return;
      }

      this.valorAtribuido = Number(inputElement.value);

      if (this.valorAtribuido !== oldValue) {
        if (
          this.valorAtribuido < maxQuantity &&
          this.valorAtribuido !== 0 &&
          inputElement.value !== ''
        ) {
          this.quantityEvent = this.valorAtribuido;
          this.cdr.markForCheck();
          return this.increment(event);
        } else {
          this.quantityInput.nativeElement.value = maxQuantity.toString();
          this.showMaxReachedMessage();
          this.cdr.markForCheck();
        }

        if (inputElement.value !== '' && this.valorAtribuido === 0) {
          if (this.isDeleting) {
            return;
          }
          this.isDeleting = true;
          this.deleteAll = true;
          this.confirmDeleteItemClick(event);
          return this.cdr.markForCheck();
        } else {
          const newValue = this.valorAtribuido;
          if (isNaN(newValue) || newValue === 0 || newValue > maxQuantity) {
            this.quantityInput.nativeElement.value = oldValue.toString();
          } else {
            this.quantityInput.nativeElement.value = newValue.toString();
          }

          this.cdr.markForCheck();
        }

        this.isFocused = false;
        this.valorAtribuido = null;
      }
    }
  }

  validateNumberInput(event: KeyboardEvent): void {
    const charCode = event.key;
    if (!/^\d$/.test(charCode)) {
      event.preventDefault();
    }
  }

  filterInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }
}
