import { Injectable } from '@angular/core';

import { ITexto } from '@vip/core';
import { GetTextoResponse } from './texto-ws.service.types';
import { BaseWsService } from '../common/base-ws-service';

import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TextoWsService {
  constructor(private api: BaseWsService<TextoWsService>) {}

  getTextoPorSlug(slug: string): Observable<ITexto> {
    return this.api
      .get<GetTextoResponse>('getTextoPorSlug', `/loja/textos/slug/${slug}`)
      .pipe(pluck('texto'));
  }
}
