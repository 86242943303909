import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClienteFacade } from '@vip/state/cliente';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ProdutosIndisponiveisStore } from '../+state/produtos-indisponiveis.store';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ProdutosIndisponiveisService {
  readonly aviseMeEnviados$ = this.produtosIndisponiveis.aviseMeEnviados$;
  private readonly isLogged$ = this.clienteFacade.isLogged$;

  constructor(
    private clienteFacade: ClienteFacade,
    private produtosIndisponiveis: ProdutosIndisponiveisStore,
    private router: Router
  ) {}

  sendAviseMeProdutoIndisponivel(produtoId: number): void {
    this.isLogged$.pipe(untilDestroyed(this), take(1)).subscribe((isLogged) => {
      if (isLogged) {
        this.aviseMeRequest(produtoId);
      } else {
        this.redirectToLogin();
      }
    });
  }

  getAviseMeEnviadoById$(produtoId: number): Observable<boolean> {
    return this.aviseMeEnviados$.pipe(
      map((aviseMe) => aviseMe.get(produtoId) || false)
    );
  }

  private aviseMeRequest(produtoId: number): void {
    this.produtosIndisponiveis.sendAviseMeProdutoIndisponivel({ produtoId });
  }

  private redirectToLogin(): void {
    this.router.navigate(['/login']);
  }
}
