import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ICarrinhoItemInconsistencia, IProdutoCarrinho } from '@vip/core';

export const addIngredientesCarrinho = createAction(
  '[Ingredientes Receita/Page] Add Ingredientes Carrinho',
  props<{ listaId: number; ignoreSelecionarCDValidation?: boolean }>()
);

export const addIngredientesCarrinhoSuccess = createAction(
  '[Ingredientes Receita/API] Add Ingredientes Carrinho Success',
  props<{
    itensAtualizado: IProdutoCarrinho[];
    itensNaoAtualizados: ICarrinhoItemInconsistencia[];
  }>()
);

export const addIngredientesCarrinhoFailure = createAction(
  '[Ingredientes Receita/API] Add Ingredientes Carrinho Failure',
  props<{ error: HttpErrorResponse }>()
);

export const enviaImpressao = createAction(
  '[Ingredientes Receita/API] Envia evento de impressao',
  props<{ res: any }>()
);

export const resetIngredientes = createAction(
  '[Ingredientes Receita/Page] Reset ingredientes'
);
