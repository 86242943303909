import { IProdutoColecao } from '../interfaces';

export const colecaoIconeLabelMock: IProdutoColecao = {
  descricao: '',
  slug: '',
  id: 111,
  descricao_resumida: 'Vegano',
  icone: 'https://picsum.photos/18',
};

export const colecaoIconeMock: IProdutoColecao = {
  descricao: '',
  slug: '',
  id: 222,
  descricao_resumida: '',
  icone: 'https://picsum.photos/18',
};

export const colecaoLabelMock: IProdutoColecao = {
  descricao: '',
  slug: '',
  id: 333,
  descricao_resumida: 'Vegetariano',
  icone: '',
};
