import { Injectable } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { filter, map, mergeMap } from 'rxjs/operators';
import { LayoutState } from './layout-state.interface';
import { zip } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LayoutStore extends ComponentStore<LayoutState> {
  readonly isVisible$ = this.select((s) => s.bottomNavigationIsVisible);
  readonly headerDesktopIsVisible$ = this.select(
    (s) => s.headerDesktopIsVisible
  );
  readonly footerDesktopIsVisible$ = this.select(
    (s) => s.footerDesktopIsVisible
  );

  readonly footerResponsivoIsVisible$ = this.select(
    (s) => s.footerResponsivoIsVisible
  );

  readonly setActivateRouteNavBar = this.updater((state, value: string) => ({
    ...state,
    activateRouteNavBar: value,
  }));

  readonly setHeaderVisibility = this.updater((state, value: boolean) => ({
    ...state,
    headerDesktopIsVisible: value,
  }));

  constructor(private router: Router, private activateRoute: ActivatedRoute) {
    super({
      bottomNavigationIsVisible: false,
      activateRouteNavBar: '',
      headerDesktopIsVisible: true,
      footerDesktopIsVisible: true,
      footerResponsivoIsVisible: true,
    });
    this.checkRouterEvents();
  }

  checkRouterEvents() {
    const routeData = this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(() => this.activateRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    );

    zip(routeData, this.state$).subscribe(([dataParamsRoute, state]) => {
      this.changeState(dataParamsRoute, state);
    });
  }

  changeState(dataParamsRoute: Data, state: LayoutState) {
    this.setState({
      ...state,
      bottomNavigationIsVisible: dataParamsRoute.bottomNavigationIsVisible,
      headerDesktopIsVisible: dataParamsRoute.headerDesktopIsVisible,
      footerDesktopIsVisible: dataParamsRoute.footerDesktopIsVisible,
      footerResponsivoIsVisible: dataParamsRoute.footerResponsivoIsVisible,
    });
  }
}
