import { Pipe, PipeTransform } from '@angular/core';
import { IProduto } from '../../interfaces';

@Pipe({ name: 'quantidadeUnidadeDiferenteProduto' })
export class QuantidadeUnidadeDiferenteProdutoPipe implements PipeTransform {
  transform(produto: IProduto): number {
    return produto.possui_unidade_diferente
      ? produto.quantidade_unidade_diferente
      : 0;
  }
}
