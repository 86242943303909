import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { ICarousel, IPaginaInstitucional } from '@vip/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'vip-institucional',
  templateUrl: './institucional.component.html',
  styleUrls: ['./institucional.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InstitucionalComponent {
  constructor(public sanitizer: DomSanitizer) {}

  @Input() pagina!: IPaginaInstitucional;
  @Input() carousel: ICarousel | null = null;
  @Input() isMobile!: boolean;
}
