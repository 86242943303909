<section class="vip-entrega-retirada-main-wrapper">
  <h2>
    <i class="icon-package vip-color-primary-main"></i>
    <span class="vip-color-first-default font-bold"
      >Opções de entrega e retirada</span
    >
  </h2>

  <hr class="vip-divider" />
  <article>
    <vip-tab-group-desktop
      [initialIndex]="
        formaEntregaFromState === formaEntregaEnum.TIPO_RETIRADA ? 1 : 0
      "
      [separeteDividerFromContent]="false"
      [preventEmitTabChangeOnInit]="true"
      (tabChange)="onTabChange($event)"
    >
      <vip-tab icon="icon-local_shipping" label="Receber em casa">
        <vip-opcoes-entrega-retirada
          [enderecos]="enderecos"
          [cdsEntrega]="cdsEntrega"
          [enderecoSelecionado]="enderecoSelecionado"
          [cdSelecionado]="cdSelecionado"
          [tiposEntrega]="getTiposEntregaOrdenados(tiposEntrega)"
          [tipoEntregaSelecionado]="tipoEntregaSelecionado"
          [exibirTiposEntrega]="exibirTiposEntrega"
          [formaEntregaFromState]="formaEntregaFromState"
          [horarioSelecionado$]="horarioSelecionado$"
          (cdChange)="cdChange.emit($event)"
          (openHorariosModal)="openHorariosModal()"
          (enderecoEntregaChange)="enderecoEntregaChange.emit($event)"
          (tipoEntregaChanged)="tipoEntregaChanged.emit($event)"
          (editarEndereco)="editarEndereco.emit($event)"
          (horarioChange)="horarioChange.emit($event)"
          [isEditar]="false"
        ></vip-opcoes-entrega-retirada>
      </vip-tab>
      <vip-tab icon="icon-directions_walk" label="Retirar na loja">
        <vip-opcoes-entrega-retirada
          [cdsRetirada]="cdsRetirada"
          [tiposEntrega]="getTiposEntregaOrdenados(tiposEntrega)"
          [cdSelecionado]="cdSelecionado"
          [tipoEntregaSelecionado]="tipoEntregaSelecionado"
          [formaEntregaFromState]="formaEntregaFromState"
          [horarioSelecionado$]="horarioSelecionado$"
          [exibirTiposRetirada]="exibirTiposRetirada"
          (cdChange)="cdChange.emit($event)"
          (openHorariosModal)="openHorariosModal()"
          (enderecoEntregaChange)="enderecoEntregaChange.emit($event)"
          (tipoEntregaChanged)="tipoEntregaChanged.emit($event)"
          (horarioChange)="horarioChange.emit($event)"
        ></vip-opcoes-entrega-retirada>
      </vip-tab>
    </vip-tab-group-desktop>
  </article>
</section>
