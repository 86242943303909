import { Injectable } from '@angular/core';
import { ITheme, TagOfertas } from '@vip/core';
import { DEFAULT_TAG_OFERTAS, DEFAULT_THEME } from '../default-theme';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _theme = DEFAULT_THEME;
  get theme() {
    return this._theme;
  }

  camelToDash(str: string): string {
    return str
      .replace(/(^[A-Z])/, ([first]) => first.toLowerCase())
      .replace(/([A-Z])/g, ([letter]) => `-${letter.toLowerCase()}`);
  }

  setupTheme(themeConfig?: ITheme) {
    this._theme = themeConfig || DEFAULT_THEME;
    this.removePreviousVariables();
    this.setupVariables(themeConfig || DEFAULT_THEME, '--vip');
    this.setupTagOfertasVariables(DEFAULT_TAG_OFERTAS, '--vip');
  }

  setupTagOfertasVariables(tags: TagOfertas, prefix = '--vip') {
    Object.entries(tags)
      .filter(([key]) => key.includes('tag_'))
      .forEach(([key, value]) => {
        document.documentElement.style.setProperty(
          `${prefix}-${key.replace(/_/g, '-')}`,
          value
        );
      });
  }

  private removePreviousVariables() {
    const itensToRemove: string[] = [];

    const styles = document.documentElement.style;
    for (let i = 0; styles[i]; i++) {
      const it = styles[i];
      if (it.startsWith('--vip-')) {
        itensToRemove.push(it);
      }
    }

    itensToRemove.forEach((it) =>
      document.documentElement.style.removeProperty(it)
    );
  }

  private setupVariables(themeConfig: ITheme, prefix: string) {
    let main: string;
    Object.entries(themeConfig).forEach(([key, value]) => {
      if (typeof value == 'object') {
        this.setupVariables(value, prefix + '-' + key);
      } else {
        document.documentElement.style.setProperty(
          this.camelToDash(prefix + '-' + key),
          value
        );
        if (key == 'main') {
          main = value;
        } else {
          const colorname = `${key}Color` as 'darkenColor' | 'contrastColor';
          if (value == '' && main) {
            document.documentElement.style.setProperty(
              this.camelToDash(prefix + '-' + key),
              this[colorname](main)
            );
          }
        }
      }
    });
  }

  private shadeColor(
    color: string,
    adjustment: { R: number; G: number; B: number; A: number }
  ) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = (R * (100 + adjustment.R)) / 100;
    G = (G * (100 + adjustment.G)) / 100;
    B = (B * (100 + adjustment.B)) / 100;

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    let RR = R.toString(16);
    let GG = G.toString(16);
    let BB = B.toString(16);

    const arrR = RR.split('.');
    const arrG = GG.split('.');
    const arrBB = BB.split('.');

    RR = arrR[0].length == 1 ? '0' + RR : RR;
    GG = arrG[0].length == 1 ? '0' + GG : GG;
    BB = arrBB[0].length == 1 ? '0' + BB : BB;

    const a = ((adjustment.A * 255) | (1 << 8)).toString(16).slice(1);

    return '#' + RR.substr(0, 2) + GG.substr(0, 2) + BB.substr(0, 2) + a;
  }

  private darkenColor = (color: string) =>
    this.shadeColor(color, { R: -21.42, G: -19, B: -17.5, A: 1 });

  private contrastColor(color: string) {
    const R = parseInt(color.substring(1, 3), 16);
    const G = parseInt(color.substring(3, 5), 16);
    const B = parseInt(color.substring(5, 7), 16);

    const contrast = Math.round((R * 299 + G * 587 + B * 114) / 1000);
    return contrast > 228 ? '#000000' : '#FFFFFF';
  }
}
