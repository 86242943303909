import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubstituirProdutoContainerComponent } from './substituir-produto-container/substituir-produto-container.component';
import { SubstituirProdutoModule } from '@vip/views/substituir-produto';
import { SubstituirProdutoStateModule } from '@vip/state/substituir-produto';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    SubstituirProdutoModule,
    SubstituirProdutoStateModule,
    RouterModule.forChild([
      {
        path: '',
        component: SubstituirProdutoContainerComponent,
      },
    ]),
  ],
  declarations: [SubstituirProdutoContainerComponent],
})
export class SubstituirProdutoContainerModule {}
