import { Action, createReducer, on } from '@ngrx/store';

import * as ListaActions from './lista.actions';
import { IList } from '@vip/core';
import { IGenericState } from '@vip/state/utils';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const LISTA_FEATURE_KEY = 'lista';

export type ListaState = IGenericState<{
  listas: EntityState<IList>;
  listaSelecionadaId: number | null;
}>;

export const listasAdapter: EntityAdapter<IList> = createEntityAdapter<IList>({
  selectId: (lista) => lista.lista_id,
});

export const initialState: ListaState = {
  data: {
    listas: listasAdapter.getInitialState(),
    listaSelecionadaId: null,
  },
  error: null,
  status: 'pending',
};

const listaReducer = createReducer(
  initialState,
  on(
    ListaActions.loadListas,
    ListaActions.criarLista,
    ListaActions.getLista,
    ListaActions.editarLista,
    (state) => ({
      ...state,
      status: 'loading',
      error: null,
    })
  ),
  on(
    ListaActions.loadListaSuccess,
    (state, { minhasListas, compartilhadas }) => ({
      ...state,
      data: {
        ...state.data,
        listas: listasAdapter.setAll(
          [...minhasListas, ...compartilhadas],
          state.data.listas
        ),
      },
      status: 'success',
      error: null,
    })
  ),
  on(
    ListaActions.criarListaSuccess,
    ListaActions.editarListaSuccess,
    ListaActions.getListaSuccess,
    (state, { lista }) => ({
      ...state,
      data: {
        ...state.data,
        listas: listasAdapter.upsertOne(lista, state.data.listas),
        listaSelecionadaId: lista.lista_id,
      },
      status: 'success',
      error: null,
    })
  ),
  on(ListaActions.selecionarLista, (state, { listaId }) => ({
    ...state,
    data: {
      ...state.data,
      listaSelecionadaId: listaId,
    },
    status: 'success',
    error: null,
  })),
  on(ListaActions.limparListaSelecionada, (state) => ({
    ...state,
    data: {
      ...state.data,
      listaSelecionadaId: null,
    },
    status: 'success',
    error: null,
  })),
  on(ListaActions.excluirLista, (state, { listaId }) => ({
    ...state,
    data: {
      ...state.data,
      listas: listasAdapter.removeOne(listaId, state.data.listas),
      listaSelecionadaId: null,
    },
    status: 'success',
    error: null,
  })),
  on(
    ListaActions.loadListaFailure,
    ListaActions.criarListaFailure,
    ListaActions.getListaFailure,
    ListaActions.editarListaFailure,
    ListaActions.excluirListaFailure,
    (state, { error }) => ({
      ...state,
      status: 'error',
      error: error.error.error,
    })
  )
);

export function reducer(state: ListaState | undefined, action: Action) {
  return listaReducer(state, action);
}
