import { createAction, props } from '@ngrx/store';
import { IConfiguracoesOmnichannel, TipoDispositivoEnum } from '@vip/core';
import { HttpErrorResponse } from '@angular/common/http';

export const loadConfiguracaoOmnichannel = createAction(
  '[Omnichannel Page] Init',
  props<{ dispositivo: TipoDispositivoEnum }>()
);

export const loadConfiguracaoOmnichannelSuccess = createAction(
  '[Omnichannel/API] Load Configuracao Omnichannel Success',
  props<{ configuracaoOmnichannel: IConfiguracoesOmnichannel[] }>()
);

export const loadConfiguracaoOmnichannelFailure = createAction(
  '[Omnichannel/API] Load Configuracao Omnichannel Failure',
  props<{ error: HttpErrorResponse }>()
);
