import { ClienteFacade } from './../+state/cliente.facade';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClienteApiService } from '@vip/api';
import { FilialFacade } from '@vip/state/filial';
import { ParametrosFacade } from '@vip/state/parametros';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
@UntilDestroy()
@Injectable()
export class ValidaPinClienteGuard implements CanActivate {
  constructor(
    private router: Router,
    private clienteFacade: ClienteFacade,
    private clienteApiService: ClienteApiService,
    private filialFacade: FilialFacade,
    private parametrosFacade: ParametrosFacade
  ) {}
  filial$ = this.filialFacade.filial$;

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.filial$,
      this.clienteFacade.cliente$,
      this.parametrosFacade.parametros$,
    ]).pipe(
      switchMap(([filial, cliente, parametros]) => {
        if (parametros.pin_ativo === true) {
          return this.clienteApiService.consultaSolicitacaoSMS(filial.id).pipe(
            map((consultaSms) => {
              if (!consultaSms.telefone_celular_validado) {
                this.router.navigateByUrl('/validar-telefone', {
                  state: { editarNumero: true },
                });
                return false;
              }
              return true;
            })
          );
        } else {
          return of(true);
        }
      }),
      untilDestroyed(this)
    );
  }
}
