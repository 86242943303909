import { Injectable } from '@angular/core';
import { Share } from '@capacitor/share';
import { IShare } from './share.interface';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  async shareLink(dataShare: IShare) {
    if (this.checkIfIsApp()) {
      await Share.share({
        title: dataShare.title,
        text: dataShare.text,
        url: dataShare.url,
        dialogTitle: dataShare.dialogTitle,
      });
    }
  }

  checkIfIsApp(): boolean {
    return environment.isApp;
  }

  shareLinkOnDesktop(url: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.opacity = '0';
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
