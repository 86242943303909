<div class="vip-empty text-center lg:w-full">
  <span
    *ngIf="image"
    [style.-webkit-mask]="'url(' + image + ')'"
    class="md:mb-3"
  ></span>
  <h1 *ngIf="title" class="text-lg font-bold">{{ title }}</h1>
  <h2 *ngIf="text" class="text-sm md:text-base">{{ text }}</h2>
  <h3 *ngIf="subtitle" class="text-sm mt-1">{{ subtitle }}</h3>
</div>
