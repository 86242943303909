import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive()
export abstract class TabItemBaseDirective {
  @Output()
  tabClick = new EventEmitter();
  @Input()
  color: 'primary' | 'secondary' = 'primary';
  @Input()
  iconColor?: 'primary' | 'secondary';
  @Input()
  rounded = false;
  @Input()
  selected = false;
  @Input()
  label = '';
  @Input()
  icon = '';
  @Input()
  count = '';
  @Input()
  disabled = false;
  @Input()
  disabledText = '';
  @Input()
  noBorder = false;

  get rippleColor() {
    return this.selected && this.rounded
      ? 'color-primary-contrast'
      : 'color-primary-main';
  }

  onTabClick(): void {
    if (!this.disabled) this.tabClick.emit();
  }
}
