import { Injectable } from '@angular/core';
import { BaseApiService } from '../common/base-api-service';
import { AntifraudeEnum, environment } from '@vip/core';
import {
  GetInitialJWTRequest,
  GetInitialJWTResponse,
} from './loja-api.service.types';
import { BaseApiResponse } from '../common/base-api-response';

@Injectable({
  providedIn: 'root',
})
export class LojaApiService {
  constructor(private api: BaseApiService<LojaApiService>) {}

  public getInitialJWT(domain: string) {
    return this.api.post<GetInitialJWTRequest, GetInitialJWTResponse>(
      'getInitialJWT',
      '/auth/loja/login',
      {
        domain: domain,
        username: environment.lojaUser,
        key: environment.lojaAuthJWT,
      }
    );
  }

  getAntifraudeFlag(antifraude: AntifraudeEnum) {
    return this.api.get<BaseApiResponse<boolean>>(
      'getAntifraudeFlag',
      `/loja/antifraudes/${antifraude}/ativo`
    );
  }

  getAppId(antifraude: AntifraudeEnum) {
    return this.api.get<BaseApiResponse<string>>(
      'getAppId',
      `/loja/antifraudes/${antifraude}/appid`
    );
  }
}
