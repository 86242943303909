import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'vip-card-produto-observacao',
  templateUrl: './card-produto-observacao.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardProdutoObservacaoComponent {
  abrirOpcoes = false;
  abrirInput = false;

  @Input() observacao?: string;
  @Input() disabled = false;
  @Input()
  isDesktop = false;
  @Input() showDivider = true;

  @Output() changeObservacao = new EventEmitter<string>();

  handleButtonClick() {
    if (!this.disabled) this.abrirInput = true;
  }
}
