import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinComponent } from './spin/spin.component';
import { ButtonModule } from '@vip/ui/button';
import { MessageModule } from '@vip/ui/message';
import { CoreModule } from '@vip/core';
import { SeletorDePesoModule } from '@vip/ui/seletor-de-peso';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    MessageModule,
    CoreModule,
    SeletorDePesoModule,
  ],
  declarations: [SpinComponent],
  exports: [SpinComponent],
})
export class SpinModule {}
