import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AnalyticsStateModule } from '@vip/state/analytics';
import { CartaoStateModule } from '@vip/state/cartao';
import { CashbackStateModule } from '@vip/state/cashback';
import { CompraStateModule } from '@vip/state/compra';
import { CompraEmProcessoStateModule } from '@vip/state/compra-em-processo';
import { PerguntasStateModule } from '@vip/state/perguntas';
import { PagamentoModule } from '@vip/views/pagamento';
import { PagamentoDesktopModule } from '@vip/views/pagamento-desktop';
import { CarteiraDigitalGuard } from './carteira-digital.guard';
import { FormaPagamentoContainerComponent } from './forma-pagamento-container/forma-pagamento-container.component';
import { ConfirmacaoSaidaPagamentoGuard } from './guards/confirmacao-saida-pagamento.guard';
import { PagamentoCartaoContainerComponent } from './pagamento-cartao-container/pagamento-cartao-container.component';
import { PagamentoCarteiraDigitalContainerComponent } from './pagamento-carteira-digital-container/pagamento-carteira-digital-container.component';
import { PagamentoContainerComponent } from './pagamento-container/pagamento-container.component';
import { PagamentoCuponsContainerComponent } from './pagamento-cupons-container/pagamento-cupons-container.component';
import { PagamentoService } from './services/pagamentos.service';
import { ModalPerguntasModule } from '@vip/ui/modal-perguntas';
import { CoreModule } from '@vip/core';
import { StepperDesktopModule } from '@vip/ui/stepper-desktop';
import { CardDetalhesCobrancaModule } from '@vip/ui/card-detalhes-cobranca';
import { CardBasicoModule } from '@vip/ui/card-basico';
import { ImageModule } from '@vip/ui/image';
import { ValidaHorarioSelecionadoGuard } from '@vip/state/tipo-entrega';
import { LoadingModule } from '@vip/views/loading';
import { LoadingStateModule } from '@vip/state/loading';
import { ContainerModalErrorModule } from '@vip/container/modal-error';

@NgModule({
  imports: [
    CommonModule,
    PagamentoModule,
    PagamentoDesktopModule,
    CompraEmProcessoStateModule,
    RouterModule.forChild([
      {
        path: '',
        canDeactivate: [ConfirmacaoSaidaPagamentoGuard],
        canActivate: [ValidaHorarioSelecionadoGuard],
        component: PagamentoContainerComponent,
      },
      {
        path: 'forma-pagamento',
        component: FormaPagamentoContainerComponent,
      },
      {
        path: 'cupons',
        component: PagamentoCuponsContainerComponent,
      },
      {
        path: 'cartao/:tipo',
        component: PagamentoCartaoContainerComponent,
      },
      {
        path: 'carteira-digital',
        canDeactivate: [CarteiraDigitalGuard],
        component: PagamentoCarteiraDigitalContainerComponent,
      },
    ]),
    CashbackStateModule,
    CompraStateModule,
    CartaoStateModule,
    AnalyticsStateModule,
    PerguntasStateModule,
    ModalPerguntasModule,
    CoreModule,
    StepperDesktopModule,
    CardDetalhesCobrancaModule,
    CardBasicoModule,
    ImageModule,
    LoadingModule,
    LoadingStateModule,
    ContainerModalErrorModule,
  ],
  declarations: [
    PagamentoContainerComponent,
    FormaPagamentoContainerComponent,
    PagamentoCuponsContainerComponent,
    PagamentoCartaoContainerComponent,
    PagamentoCarteiraDigitalContainerComponent,
  ],
  providers: [PagamentoService, CarteiraDigitalGuard],
})
export class PagamentoContainerModule {}
