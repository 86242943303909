import { Pipe, PipeTransform } from '@angular/core';
import { TipoOfertaEnum } from '../../enums';
import { IProduto } from '../../interfaces';

@Pipe({ name: 'quantidadeMaximaProduto' })
export class QuantidadeMaximaProdutoPipe implements PipeTransform {
  transform(produto: IProduto): number {
    const { quantidade_maxima, oferta } = produto;
    if (oferta && oferta.tipo_oferta_id !== TipoOfertaEnum.COMBO_DE_PRODUTOS)
      return oferta.quantidade_maxima < quantidade_maxima
        ? oferta.quantidade_maxima
        : quantidade_maxima;
    return quantidade_maxima;
  }
}
