import { Injectable } from '@angular/core';
import { IAplicativo, PlataformaEnum } from '@vip/core';
import {
  AppUpdate,
  AppUpdateAvailability,
} from '@capawesome/capacitor-app-update';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { AppLauncher } from '@capacitor/app-launcher';
import { DialogService, IDialog } from '@vip/ui/modal';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AppUpdateService {
  appUpdate = AppUpdate;
  app = App;
  capacitor = Capacitor;
  appLauncher = AppLauncher;
  readonly APPLE_DEEPLINK = 'itms-apps://itunes.apple.com';
  readonly APPLE_STORE_PATH = 'app/id/';
  private openDialogSubject = new BehaviorSubject<boolean>(false);
  private dialogIsOpen = false;
  openDialog$ = this.openDialogSubject.asObservable();

  constructor(private dialogService: DialogService) {}

  async possuiVersaoObrigatoriaDisponivel(versaoMinima: number) {
    const updateInfo = await this.appUpdate.getAppUpdateInfo({ country: 'BR' });
    const appInfo = await this.app.getInfo();
    if (
      updateInfo.updateAvailability == AppUpdateAvailability.UPDATE_AVAILABLE
    ) {
      if (parseInt(appInfo.build) < versaoMinima) {
        return true;
      }
    }
    return false;
  }

  getVersaoMinimaPlataforma(aplicativo: IAplicativo) {
    const plataforma = this.capacitor.getPlatform();
    if (plataforma === PlataformaEnum.ANDROID) {
      return aplicativo.versao_min_android;
    } else if (plataforma === PlataformaEnum.IOS) {
      return aplicativo.versao_min_ios;
    }
    return null;
  }

  showUpdateDialog(apple_id?: string): void {
    if (this.dialogIsOpen) 
      return;
    const dataDialog: IDialog = {
      open: true,
      title: 'Nova versão do App',
      subTitle: `Está disponível uma atualização para a versão mais recente do aplicativo. Essa versão irá lhe proporcionar uma melhor experiência em suas compras!`,
      disabled: true,
      showCloseButton: false,
      buttonConfirmText: 'Atualizar aplicativo',
    };
    this.dialogIsOpen = true;

    this.dialogService.openDialog(dataDialog);

    this.dialogService.dialogClick.subscribe(() => {
      this.abrirLoja(apple_id);
    });
  }

  openDialog(): void {
    this.openDialogSubject.next(true);
  }

  private async abrirLoja(apple_id?: string) {
    /**
     * appUpdate.openAppStore() deveria resolver a abertura de loja nas duas plataformas
     * Workaround para este problema
     * https://stackoverflow.com/questions/78495735/capawesome-capacitor-app-update-responses-required-app-information-could-not-b
     * https://forums.developer.apple.com/forums/thread/748819 - problema de CORS nos endpoints da apple sendo chamados por localhost
     */
    if (this.capacitor.getPlatform() === PlataformaEnum.IOS) {
      const { value: canOpen } = await this.appLauncher.canOpenUrl({
        url: `${this.APPLE_DEEPLINK}`,
      });
      if (canOpen && apple_id) {
        this.appLauncher.openUrl({
          url: `${this.APPLE_DEEPLINK}/${this.APPLE_STORE_PATH}/${apple_id}`,
        });
      }
    } else {
      this.appUpdate.openAppStore();
    }
  }
}
