<div
  [ngClass]="{ menorPrioridade: fullscreen }"
  class="vip-modal vip-common-backdrop"
  [class.show]="open"
  [class.bottom]="bottom"
  [class.fullscreen]="fullscreen"
  [class.is-desktop]="!isMobile && !isTablet"
  [class.large]="large"
  [class.ultra_large]="ultraLarge"
>
  <div
    class="vip-modal-content py-4 rounded"
    [ngClass]="{
      'py-0': paddingZoom,
      'py-4': !paddingZoom,
      'px-3': isMobile,
      'px-4': !isMobile,
      shadow: isMobile,
      isTabletModalControl: isTablet,
      'p-0 py-0 px-0': hasPrazoEntrega
    }"
    [@modalContent]="getAnimation()"
  >
    <ng-content></ng-content>
    <ng-template #modalContainer></ng-template>
  </div>
  <div
    class="vip-modal-button"
    [class.is-desktop]="!isMobile"
    [@modalButton]="getAnimation()"
    (@modalButton.done)="onAnimationDone()"
  >
    <vip-button-icon-rounded
      [disabled]="disabled"
      *ngIf="showCloseButton"
      type="outline"
      size="medium"
      color="primary"
      (btnClick)="handleClick()"
      data-cy="close-modal-button"
    >
      <i class="icon-close"></i>
    </vip-button-icon-rounded>
  </div>
</div>
