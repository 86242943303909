import { ViewModalErrorModule } from '@vip/views/modal-error';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerModalErrorComponent } from './container-modal-error/container-modal-error.component';

@NgModule({
  imports: [CommonModule, ViewModalErrorModule],
  declarations: [ContainerModalErrorComponent],
  exports: [ContainerModalErrorComponent],
})
export class ContainerModalErrorModule {}
