import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsEffects } from './+state/analytics.effects';
import { EffectsModule } from '@ngrx/effects';
import { AnalyticsFacade } from './+state/analytics.facade';
import { CarrinhoStateModule } from '@vip/state/carrinho';

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature([AnalyticsEffects])],
  providers: [AnalyticsFacade, CarrinhoStateModule],
})
export class AnalyticsStateModule {}
