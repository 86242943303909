import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ClienteApiService } from '@vip/api';
import { LayoutUtilsService } from '@vip/core';
import { ClienteTokenService } from '@vip/state/cliente';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class CompletarCadastroGuard implements CanActivate {
  constructor(
    private clienteApiService: ClienteApiService,
    private router: Router,
    private clienteTokenService: ClienteTokenService,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  canActivate(): Observable<boolean> {
    if (
      this.clienteTokenService.getToken() == '' ||
      !this.layoutUtilsService.isDesktop()
    ) {
      return of(true);
    }

    return this.clienteApiService.cadastroIncompleto().pipe(
      tap(({ data }) => {
        if (data) this.router.navigateByUrl('/');
      }),
      map((response) => {
        return !response.data;
      })
    );
  }
}
