<ng-container *ngIf="!isLoading; else skeletonTemplate">
  <vip-card-basico
    [ngClass]="{
      'block rounded': isDesktop,
      shadow: isDesktop && !isCompraLayout
    }"
    [accordion]="accordion"
    [disableRipple]="true"
    [expanded]="expanded"
    [icon]="showIcon ? 'icon-receipt' : ''"
    [titleSize]="isDesktop ? 'lg' : 'base'"
    [title]="title || 'Detalhes da Cobrança'"
    [padding]="isDesktop ? 'desktop' : 'mobile'"
    [useSubPadding]="isDesktop"
    [showHeaderDivider]="isDesktop"
    [cardBasicBorder]="cardBasicBorder"
  >
    <vip-card-cashback-desktop
      *ngIf="isDesktop && showCashback"
      [editable]="editable"
      [saldoCashback]="saldoCashback ?? 0"
      [totalCompra]="totalCompra"
      [valorMinimoCashback]="valorMinimoCashback"
      [descontos]="descontosSimples?.valor"
      [cashbackAplicado]="cashback?.valor"
      [isDesktop]="isDesktop"
      (alterarValorCashbackClick)="handleAlterarCashback($event)"
    >
    </vip-card-cashback-desktop>

    <ng-container
      *ngIf="produtos && produtos.length && isDesktop && !showCashback"
    >
      <div class="overflow-y-auto -mr-4 pr-4 max-h-[20rem]">
        <vip-card-resumo-produtos-desktop
          [produtos]="produtos"
        ></vip-card-resumo-produtos-desktop>
      </div>
      <hr class="-mx-4 mb-4" />
    </ng-container>

    <hr *ngIf="isDesktop && (!produtos || showCashback)" class="pb-4" />

    <div
      class="flex-container text-xs vip-color-first-default"
      [ngClass]="{ 'flex-container--desktop': isDesktop }"
      expandedContent
    >
      <div>
        <span>Compra</span>
        <span>{{ valorCompra | currency: 'BRL' }}</span>
      </div>
      <div *ngIf="_taxaServico?.valor_taxa !== null">
        <span [ngClass]="{ disclaimer: isDesktop }"
          >Taxa de Serviço
          <button
            *ngIf="
              _taxaServico?.mensagem !== null && _taxaServico?.mensagem !== ''
            "
            (click)="clickDisclaimer()"
            data-cy="btn-disclaimer"
            class="btn-disclaimer"
          >
            ?
          </button>
        </span>

        <span data-cy="servico">
          {{ valorTaxaServico }}
        </span>
      </div>
      <div *ngIf="disclaimer" data-cy="disclaimer">
        <span>
          {{ _taxaServico?.mensagem ?? '' }}
        </span>
      </div>
      <div>
        <span>Taxa de Frete</span>
        <div>
          <span
            *ngIf="taxaEntregaAnterior && taxaEntregaAnterior !== taxaEntrega"
            class="line-through mr-1"
            data-cy="frete-anterior"
          >
            {{ taxaEntregaAnterior | valorFrete }}
          </span>
          <span
            [class.vip-color-success-main]="
              taxaEntrega === 0 ||
              (taxaEntregaAnterior && taxaEntregaAnterior !== taxaEntrega)
            "
            data-cy="frete"
          >
            {{ taxaEntrega | valorFrete }}
          </span>
        </div>
      </div>
      <div *ngIf="valorAcrescimos">
        <span>Acréscimos</span>
        <span data-cy="acrescimos">
          {{ valorAcrescimos | currency: 'BRL' }}
        </span>
      </div>
      <div *ngIf="valorAlteracoes !== 0" data-cy="valor-alteracoes">
        <span>Alterações</span>
        <span [class.vip-color-success-main]="valorAlteracoes < 0">
          <ng-container *ngIf="valorAlteracoes < 0"> - </ng-container>
          {{ math.abs(valorAlteracoes) | currency: 'BRL' }}</span
        >
      </div>
      <div>
        <span>Subtotal</span>
        <span>{{ getSubTotal | currency: 'BRL' }}</span>
      </div>
      <div *ngIf="descontosSimples" data-cy="descontos-simples">
        <span>{{ descontoNome(descontosSimples) }}</span>
        <span class="vip-color-success-main">
          - {{ descontosSimples.valor | currency: 'BRL' }}</span
        >
      </div>

      <div *ngIf="cashback" data-cy="descontos-cashback">
        <span>
          {{ descontoNome(cashback) }}
        </span>
        <span class="vip-color-success-main">
          - {{ cashback.valor | currency: 'BRL' }}</span
        >
      </div>

      <div *ngIf="fidelidade" data-cy="descontos-fidelidade">
        <span class="flex items-center">
          <i
            aria-hidden="true"
            class="icon-discount_price vip-color-success-main text-sm mr-1 font-bold"
          ></i>
          {{ descontoNome(fidelidade) }}
        </span>
        <span class="vip-color-success-main">
          - {{ fidelidade.valor | currency: 'BRL' }}</span
        >
      </div>

      <hr class="vip-divider my-2" [ngClass]="{ 'my-4': isDesktop }" />
    </div>
    <div alwaysShowContent class="font-bold text-sm">
      <div
        class="flex justify-between"
        [ngClass]="{
          total: isDesktop,
          'total--no-margin': produtos?.length || hideConfirmButton
        }"
        data-cy="total"
      >
        <span class="font-bold">Total</span>
        <span>{{ total | currency: 'BRL' }}</span>
      </div>

      <div
        *ngIf="showMessageValorMinimo"
        class="message-error justify-center items-center text-xs text-center font-normal p-2 mb-2"
      >
        <span>{{ messageValorMinimo }}</span>
      </div>
      <vip-button
        *ngIf="isDesktop && !produtos?.length && !hideConfirmButton"
        [disabled]="disablePagamentoButton"
        data-cy="btn-ir-para-pagamento"
        (btnClick)="irParaPagamento.emit()"
      >
        {{ confirmButtonText || 'Concluir compra' }}
      </vip-button>
    </div>
  </vip-card-basico>
  <div class="mt-4 px-4" *ngIf="isDesktop && showContinuarComprando">
    <vip-button
      data-cy="btn-continuar-comprando"
      type="outline"
      (btnClick)="continuarComprando.emit()"
    >
      Continuar comprando
    </vip-button>
  </div>

  <div
    class="mt-2 px-4"
    *ngIf="isDesktop && produtos?.length && !isCompraLayout"
  >
    <vip-button
      data-cy="btn-continuar-comprando"
      type="outline"
      (btnClick)="novaCompra.emit()"
    >
      Fazer uma nova compra
    </vip-button>
  </div>

  <div class="flex flex-col pt-3" *ngIf="isDesktop && isCompraLayout">
    <vip-button
      class="mb-3"
      data-cy="btn-continuar-comprando"
      (btnClick)="repetirCompra.emit()"
      *ngIf="comprar_novamente"
    >
      <i class="icon-shopping_cart"></i>
      <span>Repetir compra</span>
    </vip-button>
    <vip-button
      data-cy="btn-rastrear-compra"
      type="outline"
      [disabled]="disableRastrearCompra"
      (btnClick)="rastrearCompra.emit($event)"
    >
      <i class="icon-location_on"></i>
      <span>Rastrear compra</span>
    </vip-button>
  </div>
</ng-container>
<ng-template #skeletonTemplate>
  <vip-card-detalhes-cobranca-skeleton></vip-card-detalhes-cobranca-skeleton>
</ng-template>
