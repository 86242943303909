<div class="flex flex-col flex-grow">
  <div class="mt-4">
    <p class="text-base mt-1 ml-2 mb-2 vip-color-secondary-default">
      Escolha uma forma de pagamento:
    </p>
  </div>
  <div class="flex flex-wrap">
    <div class="vip-cartao-credito-desktop__novo-cartao">
      <vip-selectable-card
        class="items-center"
        data-cy="card-forma-pagamento"
        [showIcon]="true"
        [fullWidth]="true"
        [expanded]="true"
        (click)="cadastrarNovoCartao.emit()"
      >
        <div title class="flex flex-col">
          <div class="flex items-center">
            <i class="icon-add icon-color"></i>
          </div>
          <div class="mt-10">
            <span class="text-xs vip-color-first-default text">
              Pagar com
              <p>novo cartão</p>
            </span>
          </div>
        </div>
      </vip-selectable-card>
    </div>

    <ng-container *ngFor="let cartaoCofre of cartoes; let isLast = last">
      <vip-selectable-card
        class="items-center"
        data-cy="card-forma-pagamento"
        [showIcon]="true"
        [fullWidth]="true"
        [fullHeight]="true"
        [expanded]="true"
        [checked]="cartaoCofre.id === cartaoSelecionadoId"
        (changed)="aoAlterarCartaoSelecionado(cartaoCofre)"
      >
        <div title>
          <div class="flex items-center justify-between">
            <img
              src="{{ cartaoCofre.logomarca }}"
              class="vip-card-forma-pagamento-image-size icon"
            />

            <vip-button-icon-rounded
              size="small"
              type="normal"
              class="mx-0"
              data-cy="btn-remove-card"
              (btnClick)="
                handleDeleteCartaoClick(
                  cartaoCofre.numero_cartao.slice(-4),
                  $event,
                  cartaoCofre.id
                )
              "
            >
              <i
                class="icon-delete_outline cursor-pointer vip-color-error-main icon-delete"
              ></i>
            </vip-button-icon-rounded>
          </div>
        </div>
        <div body>
          <div class="mt-4">
            <span class="text-xs vip-color-secondary-default block">
              {{ cartaoCofre.bandeira }}
            </span>
            <span class="text-xs vip-color-first-default text">
              **** {{ cartaoCofre.numero_cartao.slice(-4) }}
            </span>
          </div>
        </div>
      </vip-selectable-card>
    </ng-container>
  </div>
</div>
