<div class="grid gap-4 grid-cols-1 text-center" [formGroup]="form">
  <span class="vip-validar-cep-title text-3xl"
    >Ainda não atendemos no seu CEP</span
  >
  <span class="vip-validar-cep-sub-title text"
    >Nos informe seu e-mail para que possamos entrar em contato assim que a
    entrega estiver disponível na sua região</span
  >
  <vip-field
    [error]="validated && form.controls.email.invalid"
    [message]="'E-mail inválido'"
    [validations]="form.controls.email.errors"
    label="Digite seu e-mail*"
    class="text-left"
  >
    <input
      id="email"
      type="email"
      formControlName="email"
      autocomplete="off"
      data-cy="cep-nao-atendido-email"
    />
  </vip-field>

  <vip-button
    (btnClick)="onConfirmCepNaoAtendido()"
    [disabled]="loading"
    data-cy="cep-nao-atendido-enviar"
  >
    <span> Confirmar </span>
  </vip-button>
</div>
