import { Injectable } from '@angular/core';
import { BaseApiResponse } from '../common/base-api-response';
import { BaseApiService } from '../common/base-api-service';
import { pluck } from 'rxjs/operators';
import { IStoreConfig } from '@vip/core';

@Injectable({
  providedIn: 'root',
})
export class FilialAplicativoApiService {
  constructor(private api: BaseApiService<FilialAplicativoApiService>) {}

  getStoreConfig(filialId: number) {
    return this.api
      .get<BaseApiResponse<IStoreConfig>>(
        'getStoreConfig',
        `/build/app/configuracao/${filialId}`
      )
      .pipe(pluck('data'));
  }
}
