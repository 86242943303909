import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { IProduto } from '@vip/core';
import { IGenericState } from '@vip/state/utils';
import * as SubstituirProdutoActions from './substituir-produto.actions';

export const SUBSTITUIR_PRODUTO_FEATURE_KEY = 'substituir-produto';

export type SubstituirProdutoState = IGenericState<{
  produtosIndisponiveis: EntityState<IProduto>;
  produtosSubstitutos: EntityState<
    IProduto & { idProdutoIndisponivel: number }
  >;
  produtoIndisponivelId: number | null;
}>;

export const produtosIndisponiveisAdapter: EntityAdapter<IProduto> =
  createEntityAdapter<IProduto>({
    selectId: (produto) => produto.produto_id,
  });

export const produtosSubstitutosAdapter: EntityAdapter<
  IProduto & { idProdutoIndisponivel: number }
> = createEntityAdapter<IProduto & { idProdutoIndisponivel: number }>({
  selectId: (produto) => produto.idProdutoIndisponivel,
});

export const initialState: SubstituirProdutoState = {
  data: {
    produtosIndisponiveis: produtosIndisponiveisAdapter.getInitialState(),
    produtosSubstitutos: produtosSubstitutosAdapter.getInitialState(),
    produtoIndisponivelId: null,
  },
  error: null,
  status: 'pending',
};

const substituirProdutoReducer = createReducer(
  initialState,
  on(
    SubstituirProdutoActions.inserirProdutosIndisponiveis,
    (state, { produtos }) => ({
      ...state,
      data: {
        produtosIndisponiveis: produtosIndisponiveisAdapter.setAll(
          produtos,
          state.data.produtosIndisponiveis
        ),
        produtosSubstitutos: state.data.produtosSubstitutos,
        produtoIndisponivelId: state.data.produtoIndisponivelId,
      },
      status: 'success',
      error: null,
    })
  ),
  on(
    SubstituirProdutoActions.atualizarSubstituto,
    (state, { idProdutoIndisponivel, produto }) => ({
      ...state,
      data: {
        produtosIndisponiveis: state.data.produtosIndisponiveis,
        produtosSubstitutos: produtosSubstitutosAdapter.upsertOne(
          {
            ...produto,
            idProdutoIndisponivel,
          },
          state.data.produtosSubstitutos
        ),
        produtoIndisponivelId: state.data.produtoIndisponivelId,
      },
      status: 'success',
      error: null,
    })
  ),
  on(
    SubstituirProdutoActions.removerSubstituto,
    (state, { idProdutoIndisponivel }) => ({
      ...state,
      data: {
        produtosIndisponiveis: state.data.produtosIndisponiveis,
        produtosSubstitutos: produtosSubstitutosAdapter.removeOne(
          idProdutoIndisponivel,
          state.data.produtosSubstitutos
        ),
        produtoIndisponivelId: state.data.produtoIndisponivelId,
      },
      status: 'success',
      error: null,
    })
  ),
  on(
    SubstituirProdutoActions.setIdProdutoIndisponivel,
    (state, { idProdutoIndisponivel }) => ({
      ...state,
      data: {
        produtosIndisponiveis: state.data.produtosIndisponiveis,
        produtosSubstitutos: state.data.produtosSubstitutos,
        produtoIndisponivelId: idProdutoIndisponivel,
      },
      status: 'success',
      error: null,
    })
  ),
  on(SubstituirProdutoActions.resetProdutos, (state) => ({
    ...initialState,
  }))
);

export function reducer(
  state: SubstituirProdutoState | undefined,
  action: Action
) {
  return substituirProdutoReducer(state, action);
}
