import { createSelector } from '@ngrx/store';
import {
  LISTA_ITENS_FEATURE_KEY,
  listaItensAdapter,
  ListaItensState,
} from './lista-itens.reducer';
import { SelectorUtil } from '@vip/state/utils';
import { IProduto } from '@vip/core';

const { selectAll } = listaItensAdapter.getSelectors();

class ListaItensSelectors extends SelectorUtil<ListaItensState> {
  constructor() {
    super(LISTA_ITENS_FEATURE_KEY);
  }

  getItensMinhaListaArray = () =>
    createSelector(this.createFeatureSelector, (state: ListaItensState) => {
      return selectAll(state.data);
    });

  getItensMinhaListaTipoCarrinho = () =>
    createSelector(this.getItensMinhaListaArray(), (data) =>
      this.mapItensToCarrinho(data)
    );

  getPagina = () =>
    createSelector(
      this.createFeatureSelector,
      (state: ListaItensState) => state.paginator
    );

  private mapItensToCarrinho(itens: IProduto[]) {
    const produtos = new Map<number, { item_id: number; quantidade: number }>();
    itens?.forEach((item: IProduto) => {
      produtos.set(item.produto_id, {
        item_id: item.produto_id,
        quantidade: item.quantidade || 0,
      });
    });
    return produtos;
  }
}

export const listaItensSelectors = new ListaItensSelectors();
