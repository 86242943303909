import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AtualizarCarrinhoItemRequest } from '@vip/api';

import { DialogService } from '@vip/ui/modal';
import { CarrinhoFacade } from '../+state/carrinho.facade';
import { take } from 'rxjs/operators';

@Injectable()
export class ManterCarrinhoService {
  produtoPendenteCarrinho: AtualizarCarrinhoItemRequest | null = null;
  isDialogOpen = false;

  constructor(
    private carrinhoFacade: CarrinhoFacade,
    private dialogService: DialogService,
    private router: Router
  ) {}

  openDialogManterCarrinho(produtos18MaisRemovidos: boolean) {
    this.dialogService.openDialog(
      {
        open: true,
        title: 'Atenção',
        subTitle:
          'Identificamos que já havia um carrinho com alguns produtos. ' +
          'Deseja ver os itens do carrinho antigo ou excluí-lo?',
        disabled: true,
        buttonCancelText: 'Excluir',
        buttonConfirmText: 'Ver itens do carrinho antigo',
      },
      false
    );
    this.dialogService.dialogClick.subscribe((verItens) => {
      verItens
        ? this.router.navigateByUrl('carrinho-antigo')
        : this.carrinhoFacade.manterCarrinhoAnonimo();

      this.dialogService.clearDialog().then(() => {
        if (produtos18MaisRemovidos) {
          this.openDialogProdutos18MaisRemovidos();
        }
      });
    });
  }

  openDialogProdutos18MaisRemovidos() {
    this.dialogService.openDialog(
      {
        open: true,
        title: 'Atenção',
        subTitle:
          'Devido sua idade as bebidas alcoólicas foram removidas do carrinho.',
        disabled: false,
        buttonConfirmText: 'Ok, entendi',
      },
      false
    );
    this.dialogService.dialogClick.subscribe(() =>
      this.dialogService.clearDialog()
    );
  }

  openDialogManterCarrinhoDeOutroCD() {
    if (this.isDialogOpen) return;
    this.dialogService.openDialog(
      {
        open: true,
        title: 'Atenção',
        subTitle:
          'Você possui carrinho ativo em outra loja. Deseja manter seu último carrinho? ',
        disabled: true,
        buttonCancelText: 'Excluir carrinho',
        buttonConfirmText: 'Ver itens do carrinho',
      },
      false
    );
    this.isDialogOpen = true;
    this.dialogService.dialogClick.subscribe((verItens) => {
      if (verItens) {
        this.router.navigateByUrl('carrinho-antigo');
      } else {
        this.carrinhoFacade.carrinhoEmOutroCD$
          .pipe(take(1))
          .subscribe((carrinho) => {
            if (carrinho) {
              this.carrinhoFacade.removerCarrinho(carrinho.carrinho_id);
            }
          });
      }
      this.dialogService.clearDialog();
      this.isDialogOpen = false;
    });
  }
}
