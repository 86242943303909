import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CarrinhoStateModule } from '@vip/state/carrinho';
import { CentroDistribuicaoStateModule } from '@vip/state/centro-distribuicao';
import { CompraFacade } from './+state/compra.facade';
import * as fromCompras from './+state/compra.reducer';
import { CompraEffects } from './+state/compra.effects';
import { SubstituirProdutoStateModule } from '@vip/state/substituir-produto';
import { CompraEmProcessoStateModule } from '@vip/state/compra-em-processo';

@NgModule({
  imports: [
    CommonModule,
    CentroDistribuicaoStateModule,
    CarrinhoStateModule,
    CompraEmProcessoStateModule,
    StoreModule.forFeature(fromCompras.COMPRA_FEATURE_KEY, fromCompras.reducer),
    EffectsModule.forFeature([CompraEffects]),
    SubstituirProdutoStateModule,
  ],
  providers: [CompraFacade, TitleCasePipe],
})
export class CompraStateModule {}
