import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SelecaoCdSelectors } from '@vip/state/utils';

import * as SelecaoCdActions from './selecao-cd.actions';

@Injectable()
export class SelecaoCdFacade {
  permitirAtualizarItemCarrinho$ = this.store.pipe(
    select(SelecaoCdSelectors.isPermitirAtualizarItemCarrinho)
  );

  constructor(private readonly store: Store) {}

  init() {
    this.store.dispatch(SelecaoCdActions.init());
  }

  permitirAtualizarCarrinho(permitir: boolean): void {
    this.store.dispatch(
      SelecaoCdActions.permitirAtualizarCarrinho({
        permitirAtualizarItemCarrinho: permitir,
      })
    );
  }
}
