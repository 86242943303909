import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import * as BannerActions from './banner.actions';
import { BannerApiService } from '@vip/api';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { BucketsFacade } from '@vip/state/buckets';

import { EMPTY, combineLatest, noop } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BannerEffects {
  getBanner$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(BannerActions.loadBanner)),
      this.cdFacade.filialECdSelecionado$,
      this.bucketsFacade.bucketS3$,
    ]).pipe(
      map(([action, filialECdSelecionado, bucket]) => {
        return { ...action, filialECdSelecionado, bucket };
      }),
      fetch({
        run: (action) => {
          return this.bannerApiService
            .getPorLocalizacao(
              action.filialECdSelecionado[0].id,
              action.filialECdSelecionado[1].id,
              action.local,
              action.filtro
            )
            .pipe(
              map((res) => {
                const slides = res.slides.map((slide) => {
                  const src = `${action.bucket}/files/banner_imagens/${slide.src}`;

                  return { ...slide, src };
                });

                return BannerActions.loadBannerSuccess({
                  banners: {
                    ...res,
                    slides,
                  },
                  stateKey: action.stateKey,
                });
              })
            );
        },
        onError: (action, error) => {
          return BannerActions.loadBannerError({
            error,
            stateKey: action.stateKey,
          });
        },
      })
    );
  });

  addClique$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BannerActions.addClique),
        fetch({
          run: (action) => {
            this.bannerApiService.addClique(action.id).subscribe(noop);
          },
          onError: () => EMPTY,
        })
      ),
    { dispatch: false }
  );

  addCliqueHomeOmni$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BannerActions.addCliqueHomeOmni),
        fetch({
          run: (action) => {
            this.bannerApiService.addCliqueHomeOmni(action.id).subscribe(noop);
          },
          onError: () => EMPTY,
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private cdFacade: CentroDistribuicaoFacade,
    private bannerApiService: BannerApiService,
    private bucketsFacade: BucketsFacade
  ) {}
}
