import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AnalyticsActions from './analytics.actions';
import { ICompra, IProduto, IProdutoCarrinho } from '@vip/core';

@Injectable()
export class AnalyticsFacade {
  constructor(private store: Store) {}

  iniciarCheckout() {
    this.store.dispatch(AnalyticsActions.beginCheckout());
  }

  addPagamentoInfo(tipoPagamentoId: number, compraEmProcesso: ICompra) {
    this.store.dispatch(
      AnalyticsActions.addPaymentInfo({ tipoPagamentoId, compraEmProcesso })
    );
  }

  addEntregaInfo(compra: ICompra) {
    this.store.dispatch(AnalyticsActions.addShippingInfo({ compra }));
  }

  pagamento(pedido: ICompra) {
    this.store.dispatch(AnalyticsActions.purchase({ pedido }));
  }

  visualizaCarrinho() {
    this.store.dispatch(AnalyticsActions.viewCart());
  }

  addToCarrinho(item: IProdutoCarrinho) {
    this.store.dispatch(AnalyticsActions.addToCart({ item }));
  }

  removerDoCarrinho(itens: IProdutoCarrinho[]) {
    this.store.dispatch(AnalyticsActions.removeFromCart({ itens }));
  }

  addMinhaLista(product: IProduto) {
    this.store.dispatch(AnalyticsActions.addToWishList({ product }));
  }

  visualizarItem(product: IProduto) {
    this.store.dispatch(AnalyticsActions.viewItem({ product }));
  }

  buscarItem(searchTerm: string): void {
    if (!searchTerm.trim().length) return;
    this.store.dispatch(AnalyticsActions.search({ searchTerm }));
  }

  cadastrar() {
    this.store.dispatch(AnalyticsActions.signUp());
  }

  login() {
    this.store.dispatch(AnalyticsActions.login());
  }
}
