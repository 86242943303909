import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, pluck } from 'rxjs/operators';
import { BaseWsService } from '../common/base-ws-service';
import {
  ICliente,
  IOperadorTelevendas,
  TelevendasTokenService,
} from '@vip/core';
import { IWsPaging } from '../common/base-ws-response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TelevendasWsService {
  private clienteUrl = '/loja/clientes';

  constructor(
    private ws: BaseWsService<TelevendasWsService>,
    private televendasTokenService: TelevendasTokenService
  ) {}

  private getHeaders() {
    return {
      Authorization: `Bearer ${this.televendasTokenService.getToken()}`,
    };
  }

  listarClientesTelevendas(term: string) {
    const headers = this.getHeaders();
    return this.ws
      .get<{
        clientes: ICliente[];
        paging: IWsPaging;
        error?: HttpErrorResponse;
        message?: string;
      }>(
        'listarClientesTelevendas',
        `/buscas/listar-clientes?term=${term}`,
        headers
      )
      .pipe(
        map((res) => {
          if (res.error) {
            throw new HttpErrorResponse({
              status: 400,
              error: { error: res.message },
            });
          }
          return res;
        })
      );
  }

  vincularFilial(
    id: number,
    filial_id: number
  ): Observable<{
    token: string;
    operador: IOperadorTelevendas;
  }> {
    const headers = this.getHeaders();
    return this.ws
      .post(
        'vincularFilial',
        `/clientes/vincular-filial`,
        {
          id,
          filial_id,
        },
        headers
      )
      .pipe(pluck('data'));
  }
}
