import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { PagamentoService } from './services/pagamentos.service';

@Injectable()
export class CarteiraDigitalGuard implements CanDeactivate<void> {
  constructor(private pagamentoService: PagamentoService) {}

  canDeactivate(): Observable<boolean> {
    return this.pagamentoService.timerCarteiraDigitalIsRunning$.pipe(
      distinctUntilChanged(),
      map((timerIsRunning) => !timerIsRunning)
    );
  }
}
