import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { compraEmProcessoProviderSelectors } from './compra-em-processo-provider.selectors';

@Injectable()
export class CompraEmProcessoProviderFacade {
  compraEmProcesso$ = this.store.pipe(
    select(compraEmProcessoProviderSelectors.getCompraEmProcesso())
  );
  constructor(private readonly store: Store) {}
}
