<div>
  <vip-selectable-card
    *ngIf="showOpcoes['credito']"
    class="mb-3 block"
    (changed)="tipoPagamentoChange('Pre', $event)"
    [showIcon]="true"
    [fullWidth]="true"
    [checked]="tipoChecked === 'Pre'"
    data-cy="cartao-credito"
  >
    <div title class="flex items-center card">
      <i class="mr-2 text-xl icon-credit_card vip-color-primary-main"></i>
      <div class="text vip-color-primary-main">Cartão de Crédito</div>
    </div>
  </vip-selectable-card>

  <vip-selectable-card
    *ngIf="showOpcoes['privateLabel']"
    class="mb-3 block"
    (changed)="tipoPagamentoChange('privateLabel', $event)"
    [showIcon]="true"
    [fullWidth]="true"
    [checked]="tipoChecked === 'privateLabel'"
    data-cy="cartao-loja"
  >
    <div title class="flex items-center card">
      <i class="mr-2 text-xl icon-credit_card vip-color-primary-main"></i>
      <div class="text vip-color-primary-main">Cartão da Loja</div>
    </div>
  </vip-selectable-card>

  <vip-selectable-card
    *ngIf="showOpcoes['refeicao']"
    class="mb-3 block"
    (changed)="tipoPagamentoChange('ref', $event)"
    [showIcon]="true"
    [fullWidth]="true"
    [checked]="tipoChecked === 'ref'"
    data-cy="cartao-alimentacao-refeicao"
  >
    <div title class="flex items-center card">
      <i class="mr-2 text-xl icon-fastfood vip-color-primary-main"></i>
      <div class="text vip-color-primary-main">Cartão Alimentação/Refeição</div>
    </div>
  </vip-selectable-card>
</div>
