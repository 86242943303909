import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConviteListaContainerComponent } from './convite-lista-container/convite-lista-container.component';
import { RouterModule } from '@angular/router';
import { ConviteListaModule } from '@vip/views/convite-lista';
import { ListaStateModule } from '@vip/state/lista';

@NgModule({
  imports: [
    CommonModule,
    ConviteListaModule,
    ListaStateModule,
    RouterModule.forChild([
      {
        path: '',
        component: ConviteListaContainerComponent,
      },
    ]),
  ],
  declarations: [ConviteListaContainerComponent],
})
export class ConviteListaContainerModule {}
