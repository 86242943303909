import { SelecionarClienteTelevendasModule } from '@vip/views/selecionar-cliente-televendas';
import { DropdownDepartamentosModule } from '@vip/ui/dropdown-departamentos';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderDesktopMenuPrincipalComponent } from './header-desktop-menu-principal/header-desktop-menu-principal.component';
import { RouterModule } from '@angular/router';
import { DropdownModule } from '@vip/ui/dropdown';
import { HeaderDesktopSubMenuComponent } from './header-desktop-sub-menu/header-desktop-sub-menu.component';
import { ImageModule } from '@vip/ui/image';
import { SearchModule } from '@vip/ui/search';
import { ButtonModule } from '@vip/ui/button';
import { BadgeModule } from '@vip/ui/badge';
import { EntregaRetiradaModule } from '@vip/views/entrega-retirada';
import { MinhaContaMenuModule } from '@vip/ui/minha-conta-menu';
import { HeaderDesktopComponent } from './header-desktop/header-desktop.component';
import { HeaderSubMenuMobileComponent } from './header-sub-menu-mobile/header-sub-menu-mobile.component';
import { SearchMenuModule } from '@vip/ui/search-menu';
import { CarrinhoModule } from '@vip/views/carrinho';
import { ModalModule } from '@vip/ui/modal';
import { FieldModule } from '@vip/ui/field';
import { ReactiveFormsModule } from '@angular/forms';
import { ScreenTitleModule } from '@vip/ui/screen-title';
import { NotFoundModalModule } from '@vip/ui/not-found-modal';
import { SkeletonModule } from '@vip/ui/skeleton';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DropdownDepartamentosModule,
    DropdownModule,
    ImageModule,
    SearchModule,
    ButtonModule,
    BadgeModule,
    EntregaRetiradaModule,
    MinhaContaMenuModule,
    SearchMenuModule,
    CarrinhoModule,
    ModalModule,
    FieldModule,
    ReactiveFormsModule,
    ScreenTitleModule,
    NotFoundModalModule,
    SkeletonModule,
    SelecionarClienteTelevendasModule,
  ],
  declarations: [
    HeaderDesktopMenuPrincipalComponent,
    HeaderDesktopSubMenuComponent,
    HeaderDesktopComponent,
    HeaderSubMenuMobileComponent,
  ],
  exports: [
    HeaderDesktopMenuPrincipalComponent,
    HeaderDesktopSubMenuComponent,
    HeaderDesktopComponent,
    HeaderSubMenuMobileComponent,
  ],
})
export class HeaderDesktopModule {}
