import { Action, createReducer, on } from '@ngrx/store';

import { ICarousel } from '@vip/core';
import * as BannersActions from './banner.actions';
import { IGenericState } from '@vip/state/utils';

export const BANNER_FEATURE_KEY = 'banner';

export interface BannerStateData {
  receitas: ICarousel | null;
  departamentos: ICarousel | null;
  institucionais: ICarousel | null;
  colecoes: ICarousel | null;
  vitrine: ICarousel | null;
  vitrineEmBaixo: ICarousel | null;
  colecao: ICarousel | null;
  ofertas: ICarousel | null;
}

export type BannerState = IGenericState<BannerStateData>;

export const initialState: BannerState = {
  data: {
    receitas: null,
    departamentos: null,
    institucionais: null,
    colecoes: null,
    colecao: null,
    vitrine: null,
    vitrineEmBaixo: null,
    ofertas: null,
  },
  status: 'pending',
  error: null,
};

const bannerReducer = createReducer(
  initialState,
  on(BannersActions.loadBanner, (state) => ({
    ...state,
    status: 'loading',
    error: null,
  })),
  on(BannersActions.loadBannerSuccess, (state, { banners, stateKey }) => ({
    ...state,
    data: {
      ...state.data,
      [stateKey]: banners,
    },
    status: 'success',
    error: null,
  })),
  on(BannersActions.loadBannerError, (state, { error, stateKey }) => ({
    ...state,
    status: 'error',
    data: {
      ...state.data,
      [stateKey]: null,
    },
    error: error.error,
  }))
);

export function reducer(state: BannerState | undefined, action: Action) {
  return bannerReducer(state, action);
}
