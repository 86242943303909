import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { BucketsFacade } from '@vip/state/buckets';
import { ProdutoApiService } from '@vip/api';
import { ProdutoStore } from './produto.store';

@Injectable()
export class ProdutosSimilaresCompareStore extends ProdutoStore {
  readonly getSimilaresCompare = this.effect(
    (payload$: Observable<{ produtoId: number }>) => {
      return combineLatest([
        payload$,
        this.cdFacade.filialECdSelecionado$.pipe(
          filter(
            ([filial, cdSelecionado]) =>
              filial !== undefined && cdSelecionado !== undefined
          )
        ),
        this.bucketsFacade.bucketProduto$.pipe(
          filter((bucket) => bucket !== '')
        ),
      ]).pipe(
        map(([payload, [filial, cdSelecionado], bucket]) => {
          return { ...payload, filial, cdSelecionado, bucket };
        }),
        switchMap((action) => {
          this.updateStatus('loading');
          return this.produtoApiService
            .getSimilaresCompare(
              action.filial.id,
              action.cdSelecionado.id,
              action.produtoId
            )
            .pipe(
              tapResponse(
                (response) => {
                  this.updateProdutosAndPaginator(
                    {
                      produtos: response.data,
                      paginator: response.paginator,
                    },
                    action.bucket
                  );
                },
                (error) =>
                  this.updateError((error as HttpErrorResponse).error.error)
              )
            );
        })
      );
    }
  );

  readonly getSimilaresCompareDisponiveis = this.effect(
    (payload$: Observable<{ produtoId: number }>) => {
      return combineLatest([
        payload$,
        this.cdFacade.filialECdSelecionado$.pipe(
          filter(
            ([filial, cdSelecionado]) =>
              filial !== undefined && cdSelecionado !== undefined
          )
        ),
        this.bucketsFacade.bucketProduto$.pipe(
          filter((bucket) => bucket !== '')
        ),
      ]).pipe(
        map(([payload, [filial, cdSelecionado], bucket]) => {
          return { ...payload, filial, cdSelecionado, bucket };
        }),
        switchMap((action) => {
          this.updateStatus('loading');

          return this.produtoApiService
            .getSimilaresCompareDisponiveis(
              action.filial.id,
              action.cdSelecionado.id,
              action.produtoId
            )
            .pipe(
              tapResponse(
                (response) => {
                  this.updateProdutosAndPaginator(
                    {
                      produtos: response.data,
                      paginator: response.paginator,
                    },
                    action.bucket
                  );
                },
                (error) =>
                  this.updateError((error as HttpErrorResponse).error.error)
              )
            );
        })
      );
    }
  );

  constructor(
    private produtoApiService: ProdutoApiService,
    private bucketsFacade: BucketsFacade,
    private cdFacade: CentroDistribuicaoFacade
  ) {
    super();
  }
}
