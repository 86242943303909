import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'vip-seletor-de-peso',
  templateUrl: './seletor-de-peso.component.html',
  styleUrls: ['./seletor-de-peso.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeletorDePesoComponent implements OnInit, OnChanges {
  @Input() defaultSelection: 'unit' | 'weight' = 'unit';
  @Input() hasDetailProduct = false;
  @Input() miniCard: boolean | null = null;
  @Input() isTablet: boolean | null = false;
  @Output() unidadeSelecionada = new EventEmitter();
  @Output() pesoSelecionado = new EventEmitter();

  isUnitSelected = true;

  ngOnInit() {
    this.isUnitSelected = this.defaultSelection === 'unit';
  }

  ngOnChanges(): void {
    if (this.defaultSelection === 'unit') {
      this.isUnitSelected = true;
    } else if (this.defaultSelection === 'weight') {
      this.isUnitSelected = false;
    }
  }

  selectUnit(event: Event) {
    event.stopPropagation();
    this.unidadeSelecionada.emit();
    this.isUnitSelected = true;
  }

  selectWeight(event: Event) {
    event.stopPropagation();
    this.pesoSelecionado.emit();
    this.isUnitSelected = false;
  }
}
