import { LoadingFacade } from '@vip/state/loading';
import { DialogService } from '@vip/ui/modal';
import { TipoEntregaFacade } from '../+state/tipo-entrega.facade';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { CompraEmProcessoProviderFacade } from '@vip/state/compra-em-processo-provider';
import {
  ICarrinho,
  ICliente,
  ICompra,
  IHorariosFormatados,
  ITipoEntrega,
  LayoutUtilsService,
  TipoClienteEnum,
} from '@vip/core';
import { TipoEntregaApiService } from '@vip/api';

const FIVE_MINUTES: number = 1000 * 60 * 5;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ValidaEntregaService {
  private timer: ReturnType<typeof setInterval> | null = null;
  private tipoEntregaIdSelecionado$ =
    this.tipoEntregaFacade.tipoEntregaIdSelecionado$;
  private horarioSelecionado$ = this.tipoEntregaFacade.horarioSelecionado$;
  private tiposEntregas$ = this.tipoEntregaFacade.tiposEntregas$;
  private route = '';
  isDesktop = this.layoutUtilsService.isDesktop();
  timePassed = false;
  isModalOpen = false;

  constructor(
    private dialogService: DialogService,
    private tipoEntregaFacade: TipoEntregaFacade,
    private router: Router,
    private compraEmProcessoProviderFacade: CompraEmProcessoProviderFacade,
    private layoutUtilsService: LayoutUtilsService,
    private tipoEntregaApiService: TipoEntregaApiService
  ) {}

  startTimer() {
    if (!this.timer) {
      this.timePassed = false;
      this.timer = setInterval(() => {
        this.timePassed = true;
        this.validaEntrega();
      }, FIVE_MINUTES);
    }
  }

  stopTimer() {
    if (this.timer) clearInterval(this.timer);
    this.timer = null;
    this.timePassed = false;
  }

  validaEntrega() {
    let valido = false;
    this.route = 'entrega-retirada/alterar-entrega-retirada';
    this.tipoEntregaIdSelecionado$
      .pipe(
        distinctUntilChanged((curr, prev) => curr === prev),
        take(1)
      )
      .subscribe((tipoEntregaId) => {
        if (tipoEntregaId) {
          this.tipoEntregaFacade.getTiposEntregasEndereco();
          combineLatest([
            this.tiposEntregas$.pipe(take(1)),
            this.horarioSelecionado$.pipe(take(1)),
            this.compraEmProcessoProviderFacade.compraEmProcesso$.pipe(take(1)),
          ])
            .pipe(
              filter(
                ([tipoEntregas, , compraEmProcesso]) =>
                  !compraEmProcesso || !compraEmProcesso.loja_autonoma
              ),
              map(([tipoEntregas, horarioSelecionado, compraEmProcesso]) => ({
                tipoEntregaSelecionadoId: tipoEntregaId,
                tipoEntregas,
                horarioSelecionado,
                compraEmProcesso,
              }))
            )
            .subscribe(
              ({
                tipoEntregaSelecionadoId,
                tipoEntregas,
                horarioSelecionado,
              }) => {
                if (tipoEntregaSelecionadoId && horarioSelecionado) {
                  tipoEntregas.forEach((entrega) => {
                    if (entrega.id == tipoEntregaSelecionadoId) {
                      if (
                        this.validarHorarioSelecionadoDisponivel(
                          entrega.horarios_formatados,
                          horarioSelecionado
                        )
                      ) {
                        valido = true;
                      } else {
                        this.route = 'entrega-retirada/alterar-horario';
                      }
                    }
                  });
                  if (!valido || this.timePassed) {
                    this.stopTimer();
                    if (this.isDesktop) {
                      //@TODO - Implementar modal para selecionar entrega
                    } else {
                      this.showAppDialog();
                    }
                  }
                }
              }
            );
        }
      });
  }

  validarHorarioSelecionadoDisponivel(
    horarios: IHorariosFormatados[],
    horarioSelecionado: IHorariosFormatados
  ) {
    return (
      horarioSelecionado &&
      horarios.findIndex(
        (horario: IHorariosFormatados) => horario.key == horarioSelecionado?.key
      ) != -1
    );
  }

  showAppDialog() {
    if (this.isModalOpen) return;
    this.isModalOpen = true;

    this.dialogService.openDialog({
      open: true,
      title: 'Você excedeu o tempo limite para finalizar a compra',
      subTitle:
        'Por favor, selecione novamente o tipo de entrega e a forma de pagamento',
      disabled: true,
      showCloseButton: false,
      buttonConfirmText: 'Selecionar tipo de entrega',
    });

    this.dialogService.dialogClick.subscribe((value) => {
      this.isModalOpen = false;
      if (value) this.router.navigate([this.route]);
      this.dialogService.clearDialog();
    });

    this.dialogService.closeClick.subscribe(() => {
      this.isModalOpen = false;
      this.router.navigate([this.route]);
    });
  }

  validarEntregaNoPrazo(
    compraEmProcesso: ICompra | null | undefined,
    carrinho: ICarrinho,
    cliente: ICliente | null,
    validarDataCompra: boolean,
    confirmaModal: boolean
  ): Promise<boolean> {
    return new Promise((resolve) => {
      if (!compraEmProcesso) {
        resolve(false);
        return;
      }

      if (validarDataCompra && confirmaModal === false) {
        const currentDateString = compraEmProcesso?.current_date ?? '';
        const createdDateString = compraEmProcesso?.created ?? '';

        const current_date = new Date(currentDateString);
        const created_date = new Date(createdDateString);

        const validCurrentDate = current_date.getTime();
        const validCreatedDate = created_date.getTime();

        if (!isNaN(validCurrentDate) && !isNaN(validCreatedDate)) {
          const compraCriadaHaMaisDeCincoMinutos =
            validCurrentDate - validCreatedDate > FIVE_MINUTES;

          if (compraCriadaHaMaisDeCincoMinutos) {
            resolve(false);
            return;
          } else {
            resolve(true);
          }
        } else {
          resolve(false);
        }
      }

      this.tipoEntregaApiService
        .getTiposEntregasEndereco(
          compraEmProcesso.filial_id,
          compraEmProcesso.centro_distribuicao_id,
          compraEmProcesso.endereco.id,
          compraEmProcesso.total_itens,
          carrinho.peso_total,
          cliente?.tipo_cliente as TipoClienteEnum
        )
        .pipe(take(1))
        .subscribe((tipoEntregas) => {
          const tipoEntregaDisponivel = tipoEntregas.some(
            (entrega: ITipoEntrega) => {
              return (
                entrega.horarios_formatados &&
                entrega.horarios_formatados.some((horario) => {
                  return (
                    horario.key ===
                    compraEmProcesso.tipo_entrega.horario_selecionado?.key
                  );
                })
              );
            }
          );
          resolve(tipoEntregaDisponivel);
          return tipoEntregaDisponivel;
        });
    });
  }
}
