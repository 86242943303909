import { createAction, props } from '@ngrx/store';
import { IFilter, PaginaFiltro } from '@vip/core';
import { HttpErrorResponse } from '@angular/common/http';

export const abrirFiltro = createAction(
  '[Filtro/Page] Abrir Filtro',
  props<{ paginaFiltro: PaginaFiltro }>()
);

export const setFiltroAtivo = createAction(
  '[Filtro/Page] Setar filtro ativo',
  props<{ paginaFiltro: PaginaFiltro }>()
);

export const aplicarFiltro = createAction(
  '[Filtro/Page] Aplicar Filtro',
  props<{ filtros: IFilter[]; paginaFiltro: PaginaFiltro }>()
);

export const getFiltroApiDepartamento = createAction(
  '[Filtro/API] Buscar Filtro Departamento',
  props<{ cmId: number; filtrosLocais: IFilter[] }>()
);

export const getFiltroApiDepartamentoSuccess = createAction(
  '[Filtro/API] Load Filtro Departamento Success',
  props<{ filtros: IFilter[] }>()
);

export const getFiltroApiDepartamentoFailure = createAction(
  '[Filtro/API] Load Filtro Departamento Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getFiltroApiSecao = createAction(
  '[Filtro/API] Buscar Filtro Secao',
  props<{ cmId: number; filtrosLocais: IFilter[] }>()
);

export const getFiltroApiSecaoSuccess = createAction(
  '[Filtro/API] Load Filtro Secao Success',
  props<{ filtros: IFilter[] }>()
);

export const getFiltroApiSecaoFailure = createAction(
  '[Filtro/API] Load Filtro Secao Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getFiltroApiFavoritos = createAction(
  '[Filtro/Page] Buscar Filtro Favoritos '
);

export const getFiltroApiFavoritosSuccess = createAction(
  '[Filtro/API] Load Filtro Favoritos Success',
  props<{ filtros: IFilter[] }>()
);

export const getFiltroApiFavoritosFailure = createAction(
  '[Filtro/API] Load Filtro Favoritos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getFiltroBusca = createAction(
  '[Busca/Page] Buscar Filtros de busca',
  props<{ buscaId: number; filtrosLocais?: string; filtros?: IFilter[] }>()
);

export const getFiltroBuscaSuccess = createAction(
  '[Filtro/API] Load Filtros de busca Success',
  props<{ filtros: IFilter[] }>()
);

export const getFiltroBuscaFailure = createAction(
  '[Filtro/API] Load Filtros de busca Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getFiltroColecao = createAction(
  '[Busca/Page] Buscar Filtros de Colecao',
  props<{ colecaoId: number; filtrosLocais?: string }>()
);

export const getFiltroColecaoSuccess = createAction(
  '[Filtro/API] Load Filtros de Colecao Success',
  props<{ filtros: IFilter[] }>()
);

export const getFiltroColecaoFailure = createAction(
  '[Filtro/API] Load Filtros de Colecao Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getFiltroMinhasListas = createAction(
  '[Busca/Page] Buscar Filtros de Minhas Listas',
  props<{ listaId: number }>()
);

export const getFiltroMinhasListasSuccess = createAction(
  '[Filtro/API] Load Filtros de Minhas Listas Success',
  props<{ filtros: IFilter[] }>()
);

export const getFiltroMinhasListasFailure = createAction(
  '[Filtro/API] Load Filtros de Minhas Listas Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getFiltrosOfertas = createAction(
  '[Filtro/Page] Buscar Filtro Ofertas '
);

export const getFiltrosOfertasSuccess = createAction(
  '[Filtro/API] Load Filtro Ofertas Success',
  props<{ filtros: IFilter[] }>()
);

export const getFiltrosOfertasFailure = createAction(
  '[Filtro/API] Load Filtro Ofertas Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getFiltrosCombos = createAction(
  '[Filtro/Page] Buscar Filtro Combos '
);

export const getFiltrosCombosSuccess = createAction(
  '[Filtro/API] Load Filtro Combos Success',
  props<{ filtros: IFilter[] }>()
);

export const getFiltrosCombosFailure = createAction(
  '[Filtro/API] Load Filtro Combos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const atualizarFiltroCombo = createAction(
  '[Filtro/API] Atualizar Filtro Combos',
  props<{ filtroOrdenacao: IFilter }>()
);
