<div
  class="vip-header {{ type }}"
  [class.absolute]="flutuante"
  data-cy="vip_header"
>
  <div
    class="relative flex flex-row justify-center items-center vip-header-{{
      type
    }}"
  >
    <ng-container *ngIf="type === 'type_1'; else type2">
      <vip-image
        [hidden]="!imageLoadComplete || isLoading"
        [class.with-image]="!notShowingImage"
        class="flex justify-center"
        [imageUrl]="logo"
        [hiddenOnError]="hiddenLogoOnError"
        [defaultImageUrl]="defaultImageUrl"
        [fallbackImageUrl]="fallbackImageUrl"
        (notShowingImage)="notShowingImage = $event"
        (imageLoaded)="imageLoadComplete = $event"
      ></vip-image>
      <ng-container
        *vipSkeleton="isLoading; width: '220px'; height: '27px'"
      ></ng-container>
      <i
        *ngIf="(imageLoadComplete || !isLoading) && !hideSearchIcon"
        (click)="handleSearchClick($event)"
        class="absolute icon-search"
        [class.pt-5]="notShowingImage"
        data-cy="vip_header_search"
      ></i>
      <ng-container
        *vipSkeleton="
          isLoading;
          width: '24px';
          height: '24px';
          className: ['absolute', 'right-3']
        "
      ></ng-container>
    </ng-container>

    <ng-template #type2>
      <div class="flex flex-row items-center px-3 w-full">
        <vip-image
          [ngClass]="{ 'mr-3 with-image': !notShowingImage }"
          [imageUrl]="logo"
          [fallbackImageUrl]="fallbackImageUrl"
          [defaultImageUrl]="defaultImageUrl"
          [hiddenOnError]="hiddenLogoOnError"
          [hidden]="!imageLoadComplete || isLoading"
          (notShowingImage)="notShowingImage = $event"
          (imageLoaded)="imageLoadComplete = $event"
        ></vip-image>
        <ng-container
          *vipSkeleton="
            isLoading;
            width: '35px';
            height: '35px';
            radius: '100px';
            className: ['mr-3']
          "
        ></ng-container>
        <vip-search
          data-cy="vip_header_search"
          class="w-full"
          placeholder="O que você precisa?"
          [isLoading]="isLoading"
          (search)="handleSearchEvent($event)"
          (click)="handleSearchClick($event)"
        ></vip-search>
      </div>
    </ng-template>
  </div>
</div>
