<vip-carousel-card
  size="sm"
  data-cy="produto"
  class="flex items-center justify-center w-[99%]"
>
  <ng-container
    *ngFor="let produto of combo.produtos; let first = first; let last = last"
    card
  >
    <div class="flex items-center">
      <div
        [ngClass]="{ 'ml-3': first, 'mr-3': last }"
        class="flex flex-col items-center bg-white w-[118px] max-w-[120px] h-max"
      >
        <vip-card-combo-mini [produtoCombo]="produto"></vip-card-combo-mini>
      </div>
      <i
        *ngIf="!last"
        class="icon-add bg-color-tag text-[24px] flex justify-center items-center"
      ></i>
    </div>
  </ng-container>
</vip-carousel-card>
<div
  *ngIf="hasProductInfo"
  class="items-center flex gap-4 px-3 pb-[25px] pt-[25px]"
  [ngClass]="{
    'w-max': isDesktop || isTablet,
    'w-full': !isDesktop && !isTablet
  }"
>
  <div class="flex justify-center items-center w-full">
    <div class="w-full">
      <div class="w-full pb-2">
        <p class="text-[14px] font-bold text-center w-full">
          {{ combo.preco_oferta | currency: 'BRL' }}
          <span class="font-normal">({{ combo.quantidade_itens }} itens)</span>
        </p>
        <p class="text-[14px] bg-color-tag text-center pt-1 font-bold w-full">
          Você economiza {{ valorDiferenca | currency: 'BRL' }}
        </p>
      </div>
      <div class="flex items-center w-full">
        <vip-spin
          (changed)="handleSpinAlterado($event)"
          *ngIf="editable"
          [attr.data-cy]="'card_produto_combo_' + combo.id + '_spin_large'"
          [confimDeletion]="true"
          [disabled]="loading"
          [large]="showSpinLarge"
          [max]="quantidadeMaximaSpinner"
          [quantity]="quantidade"
          [subTitleModalConfimDeletion]="combo.nome"
          class="w-full"
          [size]="hasProductInfo ? 'small' : 'ex-small'"
          [addLabel]="'Adicionar ao carrinho'"
          [styleSpin]="isDesktop || isTablet"
          [produto]="combo"
          (click)="$event.stopPropagation()"
          [hasCombo]="hasCombo"
        >
        </vip-spin>
      </div>

      <div *ngIf="combo.quantidade_maxima" class="w-full mt-2">
        <p
          class="text-[12px] font-normal text-center w-full vip-color-secondary-main"
        >
          *Limite de {{ combo.quantidade_maxima }} combos por compra.
        </p>
      </div>
    </div>
  </div>
</div>
