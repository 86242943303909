<div
  class="vip-substituir-produto-container flex flex-col h-full"
  [ngClass]="{ 'pt-4': isModal }"
>
  <vip-screen-title
    [title]="
      isSubstituirTemplate ? 'Substituir Produto' : 'Produtos Indisponíveis'
    "
    [customActions]="true"
    [showActions]="true"
  >
    <vip-button-icon-rounded
      type="outline"
      class="z-30 pl-1"
      (btnClick)="goBack.emit(false)"
    >
      <i class="vip-color-secondary-main icon-arrow_back"></i>
    </vip-button-icon-rounded>
  </vip-screen-title>
  <div
    class="h-full px-3 flex flex-col overflow-hidden"
    [ngClass]="{ 'px-4 justify-between': isModal }"
  >
    <div
      class="produtos-lista"
      [ngClass]="{ substituir: isSubstituirTemplate }"
    >
      <ng-container [ngSwitch]="templateName">
        <ng-container *ngSwitchCase="'indisponiveis'">
          <h2 class="vip-modal-title" *ngIf="isModal">
            Produtos Indisponíveis
          </h2>
          <span
            class="vip-indisponiveis-message block py-6 px-2 text-center text-gray-700 text-base"
            *ngIf="!isModal"
            >{{
              'Alguns produtos da lista (' +
                produtosIndisponiveis.length +
                ' itens) estão indisponíveis em estoque. Você deseja substituí-los?'
            }}</span
          >
          <span
            *ngIf="isModal"
            class="vip-indisponiveis-message block text-gray-700 text-base mb-4"
          >
            Alguns produtos da lista estão indisponíveis em estoque. Você
            gostaria de substituí-los?
          </span>
          <ng-container *ngIf="!isLoading; else skeletonTemplate">
            <vip-card-produto-substituir
              *ngFor="
                let produto of produtosIndisponiveis;
                let lastIndisponivel = last
              "
              [produto]="produto"
              [showSpin]="false"
              [showIndisponivelBadge]="true"
              [quantidadeASubstituir]="produto.quantidade || 1"
              data-cy="produto-indisponivel"
              [ngClass]="{
                'mb-2': !lastIndisponivel,
                'mb-1': lastIndisponivel
              }"
              [isModalTemplate]="isModal"
            ></vip-card-produto-substituir>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'substituir'">
          <h2 class="vip-modal-title modal-substituir-title" *ngIf="isModal">
            Substituir Produtos
          </h2>
          <div
            class="flex justify-between"
            [ngClass]="{ 'mb-3': isModal, 'mt-3': !isModal }"
          >
            <span
              class="vip-color-first-default"
              [ngClass]="{
                'text-base': isModal,
                'text-sm font-medium': !isModal
              }"
            >
              Selecione um produto substituto para:
            </span>
            <span
              class="text-xs vip-color-primary-contrast vip-background-secondary vip-contador"
            >
              {{
                indexProdutoSelecionado +
                  1 +
                  ' de ' +
                  produtosIndisponiveis.length
              }}
            </span>
          </div>
          <ng-container *ngIf="!isLoading; else skeletonOnlyOneTemplate">
            <vip-card-produto-substituir
              *ngIf="produtoIndisponivelSelecionado"
              [produto]="produtoIndisponivelSelecionado"
              [showSpin]="false"
              [showUnidade]="true"
              [quantidadeASubstituir]="
                produtoIndisponivelSelecionado.quantidade || 1
              "
              data-cy="vip-product-to-replace-card"
              [isModalTemplate]="isModal"
            ></vip-card-produto-substituir>
          </ng-container>
          <ng-template #skeletonOnlyOneTemplate>
            <vip-card-produto-substituir-skeleton
              [quantity]="1"
            ></vip-card-produto-substituir-skeleton>
          </ng-template>
          <hr class="vip-divider my-4" />
          <vip-search
            class="w-full mb-2"
            placeholder="Procure por outro produto"
            [value]="termoPesquisado || ''"
            [(focusOnInit)]="isSearchFocused"
            [isLoading]="isLoading"
            (search)="search.emit($event.value)"
            (clearEmitter)="searchClear.emit()"
            (click)="isSearchFocused = true"
            (focusout)="isSearchFocused = false"
          >
          </vip-search>

          <div #listaSubstituicao class="substituicao-lista">
            <ng-container *ngIf="!isLoading; else skeletonTemplate">
              <vip-card-produto-substituir
                (produtoChanged)="handleProdutoChanged($event, produtoSimilar)"
                class="mb-2"
                [produto]="produtoSimilar"
                [showSpin]="true"
                *ngFor="let produtoSimilar of produtosSimilares"
                [spinQuantity]="produtoSimilar.quantidade || 0"
                [disableSpin]="
                  !!produtoSubstituto?.quantidade &&
                  produtoSubstituto?.produto_id !== produtoSimilar.produto_id
                "
                [quantidadeASubstituir]="
                  produtoIndisponivelSelecionado?.quantidade || 1
                "
                [attr.data-cy]="'vip-replace-product-' + produtoSimilar.id"
                [isModalTemplate]="isModal"
              ></vip-card-produto-substituir>
              <vip-empty
                *ngIf="produtosSimilares.length === 0"
                image="{{ imageUrl }}"
                text="Nenhum produto encontrado na sua busca"
              ></vip-empty>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div
      [ngClass]="{
        'grid-cols-2': isSubstituirTemplate && !isOnlyOneUnavailable,
        'modal-footer': isModal,
        'controls-wrapper mt-auto grid gap-2 grid-rows-1': !isModal,
        'mb-2': !isModal && !produtoSubstituto
      }"
    >
      <vip-button
        *ngIf="isSubstituirTemplate && !isOnlyOneUnavailable"
        type="normal"
        class="vip-back-button"
        (btnClick)="backProduct()"
        data-cy="vip-back-button"
        >Voltar
      </vip-button>
      <vip-button
        type="outline"
        class="bg-transparent"
        *ngIf="!produtoSubstituto"
        (btnClick)="skipProduct()"
        data-cy="vip-skip-button"
        >{{
          isOnlyOneUnavailable
            ? 'Cancelar'
            : isSubstituirTemplate
            ? 'Pular'
            : 'Não substituir'
        }}</vip-button
      >
      <vip-button
        (btnClick)="isSubstituirTemplate ? confirmProduct() : startSubstituir()"
        *ngIf="!isSubstituirTemplate || !!produtoSubstituto?.quantidade"
        data-cy="vip-confirm-button"
        [ngClass]="{ 'pb-2': !isModal }"
        >{{
          isSubstituirTemplate ? 'Confirmar' : 'Substituir produtos'
        }}</vip-button
      >
    </div>
  </div>
  <ng-template #skeletonTemplate>
    <vip-card-produto-substituir-skeleton
      [quantity]="3"
    ></vip-card-produto-substituir-skeleton>
  </ng-template>
</div>
