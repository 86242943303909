import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidarCepComponent } from './validar-cep/validar-cep.component';
import { ButtonModule } from '@vip/ui/button';
import { FieldModule } from '@vip/ui/field';
import { SolicitarCepComponent } from './views/solicitar-cep/solicitar-cep.component';
import { CepNaoAtendidoComponent } from './views/cep-nao-atendido/cep-nao-atendido.component';
import { SomenteRetiradaComponent } from './views/somente-retirada/somente-retirada.component';
import { SolicitacaoCadastradaComponent } from './views/solicitacao-cadastrada/solicitacao-cadastrada.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    FieldModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    ValidarCepComponent,
    SolicitarCepComponent,
    CepNaoAtendidoComponent,
    SomenteRetiradaComponent,
    SolicitacaoCadastradaComponent,
  ],
  exports: [ValidarCepComponent],
})
export class ValidarCepModule {}
