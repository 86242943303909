import {
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TabComponent } from '../tab/tab.component';
import { ScrollableService } from '@vip/core';

@Directive()
export abstract class TabBaseDirective implements OnChanges {
  @ContentChildren(TabComponent, { descendants: true })
  _allTabs!: QueryList<TabComponent>;

  @Input()
  color: 'primary' | 'secondary' = 'primary';

  @Input()
  initialIndex?: number;

  @Output()
  tabChange = new EventEmitter();

  @Output()
  selectedTabEmitter = new EventEmitter();

  @ViewChild('contentHeader')
  private _contentHeader: ElementRef | undefined;

  @ViewChild('contentWrap')
  private _contentWrap: ElementRef | undefined;

  selectedIndex = 0;
  lastChildrenIndex?: number;
  selectedTab?: TabComponent;

  constructor(
    public scrollableService: ScrollableService,
    public renderer: Renderer2,
    public cd: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialIndex'] && this.initialIndex !== undefined) {
      this.initTabGroup();
    }
  }

  initTabGroup(preventEmitTabChange?: boolean): void {
    if (!this._allTabs) {
      return;
    }

    this.selectedIndex = 0;
    this.scrollableService.slider = this._contentHeader?.nativeElement;

    this._allTabs.forEach((tab) => {
      if (tab.disabled) this.selectedIndex++;
    });

    this.lastChildrenIndex = this._allTabs.reduce((acc, curr, index) => {
      if (curr.chidrenLabel) {
        acc = index;
      }
      return acc;
    }, -1);

    this.selectedTab = this._allTabs.get(this.selectedIndex);
    if (this.selectedTab) this.selectedTabEmitter.emit(this.selectedTab);

    if (this.selectedIndex > 0) {
      this.translate(this.selectedIndex);
    }

    if (this.initialIndex !== undefined) {
      this._handleClick(this.initialIndex, preventEmitTabChange);
      this.selectedIndex = this.initialIndex;
    }

    this.cd.detectChanges();
  }

  _handleClick(index: number, preventEmitTabChange?: boolean): void {
    if (this.selectedIndex !== index && !this.scrollableService.disableClick) {
      this.selectedIndex = index;
      this.translate(this.selectedIndex);
      if (!preventEmitTabChange) this.tabChange.emit(this.selectedIndex);
    }
  }

  translate(index: number) {
    this.renderer.setStyle(
      this._contentWrap?.nativeElement,
      'transform',
      `translateX(calc(-100% * ${index}))`
    );
  }
}
