import { Directive, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { IEndereco } from '@vip/core';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Directive()
export abstract class FormEnderecoDirective {
  form = this.fb.group({
    cep: [
      '',
      [
        Validators.required,
        this.isCepValid.bind(this),
        this.isCepAtendido.bind(this),
      ],
    ],
    logradouro: ['', [Validators.required]],
    numero: ['', [Validators.required, Validators.pattern('^\\d+$')]],
    complemento: [''],
    bairro: ['', [Validators.required]],
    cidade: ['', [Validators.required]],
    sigla: ['', [Validators.required]],
    titulo: ['', [Validators.required]],
    referencia: [''],
    principal: [false],
  });

  cepIsComplete = false;

  @Output() changeCep = new EventEmitter();

  desativadosCepValido = ['logradouro', 'bairro', 'cidade', 'sigla'];
  desativadosEdicao = ['cep', 'logradouro', 'bairro', 'cidade', 'sigla'];
  private ativadosCepSemLogradouro = ['logradouro', 'bairro'];
  private _cepSemLogradouro!: boolean;

  constructor(private fb: UntypedFormBuilder) {
    this.atualizarCamposDesativados();
    this.cepChangeEmitter();
  }

  private _endereco!: Partial<IEndereco> | null;

  @Input()
  get endereco(): Partial<IEndereco> | null {
    return this._endereco;
  }

  set endereco(value: Partial<IEndereco> | null) {
    this._endereco = value;

    if (value) {
      this.form.patchValue(value);
    }
    if (this._isNew) {
      this._cepSemLogradouro = !value?.logradouro;
    }
  }

  private _isNew = true;

  @Input()
  get isNew(): boolean {
    return this._isNew;
  }

  set isNew(value: boolean) {
    this._isNew = value;
    this.atualizarCamposDesativados();
  }

  private _cepValido!: boolean;

  @Input()
  get cepValido(): boolean {
    return this._cepValido;
  }

  set cepValido(value: boolean) {
    this._cepValido = value;
    this.atualizarCamposDesativados();
  }

  private _cepAtendido!: boolean;

  @Input()
  get cepAtendido(): boolean {
    return this._cepAtendido;
  }

  set cepAtendido(value: boolean) {
    this._cepAtendido = value;
    if (this.naoPossuiEndereco) {
      this.desativadosCepValido.push('principal');
      this.desativadosEdicao.push('principal');
    }
    this.atualizarCamposDesativados();
  }

  @Input() naoPossuiEndereco = false;

  private get cepFormControl(): UntypedFormControl {
    return this.form.get('cep') as UntypedFormControl;
  }

  atualizarCamposDesativados() {
    Object.keys(this.form.controls).forEach((key) => {
      if (!this.isNew && this.desativadosEdicao.includes(key)) {
        this.form.get(key)?.disable();
      } else if (this._cepValido && this.desativadosCepValido.includes(key)) {
        this.form.get(key)?.disable();
        if (
          this._cepSemLogradouro &&
          this.ativadosCepSemLogradouro.includes(key)
        ) {
          this.form.get(key)?.enable();
          this.validatePrincipal();
        } else {
          this.form.get(key)?.disable();
        }
      } else {
        this.form.get(key)?.enable();
        this.validatePrincipal();
      }
    });
  }

  private validatePrincipal() {
    if (this.endereco?.principal) {
      this.form.controls['principal'].disable();
    }
  }

  private cepChangeEmitter() {
    this.cepFormControl.valueChanges
      .pipe(
        filter((value: string) => value?.length === 8),
        distinctUntilChanged()
      )
      .subscribe((value: string) => {
        this.form.controls.cep.markAsTouched();
        this.cepIsComplete = true;
        return this.changeCep.emit(value);
      });
  }

  private isCepValid(control: UntypedFormControl) {
    if (!control.value || this._cepValido || !this.cepIsComplete) return null;
    return { invalid: true };
  }

  private isCepAtendido(control: UntypedFormControl) {
    if (control.value?.length < 8 || this._cepAtendido || !this.cepIsComplete)
      return null;
    return { naoAtendido: true };
  }
}
