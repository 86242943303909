import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponentSkeletonComponent } from '@vip/ui/skeleton';

@Component({
  selector: 'vip-card-produto-lista-skeleton',
  templateUrl: './card-produto-lista-skeleton.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardProdutoListaSkeletonComponent extends BaseComponentSkeletonComponent {
  @Input() noSidePadding = false;
}
