import { ThemeService } from '@vip/ui/theme-provider';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { LayoutUtilsService } from '@vip/core';
import { Router } from '@angular/router';

@Component({
  selector: 'vip-screen-title',
  templateUrl: './screen-title.component.html',
  styleUrls: ['./screen-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.hidden]': '!shouldVisible',
    '[class.responsive]': 'isDesktopResponsive',
    '[class.show-actions]': 'showActions',
    '[class.fixed-element]': 'fixedElement',
    '[class.overlapping-element]': 'overlappingElement',
  },
})
export class ScreenTitleComponent {
  public _rightIconColor = 'primary';
  public _leftIconColor = 'primary';
  public isMobile = this.layoutUtilsService.isMobile();
  public isDesktopResponsive = this.layoutUtilsService.isDesktopResponsive();

  @Input()
  isLoading = false;

  @Input()
  isShowDesktop = false;

  @Input()
  title = '';

  @Input()
  subtitle = '';

  @Input()
  rightIcon = '';

  @Input()
  rightText = false;

  @Input()
  leftIcon = '';

  @Input()
  leftClose = '';

  @Input() isDesktop = false;

  @Input()
  get rightIconColor(): string {
    return this.getColor(this._rightIconColor);
  }
  set rightIconColor(rightIconColor) {
    this._rightIconColor = rightIconColor;
  }

  @Input()
  get leftIconColor(): string {
    return this.getColor(this._leftIconColor);
  }
  set leftIconColor(leftIconColor) {
    this._leftIconColor = leftIconColor;
  }

  @Input()
  customActions = false;

  @Input()
  showSearchButton = false;

  @Input()
  showActions = false;

  @Input()
  fixedElement = false;

  @Input()
  overlappingElement = false;

  @Output() leftIconClick = new EventEmitter();
  @Output() rightIconClick = new EventEmitter();
  @Output() goBackClicked = new EventEmitter<void>();
  @Output() closeClicked = new EventEmitter<void>();

  notShowingImage = false;
  imageLoadComplete = false;

  get shouldVisible(): boolean {
    return this.isMobile || this.isShowDesktop;
  }

  constructor(
    private themeService: ThemeService,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  onLeftIconClick() {
    this.leftIconClick.emit();
  }

  onRightIconClick() {
    this.rightIconClick.emit();
  }

  getColor(color: string) {
    if (color && color[0] === '#') {
      return color;
    }
    return `var(--vip-color-${this.themeService.camelToDash(color)}-main)`;
  }

  handleSearchClick(event: Event) {
    this.router.navigateByUrl('/busca');
  }
}
