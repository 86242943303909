<div
  class="card-basico vip-border rounded block"
  [class]="'card-basico--' + padding + '-padding'"
  (click)="handleCardClick($event)"
  vipRipple
  [rippleDisabled]="disableRipple"
  [ngClass]="{
    'vip-border': cardBasicBorder,
    'card-basico--desktop-padding': !isDesktop,
    'card-basico--desktop-padding__sub-padding': useSubPadding && isDesktop,
    'card-basico--no-padding': noPadding,
    'card-basico--h-full': contentFull,
    'with-button': !!button && buttonType === 'normal'
  }"
>
  <div
    class="card-basico-header flex items-center"
    [class.title]="addTitle"
    (click)="accordion ? (expanded = !expanded) : $event.preventDefault()"
  >
    <i
      *ngIf="icon"
      class="mr-2 text-{{ titleSize === 'sm' ? 'base' : 'xl' }} {{ icon }} {{
        addTitle ? 'icon-with-title' : ''
      }}"
    ></i>
    <ng-content select="[titleImage]"></ng-content>
    <p class="inline-block self-end" [ngClass]="'text-' + titleSize">
      {{ title }}
    </p>
    <span
      [attr.data-cy]="'quantity'"
      *ngIf="quantity"
      class="text-xs ml-auto vip-color-secondary-default"
    >
      {{ quantity }}
    </span>
    <i
      [ngClass]="{
        'card-basico-icon vip-color-third-default icon-launch text-md cursor-pointer ml-auto':
          launchIcon
      }"
      (click)="launchClick.emit()"
    ></i>
    <i
      *ngIf="accordion"
      class="icon-expand_more text-lg opacity-50 ml-auto"
      [@rotate]="!expanded ? 'default' : 'rotated'"
    ></i>

    <vip-button
      *ngIf="sideButton"
      class="vip-side-btn ml-auto mt-1"
      type="normal"
      size="small"
      (btnClick)="sideButtonClick.emit($event)"
      [customSize]="true"
    >
      <span class="text-xs vip-color-secondary-main vip-side-btn-text">
        {{ sideButton }}
      </span>
    </vip-button>
  </div>

  <!--  Conteudo sem accordion -->
  <div
    *ngIf="!accordion"
    class="wrap-content"
    [ngClass]="{ 'h-full': contentFull }"
  >
    <hr *ngIf="showHeaderDivider" class="mt-4 -mx-4" />
    <ng-content></ng-content>
  </div>

  <!--  Conteudo com accordion e dois contents atribuidos no pai -->
  <div
    [@contentExpansion]="expanded ? 'expanded' : 'collapsed'"
    class="wrap-accordion"
  >
    <ng-content select="[expandedContent]"></ng-content>
  </div>
  <ng-content select="[alwaysShowContent]"></ng-content>

  <vip-button
    *ngIf="button"
    [type]="buttonType"
    size="small"
    (btnClick)="handleButtonClick($event)"
    class="card-basico-button"
  >
    <i
      class="mr-1 text-xl {{ buttonIcon }}"
      [ngClass]="{ 'vip-color-secondary-main': buttonType === 'normal' }"
      *ngIf="buttonIcon"
    ></i>
    <span
      class="font-medium card-basico-button-text"
      [ngClass]="{
        'text-xs vip-color-secondary-main': buttonType === 'normal'
      }"
    >
      {{ button }}
    </span>
  </vip-button>
</div>
