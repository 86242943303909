import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { LayoutUtilsService } from '@vip/core';

@Component({
  selector: 'vip-simple-text-form',
  templateUrl: './simple-text-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleTextFormComponent implements OnInit {
  @Input() title = '';
  @Input() fieldRows = 1;
  @Input() fieldMaxLength?: number;
  @Input() fieldPlaceholder?: string;
  @Input() fieldValue?: string;
  @Input() autofocus = false;
  @Input() isDesktop = false;

  @Output() confirmClick = new EventEmitter<string>();

  formGroup!: UntypedFormGroup;
  isMobile = true;
  validations = {
    field: {
      required: 'Preenchimento obrigatório',
    },
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private layoutUtilsService: LayoutUtilsService
  ) {
    this.formGroup = this.formBuilder.group({
      field: ['', [Validators.required]],
    });
    this.isMobile = this.layoutUtilsService.isMobile();
  }

  ngOnInit(): void {
    this.formGroup.patchValue({ field: this.fieldValue });
  }

  confirmarClick() {
    const { field } = this.formGroup.controls;
    this.formGroup.markAllAsTouched();

    if (field.valid) {
      this.confirmClick.emit(field.value);
      field.reset();
    }
  }
}
