<vip-card-basico
  [disableRipple]="true"
  [noPadding]="true"
  [cardBasicBorder]="false"
  [addTitle]="true"
  titleSize="sm"
  icon="icon-cashback"
  title="Cashback disponível"
  [isDesktop]="isDesktop"
  [ngClass]="{ 'vip-cashback-ativo': valorCashback && editable }"
>
  <div class="vip-saldo-cashback">
    <div class="flex items-center justify-between ml-6 relative">
      <p class="text-xs vip-color-first-default pt-[3px]" id="saldo-cashback">
        {{ saldoCashback | currency: 'BRL' }} | Atualizado às
        <span>{{ date | date: 'H:mm' }}</span>
      </p>
      <div class="h-[30px] absolute flex-col justify-end items-center right-0">
        <vip-toggle
          class=""
          (toggle)="toggle()"
          [checked]="checkedCashback"
          [isDesktopView]="true"
          [disabled]="!saldoCashback || !editable"
        ></vip-toggle>
      </div>
    </div>
    <div
      class="grid gap-4 grid-cols-1"
      *ngIf="isToggleEnabled && (!(valorCashback && editable) || showInput)"
    >
      <div class="grid gap-4 grid-cols-1">
        <form [formGroup]="form" class="grid text-left">
          <h3 class="my-3 text-xs vip-color-first-default">
            Digite o valor que deseja utilizar do saldo cashback:
          </h3>
          <div class="flex gap-3 items-center">
            <vip-field
              [error]="
                form.controls['saldo'].invalid &&
                (form.controls['saldo'].touched || form.controls['saldo'].dirty)
              "
              [message]="validations"
              [validations]="form.controls['saldo'].errors"
              class="flex-grow"
              [ngClass]="{ 'mb-2': form.controls['saldo'].errors }"
            >
              <input
                currencyMask
                formControlName="saldo"
                id="inputSaldo"
                type="text"
              />
            </vip-field>
            <vip-button
              (btnClick)="alterarValorCashback()"
              data-cy="btn-salvar-cashback"
            >
              <span>Ativar</span>
            </vip-button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="ml-6" *ngIf="cashbackAplicado && editable && !showInput">
    <div class="vip-valor-cashback-wrapper">
      <div class="flex-1">
        <p class="text-sm vip-color-success-main font-medium">
          {{ cashbackAplicado | currency: 'BRL' }} aplicado
        </p>
      </div>
      <vip-button
        (btnClick)="editableCashBack()"
        data-cy="btn-alterar"
        size="small"
        type="normal"
        [noPadding]="true"
      >
        <span class="text-sm vip-color-secondary-main underline font-medium"
          >Editar</span
        >
      </vip-button>
    </div>
  </div>
</vip-card-basico>
