import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CupomEffects } from './+state/cupom.effects';
import { CupomFacade } from './+state/cupom.facade';
import * as fromCupom from './+state/cupom.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCupom.CUPOM_FEATURE_KEY, fromCupom.reducer),
    EffectsModule.forFeature([CupomEffects]),
  ],
  providers: [CupomFacade],
})
export class CupomStateModule {}
