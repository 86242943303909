<vip-simular-frete
  [ngClass]="{
    'vip-page-content': (isDesktop$ | async) === false || !isDesktopDevice
  }"
  [pagina]="pagina"
  [tiposEntregas]="(tiposEntregas$ | async) || []"
  [carousel]="banners$ | async"
  [isDesktop]="(isDesktop$ | async) ?? false"
  [isDesktopDevice]="isDesktopDevice"
  [isMobile]="isMobile"
  (confirm)="getFretesPorCep($event)"
  (btnLimpar)="limparTiposEntrega()"
  (btnFiltrarTiposEntrega)="filtrarTiposEntrega($event)"
></vip-simular-frete>
