import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { MessageType, PositionType } from '../services/message.interface';

@Directive()
export abstract class MessageBaseDirective {
  private _type: MessageType = 'info';
  private _icon = '';
  private _position: PositionType = 'top';
  protected _totalOpenMessages = 0;
  title?: string;
  subtitle?: string;
  isIos = false;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}
  @Input()
  isDesktop = false;

  @Input()
  scrolling = false;

  @Output()
  messageClick = new EventEmitter<void>();

  @Output()
  closeClick = new EventEmitter<void>();

  @HostListener('click', ['$event'])
  onClick(event: PointerEvent & { srcElement: HTMLElement }) {
    const { srcElement } = event;
    event.preventDefault();
    if (srcElement.className.includes('icon-close')) {
      return;
    }
    this.messageClick.emit();
  }

  get type(): MessageType {
    return this._type;
  }

  @Input()
  set type(value: MessageType) {
    if (value !== this._type) {
      this._type = value;
    }
  }

  get icon(): string {
    return this._icon;
  }

  @Input()
  set icon(value: string) {
    this._icon = value;
  }

  get position(): PositionType {
    return this._position;
  }

  @Input()
  set position(value: PositionType) {
    if (value !== this._position) {
      this._position = value;
    }
  }

  get totalOpenMessages(): number {
    return this._totalOpenMessages;
  }

  @Input()
  set totalOpenMessages(value: number) {
    if (value !== this._totalOpenMessages) {
      this._totalOpenMessages = value;
      let componentSpaceSize = 0;
      if (!this.isDesktop) {
        componentSpaceSize = (this.isIos ? 50 : 0) + 10 + 35 * (value * 2);
      } else {
        componentSpaceSize = 80 + 35 * (value * 2);
        if (!this.scrolling) componentSpaceSize += 50;
      }
      const size = componentSpaceSize;

      this.renderer.setStyle(this.elRef.nativeElement, 'top', size + 'px');
    }
  }
}
