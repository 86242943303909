import { Pipe, PipeTransform } from '@angular/core';
import { IProduto, TipoOfertaEnum } from '@vip/core';
@Pipe({ name: 'produtoPreco' })
export class ProdutoPrecoPipe implements PipeTransform {
  transform(produto: IProduto): number {
    return produto.oferta
      ? produto.oferta.tipo_oferta_id === TipoOfertaEnum.OFERTA_ATACAREJO
        ? produto.oferta.preco_oferta
        : produto.oferta.menor_preco
      : produto.preco;
  }
}
