import { Directive, Inject, Input, PLATFORM_ID } from '@angular/core';
import { ITheme } from '@vip/core';
import { ThemeService } from '../service/theme.service';
import { isPlatformBrowser } from '@angular/common';

/**
 * @deprecated esta diretiva não é mais necessária, no aplicativo o theme é setado
 * pelo serviço ThemeService na inicialização, precisamos manter ela, pois é usada
 * para alterar o theme no storybook
 */
@Directive({
  selector: '[vipTheme]',
})
export class ThemeProviderDirective {
  constructor(
    private themeService: ThemeService,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>
  ) {}

  @Input()
  set vipTheme(value: ITheme) {
    if (isPlatformBrowser(this.platformId)) {
      this.themeService.setupTheme(value);
    }
  }
}
