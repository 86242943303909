<div class="flex flex-col flex-grow">
  <vip-screen-title
    [title]="manualTitle || title"
    leftIcon="icon-arrow_back"
    leftIconColor="secondary"
    [rightIcon]="isNew || endereco?.principal ? '' : 'icon-delete_forever'"
    rightIconColor="error"
    (rightIconClick)="onDelete()"
  >
  </vip-screen-title>

  <div
    class="flex items-center justify-between mb-2"
    *ngIf="isDesktop && !entregaRetiradaTemplate && !isModalCompletarCadastro"
  >
    <span class="block text-lg font-bold vip-color-first-default-main">
      {{ manualTitle || title }}
    </span>
    <vip-button-icon-rounded
      type="normal"
      data-cy="close"
      (btnClick)="closeClicked.emit()"
      class="mr-[-15px]"
    >
      <i class="icon-close text-[#2F3138]"></i>
    </vip-button-icon-rounded>
  </div>

  <div class="pb-4 flex justify-center" *ngIf="entregaRetiradaTemplate">
    <h2 class="vip-color-primary-main text-lg font-bold">
      {{ manualTitle || title }}
    </h2>
  </div>

  <div
    class="flex flex-col flex-grow"
    [ngClass]="{
      'mt-0 mx-0': isDesktop,
      'mt-3 mx-3': !isDesktop,
      'endereco-max-height overflow-y-auto overflow-x-hidden custom-scrollbar':
        isModalDesktop
    }"
  >
    <div class="flex-grow flex flex-col">
      <vip-form-endereco
        #formEnderecoComponent
        [form]="form"
        [novoUsuario]="!possuiEnderecoCadastrado"
        [naoPossuiEndereco]="naoPossuiEndereco"
        [possuiEnderecoPrincipalCadastrado]="possuiEnderecoPrincipalCadastrado"
        [novoEndereco]="novoEndereco"
        [isEnderecoPrincipal]="isEnderecoPrincipal"
        [isModalCompletarCadastro]="isModalCompletarCadastro"
      ></vip-form-endereco>
    </div>
    <vip-button
      *ngIf="!isDesktop"
      class="py-3"
      type="raised"
      (click)="!loading && onSave()"
      [disabled]="loading"
    >
      Salvar
    </vip-button>

    <div
      [ngClass]="{
        contents: !showDeleteButton,
        'flex justify-between': showDeleteButton
      }"
    >
      <vip-button
        *ngIf="showDeleteButton"
        class="pt-4 vip-button"
        type="normal"
        (btnClick)="deleteClick.emit()"
      >
        <span class="vip-color-error-main">Deletar</span>
      </vip-button>
      <div
        class="flex space-x-4"
        *ngIf="isDesktop && !isModalCompletarCadastro"
        [ngClass]="{
          'justify-end': !entregaRetiradaTemplate,
          'justify-between': entregaRetiradaTemplate
        }"
      >
        <vip-button
          class="pt-4 vip-button vip-button-medium rounded"
          [ngClass]="{ 'w-full': entregaRetiradaTemplate }"
          type="outline"
          (btnClick)="closeClicked.emit()"
          [disabled]="loading"
        >
          Cancelar
        </vip-button>

        <vip-button
          class="pt-4"
          [ngClass]="{ 'w-full': entregaRetiradaTemplate }"
          type="raised"
          (click)="!loading && onSave()"
          [disabled]="loading"
        >
          Salvar
        </vip-button>
      </div>
    </div>
  </div>
</div>
