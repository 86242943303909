import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ThemeService } from '@vip/ui/theme-provider';
import { SearchEvent } from '@vip/ui/search';
import { VipThemeComponents } from '@vip/core';

@Component({
  selector: 'vip-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements AfterViewInit {
  @Input() logo!: string;
  @Input() defaultImageUrl!: string;
  @Input() fallbackImageUrl?: string;
  @Input() hiddenLogoOnError = false;
  @Input() flutuante = true;
  @Input() isLoading = false;
  @Input() hideSearchIcon = false;

  @Output() searchClick = new EventEmitter();
  private readonly defaultTypes = 'type_1';
  public type = this.defaultTypes;
  private searchValue = '';
  notShowingImage = false;
  imageLoadComplete = false;

  constructor(
    private cd: ChangeDetectorRef,
    private themeService: ThemeService
  ) {}

  private _headerTheme!: VipThemeComponents['header'];

  get headerTheme(): VipThemeComponents['header'] {
    return this._headerTheme;
  }

  @Input()
  set headerTheme(value: VipThemeComponents['header']) {
    if (value !== this._headerTheme) {
      this._headerTheme = value;
      this.setupThemeSettings();
    }
  }

  ngAfterViewInit(): void {
    this.setupThemeSettings();
  }

  public setupThemeSettings = () => {
    this.type =
      this.headerTheme?.type ||
      this.themeService.theme.component?.header?.type ||
      this.defaultTypes;

    this.cd.detectChanges();
  };

  handleSearchEvent(event: SearchEvent) {
    event.stopPropagation();
    this.searchValue = event.value;
    this.searchClick.emit(event);
  }

  handleSearchClick(event: Event) {
    event.stopPropagation();
    const newEvent = new SearchEvent('click', '', event);
    this.searchClick.emit(newEvent);
  }
}
