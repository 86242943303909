import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ScrollableService } from '@vip/core';
import { RippleModule } from '@vip/ui/ripple';
import { TabGroupDesktopComponent } from './tab-group-desktop/tab-group-desktop.component';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { TabItemHorizontalComponent } from './tab-item-horizontal/tab-item-horizontal.component';
import { TabItemComponent } from './tab-item/tab-item.component';
import { TabComponent } from './tab/tab.component';
import { TabSubItemComponent } from './tab-sub-item/tab-sub-item.component';
import { TabGroupDesktopSkeletonComponent } from './tab-group-desktop-skeleton/tab-group-desktop-skeleton.component';
import { SkeletonModule } from '@vip/ui/skeleton';

@NgModule({
  imports: [CommonModule, RippleModule, SkeletonModule],
  declarations: [
    TabItemComponent,
    TabGroupComponent,
    TabComponent,
    TabGroupDesktopComponent,
    TabItemHorizontalComponent,
    TabSubItemComponent,
    TabGroupDesktopSkeletonComponent,
  ],
  exports: [
    TabItemComponent,
    TabGroupComponent,
    TabComponent,
    TabGroupDesktopComponent,
    TabItemHorizontalComponent,
    TabSubItemComponent,
  ],
  providers: [ScrollableService],
})
export class TabsModule {}
