import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ClassificacaoMercadologicaEffects } from './+state/classificacao-mercadologica.effects';
import * as fromClassificacaoMercadologica from './+state/classificacao-mercadologica.reducer';
import { ClassificacaoMercadologicaFacade } from './+state/classificacao-mercadologica.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromClassificacaoMercadologica.CLASSIFICACAO_MERCADOLOGICA_FEATURE_KEY,
      fromClassificacaoMercadologica.reducer
    ),
    EffectsModule.forFeature([ClassificacaoMercadologicaEffects]),
  ],
  providers: [ClassificacaoMercadologicaFacade],
})
export class ClassificacaoMercadologicaStateModule {}
