import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseApiService } from '../common/base-api-service';
import {
  GetIframeResponse,
  GetSlideDetalhesResponse,
} from './iframes-api.service.types';

@Injectable({
  providedIn: 'root',
})
export class IframesApiService {
  constructor(private api: BaseApiService<IframesApiService>) {}

  getAll(
    filialId: number,
    cdId: number,
    dispositivo: 'A' | 'S' = 'A'
  ): Observable<GetIframeResponse> {
    const route = `/filial/${filialId}/centro_distribuicao/${cdId}/loja/injecao_iframe/${dispositivo}/listar`;
    return this.api.get<GetIframeResponse>('getAll', route);
  }

  getIframeById(
    filialId: number,
    cdId: number,
    iframeId: number
  ): Observable<GetSlideDetalhesResponse> {
    const route = `/filial/${filialId}/centro_distribuicao/${cdId}/loja/injecao_iframe/${iframeId}`;
    return this.api.get<GetSlideDetalhesResponse>('getIframeById', route);
  }
}
