<div
  class="vip-tab-sub-item"
  (click)="onTabClick()"
  [ngClass]="{ selected: selected }"
>
  <div class="flex items-center">
    <i
      *ngIf="icon"
      class="mr-2 no-line-height {{ iconColor ? iconColor : color }}"
      [ngClass]="[icon]"
    ></i>
    <span class="text-xs no-line-height label {{ color }}">
      {{ label }} <b>{{ count }}</b>
    </span>
  </div>
  <i
    *ngIf="selected"
    class="icon-chevron_right ml-3 no-line-height items-center"
  ></i>
</div>
