import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Router } from '@angular/router';
import { DialogService, ModalService } from '@vip/ui/modal';
import { CodeScannerService } from '@vip/native/code-scanner';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class BackButtonService {
  app = App;
  routeHandler: { [route: string]: () => void } = {};

  constructor(
    private router: Router,
    private location: Location,
    private modalService: ModalService<unknown>,
    private dialogService: DialogService,
    private codeScannerService: CodeScannerService
  ) {}

  registerListener() {
    this.app.addListener('backButton', () => this.onBackButton());
  }

  onBackButton() {
    const route = this.getCurrentRoute();
    if (this.codeScannerService.isScanning()) {
      return this.codeScannerService.exitScan();
    }

    if (this.dialogService.isOpened()) {
      return this.dialogService.clearIfAllowed();
    }

    if (this.modalService.isOpened()) {
      return this.modalService.clearIfAllowed();
    }

    if (route === '/home' || route === '/loja') {
      return this.app.exitApp();
    }

    if (this.routeHandler[route]) {
      return this.routeHandler[route]();
    }
    return this.location.back();
  }

  getCurrentRoute(): string {
    const currentRoute = this.router.url.split('?')[0];
    return currentRoute;
  }

  addRouteHandler(route: string, callback: () => void) {
    this.routeHandler[route] = callback;
  }
  removeRouteHandler(route: string) {
    delete this.routeHandler[route];
  }
}
