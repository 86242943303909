import { LOADING_FEATURE_KEY, LoadingState } from './loading.reducer';
import { SelectorUtil } from '@vip/state/utils';
import { createSelector } from '@ngrx/store';

class LoadingSelectors extends SelectorUtil<LoadingState> {
  constructor() {
    super(LOADING_FEATURE_KEY);
  }

  getTextLoading = () =>
    createSelector(
      this.createFeatureSelector,
      (state: LoadingState) => state.data.textLoading
    );
}

export const loadingSelectors = new LoadingSelectors();
