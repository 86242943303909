import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { CentroDistribuicaoService } from '@vip/api';
import {
  ICentroDistribuicao,
  IEndereco,
  TipoExibicaoRetiradaEnum,
} from '@vip/core';
import { combineLatest, Observable } from 'rxjs';

import * as CentroDistribuicaoActions from './centro-distribuicao.actions';
import { centrosDistribuicaoSelectors } from './centro-distribuicao.selectors';
import { FilialFacade } from '@vip/state/filial';
import { filter, map } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class CentroDistribuicaoFacade {
  private readonly _tipoExibicaoRetirada = TipoExibicaoRetiradaEnum;
  cd$ = this.store.pipe(
    select(centrosDistribuicaoSelectors.getCdSelecionado()),
    filter((cd): cd is ICentroDistribuicao => cd !== null && cd !== undefined)
  );
  loading$ = this.store.pipe(select(centrosDistribuicaoSelectors.isLoading()));
  isLoaded$ = this.store.pipe(select(centrosDistribuicaoSelectors.isLoaded()));
  error$ = this.store.pipe(select(centrosDistribuicaoSelectors.getError()));
  todosCds$ = this.store.pipe(
    select(centrosDistribuicaoSelectors.getCentrosDistribuicao())
  );
  cdConfirmado$ = this.store.pipe(
    select(centrosDistribuicaoSelectors.isConfirmado())
  );
  filialECdSelecionado$ = combineLatest([this.filialFacade.filial$, this.cd$]);
  possuiLojaAutonoma$ = this.store.pipe(
    select(centrosDistribuicaoSelectors.possuiLojaAutonoma())
  );
  isLojaAutonoma$ = this.store.pipe(
    select(centrosDistribuicaoSelectors.isLojaAutonoma())
  );
  enderecoRetiradaCdSelecionado$ = this.cd$.pipe(
    map((cd) => {
      let endereco!: IEndereco;

      switch (cd.tipo_exibicao_retirada) {
        case this._tipoExibicaoRetirada.ENDERECO:
          endereco = {
            ...cd.endereco,
            titulo: `${cd.endereco.logradouro}, ${cd.endereco.numero}`,
            endereco_formatado: `${cd.endereco.logradouro}, ${cd.endereco.numero}`,
            desativado: false,
            id: 0,
            principal: false,
          };
          return endereco;
        case this._tipoExibicaoRetirada.OBSERVACAO:
          endereco = {
            ...cd.endereco,
            titulo: cd.observacao_retirada,
            endereco_formatado: cd.observacao_retirada,
            desativado: false,
            id: 0,
            principal: false,
          };
          return endereco;
        default:
          return null;
      }
    })
  );
  migrarCDResponseSuccess$ = this.actions$.pipe(
    ofType(CentroDistribuicaoActions.migrarCentroDistribuicaoSuccess)
  );
  setCentroDistribuicaoDoCarrinhoSuccess$ = this.actions$.pipe(
    ofType(CentroDistribuicaoActions.setCentroDistribuicaoDoCarrinhoSuccess)
  );

  constructor(
    private store: Store,
    private centroDistribuicaoService: CentroDistribuicaoService,
    private filialFacade: FilialFacade,
    private actions$: Actions
  ) {}

  getCentroDistribuicaoRetirada(cep?: string) {
    this.store.dispatch(
      CentroDistribuicaoActions.getCentrosDistribuicao({ cep })
    );
  }

  getCentroDistribuicaoEntrega(
    filiaId: number,
    cep: string
  ): Observable<ICentroDistribuicao[]> {
    return this.centroDistribuicaoService.getCdsEntrega(filiaId, cep);
  }

  migrarCentroDistribuicao(cdAtualId: number, novoCd: ICentroDistribuicao) {
    this.store.dispatch(
      CentroDistribuicaoActions.migrarCentroDistribuicao({
        cdAtualId: cdAtualId,
        novoCd: novoCd,
      })
    );
  }

  getCentroDistribuicaoById(cdId: number) {
    this.store.dispatch(
      CentroDistribuicaoActions.getCentroDistribuicaoById({ cdId })
    );
  }

  setCentroDistribuicaoSelecionado(cd: ICentroDistribuicao) {
    this.store.dispatch(
      CentroDistribuicaoActions.setCentroDistribuicaoSelecionado({ cd })
    );
  }

  confirmarCd() {
    this.store.dispatch(
      CentroDistribuicaoActions.confirmarCentrosDistribuicao()
    );
  }

  validarCentroDistribuicaoAutonomo(cdId: number) {
    this.store.dispatch(
      CentroDistribuicaoActions.validarCentroDistribuicaoAutonomo({ cdId })
    );
  }

  setCentroDistribuicaoDoCarrinho(cdId: number) {
    this.store.dispatch(
      CentroDistribuicaoActions.setCentroDistribuicaoDoCarrinho({ cdId })
    );
  }
}
