<vip-screen-title [title]="title" [customActions]="true">
  <vip-button-icon-rounded
    type="outline"
    class="z-30 pl-1"
    (btnClick)="pageService.closePage()"
  >
    <i class="vip-color-secondary-main icon-close"></i>
  </vip-button-icon-rounded>
</vip-screen-title>
<div class="flex flex-col flex-grow m-3 break-words">
  <div
    class="text-sm"
    data-cy="page-text"
    [innerHtml]="sanitizer.bypassSecurityTrustHtml(text)"
  ></div>
</div>
