import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  IApplicationMenuItem,
  IPaginaInstitucional,
  IBreadCrumb,
} from '@vip/core';

@UntilDestroy()
@Component({
  selector: 'vip-menu-content',
  templateUrl: './menu-content.component.html',
  styleUrls: ['./menu-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuContentComponent {
  @Input() title!: string;
  @Input() paginas: IPaginaInstitucional[] = [];

  @Input() menuItens!: IApplicationMenuItem[];
  @Input() username?: string;
  @Input() subtitle!: string;
  @Input() activeMenuTitle!: string;
  @Input() breadcrumbs!: Array<IBreadCrumb>;
  @Input() hiddenMainCard = false;
  @Input() showBorder = false;
  @Input() noPaddingLeft = false;
  @Input() exibeCashback = false;
  @Input() saldoCashback = 0;
  @Input() saldoCashbackError!: string | null;
  @Input() notificationsCount = 0;
  @Input() cashbackIsLoaded!: boolean | null;

  @Output()
  itemMenuClick = new EventEmitter<IApplicationMenuItem>();

  constructor(private ngZone: NgZone, private router: Router) {}

  onMenuItemClick(item: IApplicationMenuItem): void {
    this.itemMenuClick.emit(item);
  }

  onBreadCrumbClick(link: string): void {
    this.ngZone.run(() => {
      this.router.navigateByUrl(link);
    });
  }
}
