import { Injectable } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IPerguntaSobreCompra } from '@vip/core';

@Injectable({
  providedIn: 'root',
})
export class PerguntasService {
  generateFormGroupByPerguntas(
    perguntas: IPerguntaSobreCompra[]
  ): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({});
    perguntas.forEach((pergunta) => {
      formGroup.addControl(
        pergunta.id.toString(),
        new FormControl(
          pergunta.cliente_pergunta_resposta?.resposta || null,
          pergunta.obrigatoria ? [Validators.required] : []
        )
      );
    });
    return formGroup;
  }
}
