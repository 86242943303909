import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { EnderecoStateModule } from '@vip/state/endereco';
import { EnderecoContainerComponent } from './endereco-container.component';
import { EnderecoModule } from '@vip/views/endereco';
import { CepStateModule } from '@vip/state/cep';
import { ModalModule } from '@vip/ui/modal';
import { MessageModule } from '@vip/ui/message';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: EnderecoContainerComponent,
      },
    ]),
    EnderecoStateModule,
    CepStateModule,
    EnderecoModule,
    ModalModule,
    MessageModule,
  ],
  declarations: [EnderecoContainerComponent],
})
export class EnderecoContainerModule {}
