<ng-container *ngIf="filterItem">
  <ng-container
    *ngIf="
      filterItem.options.length && filterItem.name === 'tag';
      else allFilters
    "
  >
    <ng-container *ngFor="let tagTipo of filterItem.formatedTags">
      <div
        class="mb-2 vip-color-first-default text-sm font-medium"
        [ngClass]="{ 'mt-3': tagTipo === '' }"
      >
        {{ tagTipo || '' }}
      </div>
      <ng-container *ngIf="filterItem.multiple">
        <ng-container *ngFor="let filterItemOption of filterItem.options">
          <vip-checkbox
            *ngIf="
              filterItemOption.tipo === tagTipo ||
              (filterItemOption.tipo === null && tagTipo === '')
            "
            [label]="getFilterOptionLabel(filterItemOption)"
            [(checkboxValue)]="!!filterItemOption.checked"
            size="sm"
            labelSize="md"
            [class.invisible]="!!filterItemOption.hidden"
            [class.max-h-0]="!!filterItemOption.hidden"
            [class.mb-1]="!filterItemOption.hidden"
            (checkboxValueChange)="filterItemChange.emit(filterItem)"
          >
          </vip-checkbox>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #allFilters>
    <div class="mb-2 vip-color-first-default text-sm font-bold">
      {{ filterItem.label }}
    </div>
    <div class="mb-1">
      <ng-container *ngIf="filterItem.multiple">
        <vip-checkbox
          *ngFor="let filterItemOption of getFilterOptions()"
          [label]="getFilterOptionLabel(filterItemOption)"
          [(checkboxValue)]="!!filterItemOption.checked"
          size="sm"
          labelSize="md"
          [class.invisible]="!!filterItemOption.hidden"
          [class.max-h-0]="!!filterItemOption.hidden"
          [class.mb-1]="!filterItemOption.hidden"
          (checkboxValueChange)="filterItemChange.emit(filterItem)"
        >
        </vip-checkbox>
      </ng-container>
      <ng-container *ngIf="!filterItem.multiple">
        <vip-radio-group
          direction="vertical"
          [wrap]="false"
          [visible]="true"
          (valueChange)="filterItemChange.emit(filterItem)"
        >
          <vip-radio-list-item
            *ngFor="let filterItemOption of getFilterOptions(); let i = index"
            [(checked)]="!!filterItemOption.checked"
            [value]="filterItemOption.value"
            [class.invisible]="!!filterItemOption.hidden"
            [class.max-h-0]="!!filterItemOption.hidden"
            [label]="getFilterOptionLabel(filterItemOption)"
            [invisible]="i > qtdMaxVisibleItens - 1 && !expanded"
          >
          </vip-radio-list-item>
        </vip-radio-group>
      </ng-container>
      <div
        role="button"
        data-cy="btn-expand"
        *ngIf="getVisibleOptions().length > qtdMaxVisibleItens"
        class="flex w-full my-1 justify-center cursor-pointer"
        (click)="toggleExpanded()"
      >
        <label
          class="vip-color-secondary-default text-sm font-bold cursor-pointer"
          >{{ expanded ? 'Ver menos' : 'Ver mais' }}</label
        >
        <i
          class="icon-expand_more mx-1 my-auto transition-transform"
          [class.rotate-180]="expanded"
        ></i>
      </div>
    </div>
  </ng-template>
</ng-container>
