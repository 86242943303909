<vip-card-basico
  *ngIf="editable || (cupons && cupons.length > 0)"
  icon="icon-local_activity"
  title="Cupons de Desconto"
  [disableRipple]="isRippleDisabled"
  [button]="
    (editable && cupons && cupons.length > 0) || codigoPromocional?.codigo
      ? 'Adicionar Cupom'
      : ''
  "
  (cardClick)="handleCardClick()"
  (buttonClick)="escolherClick.emit()"
>
  <ng-container
    *ngIf="
      (!cupons || cupons.length === 0) && !codigoPromocional?.codigo;
      else cuponsTemplate
    "
  >
    <span class="text-xs"> Selecionar cupom de desconto </span>
  </ng-container>
  <ng-template #cuponsTemplate>
    <div *ngFor="let cupom of cupons; let last = last">
      <div class="flex text-xs items-center font-medium">
        <div class="flex-1">
          <div
            data-cy="cupom-ativado"
            *ngIf="cupom.tipo_desconto && cupom.codigo"
            class="vip-color-success-main mb-0.5"
          >
            Código {{ cupom.codigo }} Ativado
          </div>
          <div
            data-cy="cupom-ativado"
            *ngIf="cupom.cupom_motivo"
            class="vip-color-success-main mb-0.5"
          >
            Cupom Ativado
          </div>
          <div data-cy="cupom-valor">
            <span *ngIf="cupom.cupom_motivo">{{
              cupom.valor | currency: 'BRL'
            }}</span>
            <span *ngIf="cupom.tipo_desconto">
              {{ cupom.total | currency: 'BRL' }}
              <span *ngIf="cupom.desconto === tipoCupom.PORCENTAGEM"
                >({{ cupom.valor }}%)</span
              >
            </span>
          </div>
        </div>
        <vip-button
          type="normal"
          size="ex-small"
          *ngIf="editable"
          data-cy="btn-remover"
          (btnClick)="removerClick.emit(cupom); $event.stopPropagation()"
        >
          <span class="vip-color-error-main">Remover</span>
        </vip-button>
      </div>
      <hr
        *ngIf="!last || editable"
        class="vip-divider mt-2"
        [class.mb-2]="!last"
      />
    </div>
  </ng-template>

  <div *ngIf="codigoPromocional?.codigo">
    <div class="flex text-xs items-center font-medium mt-2">
      <div class="flex-1">
        <div
          data-cy="cupom-ativado-codigo-promocional"
          class="vip-color-success-main mb-0.5"
        >
          Código {{ codigoPromocional?.codigo }} Ativado
        </div>
        <div
          *ngIf="codigoPromocionalValor"
          data-cy="cupom-valor-codigo-promocional"
        >
          <span *ngIf="codigoPromocionalValor.desconto === tipoCupom.VALOR">{{
            codigoPromocionalValor.valor | currency: 'BRL'
          }}</span>
          <span
            *ngIf="codigoPromocionalValor.desconto === tipoCupom.PORCENTAGEM"
          >
            {{ codigoPromocionalValor.total | currency: 'BRL' }}
            <span>({{ codigoPromocionalValor.valor }}%)</span>
          </span>
        </div>
      </div>
      <vip-button
        type="normal"
        size="ex-small"
        *ngIf="editable"
        data-cy="btn-remover-codigo-promocional"
        (btnClick)="
          removerCodigoPromocionalClick.emit(codigoPromocional?.codigo || '');
          $event.stopPropagation()
        "
      >
        <span class="vip-color-error-main">Remover</span>
      </vip-button>
    </div>
    <hr class="vip-divider mt-2" />
  </div>
</vip-card-basico>
