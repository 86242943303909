import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IProduto } from '@vip/core';
import { CardComboComponent } from '../card-combo/card-combo.component';

@Component({
  selector: 'vip-card-combo-mini',
  templateUrl: './card-combo-mini.component.html',
  styleUrls: ['./card-combo-mini.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComboMiniComponent extends CardComboComponent {
  @Input() produtoCombo!: IProduto;
}
