import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FilterTypeEnum, IFilter, IFilterOptions } from '@vip/core';

@Component({
  selector: 'vip-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltroComponent {
  @Input()
  loading = false;

  _filters: IFilter[] = [];
  @Input() set filters(filters: IFilter[]) {
    this._filters = filters;
    this.internalFilters = filters
      .filter((filter) => filter.type === FilterTypeEnum.INTERNAL)
      .map((filter) => {
        return {
          ...filter,
          formatedTags: this.getFormatedTags(filter.options),
        };
      });
  }

  @Output()
  clear = new EventEmitter();

  @Output()
  confirm = new EventEmitter();

  @Output()
  closeFilter = new EventEmitter();

  internalFilters: Array<IFilter & { formatedTags: string[] }> = [];

  getFormatedTags(options: IFilterOptions[]): string[] {
    const tipos = new Array<string>();

    options.forEach((value) => {
      if (value.tipo && !tipos.includes(value?.tipo)) {
        tipos.push(value.tipo);
      }
    });

    tipos.push('');
    return tipos;
  }
}
