import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ISlide, ScrollableService } from '@vip/core';

@Directive()
export abstract class CarouselBaseDirective {
  constructor(
    public cd: ChangeDetectorRef,
    public scrollableService: ScrollableService
  ) {}

  @ViewChild('contentHeader')
  contentHeader: ElementRef | undefined;

  @Input() slides: ISlide[] = [];
  @Input() bannersPerPage = 1;
  @Input() isLoading = false;
}
