import { Directive, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { environment, IPaginaInstitucional, TipoPaginaEnum } from '@vip/core';
import { IframeService } from '@vip/native/iframe';

@Directive()
export abstract class ListaInstitucionalBaseDirective {
  environment = environment;

  constructor(
    protected route: Router,
    protected iframeService: IframeService,
    protected ngZone: NgZone
  ) {}

  redirectToPage(pagina: IPaginaInstitucional, isDesktop?: boolean) {
    if (pagina.tipo == TipoPaginaEnum.INTERNA) {
      if (
        pagina.slug == 'simulador-de-frete' ||
        pagina.slug == 'fale-conosco'
      ) {
        this.route.navigateByUrl('/institucional/' + pagina.slug);
      } else if (pagina.slug == 'sobre') {
        this.navigateTo('/sobre');
      } else {
        this.navigateTo('/institucional/pagina/' + pagina.slug);
      }
    } else if (pagina.tipo == TipoPaginaEnum.IFRAME) {
      if (!isDesktop) {
        this.iframeService.open(pagina.injecao_iframe?.url ?? '', {
          iframeId: pagina.injecao_iframe_id,
          isLoginRequired: pagina.injecao_iframe?.exigir_login,
        });
      } else {
        this.navigateTo('/institucional/iframe/' + pagina.injecao_iframe_id);
      }
    } else if (pagina.link) {
      this.abrirLink(pagina.link);
    }
  }

  abrirLink(link: string) {
    const rotaInternaNoApp =
      this.environment.isApp &&
      this.environment.domainKey &&
      link.includes(this.environment.domainKey);

    if (rotaInternaNoApp) {
      link = link.endsWith('/') ? link : `${link}/`;
      const matches = link.match(/(http[s]?:\/\/)?([^/\s]+\/)(.*)/);
      const route = matches && matches.length >= 4 ? matches[3] : null;

      if (route !== null) {
        this.route.navigateByUrl(route.replace(/\/$/, ''));
      }
    } else {
      this.iframeService.open(link, { navigateHomeOnClose: false });
    }
  }

  private navigateTo(url: string) {
    this.ngZone.run(() => {
      this.route.navigateByUrl(url);
    });
  }
}
