import { createSelector } from '@ngrx/store';
import {
  NOTIFICACAO_FEATURE_KEY,
  notificacaoAdapter,
  NotificacaoState,
} from './notificacao.reducer';
import { SelectorUtil } from '@vip/state/utils';
import { INotificacao } from '@vip/core';

const { selectAll } = notificacaoAdapter.getSelectors();

class NotificacaoSelectors extends SelectorUtil<NotificacaoState> {
  constructor() {
    super(NOTIFICACAO_FEATURE_KEY);
  }

  getNotificacoesArray = () =>
    createSelector(this.createFeatureSelector, (state: NotificacaoState) =>
      selectAll(state)
    );

  getQuantidadeNaoLidas = () =>
    createSelector(
      this.getNotificacoesArray(),
      (state: INotificacao[]) =>
        state.filter(
          (notificacao) =>
            notificacao.cliente_notificacoes.length &&
            !notificacao.cliente_notificacoes[0].visualizada
        ).length
    );
}

export const notificacaoSelectors = new NotificacaoSelectors();
