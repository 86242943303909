import { groupBy } from 'lodash';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  IHorariosFormatados,
  ITipoEntrega,
  TipoIntervaloEnum,
} from '@vip/core';

@Component({
  selector: 'vip-alterar-horarios',
  templateUrl: './alterar-horarios.component.html',
  styleUrls: ['./alterar-horarios.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlterarHorariosComponent implements OnInit {
  @Input() tipoEntrega?: ITipoEntrega | null;
  @Input() isLoading = false;
  @Input() horarioSelecionado?: IHorariosFormatados | null;
  @Input() isDesktop = false;
  @Input() isDesktopWithoutScreenWidth = false;
  @Output() horarioChange = new EventEmitter();
  @Output() confirmClick = new EventEmitter();

  ngOnInit() {
    if (
      this.tipoEntrega?.horarios_formatados &&
      this.tipoEntrega.horarios_formatados.length > 0
    ) {
      this.isLoading = false;
    }
  }

  getHorario(label: string) {
    let labelEntrega: string[] = [];
    if (this.tipoEntrega && label) {
      if (
        this.tipoEntrega.tipo_intervalo === TipoIntervaloEnum.SEM_INTERVALOS
      ) {
        labelEntrega = label.split('Até às ');
      } else {
        const intervalo = label.split('De ')[1];
        labelEntrega = intervalo.split(' às ');
      }
    }
    return labelEntrega;
  }

  getHorariosPorDia(horarios: IHorariosFormatados[]) {
    const dias = groupBy(horarios, (horario) => horario.date);

    return Object.keys(dias).map((dia) => ({
      data: dia,
      horarios: dias[dia],
    }));
  }
}
