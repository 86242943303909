import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from '@vip/ui/button';
import { FieldModule } from '@vip/ui/field';
import { ScreenTitleModule } from '@vip/ui/screen-title';

import { DialogComponent } from './dialog/dialog.component';
import { ModalComponent } from './modal/modal.component';
import { PageComponent } from './page/page.component';
import { PageService } from './page/services/page.service';

@NgModule({
  imports: [CommonModule, ButtonModule, FieldModule, ScreenTitleModule],
  declarations: [ModalComponent, DialogComponent, PageComponent],
  exports: [ModalComponent, DialogComponent],
  providers: [PageService],
})
export class ModalModule {}
