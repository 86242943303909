<div
  vipRipple
  [rippleDisabled]="!clickable"
  [rippleColor]="checked && clickable ? 'primary-contrast' : undefined"
  class="p-3"
>
  <section class="flex flex-col">
    <div class="flex items-center">
      <i class="mr-2 icon-local_activity text-3xl leading-none"></i>
      <div class="flex-1 text-sm">
        <ng-content></ng-content>
      </div>
      <vip-checkbox
        *ngIf="showCheckIcon"
        size="sm"
        [checkboxValue]="checked && clickable"
      ></vip-checkbox>
    </div>
    <span class="is-unique text-sm subtitle" *ngIf="eUnico"
      >Este cupom é de uso único.</span
    >
  </section>
  <div class="border-b border-solid my-2"></div>
  <section class="flex items-center">
    <div class="flex-1 text-sm end-in">
      <ng-container *ngIf="layoutUtilsService.isDesktop(); else mobileView">
        <span class="end-in-desktop">
          Termina em
          {{
            validade
              | timeToEnd
                : (exibirData ? undefined : layoutUtilsService.isDesktop())
          }}
        </span>
      </ng-container>
      <ng-template #mobileView>
        {{
          (validade | expiredDueDate)
            ? 'Expirado'
            : 'Termina em ' + (validade | timeToEnd)
        }}
      </ng-template>
    </div>
    <div *ngIf="showProgressBar" class="flex-1">
      <vip-progress-bar
        [class.is-checked]="checked && clickable"
        [class.is-disabled]="disabled"
        [value]="progressValue"
      ></vip-progress-bar>
    </div>
  </section>
</div>
