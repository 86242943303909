import { SelectorUtil } from '@vip/state/utils';
import { APLICATIVO_FEATURE_KEY, AplicativoState } from './aplicativo.reducer';
import { createSelector } from '@ngrx/store';

export class AplicativoSelectors extends SelectorUtil<AplicativoState> {
  constructor() {
    super(APLICATIVO_FEATURE_KEY);
  }

  emRevisao = () =>
    createSelector(
      this.createFeatureSelector,
      (state: AplicativoState) => !!state?.data?.revisao
    );
}

export const aplicativoSelectors = new AplicativoSelectors();
