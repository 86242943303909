import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PARAMETROS_FEATURE_KEY, ParametrosState } from './parametros.reducer';
import { SelectorUtil } from '@vip/state/utils';

export const getParametrosState = createFeatureSelector<ParametrosState>(
  PARAMETROS_FEATURE_KEY
);

class ParametrosSelectors extends SelectorUtil<ParametrosState> {
  constructor() {
    super(PARAMETROS_FEATURE_KEY);
  }

  getExigeAutenticacao = () =>
    createSelector(
      this.createFeatureSelector,
      (state: ParametrosState) => state.data?.exige_autenticacao
    );

  getMultiplosCds = () =>
    createSelector(
      this.createFeatureSelector,
      (state: ParametrosState) => state.data?.multiplos_cds
    );

  getSelecaoCdObrigatorio = () =>
    createSelector(
      this.createFeatureSelector,
      (state: ParametrosState) => state.data?.selecao_cd_obrigatorio
    );

  getPermitirCarrinhoAnonimo = () =>
    createSelector(
      this.createFeatureSelector,
      (state: ParametrosState) => state.data?.permitir_carrinho_anonimo
    );

  getLimiteTentativasPrePago = () =>
    createSelector(
      this.createFeatureSelector,
      (state: ParametrosState) => state.data?.limite_tentativas_pre_pago
    );

  getTentativasRealizadas = () =>
    createSelector(
      this.createFeatureSelector,
      (state: ParametrosState) => state.tentativasRealizadas
    );

  getParametros = () =>
    createSelector(
      this.createFeatureSelector,
      (state: ParametrosState) => state.data
    );

  getPinAtivo = () =>
    createSelector(
      this.createFeatureSelector,
      (state: ParametrosState) => state.data?.pin_ativo
    );

  getParamsAds = () =>
    createSelector(
      this.createFeatureSelector,
      (state: ParametrosState) => state.data?.ads
    );
}

export const parametrosSelectors = new ParametrosSelectors();
