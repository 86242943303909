import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ParametrosFacade } from '@vip/state/parametros';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClienteTokenService } from '@vip/state/cliente';
import { CentroDistribuicaoFacade } from '../+state/centro-distribuicao.facade';
import { LayoutUtilsService, EntregaRetiradaUtilsService } from '@vip/core';
import { CacheUtilsService } from '@vip/utils/cache';

@Injectable()
export class SelecaoCdGuard implements CanActivate {
  constructor(
    private clienteTokenService: ClienteTokenService,
    private parametrosFacade: ParametrosFacade,
    private centroDistribuicaofacade: CentroDistribuicaoFacade,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private entregaRetiradaUtilsService: EntregaRetiradaUtilsService,
    private cacheUtilsService: CacheUtilsService
  ) {}

  canActivate(): Observable<boolean> {
    if (this.clienteTokenService.getToken()) {
      return of(true);
    } else {
      return combineLatest([
        this.parametrosFacade.multiplosCds$,
        this.parametrosFacade.selecaoCdObrigatorio$,
        this.centroDistribuicaofacade.cdConfirmado$,
        this.layoutUtilsService.isDesktop$,
      ]).pipe(
        map(([multiplosCds, selecaoCdObrigatorio, cdConfirmado, isDesktop]) => {
          if (multiplosCds && selecaoCdObrigatorio && !cdConfirmado) {
            this.cacheUtilsService.checkAplicativoFilialCache();
            if (isDesktop || this.layoutUtilsService.isDesktopResponsive()) {
              this.entregaRetiradaUtilsService.openModalSolicitarCep();
              return true;
            }
            this.router.navigateByUrl('/entrega-retirada');
            return false;
          } else {
            return true;
          }
        })
      );
    }
  }
}
