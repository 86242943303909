import { Injectable } from '@angular/core';
import {
  BannerLocalEnum,
  environment,
  ICarousel,
  LayoutUtilsService,
} from '@vip/core';

import { BaseApiService } from '../common/base-api-service';
import { GetBannersResponse } from './banner.service.types';

import { pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BannerApiService {
  constructor(
    private api: BaseApiService<BannerApiService>,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  getPorLocalizacao(
    filial: number,
    cd: number,
    local: BannerLocalEnum,
    filtro: string = ''
  ): Observable<ICarousel> {
    let dispositivo = 'A';

    if (!environment.isApp) {
      dispositivo = this.layoutUtilsService.isDesktop() ? 'S' : 'R';
    }

    return this.api
      .get<GetBannersResponse>(
        'getPorLocalizacao',
        `/filial/${filial}/centro_distribuicao/${cd}/loja/banner/localizacao/${local}/dispositivo/${dispositivo}?${filtro}`
      )
      .pipe(pluck('data'));
  }

  addClique(id: number) {
    return this.api.post('addClique', `/loja/banner/add`, { id });
  }

  addCliqueHomeOmni(bannerId: number): Observable<void> {
    return this.api.post('addCliqueHomeOmni', `/loja/banner/home/add`, {
      bannerId,
    });
  }
}
