import { Pipe, PipeTransform } from '@angular/core';
import { TipoOfertaEnum } from '../../enums/tipo-oferta.enum';
import { IOferta } from '../../interfaces';

@Pipe({ name: 'offerTitle', pure: false })
export class OfferTitlePipe implements PipeTransform {
  transform(oferta?: IOferta | null, isTelaProduto = false): string {
    let label = '';

    if (oferta) {
      switch (oferta.tipo_oferta_id) {
        case TipoOfertaEnum.PRODUTO_COM_DESCONTO:
        case TipoOfertaEnum.CATEGORIA_DE_PRODUTOS:
          label = this.getTitleProdutoComDesconto(oferta);
          break;
        case TipoOfertaEnum.COMBO_DE_PRODUTOS:
          label = isTelaProduto ? 'No combo, pague:' : 'No combo';
          break;
        case TipoOfertaEnum.LEVE_E_PAGUE:
          label = `Leve ${oferta.quantidade_minima}, pague ${oferta.quantidade_pagar}`;
          break;
        case TipoOfertaEnum.OFERTA_ATACAREJO:
          label = this.getTitleAtacarejo(oferta, isTelaProduto);
          break;
      }
    }

    return label;
  }

  private getTitleProdutoComDesconto(oferta: IOferta): string {
    const desconto =
      ((oferta.preco_antigo - oferta.preco_oferta) * 100) / oferta.preco_antigo;
    const label =
      oferta.quantidade_minima == 1
        ? 'OFF'
        : `a partir da ${oferta.quantidade_minima}ª unidade`;

    return `${Math.round(desconto)}% ${label}`;
  }

  private getTitleAtacarejo(oferta: IOferta, isTelaProduto: boolean): string {
    if (!oferta.faixas_precos) return '';
    if (oferta.faixas_precos.length == 1) {
      return isTelaProduto
        ? `A partir de ${oferta.faixas_precos[0].quantidade}`
        : `a cada ${oferta.faixas_precos[0].quantidade} por`;
    }

    return '';
  }
}
