import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../common/base-api-service';
import { GetOpcaoFaturamentoResponse } from './opcao-faturamento-api.service.types';

@Injectable({
  providedIn: 'root',
})
export class OpcaoFaturamentoApiService {
  constructor(private api: BaseApiService<OpcaoFaturamentoApiService>) {}

  getOpcaoFaturamento(
    filialId: number,
    cdId: number
  ): Observable<GetOpcaoFaturamentoResponse> {
    const route = `/filial/${filialId}/centro_distribuicao/${cdId}/loja/compras/checkout/forma_faturamento`;
    return this.api.get<GetOpcaoFaturamentoResponse>(
      'getOpcaoFaturamento',
      `${route}`
    );
  }
}
