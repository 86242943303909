import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromSubstituirProduto from './+state/substituir-produto.reducer';
import { SubstituirProdutoEffects } from './+state/substituir-produto.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromSubstituirProduto.SUBSTITUIR_PRODUTO_FEATURE_KEY,
      fromSubstituirProduto.reducer
    ),
    EffectsModule.forFeature([SubstituirProdutoEffects]),
  ],
  providers: [],
})
export class SubstituirProdutoStateModule {}
