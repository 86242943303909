import { Injectable } from '@angular/core';
import { EnvironmentService, environment } from '@vip/core';
import { NativeBiometric } from 'capacitor-native-biometric';

@Injectable()
export class BiometricsService {
  nativeBiometric = NativeBiometric;

  constructor(private environmentService: EnvironmentService) {}

  isApp() {
    return environment.isApp;
  }

  async isAvailable(): Promise<boolean> {
    if (this.isApp()) {
      try {
        const result = await this.nativeBiometric.isAvailable();
        return Promise.resolve(result.isAvailable);
      } catch (error) {
        return Promise.resolve(false);
      }
    } else {
      return Promise.resolve(false);
    }
  }

  async isAvailableButNotSet(): Promise<boolean> {
    if (this.isApp()) {
      try {
        const result = await this.nativeBiometric.isAvailable();
        return Promise.resolve(
          !result.isAvailable && result.biometryType !== 0
        );
      } catch (error) {
        return Promise.resolve(false);
      }
    } else {
      return Promise.resolve(false);
    }
  }

  async login() {
    if (await this.isAvailable()) {
      return this.verifyIdentify();
    }
    return Promise.resolve(null);
  }

  async verifyIdentify(): Promise<{
    username: string;
    password: string;
  } | null> {
    const credentials = await this.getCredentials();
    if (credentials) {
      try {
        await this.nativeBiometric.verifyIdentity({
          reason: 'Para login facilitado',
          title: 'Login com biometria',
          subtitle: 'Confirme a biometria para entrar',
          negativeButtonText: 'Cancelar',
        });
        return Promise.resolve(credentials);
      } catch (error) {
        const codigoErroAutenticacao = '10';
        if ((error as { code: string }).code == codigoErroAutenticacao) {
          return this.verifyIdentify();
        } else {
          return Promise.resolve(null);
        }
      }
    }
    return Promise.resolve(credentials);
  }

  async getCredentials(): Promise<{
    username: string;
    password: string;
  } | null> {
    if (await this.isAvailable()) {
      try {
        const credentials = await this.nativeBiometric.getCredentials({
          server: this.environmentService.getHost(),
        });
        return Promise.resolve(credentials);
      } catch (error) {
        return Promise.resolve(null);
      }
    }
    return Promise.resolve(null);
  }

  async setCredentials(username: string, password: string): Promise<boolean> {
    if (await this.isAvailable()) {
      try {
        await this.nativeBiometric.setCredentials({
          username,
          password,
          server: this.environmentService.getHost(),
        });
        return Promise.resolve(true);
      } catch (error) {
        return Promise.resolve(false);
      }
    }
    return Promise.resolve(false);
  }

  async deleteCredentials(): Promise<boolean> {
    if (await this.isAvailable()) {
      try {
        await this.nativeBiometric.deleteCredentials({
          server: this.environmentService.getHost(),
        });
        return Promise.resolve(true);
      } catch (error) {
        return Promise.resolve(false);
      }
    }
    return Promise.resolve(false);
  }
}
