<div>
  <h3 class="font-medium mb-3 vip-color-first-default text-base">
    Selecionar cliente para compra
  </h3>
  <ng-container>
    <form [formGroup]="formGroup">
      <div class="mt-4">
        <vip-select
          data-cy="select-cliente"
          id="clientSelect"
          class="text-left"
          label="Filial"
          [options]="filiais"
          [isDesktop]="true"
          formControlName="filial"
          (itemSelected)="selectedFilialItem($event)"
        >
        </vip-select>

        <vip-search
          #vipSearch
          [focusOnInit]="false"
          class="w-full mt-2"
          data-cy="vip_search"
          placeholder="Pesquise por Nome/CPF/CNPJ/Celular/E-mail"
          typeInput="retangle"
          name="cliente"
          [disabled]="!formGroup.get('filial')?.value"
          (search)="handleSearchEvent($event)"
          (keyup)="handleUserKeyUp($event)"
          [isTelevendas]="isTelevendas"
        >
        </vip-search>

        <ng-container
          *ngIf="
            searchInput?.input?.nativeElement.value?.length < 3 &&
            searchInput?.input?.nativeElement.value?.length > 0
          "
        >
          <div class="mt-1 mb-1">
            <small>Mínimo de 3 caracteres para a busca</small>
          </div>
        </ng-container>
      </div>
    </form>
    <hr class="mb-3" />

    <vip-list-select
      [items]="_clientesTelevenda || []"
      (itemSelected)="selectedItem($event)"
      (openModalCep)="openModalCep()"
      (isDeskTopResponsive)="(isDesktopResponsiveRef)"
      (resetForm)="resetFormCliente()"
      [searchValue]="searchInput?.input?.nativeElement.value"
      [loadingTelevendas]="loadingTelevendas"
    >
    </vip-list-select>
  </ng-container>
</div>
