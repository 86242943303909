<ng-container
  *ngIf="modalList; then defaultTemplate; else modalCreateTemplate"
></ng-container>
<ng-template #defaultTemplate>
  <div class="text-center overflow-y-scroll h-full">
    <span class="text-base font-medium mb-3">Adicionar à Lista</span>
    <vip-empty
      *ngIf="listas.length === 0"
      image="{{ imageUrl }}list.svg"
      text="Você ainda não criou nenhuma lista de compras."
      class="my-8"
    ></vip-empty>
    <div *ngIf="listas.length" class="divide-y mb-2">
      <div
        vipRipple
        *ngFor="let lista of listas"
        class="flex items-center py-2 px-3 cursor-pointer"
        [attr.data-cy]="'lista_' + lista.lista_id"
        (click)="listaClick.emit(lista)"
      >
        <i
          class="icon-format_list_bulleted vip-color-secondary-main text-xl mr-3"
        ></i>
        <span class="text-sm">{{ lista.nome }}</span>
      </div>
    </div>
    <vip-button [hasIcon]="true" (btnClick)="modalList = false">
      <i class="icon-add"></i>
      <span>Criar nova lista</span>
    </vip-button>
  </div>
</ng-template>
<ng-template #modalCreateTemplate>
  <vip-simple-text-form
    title="Nomear lista"
    fieldPlaceholder="Nome"
    [autofocus]="true"
    (confirmClick)="novaListaClick.emit($event)"
  ></vip-simple-text-form>
</ng-template>
