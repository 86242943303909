<div
  class="flex vip-color-first-default justify-between"
  [class.mb-4]="isMobile"
  [class.mb-2]="!isMobile"
  [class.mx-3]="applySideMargins"
>
  <ng-container *ngIf="!isLoading; else skeletonTemplate">
    <strong
      class="text-lg"
      [ngClass]="{ 'text-2xl': isHomeOmni }"
      data-cy="vip-carousel-header-title"
      >{{ title }}</strong
    >
    <span (click)="showMoreClick.emit($event)" *ngIf="isMobile">
      <span
        *ngIf="quantity && !showAll"
        class="flex items-center text-sm cursor-pointer"
        data-cy="vip-carousel-header-quantity"
      >
        mais <strong class="mx-1">{{ quantity }}</strong>
        <i class="icon-chevron_right text-xl"></i>
      </span>
    </span>
    <span
      (click)="showMoreClick.emit($event)"
      *ngIf="showAll"
      class="flex items-center text-sm cursor-pointer font-medium"
      data-cy="vip-carousel-header-show-all"
    >
      ver todos
      <i class="ml-1 icon-chevron_right text-xl"></i>
    </span>
  </ng-container>
  <ng-template #skeletonTemplate>
    <ng-container
      *vipSkeleton="true; width: '190px'; height: '20px'"
    ></ng-container>
    <ng-container
      *vipSkeleton="true; width: '50px'; height: '20px'"
    ></ng-container>
  </ng-template>
</div>
