import { createAction, props } from '@ngrx/store';
import { IEndereco } from '@vip/core';
import { AddEditEnderecoRequest } from '@vip/api';
import { HttpErrorResponse } from '@angular/common/http';

export const select = createAction(
  '[Select Effects] Select Endereco',
  props<{ enderecoSelecionadoId: number | null }>()
);

export const getEnderecos = createAction('[Endereco Page] getEnderecos');

export const getEnderecosSuccess = createAction(
  '[Endereco/API] Load Endereco Success',
  props<{ enderecos: IEndereco[] }>()
);

export const getEnderecosFailure = createAction(
  '[Endereco/API] Load Endereco Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getEnderecosEntrega = createAction(
  '[Endereco Entrega Page] getEnderecosEntrega'
);

export const getEnderecosEntregaSuccess = createAction(
  '[Endereco/API] Load Endereco Entrega Success',
  props<{ enderecos: IEndereco[] }>()
);

export const getEnderecosEntregaFailure = createAction(
  '[Endereco/API] Load Endereco Entrega Failure',
  props<{ error: HttpErrorResponse }>()
);

export const saveEndereco = createAction(
  '[Endereco/Page] Save Endereco',
  props<{
    endereco: AddEditEnderecoRequest;
    urlToRedirect?: string;
  }>()
);
export const saveEnderecoSuccess = createAction(
  '[Endereco/API] Save Endereco Success',
  props<{
    endereco: IEndereco;
    message: string;
    urlToRedirect?: string;
  }>()
);
export const saveEnderecoFailure = createAction(
  '[Endereco/API] Save Endereco Failure',
  props<{ error: HttpErrorResponse }>()
);

export const deleteEndereco = createAction(
  '[Endereco/Page] Delete Endereco',
  props<{ enderecoId: number }>()
);
export const deleteEnderecoFailure = createAction(
  '[Endereco/API] Delete Endereco Failure',
  props<{ error: HttpErrorResponse }>()
);
export const deleteEnderecoSuccess = createAction(
  '[Endereco/API] Delete Endereco Success',
  props<{ message: string }>()
);

export const selectEnderecoEntrega = createAction(
  '[Select Effects] Select Endereco Entrega',
  props<{ enderecoEntregaSelecionadoId: number | null }>()
);

export const setEnderecosCep = createAction(
  '[Endereco/API] Set Endereco Cep',
  props<{ endereco: IEndereco }>()
);
