<vip-breadcrumb
  class="pt-4"
  [breadcrumbs]="breadcrumbs"
  (breadCrumbClick)="onBreadCrumbClick($event)"
></vip-breadcrumb>
<h1 class="vip-color-first-default text-base font-bold my-4">{{ title }}</h1>
<div class="menu-content mb-4">
  <div
    class="menu-content-border card-content h-fit sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3"
    [ngClass]="{ 'no-padding-left': noPaddingLeft }"
  >
    <div
      class="nome-user mb-2"
      [ngClass]="{ 'ml-4': noPaddingLeft }"
      *ngIf="username"
    >
      <span>
        Olá, <strong>{{ username }}</strong>
      </span>
    </div>

    <ng-container *ngFor="let item of menuItens">
      <vip-menu-item
        [type]="'desktop'"
        [noPaddingLeft]="noPaddingLeft"
        [isDesktop]="true"
        [attr.data-cy]="'menu-' + item.value"
        [color]="item.color || 'primary'"
        [icon]="item.icon ? 'icon-' + item.icon : ''"
        [image]="item.image"
        [showDivider]="item.showDivider ?? false"
        [title]="item.title"
        [active]="activeMenuTitle === item.title"
        [info]="
          {
            key: item.key,
            exibeSaldo: exibeCashback,
            saldo: saldoCashback,
            erroSaldo: saldoCashbackError,
            notifications: notificationsCount
          } | menuInfo
        "
        [vipCashbackHidden]="{
          errorMessage: saldoCashbackError,
          keyIsCashback: item.key === 'cashback',
          cashbackIsLoaded: cashbackIsLoaded,
          exibeCashback: exibeCashback
        }"
        (menuClick)="onMenuItemClick(item)"
      >
      </vip-menu-item>
    </ng-container>
  </div>
  <div
    class="w-full h-max content-menu"
    [ngClass]="{
      'menu-content-border': !hiddenMainCard || showBorder
    }"
  >
    <h1
      *ngIf="activeMenuTitle && !hiddenMainCard"
      class="vip-color-first-default text-lg font-bold mb-4"
    >
      {{ activeMenuTitle }}
    </h1>
    <ng-content></ng-content>
  </div>
</div>
