import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ValidarCepContainerService } from '@vip/container/validar-cep';
import {
  FormaEntregaEnum,
  HeaderUtilsService,
  IHorariosFormatados,
  IS_APP,
  ITipoEntrega,
  LayoutUtilsService,
  LocationChangeService,
} from '@vip/core';
import { BackButtonService } from '@vip/native/back-button';
import { CodeScannerService } from '@vip/native/code-scanner';
import { CarrinhoFacade } from '@vip/state/carrinho';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { CepFacade } from '@vip/state/cep';
import { ClienteFacade } from '@vip/state/cliente';
import { CompraEmProcessoFacade } from '@vip/state/compra-em-processo';
import { EnderecoFacade } from '@vip/state/endereco';
import { FilialFacade } from '@vip/state/filial';
import { ParametrosFacade } from '@vip/state/parametros';
import { TipoEntregaFacade } from '@vip/state/tipo-entrega';
import { DialogService, ModalService } from '@vip/ui/modal';
import { IMessage, MessageService } from '@vip/ui/message';
import { EntregaRetiradaComponent } from '@vip/views/entrega-retirada';
import { EntregaRetiradaBaseDirective } from '../entrega-retirada-base.directive';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { LoadingFacade } from '@vip/state/loading';
import { LayoutStore } from '@vip/state/layout';
import { BucketsFacade } from '@vip/state/buckets';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';
import { PerguntasFacade } from '@vip/state/perguntas';
import { mergeTakeOne } from '@vip/state/utils';
import { AnalyticsFacade } from '@vip/state/analytics';
import { TelevendasFacade } from '@vip/state/televendas';

@UntilDestroy()
@Component({
  selector: 'vip-entrega-retirada-container',
  templateUrl: './entrega-retirada-container.component.html',
  styles: [
    `
      :host {
        @apply h-full;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntregaRetiradaContainerComponent
  extends EntregaRetiradaBaseDirective
  implements OnInit, AfterViewInit
{
  @ViewChild(EntregaRetiradaComponent)
  entregaRetiradaComponent!: EntregaRetiradaComponent;

  formaEntregaEnum = FormaEntregaEnum;

  cliente$ = this.clienteFacade.cliente$;
  possuiLojaAutonoma$ = this.cdFacade.possuiLojaAutonoma$;
  isLojaAutonoma$ = this.cdFacade.isLojaAutonoma$;
  qtdItensCarrinho$ = this.carrinhoFacade.qtdItensCarrinho$;
  selecaoCdObrigatoria$ = this.parametrosFacade.selecaoCdObrigatorio$;
  loadingEnderecos$ = this.enderecoFacade.loading$;
  horarioSelecionado$ = this.tipoEntregaFacade.horarioSelecionado$;
  isTelevendas$ = this.televendasFacade.isTelevendas;
  tipoEntregas$ = this.isTelevendas$
    ? this.tipoEntregaFacade.tiposEntregasTelevendas$
    : this.tipoEntregaFacade.tiposEntregas$;
  tipoEntregaSelecionado$ = this.tipoEntregaFacade.tipoEntregaSelecionado$;
  filial$ = this.cdFacade.filialECdSelecionado$.pipe(map(([filial]) => filial));
  filialId = 0;
  filial = this.filialFacade.filial$
    .pipe(untilDestroyed(this))
    .subscribe((filial) => {
      this.filialId = filial.id;
    });
  logo$ = this.headerUtilsService.getLogo(
    this.bucketsFacade.bucketS3$,
    this.filial$
  );
  logoFallback$ = this.headerUtilsService.getLogoFallback(
    this.bucketsFacade.bucketS3$
  );
  isFromResumoCompra = false;
  isDesktop$ = this.layoutUtilsService.isDesktop$;
  isDesktop = false;
  horarioSelecionado?: IHorariosFormatados;
  isLoading$ = this.loadingFacade.isLoading$;
  totalCompra$ = this.carrinhoItensFacade.itensAsProdutoCompra$.pipe(
    map((produtos) =>
      produtos.reduce(
        (prev, current) => prev + current.preco * current.quantidade,
        0
      )
    )
  );
  taxaEntrega$ = this.tipoEntregaFacade.tipoEntregaSelecionado$.pipe(
    map((tipoEntregaSelecionado) => {
      if (!tipoEntregaSelecionado) return undefined;
      return tipoEntregaSelecionado.frete - tipoEntregaSelecionado.desconto;
    })
  );
  taxaServico$ = this.compraEmProcessoFacade.taxaServico$;
  isLoadedCompraEmProcesso$ = this.compraEmProcessoFacade.isLoaded$;
  disablePagamentoButton = false;
  changeTab = new EventEmitter<number>();
  exibirTiposEntrega$?: Observable<boolean>;
  exibirTiposRetirada$?: Observable<boolean>;
  migrarCdIsRunning = false;

  constructor(
    enderecoFacade: EnderecoFacade,
    cdFacade: CentroDistribuicaoFacade,
    cepFacade: CepFacade,
    clienteFacade: ClienteFacade,
    tipoEntregaFacade: TipoEntregaFacade,
    changeDetector: ChangeDetectorRef,
    dialogService: DialogService,
    router: Router,
    navController: NavController,
    activatedRoute: ActivatedRoute,
    validarCepContainerService: ValidarCepContainerService,
    filialFacade: FilialFacade,
    compraEmProcessoFacade: CompraEmProcessoFacade,
    carrinhoFacade: CarrinhoFacade,
    messageService: MessageService,
    codeScannerService: CodeScannerService,
    modalService: ModalService<unknown>,
    televendasFacade: TelevendasFacade,
    private loadingFacade: LoadingFacade,
    private analyticsFacade: AnalyticsFacade,
    private parametrosFacade: ParametrosFacade,
    private backButtonService: BackButtonService,
    private layoutUtilsService: LayoutUtilsService,
    private layoutStore: LayoutStore,
    private bucketsFacade: BucketsFacade,
    private carrinhoItensFacade: CarrinhoItensFacade,
    private locationChangeService: LocationChangeService,
    private perguntasFacade: PerguntasFacade,
    private headerUtilsService: HeaderUtilsService,
    @Inject(IS_APP) private isApp: boolean
  ) {
    super(
      enderecoFacade,
      cdFacade,
      cepFacade,
      clienteFacade,
      tipoEntregaFacade,
      filialFacade,
      compraEmProcessoFacade,
      carrinhoFacade,
      changeDetector,
      dialogService,
      router,
      navController,
      activatedRoute,
      validarCepContainerService,
      !isApp,
      modalService,
      televendasFacade,
      messageService,
      codeScannerService
    );

    this.selecaoCdObrigatoria$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.selecaoCdObrigatoria = value;
    });

    this.isFromResumoCompra =
      this.activatedRoute.snapshot.queryParams.isFromResumoCompra;

    this.locationChangeService.addRouteHandler('/entrega-retirada', () => {
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.cdFacade.getCentroDistribuicaoRetirada();
    this.verificaFilialRealizaEntrega();
    this.selecionarCdAoInicializar();
    this.setCdEntregaEnderecoInicial();
    this.isDesktop$.pipe(untilDestroyed(this)).subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });
    this.backButtonService.addRouteHandler('/entrega-retirada', () =>
      this.handleGoBack(false)
    );
    if (this.layoutUtilsService.isDesktop()) {
      this.perguntasFacade.limparRespostas();
      this.perguntasFacade.getPerguntas();
      this.initListenersGetTipoEntregas();
      this.setValuesExibirTipoEntregasListeners();
      this.initListenersGetTipoEntregaFromDirective();
      this.initListenersDesabilitarBotaoPagamento();
    }
  }

  ngAfterViewInit(): void {
    if (this.isFromResumoCompra) {
      this.layoutStore.setHeaderVisibility(false);
    }
  }

  handleGoBack(confirmar: boolean) {
    this.validarFormaSelecionada(confirmar);
  }

  onInformarCepClick() {
    this.solicitarCep();
  }

  handleHorarioChange(horarioSelecionado: IHorariosFormatados) {
    this.horarioSelecionado = horarioSelecionado;
    this.tipoEntregaFacade.selectHorarioTipoEntrega(horarioSelecionado);
    this.disablePagamentoButton = false;
  }

  handleTipoEntregaChanged(tipoEntrega: ITipoEntrega | null) {
    this.carrinhoFacade.qtdItensCarrinho$
      .pipe(take(1), untilDestroyed(this))
      .subscribe((qtdItens) => {
        if (
          tipoEntrega?.quantidade_maxima_itens != undefined ||
          tipoEntrega?.quantidade_maxima_itens != null
        ) {
          if (qtdItens > (tipoEntrega.quantidade_maxima_itens || 0)) {
            const messageData: IMessage = {
              icon: 'icon-close',
              position: 'top',
              type: 'error',
            };
            this.messageService?.openMessage(
              messageData,
              'Você excedeu o limite de itens permitidos para esse tipo de entrega.',
              5.0
            );
            this.tipoEntregaFacade.selectTipoEntrega(null);
            if (this.isDesktop) window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
          }
        }
        this.tipoEntregaFacade.selectTipoEntrega(tipoEntrega?.id || null);
      });
  }

  criarCompra(etapa = 0): void {
    this.carrinhoFacade.verificaItensDesativados();
    this.disablePagamentoButton = true;
    this.carrinhoFacade.verificarItens$
      .pipe(untilDestroyed(this), take(1))
      .subscribe(() => {
        this.compraEmProcessoFacade.criarCompra();
      });
  }

  selecionarEnderecoParaEdicao(id: number): void {
    this.enderecoFacade.selectEndereco(id);
  }

  getTiposEntregasByCdChange(): void {
    if (this.migrarCdIsRunning) return;
    this.migrarCdIsRunning = true;
    combineLatest([this.cdFacade.migrarCDResponseSuccess$])
      .pipe(untilDestroyed(this), take(1))
      .subscribe(() => {
        this.migrarCdIsRunning = false;
        this.tipoEntregaFacade.getTiposEntregasEndereco();
        this.validarAtendidoPorEntrega();
      });
  }

  continuarComprando(): void {
    this.tipoEntregaFacade.resetTiposEntregaSelecionada();
    this.router.navigateByUrl('/loja');
  }

  private initListenersGetTipoEntregas() {
    this.getTiposEntregasEnderecos();
  }

  private getTiposEntregasEnderecos(): void {
    combineLatest([this.enderecoEntrega$, this.cdSelecionado$])
      .pipe(
        untilDestroyed(this),
        tap(([, cdSelecionado]) => {
          if (!cdSelecionado) {
            this.tipoEntregaFacade.resetTiposEntrega();
          }
        }),
        filter(([endereco, cdSelecionado]) => !!endereco && !!cdSelecionado),
        take(1)
      )
      .subscribe(() => {
        this.tipoEntregaFacade.getTiposEntregasEndereco();
        this.validarAtendidoPorEntrega();
      });
  }

  private setValuesExibirTipoEntregasListeners(): void {
    this.exibirTiposEntrega$ = combineLatest([
      this.enderecoFacade.enderecoEntrega$,
      this.isEntrega$,
    ]).pipe(
      untilDestroyed(this),
      map(([enderecoEntrega, isEntrega]) => {
        return isEntrega && !!enderecoEntrega;
      })
    );
    this.exibirTiposRetirada$ = combineLatest([
      this.cdFacade.enderecoRetiradaCdSelecionado$,
      this.isEntrega$,
    ]).pipe(
      untilDestroyed(this),
      map(([enderecoRetirada, isEntrega]) => {
        return !isEntrega && !!enderecoRetirada;
      })
    );
  }

  private initListenersGetTipoEntregaFromDirective(): void {
    this.buscarTipoEntregas
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.getTiposEntregasEnderecos();
      });
    this.buscarTipoEntregasAlterarCd
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.getTiposEntregasByCdChange();
      });
  }

  private validarAtendidoPorEntrega() {
    this.tipoEntregas$
      .pipe(
        untilDestroyed(this),
        mergeTakeOne(
          this.tipoEntregaFacade.loaded$,
          this.isEntrega$,
          this.tipoEntregaFacade.loading$
        ),
        filter(
          ([, loaded, isEntrega, loading]) => !loading && loaded && isEntrega
        ),
        take(1)
      )
      .subscribe(([tiposEntregas]) => {
        if (
          !tiposEntregas.filter((tipoEntrega) => !tipoEntrega.retirada_loja)
            .length
        ) {
          this.exibirModalEnderecoInvalido();
        }
      });
  }

  private exibirModalEnderecoInvalido(): void {
    if (this.dialogService.isOpened()) return;

    this.dialogService.openDialog({
      open: true,
      disabled: false,
      showCloseButton: false,
      title: 'Não realizamos entrega no endereço selecionado!',
      subTitle:
        'Por favor, adicione outro endereço válido para entrega, ou, se preferir, altere para fazer a retirada.',
      buttonCancelText: 'Fazer Retirada na Loja',
      buttonConfirmText: 'Selecionar outro Endereço',
    });
    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this.tipoEntregaFacade.setFormaEntrega(true);
          this.tipoEntregaFacade.resetTiposEntrega();
          this.cdSelecionado = null;
          this.novoCd = null;
          this.novoEndereco = null;
          this.enderecoFacade.selectEnderecoEntrega(null);
          this.cdsEntrega$ = of([]);
          this.changeDetector.detectChanges();
        } else {
          this.changeTab.emit(this.formaEntregaEnum.TIPO_RETIRADA);
        }
        this.dialogService.clearDialog();
      });
  }

  private initListenersDesabilitarBotaoPagamento() {
    this.perguntasFacade.fechouPerguntas$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.disablePagamentoButton = false;
      });
    this.compraEmProcessoFacade.criarCompraResponse$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.disablePagamentoButton = false;
      });
  }

  iniciarCheckout() {
    this.analyticsFacade.iniciarCheckout();
  }
}
