import { EmptyModule } from '@vip/ui/empty';
import { SearchModule } from '@vip/ui/search';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubstituirProdutoComponent } from './substituir-produto/substituir-produto.component';
import { ScreenTitleModule } from '@vip/ui/screen-title';
import { CardProdutoModule } from '@vip/ui/card-produto';
import { ButtonModule } from '@vip/ui/button';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    ScreenTitleModule,
    CardProdutoModule,
    SearchModule,
    EmptyModule,
  ],
  declarations: [SubstituirProdutoComponent],
  exports: [SubstituirProdutoComponent],
})
export class SubstituirProdutoModule {}
