import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ClienteFacade } from '@vip/state/cliente';
import { EnderecoFacade } from '@vip/state/endereco';
import { FilialFacade } from '@vip/state/filial';
import { MeusDadosBaseDirective } from '../meus-dados-base.directive';
import { CepFacade } from '@vip/state/cep';
import { ICliente, LayoutUtilsService } from '@vip/core';
import { ParametrosFacade } from '@vip/state/parametros';
import { mergeMap, take, tap } from 'rxjs/operators';
import { partition } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { CacheUtilsService } from '@vip/utils/cache';

@Component({
  selector: 'vip-meus-dados-container',
  templateUrl: './meus-dados-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeusDadosContainerComponent extends MeusDadosBaseDirective {
  pinAtivo$ = this.parametrosFacade.pinAtivo$;
  constructor(
    router: Router,
    clienteFacade: ClienteFacade,
    enderecoFacade: EnderecoFacade,
    filialFacade: FilialFacade,
    cepFacade: CepFacade,
    layoutUtilsService: LayoutUtilsService,
    private parametrosFacade: ParametrosFacade,
    private cacheUtilsService: CacheUtilsService
  ) {
    super(
      router,
      clienteFacade,
      filialFacade,
      enderecoFacade,
      cepFacade,
      layoutUtilsService
    );
  }

  verificarDadosCache(
    clienteAntigo: ICliente,
    cliente: ICliente & { password: string }
  ) {
    this.pinAtivo$
      .pipe(
        untilDestroyed(this),
        take(1),
        tap(async (pinAtivo) => {
          const telefonesDiferentes =
            cliente.telefone_celular !==
            clienteAntigo.telefone_celular_formatado;
          let isValid = true;

          if (pinAtivo && telefonesDiferentes) {
            isValid = await this.cacheUtilsService.checkAplicativoFilialCache();
          }
          if (isValid) this.saveMeusDados(cliente);
        })
      )
      .subscribe();
  }
}
