<span
  class="vip-breadcrumb"
  *ngFor="let breadcrumb of breadcrumbs; let last = last"
>
  <span
    [ngClass]="{ last: last }"
    class="vip-breadcrumb-label"
    (click)="handleClick(last, breadcrumb.link)"
    >{{ breadcrumb.label }}</span
  >
  <i *ngIf="!last" class="vip-breadcrumb-icon icon-chevron_right"></i>
</span>
