import { ChangeDetectionStrategy, Component } from '@angular/core';

import { CardCashbackDirective } from '@vip/ui/card-cashback';
@Component({
  selector: 'vip-card-cashback-desktop',
  templateUrl: './card-cashback-desktop.component.html',
  styleUrls: ['./card-cashback-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardCashbackDesktopComponent extends CardCashbackDirective {}
