import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ITermosUso } from '@vip/core';

export const getTermosUso = createAction('[TermosUso/API] Get Termos Uso');

export const getTermosUsoSuccess = createAction(
  '[TermosUso/API] Get Termos Uso Success',
  props<{ TermosUso: ITermosUso }>()
);

export const getAprovacaoPendente = createAction(
  '[TermosUso/API] Get Aprovacao Pendente'
);

export const getAprovacaoPendenteSuccess = createAction(
  '[TermosUso/API] Get Aprovacao Termos Uso Success',
  props<{ aprovacaoPendente: boolean | null }>()
);

export const getTermosUsoFailure = createAction(
  '[TermosUso/API] Get Termos Uso Failure',
  props<{ error: HttpErrorResponse }>()
);

export const aprovarTermosUso = createAction(
  '[TermosUso/API] Aprovar termos de uso'
);

export const aprovarTermosUsoSuccess = createAction(
  '[TermosUso/API] Get Aprovacao Termos Uso Success',
  props<{ aprovarTermosUso: number }>()
);

export const aprovarTermosUsoFailure = createAction(
  '[TermosUso/API] Get Termos Uso Failure',
  props<{ error: HttpErrorResponse }>()
);
