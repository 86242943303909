import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ICodigoPromocional, ICupomCredito } from '@vip/core';
import { DialogService } from '@vip/ui/modal';

@UntilDestroy()
@Directive()
export abstract class PagamentoCuponsBaseDirective {
  @Input()
  cupons: ICupomCredito[] = [];

  @Input()
  cuponsSelecionados: ICupomCredito[] = [];

  @Input()
  codigoPromocional: ICodigoPromocional | null = null;

  @Input()
  exibirData = true;

  @Output()
  changedSelecionados = new EventEmitter();

  @Output()
  ativarCodigo = new EventEmitter<string>();

  @Output()
  alterarCodigo = new EventEmitter<{
    codigoParaRemover: string;
    codigoParaManter: ICodigoPromocional;
  }>();

  @Output()
  deleteCodigoPromocionalClick = new EventEmitter<string>();

  @ViewChild('inputCodigo')
  inputCodigoPromocional!: ElementRef;

  codigoPromocionalControl = new UntypedFormControl('', [Validators.required]);

  constructor(protected dialogService: DialogService) {}

  excluirCodigoPromocional(codigo: string): void {
    this.dialogService.openDialog({
      open: true,
      title: 'Excluir código promocional?',
      subTitle: `Deseja realmente excluir o código promocional ${codigo}?`,
      disabled: false,
      buttonCancelText: 'Manter código promocional',
      buttonConfirmText: 'Excluir código promocional',
    });

    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this.deleteCodigoPromocionalClick.emit(codigo);
          if (this.inputCodigoPromocional) {
            this.inputCodigoPromocional.nativeElement.value = '';
          } else {
            this.codigoPromocionalControl.reset();
          }
        }
        this.dialogService.clearDialog();
      });
  }

  getChecked(cupom: ICupomCredito) {
    return this.cuponsSelecionados.some(
      (selecionado) => selecionado.id === cupom.id
    );
  }

  onClickCupom(checked: boolean, cupom: ICupomCredito) {
    this.atualizarSelecionados(checked, cupom);
    this.changedSelecionados.emit({
      cupons: this.cuponsSelecionados,
      adicionado: checked,
    });
  }

  atualizarSelecionados(checked: boolean, cupom: ICupomCredito) {
    this.cuponsSelecionados = this.cuponsSelecionados.filter(
      (selecionado) => selecionado.id !== cupom.id
    );
    if (checked) {
      this.cuponsSelecionados.push({ ...cupom, utilizado: checked });
    }
  }
}
