import { CarouselModule } from '@vip/ui/carousel';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComboComponent } from './card-combo/card-combo.component';
import { ImageModule } from '@vip/ui/image';
import { SpinModule } from '@vip/ui/spin';
import { ButtonModule } from '@vip/ui/button';
import { CoreModule } from '@vip/core';
import { ModalModule } from '@vip/ui/modal';
import { ProdutoPrecoModule } from '@vip/ui/produto-preco';
import { RippleModule } from '@vip/ui/ripple';
import { RouterModule } from '@angular/router';
import { SimpleTextFormModule } from '@vip/ui/simple-text-form';
import { TagsModule } from '@vip/ui/tags';
import { TagCollectionModule } from '@vip/ui/tag-collection';
import { SkeletonModule } from '@vip/ui/skeleton';
import { SeletorDePesoModule } from '@vip/ui/seletor-de-peso';
import { SwiperModule } from 'swiper/angular';
import { CardComboMiniComponent } from './card-combo-mini/card-combo-mini.component';
import { CardComboMobileComponent } from './card-combo-mobile/card-combo-mobile.component';
import { CardComboDesktopComponent } from './card-combo-desktop/card-combo-desktop.component';

@NgModule({
  imports: [
    CommonModule,
    ImageModule,
    SpinModule,
    ButtonModule,
    CoreModule,
    ModalModule,
    ProdutoPrecoModule,
    RippleModule,
    RouterModule,
    SimpleTextFormModule,
    TagsModule,
    TagCollectionModule,
    SkeletonModule,
    SeletorDePesoModule,
    CarouselModule,
    SwiperModule,
  ],
  declarations: [
    CardComboComponent,
    CardComboMiniComponent,
    CardComboMobileComponent,
    CardComboDesktopComponent,
  ],
  exports: [
    CardComboComponent,
    CardComboMiniComponent,
    CardComboMobileComponent,
    CardComboDesktopComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class CardComboModule {}
