import { AplicativoFacade } from '@vip/state/aplicativo';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FilialFacade } from '@vip/state/filial';

@Injectable()
export class ManutencaoGuard implements CanActivate {
  constructor(
    private router: Router,
    private filialFacade: FilialFacade,
    private aplicativoFacade: AplicativoFacade
  ) {}

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.filialFacade.filial$.pipe(startWith(null)),
      this.aplicativoFacade.hasError$,
      this.filialFacade.hasError$,
    ]).pipe(
      map(([filial, errorAplicativo, errorFilial]) => {
        if (errorFilial || errorAplicativo || filial?.em_manutencao) {
          return true;
        } else {
          this.router.navigateByUrl('/');
          return false;
        }
      })
    );
  }
}
