import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { ProdutoFacade } from '@vip/state/produto';
import { DialogService } from '@vip/ui/modal';
import { map } from 'rxjs/operators';
import {
  notFoundModalSuccessMessage,
  notFoundModalErrorMessage,
} from './not-found-modal-messages';
import { Observable } from 'rxjs';
import { IFilial } from '@vip/core';

@UntilDestroy()
@Component({
  selector: 'vip-not-found-modal',
  templateUrl: 'not-found-modal.component.html',
  styleUrls: ['not-found-modal.component.scss'],
})
export class NotFoundModalComponent {
  @Input() abrirModalProcurarProduto = false;
  @Output() abrirModalProcurarProdutoChange = new EventEmitter<boolean>();
  filial$: Observable<IFilial>;
  form: FormGroup;
  validations = {
    produto: {
      required: 'Por favor, informe um produto.',
    },
  };

  constructor(
    private fb: FormBuilder,
    private produtoFacade: ProdutoFacade,
    private dialogService: DialogService,
    private cdFacade: CentroDistribuicaoFacade
  ) {
    this.filial$ = this.cdFacade.filialECdSelecionado$.pipe(
      map(([filial]: any) => filial)
    );
    this.form = this.fb.group({
      produto: ['', [Validators.required]],
      codigo_barras: [''],
      observacao: [''],
    });
  }

  submit(filialId: number) {
    this.form.markAllAsTouched();
    if (this.form.invalid) return;
    this.enviarFormSolicitarProduto(filialId);
    this.abrirModalProcurarProduto = false;
    this.form.reset();
  }

  async enviarFormSolicitarProduto(filialId: number) {
    await this.produtoFacade
      .solicitarProduto(filialId, {
        produto: this.form.value.produto as string,
        codigo_barras: this.form.value.codigo_barras as string,
        observacao: this.form.value.observacao as string,
      })
      .toPromise()
      .then(({ success }) => {
        if (success) {
          this.showDialogMessage(true);
        }
      })
      .catch(() => {
        this.showDialogMessage(false);
      });
  }

  showDialogMessage(success: boolean) {
    this.dialogService.openDialog(
      success ? notFoundModalSuccessMessage : notFoundModalErrorMessage
    );
    this.dialogService.dialogClick.pipe(untilDestroyed(this)).subscribe(() => {
      this.dialogService.clearDialog();
    });
  }

  onAfterCloseModal(): void {
    this.abrirModalProcurarProduto = false;
    this.abrirModalProcurarProdutoChange.emit(false);
  }
}
