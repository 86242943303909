import { Router } from '@angular/router';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgZone,
  ViewChild,
} from '@angular/core';
import { IClassificacaoMercadologica, MenuOptions } from '@vip/core';
import { DropdownDirective } from '@vip/ui/dropdown';

@Component({
  selector: 'vip-header-desktop-menu-principal',
  templateUrl: './header-desktop-menu-principal.component.html',
  styleUrls: ['./header-desktop-menu-principal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderDesktopMenuPrincipalComponent {
  @Input()
  options: MenuOptions = [];
  @Input()
  departamentos: IClassificacaoMercadologica[] = [];

  @ViewChild(DropdownDirective) dropdown!: DropdownDirective;

  dropdownDepartamentosIsOpen = false;

  constructor(private router: Router, private ngZone: NgZone) {}

  itemClick(departamento: IClassificacaoMercadologica) {
    this.ngZone.run(() => {
      this.router.navigateByUrl(`/departamentos${departamento.link}`);
      this.dropdown.close();
    });
  }

  getMenuOptionValue(index: number, acessor: string) {
    return this.options[index][acessor];
  }
  onMenuItemClick(menuItem: any, index: number): void {
    const link = this.getMenuOptionValue(index, 'link');

    if (typeof link === 'string') {
      const isInternal = menuItem['internal'] === 'true';

      if (isInternal) {
        this.router.navigate([link]);
      } else {
        window.open(link, '_blank');
      }
    } else {
      console.error('Invalid link type:', link);
    }
  }
}
