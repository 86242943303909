import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as LoadingActions from './loading.actions';
import { loadingSelectors } from './loading.selectors';

@Injectable()
export class LoadingFacade {
  isLoading$ = this.store.pipe(select(loadingSelectors.isLoading()));
  textLoading$ = this.store.pipe(select(loadingSelectors.getTextLoading()));

  constructor(private store: Store) {}

  setLoading(textLoading?: string) {
    this.store.dispatch(LoadingActions.setLoading({ textLoading }));
  }

  disableLoading() {
    this.store.dispatch(LoadingActions.disableLoading());
  }
}
