import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  environment,
  ISlide,
  LayoutUtilsService,
  RouteTranslateService,
  StringUtilsService,
} from '@vip/core';
import { Router } from '@angular/router';
import { IframeService } from '@vip/native/iframe';
import { DialogService, IDialog } from '@vip/ui/modal';
import { BannerFacade } from '@vip/state/banner';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'vip-banner-imagem',
  templateUrl: './banner-imagem.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerImagemComponent {
  @Input()
  banner!: ISlide;
  @Output() redirectInternal = new EventEmitter();
  @Input() clienteLogado = false;
  @Input()
  isHomeOmni = false;
  isModalOpen$ = new BehaviorSubject<boolean>(false);
  isDesktop = this.layoutUtilsService.isDesktop();

  environment = environment;

  constructor(
    private router: Router,
    private iframeService: IframeService,
    private dialogService: DialogService,
    private bannerFacade: BannerFacade,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  onClickBanner() {
    let path = '';
    if (!this.banner.details) {
      this.showMessageIndisponivel();
    } else {
      switch (this.banner.model) {
        case 'Receita':
          path = `/receita/${this.banner.details.id}/${this.banner.details.slug}`;
          this.redirectInternal.emit();
          break;
        case 'Link':
          path = RouteTranslateService.getLinkInterno(
            this.banner.details.link || '',
            this.isDesktop
          );
          break;
        case 'Colecao':
          path = `/colecoes/${this.banner.details.id}/${this.banner.details.slug}`;
          this.redirectInternal.emit();
          break;
        case 'Pagina':
          path = `/institucional/pagina/${this.banner.details.slug}`;
          this.redirectInternal.emit();
          break;
        case 'Produto':
          path = `/produto/${
            this.banner.details.produto_id
          }/${StringUtilsService.slugify(this.banner.details.descricao)}`;
          this.redirectInternal.emit();
          break;
        case 'Oferta':
          path = `/ofertas-detalhes/${this.banner.details.id}`;
          this.redirectInternal.emit();
          break;
        case 'Manual':
          this.abrirLink(this.banner.details.link);
          break;
        case 'InjecaoIframe':
          this.iframeService.open(`${this.banner.details.url}`, {
            isLoginRequired: this.banner.details.necessita_login,
            iframeId: this.banner.details.id,
          });
      }

      if (path) {
        this.router.navigateByUrl(path);
      }
    }
    if (this.isHomeOmni) {
      this.bannerFacade.addCliqueHomeOmni(this.banner.id);
    } else {
      this.bannerFacade.addClique(this.banner.id);
    }
  }

  showMessageIndisponivel() {
    const dialogData: IDialog = {
      open: true,
      title: `${this.banner.model} Indisponível`,
      subTitle: `Infelizmente essa ${this.banner.model} não está mais disponível no sistema!`,
      disabled: false,
    };
    this.dialogService.openDialog(dialogData);
  }

  abrirLink(link: string | undefined) {
    if (!link) return;

    const rotaInternaNoApp =
      this.environment.isApp &&
      this.environment.domainKey &&
      link.includes(this.environment.domainKey);

    if (link.startsWith('http') && !rotaInternaNoApp) {
      return this.iframeService.open(link);
    }
    link = link.endsWith('/') ? link : `${link}/`;
    const matches = link.match(/(http[s]?:\/\/)?([^/\s]+\/)(.*)/);
    const route = matches && matches.length >= 4 ? matches[3] : null;

    if (route !== null) {
      this.router.navigateByUrl(route.replace(/\/$/, ''));
    }
    return;
  }
}
