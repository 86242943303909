<label
  class="flex items-center"
  vipRipple
  rippleColor="color-primary-main"
  rippleBorderRadius="100px"
  [rippleDisabled]="disabled"
>
  <span
    class="switch"
    [ngClass]="{ 'desktop-switch': isDesktopView }"
    [class.mr-2]="content.textContent"
  >
    <input
      type="checkbox"
      [class.disabled]="disabled"
      [checked]="checked"
      (change)="inputChange()"
    />
    <span
      class="slider round"
      [class.desktop-slider]="isDesktopView"
      [class.disabled]="disabled"
    ></span>
  </span>
  <span class="toggle-label text-sm md:text-base" #content>
    <ng-content></ng-content>
  </span>
</label>
