import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownDepartamentosComponent } from './dropdown-departamentos/dropdown-departamentos.component';
import { DropdownMenuModule } from '@vip/ui/dropdown-menu';
import { DropdownSecoesComponent } from './dropdown-secoes/dropdown-secoes.component';

@NgModule({
  imports: [CommonModule, DropdownMenuModule],
  declarations: [DropdownDepartamentosComponent, DropdownSecoesComponent],
  exports: [DropdownDepartamentosComponent],
})
export class DropdownDepartamentosModule {}
