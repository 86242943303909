import { ButtonModule } from '@vip/ui/button';
import { ModalModule } from '@vip/ui/modal';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { CardCashbackComponent } from './card-cashback/card-cashback.component';

import { CardBasicoModule } from '@vip/ui/card-basico';
import { ReactiveFormsModule } from '@angular/forms';
import { ToggleModule } from '@vip/ui/toggle';
import { FieldModule } from '@vip/ui/field';
import { ImageModule } from '@vip/ui/image';
import {
  CurrencyMaskConfig,
  CurrencyMaskModule,
  CURRENCY_MASK_CONFIG,
} from 'ng2-currency-mask';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  thousands: '.',
  suffix: '',
};
@NgModule({
  imports: [
    CommonModule,
    CardBasicoModule,
    ReactiveFormsModule,
    ToggleModule,
    FieldModule,
    ImageModule,
    CurrencyMaskModule,
    ModalModule,
    ButtonModule,
  ],
  providers: [
    CurrencyPipe,
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
  ],
  declarations: [CardCashbackComponent],
  exports: [CardCashbackComponent],
})
export class CardCashbackModule {}
