import { SearchModule } from '@vip/ui/search';
import { ListSelectModule } from '@vip/ui/list-select';
import { SelectModule } from '@vip/ui/select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelecionarClienteTelevendasComponent } from './selecionar-cliente-televendas/selecionar-cliente-televendas.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    SelectModule,
    ListSelectModule,
    SearchModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [SelecionarClienteTelevendasComponent],
  exports: [SelecionarClienteTelevendasComponent],
})
export class SelecionarClienteTelevendasModule {}
