import { AnalyticsFacade } from '@vip/state/analytics';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { ICarrinho, LayoutUtilsService, RouterTransitionEnum } from '@vip/core';
import { CarrinhoFacade } from '@vip/state/carrinho';
import {
  ProdutosFavoritosStore,
  ProdutosIndisponiveisService,
  ProdutosIndisponiveisStore,
  ProdutosMaisVendidosStore,
} from '@vip/state/produto';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, observeOn, take, tap } from 'rxjs/operators';
import { combineLatest, queueScheduler } from 'rxjs';
import { AddToListContainerService } from '@vip/container/add-to-list';
import { AtualizarCarrinhoService } from './../services/atualizar-carrinho-service';
import { ClienteFacade } from '@vip/state/cliente';
import { DialogService, IDialog } from '@vip/ui/modal';
import { mergeTakeOne } from '@vip/state/utils';
import { CacheUtilsService } from '@vip/utils/cache';
import { ParametrosFacade } from '@vip/state/parametros';

@UntilDestroy()
@Component({
  selector: 'vip-carrinho-container',
  templateUrl: './carrinho-container.component.html',
  styleUrls: ['./carrinho-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProdutosFavoritosStore,
    ProdutosMaisVendidosStore,
    ProdutosIndisponiveisStore,
    ProdutosIndisponiveisService,
  ],
})
export class CarrinhoContainerComponent implements OnInit, AfterContentInit {
  favoritos$ = this.produtosFavoritosStore.produtos$;
  maisVendidos$ = this.produtosMaisVendidosStore.produtos$;
  carrinho$ = this.carrinhoFacade.carrinho$;
  carrinho!: ICarrinho;
  produtosNoCarrinho$ = this.carrinhoItensFacade.produtosNoCarrinho$;
  combosNoCarrinho$ = this.carrinhoItensFacade.combosNoCarrinho$;
  itensDepartamento$ = this.carrinhoItensFacade.itensDepartamento$.pipe(
    tap((itens) => {
      this.qtdItensCarrinho = itens.length;
    })
  );
  isLoadingCarrinhoItens$ = this.carrinhoItensFacade.loading$;
  isLoadingCarrinho$ = this.carrinhoFacade.loading$;
  permiteCarrinhoAnonimo$ = this.parametrosFacade.permitirCarrinhoAnonimo$;
  isDesabilitarBtn$ = combineLatest([
    this.isLoadingCarrinho$,
    this.isLoadingCarrinhoItens$,
    this.permiteCarrinhoAnonimo$,
  ]).pipe(
    map(
      ([isLoadingCarrinho, isLoadingCarrinhoItens, permiteCarrinhoAnonimo]) => {
        if (permiteCarrinhoAnonimo) {
          return isLoadingCarrinhoItens || isLoadingCarrinho;
        }
        return false;
      }
    )
  );
  isLogged$ = this.clienteFacade.isLogged$;
  isTablet$ = this.layoutUtilsService.isTablet$;
  isTablet = false;
  carrinhoVazio = false;
  qtdItensCarrinho = 0;

  readonly dialogCadastroIncompleto: IDialog = {
    open: true,
    title: 'Cadastro incompleto',
    subTitle:
      'Para finalizar sua compra, precisamos completar algumas informações do seu cadastro.',
    disabled: false,
    buttonConfirmText: 'Completar cadastro',
  };
  readonly dialogFazerLogin: IDialog = {
    open: true,
    title: 'Faça login ou crie uma conta!',
    subTitle:
      'Para finalizar a sua compra, precisamos que você faça o login com seu usuário ou crie uma conta.',
    disabled: true,
    buttonConfirmText: 'Criar uma conta',
    buttonCancelText: 'Fazer login',
  };

  constructor(
    private router: Router,
    private produtosFavoritosStore: ProdutosFavoritosStore,
    private produtosMaisVendidosStore: ProdutosMaisVendidosStore,
    private carrinhoItensFacade: CarrinhoItensFacade,
    private analiytcsFacade: AnalyticsFacade,
    private carrinhoFacade: CarrinhoFacade,
    private clienteFacade: ClienteFacade,
    private parametrosFacade: ParametrosFacade,
    private dialogService: DialogService,
    public atualizarCarrinhoService: AtualizarCarrinhoService,
    public produtosIndisponiveisService: ProdutosIndisponiveisService,
    public addToListContainerService: AddToListContainerService,
    public layoutUtilsService: LayoutUtilsService,
    private cacheUtilsService: CacheUtilsService
  ) {}

  @HostBinding('attr.router-transition-type') get routerTransition() {
    return RouterTransitionEnum.BackInUp;
  }

  ngOnInit(): void {
    this.carrinhoVazio = false;

    this.carrinhoFacade.carrinho$
      //TODO VIP-17401 workaround
      .pipe(observeOn(queueScheduler), untilDestroyed(this))
      .subscribe((carrinho) => {
        if (carrinho) {
          if (this.qtdItensCarrinho !== carrinho.quantidade)
            this.carrinhoItensFacade.getItensCarrinho();

          this.carrinho = carrinho;
          if (!carrinho.quantidade || carrinho.quantidade == 0) {
            this.produtosFavoritosStore.getFavoritos();
            this.produtosMaisVendidosStore.getMaisVendidos();
            this.carrinhoItensFacade.resetItensCarrinho();
            this.carrinhoVazio = true;
          }
          this.analiytcsFacade.visualizaCarrinho();
        }
      });
    this.carrinhoFacade.getCarrinho();
  }

  ngAfterContentInit(): void {
    this.clienteFacade.isLogged$
      .pipe(untilDestroyed(this), take(1))
      .subscribe((isLogged) => {
        if (!isLogged) this.cacheUtilsService.checkAplicativoFilialCache();
      });
  }

  handleShowMoreClick(event: string) {
    this.router.navigateByUrl(event);
  }

  deleteCarrinhoClick() {
    this.carrinhoFacade.removerCarrinho(this.carrinho.carrinho_id);
  }

  handleResumeClick(event: boolean) {
    if (event) {
      this.isLogged$.pipe(untilDestroyed(this)).subscribe((isLogged) => {
        if (isLogged) {
          this.clienteFacade
            .cadastroIncompleto()
            .pipe(
              untilDestroyed(this),
              mergeTakeOne(this.carrinhoFacade.carrinho$)
            )
            .subscribe(([incompleto, carrinho]) => {
              if (incompleto.data) {
                this.dialogService.openDialog(this.dialogCadastroIncompleto);
                this.dialogService.dialogClick
                  .pipe(untilDestroyed(this))
                  .subscribe((value) => {
                    if (value)
                      this.router.navigateByUrl(
                        '/minha-conta/completar-cadastro'
                      );
                    this.dialogService.clearDialog();
                  });
              } else {
                if (carrinho.preco > carrinho.valor_minimo) {
                  this.router.navigateByUrl('/resumo-compra');
                }
              }
            });
        } else {
          this.dialogService.openDialog(this.dialogFazerLogin);
          this.dialogService.dialogClick
            .pipe(untilDestroyed(this))
            .subscribe((value) => {
              if (value) {
                this.router.navigateByUrl('/cadastrar');
              } else {
                this.router.navigate(['/login'], {
                  queryParams: { redirectTo: '/resumo-compra' },
                });
              }

              this.dialogService.clearDialog();
            });
        }
      });
    } else {
      this.router.navigateByUrl('/loja');
    }
  }
}
