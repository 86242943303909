import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Data,
  NavigationEnd,
  Router,
  UrlSegment,
} from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { filter, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { IBreadCrumb } from '@vip/core';
import {
  BreadcrumbState,
  IBreadCrumbFromRoute,
} from './breadcrumb-store.interface';
import { mergeTakeOne } from '@vip/state/utils';

type InitParameterData = {
  data: Data;
  pathFromRoot: ActivatedRouteSnapshot[];
  customLink?: string;
};

@Injectable({ providedIn: 'root' })
export class BreadcrumbStore extends ComponentStore<BreadcrumbState> {
  readonly breadcrumbs$ = this.select((s) => s.breadcrumbs);

  private readonly initialState: BreadcrumbState = {
    breadcrumbs: [],
  };
  private _breadcrumbs: IBreadCrumb[] = [];

  private MAX_QTD_BREADCRUMB = 2;

  constructor(private router: Router, private activateRoute: ActivatedRoute) {
    super({
      breadcrumbs: [],
    });
  }

  init(
    { data, pathFromRoot, customLink }: InitParameterData,
    maxQtdBreadcrumb = 2
  ): void {
    const { breadcrumb } = data;
    this.MAX_QTD_BREADCRUMB = maxQtdBreadcrumb;
    if (breadcrumb) {
      this.generateBreadCrumbs(
        of(data),
        customLink || this.getUrlFromPathFromRoot(pathFromRoot),
        this.initialState
      );
    }
    this.checkRouterEvents();
  }

  changeState(breadcrumbs: IBreadCrumb[], state: BreadcrumbState) {
    this.setState({ ...state, breadcrumbs });
  }

  getFromLastBreadcrumbItem(decrease = 1): IBreadCrumb {
    return this._breadcrumbs[this._breadcrumbs.length - decrease];
  }

  private checkRouterEvents(): void {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        map(() => this.activateRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeTakeOne(this.state$),
        map(([activatedRoute, state]) => ({
          state,
          data: activatedRoute.data,
          pathFromRoot: activatedRoute.pathFromRoot,
        }))
      )
      .subscribe(({ data, pathFromRoot, state }) =>
        this.generateBreadCrumbs(
          data,
          this.getUrlFromPathFromRoot(pathFromRoot),
          state
        )
      );
  }

  private getUrlFromPathFromRoot(
    path: ActivatedRoute[] | ActivatedRouteSnapshot[]
  ): string {
    return path
      .map((activatedRoute) => this.getUrl(activatedRoute))
      .filter((url) => !!url[0])
      .map(([url]) => url.path)
      .join('/');
  }

  private getUrl(route: ActivatedRoute | ActivatedRouteSnapshot): UrlSegment[] {
    if (route instanceof ActivatedRoute) {
      return route.snapshot.url;
    } else {
      return route.url;
    }
  }

  private generateBreadCrumbs(
    dataParamsRoute: Observable<Data>,
    link: string,
    state: BreadcrumbState
  ): void {
    dataParamsRoute.subscribe((dataRoute) => {
      const breadcrumbRoute: IBreadCrumbFromRoute = dataRoute['breadcrumb'];
      if (breadcrumbRoute) {
        const currentBreadcrumbItem = {
          link,
          label: dataRoute['breadcrumb']?.label,
        };

        if (breadcrumbRoute.fixedParent) {
          const { label: parentLabel, link: parentLink } =
            breadcrumbRoute.fixedParent;

          this._breadcrumbs = [
            { label: parentLabel, link: parentLink },
            currentBreadcrumbItem,
          ];
          this.changeState(this._breadcrumbs, state);
        } else if (
          this._breadcrumbs.length > 1 &&
          this.getFromLastBreadcrumbItem().link === currentBreadcrumbItem.link
        ) {
          this.changeState(this._breadcrumbs, state);
        } else if (this._breadcrumbs.length === this.MAX_QTD_BREADCRUMB) {
          this._breadcrumbs = this._breadcrumbs.splice(1);
          this._breadcrumbs.push(currentBreadcrumbItem);
          this.changeState(this._breadcrumbs, state);
        } else {
          if (breadcrumbRoute.getFromParentLink) {
            currentBreadcrumbItem.label = link.split('/')[1];
          }
          this._breadcrumbs.push(currentBreadcrumbItem);
          this.changeState(this._breadcrumbs, state);
        }
      }
    });
  }
}
