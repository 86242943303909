import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { IS_APP } from '@vip/core';
import { SearchEvent, SearchEventType } from '../types/search-types';
import { debounce } from 'lodash';
import { InputSearchFormat } from '../types/input-type-format';

@Component({
  selector: 'vip-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements AfterViewInit {
  @ViewChild('input')
  input: ElementRef | undefined;

  @Input() focusOnInit = false;
  @Input() isDesktop = false;
  @Input() placeholder = '';
  @Input() debounce = 500;
  @Input() searchTermMinLength = 3;
  @Input() isLoading = false;
  @Input() typeInput: InputSearchFormat = 'normal';
  @Input() disabled = false;
  @Input() isTelevendas = false;
  @Output() search: EventEmitter<SearchEvent> = new EventEmitter<SearchEvent>();
  @Output() clearEmitter = new EventEmitter();
  @Output() focusOnInitChange = new EventEmitter<boolean>();
  //eslint-disable-next-line @typescript-eslint/ban-types
  public handleInput: Function = this.setupInputHandler(this.debounce);
  public currentValue = '';

  constructor(
    public elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef,
    @Inject(IS_APP) private isApp: boolean
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => this.handleInputFocus(), 500);
  }

  @Input()
  set value(value: string) {
    this.currentValue = value;
  }

  @Input()
  set debounceTime(value: number) {
    this.handleInput = this.setupInputHandler(value);
  }

  public handleIconClick(event: Event) {
    this.doSearch('click', event);
  }

  public handleKeydown(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.input?.nativeElement.blur();
      this.doSearch('enter', event);
      if (!this.isApp) this.clear();
    }
  }

  //eslint-disable-next-line @typescript-eslint/ban-types
  public setupInputHandler(debounceTime: number): Function {
    return debounce((event: Event) => {
      if (
        this.currentValue === '' ||
        this.currentValue.length >= this.searchTermMinLength
      ) {
        this.doSearch('debounce', event);
      }
    }, debounceTime);
  }

  clear() {
    this.currentValue = '';
    if (this.input?.nativeElement) {
      this.input.nativeElement.value = '';
    }
    this.clearEmitter.emit();
    this.changeDetector.detectChanges();
  }

  private doSearch(eventType: SearchEventType, originalEvent: Event) {
    this.search.emit(
      new SearchEvent(eventType, this.currentValue, originalEvent)
    );
  }

  private handleInputFocus(): void {
    if (this.focusOnInit) {
      this.input?.nativeElement.focus();
    } else {
      this.input?.nativeElement.blur();
    }
  }

  public handleKeyup(event: KeyboardEvent): void {
    this.search.emit(new SearchEvent('keyup', this.currentValue, event));
  }
}
