import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ICodigoPromocional, ICompraDesconto } from '@vip/core';

export const getDescontos = createAction('[Descontos/Page] Get Descontos');

export const getDescontosSuccess = createAction(
  '[Descontos/WS] Get Desconto Success',
  props<{ descontos: ICompraDesconto[] }>()
);

export const getDescontosSuccessFailure = createAction(
  '[Descontos/WS] Get Desconto Failure',
  props<{ error: HttpErrorResponse }>()
);

export const validarCodigo = createAction(
  '[Descontos/WS] Get Validar Codigo',
  props<{ codigo: string }>()
);

export const validarCodigoFreteSuccessValido = createAction(
  '[Descontos/WS] Get Validar Codigo Frete Success Codigo Valido',
  props<{
    codigo: string | null;
    descontosValidados: ICompraDesconto[];
    descontoFrete: number;
  }>()
);

export const validarCodigoSuccessValido = createAction(
  '[Descontos/WS] Get Validar Codigo Success Codigo Valido',
  props<{
    codigo: string | null;
    descontosValidados: ICompraDesconto[];
  }>()
);

export const validarCodigoSuccessInvalido = createAction(
  '[Descontos/WS] Get Validar Codigo Success Codigo Invalido',
  props<{
    codigo: string | null;
    descontosValidados: ICompraDesconto[];
  }>()
);

export const validarCodigoFailure = createAction(
  '[Descontos/WS] Get Validar Codigo Failure',
  props<{ error: HttpErrorResponse }>()
);

export const removerCodigo = createAction(
  '[Descontos/WS] Get Remover Codigo',
  props<{ codigo: string }>()
);

export const setarSomenteCodigoPromocional = createAction(
  '[Descontos/WS] Setar Somente Codigo',
  props<{ codigoPromocional: ICodigoPromocional }>()
);

export const getDescontosCompra = createAction(
  '[Descontos/WS] Get Descontos compra',
  props<{ compraId: number }>()
);

export const getDescontosCompraSuccess = createAction(
  '[Descontos/WS] Get Descontos compra Success',
  props<{ descontos: ICompraDesconto[] }>()
);

export const getDescontosCompraFailure = createAction(
  '[Descontos/WS] Get Descontos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const resetDescontos = createAction(
  '[Descontos/WS] limpar state de Descontos'
);
