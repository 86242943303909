import { createAction, props } from '@ngrx/store';
import { INotificacao } from '@vip/core';

export const getNotificacoes = createAction(
  '[Notificações/API] Get Notificações'
);

export const getNotificacoesSuccess = createAction(
  '[Notificações/API] Get Notificações Success',
  props<{ notificacoes: INotificacao[] }>()
);

export const getNotificacoesFailure = createAction(
  '[Notificações/API] Get Notificações Failure',
  props<{ error: string }>()
);

export const setNotificationRead = createAction(
  '[Notificações/API] Set Notification Read',
  props<{ id: number }>()
);

export const setNotificationReadSuccess = createAction(
  '[Notificações/API] Set Notification Read Success',
  props<{ id: number }>()
);

export const setNotificationReadFailure = createAction(
  '[Notificações/API] Set Notification Read Failure',
  props<{ error: string }>()
);
