<vip-base-component-skeleton [quantity]="1" listDirection="vertical">
  <ng-template #skeletonContent let-odd>
    <div class="grid grid-cols-1 gap-2 px-3">
      <ng-container
        *vipSkeleton="true; width: '40%'; className: ['mb-3']"
      ></ng-container>
    </div>
    <div class="grid grid-cols-3 gap-2 px-3 mb-3">
      <div class="border rounded p-2 flex flex-col">
        <ng-container
          *vipSkeleton="true; width: '36%'; height: '14px'; className: ['mb-1']"
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '55%'; height: '14px'"
        ></ng-container>
      </div>

      <div class="border rounded p-2 flex flex-col">
        <ng-container
          *vipSkeleton="true; width: '36%'; height: '14px'; className: ['mb-1']"
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '55%'; height: '14px'"
        ></ng-container>
      </div>

      <div class="border rounded p-2 flex flex-col">
        <ng-container
          *vipSkeleton="true; width: '36%'; height: '14px'; className: ['mb-1']"
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '55%'; height: '14px'"
        ></ng-container>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-2 px-3">
      <ng-container
        *vipSkeleton="true; width: '40%'; className: ['mb-3']"
      ></ng-container>
    </div>
    <div class="grid grid-cols-3 gap-2 px-3 mb-3">
      <div class="border rounded p-2 flex flex-col">
        <ng-container
          *vipSkeleton="true; width: '36%'; height: '14px'; className: ['mb-1']"
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '55%'; height: '14px'"
        ></ng-container>
      </div>

      <div class="border rounded p-2 flex flex-col">
        <ng-container
          *vipSkeleton="true; width: '36%'; height: '14px'; className: ['mb-1']"
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '55%'; height: '14px'"
        ></ng-container>
      </div>

      <div class="border rounded p-2 flex flex-col">
        <ng-container
          *vipSkeleton="true; width: '36%'; height: '14px'; className: ['mb-1']"
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '55%'; height: '14px'"
        ></ng-container>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-2 px-3">
      <ng-container
        *vipSkeleton="true; width: '40%'; className: ['mb-3']"
      ></ng-container>
    </div>
    <div class="grid grid-cols-3 gap-2 px-3 mb-3">
      <div class="border rounded p-2 flex flex-col">
        <ng-container
          *vipSkeleton="true; width: '36%'; height: '14px'; className: ['mb-1']"
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '55%'; height: '14px'"
        ></ng-container>
      </div>

      <div class="border rounded p-2 flex flex-col">
        <ng-container
          *vipSkeleton="true; width: '36%'; height: '14px'; className: ['mb-1']"
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '55%'; height: '14px'"
        ></ng-container>
      </div>

      <div class="border rounded p-2 flex flex-col">
        <ng-container
          *vipSkeleton="true; width: '36%'; height: '14px'; className: ['mb-1']"
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '55%'; height: '14px'"
        ></ng-container>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-2 px-3">
      <ng-container
        *vipSkeleton="true; width: '40%'; className: ['mb-3']"
      ></ng-container>
    </div>
    <div class="grid grid-cols-3 gap-2 px-3 mb-3">
      <div class="border rounded p-2 flex flex-col">
        <ng-container
          *vipSkeleton="true; width: '36%'; height: '14px'; className: ['mb-1']"
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '55%'; height: '14px'"
        ></ng-container>
      </div>

      <div class="border rounded p-2 flex flex-col">
        <ng-container
          *vipSkeleton="true; width: '36%'; height: '14px'; className: ['mb-1']"
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '55%'; height: '14px'"
        ></ng-container>
      </div>

      <div class="border rounded p-2 flex flex-col">
        <ng-container
          *vipSkeleton="true; width: '36%'; height: '14px'; className: ['mb-1']"
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '55%'; height: '14px'"
        ></ng-container>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-2 px-3">
      <ng-container
        *vipSkeleton="true; width: '40%'; className: ['mb-3']"
      ></ng-container>
    </div>
    <div class="grid grid-cols-3 gap-2 px-3 v">
      <div class="border rounded p-2 flex flex-col">
        <ng-container
          *vipSkeleton="true; width: '36%'; height: '14px'; className: ['mb-1']"
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '55%'; height: '14px'"
        ></ng-container>
      </div>

      <div class="border rounded p-2 flex flex-col">
        <ng-container
          *vipSkeleton="true; width: '36%'; height: '14px'; className: ['mb-1']"
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '55%'; height: '14px'"
        ></ng-container>
      </div>

      <div class="border rounded p-2 flex flex-col">
        <ng-container
          *vipSkeleton="true; width: '36%'; height: '14px'; className: ['mb-1']"
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '55%'; height: '14px'"
        ></ng-container>
      </div>
    </div>
  </ng-template>
</vip-base-component-skeleton>
