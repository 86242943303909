import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

export const init = createAction('[Sessao Page] Init');

export const loadSessaoSuccess = createAction(
  '[Sessao/API] Load Sessao Success',
  props<{ sessaoId: string }>()
);

export const loadSessaoFailure = createAction(
  '[Sessao/API] Load Sessao Failure',
  props<{ error: HttpErrorResponse }>()
);
