import { Directive } from '@angular/core';
import { AddEditEnderecoRequest } from '@vip/api';
import { CepFacade } from '@vip/state/cep';
import { EnderecoFacade } from '@vip/state/endereco';

@Directive()
export abstract class EnderecoBaseDirective {
  enderecoSelecionado$ = this.enderecoFacade.enderecoSelecionado$;
  possuiEnderecoCadastrado$ = this.enderecoFacade.possuiEnderecoCadastrado$;
  newEndereco$ = this.cepFacade.enderecoDoCep$;
  loadingEndereco$ = this.enderecoFacade.loading$;
  cepAtendido$ = this.cepFacade.cepAtendido$;
  loadingCep$ = this.cepFacade.loading$;
  validated = false;

  constructor(
    protected enderecoFacade: EnderecoFacade,
    protected cepFacade: CepFacade
  ) {}

  changeCep(cep: string) {
    this.cepFacade.validarCepAtendidoPelaFilial(cep);
  }

  saveEndereco(endereco: AddEditEnderecoRequest, isCompletarCadastro: boolean) {
    this.validated = true;
    this.enderecoFacade.saveEndereco(
      endereco,
      isCompletarCadastro ? '/resumo-compra' : ''
    );
  }
}
