import { Action, createReducer, on } from '@ngrx/store';
import * as AplicativoActions from './aplicativo.actions';
import { IAplicativo } from '@vip/core';
import { IGenericState } from '@vip/state/utils';

export const APLICATIVO_FEATURE_KEY = 'aplicativo';

export type AplicativoState = IGenericState<IAplicativo | null>;

export const initialState: AplicativoState = {
  status: 'pending',
  data: null,
  error: null,
};

const aplicativoReducer = createReducer(
  initialState,
  on(AplicativoActions.init, AplicativoActions.getStoreConfig, (state) => ({
    ...state,
    status: 'loading',
  })),
  on(AplicativoActions.loadAplicativoSuccess, (state, { aplicativo }) => ({
    ...state,
    data: aplicativo,
    status: 'success',
  })),
  on(
    AplicativoActions.loadAplicativoFailure,
    AplicativoActions.getStoreConfigFailure,
    (state, { error }) => ({
      ...state,
      status: 'error',
      error: error.error,
    })
  ),
  on(AplicativoActions.getStoreConfigSuccess, (state, { storeConfig }) => ({
    ...state,
    data: {
      ...state.data,
      store_config: storeConfig,
    } as IAplicativo,
    status: 'success',
  }))
);

export function reducer(state: AplicativoState | undefined, action: Action) {
  return aplicativoReducer(state, action);
}
