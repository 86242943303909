<input
  [checked]="checkboxValue"
  [disabled]="disabled"
  [value]="checkboxValue"
  type="checkbox"
  [class.size-sm]="size === 'sm'"
/>
<div
  class="check-wrapper {{ labelSize }}"
  [ngClass]="{
    'with-label': !!label,
    outline: outline,
    'size-sm': size === 'sm'
  }"
  (click)="checkOption(checkboxValue)"
></div>
<label
  #checkboxLabel
  class="label-size__{{ labelSize }}"
  [class.size-sm]="size === 'sm'"
  [class.with-label]="!!label"
  [class.outline]="outline"
  (click)="checkOption(checkboxValue)"
  [tabindex]="disabled ? -1 : 0"
  vipRipple
  rippleColor="color-primary-main"
  rippleBorderRadius="5px"
  [rippleDisabled]="disabled"
>
  {{ label }}
</label>
<div
  vipRipple
  rippleColor="color-primary-main"
  rippleBorderRadius="5px"
  [rippleDisabled]="disabled"
  (click)="checkOption(checkboxValue)"
>
  <ng-content select="[checkboxText]"></ng-content>
</div>
