<vip-base-component-skeleton class="flex flex-row" [quantity]="quantity">
  <ng-template #skeletonContent>
    <div
      class="skeleton-card rounded flex flex-col border justify-around items-center mr-2"
    >
      <ng-container
        *vipSkeleton="
          true;
          width: '35px';
          height: '35px';
          radius: '100px';
          className: ['mt-2', 'justify-center']
        "
      ></ng-container>
      <ng-container
        *vipSkeleton="
          true;
          width: '50px';
          height: '15px';
          className: ['justify-center']
        "
      ></ng-container>
    </div>
  </ng-template>
</vip-base-component-skeleton>
