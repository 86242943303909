import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { IList, IMAGES_PATH } from '@vip/core';

@Component({
  selector: 'vip-add-to-list',
  templateUrl: './add-to-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddToListComponent {
  @Input() listas: IList[] = [];

  @Output()
  listaClick: EventEmitter<IList> = new EventEmitter();

  @Output()
  novaListaClick: EventEmitter<string> = new EventEmitter();
  
  modalList = true;
  imageUrl!: string;

  constructor(@Inject(IMAGES_PATH) private imagesPath: string) {
    this.imageUrl = `${this.imagesPath}`;
  }
}
