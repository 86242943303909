import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { filter, map, switchMap } from 'rxjs/operators';

import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { BucketsFacade } from '@vip/state/buckets';
import { ProdutoApiService } from '@vip/api';
import { ProdutoStore } from './produto.store';
import { mergeTakeOne } from '@vip/state/utils';

@Injectable()
export class ProdutosOfertasExclusivasStore extends ProdutoStore {
  readonly getOfertasExclusivas = this.effect((payload$) => {
    return payload$.pipe(
      mergeTakeOne(
        this.cdFacade.filialECdSelecionado$,
        this.bucketsFacade.bucketProduto$.pipe(
          filter((bucket) => bucket !== '')
        )
      ),
      map(([, [filial, cdSelecionado], bucket]) => {
        return { filial, cdSelecionado, bucket };
      }),
      switchMap((payload) => {
        this.updateStatus('loading');
        return this.produtoApiService
          .getOfertasExclusivas(payload.filial.id, payload.cdSelecionado.id)
          .pipe(
            tapResponse(
              (response) => {
                this.updateProdutosAndPaginator(
                  {
                    produtos: response.data,
                    paginator: response.paginator,
                  },
                  payload.bucket
                );
              },
              (error) =>
                this.updateError((error as HttpErrorResponse).error.error)
            )
          );
      })
    );
  });

  constructor(
    private produtoApiService: ProdutoApiService,
    private bucketsFacade: BucketsFacade,
    private cdFacade: CentroDistribuicaoFacade
  ) {
    super();
  }
}
