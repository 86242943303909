import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountdownBarComponent } from './countdown-bar/countdown-bar.component';

@NgModule({
  imports: [CommonModule],
  declarations: [CountdownBarComponent],
  exports: [CountdownBarComponent],
})
export class CountdownBarModule {}
