<vip-infinite-scroll
  scrollListener="selector"
  [selector]="scrollContainerSelector"
  [distance]="distanceScroll"
  (loadMore)="loadMore.emit()"
>
  <div class="scroll-container" [ngClass]="{ 'pt-5': isLoading }">
    <ng-container *ngIf="filters?.length && !isLoading">
      <vip-header-filter
        class="pt-2 pb-2"
        [filters]="filters"
        [isDesktop]="isDesktop"
        [class.pl-3]="!isDesktop"
        (filterClick)="filterClick.emit($event)"
        (externalFilterClick)="onFilterClick($event)"
      ></vip-header-filter>
    </ng-container>

    <div class="pt-3 pb-4" *ngIf="carousel && carousel.slides.length > 0">
      <vip-carousel
        [slides]="carousel.slides"
        [bannersPerPage]="carousel.configuracoes.itens_por_pagina"
      ></vip-carousel>
    </div>

    <div
      #cardListContainer
      class="vip-card-grid grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2 px-3 pb-3 h-[81vh] lg:h-full auto-rows-min"
      [class.pt-3]="!(carousel && carousel.slides.length > 0)"
    >
      <ng-content></ng-content>
    </div>
  </div>
</vip-infinite-scroll>
