<aside
  class="vip-filtro-desktop h-full flex flex-col content-center pb-4 pt-2 px-3"
>
  <ng-container
    *ngFor="let filterItem of filters; let i = index; let last = last"
  >
    <vip-filtro-desktop-item
      *ngIf="filterItem.options.length > 0"
      [(filterItem)]="filters[i]"
      [qtdMaxVisibleItens]="qtdMaxVisibleItens"
      (filterItemChange)="onFiltersChange()"
    ></vip-filtro-desktop-item>
    <hr class="vip-divider my-2" *ngIf="!last" />
  </ng-container>
</aside>
