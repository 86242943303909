import { Injectable } from '@angular/core';
import { fetch } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { IPaginaInstitucional, TipoPaginaEnum } from '@vip/core';
import { InstitucionalWsService } from '@vip/ws';
import { FilialFacade } from '@vip/state/filial';
import * as InstitucionalActions from './institucional.actions';

@Injectable()
export class InstitucionalEffects {
  init$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(InstitucionalActions.getPaginasInstitucionais)),
      this.filialFacade.filial$,
    ]).pipe(
      map(([action, filial]) => {
        return { ...action, filial };
      }),
      fetch({
        run: (action) => {
          return this.institucionalWsService
            .getPaginasInstitucionais(action.filial.id)
            .pipe(
              map((institucional) => {
                institucional.paginas.unshift({
                  id: 99,
                  filial_id: action.filial.id,
                  titulo: 'Sobre',
                  slug: 'sobre',
                  conteudo: action.filial.texto_rodape,
                  exibir_aplicativo: true,
                  tipo: TipoPaginaEnum.INTERNA,
                } as IPaginaInstitucional);
                return InstitucionalActions.getPaginasInstitucionaisSuccess({
                  paginas: institucional.paginas,
                });
              })
            );
        },

        onError: (action, error) => {
          return InstitucionalActions.getPaginasInstitucionaisFailure({
            error,
          });
        },
      })
    );
  });

  constructor(
    private actions$: Actions,
    private institucionalWsService: InstitucionalWsService,
    private filialFacade: FilialFacade
  ) {}
}
