import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  OnInit,
  Output,
} from '@angular/core';
import {
  FilterTypeEnum,
  IFilter,
  PaginaFiltro,
  RouterTransitionEnum,
} from '@vip/core';
import { Location } from '@angular/common';
import { DialogService } from '@vip/ui/modal';
import { FiltroFacade, FiltrosUtilsService } from '@vip/state/filtro';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { ComboFacade } from '@vip/state/combo';
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'vip-filtro-container',
  templateUrl: './filtro-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComboFacade],
})
export class FiltroContainerComponent implements OnInit {
  @HostBinding('attr.router-transition-type') routerTransition =
    RouterTransitionEnum.BackInUp;

  @Output() filtroAplicado = new EventEmitter();
  @Output() filtrosSubject = new Subject<string>();

  combos$ = this.comboFacade.getCombosDepartamentos$;

  filtros: IFilter[] = [];
  navegacaoConfirma = true;

  constructor(
    private location: Location,
    private filtroFacade: FiltroFacade,
    private dialogService: DialogService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private comboFacade: ComboFacade,
    private filtroService: FiltrosUtilsService
  ) {}

  limparFiltro() {
    this.dialogService.openDialog({
      open: true,
      title: 'Limpar filtros aplicados?',
      disabled: false,
      buttonConfirmText: 'Sim',
      buttonCancelText: 'Não',
    });

    //eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.dialogService.dialogClick.subscribe((value) => {
      if (value) {
        this.desmarcarTodosOsFiltros();
      }
      this.dialogService.clearDialog();
    });
  }

  desmarcarTodosOsFiltros() {
    this.filtros.forEach((filtro) => {
      if (filtro.type === FilterTypeEnum.INTERNAL) {
        filtro.options.forEach((opcao) => (opcao.checked = opcao.value == '0'));
      }
    });
    this.filtros = JSON.parse(JSON.stringify(this.filtros));
    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {
    this.filtroFacade.filtroAtivo$
      .pipe(untilDestroyed(this))
      .subscribe((filtros) => {
        this.filtros = JSON.parse(JSON.stringify(filtros));
      });
  }

  confirm() {
    this.filtroFacade.filtroAtivoPagina$
      .pipe(take(1), untilDestroyed(this))
      .subscribe((paginaFiltro: PaginaFiltro | null) => {
        if (paginaFiltro) {
          const filtrouMarca = this.filtros
            .find((filtro) => filtro.name === 'marca')
            ?.options.some((option) => option.checked);
          const orderFilterIndex = this.filtros.findIndex(
            (filtro) => filtro.name === 'orderby'
          );
          const filtrouOrdenacao = this.filtros[orderFilterIndex]?.options.some(
            (option) =>
              option.checked && option.value !== 'produto.descricao:asc'
          );

          if (filtrouMarca && !filtrouOrdenacao) {
            this.filtrarMarcaOrdenada(orderFilterIndex, paginaFiltro);
          } else {
            this.filtroFacade.aplicarFiltro(this.filtros, paginaFiltro);
          }
        }
        if (paginaFiltro === 'filtrosOfertas') {
          this.router.navigate(['ofertas'], {
            queryParamsHandling: 'preserve',
            state: { filtroAplicado: true },
          });
        }
        if (paginaFiltro === 'filtrosCombos') {
          const filtroSelecionado = this.filtros
            .map((filtro) =>
              filtro.options
                .filter((option) => option.checked)
                .map((option) => option.value)
            )
            .reduce((acc, curr) => acc.concat(curr), [])[0];

          if (filtroSelecionado) {
            const resetFiltroSelected = {
              ...this.filtros[1],
              options: this.filtros[1].options.map((opcao) => ({
                ...opcao,
                checked: false,
              })),
            };

            if (filtroSelecionado === 'todos') {
              this.filtroFacade.atualizarFiltroCombo(resetFiltroSelected);
              this.filtroService.atualizarFiltros('todos');
            }

            if (resetFiltroSelected) {
              this.filtroFacade.atualizarFiltroCombo(resetFiltroSelected);
            }

            this.router.navigateByUrl('combos', {
              state: { filtroAplicado: true, filtroSelecionado },
            });

            this.comboFacade.getCombosDepartamentos({
              departamento: filtroSelecionado,
            });
          }
        }
        this.fecharFiltro();
      });
  }

  filtrarMarcaOrdenada(
    orderFilterIndex: number,
    paginaFiltro: PaginaFiltro
  ): void {
    if (orderFilterIndex >= 0) {
      this.filtros.splice(orderFilterIndex, 1);
    }

    const orderby = {
      ...this.filtroFacade.filtroOrdenacao,
      options: this.filtroFacade.filtroOrdenacao?.options.map((option) => ({
        ...option,
        checked: option.value === 'produto.descricao:asc',
      })),
    };
    this.filtroFacade.aplicarFiltro([...this.filtros, orderby], paginaFiltro);
  }

  fecharFiltro() {
    if (this.navegacaoConfirma) {
      this.location.back();
    }
    this.filtroAplicado.emit();
  }
}
