import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FilialStateModule } from '@vip/state/filial';
import { BiometricModule } from '@vip/native/biometric';
import { TermosUsoStateModule } from '@vip/state/termos-uso';

import * as fromCliente from './+state/cliente.reducer';
import { ClienteEffects } from './+state/cliente.effects';
import { ClienteFacade } from './+state/cliente.facade';
import { ClienteTokenService } from './services/cliente-token.service';
import { ValidaPinClienteGuard } from './guards/valida-pin-cliente.guard';

@NgModule({
  imports: [
    CommonModule,
    FilialStateModule,
    TermosUsoStateModule,
    StoreModule.forFeature(
      fromCliente.CLIENTE_FEATURE_KEY,
      fromCliente.reducer
    ),
    EffectsModule.forFeature([ClienteEffects]),
    BiometricModule,
  ],
  providers: [ClienteFacade, ValidaPinClienteGuard, ClienteTokenService],
})
export class ClienteStateModule {}
