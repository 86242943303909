import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IStorage, STORAGE, WEB_PUSH_TOKEN } from '@vip/core';
import { AplicativoFacade } from '@vip/state/aplicativo';
import { FilialFacade } from '@vip/state/filial';
import { NotificacaoWsService } from '@vip/ws';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { NotificationUtilsService } from '@vip/native/push-notification';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  filial$ = this.filialFacade.filial$;
  constructor(
    protected http: HttpClient,
    private filialFacade: FilialFacade,
    private notificacaoWsService: NotificacaoWsService,
    private notificationUtilsService: NotificationUtilsService,
    @Inject(STORAGE) readonly storage: IStorage,
    private aplicativoFacade: AplicativoFacade
  ) {}
  initializeApp(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        this.aplicativoFacade.aplicativo$
          .pipe(
            filter((aplicativo) => aplicativo !== null),
            take(1)
          )
          .subscribe((aplicativo) => {
            const firebaseConfig = {
              apiKey: aplicativo?.firebase_web_api_key ?? '',
              authDomain: `${aplicativo?.firebase_projeto_id}.firebaseapp.com`,
              databaseURL: `https://${aplicativo?.firebase_projeto_id}.firebaseio.com`,
              projectId: aplicativo?.firebase_projeto_id,
              storageBucket: `${aplicativo?.firebase_projeto_id}.appspot.com`,
              messagingSenderId: aplicativo?.firebase_id_remetente,
              appId: aplicativo?.firebase_web_id,
            };

            initializeApp(firebaseConfig);
          });

        navigator.serviceWorker
          .register('/firebase-messaging-sw.js', {
            scope: '/firebase-cloud-messaging-push-scope',
          })
          .then((registration) => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging)
      .then((currentToken) => {
        if (currentToken) {
          this.saveToken(currentToken);
        }
      })
      .catch((err) => {
        console.error('Erro ao obter token:', err);
      });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, async (payload) => {
      this.notificationUtilsService.showForegroundNotification(payload);
    });
  }

  public saveToken(token: any) {
    this.filial$
      .pipe(
        distinctUntilChanged(),
        map((filial) => {
          this.notificacaoWsService
            .saveToken(token, 'web', filial.id)
            .subscribe((response) => {
              if (response.success) {
                return this.storage.setItem(WEB_PUSH_TOKEN, token);
              }
            });
        })
      )
      .subscribe();
  }
}
