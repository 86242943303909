<div
  class="vip-screen-title-fixed"
  [ngClass]="{ 'vip-screen-title-relative': isDesktop }"
  *ngIf="shouldVisible"
>
  <header [class.with-subtitle]="!!subtitle" [class.desktop]="isDesktop">
    <ng-content></ng-content>
    <div *ngIf="title || subtitle" class="screen-title-text text-center">
      <span
        [ngClass]="{ 'vip-screen-title-desktop': isDesktop }"
        *ngIf="!isLoading"
        class="vip-screen-title-title"
        data-cy="vip-screen-title-title"
        >{{ title }}
      </span>
      <span
        *ngIf="subtitle && !isLoading"
        class="vip-screen-title-subtitle"
        data-cy="vip-screen-title-subtitle"
        >{{ subtitle }}
      </span>

      <ng-container
        *vipSkeleton="isLoading; width: '60%'; className: ['justify-center']"
      ></ng-container>
    </div>

    <div *ngIf="!customActions" class="vip-screen-title-action">
      <vip-button-icon-rounded
        *ngIf="leftIcon"
        goBack
        [ngClass]="{ 'vip-screen-title-desktop': isDesktop }"
        [isLoading]="isLoading"
        type="outline"
        data-cy="voltar"
        (btnClick)="goBackClicked.emit()"
      >
        <i [class]="leftIcon" [style.color]="leftIconColor"></i>
      </vip-button-icon-rounded>

      <vip-button-icon-rounded
        *ngIf="leftClose"
        [isLoading]="isLoading"
        type="outline"
        data-cy="close"
        (btnClick)="closeClicked.emit()"
      >
        <i
          class="icon-close"
          [class]="leftClose"
          [style.color]="leftIconColor"
        ></i>
      </vip-button-icon-rounded>
    </div>

    <div *ngIf="!customActions" class="vip-screen-title-action">
      <vip-button-icon-rounded
        *ngIf="rightIcon"
        [isLoading]="isLoading"
        type="outline"
        (click)="onRightIconClick()"
      >
        <i
          *ngIf="!isDesktop"
          [class]="rightIcon"
          [style.color]="rightIconColor"
        ></i>
        <span
          class="vip-color-error-main font-roboto text-xs font-medium tracking-custom"
          *ngIf="rightText && isDesktop"
          >Limpar</span
        >
      </vip-button-icon-rounded>
    </div>

    <div *ngIf="showSearchButton" class="vip-screen-title-action">
      <vip-button-icon-rounded
        rightIcon
        class="z-10"
        data-cy="vip_header_search"
        type="outline"
      >
        <i
          *ngIf="imageLoadComplete || !isLoading"
          (click)="handleSearchClick($event)"
          class="icon-search"
          [class.pt-5]="notShowingImage"
          data-cy="vip_header_search"
        >
        </i>
      </vip-button-icon-rounded>
    </div>
  </header>
  <hr class="vip-divider" />
</div>
<div
  class="vip-screen-title-margin"
  *ngIf="!isDesktop"
  [class.with-subtitle]="!!subtitle"
></div>
