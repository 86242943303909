import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';

import * as ConfiguracaoOmnichannelActions from './configuracao-omnichannel.actions';
import { OmnichannelApiService } from '@vip/api';
import { map, withLatestFrom } from 'rxjs/operators';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { BucketsFacade } from '@vip/state/buckets';
import {
  IConfiguracoesOmnichannel,
  LayoutUtilsService,
  RouteTranslateService,
} from '@vip/core';

@Injectable()
export class ConfiguracaoOmnichannelEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfiguracaoOmnichannelActions.loadConfiguracaoOmnichannel),
      withLatestFrom(this.cdFacade.filialECdSelecionado$),
      map(([action, [filial, cdSelecionado]]) => ({
        ...action,
        filial,
        cdSelecionado,
      })),
      fetch({
        run: (action) => {
          const { filial, cdSelecionado } = action;
          return this.omnichannelApiService
            .getConfiguracoesPorFilialIdECdId(
              filial.id,
              cdSelecionado.id,
              action.dispositivo
            )
            .pipe(
              withLatestFrom(this.bucketsFacade.bucketS3$),
              map(([res, bucket]) =>
                ConfiguracaoOmnichannelActions.loadConfiguracaoOmnichannelSuccess(
                  {
                    configuracaoOmnichannel: this.prepareConfig(
                      res.data,
                      bucket
                    ),
                  }
                )
              )
            );
        },
        onError: (action, error) =>
          ConfiguracaoOmnichannelActions.loadConfiguracaoOmnichannelFailure({
            error,
          }),
      })
    )
  );

  constructor(
    private actions$: Actions,
    private cdFacade: CentroDistribuicaoFacade,
    private omnichannelApiService: OmnichannelApiService,
    private bucketsFacade: BucketsFacade,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  private prepareLink(link: string) {
    const isDesktop = this.layoutUtilsService.isDesktop();
    return RouteTranslateService.getLinkInterno(link, isDesktop);
  }

  private prepareConfig(configs: IConfiguracoesOmnichannel[], bucket?: string) {
    return configs.map((config) => {
      const banners = config.banners.map((banner) => {
        return {
          ...banner,
          src: `${bucket}/files/banners_homes_omni_imagens/${banner.src}`,
        };
      });

      return {
        ...config,
        link: this.prepareLink(config.link),
        banners,
        icone: config.icone
          ? `${bucket}/files/homes_omni_elementos/${config.icone}`
          : '',
      };
    });
  }
}
