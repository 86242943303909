import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PagamentoCarteiraDigitalBaseDirective } from '../pagamento-carteira-digital/pagamento-carteira-digital-base.directive';

@UntilDestroy()
@Component({
  selector: 'vip-pagamento-carteira-digital-desktop',
  templateUrl: './pagamento-carteira-digital-desktop.component.html',
  styleUrls: ['./pagamento-carteira-digital-desktop.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagamentoCarteiraDigitalDesktopComponent extends PagamentoCarteiraDigitalBaseDirective {}
