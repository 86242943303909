<div *ngIf="produto" class="vip-card-produto-lista">
  <div (click)="!disabled && handleCardClick()" class="flex flex-row">
    <vip-tag
      *ngIf="produto.em_oferta && showTag && exibetagPrecoOfertaAtacarejo"
      [attr.data-cy]="'card_produto_lista_' + produto.id + '_tag'"
      [classeOferta]="produto.oferta?.classe_oferta"
      [label]="getPercentOferta()"
      [disableClick]="disabled"
    ></vip-tag>

    <vip-image
      [alt]="produto.descricao"
      [defaultImageUrl]="defaultImageUrl"
      [imageUrl]="
        produto.imagem ? replaceImageSize(produto.imagem, '144x144') : ''
      "
      [size]="imageSizesEnum.md"
      class="pr-2 flex justify-center flex-shrink-0"
    ></vip-image>

    <div
      class="flex flex-col w-full justify-between"
      [ngClass]="showLayoutReceita ? ' w-[60%]' : 'w-full'"
    >
      <div class="flex flex-row justify-between">
        <span
          [ngClass]="{ 'hover:cursor-pointer': isDesktop }"
          class="vip-card-produto-lista-descricao text-xs mb-1 vip-color-first-default"
        >
          {{ produto.descricao }}
        </span>
        <i
          (click)="handleDeleteItemClick($event)"
          *ngIf="showIconDelete"
          [ngClass]="disabled ? 'vip-color-disabled' : 'vip-color-error-main'"
          class="icon-delete_outline text-xl leading-none cursor-pointer"
          data-cy="delete-button"
        ></i>
      </div>

      <div class="flex flex-row justify-between items-end">
        <div class="flex flex-col w-50 justify-between">
          <span
            *ngIf="detalheOferta !== '' && isCombo && produto.classe_oferta"
            [attr.data-cy]="'card_produto_lista_' + produto.id + '_oferta'"
            [ngClass]="[
              produto.classe_oferta ||
                produto.oferta?.classe_oferta ||
                'vip-color-secondary-default'
            ]"
            class="text-xs tag-oferta rounded font-bold"
            >{{ detalheOferta }}</span
          >
          <span
            *ngIf="!isBrinde && exibetagPrecoOfertaAtacarejo"
            [attr.data-cy]="'card_produto_lista_' + produto.id + '_preco'"
            class="text-xs vip-color-third-default"
            [ngClass]="{
              'line-through': (produto.em_oferta || isCombo) && !isBrinde
            }"
            >{{ produtoUnidadePreco }}</span
          >
          <span
            *ngIf="!isCombo && !isBrinde"
            [attr.data-cy]="'card_produto_lista_' + produto.id + '_preco'"
            class="text-xs vip-color-third-default"
            >{{ produtoPrecoComOferta }}</span
          >
          <span
            [attr.data-cy]="'card_produto_lista_' + produto.id + '_preco'"
            class="text-sm vip-color-first-default font-bold"
            >{{ produtoPreco }}</span
          >
        </div>
        <vip-spin
          (changed)="handleSpinAlterado($event)"
          *ngIf="showSpin && !isBrinde"
          [attr.data-cy]="'card_produto_lista_' + produto.id + '_spin'"
          [confimDeletion]="confirmDeletion"
          [max]="produto.quantidade_maxima"
          [quantityUnity]="produto | quantidadeUnidadeDiferenteProduto"
          [quantity]="quantidade"
          [subTitleModalConfimDeletion]="produto.descricao"
          [unityMeasureAbbreviation]="produto.unidade_sigla"
          [disabled]="disabled"
          class="w-50"
          size="small"
          [styleSpin]="isDesktop"
          [possuiUnidadeDiferente]="
            produto.possui_unidade_diferente && !this.isReceitaRoute()
          "
          [seletorDePesoHabilitado]="
            produto.habilitar_seletor_unidade_peso && !this.isReceitaRoute()
          "
          [tipoDeMedida]="produto.seletor_medida_id"
          [hiddenSeletor]="hiddenSeletor"
          [produto]="produto"
          (click)="$event.stopPropagation()"
        >
        </vip-spin>
        <span
          *ngIf="showUnidade && !showSpin && !isBrinde && produto.disponivel"
          [attr.data-cy]="'card_produto_lista_' + produto.id + '_unidade'"
          class="vip-color-first-default text-xs"
        >
          {{
            produto.unidade_sigla.toLowerCase() !== 'un'
              ? (totalUnidade | unidadeDiferente: produto.unidade_sigla:true)
              : (quantidade | i18nPlural: quantityMessageMap)
          }}
        </span>

        <span
          *ngIf="produto.disponivel === false"
          [attr.data-cy]="'card_produto_lista_' + produto.id + '_indisponivel'"
          class="text-xs vip-color-error-main ml-4"
          >Indisponível</span
        >
      </div>
    </div>
  </div>
  <div
    *ngIf="produto.permitir_observacao_na_compra && showObservacaoProduto"
    [attr.data-cy]="'card_produto_lista_' + produto.id + '_observacao'"
  >
    <vip-card-produto-observacao
      (changeObservacao)="handleChangeObservacao(produto, $event)"
      [observacao]="produto.observacao || ''"
      [disabled]="disabled"
      [isDesktop]="isDesktop"
    ></vip-card-produto-observacao>
  </div>
</div>
