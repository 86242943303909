import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardTipoPagamentoComponent } from './card-tipo-pagamento/card-tipo-pagamento.component';
import { SelectableCardModule } from '@vip/ui/selectable-card';

@NgModule({
  imports: [CommonModule, SelectableCardModule],
  declarations: [
    CardTipoPagamentoComponent
  ],
  exports:[
    CardTipoPagamentoComponent
  ]
})
export class CardTipoPagamentoModule {}
