import { Inject, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { IS_APP, IStorage, STORAGE, WEB_PUSH_TOKEN } from '@vip/core';
import { FilialFacade } from '@vip/state/filial';
import { ClienteWsService, NotificacaoWsService } from '@vip/ws';
import { noop } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { NotificationUtilsService } from './notification-utils/notification-utils.service';

const PUSH_NOTIFICATION_TOKEN_ID = 'notification_token';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  pushNotifications = PushNotifications;
  filial$ = this.filialFacade.filial$;
  isIos = this.platform.is('ios');

  constructor(
    private clienteWsService: ClienteWsService,
    private notificacaoWsService: NotificacaoWsService,
    private filialFacade: FilialFacade,
    private platform: Platform,
    @Inject(STORAGE) readonly storage: IStorage,
    @Inject(IS_APP) private isApp: boolean,
    private notificationUtilsService: NotificationUtilsService
  ) {}

  async initialize() {
    await this.requestPermission();
    await this.registerListener();
    await this.pushNotificationReceived();
    await this.pushNotificationActionPerformed();
  }

  async requestPermission() {
    let permStatus = await this.pushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt') {
      permStatus = await this.pushNotifications.requestPermissions();
    }

    if (permStatus.receive === 'granted') {
      this.register();
    }
  }

  register() {
    if (Capacitor.isPluginAvailable('PushNotifications'))
      this.pushNotifications.register();
  }

  registrarDispositivo() {
    const token = this.getNotificationToken() || this.getPushWebToken();
    if (token)
      this.filial$
        .pipe(
          take(1),
          map((filial) => {
            this.clienteWsService
              .registrarDispositivo(
                token,
                this.isApp ? (this.isIos ? 'ios' : 'android') : 'web',
                filial.id
              )
              .subscribe();
          })
        )
        .subscribe();
  }

  removerDispositivo() {
    const token = this.getNotificationToken() || this.getPushWebToken();
    if (token)
      this.filial$
        .pipe(
          take(1),
          map((filial) => {
            this.clienteWsService
              .removerDispositivo(token, filial.id)
              .subscribe();
          })
        )
        .subscribe();
  }

  async registerListener() {
    await this.pushNotifications.addListener('registration', (token: Token) => {
      const tokenSaved = this.getNotificationToken();

      if (tokenSaved !== token.value) {
        this.filial$
          .pipe(
            distinctUntilChanged(),
            map((filial) => {
              this.notificacaoWsService
                .saveToken(
                  token.value,
                  this.isIos ? 'ios' : 'android',
                  filial.id
                )
                .subscribe();
            })
          )
          .subscribe();

        this.saveNotificationToken(token.value);
      }
    });
    await this.pushNotifications.addListener(
      'registrationError',
      (error: unknown) => {
        console.log('Error ao registrar: ' + JSON.stringify(error));
      }
    );
  }

  pushNotificationReceived() {
    return this.pushNotifications.addListener(
      'pushNotificationReceived',
      async (pushNotification: PushNotificationSchema) => {
        if (!pushNotification.data || this.isIos) return;
        this.notificationUtilsService.showForegroundNotification(
          pushNotification
        );
      }
    );
  }

  pushNotificationActionPerformed() {
    return this.pushNotifications.addListener(
      'pushNotificationActionPerformed',
      (action: ActionPerformed) => {
        if (!action.notification || !action.notification.data) return;

        const data = this.notificationUtilsService.generateNotificationData(
          action.notification.data
        );

        const { id: notificationId } = data;

        this.notificationUtilsService.performAction(data);
        if (notificationId) {
          this.notificationUtilsService
            .setNotificationRead(notificationId)
            .subscribe(noop);
        }
      }
    );
  }

  saveNotificationToken(token: string) {
    this.storage.setItem(PUSH_NOTIFICATION_TOKEN_ID, token);
  }

  getNotificationToken() {
    return this.storage.getItem(PUSH_NOTIFICATION_TOKEN_ID);
  }

  getPushWebToken() {
    return this.storage.getItem(WEB_PUSH_TOKEN);
  }
}
