import { ISelectOptions } from '../interfaces/select-options.interface';

export const GeneroOptions: ISelectOptions[] = [
  {
    value: 'N',
    text: 'Prefiro não informar',
  },
  {
    value: 'F',
    text: 'Feminino',
  },
  {
    value: 'M',
    text: 'Masculino',
  },
];
