import { CurrencyPipe } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'menuInfo',
})
export class MenuInfosPipe implements PipeTransform {
  constructor(@Inject(CurrencyPipe) private currencyPipe: CurrencyPipe) {}

  transform(input: {
    key: string;
    exibeSaldo: boolean;
    saldo: number;
    erroSaldo: string | null;
    notifications: number;
  }): string {
    if (input.key === 'cashback') {
      const formattedSaldo =
        this.currencyPipe.transform(input.saldo, 'BRL') || '';
      if (input.erroSaldo) {
        return 'R$ --,--';
      } else if (!input.exibeSaldo) {
        return '';
      } else {
        return formattedSaldo;
      }
    } else if (input.key === 'notifications') {
      return input.notifications.toString();
    }
    return '';
  }
}
