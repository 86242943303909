import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TelevendasComponent } from './televendas/televendas.component';
import { TelevendasStateModule } from '@vip/state/televendas';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    TelevendasStateModule,
    RouterModule.forChild([
      {
        path: '',
        component: TelevendasComponent,
      },
    ]),
  ],
  declarations: [TelevendasComponent],
})
export class TelevendasContainerModule {}
