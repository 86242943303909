import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldComponent } from './field/field.component';
import { CoreModule, ValidationMessagesPipe } from '@vip/core';
import { SkeletonModule } from '@vip/ui/skeleton';

@NgModule({
  imports: [CoreModule, CommonModule, SkeletonModule],
  providers: [ValidationMessagesPipe],
  declarations: [FieldComponent],
  exports: [FieldComponent],
})
export class FieldModule {}
