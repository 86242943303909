import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IProdutoCombo } from '@vip/core';
import { ISpinEvent } from '@vip/ui/spin';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'vip-card-combo-desktop',
  templateUrl: './card-combo-desktop.component.html',
  styleUrls: ['./card-combo-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComboDesktopComponent implements OnInit {
  @Input() combo!: IProdutoCombo;
  @Input() isDesktop = false;
  @Input() isTablet = false;
  @Input() hasProductInfo = false;
  @Input() valorDiferenca?: string;
  @Input() editable = false;
  @Input() loading = false;
  @Input() showSpinLarge = false;
  @Input() quantidade = 0;
  @Input() swiperConfig: SwiperOptions = {};
  @Input() fullPage = false;
  @Input() comboCategory = false;
  @Input() hasCombo = false;
  @Input() hasCarrinho = false;
  @Input() quantidadeMaximaSpinner = 0;
  @Output() handleSpin = new EventEmitter();

  sizePage = 0;

  ngOnInit(): void {
    this.sizePage = window.innerWidth;
  }

  handleSpinAlterado(event: ISpinEvent) {
    this.handleSpin.emit(event);
  }
}
