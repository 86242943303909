import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectableTileComponent } from './selectable-tile/selectable-tile.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SelectableTileComponent],
  exports: [SelectableTileComponent],
})
export class SelectableTileModule {}
