export enum BucketModel {
  Produto = 'Produto',
  S3 = 'S3',
  Placeholder = 'Placeholder',
  Categoria = 'Categoria',
}

export interface IBucket {
  model: BucketModel;
  localizacao: string;
  id: number;
}
