import { SelectorUtil } from '@vip/state/utils';

import { FIDELIDADE_FEATURE_KEY, FidelidadeState } from './fidelidade.reducer';
import { createSelector } from '@ngrx/store';
import { IProdutoCompra, IProdutoFidelidade } from '@vip/core';

class FidelidadeSelectors extends SelectorUtil<FidelidadeState> {
  constructor() {
    super(FIDELIDADE_FEATURE_KEY);
  }

  getDescontosFidelidade = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FidelidadeState) => state.data
    );

  getIsFidelidadeAtivo = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FidelidadeState) => state.isFidelidadeAtivo
    );

  getProdutosComDescontosFidelidade = () =>
    createSelector(this.getDescontosFidelidade(), (descontoFidelidade) =>
      descontoFidelidade?.produtos.filter(
        (produto: IProdutoFidelidade) => produto.descontos.length > 0
      )
    );

  getTotalDescontosProdutos = () =>
    createSelector(
      this.getProdutosComDescontosFidelidade(),
      (produtosFidelidade) => {
        if (produtosFidelidade) {
          return produtosFidelidade.reduce((accum, curr) => {
            return curr.descontos.reduce((accum2, desc) => {
              return (accum2 += Number(
                desc.descontoUnitario * desc.quantidade
              ));
            }, accum);
          }, 0);
        } else {
          return 0;
        }
      }
    );

  getTotalDescontosCompra = () =>
    createSelector(this.getDescontosFidelidade(), (descontoFidelidade) => {
      if (descontoFidelidade) {
        return descontoFidelidade.descontos.reduce(function (accum, curr) {
          return accum + curr.valor;
        }, 0);
      } else {
        return 0;
      }
    });

  getTotalDescontosFidelidade = () =>
    createSelector(
      this.getTotalDescontosCompra(),
      this.getTotalDescontosProdutos(),
      (totalDescontoCompra: number, totalDescontoProdutos: number) =>
        totalDescontoCompra + totalDescontoProdutos
    );

  getProdutosDescontosFidelidadeAsProdutoCompra = () =>
    createSelector(this.getProdutosComDescontosFidelidade(), (produtos) => {
      if (produtos && produtos.length > 0) {
        return produtos.map(this.convertProdutoFidelidadeToProdutoCompra, this);
      } else {
        return [];
      }
    });

  getTotalDescontoProduto(produto: IProdutoFidelidade) {
    if (!produto || produto.descontos.length < 1) return 0;
    return produto.descontos.reduce((descontoAcomulado, desc) => {
      return (descontoAcomulado += Number(desc.descontoUnitario));
    }, 0);
  }

  convertProdutoFidelidadeToProdutoCompra(
    produtoFidelidade: IProdutoFidelidade
  ): IProdutoCompra {
    return {
      produto_id: produtoFidelidade.id,
      quantidade: produtoFidelidade.quantidade,
      preco_venda: produtoFidelidade.preco,
      id: produtoFidelidade.id,
      descricao: produtoFidelidade.descricao,
      preco: produtoFidelidade.preco,
      desconto:
        produtoFidelidade.preco -
        this.getTotalDescontoProduto(produtoFidelidade),
      possui_unidade_diferente: false,
      quantidade_unidade_diferente: 1,
      disponivel: true,
      link: '',
      imagem: produtoFidelidade.imagem,
      unidade_sigla: 'UN',
    };
  }

  getFidelidadeConfiguracoes = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FidelidadeState) => state.fidelidadeConfiguracoes
    );
}

export const fidelidadeSelectors = new FidelidadeSelectors();
