import { IEndereco } from '../interfaces';

export const endereco1Mock: IEndereco = {
  bairro: 'São Salvador',
  cep: '30881-640',
  cidade: 'Belo Horizonte',
  cliente: null,
  cliente_id: 11073,
  complemento: null,
  desativado: false,
  endereco_formatado:
    'Rua General Clark - até 487/488, nr.570 - São Salvador - Belo Horizonte/MG',
  filial_origem_id: 1,
  id: 101,
  latitude: '-19.891804',
  logradouro: 'Rua General Clark - até 487/488',
  longitude: '-44.0163281',
  numero: '570',
  principal: false,
  referencia: 'Próximo à Abílio Machado',
  sigla: 'MG',
  telefone: '3141410305',
  titulo: 'Endereço principal',
  uf: 'Minas Gerais',
};

export const endereco2Mock: IEndereco = {
  bairro: 'São Salvador',
  cep: '30881-641',
  cidade: 'Belo Horizonte',
  cliente: null,
  cliente_id: 11073,
  complemento: null,
  desativado: false,
  endereco_formatado:
    'Rua General Clark - até 487/488, nr.570 - São Salvador - Belo Horizonte/MG',
  filial_origem_id: 1,
  id: 14553,
  latitude: '-19.891804',
  logradouro: 'Rua General Clark - até 487/488',
  longitude: '-44.0163281',
  numero: '570',
  principal: false,
  referencia: 'Próximo à Abílio Machado',
  sigla: 'MG',
  telefone: '3141410305',
  titulo: 'Endereço principal',
  uf: 'Minas Gerais',
};

export const enderecoPrincipalMock: IEndereco = {
  bairro: 'São Salvador',
  cep: '30881-640',
  cidade: 'Belo Horizonte',
  cliente: null,
  cliente_id: 11073,
  complemento: null,
  desativado: false,
  endereco_formatado:
    'Rua General Clark - até 487/488, nr.570 - São Salvador - Belo Horizonte/MG',
  filial_origem_id: 1,
  id: 102,
  latitude: '-19.891804',
  logradouro: 'Rua General Clark - até 487/488',
  longitude: '-44.0163281',
  numero: '570',
  principal: true,
  referencia: 'Próximo à Abílio Machado',
  sigla: 'MG',
  telefone: '3141410305',
  titulo: 'Endereço principal',
  uf: 'Minas Gerais',
};

export const addEnderecoRequestMock = {
  bairro: 'São Salvador',
  cep: '30881-640',
  cidade: 'Belo Horizonte',
  logradouro: 'Rua General Clark - até 487/488',
  numero: '570',
  principal: false,
  referencia: 'Próximo à Abílio Machado',
  telefone: '3141410305',
  titulo: 'Endereço principal',
  uf: 'Minas Gerais',
  sigla: 'MG',
};

export const editEnderecoRequestMock = {
  id: 103,
  ...addEnderecoRequestMock,
};

export const enderecoCasaMock: IEndereco = {
  bairro: 'Santo Antonio',
  cep: '30881-640',
  cidade: 'Belo Horizonte',
  cliente: null,
  cliente_id: 11073,
  complemento: 'Apto 506',
  desativado: false,
  endereco_formatado:
    'Rua General Clark - até 487/488, nr.570 - São Salvador - Belo Horizonte/MG',
  filial_origem_id: 1,
  id: 104,
  latitude: '-19.891804',
  logradouro: 'Av. Coletor Celso Werneck',
  longitude: '-44.0163281',
  numero: '357',
  principal: true,
  referencia: 'Próximo à Abílio Machado',
  sigla: 'MG',
  telefone: '3141410305',
  titulo: 'Casa',
  uf: 'Minas Gerais',
};

export const enderecoCompraMock: IEndereco = {
  titulo: 'Cadastro via script - 18',
  endereco_formatado:
    'Rua José Sebastião Daher, nr.19 - Caiçaras - Belo Horizonte/MG',
  logradouro: 'Avenida José Faria da Rocha',
  numero: '1000',
  complemento: '',
  bairro: 'Eldorado',
  cep: '30820-000',
  desativado: false,
  id: 0,
  principal: false,
};
