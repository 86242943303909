import { SelectorUtil } from '@vip/state/utils';

import {
  CONFIGURACAO_OMNICHANNEL_FEATURE_KEY,
  OmnichannelState,
} from './configuracao-omnichannel.reducer';

export class ConfiguracaoOmnichannelSelectors extends SelectorUtil<OmnichannelState> {
  constructor() {
    super(CONFIGURACAO_OMNICHANNEL_FEATURE_KEY);
  }
}

export const configuracaoOmnichannelSelectors =
  new ConfiguracaoOmnichannelSelectors();
