import { Inject, Injectable } from '@angular/core';
import {
  ICentroDistribuicao,
  IEndereco,
  IPreferencias,
  IStorage,
  STORAGE,
} from '@vip/core';
import { take } from 'rxjs/operators';
import { ClienteFacade } from '../+state/cliente.facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export const PREFERENCIAS = 'preferencias';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PreferenciasService {
  private preferencias: IPreferencias | null = null;

  constructor(
    private clienteFacade: ClienteFacade,
    @Inject(STORAGE) readonly storage: IStorage
  ) {}

  getPreferencias(isEntrega?: boolean): IPreferencias | null {
    this.clienteFacade.cliente$
      .pipe(untilDestroyed(this), take(1))
      .subscribe((cliente) => {
        const preferenciasString = this.storage.getItem(PREFERENCIAS);

        if (cliente && cliente.id && preferenciasString) {
          const preferencias = JSON.parse(preferenciasString);

          if (preferencias.clienteId == cliente.id) {
            this.preferencias = preferencias;
          } else {
            this.startPreferencias(cliente.id, isEntrega);
          }
        } else if (cliente && !preferenciasString) {
          this.startPreferencias(cliente.id, isEntrega);
        } else {
          this.clearPreferencias();
        }
      });
    return this.preferencias;
  }

  setEnderecoEntrega(enderecoEntrega: IEndereco) {
    this.storage.setItem(
      PREFERENCIAS,
      JSON.stringify({ ...this.preferencias, enderecoEntrega: enderecoEntrega })
    );
    this.getPreferencias();
  }

  setIsEntrega(isEntrega: boolean) {
    this.storage.setItem(
      PREFERENCIAS,
      JSON.stringify({ ...this.preferencias, isEntrega: isEntrega })
    );
    this.getPreferencias(isEntrega);
  }

  private startPreferencias(cdCliente: number, isEntrega?: boolean) {
    this.preferencias = {
      clienteId: cdCliente,
      isEntrega,
    };

    this.storage.setItem(PREFERENCIAS, JSON.stringify(this.preferencias));
  }

  clearPreferencias() {
    this.preferencias = null;
    this.storage.removeItem(PREFERENCIAS);
  }
}
