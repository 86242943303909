import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../common/base-api-service';
import { BaseApiResponse } from '../common/base-api-response';
import { IFilter } from '@vip/core';

@Injectable({
  providedIn: 'root',
})
export class FiltroApiService {
  constructor(private api: BaseApiService<FiltroApiService>) {}

  getFiltroApiDepartamento(
    filialId: number,
    cdId: number,
    cmId: number
  ): Observable<BaseApiResponse<{ filtro: IFilter[] }>> {
    const prefix = `/filial/${filialId}/centro_distribuicao/${cdId}`;
    const route = `/loja/classificacoes_mercadologicas/departamentos/${cmId}/produtos/filtro`;

    return this.api.get('getFiltroApiDepartamento', `${prefix}${route}`);
  }

  getFiltroApiSecao(
    filialId: number,
    cdId: number,
    cmId: number
  ): Observable<BaseApiResponse<{ filtro: IFilter[] }>> {
    const prefix = `/filial/${filialId}/centro_distribuicao/${cdId}`;
    const route = `/loja/classificacoes_mercadologicas/secoes/${cmId}/produtos/ativos/filtro`;

    return this.api.get('getFiltroApiSecao', `${prefix}${route}`);
  }

  getFiltroApiFavoritos(
    filialId: number,
    cdId: number
  ): Observable<BaseApiResponse<{ filtro: IFilter[] }>> {
    const prefix = `/filial/${filialId}/centro_distribuicao/${cdId}`;
    const route = `/loja/produtos/favoritos/filtro`;
    return this.api
      .get<BaseApiResponse<{ filtro: IFilter[] }>>(
        'getFiltroApiFavoritos',
        `${prefix}${route}`
      )
      .pipe();
  }

  getFiltrosBusca(
    filialId: number,
    cdId: number,
    buscaId: number,
    filtro?: string
  ): Observable<BaseApiResponse<IFilter[]>> {
    const prefix = `/filial/${filialId}/centro_distribuicao/${cdId}`;
    const route = `/loja/buscas/filtro/${buscaId}`;

    return this.api.get<BaseApiResponse<IFilter[]>>(
      'getFiltrosBusca',
      `${prefix}${route}${filtro ? '?' + filtro : ''}`
    );
  }

  getFiltrosColecao(
    filialId: number,
    cdId: number,
    colecaoId: number,
    filtro?: string
  ): Observable<BaseApiResponse<IFilter[]>> {
    const prefix = `/filial/${filialId}/centro_distribuicao/${cdId}`;
    const route = `/loja/colecoes/${colecaoId}/produtos/filtro`;
    return this.api
      .get<BaseApiResponse<IFilter[]>>(
        'getFiltrosColecao',
        `${prefix}${route}${filtro ? '?' + filtro : ''}`
      )
      .pipe();
  }

  getFiltroApiMinhaLista(
    listaId: number,
    filialId: number,
    cdId: number
  ): Observable<BaseApiResponse<{ filtro: IFilter[] }>> {
    const prefix = `/filial/${filialId}/centro_distribuicao/${cdId}`;
    const route = `/loja/listas/${listaId}/filtro`;
    return this.api.get<BaseApiResponse<{ filtro: IFilter[] }>>(
      'getFiltroApiMinhaLista',
      `${prefix}${route}`
    );
  }

  getFiltrosOfertas(
    filialId: number,
    cdId: number
  ): Observable<BaseApiResponse<{ filtro: IFilter[] }>> {
    const prefix = `/filial/${filialId}/centro_distribuicao/${cdId}`;
    const route = `/loja/produtos/em-oferta/filtro`;
    return this.api.get<BaseApiResponse<{ filtro: IFilter[] }>>(
      'getFiltrosOfertas',
      `${prefix}${route}`
    );
  }
}
