import { Inject, Injectable } from '@angular/core';
import { STORAGE, IStorage } from '@vip/core';

const SESSAO_TOKEN = 'sessao-id';

@Injectable({
  providedIn: 'root',
})
export class SessaoService {
  private sessaoIdCache: string | null = null;

  constructor(@Inject(STORAGE) readonly storage: IStorage) {}

  getSessaoId(): string {
    if (this.sessaoIdCache) {
      return this.sessaoIdCache;
    }
    this.sessaoIdCache = this.storage.getItem(SESSAO_TOKEN) || '';
    return this.sessaoIdCache;
  }

  setSessaoId(value: string) {
    this.storage.setItem(SESSAO_TOKEN, value);
  }

  clearSessaoId() {
    this.sessaoIdCache = null;
    this.storage.removeItem(SESSAO_TOKEN);
  }
}
