import { Injectable } from '@angular/core';
import { DialogService, IDialog } from '@vip/ui/modal';
import { Router } from '@angular/router';
import { CompraEmProcessoFacade } from '../+state/compra-em-processo.facade';
import { ICompra } from '@vip/core';

@Injectable()
export class CompraEmProcessoService {
  readonly dialog: IDialog = {
    open: true,
    title: 'Compra não finalizada',
    subTitle:
      'Sua compra está em processo de finalização. Deseja continuar o processo?',
    disabled: false,
    buttonConfirmText: 'Sim',
    buttonCancelText: 'Agora não',
  };
  compraEmProcesso = false;

  constructor(
    private compraEmProcessoFacade: CompraEmProcessoFacade,
    private dialogService: DialogService,
    private router: Router
  ) {
    this.compraEmProcessoFacade.compraEmProcesso$.subscribe(
      (compraEmProcesso) => {
        this._temCompraEmProcesso = !!compraEmProcesso;
        this.compraEmProcesso = !!compraEmProcesso;
        this.temPagamentoDigitalPendente =
          this.verificarPagamentoDigitalPendente(compraEmProcesso);
      }
    );
  }

  temPagamentoDigitalPendente = false;
  private _temCompraEmProcesso = true;

  get temCompraEmProcesso(): boolean {
    if (this._temCompraEmProcesso) this.askFinalizarCompraEmProcesso();
    return this._temCompraEmProcesso;
  }

  askFinalizarCompraEmProcesso() {
    this.dialogService.openDialog(this.dialog);

    this.dialogService.dialogClick.subscribe(async (value) => {
      await this.dialogService.clearDialog();
      if (value) {
        this.redirecionarParaPagamento();
      }
    });
  }

  redirecionarParaPagamento() {
    if (this.temPagamentoDigitalPendente) {
      this.router.navigateByUrl('pagamento/carteira-digital');
    } else {
      this.router.navigateByUrl('pagamento');
    }
  }

  verificarPagamentoDigitalPendente(
    compraEmProcesso: ICompra | null | undefined
  ) {
    if (compraEmProcesso?.compra_pagamentos?.length) {
      return compraEmProcesso.compra_pagamentos.some((compraPagamento) => {
        return compraPagamento.carteira_digital;
      });
    }
    return false;
  }
}
