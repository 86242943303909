import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormaPagamentosFacade } from '..';
import { FormaPagamentosEffects } from './+state/forma-pagamento.effects';
import * as fromFormaPagamento from './+state/forma-pagamento.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoadingStateModule } from '@vip/state/loading';
import { CompraEmProcessoProviderModule } from '@vip/state/compra-em-processo-provider';

@NgModule({
  imports: [
    CommonModule,
    LoadingStateModule,
    StoreModule.forFeature(
      fromFormaPagamento.FORMA_PAGAMENTOS_FEATURE_KEY,
      fromFormaPagamento.reducer
    ),
    EffectsModule.forFeature([FormaPagamentosEffects]),
    CompraEmProcessoProviderModule,
  ],
  providers: [FormaPagamentosFacade],
})
export class FormaPagamentoStateModule {}
