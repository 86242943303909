import { Route } from '@angular/router';
import { LayoutDesktopContainerComponent } from '@vip/container/layout-desktop';
import {
  AplicativoMinhaContaRoutes,
  Routes,
  SiteMinhaContaRoutes,
  SiteRootRoutes,
} from '@vip/routes';
import { SelecaoCdGuard } from '@vip/state/centro-distribuicao';
import { FilialEmManutencaoGuard } from '@vip/state/filial';

export const appModuleRoutes: Route[] = [
  {
    path: '',
    canActivate: [FilialEmManutencaoGuard, SelecaoCdGuard],
    component: LayoutDesktopContainerComponent,
    children: [
      {
        path: '',
        data: { breadcrumb: { label: 'Home' } },
        loadChildren: async () =>
          (await import('@vip/container/home-omnichannel'))
            .HomeOmnichannelContainerModule,
      },
      ...Routes,
      ...SiteMinhaContaRoutes,
      ...AplicativoMinhaContaRoutes,
    ],
  },
  ...SiteRootRoutes,
];
