import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ICompraDesconto,
  ITaxaServico,
  ITipoEntrega,
  StepStatusEnum,
} from '@vip/core';
import { EntregaRetiradaPropertiesBaseDirective } from '@vip/views/entrega-retirada';
import { Observable } from 'rxjs';

@Component({
  selector: 'vip-entrega-retirada-desktop',
  templateUrl: './entrega-retirada-desktop.component.html',
  styleUrls: ['./entrega-retirada-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntregaRetiradaDesktopComponent
  extends EntregaRetiradaPropertiesBaseDirective
  implements OnInit
{
  @Input()
  tiposEntrega: ITipoEntrega[] = [];

  @Input()
  url_google = '';

  @Input()
  showStepper = false;

  @Input()
  logoSrc = '';

  @Input()
  totalCompra = 0;

  @Input()
  taxaEntrega?: number;

  @Input()
  valorDescontos?: number;

  @Input()
  descontos?: ICompraDesconto[];

  @Input()
  taxaServico: ITaxaServico | null = null;

  @Input()
  disablePagamentoButton = false;

  @Input()
  changeTab$?: Observable<number>;

  @Input()
  fallbackImageUrl?: string;

  @Output()
  tipoEntregaChanged = new EventEmitter<ITipoEntrega>();

  @Output()
  criarCompraClicked = new EventEmitter<void>();

  @Output()
  iniciarCheckout = new EventEmitter<void>();

  @Output()
  editarEndereco = new EventEmitter<number>();

  @Output()
  continuarComprando = new EventEmitter<void>();

  steps = [
    { title: 'Entrega', link: '', status: StepStatusEnum.ACTIVE },
    { title: 'Pagamento', link: '', status: StepStatusEnum.PENDING },
    { title: 'Revisão', link: '', status: StepStatusEnum.PENDING },
  ];

  ngOnInit(): void {
    this.populateTiposEntregasOptions();
    this.iniciarCheckout.emit();
  }

  goToLoja(): void {
    this.continuarComprando.emit();
  }
}
