import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstitucionalFacade } from './+state/institucional.facade';
import { StoreModule } from '@ngrx/store';
import * as fromInstitucional from './+state/institucional.reducer';
import { InstitucionalEffects } from './+state/institucional.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromInstitucional.INSTITUCIONAL_FEATURE_KEY,
      fromInstitucional.reducer
    ),
    EffectsModule.forFeature([InstitucionalEffects]),
  ],
  providers: [InstitucionalFacade],
})
export class InstitucionalStateModule {}
