import { IFaixaPrecos } from '../interfaces/oferta.interface';

export const faixaPrecoMock: IFaixaPrecos = {
  id: '48e1ef38-02a7-43c2-8294-2e2d57d6b122',
  preco_oferta_fracao: 15.54,
  quantidade: 1,
  valor: 7.77,
};

export const faixaPrecoMock2: IFaixaPrecos = {
  id: 'f1ae2c8f-01c3-4760-9200-d3676cd2c202',
  preco_oferta_fracao: 15.5,
  quantidade: 2,
  valor: 7.75,
};

export const faixaPrecoMock3: IFaixaPrecos = {
  id: 'f1ae2c8f-01c3-4760-9200-d3676cd2c202',
  preco_oferta_fracao: 15.3,
  quantidade: 3,
  valor: 7.65,
};

export const faixaPrecoMock5: IFaixaPrecos = {
  id: 'f1ae2c8f-01c3-4760-9200-d3676cd2c202',
  preco_oferta_fracao: 15.1,
  quantidade: 5,
  valor: 7.55,
};

export const faixasPrecoArrayMock: IFaixaPrecos[] = [
  faixaPrecoMock,
  faixaPrecoMock2,
  faixaPrecoMock3,
];
