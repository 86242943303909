<div>
  <vip-endereco-entrega-retirada
    [enderecos]="enderecos"
    [cdsRetirada]="cdsRetirada"
    [cdsEntrega]="cdsEntrega"
    [enderecoSelecionado]="
      (enderecoSelecionado$ | async) ||
      novoEndereco ||
      (enderecoEntrega$ | async)
    "
    [cdSelecionado]="cdSelecionado"
    [formaEntrega]="formaEntrega"
    [formaEntregaFromState]="formaEntregaFromState"
    [isPrimeiroProduto]="isPrimeiroProduto"
    (informarCepClick)="informarCepClick()"
    (cdChange)="aoAlterarCdSelecionado($event)"
    (enderecoEntregaChange)="aoAlterarEnderecoDeEntrega($event)"
    (formaEntregaChange)="aoAlterarFormaEntrega($event)"
    (entregaChange)="entregaChange.emit($event)"
    [novoEnderecoCdSelect]="novoEnderecoCdSelect"
  ></vip-endereco-entrega-retirada>
</div>
