import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EnderecoModule } from '@vip/views/endereco';
import { MeusDadosViewModule } from '@vip/views/meus-dados';
import { ModalCompletarCadastroComponent } from './modal-completar-cadastro/modal-completar-cadastro.component';
import { ButtonModule } from '@vip/ui/button';

@NgModule({
  imports: [CommonModule, MeusDadosViewModule, EnderecoModule, ButtonModule],
  declarations: [ModalCompletarCadastroComponent],
  exports: [ModalCompletarCadastroComponent],
})
export class ModalCompletarCadastroModule {}
