import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { ICepAtendido } from '@vip/core';
import { BaseApiService } from '../common/base-api-service';

@Injectable({
  providedIn: 'root',
})
export class CepApiService {
  constructor(private api: BaseApiService<CepApiService>) {}

  validarCepAtendidoPelaFilial(
    cep: string,
    filialId: number
  ): Observable<ICepAtendido> {
    return this.api
      .get<ICepAtendido>(
        'validarCepAtendidoPelaFilial',
        `/filial/${filialId}/loja/consultar/cep/${cep}`
      )
      .pipe(pluck('data'));
  }

  addSolicitacaoCep(
    filialId: number,
    cep: string,
    email: string
  ): Observable<{ cep: string; email: string }> {
    return this.api
      .post<
        { cep: string; email: string; filial_id: number },
        { cep: string; email: string }
      >('addSolicitacaoCep', `/loja/solicitacao_ceps`, {
        cep,
        email,
        filial_id: filialId,
      })
      .pipe(pluck('data'));
  }
}
