import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ClienteApiService } from './services/cliente';
import { CupomApiService } from './services/cupom';
import { AplicativoApiService } from './services/aplicativo';
import { FilialApiService } from './services/filial';
import { LojaApiService, LojaTokenService } from './services/loja';
import { CepApiService } from './services/cep';
import { FiltroApiService } from './services/filtro';
import { errorInterceptorProvider } from './errorInterceptor';
import { FilialAplicativoApiService } from './services/filial-aplicativo';
import { ApiOrganizacaoService } from './services/organizacao/api-organizacao.service';
import { VitrineDedicadaApiService } from './services/vitrine-dedicada';
import { CookiesApiService } from './services/cookies';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    AplicativoApiService,
    ApiOrganizacaoService,
    ClienteApiService,
    CupomApiService,
    CookiesApiService,
    FilialApiService,
    LojaApiService,
    LojaTokenService,
    CepApiService,
    FiltroApiService,
    errorInterceptorProvider,
    FilialAplicativoApiService,
    VitrineDedicadaApiService,
  ],
})
export class ApiModule {}
