import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LayoutUtilsService } from '@vip/core';

@Component({
  selector: 'vip-produto-preco-unidade',
  templateUrl: './produto-preco-unidade.component.html',
  styleUrls: ['./produto-preco-unidade.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.desktop]': 'isDesktop',
    '[class.mobile]': '!isDesktop',
  },
})
export class ProdutoPrecoUnidadeComponent {
  @Input() preco?: number;
  @Input() siglaUnidade?: string;
  @Input() isTag = true;
  @Input() textSmallGrey = false;
  @Input() noMargin = false;

  isDesktop = this.layoutUtilsService.isDesktop();

  constructor(private layoutUtilsService: LayoutUtilsService) {}
}
