import { createAction, props } from '@ngrx/store';
import { ICashbackSaldo, ITransacaoCashbackAPI } from '@vip/core';

export const getSaldoCashback = createAction(
  '[Cashback/API] Get Saldo Cashback'
);

export const getSaldoCashbackSuccess = createAction(
  '[Cashback/API] Get Saldo Cashback Success',
  props<{ saldo?: ICashbackSaldo; exibeCashback: boolean }>()
);

export const getSaldoCashbackFailure = createAction(
  '[Cashback/API] Get Saldo Cashback Failure',
  props<{ error: string }>()
);

export const getExtratoCashback = createAction(
  '[Cashback/API] Get Extrato Cashback',
  props<{ dataInicial: string; dataFinal: string }>()
);

export const getExtratoCashbackSuccess = createAction(
  '[Cashback/API] Get Extrato Cashback Success',
  props<{ extrato: ITransacaoCashbackAPI[] }>()
);

export const getExtratoCashbackFailure = createAction(
  '[Cashback/API] Get Extrato Cashback Failure',
  props<{ error: string }>()
);

export const carregarMaisExtratoCashback = createAction(
  '[Cashback/API] Carregar Mais Extrato Cashback',
  props<{ dataInicial: string; dataFinal: string }>()
);

export const carregarMaisExtratoCashbackSuccess = createAction(
  '[Cashback/API] Carregar Mais Extrato Cashback Success',
  props<{ extrato: ITransacaoCashbackAPI[] }>()
);

export const alterarSenhaCashback = createAction(
  '[Cashback/API] Alterar Senha Cashback',
  props<{ novaSenha: string }>()
);

export const alterarSenhaCashbackSuccess = createAction(
  '[Cashback/API] Alterar Senha Cashback Success',
  props<{ message: string }>()
);

export const alterarSenhaCashbackFailure = createAction(
  '[Cashback/API] Alterar Senha Cashback Failure',
  props<{ error: string }>()
);

export const setCashbackConfigs = createAction(
  '[Cashback/API] Seta configuracoes do cashback',
  props<{ isIdever: boolean }>()
);
