import { ChangeDetectionStrategy, Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { InstitucionalFacade } from '@vip/state/institucional';
import { IframeService } from '@vip/native/iframe';
import { ListaInstitucionalBaseDirective } from './lista-intitucional-base.directive';
@Component({
  selector: 'vip-lista-institucional-container',
  templateUrl: './lista-institucional-container.component.html',
  styleUrls: ['./lista-institucional-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListaInstitucionalContainerComponent extends ListaInstitucionalBaseDirective {
  paginas$ = this.institucionalFacade.paginas$;
  constructor(
    private institucionalFacade: InstitucionalFacade,
    protected route: Router,
    protected iframeService: IframeService,
    protected ngZone: NgZone
  ) {
    super(route, iframeService, ngZone);
    this.institucionalFacade.getPaginasInstitucionais();
  }
}
