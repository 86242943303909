import { Injectable } from '@angular/core';
import {
  AtualizarCarrinhoItemRequest,
  BuscarCarrinhoItensResponse,
  BuscarCarrinhoResponse,
  ManterCarrinhoClienteResponse,
  ManterCarrinhoSessaoResponse,
  RemoverItemCarrinhoResponse,
  SituacaoCarrinhoSessaoClienteResponse,
} from './carrinho.service.types';
import { BaseApiService } from '../common/base-api-service';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ICarrinho, IProdutoCarrinho } from '@vip/core';

@Injectable({
  providedIn: 'root',
})
export class CarrinhoService {
  private carrinhoUrl = `loja/carrinhos`;

  constructor(private api: BaseApiService<CarrinhoService>) {}

  getCarrinho(
    filialId: number,
    cdId: number
  ): Observable<BuscarCarrinhoResponse> {
    return this.api.get<BuscarCarrinhoResponse>(
      'getCarrinho',
      `/filial/${filialId}/centro_distribuicao/${cdId}/${this.carrinhoUrl}`
    );
  }

  removeCarrinho(carrinhoId: number): Observable<string> {
    return this.api.delete<string>(
      'removeCarrinho',
      `/${this.carrinhoUrl}/${carrinhoId}`
    );
  }

  getItensCarrinho(
    filialId: number,
    cdId: number
  ): Observable<BuscarCarrinhoItensResponse> {
    return this.api.get<BuscarCarrinhoItensResponse>(
      'getItensCarrinho',
      `/filial/${filialId}/centro_distribuicao/${cdId}/${this.carrinhoUrl}/itens-completo`
    );
  }

  setItemCarrinho(
    filialId: number,
    cdId: number,
    addItemRequest: AtualizarCarrinhoItemRequest
  ): Observable<IProdutoCarrinho[]> {
    return this.api
      .post<AtualizarCarrinhoItemRequest, IProdutoCarrinho>(
        'setItemCarrinho',
        `/filial/${filialId}/centro_distribuicao/${cdId}/${this.carrinhoUrl}/atualizar_item/omnichannel`,
        addItemRequest
      )
      .pipe(pluck('data'));
  }

  setListaItensCarrinho(
    filialId: number,
    cdId: number,
    addItemRequest: AtualizarCarrinhoItemRequest[]
  ): Observable<{
    carrinho: ICarrinho;
    itemAtualizado: IProdutoCarrinho[];
  }> {
    return this.api
      .post<
        AtualizarCarrinhoItemRequest[],
        {
          carrinho: ICarrinho;
          itemAtualizado: IProdutoCarrinho[];
        }
      >(
        'setListaItensCarrinho',
        `/filial/${filialId}/centro_distribuicao/${cdId}/${this.carrinhoUrl}/atualizar_varios/omnichannel`,
        addItemRequest
      )
      .pipe(pluck('data'));
  }

  removeItemCarrinho(
    filialId: number,
    cdId: number,
    itemId: number
  ): Observable<RemoverItemCarrinhoResponse> {
    return this.api.delete<RemoverItemCarrinhoResponse>(
      'removeItemCarrinho',
      `/filial/${filialId}/centro_distribuicao/${cdId}/${this.carrinhoUrl}/remover_item/${itemId}`
    );
  }

  verificaAlteracaoCdCarrinho(
    filialId: number,
    cdId: number,
    novo_cd_id: number
  ): Observable<[]> {
    return this.api
      .post<{ novo_cd_id: number }, []>(
        'verificaAlteracaoCdCarrinho',
        `/filial/${filialId}/centro_distribuicao/${cdId}/${this.carrinhoUrl}/alterar_centro_distribuicao/omnichannel`,
        { novo_cd_id: novo_cd_id }
      )
      .pipe(pluck('data'));
  }

  situacaoCarrinhoSessaoCliente(
    filialId: number,
    cdId: number
  ): Observable<SituacaoCarrinhoSessaoClienteResponse> {
    return this.api.get<SituacaoCarrinhoSessaoClienteResponse>(
      'getItensCarrinho',
      `${this.api.filialAndCdUrl(
        filialId,
        cdId
      )}/carrinhos/situacao_carrinho_sessao_cliente`
    );
  }

  manterCarrinhoCliente(
    filialId: number
  ): Observable<ManterCarrinhoClienteResponse> {
    return this.api.post<null, ManterCarrinhoClienteResponse>(
      'manterCarrinhoCliente',
      `/filial/${filialId}/${this.carrinhoUrl}/mantem_apenas_carrinho_cliente_logado`
    );
  }

  manterCarrinhoSessao(
    filialId: number,
    cdId: number
  ): Observable<ManterCarrinhoSessaoResponse> {
    return this.api.post<null, ManterCarrinhoSessaoResponse>(
      'manterCarrinhoSessao',
      `${this.api.filialAndCdUrl(
        filialId,
        cdId
      )}/carrinhos/sincroniza_carrinho_sessao_cliente`
    );
  }

  verificaItensDesativados(
    filialId: number,
    cdId: number
  ): Observable<{ mensagem: string | null; success: boolean }> {
    return this.api
      .post<
        Record<string, unknown>,
        { mensagem: string | null; success: boolean }
      >(
        'verificaItensDesativados',
        `/filial/${filialId}/centro_distribuicao/${cdId}/${this.carrinhoUrl}/verifica_itens_desativados`,
        {}
      )
      .pipe(pluck('data'));
  }
}
