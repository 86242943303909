import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { IMAGES_PATH } from '@vip/core';
import { ImageSizesEnum } from './image-size.enum';

@Component({
  selector: 'vip-image',
  templateUrl: './image.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styles: [
    `
      .vip-card-forma-pagamento-image-size {
        width: 40px;
        height: 27px;
      }

      .vip-img-custom-size {
        height: var(--vip-img-height, unset);
        width: var(--vip-img-width, unset);
      }
    `,
  ],
})
export class ImageComponent {
  private _imageUrl!: string;
  private _isLoading = false;

  @Input() size: ImageSizesEnum = ImageSizesEnum.md;
  @Input() defaultImageUrl?: string;
  @Input() fallbackImageUrl?: string;
  @Input() alt = '';
  @Input() hiddenOnError = false;
  @Input() classNames = '';
  @Input() ariaLabel = '';
  @Input() customSize = false;
  @Input() hasCombo = false;
  @Input() hasComboAccordion = false;

  @Input()
  set imageUrl(value: string) {
    this._imageUrl = value;
    this.changeDetector.detectChanges();
  }

  get imageUrl() {
    return this._imageUrl;
  }

  @Input()
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.changeDetector.detectChanges();
  }

  get isLoading() {
    return this._isLoading;
  }

  @Output() notShowingImage = new EventEmitter<boolean>();
  @Output() imageLoaded = new EventEmitter<boolean>();

  hiddenImage = false;
  imageLoadComplete = false;
  private imagesPath!: string;

  constructor(
    @Inject(IMAGES_PATH) imagesPath: string,
    private changeDetector: ChangeDetectorRef
  ) {
    this.imagesPath = imagesPath;
  }

  private getDefaultUrl(): string {
    if (!this.defaultImageUrl || this.imageUrl === this.defaultImageUrl) {
      return `${this.imagesPath}default_image_${this.size}.png`;
    }
    return this.defaultImageUrl;
  }

  handleError() {
    if (this.hiddenOnError) {
      this.hiddenImage = true;
    }

    if (this.fallbackImageUrl && this._imageUrl !== this.fallbackImageUrl) {
      this.imageUrl = this.fallbackImageUrl;
      this.hiddenImage = false;
    } else {
      this.imageUrl = this.getDefaultUrl();
    }

    this.notShowingImage.emit(this.hiddenImage);
    return true;
  }

  loadImg() {
    this.imageLoadComplete = true;
    this.imageLoaded.emit(true);
    this.changeDetector.detectChanges();
  }
}
