import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromFilial from './+state/filial.reducer';
import { FilialEffects } from './+state/filial.effects';
import { FilialFacade } from './+state/filial.facade';
import { BucketsStateModule } from '@vip/state/buckets';
import { ParametrosStateModule } from '@vip/state/parametros';

@NgModule({
  imports: [
    CommonModule,
    BucketsStateModule,
    ParametrosStateModule,
    StoreModule.forFeature(fromFilial.FILIAL_FEATURE_KEY, fromFilial.reducer),
    EffectsModule.forFeature([FilialEffects]),
  ],
  providers: [FilialFacade],
})
export class FilialStateModule {}
