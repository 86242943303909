import { select, Store } from '@ngrx/store';
import { CarrinhoItensActions } from '@vip/state/carrinho-itens';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import * as SubstituirProdutoActions from './substituir-produto.actions';
import { substituirProdutoSelectors } from './substituir-produto.selectors';
import { AtualizarCarrinhoItemRequest } from '@vip/api';
import { CarrinhoActions } from '@vip/state/carrinho';
import { AnalyticsFacade } from '@vip/state/analytics';

@Injectable()
export class SubstituirProdutoEffects {
  atualizarSubstituto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubstituirProdutoActions.definirSubstituto),
      filter((action) => action.quantidade > 0),
      map((action) =>
        SubstituirProdutoActions.atualizarSubstituto({
          produto: {
            ...action.produto,
            quantidade:
              action.produto.quantidade_maxima < action.quantidade
                ? action.produto.quantidade_maxima
                : action.quantidade,
          },
          idProdutoIndisponivel: action.idProdutoIndisponivel,
        })
      )
    )
  );

  removerSubstituto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubstituirProdutoActions.definirSubstituto),
      filter((action) => action.quantidade <= 0),
      map((action) =>
        SubstituirProdutoActions.removerSubstituto({
          idProdutoIndisponivel: action.idProdutoIndisponivel,
        })
      )
    )
  );

  adicionarItensAoCarrinho = createEffect(() =>
    this.actions$.pipe(
      ofType(SubstituirProdutoActions.adicionarItensAoCarrinho),
      withLatestFrom(
        this.store.pipe(
          select(substituirProdutoSelectors.getProdutosSubstitutosArray())
        )
      ),
      map(([, produtos]) => {
        const itensCarrinho: AtualizarCarrinhoItemRequest[] = produtos.map(
          (produto) => {
            return {
              mercadoria_id: produto.produto_id || 0,
              quantidade: produto.quantidade || 0,
              tipo: produto.tipo || 1,
            };
          }
        );

        this.store.dispatch(
          CarrinhoItensActions.setListaItensCarrinho({
            payload: itensCarrinho,
            exibirMensagemAdicionado: false,
            buscaCarrinhoAposConclusao: true,
          })
        );
      }),
      map(() => SubstituirProdutoActions.resetProdutos())
    )
  );

  getCarrinhoSubstituicao$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrinhoItensActions.setListaItensCarrinhoSuccess),
      filter((action) => !!action.buscaCarrinhoAposConclusao),
      map(() => CarrinhoActions.getAllCarrinho())
    )
  );

  enviaDadosGa4$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoItensActions.setListaItensCarrinhoSuccess),
        tap((action) => {
          action.itens.forEach((item) => {
            this.analyticFacade.addToCarrinho(item);
          });
        })
      ),
    { dispatch: false }
  );
  constructor(
    private store: Store,
    private readonly actions$: Actions,
    private analyticFacade: AnalyticsFacade
  ) {}
}
