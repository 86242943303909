import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as comboSelectors from './combo.selectors';
import * as ComboActions from './combo.actions';
import { QueryParams } from '@vip/core';

@Injectable()
export class ComboFacade {
  loading$ = this.store.pipe(select(comboSelectors.isLoading));
  getCombos$ = this.store.pipe(select(comboSelectors.getAllCombos));
  getCombosProdutos$ = this.store.pipe(
    select(comboSelectors.getCombosProdutosArray)
  );
  getCombosDepartamentos$ = this.store.pipe(
    select(comboSelectors.getCombosDepartamentos)
  );

  constructor(private readonly store: Store) {}

  getCombos() {
    this.store.dispatch(ComboActions.getCombo());
  }

  getProdutosCombos(comboId: number) {
    this.store.dispatch(ComboActions.getComboProdutos({ comboId }));
  }

  getProdutosCombosByArray(combosId: number[], queryParams?: QueryParams) {
    this.store.dispatch(
      ComboActions.getCombosProdutosByArray({ combosId, queryParams })
    );
    return this.getCombosProdutos$;
  }

  getCombosDepartamentos(queryParams: QueryParams) {
    this.store.dispatch(ComboActions.getCombosDepartamentos({ queryParams }));
  }
}
