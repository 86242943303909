import { Action, createReducer, on } from '@ngrx/store';
import { GenericStoreStatusEnum, IGenericState } from '@vip/state/utils';

import * as VitrineDedicadaActions from './vitrine-dedicada.actions';
import { IVitrinesDedicadas } from '@vip/core';

export const VITRINE_DEDICADA_FEATURE_KEY = 'vitrine-dedicada';

export interface VitrineDedicadaStateData {
  vitrines: Array<IVitrinesDedicadas>;
}

export type VitrineDedicadaState = IGenericState<VitrineDedicadaStateData>;

export const initialState: VitrineDedicadaState = {
  data: {
    vitrines: [],
  },
  status: GenericStoreStatusEnum.PENDING,
  error: null,
};

const vitrineDedicadaReducer = createReducer(
  initialState,
  on(VitrineDedicadaActions.loadVitrine, (state) => ({
    ...state,
    status: GenericStoreStatusEnum.LOADING,
    error: null,
  })),
  on(VitrineDedicadaActions.loadVitrineSuccess, (state, { vitrines }) => ({
    ...state,
    data: {
      ...state.data,
      vitrines,
    },
    status: GenericStoreStatusEnum.SUCCESS,
    error: null,
  })),
  on(VitrineDedicadaActions.loadVitrineError, (state, { error }) => ({
    ...state,
    status: GenericStoreStatusEnum.ERROR,
    data: {
      ...state.data,
      vitrines: [],
    },
    error: error.error,
  }))
);

export function reducer(
  state: VitrineDedicadaState | undefined,
  action: Action
) {
  return vitrineDedicadaReducer(state, action);
}
