<section *ngIf="!hasValidaPrazo" class="p-4">
  <h4 class="mb-3 font-medium text-sm">
    {{
      isEntrega
        ? 'Selecione um endereço de entrega:'
        : 'Selecione uma loja que deseja retirar sua compra:'
    }}
  </h4>

  <ng-container *ngIf="isEntrega; else retiradaTemplate">
    <vip-selectable-card
      *ngFor="let endereco of enderecosExibidos"
      [disableAutoToggle]="true"
      [showIcon]="false"
      [showControl]="true"
      [fullWidth]="true"
      [checked]="enderecoSelecionado?.id === endereco.id"
      class="block mb-2"
      (changed)="enderecoEntregaChange.emit(endereco)"
    >
      <div title class="flex">
        <p class="font-medium text-sm">{{ endereco.titulo }}</p>
      </div>
      <div body class="vip-color-secondary-default text-sm mt-1">
        <span>{{ endereco.endereco_formatado }}</span>
      </div>
      <div
        control
        *ngIf="isEditar"
        role="button"
        data-cy="btn-edit-endereco"
        class="flex justify-center cursor-pointer vip-color-primary-main p-1 -mr-1"
        vipRipple
        (click)="handleEditarEnderecoClick($event, endereco.id)"
      >
        <span
          class="vip-color-secondary-default text-sm font-medium cursor-pointer"
          >Editar</span
        >
        <i
          class="icon-edit ml-2 my-auto text-sm vip-color-secondary-default"
        ></i>
      </div>
    </vip-selectable-card>

    <div
      class="flex items-center justify-between"
      [ngClass]="{ 'mt-1': expandedEndereco, 'mt-2': !expandedEndereco }"
    >
      <div
        *ngIf="enderecos.length > 1"
        role="button"
        data-cy="btn-expand-endereco"
        class="flex justify-center cursor-pointer vip-color-primary-main"
        (click)="
          expandedEndereco ? verMenosEnderecosClick() : verMaisEnderecosClick()
        "
      >
        <i
          class="icon-expand_more mr-2 my-auto transition-transform"
          [class.rotate-180]="expandedEndereco"
        ></i>
        <span class="text-sm font-medium cursor-pointer"
          >{{ expandedEndereco ? 'Ver menos' : 'Ver mais' }} endereços
          salvos</span
        >
      </div>

      <div
        role="button"
        data-cy="btn-add-endereco"
        class="flex justify-center cursor-pointer vip-color-primary-main"
        (click)="handleNovoEnderecoClick($event)"
      >
        <i class="icon-add mr-2 my-auto transition-transform"></i>
        <span class="text-sm font-medium cursor-pointer">Novo endereço</span>
      </div>
    </div>
  </ng-container>
  <ng-template #retiradaTemplate>
    <ng-container
      *ngTemplateOutlet="
        cdsTemplate;
        context: {
          $implicit: {
            cdsExibidos,
            todosCds: cdsRetirada
          }
        }
      "
    ></ng-container>
  </ng-template>
</section>

<hr class="vip-divider" />

<section
  *ngIf="isEntrega && cdsEntrega.length > 0 && !hasValidaPrazo"
  class="p-4"
>
  <h4 class="mb-3 font-medium text-sm">Comprando na loja:</h4>

  <ng-container
    *ngTemplateOutlet="
      cdsTemplate;
      context: { $implicit: { cdsExibidos, todosCds: cdsEntrega } }
    "
  ></ng-container>
</section>

<hr class="vip-divider" />

<section
  *ngIf="
    !(isEntrega && !exibirTiposEntrega) &&
    !(!isEntrega && !exibirTiposRetirada) &&
    tiposEntrega.length > 0
  "
  [ngClass]="{ 'py-4': hasValidaPrazo, 'p-4': !hasValidaPrazo }"
>
  <h4 *ngIf="!hasValidaPrazo" class="mb-3 font-medium text-sm">
    Escolha o tipo de {{ isEntrega ? 'entrega' : 'retirada' }}:
  </h4>

  <h4
    *ngIf="hasValidaPrazo && !loadingValidaPrazo"
    class="mb-3 font-medium text-sm"
  >
    Escolha o tipo de {{ isEntrega ? 'entrega' : 'retirada' }}:
  </h4>

  <ng-container
    *ngTemplateOutlet="
      tiposEntregaTemplate;
      context: {
        $implicit: { tiposEntregaExibidos, todosTiposEntrega: tiposSelecionado }
      }
    "
  ></ng-container>
</section>
<div
  *ngIf="
    hasValidaPrazo &&
    tipoEntregaSelecionado &&
    diasEHorariosDisponiveis.length > 0 &&
    !loadingValidaPrazo
  "
  class="pt-[5px] pb-[5px]"
>
  <div class="w-full h-[1px] bg-[#ebecef]"></div>
</div>

<div
  class="pt-[25px] pb-[39px]"
  *ngIf="
    (loadingValidaPrazo && hasTipoRetiradaPrazoEntrega && hasValidaPrazo) ||
    (loadingValidaPrazo && !tipoEntregaSelecionado && hasValidaPrazo)
  "
>
  <div class="flex justify-center items-center pb-2">
    <vip-loading-spinner [solidSpinner]="true"></vip-loading-spinner>
  </div>
  <p *ngIf="loadingValidaPrazo" class="text-[14px] font-normal text-center">
    Carregando os tipos de
    {{ exibirTiposEntrega ? 'entrega' : 'retirada' }} disponíveis
  </p>
</div>

<ng-container
  *ngIf="
    !(isEntrega && !exibirTiposEntrega) &&
    !(!isEntrega && !exibirTiposRetirada) &&
    tipoEntregaSelecionado &&
    diasEHorariosDisponiveis.length > 0 &&
    hasValidaPrazo
  "
>
  <hr class="vip-divider" />
  <section [ngClass]="{ 'py-4': hasValidaPrazo, 'p-4': !hasValidaPrazo }">
    <h4 class="mb-3 font-medium text-sm">
      Escolha o melhor dia e horário disponível para
      {{ isEntrega ? 'receber' : 'retirar' }} sua compra:
    </h4>
    <vip-select
      class="max-w-xs"
      label="Dias e horários disponíveis"
      [options]="diasEHorariosDisponiveis"
      [value]="horarioSelecionadoString || ''"
      [isDesktop]="true"
      (itemSelected)="aoMudarHorario($event.value)"
      data-cy="dias-e-horairos-disponiveis-select"
    >
    </vip-select>
  </section>
</ng-container>

<ng-container
  *ngIf="
    !(isEntrega && !exibirTiposEntrega) &&
    !(!isEntrega && !exibirTiposRetirada) &&
    tipoEntregaSelecionado &&
    diasEHorariosDisponiveis.length > 0 &&
    !hasValidaPrazo
  "
>
  <hr class="vip-divider" />
  <section [ngClass]="{ 'py-4': hasValidaPrazo, 'p-4': !hasValidaPrazo }">
    <h4 class="mb-3 font-medium text-sm">
      Escolha o melhor dia e horário disponível para
      {{ isEntrega ? 'receber' : 'retirar' }} sua compra:
    </h4>
    <vip-select
      class="max-w-xs"
      label="Dias e horários disponíveis"
      [options]="diasEHorariosDisponiveis"
      [value]="horarioSelecionadoString || ''"
      [isDesktop]="true"
      (itemSelected)="aoMudarHorario($event.value)"
      data-cy="dias-e-horairos-disponiveis-select"
    >
    </vip-select>
  </section>
</ng-container>

<ng-template #cdsTemplate let-obj>
  <vip-selectable-card
    *ngFor="let cd of obj.cdsExibidos"
    [disableAutoToggle]="true"
    [showIcon]="false"
    [fullWidth]="true"
    [showControl]="true"
    [checked]="isEntrega && retiradaLoja ? false : cdSelecionado?.id === cd.id"
    class="block mb-2"
    (changed)="cdChange.emit({ cd, isFromRetirada: !isEntrega })"
  >
    <div title class="flex">
      <p class="font-medium text-sm">{{ cd.nome_site }}</p>
    </div>
    <div body class="vip-color-secondary-default text-sm mt-1">
      <p>
        <span>{{ cd.observacao_retirada }}</span>
      </p>
    </div>
    <div control>
      <span
        *ngIf="cd.limite_itens_carrinho"
        class="vip-color-secondary-default text-sm"
        >Limite de {{ cd.limite_itens_carrinho }} itens</span
      >
    </div>
  </vip-selectable-card>

  <div
    *ngIf="obj.todosCds.length > 1"
    class="flex items-center justify-between"
    [ngClass]="{ 'mt-1': expandedLojas, 'mt-2': !expandedLojas }"
  >
    <div
      role="button"
      data-cy="btn-expand-cds"
      class="flex justify-center cursor-pointer vip-color-primary-main"
      (click)="expandedLojas ? verMenosCdsClick() : verMaisCdsClick()"
    >
      <i
        class="icon-expand_more mr-2 my-auto transition-transform"
        [class.rotate-180]="expandedLojas"
      ></i>
      <span class="text-sm font-medium cursor-pointer"
        >{{ expandedLojas ? 'Ver menos' : 'Ver mais' }} lojas</span
      >
    </div>
  </div>
</ng-template>

<ng-template #tiposEntregaTemplate let-obj>
  <ng-container
    *ngIf="
      hasValidaPrazo
        ? !loadingValidaPrazo &&
          hasValidaPrazo &&
          obj.tiposEntregaExibidos.length > 0
        : obj.tiposEntregaExibidos.length > 0
    "
  >
    <div
      [ngClass]="{
        'overflow-scroll h-[220px]':
          obj.tiposEntregaExibidos.length > 4 && hasValidaPrazo
      }"
    >
      <vip-selectable-card
        *ngFor="let tipoEntrega of obj.tiposEntregaExibidos; let last = last"
        class="block"
        [ngClass]="{
          'mb-2': !last || hasValidaPrazo,
          'pr-[4px] pl-[2px]': hasValidaPrazo
        }"
        [showIcon]="false"
        [fullWidth]="true"
        [showControl]="true"
        [checked]="tipoEntregaSelecionado?.id === tipoEntrega.id"
        [error]="!!errosTiposEntrega.get(tipoEntrega.id)"
        (changed)="tipoEntregaChanged.emit($event ? tipoEntrega : null)"
        [disabled]="!!tipoEntrega.bloqueios?.length"
      >
        <div title class="flex">
          <p class="font-medium text-sm">{{ tipoEntrega.descricao }}</p>
        </div>
        <div body class="vip-color-secondary-default text-sm mt-1">
          <span>{{ tipoEntrega.resumo }}</span>
        </div>
        <div class="flex flex-col items-end" control>
          <div class="vip-color-secondary-default text-sm font-medium mb-1">
            <span
              *ngIf="tipoEntrega.desconto > 0 && tipoEntrega.frete > 0"
              class="line-through mr-1"
              >{{ tipoEntrega.frete | currency }}</span
            >
            <span>{{
              getValorTipoEntrega(tipoEntrega) <= 0
                ? 'Grátis'
                : (getValorTipoEntrega(tipoEntrega) | currency)
            }}</span>
          </div>
          <span
            *ngIf="tipoEntrega.quantidade_maxima_itens"
            class="vip-color-secondary-default text-sm"
            >Limite de {{ tipoEntrega.quantidade_maxima_itens }} itens</span
          >
        </div>
        <div body *ngIf="tipoEntrega.bloqueios?.length">
          <hr class="vip-divider my-2" />
          <div
            *ngFor="let bloqueio of tipoEntrega.bloqueios"
            class="vip-color-error-main"
          >
            {{ bloqueio.mensagem }}
          </div>
        </div>
      </vip-selectable-card>
    </div>
    <div
      *ngIf="obj.todosTiposEntrega.length > 1"
      class="flex items-center justify-between"
      [ngClass]="{
        'mt-1': expandedTiposEntregas,
        'mt-2': !expandedTiposEntregas
      }"
    >
      <div
        role="button"
        data-cy="btn-expand-entregas"
        class="flex justify-center cursor-pointer vip-color-primary-main"
        (click)="
          expandedTiposEntregas
            ? verMenosTiposEntregasClick()
            : verMaisTiposEntregasClick()
        "
      >
        <i
          class="icon-expand_more mr-2 my-auto transition-transform"
          [class.rotate-180]="expandedTiposEntregas"
        ></i>
        <span class="text-sm font-medium cursor-pointer"
          >{{ expandedTiposEntregas ? 'Ver menos' : 'Ver mais' }} entregas</span
        >
      </div>
    </div>
  </ng-container>
</ng-template>
