<vip-modal
  [disabled]="disabled"
  [title]="title"
  [open]="open"
  [bottom]="bottom"
  [showCloseButton]="showCloseButton"
  (closeClick)="handleClick()"
>
  <div class="grid grid-cols-1">
    <h2 class="vip-modal-title">{{ title }}</h2>
    <h3 class="vip-modal-sub-title" [innerHTML]="subTitle"></h3>

    <div
      *ngIf="buttonCancelText || buttonConfirmText"
      class="vip-modal-buttons"
    >
      <vip-button
        *ngIf="buttonCancelText"
        type="outline"
        data-cy="btn-cancelar"
        [hasIcon]="!!buttonCancelIcon"
        (btnClick)="handleDialogClick(false)"
      >
        <i *ngIf="buttonCancelIcon" class="{{ buttonCancelIcon }}"></i>
        <span> {{ buttonCancelText }} </span></vip-button
      >
      <vip-button
        *ngIf="buttonConfirmText"
        data-cy="btn-confirmar"
        [hasIcon]="!!buttonConfirmIcon"
        (btnClick)="handleDialogClick(true)"
      >
        <i *ngIf="buttonConfirmIcon" class="{{ buttonConfirmIcon }}"></i>
        <span> {{ buttonConfirmText }} </span>
      </vip-button>
    </div>
  </div>
</vip-modal>
