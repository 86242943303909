<!-- <button aria-haspopup="true" aria-expanded="false" aria-controls="vip-menu-minha-conta">Abrir</button> -->
<ul id="vip-menu-minha-conta" role="menu">
  <ng-container *ngFor="let menuItem of options">
    <ng-container
      *ngIf="
        menuItem['key'] === 'cashback' || menuItem['key'] === 'notifications';
        else simpleTemplate
      "
    >
      <li
        *ngIf="menuItem['key'] | showCashbackMenuOption: exibeCashback"
        role="menuitem"
        (click)="closeDropdown.emit()"
        [attr.data-cy]="'menu-item-' + menuItem['key']"
      >
        <a
          class="w-full flex justify-between"
          [routerLink]="'/minha-conta/' + [menuItem['value']]"
        >
          <span>{{ menuItem['title'] }}</span>
          <span
            [ngClass]="{
              'info-notification': menuItem.key === 'notifications'
            }"
            >{{
              {
                key: menuItem.key,
                exibeSaldo: exibeCashback,
                saldo: saldoCashback,
                erroSaldo: saldoCashbackError,
                notifications: quantidadeNaoLida
              } | menuInfo
            }}</span
          >
        </a>
      </li>
    </ng-container>
    <ng-template #simpleTemplate>
      <li role="menuitem" (click)="closeDropdown.emit()">
        <a [routerLink]="'/minha-conta/' + [menuItem['value']]">{{
          menuItem['title']
        }}</a>
      </li>
    </ng-template>

    <ng-container
      *ngIf="
        menuItem['showDivider'] &&
        (menuItem['key'] | showCashbackMenuOption: exibeCashback)
      "
    >
      <hr class="vip-divider" />
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isLogged">
    <li
      class="cursor-pointer"
      role="menuitem"
      data-cy="option-logout"
      (click)="goLogout.emit(); closeDropdown.emit()"
    >
      Sair
    </li>
  </ng-container>
</ul>
