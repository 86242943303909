<div class="mx-4" *ngIf="!cadastrandoNovoCartao">
  <vip-cartao-credito-desktop
    [cartoes]="cartoes"
    [cartaoSelecionadoId]="cartaoSelecionadoId"
    (cadastrarNovoCartao)="cadastrarNovoCartao()"
    (alterarCartaoSelecionado)="alterarCartaoSelecionado($event)"
    (deletarCartao)="deletarCartao.emit($event)"
  ></vip-cartao-credito-desktop>
  <div
    *ngIf="cartaoSelecionadoId"
    class="grid grid-cols-[minmax(auto,_280px)_minmax(auto,_280px)] gap-4 pt-3"
  >
    <vip-field
      label="CVV*"
      [error]="
        formGroup.controls.cc_cvv.touched && formGroup.controls.cc_cvv.invalid
      "
      [message]="validations.cc_cvv"
      [validations]="formGroup.controls.cc_cvv.errors"
    >
      <input
        id="cc_cvv"
        type="tel"
        [formControl]="formGroup.controls.cc_cvv"
        [dropSpecialCharacters]="false"
        mask="000||0000"
        (blur)="checkCvv()"
      />
    </vip-field>
    <vip-select
      data-cy="select-parcelas"
      id="installments"
      class="text-left"
      label="Parcelar compra*"
      [error]="
        formGroup.controls.installments.touched &&
        formGroup.controls.installments.invalid
      "
      [message]="{ required: 'Campo obrigatório' }"
      [validations]="formGroup.controls.installments.errors"
      [formControl]="formGroup.controls.installments"
      [options]="parcelas || []"
      [value]="parcelaSelecionada"
      (itemSelected)="selecionarParcela($event)"
    >
    </vip-select>
  </div>
</div>
<div class="px-1 pt-3" *ngIf="cadastrandoNovoCartao">
  <span class="m-3 grey-subtitle"> Insira os dados do seu cartão:</span>
  <vip-pagamento-cartao
    data-cy="cadastrar-novo-cartao"
    [compraEmProcesso]="compraEmProcesso"
    [formaPagamentoSelecionada]="formaPagamentoSelecionada || null"
    [resetarCcNumber$]="resetarCcNumber$"
    [privateLabelBandeiraAutomatica]="privateLabelBandeiraAutomatica"
    [formaPagamentosOptions]="formaPagamentosOptionsCartaoCredito"
    [formasPagamentosPrePagas]="formasPagamentosPrePagasCartaoCredito ?? []"
    [permitirSalvarCartao]="permitirSalvarCartao"
    [permiteSelecaoManual]="permiteSelecaoManual ?? false"
    [permitirBandeiraAutomatica]="permitirBandeiraAutomatica"
    [bandeiraNaoAtendida]="bandeiraNaoAtendida ?? false"
    [limparForm$]="limparForm$"
    [parcelas]="parcelas"
    [compraEmProcessoError]="compraEmProcessoError"
    (alterarBandeiraCartao)="alterarBandeiraCartao.emit($event)"
    (buscarBandeiraCartao)="buscarBandeiraCartao.emit($event)"
    (parcelaChange)="selecionarParcela($event)"
    (confirmarClick)="adicionarCartao.emit($event)"
    (formEmitter)="formEmitter.emit($event)"
  >
  </vip-pagamento-cartao>
  <div class="mx-3 w-[114px]">
    <vip-button
      size="ex-small"
      type="outline"
      data-cy="button-cancelar-cadastrar-novo-cartao"
      (btnClick)="cadastrandoNovoCartao = false; resetarFormaPgto.emit()"
      *ngIf="cartoes?.length && permitirSalvarCartao"
    >
      Cancelar
    </vip-button>
  </div>
</div>
