import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ICentroDistribuicao } from '@vip/core';

export const getCentrosDistribuicao = createAction(
  '[CD/Page] Get CDs Retirada',
  props<{ cep?: string }>()
);

export const getCentrosDistribuicaoSuccess = createAction(
  '[CD/API] Get CDs Retirada Success',
  props<{ cds: ICentroDistribuicao[] }>()
);

export const getCentrosDistribuicaoFailure = createAction(
  '[CD/API] Get CDs Retirada Failure',
  props<{ error: HttpErrorResponse }>()
);

export const migrarCentroDistribuicao = createAction(
  '[Migrar CD] Migrar CD',
  props<{
    cdAtualId: number;
    novoCd: ICentroDistribuicao;
  }>()
);

export const migrarCentroDistribuicaoSuccess = createAction(
  '[Migrar CD] Migrar CD Success',
  props<{ cd: ICentroDistribuicao }>()
);

export const migrarCentroDistribuicaoFailure = createAction(
  '[Migrar CD] Migrar CD Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getCentroDistribuicaoById = createAction(
  '[CD/Page] Get CD por id',
  props<{ cdId: number }>()
);

export const getCentroDistribuicaoByIdSuccess = createAction(
  '[CD/API] Get CD por id Success',
  props<{ cd: ICentroDistribuicao }>()
);

export const getCentroDistribuicaoByIdFailure = createAction(
  '[CD/API] Get CD por id Failure',
  props<{ error: HttpErrorResponse }>()
);

export const setCentroDistribuicaoSelecionado = createAction(
  '[Carrinho/CD] Set Centro Distribuicao Selecionado',
  props<{ cd: ICentroDistribuicao }>()
);

export const confirmarCentrosDistribuicao = createAction(
  '[CD/Page] Confirmar Centro de Distribuicao'
);

export const validarCentroDistribuicaoAutonomo = createAction(
  '[CD/API] Validar Centro de Distribuicao Autônomo',
  props<{ cdId: number }>()
);

export const setCentroDistribuicaoDoCarrinho = createAction(
  '[CD/API] Selecionar Centro de Distribuicao que possui carrinho',
  props<{ cdId: number }>()
);

export const setCentroDistribuicaoDoCarrinhoSuccess = createAction(
  '[CD/API] Selecionar Centro de Distribuicao que possui carrinho Success',
  props<{ cd: ICentroDistribuicao }>()
);

export const setCentroDistribuicaoDoCarrinhoFailure = createAction(
  '[CD/API] Selecionar Centro de Distribuicao que possui carrinho Failure',
  props<{ error: HttpErrorResponse }>()
);
