import { ButtonModule } from '@vip/ui/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListSelectComponent } from './list-select/list-select.component';

@NgModule({
  imports: [CommonModule, ButtonModule],
  declarations: [ListSelectComponent],
  exports: [ListSelectComponent],
})
export class ListSelectModule {}
