/* eslint-disable no-useless-escape */
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Injectable,
  Inject,
  PLATFORM_ID,
  Optional,
  ComponentRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import {
  IPushNotificationAndroid,
  IPushNotificationIos,
  IS_APP,
  IStorage,
  PushNotificationData,
  STORAGE,
  StringUtilsService,
  TipoOfertaEnum,
} from '@vip/core';
import { MessageComponent, MessageService } from '@vip/ui/message';
import { DialogService, IDialog } from '@vip/ui/modal';
import { NotificacaoWsService } from '@vip/ws';
import { MessagePayload, NotificationPayload } from 'firebase/messaging';
import { noop } from 'rxjs';
import { take } from 'rxjs/operators';

const CLIENTE_TOKEN = 'cliente-token';

type ModelType = {
  [model: string]: (pushNotification: PushNotificationData) => {
    id?: string;
    disponivel: boolean;
    slug?: string;
    baseUrl: string;
  } | null;
};

@Injectable({
  providedIn: 'root',
})
export class NotificationUtilsService {
  isIos = this.platform.is('ios');

  constructor(
    @Optional() @Inject(IS_APP) private isApp: boolean,
    private messageService: MessageService,
    private platform: Platform,
    private router: Router,
    private dialogService: DialogService,
    private notificacaoWsService: NotificacaoWsService,
    @Inject(STORAGE) readonly storage: IStorage
  ) {}

  async showForegroundNotification(
    payload: MessagePayload | PushNotificationSchema
  ) {
    const { notification } = payload;
    let data: any = payload.data;
    if (this.isApp) data = this.generateNotificationData(payload.data);

    const messageComponentRef = await this.openMessageByPlatform(notification);

    const componentRef = messageComponentRef;
    const { instance: messageComponent } = componentRef;
    const notificationId = data?.id;

    messageComponent.messageClick.pipe(take(1)).subscribe(() => {
      this.performAction(data);
      this.messageService.clearMessage(messageComponentRef);
    });

    if (notificationId) {
      this.setNotificationRead(notificationId).subscribe(noop);
    }
  }

  performAction(pushNotification: PushNotificationData): void {
    if (!pushNotification?.model) return;
    if (
      pushNotification.model === 'push_transacional' &&
      pushNotification.page
    ) {
      pushNotification.model = pushNotification.page;
    }

    const models: ModelType = {
      Produto: this.getProdutoNotification,
      Colecao: this.getColecaoNotication,
      Oferta: this.getOfertaNotication,
      DetalheDoPedido: this.getDetalheDoPedidoNotification,
      ProdutoDetalhe: this.getProdutoDetalheNotification,
      Carrinho: this.getCarrinhoNotification,
      Indicacoes: this.getIndicacoesNotification,
      IndicacoesDescontos: this.getIndicacoesNotification,
      Receita: this.getReceitaNotification,
    };

    const notificationMethod = models[pushNotification.model];

    if (!notificationMethod) {
      return;
    }

    const notificationData = notificationMethod(pushNotification);

    if (!notificationData) {
      return;
    }

    const { id, slug, baseUrl, disponivel } = notificationData;

    if (disponivel) {
      if (id && slug) {
        this.router.navigateByUrl(`/${baseUrl}/${id}/${slug}`);
      } else if (id) {
        this.router.navigateByUrl(`/${baseUrl}/${id}`);
      } else if (slug) {
        this.router.navigateByUrl(`/${baseUrl}/${slug}`);
      } else {
        this.router.navigateByUrl(`/${baseUrl}`);
      }
    } else {
      this.showDialogNotificacaoExpirada(pushNotification.model);
    }
  }

  getProdutoNotification(pushNotification: PushNotificationData) {
    const produto = pushNotification.produto || '';
    const { id, link } = JSON.parse(produto) || {};

    return {
      id,
      disponivel: !!id,
      slug: link,
      baseUrl: 'produto',
    };
  }

  getColecaoNotication(pushNotification: PushNotificationData) {
    const colecao = pushNotification.colecao || '';
    const { id, slug, data_final: dataFinal } = JSON.parse(colecao) || {};

    return {
      id,
      slug,
      baseUrl: 'colecoes',
      disponivel: !!id && new Date(dataFinal).getTime() >= new Date().getTime(),
    };
  }

  getOfertaNotication(pushNotification: PushNotificationData) {
    const oferta = pushNotification.oferta || '';
    const {
      tag: slug,
      tipo_oferta_id: tipoOfertaId,
      oferta_id: ofertaId,
      data_final: dataFinal,
    } = JSON.parse(oferta) || {};

    const isCombo = tipoOfertaId === TipoOfertaEnum.COMBO_DE_PRODUTOS;

    return {
      id: ofertaId,
      slug: isCombo ? slug : undefined,
      baseUrl: isCombo ? 'produto' : 'ofertas-detalhes',
      disponivel: new Date(dataFinal).getTime() >= new Date().getTime(),
    };
  }

  getDetalheDoPedidoNotification(notification: PushNotificationData) {
    if (!notification.compra) return null;
    return {
      id: notification.compra,
      baseUrl: `minha-conta/detalhes-compra`,
      disponivel: true,
    };
  }

  getProdutoDetalheNotification(notification: PushNotificationData) {
    if (!notification.produto) return null;

    return {
      id: notification.produto,
      slug: StringUtilsService.slugify(notification.produto_descricao),
      baseUrl: `produto`,
      disponivel: true,
    };
  }

  getCarrinhoNotification(notification: PushNotificationData) {
    return {
      baseUrl: notification.compra ? `pagamento` : `carrinho`,
      disponivel: true,
    };
  }

  getReceitaNotification(notification: PushNotificationData) {
    if (!notification.receita) return null;

    return {
      id: notification.receita,
      baseUrl: `receita`,
      disponivel: true,
    };
  }

  getIndicacoesNotification() {
    return {
      baseUrl: `convide-amigos`,
      disponivel: true,
    };
  }

  setNotificationRead(notificationId: number) {
    if (this.storage.getItem(CLIENTE_TOKEN)) {
      return this.notificacaoWsService.setNotificationReadByClient(
        notificationId
      );
    } else {
      return this.notificacaoWsService.setNotificationRead(notificationId);
    }
  }

  generateNotificationData(
    pushNotificationData:
      | IPushNotificationIos
      | IPushNotificationAndroid
      | {
          [key: string]: string;
        }
      | undefined
  ): PushNotificationData {
    let result = {} as any;

    if (this.isIos) {
      for (const key in pushNotificationData) {
        if (Object.prototype.hasOwnProperty.call(pushNotificationData, key)) {
          result[key.replace('gcm.notification.', '')] = (
            pushNotificationData as IPushNotificationIos
          )[key as keyof IPushNotificationIos];
        }
      }
    } else {
      result = pushNotificationData as IPushNotificationAndroid;
    }

    return result;
  }

  private showDialogNotificacaoExpirada(model: string): void {
    const dialogData: IDialog = {
      title: `${model} Indisponível`,
      subTitle: `Infelizmente a notificação para ess${
        model === 'Produto' ? 'e' : 'a'
      } ${model} está expirada!`,
      open: true,
      disabled: false,
    };
    this.dialogService.openDialog(dialogData);
    this.dialogService.closeClick
      .pipe(take(1))
      .subscribe(() => this.dialogService.clearDialog());
  }

  private async openMessageByPlatform(
    notification?: NotificationPayload
  ): Promise<ComponentRef<MessageComponent>> {
    return this.isApp
      ? await this.messageService.openInfoMessage(
          notification?.title || 'Você recebeu uma nova notificação',
          2
        )
      : await this.messageService.openInfoMessage(
          notification?.body || '',
          2,
          notification?.title || 'Você recebeu uma nova notificação'
        );
  }
}
