import { TamanhoProdutoPrecoEnum } from './../produto-preco-tamanho.enum';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IFaixaPrecos, IOferta } from '@vip/core';

@Component({
  selector: 'vip-produto-preco-oferta-tag',
  templateUrl: './produto-preco-oferta-tag.component.html',
  styleUrls: ['./produto-preco-oferta-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProdutoPrecoOfertaTagComponent {
  @Input() oferta!: IOferta;
  @Input() faixa?: IFaixaPrecos;
  @Input() descontoSimples = false;
  @Input() isCard = false;
  @Input() tamanho: TamanhoProdutoPrecoEnum = TamanhoProdutoPrecoEnum.GRANDE;
  @Input() mostrarTextoDeUnidades = true;

  TamanhoProdutoPreco = TamanhoProdutoPrecoEnum;

  unidadesPluralMapping: { [k: string]: string } = {
    '=1': '1 UNIDADE',
    other: '# UNIDADES',
  };
  unidadesCardPluralMapping: { [k: string]: string } = {
    other: '# por',
  };
}
