import { Injectable } from '@angular/core';
import { BaseWsService } from '../common/base-ws-service';
import * as CashbackWsServiceTypes from './cashback-ws.types';
import { AlterarSenhaCashbackRequest } from './cashback-ws.types';

@Injectable({
  providedIn: 'root',
})
export class CashbackWsService {
  private cashbackUrl = '/loja/cashback';

  constructor(private ws: BaseWsService<CashbackWsService>) {}

  getSaldoCashback(filialId: number) {
    return this.ws.get<CashbackWsServiceTypes.GetSaldoCashbackResponse>(
      'getSaldoCashback',
      `${this.cashbackUrl}/saldo`,
      { FilialID: filialId.toString() }
    );
  }

  getExtratoCashback(filialId: number, dataInicial: string, dataFinal: string) {
    return this.ws.get<CashbackWsServiceTypes.GetExtratoCashbackResponse>(
      'getExtratoCashback',
      `${this.cashbackUrl}/extrato/${dataInicial}/${dataFinal}`,
      { FilialID: filialId.toString() }
    );
  }

  alterarSenhaCashback(filialId: number, novaSenha: string) {
    return this.ws.post<
      AlterarSenhaCashbackRequest,
      CashbackWsServiceTypes.AlterarSenhaCashbackResponse
    >(
      'alterarSenhaCashback',
      `${this.cashbackUrl}/alterar-senha`,
      { nova_senha: novaSenha },
      { FilialID: filialId.toString() }
    );
  }
}
