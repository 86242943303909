import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ClienteTokenService } from '@vip/state/cliente';
import { UntilDestroy } from '@ngneat/until-destroy';

@Injectable()
@UntilDestroy()
export class OfertaExclusivaGuard implements CanActivate {
  constructor(
    private router: Router,
    private clienteTokenService: ClienteTokenService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (state.url.includes('oferta-exclusiva')) {
      if (this.clienteTokenService.getToken() == '') {
        this.router.navigate(['/login'], {
          queryParams: { redirectTo: state.url },
        });
        return false;
      } else {
        return true;
      }
    }
    return true;
  }
}
