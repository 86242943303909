import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { RadioButtonComponent } from '../radio-button/radio-button.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventEmitter } from '@angular/core';

let nextUniqueId = 0;
@UntilDestroy()
@Component({
  selector: 'vip-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RadioGroupComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RadioGroupComponent
  implements AfterViewInit, ControlValueAccessor
{
  @ContentChildren(forwardRef(() => RadioButtonComponent), {
    descendants: true,
  })
  private _radios!: QueryList<RadioButtonComponent>;
  private _disabled: boolean | string = false;
  private _color = 'primary';
  touched = false;

  @Input() default: unknown;
  @Input() direction = 'vertical';
  @Input() wrap = false;
  @Input() visible = false;

  @Input()
  get color() {
    return this._color;
  }
  set color(color) {
    this._color = color;
    this._radios?.forEach((radio) => {
      radio.color = this._color;
      radio.markForCheck();
    });
  }

  @Input()
  get value(): unknown {
    return this.selected ? this.selected.value : null;
  }
  set value(value) {
    this.default = this.default === undefined ? value : this.default;
    const radio = this.findRadioButtonByValue(value);
    if (radio) {
      radio.checked = true;
      this.onRadioButtonChange(radio);
    } else {
      this.uncheckAll();
    }
  }
  @Input() name = `vip-radio-group-${nextUniqueId++}`;
  @Input() label?: string;

  @Input()
  get disabled(): boolean | string {
    return this._disabled;
  }
  set disabled(value: boolean | string) {
    this._disabled = this.getBooleanValue(value);
    this.changeDetector.markForCheck();
  }

  @Output() selected: RadioButtonComponent | null = null;

  @Output() valueChange = new EventEmitter<unknown>();

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.initRadioButtons();
    if (this.default) {
      this.checkDefaultRadio();
    } else {
      this.selected = this.getCurrentCheckedRadio();
    }
  }

  getRadios() {
    return this._radios;
  }

  onRadioButtonChange(radio: RadioButtonComponent) {
    if (radio.checked && (!this.selected || this.selected != radio)) {
      this.uncheckAll();
      radio.checked = true;
      this.selected = this.getCurrentCheckedRadio();
      this.onChange(this.value);
      this.markRadiosForCheck();
    }
  }

  getCurrentCheckedRadio(): RadioButtonComponent | null {
    const checked = this._radios.find((radio) => radio.checked === true);
    return checked || null;
  }

  findRadioButtonByValue(value: unknown): RadioButtonComponent | null {
    const checked =
      this._radios && this._radios.find((radio) => radio.value === value);
    return checked || null;
  }

  initRadioButtons() {
    this._radios.forEach((radio: RadioButtonComponent) => {
      radio.name = this.name;
      radio.color = this.color;
      radio.changed
        .pipe(untilDestroyed(this))
        .subscribe(this.onRadioButtonChange.bind(this));
    });
  }

  checkDefaultRadio(): void {
    const radio = this.findRadioButtonByValue(this.default);
    if (radio) {
      this.selected = radio;
      radio.checked = true;
      radio.notifyChange();
    }
  }

  uncheckAll() {
    this._radios &&
      this._radios.forEach((radio: RadioButtonComponent) => {
        radio.checked = false;
      });
    this.selected = null;
  }

  writeValue(value: unknown) {
    this.value = value;
  }

  onChange = (value: unknown) => {
    this.valueChange.emit(value);
    return value;
  };
  registerOnChange(onChange: (value: unknown) => unknown) {
    this.onChange = onChange;
  }

  onTouched = () => {
    return this.touched;
  };
  registerOnTouched(onTouched: () => boolean) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  markRadiosForCheck() {
    this._radios.forEach((radio) => {
      radio.markForCheck();
    });
  }

  getBooleanValue(value: boolean | string): boolean {
    return value != null && `${value}` !== 'false';
  }
}
