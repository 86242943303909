import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabsModule } from '@vip/ui/tabs';
import { OpcoesPagamentoComponent } from './opcoes-pagamento/opcoes-pagamento.component';
import { CardBasicoModule } from '@vip/ui/card-basico';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldModule } from '@vip/ui/field';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CartaoLojaOpcaoPagamentoComponent } from './cartao-loja-opcao-pagamento/cartao-loja-opcao-pagamento.component';
import { PagamentoModule } from '@vip/views/pagamento';
import { CreditoOpcaoPagamentoComponent } from './credito-opcao-pagamento/credito-opcao-pagamento.component';
import { ButtonModule } from '@vip/ui/button';
import { CartaoCreditoDesktopModule } from '@vip/views/cartao-credito-desktop';
import { NgxMaskModule } from 'ngx-mask';
import { SelectModule } from '@vip/ui/select';
import { CodigoPromocionalOpcaoPagamentoComponent } from './codigo-promocional-opcao-pagamento/codigo-promocional-opcao-pagamento.component';
import { CupomModule } from '@vip/ui/cupom';
import { EmptyModule } from '@vip/ui/empty';
import { SkeletonModule } from '@vip/ui/skeleton';
import { CreditoOpcaoPagamentoSkeletonComponent } from './credito-opcao-pagamento-skeleton/credito-opcao-pagamento-skeleton.component';
import { CarteiraDigitalPagamentoComponent } from './carteira-digital-pagamento/carteira-digital-pagamento.component';
import { SelectableCardModule } from '@vip/ui/selectable-card';
import { IconPagamentoOnlinePipe } from './pipes/icon-pagamento-online.pipe';

@NgModule({
  imports: [
    CommonModule,
    TabsModule,
    CardBasicoModule,
    FormsModule,
    ReactiveFormsModule,
    FieldModule,
    SelectModule,
    CurrencyMaskModule,
    PagamentoModule,
    ButtonModule,
    CartaoCreditoDesktopModule,
    NgxMaskModule.forRoot(),
    CupomModule,
    EmptyModule,
    SkeletonModule,
    SelectableCardModule,
  ],
  declarations: [
    OpcoesPagamentoComponent,
    CartaoLojaOpcaoPagamentoComponent,
    CreditoOpcaoPagamentoComponent,
    CodigoPromocionalOpcaoPagamentoComponent,
    CreditoOpcaoPagamentoSkeletonComponent,
    CarteiraDigitalPagamentoComponent,
    IconPagamentoOnlinePipe,
  ],
  exports: [OpcoesPagamentoComponent],
})
export class OpcoesPagamentoModule {}
