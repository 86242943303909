import { Injectable } from '@angular/core';
import { fetch } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap, withLatestFrom } from 'rxjs/operators';

import { CepApiService } from '@vip/api';
import { FacebookPixelService, IFilial } from '@vip/core';
import { FilialFacade } from '@vip/state/filial';
import * as CepActions from './cep.actions';
import { MessageService } from '@vip/ui/message';

@Injectable()
export class CepEffects {
  validarCepAtendidoPelaFilial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CepActions.validarCepAtendidoPelaFilial),
      withLatestFrom(this.filialFacade.filial$),
      fetch({
        run: (action, filial: IFilial) =>
          this.cepApiService
            .validarCepAtendidoPelaFilial(action.cep, filial.id)
            .pipe(
              map((cepAtendido) =>
                CepActions.validarCepAtendidoPelaFilialSuccess({
                  cepAtendido: { ...cepAtendido },
                })
              )
            ),

        onError: (action, error) =>
          CepActions.validarCepAtendidoPelaFilialFailure({ error }),
      })
    )
  );

  addSolicitacaoCep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CepActions.addSolicitacaoCep),
      withLatestFrom(this.filialFacade.filial$),
      fetch({
        run: (action, filial: IFilial) =>
          this.cepApiService
            .addSolicitacaoCep(filial.id, action.cep, action.email)
            .pipe(
              map((solicitacao) =>
                CepActions.addSolicitacaoCepSuccess({
                  solicitacaoDeCepEnviada: !!solicitacao,
                })
              )
            ),
        onError: (action, error) => {
          return CepActions.addSolicitacaoCepFailure({ error });
        },
      })
    )
  );

  showAddSolicitacaoSuccessMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CepActions.addSolicitacaoCepSuccess),
        fetch({
          run: () => {
            this.messageService.openSuccessMessage(
              'Seu email foi cadastrado com sucesso.',
              2
            );
          },
        })
      ),
    { dispatch: false }
  );

  disparaFacebookPixelLead$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CepActions.validarCepAtendidoPelaFilialSuccess),
        tap((cepAtendido) => {
          this.facebookPixelService.lead(cepAtendido.cepAtendido.endereco.cep);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private cepApiService: CepApiService,
    private facebookPixelService: FacebookPixelService,
    private filialFacade: FilialFacade,
    private messageService: MessageService
  ) {}
}
