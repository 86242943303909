import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { FieldModule } from '@vip/ui/field';
import { ButtonModule } from '@vip/ui/button';
import { RadioModule } from '@vip/ui/radio';
import { PerguntasComponent } from './perguntas/perguntas.component';
import { PerguntasFormularioComponent } from './perguntas-formulario/perguntas-formulario.component';
import { ScreenTitleModule } from '@vip/ui/screen-title';

@NgModule({
  imports: [
    CommonModule,
    FieldModule,
    ButtonModule,
    ReactiveFormsModule,
    RadioModule,
    ScreenTitleModule,
  ],
  declarations: [PerguntasFormularioComponent, PerguntasComponent],
  exports: [PerguntasFormularioComponent, PerguntasComponent],
})
export class PerguntasModule {}
