import { Injectable } from '@angular/core';
import {
  ICartao,
  IComboItemCarrinho,
  ICompraPagamento,
  IFormaPagamento,
  IProduto,
  IProdutoCombo,
} from '@vip/core';

@Injectable()
export class BucketsService {
  readonly FORMA_PAGAMENTO_FILE_PATH = 'files/forma_pagamentos';

  addBucketProduto<T extends Pick<IProduto, 'imagem'>>(
    produto: T,
    bucket?: string
  ) {
    return <T>{
      ...produto,
      imagem: produto.imagem ? `${bucket}/${produto.imagem}` : produto.imagem,
    };
  }

  addBucketCombo(produto: IProdutoCombo[], bucket?: string): IProdutoCombo[] {
    if (!Array.isArray(produto)) {
      return [];
    }

    return produto.map((comboItem) => ({
      ...comboItem,
      produtos: comboItem.produtos.map((produtoDoCombo) =>
        this.addBucketProduto<IComboItemCarrinho>(produtoDoCombo, bucket)
      ),
    }));
  }

  addBucketFormaPagamento(formaPagamento: IFormaPagamento, bucket?: string) {
    return {
      ...formaPagamento,
      logomarca: formaPagamento.logomarca
        ? `${bucket}/${this.FORMA_PAGAMENTO_FILE_PATH}/${formaPagamento.logomarca}`
        : formaPagamento.logomarca,
    };
  }

  addBucketCompraPagamento(compraPagamento: ICompraPagamento, bucket?: string) {
    return {
      ...compraPagamento,
      forma_pagamento: {
        ...compraPagamento.forma_pagamento,
        logo: compraPagamento.forma_pagamento.logo
          ? `${bucket}/${this.FORMA_PAGAMENTO_FILE_PATH}/${compraPagamento.forma_pagamento.logo}`
          : compraPagamento.forma_pagamento.logo,
      },
    };
  }

  addBucketCartao(cartao: ICartao, bucket?: string): ICartao {
    return {
      ...cartao,
      logomarca: cartao.bandeira
        ? `${bucket}/${this.FORMA_PAGAMENTO_FILE_PATH}/${cartao.bandeira}.png`
        : cartao.bandeira,
    };
  }
}
