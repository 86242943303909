import { NgModule } from '@angular/core';
import { CoreModule } from '@vip/core';
import { RippleModule } from '@vip/ui/ripple';
import { CommonModule } from '@angular/common';
import { CupomComponent } from './components/cupom/cupom.component';
import { TimeToEndPipe } from './pipes/time-to-end.pipe';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ExpiredDueDatePipe } from './pipes/expired-due-date.pipe';
import { CheckboxModule } from '@vip/ui/checkbox';

@NgModule({
  imports: [CommonModule, CoreModule, RippleModule, CheckboxModule],
  declarations: [
    CupomComponent,
    TimeToEndPipe,
    ProgressBarComponent,
    ExpiredDueDatePipe,
  ],
  exports: [CupomComponent, ExpiredDueDatePipe],
})
export class CupomModule {}
