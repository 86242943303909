import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCep from './+state/cep.reducer';
import { CepEffects } from './+state/cep.effects';
import { CepFacade } from './+state/cep.facade';
import { MessageService } from '@vip/ui/message';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCep.CEP_FEATURE_KEY, fromCep.reducer),
    EffectsModule.forFeature([CepEffects]),
  ],
  providers: [CepFacade, MessageService],
})
export class CepStateModule {}
