import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ICarrinho, ICentroDistribuicao } from '@vip/core';
import { ISituacaoCarrinhoSessaoCliente } from '@vip/api';

export const getCarrinho = createAction(
  '[Carrinho/Page] Busca Carrinho de cliente',
  props<{ enviaGa4: boolean }>()
);

export const getCarrinhoSuccess = createAction(
  '[Carrinho/API] Busca Carrinho de clienteSuccess',
  props<{ carrinho: ICarrinho; enviaGa4?: boolean }>()
);

export const getCarrinhoFailure = createAction(
  '[Carrinho/API] Busca Carrinho de cliente Failure',
  props<{ error: HttpErrorResponse }>()
);

export const setCarrinho = createAction(
  '[Carrinho/API] Set Carrinho',
  props<{ carrinho: ICarrinho }>()
);

export const removeCarrinho = createAction(
  '[Carrinho/Page] Remove Carrinho de cliente',
  props<{ carrinhoId: number }>()
);

export const removeCarrinhoSuccess = createAction(
  '[Carrinho/API] Remove Carrinho de clienteSuccess'
);

export const removeCarrinhoFailure = createAction(
  '[Carrinho/API] Remove Carrinho de cliente Failure',
  props<{ error: HttpErrorResponse }>()
);

export const resetCarrinho = createAction('[Carrinho/API] limpar o Carrinho');

export const situacaoCarrinhoSessaoClienteSuccess = createAction(
  '[Carrinho/API] Situacao Carrinho Sessao Cliente Success',
  props<{ situacao: ISituacaoCarrinhoSessaoCliente; isLojaAutonoma: boolean }>()
);

export const situacaoCarrinhoSessaoClienteFailure = createAction(
  '[Carrinho/API] Situacao Carrinho Sessao Cliente Failure',
  props<{ error: HttpErrorResponse }>()
);

export const manterCarrinhoAnonimo = createAction(
  '[Carrinho/API] Manter Carrinho Anonimo'
);

export const manterCarrinhoAnonimoSuccess = createAction(
  '[Carrinho/API] Manter Carrinho Anonimo Success',
  props<{ pedidoCancelado: number }>()
);

export const manterCarrinhoAnonimoFailure = createAction(
  '[Carrinho/API] Manter Carrinho Anonimo Failure',
  props<{ error: HttpErrorResponse }>()
);

export const manterCarrinhoCliente = createAction(
  '[Carrinho/API] Manter Carrinho Cliente'
);

export const manterCarrinhoClienteSuccess = createAction(
  '[Carrinho/API] Manter Carrinho Cliente Success',
  props<{ cd: ICentroDistribuicao }>()
);

export const manterCarrinhoClienteFailure = createAction(
  '[Carrinho/API] Manter Carrinho Cliente Failure',
  props<{ error: HttpErrorResponse }>()
);

export const setCarrinhoOutroCD = createAction(
  '[Carrinho/API] Set Carrinho de outro CD',
  props<{ carrinho: ICarrinho }>()
);

export const getAllCarrinho = createAction(
  '[Carrinho/Carrinho Item] Busca Carrinho e Carrinho Item do Cliente'
);

export const verificaItensDesativados = createAction(
  '[Carrinho/API] Verifica Itens Desativados'
);

export const verificaItensDesativadosSuccess = createAction(
  '[Carrinho/API] Verifica Itens Desativados Success',
  props<{ message: string | null }>()
);

export const verificaItensDesativadosFailure = createAction(
  '[Carrinho/API] Verifica Itens Desativados Failure',
  props<{ error: HttpErrorResponse }>()
);
