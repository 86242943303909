import { Injectable } from '@angular/core';
import {
  BuscarCombosIdResponse,
  BuscarCombosResponse,
} from './combos.service.types';
import { BaseApiService } from '../common/base-api-service';
import { forkJoin, Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { IFilterCombos, IProduto, IProdutoCombo, QueryParams } from '@vip/core';

type ComboParams = {
  filialId: number;
  cdId: number;
  comboId: number;
};

@Injectable({
  providedIn: 'root',
})
export class CombosApiService {
  private combosUrl = `loja/combos`;

  constructor(private api: BaseApiService<CombosApiService>) {}

  getCombos(
    filialId: number,
    cdId: number,
    queryParams?: QueryParams
  ): Observable<BuscarCombosResponse> {
    const queryString = this.buildQueryString(queryParams);
    return this.api.get<BuscarCombosResponse>(
      'getCombos',
      `/filial/${filialId}/centro_distribuicao/${cdId}/${this.combosUrl}${queryString}`
    );
  }

  getComboProdutos(
    filialId: number,
    cdId: number,
    comboId: number,
    queryParams?: QueryParams
  ): Observable<{ produtos: IProduto }> {
    const queryString = this.buildQueryString(queryParams);
    return this.api
      .get<BuscarCombosIdResponse>(
        'getComboProdutos',
        `/filial/${filialId}/centro_distribuicao/${cdId}/${this.combosUrl}/${comboId}/produtos${queryString}`
      )
      .pipe(pluck('data'));
  }

  getCombosDepartamento(
    filialId: number,
    cdId: number,
    queryParams?: QueryParams
  ): Observable<{ combos: number[]; filtros: IFilterCombos[] }> {
    const queryString = this.buildQueryString(queryParams);
    return this.api
      .get<BuscarCombosResponse>(
        'getCombosDepartamento',
        `/filial/${filialId}/centro_distribuicao/${cdId}/${this.combosUrl}${queryString}`
      )
      .pipe(pluck('data'));
  }

  getCombosProdutosByArray(
    params: ComboParams[],
    queryParams?: QueryParams
  ): Observable<IProdutoCombo[]> {
    const requests = params.map((param) =>
      this.getComboProdutos(
        param.filialId,
        param.cdId,
        param.comboId,
        queryParams
      ).pipe(
        map((response) => {
          return response as unknown as IProdutoCombo[];
        })
      )
    );

    return forkJoin(requests).pipe(
      map((produtosArray) =>
        produtosArray.reduce((acc, curr) => acc.concat(curr), [])
      )
    );
  }

  private buildQueryString(queryParams?: QueryParams): string {
    if (!queryParams || Object.keys(queryParams).length === 0) {
      return '';
    }

    return (
      '?' +
      Object.entries(queryParams)
        .filter(([_, value]) => value !== undefined)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
        )
        .join('&')
    );
  }
}
