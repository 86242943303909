import { IClienteNotificacoes } from '../interfaces';

export const clienteNotificacoesVisualizadaMock = {
  visualizada: true,
  cliente_id: 1,
  removida: false,
  id: '1',
  created: new Date(),
  modified: new Date(),
  notificacao_id: 1,
} as IClienteNotificacoes;

export const clienteNotificacoesNaoVisualizadaMock = {
  visualizada: false,
  cliente_id: 1,
  removida: false,
  id: '1',
  created: new Date(),
  modified: new Date(),
  notificacao_id: 1,
} as IClienteNotificacoes;
