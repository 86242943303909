import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchComponent } from './switch/switch.component';
import { RippleModule } from '@vip/ui/ripple';
import { CoreModule, IsOnlyOneEnablePipe } from '@vip/core';

@NgModule({
  imports: [CommonModule, RippleModule, CoreModule],
  declarations: [SwitchComponent],
  exports: [SwitchComponent],
  providers: [IsOnlyOneEnablePipe],
})
export class SwitchModule {}
