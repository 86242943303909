import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalPerguntasComponent } from './modal-perguntas/modal-perguntas.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldModule } from '@vip/ui/field';
import { RadioModule } from '@vip/ui/radio';
import { ButtonModule } from '@vip/ui/button';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FieldModule,
    RadioModule,
    ButtonModule,
  ],
  declarations: [ModalPerguntasComponent],
  exports: [ModalPerguntasComponent],
})
export class ModalPerguntasModule {}
