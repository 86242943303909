import { createAction, props } from '@ngrx/store';
import { IProduto } from '@vip/core';

export const inserirProdutosIndisponiveis = createAction(
  '[Substituir Produto Page] Inserir indisponíveis produtos para substituicao',
  props<{ produtos: IProduto[] }>()
);

export const definirSubstituto = createAction(
  '[Substituir Produto] Definir produto substituto',
  props<{
    idProdutoIndisponivel: number;
    quantidade: number;
    produto: IProduto;
  }>()
);

export const atualizarSubstituto = createAction(
  '[Substituir Produto] Atualiza o produto substituto',
  props<{ idProdutoIndisponivel: number; produto: IProduto }>()
);

export const removerSubstituto = createAction(
  '[Substituir Produto] Remove o produto substituto',
  props<{ idProdutoIndisponivel: number }>()
);

export const setIdProdutoIndisponivel = createAction(
  '[Substituir Produto Page] Definir id do produto indisponível selecionado',
  props<{ idProdutoIndisponivel: number }>()
);

export const adicionarItensAoCarrinho = createAction(
  '[Substituir Produto Page] Adiciona os itens substitutos ao carrinho'
);

export const resetProdutos = createAction(
  '[Substituir Produto Page] Reseta itens substitutos e indisponíveis'
);

export const naoSubstituir = createAction(
  '[Substituir Produto Page] Não substitui itens substitutos e indisponíveis'
);
