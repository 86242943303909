import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { GenericStoreStatusEnum, IGenericState } from '@vip/state/utils';
import { IPerguntaSobreCompra } from '@vip/core';
import * as PerguntasActions from './perguntas.actions';

export const PERGUNTAS_FEATURE_KEY = 'perguntas';

export type PerguntasState = IGenericState<
  EntityState<IPerguntaSobreCompra>
> & { pulouPerguntas: boolean };

export const perguntasAdapter: EntityAdapter<IPerguntaSobreCompra> =
  createEntityAdapter<IPerguntaSobreCompra>();

export const initialState: PerguntasState = {
  data: perguntasAdapter.getInitialState(),
  pulouPerguntas: false,
  error: null,
  status: GenericStoreStatusEnum.PENDING,
};

const perguntasReducer = createReducer(
  initialState,
  on(PerguntasActions.getPerguntas, (state) => ({
    ...state,
    status: GenericStoreStatusEnum.LOADING,
    error: null,
  })),
  on(PerguntasActions.getPerguntasSuccess, (state, { perguntas }) => ({
    ...state,
    data: perguntasAdapter.upsertMany(perguntas, state.data),
    status: GenericStoreStatusEnum.SUCCESS,
    error: null,
  })),
  on(
    PerguntasActions.limparRespostas,
    PerguntasActions.salvarRespostasSuccess,

    (state) => ({
      ...state,
      data: perguntasAdapter.map(
        (pergunta) => ({
          ...pergunta,
          cliente_pergunta_resposta: undefined,
          pulouPerguntas: false,
        }),
        state.data
      ),
    })
  ),
  on(
    PerguntasActions.responderPerguntas,
    PerguntasActions.responderPerguntasResumo,
    PerguntasActions.popularRespostasPerguntasCompra,
    (state, { respostas }) => ({
      ...state,
      data: perguntasAdapter.updateMany(
        Object.keys(respostas).map((key) => ({
          id: Number(key),
          changes: {
            cliente_pergunta_resposta: respostas[Number(key)]
              ? {
                  cliente_pergunta_id: Number(key),
                  resposta: respostas[Number(key)],
                }
              : undefined,
          },
        })),
        state.data
      ),
    })
  ),
  on(
    PerguntasActions.getPerguntasFailure,
    PerguntasActions.salvarRespostasFailure,
    PerguntasActions.salvarRespostasResumoFailure,
    (state, { error }) => ({
      ...state,
      status: GenericStoreStatusEnum.ERROR,
      error: error.error.error,
    })
  ),
  on(PerguntasActions.pulouPerguntas, (state, { pulouPerguntas }) => ({
    ...state,
    pulouPerguntas,
  }))
);

export function reducer(state: PerguntasState | undefined, action: Action) {
  return perguntasReducer(state, action);
}
