import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardProdutoModule } from '@vip/ui/card-produto';
import { EmptyModule } from '@vip/ui/empty';
import { GridCardModule } from '@vip/ui/grid-card';
import { ScreenTitleModule } from '@vip/ui/screen-title';
import { ListaProdutosComponent } from './lista-produtos/lista-produtos.component';
import { PaginationModule } from '@vip/ui/pagination';

@NgModule({
  imports: [
    CommonModule,
    CardProdutoModule,
    EmptyModule,
    GridCardModule,
    ScreenTitleModule,
    PaginationModule,
  ],
  declarations: [ListaProdutosComponent],
  exports: [ListaProdutosComponent],
})
export class ListaProdutosModule {}
