import { MessageService } from '@vip/ui/message';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { fetch } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, forkJoin } from 'rxjs';
import { map, withLatestFrom, tap } from 'rxjs/operators';
import { ListaApiService } from '@vip/api';
import * as ListaActions from './lista.actions';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { select, Store } from '@ngrx/store';
import { listaSelectors } from './lista.selectors';
import { LayoutUtilsService } from '@vip/core';

@Injectable()
export class ListaEffects {
  getListas$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(ListaActions.loadListas)),
      this.cdFacade.filialECdSelecionado$,
    ]).pipe(
      map(([action, [filial, cdSelecionado]]) => {
        return { ...action, filial, cdSelecionado };
      }),
      fetch({
        run: (action) => {
          return forkJoin([
            this.listaApiService.getMinhasListas(
              action.filial.id,
              action.cdSelecionado.id
            ),
            this.listaApiService.getListasCompartilhadas(
              action.filial.id,
              action.cdSelecionado.id
            ),
          ]).pipe(
            map((result) => {
              const minhasListas = result[0].data;
              const compartilhadas = result[1].data;
              return ListaActions.loadListaSuccess({
                minhasListas,
                compartilhadas,
                minhasListasECompartilhadas:
                  minhasListas.concat(compartilhadas),
              });
            })
          );
        },
        onError: (action, error) => ListaActions.loadListaFailure({ error }),
      })
    );
  });

  criarLista$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListaActions.criarLista),
      withLatestFrom(this.cdFacade.filialECdSelecionado$),
      map(([action, [filial, cdSelecionado]]) => {
        return { ...action, filial, cdSelecionado };
      }),
      fetch({
        run: (action) =>
          this.listaApiService
            .criarLista(
              action.filial.id,
              action.cdSelecionado.id,
              action.novaLista
            )
            .pipe(
              map((res) => ListaActions.criarListaSuccess({ lista: res.data }))
            ),
        onError: (action, error) => ListaActions.criarListaFailure({ error }),
      })
    )
  );

  editarLista$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListaActions.editarLista),
      withLatestFrom(this.cdFacade.filialECdSelecionado$),
      map(([action, [filial, cdSelecionado]]) => {
        return { ...action, filial, cdSelecionado };
      }),
      fetch({
        run: (action) =>
          this.listaApiService
            .editarLista(
              action.filial.id,
              action.cdSelecionado.id,
              action.lista
            )
            .pipe(
              map((res) => ListaActions.editarListaSuccess({ lista: res.data }))
            ),
        onError: (action, error) => ListaActions.editarListaFailure({ error }),
      })
    )
  );

  getLista$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(ListaActions.getLista)),
      this.cdFacade.filialECdSelecionado$,
    ]).pipe(
      withLatestFrom(this.store.pipe(select(listaSelectors.getListas()))),
      map(([[action, [filial, cdSelecionado]], listas]) => {
        return { ...action, filial, cdSelecionado, listas };
      }),
      fetch({
        run: (action) =>
          this.listaApiService
            .getMinhaLista(
              action.filial.id,
              action.cdSelecionado.id,
              action.listaId
            )
            .pipe(
              map((response) =>
                ListaActions.getListaSuccess({ lista: response.data })
              )
            ),

        onError: (action, error) => ListaActions.getListaFailure({ error }),
      })
    );
  });

  excluirLista$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListaActions.excluirLista),
      withLatestFrom(this.cdFacade.filialECdSelecionado$),
      map(([action, [filial]]) => {
        return { ...action, filial };
      }),
      fetch({
        run: (action) =>
          this.listaApiService
            .excluirLista(action.filial.id, action.listaId)
            .pipe(
              map(() =>
                ListaActions.excluirListaSuccess({ listaId: action.listaId })
              )
            ),
        onError: (_, error) => ListaActions.excluirListaFailure({ error }),
      })
    )
  );

  excluirListaSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ListaActions.excluirListaSuccess),
        withLatestFrom(this.layoutUtilsService.isDesktop$),
        tap(([action, isDesktop]) => {
          this.messageService.openSuccessMessage(
            'Lista excluida com sucesso',
            1.5
          );
          const url = isDesktop
            ? 'minha-conta/minhas-listas'
            : '/minhas-listas';
          this.router.navigateByUrl(url);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private listaApiService: ListaApiService,
    private cdFacade: CentroDistribuicaoFacade,
    private store: Store,
    private messageService: MessageService,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService
  ) {}
}
