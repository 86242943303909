import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { format, isToday, isTomorrow, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

@Pipe({ name: 'DayOfWeekWithFullDate' })
export class DayOfWeekWithFullDatePipe implements PipeTransform {
  titleCasePipe: TitleCasePipe = inject(TitleCasePipe);

  transform(dateString: string): string | null {
    const date = parseISO(dateString);

    if (isToday(date)) return `Hoje, ${this.getfullDateWithoutDayOfWeek(date)}`;
    if (isTomorrow(date))
      return `Amanhã, ${this.getfullDateWithoutDayOfWeek(date)}`;
    return this.titleCasePipe.transform(
      format(date, 'EEEE, d MMM y', { locale: ptBR })
    );
  }

  private getfullDateWithoutDayOfWeek(date: Date) {
    return this.titleCasePipe.transform(
      format(date, 'd MMM y', { locale: ptBR })
    );
  }
}
