import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ICarrinhoItemInconsistencia,
  IProduto,
  IProdutoCarrinho,
  LayoutUtilsService,
} from '@vip/core';
import { DialogService, IDialog } from '@vip/ui/modal';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SubstituirProdutoFacade } from '../../substituir-produto.facade';

type ActionSubstituirProduto = {
  type: string;
  itemNaoAtualizados: ICarrinhoItemInconsistencia[];
  itemAtualizado: IProdutoCarrinho[];
};

type ActionListSubstituirProduto = {
  type?: string;
  itensAtualizados?: IProdutoCarrinho[];
};
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SubstituirProdutoService {
  private finalizouSubstituirProdutoSubject = new Subject<boolean>();
  private openModalSubstituirProdutosSubject = new Subject<void>();
  private latestActionSubstituirProdutoSubject =
    new BehaviorSubject<ActionSubstituirProduto | null>(null);

  private latestListActionSubstituirProdutoSubject =
    new BehaviorSubject<ActionListSubstituirProduto | null>(null);

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private substituirProdutoFacade: SubstituirProdutoFacade,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  openSubstituirProdutos(
    itensIndisponiveis: ICarrinhoItemInconsistencia[]
  ): void {
    const dialogData: Pick<
      IDialog,
      'title' | 'subTitle' | 'buttonConfirmText' | 'buttonCancelText'
    > = {
      title: 'Produto Indisponível',
      subTitle: `O produto <b>“${itensIndisponiveis[0].mercadoria.descricao}”</b> está
     indisponível, deseja substituí-lo por outro item semelhante?`,
      buttonConfirmText: 'Sim, desejo substituí-lo!',
      buttonCancelText: 'Não, obrigado!',
    };

    if (itensIndisponiveis.length > 1) {
      this.adicionarNoStateERedirecionar(itensIndisponiveis);
    } else {
      this.dialogService.openDialog({
        open: true,
        disabled: false,
        ...dialogData,
      });

      this.dialogService.dialogClick
        .pipe(untilDestroyed(this))
        .subscribe((value) => {
          this.dialogService.clearDialog().then(() => {
            if (value) {
              this.adicionarNoStateERedirecionar(itensIndisponiveis);
            } else {
              this.openModalSuccesAdicionarProdutos();
            }
          });
        });

      this.dialogService.closeClick.pipe(untilDestroyed(this)).subscribe(() => {
        this.finalizarSubstituirProdutos();
      });
    }
  }

  finalizarSubstituirProdutos(): void {
    this.finalizouSubstituirProdutoSubject.next(true);
  }

  limparFinalizarSubstituirProdutos(): void {
    this.finalizouSubstituirProdutoSubject.next(false);
  }

  openModalSubstituirProdutos(): void {
    this.openModalSubstituirProdutosSubject.next();
  }

  openModalSuccesAdicionarProdutos(): void {
    this.substituirProdutoFacade.naoSubstituirProdutos();
  }

  get finalizouSubstituirProduto$(): Observable<boolean> {
    return this.finalizouSubstituirProdutoSubject.asObservable();
  }

  get openModalSubstituirProdutos$(): Observable<void> {
    return this.openModalSubstituirProdutosSubject.asObservable();
  }

  get latestActionSubstituirProduto$(): Observable<ActionSubstituirProduto | null> {
    return this.latestActionSubstituirProdutoSubject.asObservable();
  }

  set latestActionSubstituirProduto(value: ActionSubstituirProduto | null) {
    this.latestActionSubstituirProdutoSubject.next(value);
  }

  get latestListActionSubstituirProduto$(): Observable<ActionListSubstituirProduto | null> {
    return this.latestListActionSubstituirProdutoSubject.asObservable();
  }

  set latestListActionSubstituirProduto(
    value: ActionListSubstituirProduto | null
  ) {
    this.latestListActionSubstituirProdutoSubject.next(value);
  }

  private adicionarNoStateERedirecionar(
    itensIndisponiveis: ICarrinhoItemInconsistencia[]
  ): void {
    const produtosNaoAtualizados: IProduto[] = itensIndisponiveis.map(
      (item) => ({ ...item.mercadoria, quantidade: item.quantidade })
    );
    this.substituirProdutoFacade.inserirProdutosIndisponiveis(
      produtosNaoAtualizados
    );
    if (this.layoutUtilsService.isDesktop()) {
      this.openModalSubstituirProdutos();
      return;
    }
    this.router.navigateByUrl('substituir-produto');
  }
}
