import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundModalComponent } from './not-found-component/not-found-modal.component';
import { DialogService, ModalModule } from '@vip/ui/modal';
import { FieldModule } from '@vip/ui/field';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@vip/ui/button';
import { ProdutoFacade } from '@vip/state/produto';
import { HttpClientModule } from '@angular/common/http';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { FilialFacade } from '@vip/state/filial';

@NgModule({
  imports: [
    CommonModule,
    FieldModule,
    ModalModule,
    ReactiveFormsModule,
    ButtonModule,
    HttpClientModule,
  ],
  declarations: [NotFoundModalComponent],
  exports: [NotFoundModalComponent],
  providers: [
    DialogService,
    ProdutoFacade,
    CentroDistribuicaoFacade,
    FilialFacade,
  ],
})
export class NotFoundModalModule {}
