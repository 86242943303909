import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FilterTypeEnum, IFilter, PaginaFiltro } from '@vip/core';
import { filtroSelectors } from './filtro.selectors';
import * as FiltroActions from './filtro.actions';

@Injectable()
export class FiltroFacade {
  filtroAtivoPagina$ = this.store.pipe(
    select(filtroSelectors.getFiltroAtivoPagina())
  );
  filtroAtivo$ = this.store.pipe(select(filtroSelectors.getFiltroAtivo()));
  filtroClassificacaoMercadologica$ = this.store.pipe(
    select(filtroSelectors.getFiltroClassificacaoMercadologica())
  );
  filtrosBusca$ = this.store.pipe(select(filtroSelectors.getFiltrosBusca()));
  filtroColecao$ = this.store.pipe(select(filtroSelectors.getFiltrosColecao()));
  filtroFavoritos$ = this.store.pipe(
    select(filtroSelectors.getFiltroFavoritos())
  );
  filtroMinhasListas$ = this.store.pipe(
    select(filtroSelectors.getFiltroMinhasListas())
  );
  filtrosOfertas$ = this.store.pipe(
    select(filtroSelectors.getFiltrosOfertas())
  );
  filtrosCombos$ = this.store.pipe(select(filtroSelectors.getFiltrosCombos()));
  error$ = this.store.pipe(select(filtroSelectors.getError()));
  loaded$ = this.store.pipe(select(filtroSelectors.isLoaded()));

  filtroOrdenacao: IFilter = {
    label: 'Ordenar por:',
    name: 'orderby',
    type: FilterTypeEnum.INTERNAL,
    multiple: false,
    options: [
      {
        label: 'Maior Preço',
        value: 'produto.preco:desc',
        checked: false,
      },
      {
        label: 'Menor Preço',
        value: 'produto.preco:asc',
        checked: false,
      },
      {
        label: 'Mais Vendidos',
        value: 'produto.quantidade_vendida:desc',
        checked: false,
      },
      {
        label: 'A - Z',
        value: 'produto.descricao:asc',
        checked: false,
      },
      {
        label: 'Z - A',
        value: 'produto.descricao:desc',
        checked: false,
      },
    ],
  };

  constructor(private store: Store) {}

  abrirFiltro(paginaFiltro: PaginaFiltro) {
    this.store.dispatch(FiltroActions.abrirFiltro({ paginaFiltro }));
  }

  setFiltroAtivo(paginaFiltro: PaginaFiltro) {
    this.store.dispatch(FiltroActions.setFiltroAtivo({ paginaFiltro }));
  }

  aplicarFiltro(filtros: IFilter[], paginaFiltro: PaginaFiltro) {
    this.store.dispatch(FiltroActions.aplicarFiltro({ filtros, paginaFiltro }));
  }

  getFiltroApiDepartamento(cmId: number, filtrosLocais: IFilter[]) {
    this.store.dispatch(
      FiltroActions.getFiltroApiDepartamento({ cmId, filtrosLocais })
    );
  }

  getFiltroApiSecao(cmId: number, filtrosLocais: IFilter[]) {
    this.store.dispatch(
      FiltroActions.getFiltroApiSecao({ cmId, filtrosLocais })
    );
  }

  getFiltroBusca(
    buscaId: number,
    filtrosQueryParams?: string,
    filtros?: IFilter[]
  ) {
    this.store.dispatch(
      FiltroActions.getFiltroBusca({
        buscaId,
        filtrosLocais: filtrosQueryParams,
        filtros,
      })
    );
  }

  getFiltroApiFavoritos() {
    this.store.dispatch(FiltroActions.getFiltroApiFavoritos());
  }

  getFiltroColecao(colecaoId: number, filtrosLocais?: string) {
    this.store.dispatch(
      FiltroActions.getFiltroColecao({
        colecaoId,
        filtrosLocais,
      })
    );
  }

  getFiltroMinhasLista(listaId: number) {
    this.store.dispatch(FiltroActions.getFiltroMinhasListas({ listaId }));
  }

  getFiltrosOfertas() {
    this.store.dispatch(FiltroActions.getFiltrosOfertas());
  }

  getFiltrosCombos() {
    this.store.dispatch(FiltroActions.getFiltrosCombos());
  }

  getQueryParams(filtros: IFilter[]) {
    let condicoes = filtros.map((filtro) => {
      let condicao = '';
      let valor = '';
      if (filtro.multiple) {
        const checked = filtro.options.filter(
          (opcao) => opcao.checked && opcao.value !== null
        );
        valor = checked.map((opcao) => opcao.value).join(',');
      } else {
        const checked = filtro.options.find(
          (opcao) => opcao.checked && opcao.value !== null
        );
        valor = checked ? checked.value : '';
      }
      condicao = valor !== '' ? `${filtro.name}=${valor}` : '';
      return condicao;
    });
    condicoes = condicoes.filter((condicao) => condicao.length);
    return condicoes.join('&');
  }

  atualizarFiltroCombo(novoFiltro: IFilter) {
    this.store.dispatch(
      FiltroActions.atualizarFiltroCombo({ filtroOrdenacao: novoFiltro })
    );
  }
}
