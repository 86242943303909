import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { ButtonBaseDirective } from '../button/button-base.directive';

@Component({
  selector: 'vip-button-icon-rounded',
  templateUrl: './button-icon-rounded.component.html',
  styleUrls: ['./button-icon-rounded.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ButtonIconRoundedComponent extends ButtonBaseDirective {
  hasIcon = true;
  rippleBorderRadius = '100%';
}
