import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { RadioPillComponent } from './radio-pill/radio-pill.component';
import { TabsModule } from '@vip/ui/tabs';
import { RippleModule } from '@vip/ui/ripple';
import { RadioListItemComponent } from './radio-list-item/radio-list-item.component';

@NgModule({
  imports: [CommonModule, TabsModule, RippleModule],
  declarations: [
    RadioButtonComponent,
    RadioGroupComponent,
    RadioPillComponent,
    RadioListItemComponent,
  ],
  exports: [
    RadioButtonComponent,
    RadioGroupComponent,
    RadioPillComponent,
    RadioListItemComponent,
  ],
})
export class RadioModule {}
