import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FilterTypeEnum, IFilter, IFilterOptions } from '@vip/core';
import SwiperCore, { Navigation, SwiperOptions } from 'swiper';
import { ViewEncapsulation } from '@angular/core';

SwiperCore.use([Navigation]);

@Component({
  selector: 'vip-header-filter',
  templateUrl: './header-filter.component.html',
  styleUrls: ['./header-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HeaderFilterComponent {
  @Input() filters: IFilter[] = [];

  @Input() isDesktop = false;

  @Output()
  externalFilterClick: EventEmitter<IFilterOptions> = new EventEmitter();
  @Output() filterClick: EventEmitter<Event> = new EventEmitter();

  swiperConfig: SwiperOptions = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    allowTouchMove: false,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  onChangeExternalFilter(event: IFilterOptions) {
    this.externalFilterClick.emit({ ...event, checked: !event.checked });
  }

  getInternalFiltersCount() {
    let count = 0;

    this.getInternalFilters().forEach((filter) => {
      count += filter.options.filter((option) => option.checked).length;
    });
    return count;
  }

  getInternalFilters() {
    return this.filters.filter(
      (filter) => filter.type === FilterTypeEnum.INTERNAL
    );
  }

  getExternalFilters() {
    return this.filters.filter(
      (filter) => filter.type === FilterTypeEnum.EXTERNAL
    );
  }
}
