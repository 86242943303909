import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'vip-solicitacao-cadastrada',
  templateUrl: './solicitacao-cadastrada.component.html',
  styleUrls: ['../../validar-cep/validar-cep.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolicitacaoCadastradaComponent {
  @Input() loading = false;

  @Output() confirm = new EventEmitter();

  onConfirm() {
    this.confirm.emit(true);
  }
}
