import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { mergeTakeOne } from '@vip/state/utils';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { BucketsFacade } from '@vip/state/buckets';
import { ProdutoApiService } from '@vip/api';
import { ProdutoStore } from './produto.store';
import { ProdutoFacade } from './produto.facade';

@Injectable()
export class ProdutosOfertasStore extends ProdutoStore {
  readonly getVitrine = this.effect((payload$) => {
    return payload$.pipe(
      mergeTakeOne(
        this.cdFacade.filialECdSelecionado$,
        this.bucketsFacade.bucketProduto$.pipe(
          filter((bucket) => bucket !== '')
        )
      ),
      map(([, [filial, cdSelecionado], bucket]) => {
        return { filial, cdSelecionado, bucket };
      }),
      switchMap((payload) => {
        this.updateStatus('loading');
        return this.produtoApiService
          .getVitrine(payload.filial.id, payload.cdSelecionado.id)
          .pipe(
            tapResponse(
              (response) => {
                this.updateProdutosAndPaginator(
                  {
                    produtos: response.data,
                    paginator: response.paginator,
                  },
                  payload.bucket
                );
                this.produtoFacade.enviaImpressao(response.data);
              },
              (error) =>
                this.updateError((error as HttpErrorResponse).error.error)
            )
          );
      })
    );
  });
  readonly getOfertasByTag = this.effect(
    (payload$: Observable<{ tag: string; filtro?: string }>) => {
      return combineLatest([
        payload$,
        this.cdFacade.filialECdSelecionado$.pipe(
          filter(
            (filialECdSelecionado) =>
              filialECdSelecionado[0] !== undefined &&
              filialECdSelecionado[1] !== undefined
          )
        ),
        this.bucketsFacade.bucketProduto$.pipe(
          filter((bucket) => bucket !== '')
        ),
        this.page$.pipe(filter((page) => page !== 0)),
      ]).pipe(
        map(([payload, filialECdSelecionado, bucket, page]) => {
          return { ...payload, filialECdSelecionado, bucket, page };
        }),
        switchMap((payload) => {
          this.updateStatus('loading');
          return this.produtoApiService
            .getOfertasByTag(
              payload.filialECdSelecionado[0].id,
              payload.filialECdSelecionado[1].id,
              payload.tag,
              payload.page,
              payload.filtro || ''
            )
            .pipe(
              tapResponse(
                (response) => {
                  this.updateProdutosAndPaginator(
                    {
                      produtos: response.data,
                      paginator: response.paginator,
                    },
                    payload.bucket
                  );
                  this.produtoFacade.enviaImpressao(response.data);
                },
                (error) =>
                  this.updateError((error as HttpErrorResponse).error.error)
              )
            );
        })
      );
    }
  );

  readonly getOfertasById = this.effect(
    (payload$: Observable<{ filtro: string; id: string }>) => {
      return combineLatest([
        payload$,
        this.cdFacade.filialECdSelecionado$.pipe(
          filter(
            (filialECdSelecionado) =>
              filialECdSelecionado[0] !== undefined &&
              filialECdSelecionado[1] !== undefined
          )
        ),
        this.bucketsFacade.bucketProduto$.pipe(
          filter((bucket) => bucket !== '')
        ),
        this.page$.pipe(filter((page) => page !== 0)),
      ]).pipe(
        map(([payload, filialECdSelecionado, bucket, page]) => {
          return { ...payload, filialECdSelecionado, bucket, page };
        }),
        switchMap((payload) => {
          this.updateStatus('loading');
          return this.produtoApiService
            .getOfertasById(
              payload.filialECdSelecionado[0].id,
              payload.filialECdSelecionado[1].id,
              payload.id,
              payload.filtro,
              payload.page
            )
            .pipe(
              tapResponse(
                (response) => {
                  this.updateProdutosAndPaginator(
                    {
                      produtos: response.data,
                      paginator: response.paginator,
                    },
                    payload.bucket
                  );
                  this.produtoFacade.enviaImpressao(response.data);
                },
                (error) =>
                  this.updateError((error as HttpErrorResponse).error.error)
              )
            );
        })
      );
    }
  );
  readonly getTodasOfertas = this.effect(
    (payload$: Observable<{ filtro: string }>) => {
      return combineLatest([
        payload$,
        this.page$.pipe(filter((page) => page !== 0)),
      ])
        .pipe(
          mergeTakeOne(
            this.cdFacade.filialECdSelecionado$,
            this.bucketsFacade.bucketProduto$.pipe(
              filter((bucket) => bucket !== '')
            )
          )
        )
        .pipe(
          map(([[payload, page], filialECdSelecionado, bucket]) => {
            return { ...payload, page, filialECdSelecionado, bucket };
          }),
          switchMap((payload) => {
            this.updateStatus('loading');
            return this.produtoApiService
              .getTodasOfertas(
                payload.filialECdSelecionado[0].id,
                payload.filialECdSelecionado[1].id,
                payload.filtro,
                payload.page
              )
              .pipe(
                tapResponse(
                  (response) => {
                    this.updateProdutosAndPaginator(
                      {
                        produtos: response.data,
                        paginator: response.paginator,
                      },
                      payload.bucket
                    );
                    this.produtoFacade.enviaImpressao(response.data);
                  },
                  (error) =>
                    this.updateError((error as HttpErrorResponse).error.error)
                )
              );
          })
        );
    }
  );

  constructor(
    private produtoApiService: ProdutoApiService,
    private bucketsFacade: BucketsFacade,
    private cdFacade: CentroDistribuicaoFacade,
    private produtoFacade: ProdutoFacade
  ) {
    super();
  }
}
