import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RippleModule } from '@vip/ui/ripple';
import { ButtonModule } from '@vip/ui/button';
import { CardBasicoComponent } from './card-basico/card-basico.component';

@NgModule({
  imports: [CommonModule, ButtonModule, RippleModule],
  declarations: [CardBasicoComponent],
  exports: [CardBasicoComponent],
})
export class CardBasicoModule {}
