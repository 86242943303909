import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MessageService } from './services/message.service';
import { MessageComponent } from './components/message/message.component';
import { FullColorMessageComponent } from './components/full-color-message/full-color-message.component';

@NgModule({
  imports: [CommonModule],
  declarations: [MessageComponent, FullColorMessageComponent],
  exports: [MessageComponent, FullColorMessageComponent],
  providers: [MessageService],
})
export class MessageModule {}
