<ng-container *ngIf="isDesktop; else mobileTemplate">
  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <ng-container
      *ngIf="!resumoFinal || perguntasRespondidas; else resumoFinalTemplate"
    >
      <div
        class="w-full overflow-hidden rounded"
        [ngClass]="{
          'shadow-md': perguntasRespondidas && resumoFinal,
          border: !resumoFinal
        }"
      >
        <div class="mx-4 my-3 flex items-center">
          <i
            class="mr-3 text-xl icon-format_list_bulleted vip-color-secondary-main"
          ></i>
          <p
            class="inline-block"
            [ngClass]="{ 'font-bold text-lg': perguntasRespondidas }"
          >
            Sobre a compra
          </p>
        </div>
        <hr />
        <dl data-cy="perguntas-sobre-compra" class="m-4">
          <ng-container *ngFor="let pergunta of perguntas; let last = last">
            <dt class="vip-color-first-default text-xs mb-1">
              {{ pergunta.pergunta }}
            </dt>
            <dd
              class="vip-color-secondary-default text-tiny"
              [ngClass]="{ 'mb-4': !last }"
            >
              {{
                pergunta.cliente_pergunta_resposta?.resposta || 'Sem resposta'
              }}
            </dd>
          </ng-container>
        </dl>
      </div>
    </ng-container>

    <ng-template #resumoFinalTemplate>
      <div
        *ngIf="perguntas.length > 0"
        class="card-compra-finalizada mt-2 mb-1"
      >
        <div class="w-full overflow-hidden shadow-md rounded">
          <div class="mx-4 my-4 flex items-center">
            <i
              class="mr-3 text-xl icon-format_list_bulleted vip-color-secondary-main"
            ></i>
            <p class="inline-block font-bold text-lg">Sobre a compra</p>
          </div>
          <hr />
          <div class="text-xs">
            <div class="flex items-center mx-4 my-4">
              <vip-modal-perguntas
                [perguntas]="perguntas"
                [exibeNaCompra]="false"
                (salvarPerguntas)="responderPerguntas.emit($event)"
              >
              </vip-modal-perguntas>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loadingTemplate>
    <div class="card-compra-finalizada mt-2 mb-1" data-cy="loadind-skeleton">
      <div class="w-full overflow-hidden shadow-md rounded">
        <div class="mx-4 my-4 flex items-center">
          <i
            class="mr-3 text-xl icon-format_list_bulleted vip-color-secondary-main"
          ></i>
          <p class="inline-block font-bold text-lg">Sobre a compra</p>
        </div>
        <hr />

        <dl data-cy="perguntas-sobre-compra" class="m-4 flex flex-col gap-1">
          <dt
            *vipSkeleton="true; height: '16px'; width: '25rem'"
            class="vip-color-first-default text-xs mb-2"
          ></dt>
          <dd
            *vipSkeleton="true; height: '12px'; width: '20rem'"
            class="vip-color-secondary-default text-tiny mb-1"
          ></dd>
          <dt
            *vipSkeleton="true; height: '16px'; width: '25rem'"
            class="vip-color-first-default text-xs mb-2"
          ></dt>
          <dd
            *vipSkeleton="true; height: '12px'; width: '20rem'"
            class="vip-color-secondary-default text-tiny mb-1"
          ></dd>
          <dt
            *vipSkeleton="true; height: '16px'; width: '25rem'"
            class="vip-color-first-default text-xs mb-2"
          ></dt>
          <dd
            *vipSkeleton="true; height: '12px'; width: '20rem'"
            class="vip-color-secondary-default text-tiny mb-1"
          ></dd>
        </dl>
      </div>
    </div>
  </ng-template>
</ng-container>
<ng-template #mobileTemplate>
  <vip-card-basico
    icon="icon-format_list_bulleted"
    title="Sobre a Compra"
    buttonType="raised"
    [button]="button"
    [disableRipple]="true"
    [sideButton]="sideButton"
    (buttonClick)="buttonClick.emit()"
    (sideButtonClick)="buttonClick.emit()"
  >
    <dl data-cy="perguntas-sobre-compra">
      <ng-container *ngIf="pergunta; else questionsTemplate">
        <h2 class="vip-color-first-default font-bold mb-3">
          {{ pergunta.pergunta }}
        </h2>
        <vip-radio-group>
          <ng-container *ngFor="let opcao of pergunta.cliente_pergunta_opcoes">
            <vip-radio-button
              [value]="opcao.valor"
              [checked]="
                opcao.valor === pergunta.cliente_pergunta_resposta?.resposta
              "
              (changed)="
                formGroup?.get(pergunta.id.toString())?.setValue(opcao.valor);
                responderPerguntas.emit()
              "
            >
              {{ opcao.valor }}
            </vip-radio-button>
          </ng-container>
        </vip-radio-group>
        <div
          *ngIf="
            pergunta &&
            formGroup?.get(pergunta.id.toString())?.touched &&
            formGroup?.get(pergunta.id.toString())?.invalid
          "
          class="messages-container"
        >
          Preenchimento obrigatório
        </div>
      </ng-container>

      <ng-template #questionsTemplate>
        <ng-container *ngFor="let pergunta of perguntas; let last = last">
          <dt class="vip-color-first-default text-xs mb-1">
            {{ pergunta.pergunta }}
          </dt>
          <dd class="vip-color-secondary-default text-tiny">
            {{ pergunta.cliente_pergunta_resposta?.resposta || 'Sem resposta' }}
          </dd>
          <hr
            *ngIf="button || !last"
            class="vip-divider {{ last ? 'mt-2' : 'my-2' }}"
          />
        </ng-container>
      </ng-template>
    </dl>
  </vip-card-basico>
</ng-template>
