<div
  *ngIf="produto"
  class="vip-card-produto-substituir flex items-center p-2 shadow"
>
  <vip-image
    [alt]="produto.descricao"
    [defaultImageUrl]="defaultImageUrl"
    [imageUrl]="
      produto.imagem ? replaceImageSize(produto.imagem, '144x144') : ''
    "
    [size]="imageSizesEnum.sm"
    class="mr-2"
  ></vip-image>
  <div class="flex-1">
    <div
      class="flex vip-card-produto-substituir-precos"
      [ngClass]="{
        'vip-card-produto-substituir-precos--modal-indisponiveis':
          isModalTemplate
      }"
    >
      <span
        class="text-sm vip-color-first-default font-bold"
        [ngClass]="{ 'text-lg': isModalTemplate }"
        >{{ precoTotal | currency: 'BRL' }}</span
      >
      <span
        class="text-xs vip-color-secondary-main"
        [ngClass]="{ 'text-sm': isModalTemplate }"
        >{{ produto.preco | currency: 'BRL' }} |
        {{
          (produto | quantidadeUnidadeDiferenteProduto) || 1
            | unidadeDiferente: (produto | unidadeSigla)
        }}</span
      >
    </div>
    <div class="flex items-center mt-1">
      <span
        class="vip-card-produto-substituir-descricao flex-1 text-xs vip-color-first-default"
        [ngClass]="{
          'vip-card-produto-substituir-descricao--modal-indisponiveis':
            isModalTemplate
        }"
        >{{ produto.descricao }}</span
      >
      <vip-spin
        (changed)="handleSpinAlterado($event)"
        *ngIf="showSpin && !isModalTemplate"
        [disabled]="disableSpin"
        [large]="true"
        [max]="produto.quantidade_maxima"
        [quantityUnity]="produto | quantidadeUnidadeDiferenteProduto"
        [quantity]="spinQuantity"
        [unityMeasureAbbreviation]="produto.unidade_sigla"
        addIcon="icon-add"
        size="ex-small"
        [produto]="produto"
        (click)="$event.stopPropagation()"
      ></vip-spin>
    </div>
    <span
      *ngIf="showUnidade"
      class="vip-card-produto-substituir-unidades text-xs vip-color-primary-main"
      >{{
        produto.unidade_sigla.toLowerCase() !== 'un'
          ? (quantidadeASubstituir *
              (produto | quantidadeUnidadeDiferenteProduto)
            | unidadeDiferente: produto.unidade_sigla.toLowerCase():true)
          : (quantidadeASubstituir | i18nPlural: messageMap)
      }}</span
    >
  </div>
  <vip-spin
    class="spin-modal"
    (changed)="handleSpinAlterado($event)"
    *ngIf="showSpin && isModalTemplate"
    [disabled]="disableSpin"
    [large]="true"
    [max]="produto.quantidade_maxima"
    [quantityUnity]="produto | quantidadeUnidadeDiferenteProduto"
    [quantity]="spinQuantity"
    [unityMeasureAbbreviation]="produto.unidade_sigla"
    [squared]="true"
    addIcon="icon-add"
    [size]="'small'"
    (click)="$event.stopPropagation()"
  ></vip-spin>
  <span
    class="vip-color-error-main indisponivel text-sm font-medium"
    *ngIf="showIndisponivelBadge"
  >
    Indisponível
  </span>
</div>
