import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showCashbackMenuOption',
})
export class ShowCashbackMenuOptionPipe implements PipeTransform {
  transform(optionKey: string, exibeCashback: boolean): boolean {
    return !(optionKey === 'cashback' && !exibeCashback);
  }
}
