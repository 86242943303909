import { EntregaRetiradaBaseDirective } from '@vip/container/entrega-retirada';
import { TelevendasFacade } from '@vip/state/televendas';
import {
  TipoEntregaFacade,
  TipoEntregaUtilsService,
} from '@vip/state/tipo-entrega';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { EnderecoFacade } from '@vip/state/endereco';
import { ClienteFacade } from '@vip/state/cliente';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { CepFacade } from '@vip/state/cep';
import { DialogService, ModalService } from '@vip/ui/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ValidarCepContainerService } from '@vip/container/validar-cep';
import { FilialFacade } from '@vip/state/filial';
import { CompraEmProcessoFacade } from '@vip/state/compra-em-processo';
import { CarrinhoFacade } from '@vip/state/carrinho';
import { MessageService } from '@vip/ui/message';
import { CodeScannerService } from '@vip/native/code-scanner';
import {
  FormaEntregaEnum,
  IHorariosFormatados,
  ITipoEntrega,
  ITipoEntregaCompra,
} from '@vip/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { map, take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'vip-validar-prazo-entrega-container',
  templateUrl: './validar-prazo-entrega-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidarPrazoEntregaContainerComponent
  extends EntregaRetiradaBaseDirective
  implements OnInit, AfterViewInit, OnDestroy
{
  loadingEnderecos$ = this.enderecoFacade.loading$;
  loadingTipoEntrega$ = this.tipoEntregaFacade.loading$;
  horarioSelecionado$ = this.tipoEntregaFacade.horarioSelecionado$;
  isTelevendas$ = this.televendasFacade.isTelevendas;
  tiposEntregas$ = this.isTelevendas$
    ? this.tipoEntregaFacade.tiposEntregasTelevendas$
    : this.tipoEntregaFacade.tiposEntregas$;

  taxaEntrega$ = this.tipoEntregaFacade.tipoEntregaSelecionado$.pipe(
    map((tipoEntregaSelecionado) => {
      if (!tipoEntregaSelecionado) return undefined;
      return tipoEntregaSelecionado.frete - tipoEntregaSelecionado.desconto;
    })
  );
  taxaServico$ = this.compraEmProcessoFacade.taxaServico$;
  tipoEntregaSelecionado$ = this.tipoEntregaFacade.tipoEntregaSelecionado$;
  compraEmProcesso$ = this.compraEmProcessoFacade.compraEmProcesso$;

  migrarCdIsRunning = false;
  exibirTiposEntrega$?: Observable<boolean>;
  exibirTiposRetirada$?: Observable<boolean>;

  disablePagamentoButton = false;
  formaEntregaEnum = FormaEntregaEnum;
  hasValidaPrazo = true;
  horarioSelecionado?: IHorariosFormatados;
  loadingTipoEntrega: boolean | null = false;
  hasTipoRetiradaPrazoEntrega: boolean | null = false;
  loadingTipoRetirada: boolean | null = false;
  tipoEntrega: boolean | undefined = false;
  entregaRetirada: boolean | undefined = false;
  frete!: number | undefined;
  tipoEntregaSelected: ITipoEntrega | null = null;
  private destroy$ = new Subject<void>();

  @Output() confirm = new EventEmitter();

  constructor(
    enderecoFacade: EnderecoFacade,
    cdFacade: CentroDistribuicaoFacade,
    cepFacade: CepFacade,
    clienteFacade: ClienteFacade,
    tipoEntregaFacade: TipoEntregaFacade,
    changeDetector: ChangeDetectorRef,
    dialogService: DialogService,
    router: Router,
    navController: NavController,
    activatedRoute: ActivatedRoute,
    validarCepContainerService: ValidarCepContainerService,
    filialFacade: FilialFacade,
    compraEmProcessoFacade: CompraEmProcessoFacade,
    carrinhoFacade: CarrinhoFacade,
    messageService: MessageService,
    codeScannerService: CodeScannerService,
    modalService: ModalService<unknown>,
    televendasFacade: TelevendasFacade,
    private tipoEntregaUtilsService: TipoEntregaUtilsService
  ) {
    super(
      enderecoFacade,
      cdFacade,
      cepFacade,
      clienteFacade,
      tipoEntregaFacade,
      filialFacade,
      compraEmProcessoFacade,
      carrinhoFacade,
      changeDetector,
      dialogService,
      router,
      navController,
      activatedRoute,
      validarCepContainerService,
      false,
      modalService,
      televendasFacade,
      messageService,
      codeScannerService
    );
  }

  ngOnInit(): void {
    this.setValuesExibirTipoEntregasListeners();
    this.changeDetector.detectChanges();
    this.tipoEntregaUtilsService.isLoadingTiposEntregasEnderecoRequest$
      .pipe(
        untilDestroyed(this),
        takeUntil(this.destroy$),
        tap((isLoading) => {
          if (!isLoading) {
            this.destroy$.next();
            this.destroy$.complete();
            this.loadingTipoEntrega = false;
          }
        })
      )
      .subscribe((isLoading) => {
        this.loadingTipoEntrega = isLoading;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.compraEmProcesso$
      .pipe(untilDestroyed(this))
      .subscribe((compraEmProcesso) => {
        this.entregaRetirada = compraEmProcesso?.tipo_entrega.retirada;
      });

    this.tipoEntregaSelecionado$
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        if (response) this.tipoEntregaSelected = response;
      });
    this.changeDetector.detectChanges();
  }

  private setValuesExibirTipoEntregasListeners(): void {
    this.exibirTiposEntrega$ = combineLatest([
      this.enderecoFacade.enderecoEntrega$,
      this.isEntrega$,
    ]).pipe(
      untilDestroyed(this),
      map(([enderecoEntrega, isEntrega]) => {
        return isEntrega && !!enderecoEntrega;
      })
    );
    this.exibirTiposRetirada$ = combineLatest([
      this.cdFacade.enderecoRetiradaCdSelecionado$,
      this.isEntrega$,
    ]).pipe(
      untilDestroyed(this),
      map(([enderecoRetirada, isEntrega]) => {
        return !isEntrega && !!enderecoRetirada;
      })
    );
  }

  getTiposEntregasByCdChange(): void {
    if (this.migrarCdIsRunning) return;
    this.migrarCdIsRunning = true;
    combineLatest([this.cdFacade.migrarCDResponseSuccess$])
      .pipe(untilDestroyed(this), take(1))
      .subscribe(() => {
        this.migrarCdIsRunning = false;
        this.tipoEntregaFacade.getTiposEntregasEndereco();
      });
  }

  changeSelectTipoEntrega(event: ITipoEntregaCompra) {
    if (event && typeof event.id === 'number') {
      this.tipoEntregaSelected = event as ITipoEntrega;

      this.frete = event.frete;
      if (event) {
        this.hasTipoRetiradaPrazoEntrega = true;
      }
    }
  }

  changeHorarioTipoEntrega(event: IHorariosFormatados) {
    this.handleHorarioChange(event);
  }

  handleHorarioChange(horarioSelecionado: IHorariosFormatados) {
    this.horarioSelecionado = horarioSelecionado;
    this.disablePagamentoButton = false;
  }

  confirmarPrazoEntrega() {
    this.tipoEntregaFacade.selectHorarioTipoEntrega(
      this.horarioSelecionado as IHorariosFormatados
    );
    if (this.tipoEntregaSelected) {
      this.tipoEntregaFacade.selectTipoEntrega(this.tipoEntregaSelected.id);
      this.compraEmProcessoFacade.setTipoEntrega(
        this.tipoEntregaSelected,
        this.horarioSelecionado
      );
    }
    this.compraEmProcessoFacade.setFrete(this.frete);
    this.confirm.emit();
  }
}
