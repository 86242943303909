<ng-container *ngIf="!isSite || isResponsivo; else siteTemplate">
  <vip-lista-produtos
    class="vip-page-content vip-ofertas"
    title="Ofertas"
    [filters]="(filtros$ | async) || []"
    [products]="(produtos$ | async) || []"
    [aviseMeEnviados]="produtosIndisponiveisService.aviseMeEnviados$ | async"
    [produtosNoCarrinho]="produtosNoCarrinho$ | async"
    [loaded]="(loaded$ | async) ?? false"
    [carousel]="bannersOfertas$ | async"
    [scrollSelector]="
      isResponsivo ? '' : '.scroll-container > .scroll-container'
    "
    [distanceScroll]="600"
    (externalFilterClick)="externalFilterClick($event)"
    (filterClick)="onFilterClick()"
    (produtoChanged)="atualizarCarrinhoService.setItemCarrinho($event)"
    (aviseMeClick)="
      produtosIndisponiveisService.sendAviseMeProdutoIndisponivel($event)
    "
    (loadMore)="loadMore()"
    (visible)="campanhaPatrocinadoService.visualizacao($event)"
    (addListClick)="addToListContainerService.adicionarNaLista($event)"
    [adsValue]="adsParamValue"
    [responsive]="isResponsivo"
    [paginator]="(paginator$ | async) || undefined"
    (pageClick)="goToPage($event)"
  ></vip-lista-produtos>
</ng-container>

<ng-template #siteTemplate>
  <vip-screen-title
    title="Ofertas"
    [isLoading]="!((loaded$ | async) ?? false)"
    leftIcon="icon-arrow_back"
    leftIconColor="secondary"
    [showSearchButton]="true"
  ></vip-screen-title>

  <vip-lista-produtos-desktop
    [products]="(produtos$ | async) || []"
    [aviseMeEnviados]="produtosIndisponiveisService.aviseMeEnviados$ | async"
    [produtosNoCarrinho]="produtosNoCarrinho$ | async"
    [loading]="(loading$ | async) || false"
    [isLogged]="(isLogged$ | async) ?? false"
    (produtoChanged)="atualizarCarrinhoService.setItemCarrinho($event)"
    (aviseMeClick)="
      produtosIndisponiveisService.sendAviseMeProdutoIndisponivel($event)
    "
    (addListClick)="addToListContainerService.adicionarNaLista($event)"
    (filterClick)="onFilterClick()"
    (visible)="campanhaPatrocinadoService.visualizacao($event)"
    [paginator]="(paginator$ | async) || undefined"
    (pageClick)="goToPage($event)"
    [filters]="(filtros$ | async) || []"
    [slides]="(bannersOfertas$ | async)?.slides || []"
    (filtersChange)="onChangeFiltro($event)"
    (externalFilterClick)="handleOrderFilterClick($event)"
    [breadcrumbs]="(breadcrumbs$ | async) || []"
    (breadCrumbClick)="onBreadCrumbClick($event)"
    [orderingOptions]="orderingOptions"
    [adsValue]="adsParamValue"
    [filterType]="filterType"
  ></vip-lista-produtos-desktop>
</ng-template>
