import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import * as FormaPagamentosActions from './forma-pagamento.actions';
import { FormaPagamentosApiService } from '@vip/api';
import { BucketsFacade, BucketsService } from '@vip/state/buckets';
import { TipoEntregaFacade } from '@vip/state/tipo-entrega';
import {
  ICompra,
  IPagamento,
  IPagamentosViewModel,
  TituloPagamentoEnum,
} from '@vip/core';
import { LoadingFacade } from '@vip/state/loading';
import { CompraEmProcessoProviderFacade } from '@vip/state/compra-em-processo-provider';
import { CartoesWsService } from '@vip/ws';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { mergeTakeOne } from '@vip/state/utils';
import { HttpErrorResponse } from '@angular/common/http';

const MSG_BANDEIRA_NAO_ACEITA =
  'Bandeira não aceita para esta loja. Favor informar outro cartão!';

@Injectable()
export class FormaPagamentosEffects {
  getFormaPagamentos$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(FormaPagamentosActions.getFormaPagamentos)),
      this.compraEmProcessoProviderFacade.compraEmProcesso$.pipe(
        filter(
          (compraEmProcesso): compraEmProcesso is ICompra =>
            compraEmProcesso !== null
        ),
        take(1)
      ),
      this.tipoEntregaFacade.tipoEntregaIdSelecionado$.pipe(
        filter(
          (tipoEntregaId): tipoEntregaId is number => tipoEntregaId !== null
        )
      ),
      this.bucketFacade.bucketS3$,
    ]).pipe(
      map(([action, compraEmProcesso, tipoEntregaSelecionadoId, bucket]) => {
        return {
          ...action,
          compraEmProcesso,
          tipoEntregaSelecionadoId,
          bucket,
        };
      }),
      fetch({
        run: (action) => {
          this.loadingFacade.setLoading('Carregando formas de pagamento');

          return this.FormaPagamentosApiService.getFormaPagamentos(
            action.compraEmProcesso.filial_id,
            action.compraEmProcesso.centro_distribuicao_id,
            action.tipoEntregaSelecionadoId
          ).pipe(
            map((res) =>
              FormaPagamentosActions.getFormaPagamentosSuccess({
                formaPagamentos: res.data.formas_pagamento.map(
                  (formaPagamento) => {
                    return this.bucketsService.addBucketFormaPagamento(
                      formaPagamento,
                      action.bucket
                    );
                  }
                ),
                removerPrepagos: res.data.remover_prepagos,
                pagamentosOnline: this.generatePagamentoWithBucket(
                  res.data.pagamentos_online,
                  action.bucket
                ),
                pagamentoEntrega: this.generatePagamentoWithBucket(
                  res.data.pagamentos_na_entrega,
                  action.bucket
                ),
                pagamentos: this.generatePagamentosViewModel({
                  pagamentosOnline: res.data.pagamentos_online,
                  pagamentosEntrega: res.data.pagamentos_na_entrega,
                  bucket: action.bucket,
                }),
              })
            )
          );
        },
        onError: (action, error) =>
          FormaPagamentosActions.getFormaPagamentosFailure({ error }),
      })
    )
  );

  removeLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          FormaPagamentosActions.getFormaPagamentosSuccess,
          FormaPagamentosActions.getFormaPagamentosFailure
        ),
        tap(() => this.loadingFacade.disableLoading())
      ),
    { dispatch: false }
  );

  buscarBandeiraCartao$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormaPagamentosActions.buscarBandeiraCartao),
      distinctUntilChanged(
        (prev, curr) =>
          prev.cardNumber === curr.cardNumber && !curr.makeRequestAgain
      ),
      mergeTakeOne(this.cdFacade.filialECdSelecionado$),
      map(([action, [filial, cdSelecionado]]) => ({
        ...action,
        filial,
        cdSelecionado,
      })),
      switchMap(({ cdSelecionado, cardNumber, tipoCartao }) => {
        return this.cartoesWsService
          .buscarBandeiraCartao(cdSelecionado.id, cardNumber, tipoCartao)
          .pipe(
            map(({ data, success, message }) => {
              if (!success && message == MSG_BANDEIRA_NAO_ACEITA) {
                return FormaPagamentosActions.buscarBandeiraCartaoFailure({
                  error: new HttpErrorResponse({
                    error: { error: { message: MSG_BANDEIRA_NAO_ACEITA } },
                  }),
                  bandeiraNaoAtendida: true,
                });
              }
              return FormaPagamentosActions.buscarBandeiraCartaoSuccess({
                formaPagamento: data.formaPagamento,
                permiteSelecaoManual: data.permite_selecao_manual,
              });
            }),
            takeUntil(
              this.actions$.pipe(
                ofType(FormaPagamentosActions.cancelarRequestBuscarBandeira)
              )
            )
          );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private bucketFacade: BucketsFacade,
    private bucketsService: BucketsService,
    private compraEmProcessoProviderFacade: CompraEmProcessoProviderFacade,
    private tipoEntregaFacade: TipoEntregaFacade,
    private FormaPagamentosApiService: FormaPagamentosApiService,
    private loadingFacade: LoadingFacade,
    private cdFacade: CentroDistribuicaoFacade,
    private cartoesWsService: CartoesWsService
  ) {}

  private generatePagamentoWithBucket(
    pagamento: IPagamento,
    bucket?: string
  ): IPagamento {
    return {
      ativo: pagamento.ativo,
      tipos: pagamento.tipos.map((tipoPagamento) => ({
        ...tipoPagamento,
        disponiveis: tipoPagamento.disponiveis.map((formaPagamento) =>
          this.bucketsService.addBucketFormaPagamento(formaPagamento, bucket)
        ),
      })),
    };
  }

  private generatePagamentosViewModel({
    pagamentosOnline,
    pagamentosEntrega,
    bucket,
  }: {
    pagamentosOnline: IPagamento;
    pagamentosEntrega: IPagamento;
    bucket?: string;
  }): IPagamentosViewModel {
    const result = <IPagamentosViewModel>{
      online: {},
      entrega: {},
    };
    const enumValues = Object.values(TituloPagamentoEnum);

    pagamentosOnline.ativo
      ? pagamentosOnline.tipos.forEach(({ titulo, disponiveis }) => {
          const pagamentosComBucket = disponiveis.map((formaPagamento) =>
            this.bucketsService.addBucketFormaPagamento(formaPagamento, bucket)
          );
          result.online[titulo] = pagamentosComBucket;
          result.online['todos_pagamentos'] = result.online['todos_pagamentos']
            ? [...result.online['todos_pagamentos'], ...pagamentosComBucket]
            : [...pagamentosComBucket];
        })
      : enumValues.forEach((titulo) => {
          result.online[titulo] = [];
        });

    pagamentosEntrega.ativo
      ? pagamentosEntrega.tipos.forEach(({ titulo, disponiveis }) => {
          const pagamentosComBucket = disponiveis.map((formaPagamento) =>
            this.bucketsService.addBucketFormaPagamento(formaPagamento, bucket)
          );
          result.entrega[titulo] = pagamentosComBucket;
          result.entrega['todos_pagamentos'] = result.entrega[
            'todos_pagamentos'
          ]
            ? [...result.entrega['todos_pagamentos'], ...pagamentosComBucket]
            : [...pagamentosComBucket];
        })
      : enumValues.forEach((titulo) => {
          result.entrega[titulo] = [];
        });
    return result;
  }
}
