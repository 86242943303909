<vip-pagamento-cartao
  class="vip-page-content"
  [ngClass]="{ 'pt-1': isDesktopResponsive }"
  [isDesktopResponsive]="isDesktopResponsive"
  [compraEmProcesso]="compraEmProcesso$ | async"
  [formaPagamentoSelecionada]="(formaPagamentoSelecionada$ | async) || null"
  [formaPagamentosOptions]="formaPagamentosOptions"
  [formasPagamentosPrePagas]="(formasPagamentosPrePagas$ | async) ?? []"
  [permitirSalvarCartao]="(permitirSalvarCartao$ | async) ?? false"
  [permitirBandeiraAutomatica]="(permitirBandeiraAutomatica$ | async) ?? false"
  [privateLabelBandeiraAutomatica]="privateLabelBandeiraAutomatica"
  [permiteSelecaoManual]="(permiteSelecaoManual$ | async) ?? false"
  [bandeiraNaoAtendida]="(bandeiraNaoAtendida$ | async) ?? false"
  [exibeModalBandeiraNaoAtendida$]="
    formaPagamentosFacade.bandeiraNaoAtendidaDispatched$
  "
  (confirmarClick)="confirmar($event)"
  (alterarBandeiraCartao)="formaPagamentosFacade.selectFormaPagamento($event)"
  (buscarBandeiraCartao)="
    formaPagamentosFacade.buscarBandeiraCartao(
      $event.cardNumber,
      tipoCartaoBandeiraAutomatica,
      $event.makeRequestAgain
    )
  "
  (backClick)="
    formaPagamentosFacade.resetBandeiraNaoAtendidaCartao();
    formaPagamentosFacade.resetPermiteSelecaoManual()
  "
></vip-pagamento-cartao>
