import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardCuponsCompraComponent } from './card-cupons-compra/card-cupons-compra.component';
import { CardBasicoModule } from '@vip/ui/card-basico';
import { ButtonModule } from '@vip/ui/button';

@NgModule({
  imports: [CommonModule, CardBasicoModule, ButtonModule],
  declarations: [CardCuponsCompraComponent],
  exports: [CardCuponsCompraComponent],
})
export class CardCuponsCompraModule {}
