import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  PrecoValorTamanhoEnum,
  TamanhoProdutoPrecoEnum,
} from '../produto-preco-tamanho.enum';

@Component({
  selector: 'vip-produto-preco-valor',
  templateUrl: './produto-preco-valor.component.html',
  styleUrls: ['./produto-preco-valor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProdutoPrecoValorComponent {
  @Input() preco?: number;
  @Input() siglaUnidade? = 'UN';
  @Input() quantidadeUnidade? = 1;
  @Input() tamanho = TamanhoProdutoPrecoEnum.MEDIO;
  @Input() isNegrito = true;
  @Input() marginClassUnidade = '';

  get tamanhoUnidade(): PrecoValorTamanhoEnum {
    switch (this.tamanho) {
      case TamanhoProdutoPrecoEnum.PEQUENO:
      case TamanhoProdutoPrecoEnum.MEDIO:
        return PrecoValorTamanhoEnum.SM;
      case TamanhoProdutoPrecoEnum.GRANDE:
        return PrecoValorTamanhoEnum.BASE;
      default:
        return PrecoValorTamanhoEnum.BASE;
    }
  }
}
