<div
  #contentHeader
  (mouseup)="scrollableService.disableIsDown()"
  (mouseleave)="scrollableService.disableIsDown()"
  (mousedown)="scrollableService.onMouseDown($event)"
  (mousemove)="scrollableService.onMouseMove($event)"
  class="tab-group-header"
  [ngClass]="{
    'space-items': rounded,
    scrollable: scrollable,
    'px-3 mb-2': !scrollable,
    desktop: isDesktop
  }"
>
  <vip-tab-item
    *ngFor="let tab of _allTabs.toArray(); let i = index"
    [color]="color"
    [icon]="tab.icon"
    [label]="tab.label"
    [disabled]="tab.disabled"
    [rounded]="rounded"
    [selected]="i === selectedIndex && !tab.disabled"
    [count]="tab.count"
    (tabClick)="_handleClick(i)"
  >
  </vip-tab-item>
</div>
<div [ngClass]="{ 'tab-group-height ': !isDesktop }" class="flex">
  <div
    #contentWrap
    class="tab-group-content-wrap flex-grow"
    [ngClass]="{ 'not-rounded': !rounded }"
  >
    <ng-content></ng-content>
  </div>
</div>
