<div
  class="vip-simular-frete-container flex flex-col"
  [ngClass]="{ '!relative mobile-responsive': isDesktopDevice || !isDesktop }"
  *ngIf="pagina"
>
  <vip-screen-title
    *ngIf="!isDesktop"
    [title]="pagina.titulo"
    leftIcon="icon-arrow_back"
    leftIconColor="secondary"
  ></vip-screen-title>

  <vip-carousel
    *ngIf="carousel && carousel.slides.length > 0"
    class="mb-3 -mx-3"
    [wide]="!isMobile"
    [type]="isMobile ? 'sm' : 'md'"
    [slides]="carousel.slides"
    [bannersPerPage]="carousel.configuracoes.itens_por_pagina"
  ></vip-carousel>

  <div
    *ngIf="
      pagina.conteudo && !pagina.conteudo.includes('/taxas/consultar_frete')
    "
    class="vip-paginas-conteudo flex flex-col m-3 break-word"
    [attr.data-cy]="'pagina_' + pagina.slug"
    [innerHTML]="sanitizer.bypassSecurityTrustHtml(pagina.conteudo)"
  ></div>

  <div class="m-3 flex flex-col flex-grow lg:m-0">
    <form [formGroup]="form">
      <p class="vip-color-first-default lg:text-base">
        Informe seu CEP para saber o valor da taxa de serviço:
      </p>

      <p class="pt-4 text-lg font-medium" *ngIf="isMobile">
        <i class="text-3xl leading-none icon-location_on"></i>
        Informe seu CEP
      </p>

      <div class="flex flex-row py-3">
        <vip-field
          [error]="form.controls.cep.touched && form.controls.cep.invalid"
          message="CEP inválido"
          [validations]="form.controls.cep.errors"
          label="CEP*"
          class="w-full lg:w-fit text-left"
        >
          <input
            id="cep"
            type="tel"
            formControlName="cep"
            [mask]="masks.cep"
            autocomplete="off"
            data-cy="solicitar-cep-input"
          />
        </vip-field>
        <vip-button
          class="ml-2 simular-frete-btn"
          data-cy="simular-frete-enviar"
          (btnClick)="onConfirmSolicitarCep()"
          [disabled]="loading"
        >
          <span>Calcular</span>
        </vip-button>
      </div>

      <a
        class="vip-color-primary-main text-sm font-medium cursor-pointer"
        href="https://buscacepinter.correios.com.br/app/endereco/index.php"
        target="_blank"
      >
        Não sei meu CEP
      </a>
    </form>

    <div *ngIf="tiposEntregas.length">
      <hr class="my-4 vip-divider" />
      <h3>Lojas que atendem na sua região:</h3>

      <form
        [formGroup]="formFilter"
        class="flex flex-row pt-2"
        *ngIf="formFilter && hasFilters"
      >
        <vip-checkbox-pill
          class="pr-2"
          label="Entrega"
          id="entrega"
          data-cy="simular-frete-filtrar-entrega"
          formControlName="entrega"
          (click)="handleBtnFiltrarTiposEntrega()"
          [rounded]="isDesktop"
        ></vip-checkbox-pill>
        <vip-checkbox-pill
          label="Retirada"
          id="retirada"
          data-cy="simular-frete-filtrar-retirada"
          formControlName="retirada"
          (click)="handleBtnFiltrarTiposEntrega()"
          [rounded]="isDesktop"
        ></vip-checkbox-pill>
      </form>

      <div class="grid grid-flow-row lg:grid-cols-3 lg:gap-2 mt-3">
        <vip-selectable-card
          class="mb-2 mt-3 block lg:m-0"
          *ngFor="let tipoEntrega of tiposEntregas"
          (changed)="cdChange.emit(tipoEntrega)"
          [disableAutoToggle]="true"
          data-cy="card-tipo-entrega"
        >
          <div title class="font-medium text-sm">
            {{ tipoEntrega.descricao }}
          </div>
          <div body class="text-xs vip-color-secondary-default">
            <span>
              {{
                !tipoEntrega.quantidade_maxima_itens
                  ? 'Itens ilimitados'
                  : (tipoEntrega.quantidade_maxima_itens
                    | i18nPlural: quantidadeItensMaps)
              }}
              |
            </span>
            <span *ngIf="tipoEntrega.retirada_loja; else entrega">
              Apenas Retirada
            </span>
            <ng-template #entrega>
              {{
                tipoEntrega.frete
                  | valorFrete: tipoEntrega.desconto:'Entrega Grátis'
              }}
            </ng-template>
          </div>
        </vip-selectable-card>
      </div>
    </div>
  </div>

  <vip-button
    class="p-3 lg:self-start lg:min-w-[8.5625rem] lg:mt-4 lg:p-0"
    *ngIf="tiposEntregas.length"
    type="outline"
    [size]="isDesktop ? 'small' : 'medium'"
    data-cy="simular-frete-limpar"
    (btnClick)="handleBtnLimpar()"
    [disabled]="loading"
  >
    <span>Limpar Busca</span>
  </vip-button>
</div>
