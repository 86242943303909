import { CurrencyPipe } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { ICompraPagamento } from '../../interfaces';

@Pipe({ name: 'pagamentoTextoPipe' })
export class PagamentoTextoPipe implements PipeTransform {
  constructor(@Inject(CurrencyPipe) private currencyPipe: CurrencyPipe) {}

  transform(pagamento: ICompraPagamento | string): string {
    let label = '';

    if (typeof pagamento === 'string') {
      label += `**** ${pagamento.slice(-4)}`;
    } else if (pagamento.cc_number) {
      label += `**** ${pagamento.cc_number.slice(-4)}`;
    } else {
      label += this.currencyPipe.transform(pagamento.valor, 'BRL');
    }
    return label;
  }
}
