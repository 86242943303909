import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  EventEmitter,
  Optional,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ValidarCepContainerService } from '@vip/container/validar-cep';
import {
  FormaEntregaEnum,
  ICentroDistribuicao,
  IEndereco,
  IEnderecoDoCep,
} from '@vip/core';
import { CodeScannerService } from '@vip/native/code-scanner';
import { CarrinhoFacade } from '@vip/state/carrinho';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { CepFacade, CepStateData } from '@vip/state/cep';
import { ClienteFacade } from '@vip/state/cliente';
import { CompraEmProcessoFacade } from '@vip/state/compra-em-processo';
import { EnderecoFacade } from '@vip/state/endereco';
import { FilialFacade } from '@vip/state/filial';
import { TipoEntregaFacade } from '@vip/state/tipo-entrega';
import { mergeTakeOne } from '@vip/state/utils';
import { MessageService } from '@vip/ui/message';
import { DialogService, IDialog, ModalService } from '@vip/ui/modal';
import { EnderecoEntregaRetiradaContainerComponent } from './endereco-entrega-retirada-container/endereco-entrega-retirada-container.component';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  skip,
  take,
  tap,
} from 'rxjs/operators';
import { TelevendasDirective } from '@vip/container/televendas';
import { TelevendasFacade } from '@vip/state/televendas';

@UntilDestroy()
@Directive()
export abstract class EntregaRetiradaBaseDirective extends TelevendasDirective {
  enderecos$ = this.enderecoFacade.enderecosEntregaArray$;
  enderecoSelecionado$ = this.enderecoFacade.enderecoSelecionado$;
  cdsRetirada$ = this.cdFacade.todosCds$.pipe(
    distinctUntilChanged(
      (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
    )
  );
  cdsEntrega$?: Observable<ICentroDistribuicao[]>;
  isLogged$ = this.clienteFacade.isLogged$;
  enderecoEntrega$: Observable<IEndereco | null | undefined> = combineLatest([
    this.cdFacade.enderecoRetiradaCdSelecionado$,
    this.enderecoFacade.enderecoEntrega$,
    this.tipoEntregaFacade.isEntrega$,
  ]).pipe(
    map(([enderecoRetirada, enderecoEntrega, isEntrega]) =>
      isEntrega ? enderecoEntrega : enderecoRetirada ?? undefined
    )
  );
  isEntrega$ = this.tipoEntregaFacade.isEntrega$;
  realizaEntrega$!: Observable<boolean>;
  cdSelecionado$: Observable<ICentroDistribuicao>;
  cdSelecionado: ICentroDistribuicao | null = null;
  novoEndereco?: IEndereco | null;
  exibirMsgCepNaoAtendido = false;
  filialId = 0;
  cdId = 0;
  novoCd?: ICentroDistribuicao | null;
  novaFormaEntrega?: number | null;
  antigaFormaEntrega?: number | null;
  formaEntregaAlterada = false;
  formaEntrega!: number;
  redirectTo?: string;
  isLojaAutonoma = false;
  selecaoCdObrigatoria = false;
  exibiuConfirmacaoAlteracaoEndereco = false;
  modalEntregaRetiradaCloseSubject = new Subject<void>();
  modalEntregaRetiradaClose$ =
    this.modalEntregaRetiradaCloseSubject.asObservable();
  openInformarCep = false;
  modalInformarNovoEndereco?: ComponentRef<unknown>;
  closeDropdownEntregaRetirada = new EventEmitter<void>();
  formaEntregaChangeFromModalEntregaRetirada = new EventEmitter<number>();
  cdsEntregaInitiateValue = new EventEmitter<void>();
  confirmNovoEndereco = new EventEmitter<void>();
  buscarTipoEntregas = new EventEmitter<void>();
  buscarTipoEntregasAlterarCd = new EventEmitter<void>();
  enderecoAtendidoEmitter = new EventEmitter<void>();
  retiradaInitModalEmitter = new EventEmitter();
  tipoEntregaSelecionadoPrazo?: IEndereco | null;
  cancelDialogConfirmarAlterarCDEmitter = new EventEmitter<void>();

  constructor(
    protected enderecoFacade: EnderecoFacade,
    protected cdFacade: CentroDistribuicaoFacade,
    protected cepFacade: CepFacade,
    protected clienteFacade: ClienteFacade,
    protected tipoEntregaFacade: TipoEntregaFacade,
    protected filialFacade: FilialFacade,
    protected compraEmProcessoFacade: CompraEmProcessoFacade,
    protected carrinhoFacade: CarrinhoFacade,
    protected changeDetector: ChangeDetectorRef,
    protected dialogService: DialogService,
    protected router: Router,
    protected navController: NavController,
    protected activatedRoute: ActivatedRoute,
    protected validarCepContainerService: ValidarCepContainerService,
    protected isDesktop: boolean,
    protected modalService: ModalService<unknown>,
    protected televendasFacade: TelevendasFacade,
    @Optional() protected messageService?: MessageService,
    @Optional() private codeScannerService?: CodeScannerService,
    @Optional() private isResponsive?: boolean
  ) {
    super(televendasFacade, filialFacade, dialogService);

    this.cdSelecionado$ = this.cdFacade.cd$.pipe(
      tap((cd) => {
        this.cdId = cd.id;
      })
    );

    this.cdSelecionado$.pipe(untilDestroyed(this)).subscribe((cd) => {
      this.cdSelecionado = cd;
    });

    this.isEntrega$
      .pipe(
        untilDestroyed(this),
        mergeTakeOne(this.cdFacade.isLojaAutonoma$, this.isLogged$)
      )
      .subscribe(([isEntrega, isLojaAutonoma, isLogged]) => {
        if (isLojaAutonoma && !this.isDesktop) {
          this.formaEntrega = FormaEntregaEnum.TIPO_AUTONOMA;
        } else if (!this.formaEntrega && this.isDesktop && !isLogged) {
          this.tipoEntregaFacade.setFormaEntrega(false);
          this.formaEntrega = FormaEntregaEnum.TIPO_RETIRADA;
        } else {
          this.formaEntrega = isEntrega
            ? FormaEntregaEnum.TIPO_ENTREGA
            : FormaEntregaEnum.TIPO_RETIRADA;
        }
      });

    if (!isDesktop) {
      this.cdFacade.isLojaAutonoma$
        .pipe(untilDestroyed(this))
        .subscribe(
          (isLojaAutonoma) => (this.isLojaAutonoma = isLojaAutonoma || false)
        );
    } else {
      this.isLojaAutonoma = false;
    }

    this.redirectTo = this.activatedRoute.snapshot.queryParams.redirectTo;
  }

  getCdSelecionado() {
    if (this.novoEndereco) {
      return this.novoCd || null;
    }

    if (!this.novaFormaEntrega || this.novaFormaEntrega == this.formaEntrega) {
      return this.novoCd || this.cdSelecionado;
    }
    return this.novoCd || null;
  }

  alterarEnderecoEntrega(endereco: IEndereco) {
    this.tipoEntregaSelecionadoPrazo = endereco;
    this.novoEndereco = endereco;
    this.novaFormaEntrega = FormaEntregaEnum.TIPO_ENTREGA;
    if (this.isDesktop && this.router.url.includes('/entrega-retirada'))
      this.tipoEntregaFacade.resetTiposEntrega();

    if (endereco && endereco.cep) {
      this.cepFacade.validarCepAtendidoPelaFilial(endereco.cep);
    }

    this.cepFacade.getData$
      .pipe(
        untilDestroyed(this),
        filter((data) => data.apenasRetirada !== null),
        take(1)
      )
      .subscribe((data) => {
        const { cepAtendido, apenasRetirada } = data;
        if (cepAtendido && apenasRetirada) {
          this.exibirDialogCdSemEntrega();
          this.cdsEntrega$ = of([]);
          this.cdsEntregaInitiateValue.emit();
          return;
        }
        if (!cepAtendido) {
          this.exibirMsgCepNaoAtendido = true;
          this.cdsEntrega$ = of([]);
        } else {
          this.cdsEntrega$ = this.cdFacade.getCentroDistribuicaoEntrega(
            this.filialId,
            endereco.cep
          );
          this.enderecoAtendidoEmitter.emit();
          this.validarCdsEntrega();
        }
        this.cdsEntregaInitiateValue.emit();
        this.changeDetector.detectChanges();
      });
  }

  onCdChange({ cd }: { cd: ICentroDistribuicao }) {
    this.novoCd = cd;
    if (this.isDesktop) this.validarFormaSelecionada(true);
  }

  onFormaEntregaChange(formaEntrega?: number) {
    this.formaEntregaAlterada = true;
    this.antigaFormaEntrega = this.novaFormaEntrega || this.formaEntrega;
    this.novaFormaEntrega = formaEntrega;
    if (this.isDesktop && this.formaEntrega === this.novaFormaEntrega) return;

    if (this.novaFormaEntrega == FormaEntregaEnum.TIPO_ENTREGA) {
      this.enderecoEntrega$
        .pipe(untilDestroyed(this), take(1), mergeTakeOne(this.isEntrega$))
        .subscribe(([enderecoEntrega, isEntrega]) => {
          if (enderecoEntrega && isEntrega) {
            this.carregarCdsEntrega(enderecoEntrega.cep);
            this.validarCdsEntrega();
          } else {
            this.cdsEntrega$ = of([]);
            this.cdsEntregaInitiateValue.emit();
          }
        });
    }
    this.novoCd = null;
    this.novoEndereco = null;
  }

  validarReceberEmCasa(
    possuiItensNocarrinho: boolean,
    possuiCompraEmProcesso: boolean,
    cdsEntrega?: ICentroDistribuicao[]
  ) {
    if (cdsEntrega) {
      this.cdsEntrega$ = of(cdsEntrega);
      this.cdsEntregaInitiateValue.emit();
    }
    if (!this.cdsEntrega$) {
      return this.exibirDialogSelecaoEndereco();
    }
    return this.cdsEntrega$
      .pipe(
        untilDestroyed(this),
        take(1),
        mergeTakeOne(
          this.isLogged$,
          this.enderecoFacade.enderecoEntrega$,
          this.cepFacade.cepAtendido$,
          this.cepFacade.apenasRetirada$
        )
      )
      .subscribe(
        ([
          cdsEntrega,
          isLogged,
          enderecoEntrega,
          cepAtendido,
          apenasRetirada,
        ]) => {
          const trocouParaEntrega =
            this.novaFormaEntrega === FormaEntregaEnum.TIPO_ENTREGA &&
            this.formaEntrega !== FormaEntregaEnum.TIPO_ENTREGA;
          const trocouDeEndereco =
            this.novoEndereco && this.novoEndereco.id !== enderecoEntrega?.id;
          const possuiEnderecoEntregaSelecionadoValido = trocouParaEntrega
            ? !!this.novoEndereco
            : !!this.novoEndereco ||
              (!!enderecoEntrega && cepAtendido && !apenasRetirada);
          const trocouParaEnderecoMultiplo =
            this.novoEndereco && cdsEntrega.length > 1;
          const trocouParaEnderecoMultiploENaoSelecionouCd =
            trocouParaEnderecoMultiplo && !this.novoCd;
          const trocouDeCd = this.novoCd && this.novoCd.id !== this.cdId;

          if (!possuiEnderecoEntregaSelecionadoValido) {
            return this.exibirDialogSelecaoEndereco();
          }
          if (trocouParaEnderecoMultiploENaoSelecionouCd) {
            return this.exibirDialogSelecaoLojaEntrega();
          }
          if (
            trocouDeEndereco &&
            isLogged &&
            !trocouDeCd &&
            !trocouParaEntrega
          ) {
            this.exibiuConfirmacaoAlteracaoEndereco = true;
            return this.exibirConfirmacaoAlteracaoEndereco();
          }

          if (this.novoCd) {
            this.novoEndereco = this.novoEndereco || enderecoEntrega;

            return this.alterarCd(
              this.novoCd,
              possuiItensNocarrinho,
              possuiCompraEmProcesso
            );
          }

          return this.goBack();
        }
      );
  }

  validarFormaSelecionada(
    confirmar: boolean,
    cdsEntrega?: ICentroDistribuicao[]
  ): void {
    this.tipoEntregaFacade.isEntrega$
      .pipe(
        untilDestroyed(this),
        take(1),
        mergeTakeOne(
          this.carrinhoFacade.qtdItensCarrinho$,
          this.compraEmProcessoFacade.compraEmProcesso$,
          this.cepFacade.loading$
        ),
        filter(([, , , loading]) => !loading)
      )
      .subscribe(
        ([_isEntrega, qtdItensCarrinho, compraEmProcesso, _loading]) => {
          const formaEntregaSelecionada =
            this.novaFormaEntrega || this.formaEntrega;

          if (formaEntregaSelecionada === FormaEntregaEnum.TIPO_ENTREGA) {
            this.validarReceberEmCasa(
              qtdItensCarrinho > 0,
              !!compraEmProcesso,
              cdsEntrega
            );
          } else if (
            formaEntregaSelecionada === FormaEntregaEnum.TIPO_RETIRADA
          ) {
            this.validarRetirarNaLoja(qtdItensCarrinho > 0, !!compraEmProcesso);
          } else {
            this.validarLojaAutonoma(
              qtdItensCarrinho > 0,
              !!compraEmProcesso,
              confirmar
            );
          }
        }
      );
  }

  validarRetirarNaLoja(
    possuiItensNocarrinho: boolean,
    possuiCompraEmProcesso: boolean
  ) {
    const trocouParaRetirada =
      this.novaFormaEntrega === FormaEntregaEnum.TIPO_RETIRADA &&
      this.formaEntrega !== FormaEntregaEnum.TIPO_RETIRADA;
    if (this.novoCd) {
      return this.alterarCd(
        this.novoCd,
        possuiItensNocarrinho,
        possuiCompraEmProcesso
      );
    } else if (trocouParaRetirada) {
      return this.exibirDialogSelecaoLojaRetirada();
    }
    this.tipoEntregaFacade.setFormaEntrega(false);
    return this.goBack();
  }

  validarLojaAutonoma(
    possuiItensNocarrinho: boolean,
    possuiCompraEmProcesso: boolean,
    confirmar: boolean
  ) {
    if (!this.isLojaAutonoma && confirmar) {
      return this.exibirDialogSelecaoLojaAutonoma(possuiItensNocarrinho);
    }
    return this.goBack();
  }

  exibirDialogSelecaoLojaRetirada() {
    const dialogData: IDialog = {
      open: true,
      title: 'Selecione uma loja para fazer a retirada',
      subTitle:
        'Você optou por fazer a retirada da compra, mas não selecionou a loja desejada. Por favor, selecione a loja que deseja fazer a retirada ou opte por receber em casa.',
      disabled: false,
      buttonConfirmText: 'Selecionar loja',
      buttonCancelText: 'Receber em casa',
    };
    this.dialogService.openDialog(dialogData);
    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe(async (confirm) => {
        await this.dialogService.clearDialog();
        if (!confirm) {
          this.novaFormaEntrega = FormaEntregaEnum.TIPO_ENTREGA;
          this.setCdEntregaEnderecoInicial();
          this.changeDetector.detectChanges();
        }
      });
  }

  exibirDialogSelecaoEndereco() {
    this.dialogService.openDialog({
      open: true,
      title: 'Selecione um endereço de entrega',
      subTitle:
        'Você optou por receber a compra em casa mas não selecionou nenhum endereço. Por favor, selecione um endereço para receber sua compra.',
      disabled: false,
      buttonConfirmText: 'Ok, entendi!',
    });
    this.dialogService.dialogClick.pipe(untilDestroyed(this)).subscribe(() => {
      this.dialogService.clearDialog();
    });
  }

  exibirDialogSelecaoLojaEntrega() {
    const dialogData: IDialog = {
      open: true,
      title: 'Selecione a loja para fazer a compra',
      subTitle: 'Por favor, selecione a loja que deseja fazer a sua compra.',
      disabled: false,
      buttonConfirmText: 'Selecionar loja',
    };
    this.dialogService.openDialog(dialogData);
    this.dialogService.dialogClick.pipe(untilDestroyed(this)).subscribe(() => {
      this.isEntrega$
        .pipe(untilDestroyed(this), take(1))
        .subscribe((isEntrega) => {
          if ((isEntrega || this.novoEndereco) && !this.isDesktop) {
            this.scrollToLojas();
          }
        });
      this.dialogService.clearDialog();
    });
  }

  exibirConfirmacaoAlteracaoEndereco() {
    this.dialogService.openDialog({
      open: true,
      disabled: false,
      title: 'Atenção',
      subTitle:
        'Você fez a troca do endereço que deseja receber sua compra, deseja confirmar essa alteração?',
      buttonConfirmText: 'Sim',
      buttonCancelText: 'Cancelar',
    });
    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe((confirm) => {
        if (confirm && this.novoEndereco) {
          this.confirmNovoEndereco.emit();
          this.setEnderecoEntrega(this.novoEndereco.id);
          this.goBack();
          this.buscarTipoEntregas.emit();
        }
        this.fecharDialogEResetarEnderecoSelecionado();
      });
    this.dialogService.closeClick.pipe(untilDestroyed(this)).subscribe(() => {
      this.fecharDialogEResetarEnderecoSelecionado();
    });
  }

  fecharDialogEResetarEnderecoSelecionado() {
    this.novoEndereco = null;
    this.dialogService.clearDialog();
  }

  exibirDialogCdSemEntrega(): void {
    this.dialogService.openDialog({
      open: true,
      disabled: false,
      title: 'Não realizamos entrega no endereço selecionado!',
      subTitle:
        'Por favor, adicione outro endereço válido para entrega, ou, se preferir, altere para fazer a retirada.',
      buttonCancelText: 'Fazer Retirada na Loja',
      buttonConfirmText: 'Adicionar Novo Endereço',
    });
    this.novoEndereco = null;
    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe((confirm) => {
        if (confirm) {
          this.cadastrarEndereco();
          this.modalService.clearModal();
          this.dialogService.clearDialog();
        } else {
          this.novaFormaEntrega = FormaEntregaEnum.TIPO_RETIRADA;
          this.tipoEntregaFacade.setFormaEntrega(false);
          this.cdsRetirada$.pipe(take(1)).subscribe((cds) => {
            if (cds) {
              this.cdFacade.migrarCentroDistribuicao(this.cdId, cds[0]);
            }
          });
          this.modalService.clearModal();
          this.changeDetector.detectChanges();
          this.dialogService.clearDialog();
        }
      });
    this.dialogService.closeClick.pipe(untilDestroyed(this)).subscribe(() => {
      this.modalService.clearModal();
      this.dialogService.clearDialog();
    });
  }

  exibirDialogSairLojaAutonoma(enderedoEntregaId?: number) {
    this.dialogService.openDialog({
      open: true,
      title: 'Atenção',
      subTitle: 'Deseja realmente sair da loja autônoma?',
      disabled: false,
      buttonConfirmText: 'Sim',
      buttonCancelText: 'Não',
    });
    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe((sairLojaAutonoma) => {
        if (sairLojaAutonoma) {
          this.migrarCd(enderedoEntregaId);
        }
        this.dialogService.clearDialog();
      });
  }

  exibirDialogConfirmarAlterarCD(
    cd: ICentroDistribuicao,
    enderecoEntregaId?: number
  ): void {
    this.dialogService.openDialog({
      open: true,
      disabled: false,
      title: 'Atenção',
      subTitle:
        'A confirmação desta alteração pode acarretar em alterações nos preços dos produtos e na disponibilidade dos mesmos. Deseja continuar?',
      buttonConfirmText: 'Sim',
      buttonCancelText: 'Cancelar',
    });
    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe((confirm) => {
        if (confirm) {
          this.novoCd = cd;
          this.buscarTipoEntregasAlterarCd.emit();
          this.migrarCd(enderecoEntregaId);
        } else {
          if (this.formaEntregaAlterada) {
            this.novaFormaEntrega = this.antigaFormaEntrega;
            this.novoCd = this.cdSelecionado;
          } else {
            this.novoCd = null;
          }
          this.novoEndereco = null;
          this.changeDetector.detectChanges();
          this.cancelDialogConfirmarAlterarCDEmitter.emit();
        }
        this.formaEntregaAlterada = false;
        this.dialogService.clearDialog();
      });
    this.dialogService.closeClick.pipe(untilDestroyed(this)).subscribe(() => {
      this.cancelDialogConfirmarAlterarCDEmitter.emit();
      this.dialogService.clearDialog();
    });
  }

  exibirDialogSelecaoLojaAutonoma(possuiItensNocarrinho: boolean) {
    const dialogData: IDialog = {
      open: true,
      title: 'Leitura do QrCode da loja autônoma necessária!',
      subTitle:
        'Você optou por fazer a compra em uma loja autônoma, mas não realizou a leitura do QrCode. Por favor, escaneie o QrCode da loja ou faça a compra online.',
      disabled: false,
      buttonConfirmText: 'Fazer leitura do QrCode',
      buttonCancelText: 'Comprar online',
    };
    this.dialogService.openDialog(dialogData);
    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe(async (confirm) => {
        await this.dialogService.clearDialog();
        if (confirm) {
          this.handleQrCodeScanClick(possuiItensNocarrinho);
        } else {
          this.novaFormaEntrega = null;
          this.changeDetector.detectChanges();
        }
      });
  }

  async handleQrCodeScanClick(possuiItensNoCarrinho: boolean) {
    try {
      const result = await this.codeScannerService?.scanQRCode();
      if (result) {
        const url = new URL(result);
        const cdId = url.pathname.split('/').pop();
        if (cdId && parseInt(cdId)) {
          if (possuiItensNoCarrinho) {
            this.exibirDialogCarrinhoLojaAutonoma(parseInt(cdId));
          } else {
            this.cdFacade.validarCentroDistribuicaoAutonomo(parseInt(cdId));
          }
        } else {
          this.messageService?.openErrorMessage('QR Code inválido', 2);
        }
      }
    } catch (err) {
      this.messageService?.openErrorMessage(
        'Ocorreu um erro ao ler o código, tente novamente.',
        1.5
      );
    }
  }

  exibirDialogCarrinhoLojaAutonoma(cdId: number) {
    this.dialogService.openDialog({
      open: true,
      disabled: false,
      title: 'Atenção',
      subTitle:
        'Você irá perder o seu carrinho atual se continuar a mudança para loja autônoma',
      buttonConfirmText: 'Ok, entendi',
      buttonCancelText: 'Cancelar',
    });
    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe((confirm) => {
        if (confirm) {
          this.cdFacade.validarCentroDistribuicaoAutonomo(cdId);
        }
        this.dialogService.clearDialog();
      });
  }

  subscribeCepChange() {
    this.cepFacade.getData$
      .pipe(
        untilDestroyed(this),
        mergeTakeOne(this.isLogged$),
        filter(([, isLogged]) => !isLogged)
      )
      .subscribe(([data]) => {
        if (data.cepAtendido != null) {
          if (data.cep && data.cepAtendido && !data.apenasRetirada) {
            this.novoEndereco = this.cepAsEndereco(data);
            this.carregarCdsEntrega(data.cep);
            this.validarCdsEntrega();
          } else if (data.apenasRetirada) {
            this.formaEntrega = FormaEntregaEnum.TIPO_RETIRADA;
          }
          this.cdFacade.getCentroDistribuicaoRetirada(data.cep);
        }
      });
  }

  scrollToLojas() {
    const element = document.getElementById('cds-entrega');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  setEnderecoEntrega(enderecoEntregaId?: number) {
    if (enderecoEntregaId) {
      this.enderecoFacade.selectEnderecoEntrega(enderecoEntregaId);
      this.tipoEntregaFacade.setFormaEntrega(true);
    }
  }

  goBack(aguardarTrocaDeCd = false): void {
    let cdObservable = this.cdSelecionado$.pipe(skip(1), take(1));

    if (!aguardarTrocaDeCd) {
      this.cdFacade.confirmarCd();
      cdObservable = this.cdSelecionado$.pipe(take(1));
    }

    cdObservable.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.redirectTo) {
        this.router.navigateByUrl(`/${this.redirectTo}`);
      } else {
        if (!this.isDesktop) this.navController.back();
      }
      this.redirectTo = undefined;
    });
  }

  alterarCd(
    cd: ICentroDistribuicao,
    possuiItensNocarrinho: boolean,
    possuiCompraEmProcesso: boolean
  ) {
    if (cd.id !== this.cdId) {
      if (
        !this.isLojaAutonoma &&
        (possuiItensNocarrinho || possuiCompraEmProcesso)
      ) {
        this.exibirDialogConfirmarAlterarCD(cd, this.novoEndereco?.id);
      } else if (this.isLojaAutonoma) {
        this.formaEntregaAlterada = false;
        this.novoCd = cd;
        this.exibirDialogSairLojaAutonoma(this.novoEndereco?.id);
      } else {
        this.formaEntregaAlterada = false;
        this.migrarCd(this.novoEndereco?.id);
      }
    } else {
      this.migrarCd(this.novoEndereco?.id);
      this.formaEntregaAlterada = false;
    }
  }

  migrarCd(enderecoEntregaId?: number) {
    if (enderecoEntregaId) {
      this.tipoEntregaFacade.setFormaEntrega(false);
      this.setEnderecoEntrega(enderecoEntregaId);
      this.formaEntrega = FormaEntregaEnum.TIPO_ENTREGA;
    } else {
      this.tipoEntregaFacade.setFormaEntrega(false);
      this.formaEntrega = FormaEntregaEnum.TIPO_RETIRADA;
    }
    const pathToAlwaysMigration = '/entrega-retirada';

    if (
      this.novoCd &&
      (this.novoCd.id !== this.cdSelecionado?.id ||
        this.router.url.includes(pathToAlwaysMigration))
    ) {
      this.cdFacade.migrarCentroDistribuicao(this.cdId, this.novoCd);
    } else if (
      this.novoCd &&
      this.cdSelecionado &&
      this.novoCd.id === this.cdSelecionado.id
    ) {
      this.cdFacade.confirmarCd();
    }

    if (this.isDesktop || this.isResponsive) {
      this.closeDropdownEntregaRetirada.emit();
    } else {
      this.goBack(true);
    }
  }

  solicitarCepCadastro() {
    this.validarCepContainerService.validarCep('cadastrar');
  }

  solicitarCep(disabled?: boolean) {
    const validateSuccess = this.validarCepContainerService.validarCep(
      this.isDesktop ? '' : 'entrega-retirada',
      this.selecaoCdObrigatoria || disabled
    );

    if (validateSuccess) {
      validateSuccess.pipe(untilDestroyed(this)).subscribe((isEntrega) => {
        this.modalService.clearModal().finally(() => {
          this.novaFormaEntrega = isEntrega
            ? FormaEntregaEnum.TIPO_ENTREGA
            : FormaEntregaEnum.TIPO_RETIRADA;
          this.changeDetector.detectChanges();

          if (this.isDesktop) {
            combineLatest([
              this.enderecoSelecionado$,
              this.cdsRetirada$,
              this.cdsEntrega$ ? this.cdsEntrega$ : of([]),
            ])
              .pipe(take(1))
              .subscribe(([enderecoSelecionado, cdsRetirada, cdsEntrega]) => {
                this.openModalInformarNovoEndereco(cdsRetirada, cdsEntrega);
              });
          }
          this.openInformarCep = false;
        });
      });
      this.modalService.closeClick
        .pipe(untilDestroyed(this), take(1))
        .subscribe(() => {
          this.novaFormaEntrega = FormaEntregaEnum.TIPO_RETIRADA;
          this.openInformarCep = false;
        });
    }
  }

  resetFormasDeEntrega(
    modalInstance: EnderecoEntregaRetiradaContainerComponent
  ) {
    this.novoEndereco = null;
    this.enderecoFacade.selectEnderecoEntrega(null);
    modalInstance.novoEndereco = null;
  }

  verificaFilialRealizaEntrega() {
    this.filialFacade.filial$.pipe(untilDestroyed(this)).subscribe((filial) => {
      this.filialId = filial.id;
      this.realizaEntrega$ = this.tipoEntregaFacade.realizaEntrega(
        this.filialId
      );
    });
  }

  setCdEntregaEnderecoInicial() {
    this.enderecoEntrega$
      .pipe(
        untilDestroyed(this),
        filter((endereco) => endereco !== null),
        take(1),
        mergeTakeOne(this.isEntrega$, this.enderecoFacade.enderecoSelecionado$)
      )
      .subscribe(([enderecoEntrega, isEntrega, enderecoSelecionado]) => {
        if (enderecoEntrega && isEntrega) {
          this.carregarCdsEntrega(enderecoEntrega.cep);
          this.validarCdsEntrega();
          this.cdsEntrega$
            ?.pipe(untilDestroyed(this), take(1))
            .subscribe((cdsEntrega) => {
              if (!cdsEntrega.length && isEntrega && enderecoSelecionado)
                this.exibirDialogCdSemEntrega();
            });
        }
      });
  }

  selecionarCdAoInicializar() {
    this.clienteFacade.isLogged$
      .pipe(untilDestroyed(this), take(1))
      .subscribe((isLogged) => {
        if (isLogged) {
          this.enderecoFacade.getEnderecosEntrega();
        } else {
          /** TODO remover quando ajustar responsivo da tela */
          setTimeout(() => {
            if (!this.isDesktop) {
              this.solicitarCep(true);
            }
          }, 1000);

          this.subscribeCepChange();
        }
      });
  }

  openModalInformarNovoEndereco(
    cdsRetirada: ICentroDistribuicao[],
    cdsEntrega: ICentroDistribuicao[],
    isPrimeiroProduto?: boolean
  ) {
    if (this.router.url.includes('/cadastrar')) return;
    if (this.modalInformarNovoEndereco) return;
    const modal = this.modalService.openModal(
      EnderecoEntregaRetiradaContainerComponent,
      {
        bottom: false,
      }
    );
    if (modal) {
      this.modalInformarNovoEndereco = modal;
      if (isPrimeiroProduto) this.novaFormaEntrega = null;
      const { instance: modalInstance } =
        modal as ComponentRef<EnderecoEntregaRetiradaContainerComponent>;
      modalInstance.enderecos = this.novoEndereco ? [this.novoEndereco] : [];
      modalInstance.cdsRetirada = cdsRetirada;
      modalInstance.cdsEntrega = cdsEntrega;
      modalInstance.informarCepClicked
        .asObservable()
        .pipe(take(1))
        .subscribe(() => {
          this.openInformarCep = true;
          this.modalService.clearModal().finally(() => this.solicitarCep());
        });

      modalInstance.enderecoSelecionado$ = this.enderecoSelecionado$.pipe(
        map((endereco) => {
          if (endereco === null) {
            return null;
          }
          return endereco;
        })
      );

      this.enderecoFacade.enderecoSelecionado$.subscribe((endereco) => {
        modalInstance.enderecoSelecionado = endereco;
      });

      modalInstance.refreshNovoCd.pipe(untilDestroyed(this)).subscribe((cd) => {
        this.novoCd = cd;
      });

      modalInstance.enderecoEntrega$ = this.enderecoEntrega$;
      modalInstance.novoEndereco = this.novoEndereco;
      this.isEntrega$.pipe(untilDestroyed(this)).subscribe((isEntrega) => {
        modalInstance.formaEntregaFromState = isEntrega
          ? FormaEntregaEnum.TIPO_ENTREGA
          : FormaEntregaEnum.TIPO_RETIRADA;
      });
      modalInstance.cdChange.pipe(untilDestroyed(this)).subscribe((cd) => {
        this.onCdChange(cd);
        this.modalService.clearModal();
      });
      modalInstance.formaEntregaChange
        .pipe(untilDestroyed(this))
        .subscribe((formaEntrega) => {
          this.onFormaEntregaChange(formaEntrega);
          if (!modalInstance.isPrimeiroProduto)
            this.resetFormasDeEntrega(modalInstance);
        });
      modalInstance.enderecoEntregaChange
        .pipe(untilDestroyed(this), mergeTakeOne(this.cdFacade.cd$))
        .subscribe(([enderecoEntrega, cd]) => {
          if (
            !modalInstance.isPrimeiroProduto &&
            modalInstance.cdsEntrega.length === 1
          ) {
            this.modalService.clearModal();
            return;
          }
          this.alterarEnderecoEntrega(enderecoEntrega);
        });
      this.modalService.closeClick
        .asObservable()
        .pipe(untilDestroyed(this), take(1))
        .subscribe(() => {
          this.cdFacade.confirmarCd();
        });
      this.modalService.modalComponent.instance.afterClose
        .pipe(take(1))
        .subscribe(() => {
          this.modalEntregaRetiradaCloseSubject.next();
          this.modalInformarNovoEndereco = undefined;
        });
      return modalInstance;
    }
    return null;
  }

  async cadastrarEndereco() {
    await this.router.navigateByUrl('/minha-conta/endereco');
  }

  private carregarCdsEntrega(cep: string) {
    this.cdsEntrega$ = this.cdFacade.getCentroDistribuicaoEntrega(
      this.filialId,
      cep
    );
    this.cdsEntregaInitiateValue.emit();
  }

  private cepAsEndereco(dadosCep: CepStateData): IEndereco {
    const endereco: IEndereco = {
      titulo: dadosCep.cep,
      cep: dadosCep.cep,
      desativado: false,
      endereco_formatado: this.getEnderecoFormatado(dadosCep.enderecoDoCep),
      id: 1,
      principal: true,
    };

    return endereco;
  }

  private getEnderecoFormatado(endereco?: IEnderecoDoCep): string {
    if (!endereco) return '';
    return `${endereco.logradouro ? endereco.logradouro : ''} - ${
      endereco.bairro ? endereco.bairro : ''
    } ${endereco.cidade ? endereco.cidade : ''} / ${endereco.uf}`;
  }

  validarCdsEntrega() {
    this.cdsEntrega$
      ?.pipe(
        untilDestroyed(this),
        take(1),
        mergeTakeOne(this.enderecoFacade.enderecoSelecionado$)
      )
      .subscribe(([cdsEntrega, enderecoSelecionado]) => {
        if (cdsEntrega.length > 0) {
          if (cdsEntrega.length === 1) {
            this.novoCd = cdsEntrega[0];
            if (this.isDesktop) this.validarFormaSelecionada(true, cdsEntrega);
          } else if (this.cdSelecionado !== null) {
            this.novoCd = this.cdSelecionado;
          } else {
            this.novoCd = null;
          }
        } else if (enderecoSelecionado) {
          this.exibirMsgCepNaoAtendido = true;
          this.changeDetector.detectChanges();
        }
      });
  }
}
