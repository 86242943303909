import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  IMAGES_PATH,
  IStep,
  IStepperPage,
  ScrollService,
  StepStatusEnum,
} from '@vip/core';

@Component({
  selector: 'vip-stepper-desktop',
  templateUrl: './stepper-desktop.component.html',
  styleUrls: ['./stepper-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperDesktopComponent implements OnInit, OnDestroy {
  @Input()
  logoSrc!: string;

  @Input() fallbackImageUrl?: string;

  @Input() steps: IStep[] = [];
  @Input() stepsWithoutLink: IStepperPage | null = null;
  @Input() stepActive: number | null = null;
  @Input() mostrarHeader = true;

  @Input()
  url_google!: string;

  STATUS_ENUM = StepStatusEnum;

  constructor(@Inject(IMAGES_PATH) public imagesPath: string) {}

  ngOnDestroy(): void {
    ScrollService.smallestHeader = false;
  }

  ngOnInit(): void {
    ScrollService.smallestHeader = true;
  }
}
