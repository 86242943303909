<ng-container *ngIf="isMobile">
  <vip-pagamento-carteira-digital
    class="vip-page-content"
    [loading]="!!(loadingCompraEmProcesso$ | async)"
    [compraEmProcesso]="(compraEmProcesso$ | async) || null"
    [taxaServico]="taxaServico || null"
    [descontos]="compraEmProcesso?.compra_descontos || []"
    [valorDescontos]="compraEmProcesso?.valor_descontos || 0"
    [currentDate]="currentDate$ | async"
    (tempoExpirado)="onTempoExpirado()"
    (clickedCancelarPagamento)="handleCancelarPagamentoDigital($event)"
    (clickedTentarNovamente)="handleTentarNovamente()"
    (clickedDeeplink)="handleDeeplink($event)"
    (goBack)="handleGoBack($event)"
    (timerIsRunning)="pagamentoService.setTimerIsRunning($event)"
  ></vip-pagamento-carteira-digital>
</ng-container>

<ng-container *ngIf="!isMobile">
  <vip-stepper-desktop
    [logoSrc]="(logo$ | async) || ''"
    [url_google]="(filial$ | async)?.site_url || '' | getURLGoogleSafe"
    [fallbackImageUrl]="(logoFallback$ | async) || ''"
    [steps]="steps"
  ></vip-stepper-desktop>

  <div class="flex mt-[7.1875rem] mb-4">
    <vip-card-basico
      ngClass="block rounded shadow ml-12 mr-4"
      [disableRipple]="true"
      [expanded]="true"
      [title]="'Pagamento via ' + compraPagamento?.forma_pagamento?.descricao"
      padding="desktop"
      [useSubPadding]="true"
      [showHeaderDivider]="true"
    >
      <vip-image
        *ngIf="compraPagamento"
        class="mr-1 max-h-[1.875rem] max-w-[2rem]"
        [alt]="compraPagamento.forma_pagamento.descricao"
        [imageUrl]="compraPagamento.forma_pagamento.logo"
        titleImage
      ></vip-image>
      <vip-pagamento-carteira-digital-desktop
        class="vip-page-content"
        [loading]="!!(loadingCompraEmProcesso$ | async)"
        [compraEmProcesso]="(compraEmProcesso$ | async) || null"
        [taxaServico]="taxaServico || null"
        [descontos]="compraEmProcesso?.compra_descontos || []"
        [valorDescontos]="compraEmProcesso?.valor_descontos || 0"
        [currentDate]="currentDate$ | async"
        (clickedCancelarPagamento)="handleCancelarPagamentoDigital($event)"
        (clickedTentarNovamente)="handleTentarNovamente()"
        (clickedDeeplink)="handleDeeplink($event)"
        (timerIsRunning)="pagamentoService.setTimerIsRunning($event)"
      ></vip-pagamento-carteira-digital-desktop>
    </vip-card-basico>

    <vip-card-detalhes-cobranca
      class="w-full max-w-[20rem] mr-4 display-stick"
      confirmButtonText="Concluir Compra"
      data-cy="vip-detalhes-cobranca"
      [isDesktop]="true"
      [saldoCashback]="undefined"
      [showCashback]="false"
      [disablePagamentoButton]="false"
      [isLoading]="false"
      [showContinuarComprando]="false"
      [valorCompra]="compraEmProcesso?.valor_inicial || 0"
      [totalCompra]="(totalCompra$ | async) || 0"
      [taxaEntrega]="(taxaEntrega$ | async) || 0"
      [descontos]="compraEmProcesso?.compra_descontos || []"
      [valorDescontos]="compraEmProcesso?.valor_descontos || 0"
      [taxaServico]="taxaServico || null"
      [cardBasicBorder]="false"
      [editable]="true"
      [hideConfirmButton]="true"
    ></vip-card-detalhes-cobranca>
  </div>
</ng-container>
