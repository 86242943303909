<vip-card-basico
  [title]="title"
  icon="icon-shopping_basket"
  [button]="showButton ? 'Ver itens' : ''"
  [disableRipple]="!!produtos.length"
  [quantity]="produtos | qtdItensProdutos"
  (buttonClick)="verProdutosClick.emit()"
  (cardClick)="!produtos.length ? adicionarProdutosClick.emit() : null"
>
  <div class="mt-2">
    <ng-container *ngFor="let produtoCompra of resumoProdutos; let last = last">
      <vip-card-resumo-produtos-item
        [produtoCompra]="produtoCompra"
        (click)="itemProdutoClick.emit(produtoCompra)"
      ></vip-card-resumo-produtos-item>
      <hr *ngIf="!last || showButton" class="vip-divider my-1" />
    </ng-container>
    <div *ngIf="!produtos.length" class="text-xs vip-color-secondary-default">
      Adicionar produtos na lista
    </div>
  </div>
</vip-card-basico>
