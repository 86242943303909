import { Action, createReducer, on } from '@ngrx/store';

import * as FidelidadeActions from './fidelidade.actions';
import { IGenericState } from '@vip/state/utils';
import { ConfiguracoesFidelidade, IDescontoFidelidade } from '@vip/core';
import { FilialActions } from '@vip/state/filial';

export const FIDELIDADE_FEATURE_KEY = 'fidelidade';

export interface FidelidadeState
  extends IGenericState<IDescontoFidelidade | null> {
  isFidelidadeAtivo: boolean;
  fidelidadeConfiguracoes: ConfiguracoesFidelidade | null;
}

export const initialState: FidelidadeState = {
  data: null,
  status: 'pending',
  error: null,
  isFidelidadeAtivo: false,
  fidelidadeConfiguracoes: null,
};

const fidelidadeReducer = createReducer(
  initialState,
  on(FidelidadeActions.resetFidelidadeCompra, () => ({
    ...initialState,
  })),
  on(FidelidadeActions.getDescontosFidelidadeCompra, (state) => ({
    ...state,
    status: 'loading',
    error: null,
  })),
  on(
    FidelidadeActions.getDescontosFidelidadeCompraSuccess,
    (state, { descontoFidelidade }) => ({
      ...state,
      data: descontoFidelidade,
      status: 'success',
      error: null,
    })
  ),
  on(
    FidelidadeActions.getDescontosFidelidadeCompraFailure,
    (state, { error }) => ({
      ...state,
      status: 'error',
      error: error.error ? error.error.error : error,
    })
  ),
  on(
    FidelidadeActions.setIsFidelidadeAtivoSuccess,
    (state, { isFidelidadeAtivo }) => ({
      ...state,
      isFidelidadeAtivo,
    })
  ),
  on(
    FilialActions.getFilialPorVipCommerceFilialIdSuccess,
    (state, { fidelidade }) => ({
      ...state,
      fidelidadeConfiguracoes: fidelidade.configuracoes,
    })
  )
);

export function reducer(state: FidelidadeState | undefined, action: Action) {
  return fidelidadeReducer(state, action);
}
