<div [attr.data-cy]="'card_produto_observacao'">
  <div *ngIf="showDivider" class="vip-divider-dashed mt-2"></div>

  <vip-button
    (btnClick)="abrirInput = true"
    *ngIf="!observacao; else obsProduto"
    size="small"
    type="normal"
    [disabled]="disabled"
    [isDesktop]="isDesktop"
  >
    <i class="icon-add_comment text-md leading-none mr-1"></i>
    <span class="text-xs font-bold vip-adicionar-obs-text">
      Adicionar Observação
    </span>
  </vip-button>

  <ng-template #obsProduto>
    <div class="flex flex-row justify-between">
      <span
        class="text-xs mt-2"
        [ngClass]="
          disabled ? 'vip-color-disabled' : 'vip-color-secondary-default'
        "
      >
        {{ observacao }}
      </span>
      <i
        (click)="!disabled && (abrirOpcoes = true)"
        class="icon-more_vert text-md leading-none p-2 cursor-pointer rounded-full"
        rippleBorderRadius="100%"
        vipRipple
        [rippleDisabled]="disabled"
        [ngClass]="disabled ? 'vip-color-disabled' : 'vip-color-primary-main'"
        data-cy="btn-editar-observacao"
      ></i>
    </div>
  </ng-template>

  <vip-modal
    (closeClick)="abrirOpcoes = false"
    [attr.data-cy]="'card_produto_observacao_modal_opcoes'"
    [bottom]="true"
    [open]="abrirOpcoes"
  >
    <div class="py-1 px-4">
      <div
        (click)="abrirOpcoes = false; abrirInput = true"
        class="flex items-center cursor-pointer"
      >
        <i
          class="icon-edit vip-color-primary-main text-xl leading-none mr-5"
        ></i>
        <span class="text-sm vip-color-first-default">Editar observação</span>
      </div>
      <div class="vip-divider my-2"></div>
      <div
        (click)="abrirOpcoes = false; changeObservacao.emit('')"
        class="flex items-center cursor-pointer"
      >
        <i
          class="icon-close vip-color-error-main text-xl leading-none mr-5"
        ></i>
        <span class="text-sm vip-color-first-default">Remover observação</span>
      </div>
    </div>
  </vip-modal>

  <vip-modal
    (closeClick)="abrirInput = false"
    [attr.data-cy]="'card_produto_observacao_modal_input'"
    [open]="abrirInput"
  >
    <vip-simple-text-form
      (confirmClick)="abrirInput = false; changeObservacao.emit($event)"
      [fieldMaxLength]="200"
      [fieldRows]="3"
      [fieldValue]="observacao || ''"
      fieldPlaceholder="Como você deseja receber o seu produto?"
      title="Observação"
    ></vip-simple-text-form>
  </vip-modal>
</div>
