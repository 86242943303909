import { BreadcrumbModule } from '@vip/ui/breadcrumb';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuContentComponent } from './menu-content/menu-content.component';
import { MenuModule } from '@vip/ui/menu';
import { CoreModule } from '@vip/core';
import { CashbackHiddenDirective } from './directives/cashback-hidden.directive';
@NgModule({
  imports: [CommonModule, BreadcrumbModule, MenuModule, CoreModule],
  declarations: [MenuContentComponent, CashbackHiddenDirective],
  exports: [MenuContentComponent],
  providers: [],
})
export class MenuContentModule {}
