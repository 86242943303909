import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAplicativo from './+state/aplicativo.reducer';
import { AplicativoEffects } from './+state/aplicativo.effects';
import { AplicativoFacade } from './+state/aplicativo.facade';
import { FilialStateModule } from '@vip/state/filial';

@NgModule({
  imports: [
    CommonModule,
    FilialStateModule,
    StoreModule.forFeature(
      fromAplicativo.APLICATIVO_FEATURE_KEY,
      fromAplicativo.reducer
    ),
    EffectsModule.forFeature([AplicativoEffects]),
  ],
  providers: [AplicativoFacade],
})
export class AplicativoStateModule {}
