import { Injectable } from '@angular/core';
import { Location, PopStateEvent } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LocationChangeService {
  routeHandler: { [route: string]: () => void } = {};

  constructor(private location: Location) {
    this.registerListener();
  }

  registerListener() {
    this.location.subscribe((ev: PopStateEvent) => this.onLocationChange(ev));
  }

  onLocationChange(ev: PopStateEvent) {
    const route = ev.url?.split('?')[0] || -1;
    if (this.routeHandler[route]) {
      this.routeHandler[route]();
    }
  }

  addRouteHandler(route: string, callback: () => void) {
    this.routeHandler[route] = callback;
  }

  removeRouteHandler(route: string) {
    delete this.routeHandler[route];
  }
}
