import { MessageService } from '@vip/ui/message';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { tapResponse } from '@ngrx/component-store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import {
  IAvalicaoProduto,
  IProdutoCombo,
  IProdutoDetalhes,
  ISlide,
} from '@vip/core';
import { AdicionarAvaliacaoPayload, ProdutoWsService } from '@vip/ws';
import { ProdutoApiService } from '@vip/api';
import { FilialFacade } from '@vip/state/filial';
import { BucketsFacade, BucketsService } from '@vip/state/buckets';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { GenericComponentStore, IGenericState } from '@vip/state/utils';
import { DialogService, IDialog } from '@vip/ui/modal';
import { isPlatformBrowser, Location } from '@angular/common';
import { Router } from '@angular/router';

export interface IProdutoDetalhesStateData {
  detalhes: IProdutoDetalhes | null;
  combo: IProdutoCombo[] | null;
  avaliacao: IAvalicaoProduto | null;
}

export const initialState = {
  detalhes: null,
  combo: null,
  avaliacao: null,
};

@Injectable()
export class ProdutoDetalhesStore extends GenericComponentStore<IProdutoDetalhesStateData> {
  readonly detalhes$ = this.select(
    (stateContext: IGenericState<IProdutoDetalhesStateData>) =>
      stateContext.data.detalhes
  );

  readonly combo$ = this.select(
    (stateContext: IGenericState<IProdutoDetalhesStateData>) => {
      return stateContext.data;
    }
  );

  readonly avaliacao$ = this.select(
    (stateContext: IGenericState<IProdutoDetalhesStateData>) =>
      stateContext.data.avaliacao
  );

  readonly getDetalhes = this.effect(
    (payload$: Observable<{ produtoId: number }>) => {
      return combineLatest([
        payload$,
        this.cdFacade.filialECdSelecionado$.pipe(
          filter(
            (filialECdSelecionado) =>
              filialECdSelecionado[0] !== undefined &&
              filialECdSelecionado[1] !== undefined
          )
        ),
        this.bucketsFacade.bucketProduto$.pipe(
          filter((bucket) => bucket !== '')
        ),
      ]).pipe(
        map(([payload, [filial, cdSelecionado], bucket]) => ({
          ...payload,
          filial,
          cdSelecionado,
          bucket,
        })),
        switchMap((payload) => {
          this.updateStatus('loading');
          return this.produtoApiService
            .getDetalhes(
              payload.filial.id,
              payload.cdSelecionado.id,
              payload.produtoId
            )
            .pipe(
              tapResponse(
                (response) => {
                  this.patchState((state) => {
                    return {
                      data: {
                        ...state.data,
                        detalhes: this.converterDetalhes(
                          response.data,
                          payload.bucket
                        ),
                      },
                      status: 'success',
                    };
                  });
                },
                (error) => {
                  this.updateError((error as HttpErrorResponse).error.error);
                  if (
                    this.router.url.includes('produto') &&
                    isPlatformBrowser(this.platformId)
                  ) {
                    this.getDetalhesOnFailure();
                  }
                }
              )
            );
        })
      );
    }
  );

  readonly getCombo = this.effect(
    (payload$: Observable<{ produtoId: number }>) => {
      return combineLatest([
        payload$,
        this.cdFacade.filialECdSelecionado$.pipe(
          filter(
            (filialECdSelecionado) =>
              filialECdSelecionado[0] !== undefined &&
              filialECdSelecionado[1] !== undefined
          )
        ),
        this.bucketsFacade.bucketProduto$,
      ]).pipe(
        map(([payload, [filial, cdSelecionado], bucket]) => {
          return {
            ...payload,
            filial,
            cdSelecionado,
            bucket,
          };
        }),
        switchMap((payload) => {
          this.updateStatus('loading');
          return this.produtoApiService
            .getCombos(
              payload.filial.id,
              payload.cdSelecionado.id,
              payload.produtoId
            )
            .pipe(
              tapResponse(
                (response) => {
                  this.patchState((state) => {
                    return {
                      data: {
                        ...state.data,
                        combo: response.data
                          ? this.bucketsService.addBucketCombo(
                              response.data,
                              payload.bucket
                            )
                          : null,
                      },
                      status: 'success',
                    };
                  });
                },
                (error) =>
                  this.updateError((error as HttpErrorResponse).error.error)
              )
            );
        })
      );
    }
  );

  readonly getAvaliacao = this.effect(
    (payload$: Observable<{ produtoId: number }>) => {
      return combineLatest([
        payload$,
        this.filialFacade.filial$,
        this.bucketsFacade.bucketProduto$.pipe(
          filter((bucket) => bucket !== '')
        ),
      ]).pipe(
        map(([payload, filial, bucket]) => {
          return { ...payload, filial, bucket };
        }),
        switchMap((payload) => {
          this.updateStatus('loading');
          return this.produtoApiService
            .getAvaliacoes(payload.filial.id, payload.produtoId)
            .pipe(
              tapResponse(
                (response) => {
                  this.patchState((state) => {
                    return {
                      data: {
                        ...state.data,
                        avaliacao: response.data,
                      },
                      status: 'success',
                    };
                  });
                },
                (error) =>
                  this.updateError((error as HttpErrorResponse).error.error)
              )
            );
        })
      );
    }
  );

  readonly addAvaliacao = this.effect(
    (payload$: Observable<AdicionarAvaliacaoPayload>) => {
      return combineLatest([payload$]).pipe(
        map(([payload]) => {
          return { ...payload };
        }),
        switchMap((payload) => {
          this.updateStatus('loading');
          return this.produtoWsService.addAvaliacao(payload).pipe(
            tapResponse(
              (response) => {
                if (response.produtoComentario.id) {
                  this.messageService.openSuccessMessage(
                    response.message || 'Comentário cadastrado com sucesso',
                    1.5
                  );
                } else {
                  this.messageService.openErrorMessage(
                    response.message || 'Erro ao cadastrar comentário',
                    1.5
                  );
                }
                this.patchState((state) => {
                  return {
                    data: {
                      ...state.data,
                    },
                    status: 'success',
                  };
                });
              },
              (error) =>
                this.updateError((error as HttpErrorResponse).error.error)
            )
          );
        })
      );
    }
  );

  readonly resetDetalhes = () => {
    this.patchState((state) => {
      return {
        data: {
          ...state.data,
          detalhes: null,
        },
      };
    });
  };

  constructor(
    private produtoApiService: ProdutoApiService,
    private produtoWsService: ProdutoWsService,
    private bucketsFacade: BucketsFacade,
    private bucketsService: BucketsService,
    private cdFacade: CentroDistribuicaoFacade,
    private filialFacade: FilialFacade,
    private messageService: MessageService,
    private location: Location,
    private router: Router,
    private dialogService: DialogService,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>
  ) {
    super(initialState);
  }

  protected converterDetalhes(detalhes: IProdutoDetalhes, bucket?: string) {
    if (detalhes.videos) {
      detalhes.videos = detalhes.videos.map(
        (video: ISlide & { descricao?: string; url?: string }) => {
          return {
            id: video.id,
            seconds: 0,
            nova_janela: false,
            model: '',
            description: video.descricao || '',
            src: video.url || '',
            details: { is_video: true },
          };
        }
      );
    }

    if (detalhes.imagens) {
      detalhes.imagens = detalhes.imagens.map(
        (imagem: ISlide & { filename?: string }) => {
          return {
            id: imagem.id,
            seconds: 0,
            nova_janela: false,
            model: '',
            description: detalhes.produto.descricao,
            src: `${bucket}/250x250/${imagem.filename}`,
            details: { is_video: false },
          };
        }
      );
    }
    detalhes.familia = detalhes.familia
      .filter((produto) => produto.produto_id !== detalhes.produto.produto_id)
      .map((produto) => this.bucketsService.addBucketProduto(produto, bucket));

    detalhes.produto = this.bucketsService.addBucketProduto(
      detalhes.produto,
      bucket
    );
    return detalhes;
  }

  getDetalhesOnFailure() {
    const dialogData: IDialog = {
      title: `Produto Indisponível`,
      subTitle: `Infelizmente este produto não está disponível na Loja selecionada`,
      open: true,
      disabled: false,
    };
    this.dialogService.openDialog(dialogData);
    this.dialogService.closeClick
      .pipe(take(1))
      .subscribe(() => this.dialogService.clearDialog());
    this.location.back();
  }
}
