import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  Type,
} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ComponentInjectorService<T> {
  constructor(
    private componentResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document
  ) {}

  // Cria um dinamicamente no ApplicationRef
  createComponentInApplication(
    component: Type<T>,
    content?: string
  ): ComponentRef<T> {
    const ngContent = content ? this.resolveNgContent(content) : undefined;
    const componentRef = this.componentResolver
      .resolveComponentFactory(component)
      .create(this.injector, ngContent);
    this.applicationRef.attachView(componentRef.hostView);
    const domElem = (<EmbeddedViewRef<T>>componentRef.hostView).rootNodes[0];
    this.document
      .getElementById('component-injector-target')
      ?.appendChild(domElem);
    return componentRef;
  }

  // Destrói o componente e remove do ApplicationRef
  destroyComponentInApplication(componentRef: ComponentRef<T>) {
    if (!this.applicationRef.destroyed)
      this.applicationRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }

  resolveNgContent(content: string) {
    const element = this.document.createTextNode(content);
    return [[element]];
  }
}
