import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'unidadeDiferente' })
export class UnidadeDiferentePipe implements PipeTransform {
  transform(
    valorUnidade: number = 1,
    siglaUnidade: string = 'kg',
    sempreExibirValor = false
  ): string {
    siglaUnidade = siglaUnidade.toLowerCase();
    let response = siglaUnidade;
    const locale = 'pt-BR';
    const options = {
      maximumFractionDigits: 3,
      minimumFractionDigits: 0,
    };

    if (sempreExibirValor) {
      response = valorUnidade.toLocaleString(locale, options) + siglaUnidade;
    }

    if (siglaUnidade === 'kg' && valorUnidade != 1) {
      response =
        valorUnidade < 1
          ? (valorUnidade * 1000).toLocaleString(locale, options) + 'g'
          : valorUnidade.toLocaleString(locale, options) + siglaUnidade;
    }

    if (!valorUnidade) {
      response = siglaUnidade;
    }
    return response;
  }
}
