<div class="relative" [ngClass]="{ 'h-full': !products.length && loaded }">
  <div
    class="flex flex-col flex-grow h-full"
    [ngClass]="{ 'pb-7': responsive }"
  >
    <vip-screen-title
      [title]="title"
      [isLoading]="!loaded"
      leftIcon="icon-arrow_back"
      leftIconColor="secondary"
      [showSearchButton]="true"
    ></vip-screen-title>

    <vip-grid-card
      [filters]="filters"
      [carousel]="carousel"
      [isLoading]="!loaded || products.length === 0"
      [scrollContainerSelector]="scrollSelector"
      [distanceScroll]="distanceScroll"
      (loadMore)="loaded ? loadMore.emit() : $event?.preventDefault()"
      (filterClick)="filterClick.emit($event)"
      (externalFilterClick)="externalFilterClick.emit($event)"
    >
      <vip-card-produto
        *ngFor="let product of products; trackBy: trackByIdEQuantidade"
        [produto]="product"
        [attr.data-cy]="'produto_card_' + product.id"
        [showUnavailableMessage]="
          aviseMeEnviados?.get(product.produto_id) ?? false
        "
        [produtosNoCarrinho]="produtosNoCarrinho"
        (visible)="visible.emit(product)"
        (getClickAds)="getClique($event)"
        (addListClick)="addListClick.emit(product)"
        (produtoChanged)="produtoChanged.emit($event)"
        (aviseMeClick)="aviseMeClick.emit($event)"
        [adsValueParams]="valueAds"
      ></vip-card-produto>

      <div *ngIf="!loaded && products.length" class="col-span-2">
        <vip-card-produto-lista-skeleton
          [noSidePadding]="true"
          [quantity]="6"
        ></vip-card-produto-lista-skeleton>
      </div>
    </vip-grid-card>

    <div
      *ngIf="responsive && loaded && paginator && paginator.total_pages > 1"
      class="pt-7"
    >
      <vip-pagination
        [paginator]="paginator"
        (pageClick)="pageClick.emit($event)"
      ></vip-pagination>
    </div>

    <vip-empty
      *ngIf="!products?.length && loaded"
      [image]="imageUrl"
      [text]="emptyText"
    ></vip-empty>
  </div>

  <div class="skeleton-wrapper" *ngIf="!loaded && !products.length">
    <vip-card-produto-lista-skeleton
      class="mt-3"
      [quantity]="16"
    ></vip-card-produto-lista-skeleton>
  </div>
</div>
