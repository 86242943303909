import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TELEVENDAS_FEATURE_KEY, TelevendasState } from './televendas.reducer';
import { GenericStoreStatusEnum } from '@vip/state/utils';

export const getTelevendasState = createFeatureSelector<TelevendasState>(
  TELEVENDAS_FEATURE_KEY
);

export const getTelevendasError = createSelector(
  getTelevendasState,
  (state: TelevendasState) => state.error
);

export const getToken = createSelector(
  getTelevendasState,
  (state: TelevendasState) => state.data.token
);

export const getOperador = createSelector(
  getTelevendasState,
  (state: TelevendasState) => state.data.operador
);

export const getFiliaisTelevendasSelector = createSelector(
  getTelevendasState,
  (state: TelevendasState) => state.data
);

export const getClienteTelevendasSelector = createSelector(
  getTelevendasState,
  (state: TelevendasState) => state.data.clientes
);

export const vincularFilialTelevendasSelector = createSelector(
  getTelevendasState,
  (state: TelevendasState) => state.data
);

export const isLoading = createSelector(
  getTelevendasState,
  (state: TelevendasState) => state.status === GenericStoreStatusEnum.LOADING
);
