import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridCardComponent } from './grid-card/grid-card.component';
import { CarouselModule } from '@vip/ui/carousel';
import { InfiniteScrollModule } from '@vip/ui/infinite-scroll';
import { RadioModule } from '@vip/ui/radio';
import { HeaderFilterComponent } from './header-filter/header-filter.component';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  imports: [
    CarouselModule,
    CommonModule,
    InfiniteScrollModule,
    RadioModule,
    SwiperModule,
  ],
  declarations: [GridCardComponent, HeaderFilterComponent],
  exports: [GridCardComponent, HeaderFilterComponent],
})
export class GridCardModule {}
