import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { FilialFacade } from '@vip/state/filial';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class HomeOmniGuard implements CanActivate {
  constructor(private filialFacade: FilialFacade, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.filialFacade.filial$.pipe(
      map((filial) => filial.exibe_home_omni || false),
      tap((exibeHomeOmni) => {
        if (!exibeHomeOmni) this.router.navigateByUrl('/loja');
      })
    );
  }
}
