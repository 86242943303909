export const layoutDesktopContainerMensagemDeSucesso = {
  open: true,
  title: 'Obrigado pela sua sugestão!',
  subTitle:
    'Sua sugestão é muito importante para nós! Através dela podemos melhorar nossa variedade de produtos para você.',
  disabled: false,
  buttonConfirmText: 'Ok, entendi',
};

export const layoutDesktopContainerMensagemDeErro = {
  open: true,
  title: 'Erro ao enviar formulário',
  subTitle:
    'Ocorreu um erro e não foi possível enviar seu formulário de sugestão no momento. Por favor, tente novamente mais tarde.',
  disabled: false,
  buttonConfirmText: 'Ok, entendi',
};
