import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ICupomCredito } from '@vip/core';

export const loadCupons = createAction('[Cupom/Page] Get Cupons');

export const loadCupomSuccess = createAction(
  '[Cupom/Page] Load Cupom Success',
  props<{ meusCupons: ICupomCredito[] }>()
);

export const loadCupomFailure = createAction(
  '[Cupom/Page] Load Cupom Failure',
  props<{ error: HttpErrorResponse }>()
);

export const loadCuponsDisponiveis = createAction(
  '[CupomPagamento/Page] Get Cupons disponiveis'
);

export const loadCuponsDisponiveisSuccess = createAction(
  '[CupomPagamento/Page] Get Cupons disponiveis Success',
  props<{ meusCupons: ICupomCredito[] }>()
);

export const loadCuponsDisponiveisFailure = createAction(
  '[CupomPagamento/Page] Get Cupons disponiveis Failure',
  props<{ error: HttpErrorResponse }>()
);

export const setCuponsSelecionados = createAction(
  '[CupomPagamento/Page] Set Cupons selecionados Success',
  props<{ selecionados: ICupomCredito[] }>()
);

export const removeCupomSelecionado = createAction(
  '[CupomPagamento/Page] Remove cupom selecionado',
  props<{ cupom: ICupomCredito }>()
);

export const resetCupons = createAction('[CupomPagamento/Page] Remove cupons');
