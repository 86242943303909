import { Injectable } from '@angular/core';

import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { tapResponse } from '@ngrx/component-store';
import { HttpErrorResponse } from '@angular/common/http';
import { ProdutoApiService } from '@vip/api';
import { GenericComponentStore, IGenericState } from '@vip/state/utils';

interface ProdutoIndisponivelStateData {
  aviseMeEnviados: Map<number, boolean>;
}

@Injectable()
export class ProdutosIndisponiveisStore extends GenericComponentStore<ProdutoIndisponivelStateData> {
  readonly aviseMeEnviados$ = this.select(
    (stateContext: IGenericState<ProdutoIndisponivelStateData>) =>
      stateContext.data.aviseMeEnviados
  );

  readonly sendAviseMeProdutoIndisponivel = this.effect(
    (payload$: Observable<{ produtoId: number }>) =>
      combineLatest([
        payload$,
        this.cdFacade.filialECdSelecionado$.pipe(
          filter(
            (filialECdSelecionado) =>
              filialECdSelecionado[0] !== undefined &&
              filialECdSelecionado[1] !== undefined
          )
        ),
      ]).pipe(
        map(([payload$, filialECdSelecionado]) => ({
          ...payload$,
          filialECdSelecionado,
        })),
        switchMap((payload) => {
          this.updateStatus('loading');

          return this.produtoApiService
            .aviseMeProdutoIndisponivel(
              payload.filialECdSelecionado[0].id,
              payload.filialECdSelecionado[1].id,
              payload.produtoId
            )
            .pipe(
              tapResponse(
                () => {
                  this.patchState((state) => ({
                    data: {
                      aviseMeEnviados: new Map<number, boolean>(
                        state.data.aviseMeEnviados.set(payload.produtoId, true)
                      ),
                    },
                    status: 'success',
                  }));
                },
                (error) =>
                  this.updateError((error as HttpErrorResponse).error.error)
              )
            );
        })
      )
  );

  constructor(
    private cdFacade: CentroDistribuicaoFacade,
    private produtoApiService: ProdutoApiService
  ) {
    super({
      aviseMeEnviados: new Map<number, boolean>(),
    });
  }
}
