import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseSkeletonComponent } from './base-skeleton/base-skeleton.component';
import { SkeletonDirective } from './base-skeleton/base-skeleton.directive';
import { BaseComponentSkeletonComponent } from './base-component-sekeleton.component';
@NgModule({
  imports: [CommonModule],
  declarations: [
    BaseSkeletonComponent,
    SkeletonDirective,
    BaseComponentSkeletonComponent,
  ],
  exports: [
    BaseSkeletonComponent,
    SkeletonDirective,
    BaseComponentSkeletonComponent,
  ],
})
export class SkeletonModule {}
