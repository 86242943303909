import { Component, ViewEncapsulation } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { ModalDirective } from './modal.diretive';
import { ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'vip-modal',
  templateUrl: './modal.component.html',
  styleUrls: [
    './modal.component.scss',
    '../../../../../utils/styles/backdrop.scss',
    '../../../../../utils/styles/global.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('modalButton', [
      state(
        'openDefault',
        style({
          transform: 'scale(1)',
          opacity: 1,
        })
      ),
      state(
        'closedDefault',
        style({
          transform: 'scale(0)',
          opacity: 0,
        })
      ),
      state(
        'openBottom',
        style({
          opacity: 1,
        })
      ),
      state(
        'closedBottom',
        style({
          opacity: 0,
        })
      ),
      transition('openDefault <=> closedDefault', [animate('0.2s')]),
      transition('openBottom <=> closedBottom', [animate('0.2s')]),
    ]),
    trigger('modalContent', [
      state(
        'openDefault',
        style({
          transform: 'scale(1)',
        })
      ),
      state(
        'closedDefault',
        style({
          transform: 'scale(0)',
        })
      ),
      state(
        'openBottom',
        style({
          transform: 'translate(0,0)',
          maxHeight: '80%',
          opacity: 1,
          overflow: 'visible',
        })
      ),
      state(
        'closedBottom',
        style({
          transform: 'translate(0,100%)',
          maxHeight: 0,
          opacity: 0,
        })
      ),
      transition('openDefault <=> closedDefault', [animate('0.2s')]),
      transition('openBottom <=> closedBottom', [animate('0.2s')]),
    ]),
  ],
})
export class ModalComponent extends ModalDirective {
  @ViewChild('modalContainer', { read: ViewContainerRef })
  public modalContainer: ViewContainerRef | undefined;

  getAnimation() {
    let state = 'false';
    if (this.bottom) {
      state = this.open ? 'openBottom' : 'closedBottom';
    } else {
      state = this.open ? 'openDefault' : 'closedDefault';
    }
    return state;
  }
}
