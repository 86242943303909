import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ICartao } from '@vip/core';

export const getCartoes = createAction('[Pagamento/Page] Get Cartoes');

export const getCartoesSuccess = createAction(
  '[Cartoes/WS] Get Cartoes Success',
  props<{ cartoes: ICartao[] }>()
);

export const getCartoesFailure = createAction(
  '[Cartoes/WS] Get Cartoes Failure',
  props<{ error: HttpErrorResponse }>()
);

export const setCartaoSelecionado = createAction(
  '[Cartoes/Page] Set Cartao Selecionado',
  props<{ cartaoId: string | null }>()
);

export const deletarCartao = createAction(
  '[Pagamento/Page] Deletar Cartao',
  props<{ id: string }>()
);

export const deletarCartaoSuccess = createAction(
  '[Cartoes/WS] Deletar Cartao Success',
  props<{ id: string }>()
);

export const deletarCartaoFailure = createAction(
  '[Cartoes/WS] Deletar Cartao Failure',
  props<{ error: HttpErrorResponse }>()
);
