import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IProduto } from '@vip/core';
import { ClienteFacade } from '@vip/state/cliente';
import { ModalService } from '@vip/ui/modal';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AddToListContainerComponent } from './add-to-list/add-to-list.component';

@Injectable({
  providedIn: 'root',
})
export class AddToListContainerService {
  isModalOpen$ = new BehaviorSubject<boolean>(false);

  constructor(
    private modalService: ModalService<AddToListContainerComponent>,
    private clienteFacade: ClienteFacade,
    private router: Router
  ) {}

  adicionarNaLista(produto: IProduto) {
    this.clienteFacade.isLogged$.pipe(take(1)).subscribe((isLogged) => {
      if (!isLogged) {
        this.router.navigateByUrl('/login');
        return;
      }

      const modalRef = this.modalService.openModal(
        AddToListContainerComponent,
        {
          bottom: true,
        }
      );
      this.isModalOpen$.next(true);
      if (modalRef && produto.produto_id) {
        modalRef.instance.product = produto;
        modalRef.instance.addedToList.subscribe(() => {
          this.modalService.clearModal();
          this.isModalOpen$.next(false);
        });
      }
      this.modalService.closeClick.pipe(take(1)).subscribe(() => {
        this.isModalOpen$.next(false);
      });
    });
  }
}
