import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { vitrineDedicadaSelectors } from './vitrine-dedicada.selector';
import * as VitrineDedicadaActions from './vitrine-dedicada.actions';
import { filter } from 'rxjs/operators';
import { IVitrinesDedicadas } from '@vip/core';

@Injectable()
export class VitrineDedicadaFacade {
  loading$ = this.store.pipe(select(vitrineDedicadaSelectors.isLoading()));
  error$ = this.store.pipe(select(vitrineDedicadaSelectors.getError()));

  vitrines$ = this.store.pipe(select(vitrineDedicadaSelectors.getVitrines()));

  vitrinesSemPerfil$ = this.store.pipe(
    select(vitrineDedicadaSelectors.getVitrinesSemPerfil())
  );

  vitrinesComPerfil$ = this.store.pipe(
    select(vitrineDedicadaSelectors.getVitrinesComPerfil())
  );

  isLoaded$ = this.store.pipe(select(vitrineDedicadaSelectors.isLoaded()));

  constructor(private store: Store) {}

  getVitrineById(id: string) {
    return this.store.pipe(
      select(vitrineDedicadaSelectors.getVitrineById(id)),
      filter((vitrine): vitrine is IVitrinesDedicadas => vitrine !== null)
    );
  }

  getVitrines() {
    this.store.dispatch(VitrineDedicadaActions.loadVitrine());
  }
}
