import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TipoEntregaApiService } from '@vip/api';
import { IHorariosFormatados } from '@vip/core';
import { CepFacade } from '@vip/state/cep';
import { PreferenciasService } from '@vip/state/cliente';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as TipoEntregaActions from './tipo-entrega.actions';
import { tipoEntregaSelectors } from './tipo-entrega.selectors';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class TipoEntregaFacade {
  loading$ = this.store.pipe(select(tipoEntregaSelectors.isLoading()));
  loaded$ = this.store.pipe(select(tipoEntregaSelectors.isLoaded()));
  error$ = this.store.pipe(select(tipoEntregaSelectors.getError()));
  tiposEntregas$ = this.store.pipe(
    select(tipoEntregaSelectors.getAllTiposEntregasArray())
  );
  tiposEntregasTelevendas$ = this.store.pipe(
    select(tipoEntregaSelectors.getAllTiposEntregasTelevendasArray())
  );
  isEntregaComCepValidado$ = combineLatest([
    this.store.pipe(select(tipoEntregaSelectors.isEntrega())),
    this.cepFacade.cepAtendido$.pipe(
      filter((cepAtendido): cepAtendido is boolean => cepAtendido !== null)
    ),
    this.cepFacade.apenasRetirada$.pipe(
      filter(
        (apenasRetirada): apenasRetirada is boolean => apenasRetirada !== null
      )
    ),
  ]).pipe(
    map(([isEntrega, cepAtendido, apenasRetirada]) => {
      if (cepAtendido) {
        if (apenasRetirada) {
          return false;
        } else {
          return isEntrega;
        }
      } else {
        return false;
      }
    })
  );
  isEntrega$ = this.store.pipe(select(tipoEntregaSelectors.isEntrega()));
  tipoEntregaPadrao$ = this.store.pipe(
    select(tipoEntregaSelectors.tipoEntregaPadrao())
  );

  tipoEntregaSelecionado$ = this.store.pipe(
    select(tipoEntregaSelectors.getTipoEntregaSelecionada())
  );

  tipoEntregaIdSelecionado$ = this.store.pipe(
    select(tipoEntregaSelectors.getTipoEntregaSelecionadoId())
  );

  horarioSelecionado$ = this.store.pipe(
    select(tipoEntregaSelectors.getHorarioSelecionado()),
    filter((horario): horario is IHorariosFormatados => horario !== undefined)
  );

  tipoEntregaEHorarioSelecionados$ = combineLatest([
    this.tipoEntregaSelecionado$,
    this.horarioSelecionado$,
  ]);

  getTipoEntregaSuccess$ = this.actions$.pipe(
    ofType(TipoEntregaActions.getTipoEntregaSuccess)
  );

  constructor(
    private store: Store,
    private tipoEntregaService: TipoEntregaApiService,
    private cepFacade: CepFacade,
    private preferenciasService: PreferenciasService,
    private actions$: Actions
  ) {}

  init() {
    const preferencias = this.preferenciasService.getPreferencias();
    if (preferencias && preferencias.isEntrega != undefined) {
      this.setFormaEntrega(preferencias.isEntrega);
    }
  }

  realizaEntrega(filialId: number) {
    return this.tipoEntregaService.realizaEntrega(filialId);
  }

  setFormaEntrega(isEntrega: boolean) {
    this.preferenciasService.setIsEntrega(isEntrega);
    return this.store.dispatch(
      TipoEntregaActions.setFormaEntrega({ isEntrega })
    );
  }

  selectTipoEntrega(tipoEntregaId: number | null) {
    return this.store.dispatch(
      TipoEntregaActions.selectTipoEntrega({ tipoEntregaId })
    );
  }

  selectHorarioTipoEntrega(horarioTipoEntrega: IHorariosFormatados) {
    return this.store.dispatch(
      TipoEntregaActions.selectHorarioTipoEntrega({ horarioTipoEntrega })
    );
  }

  getFretesPorCep(cep: string) {
    this.store.dispatch(TipoEntregaActions.getFretesPorCep({ cep }));
  }

  resetTiposEntrega() {
    this.store.dispatch(TipoEntregaActions.resetTiposEntregaStore());
  }

  resetTiposEntregaSelecionada() {
    this.store.dispatch(TipoEntregaActions.resetTipoEntregaSelecionada());
  }

  getTiposEntregasEndereco(showLoading: boolean = false) {
    this.store.dispatch(
      TipoEntregaActions.getTiposEntregasEndereco({ showLoading })
    );
  }

  getTipoEntregaPagamento(showLoading: boolean = false) {
    this.store.dispatch(
      TipoEntregaActions.getTipoEntregaPagamento({ showLoading })
    );
  }

  getTipoEntregaPadrao(
    enderecoId: number,
    totalItens: number,
    compraId: number
  ) {
    this.store.dispatch(
      TipoEntregaActions.getTipoEntregaPadrao({
        enderecoId,
        totalItens,
        compraId,
      })
    );
  }

  getTipoEntrega(
    tipoEntregaId: number,
    enderecoId: number,
    totalItens: number
  ) {
    return this.store.dispatch(
      TipoEntregaActions.getTipoEntrega({
        tipoEntregaId,
        enderecoId,
        totalItens,
      })
    );
  }
}
