<vip-forma-pagamento
  class="vip-page-content"
  [ngClass]="{ 'pt-1': isDesktopResponsive }"
  [desativaOnline]="(removerPrePagos$ | async) || false"
  [formaPagamentos]="(formaPagamentos$ | async) || []"
  [formaPagamentoSelecionada]="(formaPagamentosSelecionadas$ | async) || null"
  [hasPortadorValido]="(hasPortadorValido$ | async) || false"
  [cartoes]="(cartoes$ | async) || []"
  [permitirSalvarCartao]="(permitirSalvarCartao$ | async) ?? false"
  [cartaoSelecionadoId]="cartaoSelecionadoId$ | async"
  [isLojaAutonoma]="(isLojaAutonoma$ | async) || false"
  [pagamentos]="
    (pagamentos$ | async) ?? formaPagamentosFacade.emptyPagamentosViewModel
  "
  (formaPagamentoChange)="selecionaFormaPagamento($event)"
  (cartaoSalvoChange)="cartaoFacade.setCartaoSelecionado($event)"
  (portadorChange)="compraEmProcessoFacade.setPortador($event)"
  (deletarCartao)="cartaoFacade.deletarCartao($event)"
  (confirm)="onConfirm()"
  (limparFormaPagamentoSelecionada)="
    formaPagamentosFacade.resetFormaPagamentoSelecionada()
  "
></vip-forma-pagamento>
