<vip-stepper-desktop
  *ngIf="isFromResumoCompra"
  [logoSrc]="(logo$ | async) ?? ''"
  [fallbackImageUrl]="(logoFallback$ | async) ?? ''"
  [steps]="steps"
  [url_google]="(filial$ | async)?.site_url | getURLGoogleSafe"
></vip-stepper-desktop>

<div class="endereco-wrapper">
  <vip-endereco
    [entregaRetiradaTemplate]="true"
    [endereco]="
      !isNew ? (newEndereco$ | async) || null : (enderecoSelecionado$ | async)
    "
    [cepAtendido]="(cepAtendido$ | async) === true"
    [cepValido]="(cepAtendido$ | async) === true"
    [isNew]="isNew"
    [possuiEnderecoCadastrado]="(possuiEnderecoCadastrado$ | async) === true"
    [loading]="(loadingCep$ | async) || (loadingEndereco$ | async) === true"
    [manualTitle]="manualTitle"
    (changeCep)="changeCep($event)"
    (confirm)="saveEndereco($event)"
    (closeClicked)="onCloseClick()"
  ></vip-endereco>
</div>
