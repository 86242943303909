import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CookiesApiService } from '@vip/api';
@UntilDestroy()
@Component({
  selector: 'vip-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesComponent implements OnInit {
  public autorizadoUsarCookie: boolean | null = null;

  constructor(private cookiesApiService: CookiesApiService) {}

  public ngOnInit() {
    this.validarExibicaoAceiteCookie();
  }

  public validarExibicaoAceiteCookie() {
    const clienteLogado = this.cookiesApiService.logged();
    const autorizadoUsarCookieLocal =
      this.cookiesApiService.getAutorizadoUsarCookieLocal();

    if (autorizadoUsarCookieLocal) {
      this.autorizadoUsarCookie = autorizadoUsarCookieLocal;
    } else if (clienteLogado) {
      this.buscarClienteAceitouUsoCookie();
    }
  }

  public buscarClienteAceitouUsoCookie() {
    this.cookiesApiService
      .buscarAceiteUsoCookie()
      .pipe(untilDestroyed(this))
      .subscribe((res: any) => {
        this.autorizadoUsarCookie = res.data;
        this.cookiesApiService.salvarAutorizacaoUsoCookie(res.data);
      });
  }

  public aceitarUsoCookie() {
    this.cookiesApiService.aceitarUsoCookie();
    this.cookiesApiService.salvarAutorizacaoUsoCookie();
    this.autorizadoUsarCookie = true;
  }
}
