import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  IEndereco,
  IHorariosFormatados,
  IPortador,
  ITipoEntregaCompra,
} from '@vip/core';
import { compraEmProcessoSelectors } from './compra-em-processo.selectors';
import * as CompraEmProcessoActions from './compra-em-processo.actions';
import { IFinalizarCompraPayload } from '@vip/ws';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class CompraEmProcessoFacade {
  taxaServico$ = this.store.pipe(
    select(compraEmProcessoSelectors.taxaServico())
  );
  loading$ = this.store.pipe(select(compraEmProcessoSelectors.isLoading()));
  isLoaded$ = this.store.pipe(select(compraEmProcessoSelectors.isLoaded()));
  error$ = this.store.pipe(select(compraEmProcessoSelectors.getError()));
  currentDate$ = this.store.pipe(
    select(compraEmProcessoSelectors.currentDate())
  );
  gAdWords$ = this.store.pipe(select(compraEmProcessoSelectors.gAdWords()));
  compraEmProcesso$ = this.store.pipe(
    select(compraEmProcessoSelectors.getCompraEmProcesso(this.store))
  );
  hasPortadorValido$ = this.store.pipe(
    select(compraEmProcessoSelectors.hasPortadorValido(this.store))
  );
  cashbackUtilizado$ = this.store.pipe(
    select(
      compraEmProcessoSelectors.getCashbackUtilizadoCompraEmProcesso(this.store)
    )
  );
  pagamentoDigitalEfetuado$ = this.actions$.pipe(
    ofType(CompraEmProcessoActions.pagamentoDigitalEfetuado)
  );
  criarCompraResponse$ = this.actions$.pipe(
    ofType(CompraEmProcessoActions.criarCompraSuccess),
    ofType(CompraEmProcessoActions.criarCompraFailure)
  );

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions
  ) {}

  compraEmProcessoById(compraId: string) {
    return this.store.pipe(
      select(compraEmProcessoSelectors.getCompraEmProcessoById(compraId))
    );
  }

  init() {
    this.getCompraEmProcesso();
  }

  getCompraEmProcesso() {
    this.store.dispatch(CompraEmProcessoActions.getCompraEmProcesso());
  }

  setPortador(portador?: IPortador) {
    this.store.dispatch(
      CompraEmProcessoActions.setPortadorCompraEmProcesso({ portador })
    );
  }

  setFrete(frete?: number) {
    this.store.dispatch(
      CompraEmProcessoActions.setFreteCompraEmProcesso({ frete })
    );
  }

  setTipoEntrega(
    tipo_entrega?: ITipoEntregaCompra,
    horarioSelecionado?: IHorariosFormatados
  ) {
    this.store.dispatch(
      CompraEmProcessoActions.setTipoEntregaCompraEmProcesso({
        tipo_entrega,
        horarioSelecionado,
      })
    );
  }

  updateParcelas(parcelas: number) {
    this.store.dispatch(
      CompraEmProcessoActions.updateParcelasCompraEmProcesso({ parcelas })
    );
  }

  updateCvv(cvv: string) {
    this.store.dispatch(
      CompraEmProcessoActions.updateCVVCompraEmProcesso({ cvv })
    );
  }
  getTaxaServico(valorInicial?: number) {
    this.store.dispatch(
      CompraEmProcessoActions.getTaxaServico({ valorInicial })
    );
  }

  setCashback(valor: number): void {
    this.store.dispatch(
      CompraEmProcessoActions.setCashbackCompraEmProcesso({
        valorCashback: valor,
      })
    );
  }

  setCashbackSuccess(valor: number): void {
    this.store.dispatch(
      CompraEmProcessoActions.setCashbackCompraEmProcessoSuccess({
        valorCashback: valor,
      })
    );
  }

  criarCompra() {
    this.store.dispatch(CompraEmProcessoActions.criarCompra());
  }

  recriarCarrinhoContinuarComprando(
    compraId: number,
    filialId: number,
    cdId: number
  ) {
    this.store.dispatch(
      CompraEmProcessoActions.recriarCarrinhoContinuarComprando({
        compraId,
        filialId,
        cdId,
      })
    );
  }

  finalizarCompra(filialId: number, payload: IFinalizarCompraPayload) {
    this.store.dispatch(
      CompraEmProcessoActions.finalizarCompraEmProcesso({
        filialId: filialId,
        payload: payload,
      })
    );
  }

  cancelarPagamentoDigital(
    filialId: number,
    compraId: number,
    compraPagamentoId: number
  ) {
    this.store.dispatch(
      CompraEmProcessoActions.cancelarPagamentoDigital({
        filialId,
        compraId,
        compraPagamentoId,
      })
    );
  }

  verificarPagamentoDigital(
    filialId: number,
    compraId: number,
    compraPagamentoId: number
  ) {
    this.store.dispatch(
      CompraEmProcessoActions.verificarPagamentoDigital({
        filialId,
        compraId,
        compraPagamentoId,
      })
    );
  }

  resetCompraEmProcesso() {
    this.store.dispatch(CompraEmProcessoActions.resetCompraEmProcesso());
  }

  getcompraView(compraId: number) {
    this.store.dispatch(CompraEmProcessoActions.getCompraView({ compraId }));
  }
}
