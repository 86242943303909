import { Inject, Injectable } from '@angular/core';
import { BaseApiService } from '../common/base-api-service';
import { BaseApiResponse } from '../common/base-api-response';
import { CLIENTE_TOKEN, LGPD_COOKIE, STORAGE } from '@vip/core';

@Injectable({
  providedIn: 'root',
})
export class CookiesApiService {
  private autorizadoUsarCookie: boolean | null = null;
  constructor(
    private api: BaseApiService<CookiesApiService>,
    @Inject(STORAGE) readonly storage: Storage
  ) {}

  aceitarUsoCookie(): Promise<any> | null {
    if (this.logged()) {
      return this.api
        .put('aceitarUsoCookie', '/loja/clientes/aceite_cookies', '')
        .toPromise();
    }
    return null;
  }

  salvarAutorizacaoUsoCookie(autorizado: boolean = true) {
    this.storage.set(LGPD_COOKIE, autorizado);
  }

  buscarAceiteUsoCookie() {
    return this.api.get(
      'buscarAceiteUsoCookie',
      `/loja/clientes/aceite_cookies`
    );
  }

  setAutorizadoUsarCookie() {
    this.autorizadoUsarCookie = this.storage.get(LGPD_COOKIE) || false;
  }

  getAutorizadoUsarCookieLocal(): boolean {
    return this.autorizadoUsarCookie || this.storage.get(LGPD_COOKIE) || false;
  }

  logged() {
    return this.storage.get(CLIENTE_TOKEN) !== '';
  }
}
