<div class="mt-3">
  <h3 class="font-medium mb-3 vip-color-first-default text-base">
    Como deseja receber a sua compra:
  </h3>

  <vip-switch
    *ngIf="tiposEntregasOptions"
    class="block"
    [firstOption]="tiposEntregasOptions[formaEntregaEnum.TIPO_ENTREGA]"
    [secondOption]="tiposEntregasOptions[formaEntregaEnum.TIPO_RETIRADA]"
    [thirdOption]="tiposEntregasOptions[formaEntregaEnum.TIPO_AUTONOMA]"
    [optionChecked]="tiposEntregasOptions['checked']"
    (changedItemActive)="handleTiposEntregasChanged($event)"
  ></vip-switch>
  <ng-container [ngSwitch]="formaEntrega">
    <ng-container *ngSwitchCase="formaEntregaEnum.TIPO_ENTREGA">
      <hr class="vip-divider mb-4 mt-4" />
      <h3 class="font-medium mb-3 vip-color-first-default text-base">
        Em qual endereço deseja receber?
      </h3>

      <ng-container *ngIf="novoEnderecoCdSelect; else listaEnderecos">
        <vip-selectable-card
          *ngIf="enderecoSelecionado"
          class="my-2 block"
          [ngClass]="{
            'selected-color': enderecoSelecionado.id === enderecoSelecionado.id
          }"
          [checked]="enderecoSelecionado.id === enderecoSelecionado.id"
          [showIcon]="true"
          [fullWidth]="true"
          [disableAutoToggle]="true"
          (changed)="onEnderecoChange(enderecoSelecionado)"
          data-cy="enderecos-entrega"
        >
          <div title class="flex items-center card">
            <i class="mr-2 text-xl icon-location_on vip-color-primary-main"></i>
            <div class="vip-color-first-default text-xs text">
              {{ enderecoSelecionado.endereco_formatado }} /
              {{ enderecoSelecionado.cep }}
            </div>
          </div>
        </vip-selectable-card>
      </ng-container>

      <ng-template #listaEnderecos>
        <ng-container *ngFor="let endereco of enderecos; let last = last">
          <vip-selectable-card
            *ngIf="endereco"
            class="my-2 block"
            [ngClass]="{
              'selected-color': endereco.id === enderecoSelecionado?.id
            }"
            [checked]="endereco.id === enderecoSelecionado?.id"
            [showIcon]="true"
            [fullWidth]="true"
            [disableAutoToggle]="true"
            (changed)="onEnderecoChange(endereco)"
            data-cy="enderecos-entrega"
          >
            <div title class="flex items-center card">
              <i
                class="mr-2 text-xl icon-location_on vip-color-primary-main"
              ></i>
              <div class="vip-color-first-default text-xs text">
                {{ endereco.endereco_formatado }} / {{ endereco.cep }}
              </div>
            </div>
          </vip-selectable-card>
        </ng-container>
      </ng-template>

      <div
        class="pt-2 pb-4 w-full flex justify-center"
        *ngIf="novoEnderecoCdSelect"
      >
        <div class="w-[120px]">
          <vip-button
            data-cy="button-compartilhar-desktop"
            size="ex-small"
            [hasIcon]="true"
            (btnClick)="novoEnderecoCdSelect = false"
            [type]="'outline'"
          >
            Trocar endereço
          </vip-button>
        </div>
      </div>

      <div
        *ngIf="
          (!isPrimeiroProduto && !novoEnderecoCdSelect) ||
          (enderecos.length === 0 && !novoEnderecoCdSelect)
        "
        class="flex flex-col items-center mb-4"
      >
        <vip-button
          data-cy="btn-acessar-lista"
          size="small"
          type="outline"
          (click)="informarCepClick.emit($event)"
        >
          Informe seu cep
        </vip-button>
      </div>
      <hr *ngIf="cdsEntrega.length > 1" />

      <ng-container *ngIf="cdsEntrega.length > 1 && !!enderecoSelecionado?.id">
        <h3 class="font-medium my-4 vip-color-first-default text-base">
          Selecione a loja que deseja efetuar sua compra:
        </h3>
        <div *ngFor="let cd of cdsEntrega; let last = last">
          <vip-selectable-card
            class="my-2 block"
            [showIcon]="true"
            [fullWidth]="true"
            [ngClass]="{
              'selected-color': isSelectableValidateCd(cd)
            }"
            [checked]="isSelectableValidateCd(cd)"
            (changed)="onCdChanged(cd, false)"
            data-cy="cd-entrega"
          >
            <div title class="flex items-center card">
              <i
                class="mr-2 text-xl icon-store_mall vip-color-primary-main"
              ></i>
              <div class="vip-color-first-default text-xs text-lojas">
                <span class="vip-color-first-default text-xs text">{{
                  cd.nome_site
                }}</span>
                {{ cd.endereco.logradouro }}, {{ cd.endereco.numero }},
                {{ cd.endereco.bairro }} - {{ cd.endereco.cidade }} -
                {{ cd.endereco.estado }}
              </div>
            </div>
          </vip-selectable-card>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="formaEntregaEnum.TIPO_RETIRADA">
      <h3 class="font-medium my-4 vip-color-first-default text-base">
        Selecione a loja que deseja efetuar sua compra:
      </h3>
      <div *ngFor="let cd of cdsRetirada; let last = last">
        <vip-selectable-card
          class="my-2 block"
          [showIcon]="true"
          [fullWidth]="true"
          [ngClass]="{
            'selected-color': !isEntrega && cd.id === cdSelecionado?.id
          }"
          [checked]="!isEntrega && cd.id === cdSelecionado?.id"
          [disableAutoToggle]="true"
          data-cy="cds-retiradas"
          (changed)="onCdChanged(cd, true)"
        >
          <div title class="flex items-center card">
            <i class="mr-2 text-xl icon-store_mall vip-color-primary-main"></i>
            <div class="vip-color-first-default text-xs text-lojas">
              <span class="vip-color-first-default text-xs text">{{
                cd.nome_site
              }}</span>
              {{ cd.endereco.logradouro }}, {{ cd.endereco.numero }},
              {{ cd.endereco.bairro }} - {{ cd.endereco.cidade }} -
              {{ cd.endereco.estado }}
            </div>
          </div>
        </vip-selectable-card>
      </div>
    </ng-container>
  </ng-container>
</div>
