<div
  class="flex flex-col h-full w-full"
  [ngClass]="{
    absolute: !isDesktop && !isDesktopWithoutScreenWidth,
    'vip-modal': isDesktop || isDesktopWithoutScreenWidth
  }"
>
  <vip-screen-title
    data-cy="horarios-title"
    [title]="
      tipoEntrega?.retirada_loja
        ? 'Escolher Horário de Retirada'
        : 'Escolher Horário de Entrega'
    "
    [customActions]="true"
    [showActions]="true"
    [isLoading]="isLoading || !tipoEntrega"
  >
    <vip-button-icon-rounded
      class="z-10"
      type="outline"
      data-cy="voltar"
      routerLink="/entrega-retirada/alterar-entrega-retirada"
    >
      <i class="icon-arrow_back vip-color-secondary-main"></i
    ></vip-button-icon-rounded>
  </vip-screen-title>
  <div
    class="flex flex-col flex-grow h-full w-full vip-color-first-default overflow-hidden"
    *ngIf="!isLoading && tipoEntrega"
  >
    <div class="px-3 pt-3 flex-grow flex flex-col overflow-y-scroll">
      <div
        *ngFor="
          let dia of getHorariosPorDia(tipoEntrega.horarios_formatados);
          let first = first
        "
      >
        <span
          class="mb-2 block text-sm font-bold capitalize"
          [ngClass]="{ 'alterar-horarios-dia': !first }"
          *ngIf="dia.data"
        >
          {{ dia.data | DayOfWeekWithFullDate }}
        </span>
        <vip-selectable-card
          *ngFor="let horario of dia.horarios"
          class="inline-block card-horario"
          data-cy="horarios-selectable"
          [showIcon]="true"
          [checked]="horarioSelecionado?.key === horario.key"
          [changeTextColor]="true"
          [fullWidth]="false"
          (changed)="horarioChange.emit(horario)"
          [disableAutoToggle]="true"
        >
          <span title class="font-sm">
            <span>
              {{ getHorario(horario.labelMobileCalendario)[0] }}
            </span>
            <div>
              às
              {{ getHorario(horario.labelMobileCalendario)[1] }}
            </div>
          </span>
        </vip-selectable-card>
      </div>
    </div>
    <ng-container *ngIf="isDesktop; else mobileTemplate">
      <div class="vip-modal-controls">
        <vip-button
          [disabled]="!horarioSelecionado"
          (btnClick)="confirmClick.emit()"
          data-cy="horarios-confirmar"
        >
          Confirmar
        </vip-button>
      </div>
    </ng-container>
    <ng-template #mobileTemplate>
      <hr class="vip-divider" />
      <div class="p-3">
        <vip-button
          [disabled]="!horarioSelecionado"
          (btnClick)="confirmClick.emit()"
          data-cy="horarios-confirmar"
        >
          Confirmar
        </vip-button>
      </div>
    </ng-template>
  </div>
  <ng-container #loadingTemplate *ngIf="isLoading || !tipoEntrega">
    <vip-alterar-horarios-skeleton
      data-cy="skeleton-alterar-horarios"
      class="mt-2"
    ></vip-alterar-horarios-skeleton>
  </ng-container>
</div>
