import { Injectable } from '@angular/core';

import { BaseWsService } from '../common/base-ws-service';
import { GetCompraDescontos } from './desconto-ws.types';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DescontoWsService {
  private descontoUrl = '/loja/descontos';

  constructor(private ws: BaseWsService<DescontoWsService>) {}

  getDescontos(parametros: {
    filialId: number;
    compraId?: number;
    codigo?: string | null;
    formaPagamentoId?: number;
  }) {
    return this.ws
      .get<GetCompraDescontos>(
        'getDescontos',
        `${this.descontoUrl}/index?/${
          parametros.compraId ? '&compra_id=' + parametros.compraId : ''
        }${
          parametros.formaPagamentoId
            ? '&forma_pagamento_ids[]=' + parametros.formaPagamentoId
            : ''
        }${parametros.codigo ? '&codigo=' + parametros.codigo : ''}${
          parametros.filialId ? '&filial_id=' + parametros.filialId : ''
        }`
      )
      .pipe(
        map((res) => ({
          descontos: res.descontos,
          descontosFrete: res.frete_descontos,
        }))
      );
  }
}
