import { Injectable, Inject, Optional } from '@angular/core';
import { HOST } from '../../constants';
import { environment } from '../../environments';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  environment = environment;

  private orgId!: number;
  private vipcommerceFilialId!: number;
  private domainKey!: string;

  constructor(@Optional() @Inject(HOST) readonly host: string) {}

  setOrgId(orgId: number) {
    this.orgId = orgId;
  }

  getOrgId() {
    if (this.environment.orgId) {
      return this.environment.orgId;
    }
    return this.orgId;
  }

  setDomainKey(domainKey: string) {
    this.domainKey = domainKey;
  }

  getDomainKey() {
    if (this.environment.domainKey) {
      return this.environment.domainKey;
    }
    return this.domainKey;
  }

  setVipcommerceFilialId(vipcommerceFilialId: number) {
    this.vipcommerceFilialId = vipcommerceFilialId;
  }

  getVipcommerceFilialId() {
    return this.vipcommerceFilialId;
  }

  getHost() {
    let host;
    if (this.host) {
      host = this.host
        .replace('https://', '')
        .replace('http://', '')
        .split(':')[0];
    } else {
      host = window.location.hostname;
    }
    return host.replace(/(www\.|site\.|rc\.|site-cdn\.)/g, '');
  }

  getApiUrl() {
    if (this.environment.apiUrl) {
      return this.environment.apiUrl;
    }

    if (this.environment.horizontalizado) {
      return `${this.environment.apiServicesUrl}/api-admin/v1`;
    }

    const host = this.getHost();
    return `https://api-loja.${host}/v1`;
  }

  getWsUrl() {
    if (this.environment.wsUrl) {
      return this.environment.wsUrl;
    }

    if (this.environment.horizontalizado) {
      return `${this.environment.apiServicesUrl}/ws`;
    }

    const host = this.getHost();
    return `https://ws.${host}`;
  }

  getApiOrganizacoesUrl() {
    if (this.environment.apiOrganizacoesUrl) {
      return this.environment.apiOrganizacoesUrl;
    }
    return `${this.environment.apiServicesUrl}/organizacoes`;
  }

  getApiAplicativosUrl() {
    if (this.environment.apiAplicativosUrl) {
      return this.environment.apiAplicativosUrl;
    }

    return `${this.environment.apiServicesUrl}/aplicativos`;
  }
}
