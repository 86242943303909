import { createSelector } from '@ngrx/store';
import {
  COMPRA_FEATURE_KEY,
  compraAdapter,
  CompraState,
} from './compra.reducer';
import { SelectorUtil } from '@vip/state/utils';
import {
  CompraStatusEnum,
  CompraTipoEnum,
  ICompra,
  IProdutoCarrinho,
  IProdutoCompra,
} from '@vip/core';
import { groupBy } from 'lodash';

const { selectEntities, selectAll } = compraAdapter.getSelectors();

class CompraSelectors extends SelectorUtil<CompraState> {
  constructor() {
    super(COMPRA_FEATURE_KEY);
  }

  getAllCompras = () =>
    createSelector(this.createFeatureSelector, (state: CompraState) =>
      selectEntities(state)
    );

  getComprasArray = () =>
    createSelector(this.createFeatureSelector, (state: CompraState) =>
      selectAll(state)
    );

  getPrazoDevolucao = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CompraState) => state.data.prazoDevolucao
    );

  getPrazoTroca = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CompraState) => state.data.prazoTroca
    );

  getPerguntasCompra = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CompraState) => state.data.perguntas
    );

  temRespostas = () =>
    createSelector(
      this.getPerguntasCompra(),
      (perguntas) =>
        perguntas !== null &&
        !!perguntas.find((pergunta) => pergunta.cliente_pergunta_resposta)
    );

  getComprasTrocasDevolucao = () =>
    createSelector(this.getComprasArray(), (compras) => compras);

  getTrocaDevolucao = () =>
    createSelector(this.getComprasArray(), (compras) =>
      compras.filter(
        (compra: ICompra) =>
          compra.tipo_compra == CompraTipoEnum.TROCA ||
          compra.tipo_compra == CompraTipoEnum.DEVOLUCAO
      )
    );

  getCompras = () =>
    createSelector(this.getComprasArray(), (compras) =>
      compras.filter(
        (compra: ICompra) => compra.tipo_compra == CompraTipoEnum.COMPRAS
      )
    );

  getCompraSelecionadaId = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CompraState) => state.compraSelecionadaId
    );

  getCompraSelecionada = () =>
    createSelector(
      this.getAllCompras(),
      this.getCompraSelecionadaId(),
      (entities, selectedId) => selectedId && entities[selectedId]
    );

  getProdutosCompraSelecionada = () =>
    createSelector(this.getCompraSelecionada(), (compra) =>
      compra ? compra.produtos : []
    );

  getProdutosCompraSelecionadaAgrupadosPorDepartamento = () =>
    createSelector(
      this.getProdutosCompraSelecionada(),
      (produtos: IProdutoCompra[]) => {
        const itens = groupBy(produtos, (item) => {
          if (item.brinde) return 'Brinde';
          return item?.departamento?.descricao || 'Combos';
        });

        return Object.entries(itens)
          .map(([key, value]) => ({
            departamento: key,
            produtos: value.map((produto) =>
              this.convertProdutoCompraToProdutosCarrinho(produto)
            ),
          }))
          .sort((item) =>
            item.departamento == 'Combos' || item.departamento == 'Brinde'
              ? -1
              : 0
          );
      }
    );

  convertProdutoCompraToProdutosCarrinho(
    produtoCompra: IProdutoCompra & { produto_id: number }
  ): IProdutoCarrinho {
    return {
      ...produtoCompra,
      bebida_alcoolica: false,
      classificacao_mercadologica_id: 0,
      codigo_barras: '',
      em_oferta: false,
      exibe_preco_original: false,
      oferta: null,
      permitir_observacao_na_compra: false,
      preco_antigo: produtoCompra.preco,
      preco: produtoCompra.preco_venda,
      preco_original: 0,
      quantidade_maxima: 0,
      quantidade_minima: 0,
      quantidade_vendida: 0,
      classe_oferta: '',
      descricao_oferta: '',
      item_id: produtoCompra.produto_id,
      mercadoria_id: 0,
      observacao: '',
      tipo: 0,
      volume_principal: null,
    };
  }

  getComprasEmAberto = () =>
    createSelector(this.getComprasArray(), (compras) =>
      compras.filter(
        (compra: ICompra) =>
          compra.tipo_compra == CompraTipoEnum.COMPRAS &&
          compra.compra_status_id != CompraStatusEnum.CANCELADA &&
          compra.compra_status_id != CompraStatusEnum.FINALIZADA &&
          compra.compra_status_id != CompraStatusEnum.ENTREGUE
      )
    );

  getTaxaServico = () =>
    createSelector(
      this.getCompraSelecionada(),
      (compraSelecionada) =>
        compraSelecionada && compraSelecionada.taxa_de_servico
    );

  getPaginator = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CompraState) => state.data.paginator
    );
}

export const compraSelectors = new CompraSelectors();
