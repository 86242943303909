import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResumeComponent } from './resume/resume.component';
import { RippleModule } from '@vip/ui/ripple';
@NgModule({
  imports: [CommonModule, RippleModule],
  declarations: [ResumeComponent],
  exports: [ResumeComponent],
})
export class ResumeModule {}
