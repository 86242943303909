import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[vipLayoutDesktopFavicon]',
})
export class LayoutDesktopFavIconDirective {
  @Input()
  set vipLayoutDesktopFavicon(linkUrl: string | null | undefined) {
    if (!linkUrl) return;

    const existingLink = document.head.querySelector("[href='/favicon.ico']");

    if (existingLink) {
      existingLink.setAttribute('href', linkUrl);
    } else {
      const link = document.createElement('link');
      link.id = 'favicon';
      link.rel = 'icon';
      link.href = linkUrl;
      document.head.appendChild(link);
    }
  }
}
