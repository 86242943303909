import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { LayoutUtilsService } from '@vip/core';

@Component({
  selector: 'vip-cupom',
  templateUrl: './cupom.component.html',
  styleUrls: ['./cupom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(click)': 'isCheckedPropFirstChange = false; checked = !checked',
    '[class.is-clickable]': 'clickable',
    '[class.is-checked]': 'checked && clickable',
    '[class.is-disabled]': 'disabled',
  },
})
export class CupomComponent implements OnChanges {
  @Input() clickable = false;
  @Input() disabled = false;
  @Input() eUnico = true;
  @Input() showCheckIcon = true;
  @Input() created: Date = new Date();
  @Input() validade: Date = new Date();
  @Input() showProgressBar = true;
  @Input() exibirData = false;

  @Output() changed = new EventEmitter();

  constructor(public layoutUtilsService: LayoutUtilsService) {}

  private _checked = false;
  private isCheckedPropFirstChange = true;

  get checked(): boolean {
    return this._checked;
  }

  @Input()
  set checked(value: boolean) {
    if (value !== this._checked && !this.disabled) {
      this._checked = value;
      if (!this.isCheckedPropFirstChange) {
        this.changed.emit(this.checked);
      }
      this.isCheckedPropFirstChange = false;
    }
  }

  get progressValue(): number {
    const validadeTime = this.validade.getTime();
    const createdTime = this.created.getTime();
    const progressValue =
      (new Date().getTime() - createdTime) / (validadeTime - createdTime);
    return isNaN(validadeTime) || isNaN(createdTime) ? 100 : progressValue;
  }

  get now(): Date {
    return new Date();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.checked) {
      this.isCheckedPropFirstChange = changes.checked.firstChange;
    }
  }
}
