import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FACEBOOK_PIXEL } from '@vip/core';
import { environment } from '@vip/core';
import { FilialFacade } from '@vip/state/filial';
import { Meta } from '@angular/platform-browser';
import { FirebaseService } from '@vip/native/firebase';
import { MigrarCdService } from '@vip/state/selecao-cd';
@UntilDestroy()
@Component({
  selector: 'vip-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'site';
  constructor(
    private filialFacade: FilialFacade,
    private _renderer2: Renderer2,
    private meta: Meta,
    private firebaseService: FirebaseService,
    private migrarCdService: MigrarCdService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>
  ) {}

  ngOnInit(): void {
    this.migrarCdService.selecionarCdViaParametro();
    this.setInfoFilial();
    this.firebaseService.requestPermission();
    this.firebaseService.listen();
  }

  private setInfoFilial() {
    this.filialFacade.filial$.pipe(untilDestroyed(this)).subscribe((filial) => {
      this.addFacebookDomainVerification(filial.facebook_domain_verification);
      if (isPlatformBrowser(this.platformId)) {
        this.addRdStation(filial.rd_station);
        this.addFacebookPixel(filial.facebook_pixel);
        this.addGoogleAnalytics(filial.google_analytics);
        this.addScripts(filial.id);
      }
    });
  }

  private addFacebookDomainVerification(
    facebook_domain_verification: string | undefined
  ) {
    if (facebook_domain_verification) {
      this.meta.addTag({
        name: 'facebook-domain-verification',
        content: facebook_domain_verification,
      });
    }
  }

  private addRdStation(rdStation: string | undefined) {
    if (rdStation) {
      this._appendScript(rdStation);
    }
  }

  createElement(script: string) {
    return this.document.createRange().createContextualFragment(script);
  }

  private addFacebookPixel(facebookPixel: string | undefined) {
    if (facebookPixel) {
      const script = FACEBOOK_PIXEL.replace(
        'CODIGO_FACEBOOK_PIXEL',
        facebookPixel
      );
      this._appendScript(script);
    }
  }

  private addScripts(filialId: number) {
    this.filialFacade.getScripts(filialId);
    this.filialFacade.scripts$
      .pipe(untilDestroyed(this))
      .subscribe((scripts) => {
        if (scripts.length > 0) {
          scripts.forEach((script) => {
            script.tag === 'metatag'
              ? this._renderer2.appendChild(
                  this.document.head,
                  this.createElement(script.txt_script)
                )
              : this._appendScript(script.txt_script);
          });
        }
      });
  }

  private _appendScript(script: string, appendTo: keyof Document = 'body') {
    const scriptElement = this.createElement(script);
    if (scriptElement)
      this._renderer2.insertBefore(
        this.document[appendTo],
        scriptElement,
        (this.document[appendTo] as Element).firstChild
      );
  }

  private addGoogleAnalytics(googleAnalytics: string | undefined) {
    if (googleAnalytics) {
      this.appendGoogleRemarketing(googleAnalytics);
    }
  }

  private appendGoogleRemarketing(remarketingTag: string | undefined) {
    if (remarketingTag) {
      const appendTo = remarketingTag.search('gtag') !== -1 ? 'head' : 'body';
      this._appendScript(remarketingTag, appendTo);
    }
  }
}
