import { ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'vip-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RadioButtonComponent {
  private _disabled: boolean | string = false;
  private _checked: boolean | string = false;

  @Input()
  get checked(): boolean | string {
    return this._checked;
  }
  set checked(checked) {
    this._checked = this.getBooleanValue(checked);
    this.checkedChange.emit(checked);
  }

  @Input()
  get disabled(): boolean | string {
    return this._disabled;
  }
  set disabled(disabled) {
    this._disabled = this.getBooleanValue(disabled);
    this.markForCheck();
  }

  @Output() changed = new EventEmitter();
  @Output() checkedChange = new EventEmitter();
  @Input() name = '';
  @Input() value = '';
  @Input() allowUncheck = false;

  @Input() color = 'primary';

  constructor(private changeDetector: ChangeDetectorRef) {}

  onClick(radio: RadioButtonComponent) {
    if (!radio.checked || this.allowUncheck) {
      radio.checked = !radio.checked;
      this.onChange(radio);
    }
  }

  onChange(radio: RadioButtonComponent) {
    this.changed.emit(radio);
  }

  markForCheck() {
    this.changeDetector.markForCheck();
  }
  notifyChange() {
    this.changeDetector.detectChanges();
  }

  getBooleanValue(value: boolean | string): boolean {
    return value != null && `${value}` !== 'false';
  }
}
