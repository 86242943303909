import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodeScannerAreaComponent } from './code-scanner-area/code-scanner-area.component';
import { CodeScannerService } from './code-scanner.service';
import { ScreenTitleModule } from '@vip/ui/screen-title';
import { ButtonModule } from '@vip/ui/button';

@NgModule({
  imports: [CommonModule, ScreenTitleModule, ButtonModule],
  declarations: [CodeScannerAreaComponent],
  exports: [CodeScannerAreaComponent],
  providers: [CodeScannerService],
})
export class CodeScannerModule {}
