import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../common/base-api-service';
import {
  GetListaResponse,
  GetListasResponse,
  ParticiparListaRequest,
  ParticiparListaResponse,
} from './lista-api.service.types';
import { IList, INovaLista } from '@vip/core';

@Injectable({
  providedIn: 'root',
})
export class ListaApiService {
  constructor(private api: BaseApiService<ListaApiService>) {}

  getMinhasListas(filialId: number, cdId: number) {
    return this.api.get<GetListasResponse>(
      'getMinhasListas',
      `${this.api.filialAndCdUrl(
        filialId,
        cdId
      )}/listas/personalizadas/minhas_listas`
    );
  }

  getListasCompartilhadas(filialId: number, cdId: number) {
    return this.api.get<GetListasResponse>(
      'getListasCompartilhadas',
      `${this.api.filialAndCdUrl(
        filialId,
        cdId
      )}/listas/personalizadas/compartilhadas`
    );
  }

  getMinhaLista(filialId: number, cdId: number, listaId: number) {
    return this.api.get<GetListaResponse>(
      'getMinhaLista',
      `${this.api.filialAndCdUrl(
        filialId,
        cdId
      )}/listas/personalizadas/${listaId}`
    );
  }

  criarLista(
    filialId: number,
    cdId: number,
    novaLista: INovaLista
  ): Observable<GetListaResponse> {
    return this.api.post<INovaLista, GetListaResponse>(
      'criarLista',
      `/filial/${filialId}/loja/listas/add`,
      novaLista
    );
  }

  editarLista(filialId: number, cdId: number, lista: IList) {
    return this.api.put<{ nome: string }, GetListaResponse>(
      'criarLista',
      `${this.api.filialAndCdUrl(filialId, cdId)}/listas/edit`,
      lista.lista_id.toString(),
      { nome: lista.nome }
    );
  }

  participarLista(
    filialId: number,
    params?: ParticiparListaRequest
  ): Observable<ParticiparListaResponse> {
    return this.api.post<ParticiparListaRequest, ParticiparListaResponse>(
      'participarLista',
      `/filial/${filialId}/loja/listas/personalizadas/aceitar_convite`,
      params
    );
  }

  excluirLista(filialId: number, listaId: number) {
    return this.api.delete<string>(
      'excluirLista',
      `/filial/${filialId}/loja/listas/delete/${listaId}`
    );
  }
}
