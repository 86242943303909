import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromBanner from './+state/banner.reducer';
import { BannerFacade } from './+state/banner.facade';
import { BannerEffects } from './+state/banner.effect';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromBanner.BANNER_FEATURE_KEY, fromBanner.reducer),
    EffectsModule.forFeature([BannerEffects]),
  ],
  providers: [BannerFacade],
})
export class BannerStateModule {}
