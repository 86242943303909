import { Action, createReducer, on } from '@ngrx/store';

import { ICompraDesconto, IDesconto } from '@vip/core';
import * as DescontoActions from './desconto.actions';
import { IGenericState } from '@vip/state/utils';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

export const DESCONTO_FEATURE_KEY = 'desconto';

export type DescontoState = IGenericState<IDesconto>;

export const descontoAdapter: EntityAdapter<ICompraDesconto> =
  createEntityAdapter<ICompraDesconto>();

export const initialState: DescontoState = {
  data: {
    codigoPromocional: {
      codigo: null,
      descontosCodigoPromocional: [],
      descontoFrete: 0,
    },
    descontos: descontoAdapter.getInitialState(),
  },
  status: 'pending',
  error: null,
};

const descontoReducer = createReducer(
  initialState,
  on(DescontoActions.resetDescontos, () => ({
    ...initialState,
  })),
  on(
    DescontoActions.getDescontos,
    DescontoActions.getDescontosCompra,
    (state) => ({
      ...state,
      status: 'loading',
      error: null,
    })
  ),
  on(
    DescontoActions.getDescontosSuccess,
    DescontoActions.getDescontosCompraSuccess,
    (state, { descontos }) => ({
      ...state,
      data: {
        ...state.data,
        descontos: descontoAdapter.setAll(descontos, state.data.descontos),
      },
      status: 'success',
      error: null,
    })
  ),
  on(
    DescontoActions.getDescontosSuccessFailure,
    DescontoActions.getDescontosCompraFailure,
    (state, { error }) => ({
      ...state,
      status: 'error',
      error: error.message,
    })
  ),
  on(
    DescontoActions.validarCodigoSuccessValido,
    DescontoActions.validarCodigoSuccessInvalido,
    (state, { codigo, descontosValidados }) => ({
      ...state,
      data: {
        codigoPromocional: {
          codigo: codigo ? codigo.toUpperCase() : codigo,
          descontosCodigoPromocional: descontosValidados,
          descontoFrete: 0,
        },
        descontos: descontoAdapter.addMany(
          descontosValidados,
          state.data.descontos
        ),
      },
      status: 'success',
      error: null,
    })
  ),
  on(
    DescontoActions.validarCodigoFreteSuccessValido,
    (state, { codigo, descontosValidados, descontoFrete }) => ({
      ...state,
      data: {
        codigoPromocional: {
          codigo: codigo ? codigo.toUpperCase() : codigo,
          descontosCodigoPromocional: descontosValidados,
          descontoFrete,
        },
        descontos: descontoAdapter.removeAll(state.data.descontos),
      },
      status: 'success',
      error: null,
    })
  ),
  on(DescontoActions.validarCodigoFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error.message,
  })),
  on(DescontoActions.removerCodigo, (state, { codigo }) => ({
    ...state,
    data: {
      ...initialState.data,
      descontos: descontoAdapter.removeMany(
        (entity) => entity.codigo?.toUpperCase() === codigo.toUpperCase(),
        state.data.descontos
      ),
    },
    status: 'success',
    error: null,
  })),
  on(
    DescontoActions.setarSomenteCodigoPromocional,
    (state, { codigoPromocional }) => ({
      ...state,
      data: {
        ...state.data,
        codigoPromocional: {
          ...codigoPromocional,
          codigo: codigoPromocional.codigo
            ? codigoPromocional.codigo.toUpperCase()
            : codigoPromocional.codigo,
        },
      },
      status: 'success',
      error: null,
    })
  )
);

export function reducer(state: DescontoState | undefined, action: Action) {
  return descontoReducer(state, action);
}
