import { NgModule } from '@angular/core';
import { RippleModule } from '@vip/ui/ripple';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox.component';
import { CheckboxPillComponent } from './checkbox-pill/checkbox-pill.component';

@NgModule({
  declarations: [CheckboxComponent, CheckboxPillComponent],
  imports: [CommonModule, RippleModule],
  exports: [CheckboxComponent, CheckboxPillComponent],
})
export class CheckboxModule {}
