import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DropdownAnimation, DropdownItemEvent } from '@vip/ui/dropdown-menu';
import { SelectEvent } from '../../types';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ISelectOptions } from '@vip/core';

@Component({
  selector: 'vip-select',
  templateUrl: './select.component.html',
  styleUrls: [
    './select.component.scss',
    '../../../../../utils/styles/backdrop.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent implements AfterViewChecked, ControlValueAccessor {
  constructor(private changeDetectionRef: ChangeDetectorRef) {}

  @Input()
  message?: string | string[] | { [key: string]: string } = [];

  @Input()
  validations?: { [key: string]: boolean } | null;

  @Input()
  error? = false;

  @Input()
  isDesktop = false;

  @Input()
  showMessage? = false;

  @Input()
  disabled? = false;

  @Input()
  showEmpty = true;

  @Input()
  label? = '';

  @Input()
  placeholder? = '';

  @Output()
  itemSelected: EventEmitter<SelectEvent> = new EventEmitter();

  @Input() options!: ISelectOptions[];

  @Input()
  set value(value: string | number | boolean | undefined) {
    this._currentValue = value;
    this.refreshValue();
  }

  get value(): string | number | boolean | undefined {
    return this._currentValue;
  }

  get animationY(): DropdownAnimation {
    return !this.invertY ? 'top-to-bottom' : 'bottom-to-top';
  }

  @ViewChild('input') input!: ElementRef;
  @ViewChild('dropDown') dropDown!: ElementRef;

  public open = false;
  public invertX = true;
  public invertY = false;
  public inputString = '';
  private _currentValue!: string | number | boolean | undefined;

  getSelectedItem() {
    return this.options.find((it) => it.value == this.value);
  }

  private markSelectedItem() {
    this.options.forEach((it) => {
      it.selected = it.value == this.value;
    });
  }

  private refreshValue() {
    if (this.options) {
      const item = this.getSelectedItem();
      this.inputString = this.value ? item?.text || '' : '';
      this.markSelectedItem();
      this.setSelectedItem(
        this.value,
        this.options.find((it) => it.value == this.value)?.text,
        new DropdownItemEvent('click', this.value)
      );
    }
  }

  setupDropdownPosition() {
    const dropDownContent =
      this.dropDown.nativeElement.querySelector('.dropdown-content');
    const dropDownRect = this.dropDown.nativeElement.getBoundingClientRect();

    const screenWidth = document.documentElement.clientWidth;
    const screenHeight = document.documentElement.clientHeight;
    const dropDownContentWidth = dropDownContent.clientWidth;
    const dropDownContentHeight =
      dropDownContent.querySelector('ul').clientHeight;
    const dropDownLeft = dropDownRect.x;
    const dropDownTop = dropDownRect.y;
    const inputHeight = this.input.nativeElement.clientHeight;

    const fitRight = screenWidth - dropDownLeft > dropDownContentWidth;
    const fitBottom = screenHeight - dropDownTop > dropDownContentHeight;
    const fitTop = dropDownRect.y - dropDownContentHeight >= 0;

    this.invertX = !fitRight;
    this.invertY = !fitBottom && fitTop;

    /* Se o elemento não cabe verticalmente na tela, empurra para cima.
     * caso não caiba horizontalmente, nesse caso é feito via CSS, pois não depende do tamanho do elemento (classe pull-right).
     */
    const offset = this.invertY ? -(dropDownContentHeight + inputHeight) : 0;
    dropDownContent.style.transform = `translateY(${offset}px)`;
  }

  toggle() {
    this.open = !this.open;
    this.input.nativeElement.focus();
  }

  close() {
    this.open = false;
  }

  handleKeyDown(event: KeyboardEvent) {
    let result = false;

    if (event.key) {
      let matchItens: ISelectOptions[] = [];
      const selectItem = (index: number) => {
        this.setSelectedItem(
          matchItens[index].value,
          matchItens[index].text,
          event
        );
      };
      const selectFirst = () => {
        selectItem(0);
      };
      const selectLast = () => {
        selectItem(matchItens.length - 1);
      };
      const selectNext = (index: number) => {
        selectItem(index + 1);
      };
      const selectPrevious = (index: number) => {
        selectItem(index - 1);
      };
      const isLastItem = (index: number) => index == matchItens.length - 1;
      const isFirstItem = (index: number) => index == 0;

      if (event.key.length == 1 && event.key != ' ') {
        matchItens =
          this.options.filter(
            (item) =>
              !item.disabled &&
              item.text.toUpperCase().startsWith(event.key.toUpperCase())
          ) || [];
      } else {
        switch (event.key) {
          case 'ArrowDown':
          case 'ArrowUp':
            matchItens = this.options.filter((item) => !item.disabled) || [];
            break;
          case 'Enter':
            this.toggle();
            break;
          case ' ':
            this.toggle();
            break;
          case 'Escape':
            this.close();
            break;
          case 'Tab':
            result = true;
        }
      }

      if (matchItens.length > 0) {
        const selected = matchItens.findIndex((item) => item.selected);

        if (event.key == 'ArrowUp') {
          if (selected == -1 || isFirstItem(selected)) {
            selectLast();
          } else {
            selectPrevious(selected);
          }
        } else {
          if (selected == -1 || isLastItem(selected)) {
            selectFirst();
          } else {
            selectNext(selected);
          }
        }
      }
    }
    return result;
  }

  private setSelectedItem(
    value: string | number | boolean | undefined,
    text: string | undefined,
    event: KeyboardEvent | DropdownItemEvent
  ) {
    this.writeValue(value);
    this.itemSelected.emit(new SelectEvent('selectItem', value, text, event));
  }

  handleMenuItemClick(event: DropdownItemEvent) {
    if (this.value != event.value) {
      this.setSelectedItem(event.value, event.text, event);
    }
    this.close();
    this.changeDetectionRef.markForCheck();
  }

  handleInputBlur() {
    setTimeout(() => {
      this.close();
    }, 100);
  }

  ngAfterViewChecked() {
    this.setupDropdownPosition();
    this.changeDetectionRef.detectChanges();
  }

  writeValue(value: string | number | boolean | undefined) {
    if (value !== this.value) {
      this.value = value;
      this.onChange(value);
      this.onTouched(value);
    }
  }

  setDisabledState(value: boolean) {
    this.disabled = value;
  }

  onChange = (value: string | number | boolean | undefined) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(
    onChange: (
      value: string | number | boolean | undefined
    ) => string | number | boolean | undefined
  ) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
}
