import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import * as InstitucionalActions from './institucional.actions';
import { institucionalSelectors } from './institucional.selectors';

@Injectable()
export class InstitucionalFacade {
  paginas$ = this.store.pipe(select(institucionalSelectors.getData()));
  paginasSite$ = this.store.pipe(
    select(institucionalSelectors.getPaginasSite())
  );
  paginaPoliticaDePrivacidade$ = this.store.pipe(
    select(institucionalSelectors.getPoliticaDePrivacidadePage())
  );

  constructor(private store: Store) {}

  getPaginasInstitucionais() {
    this.store.dispatch(InstitucionalActions.getPaginasInstitucionais());
  }
}
