<ng-container *ngIf="!isDesktop; else desktopTemplate">
  <vip-pagamento
    class="vip-page-content"
    [ngClass]="{ 'pt-1': isDesktopResponsive }"
    [taxaEntrega]="(taxaEntrega$ | async) ?? undefined"
    [formaPagamentoSelecionada]="(formaPagamentoSelecionada$ | async) || null"
    [cuponsSelecionados]="(cuponsSelecionados$ | async) || []"
    [opcoesFaturamento]="(formaFaturamento$ | async) || null"
    [exibirOpcoesFaturamento]="
      (cliente$ | async)?.tipo_cliente === tipoClienteEnum.PESSOA_JURIDICA
    "
    [opcaoFaturamento]="(opcaoFaturamentoSelecionada$ | async) || null"
    [compraEmProcesso]="compraEmProcesso$ | async"
    [tipoEntregaSelecionado]="tipoEntregaSelecionado$ | async"
    [totalCompra]="(pagamentoService.compraEmProcessoValorFinal$ | async) || 0"
    [valorAcrescimos]="
      (pagamentoService.compraEmProcessoValorAcrescimos$ | async) || 0
    "
    [descontos]="(pagamentoService.compraEmProcessoDescontos$ | async) || []"
    [valorDescontos]="
      (pagamentoService.compraEmProcessoValorDescontos$ | async) || 0
    "
    [produtosComDesconto]="(produtosDesconto$ | async) || []"
    [saldoCashback]="saldoCashback$ | async"
    [taxaServico]="taxaServico$ | async"
    [codigoPromocional]="codigoPromocional$ | async"
    [pagamentos]="(pagamentos$ | async) || []"
    [loading]="(loading$ | async) || false"
    [parcelas]="pagamentoService.parcelas"
    [cartaoSelecionadoId]="cartaoSelecionadoId$ | async"
    [valorTroco]="(valorTroco$ | async) || 0"
    [valorMinimoCashback]="(valorMinimoCashback$ | async) || 0.01"
    [excedeuLimiteTentativas]="(excedeuLimiteTentativas$ | async) ?? false"
    (changedOpcaoFaturamento)="onChangeOpcaoFaturamento($event)"
    (removerCupomSelecionado)="removerCupom($event)"
    (finalizarCompraClick)="
      !isDesktop ? confirmaPagamentoMobile() : confirmarPagamento()
    "
    (changedValorUtilizadoCashback)="utilizarValorCashback($event)"
    (alterarTrocoValue)="setValorTrocoPagamento($event)"
    (voltarClick)="
      pagamentoService.exibirModalAvisoVoltar(
        $event.compraId,
        $event.filialId,
        $event.cdId
      )
    "
    (removerCodigoPromocionalClick)="descontoFacade.removerCodigo($event)"
    (selecionarParcelaClick)="selectParcelaFn($event)"
    (cvvValueChange)="compraEmProcessoFacade.updateCvv($event)"
  >
  </vip-pagamento>
</ng-container>
<ng-template #desktopTemplate>
  <vip-pagamento-desktop
    class="flex h-full"
    [exibeCashback]="exibeCashback$ | async"
    [saldoCashback]="saldoCashback$ | async"
    [disablePagamentoButton]="disablePagamentoButton"
    [pagamentosOnline]="pagamentosOnline$ | async"
    [pagamentosEntrega]="pagamentosEntrega$ | async"
    [loadedPagamentos]="(loadedPagamentos$ | async) ?? false"
    [removerPrepagos]="(removerPrePagos$ | async) || false"
    [valorAcrescimos]="
      (pagamentoService.compraEmProcessoValorAcrescimos$ | async) || 0
    "
    [totalCompra]="(pagamentoService.compraEmProcessoValorFinal$ | async) || 0"
    [logoSrc]="(logo$ | async) || ''"
    [fallbackImageUrl]="(logoFallback$ | async) || ''"
    [url_google]="(filial$ | async)?.site_url | getURLGoogleSafe"
    [formaPagamentoSelecionada]="(formaPagamentoSelecionada$ | async) || null"
    [cartoes]="(cartoes$ | async) || []"
    [permitirSalvarCartao]="(permitirSalvarCartao$ | async) ?? false"
    [compraEmProcesso]="compraEmProcesso$ | async"
    [parcelas]="pagamentoService.parcelas"
    [descontos]="(pagamentoService.compraEmProcessoDescontos$ | async) || []"
    [taxaEntrega]="(taxaEntrega$ | async) ?? undefined"
    [taxaServico]="(taxaServico$ | async) ?? null"
    [cartaoSelecionadoId]="cartaoSelecionadoId$ | async"
    [exibeModalBandeiraNaoAtendida$]="exibeModalBandeiraNaoAtendida$"
    [privateLabelBandeiraAutomatica]="privateLabelBandeiraAutomatica"
    [formaPagamentosOptionsCartaoLoja]="formaPagamentosOptionsCartaoLoja"
    [formasPagamentosPrePagasCartaoLoja]="
      (formasPagamentosPrePagasCartaoLoja$ | async) ?? []
    "
    [formaPagamentosOptionsCartaoCredito]="formaPagamentosOptionsCartaoCredito"
    [formasPagamentosPrePagasCartaoCredito]="
      (formasPagamentosPrePagasCartaoCredito$ | async) ?? []
    "
    [formasPagamentosOptionsVoucher]="formasPagamentosOptionsVoucher"
    [formasPagamentosPrePagasVoucher]="
      (formasPagamentosPrePagasVoucher$ | async) ?? []
    "
    [formasPagamentosOptionsCarteiraDigital]="
      formasPagamentosOptionsCarteiraDigital
    "
    [formasPagamentosCarteiraDigital]="
      (formasPagamentosCarteiraDigital$ | async) ?? []
    "
    [permiteSelecaoManual]="(permiteSelecaoManual$ | async) ?? false"
    [permitirBandeiraAutomatica]="
      (permitirBandeiraAutomatica$ | async) ?? false
    "
    [bandeiraNaoAtendida]="(bandeiraNaoAtendida$ | async) ?? false"
    [cupons]="(cupons$ | async) || []"
    [cuponsSelecionados]="(cuponsSelecionados$ | async) || []"
    [codigoPromocional]="codigoPromocional$ | async"
    [valorDescontos]="
      (pagamentoService.compraEmProcessoValorDescontos$ | async) || 0
    "
    [isLoading]="
      !((loadedPagamentos$ | async) || false) ||
      ((isLoadingCompraEmProcesso$ | async) ?? false) ||
      ((isLoadingCartao$ | async) ?? false)
    "
    [compraEmProcessoError]="!!(compraEmProcessoError$ | async) || false"
    (formaPagamentoChange)="selecionaFormaPagamento($event)"
    (selectParcela)="selectParcelaFn($event)"
    (cvvValueChange)="setCVV($event)"
    (deletarCartao)="deletarCartaoFn($event)"
    (cartaoSalvoChange)="cartaoFacade.setCartaoSelecionado($event)"
    (selectFormaPagamento)="selecionaFormaPagamento($event)"
    (ativarCodigo)="descontoFacade.validarCodigo($event)"
    (deleteCodigoPromocionalClick)="descontoFacade.removerCodigo($event)"
    (changedValorUtilizadoCashback)="utilizarValorCashback($event)"
    (concluirCompraClicked)="concluirCompraDesktop()"
    (buscarBandeiraCartao)="buscaBandeiraCartao($event, true)"
    (changedCuponsSelecionados)="onChangeCuponsSelecionados($event)"
    (continuarComprando)="voltarContinuarComprandoDesktop()"
    (portadorChange)="setPortador($event)"
    (adicionarCartao)="setPortador($event)"
    (setValorTroco)="setValorTrocoPagamento($event)"
    (tabChange)="handleTabChange()"
    [isTelevendas]="isTelevendas$"
  ></vip-pagamento-desktop>
</ng-template>

<input type="hidden" id="fpSessionId" #fpSessionId />

<input type="hidden" id="fpAppId" #fpAppId />

<vip-loading *ngIf="isLoading" textLoading="Carregando"></vip-loading>
