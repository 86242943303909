import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

import {
  ICompra,
  ICompraDTO,
  IMotivoReposicao,
  IPedido,
  IProduto,
  IProdutoCompra,
} from '@vip/core';
import { BaseWsService } from '../common/base-ws-service';
import {
  ConfirmaTrocaDevolucaoResponse,
  GetMotivosResponse,
  GetPedidoResponse,
} from './troca-devolucao.service.types';
import { parseISO } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class TrocaDevolucaoService {
  constructor(private ws: BaseWsService<TrocaDevolucaoService>) {}

  getDetalhesDaCompra(filialId: number, compraId: number): Observable<ICompra> {
    return this.ws
      .get<GetPedidoResponse>(
        'getDetalhesDaCompra',
        `/loja/compras/detalhe-troca-devolucao/${filialId}/${compraId}`
      )
      .pipe(
        pluck('pedido'),
        map((pedido) => this.mapPedidoToCompra(pedido))
      );
  }

  confirmaTrocaDevolucao(
    filialId: number,
    cdId: number,
    params: IPedido
  ): Observable<ConfirmaTrocaDevolucaoResponse> {
    return this.ws.post<IPedido, ConfirmaTrocaDevolucaoResponse>(
      'confirmaTrocaDevolucao',
      `/loja/compras/add/${filialId}/${cdId}`,
      params
    );
  }

  getMotivos(tipo: string): Observable<IMotivoReposicao[]> {
    return this.ws
      .get<GetMotivosResponse>(
        'getMotivos',
        `/loja/compra-reposicao-motivos/index/${tipo}`
      )
      .pipe(pluck('compraReposicaoMotivos'));
  }

  private mapPedidoToCompra(
    pedido: ICompraDTO & {
      produtos_formatados: [IProdutoCompra & { produto: IProduto }];
    }
  ): ICompra {
    return {
      ...pedido,
      created: parseISO(pedido.created),
      data_entrega: parseISO(pedido.data_entrega),
      data_entregue: pedido.data_entregue
        ? parseISO(pedido.data_entregue)
        : null,
      produtos: pedido.produtos_formatados.map((produto) =>
        this.mapProdutoPedidoToProdutoCompra(produto)
      ),
    };
  }

  private mapProdutoPedidoToProdutoCompra(
    compraPedido: IProdutoCompra & { produto: IProduto }
  ): IProdutoCompra {
    return {
      ...compraPedido.produto,
      ...compraPedido,
    };
  }
}
