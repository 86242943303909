import { Injectable } from '@angular/core';
import { IProduto } from '@vip/core';

@Injectable({
  providedIn: 'root',
})
export class CampanhaPatrocinadoService {
  visualizacao(produto: IProduto): void {
    if (produto.anunciado && produto.urls_eventos_ads?.url_visualizacao) {
      const url = produto.urls_eventos_ads.url_visualizacao;
      navigator.sendBeacon(url);
    }
  }

  impressao(produtos: IProduto[]): void {
    produtos.forEach((produto) => {
      if (produto.anunciado && produto.urls_eventos_ads?.url_impressao) {
        const url = produto.urls_eventos_ads.url_impressao;
        navigator.sendBeacon(url);
      }
    });
  }

  clique(produto: IProduto): void {
    if (produto.anunciado && produto.urls_eventos_ads?.url_click) {
      const url = produto.urls_eventos_ads.url_click;
      navigator.sendBeacon(url);
    }
  }
}
