<div class="grid text-center">
  <h2 class="vip-modal-title">Número de Parcelas</h2>
  <h3 class="vip-modal-sub-title">Selecione o número de parcelas desejadas:</h3>
  <vip-select
    data-cy="select-parcelas"
    id="installments"
    class="text-left"
    [label]="'NÚMERO DE PARCELAS'"
    [error]="installmentsControl.touched && installmentsControl.invalid"
    [message]="{ required: 'Campo obrigatório' }"
    [validations]="installmentsControl.errors"
    [formControl]="installmentsControl"
    [options]="parcelas"
    [value]="parcelaSelecionada"
    (itemSelected)="selectParcela($event)"
  >
  </vip-select>
  <vip-button class="mt-4" data-cy="btn-salvar-cashback" (btnClick)="submit()">
    <span>Confirmar</span>
  </vip-button>
</div>
