import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CentroDistribuicaoStateModule } from '@vip/state/centro-distribuicao';
import { CarrinhoEffects } from './+state/carrinho.effects';
import * as fromCarrinho from './+state/carrinho.reducer';
import { CarrinhoFacade } from './+state/carrinho.facade';
import { CarrinhoGuard } from './guards/carrinho.guard';
import { ManterCarrinhoService } from './services/manter-carrinho.service';
import { ConfirmacaoSaidaCarrinhoAntigoGuard } from './guards/carrinho-antigo.guard';

@NgModule({
  imports: [
    CommonModule,
    CentroDistribuicaoStateModule,
    RouterModule,
    StoreModule.forFeature(
      fromCarrinho.CARRINHO_FEATURE_KEY,
      fromCarrinho.carrinhoReducer
    ),
    EffectsModule.forFeature([CarrinhoEffects]),
  ],
  providers: [
    CarrinhoFacade,
    CarrinhoGuard,
    ManterCarrinhoService,
    ConfirmacaoSaidaCarrinhoAntigoGuard,
  ],
})
export class CarrinhoStateModule {}
