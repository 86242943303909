import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetAvaliacoesProdutos } from '.';
import { BaseApiService } from '../common/base-api-service';
import {
  AviseMeProdutoIndisponivelResponse,
  GetComboResponse,
  GetProdutoDetalhesResponse,
  GetProdutosResponse,
  SolicitarProdutoRequest,
  SolicitarProdutoResponse,
} from './produto-api.service.types';

@Injectable({
  providedIn: 'root',
})
export class ProdutoApiService {
  constructor(private api: BaseApiService<ProdutoApiService>) {}

  getProdutosByDepartamento(
    filialId: number,
    cdId: number,
    cmId: number | string,
    filtro: string,
    page: number
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/classificacoes_mercadologicas/departamentos/${cmId}/produtos`;
    return this.api.get<GetProdutosResponse>(
      'getProdutosByDepartamento',
      `${baseUrl}${route}?page=${page}&${filtro}`
    );
  }

  getProdutosByTag(
    filialId: number,
    cdId: number,
    tag: number
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/produtos/tag/${tag}`;
    return this.api.get<GetProdutosResponse>(
      'getProdutosByDepartamento',
      `${baseUrl}${route}`
    );
  }

  getProdutosBySecao(
    filialId: number,
    cdId: number,
    cmId: number | string,
    filtro: string,
    page: number
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/classificacoes_mercadologicas/secoes/${cmId}/produtos/ativos`;

    return this.api.get<GetProdutosResponse>(
      'getProdutosBySecao',
      `${baseUrl}${route}?page=${page}&${filtro}`
    );
  }

  getFavoritos(
    filialId: number,
    cdId: number
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/produtos/favoritos`;
    return this.api.get<GetProdutosResponse>(
      'getFavoritos',
      `${baseUrl}${route}`
    );
  }

  getVitrine(filialId: number, cdId: number): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const filters = '?oferta_exclusiva=false';
    const route = `/vitrines/produtos/disponiveis${filters}`;
    return this.api.get<GetProdutosResponse>(
      'getVitrine',
      `${baseUrl}${route}`
    );
  }

  getVitrinesDedicadas(
    filialId: number,
    cdId: number,
    vitrineId: string
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/vitrines/${vitrineId}/produtos`;
    return this.api.get<GetProdutosResponse>(
      'getVitrinesDedicadas',
      `${baseUrl}${route}`
    );
  }

  getVitrinesEspecificas(
    filialId: number,
    cdId: number,
    vitrinesId: Array<string>
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/vitrines/produtos`;
    let url = `${baseUrl}${route}`;
    url = url + `?vitrine_ids=${vitrinesId.toString()}`;
    return this.api.get<GetProdutosResponse>('getVitrinesEspecificas', url);
  }

  getOfertasExclusivas(
    filialId: number,
    cdId: number
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/ofertas_exclusivas/produtos`;
    return this.api.get<GetProdutosResponse>(
      'getOfertasExclusivas',
      `${baseUrl}${route}`
    );
  }

  getOfertasByTag(
    filialId: number,
    cdId: number,
    tag: string,
    page: number,
    filtro: string
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/ofertas/tag/${tag}/produtos`;
    return this.api.get<GetProdutosResponse>(
      'getOfertasByTag',
      `${baseUrl}${route}?page=${page}${filtro !== '' ? `&${filtro}` : ''}`
    );
  }

  getOfertasById(
    filialId: number,
    cdId: number,
    id: string,
    filtro: string,
    page: number
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/ofertas/${id}/produtos`;
    return this.api.get<GetProdutosResponse>(
      'getOfertasById',
      `${baseUrl}${route}?page=${page}&${filtro}`
    );
  }

  getTodasOfertas(
    filialId: number,
    cdId: number,
    filtro: string,
    page: number
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/produtos/em-oferta`;
    let url = `${baseUrl}${route}?page=${page}`;
    url = filtro !== '' ? url + `&${filtro}` : url;

    return this.api.get<GetProdutosResponse>('getTodasOfertas', url);
  }

  getFavoritosByDepartamento(
    filialId: number,
    cdId: number,
    cmId: number | string,
    filtro: string
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/classificacoes_mercadologicas/departamentos/${cmId}/produtos/favoritos`;
    return this.api.get<GetProdutosResponse>(
      'getFavoritosByDepartamento',
      `${baseUrl}${route}?${filtro}`
    );
  }

  getMaisVendidos(
    filialId: number,
    cdId: number
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/produtos/mais_vendidos`;
    return this.api.get<GetProdutosResponse>(
      'getMaisVendidos',
      `${baseUrl}${route}`
    );
  }

  getColecoes(
    filialId: number,
    cdId: number,
    colecaoId: number,
    filtro?: string,
    page: number = 1
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/colecoes/${colecaoId}/produtos`;
    return this.api.get<GetProdutosResponse>(
      'getColecoes',
      `${baseUrl}${route}?page=${page}&${filtro ? '?' + filtro : ''}`
    );
  }

  solicitarProduto(
    filialId: number,
    params: SolicitarProdutoRequest
  ): Observable<SolicitarProdutoResponse> {
    return this.api.post<SolicitarProdutoRequest, SolicitarProdutoResponse>(
      'solicitarProduto',
      `/filial/${filialId}/loja/produtos/solicitacao/adicionar`,
      params
    );
  }

  getDetalhes(
    filialId: number,
    cdId: number,
    produtoId: number
  ): Observable<GetProdutoDetalhesResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/produtos/${produtoId}/detalhes`;
    return this.api.get<GetProdutoDetalhesResponse>(
      'getDetalhes',
      `${baseUrl}${route}`
    );
  }

  getSimilaresCompare(
    filialId: number,
    cdId: number,
    produtoId: number,
    limit = 10
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/produtos/${produtoId}/similares_comparacao?limit=${limit}`;
    return this.api.get<GetProdutosResponse>(
      'getSimilaresCompare',
      `${baseUrl}${route}`
    );
  }

  getSimilaresCompareDisponiveis(
    filialId: number,
    cdId: number,
    produtoId: number,
    limit = 10
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/produtos/${produtoId}/similares_comparacao/disponiveis?limit=${limit}`;
    return this.api.get<GetProdutosResponse>(
      'getSimilaresCompareDisponiveis',
      `${baseUrl}${route}`
    );
  }

  getLeveTambem(
    filialId: number,
    cdId: number,
    produtoId: number,
    limit = 10
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/produtos/${produtoId}/associados?limit=${limit}`;
    return this.api.get<GetProdutosResponse>(
      'getLeveTambem',
      `${baseUrl}${route}`
    );
  }

  getCombo(
    filialId: number,
    cdId: number,
    produtoId: number
  ): Observable<GetComboResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/produtos/${produtoId}/combos_pertencente`;
    return this.api.get<GetComboResponse>('getCombo', `${baseUrl}${route}`);
  }

  getCombos(
    filialId: number,
    cdId: number,
    produtoId: number
  ): Observable<GetComboResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/produtos/${produtoId}/combos_por_produtos`;
    return this.api.get<GetComboResponse>('getCombo', `${baseUrl}${route}`);
  }

  getAvaliacoes(
    filialId: number,
    produtoId: number
  ): Observable<GetAvaliacoesProdutos> {
    const route = `/filial/${filialId}/loja/produtos/${produtoId}/avaliacoes`;
    return this.api.get<GetAvaliacoesProdutos>('getAvaliacoes', route);
  }

  aviseMeProdutoIndisponivel(
    filialId: number,
    cdId: number,
    produtoId: number,
    params: Record<string, never> = {}
  ): Observable<AviseMeProdutoIndisponivelResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/avisos/produto/${produtoId}/quando_chegar`;
    return this.api.post<
      Record<string, never>,
      AviseMeProdutoIndisponivelResponse
    >('aviseMeProdutoIndisponivel', `${baseUrl}${route}`, params);
  }

  getProdutosPatrocinados(
    filialId: number,
    cdId: number
  ): Observable<GetProdutosResponse> {
    const baseUrl = this.api.filialAndCdUrl(filialId, cdId);
    const route = `/vitrines/anunciados`;
    return this.api.get<GetProdutosResponse>(
      'getProdutosPatrocinados',
      `${baseUrl}${route}`
    );
  }
}
