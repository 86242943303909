export enum PrecoValorTamanhoEnum {
  XS2 = 'text-2xs',
  TINY = 'text-tiny',
  XS = 'text-xs',
  SM = 'text-sm',
  BASE = 'text-base',
  LG = 'text-lg',
  XL = 'text-xl',
  XL2 = 'text-2xl',
  XL3 = 'text-3xl',
  XL4 = 'text-4xl',
}

export enum TamanhoProdutoPrecoEnum {
  PEQUENO = 'pequeno',
  MEDIO = 'medio',
  GRANDE = 'grande',
}
