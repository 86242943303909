import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'vip-selectable-tile',
  templateUrl: './selectable-tile.component.html',
  styleUrls: ['./selectable-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SelectableTileComponent {
  @Input() showIcon = true;
  @Input() checked = false;
  @Input() changeTextColor = false;
  @Input() iconSize: 'medium' | 'large' = 'medium';
  @Input() disableAutoToggle = false;
  @Input() icon?: string;

  @Output() changed = new EventEmitter();

  toggleChecked() {
    if (!this.disableAutoToggle) {
      this.checked = !this.checked;
    }
    this.changed.emit(this.checked);
  }
}
