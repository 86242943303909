<div class="pagina-institucional flex flex-col" *ngIf="pagina">
  <vip-screen-title
    [title]="pagina.titulo"
    leftIcon="icon-arrow_back"
    leftIconColor="secondary"
  >
  </vip-screen-title>

  <vip-carousel
    *ngIf="carousel && carousel.slides.length > 0"
    [ngClass]="{ 'mt-3': isMobile }"
    [slides]="carousel.slides"
    [bannersPerPage]="carousel.configuracoes.itens_por_pagina"
    [wide]="!isMobile"
    [type]="isMobile ? 'sm' : 'md'"
    [removeBlockMargins]="!isMobile"
  >
  </vip-carousel>

  <div
    class="vip-paginas-conteudo flex flex-col break-word"
    [ngClass]="{ 'm-3': isMobile }"
    [attr.data-cy]="'pagina_' + pagina.slug"
    [innerHTML]="
      pagina.conteudo ? sanitizer.bypassSecurityTrustHtml(pagina.conteudo) : ''
    "
  ></div>
</div>
