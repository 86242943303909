import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseWsService } from '../common/base-ws-service';
import {
  BandeiraCartaoResquest,
  GetBandeiraCartaoResponse,
  GetCartoesResponse,
} from './cartoes-ws.types';

@Injectable({
  providedIn: 'root',
})
export class CartoesWsService {
  private cartoesUrl = '/loja/clientes/cartoes';
  private formaPagamentosUrl = '/loja/forma_pagamentos/cd';

  constructor(private ws: BaseWsService<CartoesWsService>) {}

  getCartoesSalvos(
    filialId: number,
    cdId: number,
    tipoEntregaId: number
  ): Observable<GetCartoesResponse> {
    return this.ws.get<GetCartoesResponse>(
      'getCartoesSalvos',
      `${this.cartoesUrl}/filial/${filialId}/centro_distribuicao/${cdId}/tipo_entrega/${tipoEntregaId}`
    );
  }

  buscarBandeiraCartao(
    cdId: number,
    cardNumber: string,
    tipoCartao: 'credito' | 'voucher'
  ): Observable<GetBandeiraCartaoResponse> {
    return this.ws.post<BandeiraCartaoResquest, GetBandeiraCartaoResponse>(
      'buscarBandeiraCartao',
      `${this.formaPagamentosUrl}/${cdId}/tipo_pagamento/${tipoCartao}`,
      { bin: cardNumber }
    );
  }

  deletarCartao(id: string): Observable<GetCartoesResponse> {
    return this.ws.delete<GetCartoesResponse>(
      'deletarCartao',
      `${this.cartoesUrl}/${id}`
    );
  }
}
