import { Pipe, PipeTransform } from '@angular/core';
import { IProdutoCompra } from '../../interfaces';

@Pipe({ name: 'qtdItensProdutos' })
export class QtdItensProdutosPipe implements PipeTransform {
  transform(produtos: IProdutoCompra[]): string {
    let qtdItens = 0;

    produtos.forEach((produto) => {
      qtdItens += produto.quantidade;
    });

    return qtdItens === 1 ? `${qtdItens} item` : `${qtdItens} itens`;
  }
}
