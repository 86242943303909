import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CASHBACK_FEATURE_KEY, CashbackState } from './cashback.reducer';

export const getCashbackState =
  createFeatureSelector<CashbackState>(CASHBACK_FEATURE_KEY);

export const getLoading = createSelector(
  getCashbackState,
  (state: CashbackState) => state.status === 'loading'
);

export const getSaldoCashbackError = createSelector(
  getCashbackState,
  (state: CashbackState) => state.saldoError
);
export const getExtratoCashbackError = createSelector(
  getCashbackState,
  (state: CashbackState) => state.extratoError
);

export const getSaldoCashback = createSelector(
  getCashbackState,
  (state: CashbackState) => state.saldoCashback
);

export const getExtratoCashback = createSelector(
  getCashbackState,
  (state: CashbackState) => state.extrato
);

export const getUltimaAtualizacaoSaldo = createSelector(
  getCashbackState,
  (state: CashbackState) => state.ultimaAtualizacao
);

export const getExibeCashback = createSelector(
  getCashbackState,
  (state: CashbackState) => state.exibeCashback
);

export const getExtratoCompleto = createSelector(
  getCashbackState,
  (state: CashbackState) => state.extratoCompleto
);

export const getAlterarSenhaAtivo = createSelector(
  getCashbackState,
  (state: CashbackState) => state.alterarSenhaAtivo
);

export const getValorMinimo = createSelector(
  getCashbackState,
  (state: CashbackState) => state.valorMinimo
);

export const getLoaded = createSelector(
  getCashbackState,
  (state: CashbackState) =>
    state.status === 'success' || state.status === 'error'
);
