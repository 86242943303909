import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeEcommerceComponent } from './home-ecommerce/home-ecommerce.component';
import { LocationModule } from '@vip/ui/location';
import { HeaderModule } from '@vip/ui/header';
import { CarouselModule } from '@vip/ui/carousel';
import { CarouselCategoryModule } from '@vip/ui/carousel-category';
import { CarouselProductModule } from '@vip/ui/carousel-product';

@NgModule({
  imports: [
    CommonModule,
    LocationModule,
    HeaderModule,
    CarouselModule,
    CarouselCategoryModule,
    CarouselProductModule,
  ],
  declarations: [HomeEcommerceComponent],
  exports: [HomeEcommerceComponent],
})
export class HomeEcommerceModule {}
