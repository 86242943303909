import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { BucketsFacade } from '@vip/state/buckets';
import { ProdutoApiService } from '@vip/api';
import { MultipleProdutoStore } from './produto.store';
import { Observable, combineLatest, of } from 'rxjs';
import { IApiPaginator, IProduto, MultipleProdutoStateData } from '@vip/core';
import { GenericStoreStatusEnum } from '@vip/state/utils';

@Injectable()
export class ProdutosDestaquesStore extends MultipleProdutoStore {
  readonly listaVitrinesDestaques$ = this.select(
    this.listaVitrinesArray$,
    (lista: MultipleProdutoStateData[]) => lista
  );

  readonly getVitrineById = this.effect(
    (payload$: Observable<{ vitrineId: string }>) => {
      return payload$.pipe(
        switchMap(({ vitrineId }) =>
          combineLatest([
            of(vitrineId),
            this.cdFacade.filialECdSelecionado$,
            this.bucketsFacade.bucketProduto$.pipe(
              filter((bucket) => bucket !== '')
            ),
          ])
        ),
        map(([vitrineId, [filial, cdSelecionado], bucket]) => {
          return { vitrineId, filial, cdSelecionado, bucket };
        }),
        mergeMap((payload) => {
          this.updateStatus(GenericStoreStatusEnum.LOADING);

          return this.produtoApiService
            .getVitrine(payload.filial.id, payload.cdSelecionado.id)
            .pipe(
              tapResponse(
                (response) => {
                  this.updateProdutosAndPaginator({
                    id: payload.vitrineId,
                    title: 'Destaques',
                    produtos: response.data,
                    paginator: response.paginator,
                    bucket: payload.bucket,
                  });
                },
                (error) =>
                  this.updateError((error as HttpErrorResponse).error.error)
              )
            );
        })
      );
    }
  );

  constructor(
    private produtoApiService: ProdutoApiService,
    private bucketsFacade: BucketsFacade,
    private cdFacade: CentroDistribuicaoFacade
  ) {
    super();
  }

  readonly updateProdutosAndPaginator = (data: {
    id: string;
    title: string;
    produtos: IProduto[];
    perfil?: boolean;
    paginator?: IApiPaginator;
    bucket?: string;
  }) =>
    this.addListaProdutos([
      {
        id: data.id,
        title: data.title,
        perfil: data.perfil,
        produtos: data.produtos.map((produto) => {
          return this.mapProdutos(produto, data.bucket);
        }),
        paginator: data.paginator || this.initialPaginator,
      },
    ]);
}
