import { Injectable } from '@angular/core';
import { BaseApiService } from '../common/base-api-service';
import { Observable } from 'rxjs';
import {
  BuscarCampanhaIndicacaoDescontoResponse,
  BuscarCampanhaIndicacaoResponse,
  GetCupomResponseDTO,
} from './campanha-indicacao.service.types';
import { ICupomIndicacaoDTO, ICuponsIndicacao } from '@vip/core';
import { parseISO } from 'date-fns';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CampanhaIndicacaoService {
  private campanhaIndicacoesUrl = `loja/campanha-indicacoes`;
  private campanhaIndicacaoDescontoUrl = `loja/campanha-indicacao-descontos`;

  constructor(private api: BaseApiService<CampanhaIndicacaoService>) {}

  getCampanhaIndicacao(
    filialId: number
  ): Observable<BuscarCampanhaIndicacaoResponse> {
    return this.api.get<BuscarCampanhaIndicacaoResponse>(
      'getCampanhaIndicacao',
      `/filial/${filialId}/${this.campanhaIndicacoesUrl}`
    );
  }

  getDescontosIndicacao(filialId: number) {
    return this.api
      .get<GetCupomResponseDTO>(
        'getDescontosIndicacao',
        `/filial/${filialId}/${this.campanhaIndicacaoDescontoUrl}`
      )
      .pipe(
        map((cuponsDTO: GetCupomResponseDTO) => {
          const cupons: BuscarCampanhaIndicacaoDescontoResponse = {
            ...cuponsDTO,
            data: cuponsDTO.data.map((cupomDTO: ICupomIndicacaoDTO) => {
              const cupom: ICuponsIndicacao = {
                ...cupomDTO,
                validade: parseISO(cupomDTO.validade),
                created: parseISO(cupomDTO.created),
              };
              return cupom;
            }),
          };
          return cupons;
        })
      );
  }
}
