import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseComponentSkeletonComponent } from '@vip/ui/skeleton';

@Component({
  selector: 'vip-selectable-card-skeleton',
  templateUrl: './selectable-card-skeleton.component.html',
  styleUrls: ['./selectable-card-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectableCardSkeletonComponent extends BaseComponentSkeletonComponent {}
