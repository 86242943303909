<div
  class="vip-tag-wrapper text-center {{ classeOferta }}"
  [ngClass]="{
    'vip-tag': !value,
    'vip-tag-withValue': value,
    'vip-full-rounded': type === 'full-rounded',
    'vip-small': type === 'small',
    'vip-ex-small': type === 'ex-small',
    'rounded-combo': hasCombo,
    'rounded-combo-desktop': hasCombo && isDesktop
  }"
  [ngStyle]="{ 'background-color': color, 'text-color': textColor }"
  (click)="handleClick($event)"
>
  <div
    [ngStyle]="{ color: textColor }"
    [ngClass]="{ 'vip-tag-text--text-small': isTextSmall }"
    class="vip-tag-text"
  >
    {{ label }}
  </div>
  <div [ngStyle]="{ color: textColor }" class="vip-tag-value">{{ value }}</div>
</div>
