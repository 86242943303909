<div *ngIf="!hasDetailProduct" vipRipple class="product-card w-full mb-2">
  <div class="w-full absolute bottom-1">
    <div
      vipRipple
      class="flex justify-center w-full mt-4 vip-background-fourth-default rounded-full p-1"
    >
      <button
        vipRipple
        [rippleBorderRadius]="'100px'"
        class="flex-1 py-1 text-xs text-center rounded-full w-[50%] transition-colors duration-200"
        [ngClass]="{
          'vip-background-primary-main font-medium text-white': isUnitSelected,
          'vip-background-fourth-default text-black font-normal':
            !isUnitSelected
        }"
        (click)="selectUnit($event)"
      >
        Unidade
      </button>
      <button
        vipRipple
        [rippleBorderRadius]="'100px'"
        class="flex-1 py-1 text-xs text-center rounded-full w-[50%] transition-colors duration-200"
        [ngClass]="{
          'vip-background-primary-main font-medium text-white': !isUnitSelected,
          'vip-background-fourth-default text-black font-normal': isUnitSelected
        }"
        (click)="selectWeight($event)"
      >
        Peso
      </button>
    </div>
  </div>
</div>

<div *ngIf="hasDetailProduct" class="flex w-full gap-2 items-center mb-2">
  <div
    vipRipple
    class="flex items-center justify-center rounded-[3px] h-[44px] pl-[16.17px] cursor-pointer"
    (click)="selectUnit($event)"
    [ngClass]="{
      'vip-background-complementary-main vip-color-primary-main':
        isUnitSelected,
      'vip-color-secondary-default text-normal': !isUnitSelected,
      'border-[2px] border-[vip-color-fourth-default]': !isUnitSelected,
      'md:w-1/2': isTablet,
      'w-1/2': !isTablet
    }"
  >
    <div class="flex gap-2 w-full justify-between">
      <div class="flex items-baseline leading-none">
        <i class="icon-und vip-icons"></i>
        <p
          class="leading-none text-base"
          [ngClass]="{
            'font-medium': isUnitSelected,
            'font-normal': !isUnitSelected
          }"
        >
          Unidade
        </p>
      </div>

      <label class="vip-custom-radio-container">
        <input type="radio" name="option" [class.checked]="isUnitSelected" />
        <span class="vip-radio-checkmark"></span>
      </label>
    </div>
  </div>
  <div
    vipRipple
    class="flex items-center justify-center rounded-[2px] h-[44px] pl-[16.17px] cursor-pointer"
    (click)="selectWeight($event)"
    [ngClass]="{
      'vip-background-complementary-main vip-color-primary-main':
        !isUnitSelected,
      'vip-color-secondary-default text-normal': isUnitSelected,
      'border-[2px] border-[vip-color-fourth-default]': isUnitSelected,
      'md:w-1/2': isTablet,
      'w-1/2': !isTablet
    }"
  >
    <div class="flex gap-2 w-full justify-between">
      <div class="flex items-baseline leading-none">
        <i class="icon-weight vip-icons"></i>
        <p
          class="leading-none text-base"
          [ngClass]="{
            'font-medium': !isUnitSelected,
            'font-normal': isUnitSelected
          }"
        >
          Peso
        </p>
      </div>
      <label class="vip-custom-radio-container">
        <input type="radio" name="option" [class.checked]="!isUnitSelected" />
        <span class="vip-radio-checkmark"></span>
      </label>
    </div>
  </div>
</div>
