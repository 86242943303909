import { UntypedFormGroup } from '@angular/forms';

export function DateValidator(controlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const mesInformado = control.value?.split('/')[0];
    const anoInformado = control.value?.split('/')[1];
    const mesAtual = new Date().getMonth() + 1;
    const anoAtual = new Date().getFullYear();

    if (
      anoInformado?.length === 2
        ? twoNumbersValidation(mesInformado, anoInformado, mesAtual, anoAtual)
        : fourNumbersValidation(mesInformado, anoInformado, mesAtual, anoAtual)
    ) {
      control.setErrors({ invalid: true });
    } else {
      if (control.errors && control.errors['invalid']) {
        delete control.errors['invalid'];
        if (Object.keys(control.errors).length === 0) {
          control.setErrors(null);
        }
      }
    }
  };
}

function fourNumbersValidation(
  mesInformado: string,
  anoInformado: string,
  mesAtual: number,
  anoAtual: number
): boolean {
  const mesInformadoParsed = Number(mesInformado);
  const anoInformadoParsed = Number(anoInformado);
  return (
    (mesInformadoParsed < mesAtual && anoInformadoParsed == anoAtual) ||
    anoInformadoParsed < anoAtual ||
    mesInformadoParsed > 12
  );
}

function twoNumbersValidation(
  mesInformado: string,
  anoInformado: string,
  mesAtual: number,
  anoAtual: number
): boolean {
  const mesInformadoParsed = Number(mesInformado);
  const anoInformadoParsed = Number(anoInformado);
  const anoAtualParsed = Number(String(anoAtual).slice(-2));
  return (
    (mesInformadoParsed < mesAtual && anoInformadoParsed == anoAtualParsed) ||
    anoInformadoParsed < anoAtualParsed ||
    mesInformadoParsed > 12
  );
}
