import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromCompraEmProcessoProvider from './+state/compra-em-processo-provider.reducer';
import { CompraEmProcessoProviderFacade } from './+state/compra-em-processo-provider.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCompraEmProcessoProvider.COMPRA_EM_PROCESSO_PROVIDER_FEATURE_KEY,
      fromCompraEmProcessoProvider.reducer
    ),
  ],
  providers: [CompraEmProcessoProviderFacade],
})
export class CompraEmProcessoProviderModule {}
