import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DialogService, IDialog } from '@vip/ui/modal';
import { ClienteFacade } from '@vip/state/cliente';
import { LayoutUtilsService } from '@vip/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class AuthService {
  readonly logoutDialog: IDialog = {
    open: true,
    title: 'Deseja mesmo sair?',
    subTitle:
      'Ao sair, sua conta será deslogada do nosso aplicativo e terá que refazer o processo de login. Deseja mesmo sair?',
    disabled: false,
    buttonConfirmText: 'Não, permanecer logado',
    buttonCancelText: 'Sim, deslogar minha conta',
  };

  userLoginLogoutChannel?: BroadcastChannel;
  tabsChannel?: BroadcastChannel;

  constructor(
    private dialogService: DialogService,
    private clienteFacade: ClienteFacade,
    public layoutUtilsService: LayoutUtilsService,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.setupStorageEventListener();
      this.setupTabsEventListener();
    }
  }

  get isDesktop(): boolean {
    return this.layoutUtilsService.isDesktop();
  }

  logout() {
    this.dialogService.openDialog(this.logoutDialog);

    this.dialogService.dialogClick.subscribe((value) => {
      if (!value) {
        if (isPlatformBrowser(this.platformId)) {
          this.userLoginLogoutChannel?.postMessage('logout');
        }
        this.clienteFacade.logout();
      }
      this.dialogService.clearDialog();
    });
  }

  private setupStorageEventListener(): void {
    this.userLoginLogoutChannel = new BroadcastChannel('login-logout');
    this.userLoginLogoutChannel.onmessage = (message) => {
      const { data } = message;
      if (data === 'logout') {
        this.clienteFacade.logout();
      } else if (data === 'login') {
        this.clienteFacade.init();
      }
    };
  }

  private setupTabsEventListener(): void {
    this.tabsChannel = new BroadcastChannel('tabs');
    this.tabsChannel.postMessage('first-tab');
    this.tabsChannel.onmessage = (event) => {
      if (event.data === 'first-tab') {
        this.tabsChannel?.postMessage('duplicate-tab');
      }
      if (event.data === 'duplicate-tab') {
        this.clienteFacade.init();
      }
    };
  }
}
