import { SelectorUtil } from '@vip/state/utils';
import {
  INGREDIENTES_RECEITA_FEATURE_KEY,
  IngredientesReceitaState,
} from './ingredientes-receita.reducer';
import { createSelector } from '@ngrx/store';

export class IngredientesReceitaSelectors extends SelectorUtil<IngredientesReceitaState> {
  constructor() {
    super(INGREDIENTES_RECEITA_FEATURE_KEY);
  }

  getItemsAtualizadosENaoAtualizados = () =>
    createSelector(
      this.createFeatureSelector,
      (state: IngredientesReceitaState) => {
        return {
          itensAtualizado: state.data?.itensAtualizado || null,
          itensNaoAtualizados: state.data?.itensNaoAtualizados || null,
        };
      }
    );
}

export const ingredientesReceitaSelectors = new IngredientesReceitaSelectors();
