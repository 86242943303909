import { createAction, props } from '@ngrx/store';
import { AtualizarCarrinhoItemRequest } from '@vip/api';

export const init = createAction('[SelecaoCd/Page] init state');

export const setItemParaAtualizarCarrinho = createAction(
  '[SelecaoCd/Page] set item para atualizar carrinho de cliente',
  props<{
    item: AtualizarCarrinhoItemRequest | AtualizarCarrinhoItemRequest[] | null;
    exibirMensagemAdicionado: boolean;
  }>()
);

export const permitirAtualizarCarrinho = createAction(
  '[SelecaoCd/Page] permitir atualizar carrinho de cliente',
  props<{ permitirAtualizarItemCarrinho: boolean }>()
);

export const setCompraIdArmazenado = createAction(
  '[SelecaoCd/Page] set compra id armazenado de compra',
  props<{ compraIdArmazenado: number | null }>()
);

export const setListaIdArmazenado = createAction(
  '[SelecaoCd/Page] set lista id armazenado de lista',
  props<{ listaIdArmazenado: number | null }>()
);

export const setListaIngredientesIdArmazenado = createAction(
  '[SelecaoCd/Page] set lista id armazenado de lista de ingredientes',
  props<{ listaIngredientesIdArmazenado: number | null }>()
);

export const redirecionarParaLogin = createAction(
  '[SelecaoCd/Page] redirecionar usuário para login'
);
