import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CarrinhoItensEffects } from './+state/carrinho-itens.effects';
import * as fromCarrinhoItens from './+state/carrinho-itens.reducer';
import { CarrinhoItensFacade } from './+state/carrinho-itens.facade';
import { CentroDistribuicaoStateModule } from '@vip/state/centro-distribuicao';
import { ClienteStateModule } from '@vip/state/cliente';
import { ParametrosStateModule } from '@vip/state/parametros';

@NgModule({
  imports: [
    CommonModule,
    CentroDistribuicaoStateModule,
    ClienteStateModule,

    ParametrosStateModule,
    StoreModule.forFeature(
      fromCarrinhoItens.CARRINHO_ITENS_FEATURE_KEY,
      fromCarrinhoItens.reducer
    ),
    EffectsModule.forFeature([CarrinhoItensEffects]),
  ],
  providers: [CarrinhoItensFacade],
})
export class CarrinhoItensStateModule {}
