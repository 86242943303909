import { createSelector } from '@ngrx/store';
import { SelectorUtil } from '@vip/state/utils';
import {
  produtosIndisponiveisAdapter,
  SUBSTITUIR_PRODUTO_FEATURE_KEY,
  SubstituirProdutoState,
} from './substituir-produto.reducer';

const { selectAll, selectEntities } =
  produtosIndisponiveisAdapter.getSelectors();

class SubstituirProdutoSelectors extends SelectorUtil<SubstituirProdutoState> {
  constructor() {
    super(SUBSTITUIR_PRODUTO_FEATURE_KEY);
  }

  getProdutoIndisponivelId = () =>
    createSelector(
      this.createFeatureSelector,
      (state: SubstituirProdutoState) => state.data.produtoIndisponivelId
    );

  getProdutosIndisponiveisArray = () =>
    createSelector(
      this.createFeatureSelector,
      (state: SubstituirProdutoState) =>
        selectAll(state.data.produtosIndisponiveis)
    );

  getProdutosIndisponiveis = () =>
    createSelector(
      this.createFeatureSelector,
      (state: SubstituirProdutoState) =>
        state ? selectEntities(state.data.produtosIndisponiveis) : undefined
    );

  getProdutoIndisponivelSelecionado = () =>
    createSelector(
      this.getProdutosIndisponiveis(),
      this.getProdutoIndisponivelId(),
      (entities, selectedId) =>
        selectedId && entities ? entities[selectedId] : null
    );

  getProdutosSubstitutos = () =>
    createSelector(
      this.createFeatureSelector,
      (state: SubstituirProdutoState) =>
        selectEntities(state.data.produtosSubstitutos)
    );

  getProdutosSubstitutosArray = () =>
    createSelector(
      this.createFeatureSelector,
      (state: SubstituirProdutoState) =>
        selectAll(state.data.produtosSubstitutos)
    );

  getProdutoSubstitutoSelecionado = () =>
    createSelector(
      this.getProdutosSubstitutos(),
      this.getProdutoIndisponivelId(),
      (entities, selectedId) =>
        selectedId && entities ? entities[selectedId] : null
    );
}

export const substituirProdutoSelectors = new SubstituirProdutoSelectors();
