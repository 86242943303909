import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { IS_APP } from '../../constants';
import { fromEvent, Observable, Subscriber, Subscription } from 'rxjs';

const DESKTOP_BREAKPOINT = 1024;
const TABLET_BREAKPOINT_MIN = 768;
const TABLET_BREAKPOINT_MAX = 1024;

@Injectable({
  providedIn: 'root',
})
export class LayoutUtilsService {
  private isDesktopUpdate!: Subscriber<boolean>;
  private isTabletUpdate!: Subscriber<boolean>;

  screenWidth!: number;
  screenHeight!: number;
  resizeObservable$ = fromEvent(window, 'resize');
  resizeSubscription$: Subscription;

  isDesktop$: Observable<boolean>;
  isTablet$: Observable<boolean>;

  constructor(
    @Optional() @Inject(IS_APP) private isApp: boolean,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>
  ) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.isDesktop$ = new Observable((isDesktopUpdate) => {
      this.isDesktopUpdate = isDesktopUpdate;
      this.isDesktopUpdate.next(this.isDesktop());
    });

    this.isTablet$ = new Observable((isTabletUpdate) => {
      this.isTabletUpdate = isTabletUpdate;
      this.isTabletUpdate.next(this.isTablet());
    });

    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      this.screenWidth = (evt.target as Window).innerWidth;
      this.screenHeight = (evt.target as Window).innerHeight;
      this.isDesktopUpdate.next(this.isDesktop());
      this.isTabletUpdate.next(this.isTablet());
    });

    this.isDesktop$.subscribe();
    this.isTablet$.subscribe();
  }

  isMobile() {
    if (this.isApp) {
      return true;
    }
    return this.isMobileUserAgent() || this.screenWidth <= DESKTOP_BREAKPOINT;
  }

  isDesktop() {
    return !this.isApp && this.screenWidth > DESKTOP_BREAKPOINT;
  }

  isTablet() {
    //tablet vertical
    if (this.isApp) {
      return false;
    }
    const isTabletScreenSize =
      this.screenWidth >= TABLET_BREAKPOINT_MIN &&
      this.screenWidth <= TABLET_BREAKPOINT_MAX;
    return isTabletScreenSize || this.isTabletUserAgent();
  }

  isTabletLandscape() {
    //tablet horizontal
    return this.isTablet() && this.screenWidth > this.screenHeight;
  }

  isTabletPortrait() {
    return this.isTablet() && this.screenHeight > this.screenWidth;
  }

  isTabletUserAgent() {
    if (isPlatformServer(this.platformId)) {
      return false;
    }

    const tabletRegex = /iPad|Android(?!.*Mobile)/i;
    return tabletRegex.test(navigator.userAgent || navigator.vendor);
  }

  isMobileUserAgent() {
    if (isPlatformServer(this.platformId)) {
      return false;
    }

    let mobileUserAgent = false;
    // regex feito com base no http://detectmobilebrowsers.com/
    (function (userAgent) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          userAgent
        ) ||
        // eslint-disable-next-line no-useless-escape
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          userAgent.substr(0, 4)
        )
      )
        mobileUserAgent = true;
    })(navigator.userAgent || navigator.vendor);
    return mobileUserAgent;
  }

  isDesktopResponsive() {
    return !this.isApp && this.screenWidth <= DESKTOP_BREAKPOINT;
  }

  isDesktopWithoutScreenWidth() {
    return !this.isApp;
  }

  isIos(): boolean {
    return (
      isPlatformBrowser(this.platformId) &&
      /iP(hone|od|ad)/.test(navigator.platform)
    );
  }
}
