import { Injectable } from '@angular/core';
import { BaseApiService } from '../common/base-api-service';
import {
  AddListaCarrinhoResponse,
  atualizarItensMinhaListaRequest,
  atualizarItensMinhaListaResponse,
  GetItensMinhaListaResponse,
} from './lista-itens-api.service.types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ListaItensApiService {
  constructor(private api: BaseApiService<ListaItensApiService>) {}

  getItensMinhaLista(
    filialId: number,
    cdId: number,
    listaId: number,
    departamento?: number,
    pagina = 1
  ): Observable<GetItensMinhaListaResponse> {
    const filtroDepartamento = departamento
      ? `&departamento=${departamento}`
      : '';

    return this.api.get<GetItensMinhaListaResponse>(
      'getItensMinhaLista',
      `${this.api.filialAndCdUrl(
        filialId,
        cdId
      )}/listas/personalizadas/${listaId}/itens?page=${pagina}${filtroDepartamento}`
    );
  }

  adicionarItensListaNoCarrinho(
    filialId: number,
    cdId: number,
    listaId: number
  ): Observable<AddListaCarrinhoResponse> {
    return this.api.post<{ minha_lista_id: number }, AddListaCarrinhoResponse>(
      'adicionarItensListaNoCarrinho',
      `${this.api.filialAndCdUrl(
        filialId,
        cdId
      )}/carrinhos/incluir_minha_lista`,
      { minha_lista_id: listaId }
    );
  }

  atualizarItensMinhaLista(
    request: atualizarItensMinhaListaRequest,
    filialId: number,
    cdId: number
  ): Observable<atualizarItensMinhaListaResponse> {
    return this.api.post<
      atualizarItensMinhaListaRequest,
      atualizarItensMinhaListaResponse
    >(
      'atualizarItensMinhaLista',
      `${this.api.filialAndCdUrl(
        filialId,
        cdId
      )}/listas/personalizadas/atualizar-produto-lista`,
      request
    );
  }
}
