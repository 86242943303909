import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as ListaItensActions from './lista-itens.actions';
import { GenericStoreStatusEnum, IGenericState } from '@vip/state/utils';
import {
  ICarrinhoItemInconsistencia,
  IProduto,
  IProdutoCarrinho,
  IApiPaginator,
} from '@vip/core';

export const LISTA_ITENS_FEATURE_KEY = 'lista-itens';

export interface ListaItensState extends IGenericState<EntityState<IProduto>> {
  itensAtualizados: IProdutoCarrinho[];
  itensNaoAtualizados: ICarrinhoItemInconsistencia[];
  paginator: IApiPaginator;
}

export const listaItensAdapter: EntityAdapter<IProduto> =
  createEntityAdapter<IProduto>({
    selectId: (listaItens) => listaItens.produto_id,
  });

export const initialState: ListaItensState = {
  data: listaItensAdapter.getInitialState(),
  error: null,
  status: GenericStoreStatusEnum.PENDING,
  itensAtualizados: [],
  itensNaoAtualizados: [],
  paginator: {
    page: 1,
    total_pages: 1,
  },
};

const listaItensReducer = createReducer(
  initialState,
  on(
    ListaItensActions.getItensLista,
    ListaItensActions.editItemLista,
    (state) => ({
      ...state,
      status: GenericStoreStatusEnum.LOADING,
      error: null,
    })
  ),
  on(ListaItensActions.nextPage, (state) => ({
    ...state,
    status: nextPage(state)
      ? GenericStoreStatusEnum.LOADING
      : GenericStoreStatusEnum.SUCCESS,
    paginator: {
      ...state.paginator,
      page: nextPage(state) ? state.paginator.page + 1 : state.paginator.page,
    },
    error: null,
  })),
  on(ListaItensActions.getItensListaSuccess, (state, { itens, paginator }) => ({
    ...state,
    paginator,
    data: listaItensAdapter.setAll(itens, state.data),
    status: GenericStoreStatusEnum.SUCCESS,
    error: null,
  })),
  on(ListaItensActions.addItensListaCarrinho, (state) => ({
    ...state,
    itensAtualizados: initialState.itensAtualizados,
    itensNaoAtualizados: initialState.itensNaoAtualizados,
    status: GenericStoreStatusEnum.LOADING,
    error: null,
  })),
  on(
    ListaItensActions.addItensListaCarrinhoSuccess,
    (state, { itensAtualizados, itensNaoAtualizados }) => ({
      ...state,
      itensAtualizados,
      itensNaoAtualizados,
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
    })
  ),
  on(
    ListaItensActions.getItensListaFailure,
    ListaItensActions.addItensListaCarrinhoFailure,
    ListaItensActions.nextPageFailure,
    ListaItensActions.editItemListaFailure,
    (state, { error }) => ({
      ...state,
      status: GenericStoreStatusEnum.ERROR,
      error: error.error,
    })
  ),
  on(ListaItensActions.resetItensMinhaLista, () => ({
    ...initialState,
  })),
  on(ListaItensActions.nextPageSuccess, (state, { itens, paginator }) => {
    return {
      ...state,
      paginator,
      data: listaItensAdapter.addMany(itens, state.data),
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
    };
  }),
  on(ListaItensActions.editItemListaSuccess, (state, { produto }) => {
    return {
      ...state,
      data: listaItensAdapter.upsertOne(produto, state.data),
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
    };
  }),
  on(
    ListaItensActions.editItemListaDeleteSuccess,
    (state, { produto_id: id }) => {
      return {
        ...state,
        data: listaItensAdapter.removeOne(id, state.data),
        status: GenericStoreStatusEnum.SUCCESS,
        error: null,
      };
    }
  )
);

export function reducer(state: ListaItensState | undefined, action: Action) {
  return listaItensReducer(state, action);
}

function nextPage(state: ListaItensState) {
  return state.paginator.page < state.paginator.total_pages;
}
