import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseWsService } from '../common/base-ws-service';
import {
  GetListaResponse,
  GetParticipantesListaResponse,
} from './lista-ws.types';
import { pluck } from 'rxjs/operators';
import { IList, IListaParticipante } from '@vip/core';

@Injectable({
  providedIn: 'root',
})
export class ListaWsService {
  private listasUrl = '/loja/minha-listas';

  constructor(private ws: BaseWsService<ListaWsService>) {}

  getListaPerSlug(filialId: number, listaSlug: string): Observable<IList> {
    return this.ws
      .get<GetListaResponse>(
        'getListaPerSlug',
        `${this.listasUrl}/view/${listaSlug}`,
        { FilialID: filialId.toString() }
      )
      .pipe(pluck('minhaLista'));
  }

  getParticipantesMinhaLista(
    filialId: number,
    listaId: number
  ): Observable<IListaParticipante[]> {
    return this.ws
      .get<GetParticipantesListaResponse>(
        'getListaPerSlug',
        `/loja/minha-lista-clientes/users/${listaId}`,
        { FilialID: filialId.toString() }
      )
      .pipe(pluck('users'));
  }
}
