import { EnvironmentInterface } from '../../interfaces';

export const environment: EnvironmentInterface = {
  production: true,
  apiServicesUrl: 'https://services-dev.vipcommerce.com.br',
  apiAplicativosUrl: 'https://aplicativos-service.dev.vipcommerce.com.br/v1',
  lojaUser: 'loja',
  lojaAuthJWT:
    'df072f85df9bf7dd71b6811c34bdbaa4f219d98775b56cff9dfa5f8ca1bf8469',
  isApp: false,
  showCaptcha: true,
  horizontalizado: false,
};
