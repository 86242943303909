import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormaEntregaEnum,
  ICentroDistribuicao,
  IEndereco,
  ISwitchOption,
} from '@vip/core';
import { EntregaRetiradaPropertiesBaseDirective } from '../entrega-retirada-properties-base.directive';

@Component({
  selector: 'vip-endereco-entrega-retirada',
  templateUrl: './endereco-entrega-retirada.component.html',
  styleUrls: ['./endereco-entrega-retirada.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnderecoEntregaRetiradaComponent
  extends EntregaRetiradaPropertiesBaseDirective
  implements OnInit
{
  private _entrega = true;
  @Input()
  isPrimeiroProduto = false;
  @Input() novoEnderecoCdSelect = false;

  @Output() informarCepClick = new EventEmitter();
  @Output() cdChange = new EventEmitter();

  get isEntrega(): boolean {
    return this.formaEntregaFromState === FormaEntregaEnum.TIPO_ENTREGA;
  }

  constructor(private changeDetector: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this._entrega = this.formaEntregaFromState == FormaEntregaEnum.TIPO_ENTREGA;
    if (!this.isPrimeiroProduto)
      this.formaEntrega =
        this.enderecos.length > 0
          ? FormaEntregaEnum.TIPO_ENTREGA
          : FormaEntregaEnum.TIPO_RETIRADA;
    this.populateTiposEntregasOptions();
  }

  handleTiposEntregasChanged(option: ISwitchOption): void {
    if (!this.tiposEntregasOptions) return;
    switch (option.key) {
      case FormaEntregaEnum.TIPO_ENTREGA:
        this.formaEntrega = FormaEntregaEnum.TIPO_ENTREGA;
        this.setEntrega(true, false);
        break;
      case FormaEntregaEnum.TIPO_RETIRADA:
        this.formaEntrega = FormaEntregaEnum.TIPO_RETIRADA;
        this.setEntrega(false, false);
        break;
      case FormaEntregaEnum.TIPO_AUTONOMA:
        this.formaEntrega = FormaEntregaEnum.TIPO_AUTONOMA;
        this.setEntrega(false, false);
        break;
    }

    this.formaEntregaChange.emit(this.formaEntrega);
  }

  public setEntrega(isEntrega: boolean, modifyState: boolean) {
    this._entrega = isEntrega;
    this.entregaChange.emit({ isEntrega, modifyState });
  }

  onEnderecoChange(endereco: IEndereco) {
    this.enderecoSelecionado = endereco;
    this.enderecoEntregaChange.emit(endereco);
  }

  onCdChanged(cd: ICentroDistribuicao, isRetirada = false) {
    this.cdSelecionado = cd;
    this.cdChange.emit({ cd, isFromRetirada: isRetirada });
  }

  isSelectableValidateCd(cd: ICentroDistribuicao): boolean {
    return this.isEntrega && cd.id === this.cdSelecionado?.id;
  }
}
