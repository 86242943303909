import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../common/base-api-service';
import { GetFormaPagamentosResponse } from './forma-pagamento-api.service.types';

@Injectable({
  providedIn: 'root',
})
export class FormaPagamentosApiService {
  constructor(private api: BaseApiService<FormaPagamentosApiService>) {}

  getFormaPagamentos(
    filialId: number,
    cdId: number,
    tipoEntregaId: number
  ): Observable<GetFormaPagamentosResponse> {
    const route = `/filial/${filialId}/centro_distribuicao/${cdId}/loja/compras/checkout/forma_pagamentos/tipo_entrega/${tipoEntregaId}`;
    return this.api.get<GetFormaPagamentosResponse>(
      'getFormaPagamentos',
      `${route}`
    );
  }
}
