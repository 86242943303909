<div [class.opacity-50]="disabled" [class.pointer-events-none]="disabled">
  <label *ngIf="label" class="truncate">{{ label }}</label>
  <div
    class="vip-radio-group-items {{ direction }}"
    [ngClass]="{
      wrap: wrap,
      'overflow-visible': visible,
      'overflow-x-scroll': !visible
    }"
  >
    <ng-content></ng-content>
  </div>
</div>
