import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromParametros from './+state/parametros.reducer';
import { ParametrosFacade } from './+state/parametros.facade';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromParametros.PARAMETROS_FEATURE_KEY,
      fromParametros.reducer
    ),
  ],
  providers: [ParametrosFacade],
})
export class ParametrosStateModule {}
