<div class="vip-dropdown-departamentos" (mouseleave)="closeSubmenu()">
  <div class="menu">
    <ul>
      <li
        *ngFor="let departamento of items; let last = last"
        [class.pb-3]="last"
        class="menu-item"
        (mouseenter)="openSubmenu(departamento)"
      >
        <span
          [attr.data-cy]="
            'departamento-item-' + departamento.classificacao_mercadologica_id
          "
          class="flex-1 cursor-pointer"
          (click)="handleItemClick(departamento)"
        >
          {{ departamento.descricao }}
        </span>
        <i
          *ngIf="departamento.children"
          class="icon-chevron_right text-sm cursor-pointer"
          (click)="handleItemClick(departamento)"
        ></i>
      </li>
    </ul>
  </div>
  <vip-dropdown-secoes
    *ngIf="departamentoAberto"
    [items]="departamentoAberto.children || []"
    [hasCategories]="hasCategories"
    (itemClick)="handleItemClick($event)"
    (mouseleave)="closeSubmenu()"
  >
  </vip-dropdown-secoes>
</div>
