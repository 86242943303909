import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { MessageBaseDirective } from '../../directives/message-base.directive';

@Component({
  selector: 'vip-full-color-message',
  templateUrl: './full-color-message.component.html',
  styleUrls: ['./full-color-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.type-info]': 'type === "info"',
    '[class.type-error]': 'type === "error"',
    '[class.type-success]': 'type === "success"',
    '[class.position-top]': 'position === "top"',
    '[class.position-bottom]': 'position === "bottom"',
    '[class.position-neutral]': 'position === "neutral"',
  },
})
export class FullColorMessageComponent extends MessageBaseDirective {
  constructor(elRef: ElementRef, renderer: Renderer2) {
    super(elRef, renderer);
  }
}
