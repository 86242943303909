import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ICliente } from '@vip/core';
import {
  AddClienteRequest,
  ConsultaSolicitacaoSMSResponse,
  EditClienteRequest,
  LoginRequest,
} from '@vip/api';

export const login = createAction(
  '[Login Page] Login',
  props<{ login?: LoginRequest; redirectTo?: string; validarPin?: boolean }>()
);

export const logout = createAction(
  '[Auth/Service] Logout',
  props<{ redirectTo?: string }>()
);

export const logoutSuccess = createAction(
  '[Cliente/WS] Logout Success',
  props<{ redirectTo?: string }>()
);

export const getClienteData = createAction(
  '[Cliente/API] Get Cliente Data',
  props<{ token: string }>()
);

export const loginSuccess = createAction(
  '[Cliente/API] Login Success',
  props<{
    token: string;
    login?: LoginRequest;
    redirectTo?: string;
    validarPin?: boolean;
  }>()
);

export const loginFailure = createAction(
  '[Cliente/API] Login Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getClienteSuccess = createAction(
  '[Cliente/API] Get Cliente Success',
  props<{
    cliente: ICliente;
    token: string;
    requestConsultaSms?: boolean;
    validarPin?: boolean;
  }>()
);

export const getClienteFailure = createAction(
  '[Cliente/API] Get Cliente Failure',
  props<{ error: HttpErrorResponse }>()
);

export const addCliente = createAction(
  '[Cadastro/Page] Cadastrar cliente',
  props<{ addClienteRequest: AddClienteRequest }>()
);

export const addClienteSuccess = createAction(
  '[Cliente/API] Add Cliente Success',
  props<{ cliente: ICliente & { password: string }; token: string }>()
);

export const addClienteFailure = createAction(
  '[Cliente/API] Add Cliente Failure',
  props<{ error: HttpErrorResponse }>()
);

export const editCliente = createAction(
  '[Meus Dados Page] Meus Dados',
  props<{
    editClienteRequest: EditClienteRequest;
    urlToRedirect: string;
  }>()
);

export const editClienteSuccess = createAction(
  '[Cliente/API] Edit Dados Success',
  props<{
    cliente: ICliente;
    urlToRedirect: string;
  }>()
);

export const editClienteFailure = createAction(
  '[Cliente/API] Edit Dados Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getPinParameterSuccess = createAction(
  '[Cliente/API] Get Pin Parameter Success'
);

export const getPinParameterFailure = createAction(
  '[Cliente/API] Get Pin Parameter Failure',
  props<{ error: HttpErrorResponse }>()
);

export const solicitaSMS = createAction(
  '[Cliente/API] Solicita envio de SMS',
  props<{ numeroCelular: string; edicaoNumero?: boolean }>()
);

export const solicitaSMSSucess = createAction(
  '[Cliente/API] Solicitação envio de SMS Sucess',
  props<{ edicaoNumero?: boolean }>()
);

export const solicitaSMSFailure = createAction(
  '[Cliente/API] Solicitação do envio de SMS Failure',
  props<{ error: HttpErrorResponse }>()
);

export const validarOTP = createAction(
  '[Cliente/API] Valida codigo OTP',
  props<{ code: string; msg?: string; redirectTo?: string }>()
);

export const validarOTPSuccess = createAction(
  '[Cliente/API] Valida codigo OTP Success',
  props<{ telefoneCelular: string; msg?: string; redirectTo?: string }>()
);

export const validarOTPFailure = createAction(
  '[Cliente/API] Valida codigo OTP Failure',
  props<{ error: HttpErrorResponse }>()
);

export const solicitaSMSLogin = createAction(
  '[Cliente/API] Solicita envio de SMS para Login',
  props<{ numeroCelular: string; cpf: string }>()
);

export const solicitaSMSLoginSucess = createAction(
  '[Cliente/API] Solicitação envio de SMS Login Sucess',
  props<{ consultaSMS: ConsultaSolicitacaoSMSResponse }>()
);

export const solicitaSMSLoginFailure = createAction(
  '[Cliente/API] Solicitação do envio de SMS Login Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getConsultaSMSSucess = createAction(
  '[Cliente/API] Get Consulta Solicitação SMS Sucess',
  props<{
    consultaSMS: ConsultaSolicitacaoSMSResponse;
    edicaoNumero?: boolean;
  }>()
);

export const getConsultaSMSFailure = createAction(
  '[Cliente/API] Get Consulta Solicitação SMS Failure',
  props<{ error: HttpErrorResponse }>()
);

export const validaPinLogin = createAction(
  '[Cliente/API] Valida Pin Login',
  props<{ numeroCelular: string; cpf: string; codigo: string }>()
);

export const validaPinLoginSucess = createAction(
  '[Cliente/API] Valida Pin Login Sucess',
  props<{ token: string; redirectTo?: string }>()
);

export const validaPinLoginFailure = createAction(
  '[Cliente/API] Valida Pin Login Failure',
  props<{ error: HttpErrorResponse }>()
);
