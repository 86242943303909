<main
  [ngClass]="{
    'pt-[4.688rem]': (scrolling$ | async) && isDesktopResponsive,
    'pt-[6.44rem]': (scrolling$ | async) && !isDesktopResponsive,
    'pt-0': (headerDesktopIsVisible$ | async) === false,
    'fullscreen-iframe': fullscreenIframe
  }"
>
  <vip-header-desktop
    *ngIf="(headerDesktopIsVisible$ | async) !== false"
    [vipLayoutDesktopFavicon]="(faviconBusca$ | async) || ''"
    [options]="options"
    [departamentos]="(departamentos$ | async) || []"
    [logo]="(logo$ | async) || ''"
    [fallbackImageUrl]="(logoFallback$ | async) || ''"
    [cliente]="cliente$ | async"
    [loading]="(loading$ | async) ?? false"
    [termos]="(termos$ | async) || null"
    [produtos]="produtos$ | async"
    [produtosNoCarrinho]="produtosNoCarrinho$ | async"
    [produtosCarrinho]="(itensDepartamento$ | async) || []"
    [produtosFavoritos]="(favoritos$ | async) || []"
    [produtosMaisVendidos]="(maisVendidos$ | async) || []"
    [carrinhoVazio]="false"
    [carrinho]="carrinho$ | async"
    [combosNoCarrinho]="combosNoCarrinho$ | async"
    [isTelevendas]="isTelevendas"
    [qtdItensCarrinho]="(qtdItensCarrinho$ | async) || 0"
    [enderecos]="
      (isLogged$ | async)
        ? (enderecos$ | async) || []
        : novoEndereco
        ? [novoEndereco]
        : []
    "
    [cdsEntrega]="(cdsEntrega$ | async) ?? []"
    [cdsRetirada]="(cdsRetirada$ | async) ?? []"
    [realizaEntrega]="(realizaEntrega$ | async) || false"
    [enderecoSelecionado]="novoEndereco || (enderecoEntrega$ | async)"
    [cdSelecionado]="getCdSelecionado()"
    [formaEntregaFromState]="formaEntrega"
    [possuiLojaAutonoma]="false"
    [isLojaAutonoma]="false"
    [isFromResumoCompra]="false"
    [novoCd]="novoCd"
    [novaFormaEntrega]="novaFormaEntrega"
    [cdSelecionadoProperty]="cdSelecionado"
    [closeDropdownEntregaRetirada$]="
      closeDropdownEntregaRetirada.asObservable()
    "
    [minhaContaOptions]="minhaContaOptions"
    [disableEntregaRetirada]="disableEntregaRetirada"
    [exibeCashback]="(exibeCashback$ | async) ?? false"
    [saldoCashback]="(saldoCashback$ | async) ?? 0"
    [saldoCashbackError]="saldoCashbackError$ | async"
    [quantidadeNaoLida]="(notificationsCount$ | async) ?? 0"
    [isDesktop]="(isDesktop$ | async) ?? false"
    [scrollingShadow]="(scrolling$ | async) ?? false"
    [loadingBuscaRapida]="(loadingBuscaRapida$ | async) ?? false"
    (goLogout)="handleLogout()"
    (enviarFormSolicitarProduto)="enviarFormSolicitarProduto($event)"
    (goToLoginPage)="handleGoLogin()"
    (searchClick)="handleSearchClick()"
    (search)="handleSearchEvent($event)"
    (clickSearchByTerm)="handleClickSearchByTerm($event)"
    (searchMobile)="handleClickSearchByTerm($event.value)"
    (clickProduct)="handleClickProduct($event)"
    (clickSpinChange)="handleClickSpinChange($event)"
    (clickMenu)="handleClickMenu()"
    (showMoreClick)="showMoreClick($event)"
    (produtoChanged)="atualizarCarrinhoService.setItemCarrinho($event)"
    (deleteCarrinhoClick)="deleteCarrinhoClick()"
    (resumeClick)="resumeClick($event)"
    (cadastrarEndereco)="openNovoEnderecoModal()"
    (alterarEndereco)="openAlterarEnderecoModal()"
    (deleteItemClick)="atualizarCarrinhoService.deleteItemCarrinho($event)"
    (addListClick)="addToListContainerService.adicionarNaLista($event)"
    (enderecoEntregaChange)="alterarEnderecoEntrega($event)"
    (cdChange)="onCdChange($event)"
    (formaEntregaChange)="onFormaEntregaChange($event)"
    [aviseMeEnviados]="produtosIndisponiveisService.aviseMeEnviados$ | async"
    (aviseMeClick)="
      produtosIndisponiveisService.sendAviseMeProdutoIndisponivel($event)
    "
    (informarCepClick)="solicitarCep()"
    (encerrarSessaoTelevendas)="encerrarSessaoTelevendas()"
    [filiaisTelevendas]="filiaisTelevendas$ | async"
    [clientesTelevenda]="(clientesTelevenda$ | async) ?? []"
    (searchClickTelevendas)="getTelevendasClientes($event)"
    (itemSelected)="selectClienteTelevenda($event)"
    (selectedFilial)="selectedFilialTelevenda($event)"
    (openModalCepRef)="solicitarCep()"
    (openDesktopCepModal)="solicitarCepCadastro()"
    (encerrarSessaoTelevandas)="encerrarSessaoTelevendas()"
    [loadingTelevendas]="(loadingTelevendas$ | async) || false"
  >
  </vip-header-desktop>
  <section class="container">
    <router-outlet></router-outlet>
  </section>
  <vip-footer
    *ngIf="
      (footerDesktopIsVisible$ | async) !== false &&
      ((isDesktop$ | async) === true ||
        (footerResponsivoIsVisible$ | async) !== false)
    "
    [paginaInstitucionais]="(paginasSite$ | async) || []"
    [redesSociais]="redesSociais"
    [textoFooter]="(filial$ | async)?.texto_rodape"
    [appleId]="(aplicativo$ | async)?.store_config?.apple_id"
    [package]="(aplicativo$ | async)?.store_config?.package"
    [participaSupermercadoOnline]="
      (filial$ | async)?.participa_supermercado_online
    "
    [url_google]="(filial$ | async)?.site_url | getURLGoogleSafe"
    [link_ebit]="(filial$ | async)?.link_ebit"
    [relacionamentoCliente]="relacionamentoCliente$ | async"
  ></vip-footer>
  <vip-cookies></vip-cookies>
  <vip-whats-app></vip-whats-app>
</main>
