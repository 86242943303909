import { Inject, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'valorFrete' })
export class ValorFretePipe implements PipeTransform {
  constructor(@Inject(CurrencyPipe) private currencyPipe: CurrencyPipe) {}

  transform(valor?: number, desconto = 0, textoGratis = 'Grátis') {
    if (typeof valor !== 'number') return 'a calcular';
    return valor - desconto > 0
      ? this.currencyPipe.transform(valor - desconto, 'BRL')
      : textoGratis;
  }
}
