import { createSelector } from '@ngrx/store';
import { BANNER_FEATURE_KEY, BannerState } from './banner.reducer';
import { SelectorUtil } from '@vip/state/utils';

class BannerSelectors extends SelectorUtil<BannerState> {
  constructor() {
    super(BANNER_FEATURE_KEY);
  }

  getBannerReceitas = () =>
    createSelector(
      this.createFeatureSelector,
      (state: BannerState) => state.data.receitas
    );

  getBannerDepartamentos = () =>
    createSelector(
      this.createFeatureSelector,
      (state: BannerState) => state.data.departamentos
    );

  getBannerInstitucionais = () =>
    createSelector(
      this.createFeatureSelector,
      (state: BannerState) => state.data.institucionais
    );

  getBannerColecoes = () =>
    createSelector(
      this.createFeatureSelector,
      (state: BannerState) => state.data.colecoes
    );

  getBannerColecao = () =>
    createSelector(
      this.createFeatureSelector,
      (state: BannerState) => state.data.colecao
    );

  getBannerVitrine = () =>
    createSelector(
      this.createFeatureSelector,
      (state: BannerState) => state.data.vitrine
    );

  getBannerVitrineEmbaixo = () =>
    createSelector(
      this.createFeatureSelector,
      (state: BannerState) => state.data.vitrineEmBaixo
    );

  getBannerOfertas = () =>
    createSelector(
      this.createFeatureSelector,
      (state: BannerState) => state.data.ofertas
    );
}

export const bannerSelectors = new BannerSelectors();
