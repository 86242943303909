import { Action, createReducer, on } from '@ngrx/store';
import { ITermosUso } from '@vip/core';
import * as TermosUsoActions from './termos-uso.actions';
import { IGenericState } from '@vip/state/utils';

export const TERMOS_USO_FEATURE_KEY = 'termos-de-uso';

export interface TermosUsoState extends IGenericState<ITermosUso | null> {
  aprovacaoPendente: boolean | null;
}

export const initialState: TermosUsoState = {
  data: null,
  aprovacaoPendente: false,
  error: null,
  status: 'pending',
};

const termosUsoReducer = createReducer(
  initialState,
  on(
    TermosUsoActions.getTermosUso,
    TermosUsoActions.aprovarTermosUso,
    (state) => ({
      ...state,
      status: 'loading',
      error: null,
    })
  ),
  on(TermosUsoActions.aprovarTermosUsoSuccess, (state) => ({
    ...state,
    status: 'success',
    error: null,
  })),
  on(TermosUsoActions.getTermosUsoSuccess, (state, { TermosUso }) => ({
    ...state,
    data: {
      ...TermosUso,
    },
    status: 'success',
    error: null,
  })),
  on(
    TermosUsoActions.getAprovacaoPendenteSuccess,
    (state, { aprovacaoPendente }) => ({
      ...state,
      aprovacaoPendente: aprovacaoPendente,
      status: 'success',
      error: null,
    })
  ),
  on(
    TermosUsoActions.getTermosUsoFailure,
    TermosUsoActions.aprovarTermosUsoFailure,
    (state, { error }) => ({
      ...state,
      status: 'error',
      error: error.error.error,
    })
  )
);

export function reducer(state: TermosUsoState | undefined, action: Action) {
  return termosUsoReducer(state, action);
}
