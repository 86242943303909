import { FilterTypeEnum } from '../enums/filter-type.enum';
import { IFilter, IFilterOptions } from '../interfaces/filter.interface';

export const filterOrdenacaoMock = {
  label: 'Ordenar por:',
  name: 'ordenacao',
  type: FilterTypeEnum.INTERNAL,
  multiple: false,
  options: [
    {
      label: 'Maior Preço',
      value: 'produto.preco:desc',
      checked: false,
    },
    {
      label: 'Menor Preço',
      value: 'produto.preco:asc',
      checked: false,
    },
    {
      label: 'Mais Vendidos',
      value: 'produto.quantidade_vendida:desc',
      checked: false,
    },
    {
      label: 'Mais Relevantes',
      value: 'relevantes_desc',
      checked: true,
    },
    {
      label: 'A - Z',
      value: 'produto.descricao:asc',
      checked: false,
    },
    {
      label: 'Z - A',
      value: 'produto.descricao:desc',
      checked: false,
    },
  ],
};

export const filter1Mock: IFilter[] = [
  {
    label: 'Seções',
    name: 'secoes',
    type: FilterTypeEnum.EXTERNAL,
    multiple: false,
    options: [
      {
        label: 'Todos',
        value: 'T',
        checked: false,
      },
      {
        label: 'Matinais e Sobremesa',
        value: 'matinais',
        count: 2,
        checked: false,
      },
      {
        label: 'Hortifruti',
        value: 'hortifruti',
        checked: false,
      },
      {
        label: 'Açougue',
        value: 'acougue',
        checked: false,
      },
      {
        label: 'Bolachas e Achocolatados',
        value: 'bolachas',
        checked: false,
      },
    ],
  },
  filterOrdenacaoMock,
  {
    label: 'Categoria:',
    name: 'categoria',
    type: FilterTypeEnum.INTERNAL,
    multiple: true,
    options: [
      {
        label: 'Todos',
        value: 'todos',
      },
      {
        label: 'Recheado',
        value: 'recheado',
      },
      {
        label: 'Água e Sal',
        value: 'agua_e_sal',
        checked: true,
      },
      {
        label: 'Cookie',
        value: 'cookie',
        checked: true,
      },
      {
        label: 'Wafer',
        value: 'wafer',
      },
      {
        label: 'Tortini',
        value: 'tortini',
      },
    ],
  },
  {
    label: 'Marca:',
    name: 'marca',
    type: FilterTypeEnum.INTERNAL,
    multiple: true,
    options: [
      {
        label: 'Bauducco',
        value: 'bauducco',
        checked: true,
      },
      {
        label: 'Aymoré',
        value: 'aymore',
      },
      {
        label: 'Danix',
        value: 'danix',
        checked: true,
      },
      {
        label: 'Oreo',
        value: 'oreo',
        checked: true,
      },
      {
        label: 'Clube Social',
        value: 'clube Social',
      },
      {
        label: 'Passa Tempo',
        value: 'passa Tempo',
      },
      {
        label: 'Piraquê',
        value: 'piraque',
        checked: true,
      },
    ],
  },
  {
    label: 'Peso:',
    name: 'peso',
    type: FilterTypeEnum.INTERNAL,
    multiple: true,
    options: [
      {
        label: 'até 500g',
        value: '0.5',
        checked: true,
      },
      {
        label: 'até 1kg',
        value: '1',
      },
      {
        label: 'até 2kg',
        value: '2',
      },
      {
        label: 'até 3kg',
        value: '3',
      },
      {
        label: 'até 4kg',
        value: '4',
      },
      {
        label: 'até 5kg',
        value: '5',
        checked: true,
      },
      {
        label: 'até 10kg',
        value: '10',
      },
    ],
  },
  {
    label: 'Sabor:',
    name: 'sabor',
    type: FilterTypeEnum.INTERNAL,
    multiple: true,
    options: [
      {
        label: 'Chocolate',
        value: 'chocolate',
        checked: true,
      },
      {
        label: 'Morango',
        value: 'morango',
      },
      {
        label: 'Baunilha',
        value: 'baunilha',
        checked: true,
      },
      {
        label: 'Chocoshake',
        value: 'chocoshake',
      },
      {
        label: 'Doce de Leite',
        value: 'doce_de_leite',
      },
      {
        label: 'Limão',
        value: 'limao',
        checked: true,
      },
    ],
  },
  {
    name: 'tag',
    type: FilterTypeEnum.INTERNAL,
    label: 'Tag',
    multiple: true,
    options: [
      {
        value: '6e241089-0a44-4400-bac7-d480e79dd96e',
        label: 'Produtos de fazenda',
        count: 2,
        checked: false,
        tipo: '',
      },
      {
        value: '1cc5328a-8cc7-420f-8fa0-8dd3067c18eb',
        label: 'FRU',
        count: 9,
        checked: false,
        tipo: 'FRU',
      },
      {
        value: 'c207d275-e031-4eb6-9bc7-12e427d7963b',
        label: 'Tinto',
        count: 42,
        checked: false,
        tipo: 'Tipos de Vinho',
      },
      {
        value: '78a9397b-66c2-4a75-8853-3cb6807489a4',
        label: 'Branco',
        count: 21,
        checked: false,
        tipo: 'Tipos de Vinho',
      },
      {
        value: '147068b8-1a01-4492-9a66-d43b3e8070ef',
        label: 'Vinhos Nacionais',
        count: 6,
        checked: false,
        tipo: 'Vinhos Nacionais',
      },
      {
        value: '23ecd941-088e-4e2e-9dbe-c2b16cd45921',
        label: 'Vinhos Italianos',
        count: 2,
        checked: false,
        tipo: 'Vinhos importados ',
      },
      {
        value: '225b2555-8165-40bd-b161-66223b4228cc',
        label: 'Vinhos Chilenos',
        count: 7,
        checked: false,
        tipo: 'Vinhos importados',
      },
      {
        value: '4efcc66b-8c0b-401a-8482-e5a940e136ba',
        label: 'Vinhos Sul-africanos',
        count: 6,
        checked: false,
        tipo: 'Vinhos importados',
      },
      {
        value: '8636ef8e-33e4-412d-8ddd-f7fb0589ff36',
        label: 'Vinhos Argentinos',
        count: 4,
        checked: false,
        tipo: 'Vinhos importados',
      },
      {
        value: '39e384b5-b541-4e48-b889-8e3e434eaed8',
        label: 'Teste',
        count: 2,
        checked: false,
        tipo: 'teste',
      },
    ],
  },
];

export const ofertasFiltroMock: IFilter[] = [
  {
    name: 'tipo_oferta',
    type: FilterTypeEnum.EXTERNAL,
    label: 'Tipo Oferta',
    multiple: false,
    options: [
      {
        value: '7',
        label: 'Oferta atacarejo',
        checked: false,
      },
      {
        value: '1',
        label: 'Produto com desconto',
        checked: false,
      },
      {
        value: '4',
        label: 'Combo de produtos',
        checked: false,
      },
      {
        value: '5',
        label: 'Leve e pague',
        checked: false,
      },
    ],
  },
];

export const filterClassificacoesMercadologicasMock: IFilter[] = [
  {
    label: 'Departamento',
    name: 'departamento',
    type: FilterTypeEnum.INTERNAL,
    multiple: false,
    options: [
      {
        label: 'Todos',
        value: '0',
        checked: true,
      },
      {
        label: 'Mercearia',
        value: '1',
      },
      {
        label: 'Bebidas',
        value: '2',
      },
      {
        label: 'Pet e jardim',
        value: '3',
      },
      {
        label: 'Açougue',
        value: '4',
      },
    ],
  },
  {
    label: 'Categoria',
    name: 'secao',
    type: FilterTypeEnum.INTERNAL,
    multiple: true,
    options: [
      {
        label: 'Arroz e Feijão',
        value: '10',
        departamento: 1,
      },
      {
        label: 'Bisc.doces e salgados',
        value: '11',
        departamento: 1,
      },
      {
        label: 'Molhos e temperos',
        value: '12',
        departamento: 1,
        count: 10,
      },
      {
        label: 'Massas',
        value: '13',
        departamento: 1,
      },
      {
        label: 'Azeites',
        value: '14',
        departamento: 1,
      },
      {
        label: 'Cereais',
        value: '15',
        departamento: 1,
      },
      {
        label: 'Conservas',
        value: '16',
        departamento: 1,
      },
      {
        label: 'Água',
        value: '17',
        departamento: 2,
        count: 2,
      },
      {
        label: 'Água de coco',
        value: '18',
        departamento: 2,
      },
      {
        label: 'Água saborizada',
        value: '19',
        departamento: 2,
      },
      {
        label: 'Cervejas artesanais',
        value: '20',
        departamento: 2,
        count: 32,
      },
      {
        label: 'Cervejas importadas',
        value: '21',
        departamento: 2,
      },
      {
        label: 'Chás',
        value: '22',
        departamento: 2,
      },
      {
        label: 'Refrigerantes',
        value: '23',
        departamento: 2,
      },
      {
        label: 'Acessórios p/ pets',
        value: '24',
        departamento: 3,
        count: 1,
      },
      {
        label: 'Copos e taças',
        value: '25',
        departamento: 3,
      },
      {
        label: 'Descartáveis',
        value: '26',
        departamento: 3,
      },
      {
        label: 'Bovinas',
        value: '27',
        departamento: 4,
      },
      {
        label: 'Carpaccios',
        value: '28',
        departamento: 4,
      },
      {
        label: 'Frangos',
        value: '29',
        departamento: 4,
      },
      {
        label: 'Frutos do mar',
        value: '30',
        departamento: 4,
      },
      {
        label: 'Suínas',
        value: '31',
        departamento: 4,
      },
      {
        label: 'Processadas',
        value: '32',
        departamento: 4,
      },
    ],
  },
  {
    label: 'Marca',
    name: 'marca',
    type: FilterTypeEnum.INTERNAL,
    multiple: true,
    options: [
      {
        label: 'Bauducco',
        value: 'bauducco',
        checked: true,
      },
      {
        label: 'Aymoré',
        value: 'aymore',
      },
      {
        label: 'Danix',
        value: 'danix',
        checked: true,
      },
      {
        label: 'Oreo',
        value: 'oreo',
        checked: true,
      },
      {
        label: 'Clube Social',
        value: 'clube Social',
      },
      {
        label: 'Passa Tempo',
        value: 'passa Tempo',
      },
      {
        label: 'Piraquê',
        value: 'piraque',
        checked: true,
      },
    ],
  },
  {
    label: 'Peso',
    name: 'peso',
    type: FilterTypeEnum.INTERNAL,
    multiple: true,
    options: [
      {
        label: 'até 500g',
        value: '0.5',
        checked: true,
      },
      {
        label: 'até 1kg',
        value: '1',
      },
      {
        label: 'até 2kg',
        value: '2',
      },
      {
        label: 'até 3kg',
        value: '3',
      },
      {
        label: 'até 4kg',
        value: '4',
      },
      {
        label: 'até 5kg',
        value: '5',
        checked: true,
      },
      {
        label: 'até 10kg',
        value: '10',
      },
    ],
  },
  {
    label: 'Sabor',
    name: 'sabor',
    type: FilterTypeEnum.INTERNAL,
    multiple: true,
    options: [
      {
        label: 'Chocolate',
        value: 'chocolate',
        checked: true,
      },
      {
        label: 'Morango',
        value: 'morango',
      },
      {
        label: 'Baunilha',
        value: 'baunilha',
        checked: true,
      },
      {
        label: 'Chocoshake',
        value: 'chocoshake',
      },
      {
        label: 'Doce de Leite',
        value: 'doce_de_leite',
      },
      {
        label: 'Limão',
        value: 'limao',
        checked: true,
      },
    ],
  },
];

export const filtroOrdenacaoMock: IFilter = {
  label: 'Ordenar por:',
  name: 'orderby',
  type: FilterTypeEnum.INTERNAL,
  multiple: false,
  options: [
    {
      label: 'Maior Preço',
      value: 'produto.preco:desc',
      checked: false,
    },
    {
      label: 'Menor Preço',
      value: 'produto.preco:asc',
      checked: false,
    },
    {
      label: 'Mais Vendidos',
      value: 'produto.quantidade_vendida:desc',
      checked: false,
    },
    {
      label: 'A - Z',
      value: 'produto.descricao:asc',
      checked: false,
    },
    {
      label: 'Z - A',
      value: 'produto.descricao:desc',
      checked: false,
    },
  ],
};

export const orderingFilterOptionsMock: IFilterOptions[] = [
  {
    label: 'Maior Preço',
    value: 'produto.preco:desc',
    checked: false,
  },
  {
    label: 'Menor Preço',
    value: 'produto.preco:asc',
    checked: false,
  },
  {
    label: 'Mais Vendidos',
    value: 'produto.quantidade_vendida:desc',
    checked: false,
  },
  {
    label: 'A - Z',
    value: 'produto.descricao:asc',
    checked: true,
  },
  {
    label: 'Z - A',
    value: 'produto.descricao:desc',
    checked: false,
  },
];

export const marcaFilterMock: IFilter = {
  label: 'Marca:',
  name: 'marca',
  type: FilterTypeEnum.INTERNAL,
  multiple: true,
  options: [
    {
      label: 'Bauducco',
      value: 'bauducco',
      checked: true,
    },
    {
      label: 'Aymoré',
      value: 'aymore',
    },
    {
      label: 'Danix',
      value: 'danix',
      checked: true,
    },
    {
      label: 'Oreo',
      value: 'oreo',
      checked: true,
    },
    {
      label: 'Clube Social',
      value: 'clube Social',
    },
    {
      label: 'Passa Tempo',
      value: 'passa Tempo',
    },
    {
      label: 'Piraquê',
      value: 'piraque',
      checked: true,
    },
  ],
};

export const filtroOrdenacaoCombo: IFilter = {
  label: 'Ordenar por:',
  name: 'orderby',
  type: FilterTypeEnum.INTERNAL,
  multiple: false,
  options: [
    {
      label: 'Maior Preço',
      value: 'produto.preco:desc',
      checked: false,
    },
    {
      label: 'Menor Preço',
      value: 'produto.preco:asc',
      checked: false,
    },
  ],
};
