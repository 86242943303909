import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromOpcaoFaturamento from './+state/opcao-faturamento.reducer';
import { OpcaoFaturamentoEffects } from './+state/opcao-faturamento.effects';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OpcaoFaturamentoFacade } from '..';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromOpcaoFaturamento.OPCAO_FATURAMENTO_FEATURE_KEY,
      fromOpcaoFaturamento.reducer
    ),
    EffectsModule.forFeature([OpcaoFaturamentoEffects]),
  ],
  providers: [OpcaoFaturamentoFacade],
})
export class OpcaoFaturamentoStateModule {}
