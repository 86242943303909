import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { combineLatest } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CarrinhoService, CentroDistribuicaoService } from '@vip/api';
import { ICarrinho, ICentroDistribuicao, IFilial } from '@vip/core';
import { FilialActions, FilialFacade } from '@vip/state/filial';

import * as CentroDistribuicaoActions from './centro-distribuicao.actions';
import { DialogService } from '@vip/ui/modal';
import { CentroDistribuicaoFacade } from './centro-distribuicao.facade';
import { MessageService } from '@vip/ui/message';
import { Location } from '@angular/common';

@Injectable()
export class CentroDistribuicaoEffect {
  getCentrosDistribuicaoRetirada$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CentroDistribuicaoActions.getCentrosDistribuicao),
      (action) => combineLatest([action, this.filialFacade.filial$]),
      fetch({
        run: (action, filial: IFilial) =>
          this.centroDistribuicaoService
            .getCdsRetirada(filial.id, action.cep)
            .pipe(
              map((res) =>
                CentroDistribuicaoActions.getCentrosDistribuicaoSuccess({
                  cds: res.data,
                })
              )
            ),

        onError: (action, error) => {
          return CentroDistribuicaoActions.getCentrosDistribuicaoFailure({
            error,
          });
        },
      })
    )
  );

  cdConfirmado$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CentroDistribuicaoActions.migrarCentroDistribuicaoSuccess),
      fetch({
        run: ({ cd }) => {
          if (cd.loja_autonoma) {
            this.location.back();
          } else {
            const goBackTo = this.activatedRoute.snapshot.queryParams['goBack'];
            if (goBackTo) {
              this.router.navigateByUrl(goBackTo);
            }
          }
        },
      })
    )
  );

  getCentroDistribuicaoPadrao$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilialActions.getFilialPorVipCommerceFilialIdSuccess),
      map((action) =>
        CentroDistribuicaoActions.getCentroDistribuicaoById({
          cdId: action.filial.centro_distribuicao_padrao_id,
        })
      )
    )
  );

  getCentroDistribuicaoById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CentroDistribuicaoActions.getCentroDistribuicaoById),
      fetch({
        run: (action) =>
          this.centroDistribuicaoService
            .getCentroDistribuicaoById(action.cdId)
            .pipe(
              map((res) =>
                CentroDistribuicaoActions.getCentroDistribuicaoByIdSuccess({
                  cd: res.data,
                })
              )
            ),

        onError: (action, error) => {
          return CentroDistribuicaoActions.getCentroDistribuicaoByIdFailure({
            error,
          });
        },
      })
    )
  );

  migrarCentroDistribuicao$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CentroDistribuicaoActions.migrarCentroDistribuicao),
      withLatestFrom(this.filialFacade.filial$),
      fetch({
        run: (action, filial: IFilial) => {
          return this.carrinhoService
            .verificaAlteracaoCdCarrinho(
              filial.id,
              action.cdAtualId,
              action.novoCd.id
            )
            .pipe(
              map(() =>
                CentroDistribuicaoActions.migrarCentroDistribuicaoSuccess({
                  cd: action.novoCd,
                })
              )
            );
        },
        onError: (action, error) => {
          return CentroDistribuicaoActions.migrarCentroDistribuicaoFailure({
            error,
          });
        },
      })
    )
  );

  migrarCentroDistribuicaoFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CentroDistribuicaoActions.migrarCentroDistribuicaoFailure),
        tap((action) => {
          const possuiMensagemDeErroECarrinho =
            action.error.error.error?.message &&
            action.error.error.error?.carrinho;
          if (possuiMensagemDeErroECarrinho) {
            return this.exibirDialogCarrinhoEmOutroCd(
              action.error.error.error.message,
              action.error.error.error.carrinho
            );
          }
          return this.messageService.openErrorMessage(
            action.error.error.error.message,
            1.5
          );
        })
      ),
    { dispatch: false }
  );

  validarCentroDistribuicao$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CentroDistribuicaoActions.validarCentroDistribuicaoAutonomo),
        withLatestFrom(this.centroDistribuicaoFacade.filialECdSelecionado$),
        switchMap(([action, [filial, cdAtual]]) => {
          return this.centroDistribuicaoService
            .validarCentroDistribuicaoAutonomo(filial.id, action.cdId)
            .pipe(
              tap((res) => {
                if (res.compra_processo) {
                  this.confirmarTrocaCdCompraEmProcesso(cdAtual.id, res.cd);
                } else {
                  this.centroDistribuicaoFacade.migrarCentroDistribuicao(
                    cdAtual.id,
                    res.cd
                  );
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  setCentroDistribuicaoDoCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CentroDistribuicaoActions.setCentroDistribuicaoDoCarrinho),
      fetch({
        run: (action) =>
          this.centroDistribuicaoService
            .getCentroDistribuicaoById(action.cdId)
            .pipe(
              map((res) =>
                CentroDistribuicaoActions.setCentroDistribuicaoDoCarrinhoSuccess(
                  {
                    cd: res.data,
                  }
                )
              )
            ),

        onError: (action, error) => {
          return CentroDistribuicaoActions.setCentroDistribuicaoDoCarrinhoFailure(
            {
              error,
            }
          );
        },
      })
    )
  );

  private confirmarTrocaCdCompraEmProcesso(
    cdAtual: number,
    novoCd: ICentroDistribuicao
  ) {
    this.dialogService.openDialog({
      open: true,
      title: 'Compra não finalizada',
      subTitle:
        'Sua compra está em processo de finalização. Deseja cancelar a compra e prosseguir?',
      disabled: false,
      buttonConfirmText: 'Sim',
      buttonCancelText: 'Não',
    });

    this.dialogService.dialogClick.subscribe((value) => {
      if (value) {
        this.centroDistribuicaoFacade.migrarCentroDistribuicao(cdAtual, novoCd);
      }
      this.dialogService.clearDialog();
    });
  }

  exibirDialogCarrinhoEmOutroCd(mensagem: string, carrinho: ICarrinho) {
    this.dialogService.openDialog({
      open: true,
      title: 'Atenção',
      subTitle: mensagem,
      disabled: false,
      buttonConfirmText: 'Ok, entendi',
    });

    this.dialogService.dialogClick.subscribe(async () => {
      await this.dialogService.clearDialog();
      this.centroDistribuicaoFacade.setCentroDistribuicaoDoCarrinho(
        carrinho.centro_distribuicao_id
      );
    });
  }

  constructor(
    private actions$: Actions,
    private filialFacade: FilialFacade,
    private centroDistribuicaoFacade: CentroDistribuicaoFacade,
    private carrinhoService: CarrinhoService,
    private centroDistribuicaoService: CentroDistribuicaoService,
    private dialogService: DialogService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private location: Location
  ) {}
}
