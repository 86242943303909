import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IPerguntaSobreCompra } from '@vip/core';

export const getPerguntas = createAction('[Perguntas Page] Get perguntas');

export const getPerguntasSuccess = createAction(
  '[Perguntas/API] Get Perguntas Success',
  props<{ perguntas: IPerguntaSobreCompra[] }>()
);

export const getPerguntasFailure = createAction(
  '[Perguntas/API] Get Perguntas Failure',
  props<{ error: HttpErrorResponse }>()
);

export const responderPerguntas = createAction(
  '[Perguntas/API] Responder Perguntas',
  props<{ respostas: { [key: number]: string } }>()
);

export const popularRespostasPerguntasCompra = createAction(
  '[Perguntas/API] Popular Respostas Perguntas da Compra',
  props<{ respostas: { [key: number]: string } }>()
);

export const pulouPerguntas = createAction(
  '[Resumo Compra/Page] Pular Perguntas',
  props<{ pulouPerguntas: boolean }>()
);

export const limparRespostas = createAction(
  '[Resumo Compra/Page] Limpar Respostas'
);

export const salvarRespostas = createAction(
  '[Resumo Compra/Page] Salvar Respostas',
  props<{ compraId: number }>()
);

export const salvarRespostasResumo = createAction(
  '[Resumo Compra/Page] Salvar Respostas',
  props<{ compraId: number }>()
);

export const salvarRespostasSuccess = createAction(
  '[Perguntas/API] Salvar Respostas Success',
  props<{ success: boolean }>()
);

export const salvarRespostasFailure = createAction(
  '[Perguntas/API] Salvar Respostas Failure',
  props<{ error: HttpErrorResponse }>()
);

export const responderPerguntasResumo = createAction(
  '[Perguntas/API] Salvar Respostas Resumo',
  props<{ respostas: { [key: number]: string }; idCompra: number }>()
);

export const salvarRespostasResumoSuccess = createAction(
  '[Perguntas/API] Salvar Respostas Resumo Success',
  props<{ success: boolean }>()
);

export const salvarRespostasResumoFailure = createAction(
  '[Perguntas/API] Salvar Respostas Resumo Failure',
  props<{ error: HttpErrorResponse }>()
);

export const fechouPerguntas = createAction(
  '[Resumo Compra/Page] Fechou Perguntas'
);
