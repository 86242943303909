import { NgModule } from '@angular/core';
import { RippleModule } from '@vip/ui/ripple';
import { CommonModule } from '@angular/common';
import { ReceitaCardComponent } from './receita-card/receita-card.component';
import { ImageModule } from '@vip/ui/image';
import { ReceitaCardSkeletonComponent } from './receita-card-skeleton/receita-card-skeleton.component';
import { SkeletonModule } from '@vip/ui/skeleton';

@NgModule({
  imports: [CommonModule, RippleModule, ImageModule, SkeletonModule],
  declarations: [ReceitaCardComponent, ReceitaCardSkeletonComponent],
  exports: [ReceitaCardComponent, ReceitaCardSkeletonComponent],
})
export class ReceitaCardModule {}
