import { SeletorDePesoEnum, urlsMock } from '@vip/core';
import {
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  IOferta,
  IProduto,
  IProdutoCombo,
  ProdutoUtilsService,
  StringUtilsService,
} from '@vip/core';
import { ISpinEvent, ISpinEventComProduto } from '@vip/ui/spin';
import { Router } from '@angular/router';
import { ImageSizesEnum } from '@vip/ui/image';
import { TamanhoProdutoPrecoEnum } from '@vip/ui/produto-preco';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';

@Directive()
export abstract class CardProdutoDirective implements OnChanges {
  imageSizesEnum = ImageSizesEnum;
  TamanhoProdutoPreco = TamanhoProdutoPrecoEnum;
  totalUnidade = 0;
  _produto!: IProduto;
  readonly produtoAnunciadoTagText = 'Patrocinado';
  readonly produtoAnunciadoTagName = 'produto-anunciado';
  mostrarLabelPatrocinado = false;
  produtosNoCarrinho$ = this.carrinhoItensFacade.produtosNoCarrinho$;
  seletorDePesoMedida: number | null | undefined = SeletorDePesoEnum.UNIDADE;
  combosNoCarrinho$ = this.carrinhoItensFacade.combosNoCarrinho$;

  get produto(): IProduto {
    return this._produto;
  }

  @Input()
  set produto(value: IProduto) {
    this._produto = value;
    if (value.total_unidade) this.totalUnidade = value.total_unidade;
    else {
      this.totalUnidade =
        (value.faturamento_unidade ?? value.quantidade_unidade_diferente) *
        (value?.quantidade ?? 1);
    }
  }
  @Input() showTag = true;
  @Input() combo!: IProdutoCombo;
  @Input() produtosNoCarrinho?: Map<
    number,
    { item_id: number; quantidade: number; observacao?: string }
  > | null = null;

  @Input() defaultImageUrl!: string;
  @Input() disableClick = false;
  @Input() showUnavailableMessage = false;
  @Input() adsValueParams: boolean | null = false;
  @Input() seletorDeMedida: number | null | undefined =
    SeletorDePesoEnum.UNIDADE;
  @Input() hiddenSeletor = false;

  @Output() addListClick = new EventEmitter();
  @Output() produtoChanged = new EventEmitter<ISpinEventComProduto>();
  @Output() aviseMeClick = new EventEmitter<number>();

  @Output()
  getClickAds = new EventEmitter<IProduto>();

  constructor(
    public router: Router,
    public produtoUtilsService: ProdutoUtilsService,
    private carrinhoItensFacade: CarrinhoItensFacade
  ) {}

  ngOnChanges(): void {
    this.verificarUrlAtual();
  }

  verificarUrlAtual(): void {
    const urlAtual = this.router.url;
    this.mostrarLabelPatrocinado = urlsMock.test(urlAtual);
  }

  get id(): number | string {
    const { produto_id, item_id, oferta_id } = this.getProduto();
    if (item_id) return item_id;
    if (produto_id)
      return this.produtosNoCarrinho?.get(produto_id)?.item_id || produto_id;
    return oferta_id || 0;
  }

  get observacao(): string {
    return this.produtoUtilsService.getObservacao(
      this.produto,
      this.produtosNoCarrinho
    );
  }

  handleListClick(event: Event) {
    const _produto = {
      ...this.produto,
      seletor_medida_id: this.seletorDePesoMedida
        ? this.seletorDePesoMedida
        : SeletorDePesoEnum.UNIDADE,
    };
    event.stopPropagation();
    this.addListClick.emit(_produto);
  }

  handleSpinAlterado(event: ISpinEvent) {
    event.event.stopPropagation();
    const produto = {
      ...this.getProduto(),
      produto_id: this.produto?.produto_id,
      item_id: this.id,
      observacao: this.observacao,
      seletor_medida_id: event.seletor_medida_id
        ? event.seletor_medida_id
        : this.produto?.seletor_medida_id,
    };

    this.seletorDePesoMedida = event.seletor_medida_id;
    if (event.quantity < 0) event.quantity = 0;
    this.produtoChanged.emit({ ...event, ...produto, produto });
  }

  handleCardClick() {
    this.chamarClickAds();
    if (!this.disableClick) {
      const slug = StringUtilsService.slugify(this.produto.descricao);
      this.router.navigateByUrl(`/produto/${this.produto.produto_id}/${slug}`);
    }
  }

  chamarClickAds() {
    if (!this.mostrarLabelPatrocinado && this.produto.urls_eventos_ads) {
      this.getClickAds.emit(this.produto);
    }
  }

  private getProduto(): (IProdutoCombo | IProduto) & {
    oferta_id?: number;
    oferta?: IOferta | null;
  } {
    return this.combo || this.produto;
  }

  tipoDeMedidaSeletor() {
    if (this.produtosNoCarrinho$) {
      this.produtosNoCarrinho$.subscribe((response) => {
        const productMap = response as Map<number, any>;

        if (this.produto && productMap.has(this.produto.produto_id)) {
          const seletorMedidaId = productMap.get(
            this.produto.produto_id
          ).seletor_medida_id;

          this.produto = {
            ...this.produto,
            seletor_medida_id: seletorMedidaId,
          };

          this.seletorDePesoMedida = this.produto.seletor_medida_id;
        }
      });
    }
  }

  isReceitaRoute(): boolean {
    const currentUrl = window.location.href;
    return currentUrl.includes('/receita/');
  }

  public replaceImageSize(src: string, newSize: string): string {
    const regex = /(\d+x\d+)\//;

    let finalUrl: string;
    if (regex.test(src)) {
      finalUrl = src.replace(regex, `${newSize}/`);
    } else {
      const parts = src.split('/');
      const fileName = parts.pop();
      finalUrl = `${parts.join('/')}/${newSize}/${fileName}`;
    }
    return finalUrl;
  }
}
