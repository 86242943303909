import { createSelector } from '@ngrx/store';
import { CLIENTE_FEATURE_KEY, ClienteState } from './cliente.reducer';
import { SelectorUtil } from '@vip/state/utils';

export class ClienteSelectors extends SelectorUtil<ClienteState> {
  constructor() {
    super(CLIENTE_FEATURE_KEY);
  }

  getCliente = () =>
    createSelector(
      this.createFeatureSelector,
      (state: ClienteState) => state.data.cliente
    );

  isLogged = () =>
    createSelector(
      this.createFeatureSelector,
      (state: ClienteState) => !!state.data.cliente && !!state.data.token
    );

  getSolicitacaoPin = () =>
    createSelector(
      this.createFeatureSelector,
      (state: ClienteState) => state.data.solicitacaoPin
    );
}

export const clienteSelectors = new ClienteSelectors();
