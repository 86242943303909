import { Action, createReducer, on } from '@ngrx/store';
import { IGenericState } from '@vip/state/utils';
import { IOpcaoFaturamento, OpcaoFaturamentoEnum } from '@vip/core';
import * as OpcaoFaturamentoActions from './opcao-faturamento.actions';

export const OPCAO_FATURAMENTO_FEATURE_KEY = 'OpcaoFaturamento';

export interface OpcaoFaturamentoState
  extends IGenericState<IOpcaoFaturamento | null> {
  opcaoFaturamentoSelecionada: string | null;
}

export const initialState: OpcaoFaturamentoState = {
  data: null,
  opcaoFaturamentoSelecionada: null,
  status: 'pending',
  error: null,
};

const OpcaoFaturamentoReducer = createReducer(
  initialState,
  on(OpcaoFaturamentoActions.getOpcaoFaturamento, (state) => ({
    ...state,
    status: 'loading',
    error: null,
  })),
  on(
    OpcaoFaturamentoActions.getOpcaoFaturamentoSuccess,
    (state, opcaoFaturamento: IOpcaoFaturamento) => {
      let selecionada = state.opcaoFaturamentoSelecionada;
      if (!selecionada) {
        if (
          opcaoFaturamento.valorFormaFaturamento !== OpcaoFaturamentoEnum.AMBOS
        ) {
          selecionada = opcaoFaturamento.valorFormaFaturamento;
        } else {
          selecionada = opcaoFaturamento.valorFaturamentoPadrao;
        }
      }
      return {
        ...state,
        opcaoFaturamentoSelecionada: selecionada,
        data: opcaoFaturamento,
      };
    }
  ),
  on(
    OpcaoFaturamentoActions.selectOpcaoFaturamento,
    (state, { opcaoFaturamentoSelecionada }) => ({
      ...state,
      opcaoFaturamentoSelecionada,
    })
  ),
  on(
    OpcaoFaturamentoActions.getOpcaoFaturamentoFailure,
    (state, { error }) => ({
      ...state,
      status: 'error',
      error: error.error.error,
    })
  )
);

export function reducer(
  state: OpcaoFaturamentoState | undefined,
  action: Action
) {
  return OpcaoFaturamentoReducer(state, action);
}
