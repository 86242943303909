import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TermosUsoModalService {
  private openModalTermosUsoSubject = new Subject<void>();

  openModalTermosUso(): void {
    this.openModalTermosUsoSubject.next();
  }

  get openModalTermosUso$(): Observable<void> {
    return this.openModalTermosUsoSubject.asObservable();
  }
}
