import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { map, tap, filter } from 'rxjs/operators';
import * as FilialActions from './filial.actions';
import { FilialApiService, OmnichannelApiService } from '@vip/api';
import { BucketsFacade } from '@vip/state/buckets';
import { ParametrosFacade } from '@vip/state/parametros';
import { ThemeService } from '@vip/ui/theme-provider';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  CacheIdsEnum,
  IBucket,
  IFidelidade,
  IFilial,
  IParametros,
  IS_APP,
  IStorage,
  STORAGE,
  TelevendasTokenService,
} from '@vip/core';
import { Title, Meta } from '@angular/platform-browser';
import { addDays } from 'date-fns';
import { FilialFacade } from './filial.facade';

@Injectable()
export class FilialEffects {
  isBrowser: boolean;
  getFilialPorVipCommerceFilialId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilialActions.getFilialPorVipCommerceFilialId),
      filter(
        (action) =>
          !this.isTelevendas ||
          action.vipCommerceFilialId !=
            this.filialFacade.filial?.vipcommerce_filial_id
      ),
      fetch({
        run: (action) =>
          this.omnichannelApiService
            .getFilialPorVipCommerceFilialId(action.vipCommerceFilialId)
            .pipe(
              map((res) => {
                if (res.success) {
                  this.bucketsFacade.setBuckets(res.data.localizacaoArquivos);
                  this.parametroFacade.setParametros(res.data.parametros);
                  if (this.isApp) {
                    this.storage.setItem(
                      CacheIdsEnum.FILIAL_ID,
                      JSON.stringify({
                        value: res.data,
                        timestamp: addDays(new Date(), 1),
                      })
                    );
                  } else if (this.isBrowser) {
                    localStorage.setItem(
                      CacheIdsEnum.FILIAL_ID,
                      JSON.stringify({
                        value: res.data,
                        timestamp: addDays(new Date(), 1),
                      })
                    );
                  }
                  return FilialActions.getFilialPorVipCommerceFilialIdSuccess({
                    filial: res.data.filial,
                    fidelidade: res.data.fidelidade,
                    possui_loja_autonoma: res.data.possui_loja_autonoma,
                  });
                } else {
                  return this.verificarFilialStorage({
                    status: 200,
                    error: res.error || '',
                  } as HttpErrorResponse);
                }
              })
            ),

        onError: (action, error) => this.verificarFilialStorage(error),
      })
    )
  );

  atualizarMetatagsFilial$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilialActions.getFilialPorVipCommerceFilialIdSuccess),
        tap((action) => {
          const title = action.filial.titulo_site || 'Supermercados Online';
          const description = action.filial.descricao_site || '';
          this.titleService.setTitle(title);
          this.meta.updateTag({ name: 'title', content: title });
          this.meta.updateTag({ name: 'description', content: description });
          this.meta.updateTag({ name: 'og:title', content: title });
          this.meta.updateTag({
            name: 'og:description',
            content: description,
          });
          this.meta.updateTag({ name: 'og:type', content: 'website' });
        })
      ),
    { dispatch: false }
  );

  setTagsOfertasTheme$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilialActions.getFilialPorVipCommerceFilialIdSuccess),
        filter(() => this.isBrowser),
        tap((action) =>
          this.themeService.setupTagOfertasVariables(action.filial)
        )
      ),
    { dispatch: false }
  );

  redirectManutencaoOnFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilialActions.getFilialPorVipCommerceFilialIdFailure),
        tap((action) => {
          this.storage.removeItem('vip-token');
          this.storage.removeItem('cliente-token');
          return this.route.navigateByUrl('manutencao', {
            state: { rota: 'filial', error: action.error.status },
          });
        })
      ),
    { dispatch: false }
  );

  getFilialScripts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilialActions.getFilialScripts),

      fetch({
        run: (action) =>
          this.filialApiService.getFilialScripts(action.filialId).pipe(
            map((res) => {
              if (res.success) {
                return FilialActions.getFilialScriptsSuccess({
                  scripts: res.data,
                });
              }
              return FilialActions.getFilialScriptsFailure({
                error: { error: res.error || '' } as HttpErrorResponse,
              });
            })
          ),
        onError: (action, error) =>
          FilialActions.getFilialScriptsFailure({ error }),
      })
    )
  );

  verificarFilialStorage(error: HttpErrorResponse) {
    let storageData;
    if (this.isApp) {
      storageData = this.storage.getItem(CacheIdsEnum.FILIAL_ID);
    } else if (this.isBrowser) {
      storageData = localStorage.getItem(CacheIdsEnum.FILIAL_ID);
    }
    const filialStorage = storageData
      ? (JSON.parse(storageData) as {
          value: {
            fidelidade: IFidelidade;
            filial: IFilial;
            localizacaoArquivos: IBucket[];
            parametros: IParametros;
            possui_loja_autonoma: boolean;
          };
          timestamp: Date;
        })
      : null;

    if (filialStorage) {
      this.bucketsFacade.setBuckets(filialStorage.value.localizacaoArquivos);
      this.parametroFacade.setParametros(filialStorage.value.parametros);
      return FilialActions.getFilialPorVipCommerceFilialIdSuccess({
        filial: { ...filialStorage.value.filial, fromCache: true },
        fidelidade: filialStorage.value.fidelidade,
        possui_loja_autonoma: filialStorage.value.possui_loja_autonoma,
      });
    } else {
      return FilialActions.getFilialPorVipCommerceFilialIdFailure({
        error,
      });
    }
  }

  get isTelevendas(): boolean {
    return (
      !!this.televendasTokenService.getToken() &&
      this.televendasTokenService.getOperador() !== null
    );
  }

  constructor(
    private actions$: Actions,
    private bucketsFacade: BucketsFacade,
    private parametroFacade: ParametrosFacade,
    private omnichannelApiService: OmnichannelApiService,
    private themeService: ThemeService,
    private route: Router,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    @Inject(STORAGE) readonly storage: IStorage,
    private titleService: Title,
    private meta: Meta,
    private filialApiService: FilialApiService,
    private filialFacade: FilialFacade,
    private televendasTokenService: TelevendasTokenService,
    @Inject(IS_APP) private isApp: boolean
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }
}
