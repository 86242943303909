import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { bannerSelectors } from './banner.selector';
import * as BannerActions from './banner.actions';

import { BannerLocalEnum } from '@vip/core';

@Injectable()
export class BannerFacade {
  bannersReceitas$ = this.store.pipe(
    select(bannerSelectors.getBannerReceitas())
  );
  bannersDepartamentos$ = this.store.pipe(
    select(bannerSelectors.getBannerDepartamentos())
  );
  bannersInstitucionais$ = this.store.pipe(
    select(bannerSelectors.getBannerInstitucionais())
  );
  bannersVitrine$ = this.store.pipe(select(bannerSelectors.getBannerVitrine()));
  bannersVitrineEmBaixo$ = this.store.pipe(
    select(bannerSelectors.getBannerVitrineEmbaixo())
  );
  bannersColecoes$ = this.store.pipe(
    select(bannerSelectors.getBannerColecoes())
  );
  bannersOfertas$ = this.store.pipe(select(bannerSelectors.getBannerOfertas()));
  bannersColecao$ = this.store.pipe(select(bannerSelectors.getBannerColecao()));
  loading$ = this.store.pipe(select(bannerSelectors.isLoading()));

  constructor(private store: Store) {}

  getBannersReceitas() {
    this.store.dispatch(
      BannerActions.loadBanner({
        local: BannerLocalEnum.RECEITAS,
        stateKey: 'receitas',
      })
    );
  }

  getBannersColecoes() {
    this.store.dispatch(
      BannerActions.loadBanner({
        local: BannerLocalEnum.COLECAO,
        stateKey: 'colecoes',
      })
    );
  }

  getBannersColecao(colecaoId: number) {
    this.store.dispatch(
      BannerActions.loadBanner({
        local: BannerLocalEnum.COLECAO,
        stateKey: 'colecao',
        filtro: `colecoes.id=${colecaoId}`,
      })
    );
  }

  getBannersDepartamentos(cmId?: number) {
    this.store.dispatch(
      BannerActions.loadBanner({
        local: BannerLocalEnum.CLASSIFICACAO_MERCADOLOGICA,
        stateKey: 'departamentos',
        filtro: `cm.id=${cmId}`,
      })
    );
  }

  getBannersInstitucionais(slug?: string) {
    const filtro = `paginasexibidas.slug=${slug}`;

    this.store.dispatch(
      BannerActions.loadBanner({
        local: BannerLocalEnum.PAGINAS_INSTITUCIONAIS,
        stateKey: 'institucionais',
        filtro,
      })
    );
  }

  getBannersVitrineSuperior() {
    this.store.dispatch(
      BannerActions.loadBanner({
        local: BannerLocalEnum.VITRINE_SUPERIOR,
        stateKey: 'vitrine',
        filtro: ``,
      })
    );
  }

  getBannersVitrineInferior() {
    this.store.dispatch(
      BannerActions.loadBanner({
        local: BannerLocalEnum.VITRINE_INFERIOR,
        stateKey: 'vitrineEmBaixo',
        filtro: ``,
      })
    );
  }

  getBannersOfertas() {
    this.store.dispatch(
      BannerActions.loadBanner({
        local: BannerLocalEnum.OFERTAS,
        stateKey: 'ofertas',
        filtro: '',
      })
    );
  }

  addClique(id: number) {
    this.store.dispatch(BannerActions.addClique({ id }));
  }

  addCliqueHomeOmni(id: number) {
    this.store.dispatch(BannerActions.addCliqueHomeOmni({ id }));
  }
}
