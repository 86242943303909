import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { IMAGES_PATH } from '@vip/core';
import { DialogService } from '@vip/ui/modal';
import { PagamentoCuponsBaseDirective } from '@vip/views/pagamento';

@Component({
  selector: 'vip-codigo-promocional-opcao-pagamento',
  templateUrl: './codigo-promocional-opcao-pagamento.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodigoPromocionalOpcaoPagamentoComponent extends PagamentoCuponsBaseDirective {
  imageUrl: string;
  constructor(
    dialogService: DialogService,
    @Inject(IMAGES_PATH) private imagesPath: string
  ) {
    super(dialogService);
    this.imageUrl = `${this.imagesPath}price-tag.svg`;
  }

  ativarCodigoClick(): void {
    if (this.codigoPromocionalControl.invalid) {
      this.codigoPromocionalControl.markAsTouched();
      return;
    }
    this.ativarCodigo.emit(this.codigoPromocionalControl.value);
  }
}
