<div class="flex items-center vip-color-first-default">
  <span [class.font-bold]="isNegrito" class="{{ tamanho }}" data-cy="preco">
    {{ preco | currency: 'BRL' }}
  </span>
  <span
    [ngClass]="marginClassUnidade"
    class="{{ tamanhoUnidade }}"
    data-cy="unidade"
  >
    /{{ quantidadeUnidade | unidadeDiferente: siglaUnidade }}
  </span>
</div>
