import { Action, createReducer, on } from '@ngrx/store';
import * as InstitucionalActions from './institucional.actions';
import { IPaginaInstitucional } from '@vip/core';
import { IGenericState } from '@vip/state/utils';

export const INSTITUCIONAL_FEATURE_KEY = 'institucional';

export type InstitucionalState = IGenericState<IPaginaInstitucional[]>;

export const initialState: InstitucionalState = {
  data: [],
  error: null,
  status: 'pending',
};

const institucionalReducer = createReducer(
  initialState,
  on(InstitucionalActions.getPaginasInstitucionais, (state) => ({
    ...state,
    status: 'loading',
  })),
  on(
    InstitucionalActions.getPaginasInstitucionaisSuccess,
    (state, { paginas }) => ({
      ...state,
      data: [...paginas],
      status: 'success',
    })
  ),
  on(
    InstitucionalActions.getPaginasInstitucionaisFailure,
    (state, { error }) => ({
      ...state,
      status: 'error',
      error,
    })
  )
);

export function reducer(state: InstitucionalState | undefined, action: Action) {
  return institucionalReducer(state, action);
}
