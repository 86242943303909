import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import * as OpcaoFaturamentoActions from './opcao-faturamento.actions';
import { BaseApiResponse, OpcaoFaturamentoApiService } from '@vip/api';
import { ICentroDistribuicao, IFilial, IOpcaoFaturamento } from '@vip/core';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { combineLatest } from 'rxjs';

@Injectable()
export class OpcaoFaturamentoEffects {
  getOpcaoFaturamento$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpcaoFaturamentoActions.getOpcaoFaturamento),
      (action) => combineLatest([action, this.cdFacade.filialECdSelecionado$]),
      fetch({
        run: (
          _action,
          [filial, cdSelecionado]: [IFilial, ICentroDistribuicao]
        ) =>
          this.opcaoFaturamentoApiService
            .getOpcaoFaturamento(filial.id, cdSelecionado.id)
            .pipe(
              map((res: BaseApiResponse<IOpcaoFaturamento>) =>
                OpcaoFaturamentoActions.getOpcaoFaturamentoSuccess(res.data)
              )
            ),
        onError: (_action, error) =>
          OpcaoFaturamentoActions.getOpcaoFaturamentoFailure({ error }),
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private opcaoFaturamentoApiService: OpcaoFaturamentoApiService,
    private cdFacade: CentroDistribuicaoFacade
  ) {}
}
