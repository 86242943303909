import { Injectable } from '@angular/core';
import {
  BuscarColecaoResponse,
  BuscarColecoesResponse,
} from './colecao.service.types';
import { BaseApiService } from '../common/base-api-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ColecaoApiService {
  private colecaoUrl = `loja/colecoes`;

  constructor(private api: BaseApiService<ColecaoApiService>) {}

  getColecoes(
    filialId: number,
    cdId: number
  ): Observable<BuscarColecoesResponse> {
    return this.api.get<BuscarColecoesResponse>(
      'getColecoes',
      `/filial/${filialId}/centro_distribuicao/${cdId}/${this.colecaoUrl}/vigentes`
    );
  }

  getColecao(colecaoId: number): Observable<BuscarColecaoResponse> {
    return this.api.get<BuscarColecaoResponse>(
      'getColecao',
      `/${this.colecaoUrl}/${colecaoId}`
    );
  }
}
