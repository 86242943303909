import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IFormaPagamento, IPagamento, IPagamentosViewModel } from '@vip/core';

export const getFormaPagamentos = createAction(
  '[FormasPagamento Page] Get FormaPagamentos'
);

export const getFormaPagamentosSuccess = createAction(
  '[FormasPagamento/API] Get FormaPagamentos Success',
  props<{
    formaPagamentos: IFormaPagamento[];
    pagamentosOnline: IPagamento;
    pagamentoEntrega: IPagamento;
    pagamentos: IPagamentosViewModel;
    removerPrepagos: boolean;
  }>()
);

export const getFormaPagamentosFailure = createAction(
  '[FormasPagamento/API] Get FormaPagamentos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const selectFormaPagamento = createAction(
  '[Select Effects] Select FormaPagamento',
  props<{ formaPagamentoId: number }>()
);

export const resetFormaPagamentoSelecionada = createAction(
  '[Reset Effects] reset FormaPagamento selecionada'
);

export const setValorTroco = createAction(
  '[FormasPagamento/Troco] Definir valor para Troco',
  props<{ valorTroco: number }>()
);

export const buscarBandeiraCartao = createAction(
  '[FormasPagamento Page] Buscar Bandeira Cartao',
  props<{
    cardNumber: string;
    makeRequestAgain?: boolean;
    tipoCartao: 'credito' | 'voucher';
    dontChangeStateStatus?: boolean;
  }>()
);

export const buscarBandeiraCartaoSuccess = createAction(
  '[FormasPagamento/API] Buscar Bandeira Cartao Success',
  props<{
    formaPagamento: IFormaPagamento;
    permiteSelecaoManual: boolean;
  }>()
);

export const buscarBandeiraCartaoFailure = createAction(
  '[FormasPagamento/API] Buscar Bandeira Cartao Failure',
  props<{ error: HttpErrorResponse; bandeiraNaoAtendida?: boolean }>()
);

export const resetBandeiraNaoAtendidaCartao = createAction(
  '[FormasPagamento Page] Reset Bandeira Nao Atendida Cartao'
);

export const resetPermiteSelecaoManual = createAction(
  '[FormasPagamento Page] Reset Permite Selecao Manual'
);

export const cancelarRequestBuscarBandeira = createAction(
  '[FormasPagamento Page] Cancelar Request Buscar Bandeira Cartao'
);
