import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardBasicoModule } from '@vip/ui/card-basico';
import { ButtonModule } from '@vip/ui/button';
import { CoreModule } from '@vip/core';
import { CardEntregaRetiradaComponent } from './card-entrega-retirada/card-entrega-retirada.component';

@NgModule({
  imports: [CommonModule, CoreModule, CardBasicoModule, ButtonModule],
  declarations: [CardEntregaRetiradaComponent],
  exports: [CardEntregaRetiradaComponent],
})
export class CardEntregaRetiradaModule {}
