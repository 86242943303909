import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IProdutoColecao } from '@vip/core';

@Component({
  selector: 'vip-tag-collection-group',
  templateUrl: './tag-collection-group.component.html',
  styleUrls: ['./tag-collection-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagCollectionGroupComponent {
  private _collections!: IProdutoColecao[];
  @Input()
  set collections(collections: IProdutoColecao[]) {
    this._collections = collections.slice(0, 2);
  }

  get collections() {
    return this._collections;
  }

  @Input()
  size: 'small' | 'medium' | 'large' = 'small';
}
