import { IComentario } from '../interfaces';

export const comentario1Mock: IComentario = {
  descricao: 'Teste comentario',
  avaliacao: '3',
  nome_criador: 'Jeff',
  created_at: new Date(),
};

export const comentario2Mock: IComentario = {
  descricao:
    'Produto muito saboroso, o gosto é bem específico. O produto também veio muito bem embalado. Parabéns ao supermercado e ao fornecedor.',
  avaliacao: '4.5',
  nome_criador: 'José da Silva',
  created_at: new Date(),
};

export const comentario3Mock: IComentario = {
  descricao: 'Teste comentário produto.',
  avaliacao: '5.0',
  nome_criador: 'Samantha Sobrinho',
  created_at: new Date(),
};
