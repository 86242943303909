import { ButtonModule } from '@vip/ui/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewsModalErrorComponent } from './views-modal-error/views-modal-error.component';

@NgModule({
  imports: [CommonModule, ButtonModule],
  declarations: [ViewsModalErrorComponent],
  exports: [ViewsModalErrorComponent],
})
export class ViewModalErrorModule {}
