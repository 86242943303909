<ng-container *ngIf="mostrarHeader">
  <header class="flex justify-between items-center">
    <a class="vip-logo cursor-pointer" routerLink="/">
      <vip-image
        [imageUrl]="logoSrc"
        [fallbackImageUrl]="fallbackImageUrl"
        [customSize]="true"
        role="img"
        alt="Logo do mercado"
        ariaLabel="logo principal do mercado"
        [hiddenOnError]="true"
      ></vip-image>
    </a>

    <nav class="relative">
      <ul class="flex vip-stepper-wrapper">
        <li *ngFor="let step of steps; let i = index">
          <a
            class="cursor-pointer flex flex-col items-center"
            [routerLink]="step.link || '#'"
          >
            <div
              class="vip-step-number-wrapper"
              [ngClass]="{ active: step.status !== STATUS_ENUM.PENDING }"
            >
              <ng-container
                *ngIf="
                  step.status === STATUS_ENUM.COMPLETED;
                  else stepNumberTemplate
                "
              >
                <i class="icon-check vip-color-primary-contrast"></i>
              </ng-container>
              <ng-template #stepNumberTemplate>
                <span class="vip-step-number">{{ i + 1 }}</span>
              </ng-template>
            </div>
            <span
              class="vip-step-label text-sm vip-component-stepper-desktop-color-text"
              >{{ step.title }}</span
            >
          </a>
        </li>
      </ul>
    </nav>

    <div class="flex justify-end" *ngIf="url_google">
      <a
        *ngIf="url_google"
        [href]="url_google"
        target="_blank"
        rel="noopener noreferrer"
      >
        <vip-image
          imageUrl="{{ imagesPath }}/selo-google-safe.png"
          classNames="w-full h-auto"
          alt="Google Safe Browsing"
        >
        </vip-image>
      </a>
    </div>
  </header>
</ng-container>
<ng-container *ngIf="!mostrarHeader">
  <nav class="relative">
    <ul class="flex vip-stepper-wrapper justify-center" *ngIf="stepActive">
      <li
        *ngFor="let step of stepsWithoutLink; let i = index"
        class="flex flex-col justify-center items-center gap-2 w-fit relative"
      >
        <div
          class="vip-step-number-wrapper"
          [ngClass]="{
            active: i === stepActive - 1,
            completed: i < stepActive - 1
          }"
        >
          <ng-container *ngIf="i < stepActive - 1; else stepNumberTemplate">
            <i class="icon-check vip-color-primary-contrast"></i>
          </ng-container>
          <ng-template #stepNumberTemplate>
            <span class="vip-step-number">{{ i + 1 }}</span>
          </ng-template>
        </div>
        <span class="vip-step-label text-sm vip-color-primary-main">{{
          step.title
        }}</span>
      </li>
    </ul>
  </nav>
</ng-container>

<section>
  <ng-content></ng-content>
</section>
