import { ImageModule } from '@vip/ui/image';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerImagemComponent } from './banner-imagem/banner-imagem.component';
import { SkeletonModule } from '@vip/ui/skeleton';
import { BannerImagemSkeletonComponent } from './banner-imagem-skeleton/banner-imagem-skeleton.component';

@NgModule({
  imports: [CommonModule, SkeletonModule, ImageModule],
  declarations: [BannerImagemComponent, BannerImagemSkeletonComponent],
  exports: [BannerImagemComponent, BannerImagemSkeletonComponent],
})
export class BannerImagemModule {}
