import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'vip-card-basico',
  templateUrl: './card-basico.component.html',
  styleUrls: ['./card-basico.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('contentExpansion', [
      state('expanded', style({ height: '*', overflow: 'hidden' })),
      state('collapsed', style({ height: 0, overflow: 'hidden' })),
      transition(
        'expanded <=> collapsed',
        animate('200ms cubic-bezier(.37,1.04,.68,.98)')
      ),
    ]),
    trigger('rotate', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('200ms ease-out')),
      transition('default => rotated', animate('200ms ease-in')),
    ]),
  ],
})
export class CardBasicoComponent {
  @Input() button!: string;
  @Input() buttonIcon!: string;
  @Input() icon!: string;
  @Input() title!: string;
  @Input() quantity!: string;
  @Input() disableRipple = false;
  @Input() accordion = false;
  @Input() expanded = true;
  @Input() cardBasicBorder = true;
  @Input() useSubPadding = false;
  @Input() fontWeight = false;
  @Input() isDesktop = false;
  @Input() titleSize: 'base' | 'sm' | 'lg' = 'base';
  @Input() padding: 'mobile' | 'desktop' = 'mobile';
  @Input() showHeaderDivider = false;
  @Input() addTitle = false;
  @Input() noPadding = false;
  @Input() contentFull = false;
  @Input() launchIcon = false;
  @Input() buttonType: 'normal' | 'raised' = 'normal';
  @Input() sideButton = '';

  @Output() cardClick: EventEmitter<Event> = new EventEmitter();
  @Output() buttonClick: EventEmitter<Event> = new EventEmitter();
  @Output() sideButtonClick: EventEmitter<Event> = new EventEmitter();
  @Output() launchClick: EventEmitter<Event> = new EventEmitter();

  handleButtonClick(event: Event) {
    event.stopPropagation();
    this.buttonClick.emit(event);
  }

  handleCardClick(event: Event) {
    this.cardClick.emit(event);
  }
}
