import { Injectable } from '@angular/core';
import {
  BuscarCentroDistribuicaoByIdResponse,
  BuscarCentrosDistribuicaoResponse,
  CentroDistribuicaoLojaAutonomaResponse,
} from './centro-distribuicao.service.types';
import { BaseApiService } from '../common/base-api-service';
import { Observable } from 'rxjs';
import { pluck, share } from 'rxjs/operators';
import { ICentroDistribuicao } from '@vip/core';

@Injectable({
  providedIn: 'root',
})
export class CentroDistribuicaoService {
  constructor(private api: BaseApiService<CentroDistribuicaoService>) {}

  getCdsRetirada(
    filialId: number,
    cep?: string
  ): Observable<BuscarCentrosDistribuicaoResponse> {
    const filter = cep ? `?cep=${cep}` : '';
    return this.api.get<BuscarCentrosDistribuicaoResponse>(
      'getCdsRetirada',
      `/filial/${filialId}/loja/centros_distribuicoes/retiradas` + filter
    );
  }

  getCdsEntrega(
    filialId: number,
    cep: string
  ): Observable<ICentroDistribuicao[]> {
    return this.api
      .get<BuscarCentrosDistribuicaoResponse>(
        'getCdsEntrega',
        `/filial/${filialId}/loja/centros_distribuicoes/cep/${cep}/entrega`
      )
      .pipe(pluck('data'), share());
  }

  getCentroDistribuicaoById(
    cdId: number
  ): Observable<BuscarCentroDistribuicaoByIdResponse> {
    return this.api.get<BuscarCentroDistribuicaoByIdResponse>(
      'getCentroDistribuicaoById',
      `/loja/centros_distribuicoes/${cdId}`
    );
  }

  validarCentroDistribuicaoAutonomo(
    filialId: number,
    cdId: number
  ): Observable<CentroDistribuicaoLojaAutonomaResponse> {
    return this.api
      .get<CentroDistribuicaoLojaAutonomaResponse>(
        'validarCentroDistribuicaoAutonomo',
        `/filial/${filialId}/centro_distribuicao/${cdId}/loja/validar_loja_autonoma`
      )
      .pipe(pluck('data'));
  }
}
