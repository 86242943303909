import { Component } from '@angular/core';
import { PagamentoCartaoDirective } from '@vip/views/pagamento';

@Component({
  templateUrl: 'carteira-digital-pagamento.component.html',
  styleUrls: ['carteira-digital-pagamento.component.scss'],
  selector: 'vip-carteira-digital-pagamento',
})
export class CarteiraDigitalPagamentoComponent extends PagamentoCartaoDirective {
  handleChangeFormaPagamento(formaPagamentoSelecionadaId: number) {
    if (
      this.formaPagamentoSelecionada &&
      this.formaPagamentoSelecionada.id == formaPagamentoSelecionadaId
    ) {
      this.formaPagamentoChange.emit();
    } else {
      this.formaPagamentoChange.emit(formaPagamentoSelecionadaId);
    }
  }
}
