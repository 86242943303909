import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ICliente, TelevendasEnum } from '@vip/core';

@Component({
  selector: 'vip-list-select',
  templateUrl: './list-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListSelectComponent implements OnChanges {
  @Input() items: ICliente[] = [];
  @Input() selectedItem: ICliente | null = null;
  @Input() isDeskTopResponsive = false;
  @Input() loadingTelevendas = false;

  @Input() searchValue = '';

  @Output() itemSelected: EventEmitter<ICliente> = new EventEmitter<ICliente>();
  @Output() openModalCep = new EventEmitter<void>();
  @Output() resetForm = new EventEmitter();
  @Output() fetchItems = new EventEmitter<void>();

  viewButtonRegister = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchValue']) {
      if (this.searchValue?.length < 3) {
        this.items = [];
        this.viewButtonRegister = true;
      }
    }

    if (changes['items']) {
      if (this.items.length <= 0) {
        this.viewButtonRegister = true;
      }
    }
  }

  selectItem(item: ICliente): void {
    this.selectedItem = item;
    this.itemSelected.emit(item);
    this.clearFilter();
  }

  clearFilter(): void {
    this.items = [];
    this.selectedItem = null;
    this.resetForm.emit();
    this.viewButtonRegister = true;
  }

  abrirModalValidaCEP(): void {
    this.openModalCep.emit();
  }

  isOnlyNumbers(value: string | null | undefined): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    if (value.includes('@')) {
      return false;
    }

    const cleanedValue = value.replace(/\D/g, '');
    return /^\d+$/.test(cleanedValue);
  }

  getMessage(): string {
    const searchValue = this.searchValue || '';

    if (searchValue.includes('@')) {
      return TelevendasEnum.EMAIL;
    }

    if (this.isCellPhoneNumber(searchValue)) {
      return TelevendasEnum.CELULAR;
    }

    if (this.isOnlyNumbers(searchValue)) {
      return TelevendasEnum.CPF;
    }

    return TelevendasEnum.NOME;
  }

  private isCellPhoneNumber(value: string): boolean {
    const cellPhonePattern = /\(\d{2}\)/;
    return cellPhonePattern.test(value);
  }
}
