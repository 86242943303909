<form
  [class.forms-pf]="isDesktop"
  [formGroup]="formGroup"
  class="form-pessoa-fisica"
  [class.form-grid]="isDesktop"
  [class.modal-template]="isModal"
  autocomplete="off"
>
  <vip-field
    label="NOME*"
    [error]="formGroup.controls.nome.touched && formGroup.controls.nome.invalid"
    [message]="validations.default"
    [validations]="formGroup.controls.nome.errors"
  >
    <input id="nome" type="text" formControlName="nome" />
  </vip-field>

  <vip-field
    label="SOBRENOME*"
    [error]="
      formGroup.controls.sobrenome.touched &&
      formGroup.controls.sobrenome.invalid
    "
    [message]="validations.default"
    [validations]="formGroup.controls.sobrenome.errors"
  >
    <input id="sobrenome" type="text" formControlName="sobrenome" />
  </vip-field>

  <vip-field
    label="CPF{{ emRevisao ? '' : '*' }}"
    [error]="formGroup.controls.cpf.touched && formGroup.controls.cpf.invalid"
    [message]="validations.cpf"
    [validations]="formGroup.controls.cpf.errors"
  >
    <input
      id="cpf"
      type="text"
      formControlName="cpf"
      [dropSpecialCharacters]="false"
      [mask]="masks.cpf"
      inputmode="numeric"
    />
  </vip-field>

  <vip-select
    *ngIf="exibeSexo"
    id="sexo"
    label="SEXO{{ emRevisao ? '' : '*' }}"
    [error]="formGroup.controls.sexo.touched && formGroup.controls.sexo.invalid"
    [message]="validations.default"
    [options]="generoOptions"
    [showEmpty]="false"
    [validations]="formGroup.controls.sexo.errors"
    formControlName="sexo"
  >
  </vip-select>

  <vip-field
    label="DATA DE NASCIMENTO{{ emRevisao ? '' : '*' }}"
    icon="insert_invitation"
    iconDirection="right"
    [error]="
      formGroup.controls.data_nascimento.touched &&
      formGroup.controls.data_nascimento.invalid
    "
    [message]="validations.data_nascimento"
    [validations]="formGroup.controls.data_nascimento.errors"
  >
    <input
      id="data_nascimento"
      type="text"
      formControlName="data_nascimento"
      [dropSpecialCharacters]="false"
      [mask]="masks.data"
      inputmode="numeric"
    />
  </vip-field>

  <vip-field
    label="E-MAIL*"
    [error]="
      formGroup.controls.email.touched && formGroup.controls.email.invalid
    "
    [message]="validations.email"
    [validations]="formGroup.controls.email.errors"
  >
    <input id="email" type="email" formControlName="email" />
  </vip-field>

  <vip-field
    label="CELULAR*"
    [error]="
      formGroup.controls.telefone_celular.touched &&
      formGroup.controls.telefone_celular.invalid
    "
    [message]="validations.celular"
    [validations]="formGroup.controls.telefone_celular.errors"
  >
    <input
      id="telefone_celular"
      type="tel"
      formControlName="telefone_celular"
      [dropSpecialCharacters]="false"
      [mask]="masks.celular"
    />
  </vip-field>

  <vip-field
    label="TELEFONE"
    [error]="
      formGroup.controls.telefone_fixo.touched &&
      formGroup.controls.telefone_fixo.invalid
    "
    [message]="validations.telefone"
    [validations]="formGroup.controls.telefone_fixo.errors"
  >
    <input
      id="telefone_fixo"
      type="tel"
      formControlName="telefone_fixo"
      [dropSpecialCharacters]="false"
      [mask]="masks.telefone"
    />
  </vip-field>

  <ng-container *ngIf="isEdit">
    <div class="is-edit-container">
      <vip-checkbox
        id="comunicacao_email"
        formControlName="comunicacao_email"
        label="Desejo receber via e-mail os tabloides"
      >
      </vip-checkbox>
      <vip-checkbox
        id="comunicacao_sms"
        formControlName="comunicacao_sms"
        label="Desejo receber via SMS as promoções"
      >
      </vip-checkbox>
    </div>
  </ng-container>
</form>
