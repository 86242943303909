<div [formGroup]="formGroup" class="grid gap-4 grid-cols-1 text-left">
  <h1 class="vip-color-primary-main text-xl font-bold">
    Digite sua senha para confirmar as alterações
  </h1>
  <p class="vip-color-secondary-default text-base">
    Você precisa digitar a sua senha para confirmar as alterações dos dados
    pessoais da sua conta.
  </p>
  <form autocomplete="off" novalidate [formGroup]="formGroup">
    <vip-field
      [error]="
        formGroup.controls.password.touched &&
        formGroup.controls.password.invalid
      "
      [message]="validations.password"
      label="Digitar sua senha"
      [validations]="formGroup.controls.password.errors"
      (iconClick)="showPassword = !showPassword"
      iconDirection="right"
      [icon]="'visibility_' + (showPassword ? 'off' : 'on')"
    >
      <input
        [type]="showPassword ? 'text' : 'password'"
        id="password"
        formControlName="password"
        autocomplete="off"
      />
    </vip-field>
  </form>
  <div
    [ngClass]="{
      'vip-modal-buttons flex justify-end': isDesktop
    }"
  >
    <vip-button type="raised" (click)="confirmar()" class="btn-senha">
      Validar
    </vip-button>
  </div>
</div>
