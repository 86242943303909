import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'vip-tab',
  templateUrl: './tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
  @Input() label = '';
  @Input() icon = '';
  @Input() count = '';
  @Input() disabled = false;
  @Input() showParent = false;
  @Input() chidrenLabel = '';
  @Input() chidrenIcon = '';
  @Input() disabledText = '';
  @Input() id?: string | number;
}
