import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConviteListaComponent } from './convite-lista/convite-lista.component';
import { ScreenTitleModule } from '@vip/ui/screen-title';
import { ButtonModule } from '@vip/ui/button';

@NgModule({
  imports: [CommonModule, ScreenTitleModule, ButtonModule],
  declarations: [ConviteListaComponent],
  exports: [ConviteListaComponent],
})
export class ConviteListaModule {}
