import { Injectable } from '@angular/core';
import { BaseWsService } from '../common/base-ws-service';
import {
  GetNotificacoesResponse,
  GetNotificacoesResponseDTO,
  INotificacaoResponse,
  ITokenPayload,
} from './notificacao-ws.types';
import { map } from 'rxjs/operators';
import { parseISO } from 'date-fns';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NotificacaoWsService {
  private notificacoesUrl = '/loja/notificacoes';

  constructor(private ws: BaseWsService<NotificacaoWsService>) {}

  getNotificacoes(filialId: number) {
    return this.ws
      .get<GetNotificacoesResponseDTO>(
        'getNotificacoes',
        `${this.notificacoesUrl}`,
        { FilialID: filialId.toString() }
      )
      .pipe(
        map(
          (notificacoes: GetNotificacoesResponseDTO) =>
            <GetNotificacoesResponse>{
              ...notificacoes,
              notificacoes: notificacoes.notificacoes?.map((notificacao) => ({
                ...notificacao,
                created: parseISO(notificacao.created),
                modified: parseISO(notificacao.modified),
                data_envio: parseISO(notificacao.data_envio),
                data_final: parseISO(notificacao.data_final),
              })),
            }
        )
      );
  }

  saveToken(token: string, platform: string, filialId: number) {
    return this.ws
      .post<ITokenPayload, INotificacaoResponse>(
        'saveToken',
        `${this.notificacoesUrl}/save_token`,
        {
          token,
          platform,
        },
        { FilialID: filialId.toString() }
      )
      .pipe(
        map((res) => {
          if (res.error || !res.success) {
            throw new HttpErrorResponse({
              status: 400,
              error: { error: res.message },
            });
          }
          return res;
        })
      );
  }

  setNotificationRead(notificationId: number) {
    return this.ws
      .post<{ notificationId: number }, INotificacaoResponse>(
        'setNotificationRead',
        `${this.notificacoesUrl}/set_notification_read`,
        {
          notificationId,
        }
      )
      .pipe(
        map((res) => {
          if (!res) return;
          if (res.error || !res.success) {
            throw new HttpErrorResponse({
              status: 400,
              error: { error: res.message },
            });
          }
          return res;
        })
      );
  }

  setNotificationReadByClient(notificationId: number) {
    return this.ws
      .post<{ notificationId: number }, INotificacaoResponse>(
        'setNotificationRead',
        `${this.notificacoesUrl}/set_notification_read_by_client`,
        {
          notificationId,
        }
      )
      .pipe(
        map((res) => {
          if (!res) return;
          if (res.error || !res.success) {
            throw new HttpErrorResponse({
              status: 400,
              error: { error: res.message },
            });
          }
          return res;
        })
      );
  }
}
