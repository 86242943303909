import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as cashbackActions from './cashback.actions';
import * as cashbackSelectors from './cashback.selectors';

@Injectable()
export class CashbackFacade {
  saldoCashback$ = this.store.pipe(select(cashbackSelectors.getSaldoCashback));
  extratoCashback$ = this.store.pipe(
    select(cashbackSelectors.getExtratoCashback)
  );
  loading$ = this.store.pipe(select(cashbackSelectors.getLoading));
  saldoCashbackError$ = this.store.pipe(
    select(cashbackSelectors.getSaldoCashbackError)
  );
  extratoCashbackError$ = this.store.pipe(
    select(cashbackSelectors.getExtratoCashbackError)
  );
  ultimaAtualizacaoSaldo$ = this.store.pipe(
    select(cashbackSelectors.getUltimaAtualizacaoSaldo)
  );
  exibeCashback$ = this.store.pipe(select(cashbackSelectors.getExibeCashback));
  extratoCompleto$ = this.store.pipe(
    select(cashbackSelectors.getExtratoCompleto)
  );
  isAlterarSenhaAtivo$ = this.store.pipe(
    select(cashbackSelectors.getAlterarSenhaAtivo)
  );
  valorMinimo$ = this.store.pipe(select(cashbackSelectors.getValorMinimo));
  loaded$ = this.store.pipe(select(cashbackSelectors.getLoaded));

  constructor(private store: Store) {}

  getSaldoCashback() {
    this.store.dispatch(cashbackActions.getSaldoCashback());
  }

  getExtratoCashback(dataInicial: string, dataFinal: string) {
    this.store.dispatch(
      cashbackActions.getExtratoCashback({ dataInicial, dataFinal })
    );
  }

  carregarMaisExtratoCashback(dataInicial: string, dataFinal: string) {
    this.store.dispatch(
      cashbackActions.carregarMaisExtratoCashback({ dataInicial, dataFinal })
    );
  }

  alterarSenhaCashback(novaSenha: string) {
    this.store.dispatch(cashbackActions.alterarSenhaCashback({ novaSenha }));
  }
}
