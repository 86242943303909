import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriaItemComponent } from './categoria-item/categoria-item.component';
import { ImageModule } from '@vip/ui/image';
import { CategoriaItemSkeletonComponent } from './categoria-item-skeleton/categoria-item-skeleton.component';
import { SkeletonModule } from '@vip/ui/skeleton';

@NgModule({
  imports: [CommonModule, ImageModule, SkeletonModule],
  declarations: [CategoriaItemComponent, CategoriaItemSkeletonComponent],
  exports: [CategoriaItemComponent, CategoriaItemSkeletonComponent],
})
export class CategoriaItemModule {}
