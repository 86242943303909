import { CUPOM_FEATURE_KEY, CupomState } from './cupom.reducer';
import { SelectorUtil } from '@vip/state/utils';
import { createSelector } from '@ngrx/store';

export class CupomSelectors extends SelectorUtil<CupomState> {
  constructor() {
    super(CUPOM_FEATURE_KEY);
  }

  getCuponsSelecionados = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CupomState) => state.selecionados
    );

  getTotalCupons = () =>
    createSelector(this.createFeatureSelector, (state: CupomState) =>
      state.selecionados.reduce((acc, curr) => {
        return acc + curr.valor;
      }, 0)
    );
}

export const cupomSelectors = new CupomSelectors();
