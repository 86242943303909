import { Injectable } from '@angular/core';
import { BaseApiService } from '../common/base-api-service';
import { BaseApiResponse } from '../common/base-api-response';

@Injectable({
  providedIn: 'root',
})
export class SessaoApiService {
  constructor(private api: BaseApiService<SessaoApiService>) {}

  getSessaoId() {
    return this.api.get<BaseApiResponse<{ sessao_id: string }>>(
      'getSessaoId',
      '/loja/sessao_cliente'
    );
  }
}
