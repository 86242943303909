import { createAction, props } from '@ngrx/store';
import {
  ICompra,
  IHorariosFormatados,
  IPortador,
  ITaxaServico,
  ITipoEntregaCompra,
} from '@vip/core';
import { HttpErrorResponse } from '@angular/common/http';
import { IFinalizarCompraPayload, IPayloadCompraPagamento } from '@vip/ws';

export const criarCompra = createAction('[Compra/Page] Criar compra');

export const criarCompraSuccess = createAction(
  '[Compras/API] Criar Compra Success',
  props<{ compra: ICompra; id?: number }>()
);

export const criarCompraFailure = createAction(
  '[Compras/API] Criar Compra Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getCompraEmProcesso = createAction(
  '[CompraEmProcesso/Pages] Get Compra Em Processo'
);

export const getCompraEmProcessoSuccess = createAction(
  '[CompraEmProcesso/API] Get Compra Em Processo Success',
  props<{ compra: ICompra }>()
);

export const getCompraEmProcessoEmpty = createAction(
  '[CompraEmProcesso/API] Get Compra Em Processo Empty'
);

export const getCompraEmProcessoFailure = createAction(
  '[CompraEmProcesso/API] Get Compra Em Processo Failure',
  props<{ error: HttpErrorResponse }>()
);

export const setPortadorCompraEmProcesso = createAction(
  '[CompraEmProcesso/Pages] set Portador Compra Em Processo',
  props<{ portador?: IPortador }>()
);

export const updateParcelasCompraEmProcesso = createAction(
  '[CompraEmProcesso/Pages] Update Parcelas Compra Em Processo',
  props<{ parcelas: number }>()
);

export const updateCVVCompraEmProcesso = createAction(
  '[CompraEmProcesso/Pages] Update CVV Compra Em Processo',
  props<{ cvv: string }>()
);

export const setCashbackCompraEmProcesso = createAction(
  '[CompraEmProcesso/API] Set Cashback Compra Em Processo',
  props<{ valorCashback: number }>()
);

export const setCashbackCompraEmProcessoSuccess = createAction(
  '[CompraEmProcesso/API] Set Cashback Compra Em Processo Success',
  props<{ valorCashback: number }>()
);

export const selectTipoEntrega = createAction(
  '[CompraEmProcesso/API] Select Tipo Entrega',
  props<{ compra: ICompra }>()
);

export const recriarCarrinhoContinuarComprando = createAction(
  '[CompraEmProcesso/Page] Recriar carrinho e continuar comprando',
  props<{
    compraId: number;
    cdId: number;
    filialId: number;
  }>()
);

export const getTaxaServico = createAction(
  '[CD/API] Busca a taxa de serviço',
  props<{ valorInicial?: number }>()
);

export const getTaxaServicoSuccess = createAction(
  '[CD/API] Sucesso Na busca da taxa de serviço',
  props<{ taxaServico: ITaxaServico }>()
);

export const getTaxaServicoFailure = createAction(
  '[CD/API] Erro na busca da taxa de serviço',
  props<{
    error: HttpErrorResponse;
    taxa_servico?: ITaxaServico[];
  }>()
);

export const continuarComprando = createAction(
  '[CompraEmProcesso/Page] Continuar Comprando',
  props<{
    compraId: number;
    cdId: number;
    filialId: number;
  }>()
);

export const continuarComprandoSuccess = createAction(
  '[CompraEmProcesso/API] Continuar Comprando Success',
  props<{
    compraId: number;
    cdId: number;
    filialId: number;
  }>()
);

export const continuarComprandoFailure = createAction(
  '[CompraEmProcesso/API] Continuar Comprando Failure',
  props<{ error: HttpErrorResponse }>()
);

export const finalizarCompraEmProcesso = createAction(
  '[CompraEmProcesso/Pages] Finalizar Compra Em Processo',
  props<{ filialId: number; payload: IFinalizarCompraPayload }>()
);

export const finalizarCompraEmProcessoSuccess = createAction(
  '[CompraEmProcesso/API] Finalizar Compra Em Processo Success',
  props<{ pedido: ICompra; current_date?: string; id?: number }>()
);

export const finalizarCompraEmProcessoFailure = createAction(
  '[CompraEmProcesso/API] Finalizar Compra Em Processo Failure',
  props<{
    error: HttpErrorResponse;
    compra_pagamentos?: IPayloadCompraPagamento[];
  }>()
);

export const resetCompraEmProcesso = createAction(
  '[CompraEmProcesso/API] Reset Compra Em Processo '
);

export const criarCompraEmpty = createAction(
  '[CompraEmProcesso/API] Criar Compra Empty'
);

export const cancelarPagamentoDigital = createAction(
  '[CompraEmProcesso/Pages] Cancelar Pagamento Digital',
  props<{ filialId: number; compraId: number; compraPagamentoId: number }>()
);

export const cancelarPagamentoDigitalSuccess = createAction(
  '[CompraEmProcesso/API] Cancelar Pagamento Digital Success',
  props<{ message: string }>()
);

export const cancelarPagamentoDigitalFailure = createAction(
  '[CompraEmProcesso/API] Cancelar Pagamento Digital Failure',
  props<{ error: HttpErrorResponse }>()
);

export const verificarPagamentoDigital = createAction(
  '[CompraEmProcesso/Pages] Verificar Pagamento Digital',
  props<{ filialId: number; compraId: number; compraPagamentoId: number }>()
);

export const verificarPagamentoDigitalSuccess = createAction(
  '[CompraEmProcesso/API] Verificar Pagamento Digital Success',
  props<{ message: string; success: boolean; compraId: number }>()
);

export const verificarPagamentoDigitalFailure = createAction(
  '[CompraEmProcesso/API] Verificar Pagamento Digital Failure',
  props<{ error: HttpErrorResponse }>()
);

export const pagamentoDigitalEfetuado = createAction(
  '[CompraEmProcesso/API] Pagamento Digital Efetuado',
  props<{ compraId: number }>()
);

export const getCompraView = createAction(
  '[CompraEmProcesso/API] Get Compra View',
  props<{ compraId: number }>()
);

export const getCompraViewSuccess = createAction(
  '[CompraEmProcesso/API] Get Compra View Success',
  props<{ compra: ICompra }>()
);
export const getCompraViewFailure = createAction(
  '[CompraEmProcesso/API] Get Compra View Failure',
  props<{ error: HttpErrorResponse }>()
);

export const setFreteCompraEmProcesso = createAction(
  '[CompraEmProcesso/Pages] set Frete Compra Em Processo',
  props<{ frete?: number }>()
);

export const setTipoEntregaCompraEmProcesso = createAction(
  '[CompraEmProcesso/Pages] set Tipo Entrega Compra Em Processo',
  props<{
    tipo_entrega?: ITipoEntregaCompra;
    horarioSelecionado?: IHorariosFormatados;
  }>()
);
