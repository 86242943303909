import { combineLatest, ObservableInput, OperatorFunction } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';

// https://gist.github.com/sod/a34fc7705b396f61d0ef00713b807209
// Operador para aguardar algum valor não disparado durante um effect
//
// Example:
//     this.action.pipe(
//         ofType(myActionType),
//         tap((myActionType) => {}),                        // <-- only has the action value
//         mergeTakeOne(this.store.select(myStoreValue), /* andAnotherObservable$, andEvenMoreObservables$ */),
//         tap(([myActionType, myStoreValue]) => {}),        // <-- has both, the action value and the value from the store observable
//

export function mergeTakeOne<SOURCE, MERGE extends ObservableInput<any>[]>(
  ...toBeMerged$: [...MERGE]
): OperatorFunction<
  SOURCE,
  [
    SOURCE,
    ...{
      [P in keyof MERGE]: MERGE[P] extends ObservableInput<infer U> ? U : never;
    }
  ]
> {
  return mergeMap((source) =>
    combineLatest(toBeMerged$).pipe(
      take(1),
      map((source2): any => [source, ...source2])
    )
  );
}
