import { Injectable } from '@angular/core';
import { CompraStatusEnum, ICompra, ICompraDTO, ITaxaServico } from '@vip/core';
import { parseISO } from 'date-fns';
import { map, pluck } from 'rxjs/operators';

import { BaseApiService } from '../common/base-api-service';
import {
  GetCompraResponse,
  GetCompraResponseDTO,
  GetComprasResponse,
  GetComprasResponseDTO,
  GetPerguntasCompraResponseDTO,
  GetPrazoResponse,
  ICriarCompraPayload,
  PostContinuarComprandoResponse,
  PostRepetirCompraResponse,
} from './compra-api.service.types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompraApiService {
  constructor(private api: BaseApiService<CompraApiService>) {}

  getPrazoTrocaDevolucao() {
    return this.api.get<GetPrazoResponse>(
      'getPrazoTrocaDevolucao',
      '/loja/compras/prazo-troca-devolucao'
    );
  }

  criarCompra(filialId: number, cdId: number, novaCompra: ICriarCompraPayload) {
    return this.api
      .post<ICriarCompraPayload, GetCompraResponseDTO>(
        'criarCompra',
        `${this.api.filialAndCdUrl(filialId, cdId)}/compras/add`,
        { ...novaCompra }
      )
      .pipe(map((res) => this.mapCompra(res)));
  }

  getCompras(filial: number, page = 1) {
    return this.api
      .get<GetComprasResponseDTO>(
        'getCompras',
        `/filial/${filial}/loja/compras/N?page=${page}`
      )
      .pipe(map((res) => this.mapCompras(res)));
  }

  getCompra(filial: number, compraId: number) {
    return this.api
      .get<GetCompraResponseDTO>(
        'getCompra',
        `/filial/${filial}/loja/compra/${compraId}`
      )
      .pipe(map((res) => this.mapCompra(res)));
  }

  getCompraEmProcesso(filial: number) {
    return this.api
      .get<GetCompraResponseDTO>(
        'getCompra',
        `/filial/${filial}/loja/compra-em-processo`
      )
      .pipe(map((res) => (res.data ? this.mapCompra(res) : null)));
  }

  getPerguntasCompraComResposta(filial: number, compraId: number) {
    return this.api.get<GetPerguntasCompraResponseDTO>(
      'getPerguntasCompraComResposta',
      `/filial/${filial}/loja/clientes/perguntas/compra-respostas/${compraId}`
    );
  }

  getComprasEmAberto(filial: number) {
    return this.api
      .get<GetComprasResponseDTO>(
        'getCompras',
        `/filial/${filial}/loja/compras/N/${this.getStatusCompraFinalizada()}`
      )
      .pipe(map((res) => this.mapCompras(res)));
  }

  getTrocaDevolucao(filial: number) {
    return this.api
      .get<GetComprasResponseDTO>(
        'getTrocaDevolucao',
        `/filial/${filial}/loja/compras/T,D/${this.getStatusCompraFinalizada()}`
      )
      .pipe(map((res) => this.mapCompras(res)));
  }

  getComprasTrocasDevolucao(filial: number) {
    return this.api
      .get<GetComprasResponseDTO>(
        'getComprasTrocasDevolucao',
        `/filial/${filial}/loja/compras/N,T,D/${this.getStatusCompraFinalizada()}`
      )
      .pipe(map((res) => this.mapCompras(res)));
  }

  cancelarCompra(filialId: number, compraId: number, observacao: string) {
    return this.api.post<{ observacao: string }, { data: string }>(
      'cancelarCompra',
      `/filial/${filialId}/loja/compras/delete/${compraId}`,
      { observacao }
    );
  }

  repetirCompra(filialId: number, cdId: number, compraId: number) {
    return this.api.post<{ compra_id: number }, PostRepetirCompraResponse>(
      'repetirCompra',
      `/filial/${filialId}/centro_distribuicao/${cdId}/loja/carrinhos/incluir_compra_anterior`,
      { compra_id: compraId }
    );
  }

  continuarComprando(filialId: number, compraId: number) {
    return this.api.post<Record<string, never>, PostContinuarComprandoResponse>(
      'continuarComprando',
      `/filial/${filialId}/loja/compras/continuar-comprando/${compraId}`,
      {}
    );
  }

  getTaxaServico(
    valorCompra: number,
    filialId: number,
    cdId: number
  ): Observable<ITaxaServico> {
    const filter = valorCompra ? `?valorCompra=${valorCompra}` : '';
    return this.api
      .get<ITaxaServico>(
        'getTaxaServico',
        `/filial/${filialId}/centro_distribuicao/${cdId}/loja/taxa_servico/` +
          filter
      )
      .pipe(pluck('data'));
  }

  private mapCompras(compras: GetComprasResponseDTO): GetComprasResponse {
    return {
      ...compras,
      data: compras.data.map((compraDTO: ICompraDTO) => {
        const compra: ICompra = {
          ...compraDTO,
          tipo_entrega: {
            ...compraDTO.tipo_entrega,
            frete: compraDTO.frete,
            desconto: 0,
          },
          created: parseISO(compraDTO.created),
          data_entrega: parseISO(compraDTO.data_entrega),
          data_entregue: compraDTO.data_entregue
            ? parseISO(compraDTO.data_entregue)
            : null,
        };
        return compra;
      }),
    };
  }

  private mapCompra(compra: GetCompraResponseDTO): GetCompraResponse {
    const _compra: ICompra = {
      ...compra.data,
      tipo_entrega: {
        ...compra.data.tipo_entrega,
        frete: compra.data.frete,
        desconto: 0,
      },
      created: parseISO(compra.data.created),
      data_entrega: parseISO(compra.data.data_entrega),
      data_entregue: compra.data.data_entregue
        ? parseISO(compra.data.data_entregue)
        : null,
    };

    return {
      ...compra,
      data: _compra,
    };
  }

  private getStatusCompraFinalizada(): string {
    return [
      CompraStatusEnum.CANCELADA,
      CompraStatusEnum.FINALIZADA,
      CompraStatusEnum.ENTREGUE,
    ].join(',');
  }
}
