<vip-stepper-desktop
  [logoSrc]="logoSrc"
  [fallbackImageUrl]="fallbackImageUrl"
  [steps]="steps"
  [url_google]="url_google"
></vip-stepper-desktop>

<div
  class="tabs-wrapper justify-between pagamento-desktop mb-4 display-flex-start"
>
  <vip-opcoes-pagamento
    class="w-full max-w-[60rem] ml-12 mr-4 fixed-card"
    [pagamentosOnline]="pagamentosOnline ?? undefined"
    [pagamentosEntrega]="pagamentosEntrega ?? undefined"
    [loadedPagamentos]="loadedPagamentos"
    [removerPrepagos]="removerPrepagos"
    [totalCompra]="totalCompra"
    [valorDescontos]="valorDescontos"
    [formaPagamentoSelecionada]="formaPagamentoSelecionada"
    [formasPagamentosPrePagasCartaoLoja]="formasPagamentosPrePagasCartaoLoja"
    [formaPagamentosOptionsCartaoLoja]="formaPagamentosOptionsCartaoLoja"
    [formasPagamentosPrePagasCartaoCredito]="
      formasPagamentosPrePagasCartaoCredito
    "
    [formaPagamentosOptionsCartaoCredito]="formaPagamentosOptionsCartaoCredito"
    [formasPagamentosPrePagasCartaoCredito]="
      formasPagamentosPrePagasCartaoCredito
    "
    [formasPagamentosOptionsVoucher]="formasPagamentosOptionsVoucher"
    [formasPagamentosPrePagasVoucher]="formasPagamentosPrePagasVoucher"
    [formasPagamentosOptionsCarteiraDigital]="
      formasPagamentosOptionsCarteiraDigital
    "
    [formasPagamentosCarteiraDigital]="formasPagamentosCarteiraDigital"
    [exibeModalBandeiraNaoAtendida$]="exibeModalBandeiraNaoAtendida$"
    [privateLabelBandeiraAutomatica]="privateLabelBandeiraAutomatica"
    [permiteSelecaoManual]="permiteSelecaoManual"
    [permitirBandeiraAutomatica]="permitirBandeiraAutomatica"
    [isLoading]="isLoading"
    [cartoes]="cartoes"
    [permitirSalvarCartao]="permitirSalvarCartao"
    [compraEmProcesso]="compraEmProcesso"
    [parcelas]="parcelas"
    [cartaoSelecionadoId]="cartaoSelecionadoId"
    [cupons]="cupons"
    [cuponsSelecionados]="cuponsSelecionados"
    [codigoPromocional]="codigoPromocional"
    [compraEmProcessoError]="compraEmProcessoError"
    (ativarCodigo)="ativarCodigo.emit($event)"
    (deleteCodigoPromocionalClick)="deleteCodigoPromocionalClick.emit($event)"
    (selectParcela)="selectParcela.emit($event)"
    (cvvValueChange)="cvvValueChange.emit($event)"
    (deletarCartao)="deletarCartao.emit($event)"
    (cartaoSalvoChange)="selectCartaoSalvo($event)"
    (selectFormaPagamento)="selectFormaPagamento.emit($event)"
    (buscarBandeiraCartao)="buscarBandeiraCartao.emit($event)"
    (changedCuponsSelecionados)="changedCuponsSelecionados.emit($event)"
    (formaPagamentoChange)="formaPagamentoChange.emit($event)"
    (adicionarCartao)="adicionarCartao.emit($event)"
    (setValorTroco)="setValorTroco.emit($event)"
    (tabChange)="tabChange.emit()"
    [isTelevendas]="isTelevendas"
  ></vip-opcoes-pagamento>

  <vip-card-detalhes-cobranca
    class="w-full max-w-[20rem] mr-4 display-stick"
    confirmButtonText="Concluir Compra"
    data-cy="vip-detalhes-cobranca"
    [isDesktop]="true"
    [saldoCashback]="saldoCashback || undefined"
    [showCashback]="exibeCashback || false"
    [disablePagamentoButton]="disablePagamentoButton"
    [isLoading]="isLoading"
    [showContinuarComprando]="true"
    [valorCompra]="compraEmProcesso?.valor_inicial || 0"
    [totalCompra]="totalCompra"
    [taxaEntrega]="taxaEntrega"
    [valorAcrescimos]="valorAcrescimos"
    [valorDescontos]="valorDescontos"
    [descontos]="descontos"
    [taxaServico]="taxaServico"
    [cardBasicBorder]="false"
    [editable]="true"
    (continuarComprando)="continuarComprando.emit($event)"
    (irParaPagamento)="concluirCompraClicked.emit()"
    (changedValorUtilizadoCashback)="changedValorUtilizadoCashback.emit($event)"
  ></vip-card-detalhes-cobranca>
</div>
