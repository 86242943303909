import { createSelector, Store } from '@ngrx/store';
import { SelectorUtil } from '@vip/state/utils';

import {
  COMPRA_EM_PROCESSO_FEATURE_KEY,
  compraEmProcessoAdapter,
  CompraEmProcessoState,
} from './compra-em-processo.reducer';
import * as CompraEmProcessoActions from './compra-em-processo.actions';
import { ICompra } from '@vip/core';

const { selectEntities } = compraEmProcessoAdapter.getSelectors();

class CompraEmProcessoSelectors extends SelectorUtil<CompraEmProcessoState> {
  constructor() {
    super(COMPRA_EM_PROCESSO_FEATURE_KEY);
  }

  getAllCompras = () =>
    createSelector(this.createFeatureSelector, (state: CompraEmProcessoState) =>
      selectEntities(state)
    );
  getCompraEmProcessoId = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CompraEmProcessoState) => state.compraEmProcessoId
    );

  getCompraEmProcesso = (store: Store) =>
    createSelector(
      this.getAllCompras(),
      this.getCompraEmProcessoId(),
      this.isPending(),
      (entities, selectedId, isPending) => {
        if (isPending)
          store.dispatch(CompraEmProcessoActions.getCompraEmProcesso());
        if (selectedId && entities) return entities[selectedId];
        return null;
      }
    );

  getCompraEmProcessoById = (compraId: string) =>
    createSelector(this.getAllCompras(), (entities) => {
      return compraId && entities[compraId] ? entities[compraId] : null;
    });

  getCashbackUtilizadoCompraEmProcesso = (store: Store) =>
    createSelector(
      this.getCompraEmProcesso(store),
      (data: ICompra | null | undefined) => data?.valor_cashback || 0
    );

  hasPortadorValido = (store: Store) =>
    createSelector(this.getCompraEmProcesso(store), (data) => !!data?.portador);

  taxaServico = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CompraEmProcessoState) => state.taxaServico
    );

  currentDate = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CompraEmProcessoState) => state.current_date
    );

  gAdWords = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CompraEmProcessoState) => state.gAdWords
    );
}

export const compraEmProcessoSelectors = new CompraEmProcessoSelectors();
