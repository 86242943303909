import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { GenericStoreStatusEnum, IGenericState } from '@vip/state/utils';

import * as TelevendasActions from './televendas.actions';
import { ICliente, IFilialTelevendas, IOperadorTelevendas } from '@vip/core';
import { IWsPaging } from '@vip/ws';

export const TELEVENDAS_FEATURE_KEY = 'televendas';

export type TelevendasState = IGenericState<{
  token: string | null;
  operador: IOperadorTelevendas | null;
  clientes: ICliente[] | null;
  paging: IWsPaging | null;
  filiais: IFilialTelevendas[] | null;
}>;

export const televendasAdapter: EntityAdapter<{
  token: string | null;
  operador: IOperadorTelevendas | null;
}> = createEntityAdapter<{
  token: string | null;
  operador: IOperadorTelevendas | null;
}>();

export const initialTelevendasState: TelevendasState =
  televendasAdapter.getInitialState({
    data: {
      operador: null,
      token: null,
      filiais: null,
      clientes: null,
      paging: null,
    },
    error: null,
    status: 'pending',
  });

const reducer = createReducer(
  initialTelevendasState,
  on(TelevendasActions.logarTelevendas, (state) => ({
    ...state,
    error: null,
  })),
  on(
    TelevendasActions.logarTelevendasSuccess,
    (state, { token, operador }) => ({
      ...state,
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
      data: { ...state.data, token, operador },
    })
  ),
  on(TelevendasActions.logarTelevendasFailure, (state, { error }) => ({
    ...state,
    status: GenericStoreStatusEnum.ERROR,
    error: error.error.error,
  })),
  on(TelevendasActions.setOperador, (state, { operador }) => ({
    ...state,
    data: {
      ...state.data,
      operador,
    },
    status: GenericStoreStatusEnum.SUCCESS,
  })),
  on(TelevendasActions.setToken, (state, { token }) => ({
    ...state,
    data: {
      ...state.data,
      token,
    },
    status: GenericStoreStatusEnum.SUCCESS,
  })),
  on(TelevendasActions.getFiliaisTelevendasSuccess, (state, { filiais }) => {
    return {
      ...state,
      data: {
        ...state.data,
        filiais,
      },
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
    };
  }),
  on(TelevendasActions.getClientesTelevendas, (state) => ({
    ...state,
    error: null,
    status: GenericStoreStatusEnum.LOADING,
  })),
  on(
    TelevendasActions.getClientesTelevendaSuccess,
    (state, { clientes, paging }) => {
      return {
        ...state,
        data: {
          ...state.data,
          clientes,
          paging,
        },
        status: GenericStoreStatusEnum.SUCCESS,
        error: null,
      };
    }
  ),
  on(
    TelevendasActions.getFiliaisTelevendasFailure,
    TelevendasActions.getClientesTelevendasFailure,
    (state, { error }) => ({
      ...state,
      status: GenericStoreStatusEnum.ERROR,
      error: error.error,
    })
  ),
  on(TelevendasActions.logarClienteTelevendas, (state) => ({
    ...state,
    error: null,
    status: GenericStoreStatusEnum.LOADING,
  })),
  on(
    TelevendasActions.logarClienteTelevendasSuccess,
    (state, { token, operador }) => ({
      ...state,
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
      data: { ...state.data, token, operador },
    })
  ),
  on(TelevendasActions.logarClienteTelevendasFailure, (state, { error }) => ({
    ...state,
    status: GenericStoreStatusEnum.ERROR,
    error: error.error.error,
  })),
  on(TelevendasActions.vincularFilialTelevendas, (state) => ({
    ...state,
    error: null,
  })),
  on(
    TelevendasActions.vincularFilialTelevendasSuccess,
    (state, { token, operador }) => ({
      ...state,
      status: GenericStoreStatusEnum.LOADING,
      error: null,
      data: { ...state.data, token, operador },
    })
  ),
  on(TelevendasActions.vincularFilialTelevendasFailure, (state, { error }) => ({
    ...state,
    status: GenericStoreStatusEnum.ERROR,
    error: error.error.error,
  }))
);

export function televendasReducer(
  state: TelevendasState | undefined,
  action: Action
) {
  return reducer(state, action);
}
