import { AuthGuard, ExigeAutenticacaoGuard } from '@vip/state/auth';
import { SelecaoCdGuard } from '@vip/state/centro-distribuicao';
import { HomeOmniGuard } from '@vip/state/home-omnichannel';
import { CompraEmProcessoGuard } from '@vip/state/compra-em-processo';
import { CarrinhoGuard } from '@vip/state/carrinho';
import { Route } from '@angular/router';
import { ValidaPinClienteGuard } from '@vip/state/cliente';
import { ConfirmacaoSaidaPagamentoGuard } from '@vip/container/pagamento';
import { FilialSemEcommerceGuard } from '@vip/state/filial';
import { SiteLojaGuard } from '@vip/container/home-ecommerce';
import { OfertaExclusivaGuard } from '@vip/container/ofertas';
import { CompletarCadastroGuard } from '@vip/container/modal-completar-cadastro';
import { ConviteListaGuard } from '@vip/container/convite-lista';

export const Routes: Route[] = [
  {
    path: 'home',
    data: { bottomNavigationIsVisible: true, breadcrumb: { label: 'Home' } },
    canActivate: [ExigeAutenticacaoGuard, SelecaoCdGuard, HomeOmniGuard],
    loadChildren: async () =>
      (await import('@vip/container/home-omnichannel'))
        .HomeOmnichannelContainerModule,
  },
  {
    path: 'login',
    loadChildren: async () =>
      (await import('@vip/container/login')).LoginContainerModule,
  },
  {
    path: 'clientes/cadastro',
    redirectTo: 'cadastrar',
  },
  {
    path: 'clientes/cadastro/:cep',
    redirectTo: 'cadastrar',
  },
  {
    path: 'cadastrar',
    loadChildren: async () =>
      (await import('@vip/container/cadastro')).CadastroContainerModule,
  },
  {
    path: 'menu',
    canActivate: [ExigeAutenticacaoGuard, SelecaoCdGuard],
    data: {
      bottomNavigationIsVisible: true,
      headerDesktopIsVisible: false,
      footerDesktopIsVisible: false,
      breadcrumb: 'Menu',
    },
    loadChildren: async () =>
      (await import('@vip/container/main-menu')).MainMenuContainerModule,
  },
  {
    path: 'termos-uso',
    canActivate: [ExigeAutenticacaoGuard, SelecaoCdGuard],
    loadChildren: async () =>
      (await import('@vip/container/termos-uso')).TermosUsoContainerModule,
  },
  {
    path: 'produtos/departamento/:departamento',
    redirectTo: 'departamentos/:departamento',
  },
  {
    path: 'produtos/departamento/:departamento/:secao',
    redirectTo: 'departamentos/:departamento/:secao',
  },
  {
    path: 'departamentos',
    data: {
      bottomNavigationIsVisible: true,
      breadcrumb: {
        getFromParentLink: true,
        fixedParent: {
          label: 'Loja',
          link: '/',
        },
      },
    },
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    children: [
      {
        path: '',
        data: { bottomNavigationIsVisible: true },
        loadChildren: async () =>
          (await import('@vip/container/classificacao-mercadologica'))
            .ClassificacaoMercadologicaContainerModule,
      },
      {
        path: ':departamento',
        data: { bottomNavigationIsVisible: true },
        loadChildren: async () =>
          (await import('@vip/container/classificacao-mercadologica'))
            .ClassificacaoMercadologicaContainerModule,
      },
      {
        path: ':departamento/:secao',
        data: { bottomNavigationIsVisible: true },
        loadChildren: async () =>
          (await import('@vip/container/classificacao-mercadologica'))
            .ClassificacaoMercadologicaContainerModule,
      },
    ],
  },
  {
    path: 'receitas',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    data: {
      bottomNavigationIsVisible: true,
      breadcrumb: {
        label: 'Receita',
        fixedParent: {
          label: 'Home',
          link: '/',
        },
      },
    },
    loadChildren: async () =>
      (await import('@vip/container/lista-receitas'))
        .ListaReceitasContainerModule,
  },
  {
    path: 'receitas/:id/:slug',
    redirectTo: 'receita/:id',
  },
  {
    path: 'receita/:id',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    data: { bottomNavigationIsVisible: true },
    loadChildren: async () =>
      (await import('@vip/container/receita')).ReceitaContainerModule,
  },
  {
    path: 'receita/:id/ingredientes/:lista_id',
    data: { bottomNavigationIsVisible: true },
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    loadChildren: async () =>
      (await import('@vip/container/ingredientes-receita'))
        .IngredientesReceitaContainerModule,
  },
  {
    path: 'receita/:id/ingredientes/:lista_id/comprar/:comprar',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    data: { bottomNavigationIsVisible: true },
    loadChildren: async () =>
      (await import('@vip/container/ingredientes-receita'))
        .IngredientesReceitaContainerModule,
  },
  {
    path: 'entrega-retirada',
    canActivate: [FilialSemEcommerceGuard, CompletarCadastroGuard],
    loadChildren: async () =>
      (await import('@vip/container/entrega-retirada'))
        .EntregaRetiradaContainerModule,
  },
  {
    path: 'institucional/paginas/fale-conosco',
    redirectTo: 'institucional/fale-conosco',
  },
  {
    path: 'institucional',
    canActivate: [ExigeAutenticacaoGuard, SelecaoCdGuard],
    data: {
      footerResponsivoIsVisible: false,
    },
    loadChildren: async () =>
      (await import('@vip/container/institucional'))
        .InstitucionalContainerModule,
  },
  {
    path: 'clientes/redefinirSenha',
    loadChildren: async () =>
      (await import('@vip/container/recuperar-senha'))
        .RecuperarSenhaContainerModule,
  },
  {
    path: 'sobre',
    canActivate: [ExigeAutenticacaoGuard, SelecaoCdGuard],
    loadChildren: async () =>
      (await import('@vip/container/sobre')).SobreContainerModule,
  },
  {
    path: 'buscas',
    redirectTo: 'busca',
  },
  {
    path: 'busca',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    data: {
      bottomNavigationIsVisible: true,
      breadcrumb: {
        label: 'Busca',
        fixedParent: {
          label: 'Loja',
          link: '/',
        },
      },
    },

    loadChildren: async () =>
      (await import('@vip/container/busca')).BuscaContainerModule,
  },
  {
    path: 'compartilhar-lista/:slug',
    canActivate: [AuthGuard, FilialSemEcommerceGuard, ConviteListaGuard],
    loadChildren: async () =>
      (await import('@vip/container/convite-lista'))
        .ConviteListaContainerModule,
  },
  {
    path: 'filtro',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    loadChildren: async () =>
      (await import('@vip/container/filtro')).FiltroContainerModule,
  },
  {
    path: 'nao-encontrado',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    loadChildren: async () =>
      (await import('@vip/container/nao-encontrado'))
        .NaoEncontradoContainerModule,
  },
  {
    path: 'fale-conosco',
    canActivate: [ExigeAutenticacaoGuard],
    data: { bottomNavigationIsVisible: true },
    loadChildren: async () =>
      (await import('@vip/container/fale-conosco')).FaleConoscoContainerModule,
  },
  {
    path: 'colecoes',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    data: {
      bottomNavigationIsVisible: true,
      breadcrumb: 'Coleções',
    },
    loadChildren: async () =>
      (await import('@vip/container/colecoes')).ColecoesContainerModule,
  },
  {
    path: 'produtos/colecoes/:id/:slug',
    redirectTo: 'colecoes/:id/:slug',
  },
  {
    path: 'colecoes/:id/:slug',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    data: { bottomNavigationIsVisible: true },
    loadChildren: async () =>
      (await import('@vip/container/colecao')).ColecaoContainerModule,
  },

  {
    path: 'tag/:tag/:tag_id',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    data: { bottomNavigationIsVisible: true },
    loadChildren: async () =>
      (await import('@vip/container/tag-produtos')).TagProdutosContainerModule,
  },
  {
    path: 'loja',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
      SiteLojaGuard,
    ],
    data: { bottomNavigationIsVisible: true, breadcrumb: 'Loja' },
    loadChildren: async () =>
      (await import('@vip/container/home-ecommerce'))
        .HomeEcommerceContainerModule,
  },
  {
    path: 'carrinho',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      CompraEmProcessoGuard,
      FilialSemEcommerceGuard,
    ],
    data: {
      bottomNavigationIsVisible: true,
      compraEmProcesso: false,
      breadcrumb: 'Carrinho',
    },
    loadChildren: async () =>
      (await import('@vip/container/carrinho')).CarrinhoContainerModule,
  },
  {
    path: 'produto/:id',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    data: { bottomNavigationIsVisible: true, noReuse: true },
    loadChildren: async () =>
      (await import('@vip/container/produto')).ProdutoContainerModule,
  },
  {
    path: 'produto/:id/:slug',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    data: { bottomNavigationIsVisible: true, noReuse: true },
    loadChildren: async () =>
      (await import('@vip/container/produto')).ProdutoContainerModule,
  },
  {
    path: 'produtos/detalhe/:id',
    redirectTo: 'produto/:id',
  },
  {
    path: 'produtos/detalhe/:id/:slug',
    redirectTo: 'produto/:id/:slug',
  },
  {
    path: 'produtoImagem',
    canActivate: [ExigeAutenticacaoGuard, SelecaoCdGuard],
    loadChildren: async () =>
      (await import('@vip/container/produto-zoom')).ProdutoZoomContainerModule,
  },
  {
    path: 'checkout/carrinho',
    redirectTo: 'resumo-compra',
  },
  {
    path: 'resumo-compra',
    canActivate: [
      AuthGuard,
      SelecaoCdGuard,
      CarrinhoGuard,
      CompraEmProcessoGuard,
      ValidaPinClienteGuard,
      FilialSemEcommerceGuard,
      CompletarCadastroGuard,
    ],
    data: {
      compraEmProcesso: false,
      breadcrumb: {
        label: 'Carrinho',
        fixedParent: {
          label: 'Home',
          link: '/',
        },
      },
    },
    loadChildren: async () =>
      (await import('@vip/container/resumo-compra'))
        .ResumoCompraContainerModule,
  },
  {
    path: 'destaques',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    data: { bottomNavigationIsVisible: true, breadcrumb: 'Destaques' },
    loadChildren: async () =>
      (await import('@vip/container/oferta')).OfertaContainerModule,
  },
  {
    path: 'produtos/ofertas/tags/:tag',
    redirectTo: 'oferta/:tag',
  },
  {
    path: 'oferta/:tag',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
      OfertaExclusivaGuard,
    ],
    data: { bottomNavigationIsVisible: true },
    loadChildren: async () =>
      (await import('@vip/container/oferta')).OfertaContainerModule,
  },

  {
    path: 'ofertas-detalhes/:id',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    data: { bottomNavigationIsVisible: true },
    loadChildren: async () =>
      (await import('@vip/container/ofertas-detalhes'))
        .OfertasDetalhesContainerModule,
  },
  {
    path: 'ofertas',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    data: {
      bottomNavigationIsVisible: true,
      breadcrumb: {
        label: 'Ofertas',
        fixedParent: {
          label: 'Loja',
          link: '/',
        },
      },
    },
    loadChildren: async () =>
      (await import('@vip/container/ofertas')).OfertasContainerModule,
  },
  {
    path: 'resumo-compra/perguntas/:insercao',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      CompraEmProcessoGuard,
      FilialSemEcommerceGuard,
    ],
    data: { compraEmProcesso: false },
    loadChildren: async () =>
      (await import('@vip/container/perguntas')).PerguntasContainerModule,
  },
  {
    path: 'checkout/pagamento',
    redirectTo: 'pagamento',
  },
  {
    path: 'pagamento',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      CompraEmProcessoGuard,
      ValidaPinClienteGuard,
      FilialSemEcommerceGuard,
    ],
    canDeactivate: [ConfirmacaoSaidaPagamentoGuard],
    data: { compraEmProcesso: true },
    loadChildren: async () =>
      (await import('@vip/container/pagamento')).PagamentoContainerModule,
  },

  {
    path: 'pagamento/cartao/:tipo',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      CompraEmProcessoGuard,
      ValidaPinClienteGuard,
      FilialSemEcommerceGuard,
    ],
    data: { compraEmProcesso: true },
    loadChildren: async () =>
      (await import('@vip/container/pagamento')).PagamentoContainerModule,
  },
  {
    path: 'substituir-produto',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    loadChildren: async () =>
      (await import('@vip/container/substituir-produto'))
        .SubstituirProdutoContainerModule,
  },
  {
    path: 'produtos/mais-vendidos/:departamento',
    redirectTo: 'mais-vendidos',
  },
  {
    path: 'mais-vendidos',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    data: {
      bottomNavigationIsVisible: true,
      breadcrumb: {
        label: 'Mais Vendidos',
        fixedParent: {
          label: 'Loja',
          link: '/',
        },
      },
    },
    loadChildren: async () =>
      (await import('@vip/container/mais-vendidos'))
        .MaisVendidosContainerModule,
  },
  {
    path: 'iframe/:iframeId',
    canActivate: [ExigeAutenticacaoGuard],
    data: { bottomNavigationIsVisible: true },
    loadChildren: async () =>
      (await import('@vip/container/iframe')).IframeContainerModule,
  },
  {
    path: 'verificar-celular',
    redirectTo: 'validar-telefone',
  },
  {
    path: 'validar-telefone',
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: async () =>
          (await import('@vip/container/validar-numero-celular'))
            .ValidarNumeroCelularContainerModule,
      },
      {
        path: ':tipo-validacao',
        loadChildren: async () =>
          (await import('@vip/container/validar-numero-celular'))
            .ValidarNumeroCelularContainerModule,
      },
    ],
  },
  {
    path: 'checkout/resumo/:compra',
    redirectTo: 'compra-finalizada/:compra',
  },
  {
    path: 'compra-finalizada/:compra',
    data: { bottomNavigationIsVisible: true },
    loadChildren: async () =>
      (await import('@vip/container/compra-resumo-final'))
        .CompraResumoFinalContainerModule,
  },
  {
    path: 'carrinho-antigo',
    canActivate: [ExigeAutenticacaoGuard, FilialSemEcommerceGuard],
    data: { bottomNavigationIsVisible: false },
    loadChildren: async () =>
      (await import('@vip/container/carrinho-antigo'))
        .CarrinhoAntigoContainerModule,
  },
  {
    path: 'vitrines/:id',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    data: {
      bottomNavigationIsVisible: true,
      breadcrumb: {
        label: 'Vitrines',
        fixedParent: {
          label: 'Loja',
          link: '/',
        },
      },
    },
    loadChildren: async () =>
      (await import('@vip/container/vitrines')).VitrinesContainerModule,
  },
  {
    path: 'produtos-patrocinados',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    data: {
      bottomNavigationIsVisible: true,
      breadcrumb: {
        label: 'Produtos Patrocinados',
        fixedParent: {
          label: 'Loja',
          link: '/',
        },
      },
    },
    loadChildren: async () =>
      (await import('@vip/container/produtos-patrocinados'))
        .ProdutosPatrocinadosContainerModule,
  },
  {
    path: 'minhas-listas/criadas-por-mim',
    redirectTo: 'minha-conta/minhas-listas',
  },
  {
    path: 'minhas-listas/produtos/:lista',
    redirectTo: 'minha-conta/minhas-listas/detalhes/:lista',
  },
  {
    path: 'pagina-nao-encontrada',
    data: { bottomNavigationIsVisible: true },
    loadChildren: async () =>
      (await import('@vip/container/pagina-nao-encontrada'))
        .PaginaNaoEncontradaModule,
  },
  {
    path: 'combos',
    canActivate: [
      ExigeAutenticacaoGuard,
      SelecaoCdGuard,
      FilialSemEcommerceGuard,
    ],
    data: {
      bottomNavigationIsVisible: true,
      breadcrumb: {
        label: 'Combos',
        fixedParent: {
          label: 'Loja',
          link: '/',
        },
      },
    },
    loadChildren: async () =>
      (await import('@vip/container/combo')).ComboContainerModule,
  },
];
