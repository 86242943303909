<div class="flex flex-col h-full">
  <vip-screen-title
    title="Cupons de Desconto"
    leftIcon="icon-arrow_back"
    leftIconColor="secondary"
    [showActions]="true"
  >
  </vip-screen-title>

  <div class="mx-3 mt-3 mb-4">
    <div class="mb-3 vip-color-first-default font-bold">Código Promocional</div>
    <div class="flex justify-between mb-3">
      <vip-field
        icon="confirmation_number"
        iconDirection="left"
        class="flex-1 mr-3"
      >
        <input placeholder="Digite o código promocional" #inputCodigo />
      </vip-field>
      <vip-button
        class="btn-ativar"
        (btnClick)="
          inputCodigo.value
            ? ativarCodigo.emit(inputCodigo.value)
            : $event.preventDefault()
        "
        >Ativar
      </vip-button>
    </div>
    <div
      *ngIf="codigoPromocional?.codigo"
      class="card-codigo-promocional-valido rounded shadow flex items-center justify-between px-3 mb-3"
    >
      <div class="flex items-center vip-color-secondary-default">
        <i class="mr-2 icon-local_activity text-3xl"></i>
        <span class="font-medium text-sm vip-color">{{
          codigoPromocional?.codigo
        }}</span>
      </div>
      <i
        (click)="excluirCodigoPromocional(codigoPromocional?.codigo || '')"
        class="vip-color-error-main icon-delete_outline text-3xl"
        data-cy="vip-excluir-codigo-btn"
      ></i>
    </div>
    <span class="vip-color-secondary-default text-xs">
      Você só pode usar um código promocional por compra.
    </span>
  </div>
  <hr class="vip-divider mx-3 mb-4" />
  <div class="mx-3 mb-3 vip-color-first-default font-bold">Cupons</div>
  <div class="mx-3">
    <vip-cupom
      *ngFor="let cupom of cupons"
      class="mb-2"
      [showCheckIcon]="true"
      [created]="cupom.created"
      [validade]="cupom.validade"
      [checked]="getChecked(cupom)"
      [clickable]="true"
      [disabled]="cupom.validade | expiredDueDate"
      (changed)="onClickCupom($event, cupom)"
    >
      <span class="font-medium">
        {{ cupom.valor | currency }} | {{ cupom.cupom_motivo?.descricao }}
      </span>
    </vip-cupom>
  </div>
  <vip-empty
    *ngIf="cupons.length === 0"
    [image]="imageUrl"
    text="Nenhum cupom encontrado"
  ></vip-empty>
</div>
