import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddToListContainerService } from '@vip/container/add-to-list';
import { AtualizarCarrinhoService } from '@vip/container/carrinho';
import {
  FilterTypeEnum,
  IFilter,
  IFilterOptions,
  LayoutUtilsService,
  PaginaFiltro,
} from '@vip/core';
import { BannerFacade } from '@vip/state/banner';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';
import { FiltroFacade, FiltrosUtilsService } from '@vip/state/filtro';
import {
  ProdutosIndisponiveisService,
  ProdutosIndisponiveisStore,
  ProdutosOfertasStore,
} from '@vip/state/produto';
import { cloneDeep } from 'lodash';
import { combineLatest, Subscription } from 'rxjs';
import { filter, map, skip, take } from 'rxjs/operators';
import { BreadcrumbStore } from '@vip/state/breadcrumb';
import { ClienteFacade } from '@vip/state/cliente';
import { mergeTakeOne } from '@vip/state/utils';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { CampanhaPatrocinadoService } from '@vip/api';
import { ParametrosFacade } from '@vip/state/parametros';
import { ModalService } from '@vip/ui/modal';
import { FiltroContainerComponent } from '@vip/container/filtro';
@UntilDestroy()
@Component({
  selector: 'vip-ofertas-container',
  templateUrl: './ofertas-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProdutosOfertasStore,
    ProdutosIndisponiveisStore,
    ProdutosIndisponiveisService,
  ],
  styles: [
    `
      :host {
        @apply h-full;
      }
    `,
  ],
})
export class OfertasContainerComponent implements OnInit, OnDestroy {
  produtos$ = this.produtosOfertasStore.produtos$;
  paginator$ = this.produtosOfertasStore.paginator$;
  produtosNoCarrinho$ = this.carrinhoItensFacade.produtosNoCarrinho$;
  filtros$ = this.filtroFacade.filtrosOfertas$.pipe(
    map((filtros) =>
      filtros.map((filtro) =>
        filtro.name === 'tipo_oferta'
          ? { ...filtro, options: this.buildFilterOptions(filtro) }
          : filtro
      )
    )
  );
  filtroSubscriber?: Subscription;
  filtroOrdenacao: IFilter = this.filtroFacade.filtroOrdenacao;
  opcaoTodos: IFilterOptions = { value: '0', label: 'Todos', checked: true };
  filtrosOfertas: IFilter[] = [];
  loaded$ = this.produtosOfertasStore.loaded$;
  filterType = FilterTypeEnum.EXTERNAL;
  filterInitialized = false;

  loading$ = combineLatest([
    this.produtosOfertasStore.loading$,
    this.filtroFacade.loaded$.pipe(map((loaded) => !loaded)),
  ]).pipe(map((loadings) => loadings.some((loading) => loading)));

  bannersOfertas$ = this.bannerFacade.bannersOfertas$;
  isSite = this.layoutUtilsService.isDesktopWithoutScreenWidth();
  isResponsivo = this.layoutUtilsService.isDesktopResponsive();
  filtros: IFilter[] = [];
  breadcrumbs$ = this.breadcrumbStore.breadcrumbs$;
  isLogged$ = this.clienteFacade.isLogged$;
  orderingOptions = this.filtroFacade.filtroOrdenacao.options;
  currentOrderOption?: IFilterOptions;
  adsParamValue: boolean | null = false;
  filtroAplicado =
    this.router.getCurrentNavigation()?.extras.state?.filtroAplicado;
  constructor(
    private produtosOfertasStore: ProdutosOfertasStore,
    public filtroFacade: FiltroFacade,
    public atualizarCarrinhoService: AtualizarCarrinhoService,
    public produtosIndisponiveisService: ProdutosIndisponiveisService,
    public addToListContainerService: AddToListContainerService,
    private carrinhoItensFacade: CarrinhoItensFacade,
    private bannerFacade: BannerFacade,
    private layoutUtilsService: LayoutUtilsService,
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbStore: BreadcrumbStore,
    private clienteFacade: ClienteFacade,
    private cdFacade: CentroDistribuicaoFacade,
    public campanhaPatrocinadoService: CampanhaPatrocinadoService,
    private parametrosFacade: ParametrosFacade,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
    private filtrosUtilsService: FiltrosUtilsService
  ) {
    this.filtros$.pipe(untilDestroyed(this)).subscribe((filtros) => {
      this.filtros = cloneDeep(filtros);
    });
  }

  atualizarProdutos(filtro: string, resetState = true) {
    if (resetState) this.produtosOfertasStore.resetState();
    this.produtosOfertasStore.getTodasOfertas({
      filtro,
    });
    this.handleFirstPageFromQueryParams(this.produtosOfertasStore);
  }

  handleFirstPageFromQueryParams = (store: ProdutosOfertasStore) => {
    const params = this.route.snapshot.queryParams;
    if (!Object.prototype.hasOwnProperty.call(params, 'page')) return;
    const page = params?.page;
    if (page) store.goToPage(parseInt(page));
  };

  ngOnInit(): void {
    if (!this.filtroAplicado) {
      this.setFiltrosIniciais();
    }
    this.subscribeAtualizarListagem();
    this.bannerFacade.getBannersOfertas();
    this.subscribePageChange();
    if (!this.filtroAplicado) {
      this.produtosOfertasStore.getTodasOfertas({ filtro: '' });
    }
    this.cdFacade.cd$.pipe(untilDestroyed(this), skip(1)).subscribe(() => {
      this.produtosOfertasStore.getTodasOfertas({ filtro: '' });
    });

    this.parametrosFacade.adsParams$
      .pipe(
        filter((value) => value !== undefined),
        untilDestroyed(this)
      )
      .subscribe((facadeValue) => {
        this.adsParamValue = facadeValue;
        this.cd.detectChanges();
      });
  }

  ngOnDestroy() {
    if (!this.isSite) {
      this.filtroOrdenacao = this.filtroFacade.filtroOrdenacao;
      this.produtosOfertasStore.resetState();
      this.filtroFacade.aplicarFiltro([], PaginaFiltro.ofertas);
    }
  }

  setFiltrosIniciais() {
    this.filtroFacade.getFiltrosOfertas();
  }

  externalFilterClick({ value }: IFilterOptions) {
    this.filtroFacade.filtrosOfertas$
      .pipe(take(1), untilDestroyed(this))
      .subscribe((filtros) => {
        this.setFilters(filtros, value);
      });
  }

  onFilterClick() {
    if (!this.isSite) {
      this.filtroFacade.abrirFiltro(PaginaFiltro.ofertas);
    } else {
      this.filtrosUtilsService.abrirModalFiltros(
        PaginaFiltro.ofertas,
        FiltroContainerComponent
      );
    }
  }

  loadMore() {
    this.produtosOfertasStore.nextPage();
  }

  onChangeFiltro(filtros: IFilter[], resetState = true) {
    const filtro = this.filtroFacade.getQueryParams(filtros);

    if (!filtro.includes('orderby=') && this.currentOrderOption) {
      this.filtroOrdenacao.options = this.filtroOrdenacao.options.map(
        (option) => ({ ...option, checked: false })
      );
      this.currentOrderOption = undefined;

      if (!this.isResponsivo) {
        this.filtroFacade.aplicarFiltro(
          [...filtros, { ...this.filtroOrdenacao }],
          PaginaFiltro.ofertas
        );
      }
      return;
    }
    filtros.forEach((filtroSelecionado) => {
      const index = this.filtros.findIndex(
        (filtro) => filtro.name === filtroSelecionado.name
      );
      if (index >= 0) this.filtros[index].options = filtroSelecionado.options;
    });

    this.atualizarProdutos(filtro, resetState);
  }

  atualizarFiltros(filtrosOfertas: IFilter, value: string) {
    filtrosOfertas.options.forEach((opcao: IFilterOptions) => {
      opcao.checked = opcao.value === value;
    });
  }

  subscribeAtualizarListagem() {
    if (!this.filtroSubscriber) {
      this.filtroSubscriber = this.filtroFacade.filtrosOfertas$
        .pipe(
          filter((filtros) => filtros.length > 1),
          untilDestroyed(this),
          mergeTakeOne(this.paginator$)
        )
        .subscribe(([filtros, paginator]) => {
          this.onChangeFiltro(filtros, paginator.page === 1);
        });
    }
  }

  goToPage(page: number) {
    this.ngZone.run(() => {
      if (page === 1) {
        this.router.navigate([]);
      } else {
        this.router.navigate([], { queryParams: { page } });
      }
    });
  }

  subscribePageChange() {
    if (this.isSite) {
      this.route.queryParamMap
        .pipe(untilDestroyed(this))
        .subscribe((params) => {
          const page = params.get('page');
          if (page) {
            this.produtosOfertasStore.goToPage(parseInt(page));
          } else {
            this.produtosOfertasStore.resetState();
          }
        });
    }
  }

  setFilters(filtros: IFilter[], currentValue?: string) {
    const filtrosOfertas: IFilter = filtros.length
      ? JSON.parse(JSON.stringify(filtros[0]))
      : this.filtroOrdenacao;

    filtrosOfertas.options = this.buildFilterOptions(filtrosOfertas);

    if (currentValue) this.atualizarFiltros(filtrosOfertas, currentValue);

    if (filtrosOfertas === this.filtroOrdenacao) {
      this.filtroFacade.aplicarFiltro(
        [this.filtroOrdenacao],
        PaginaFiltro.ofertas
      );
    } else {
      this.filtroFacade.aplicarFiltro(
        [filtrosOfertas, this.filtroOrdenacao],
        PaginaFiltro.ofertas
      );
    }
  }

  buildFilterOptions(filtro: IFilter): IFilterOptions[] {
    if (!filtro) return [];
    if (filtro.options.length && filtro.options[0].label === 'Todos')
      return filtro.options;

    const todos: IFilterOptions = this.opcaoTodos;

    const options = filtro.options;

    if (
      (!this.filterInitialized && this.isResponsivo) ||
      (!this.filterInitialized && !this.isSite)
    ) {
      this.filterInitialized = true;
      Promise.resolve().then(() => {
        this.externalFilterClick(this.opcaoTodos);
      });
    }

    return [todos].concat(options);
  }

  onBreadCrumbClick(link: string) {
    this.ngZone.run(() => {
      this.router.navigateByUrl(link);
    });
  }

  handleOrderFilterClick(event: IFilterOptions): void {
    this.currentOrderOption = event;
    this.filtroOrdenacao.options = this.filtroOrdenacao.options.map(
      (option) => ({ ...option, checked: option.value === event.value })
    );
    const orderFilterIndex = this.filtros.findIndex(
      (filtro) => filtro.name === 'orderby'
    );

    if (orderFilterIndex >= 0) {
      this.filtros.splice(orderFilterIndex, 1);
    }
    this.filtroFacade.aplicarFiltro(
      [...this.filtros, { ...this.filtroOrdenacao }],
      PaginaFiltro.ofertas
    );
    this.atualizarProdutos(
      this.filtroFacade.getQueryParams([
        ...this.filtros,
        { ...this.filtroOrdenacao, options: [event] },
      ])
    );
  }
}
