import {
  ChangeDetectorRef,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';

import { differenceInSeconds, intervalToDuration } from 'date-fns';

@Component({
  selector: 'vip-countdown-bar',
  templateUrl: './countdown-bar.component.html',
  styleUrls: ['./countdown-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownBarComponent implements AfterViewInit {
  @ViewChild('progressBar') progressBarEl!: ElementRef;

  constructor(private changeDetector: ChangeDetectorRef) {}

  private _timeTotal!: number;

  @Input()
  get timeTotal(): number {
    return this._timeTotal;
  }
  set timeTotal(timeTotal) {
    this._timeTotal = timeTotal;
    this.progress();
  }

  @Input()
  timeLeft!: number;

  @Input()
  showText = true;

  @Output() finished = new EventEmitter();
  @Output() decreased = new EventEmitter();

  width!: number;
  bluredTimeLeft = 0;
  bluredLatestDate?: Date;

  @HostListener('window:focus', ['$event'])
  onFocus(): void {
    if (!this.bluredLatestDate) return;
    const difference = differenceInSeconds(new Date(), this.bluredLatestDate);
    const newTimeLeft = this.bluredTimeLeft - difference;
    if (newTimeLeft < this.timeLeft) this.timeLeft = newTimeLeft;
  }
  @HostListener('window:blur', ['$event'])
  onBlur(): void {
    this.bluredTimeLeft = this.timeLeft;
    this.bluredLatestDate = new Date();
  }

  ngAfterViewInit(): void {
    window.focus();
    this.progress();
  }

  progress() {
    if (this.progressBarEl) {
      const barElementWidth = this.progressBarEl.nativeElement.offsetWidth;

      this.width = (this.timeLeft * barElementWidth) / this.timeTotal;
      this.changeDetector.markForCheck();
      if (this.timeLeft > 0) {
        setTimeout(() => {
          this.timeLeft -= 1;
          this.decreased.emit();
          this.progress();
        }, 1000);
      } else {
        this.finished.emit();
      }
    }
  }

  getRemainingTime() {
    const duration = intervalToDuration({
      start: 0,
      end: this.timeLeft * 1000,
    });
    return `${duration.minutes}min ${duration.seconds}seg`;
  }
}
