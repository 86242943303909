import { IAssunto } from '../interfaces/assunto.interface';

export const assunto1Mock = {
  id: 1,
  filial_id: 1,
  descricao: 'Dúvidas, Reclamações ou Sugestões',
  email: 'contato@vipcommerce.com.br',
  data_inicial: new Date('2020-06-18T00:00:00+00:00'),
  data_final: new Date('2030-06-18T00:00:00+00:00'),
  created: new Date('2020-05-22T10:31:03-03:00'),
  modified: new Date('2020-06-18T14:13:36-03:00'),
} as IAssunto;

export const assunto2Mock = {
  id: 2,
  filial_id: 1,
  descricao: 'Informações sobre pedidos em andamento',
  email: 'contato@vipcommerce.com.br',
  data_inicial: new Date('2020-06-18T00:00:00+00:00'),
  data_final: new Date('2030-06-25T00:00:00+00:00'),
  created: new Date('2020-05-22T10:31:03-03:00'),
  modified: new Date('2020-06-18T14:14:00-03:00'),
} as IAssunto;

export const assunto3Mock = {
  id: 3,
  filial_id: 1,
  descricao: 'Canal direto - Fale com o Diretor',
  email: 'contato@vipcommerce.com.br',
  data_inicial: new Date('2020-06-18T00:00:00+00:00'),
  data_final: new Date('2030-06-25T00:00:00+00:00'),
  created: new Date('2020-05-22T10:31:03-03:00'),
  modified: new Date('2020-06-18T14:14:25-03:00'),
} as IAssunto;
