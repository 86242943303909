import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { FilialFacade } from '@vip/state/filial';

@Component({
  selector: 'vip-manutencao-container',
  templateUrl: './manutencao-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManutencaoContainerComponent {
  filial$ = this.filialFacade.filial$;
  error;

  constructor(private filialFacade: FilialFacade, private router: Router) {
    this.error = {
      rota: this.router.getCurrentNavigation()?.extras.state?.rota,
      error: this.router.getCurrentNavigation()?.extras.state?.error,
    };
  }
}
