import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromTelevendas from './+state/televendas.reducer';
import { TelevendasEffects } from './+state/televendas.effects';
import { TelevendasFacade } from './+state/televendas.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromTelevendas.TELEVENDAS_FEATURE_KEY,
      fromTelevendas.televendasReducer
    ),
    EffectsModule.forFeature([TelevendasEffects]),
  ],
  providers: [TelevendasFacade],
})
export class TelevendasStateModule {}
