import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PageService } from './services/page.service';

@Component({
  selector: 'vip-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageComponent {
  @Input() title = '';
  @Input() text = '';

  constructor(
    public sanitizer: DomSanitizer,
    public pageService: PageService
  ) {}
}
