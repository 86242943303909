import { ComponentRef, EventEmitter, Injectable } from '@angular/core';
import { ComponentInjectorService } from '@vip/core';
import { DialogComponent } from './../dialog.component';
import { IDialog } from './dialog.interface';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  public dialogComponent: ComponentRef<DialogComponent> | undefined;

  public dialogClick = new EventEmitter();
  public closeClick = new EventEmitter();

  constructor(
    private componentInjector: ComponentInjectorService<DialogComponent>
  ) {}

  openDialog(dialogData: IDialog, closeOnClick?: boolean) {
    this.dialogClick = new EventEmitter();
    this.closeClick = new EventEmitter();

    this.dialogComponent =
      this.componentInjector.createComponentInApplication(DialogComponent);
    this.dialogComponent.changeDetectorRef.detectChanges();

    this.dialogComponent.instance.dialogClick.subscribe((action: boolean) => {
      this.dialogClick.emit(action);

      const observers = this.dialogClick.observers;
      this.dialogClick.observers = observers;

      if (closeOnClick) this.clearDialog();
    });

    this.dialogComponent.instance.closeClick.subscribe(() => {
      this.closeClick.emit();

      const observers = this.closeClick.observers;
      this.closeClick.observers = observers;
    });

    this.dialogComponent.instance.open = dialogData.open;
    this.dialogComponent.instance.title = dialogData.title;
    this.dialogComponent.instance.disabled = dialogData.disabled;
    this.dialogComponent.instance.bottom = !!dialogData.bottom;
    this.dialogComponent.instance.subTitle = dialogData.subTitle || '';
    this.dialogComponent.instance.buttonConfirmText =
      dialogData.buttonConfirmText || '';
    this.dialogComponent.instance.buttonConfirmIcon =
      dialogData.buttonConfirmIcon || '';
    this.dialogComponent.instance.buttonCancelText =
      dialogData.buttonCancelText || '';
    this.dialogComponent.instance.buttonCancelIcon =
      dialogData.buttonCancelIcon || '';
    this.dialogComponent.instance.showCloseButton =
      dialogData.showCloseButton !== undefined
        ? dialogData.showCloseButton
        : true;
    this.dialogComponent.changeDetectorRef.detectChanges();
  }

  clearDialog() {
    return new Promise((resolve) => {
      if (this.dialogComponent) {
        this.dialogComponent.instance.open = false;
        this.dialogComponent.changeDetectorRef.detectChanges();
        this.dialogClick.complete();
        this.closeClick.complete();
        setTimeout(() => {
          if (!this.dialogComponent) return;
          this.componentInjector.destroyComponentInApplication(
            this.dialogComponent
          );
          this.dialogComponent = undefined;
          resolve(true);
        }, 250);
      } else {
        resolve(true);
      }
    });
  }

  isOpened() {
    return this.dialogComponent?.instance?.open;
  }

  canClose() {
    return (
      this.dialogComponent?.instance && !this.dialogComponent.instance.disabled
    );
  }

  clearIfAllowed() {
    if (this.canClose()) {
      this.clearDialog();
    }
  }
}
