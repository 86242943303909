<div
  class="tab-item-horizontal"
  (click)="onTabClick()"
  [ngClass]="{
    selected: selected,
    disabled: disabled
  }"
  vipRipple
  [rippleColor]="rippleColor"
  [rippleDisabled]="disabled"
  rippleBorderRadius="0px"
>
  <div class="hover-wrapper"></div>
  <i
    *ngIf="icon"
    class="mr-2 {{ iconColor ? iconColor : color }} no-line-height "
    [ngClass]="[icon]"
  ></i>
  <div class="flex flex-col">
    <span class="text-sm label {{ color }}">
      {{ label }} <b>{{ count }}</b>
    </span>
    <span *ngIf="disabled" class="text-tiny label vip-color-error-main mt-1">
      {{ disabledText }}
    </span>
  </div>
</div>
