import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../common/base-api-service';
import {
  AddRespostasResponse,
  GetPerguntasResponse,
} from './perguntas-api.service.types';
import { IClientePerguntaResposta } from '@vip/core';

@Injectable({
  providedIn: 'root',
})
export class PerguntasApiService {
  constructor(private api: BaseApiService<PerguntasApiService>) {}

  getPerguntas(
    filialId: number,
    cdId?: number
  ): Observable<GetPerguntasResponse> {
    let route = `/filial/${filialId}/loja/clientes/perguntas`;
    if (cdId) route += `/cd/${cdId}`;
    return this.api.get<GetPerguntasResponse>('getPerguntas', `${route}`);
  }

  salvarRespostas(
    filialId: number,
    respostas: IClientePerguntaResposta[]
  ): Observable<AddRespostasResponse> {
    const route = `/filial/${filialId}/loja/clientes/perguntas/respostas/adicionar`;
    return this.api.post<IClientePerguntaResposta[], AddRespostasResponse>(
      'getPerguntas',
      `${route}`,
      respostas
    );
  }
}
