import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  ICliente,
  IFilial,
  IFilialTelevendas,
  IOperadorTelevendas,
} from '@vip/core';
import { IWsPaging } from '@vip/ws';

export const logarTelevendas = createAction(
  '[Televendas Page] Logar',
  props<{
    id: string;
    password: string;
  }>()
);

export const logarTelevendasSuccess = createAction(
  '[Televendas/API] Logar Televendas Success',
  props<{ token: string; operador: IOperadorTelevendas }>()
);

export const logarTelevendasFailure = createAction(
  '[Televendas/API] Logar Televendas Failure',
  props<{ error: HttpErrorResponse }>()
);

export const setOperador = createAction(
  '[Televendas Page] Set Operador Televendas',
  props<{ operador: IOperadorTelevendas }>()
);

export const setToken = createAction(
  '[Televendas Page] Set Token Televendas',
  props<{ token: string }>()
);

export const encerrarSessao = createAction(
  '[Televendas Page] Encerrar Sessão Televendas'
);

export const getFiliaisTelevendas = createAction(
  '[Televendas/API] Get Filiais televendas'
);

export const getFiliaisTelevendasSuccess = createAction(
  '[Televendas/API] Get Filiais success televendas',
  props<{ filiais: IFilialTelevendas[] }>()
);

export const getFiliaisTelevendasFailure = createAction(
  '[Televendas/API] Get Filiais failure televendas',
  props<{ error: HttpErrorResponse }>()
);

export const getClientesTelevendas = createAction(
  '[Televendas/API] Get Clientes Televendas',
  props<{ term: string }>()
);

export const getClientesTelevendaSuccess = createAction(
  '[Televendas/API] Get Clientes Televendas Success',
  props<{ clientes: ICliente[]; paging: IWsPaging }>()
);

export const getClientesTelevendasFailure = createAction(
  '[Televendas/API] Get Clientes Televendas Failure',
  props<{ error: HttpErrorResponse }>()
);

export const logarClienteTelevendas = createAction(
  '[Televendas Page] Logar cliente Televendas',
  props<{
    cliente_id: string;
    filial_id: string;
  }>()
);

export const logarClienteTelevendasSuccess = createAction(
  '[Televendas/API] Logar cliente Televendas Success',
  props<{ token: string; operador: IOperadorTelevendas }>()
);

export const logarClienteTelevendasFailure = createAction(
  '[Televendas/API] Logar cliente Televendas Failure',
  props<{ error: HttpErrorResponse }>()
);

export const vincularFilialTelevendas = createAction(
  '[Televendas Page] Vincular Filial Televendas',
  props<{
    id: number;
    filial_id: number;
  }>()
);

export const vincularFilialTelevendasSuccess = createAction(
  '[Televendas/API] Vincular Filial Televendas Success',
  props<{
    token: string;
    operador: IOperadorTelevendas;
  }>()
);

export const vincularFilialTelevendasFailure = createAction(
  '[Televendas/API] Vincular Filial Televendas Failure',
  props<{ error: HttpErrorResponse }>()
);
