import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'vip-base-component-skeleton',
  template: `
    <div *ngFor="let loading of [].constructor(quantity); let odd = odd">
      <ng-container
        *ngTemplateOutlet="skeletonContentTemplate; context: { $implicit: odd }"
      ></ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.flex-wrap]': 'listDirection === "vertical"',
  },
})
export class BaseComponentSkeletonComponent {
  @Input() quantity = 6;
  @Input() listDirection: 'horizontal' | 'vertical' = 'horizontal';
  @ContentChild('skeletonContent')
  skeletonContentTemplate!: TemplateRef<HTMLElement>;
}
