<div class="grid grid-cols-12 p-4">
  <div class="col-span-3 flex">
    <div class="flex flex-col">
      <div
        *vipSkeleton="true; width: '168px'; height: '18px'; className: ['mb-3']"
      ></div>
      <vip-base-component-skeleton [quantity]="4">
        <ng-template #skeletonContent>
          <div
            *vipSkeleton="
              true;
              width: '138px';
              height: '14px';
              className: ['mb-2']
            "
          ></div>
        </ng-template>
      </vip-base-component-skeleton>
    </div>

    <hr class="vip-divider-vertical whitespace-nowrap h-full ml-4" />
  </div>

  <div class="flex flex-col col-span-9 ml-4">
    <div
      *vipSkeleton="true; width: '65px'; height: '18px'; className: ['mb-3']"
    ></div>

    <vip-base-component-skeleton [quantity]="5">
      <ng-template #skeletonContent>
        <div class="mb-2 grid grid-cols-12">
          <div
            *vipSkeleton="
              true;
              width: '49px';
              height: '49px';
              radius: '5px';
              className: ['mr-3', 'col-span-2']
            "
          ></div>

          <div
            *vipSkeleton="
              true;
              width: '157px';
              height: '16px';
              className: [
                'mr-3',
                'col-span-3',
                'flex',
                'justify-center',
                'items-center'
              ]
            "
          ></div>

          <div
            *vipSkeleton="
              true;
              width: '39px';
              height: '14px';
              className: [
                'mr-3',
                'col-span-3',
                'flex',
                'justify-center',
                'items-center'
              ]
            "
          ></div>

          <div
            *vipSkeleton="
              true;
              width: '183px';
              height: '34px';
              radius: '5px';
              className: ['col-span-4']
            "
          ></div>
        </div>
      </ng-template>
    </vip-base-component-skeleton>
  </div>
</div>
