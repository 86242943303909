import { TipoOfertaEnum } from '../enums/tipo-oferta.enum';
import { IOferta } from './../interfaces/oferta.interface';
import { faixaPrecoMock5, faixasPrecoArrayMock } from './faixa-preco.mock';

export const ofertaPrecoVipMock: IOferta = {
  categoria: 'G',
  classe_oferta: 'preco-vip',
  exibe_preco_original: true,
  faixas_precos: null,
  familia: false,
  menor_preco: 7.99,
  nome: 'Preço VIP',
  oferta_id: 111,
  oferta_item_id: '613a6648-165c-4738-ba5f-3a24ac140234',
  preco_antigo: 9.99,
  preco_antigo_fracao: 18.98,
  preco_oferta: 8.99,
  preco_oferta_fracao: 15.98,
  preco_original: 18.98,
  quantidade_maxima: 99,
  quantidade_minima: 1,
  quantidade_oferta: 1,
  quantidade_pagar: 1,
  tag: 'preco-vip',
  tipo_oferta_id: TipoOfertaEnum.PRODUTO_COM_DESCONTO,
};

export const ofertaMaisPorMenosMock: IOferta = {
  ...ofertaPrecoVipMock,
  classe_oferta: 'mais-por-menos',
  nome: 'Mais por menos',
  oferta_id: 222,
  tag: 'mais-por-menos',
  quantidade_minima: 2,
  tipo_oferta_id: TipoOfertaEnum.PRODUTO_COM_DESCONTO,
};

export const ofertaAtacarejoMock: IOferta = {
  ...ofertaPrecoVipMock,
  classe_oferta: 'oferta-atacarejo',
  faixas_precos: faixasPrecoArrayMock,
  nome: 'Oferta atacarejo',
  oferta_id: 333,
  tag: 'oferta-atacarejo',
  tipo_oferta_id: TipoOfertaEnum.OFERTA_ATACAREJO,
};

export const ofertaAtacarejoUmaFaixaMock: IOferta = {
  ...ofertaPrecoVipMock,
  classe_oferta: 'oferta-atacarejo',
  faixas_precos: [faixaPrecoMock5],
  nome: 'Oferta atacarejo',
  oferta_id: 444,
  tag: 'oferta-atacarejo',
  tipo_oferta_id: TipoOfertaEnum.OFERTA_ATACAREJO,
};

export const ofertaLevePagueMock: IOferta = {
  ...ofertaPrecoVipMock,
  classe_oferta: 'leve-e-pague',
  nome: 'Leve e pague',
  quantidade_minima: 5,
  quantidade_pagar: 4,
  oferta_id: 555,
  tag: 'leve-e-pague',
  tipo_oferta_id: TipoOfertaEnum.LEVE_E_PAGUE,
};

export const ofertaComboMock: IOferta = {
  ...ofertaPrecoVipMock,
  classe_oferta: 'combo-vip',
  nome: 'Combo VIP',
  oferta_id: 666,
  quantidade_minima: 2,
  tag: 'combo-vip',
  tipo_oferta_id: TipoOfertaEnum.COMBO_DE_PRODUTOS,
};

export const ofertaCashbackMock: IOferta = {
  ...ofertaPrecoVipMock,
  classe_oferta: 'oferta-cashback',
  nome: '20% de cashback',
  oferta_id: 777,
  tag: 'oferta-cashback',
  tipo_oferta_id: TipoOfertaEnum.COMBO_DE_PRODUTOS,
};

export const ofertaCategoriaDeProdutosMock: IOferta = {
  ...ofertaPrecoVipMock,
  oferta_id: 888,
  quantidade_minima: 1,
  tipo_oferta_id: TipoOfertaEnum.CATEGORIA_DE_PRODUTOS,
};
