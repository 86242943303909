import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as CarrinhoActions from './carrinho.actions';
import { carrinhoSelectors } from './carrinho.selectors';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ICarrinho } from '@vip/core';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class CarrinhoFacade {
  loading$ = this.store.pipe(select(carrinhoSelectors.isLoading()));
  loaded$ = this.store.pipe(select(carrinhoSelectors.isLoaded()));
  carrinho$ = this.store.pipe(
    select(carrinhoSelectors.getData()),
    filter((carrinho): carrinho is ICarrinho => carrinho !== null),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
  );

  qtdItensCarrinho$ = this.store.pipe(
    select(carrinhoSelectors.getData()),
    map((carrinho: ICarrinho | null) => carrinho?.quantidade || 0)
  );
  itensCarrinhoAntigo$ = this.store.pipe(
    select(carrinhoSelectors.getItensCarrinhoAntigo())
  );

  carrinhoEmOutroCD$ = this.store.pipe(
    select(carrinhoSelectors.getCarrinhoEmOutroCD())
  );

  removeItemSuccess$ = this.actions$.pipe(
    ofType(CarrinhoActions.removeCarrinhoSuccess)
  );

  verificarItens$ = this.actions$.pipe(
    ofType(
      CarrinhoActions.verificaItensDesativadosSuccess,
      CarrinhoActions.verificaItensDesativadosFailure
    )
  );

  constructor(private store: Store, private actions$: Actions) {}

  getCarrinho(enviaGa4 = false) {
    this.store.dispatch(CarrinhoActions.getCarrinho({ enviaGa4 }));
  }

  getAllCarrinho() {
    this.store.dispatch(CarrinhoActions.getAllCarrinho());
  }

  setCarrinho(carrinho: ICarrinho) {
    this.store.dispatch(
      CarrinhoActions.setCarrinho({
        carrinho,
      })
    );
  }

  removerCarrinho(carrinhoId: number) {
    this.store.dispatch(CarrinhoActions.removeCarrinho({ carrinhoId }));
  }

  resetCarrinho() {
    this.store.dispatch(CarrinhoActions.resetCarrinho());
  }

  manterCarrinhoCliente() {
    this.store.dispatch(CarrinhoActions.manterCarrinhoCliente());
  }

  manterCarrinhoAnonimo() {
    this.store.dispatch(CarrinhoActions.manterCarrinhoAnonimo());
  }

  verificaItensDesativados() {
    this.store.dispatch(CarrinhoActions.verificaItensDesativados());
  }
}
