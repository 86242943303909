import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvironmentService } from '@vip/core';
import { IApiMessages } from './base-api-messages';

@Injectable({
  providedIn: 'root',
})
export class BaseApiService<T> {
  get defaultHeaders() {
    const defaultHeaders: { [key: string]: string } = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      DomainKey: this.environmentService.getDomainKey(),
      OrganizationId: String(this.environmentService.getOrgId()),
    };
    return defaultHeaders;
  }
  private messages?: IApiMessages<T>;

  constructor(
    protected http: HttpClient,
    private environmentService: EnvironmentService
  ) {}

  filialAndCdUrl(filialId: number, cdId: number) {
    return `/filial/${filialId}/centro_distribuicao/${cdId}/loja`;
  }

  public setMessages(messages: IApiMessages<T>) {
    this.messages = messages;
  }

  public getMessages(): IApiMessages<T> | undefined {
    return this.messages;
  }

  public getApiUrl() {
    return this.environmentService.getApiUrl();
  }

  public getOrgUrl() {
    const orgId = this.environmentService.getOrgId();
    return `/org/${orgId}`;
  }

  public getBaseUrl() {
    return this.getApiUrl() + this.getOrgUrl();
  }

  public post<Request, Response>(
    method: keyof T,
    route: string,
    body?: Request,
    headers?: { [key: string]: string }
  ) {
    return this.http
      .post(`${this.getBaseUrl()}${route}`, body, {
        headers: {
          ...this.defaultHeaders,
          ...headers,
        },
      })
      .pipe(this.errorCodePipe(method)) as Observable<Response>;
  }

  public put<Request, Response>(
    method: keyof T,
    route: string,
    id: string,
    body?: Request,
    headers?: { [key: string]: string }
  ) {
    return this.http
      .put(`${this.getBaseUrl()}${route}/${id}`, body, {
        headers: {
          ...this.defaultHeaders,
          ...headers,
        },
      })
      .pipe(this.errorCodePipe(method)) as Observable<Response>;
  }

  public get<Response>(
    method: keyof T,
    route: string,
    headers?: { [key: string]: string }
  ) {
    return this.http
      .get(`${this.getBaseUrl()}${route}`, {
        headers: {
          ...this.defaultHeaders,
          ...headers,
        },
      })
      .pipe(this.errorCodePipe(method)) as Observable<Response>;
  }

  public delete<Response>(
    method: keyof T,
    route: string,
    headers?: { [key: string]: string }
  ) {
    return this.http
      .delete(`${this.getBaseUrl()}${route}`, {
        headers: {
          ...this.defaultHeaders,
          ...headers,
        },
      })
      .pipe(this.errorCodePipe(method)) as Observable<Response>;
  }

  private errorCodePipe = (method: keyof T) =>
    catchError((err: HttpErrorResponse) => {
      if (err.error)
        err.error.errorCode = this.messages?.getErrorCode(err, method);
      return throwError(err);
    });
}
