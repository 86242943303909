import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  BUCKETS_FEATURE_KEY,
  bucketsAdapter,
  BucketsState,
} from './buckets.reducer';
import { BucketModel } from '@vip/core';

export const getBucketsState =
  createFeatureSelector<BucketsState>(BUCKETS_FEATURE_KEY);

const { selectEntities } = bucketsAdapter.getSelectors();

export const getBucketsEntities = createSelector(
  getBucketsState,
  (state: BucketsState) => selectEntities(state)
);

export const getBucketProduto = createSelector(
  getBucketsEntities,
  (entities) => entities[BucketModel.Produto]?.localizacao
);

export const getBucketS3 = createSelector(
  getBucketsEntities,
  (entities) => entities[BucketModel.S3]?.localizacao
);

export const getBucketPlaceholder = createSelector(
  getBucketsEntities,
  (entities) => entities[BucketModel.Placeholder]?.localizacao
);

export const getBucketCategoria = createSelector(
  getBucketsEntities,
  (entities) => entities[BucketModel.Categoria]?.localizacao
);
