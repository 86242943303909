<div
  class="vip-meus-dados-container flex flex-col flex-grow"
  [ngClass]="{ 'overflow-hidden': isDesktop }"
>
  <vip-screen-title
    [title]="title"
    leftIcon="icon-arrow_back"
    leftIconColor="secondary"
  ></vip-screen-title>

  <div
    class="flex flex-col flex-grow mt-2 mx-3"
    [ngClass]="{ 'mx-0': isDesktop }"
  >
    <div
      class="flex-grow flex flex-col"
      [ngClass]="{ 'flex-col': !isModal, 'flex-col-reverse': isModal }"
    >
      <p
        *ngIf="tipoDeEdicao === TipoDeEdicaoMeusDados.Ambos && !isModal"
        class="text-base mt-1"
      >
        Dados Comerciais
      </p>
      <vip-form-pessoa-juridica
        #formPessoaJuridica
        *ngIf="
          tipoDeEdicao === TipoDeEdicaoMeusDados.PessoaJuridica ||
          tipoDeEdicao === TipoDeEdicaoMeusDados.Ambos
        "
        [isEdit]="true"
        [displayGrid]="isDesktop"
        [isModal]="isModal"
      ></vip-form-pessoa-juridica>
      <p
        *ngIf="tipoDeEdicao === TipoDeEdicaoMeusDados.Ambos"
        class="mt-4"
        [ngClass]="{
          'mb-4 text-lg font-bold vip-color-secondary-default': isModal,
          'text-base': !isModal
        }"
      >
        {{ isModal ? 'Dados comerciais' : 'Dados Pessoais' }}
      </p>

      <hr
        *ngIf="tipoDeEdicao === TipoDeEdicaoMeusDados.Ambos && isModal"
        class="vip-divider"
      />

      <vip-form-pessoa-fisica
        #formPessoaFisica
        *ngIf="
          tipoDeEdicao === TipoDeEdicaoMeusDados.PessoaFisica ||
          tipoDeEdicao === TipoDeEdicaoMeusDados.Ambos
        "
        [exibeSexo]="exibeSexo"
        [isEdit]="true"
        [isDesktop]="isDesktop"
        [isModal]="isModal"
        [ngClass]="{ 'mb-4': isModal }"
      ></vip-form-pessoa-fisica>
    </div>
    <div
      *ngIf="!isModal"
      class="py-3"
      [ngClass]="{
        'flex justify-end mt-4 lg:mt-0': isDesktop
      }"
    >
      <vip-button
        *ngIf="isDesktop"
        class="py-3 vip-button vip-button-medium rounded"
        type="outline"
        (click)="handleCancel()"
      >
        Cancelar
      </vip-button>
      <vip-button
        class="py-3 md:ml-4 lg:ml-4 vip-button vip-button-medium rounded"
        type="raised"
        (click)="handleSave()"
        [disabled]="isDesktop && isSaveDisabled()"
      >
        Salvar
      </vip-button>
    </div>
  </div>
</div>
