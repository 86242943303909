<div
  *ngIf="combo"
  class="com-tag"
  [ngClass]="{
    'w-max': hasProductInfo && !isTablet && !fullPage,
    'vip-card-produto-combo': !hasProductInfo,
    'vip-card-produto-combo-info': hasProductInfo,
    'w-[100%]': !isDesktop && !isTablet,
    'w-full': isTablet || fullPage
  }"
>
  <div
    class="flex justify-between"
    [ngClass]="{
      'w-full': !hasProductInfo
    }"
  >
    <vip-tag
      *ngIf="showTagOferta"
      [classeOferta]="combo.classe_oferta"
      [label]="combo.nome || ''"
      [tag]="combo.tag"
      [hasCombo]="true"
      class="w-full"
    ></vip-tag>

    <div
      *ngIf="!hasProductInfo"
      class="flex items-center justify-end w-full mb-[7px] pr-3 mt-2"
    >
      <button
        (click)="handleDeleteItemClick(combo)"
        class="icon-delete_outline text-xl text-red-500 cursor-pointer"
      ></button>
    </div>
  </div>

  <div class="flex justify-center flex-row items-center w-full">
    <div
      [ngClass]="{
        'pt-[6px] card-full': hasProductInfo,
        'flex justify-between w-full': isDesktop || isTablet,
        'pb-0 w-full full-card-mobile': !isDesktop && !isTablet,
        'w-[100%]': !hasProductInfo || isTablet,
        'pl-4': isTablet && !hasCarrinho,
        'w-full': fullPage,
        'pt-[6px] pb-[24px]':
          (comboCategory || hasProductInfo) && (isDesktop || isTablet),
        'pt-[9px]': !isDesktop && !hasCarrinho
      }"
    >
      <ng-container *ngIf="!isDesktop && !isTablet">
        <!-- Layout para mobile -->
        <vip-card-combo-mobile
          [combo]="combo"
          [isDesktop]="isDesktop"
          [isTablet]="isTablet"
          [hasProductInfo]="hasProductInfo"
          [valorDiferenca]="valorDiferenca"
          [editable]="editable"
          [loading]="loading"
          [showSpinLarge]="showSpinLarge"
          [quantidade]="quantidade"
          (handleSpin)="handleSpinAlterado($event)"
          [hasCombo]="true"
          [quantidadeMaximaSpinner]="quantidadeMaximaSpinner"
        ></vip-card-combo-mobile>
      </ng-container>

      <!-- Layout para tablet e desktop -->
      <ng-container *ngIf="(isDesktop || isTablet) && swiperConfig">
        <vip-card-combo-desktop
          [combo]="combo"
          [isDesktop]="isDesktop"
          [isTablet]="isTablet"
          [hasProductInfo]="hasProductInfo"
          [valorDiferenca]="valorDiferenca"
          [editable]="editable"
          [loading]="loading"
          [showSpinLarge]="showSpinLarge"
          [quantidade]="quantidade"
          [swiperConfig]="swiperConfig"
          (handleSpin)="handleSpinAlterado($event)"
          [fullPage]="fullPage"
          [comboCategory]="comboCategory"
          [hasCombo]="true"
          [hasCarrinho]="hasCarrinho"
          [quantidadeMaximaSpinner]="quantidadeMaximaSpinner"
        ></vip-card-combo-desktop>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="!hasProductInfo"
    class="flex justify-between items-center px-3 py-3"
  >
    <div class="w-3/4">
      <p
        class="text-[12px] leading-[14.06px] line-through vip-color-third-default pb-[3px]"
      >
        {{ currencyComboProdutoView(combo.preco_antigo) }}
      </p>
      <p class="text-[14px] font-bold w-full">
        {{ combo.preco_oferta | currency: 'BRL' }} ({{ combo.quantidade_itens }}
        itens)
      </p>
    </div>
    <div class="flex items-center w-1/2 justify-end">
      <vip-spin
        (changed)="handleSpinAlterado($event)"
        *ngIf="editable"
        [attr.data-cy]="'card_produto_combo_' + combo.id + '_spin_large'"
        [confimDeletion]="true"
        [disabled]="loading"
        [max]="quantidadeMaximaSpinner"
        [quantity]="quantidade"
        [squared]="false"
        [subTitleModalConfimDeletion]="combo.nome"
        class="my-2"
        [styleSpin]="isDesktop"
        [size]="hasProductInfo && !hasCarrinho ? 'medium' : 'small'"
        label="Adicionar ao carrinho"
        [styleSpin]="isDesktop || isTablet"
        [produto]="combo"
        (click)="$event.stopPropagation()"
      >
      </vip-spin>
    </div>
  </div>
</div>
