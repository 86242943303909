import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as ListaItensActions from './lista-itens.actions';
import { listaItensSelectors } from './lista-itens.selectors';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class ListaItensFacade {
  itensDaLista$ = this.store.pipe(
    select(listaItensSelectors.getItensMinhaListaArray())
  );
  itensDaListaTipoCarrinho$ = this.store.pipe(
    select(listaItensSelectors.getItensMinhaListaTipoCarrinho())
  );
  error$ = this.store.pipe(select(listaItensSelectors.getError()));
  paginator$ = this.store.pipe(select(listaItensSelectors.getPagina()));
  loaded$ = this.store.pipe(select(listaItensSelectors.isLoaded()));
  loading$ = this.store.pipe(select(listaItensSelectors.isLoading()));
  itemAdicionado$ = this.actions$.pipe(
    ofType(ListaItensActions.editItemListaSuccess)
  );

  constructor(private actions$: Actions, private readonly store: Store) {}

  getItensMinhaLista(listaId: number, departamentoId?: number) {
    this.store.dispatch(
      ListaItensActions.getItensLista({ listaId, departamentoId })
    );
  }

  resetItensMinhaLista() {
    this.store.dispatch(ListaItensActions.resetItensMinhaLista());
  }

  addItensListaCarrinho(listaId: number) {
    this.store.dispatch(ListaItensActions.addItensListaCarrinho({ listaId }));
  }

  nextPage(listaId: number, departamentoId?: number): void {
    this.store.dispatch(
      ListaItensActions.nextPage({ listaId, departamentoId })
    );
  }

  editItemLista(
    produto_id: number,
    quantidade: number,
    listaId: number,
    seletor_medida_id: number,
    showMessage: boolean = true
  ) {
    this.store.dispatch(
      ListaItensActions.editItemLista({
        produto_id,
        quantidade,
        listaId,
        seletor_medida_id,
        showMessage,
      })
    );
  }
}
