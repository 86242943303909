import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DropdownButtonDirective,
  DropdownContentDirective,
  DropdownDirective,
} from './dropdown/dropdown.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    DropdownButtonDirective,
    DropdownContentDirective,
    DropdownDirective,
  ],
  exports: [
    DropdownButtonDirective,
    DropdownContentDirective,
    DropdownDirective,
  ],
})
export class DropdownModule {}
