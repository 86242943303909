import { MessageModule } from '@vip/ui/message';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { CarrinhoComponent } from './carrinho/carrinho.component';

import { EmptyModule } from '@vip/ui/empty';
import { ScreenTitleModule } from '@vip/ui/screen-title';
import { CarouselProductModule } from '@vip/ui/carousel-product';
import { CardProdutoModule } from '@vip/ui/card-produto';
import { ResumeModule } from '@vip/ui/resume';
import { ListaProdutosSelecionadosModule } from '@vip/views/lista-produtos-selecionados';

@NgModule({
  imports: [
    CommonModule,
    ScreenTitleModule,
    EmptyModule,
    CarouselProductModule,
    CardProdutoModule,
    ResumeModule,
    ListaProdutosSelecionadosModule,
    MessageModule,
  ],
  declarations: [CarrinhoComponent],
  exports: [CarrinhoComponent],
  providers: [CurrencyPipe],
})
export class CarrinhoModule {}
