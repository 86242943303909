import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  CustomValidators,
  GeneroOptions,
  ICliente,
  LayoutUtilsService,
  MaskPatterns,
  RegexPatters,
} from '@vip/core';

@Component({
  selector: 'vip-form-pessoa-fisica',
  templateUrl: './form-pessoa-fisica.component.html',
  styleUrls: ['./form-pessoa-fisica.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPessoaFisicaComponent implements OnInit {
  formGroup: UntypedFormGroup;

  @Input() isTelevendas = false;

  readonly masks = {
    telefone: MaskPatterns.TELEFONE,
    celular: MaskPatterns.CELULAR,
    cpf: MaskPatterns.CPF,
    data: MaskPatterns.DATA,
  };

  generoOptions = GeneroOptions;

  validations = {
    default: {
      required: 'Campo obrigatório',
    },
    cpf: {
      invalidCpf: 'CPF inválido',
      required: 'Campo obrigatório',
    },
    data_nascimento: {
      invalidDate: 'Data de nascimento inválida',
      required: 'Campo obrigatório',
    },
    telefone: {
      pattern: 'Telefone inválido',
    },
    celular: {
      pattern: 'Celular inválido',
      required: 'Campo obrigatório',
    },
    email: {
      email: 'Email inválido',
      required: 'Campo obrigatório',
    },
  };
  private _emRevisao = false;

  @Input() isEdit = false;
  @Input() exibeSexo = false;
  @Input() isDesktop = false;
  @Input() isModal = false;

  @Input() set emRevisao(value: boolean) {
    this._emRevisao = value;
    this.alterarObrigatoriedadeEmRevisao();
  }

  get emRevisao() {
    return this._emRevisao;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private zone: NgZone,
    public layoutUtilsService: LayoutUtilsService
  ) {
    this.formGroup = this.formBuilder.group({
      nome: ['', [Validators.required]],
      sobrenome: ['', [Validators.required]],
      sexo: ['N', [Validators.required]],
      cpf: ['', [CustomValidators.cpf, Validators.required]],
      data_nascimento: ['', [CustomValidators.date, Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      telefone_celular: [
        '',
        [
          Validators.required,
          Validators.pattern(new RegExp(RegexPatters.CELULAR)),
        ],
      ],
      telefone_fixo: [
        '',
        Validators.pattern(new RegExp(RegexPatters.TELEFONE)),
      ],
      comunicacao_email: [false],
      comunicacao_sms: [false],
    });
  }

  ngOnInit(): void {
    if (this.isTelevendas) {
      this.formGroup.get('email')?.setValue('@televendas.com.br');
    }
  }

  setValue(cliente: ICliente) {
    this.formGroup.patchValue(cliente);
    this.formGroup.controls.data_nascimento.setValue(
      cliente.data_nascimento_formatada
    );
    this.formGroup.controls.telefone_celular.setValue(
      cliente.telefone_celular_formatado
    );
    this.formGroup.controls.telefone_fixo.setValue(
      cliente.telefone_fixo_formatado
    );

    if (this.formGroup.get('data_nascimento')?.value) {
      this.formGroup.get('data_nascimento')?.disable();
    }

    this.formGroup.controls.cpf.setValue(cliente.cpf_formatado);
    if (this.formGroup.get('cpf')?.value) {
      this.formGroup.get('cpf')?.disable();
    }

    if (this.formGroup.get('sexo')?.value) {
      this.formGroup.get('sexo')?.disable();
    }

    if (this.isTelevendas) {
      this.formGroup.get('email')?.setValue('@televendas.com.br');
    }

    this.changeDetectorRef.detectChanges();
  }

  submit() {
    this.formGroup.markAllAsTouched();
    this.changeDetectorRef.markForCheck();
  }

  alterarObrigatoriedadeEmRevisao() {
    if (this._emRevisao) {
      this.formGroup.controls.cpf.setValidators([CustomValidators.cpf]);
      this.formGroup.controls.data_nascimento.setValidators([
        CustomValidators.date,
      ]);
      this.formGroup.controls.sexo.setValidators([]);
    } else {
      this.formGroup.controls.cpf.setValidators([
        CustomValidators.cpf,
        Validators.required,
      ]);
      this.formGroup.controls.data_nascimento.setValidators([
        CustomValidators.date,
        Validators.required,
      ]);
      this.formGroup.controls.sexo.setValidators([Validators.required]);
    }
  }

  handleOpenTermosClick(event: Event): void {
    this.zone.run(() => {
      event.preventDefault();
      event.stopPropagation();
      this.router.navigateByUrl('/termos-uso?page=completar');
    });
  }
}
