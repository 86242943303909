import { ModalService } from '@vip/ui/modal';
import { ICliente } from '@vip/core';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmarSenhaComponent } from '../confirmar-senha/confirmar-senha.component';
import { FormPessoaFisicaComponent } from '../form-pessoa-fisica/form-pessoa-fisica.component';
import { FormPessoaJuridicaComponent } from '../form-pessoa-juridica/form-pessoa-juridica.component';
import { TipoDeEdicaoMeusDados } from '../meus-dados.enum';
import { Router } from '@angular/router';
import { CompletarCadastroHelperService } from '../services/completar-cadastro-helper.service';

@UntilDestroy()
@Component({
  selector: 'vip-meus-dados',
  templateUrl: './meus-dados.component.html',
  styleUrls: ['./meus-dados.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeusDadosComponent implements AfterViewInit {
  @ViewChild('formPessoaFisica') formPessoaFisica!: FormPessoaFisicaComponent;
  @ViewChild('formPessoaJuridica')
  formPessoaJuridica!: FormPessoaJuridicaComponent;

  @Input() exibeSexo = false;
  @Input() isDesktop = false;
  @Input() tipoDeEdicao: TipoDeEdicaoMeusDados =
    TipoDeEdicaoMeusDados.PessoaFisica;
  @Input() isModal = false;

  @Output() saveMeusDados = new EventEmitter();
  @Output() validarSenha = new EventEmitter();

  TipoDeEdicaoMeusDados = TipoDeEdicaoMeusDados;

  constructor(
    private modalService: ModalService<ConfirmarSenhaComponent>,
    private router: Router,
    private completarCadastroHelperService: CompletarCadastroHelperService
  ) {}

  private _cliente!: ICliente;

  get cliente(): ICliente {
    return this._cliente;
  }

  @Input()
  set cliente(cliente: ICliente) {
    this._cliente = cliente;
  }

  ngAfterViewInit() {
    switch (this.tipoDeEdicao) {
      case TipoDeEdicaoMeusDados.PessoaFisica:
        this.formPessoaFisica.setValue(this._cliente);
        break;
      case TipoDeEdicaoMeusDados.PessoaJuridica:
        this.formPessoaJuridica.setValue(this._cliente);
        break;
      case TipoDeEdicaoMeusDados.Ambos:
        this.formPessoaFisica.setValue(this._cliente);
        this.formPessoaJuridica.setValue(this._cliente);
    }
  }

  get title(): string {
    switch (this.tipoDeEdicao) {
      case TipoDeEdicaoMeusDados.PessoaFisica:
        return 'Meus Dados';
      case TipoDeEdicaoMeusDados.PessoaJuridica:
        return 'Dados Comerciais';
      case TipoDeEdicaoMeusDados.Ambos:
        return 'Completar Cadastro';
    }
  }

  submitForms() {
    switch (this.tipoDeEdicao) {
      case TipoDeEdicaoMeusDados.PessoaFisica:
        this.formPessoaFisica.submit();
        break;
      case TipoDeEdicaoMeusDados.PessoaJuridica:
        this.formPessoaJuridica.submit();
        break;
      case TipoDeEdicaoMeusDados.Ambos:
        this.formPessoaFisica.submit();
        this.formPessoaJuridica.submit();
    }
  }

  isFormsValid(): boolean {
    switch (this.tipoDeEdicao) {
      case TipoDeEdicaoMeusDados.PessoaFisica:
        return this.formPessoaFisica.formGroup.valid;
      case TipoDeEdicaoMeusDados.PessoaJuridica:
        return this.formPessoaJuridica.formGroup.valid;
      case TipoDeEdicaoMeusDados.Ambos:
        return (
          this.formPessoaFisica.formGroup.valid &&
          this.formPessoaJuridica.formGroup.valid
        );
    }
  }

  formsValues() {
    switch (this.tipoDeEdicao) {
      case TipoDeEdicaoMeusDados.PessoaFisica:
        return this.formPessoaFisica.formGroup.value;
      case TipoDeEdicaoMeusDados.PessoaJuridica:
        return this.formPessoaJuridica.formGroup.value;
      case TipoDeEdicaoMeusDados.Ambos:
        return {
          ...this.formPessoaFisica.formGroup.value,
          ...this.formPessoaJuridica.formGroup.value,
        };
    }
  }

  handleCancel() {
    this.router.navigate(['/minha-conta/perfil']);
  }

  async handleSave() {
    this.submitForms();
    if (!this.isFormsValid()) return;

    if (this.isModal) await this.modalService.clearModal();

    const modalRef = this.modalService.openModal(ConfirmarSenhaComponent, {
      bottom: true,
      disabled: false,
      showCloseButton: true,
    });

    if (modalRef) {
      modalRef.instance.save
        .pipe(untilDestroyed(this))
        .subscribe((password: { password: string }) => {
          const saveObj = {
            ...this.formsValues(),
            ...password,
          };
          if (this.isModal)
            this.completarCadastroHelperService.saveDados = saveObj;
          this.saveMeusDados.emit(saveObj);
          this.modalService.clearModal();
        });
    }
  }

  isSaveDisabled(): boolean {
    const isPessoaFisicaPristine = this.formPessoaFisica
      ? this.formPessoaFisica.formGroup.pristine
      : true;
    const isPessoaJuridicaPristine = this.formPessoaJuridica
      ? this.formPessoaJuridica.formGroup.pristine
      : true;
    return isPessoaFisicaPristine && isPessoaJuridicaPristine;
  }
}
