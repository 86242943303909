<div class="flex items-center flex-col px-10 text-center">
  <vip-image
    [alt]="mensagem || ''"
    [imageUrl]="maintenanceImageUrl || ''"
  ></vip-image>
  <span class="vip-color-primary-main text-xl mt-5 mb-3 font-medium">{{
    mensagem !== '' ? mensagem : getDefaultMessage()
  }}</span>
  <span *ngIf="!mensagem" class="vip-color-third-default font-medium"
    >Mas fique tranquilo, em breve estaremos de volta!</span
  >
</div>
