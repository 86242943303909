<vip-screen-title
  title="Filtrar"
  leftClose="backspace"
  leftIconColor="secondary"
  rightIcon="icon-delete_forever"
  rightIconColor="error"
  (rightIconClick)="clear.emit()"
  (closeClicked)="closeFilter.emit()"
  [showActions]="true"
>
</vip-screen-title>

<div class="h-full flex flex-col content-center py-4 px-3 overflow-y-scroll">
  <ng-container *ngFor="let filterItem of internalFilters">
    <ng-container
      *ngIf="filterItem.options.length && filterItem.name !== 'tag'"
    >
      <div class="mb-2 vip-color-first-default text-sm font-medium">
        {{ filterItem.label }}
      </div>
      <div class="vip-filtro-item-options mb-1">
        <ng-container *ngIf="filterItem.multiple">
          <vip-checkbox-pill
            *ngFor="let filterItemOption of filterItem.options"
            [label]="filterItemOption.label"
            [(checkboxValue)]="!!filterItemOption.checked"
            [hidden]="
              filterItemOption
                | filtroOptionIsHidden: { type: filterItem.name, _filters }
            "
          >
          </vip-checkbox-pill>
        </ng-container>
        <ng-container *ngIf="!filterItem.multiple">
          <vip-radio-group direction="horizontal" [wrap]="true">
            <vip-radio-pill
              *ngFor="let filterItemOption of filterItem.options"
              [label]="filterItemOption.label"
              [(checked)]="!!filterItemOption.checked"
              [value]="filterItemOption.value"
              [hidden]="
                filterItemOption
                  | filtroOptionIsHidden: { type: filterItem.name, _filters }
              "
            >
            </vip-radio-pill>
          </vip-radio-group>
        </ng-container>
      </div>
    </ng-container>
    <ng-container
      *ngIf="filterItem.options.length && filterItem.name === 'tag'"
    >
      <ng-container *ngFor="let tagTipo of filterItem.formatedTags">
        <div
          class="mb-2 vip-color-first-default text-sm font-medium"
          [ngClass]="{ 'mt-3': tagTipo === '' }"
        >
          {{ tagTipo || '' }}
        </div>
        <ng-container *ngFor="let filterItemOption of filterItem.options">
          <vip-checkbox-pill
            *ngIf="
              filterItemOption.tipo === tagTipo ||
              (filterItemOption.tipo === null && tagTipo === '')
            "
            [label]="filterItemOption.label"
            [(checkboxValue)]="!!filterItemOption.checked"
            [hidden]="
              filterItemOption
                | filtroOptionIsHidden: { type: filterItem.name, _filters }
            "
          >
          </vip-checkbox-pill>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <vip-button
    class="mt-auto"
    data-cy="button-aplicar-filtro"
    type="raised"
    (click)="!loading && confirm.emit(filters)"
    [disabled]="loading"
  >
    Aplicar
  </vip-button>
</div>
