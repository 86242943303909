import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import * as CashbackActions from './cashback.actions';
import { FilialActions, FilialFacade } from '@vip/state/filial';
import { combineLatest } from 'rxjs';
import { IFilial, LayoutUtilsService } from '@vip/core';
import { CashbackWsService, GetSaldoCashbackResponse } from '@vip/ws';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { MessageService } from '@vip/ui/message';

@Injectable()
export class CashbackEffects {
  getSaldoCashback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CashbackActions.getSaldoCashback),
      (action) =>
        combineLatest([
          action,
          this.filialFacade.filial$,
          this.layoutUtilsService.isDesktop$,
        ]),
      fetch({
        run: (action, filial: IFilial, isDesktop: boolean) =>
          this.cashbackWsService.getSaldoCashback(filial.id).pipe(
            map((saldo: GetSaldoCashbackResponse) => {
              if (!saldo.success && isDesktop) {
                return CashbackActions.getSaldoCashbackFailure({
                  error: saldo.message,
                });
              }
              return CashbackActions.getSaldoCashbackSuccess({
                saldo: saldo.saldo,
                exibeCashback: saldo.success || false,
              });
            })
          ),

        onError: (action, error) => {
          return CashbackActions.getSaldoCashbackFailure({ error });
        },
      })
    )
  );

  getExtratoCashback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CashbackActions.getExtratoCashback),
      (action) => combineLatest([action, this.filialFacade.filial$]),
      fetch({
        run: (action, filial: IFilial) =>
          this.cashbackWsService
            .getExtratoCashback(filial.id, action.dataInicial, action.dataFinal)
            .pipe(
              map(({ extrato }) =>
                CashbackActions.getExtratoCashbackSuccess({
                  extrato,
                })
              )
            ),

        onError: (action, error) => {
          return CashbackActions.getExtratoCashbackFailure({ error });
        },
      })
    )
  );

  carregarMaisExtratoCashback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CashbackActions.carregarMaisExtratoCashback),
      (action) => combineLatest([action, this.filialFacade.filial$]),
      fetch({
        run: (action, filial: IFilial) =>
          this.cashbackWsService
            .getExtratoCashback(filial.id, action.dataInicial, action.dataFinal)
            .pipe(
              map(({ extrato }) =>
                CashbackActions.carregarMaisExtratoCashbackSuccess({
                  extrato,
                })
              )
            ),

        onError: (action, error) => {
          return CashbackActions.getExtratoCashbackFailure({ error });
        },
      })
    )
  );

  alterarSenhaCashback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CashbackActions.alterarSenhaCashback),
      (action) => combineLatest([action, this.filialFacade.filial$]),
      fetch({
        run: (action, filial: IFilial) =>
          this.cashbackWsService
            .alterarSenhaCashback(filial.id, action.novaSenha)
            .pipe(
              map(({ message }) =>
                CashbackActions.alterarSenhaCashbackSuccess({ message })
              )
            ),

        onError: (action, error) => {
          return CashbackActions.alterarSenhaCashbackFailure({ error });
        },
      })
    )
  );

  alterarSenhaCashbackSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CashbackActions.alterarSenhaCashbackSuccess),
        fetch({
          run: (action) => {
            this.messageService.openSuccessMessage(action.message, 1.5);
            this.location.back();
          },
        })
      ),
    { dispatch: false }
  );

  setCashbackConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilialActions.getFilialPorVipCommerceFilialIdSuccess),
      map((action) =>
        CashbackActions.setCashbackConfigs({
          isIdever:
            (action.fidelidade.configuracoes.vortice?.ativo &&
              action.fidelidade.configuracoes.vortice.cashback === '1') ||
            false,
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private filialFacade: FilialFacade,
    private cashbackWsService: CashbackWsService,
    private location: Location,
    private messageService: MessageService,
    private layoutUtilsService: LayoutUtilsService
  ) {}
}
