import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidarCepModule } from '@vip/views/validar-cep';
import { CepStateModule } from '@vip/state/cep';
import { ValidarCepContainerComponent } from './validar-cep-container/validar-cep-container.component';
import { ValidarCepContainerService } from './validar-cep-container.service';

@NgModule({
  imports: [CommonModule, ValidarCepModule, CepStateModule],
  declarations: [ValidarCepContainerComponent],
  exports: [ValidarCepContainerComponent],
  providers: [ValidarCepContainerService],
})
export class ValidarCepContainerModule {}
