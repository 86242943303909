<h2 class="text-base vip-color-secondary-default mb-1 ml-3 mt-4">
  Insira os dados do seu cartão:
</h2>
<vip-pagamento-cartao
  [compraEmProcesso]="compraEmProcesso"
  [formaPagamentoSelecionada]="formaPagamentoSelecionada || null"
  [exibeModalBandeiraNaoAtendida$]="exibeModalBandeiraNaoAtendida$"
  [formasPagamentosPrePagas]="formasPagamentosPrePagasCartaoLoja ?? []"
  [privateLabelBandeiraAutomatica]="formaPagamentosOptionsCartaoLoja.length < 2"
  [formaPagamentosOptions]="formaPagamentosOptionsCartaoLoja"
  [permitirSalvarCartao]="false"
  [permiteSelecaoManual]="permiteSelecaoManual ?? false"
  [bandeiraNaoAtendida]="bandeiraNaoAtendida ?? false"
  [parcelas]="parcelas"
  [limparForm$]="limparForm$"
  (alterarBandeiraCartao)="alterarBandeiraCartao.emit($event)"
  (parcelaChange)="selecionarParcela($event)"
  (confirmarClick)="adicionarCartao.emit($event)"
  (formEmitter)="formEmitter.emit($event)"
></vip-pagamento-cartao>
