import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as CompraEmProcessoActions from './compra-em-processo.actions';
import { GenericStoreStatusEnum, IGenericState } from '@vip/state/utils';
import { ICompra, ITaxaServico, ITipoEntregaCompra } from '@vip/core';

export const COMPRA_EM_PROCESSO_FEATURE_KEY = 'compra-em-processo';

export interface CompraEmProcessoState
  extends IGenericState<ICompra | null>,
    EntityState<ICompra> {
  compraEmProcessoId: number | null;
  taxaServico: ITaxaServico | null;
  current_date: string | null;
  gAdWords: number | null;
}

export const compraEmProcessoAdapter: EntityAdapter<ICompra> =
  createEntityAdapter<ICompra>();

export const initialState: CompraEmProcessoState =
  compraEmProcessoAdapter.getInitialState({
    data: null,
    status: 'pending',
    compraEmProcessoId: null,
    error: null,
    taxaServico: null,
    current_date: null,
    gAdWords: null,
  });

const compraEmProcessoReducer = createReducer(
  initialState,
  on(CompraEmProcessoActions.resetCompraEmProcesso, () => ({
    ...initialState,
  })),
  on(
    CompraEmProcessoActions.getCompraEmProcesso,
    CompraEmProcessoActions.criarCompra,
    CompraEmProcessoActions.continuarComprando,
    CompraEmProcessoActions.finalizarCompraEmProcesso,
    CompraEmProcessoActions.getCompraView,
    (state) => ({
      ...state,
      status: GenericStoreStatusEnum.LOADING,
      error: null,
    })
  ),
  on(CompraEmProcessoActions.criarCompraEmpty, (state) => ({
    ...state,
    status: GenericStoreStatusEnum.SUCCESS,
    error: null,
  })),
  on(
    CompraEmProcessoActions.getCompraEmProcessoSuccess,
    CompraEmProcessoActions.criarCompraSuccess,
    (state, { compra }) =>
      compraEmProcessoAdapter.upsertOne(compra, {
        ...state,
        compraEmProcessoId: compra.id,
        status: GenericStoreStatusEnum.SUCCESS,
        error: null,
      })
  ),
  on(
    CompraEmProcessoActions.getCompraEmProcessoEmpty,
    CompraEmProcessoActions.continuarComprandoSuccess,
    (state) =>
      compraEmProcessoAdapter.removeAll({
        ...state,
        compraEmProcessoId: null,
        status: GenericStoreStatusEnum.SUCCESS,
        error: null,
      })
  ),
  on(
    CompraEmProcessoActions.getCompraEmProcessoFailure,
    CompraEmProcessoActions.criarCompraFailure,
    CompraEmProcessoActions.continuarComprandoFailure,
    CompraEmProcessoActions.finalizarCompraEmProcessoFailure,
    CompraEmProcessoActions.getCompraViewFailure,
    (state, { error }) => ({
      ...state,
      status: GenericStoreStatusEnum.ERROR,
      error: error.error ? error.error.error : error,
    })
  ),
  on(
    CompraEmProcessoActions.finalizarCompraEmProcessoSuccess,
    (state, { pedido, current_date }) => ({
      ...state,
      current_date: current_date ?? null,
      gAdWords: pedido.adwords === undefined ? null : pedido.adwords,
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
    })
  ),
  on(
    CompraEmProcessoActions.setPortadorCompraEmProcesso,
    (state, { portador }) =>
      compraEmProcessoAdapter.updateOne(
        {
          id: state.compraEmProcessoId as number,
          changes: { portador: portador },
        },
        state
      )
  ),
  on(
    CompraEmProcessoActions.setCashbackCompraEmProcessoSuccess,
    (state, { valorCashback }) =>
      compraEmProcessoAdapter.updateOne(
        {
          id: state.compraEmProcessoId as number,
          changes: { valor_cashback: valorCashback < 0 ? 0 : valorCashback },
        },
        state
      )
  ),
  on(
    CompraEmProcessoActions.getTaxaServicoSuccess,
    (state, { taxaServico }) => ({
      ...state,
      status: GenericStoreStatusEnum.SUCCESS,
      taxaServico,
      error: null,
    })
  ),
  on(CompraEmProcessoActions.getTaxaServicoFailure, (state, { error }) => ({
    ...state,
    status: GenericStoreStatusEnum.SUCCESS,
    taxaServico: {
      valor_taxa: null,
      mensagem: '',
    },
    error: error.error ? error.error.error : error,
  })),
  on(CompraEmProcessoActions.getCompraViewSuccess, (state, { compra }) =>
    compraEmProcessoAdapter.upsertOne(compra, {
      ...state,
      gAdWords: compra.adwords === undefined ? null : compra.adwords,
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
    })
  ),
  on(CompraEmProcessoActions.setFreteCompraEmProcesso, (state, { frete }) =>
    compraEmProcessoAdapter.updateOne(
      {
        id: state.compraEmProcessoId as number,
        changes: { frete: frete },
      },
      state
    )
  ),
  on(
    CompraEmProcessoActions.setTipoEntregaCompraEmProcesso,
    (state, { tipo_entrega, horarioSelecionado }) => {
      const tipoEntregaAtualizado = {
        ...tipo_entrega,
        horario_selecionado: horarioSelecionado,
      };

      return compraEmProcessoAdapter.updateOne(
        {
          id: state.compraEmProcessoId as number,
          changes: {
            tipo_entrega: tipoEntregaAtualizado as ITipoEntregaCompra,
          },
        },
        state
      );
    }
  )
);

export function reducer(
  state: CompraEmProcessoState | undefined,
  action: Action
) {
  return compraEmProcessoReducer(state, action);
}
