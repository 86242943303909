import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search/search.component';
import { FormsModule } from '@angular/forms';
import { FieldModule } from '@vip/ui/field';

@NgModule({
  imports: [CommonModule, FormsModule, FieldModule],
  declarations: [SearchComponent],
  exports: [SearchComponent],
})
export class SearchModule {}
