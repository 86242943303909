import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class ScrollService {
  private static scrollSubject = new BehaviorSubject(false);
  private static isSmallestHeaderSubject = new BehaviorSubject(false);
  static scrolling$ = ScrollService.scrollSubject.asObservable();
  static isSmallestHeader$ =
    ScrollService.isSmallestHeaderSubject.asObservable();

  static onScroll() {
    ScrollService.scrollSubject.next(window.scrollY > 2 ? true : false);
  }

  static set smallestHeader(value: boolean) {
    ScrollService.isSmallestHeaderSubject.next(value);
  }
}
