import { Action, createReducer, on } from '@ngrx/store';

import { ICarrinhoItemInconsistencia, IProdutoCarrinho } from '@vip/core';
import * as IngredientesReceitaActions from './ingredientes-receita.actions';
import { IGenericState } from '@vip/state/utils';

export const INGREDIENTES_RECEITA_FEATURE_KEY = 'ingredientes-receita';

export interface IngredientesReceitaStateData {
  itensAtualizado: IProdutoCarrinho[];
  itensNaoAtualizados: ICarrinhoItemInconsistencia[];
}

export type IngredientesReceitaState =
  IGenericState<IngredientesReceitaStateData | null>;

export const initialState: IngredientesReceitaState = {
  data: null,
  status: 'pending',
  error: null,
};

const ingredientesReceitaReducer = createReducer(
  initialState,
  on(IngredientesReceitaActions.resetIngredientes, () => ({
    ...initialState,
  })),
  on(IngredientesReceitaActions.addIngredientesCarrinho, (state) => ({
    ...state,
    status: 'loading',
    data: null,
    error: null,
  })),
  on(
    IngredientesReceitaActions.addIngredientesCarrinhoSuccess,
    (state, { itensAtualizado, itensNaoAtualizados }) => ({
      ...state,
      data: {
        itensAtualizado,
        itensNaoAtualizados,
      },
      status: 'success',
      error: null,
    })
  ),
  on(
    IngredientesReceitaActions.addIngredientesCarrinhoFailure,
    (state, { error }) => ({
      ...state,
      data: null,
      status: 'error',
      error: error.error,
    })
  )
);

export function reducer(
  state: IngredientesReceitaState | undefined,
  action: Action
) {
  return ingredientesReceitaReducer(state, action);
}
