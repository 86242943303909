import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { tapResponse } from '@ngrx/component-store';
import { IProduto, IApiPaginator, LayoutUtilsService } from '@vip/core';
import { BuscaApiService } from '@vip/api';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { BuscaState } from './busca-state.interface';
import { FiltroFacade } from '@vip/state/filtro';
import { BucketsFacade } from '@vip/state/buckets';
import {
  GenericComponentStore,
  GenericStoreStatus,
  IGenericState,
} from '@vip/state/utils';
import { AnalyticsFacade } from '@vip/state/analytics';
import { ProdutoFacade } from '@vip/state/produto';
type buscaProdutosPayload = {
  termo: string;
  filtro: string;
  page: number;
};

@Injectable()
export class BuscaStore extends GenericComponentStore<BuscaState> {
  readonly produtos$ = this.select((state) => state.data.produtos);
  readonly paginator$ = this.select((state) => state.data.paginator);
  readonly loading$ = this.select((state) => state.status === 'loading');
  readonly pending$ = this.select((state) => state.status === 'pending');
  readonly error$ = this.select((state) => state.error);
  readonly termos$ = this.select((state) => state.data.termos).pipe(
    filter((termo): termo is Array<string> => termo !== undefined)
  );

  readonly page$ = this.select(
    (stateContext: IGenericState<BuscaState>) =>
      stateContext.data.paginator.page
  );
  private initialPaginator = {
    page: 1,
    items_per_page: 52,
    total_items: 0,
    total_pages: 1,
  };

  readonly setTermo = this.updater(
    (
      state,
      data: {
        termos: string[];
        page?: number;
      }
    ) => {
      if (state.data.termos?.toString() === data.termos.toString()) {
        return {
          ...state,
          data: {
            ...state.data,
            paginator: data.page
              ? { ...state.data.paginator, page: data.page }
              : state.data.paginator,
          },
          status: 'success',
        };
      }
      return {
        ...state,
        data: {
          produtos: [],
          paginator: this.initialPaginator,
          termos: data.termos,
        },
        status: 'success',
      };
    }
  );

  readonly setData = this.updater(
    (
      state,
      data: {
        produtos: IProduto[];
        paginator: IApiPaginator;
        termos?: string[];
        naoConcatenarProdutos?: boolean;
      }
    ) => {
      if (data.paginator && data.paginator?.page > 1) {
        if (!data.naoConcatenarProdutos) {
          return {
            ...state,
            data: {
              produtos: [...state.data.produtos, ...data.produtos],
              paginator: data.paginator,
              termos: data.termos,
            },
            status: 'success',
          };
        } else {
          return {
            ...state,
            data: {
              produtos: [...data.produtos],
              paginator: data.paginator,
              termos: data.termos,
            },
            status: 'success',
          };
        }
      }
      return {
        ...state,
        data: { ...data },
        status: 'success',
      };
    }
  );

  readonly setPartialData = this.updater(
    (
      state,
      data: Partial<{
        produtos: IProduto[];
        paginator: IApiPaginator;
        termos: string[];
      }>
    ) => {
      return {
        ...state,
        data: {
          ...data,
          produtos: data.produtos || state.data.produtos,
          paginator: data.paginator || state.data.paginator,
        },
        status: 'success',
      };
    }
  );

  readonly setStatus = this.updater((state, status: GenericStoreStatus) => ({
    ...state,
    status,
  }));

  readonly setError = this.updater((state, error: string) => ({
    ...state,
    error,
    produtos: [],
    status: 'error',
  }));

  readonly buscaProdutos = this.effect(
    (payload$: Observable<buscaProdutosPayload>) => {
      return combineLatest([
        payload$,
        this.cdFacade.filialECdSelecionado$.pipe(
          filter(
            ([filial, cdSelecionado]) =>
              filial !== undefined && cdSelecionado !== undefined
          )
        ),
        this.bucketsFacade.bucketProduto$.pipe(
          filter((bucket) => bucket !== '')
        ),
        this.page$.pipe(filter((page) => page !== 0)),
      ]).pipe(
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        ),
        filter(([payload]) => !!payload.termo.trim()),
        tap(() => this.setStatus('loading')),
        switchMap(([payload, [filial, cdSelecionado], bucket]) => {
          this.removeResetFromFilter(payload);
          this.analyticsFacade.buscarItem(payload.termo);
          return this.buscaApiService
            .searchProdutosByTermo(
              filial.id,
              cdSelecionado.id,
              payload.termo,
              'page=' + payload.page,
              payload.filtro
            )
            .pipe(
              tapResponse(
                (response) => {
                  this.filtroFacade.getFiltroBusca(
                    response.data.busca_id,
                    payload.filtro,
                    response.data.filtros
                  );

                  this.setData({
                    produtos: response.data.produtos
                      ? response.data.produtos?.map((produto) => {
                          return this.mapProdutos(produto, bucket);
                        })
                      : [],
                    termos: [response.data.termo],
                    paginator: response.paginator || this.initialPaginator,
                    naoConcatenarProdutos:
                      this.layoutUtilsService.isDesktopWithoutScreenWidth(),
                  });
                  this.produtosFacade?.enviaImpressao(
                    response.data.produtos ?? []
                  );
                },
                (error) =>
                  this.setError((error as HttpErrorResponse).error.error)
              )
            );
        })
      );
    }
  );

  readonly getSuggestionsByTermo = this.effect(
    (payload$: Observable<{ termo: string }>) => {
      return combineLatest([
        payload$,
        this.cdFacade.filialECdSelecionado$.pipe(
          filter(
            ([filial, cdSelecionado]) =>
              filial !== undefined && cdSelecionado !== undefined
          )
        ),
      ]).pipe(
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        ),
        tap(() => this.setStatus('loading')),
        switchMap(([payload, [filial]]) =>
          this.buscaApiService
            .getSuggestionsByTermo(filial.id, payload.termo)
            .pipe(
              tapResponse(
                (response) => {
                  this.setStatus('success');
                  this.setPartialData({
                    termos: response.data.map((termos) => termos.termo),
                  });
                },
                () => {
                  this.setStatus('error');
                  this.setPartialData({
                    termos: [],
                  });
                }
              )
            )
        )
      );
    }
  );

  nextPage() {
    this.patchState((state) => ({
      data: {
        paginator: {
          ...state.data.paginator,
          page:
            state.data.paginator.page < state.data.paginator.total_pages
              ? state.data.paginator.page + 1
              : state.data.paginator.page,
          total_pages: state.data.paginator.total_pages,
        },
        produtos: state.data.produtos,
      },
    }));
  }

  firstPage() {
    this.patchState((state) => ({
      data: {
        ...state.data,
        paginator: this.initialPaginator,
      },
    }));
  }

  goToPage(page: number) {
    this.patchState((state) => ({
      data: {
        paginator:
          state.data.paginator.page !== page
            ? {
                ...state.data.paginator,
                page,
              }
            : state.data.paginator,
        produtos: state.data.paginator.page !== page ? [] : state.data.produtos,
      },
    }));
  }

  goToPageFirstTime(page: number) {
    this.patchState((state) => ({
      data: {
        paginator: {
          ...state.data.paginator,
          page,
          total_pages: page,
        },
        produtos: [],
      },
    }));
  }

  constructor(
    private bucketsFacade: BucketsFacade,
    private buscaApiService: BuscaApiService,
    private filtroFacade: FiltroFacade,
    private cdFacade: CentroDistribuicaoFacade,
    private analyticsFacade: AnalyticsFacade,
    private layoutUtilsService: LayoutUtilsService,
    private produtosFacade?: ProdutoFacade
  ) {
    super({
      produtos: [],
      paginator: {
        page: 1,
        items_per_page: 52,
        total_items: 0,
        total_pages: 1,
      },
    });
  }

  protected mapProdutos(produto: IProduto, bucket?: string) {
    return {
      ...produto,
      imagem: produto.imagem ? `${bucket}/${produto.imagem}` : produto.imagem,
    };
  }

  private removeResetFromFilter(payload: buscaProdutosPayload): void {
    payload.filtro = payload.filtro.replace('&reset', '');
  }
}
