<div class="grid gap-4 grid-cols-1 text-center">
  <span class="vip-validar-cep-title text-3xl">Atenção</span>
  <span class="vip-validar-cep-sub-title text"
    >O serviço de entrega ainda não está disponível para seu endereço, mas é
    possível realizar compras para a retirada em uma de nossas lojas.</span
  >
  <vip-button (btnClick)="confirm.emit()" data-cy="somente-retirada-confirmar">
    <span> Ok, entendi </span>
  </vip-button>
</div>
