import { Injectable } from '@angular/core';
import { DescontoFacade } from '@vip/state/desconto';
import { CupomFacade } from '@vip/state/cupom';
import { map, take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { CompraEmProcessoFacade } from '@vip/state/compra-em-processo';
import {
  ICompraDesconto,
  ICupomCredito,
  IFormaPagamento,
  ISelectOptions,
  MotivoDescontoEnum,
} from '@vip/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FidelidadeFacade } from '@vip/state/fidelidade';
import { CurrencyPipe } from '@angular/common';
import { DialogService } from '@vip/ui/modal';

@UntilDestroy()
@Injectable()
export class PagamentoService {
  constructor(
    private compraEmProcesso: CompraEmProcessoFacade,
    private descontoFacade: DescontoFacade,
    private cupomFacade: CupomFacade,
    private fidelidadeFacade: FidelidadeFacade,
    private currencyPipe: CurrencyPipe,
    private dialogService: DialogService
  ) {}

  compraEmProcessoValorAcrescimos$ = new BehaviorSubject(0);

  compraEmProcessoValorFinal$: Observable<number> = this.getValorFinal();

  compraEmProcessoValorDescontos$: Observable<number> =
    this.getValorDescontos();

  compraEmProcessoDescontos$: Observable<ICompraDesconto[]> =
    this.getDescontos();

  compraEmProcessoValorFinalSemAcrescimo$: Observable<number> =
    this.getValorFinalSemAcrescimo();

  parcelas: ISelectOptions[] = [];

  private timerCarteiraDigitalIsRunningSubject = new BehaviorSubject<boolean>(
    false
  );

  timerCarteiraDigitalIsRunning$ =
    this.timerCarteiraDigitalIsRunningSubject.asObservable();

  confirmarVoltar$ = new Subject<{
    compraId: number;
    filialId: number;
    cdId: number;
  }>();

  getValorFinal() {
    return combineLatest([
      this.compraEmProcesso.compraEmProcesso$,
      this.descontoFacade.totalDesconto$,
      this.cupomFacade.totalCuponsSelecionados$,
      this.compraEmProcessoValorAcrescimos$.asObservable(),
      this.compraEmProcesso.cashbackUtilizado$,
      this.fidelidadeFacade.totalDescontoFidelidade$,
    ]).pipe(
      untilDestroyed(this),
      map(
        ([
          compraEmProcesso,
          totalDesconto,
          totalCupons,
          totalAcrescimos,
          cashbackUtilizado,
          descontoFidelidade,
        ]) => {
          if (compraEmProcesso) {
            const valorFinalCompra = this.formatValue(
              compraEmProcesso.valor_final +
                totalAcrescimos -
                totalDesconto -
                totalCupons -
                cashbackUtilizado -
                descontoFidelidade
            );
            return valorFinalCompra > 0 ? valorFinalCompra : 0;
          } else {
            return 0;
          }
        }
      )
    );
  }

  getValorFinalSemAcrescimo() {
    return combineLatest([
      this.compraEmProcesso.compraEmProcesso$,
      this.descontoFacade.totalDesconto$,
      this.cupomFacade.totalCuponsSelecionados$,
      this.compraEmProcesso.cashbackUtilizado$,
      this.fidelidadeFacade.totalDescontoFidelidade$,
    ]).pipe(
      untilDestroyed(this),
      map(
        ([
          compraEmProcesso,
          totalDesconto,
          totalCupons,
          cashbackUtilizado,
          descontoFidelidade,
        ]) => {
          if (compraEmProcesso) {
            const valorFinalCompra = this.formatValue(
              compraEmProcesso.valor_final -
                totalDesconto -
                totalCupons -
                cashbackUtilizado -
                descontoFidelidade
            );
            return valorFinalCompra > 0 ? valorFinalCompra : 0;
          } else {
            return 0;
          }
        }
      )
    );
  }

  getDescontos() {
    return combineLatest([
      this.descontoFacade.descontos$,
      this.cupomFacade.cuponsSelecionados$,
      this.compraEmProcesso.cashbackUtilizado$,
      this.fidelidadeFacade.totalDescontoFidelidade$,
    ]).pipe(
      untilDestroyed(this),
      map(([descontos, cupons, cashbackUtilizado, descontoFidelidade]) => {
        return this.agruparDescontos(
          descontos,
          cupons,
          cashbackUtilizado,
          descontoFidelidade
        );
      })
    );
  }

  getValorDescontos() {
    return combineLatest([
      this.descontoFacade.totalDesconto$,
      this.cupomFacade.totalCuponsSelecionados$,
      this.compraEmProcesso.cashbackUtilizado$,
      this.fidelidadeFacade.totalDescontoFidelidade$,
    ]).pipe(
      untilDestroyed(this),
      map(
        ([
          totalDesconto,
          totalCupons,
          cashbackUtilizado,
          descontoFidelidade,
        ]) => {
          return this.formatValue(
            totalDesconto + totalCupons + cashbackUtilizado + descontoFidelidade
          );
        }
      )
    );
  }

  setValorAcrescimos(valorComJuros: number) {
    this.compraEmProcessoValorFinalSemAcrescimo$
      .pipe(untilDestroyed(this), take(1))
      .subscribe((compraEmProcessoValorFinalSemAcrescimo) => {
        this.compraEmProcessoValorAcrescimos$.next(
          this.formatValue(
            valorComJuros - compraEmProcessoValorFinalSemAcrescimo
          )
        );
      });
  }

  limparValorAcrescimos() {
    this.compraEmProcessoValorAcrescimos$.next(0);
  }

  updateParcelasOptions(formaPgto: IFormaPagamento, valorCompra: number): void {
    this.parcelas = [];
    let valorComJuros: number = valorCompra;
    const valorDeParcela: number =
      valorCompra /
        (formaPgto.valor_minimo_parcela > 0
          ? formaPgto.valor_minimo_parcela
          : 1) || 1;
    const numeroDeParcelasSemJuros = formaPgto.parcelas_sem_juros;

    for (let i = 1; i <= formaPgto.parcelas && i <= valorDeParcela; i++) {
      valorComJuros = formaPgto.juros
        ? valorComJuros + (valorComJuros / 100) * formaPgto.juros
        : valorCompra;
      if (
        numeroDeParcelasSemJuros === undefined ||
        i <= numeroDeParcelasSemJuros
      ) {
        this.setParcelas(
          valorCompra,
          i,
          this.getTextJuros(formaPgto, false),
          valorCompra
        );
      } else {
        this.setParcelas(
          valorComJuros,
          i,
          this.getTextJuros(formaPgto, true),
          valorComJuros
        );
      }
    }

    if (
      this.parcelas.length == 0 &&
      valorDeParcela <= formaPgto.valor_minimo_parcela
    ) {
      if (
        numeroDeParcelasSemJuros === undefined ||
        numeroDeParcelasSemJuros > 0
      ) {
        this.setParcelas(
          valorCompra,
          1,
          this.getTextJuros(formaPgto, false),
          valorComJuros
        );
      } else {
        this.setParcelas(
          valorComJuros,
          1,
          this.getTextJuros(formaPgto, true),
          valorComJuros
        );
      }
    }
  }

  setTimerIsRunning(isRunning: boolean): void {
    this.timerCarteiraDigitalIsRunningSubject.next(isRunning);
  }

  exibirModalAvisoVoltar(
    compraId?: number,
    filialId?: number,
    cdId?: number
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.dialogService.openDialog(
        {
          open: true,
          title: 'Atenção!',
          subTitle:
            'Você está no processo de pagamento, interrompê-lo para alterar o endereço ou a compra, poderá acarretar na <b>alteração dos preços e na disponibilidade dos produtos.</b> Deseja realmente voltar?',
          disabled: false,
          buttonConfirmText: 'Permanecer e fazer o pagamento',
          buttonCancelText: 'Sim, desejo voltar!',
        },
        true
      );

      this.dialogService.dialogClick
        .pipe(untilDestroyed(this))
        .subscribe((permanecer) => {
          if (!permanecer && this.compraEmProcesso) {
            if (compraId && filialId && cdId)
              this.confirmarVoltar$.next({
                compraId,
                filialId,
                cdId,
              });
            resolve(true);
          } else {
            resolve(false);
          }
          this.dialogService.clearDialog();
        });
    });
  }

  private agruparDescontos(
    descontos: ICompraDesconto[],
    cupons: ICupomCredito[],
    cashbackUtilizado: number,
    descontoFidelidade: number
  ) {
    if (!descontos) descontos = [];

    descontos.filter(
      (desconto) => desconto.motivo !== MotivoDescontoEnum.MOTIVO_CASHBACK
    );

    if (cashbackUtilizado > 0) {
      const descontoCashback = {
        valor: cashbackUtilizado,
        string: 'Cashback',
        motivo: MotivoDescontoEnum.MOTIVO_CASHBACK,
      };
      descontos = [...descontos, descontoCashback];
    }

    if (descontoFidelidade > 0) {
      const descontoFidelidadeObject = {
        valor: descontoFidelidade,
        string: 'Desconto Fidelidade',
        motivo: MotivoDescontoEnum.MOTIVO_FIDELIDADE,
      };
      descontos = [...descontos, descontoFidelidadeObject];
    }

    return [
      ...descontos,
      ...cupons.map((cupom) => {
        const cupomAsDesconto: ICompraDesconto = {
          valor: cupom.valor,
          string: cupom.observacoes || '',
          motivo: MotivoDescontoEnum.MOTIVO_CUPOM,
          codigo: cupom.codigo,
        };

        return cupomAsDesconto;
      }),
    ];
  }

  private formatValue(value: number) {
    return parseFloat(value.toFixed(3));
  }

  private setParcelas(
    valor: number,
    parcela: number,
    textJuros: string,
    valorComJuros: number
  ) {
    this.parcelas.push({
      value: parcela.toString(),
      text: `${parcela} x de ${this.currencyPipe.transform(
        this.getValorParcela(valor, parcela)
      )} ${textJuros}`,
      info: Math.round((valorComJuros + Number.EPSILON) * 100) / 100,
    });
  }

  private getValorParcela(valor: number, parcela: number) {
    return Math.round((valor / parcela) * 100) / 100;
  }

  private getTextJuros(formaPgto: IFormaPagamento, temJuros: boolean) {
    return temJuros ? `com juros de ${formaPgto.juros}% a.m.` : 'sem juros';
  }
}
