import { createSelector } from '@ngrx/store';
import { LISTA_FEATURE_KEY, listasAdapter, ListaState } from './lista.reducer';
import { SelectorUtil } from '@vip/state/utils';

const { selectEntities, selectAll } = listasAdapter.getSelectors();

class ListaSelectors extends SelectorUtil<ListaState> {
  constructor() {
    super(LISTA_FEATURE_KEY);
  }

  getMinhasListasArray = () =>
    createSelector(this.createFeatureSelector, (state: ListaState) =>
      selectAll(state.data.listas).filter((lista) => !lista.compartilhada)
    );

  getListasCompartilhadasArray = () =>
    createSelector(this.createFeatureSelector, (state: ListaState) =>
      selectAll(state.data.listas).filter((lista) => lista.compartilhada)
    );

  getListasArray = () =>
    createSelector(this.createFeatureSelector, (state: ListaState) =>
      selectAll(state.data.listas)
    );

  getListas = () =>
    createSelector(this.createFeatureSelector, (state: ListaState) =>
      selectEntities(state.data.listas)
    );

  getMinhaListaSelecionadaId = () =>
    createSelector(
      this.createFeatureSelector,
      (state: ListaState) => state.data.listaSelecionadaId
    );

  getListaSelecionada = () =>
    createSelector(
      this.getListas(),
      this.getMinhaListaSelecionadaId(),
      (entities, selectedId) =>
        selectedId && entities[selectedId] ? entities[selectedId] : null
    );
}

export const listaSelectors = new ListaSelectors();
