import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IList, IProduto } from '@vip/core';
import { AnalyticsFacade } from '@vip/state/analytics';
import { ListaFacade } from '@vip/state/lista';
import { ListaItensFacade } from '@vip/state/lista-itens';
import { MessageService } from '@vip/ui/message';

@UntilDestroy()
@Component({
  selector: 'vip-add-to-list-container',
  templateUrl: './add-to-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddToListContainerComponent implements OnInit {
  @Input() product!: IProduto;

  @Output() addedToList = new EventEmitter();

  listas$ = this.listaFacade.minhasListasECompartilhadas$;

  constructor(
    private listaFacade: ListaFacade,
    private listaItensFacade: ListaItensFacade,
    private messageService: MessageService,
    private analyticsFacade: AnalyticsFacade
  ) {}

  ngOnInit(): void {
    this.listaFacade.getListas();

    this.listaItensFacade.itemAdicionado$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.addedToList.emit());

    this.listaFacade.criarListaSuccess$
      .pipe(untilDestroyed(this))
      .subscribe((lista) => {
        this.messageService.openSuccessMessage('Lista criada com sucesso', 1.5);
        this.addProdutoToLista(lista);
      });
  }

  addProdutoToLista(lista: IList) {
    const quantidade = 1;
    const listaId = lista.lista_id;
    const seletor_medida_id = this.product?.seletor_medida_id;

    this.listaItensFacade.editItemLista(
      this.product.produto_id,
      quantidade,
      listaId,
      seletor_medida_id as number
    );
    this.analyticsFacade.addMinhaLista(this.product);
  }

  onNovaListaClick(nome: string) {
    this.listaFacade.criarLista({ nome });
  }
}
