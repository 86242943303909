import { createSelector } from '@ngrx/store';
import {
  CENTRO_DISTRIBUICAO_FEATURE_KEY,
  CentrosDistribuicaoState,
} from './centro-distribuicao.reducer';
import { SelectorUtil } from '@vip/state/utils';

export class CentrosDistribuicaoSelectors extends SelectorUtil<CentrosDistribuicaoState> {
  constructor() {
    super(CENTRO_DISTRIBUICAO_FEATURE_KEY);
  }

  getCentrosDistribuicao = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CentrosDistribuicaoState) =>
        state.data.cds.filter((cd) => !cd.exclusivo_televendas)
    );

  getCdSelecionado = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CentrosDistribuicaoState) => state.data.cdSelecionado
    );

  isLojaAutonoma = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CentrosDistribuicaoState) =>
        state.data.cdSelecionado?.loja_autonoma
    );

  isConfirmado = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CentrosDistribuicaoState) => state.data.cdConfirmado
    );

  possuiLojaAutonoma = () =>
    createSelector(
      this.createFeatureSelector,
      (state: CentrosDistribuicaoState) => state.data.possuiLojaAutonoma
    );
}

export const centrosDistribuicaoSelectors = new CentrosDistribuicaoSelectors();
