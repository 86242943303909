<vip-card-basico
  [disableRipple]="true"
  icon="icon-cashback"
  title="Pagar com cashback"
>
  <div class="vip-saldo-cashback">
    <div class="flex items-center justify-between">
      <div>
        <p class="text-xs font-medium vip-color-first-default">
          Saldo Cashback
        </p>
        <p class="text-xs vip-color-third-default" id="saldo-cashback">
          Disponível: {{ saldoCashback | currency: 'BRL' }}
        </p>
      </div>
      <vip-toggle
        (toggle)="toggle()"
        [checked]="checkedCashback"
        [disabled]="!saldoCashback || !editable"
      ></vip-toggle>
    </div>
  </div>
  <div *ngIf="cashbackAplicado && editable">
    <hr class="vip-divider my-2" />
    <div class="flex justify-between items-center">
      <div class="flex-1">
        <p class="text-xs mb-0.5 vip-color-secondary-main font-medium">
          Utilizando
        </p>
        <p class="text-xs vip-color-success-main font-medium">
          {{ cashbackAplicado | currency: 'BRL' }}
        </p>
      </div>
      <vip-button
        (btnClick)="openModal()"
        data-cy="btn-alterar"
        size="small"
        type="normal"
      >
        <span class="text-xs vip-color-secondary-main">Alterar</span>
      </vip-button>
    </div>
  </div>
  <vip-modal (closeClick)="fecharModal()" [open]="modal">
    <div class="grid gap-4 grid-cols-1">
      <form [formGroup]="form" class="grid text-center">
        <h2 class="vip-modal-title">Cashback</h2>
        <h3 class="vip-modal-sub-title">
          Digite o valor que você deseja utilizar do seu saldo de cashback:
        </h3>
        <vip-field
          [error]="
            form.controls['saldo'].invalid &&
            (form.controls['saldo'].touched || form.controls['saldo'].dirty)
          "
          [message]="validations"
          [validations]="form.controls['saldo'].errors"
          class="text-left"
        >
          <input
            currencyMask
            formControlName="saldo"
            id="inputSaldo"
            type="text"
          />
        </vip-field>
        <vip-button
          (btnClick)="alterarValorCashback()"
          class="mt-8"
          data-cy="btn-salvar-cashback"
        >
          <span>Salvar</span>
        </vip-button>
      </form>
    </div>
  </vip-modal>
</vip-card-basico>
