import { fetch } from '@nrwl/angular';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  distinctUntilChanged,
  filter,
  map,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import {
  CompraStatusEnum,
  FacebookPixelService,
  ICompra,
  ICompraPagamento,
  IFilial,
  IProdutoCompra,
  LayoutUtilsService,
} from '@vip/core';
import { CompraApiService } from '@vip/api';
import { FilialFacade } from '@vip/state/filial';
import { BucketsFacade, BucketsService } from '@vip/state/buckets';
import { MessageService } from '@vip/ui/message';
import * as CompraActions from './compra.actions';
import { CarrinhoFacade } from '@vip/state/carrinho';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { DialogService } from '@vip/ui/modal';
import { NavigationEnd, Router } from '@angular/router';
import { SubstituirProdutoService } from '@vip/state/substituir-produto';
import { CompraEmProcessoFacade } from '@vip/state/compra-em-processo';
import {
  CarrinhoItensActions,
  CarrinhoItensFacade,
} from '@vip/state/carrinho-itens';
import { mergeTakeOne, SelecaoCdSelectors } from '@vip/state/utils';
import { select, Store } from '@ngrx/store';
import { PerguntasActions } from '@vip/state/perguntas';
import { compraSelectors } from './compra.selectors';
import { CompraFacade } from './compra.facade';

const FORMA_PAGAMENTO_FILE_PATH = 'files/forma_pagamentos';

@Injectable()
export class CompraEffects {
  getComprasEmAberto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraActions.getComprasEmAberto),
      withLatestFrom(
        this.filialFacade.filial$,
        this.bucketsFacade.bucketProduto$
      ),
      map(([action, filial, bucket]) => ({ ...action, filial, bucket })),
      fetch({
        run: (action) =>
          this.compraApiService.getComprasEmAberto(action.filial.id).pipe(
            map((compras) => {
              return CompraActions.getComprasEmAbertoSuccess({
                compras: compras.data.map((compra) => {
                  return {
                    ...compra,
                    produtos: compra.produtos.map((item) =>
                      this.bucketsService.addBucketProduto(item, action.bucket)
                    ),
                  };
                }),
              });
            })
          ),

        onError: (action, error) => {
          return CompraActions.getComprasEmAbertoFailure({ error });
        },
      })
    )
  );

  getCompras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraActions.getCompras),
      withLatestFrom(
        this.filialFacade.filial$,
        this.bucketsFacade.bucketS3$,
        this.bucketsFacade.bucketProduto$
      ),
      map(([action, filial, bucketS3, bucketProduto]) => ({
        ...action,
        filial,
        bucketS3,
        bucketProduto,
      })),
      fetch({
        run: (action) =>
          this.compraApiService.getCompras(action.filial.id).pipe(
            map((compras) => {
              return CompraActions.getComprasSuccess({
                compras: compras.data.map((compra) =>
                  this.addBucket(compra, action.bucketS3, action.bucketProduto)
                ),
                paginator: compras.paginator,
              });
            })
          ),
        onError: (action, error) => {
          return CompraActions.getComprasFailure({ error });
        },
      })
    )
  );

  getTrocaDevolucao$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraActions.getTrocaDevolucao),
      (action) => combineLatest([action, this.filialFacade.filial$]),
      fetch({
        run: (action, filial: IFilial) =>
          this.compraApiService.getTrocaDevolucao(filial.id).pipe(
            map((compras) =>
              CompraActions.getTrocaDevolucaoSuccess({
                compras: compras.data,
              })
            )
          ),
        onError: (action, error) => {
          return CompraActions.getTrocaDevolucaoFailure({ error });
        },
      })
    )
  );

  getPrazoTrocaDevolucao$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraActions.getPrazoTrocaDevolucao),
      fetch({
        run: () =>
          this.compraApiService.getPrazoTrocaDevolucao().pipe(
            map((res) =>
              CompraActions.getPrazoTrocaDevolucaoSuccess({
                prazoTroca: res.data.troca,
                prazoDevolucao: res.data.devolucao,
              })
            )
          ),
        onError: (action, error) => {
          return CompraActions.getTrocaDevolucaoFailure({ error });
        },
      })
    )
  );

  getComprasTrocasDevolucao$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraActions.getComprasTrocasDevolucao),
      (action) => combineLatest([action, this.filialFacade.filial$]),
      fetch({
        run: (action, filial: IFilial) =>
          this.compraApiService.getComprasTrocasDevolucao(filial.id).pipe(
            map((compras) =>
              CompraActions.getComprasTrocasDevolucaoSuccess({
                compras: compras.data,
              })
            )
          ),
        onError: (action, error) => {
          return CompraActions.getComprasTrocasDevolucaoFailure({ error });
        },
      })
    )
  );

  getCompra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraActions.getCompra, CompraActions.cancelarCompraSuccess),
      mergeTakeOne(
        this.filialFacade.filial$,
        this.bucketsFacade.bucketS3$,
        this.bucketsFacade.bucketProduto$
      ),
      map(([action, filial, bucket, bucketProduto]) => ({
        ...action,
        filial,
        bucket,
        bucketProduto,
      })),
      fetch({
        run: (action) =>
          this.compraApiService
            .getCompra(action.filial.id, action.compraId)
            .pipe(
              map((res) => {
                return CompraActions.getCompraSuccess({
                  compra: this.addBucket(
                    res.data,
                    action.bucket,
                    action.bucketProduto
                  ),
                });
              })
            ),
        onError: (action, error) => CompraActions.getCompraFailure({ error }),
      })
    )
  );

  getPerguntasCompraComRespostas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraActions.getPerguntasCompraComResposta),
      (action) => combineLatest([action, this.filialFacade.filial$]),
      fetch({
        run: (action, filial: IFilial) =>
          this.compraApiService
            .getPerguntasCompraComResposta(filial.id, action.compraId)
            .pipe(
              map((perguntas) =>
                CompraActions.getPerguntasCompraComRespostaSuccess({
                  perguntas: perguntas.data,
                })
              )
            ),
        onError: (action, error) =>
          CompraActions.getPerguntasCompraComRespostaFailure({ error }),
      })
    )
  );

  cancelarCompra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraActions.cancelarCompra),
      (action) => combineLatest([action, this.filialFacade.filial$]),
      fetch({
        run: (action, filial: IFilial) =>
          this.compraApiService
            .cancelarCompra(filial.id, action.compraId, action.observacao)
            .pipe(
              map((res) => {
                this.messageService.openSuccessMessage(res.data, 2);
                return CompraActions.cancelarCompraSuccess({
                  compraId: action.compraId,
                  compra_status_id: action.compra_status_id,
                });
              })
            ),
        onError: (action, error) =>
          CompraActions.cancelarCompraFailure({ error }),
      })
    )
  );

  removerCompraEmProcesso$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraActions.cancelarCompraSuccess),
        filter(
          (action) =>
            action.compra_status_id === CompraStatusEnum.EM_PROCESSO_DE_COMPRA
        ),
        tap(() => this.compraEmProcessoFacade.getCompraEmProcesso())
      ),
    { dispatch: false }
  );

  repetirCompra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraActions.repetirCompra),
      withLatestFrom(
        this.centroDistribuicaoFacade.filialECdSelecionado$,
        this.bucketsFacade.bucketProduto$,
        this.store.pipe(
          select(SelecaoCdSelectors.isPermitirAtualizarItemCarrinho)
        )
      ),
      map(([action, [filial, cd], bucket, permitirAtualizarItemCarrinho]) => ({
        ...action,
        filial,
        cd,
        bucket,
        permitirAtualizarItemCarrinho,
      })),
      filter(
        ({ permitirAtualizarItemCarrinho }) => !!permitirAtualizarItemCarrinho
      ),
      fetch({
        run: (action) =>
          this.compraApiService
            .repetirCompra(action.filial.id, action.cd.id, action.compraId)
            .pipe(
              map((res) => {
                if (res.data.itemNaoAtualizados?.length) {
                  return CompraActions.repetirCompraSubstituirProdutos({
                    itemNaoAtualizados: res.data.itemNaoAtualizados.map(
                      (item) => {
                        return {
                          ...item,
                          mercadoria: this.bucketsService.addBucketProduto(
                            item.mercadoria,
                            action.bucket
                          ),
                        };
                      }
                    ),
                    itemAtualizado: res.data.itemAtualizado.map((item) =>
                      this.bucketsService.addBucketProduto(item, action.bucket)
                    ),
                  });
                } else {
                  return CompraActions.repetirCompraSuccess();
                }
              })
            ),
        onError: (action, error) =>
          CompraActions.repetirCompraFailure({ error }),
      })
    )
  );

  repetirCompraFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraActions.repetirCompraFailure),
        tap((action) => {
          this.messageService.openErrorMessage(action.error.error.error, 1.5);
        })
      ),
    { dispatch: false }
  );

  repetirCompraSubstituirProdutos$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraActions.repetirCompraSubstituirProdutos),
        map((action) => {
          this.carrinhoFacade.getCarrinho();
          this.carrinhoItensFacade.getItensCarrinho();
          this.substituirProdutoService.latestActionSubstituirProduto = action;
          this.substituirProdutoService.openSubstituirProdutos(
            action.itemNaoAtualizados
          );
        })
      ),
    { dispatch: false }
  );
  exibeopenDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoItensActions.setListaItensCarrinhoSuccess),
        mergeTakeOne(this.layoutUtilsService.isDesktop$),
        filter(([action]) => {
          return action.itens.length > 0;
        }),
        map(([, isDesktop]) => {
          this.openDialogProdutosAdicionados(isDesktop);
        })
      ),
    { dispatch: false }
  );

  repetirCompraSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompraActions.repetirCompraSuccess),
        mergeTakeOne(
          this.layoutUtilsService.isDesktop$,
          this.substituirProdutoService.latestActionSubstituirProduto$
        ),
        tap(() => {
          this.carrinhoFacade.getCarrinho(true);
          this.carrinhoItensFacade.getItensCarrinho();
          this.substituirProdutoService.latestActionSubstituirProduto = null;
          this.facebookPixelService.addToCart('Compra');
        }),
        filter(([, , actionSubstituirProduto]) => {
          return (
            actionSubstituirProduto === null ||
            actionSubstituirProduto.itemAtualizado.length > 0
          );
        }),
        map(([, isDesktop]) => {
          this.openDialogProdutosAdicionados(isDesktop);
        })
      ),
    { dispatch: false }
  );

  handleRepetirCompra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraActions.repetirCompra),
      withLatestFrom(
        this.store.pipe(
          select(SelecaoCdSelectors.isPermitirAtualizarItemCarrinho)
        )
      ),
      filter(
        ([, permitirAtualizarItemCarrinho]) => !!permitirAtualizarItemCarrinho
      ),
      map(([action]) => {
        return CompraActions.setIdRepetirCompra({ compraId: action.compraId });
      })
    )
  );

  popularRespostasPerguntasState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraActions.getPerguntasCompraComRespostaSuccess),
      map(({ perguntas }) => {
        const respostas = perguntas
          .map((pergunta) => ({
            [pergunta.id]: pergunta.cliente_pergunta_resposta?.resposta || '',
          }))
          .reduce((acc, pergunta) => ({ ...acc, ...pergunta }));

        return PerguntasActions.popularRespostasPerguntasCompra({ respostas });
      })
    )
  );

  nextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompraActions.nextPage),
      mergeTakeOne(
        this.store.pipe(select(compraSelectors.getPaginator())),
        this.filialFacade.filial$,
        this.bucketsFacade.bucketS3$,
        this.bucketsFacade.bucketProduto$
      ),
      filter(
        ([action, paginator]) =>
          !!paginator && paginator.page < paginator.total_pages
      ),
      distinctUntilChanged(([, prevPaginator], [, currPaginator]) => {
        if (
          prevPaginator?.page === 1 &&
          currPaginator?.page === 1 &&
          !this.callFirstPage
        ) {
          return false;
        }
        return prevPaginator?.page === currPaginator?.page;
      }),
      tap(([, paginator]) => {
        if (paginator?.page === 1 && !this.callFirstPage) {
          this.callFirstPage = true;
          this.router.events
            .pipe(
              filter((event) => event instanceof NavigationEnd),
              take(1)
            )
            .subscribe(() => {
              this.callFirstPage = false;
            });
        }
      }),
      fetch({
        run: (action, paginator, filial, bucketS3, bucketProduto) =>
          this.compraApiService
            .getCompras(filial.id, paginator ? paginator.page + 1 : 1)
            .pipe(
              map((compras) => {
                return CompraActions.getComprasSuccess({
                  compras: compras.data.map((compra) =>
                    this.addBucket(compra, bucketS3, bucketProduto)
                  ),
                  paginator: compras.paginator,
                });
              })
            ),
        onError: (action, error) => {
          return CompraActions.getComprasFailure({ error });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private bucketsFacade: BucketsFacade,
    private bucketsService: BucketsService,
    private carrinhoFacade: CarrinhoFacade,
    private carrinhoItensFacade: CarrinhoItensFacade,
    private centroDistribuicaoFacade: CentroDistribuicaoFacade,
    private compraApiService: CompraApiService,
    private dialogService: DialogService,
    private facebookPixelService: FacebookPixelService,
    private filialFacade: FilialFacade,
    private messageService: MessageService,
    private router: Router,
    private substituirProdutoService: SubstituirProdutoService,
    private compraEmProcessoFacade: CompraEmProcessoFacade,
    private store: Store,
    private layoutUtilsService: LayoutUtilsService,
    private compraFacade: CompraFacade
  ) {}

  private modalAdicionaProdutos = false;
  private callFirstPage = false;

  private addBucket(
    compra: ICompra,
    bucketS3?: string,
    bucketProduto?: string
  ) {
    return {
      ...compra,
      compra_pagamentos: compra.compra_pagamentos.map((compraPagamento) => {
        const _compraPagamento: ICompraPagamento = {
          ...compraPagamento,
          forma_pagamento: {
            ...compraPagamento.forma_pagamento,
            logo: `${bucketS3}/${FORMA_PAGAMENTO_FILE_PATH}/${compraPagamento.forma_pagamento.logo}`,
          },
        };
        return _compraPagamento;
      }),

      produtos: compra.produtos.map((produtoCompra) =>
        this.bucketsService.addBucketProduto<IProdutoCompra>(
          produtoCompra,
          bucketProduto
        )
      ),
    };
  }

  private openDialogProdutosAdicionados(isDesktop: boolean) {
    if (this.modalAdicionaProdutos) {
      return;
    }
    this.dialogService.openDialog({
      open: true,
      title: 'Itens adicionados ao carrinho!',
      subTitle: 'Os itens foram adicionados ao carrinho. Finalize sua compra!',
      disabled: false,
      buttonConfirmText: 'Finalizar compra',
    });
    this.modalAdicionaProdutos = true;
    this.dialogService.dialogClick.subscribe((value) => {
      if (value) {
        if (isDesktop) this.router.navigateByUrl('resumo-compra');
        else this.router.navigateByUrl('carrinho');
      }
      this.dialogService.clearDialog();
      this.modalAdicionaProdutos = false;
    });

    this.dialogService.closeClick.subscribe(() => {
      this.modalAdicionaProdutos = false;
    });
  }
}
