import { CurrencyPipe } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { ICompraPagamento } from '../../interfaces';

@Pipe({ name: 'parcelasTextoPipe' })
export class ParcelasTextoPipe implements PipeTransform {
  constructor(@Inject(CurrencyPipe) private currencyPipe: CurrencyPipe) {}

  transform(pagamento: ICompraPagamento): string {
    const valorPagamentoParcelado =
      (pagamento.valor + pagamento.juros) / pagamento.parcelas;
    const valorPiped = this.currencyPipe.transform(
      valorPagamentoParcelado,
      'BRL'
    );
    const numeroDeParcelasSemJuros =
      pagamento.forma_pagamento.parcelas_sem_juros;
    const label = `${pagamento.parcelas}x de ${valorPiped} ${
      numeroDeParcelasSemJuros === undefined ||
      pagamento.parcelas <= numeroDeParcelasSemJuros
        ? 'sem juros'
        : `com juros de ${pagamento.forma_pagamento.juros}% a.m.`
    }`;

    return label;
  }
}
