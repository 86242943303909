import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, take, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  IHorariosFormatados,
  ITipoEntrega,
  LayoutUtilsService,
} from '@vip/core';
import {
  TipoEntregaFacade,
  ValidaEntregaService,
} from '@vip/state/tipo-entrega';
import { CompraEmProcessoFacade } from '@vip/state/compra-em-processo';
import { DialogService } from '@vip/ui/modal';
import { CarrinhoFacade } from '@vip/state/carrinho';
import { IMessage, MessageService } from '@vip/ui/message';
import { mergeTakeOne } from '@vip/state/utils';
import { LayoutStore } from '@vip/state/layout';
import { noop } from 'rxjs';
import { EnderecoFacade } from '@vip/state/endereco';

@UntilDestroy()
@Component({
  selector: 'vip-alterar-entrega-retirada-container',
  templateUrl: './alterar-entrega-retirada-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlterarEntregaRetiradaContainerComponent implements OnInit {
  isEntrega$ = this.tipoEntregaFacade.isEntrega$;
  isLoading$ = this.tipoEntregaFacade.loading$;
  tipoEntregas$ = this.tipoEntregaFacade.tiposEntregas$;
  tipoSelecionado$ = this.tipoEntregaFacade.tipoEntregaSelecionado$;
  qtdItensCarrinho$ = this.carrinhoFacade.qtdItensCarrinho$;
  hasCompraEmProcesso = false;
  isDesktopResponsive = this.layoutUtilsService.isDesktopResponsive();

  constructor(
    private router: Router,
    private tipoEntregaFacade: TipoEntregaFacade,
    private compraEmProcessoFacade: CompraEmProcessoFacade,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    private validaEntregaService: ValidaEntregaService,
    private carrinhoFacade: CarrinhoFacade,
    private messageService: MessageService,
    private layoutUtilsService: LayoutUtilsService,
    private layoutStore: LayoutStore,
    private enderecoFacade: EnderecoFacade
  ) {}

  ngOnInit() {
    this.enderecoFacade.loaded$
      .pipe(
        take(1),
        filter((loaded) => !loaded),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.enderecoFacade.getEnderecosEntrega();
      });

    this.compraEmProcessoFacade.compraEmProcesso$
      .pipe(untilDestroyed(this))
      .subscribe(
        (compraEmProcesso) => (this.hasCompraEmProcesso = !!compraEmProcesso)
      );

    this.activatedRoute.url
      .pipe(
        untilDestroyed(this),
        filter((url) => url[0].path === 'alterar-entrega-retirada'),
        tap(() => {
          this.tipoEntregaFacade.getTiposEntregasEndereco(true);
          this.validarAtendidoPorEntrega();
        })
      )
      .subscribe(noop);

    if (this.isDesktopResponsive) this.layoutStore.setHeaderVisibility(false);
  }

  handleTipoEntregaChange(tipoEntrega: ITipoEntrega) {
    let navegaProximaTela = true;
    this.carrinhoFacade.qtdItensCarrinho$
      .pipe(take(1), untilDestroyed(this))
      .subscribe((qtdItens) => {
        if (
          tipoEntrega.quantidade_maxima_itens != undefined ||
          tipoEntrega.quantidade_maxima_itens != null
        ) {
          if (qtdItens > (tipoEntrega.quantidade_maxima_itens || 0)) {
            const messageData: IMessage = {
              icon: 'icon-close',
              position: 'top',
              type: 'error',
            };
            navegaProximaTela = false;
            this.messageService.openMessage(
              messageData,
              'Você excedeu o limite de itens permitidos para esse tipo de entrega.',
              5.0
            );
          }
        }
      });

    this.tipoEntregaFacade.selectHorarioTipoEntrega(
      tipoEntrega.horario_selecionado as IHorariosFormatados
    );
    this.compraEmProcessoFacade.setTipoEntrega(
      tipoEntrega,
      tipoEntrega.horario_selecionado
    );

    if (navegaProximaTela) {
      this.tipoEntregaFacade.selectTipoEntrega(tipoEntrega.id);
      this.router.navigateByUrl('/entrega-retirada/alterar-horario');
    }
  }

  voltar() {
    this.validaEntregaService.validaEntrega();
    this.router.navigateByUrl(
      this.hasCompraEmProcesso ? '/pagamento' : '/resumo-compra'
    );
  }

  validarAtendidoPorEntrega() {
    this.tipoEntregas$
      .pipe(
        untilDestroyed(this),
        mergeTakeOne(this.tipoEntregaFacade.loaded$, this.isEntrega$),
        filter(
          ([, loaded, isEntrega]) => loaded === true && isEntrega === true
        ),
        take(1)
      )
      .subscribe(([tiposEntregas]) => {
        if (
          !tiposEntregas.filter((tipoEntrega) => !tipoEntrega.retirada_loja)
            .length
        ) {
          this.exibirModalEnderecoInvalido();
        }
      });
  }

  private exibirModalEnderecoInvalido(): void {
    this.dialogService.openDialog({
      open: true,
      disabled: false,
      title: 'Não realizamos entrega no endereço selecionado!',
      subTitle:
        'Por favor, adicione outro endereço válido para entrega, ou, se preferir, altere para fazer a retirada.',
      buttonCancelText: 'Fazer Retirada na Loja',
      buttonConfirmText: 'Adicionar Novo Endereço',
    });
    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this.tipoEntregaFacade.setFormaEntrega(true);
          this.router.navigate(['minha-conta', 'endereco']);
        } else {
          this.tipoEntregaFacade.setFormaEntrega(false);
          this.router.navigate(['entrega-retirada']);
        }
        if (!this.hasCompraEmProcesso)
          this.tipoEntregaFacade.resetTiposEntregaSelecionada();
        this.dialogService.clearDialog();
      });
  }
}
