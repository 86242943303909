import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { LayoutUtilsService, MaskPatterns } from '@vip/core';

@Component({
  selector: 'vip-solicitar-cep',
  templateUrl: './solicitar-cep.component.html',
  styleUrls: ['../../validar-cep/validar-cep.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolicitarCepComponent {
  readonly masks = {
    cep: MaskPatterns.CEP,
  };

  @Input() cepInvalido = false;
  @Input() loading = false;
  @Input() isMobile = false;
  @Output() confirm = new EventEmitter();

  validated = false;
  form = this.fb.group({
    cep: ['', [Validators.required]],
  });

  constructor(
    private fb: UntypedFormBuilder,
    public layoutUtilsService: LayoutUtilsService
  ) {}

  get isDesktop(): boolean {
    return this.layoutUtilsService.isDesktop();
  }

  onConfirmSolicitarCep() {
    this.validated = true;
    if (!this.form.invalid) {
      this.confirm.emit(this.form.controls.cep.value);
    }
  }
}
