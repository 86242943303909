import { FilialFacade } from '@vip/state/filial';
import { Directive } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import {
  ICliente,
  IClienteTelevendas,
  IFilialTelevendas,
  IFilialTelevendasSelect,
} from '@vip/core';
import { TelevendasFacade } from '@vip/state/televendas';
import { DialogService, IDialog } from '@vip/ui/modal';

import { SearchEvent } from '@vip/ui/search';
import { filter, tap } from 'rxjs/operators';

@Directive()
export abstract class TelevendasDirective {
  clientData?: IClienteTelevendas;
  selectedEventFilialTelevendas: IFilialTelevendasSelect =
    {} as IFilialTelevendasSelect;

  filiaisTelevendas$ = this.televendasFacade.filiaisTelevendas$;
  clientesTelevenda$ = this.televendasFacade.clientesTelevenda$;
  vincularFilial$ = this.televendasFacade.vincularFilialSuccess$;
  loadingTelevendas$ = this.televendasFacade.loading$;

  readonly dialogVincularTelevenda: IDialog = {
    open: true,
    title: 'Cliente não vinculado',
    subTitle: '',
    disabled: false,
    buttonConfirmText: 'Vincular cliente',
  };

  constructor(
    protected televendasFacade: TelevendasFacade,
    protected filialFacade: FilialFacade,
    protected dialogService: DialogService
  ) {}

  getTelevendasClientes(event: SearchEvent) {
    this.televendasFacade.getClienteTelevendas(event.value);

    this.televendasFacade.clientesTelevenda$
      .pipe(
        filter(
          (clientes): clientes is ICliente[] =>
            clientes !== undefined && clientes !== null
        ),
        tap()
      )
      .subscribe();
  }

  selectClienteTelevenda(event: IClienteTelevendas) {
    this.clientData = event;

    if (!this.selectedEventFilialTelevendas) {
      return;
    }

    const selectedFilial = this.selectedEventFilialTelevendas.value;

    const pertenceAFilial = this.clientData.filiais.some(
      (filial: IFilialTelevendas) => {
        return (
          String(filial._joinData?.filial_id) ===
          String(selectedFilial.filial_id)
        );
      }
    );

    const buscarDadosDeFilial =
      selectedFilial.filial_id !== this.filialFacade.filial.id;

    if (buscarDadosDeFilial) {
      this.filialFacade.getFilialPorVipCommerceFilialId(
        Number(selectedFilial.vipcommerce_filial_id)
      );
    }

    if (!pertenceAFilial) {
      this.dialogVincularTelevenda.subTitle = `O cliente ${
        this.clientData.nome + ' ' + this.clientData.sobrenome
      } não está vinculado à filial <b>${
        this.selectedEventFilialTelevendas?.text
      }</b>. Deseja vincular e iniciar a compra?`;
      return this.dialogConfirmTelevendas();
    }

    this.televendasFacade.logarClienteTelevendas(
      this.clientData.id.toString(),
      String(selectedFilial.filial_id)
    );
  }

  selectedFilialTelevenda(event: IFilialTelevendasSelect) {
    this.selectedEventFilialTelevendas = event;
  }

  dialogConfirmTelevendas() {
    this.dialogService.openDialog(this.dialogVincularTelevenda);
    this.dialogService.dialogClick.pipe(untilDestroyed(this)).subscribe(() => {
      const clientId = this.clientData?.id;

      if (clientId !== undefined) {
        this.televendasFacade.vincularFilial(
          clientId,
          this.selectedEventFilialTelevendas?.value.filial_id
        );
        this.vincularFilial$.pipe(untilDestroyed(this)).subscribe(() => {
          this.televendasFacade.logarClienteTelevendas(
            String(clientId),
            String(this.selectedEventFilialTelevendas?.value.filial_id)
          );
          this.dialogService.clearDialog();
        });
      }
    });
  }
}
