<vip-base-component-skeleton class="flex flex-col" [quantity]="quantity">
  <ng-template #skeletonContent>
    <div
      class="border rounded p-2 flex flex-row mb-2"
      [ngClass]="{ 'mx-3': !layoutReceita }"
    >
      <ng-container
        *vipSkeleton="
          true;
          width: '60px';
          height: '60px';
          radius: '3px';
          className: ['mr-2']
        "
      ></ng-container>
      <div class="w-full flex flex-col">
        <div *ngIf="!layoutReceita">
          <ng-container
            *vipSkeleton="
              true;
              width: '214px';
              height: '20px';
              className: ['mb-4']
            "
          ></ng-container>
          <div class="w-full flex flex-row justify-between">
            <ng-container
              *vipSkeleton="true; width: '80px'; height: '20px'"
            ></ng-container>
            <ng-container
              *vipSkeleton="true; width: '105px'; height: '27px'; radius: '5px'"
            ></ng-container>
          </div>
        </div>
        <div *ngIf="layoutReceita">
          <ng-container
            *vipSkeleton="
              true;
              width: '150px';
              height: '20px';
              className: ['mb-4']
            "
          ></ng-container>
          <div class="w-full flex flex-row justify-between">
            <ng-container
              *vipSkeleton="true; width: '80px'; height: '20px'"
            ></ng-container>
            <ng-container
              *vipSkeleton="true; width: '80px'; height: '27px'; radius: '5px'"
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</vip-base-component-skeleton>
