import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IProduto } from '@vip/core';
import * as SubstituirProdutoActions from './substituir-produto.actions';
import { substituirProdutoSelectors } from './substituir-produto.selectors';

@Injectable({
  providedIn: 'root',
})
export class SubstituirProdutoFacade {
  produtosIndisponiveis$ = this.store.pipe(
    select(substituirProdutoSelectors.getProdutosIndisponiveisArray())
  );
  produtoIndisponivelSelecionado$ = this.store.pipe(
    select(substituirProdutoSelectors.getProdutoIndisponivelSelecionado())
  );
  produtoSubstitutoSelecionado$ = this.store.pipe(
    select(substituirProdutoSelectors.getProdutoSubstitutoSelecionado())
  );

  constructor(private readonly store: Store) {}

  inserirProdutosIndisponiveis(produtos: IProduto[]): void {
    this.store.dispatch(
      SubstituirProdutoActions.inserirProdutosIndisponiveis({ produtos })
    );
  }

  setIdProdutoIndisponivel(idProdutoIndisponivel: number): void {
    this.store.dispatch(
      SubstituirProdutoActions.setIdProdutoIndisponivel({
        idProdutoIndisponivel,
      })
    );
  }

  definirProdutoSubstituto(
    idProdutoIndisponivel: number,
    quantidade: number,
    produto: IProduto
  ): void {
    this.store.dispatch(
      SubstituirProdutoActions.definirSubstituto({
        idProdutoIndisponivel,
        quantidade,
        produto,
      })
    );
  }

  adicionarItensAoCarrinho(): void {
    this.store.dispatch(SubstituirProdutoActions.adicionarItensAoCarrinho());
  }

  resetProdutos(): void {
    this.store.dispatch(SubstituirProdutoActions.resetProdutos());
  }

  naoSubstituirProdutos(): void {
    this.store.dispatch(SubstituirProdutoActions.naoSubstituir());
  }
}
