<div class="flex flex-col h-full w-full relative">
  <div class="flex flex-col flex-grow" *ngIf="compraPagamento && !loading">
    <div>
      <div class="carteira-digital-content rounded block flex flex-col">
        <div class="mx-7 mt-4 text-base vip-color-secondary-default">
          <ng-container *ngIf="ePix; else tituloNaoPix">
            Por favor, leia o qr-code abaixo com o seu celular para realizar o
            pagamento através do aplicativo da carteira digital. O pedido será
            concluído assim que recebermos a confirmação do pagamento.
          </ng-container>
          <ng-template #tituloNaoPix>
            Realize o pagamento através do aplicativo da carteira digital. O
            pedido será concluído assim que recebermos a confirmação.
          </ng-template>
        </div>

        <div class="text-center font-bold mt-10 mb-3">Aguardando pagamento</div>

        <div
          class="rounded shadow self-center max-w-[20.625rem] p-3 mb-4"
          *ngIf="!ePix; else qrCodePix"
        >
          <ng-container *ngIf="!tempoEsgotado; else divtempoEsgotado">
            <img
              data-cy="qr-code-carteira-digital"
              class="qr-code mx-auto mt-3"
              [src]="compraPagamento.qr_code"
            />
            <vip-button
              *ngIf="!ePix"
              type="raised"
              class="w-fit mt-4 m-auto flex fixed-width"
              (btnClick)="clickedDeeplink.emit(compraPagamento.deep_link)"
            >
              Ir para o site
            </vip-button>
          </ng-container>
        </div>
        <ng-template #qrCodePix>
          <ng-container *ngIf="!tempoEsgotado; else divtempoEsgotado">
            <img
              data-cy="qr-code-pix"
              class="qr-code mx-auto my-10 mt-3 mb-4"
              [src]="compraPagamento.qr_code"
            />
          </ng-container>
        </ng-template>

        <vip-countdown-bar
          class="min-w-[20.5rem] self-center mb-10 text-base"
          [timeTotal]="tempoLimitePagamento"
          [timeLeft]="tempoRestante"
          (finished)="handleTempoEsgotado(); timerIsRunning.emit(false)"
        ></vip-countdown-bar>
        <div class="flex justify-end gap-4">
          <vip-button
            type="outline"
            class="flex cancel-button"
            (btnClick)="
              tempoEsgotado
                ? clickedTentarNovamente.emit(compraEmProcesso)
                : clickedCancelarPagamento.emit(compraPagamento)
            "
          >
            <i class="icon-block"></i>
            Alterar Forma de Pagamento
          </vip-button>
          <vip-button
            type="raised"
            class="flex"
            (btnClick)="copiarCodigo()"
            data-cy="button-copy"
          >
            <i class="icon-copy"></i>
            Copiar código
          </vip-button>
        </div>
      </div>

      <ng-template #divtempoEsgotado>
        <div class="flex flex-col gap-3 max-w-[20.5rem]">
          <div class="flex text-xs vip-color-secondary-default text-center">
            O tempo de espera para a confirmação do pagamento esgotou. Tente
            novamente ou altere a forma de pagamento.
          </div>

          <vip-button
            type="raised"
            class="w-fit m-auto flex"
            (btnClick)="clickedTentarNovamente.emit(compraEmProcesso)"
          >
            Tentar Novamente
          </vip-button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
