import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltroFacade } from './+state/filtro.facade';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FiltroEffects } from './+state/filtro.effects';
import * as fromFiltro from './+state/filtro.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromFiltro.FILTRO_FEATURE_KEY, fromFiltro.reducer),
    EffectsModule.forFeature([FiltroEffects]),
  ],
  providers: [FiltroFacade],
})
export class FiltroStateModule {}
