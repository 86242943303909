import { Injectable } from '@angular/core';
import { AdicionarAvaliacaoPayload, AdicionarAvaliacaoResponse } from '.';

import { BaseWsService } from '../common/base-ws-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProdutoWsService {
  constructor(private ws: BaseWsService<ProdutoWsService>) {}

  addAvaliacao(
    params: AdicionarAvaliacaoPayload
  ): Observable<AdicionarAvaliacaoResponse> {
    return this.ws.post<AdicionarAvaliacaoPayload, AdicionarAvaliacaoResponse>(
      'addAvaliacao',
      `/loja/produto-comentarios/add`,
      params
    );
  }
}
