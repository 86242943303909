<aside #contentHeader class="vip-tab-group-controls">
  <ng-container
    *ngIf="!isLoading; then tabsTemplate; else skeletonTemplate"
  ></ng-container>
  <ng-template #tabsTemplate>
    <ng-container *ngFor="let tab of _allTabs.toArray(); let i = index">
      <vip-tab-item-horizontal
        *ngIf="tab.showParent"
        [color]="color"
        [icon]="tab.icon"
        [label]="tab.label"
        [disabled]="tab.disabled"
        [disabledText]="tab.disabledText"
        [selected]="selectedIndex <= (lastChildrenIndex || 0) && !tab.disabled"
        [count]="tab.count"
        (tabClick)="_handleClick(i)"
      ></vip-tab-item-horizontal>
      <vip-tab-sub-item
        *ngIf="tab.chidrenLabel"
        class="block"
        [ngClass]="{ 'my-2': i === selectedIndex, 'my-1': i !== selectedIndex }"
        [color]="color"
        [icon]="tab.chidrenIcon"
        [label]="tab.chidrenLabel"
        [disabled]="tab.disabled"
        [selected]="i === selectedIndex && !tab.disabled"
        [count]="tab.count"
        (tabClick)="_handleClick(i)"
      ></vip-tab-sub-item>

      <hr
        *ngIf="i === lastChildrenIndex"
        class="vip-divider mx-4"
        [ngClass]="{
          'mt-3': lastChildrenIndex === selectedIndex,
          'mt-2': lastChildrenIndex !== selectedIndex
        }"
      />

      <vip-tab-item-horizontal
        *ngIf="!tab.chidrenLabel"
        [color]="color"
        [icon]="tab.icon"
        [label]="tab.label"
        [disabled]="tab.disabled"
        [disabledText]="tab.disabledText"
        [selected]="i === selectedIndex && !tab.disabled"
        [count]="tab.count"
        (tabClick)="_handleClick(i)"
      ></vip-tab-item-horizontal>
    </ng-container>
  </ng-template>
  <ng-template #skeletonTemplate>
    <vip-tab-group-desktop-skeleton></vip-tab-group-desktop-skeleton>
  </ng-template>
</aside>
<hr
  class="vip-divider-vertical"
  [ngClass]="{ 'mr-3 ': separeteDividerFromContent }"
/>
<div class="vip-section-limiter">
  <section #contentWrap class="tab-group-content-wrap">
    <ng-content></ng-content>
  </section>
</div>
