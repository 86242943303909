<div class="flex flex-col">
  <ng-container *ngIf="isApp">
    <vip-header
      data-cy="vip-header"
      [logo]="logo"
      [fallbackImageUrl]="fallbackImageUrl"
      [hiddenLogoOnError]="true"
      [headerTheme]="headerTheme"
      [flutuante]="false"
      [isLoading]="isLoading"
      (searchClick)="searchClick.emit($event)"
    >
    </vip-header>
    <vip-location
      *ngIf="isLoading || endereco"
      data-cy="vip-location"
      class="mx-3 mb-3"
      color="error"
      [retiradaLoja]="retiradaLoja"
      [isLojaAutonoma]="isLojaAutonoma"
      [endereco]="endereco"
      [isLoading]="isLoading"
      (enderecoClick)="alterarEnderecoClick.emit($event)"
    ></vip-location>
  </ng-container>
  <div *ngIf="!isApp && !isMobile" class="mb-4"></div>
  <vip-carousel
    *ngIf="isLoading || (firstCarousel && firstCarousel.slides.length > 0)"
    data-cy="first_carousel_banner"
    [slides]="firstCarousel?.slides || []"
    [bannersPerPage]="firstCarousel?.configuracoes?.itens_por_pagina || 0"
    [isLoading]="isLoading"
    [isMobile]="isMobile"
    [removeBlockMargins]="!isMobile"
    [isHomeOmni]="true"
    [wide]="true"
    type="md"
  ></vip-carousel>

  <vip-carousel-category
    *ngIf="
      !isDesktop && categoriaItens && categoriaItens.length > 0 && !isLoading
    "
    class="mt-4 mb-1"
    [isLoading]="isLoading"
    data-cy="carousel_categorias"
    [categoriaItens]="categoriaItens"
    (categoriaItemClick)="categoriaItemClick.emit($event)"
  >
  </vip-carousel-category>

  <vip-carousel-product
    *ngIf="isLoading || (ofertasExclusivas && ofertasExclusivas.length > 0)"
    class="mt-4"
    data-cy="carousel_produtos_ofertas_exclusivas"
    title="Ofertas exclusivas"
    showMoreBtnLabel="Mais Produtos"
    [showAll]="isMobile ? false : true"
    [produtos]="ofertasExclusivas"
    [produtosNoCarrinho]="produtosNoCarrinho"
    [isLoading]="isLoading"
    [isMobile]="isMobile"
    (showMoreClick)="showMoreOfertaExclusiva()"
    (addListClick)="addListClick.emit($event)"
    (produtoChanged)="produtoChanged.emit($event)"
    [adsValue]="valueAds"
  >
  </vip-carousel-product>

  <ng-container *ngFor="let vitrine of vitrinesDedicadasPerfil">
    <vip-carousel-product
      *ngIf="isLoading || (vitrine && vitrine.produtos.length > 0)"
      data-cy="carousel_produtos_vitrines_dedicadas_perfil"
      class="mt-4"
      [showAll]="isMobile ? false : true"
      [title]="vitrine.title"
      showMoreBtnLabel="Mais Produtos"
      [produtos]="vitrine.produtos"
      [aviseMeEnviados]="aviseMeEnviados"
      [produtosNoCarrinho]="produtosNoCarrinho"
      [isLoading]="isLoading"
      [isMobile]="isMobile"
      (showMoreClick)="showMoreClick.emit('vitrines/' + vitrine.id)"
      (addListClick)="addListClick.emit($event)"
      (produtoChanged)="produtoChanged.emit($event)"
      (aviseMeClick)="aviseMeClick.emit($event)"
      [adsValue]="valueAds"
    >
    </vip-carousel-product>
  </ng-container>

  <vip-carousel-product
    *ngIf="isLoading || (ofertas && ofertas.length > 0)"
    data-cy="carousel_produtos_ofertas"
    class="mt-4"
    title="Destaques"
    showMoreBtnLabel="Mais Produtos"
    [showAll]="isMobile ? false : true"
    [produtos]="ofertas"
    [aviseMeEnviados]="aviseMeEnviados"
    [produtosNoCarrinho]="produtosNoCarrinho"
    [isLoading]="isLoading"
    [isMobile]="isMobile"
    (showMoreClick)="showMoreClick.emit('destaques')"
    (addListClick)="addListClick.emit($event)"
    (produtoChanged)="produtoChanged.emit($event)"
    (aviseMeClick)="aviseMeClick.emit($event)"
    [adsValue]="valueAds"
  >
  </vip-carousel-product>

  <vip-carousel-product
    *ngIf="
      isLoading || (produtosPatrocinados && produtosPatrocinados.length > 0)
    "
    data-cy="carousel_produtos_patrocinados"
    class="mt-4"
    title="Produtos Patrocinados"
    showMoreBtnLabel="Mais Produtos"
    [showAll]="isMobile ? false : true"
    [produtos]="produtosPatrocinados"
    [aviseMeEnviados]="aviseMeEnviados"
    [produtosNoCarrinho]="produtosNoCarrinho"
    [isLoading]="isLoading"
    [isMobile]="isMobile"
    (showMoreClick)="showMoreClick.emit('produtos-patrocinados')"
    (addListClick)="addListClick.emit($event)"
    (produtoChanged)="produtoChanged.emit($event)"
    (aviseMeClick)="aviseMeClick.emit($event)"
    [adsValue]="valueAds"
  >
  </vip-carousel-product>

  <ng-container *ngFor="let vitrine of vitrinesDedicadas">
    <vip-carousel-product
      *ngIf="isLoading || (vitrine && vitrine.produtos.length > 0)"
      data-cy="carousel_produtos_vitrines_dedicadas"
      class="mt-4"
      [showAll]="isMobile ? false : true"
      [title]="vitrine.title"
      showMoreBtnLabel="Mais Produtos"
      [produtos]="vitrine.produtos"
      [aviseMeEnviados]="aviseMeEnviados"
      [produtosNoCarrinho]="produtosNoCarrinho"
      [isLoading]="isLoading"
      [isMobile]="isMobile"
      (showMoreClick)="showMoreClick.emit('vitrines/' + vitrine.id)"
      (addListClick)="addListClick.emit($event)"
      (produtoChanged)="produtoChanged.emit($event)"
      (aviseMeClick)="aviseMeClick.emit($event)"
      [adsValue]="valueAds"
    >
    </vip-carousel-product>
  </ng-container>
  <vip-carousel
    *ngIf="isLoading || (secondCarousel && secondCarousel.slides.length > 0)"
    data-cy="second_carousel_banner"
    class="mt-3"
    [slides]="secondCarousel?.slides || []"
    [bannersPerPage]="secondCarousel?.configuracoes?.itens_por_pagina || 0"
    [isLoading]="isLoading"
  >
  </vip-carousel>

  <vip-carousel-product
    *ngIf="isLoading || (favoritos && favoritos.length > 0)"
    class="mt-4"
    data-cy="carousel_produtos_favoritos"
    title="Favoritos"
    showMoreBtnLabel="Mais Produtos"
    [showAll]="isMobile ? false : true"
    [produtos]="favoritos"
    [aviseMeEnviados]="aviseMeEnviados"
    [produtosNoCarrinho]="produtosNoCarrinho"
    [isLoading]="isLoading"
    [isMobile]="isMobile"
    (showMoreClick)="
      showMoreClick.emit(isMobile ? 'favoritos' : 'minha-conta/favoritos')
    "
    (addListClick)="addListClick.emit($event)"
    (produtoChanged)="produtoChanged.emit($event)"
    (aviseMeClick)="aviseMeClick.emit($event)"
    [adsValue]="valueAds"
  >
  </vip-carousel-product>

  <vip-carousel-product
    *ngIf="isLoading || (maisVendidos && maisVendidos.length > 0)"
    data-cy="carousel_produtos_mais_vendidos"
    class="mt-4 mb-3"
    title="Mais Vendidos"
    showMoreBtnLabel="Mais Produtos"
    [showAll]="isMobile ? false : true"
    [produtos]="maisVendidos"
    [aviseMeEnviados]="aviseMeEnviados"
    [produtosNoCarrinho]="produtosNoCarrinho"
    [isLoading]="isLoading"
    [isMobile]="isMobile"
    (showMoreClick)="showMoreClick.emit('mais-vendidos')"
    (addListClick)="addListClick.emit($event)"
    (produtoChanged)="produtoChanged.emit($event)"
    (aviseMeClick)="aviseMeClick.emit($event)"
    [adsValue]="valueAds"
  >
  </vip-carousel-product>
</div>
