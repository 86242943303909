<div class="flex w-full">
  <ng-container
    *ngIf="
      (!hasProductInfo && !hasCarrinho) ||
      (hasProductInfo &&
        combo.produtos.length >= 5 &&
        !comboCategory &&
        !hasCarrinho) ||
      (hasProductInfo &&
        combo.produtos.length >= 4 &&
        comboCategory &&
        !hasCarrinho) ||
      (hasProductInfo &&
        combo.produtos.length >= 3 &&
        isTablet &&
        !hasCarrinho) ||
      (combo.produtos.length >= 3 && hasCarrinho && !isTablet) ||
      (combo.produtos.length >= 5 && hasCarrinho && isTablet)
    "
  >
    <div
      class="flex justify-center overflow-hidden"
      [ngClass]="{
        'w-[70%] pl-[26px]': !isTablet && comboCategory,
        'w-[100%]': comboCategory && isTablet && !hasCarrinho,
        'w-full':
          (!hasProductInfo && !comboCategory) ||
          (comboCategory && isTablet && hasCarrinho),
        'w-[74%]': hasProductInfo && isTablet && !comboCategory
      }"
    >
      <swiper
        [config]="swiperConfig"
        [navigation]="false"
        class="flex justify-center w-full pl-[26px]"
        [ngClass]="{
          'justify-center': !hasProductInfo || isTablet || fullPage,
          'is-tablet-swiper': isTablet,
          'center-carrinho-swipper':
            !hasProductInfo && combo.produtos.length <= 2 && comboCategory
        }"
      >
        <ng-template
          swiperSlide
          *ngFor="
            let produto of combo.produtos;
            let first = first;
            let last = last
          "
        >
          <div class="flex items-center" [ngClass]="{ 'w-full': isTablet }">
            <vip-card-combo-mini [produtoCombo]="produto"></vip-card-combo-mini>
            <div class="w-full">
              <i
                *ngIf="!last"
                class="icon-add bg-color-tag text-[24px] w-full flex justify-center items-center rounded-full"
                [ngClass]="{ 'mr-[-1px]': !hasProductInfo && !comboCategory }"
              ></i>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      (hasProductInfo &&
        combo.produtos.length < 4 &&
        !isTablet &&
        comboCategory) ||
      (combo.produtos.length <= 2 && hasCarrinho && !isTablet) ||
      (combo.produtos.length <= 4 && hasCarrinho && isTablet) ||
      (hasProductInfo && combo.produtos.length <= 2 && isTablet && !hasCarrinho)
    "
  >
    <div
      class="flex items-center pl-[26px] pr-[26px] w-full overflow-hidden"
      [ngClass]="{
        'justify-normal': hasProductInfo && !fullPage,
        'justify-center': !hasProductInfo || isTablet || fullPage,
        'gap-[20px] md:gap-[45px]':
          (isDesktop || isTablet || fullPage) &&
          comboCategory &&
          !hasCarrinho &&
          combo.produtos.length <= 2,
        'gap-[20px]':
          (isDesktop || isTablet || fullPage) && comboCategory && hasCarrinho,
        'gap-2': hasProductInfo && !comboCategory && (isTablet || isDesktop),
        'gap-3':
          hasProductInfo &&
          comboCategory &&
          (isTablet || isDesktop) &&
          sizePage > 1200 &&
          combo.produtos.length === 3
      }"
    >
      <ng-container *ngFor="let produto of combo.produtos; let last = last">
        <vip-card-combo-mini [produtoCombo]="produto"></vip-card-combo-mini>
        <i
          *ngIf="!last"
          class="icon-add bg-color-tag text-[24px] flex justify-center items-center"
        ></i>
      </ng-container>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      hasProductInfo && combo.produtos.length < 5 && !isTablet && !comboCategory
    "
  >
    <div
      class="flex items-center pl-[26px] pr-[26px] w-full"
      [ngClass]="{
        'justify-normal': hasProductInfo && !fullPage,
        'justify-center': !hasProductInfo || isTablet || fullPage,
        'gap-[20px] md:gap-[45px]':
          (isDesktop || isTablet || fullPage) && comboCategory && !hasCarrinho,
        'gap-[20px] md:gap-[40px]':
          (isDesktop || isTablet || fullPage) &&
          !hasProductInfo &&
          !hasCarrinho,
        'gap-2': hasProductInfo && (isTablet || isDesktop)
      }"
    >
      <ng-container *ngFor="let produto of combo.produtos; let last = last">
        <vip-card-combo-mini [produtoCombo]="produto"></vip-card-combo-mini>
        <i
          *ngIf="!last"
          class="icon-add bg-color-tag text-[24px] flex justify-center items-center"
        ></i>
      </ng-container>
    </div>
  </ng-container>

  <div
    *ngIf="hasProductInfo"
    class="items-center flex gap-5 pr-[26px] md:justify-end"
    [ngClass]="{
      'justify-normal': hasProductInfo,
      'justify-end w-full': !hasProductInfo || isTablet,
      'md:w-[41%]': isTablet
    }"
  >
    <div class="w-[1px] h-[151px] bg-[#ebecef] my-4"></div>
    <div>
      <div class="w-full pb-2">
        <p class="text-[18px] font-bold w-full">
          {{ combo.preco_oferta | currency: 'BRL' }}
          <span class="font-normal">({{ combo.quantidade_itens }} itens)</span>
        </p>
        <p class="text-[16px] bg-color-tag pt-1 font-bold w-full">
          Você economiza {{ valorDiferenca | currency: 'BRL' }}
        </p>
      </div>
      <div class="flex items-center w-full">
        <vip-spin
          (changed)="handleSpinAlterado($event)"
          *ngIf="editable"
          [attr.data-cy]="'card_produto_combo_' + combo.id + '_spin_large'"
          [confimDeletion]="true"
          [disabled]="loading"
          [large]="showSpinLarge"
          [max]="quantidadeMaximaSpinner"
          [quantity]="quantidade"
          [subTitleModalConfimDeletion]="combo.nome"
          class="w-[259.53px]"
          [size]="hasProductInfo ? 'small' : 'ex-small'"
          [addLabel]="'Adicionar ao carrinho'"
          [styleSpin]="isDesktop || isTablet"
          [squared]="true"
          [addIcon]="'icon-add_shopping_cart'"
          [produto]="combo"
          (click)="$event.stopPropagation()"
          [hasCombo]="hasCombo"
        ></vip-spin>
      </div>
      <div *ngIf="combo.quantidade_maxima" class="w-full mt-2">
        <p class="text-[12px] font-normal w-full vip-color-secondary-main">
          *Limite de {{ combo.quantidade_maxima }} combos por compra.
        </p>
      </div>
    </div>
  </div>
</div>
