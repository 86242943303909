<section>
  <hr class="vip-divider my-1" />
  <article class="p-3 flex flex-col items-baseline">
    <h2 class="vip-color-secondary-default text-base font-bold mb-4">
      Código Promocional:
    </h2>
    <ng-container
      *ngIf="codigoPromocional?.codigo; else inputCodigoPromocionalTemplate"
    >
      <div
        class="card-codigo-promocional-valido rounded shadow flex items-center justify-between p-3 mb-3 w-full max-w-[330px]"
      >
        <div class="flex items-center vip-color-secondary-default">
          <i class="mr-2 icon-local_activity text-3xl"></i>
          <span class="font-medium text-sm vip-color">{{
            codigoPromocional?.codigo
          }}</span>
        </div>
        <i
          (click)="excluirCodigoPromocional(codigoPromocional?.codigo || '')"
          class="vip-color-error-main icon-delete_outline text-3xl"
          data-cy="vip-excluir-codigo-btn"
        ></i>
      </div>
    </ng-container>
    <ng-template #inputCodigoPromocionalTemplate>
      <div class="flex mb-3 w-full justify-start">
        <vip-field
          icon="confirmation_number"
          iconDirection="left"
          class="mr-3 w-full max-w-[330px]"
        >
          <input
            placeholder="Digite o código promocional"
            [formControl]="codigoPromocionalControl"
          />
        </vip-field>
        <vip-button
          class="btn-ativar"
          data-cy="btn-ativar-codigo"
          (btnClick)="ativarCodigoClick()"
          >Ativar</vip-button
        >
      </div>
    </ng-template>

    <span class="vip-color-secondary-default text-xs">
      Você só pode usar um código promocional por compra.
    </span>
  </article>
  <ng-container>
    <hr class="vip-divider my-1" />
    <article class="p-3 flex flex-col items-baseline">
      <h2 class="vip-color-secondary-default text-base font-bold mb-4">
        Cupons
      </h2>
      <div class="flex flex-wrap gap-4" *ngIf="cupons.length > 0">
        <vip-cupom
          *ngFor="let cupom of cupons"
          [exibirData]="exibirData"
          class="mb-2 min-w-[16.875rem]"
          [showCheckIcon]="true"
          [created]="cupom.created"
          [validade]="cupom.validade"
          [clickable]="true"
          [disabled]="cupom.validade | expiredDueDate"
          [showProgressBar]="false"
          [checked]="getChecked(cupom)"
          (changed)="onClickCupom($event, cupom)"
        >
          <span class="font-medium">
            {{ cupom.valor | currency }} | {{ cupom.cupom_motivo?.descricao }}
          </span>
        </vip-cupom>
      </div>
      <div *ngIf="cupons.length === 0" class="w-full">
        <vip-empty
          class="h-full w-full"
          [image]="imageUrl"
          subtitle="Você não possui cupons de desconto"
          text=""
        ></vip-empty>
      </div>
    </article>
  </ng-container>
</section>
