export type DropdownItemValue = string | number | boolean | undefined;

export type DropdownItemEventType = 'click';

export class DropdownItemEvent extends Event {
  constructor(
    type: DropdownItemEventType,
    public value: DropdownItemValue,
    public text?: string,
    public originalEvent?: Event
  ) {
    super('DropdownItemEvent.' + type, {
      bubbles: originalEvent?.bubbles,
      cancelable: originalEvent?.cancelable,
      composed: originalEvent?.composed,
    });
  }
}
