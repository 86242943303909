import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IAplicativo, IStoreConfig } from '@vip/core';

export const init = createAction('[Aplicativo Page] Init');
export const initFromVipcommerceFilialId = createAction(
  '[Aplicativo Page] Init from vipcommerce filial id',
  props<{ filialId: number }>()
);

export const loadAplicativoSuccess = createAction(
  '[Aplicativo/API] Load Aplicativo Success',
  props<{ aplicativo: IAplicativo }>()
);

export const loadAplicativoFailure = createAction(
  '[Aplicativo/API] Load Aplicativo Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getStoreConfig = createAction(
  '[Aplicativo Page] Get Store Config'
);

export const getStoreConfigSuccess = createAction(
  '[Aplicativo/API] Get Store Config Success',
  props<{ storeConfig: IStoreConfig }>()
);

export const getStoreConfigFailure = createAction(
  '[Aplicativo/API] Get Store Config Failure',
  props<{ error: HttpErrorResponse }>()
);
