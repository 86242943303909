<div *ngIf="enderecos.length > 0">
  <div class="grid grid-cols-2 gap-4">
    <div *ngFor="let endereco of enderecos">
      <vip-card-endereco
        [endereco]="endereco"
        (editClick)="editarEndereco.emit(endereco)"
      ></vip-card-endereco>
    </div>
  </div>
  <div class="flex gap-3 w-full justify-end mt-4">
    <vip-button
      data-cy="button-adicionar-endereco-desktop"
      size="medium"
      (btnClick)="adicionarEndereco.emit()"
    >
      Adicionar endereço
    </vip-button>
  </div>
</div>
<div
  *ngIf="enderecos.length === 0"
  class="flex items-center justify-center h-full"
>
  <div class="flex items-center flex-col px-10 text-center mb-8">
    <i class="mr-2 size-icon icon-house vip-color-primary-main mb-3"></i>
    <span class="vip-color-third-default font-medium"
      >Você ainda não possui nenhum endereço cadastrado</span
    >

    <vip-button
      class="mt-4"
      data-cy="button-adicionar-endereco-desktop"
      size="medium"
      (btnClick)="adicionarEndereco.emit()"
    >
      Cadastrar endereço
    </vip-button>
  </div>
</div>
