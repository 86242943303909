import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltroDesktopComponent } from './filtro-desktop/filtro-desktop.component';
import { ButtonModule } from '@vip/ui/button';
import { CheckboxModule } from '@vip/ui/checkbox';
import { RadioModule } from '@vip/ui/radio';
import { FiltroDesktopItemComponent } from './filtro-desktop-item/filtro-desktop-item.component';

@NgModule({
  imports: [CommonModule, ButtonModule, CheckboxModule, RadioModule],
  declarations: [FiltroDesktopComponent, FiltroDesktopItemComponent],
  exports: [FiltroDesktopComponent],
})
export class FiltroDesktopModule {}
