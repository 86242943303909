import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as OpcaoFaturamentoActions from './opcao-faturamento.actions';
import { opcaoFaturamentoSelectors } from './opcao-faturamento.selectors';

@Injectable()
export class OpcaoFaturamentoFacade {
  error$ = this.store.pipe(select(opcaoFaturamentoSelectors.getError()));
  formaFaturamento$ = this.store.pipe(
    select(opcaoFaturamentoSelectors.getFormaFaturamento())
  );
  faturamentoPadrao$ = this.store.pipe(
    select(opcaoFaturamentoSelectors.getFaturamentoPadrao())
  );
  opcaoFaturamentoSelecionada$ = this.store.pipe(
    select(opcaoFaturamentoSelectors.getOpcaoFaturamentoSelecionada())
  );

  constructor(private readonly store: Store) {}

  getOpcaoFaturamento() {
    this.store.dispatch(OpcaoFaturamentoActions.getOpcaoFaturamento());
  }

  selectOpcaoFaturamento(opcaoFaturamentoSelecionada: string | null) {
    this.store.dispatch(
      OpcaoFaturamentoActions.selectOpcaoFaturamento({
        opcaoFaturamentoSelecionada,
      })
    );
  }
}
