import { Action, createReducer, on } from '@ngrx/store';
import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update,
} from '@ngrx/entity';

import { IProdutoCarrinho } from '@vip/core';
import * as CarrinhoItensActions from './carrinho-itens.actions';
import { GenericStoreStatusEnum, IGenericState } from '@vip/state/utils';
import { CarrinhoActions } from '@vip/state/carrinho';
import { AtualizarCarrinhoItemRequest } from '@vip/api';

export const CARRINHO_ITENS_FEATURE_KEY = 'carrinho_itens';

export interface CarrinhoItensState
  extends IGenericState<IProdutoCarrinho[]>,
    EntityState<IProdutoCarrinho> {
  itemPendenteCarrinho: AtualizarCarrinhoItemRequest | null;
}

export const carrinhoItensAdapter: EntityAdapter<IProdutoCarrinho> =
  createEntityAdapter<IProdutoCarrinho>({
    selectId: (carrinho) => carrinho.item_id,
  });

export const initialState: CarrinhoItensState =
  carrinhoItensAdapter.getInitialState({
    data: [],
    status: 'pending',
    error: null,
    itemPendenteCarrinho: null,
  });

const carrinhoItensReducer = createReducer(
  initialState,
  on(
    CarrinhoItensActions.getItensCarrinho,
    CarrinhoItensActions.removeItemCarrinho,
    (state) => ({
      ...state,
      status: GenericStoreStatusEnum.LOADING,
      error: null,
      itemPendenteCarrinho: null,
    })
  ),
  on(CarrinhoItensActions.setItemCarrinho, (state, { payload }) => {
    if (state.entities[`${payload.item_id}`]) {
      return carrinhoItensAdapter.upsertOne(
        {
          item_id: payload.item_id,
          quantidade: payload.quantidade,
          quantidade_antiga: state.entities[`${payload.item_id}`]?.quantidade,
        } as IProdutoCarrinho,
        {
          ...state,
          status: GenericStoreStatusEnum.LOADING,
          error: null,
          itemPendenteCarrinho: null,
        }
      );
    }
    return {
      ...state,
      status: GenericStoreStatusEnum.LOADING,
      error: null,
      itemPendenteCarrinho: null,
    };
  }),
  on(
    CarrinhoItensActions.setItens,
    CarrinhoItensActions.setListaItensCarrinhoSuccess,
    CarrinhoItensActions.setItemCarrinhoSuccess,
    CarrinhoItensActions.removeItemCarrinhoComItenAtualizados,
    (state, { itens }) =>
      carrinhoItensAdapter.upsertMany(itens, {
        ...state,
        status: GenericStoreStatusEnum.SUCCESS,
        error: null,
        itemPendenteCarrinho: null,
      })
  ),
  on(CarrinhoItensActions.getItensCarrinhoSuccess, (state, { itens }) =>
    carrinhoItensAdapter.setAll(itens, {
      ...state,
      status: GenericStoreStatusEnum.SUCCESS,
      error: null,
    })
  ),
  on(CarrinhoItensActions.removeItemCarrinhoSuccess, (state, { itemId }) =>
    carrinhoItensAdapter.removeOne(itemId, {
      ...state,
      status: GenericStoreStatusEnum.SUCCESS,
    })
  ),
  on(
    CarrinhoItensActions.getItensCarrinhoFailure,
    CarrinhoItensActions.removeItemCarrinhoFailure,
    (state, { error }) => ({
      ...state,
      status: GenericStoreStatusEnum.ERROR,
      error: error.message,
    })
  ),
  on(CarrinhoItensActions.setItemCarrinhoFailure, (state, action) => {
    if (action?.payload?.item_id) {
      return carrinhoItensAdapter.updateOne(
        {
          id: action.payload.item_id,
          changes: {
            quantidade:
              state.entities[`${action.payload.item_id}`]?.quantidade_antiga,
          },
        } as Update<IProdutoCarrinho>,
        {
          ...state,
          status: GenericStoreStatusEnum.ERROR,
          error: action.error.message,
          itemPendenteCarrinho: action.payload,
        }
      );
    }
    return state;
  }),
  on(
    CarrinhoItensActions.resetItensCarrinho,
    CarrinhoActions.removeCarrinho,
    (state) => ({
      ...initialState,
      itemPendenteCarrinho: state.itemPendenteCarrinho,
    })
  )
);

export function reducer(state: CarrinhoItensState | undefined, action: Action) {
  return carrinhoItensReducer(state, action);
}
