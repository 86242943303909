<ng-container *ngIf="!isDesktop; else desktopTemplate">
  <vip-entrega-retirada
    class="vip-page-content"
    [enderecos]="
      (isLogged$ | async)
        ? (enderecos$ | async) || []
        : novoEndereco
        ? [novoEndereco]
        : []
    "
    [cdsEntrega]="(cdsEntrega$ | async) ?? []"
    [cdsRetirada]="(cdsRetirada$ | async) || []"
    [realizaEntrega]="(realizaEntrega$ | async) || false"
    [enderecoSelecionado]="novoEndereco || (enderecoEntrega$ | async)"
    [cdSelecionado]="getCdSelecionado()"
    [formaEntregaFromState]="formaEntrega"
    [clienteLogado]="(cliente$ | async) !== null"
    [possuiLojaAutonoma]="(possuiLojaAutonoma$ | async) || false"
    [isLojaAutonoma]="(isLojaAutonoma$ | async) || false"
    [isFromResumoCompra]="isFromResumoCompra"
    [qtdItensCarrinho]="(qtdItensCarrinho$ | async) || 0"
    (enderecoEntregaChange)="alterarEnderecoEntrega($event)"
    (cdChange)="onCdChange($event)"
    (cadastrarEndereco)="cadastrarEndereco()"
    (goBackClick)="handleGoBack(false)"
    (confirmarClick)="handleGoBack(true)"
    (qrCodeScan)="handleQrCodeScanClick($event)"
    (informarCepClick)="onInformarCepClick()"
    (formaEntregaChange)="onFormaEntregaChange($event)"
    [formaEntrega]="novaFormaEntrega || formaEntrega"
    [loadingEnderecos]="(loadingEnderecos$ | async) || false"
  ></vip-entrega-retirada>
</ng-container>
<ng-template #desktopTemplate>
  <vip-entrega-retirada-desktop
    class="flex h-full"
    [enderecos]="
      (isLogged$ | async)
        ? (enderecos$ | async) || []
        : novoEndereco
        ? [novoEndereco]
        : []
    "
    [logoSrc]="(logo$ | async) ?? ''"
    [fallbackImageUrl]="(logoFallback$ | async) || ''"
    [showStepper]="isFromResumoCompra"
    [cdsEntrega]="(cdsEntrega$ | async) ?? []"
    [cdsRetirada]="(cdsRetirada$ | async) || []"
    [cdSelecionado]="getCdSelecionado()"
    [enderecoSelecionado]="
      novoEndereco ??
      (formaEntrega === formaEntregaEnum.TIPO_ENTREGA
        ? (enderecoEntrega$ | async)
        : null)
    "
    [formaEntregaFromState]="formaEntrega"
    [tiposEntrega]="(tipoEntregas$ | async) ?? []"
    [taxaEntrega]="(taxaEntrega$ | async) ?? undefined"
    [taxaServico]="taxaServico$ | async"
    [horarioSelecionado$]="horarioSelecionado$"
    [tipoEntregaSelecionado]="tipoEntregaSelecionado$ | async"
    [url_google]="(filial$ | async)?.site_url | getURLGoogleSafe"
    [totalCompra]="(totalCompra$ | async) ?? 0"
    [disablePagamentoButton]="disablePagamentoButton"
    [changeTab$]="changeTab.asObservable()"
    [exibirTiposEntrega]="exibirTiposEntrega$ | async"
    [exibirTiposRetirada]="exibirTiposRetirada$ | async"
    (horarioChange)="handleHorarioChange($event)"
    (formaEntregaChange)="onFormaEntregaChange($event)"
    (enderecoEntregaChange)="alterarEnderecoEntrega($event)"
    (cdChange)="onCdChange($event); getTiposEntregasByCdChange()"
    (tipoEntregaChanged)="handleTipoEntregaChanged($event)"
    (iniciarCheckout)="iniciarCheckout()"
    (criarCompraClicked)="criarCompra()"
    (editarEndereco)="selecionarEnderecoParaEdicao($event)"
    (continuarComprando)="continuarComprando()"
  ></vip-entrega-retirada-desktop>
  <vip-loading
    *ngIf="isLoading$ | async"
    textLoading="Carregando tipos de {{
      formaEntrega === formaEntregaEnum.TIPO_ENTREGA ? 'entrega' : 'retirada'
    }}"
  ></vip-loading>
</ng-template>
<vip-dialog
  data-cy="dialog-cep-nao-atendido"
  [open]="exibirMsgCepNaoAtendido"
  title="CEP não atendido"
  buttonConfirmText="Ok, entendi"
  (dialogClick)="exibirMsgCepNaoAtendido = false"
  (closeClick)="exibirMsgCepNaoAtendido = false"
>
</vip-dialog>
