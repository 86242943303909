import { Inject, Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { IGa4Item } from '../../interfaces/ga4.interface';
import { IS_APP } from '../../constants';
@Injectable({
  providedIn: 'root',
})
export class Ga4UtilsService {
  constructor(
    private gaService: GoogleAnalyticsService,
    @Inject(IS_APP) private isApp: boolean
  ) {}

  viewCart(value: number, itens: Array<IGa4Item>) {
    const eventParams = {
      currency: 'BRL',
      value: value,
      items: itens,
      aplicacao: this.isApp ? 'App' : 'Site',
    };

    this.gaService.gtag('event', 'view_cart', eventParams);
  }

  addToCart(value: number, itens: Array<IGa4Item>) {
    const eventParams = {
      currency: 'BRL',
      value: value,
      items: itens,
      aplicacao: this.isApp ? 'App' : 'Site',
    };
    this.gaService.gtag('event', 'add_to_cart', eventParams);
  }

  beginCheckout(value: number, items: Array<IGa4Item>) {
    const eventParams = {
      currency: 'BRL',
      value,
      items: items,
      aplicacao: this.isApp ? 'App' : 'Site',
    };
    this.gaService.gtag('event', 'begin_checkout', eventParams);
  }

  addShippingInfo(value: number, dataEntrega: string, items: Array<IGa4Item>) {
    const eventParams = {
      currency: 'BRL',
      value,
      shipping_tier: dataEntrega,
      items: items,
      aplicacao: this.isApp ? 'App' : 'Site',
    };
    this.gaService.gtag('event', 'add_shipping_info', eventParams);
  }

  addPaymentInfo(
    value: number,
    tipoPagamento: string,
    items: Array<IGa4Item>
  ): void {
    const eventParams = {
      currency: 'BRL',
      value,
      payment_type: tipoPagamento,
      items: items,
      aplicacao: this.isApp ? 'App' : 'Site',
    };
    this.gaService.gtag('event', 'add_payment_info', eventParams);
  }

  purchase(
    value: number,
    frete: number,
    compraId: string,
    items: Array<IGa4Item>
  ): void {
    const eventParams = {
      currency: 'BRL',
      value,
      shipping: frete,
      transaction_id: compraId,
      items: items,
      aplicacao: this.isApp ? 'App' : 'Site',
    };
    this.gaService.gtag('event', 'purchase', eventParams);
  }

  removeFromCart(value: number, itens: Array<IGa4Item>) {
    const eventParams = {
      currency: 'BRL',
      value: value,
      items: itens,
      aplicacao: this.isApp ? 'App' : 'Site',
    };
    this.gaService.gtag('event', 'remove_from_cart', eventParams);
  }

  addToWishList(value: number, itens: Array<IGa4Item>) {
    const eventParams = {
      currency: 'BRL',
      value: value,
      items: itens,
      aplicacao: this.isApp ? 'App' : 'Site',
    };
    this.gaService.gtag('event', 'add_to_wishlist', eventParams);
  }

  viewProductPage(value: number, itens: Array<IGa4Item>) {
    const eventParams = {
      currency: 'BRL',
      value: value,
      items: itens,
      aplicacao: this.isApp ? 'App' : 'Site',
    };
    this.gaService.gtag('event', 'view_item', eventParams);
  }

  searchProduct(searchTerm: string) {
    const eventParams = {
      search_term: searchTerm,
      aplicacao: this.isApp ? 'App' : 'Site',
    };
    this.gaService.gtag('event', 'search', eventParams);
  }

  loginSignup(evento: string) {
    const eventParams = {
      method: this.isApp ? 'App' : 'Site',
      aplicacao: this.isApp ? 'App' : 'Site',
    };
    this.gaService.gtag('event', evento, eventParams);
  }
}
