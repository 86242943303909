import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IDescontoFidelidade } from '@vip/core';

export const getDescontosFidelidadeCompra = createAction(
  '[Descontos Fidelidade/WS] Get descontos de fidelidade compra',
  props<{ compraId: number }>()
);

export const getDescontosFidelidadeCompraSuccess = createAction(
  '[Descontos Fidelidade/WS] Get descontos de fidelidade compra Success',
  props<{ descontoFidelidade: IDescontoFidelidade }>()
);

export const getDescontosFidelidadeCompraFailure = createAction(
  '[Descontos Fidelidade/WS] Get descontos de fidelidade compra Failure',
  props<{ error: HttpErrorResponse }>()
);

export const resetFidelidadeCompra = createAction(
  '[Descontos Fidelidade/WS] reset descontos de fidelidade compra'
);

export const setIsFidelidadeAtivoSuccess = createAction(
  '[Descontos Fidelidade/WS] Set fidelidade ativo',
  props<{ isFidelidadeAtivo: boolean }>()
);
