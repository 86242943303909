import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { FilialFacade } from '@vip/state/filial';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { FidelidadeWsService, IGetDescontosFidelidadeResponse } from '@vip/ws';
import { BucketsFacade, BucketsService } from '@vip/state/buckets';

import * as FidelidadeActions from './fidelidade.actions';
import { FilialActions } from '@vip/state/filial';
import { FidelidadeFacade } from './fidelidade.facade';

@Injectable()
export class FidelidadeEffects {
  getDescontoFidelidadeCompra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FidelidadeActions.getDescontosFidelidadeCompra),
      withLatestFrom(
        this.filialFacade.filial$,
        this.bucketsFacade.bucketProduto$,
        this.fidelidadeFacade.isFidelidadeAtivo$
      ),
      filter(([, , , fidelidade]) => fidelidade),
      map(([action, filial, bucket]) => ({
        ...action,
        filial,
        bucket,
      })),
      fetch({
        run: (action) =>
          this.fidelidadeWsService
            .getOfertasFidelidade(action.filial.id, action.compraId)
            .pipe(
              map((descontos: IGetDescontosFidelidadeResponse) => {
                return FidelidadeActions.getDescontosFidelidadeCompraSuccess({
                  descontoFidelidade: {
                    ...descontos.desconto,
                    produtos: descontos.desconto.produtos.map((item) =>
                      this.bucketsService.addBucketProduto(item, action.bucket)
                    ),
                  },
                });
              })
            ),
        onError: (action, error) => {
          return FidelidadeActions.getDescontosFidelidadeCompraFailure({
            error,
          });
        },
      })
    )
  );

  setIsFidelidadeAtivo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilialActions.getFilialPorVipCommerceFilialIdSuccess),
      map((action) =>
        FidelidadeActions.setIsFidelidadeAtivoSuccess({
          isFidelidadeAtivo:
            action.fidelidade.configuracoes.propz?.ativo || false,
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private fidelidadeWsService: FidelidadeWsService,
    private bucketsFacade: BucketsFacade,
    private filialFacade: FilialFacade,
    private bucketsService: BucketsService,
    private fidelidadeFacade: FidelidadeFacade
  ) {}
}
