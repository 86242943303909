import { Output, EventEmitter } from '@angular/core';
import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
} from '@angular/core';

@Component({
  selector: 'vip-selectable-card',
  templateUrl: './selectable-card.component.html',
  styleUrls: ['./selectable-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SelectableCardComponent {
  @Input() showIcon = true;
  @Input() fullWidth = true;
  @Input() fullHeight = false;
  @Input() checked = false;
  @Input() changeTextColor = false;
  @Input() iconSize: 'medium' | 'large' = 'medium';
  @Input() disableAutoToggle = false;
  @Input() disabled = false;
  @Input() expanded = false;
  @Input() showControl = false;
  @Input() noShadow = false;
  @Input() error = false;

  @Output() changed = new EventEmitter();

  toggleChecked() {
    if (this.disabled || this.error) {
      return;
    }
    if (!this.disableAutoToggle) {
      this.checked = !this.checked;
    }
    this.changed.emit(this.checked);
  }
}
