import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as TelevendasActions from './televendas.actions';
import * as TelevendasSelectors from './televendas.selectors';
import {
  ICliente,
  IOperadorTelevendas,
  TelevendasTokenService,
} from '@vip/core';
import { filter, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class TelevendasFacade {
  token$ = this.store.pipe(
    select(TelevendasSelectors.getToken),
    tap((token) => {
      if (!token) {
        const tokenFromStorage = this.televendasTokenService.getToken();
        if (tokenFromStorage) {
          this.setTokenInState(tokenFromStorage);
        }
      }
    }),
    filter((token) => !!token)
  );
  operador$ = this.store.pipe(
    select(TelevendasSelectors.getOperador),
    tap((operador) => {
      if (operador === null) {
        const operadorFromStorage = this.televendasTokenService.getOperador();
        if (operadorFromStorage !== null) {
          this.setOperadorInState(operadorFromStorage);
        }
      }
    }),
    filter((operador) => operador !== null)
  );

  filiaisTelevendas$ = this.store.pipe(
    select(TelevendasSelectors.getFiliaisTelevendasSelector)
  );
  clientesTelevenda$ = this.store.pipe(
    select(TelevendasSelectors.getClienteTelevendasSelector)
  );
  vincularFilialSuccess$ = this.actions$.pipe(
    ofType(TelevendasActions.vincularFilialTelevendasSuccess)
  );

  loading$ = this.store.pipe(select(TelevendasSelectors.isLoading));

  get isTelevendas(): boolean {
    return (
      !!this.televendasTokenService.getToken() &&
      this.televendasTokenService.getOperador() !== null
    );
  }

  constructor(
    private readonly store: Store,
    private televendasTokenService: TelevendasTokenService,
    private readonly actions$: Actions
  ) {}

  logar(id: string, password: string): void {
    this.store.dispatch(TelevendasActions.logarTelevendas({ id, password }));
  }

  setTokenInState(token: string): void {
    this.store.dispatch(TelevendasActions.setToken({ token }));
  }

  setOperadorInState(operador: IOperadorTelevendas): void {
    this.store.dispatch(TelevendasActions.setOperador({ operador }));
  }

  encerrarSessao(): void {
    this.store.dispatch(TelevendasActions.encerrarSessao());
  }

  getFiliaisTelevendas() {
    this.store.dispatch(TelevendasActions.getFiliaisTelevendas());
  }

  getClienteTelevendas(term: string) {
    this.store.dispatch(TelevendasActions.getClientesTelevendas({ term }));
  }

  logarClienteTelevendas(cliente_id: string, filial_id: string): void {
    this.store.dispatch(
      TelevendasActions.logarClienteTelevendas({ cliente_id, filial_id })
    );
  }

  vincularFilial(id: number, filial_id: number): void {
    this.store.dispatch(
      TelevendasActions.vincularFilialTelevendas({ id, filial_id })
    );
  }
}
