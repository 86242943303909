import { IFilter, IFilterCombos, IProdutoCombo, QueryParams } from '@vip/core';
import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const getCombo = createAction('[Combo/API] Listar combos');

export const getComboSuccess = createAction(
  '[Combo/API] Listar combos Success',
  props<{ combos: number[]; filtro: IFilterCombos[]; possuiFiltro: boolean }>()
);

export const getComboFailure = createAction(
  '[Combo/API] Listar combos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getComboProdutos = createAction(
  '[Combo/API] Listar produtos combos',
  props<{ comboId: number }>()
);

export const getComboProdutosSuccess = createAction(
  '[Combo/API] Listar combos Produtos Success',
  props<{ produtos: IProdutoCombo[] }>()
);

export const getComboProdutosFailure = createAction(
  '[Combo/API] Listar combos Protudos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getCombosProdutosByArray = createAction(
  '[Combo/API] Listar produtos combos',
  props<{ combosId: number[]; queryParams?: QueryParams }>()
);

export const getCombosDepartamentos = createAction(
  '[Combo/API] Listar combos departamentos',
  props<{ queryParams?: QueryParams }>()
);

export const getCombosDepartamentosSuccess = createAction(
  '[Combo/API] Listar combos departamentos Success',
  props<{ combos: number[]; filtro: IFilter[] }>()
);

export const getCombosDepartamentosFailure = createAction(
  '[Combo/API] Listar combos departamentos Failure',
  props<{ error: HttpErrorResponse }>()
);
