export enum CompraStatusEnum {
  ATENDIMENTO = 1,
  PENDENTE = 11,
  AGUARDANDO_ATENDIMENTO = 9,
  SOLICITACAO_DE_CANCELAMENTO = 31,
  RISCO_DE_FRAUDE = 37,
  PENDENTE_ANTIFRAUDE = 39,
  EM_ATENDIMENTO = 10,
  SEPARACAO = 2,
  AGUARDANDO_SEPARACAO = 12,
  EM_SEPARACAO = 13,
  PENDENTE_SEPARACAO = 14,
  EMPACOTAMENTO = 3,
  AGUARDANDO_EMPACOTAMENTO = 18,
  EM_EMPACOTAMENTO = 19,
  PENDENTE_EMPACOTAMENTO = 20,
  FATURAMENTO = 4,
  AGUARDANDO_FATURAMENTO = 21,
  EM_FATURAMENTO = 22,
  LOGISTICA = 5,
  AGUARDANDO_LOGISTICA = 23,
  EM_LOGISTICA = 25,
  CARREGAMENTO = 32,
  AGUARDANDO_CARREGAMENTO = 33,
  EM_CARREGAMENTO = 34,
  CHECK_OUT = 6,
  AGUARDANDO_CHECK_OUT = 30,
  EM_CHECK_OUT = 26,
  CHECK_IN = 7,
  EM_ENTREGA = 27,
  ENTREGUE = 35,
  NAO_ENTREGUE = 36,
  AGUARDANDO_CHECK_IN = 28,
  EM_CHECK_IN = 29,
  COMPRA = 8,
  FINALIZADA = 15,
  EM_PROCESSO_DE_COMPRA = 16,
  ANALISE_DE_FRAUDE = 38,
  CANCELADA = 17,
}
