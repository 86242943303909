import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { ClienteTokenService } from '@vip/state/cliente';
import * as SessaoActions from './sessao.actions';
import { sessaoSelectors } from './sessao.selectors';
import { SessaoService } from '../services/sessao.service';
import { CarrinhoFacade } from '@vip/state/carrinho';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';

@Injectable()
export class SessaoFacade {
  loaded$ = this.store.pipe(select(sessaoSelectors.isLoaded()));
  sessaoId$ = this.store.pipe(select(sessaoSelectors.getSessaoId()));

  constructor(
    private readonly store: Store,
    private carrinhoFacade: CarrinhoFacade,
    private carrinhoItensFacade: CarrinhoItensFacade,
    private clienteTokenService: ClienteTokenService,
    private sessaoService: SessaoService
  ) {}

  init() {
    if (!this.sessaoService.getSessaoId()) {
      this.store.dispatch(SessaoActions.init());
    }
    if (
      !this.clienteTokenService.getToken() &&
      this.sessaoService.getSessaoId()
    ) {
      this.carrinhoFacade.getCarrinho();
      this.carrinhoItensFacade.getItensCarrinho();
    }
  }
}
