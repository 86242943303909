import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TabItemBaseDirective } from '../base/tab-item-base.directive';

@Component({
  selector: 'vip-tab-item-horizontal',
  templateUrl: './tab-item-horizontal.component.html',
  styleUrls: ['./tab-item-horizontal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.selected]': 'selected',
  },
})
export class TabItemHorizontalComponent extends TabItemBaseDirective {}
