<vip-base-component-skeleton
  [quantity]="quantity"
  listDirection="vertical"
  class="grid grid-cols-2 gap-2 px-3"
  [ngClass]="{ 'px-0': noSidePadding }"
>
  <ng-template #skeletonContent let-odd>
    <div class="border rounded p-3 flex flex-col">
      <ng-container
        *vipSkeleton="
          true;
          width: '100%';
          height: '100%';
          radius: '5px';
          className: ['aspect-square']
        "
      ></ng-container>
      <div class="w-full mt-3 flex justify-end">
        <ng-container
          *vipSkeleton="
            true;
            width: '35px';
            height: '35px';
            radius: '50px';
            className: ['mb-3']
          "
        ></ng-container>
      </div>
      <div class="w-full flex flex-col">
        <ng-container
          *vipSkeleton="
            true;
            width: '100%';
            height: '15px';
            className: ['mb-1']
          "
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '80px'; height: '33px'"
        ></ng-container>
      </div>
    </div>
  </ng-template>
</vip-base-component-skeleton>
