import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  ICentroDistribuicao,
  IFilial,
  IRelacionamentoCliente,
} from '@vip/core';

@Component({
  selector: 'vip-whats-app',
  templateUrl: './whats-app.component.html',
  styleUrls: ['./whats-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsAppComponent implements OnInit {
  filialECdSelecionado$ = this.centroDistribuicaoFacade.filialECdSelecionado$;
  whatsappLink = '';
  hasLink = false;

  constructor(
    private centroDistribuicaoFacade: CentroDistribuicaoFacade,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.filialECdSelecionado$.subscribe((response) => {
      response.forEach((item: IFilial | ICentroDistribuicao) => {
        if (this.hasRelacionamentoCliente(item)) {
          if ((item.relacionamento_cliente as any).link_whatsapp === '1') {
            this.hasLink = true;
            this.whatsappLink = this.urlLinkWhatsApp(
              item.relacionamento_cliente
            );
          } else {
            this.hasLink = false;
          }
          this.changeDetectorRef.detectChanges();
        }
      });
    });
  }

  private hasRelacionamentoCliente(
    item: IFilial | ICentroDistribuicao
  ): item is ICentroDistribuicao {
    return (item as ICentroDistribuicao).relacionamento_cliente !== undefined;
  }

  openWhatsAppLink(): void {
    if (this.whatsappLink) {
      window.open(this.whatsappLink, '_blank');
    }
  }

  private urlLinkWhatsApp(relacionamento: IRelacionamentoCliente): string {
    if (!relacionamento) {
      return '';
    }

    const whatsappNumber = relacionamento.whatsapp;
    if (!whatsappNumber) {
      return '';
    }

    const message = relacionamento.mensagem_whatsapp
      ? '&text=' + encodeURIComponent(relacionamento.mensagem_whatsapp)
      : '';
    return (
      'https://api.whatsapp.com/send?phone=55' +
      whatsappNumber.replace(/[^0-9]/g, '').toString() +
      message
    );
  }
}
