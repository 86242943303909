import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FILTRO_FEATURE_KEY, FiltroState } from './filtro.reducer';
import { SelectorUtil } from '@vip/state/utils';

export const getFiltroState =
  createFeatureSelector<FiltroState>(FILTRO_FEATURE_KEY);

class FiltroSelectors extends SelectorUtil<FiltroState> {
  constructor() {
    super(FILTRO_FEATURE_KEY);
  }

  getFiltroAtivoPagina = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FiltroState) => state.data.filtroAtivo
    );

  getFiltroAtivo = () =>
    createSelector(this.createFeatureSelector, (state: FiltroState) =>
      state.data.filtroAtivo ? state.data[state.data.filtroAtivo] : []
    );

  getFiltroClassificacaoMercadologica = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FiltroState) => state.data.filtroClassificacaoMercadologica
    );

  getFiltrosBusca = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FiltroState) => state.data.filtrosBusca
    );

  getFiltrosColecao = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FiltroState) => state.data.filtrosColecao
    );

  getFiltroFavoritos = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FiltroState) => state.data.filtroFavoritos
    );

  getFiltroMinhasListas = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FiltroState) => state.data.filtroMinhasListas
    );

  getFiltrosOfertas = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FiltroState) => state.data.filtrosOfertas
    );

  getFiltrosCombos = () =>
    createSelector(
      this.createFeatureSelector,
      (state: FiltroState) => state.data.filtrosCombos
    );
}

export const filtroSelectors = new FiltroSelectors();
