<vip-base-component-skeleton class="flex flex-col" [quantity]="quantity">
  <ng-template #skeletonContent>
    <div class="border rounded p-2 flex flex-row mb-2">
      <ng-container
        *vipSkeleton="
          true;
          width: '60px';
          height: '60px';
          radius: '3px';
          className: ['mr-2']
        "
      ></ng-container>
      <div class="w-full flex flex-col">
        <ng-container
          *vipSkeleton="
            true;
            width: '130px';
            height: '15px';
            className: ['mb-2']
          "
        ></ng-container>
        <ng-container
          *vipSkeleton="
            true;
            width: '214px';
            height: '15px';
            className: ['mb-2']
          "
        ></ng-container>
        <ng-container
          *vipSkeleton="true; width: '130px'; height: '10px'"
        ></ng-container>
      </div>
    </div>
  </ng-template>
</vip-base-component-skeleton>
