<vip-card-basico
  icon="icon-description"
  title="Opções de Faturamento"
  [disableRipple]="true"
>
  <vip-radio-group>
    <vip-radio-button
      *ngIf="
        opcoesFaturamento === opcaoFaturamentoEnum.AMBOS ||
        opcoesFaturamento === opcaoFaturamentoEnum.CUPOM_FISCAL
      "
      [value]="opcaoFaturamentoEnum.CUPOM_FISCAL"
      [checked]="opcaoFaturamento === opcaoFaturamentoEnum.CUPOM_FISCAL"
      (changed)="changed.emit(opcaoFaturamentoEnum.CUPOM_FISCAL)"
    >
      Cupom Fiscal
    </vip-radio-button>
    <hr
      class="vip-divider mb-2"
      *ngIf="opcoesFaturamento === opcaoFaturamentoEnum.AMBOS"
    />
    <vip-radio-button
      *ngIf="
        opcoesFaturamento === opcaoFaturamentoEnum.AMBOS ||
        opcoesFaturamento === opcaoFaturamentoEnum.NOTA_FISCAL
      "
      [value]="opcaoFaturamentoEnum.NOTA_FISCAL"
      [checked]="opcaoFaturamento === opcaoFaturamentoEnum.NOTA_FISCAL"
      (changed)="changed.emit(opcaoFaturamentoEnum.NOTA_FISCAL)"
    >
      Nota Fiscal
    </vip-radio-button>
  </vip-radio-group>
</vip-card-basico>
