import { Inject, Injectable } from '@angular/core';
import {
  DEFAULT_COOKIE_EXPIRATION_TIME,
  OPERADOR,
  OPERADOR_TOKEN,
  STORAGE,
} from '../../constants';
import { IOperadorTelevendas, IStorage } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class TelevendasTokenService {
  private tokenCache: string | null = null;
  private operadorCache: IOperadorTelevendas | null = null;
  readonly defaultExpirationTime = DEFAULT_COOKIE_EXPIRATION_TIME;

  constructor(@Inject(STORAGE) readonly storage: IStorage) {}

  getToken(): string | null {
    if (this.tokenCache) {
      return this.tokenCache;
    }
    this.tokenCache = this.storage.getItem(OPERADOR_TOKEN) || '';
    return this.tokenCache;
  }

  getOperador(): IOperadorTelevendas | null {
    if (this.operadorCache) {
      return this.operadorCache;
    }
    const parsedObj = JSON.parse(this.storage.getItem(OPERADOR) || '{}');
    this.operadorCache = Object.keys(parsedObj).length === 0 ? null : parsedObj;
    return this.operadorCache;
  }

  setToken(value: string): void {
    this.storage.setItem(
      OPERADOR_TOKEN,
      value,
      this.defaultExpirationTime,
      '/'
    );
  }

  setOperador(value: IOperadorTelevendas): void {
    this.storage.setItem(
      OPERADOR,
      JSON.stringify(value),
      this.defaultExpirationTime,
      '/'
    );
  }

  clearToken(): void {
    this.tokenCache = null;
    this.storage.removeItem(OPERADOR_TOKEN, '/');
  }

  clearOperador(): void {
    this.operadorCache = null;
    this.storage.removeItem(OPERADOR, '/');
  }
}
