import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IFilter, IFilterOptions } from '@vip/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'vip-filtro-desktop',
  templateUrl: './filtro-desktop.component.html',
  styleUrls: ['./filtro-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltroDesktopComponent implements OnChanges {
  private _filters: IFilter[] = [];
  _filterType = '';
  filtroTypes = '';

  @Input() loading = false;

  @Input() set filterType(type: string) {
    this._filterType = type;
    this.filtroTypes = type;
  }

  get filterType(): string {
    return this._filterType;
  }

  @Input() qtdMaxVisibleItens = 5;

  @Input() set filters(filters: IFilter[]) {
    setTimeout(() => {
      // TODO timeout aguardando o filtroTypes
      const filtros = filters.filter((filter) => filter.name !== 'orderby');
      this.setHiddenOptions();
      this._filters = filtros
        .filter((filter) => filter.type === this.filtroTypes)
        .map((filter) => ({
          ...filter,
          formatedTags: this.getFormatedTags(filter.options),
        }));
      this._filters = cloneDeep(this._filters);
      this.changeDetector.detectChanges();
    });
  }

  get filters(): IFilter[] {
    return this._filters;
  }

  @Output() filtersChange = new EventEmitter();

  filtersChangeDebouncer: Subject<void> = new Subject<void>();

  constructor(private changeDetector: ChangeDetectorRef) {
    this.filtersChangeDebouncer
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe(() => {
        this.filtersChange.emit(this.filters);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filterType']) {
      this.filtroTypes = this.filterType;
      this.changeDetector.detectChanges();
    }
  }

  onFiltersChange() {
    this.setHiddenOptions();
    this.changeDetector.detectChanges();
    this.filtersChangeDebouncer.next();
  }

  setHiddenOptions() {
    const secoesSelecionadas = this.filters
      .find((filtro) => filtro.name === 'secao')
      ?.options.map((option) => (option.checked === true ? option : null))
      .filter((option) => option);

    const departamentosSelecionados = this.filters
      .find((filtro) => filtro.name === 'departamento')
      ?.options.map((option) => (option.checked === true ? option : null))
      .filter((option) => option);

    this.filters.forEach((filter) => {
      if (filter.name === 'secao') {
        if (departamentosSelecionados?.length) {
          const departamentoSelecionado = departamentosSelecionados.pop();
          filter.options.forEach((option) => {
            if (departamentoSelecionado?.value !== '0') {
              if (
                departamentoSelecionado?.value &&
                parseInt(departamentoSelecionado?.value) !== option.departamento
              ) {
                if ('hidden' in option) {
                  option.hidden = true;
                }
                if (option.checked) {
                  option.checked = false;
                }
              } else {
                if ('hidden' in option) {
                  option.hidden = false;
                }
              }
            } else {
              if ('hidden' in option) {
                option.hidden = false;
              }
            }
          });
        }
      }
    });
  }

  getFormatedTags(options: IFilterOptions[]): string[] {
    const tipos = new Array<string>();

    options.forEach((value) => {
      if (value.tipo && !tipos.includes(value?.tipo)) {
        tipos.push(value.tipo);
      }
    });

    tipos.push('');
    return tipos;
  }
}
