import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { TermosUsoFacade } from '@vip/state/termos-uso';
import { DialogService, IDialog } from '@vip/ui/modal';
import { RouterTransitionEnum } from '@vip/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'vip-termos-uso-container',
  templateUrl: './termos-uso-container.component.html',
  styleUrls: ['./termos-uso-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TermosUsoContainerComponent implements OnInit {
  loading$ = this.termosUsoFacade.loading$;
  termosUso$ = this.termosUsoFacade.termosUso$;
  aprovacaoPendente = false;
  canGoBack = true;
  openAsModal = false;
  isDesktopModal = false;
  @Input() modalPage = '';
  @Output() handleAcceptOutput = new EventEmitter<void>();

  constructor(
    private termosUsoFacade: TermosUsoFacade,
    private location: Location,
    private route: ActivatedRoute,
    private dialogService: DialogService
  ) {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.setPageProperties(params['page']);
    });
    termosUsoFacade.getTermosUso();
  }

  ngOnInit(): void {
    if (this.modalPage) {
      this.isDesktopModal = true;
      this.setPageProperties(this.modalPage);
    }
  }

  @HostBinding('attr.router-transition-type') get routerTransition() {
    return this.openAsModal
      ? RouterTransitionEnum.BackInUp
      : RouterTransitionEnum.BackInRight;
  }

  handleAccept() {
    this.handleAcceptOutput.emit();
    this.termosUsoFacade.aprovarTermosUso();
  }

  goBack() {
    if (!this.canGoBack && this.aprovacaoPendente) {
      const dialogData: IDialog = {
        open: true,
        title: 'Por favor, aceite os termos de uso!',
        subTitle:
          'É necessário aceitar os termos de uso para continuar a usar o aplicativo.',
        disabled: false,
        buttonConfirmText: 'Aceitar termos de uso',
      };
      this.dialogService.openDialog(dialogData);
      this.dialogService.dialogClick
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.dialogService.clearDialog();
        });
    } else {
      this.location.back();
    }
  }

  setPageProperties(page: string) {
    this.aprovacaoPendente = false;
    this.openAsModal = false;
    this.canGoBack = true;
    if (page == 'cadastro') {
      this.aprovacaoPendente = false;
      this.openAsModal = true;
    }
    if (page == 'atualizacao') {
      this.openAsModal = true;
      this.canGoBack = false;
      this.verificarAprovacaoPendente();
    }
    if (page == 'completar') {
      this.aprovacaoPendente = false;
      this.openAsModal = true;
    }
  }

  verificarAprovacaoPendente() {
    this.termosUsoFacade.aprovacaoPendente$
      .pipe(untilDestroyed(this))
      .subscribe((_aprovacaoPendente) => {
        this.aprovacaoPendente = _aprovacaoPendente as boolean;
        if (!this.aprovacaoPendente && !this.canGoBack) {
          this.canGoBack = true;
          this.goBack();
        }
      });
  }
}
