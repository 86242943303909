import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { TabBaseDirective } from '../base/tab-base.directive';
import { ScrollableService } from '@vip/core';

@Component({
  selector: 'vip-tab-group-desktop',
  templateUrl: './tab-group-desktop.component.html',
  styleUrls: ['./tab-group-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
})
export class TabGroupDesktopComponent
  extends TabBaseDirective
  implements AfterViewInit
{
  private _isLoading = false;

  @Input()
  separeteDividerFromContent = true;

  @Input()
  preventEmitTabChangeOnInit = false;

  @Input()
  set isLoading(value: boolean) {
    this._isLoading = value;
    if (!value) {
      this.initTabGroupAfterSkeletonDisapear();
    }
  }
  get isLoading(): boolean {
    return this._isLoading;
  }

  constructor(
    public scrollableService: ScrollableService,
    public renderer: Renderer2,
    private changeDetector: ChangeDetectorRef
  ) {
    super(scrollableService, renderer, changeDetector);
  }

  ngAfterViewInit(): void {
    this.initTabGroup(this.preventEmitTabChangeOnInit);
    if (this.initialIndex) {
      this._handleClick(this.initialIndex, this.preventEmitTabChangeOnInit);
      this.selectedIndex = this.initialIndex;
    }
    this.changeDetector.detectChanges();
  }

  private initTabGroupAfterSkeletonDisapear(): void {
    setTimeout(() => {
      this.initTabGroup();
      this.changeDetector.detectChanges();
    }, 0);
  }
}
