import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isValid, parse } from 'date-fns';

export class CustomValidators {
  static numeric(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null || value === '') return null;
    if (!value.toString().match(/^[0-9]+(\.?[0-9]+)?$/))
      return { invalidNumber: true };
    return null;
  }

  static date(control: AbstractControl): ValidationErrors | null {
    let date = control.value || '';

    if (date.length === 0) return null;

    date = parse(control.value, 'dd/MM/yyyy', new Date());
    return isValid(date) ? null : { invalidDate: true };
  }

  static cpf(control: AbstractControl): ValidationErrors | null {
    const DIGITOS_IGUAIS = /^(.)\1+$/;
    const CPF_ERROR = { invalidCpf: true };
    let cpf = control.value || '';
    cpf = cpf.replace(/[\D]+/g, '');
    let numeros, soma, resultado;

    if (cpf.length === 0) return null;

    // CPF deve ter 11 dígitos e os mesmos devem ser diferentes
    if (!(cpf.length === 11) && cpf.length > 0) return CPF_ERROR;
    if (DIGITOS_IGUAIS.test(cpf)) return CPF_ERROR;

    // Fazendo a validação do primeiro dígito verificador
    numeros = cpf.substring(0, 9);
    const digitos = cpf.substring(9);
    soma = 0;

    for (let i = 10; i > 1; i--) {
      soma += numeros.charAt(10 - i) * i;
    }
    resultado = (soma % 11 < 2 ? 0 : 11 - (soma % 11)).toString();
    if (resultado !== digitos.charAt(0)) return CPF_ERROR;

    // Fazendo a validação do segundo digito verificador
    numeros = cpf.substring(0, 10);
    soma = 0;

    for (let i = 11; i > 1; i--) {
      soma += numeros.charAt(11 - i) * i;
    }
    resultado = (soma % 11 < 2 ? 0 : 11 - (soma % 11)).toString();
    if (resultado !== digitos.charAt(1)) return CPF_ERROR;

    // Se não retornou erro até o momento, o CPF é válido
    return null;
  }

  static cnpj(control: AbstractControl): ValidationErrors | null {
    const DIGITOS_IGUAIS = /^(.)\1+$/;
    const CNPJ_ERROR = { invalidCnpj: true };
    const cnpj = control.value.replace(/[\D]+/g, '');
    let tamanho, numeros, soma, pos;

    if (cnpj.length === 0) return null;

    if (!(cnpj.length === 14) && cnpj.length > 0) return CNPJ_ERROR;
    if (DIGITOS_IGUAIS.test(cnpj)) return CNPJ_ERROR;

    // Fazendo validação do primeiro dígito de verificação
    tamanho = cnpj.length - 2;
    numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = (soma % 11 < 2 ? 0 : 11 - (soma % 11)).toString();
    if (resultado !== digitos.charAt(0)) return CNPJ_ERROR;

    // Fazendo validação do segundo dígito de verificação
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = (soma % 11 < 2 ? 0 : 11 - (soma % 11)).toString();
    if (resultado !== digitos.charAt(1)) return CNPJ_ERROR;

    return null;
  }
}
