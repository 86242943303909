import { ActionReducer } from '@ngrx/store';
import { ClienteActions } from '@vip/state/cliente';
import { initialState as cashbackState } from '@vip/state/cashback';

export function limparStatesOnLogoutMetaReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function (state, action) {
    if (action.type === ClienteActions.logoutSuccess.type && state) {
      state = {
        aplicativo: state['aplicativo'],
        parametros: state['parametros'],
        institucional: state['institucional'],
        filial: state['filial'],
        'centro-distribuicao': state['centro-distribuicao'],
        buckets: state['buckets'],
        'classificacao-mercadologica': state['classificacao-mercadologica'],
        cashback: {
          ...cashbackState,
          valorMinimo: state.cashback.valorMinimo,
          alterarSenhaAtivo: state.cashback.alterarSenhaAtivo,
        },
        fidelidade: state['fidelidade'],
      };
    }

    return reducer(state, action);
  };
}
