<div
  class="vip-radio-list-item"
  [class.checked]="checked"
  [class.invisible]="invisible"
  [class.max-h-0]="invisible"
  (click)="onClick(this)"
  vipRipple
  [rippleColor]="rippleColor"
  rippleBorderRadius="12px"
>
  <label class="text-xs">
    {{ label }}
  </label>
</div>
