import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { DEFAULT_COOKIE_EXPIRATION_TIME, IStorage } from '@vip/core';
import { Injectable } from '@angular/core';

@Injectable()
export class StorageSiteService extends SsrCookieService implements IStorage {
  private defaultExpirationTime = DEFAULT_COOKIE_EXPIRATION_TIME;

  clear(
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: 'Lax' | 'None' | 'Strict'
  ): void {
    this.deleteAll(path, domain, secure, sameSite);
  }
  getItem(key: string): string | null {
    return this.get(key);
  }
  removeItem(
    name: string,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: 'Lax' | 'None' | 'Strict'
  ): void {
    this.delete(name, path, domain, secure, sameSite);
  }
  setItem(
    name: string,
    value: string,
    expires: number | Date = this.defaultExpirationTime,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: 'Lax' | 'None' | 'Strict'
  ): void {
    this.set(name, value, expires, path, domain, secure, sameSite);
  }
}
