import { Pipe, PipeTransform } from '@angular/core';
import { LayoutUtilsService } from '../../services/layout-utils/layout-utils.service';

@Pipe({ name: 'desktopText' })
export class DesktopTextPipe implements PipeTransform {
  constructor(private layoutUtilsService: LayoutUtilsService) {}

  transform(text: string, desktopText: string) {
    return this.layoutUtilsService.isMobile() ? text : desktopText;
  }
}
