import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, combineLatest, of } from 'rxjs';
import { ClienteFacade } from '../+state/cliente.facade';
import { ParametrosFacade } from '@vip/state/parametros';
import { ClienteApiService } from '@vip/api';
import { filter, map, switchMap } from 'rxjs/operators';
import { ICliente } from '@vip/core';

@Injectable()
export class CadastroIncompletoGuard
  implements CanActivate, CanDeactivate<any>
{
  constructor(
    private router: Router,
    private clienteFacade: ClienteFacade,
    private paramentrosFacade: ParametrosFacade,
    private clienteApiService: ClienteApiService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.verificaCadastroIncompleto(state.url);
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> {
    if (this.rotasLivres(nextState.url)) {
      return of(true);
    }
    return this.verificaCadastroIncompleto(nextState.url);
  }

  private rotasLivres(url: string) {
    return [
      '/clientes/redefinirSenha',
      '/cadastrar',
      '/home',
      '/menu?bottomNavigation=true',
    ].includes(url);
  }

  verificaCadastroIncompleto(url: string) {
    return combineLatest([
      this.clienteFacade.cliente$.pipe(
        filter((cliente): cliente is ICliente => cliente !== null)
      ),
      this.clienteFacade.isLogged$.pipe(filter((isLogged) => isLogged)),
      this.paramentrosFacade.pinAtivo$,
    ]).pipe(
      switchMap(([cliente, isLogged, pinAtivo]) => {
        if (pinAtivo && isLogged) {
          return this.clienteApiService
            .cadastroIncompleto()
            .pipe(
              map((cadastroIncompleto) => ({ cliente, cadastroIncompleto }))
            );
        } else {
          return of({ cliente: null, cadastroIncompleto: null });
        }
      }),
      map(({ cliente, cadastroIncompleto }) => {
        if (cadastroIncompleto !== null) {
          if (!cliente?.telefone_celular) {
            this.router.navigateByUrl(
              `/validar-telefone/completar-cadastro?redirectTo=${url}`
            );
            return false;
          }
        }
        return true;
      })
    );
  }
}
