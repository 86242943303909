import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IProdutoCompra } from '@vip/core';

@Component({
  selector: 'vip-card-resumo-produtos-item',
  templateUrl: './card-resumo-produtos-item.component.html',
  styleUrls: ['./card-resumo-produtos-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardResumoProdutosItemComponent {
  @Input()
  produtoCompra!: IProdutoCompra;
}
