import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { ReceitasService } from '@vip/api';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { CarrinhoFacade } from '@vip/state/carrinho';
import { CarrinhoItensFacade } from '@vip/state/carrinho-itens';

import * as IngredientesReceitasActions from './ingredientes-receita.actions';
import { select, Store } from '@ngrx/store';
import { SelecaoCdSelectors } from '@vip/state/utils';
import { MessageService } from '@vip/ui/message';
import { AnalyticsFacade } from '@vip/state/analytics';
import { ProdutoFacade } from '@vip/state/produto';
import { FacebookPixelService } from '@vip/core';

@Injectable()
export class IngredientesReceitaEffects {
  addIngredientesCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IngredientesReceitasActions.addIngredientesCarrinho),
      withLatestFrom(
        this.cdFacade.filialECdSelecionado$,
        this.store.pipe(
          select(SelecaoCdSelectors.isPermitirAtualizarItemCarrinho)
        )
      ),
      map(([action, [filial, cd], permitirAtualizarItemCarrinho]) => ({
        ...action,
        filial,
        cd,
        permitirAtualizarItemCarrinho,
      })),
      filter(
        ({ permitirAtualizarItemCarrinho }) => !!permitirAtualizarItemCarrinho
      ),
      fetch({
        run: (action) => {
          if (action.cd) {
            return this.receitaService
              .adicionarIngredientesNoCarrinho(
                action.filial.id,
                action.cd.id,
                action.listaId
              )
              .pipe(
                map((res) => {
                  return IngredientesReceitasActions.addIngredientesCarrinhoSuccess(
                    {
                      itensAtualizado: res.data.itemAtualizado,
                      itensNaoAtualizados: res.data.itemNaoAtualizados,
                    }
                  );
                })
              );
          } else {
            this.router.navigateByUrl('/entrega-retirada');
            return;
          }
        },
        onError: (action, error) =>
          IngredientesReceitasActions.addIngredientesCarrinhoFailure({
            error,
          }),
      })
    )
  );

  addIngredientesCarrinhoFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IngredientesReceitasActions.addIngredientesCarrinhoFailure),
        tap((action) =>
          this.messageService.openErrorMessage(action.error.error.error, 1.5)
        )
      ),
    { dispatch: false }
  );

  recarregaCarrinho$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IngredientesReceitasActions.addIngredientesCarrinhoSuccess),
        tap((state) => {
          this.carrinhoFacade.getCarrinho();
          this.carrinhoItensFacade.setItens(state.itensAtualizado);
          this.carrinhoItensFacade.getItensCarrinho();
          this.facebookPixelService.addToCart('Lista ingredientes');
        })
      ),
    { dispatch: false }
  );

  enviaDadosGa4$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IngredientesReceitasActions.addIngredientesCarrinhoSuccess),
        tap((state) => {
          state.itensAtualizado.forEach((item) => {
            this.analyticFacade.addToCarrinho(item);
          });
        })
      ),
    { dispatch: false }
  );
  enviaImpressaoColecao$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IngredientesReceitasActions.enviaImpressao),
        tap((response: { res: { data: { produto: any }[] } }) => {
          const produtos: any[] = [];
          response?.res?.data.forEach((item: { produto: any }) => {
            produtos.push(item.produto);
          });
          this.produtoFacade.enviaImpressao(produtos);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private carrinhoFacade: CarrinhoFacade,
    private analyticFacade: AnalyticsFacade,
    private carrinhoItensFacade: CarrinhoItensFacade,
    private facebookPixelService: FacebookPixelService,
    private cdFacade: CentroDistribuicaoFacade,
    private produtoFacade: ProdutoFacade,
    private receitaService: ReceitasService,
    private router: Router,
    private store: Store,
    private messageService: MessageService
  ) {}
}
