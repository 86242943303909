import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ParticiparListaRequest } from '@vip/api';
import { ICliente, IFilial, IList, LayoutUtilsService } from '@vip/core';
import { FilialFacade } from '@vip/state/filial';
import { ListaFacade } from '@vip/state/lista';
import { map, withLatestFrom } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { IMessage, MessageService } from '@vip/ui/message';
import { CodigoCompartilhamentoService } from '@vip/state/cliente';

@UntilDestroy()
@Component({
  selector: 'vip-convite-lista-container',
  templateUrl: './convite-lista-container.component.html',
  styleUrls: ['./convite-lista-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ConviteListaContainerComponent implements OnInit {
  lista!: IList;
  filial!: IFilial;
  cliente!: ICliente | null;
  @Output() acessarListaDesktop = new EventEmitter<number>();
  @Output() participarListaDesktop = new EventEmitter<number>();
  estaParticipando = false;
  @Input() params?: Params;
  isDesktop = this.layoutUtilsService.isDesktop();
  isDesktopResponsive = this.layoutUtilsService.isDesktopWithoutScreenWidth();
  isDesktop$ = this.layoutUtilsService.isDesktop$;
  constructor(
    private listaFacade: ListaFacade,
    private activatedRoute: ActivatedRoute,
    private filialFacade: FilialFacade,
    private changeDetector: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
    private messageService: MessageService,
    private codigoCompartilhamentoService: CodigoCompartilhamentoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.filialFacade.filial$
      .pipe(
        untilDestroyed(this),
        withLatestFrom(this.activatedRoute.params),
        map(([filial, params]) => {
          const paramsToUse = this.params || params;
          const slug =
            this.isDesktop || this.isDesktopResponsive
              ? paramsToUse.slug
              : paramsToUse.value.slug;
          if (slug && filial.id != 0) {
            this.filial = filial;
            this.listaFacade
              .getListaPerSlug(filial.id, slug)
              .pipe(untilDestroyed(this))
              .subscribe((lista) => {
                this.lista = lista;
                this.changeDetector.detectChanges();
              });
          }
        })
      )
      .subscribe();
  }

  participarLista() {
    const dataLista: ParticiparListaRequest = {
      slug: this.lista.slug,
    };

    this.listaFacade
      .participarLista(this.filial.id, dataLista)
      .toPromise()
      .then(({ success }) => {
        if (success) {
          this.estaParticipando = true;
          this.changeDetector.detectChanges();
        }
      })
      .catch((error) => {
        this.participarListaDesktop.emit();
        if (error.error.error !== 'Cliente já cadastrado.') {
          const messageData: IMessage = {
            icon: 'icon-close',
            position: 'top',
            type: 'error',
          };
          this.messageService.openMessage(messageData, error.error.error, 2);
        }
        this.codigoCompartilhamentoService.clearCodigo();
        this.router.navigateByUrl(
          `minha-conta/minhas-listas/detalhes/${this.lista.id}`
        );
      });
  }

  acessarLista(listaId: number) {
    if (!this.isDesktop && !this.isDesktopResponsive) {
      this.router.navigateByUrl(
        `minha-conta/minhas-listas/detalhes/${listaId}`
      );
    } else {
      this.acessarListaDesktop.emit(listaId);
    }
  }
}
