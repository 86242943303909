import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ISpinEvent } from '@vip/ui/spin';
import { CardProdutoDirective } from '../card-produto/card-produto.directive';

@Component({
  selector: 'vip-card-produto-substituir',
  templateUrl: './card-produto-substituir.component.html',
  styleUrls: ['./card-produto-substituir.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardProdutoSubstituirComponent extends CardProdutoDirective {
  @Input() showUnidade = false;
  @Input() showSpin = true;
  @Input() disableSpin = false;
  @Input() showIndisponivelBadge = false;
  @Input() quantidadeASubstituir!: number;
  @Input()
  spinQuantity = 0;
  messageMap: { [count: string]: string } = {
    '=0': '',
    '=1': '1 unidade',
    other: '# unidades',
  };
  @Input()
  isModalTemplate = false;

  get precoTotal(): number {
    return (
      this.produto.preco * (this.spinQuantity || this.quantidadeASubstituir)
    );
  }

  handleSpinAlterado(event: ISpinEvent) {
    if (this.spinQuantity === 0) {
      this.spinQuantity = this.quantidadeASubstituir;
      event.quantity = this.spinQuantity;
    } else {
      this.spinQuantity = event.quantity;
    }
    super.handleSpinAlterado(event);
  }
}
