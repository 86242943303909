import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstitucionalComponent } from './institucional/institucional.component';
import { ScreenTitleModule } from '@vip/ui/screen-title';
import { ListaPaginasComponent } from './lista-paginas/lista-paginas.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@vip/ui/button';
import { FieldModule } from '@vip/ui/field';
import { RippleModule } from '@vip/ui/ripple';
import { NgxMaskModule } from 'ngx-mask';
import { SimularFreteComponent } from './simular-frete/simular-frete.component';
import { SelectableCardModule } from '@vip/ui/selectable-card';
import { CheckboxModule } from '@vip/ui/checkbox';
import { CarouselModule } from '@vip/ui/carousel';
import { CoreModule } from '@vip/core';
import { RadioModule } from '@vip/ui/radio';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    RippleModule,
    ScreenTitleModule,
    ButtonModule,
    RadioModule,
    FieldModule,
    CheckboxModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    SelectableCardModule,
    CarouselModule,
  ],
  declarations: [
    InstitucionalComponent,
    ListaPaginasComponent,
    SimularFreteComponent,
  ],
  exports: [
    InstitucionalComponent,
    ListaPaginasComponent,
    SimularFreteComponent,
  ],
})
export class InstitucionalModule {}
