import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  ICarousel,
  IClassificacaoMercadologica,
  VipThemeComponents,
} from '@vip/core';
import { HomeBaseDirective } from '../directive/home-base.directive';

@Component({
  selector: 'vip-home-ecommerce',
  templateUrl: './home-ecommerce.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeEcommerceComponent
  extends HomeBaseDirective
  implements OnChanges
{
  @Input() retiradaLoja!: boolean;
  @Input() isLojaAutonoma!: boolean;
  @Input() endereco!: string;
  @Input() logo!: string;
  @Input() defaultImageUrl!: string;
  @Input() firstCarousel?: ICarousel | null;
  @Input() secondCarousel?: ICarousel | null;
  @Input() categoriaItens: IClassificacaoMercadologica[] = [];
  @Input() fallbackImageUrl?: string;
  @Input() adsValue: boolean | null = false;

  @Output()
  categoriaItemClick = new EventEmitter<IClassificacaoMercadologica>();
  @Output() searchClick = new EventEmitter();
  @Output() alterarEnderecoClick = new EventEmitter();

  valueAds: boolean | null = false;

  headerTheme: VipThemeComponents['header'] = {
    type: 'type_2',
    color: {
      main: '',
      contrast: '',
    },
  };

  showMoreOfertaExclusiva() {
    this.showMoreClick.emit(`oferta/${this.ofertasExclusivas[0].oferta?.tag}`);
  }

  ngOnChanges(): void {
    this.valueAds = this.adsValue;
  }
}
