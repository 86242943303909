import { createAction, props } from '@ngrx/store';
import { IPaginaInstitucional } from '@vip/core';

export const getPaginasInstitucionais = createAction(
  '[Instituição/Page] get Paginas InstitucionaisSuccess'
);

export const getPaginasInstitucionaisSuccess = createAction(
  '[Instituição/Page] get Paginas Institucionais Success',
  props<{ paginas: IPaginaInstitucional[] }>()
);

export const getPaginasInstitucionaisFailure = createAction(
  '[Instituição/Page] get Paginas Institucionais Failure',
  props<{ error: string }>()
);
