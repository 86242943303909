<section
  class="flex-grow h-full flex flex-col"
  [ngClass]="{ 'w-full': loading, 'with-padding': !hasSideMenu && isDesktop }"
>
  <ng-container
    *ngIf="
      products.length === 0 && selectedFilters.length === 0 && !loading;
      then notFoundTemplate;
      else productsTemplate
    "
  ></ng-container>

  <ng-template #productsTemplate>
    <vip-breadcrumb
      *ngIf="isDesktop && breadcrumbs.length"
      class="ml-3 mb-4"
      [breadcrumbs]="breadcrumbs"
      (breadCrumbClick)="breadCrumbClick.emit($event)"
    ></vip-breadcrumb>

    <article class="flex" [ngClass]="{ 'flex-col': !isDesktop }">
      <ng-container *ngIf="isDesktop && !hasSideMenu && filters.length">
        <div>
          <h1
            class="vip-color-first-default text-base font-bold ml-3 mb-1"
            *ngIf="breadcrumbs.length"
          >
            {{ breadcrumbs[breadcrumbs.length - 1].label }}
          </h1>
          <vip-filtro-desktop
            [filters]="filters"
            [qtdMaxVisibleItens]="qtdMaxVisibleItens"
            (filtersChange)="handleFiltersChange($event)"
            [filterType]="filterType"
          ></vip-filtro-desktop>
        </div>
        <hr class="vip-divider-vertical h-auto" />
      </ng-container>
      <div
        class="flex flex-col flex-grow"
        [ngClass]="{ 'items-center': !isDesktop, 'w-full': loading }"
      >
        <div *ngIf="slides.length > 0" class="px-3 mb-6">
          <vip-carousel
            [slides]="slides"
            [bannersPerPage]="bannersPerPage"
            [isMobile]="!isDesktop"
            [removeBlockMargins]="true"
            type="md"
          ></vip-carousel>
        </div>
        <div
          *ngIf="isDesktop && !hasSideMenu && !loading"
          class="vip-filter-wrapper vip-color-secondary-default px-3 mb-3 flex items-center"
          [ngClass]="{
            'justify-end': selectedFilters.length === 0,
            'justify-between': selectedFilters.length > 0
          }"
        >
          <div
            *ngIf="selectedFilters.length > 0"
            class="vip-selected-filters-wrapper flex"
          >
            <vip-tab-item
              *ngFor="let filter of selectedFilters; let i = index"
              icon="icon-close"
              class="mr-3"
              color="primary"
              iconColor="secondary"
              [attr.data-cy]="'vip_filter_selected_' + filter.value"
              [label]="filter.label || ''"
              [rounded]="true"
              [selected]="true"
              (tabClick)="handleSelectedFilterClick(filter, i)"
            ></vip-tab-item>
            <vip-tab-item
              icon="icon-close"
              [label]="'Limpar Filtros'"
              data-cy="vip_filter_selected_limpar_todos"
              [rounded]="true"
              (tabClick)="handleSelectedFilterClick()"
            ></vip-tab-item>
          </div>

          <div class="flex items-center flex-shrink-0 ml-1">
            <span
              *ngIf="paginator && paginator.total_items"
              class="text-sm mr-1"
              >{{
                paginator.total_items | i18nPlural: produtosMessageMap
              }}</span
            >
            <div
              id="dropdown-ordenar"
              *ngIf="orderingOptions.length"
              #vipDropdownOrdenar
              vipDropdown
              [dontTranslate]="true"
              (openChange)="dropdownOrdenarPorOpen = $event"
              class="vip-dropdown-container"
            >
              <vip-button
                class="vip-btn-ordering"
                type="normal"
                vipDropdownButton
                [ariaExpanded]="dropdownOrdenarPorOpen"
                [ariaHaspopup]="true"
                [customSize]="true"
                ><span
                  data-cy="vip_ordering_label"
                  class="vip-color-secondary-default text-sm"
                  >Ordenar por {{ selectedOrderingOption?.label }}</span
                ><i
                  class="vip-icon-ordering icon-expand_more vip-color-secondary-default ml-1"
                  [class.expanded]="dropdownOrdenarPorOpen"
                ></i
              ></vip-button>
              <ul
                class="vip-dropdown-content vip-ordering"
                vipDropdownContent
                [borderInfinite]="true"
              >
                <li
                  *ngFor="let option of orderingOptions; let i = index"
                  class="vip-ordering-option vip-color-secondary-default"
                  [attr.data-cy]="'vip_ordering_option_' + i"
                  (click)="handleOrderingOptionClick(option)"
                >
                  <span class="flex-1">{{ option.label }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <vip-header-filter
          *ngIf="(!isDesktop || hasSideMenu) && filters?.length"
          class="mb-4"
          [ngClass]="{ 'pl-3': !isDesktop }"
          [class.grid]="hasSideMenu"
          [filters]="filters"
          (filterClick)="filterClick.emit($event)"
          (externalFilterClick)="externalFilterClick.emit($event)"
          [isDesktop]="isDesktop"
        ></vip-header-filter>
        <ng-container *ngIf="loading; else loadedTemplate">
          <div
            class="vip-loading-wrapper flex flex-grow items-center justify-center"
          >
            <vip-loading-spinner></vip-loading-spinner>
          </div>
        </ng-container>
        <ng-template #loadedTemplate>
          <ng-container *ngIf="products.length > 0; else notFoundTemplate">
            <div
              class="vip-products-wrapper w-full px-3 pb-3"
              [class.px-1]="hasSideMenu"
              [class.with-side-menu]="hasSideMenu"
            >
              <vip-card-produto
                *ngFor="let product of products; trackBy: trackByIdEQuantidade"
                [attr.data-cy]="'vip_produto_card_' + product.id"
                [produto]="product"
                [produtosNoCarrinho]="produtosNoCarrinho"
                [showUnavailableMessage]="
                  aviseMeEnviados?.get(product.produto_id) || false
                "
                (visible)="visible.emit(product)"
                (getClickAds)="getClique($event)"
                (addListClick)="addListClick.emit(product)"
                (produtoChanged)="produtoChanged.emit($event)"
                (aviseMeClick)="aviseMeClick.emit($event)"
                [adsValueParams]="valueAds"
              ></vip-card-produto>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </article>
    <div
      *ngIf="!loading && paginator && paginator.total_pages > 1"
      class="vip-end-page-wrapper pt-7"
      [ngClass]="{ 'pb-8': !isLogged }"
    >
      <vip-pagination
        [paginator]="paginator"
        (pageClick)="pageClick.emit($event)"
      ></vip-pagination>
    </div>
    <div
      *ngIf="isLogged && !loading && products.length > 0"
      class="btn-not-found-wrapper mt-8 flex justify-center"
    >
      <vip-button class="mb-2" (btnClick)="abrirModalProcurarProduto = true">
        <span class="mx-6">Não encontrou o que procurava?</span>
      </vip-button>
    </div>
  </ng-template>

  <ng-template #notFoundTemplate>
    <article class="vip-not-found-wrapper">
      <vip-empty [image]="emptyImageUrl" [text]="emptyText"></vip-empty>
      <div *ngIf="isLogged" class="btn-not-found-wrapper flex justify-center">
        <vip-button (btnClick)="abrirModalProcurarProduto = true">
          <span class="mx-6">Não encontrou o que procurava?</span>
        </vip-button>
      </div>
    </article>
  </ng-template>
</section>

<vip-not-found-modal
  [(abrirModalProcurarProduto)]="abrirModalProcurarProduto"
></vip-not-found-modal>
