import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromEndereco from './+state/endereco.reducer';
import { EnderecoEffects } from './+state/endereco.effects';
import { MessageService } from '@vip/ui/message';
import { CepStateModule } from '@vip/state/cep';
import { CentroDistribuicaoStateModule } from '@vip/state/centro-distribuicao';

@NgModule({
  imports: [
    CommonModule,
    CepStateModule,
    CentroDistribuicaoStateModule,
    StoreModule.forFeature(
      fromEndereco.ENDERECO_FEATURE_KEY,
      fromEndereco.reducer
    ),
    EffectsModule.forFeature([EnderecoEffects]),
  ],
  providers: [MessageService],
})
export class EnderecoStateModule {}
