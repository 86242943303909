import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AddEditEnderecoRequest } from '@vip/api';
import { IEndereco } from '@vip/core';
import { CepFacade } from '@vip/state/cep';
import { PreferenciasService } from '@vip/state/cliente';
import * as EnderecoActions from './endereco.actions';
import { enderecoSelectors } from './endereco.selectors';
import { EnderecoState } from './endereco.reducer';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({
  providedIn: 'root',
})
export class EnderecoFacade {
  loading$ = this.store.pipe(select(enderecoSelectors.isLoading()));
  loaded$ = this.store.pipe(select(enderecoSelectors.isLoaded()));
  error$ = this.store.pipe(select(enderecoSelectors.getError()));
  enderecos$ = this.store.pipe(select(enderecoSelectors.getAllEnderecos()));
  enderecosArray$ = this.store.pipe(
    select(enderecoSelectors.getAllEnderecosArray())
  );
  enderecosEntregaArray$ = this.store.pipe(
    select(enderecoSelectors.getAllEnderecosEntregaArray())
  );
  enderecoEntrega$ = this.store.pipe(
    select(enderecoSelectors.getEnderecoEntrega())
  );
  enderecoSelecionado$ = this.store.pipe(
    select(enderecoSelectors.getEnderecoSelecionado())
  );
  possuiEnderecoCadastrado$ = this.store.pipe(
    select(enderecoSelectors.possuiEnderecoCadastrado())
  );
  possuiEnderecoPrincipalCadastrado$ = this.store.pipe(
    select(enderecoSelectors.possuiEnderecoPrincipalCadastrado())
  );
  saveEnderecoSuccess$ = this.actions$.pipe(
    ofType(EnderecoActions.saveEnderecoSuccess)
  );

  constructor(
    private store: Store<EnderecoState>,
    private cepFacade: CepFacade,
    private preferenciasService: PreferenciasService,
    private actions$: Actions
  ) {}

  init() {
    const preferencias = this.preferenciasService.getPreferencias();
    if (preferencias && preferencias.enderecoEntrega) {
      this.setEnderecoCep(preferencias.enderecoEntrega);
      this.cepFacade.validarCepAtendidoPelaFilial(
        preferencias.enderecoEntrega.cep
      );
    }
  }

  getEnderecos() {
    this.store.dispatch(EnderecoActions.getEnderecos());
  }

  getEnderecosEntrega() {
    this.store.dispatch(EnderecoActions.getEnderecosEntrega());
  }

  selectEndereco(id: number | null) {
    this.store.dispatch(EnderecoActions.select({ enderecoSelecionadoId: id }));
  }

  selectEnderecoEntrega(id: number | null) {
    this.store.dispatch(
      EnderecoActions.selectEnderecoEntrega({
        enderecoEntregaSelecionadoId: id,
      })
    );
  }

  saveEndereco(endereco: AddEditEnderecoRequest, urlToRedirect?: string) {
    this.store.dispatch(
      EnderecoActions.saveEndereco({ endereco, urlToRedirect })
    );
  }

  deleteEndereco(enderecoId: number) {
    this.store.dispatch(EnderecoActions.deleteEndereco({ enderecoId }));
  }

  setEnderecoCep(endereco: IEndereco) {
    this.store.dispatch(EnderecoActions.setEnderecosCep({ endereco }));
  }
}
