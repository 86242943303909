import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as PerguntasActions from './perguntas.actions';
import { perguntasSelectors } from './perguntas.selectors';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class PerguntasFacade {
  error$ = this.store.pipe(select(perguntasSelectors.getError()));
  loading$ = this.store.pipe(select(perguntasSelectors.isLoading()));
  perguntas$ = this.store.pipe(select(perguntasSelectors.getPerguntasArray()));
  perguntasDoPagamento$ = this.store.pipe(
    select(perguntasSelectors.getPerguntasDoPagamentoArray())
  );
  perguntasDoResumo$ = this.store.pipe(
    select(perguntasSelectors.getPerguntasDoResumoArray())
  );
  temRespostas$ = this.store.pipe(select(perguntasSelectors.temRespostas()));
  temRespostasResumo$ = this.store.pipe(
    select(perguntasSelectors.temRespostasResumo())
  );
  temPerguntaObrigatoriaSemResposta$ = this.store.pipe(
    select(perguntasSelectors.temPerguntaObrigatoriaSemResposta())
  );

  responderPerguntasDispatched$ = this.actions$.pipe(
    ofType(PerguntasActions.responderPerguntas)
  );

  pulouPerguntas$ = this.store.pipe(
    select(perguntasSelectors.getPulouPerguntas())
  );
  fechouPerguntas$ = this.actions$.pipe(
    ofType(PerguntasActions.fechouPerguntas)
  );

  perguntasRespondidasSuccess$ = this.actions$.pipe(
    ofType(PerguntasActions.salvarRespostasResumoSuccess)
  );

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions
  ) {}

  getPerguntas() {
    this.store.dispatch(PerguntasActions.getPerguntas());
  }

  responderPerguntas(respostas: { [key: number]: string }) {
    this.store.dispatch(PerguntasActions.responderPerguntas({ respostas }));
  }

  pularPerguntas(pulouPerguntas = false) {
    this.store.dispatch(PerguntasActions.pulouPerguntas({ pulouPerguntas }));
  }

  fechouPerguntas() {
    this.store.dispatch(PerguntasActions.fechouPerguntas());
  }

  responderPerguntasResumo(
    respostas: { [key: number]: string },
    idCompra: number
  ) {
    this.store.dispatch(
      PerguntasActions.responderPerguntasResumo({ respostas, idCompra })
    );
  }

  limparRespostas(): void {
    this.store.dispatch(PerguntasActions.limparRespostas());
  }

  salvarRespostas(compraId: number) {
    this.store.dispatch(PerguntasActions.salvarRespostas({ compraId }));
  }
}
