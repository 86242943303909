import { AnalyticsFacade } from '@vip/state/analytics';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { delay, filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { CarrinhoService } from '@vip/api';
import {
  FacebookPixelService,
  ICentroDistribuicao,
  IComboItemCarrinho,
  IFilial,
  IProdutoCarrinho,
  SeletorDePesoEnum,
} from '@vip/core';
import { BucketsFacade } from '@vip/state/buckets';
import { CarrinhoActions, CarrinhoFacade } from '@vip/state/carrinho';
import {
  CentroDistribuicaoActions,
  CentroDistribuicaoFacade,
} from '@vip/state/centro-distribuicao';
import { ClienteFacade, ClienteTokenService } from '@vip/state/cliente';
import { mergeTakeOne } from '@vip/state/utils';
import { MessageService } from '@vip/ui/message';
import * as CarrinhoItensActions from './carrinho-itens.actions';
import { Router } from '@angular/router';
import { ParametrosFacade } from '@vip/state/parametros';
import { ManterCarrinhoService } from '@vip/state/carrinho';
import { Store, select } from '@ngrx/store';
import { carrinhoItensSelectors } from './carrinho-itens.selectors';
import { TipoEntregaFacade } from '@vip/state/tipo-entrega';
import { SelecaoCdSelectors } from '@vip/state/utils';
import { DialogService, IDialog } from '@vip/ui/modal';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class CarrinhoItensEffects {
  readonly ERROR_MESSAGE_PRODUTO_INDISPONIVEL = 'Produto indisponível';

  getItensCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CarrinhoItensActions.getItensCarrinho,
        CarrinhoActions.getAllCarrinho
      ),
      mergeTakeOne(
        this.cdFacade.filialECdSelecionado$,
        this.bucketsFacade.bucketProduto$,
        this.carrinhoFacade.carrinho$.pipe(
          filter((carrinho) => !!carrinho.quantidade)
        )
      ),
      map(([action, [filial, cdSelecionado], bucket]) => {
        return { ...action, filial, cdSelecionado, bucket };
      }),
      fetch({
        run: (action) => {
          return this.carrinhoService
            .getItensCarrinho(action.filial.id, action.cdSelecionado.id)
            .pipe(
              map((itens) =>
                CarrinhoItensActions.getItensCarrinhoSuccess({
                  itens: itens.data.map((item) =>
                    this.addBucket(item, action.bucket)
                  ),
                })
              )
            );
        },
        onError: (action, error) => {
          return CarrinhoItensActions.getItensCarrinhoFailure({ error });
        },
      })
    )
  );

  getCarrinhoSuccessCarrinhoVazio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrinhoActions.getCarrinhoSuccess),
      filter((action) => action.carrinho.quantidade === 0),

      map(() =>
        CarrinhoItensActions.getItensCarrinhoSuccess({
          itens: [],
        })
      )
    )
  );

  getCarrinhoFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrinhoActions.getCarrinhoFailure),
      map((action) =>
        CarrinhoItensActions.getItensCarrinhoFailure({ error: action.error })
      )
    )
  );

  setItemCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrinhoItensActions.setItemCarrinho),
      withLatestFrom(
        this.cdFacade.filialECdSelecionado$,
        this.bucketsFacade.bucketProduto$,
        this.parametrosFacade.permitirCarrinhoAnonimo$,
        this.clienteFacade.isLogged$,
        this.store.pipe(
          select(SelecaoCdSelectors.isPermitirAtualizarItemCarrinho)
        )
      ),
      map(
        ([
          action,
          [filial, cd],
          bucket,
          permitirCarrinhoAnonimo,
          clienteLogado,
          permitirAtualizarItemCarrinho,
        ]) => ({
          ...action,
          filial: filial,
          cd,
          bucket,
          permitirCarrinhoAnonimo,
          clienteLogado,
          permitirAtualizarItemCarrinho,
        })
      ),
      filter(
        ({ permitirAtualizarItemCarrinho }) => !!permitirAtualizarItemCarrinho
      ),
      filter(({ permitirCarrinhoAnonimo, clienteLogado: isLogged }) => {
        const clienteLogado = isLogged || this.hasToken;
        return (!clienteLogado && permitirCarrinhoAnonimo) || clienteLogado;
      }),
      fetch({
        run: (action) =>
          this.carrinhoService
            .setItemCarrinho(action.filial.id, action.cd.id, action.payload)
            .pipe(
              map((itemCarrinho) => {
                if (action.exibirMensagemAdicionado) {
                  this.messageService.openSuccessMessage(
                    'Item adicionado ao carrinho!',
                    1.5
                  );
                } else {
                  this.analyticsFacade.removerDoCarrinho(itemCarrinho);
                }
                return CarrinhoItensActions.setItemCarrinhoSuccess({
                  itens: itemCarrinho.map((item) =>
                    this.addBucket(item, action.bucket)
                  ),
                });
              })
            ),
        onError: (action, error) =>
          CarrinhoItensActions.setItemCarrinhoFailure({
            error,
            payload: action.payload,
          }),
      })
    )
  );

  usuarioNaoAutorizado$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrinhoItensActions.setItemCarrinho),
      withLatestFrom(
        this.cdFacade.filialECdSelecionado$,
        this.bucketsFacade.bucketProduto$,
        this.parametrosFacade.permitirCarrinhoAnonimo$,
        this.clienteFacade.isLogged$,
        this.store.pipe(
          select(SelecaoCdSelectors.isPermitirAtualizarItemCarrinho)
        )
      ),
      map(
        ([
          action,
          [filial, cd],
          bucket,
          permitirCarrinhoAnonimo,
          clienteLogado,
          permitirAtualizarItemCarrinho,
        ]) => ({
          ...action,
          filial: filial,
          cd,
          bucket,
          permitirCarrinhoAnonimo,
          clienteLogado,
          permitirAtualizarItemCarrinho,
        })
      ),
      filter(
        ({ permitirAtualizarItemCarrinho }) => !!permitirAtualizarItemCarrinho
      ),
      filter(
        ({ permitirCarrinhoAnonimo, clienteLogado }) =>
          !(clienteLogado || this.hasToken) && !permitirCarrinhoAnonimo
      ),
      fetch({
        run: (action) => {
          return CarrinhoItensActions.usuarioNaoAutorizado();
        },
      })
    )
  );

  setItemCarrinhoFailureLimiteItens$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoItensActions.setItemCarrinhoFailure),
        tap((action) => {
          if (action.error.error?.error?.message) {
            this.messageService.openErrorMessage(
              action.error.error.error.message,
              1.5
            );
          }
        })
      ),
    { dispatch: false }
  );

  setListaItensCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrinhoItensActions.setListaItensCarrinho),
      withLatestFrom(
        this.cdFacade.filialECdSelecionado$,
        this.bucketsFacade.bucketProduto$,
        this.store.pipe(
          select(SelecaoCdSelectors.isPermitirAtualizarItemCarrinho)
        )
      ),
      map(([action, [filial, cd], bucket, permitirAtualizarItemCarrinho]) => ({
        ...action,
        filial,
        cd,
        bucket,
        permitirAtualizarItemCarrinho,
      })),
      filter(
        ({ permitirAtualizarItemCarrinho }) => !!permitirAtualizarItemCarrinho
      ),
      fetch({
        run: (action) =>
          this.carrinhoService
            .setListaItensCarrinho(
              action.filial.id,
              action.cd.id,
              action.payload
            )
            .pipe(
              map(({ carrinho, itemAtualizado }) => {
                this.messageService.openSuccessMessage(
                  'Itens adicionados ao carrinho!',
                  1.5
                );
                return CarrinhoItensActions.setListaItensCarrinhoSuccess({
                  carrinho,
                  itens: itemAtualizado.map((item) =>
                    this.addBucket(item, action.bucket)
                  ),
                  buscaCarrinhoAposConclusao: action.buscaCarrinhoAposConclusao,
                });
              })
            ),
        onError: (action, error) =>
          CarrinhoItensActions.getCarrinhoFailure({ error }),
      })
    )
  );

  removeItemCarrinho$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrinhoItensActions.removeItemCarrinho),
      withLatestFrom(this.cdFacade.filialECdSelecionado$),
      fetch({
        run: (
          action,
          [filial, cdSelecionado]: [IFilial, ICentroDistribuicao]
        ) =>
          this.carrinhoService
            .removeItemCarrinho(filial.id, cdSelecionado.id, action.itemId)
            .pipe(
              map((response) =>
                CarrinhoItensActions.removeItemCarrinhoSuccess({
                  itemId: action.itemId,
                  itens: response.data.itens_atualizados,
                })
              )
            ),
        onError: (action, error) => {
          return CarrinhoItensActions.removeItemCarrinhoFailure({ error });
        },
      })
    )
  );

  removeItemGa4$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoItensActions.removeItemCarrinhoSuccess),
        withLatestFrom(this.carrinhoFacade.carrinho$),
        tap(([action, carrinho]) => {
          if (carrinho?.itens) {
            const itens = [];
            const produtoCarrinho = carrinho?.itens?.find(
              (itemCarrinho) => itemCarrinho.item_id === action.itemId
            );
            if (produtoCarrinho) itens.push(produtoCarrinho);
            this.analyticsFacade.removerDoCarrinho(itens);
          }
        })
      ),
    { dispatch: false }
  );

  recarregaCarrinho$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CarrinhoItensActions.setItemCarrinhoSuccess,
          CarrinhoItensActions.removeItemCarrinhoSuccess
        ),
        tap(() => this.carrinhoFacade.getCarrinho())
      ),
    { dispatch: false }
  );

  enviaDadosParaGa4$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoItensActions.setItemCarrinhoSuccess),
        tap((action) => {
          const itens = [];
          if (action.itens[0].combo) {
            itens.push(...action.itens[0].combo.produtos);
          } else {
            itens.push(action.itens[0]);
          }

          const itensConvertidos: (IProdutoCarrinho | IComboItemCarrinho)[] =
            itens.map((item) => {
              return 'combo' in item ? { ...item } : item;
            });
          itensConvertidos.forEach((item) => {
            this.analyticsFacade.addToCarrinho(item as IProdutoCarrinho);
            this.facebookPixelService.addToCart('Produto');
          });
        })
      ),
    { dispatch: false }
  );

  limpaTipoEntrega$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CarrinhoItensActions.setItemCarrinhoSuccess,
          CarrinhoItensActions.removeItemCarrinhoSuccess,
          CarrinhoItensActions.setListaItensCarrinhoSuccess
        ),
        tap(() => this.tipoEntregaFacade.resetTiposEntregaSelecionada())
      ),
    { dispatch: false }
  );

  atualizarItensAlteradosAoExcluirItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrinhoItensActions.removeItemCarrinhoSuccess),
      filter((action) => !!action.itens.length),
      map((action) =>
        CarrinhoItensActions.removeItemCarrinhoComItenAtualizados({
          itens: action.itens,
        })
      )
    )
  );

  setCarrinho$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoItensActions.setListaItensCarrinhoSuccess),
        tap(({ carrinho }) => this.carrinhoFacade.setCarrinho(carrinho))
      ),
    { dispatch: false }
  );

  resetarItensCarrinhoAposMigrarCd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CentroDistribuicaoActions.migrarCentroDistribuicaoSuccess),
      map(() => CarrinhoItensActions.resetItensCarrinho())
    )
  );

  setItemCarrinhoFailureCarrinhoEmOutroCD$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoItensActions.setItemCarrinhoFailure),
        filter((action) => {
          return (
            action.error.error?.error?.carrinho_id &&
            !action.error.error?.error?.loja_autonoma
          );
        }),
        tap((action) => {
          this.manterCarrinhoService.openDialogManterCarrinhoDeOutroCD();
          this.store.dispatch(
            CarrinhoActions.setCarrinhoOutroCD({
              carrinho: action.error.error.error,
            })
          );
        })
      ),
    { dispatch: false }
  );

  setItemCarrinhoFailureCarrinhoEmLojaAutonoma$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoItensActions.setItemCarrinhoFailure),
        filter(
          (action) =>
            action.error.error?.error?.carrinho_id &&
            action.error.error?.error?.loja_autonoma
        ),
        tap((action) => {
          this.carrinhoFacade.removerCarrinho(
            action.error.error?.error?.carrinho_id
          );
          this.store.dispatch(
            CarrinhoActions.setCarrinhoOutroCD({
              carrinho: action.error.error.error,
            })
          );
        })
      ),
    { dispatch: false }
  );

  removeCarrinhoSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoActions.removeCarrinhoSuccess),
        mergeTakeOne(
          this.store.pipe(
            select(carrinhoItensSelectors.getItemPendenteCarrinho())
          )
        ),
        map(([action, itemPendenteCarrinho]) => {
          if (itemPendenteCarrinho) {
            this.store.dispatch(
              CarrinhoItensActions.setItemCarrinho({
                payload: itemPendenteCarrinho,
                exibirMensagemAdicionado: true,
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  primeiroProdutoIndisponivelDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrinhoItensActions.setItemCarrinhoFailure),
        mergeTakeOne(this.carrinhoFacade.qtdItensCarrinho$),
        filter(
          ([{ error }, qtdItens]) =>
            qtdItens === 0 &&
            error.error.error.message ===
              this.ERROR_MESSAGE_PRODUTO_INDISPONIVEL
        ),
        tap(() => {
          const dialogData: IDialog = {
            open: true,
            disabled: false,
            title: 'Produto Indisponível',
            subTitle:
              'Infelizmente este produto não está disponível na Loja selecionada',
          };
          this.dialogService.openDialog({ ...dialogData });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private carrinhoService: CarrinhoService,
    private cdFacade: CentroDistribuicaoFacade,
    private carrinhoFacade: CarrinhoFacade,
    private analyticsFacade: AnalyticsFacade,
    private messageService: MessageService,
    private facebookPixelService: FacebookPixelService,
    private bucketsFacade: BucketsFacade,
    private router: Router,
    private parametrosFacade: ParametrosFacade,
    private clienteFacade: ClienteFacade,
    private store: Store,
    private manterCarrinhoService: ManterCarrinhoService,
    private tipoEntregaFacade: TipoEntregaFacade,
    private dialogService: DialogService,
    private clienteTokenService: ClienteTokenService
  ) {}

  private addBucket(produtoCarrinho: IProdutoCarrinho, bucket?: string) {
    if (produtoCarrinho.combo) {
      return {
        ...produtoCarrinho,
        combo: {
          ...produtoCarrinho.combo,
          produtos: produtoCarrinho.combo.produtos.map((produto) => {
            return {
              ...produto,
              imagem: produto.imagem
                ? `${bucket}/${produto.imagem}`
                : produto.imagem,
            };
          }),
        },
      };
    }
    return {
      ...produtoCarrinho,
      imagem: produtoCarrinho.imagem
        ? `${bucket}/${produtoCarrinho.imagem}`
        : produtoCarrinho.imagem,
    };
  }

  private get hasToken(): boolean {
    return !!this.clienteTokenService.getToken();
  }
}
