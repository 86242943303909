import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { ClassificacaoMercadologicaApiService } from '@vip/api';
import { IClassificacaoMercadologica } from '@vip/core';
import { map } from 'rxjs/operators';
import * as ClassificacaoMercadologicaActions from './classificacao-mercadologica.actions';
import { BucketsFacade } from '@vip/state/buckets';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { combineLatest } from 'rxjs';

@Injectable()
export class ClassificacaoMercadologicaEffects {
  loadArvore$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(ClassificacaoMercadologicaActions.loadArvore)),
      this.cdFacade.filialECdSelecionado$,
      this.bucketsFacade.bucketCategoria$,
    ]).pipe(
      map(([action, [filial, cd], bucket]) => {
        return { ...action, filial, cd, bucket };
      }),
      fetch({
        run: (action) => {
          return this.classificacaoMercadologicaApiService
            .loadArvore(action.filial.id, action.cd.id)
            .pipe(
              map((result) => {
                return ClassificacaoMercadologicaActions.loadArvoreSuccess({
                  arvore: result.data.map((classificacaoMercadologica) =>
                    this.addBucket(classificacaoMercadologica, action.bucket)
                  ),
                });
              })
            );
        },
        onError: (_, error) => {
          return ClassificacaoMercadologicaActions.loadArvoreFailure({ error });
        },
      })
    );
  });

  private addBucket(
    classificacaoMercadologica: IClassificacaoMercadologica,
    bucket?: string
  ) {
    return {
      ...classificacaoMercadologica,
      imagem: classificacaoMercadologica.imagem
        ? `${bucket}/${classificacaoMercadologica.imagem}`
        : classificacaoMercadologica.imagem,
    };
  }

  constructor(
    private actions$: Actions,
    private classificacaoMercadologicaApiService: ClassificacaoMercadologicaApiService,
    private cdFacade: CentroDistribuicaoFacade,
    private bucketsFacade: BucketsFacade
  ) {}
}
