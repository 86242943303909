<section *ngIf="formGroup" class="flex flex-col">
  <div class="vip-title flex mb-4" *ngIf="exibeNaCompra">
    <i class="icon-format_list_bulleted vip-color-primary-main mr-3"></i>
    <h2 class="vip-color-first-default text-base font-bold">Sobre a Compra</h2>
  </div>
  <form [formGroup]="formGroup" class="vip-perguntas-wrapper">
    <ng-container *ngFor="let pergunta of perguntas; let last = last">
      <span class="vip-color-secondary-default text-sm mb-3 inline-block">{{
        pergunta.pergunta
      }}</span>

      <ng-container
        *ngIf="
          pergunta.tipo === PERGUNTA_TIPO.TEXTO;
          then textoTemplate;
          else listaTemplate
        "
      ></ng-container>
      <ng-template #textoTemplate>
        <vip-field
          [error]="
            formGroup.get(pergunta.id.toString())?.touched &&
            formGroup.get(pergunta.id.toString())?.invalid
          "
          message="Preenchimento obrigatório"
          [validations]="formGroup.get(pergunta.id.toString())?.errors"
        >
          <input
            formControlName="{{ pergunta.id.toString() }}"
            placeholder="Digite sua resposta aqui."
          />
        </vip-field>
      </ng-template>
      <ng-template #listaTemplate>
        <vip-radio-group direction="vertical">
          <vip-radio-button
            *ngFor="let opcao of pergunta.cliente_pergunta_opcoes"
            [value]="opcao.valor"
            [checked]="
              opcao.valor === pergunta.cliente_pergunta_resposta?.resposta
            "
            (changed)="
              formGroup.get(pergunta.id.toString())?.setValue(opcao.valor)
            "
            >{{ opcao.valor }}</vip-radio-button
          >
        </vip-radio-group>
        <div
          *ngIf="
            formGroup.get(pergunta.id.toString())?.touched &&
            formGroup.get(pergunta.id.toString())?.invalid
          "
          class="messages-container mt-2"
        >
          <span class="vip-color-error-main text-sm"
            >Preenchimento obrigatório</span
          >
        </div>
      </ng-template>

      <hr *ngIf="!last" class="vip-divider my-4" />
    </ng-container>
  </form>
  <div class="vip-modal-buttons max-h-[74px] mt-4" *ngIf="exibeNaCompra">
    <vip-button
      *ngIf="!temPerguntaObrigatoria"
      class="min-w-[107px]"
      data-cy="btn-pular"
      type="outline"
      (btnClick)="pularPerguntas.emit()"
    >
      <span>Pular</span>
    </vip-button>
    <vip-button (btnClick)="responderPerguntas()" data-cy="btn-responder">
      <span>Responder</span>
    </vip-button>
  </div>

  <div *ngIf="!exibeNaCompra" class="mt-4 flex flex-col gap-1">
    <span *ngIf="formGroup.invalid" class="vip-color-error-main text-xs"
      >Por favor forneça pelo menos uma resposta</span
    >
    <button
      *ngIf="showButton"
      class="min-w-[107px]"
      (click)="responderPerguntas()"
      data-cy="btn-enviar-perguntas"
    >
      <span>Enviar respostas</span>
    </button>
  </div>
</section>
