import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'vip-validar-cep',
  templateUrl: './validar-cep.component.html',
  styleUrls: ['./validar-cep.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidarCepComponent {
  @Input()
  type:
    | 'solicitarCep'
    | 'cepNaoAtendido'
    | 'apenasRetirada'
    | 'solicitacaoCadastrada'
    | '' = 'solicitarCep';

  @Input()
  cepInvalido = false;

  @Input()
  loading = false;

  @Output()
  confirmSolicitarCep = new EventEmitter();

  @Output()
  confirmCepNaoAtendido = new EventEmitter();

  @Output()
  confirmSomenteRetirada = new EventEmitter();

  @Output()
  confirmSolicitacaoCadastrada = new EventEmitter();
}
