import { formatRFC3339 } from 'date-fns';
import {
  INotificacao,
  INotificacaoDTO,
  PushNotificationData,
} from '../interfaces';
import {
  clienteNotificacoesNaoVisualizadaMock,
  clienteNotificacoesVisualizadaMock,
} from './cliente-notificacoes.mock';

const now = new Date();
const nowSTR = formatRFC3339(now, { fractionDigits: 3 });

export const notificacaoMock1 = {
  id: 1,
  titulo: 'Notificacao 1',
  descricao: 'Descrição Notificacao 1',
  created: now,
  modified: now,
  filial_id: 1,
  data_envio: new Date('2021-06-22T12:30:00-03:00'),
  data_final: now,
  cliente_notificacoes: [clienteNotificacoesNaoVisualizadaMock],
} as INotificacao;

export const notificacaoMock2 = {
  id: 2,
  titulo: 'Notificacao 2',
  descricao: 'Descrição Notificacao 2',
  created: now,
  modified: now,
  filial_id: 2,
  data_envio: now,
  data_final: now,
  cliente_notificacoes: [clienteNotificacoesVisualizadaMock],
} as INotificacao;

export const notificacaoMock3 = {
  id: 3,
  titulo: 'Notificacao 3',
  descricao: 'Descrição Notificacao 3',
  created: now,
  modified: now,
  filial_id: 3,
  data_envio: now,
  data_final: now,
  cliente_notificacoes: [clienteNotificacoesNaoVisualizadaMock],
} as INotificacao;

export const notificacaoMock4 = {
  id: 4,
  descricao: 'Se liga nessa promoção especial de início de ano',
  titulo: 'Olha só o que preparamos pra você 😍',
  created: now,
  modified: now,
  filial_id: 3,
  data_envio: now,
  data_final: now,
  cliente_notificacoes: [clienteNotificacoesNaoVisualizadaMock],
} as INotificacao;

export const notificacaoMockDTO1 = {
  ...notificacaoMock1,
  created: nowSTR,
  modified: nowSTR,
  data_envio: nowSTR,
  data_final: nowSTR,
} as INotificacaoDTO;

export const notificacaoMockDTO2 = {
  ...notificacaoMock2,
  created: nowSTR,
  modified: nowSTR,
  data_envio: nowSTR,
  data_final: nowSTR,
} as INotificacaoDTO;

export const notificacaoMockDTO3 = {
  ...notificacaoMock3,
  created: nowSTR,
  modified: nowSTR,
  data_envio: nowSTR,
  data_final: nowSTR,
} as INotificacaoDTO;

export const notificacaoPushProdutoMock = {
  model: 'Produto',
  id: 0,
  foreign_key: 0,
  oferta: null,
  produto: '123',
  colecao: null,
  notId: 0,
  title: 'Título notificação',
  body: 'Conteúdo notificação',
  priority: 0,
} as PushNotificationData;

export const notificacaoPushTransacionalSemPageMock = {
  model: 'push_transacional',
  id: 0,
  foreign_key: 0,
  oferta: null,
  produto: null,
  colecao: null,
  notId: 0,
  title: 'Título notificação',
  body: 'Conteúdo notificação',
  priority: 0,
} as PushNotificationData;

export const notificacaoPushTransacionalCompraMock = {
  ...notificacaoPushTransacionalSemPageMock,
  page: 'DetalheDoPedido',
  compra: '123',
} as PushNotificationData;

export const notificacaoPushTransacionalProdutoMock = {
  ...notificacaoPushTransacionalSemPageMock,
  page: 'ProdutoDetalhe',
  produto: '123',
  produto_descricao: 'Descrição do produto',
} as PushNotificationData;

export const notificacaoPushTransacionalCarrinhoMock = {
  ...notificacaoPushTransacionalSemPageMock,
  page: 'Carrinho',
} as PushNotificationData;

export const notificacaoPushTransacionalReceitaMock = {
  ...notificacaoPushTransacionalSemPageMock,
  page: 'Receita',
  receita: '123',
} as PushNotificationData;

export const notificacaoPushTransacionalIndicacaoMock = {
  ...notificacaoPushTransacionalSemPageMock,
  page: 'Indicacoes',
} as PushNotificationData;
