<vip-validar-cep
  class="vip-page-content"
  [type]="currentView"
  (confirmSolicitarCep)="onConfirmSolicitarCep($event)"
  (confirmSomenteRetirada)="onConfirmSomenteRetirada()"
  (confirmCepNaoAtendido)="onConfirmCepNaoAtendido($event)"
  (confirmSolicitacaoCadastrada)="onConfirmSolicitacaoCadastrada()"
  [cepInvalido]="(error$ | async) !== null"
  [loading]="(loading$ | async) === true"
></vip-validar-cep>
