import { EventEmitter, Injectable, Type } from '@angular/core';
import { IFilter, PaginaFiltro } from '@vip/core';
import { FiltroFacade } from '../+state/filtro.facade';
import { ModalService } from '@vip/ui/modal';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

type FiltroContainerProps = {
  filtroAplicado: EventEmitter<void>;
  navegacaoConfirma: boolean;
};
@Injectable({ providedIn: 'root' })
export class FiltrosUtilsService {
  private filtrosSubject = new BehaviorSubject<string | null>(null);
  filtros$ = this.filtrosSubject.asObservable();

  constructor(
    private filtroFacade: FiltroFacade,
    private modalService: ModalService<FiltroContainerProps>
  ) {}

  abrirModalFiltros(
    paginaFiltro: PaginaFiltro,
    componentContainer: Type<FiltroContainerProps>
  ): void {
    this.filtroFacade.setFiltroAtivo(paginaFiltro);
    const modalRef = this.modalService.openModal(componentContainer, {
      open: true,
      showCloseButton: false,
      bottom: false,
      fullscreen: true,
    });
    if (modalRef) {
      modalRef.instance.navegacaoConfirma = false;
      modalRef.instance.filtroAplicado.pipe(take(1)).subscribe(() => {
        this.modalService.clearModal();
      });
    }
  }

  atualizarFiltros(filtros: string): void {
    this.filtrosSubject.next(filtros);
  }
}
