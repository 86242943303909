import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CompraEmProcessoService } from '../services/compra-em-processo.service';
import { CompraEmProcessoFacade } from '../+state/compra-em-processo.facade';
import { mergeTakeOne } from '@vip/state/utils';

@Injectable()
export class CompraEmProcessoGuard implements CanActivate {
  constructor(
    private compraEmProcessoService: CompraEmProcessoService,
    private compraEmProcessoFacade: CompraEmProcessoFacade
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.compraEmProcessoFacade.compraEmProcesso$.pipe(
      mergeTakeOne(this.compraEmProcessoFacade.isLoaded$),
      filter(([, isLoaded]) => isLoaded),
      tap(([compraEmProcesso]) => {
        if (compraEmProcesso && !route.data.compraEmProcesso) {
          this.compraEmProcessoService.askFinalizarCompraEmProcesso();
        }
      }),
      map(([compraEmProcesso]) => {
        return !!compraEmProcesso === route.data.compraEmProcesso;
      })
    );
  }
}
