<div
  data-cy="preco-unidade"
  [ngClass]="{
    'is-tag': isTag && !textSmallGrey,
    'vip-color-third-default': !isTag && !textSmallGrey,
    'text-small-grey': textSmallGrey,
    'no-margin': noMargin,
    'text-[10px]': !isDesktop
  }"
>
  {{ preco | currency: 'BRL' }}/{{ siglaUnidade }}
</div>
