import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstitucionalContainerComponent } from './institucional-container/institucional-container.component';
import { ListaInstitucionalContainerComponent } from './lista-institucional-container/lista-institucional-container.component';
import { RouterModule } from '@angular/router';
import { InstitucionalModule } from '@vip/views/institucional';
import { InstitucionalStateModule } from '@vip/state/institucional';
import { CepStateModule } from '@vip/state/cep';
import { MenuContentModule } from '@vip/ui/menu-content';
import { TipoEntregaStateModule } from '@vip/state/tipo-entrega';
import { BannerStateModule } from '@vip/state/banner';
import { SimularFreteContainerComponent } from './simular-frete-container/simular-frete-container.component';
import { InstitucionalMenuContainerComponent } from './institucional-menu-container/institucional-menu-container.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: InstitucionalMenuContainerComponent,
        children: [
          {
            path: '',
            component: ListaInstitucionalContainerComponent,
            data: {
              footerResponsivoIsVisible: false,
            },
          },
          {
            path: 'simulador-de-frete',
            component: SimularFreteContainerComponent,
            data: {
              footerResponsivoIsVisible: false,
            },
          },
          {
            path: 'pagina/fale-conosco',
            redirectTo: 'fale-conosco',
          },
          {
            path: 'pagina/:slug',
            component: InstitucionalContainerComponent,
            data: {
              footerResponsivoIsVisible: false,
            },
          },
          {
            path: 'iframe/:iframeId',
            data: {
              footerResponsivoIsVisible: false,
            },
            loadChildren: async () =>
              (await import('@vip/container/iframe')).IframeContainerModule,
          },
          {
            path: 'fale-conosco',
            data: {
              footerResponsivoIsVisible: false,
            },
            loadChildren: async () =>
              (await import('@vip/container/fale-conosco'))
                .FaleConoscoContainerModule,
          },
        ],
      },
      {
        path: 'fale-conosco',
        loadChildren: async () =>
          (await import('@vip/container/fale-conosco'))
            .FaleConoscoContainerModule,
      },
    ]),
    InstitucionalModule,
    InstitucionalStateModule,
    CepStateModule,
    TipoEntregaStateModule,
    BannerStateModule,
    MenuContentModule,
  ],
  declarations: [
    InstitucionalContainerComponent,
    ListaInstitucionalContainerComponent,
    SimularFreteContainerComponent,
    InstitucionalMenuContainerComponent,
  ],
})
export class InstitucionalContainerModule {}
