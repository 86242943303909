import { SelectorUtil } from '@vip/state/utils';
import {
  INSTITUCIONAL_FEATURE_KEY,
  InstitucionalState,
} from './institucional.reducer';
import { createSelector } from '@ngrx/store';

export class InstitucionalSelectors extends SelectorUtil<InstitucionalState> {
  constructor() {
    super(INSTITUCIONAL_FEATURE_KEY);
  }

  getPoliticaDePrivacidadePage = () =>
    createSelector(this.createFeatureSelector, (state: InstitucionalState) =>
      state.data.find((page) => page.slug.startsWith('politica-de-privacidade'))
    );

  getPaginasSite = () =>
    createSelector(this.createFeatureSelector, (state: InstitucionalState) =>
      state.data.filter(
        (page) => page.aplicacao === 'todos' || page.aplicacao === 'site'
      )
    );
}

export const institucionalSelectors = new InstitucionalSelectors();
