import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EntregaRetiradaContainerComponent } from './entrega-retirada-container/entrega-retirada-container.component';
import { CarrinhoStateModule } from '@vip/state/carrinho';
import { EntregaRetiradaModule } from '@vip/views/entrega-retirada';
import { ModalModule } from '@vip/ui/modal';
import { EnderecoStateModule } from '@vip/state/endereco';
import { CentroDistribuicaoStateModule } from '@vip/state/centro-distribuicao';
import { TipoEntregaStateModule } from '@vip/state/tipo-entrega';
import { AlterarHorarioContainerComponent } from './alterar-horario-container/alterar-horario-container.component';
import { CompraEmProcessoStateModule } from '@vip/state/compra-em-processo';
import { AlterarEntregaRetiradaContainerComponent } from './alterar-entrega-retirada-container/alterar-entrega-retirada-container.component';
import { CodeScannerService } from '@vip/native/code-scanner';
import { EntregaRetiradaDesktopModule } from '@vip/views/entrega-retirada-desktop';
import { LoadingModule } from '@vip/views/loading';
import { LoadingStateModule } from '@vip/state/loading';
import { AlterarEnderecoContainerComponent } from './alterar-endereco-container/alterar-endereco-container.component';
import { EnderecoModule } from '@vip/views/endereco';
import { StepperDesktopModule } from '@vip/ui/stepper-desktop';
import { EnderecoEntregaRetiradaContainerComponent } from './endereco-entrega-retirada-container/endereco-entrega-retirada-container.component';
import { PerguntasStateModule } from '@vip/state/perguntas';
import { CoreModule } from '@vip/core';
import { TelevendasStateModule } from '@vip/state/televendas';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    RouterModule.forChild([
      {
        path: '',
        component: EntregaRetiradaContainerComponent,
      },
      {
        path: 'alterar-entrega-retirada',
        component: AlterarEntregaRetiradaContainerComponent,
      },
      {
        path: 'alterar-horario',
        component: AlterarHorarioContainerComponent,
      },
      {
        path: 'endereco/:acao',
        component: AlterarEnderecoContainerComponent,
      },
    ]),
    EntregaRetiradaModule,
    EnderecoStateModule,
    CompraEmProcessoStateModule,
    CentroDistribuicaoStateModule,
    TipoEntregaStateModule,
    CarrinhoStateModule,
    ModalModule,
    EntregaRetiradaDesktopModule,
    LoadingModule,
    LoadingStateModule,
    EnderecoModule,
    StepperDesktopModule,
    PerguntasStateModule,
    CoreModule,
    TelevendasStateModule,
  ],
  declarations: [
    EntregaRetiradaContainerComponent,
    AlterarEntregaRetiradaContainerComponent,
    AlterarHorarioContainerComponent,
    AlterarEnderecoContainerComponent,
    EnderecoEntregaRetiradaContainerComponent,
  ],
  providers: [CodeScannerService],
})
export class EntregaRetiradaContainerModule {}
