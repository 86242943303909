import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromCashback from './+state/cashback.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CashbackEffects } from './+state/cashback.effects';
import { CashbackFacade } from './+state/cashback.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCashback.CASHBACK_FEATURE_KEY,
      fromCashback.reducer
    ),
    EffectsModule.forFeature([CashbackEffects]),
  ],
  providers: [CashbackFacade],
})
export class CashbackStateModule {}
