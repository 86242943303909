import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as BucketsActions from './buckets.actions';
import * as BucketsSelectors from './buckets.selectors';
import { IBucket } from '@vip/core';

@Injectable()
export class BucketsFacade {
  bucketProduto$ = this.store.pipe(select(BucketsSelectors.getBucketProduto));
  bucketCategoria$ = this.store.pipe(
    select(BucketsSelectors.getBucketCategoria)
  );
  bucketS3$ = this.store.pipe(select(BucketsSelectors.getBucketS3));
  bucketPlaceholder$ = this.store.pipe(
    select(BucketsSelectors.getBucketPlaceholder)
  );

  constructor(private store: Store) {}

  setBuckets(buckets: IBucket[]) {
    this.store.dispatch(BucketsActions.loadBucketsSuccess({ buckets }));
  }
}
