import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core';
import { CarouselBaseDirective } from '../carousel/carousel-base.directive';

@Component({
  selector: 'vip-carousel-card',
  templateUrl: './carousel-card.component.html',
  styleUrls: ['./carousel-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.xsmall]': 'size === "xs"',
    '[class.small]': 'size === "sm"',
    '[class.medium]': 'size === "md"',
    '[class.large]': 'size === "lg"',
  },
})
export class CarouselCardComponent
  extends CarouselBaseDirective
  implements AfterViewInit
{
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' = 'md';
  @Input() showMoreBtnLabel!: string;
  @Output() showMoreClick = new EventEmitter();

  ngAfterViewInit(): void {
    this.scrollableService.slider = this.contentHeader?.nativeElement;
  }
}
