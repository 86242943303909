import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, switchMap } from 'rxjs/operators';

import {
  AddClienteRequest,
  AlterarSenhaRequest,
  ClienteApiService,
  ConsultaSolicitacaoSMS,
  EditClienteRequest,
  LoginRequest,
  RecuperarSenhaRequest,
} from '@vip/api';
import { RedefinirSenhaRequest, RedefinirSenhaWsService } from '@vip/ws';
import { FilialFacade } from '@vip/state/filial';
import { clienteSelectors } from './cliente.selectors';
import { ClienteTokenService } from '../services/cliente-token.service';
import { ICliente } from '@vip/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ClienteActions from './cliente.actions';

@Injectable()
export class ClienteFacade {
  loading$ = this.store.pipe(select(clienteSelectors.isLoading()));
  cliente$ = this.store.pipe(select(clienteSelectors.getCliente()));
  error$ = this.store.pipe(select(clienteSelectors.getError()));
  isLogged$ = this.store.pipe(select(clienteSelectors.isLogged()));
  solicitacaoSMSEfetuada$ = this.store.pipe(
    select(clienteSelectors.getSolicitacaoPin()),
    filter(
      (solicitacaoPin): solicitacaoPin is ConsultaSolicitacaoSMS =>
        solicitacaoPin !== null
    )
  );

  validarOTPSuccess$ = this.actions$.pipe(
    ofType(ClienteActions.validarOTPSuccess)
  );

  constructor(
    private store: Store,
    private clienteApiService: ClienteApiService,
    private clienteTokenService: ClienteTokenService,
    private filialFacade: FilialFacade,
    private redefinirSenhaWsService: RedefinirSenhaWsService,
    private actions$: Actions
  ) {}

  init() {
    const token = this.clienteTokenService.getToken();
    if (token) {
      this.getCliente(token);
    }
  }

  login(login: LoginRequest, redirectTo?: string, validarPin = false) {
    this.store.dispatch(
      ClienteActions.login({ login, redirectTo, validarPin })
    );
  }

  getCliente(token: string) {
    this.store.dispatch(ClienteActions.getClienteData({ token }));
  }

  logout(redirectTo?: string) {
    this.store.dispatch(ClienteActions.logout({ redirectTo }));
  }

  validarCliente(cliente: AddClienteRequest) {
    return this.filialFacade.filial$.pipe(
      switchMap((filial) =>
        this.clienteApiService.validarCliente(cliente, filial.id)
      )
    );
  }

  addCliente(addClienteRequest: AddClienteRequest) {
    this.store.dispatch(ClienteActions.addCliente({ addClienteRequest }));
  }

  editCliente(
    editClienteRequest: EditClienteRequest,
    urlToRedirect: string = 'minha-conta'
  ) {
    this.store.dispatch(
      ClienteActions.editCliente({ editClienteRequest, urlToRedirect })
    );
  }

  validarSenha(filialId: number, params: LoginRequest) {
    return this.clienteApiService.login(filialId, params);
  }

  alterarSenha(params: AlterarSenhaRequest) {
    return this.clienteApiService.alterarSenha(params);
  }

  recuperarSenha(params: RecuperarSenhaRequest) {
    return this.filialFacade.filial$.pipe(
      switchMap((filial) => {
        return this.clienteApiService.recuperarSenha(filial.id, params);
      })
    );
  }

  verificaChaveAcesso(chave: string) {
    return this.redefinirSenhaWsService.verificaChaveAcesso(chave);
  }

  redefinirSenha(params: RedefinirSenhaRequest, chave: string) {
    return this.redefinirSenhaWsService.redefinirSenha(params, chave);
  }

  solicitarExclusao(observacao?: string) {
    return this.filialFacade.filial$.pipe(
      switchMap((filial) =>
        this.clienteApiService.solicitarExclusao(filial.id, observacao)
      )
    );
  }

  cadastroIncompleto() {
    return this.clienteApiService.cadastroIncompleto();
  }

  solicitarEnvioSMS(numeroCelular: string, edicaoNumero = false) {
    this.store.dispatch(
      ClienteActions.solicitaSMS({ numeroCelular, edicaoNumero })
    );
  }

  validarOTP(code: string, msg?: string, redirectTo?: string) {
    this.store.dispatch(ClienteActions.validarOTP({ code, msg, redirectTo }));
  }

  solicitaEnvioSMSLogin(numeroCelular: string, cpf: string) {
    this.store.dispatch(
      ClienteActions.solicitaSMSLogin({ numeroCelular, cpf })
    );
  }

  validarPin(numeroCelular: string, cpf: string, codigo: string) {
    this.store.dispatch(
      ClienteActions.validaPinLogin({ numeroCelular, cpf, codigo })
    );
  }

  invalidarNumeroCelular(numeroCelular: string) {
    return this.filialFacade.filial$.pipe(
      switchMap((filial) =>
        this.clienteApiService.invalidarUsuarioOTP(filial.id, numeroCelular)
      )
    );
  }
}
