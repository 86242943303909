import { Action, createReducer, on } from '@ngrx/store';
import { ICentroDistribuicao } from '@vip/core';
import { IGenericState } from '@vip/state/utils';
import {
  confirmarCentrosDistribuicao,
  getCentroDistribuicaoByIdFailure,
  getCentroDistribuicaoByIdSuccess,
  getCentrosDistribuicao,
  getCentrosDistribuicaoFailure,
  getCentrosDistribuicaoSuccess,
  migrarCentroDistribuicao,
  migrarCentroDistribuicaoFailure,
  migrarCentroDistribuicaoSuccess,
  setCentroDistribuicaoSelecionado,
  setCentroDistribuicaoDoCarrinhoSuccess,
  setCentroDistribuicaoDoCarrinhoFailure,
} from './centro-distribuicao.actions';
import { FilialActions } from '@vip/state/filial';

export const CENTRO_DISTRIBUICAO_FEATURE_KEY = 'centro-distribuicao';

// TODO verificar a necessidade desse cdConfirmado, essa regra não é a mesma de se ele selecionar um cd? VIP-17952
export interface CentrosDistribuicaoData {
  cds: ICentroDistribuicao[];
  cdSelecionado: ICentroDistribuicao | null;
  cdConfirmado: boolean;
  possuiLojaAutonoma: boolean;
}

export type CentrosDistribuicaoState = IGenericState<CentrosDistribuicaoData>;

export const initialState: CentrosDistribuicaoState = {
  data: {
    cds: [],
    cdSelecionado: null,
    cdConfirmado: false,
    possuiLojaAutonoma: false,
  },
  status: 'pending',
  error: null,
};

const centroDistribuicaoReducer = createReducer(
  initialState,
  on(getCentrosDistribuicao, migrarCentroDistribuicao, (state) => ({
    ...state,
    status: 'loading',
    error: null,
  })),
  on(
    getCentroDistribuicaoByIdSuccess,
    setCentroDistribuicaoDoCarrinhoSuccess,
    (state, { cd }) => ({
      ...state,
      data: {
        ...state.data,
        cdSelecionado: cd,
      },
      status: 'success',
      error: null,
    })
  ),
  on(
    migrarCentroDistribuicaoSuccess,
    setCentroDistribuicaoSelecionado,
    (state, { cd }) => ({
      ...state,
      data: {
        ...state.data,
        cdConfirmado: true,
        cdSelecionado: cd,
      },
      status: 'success',
      error: null,
    })
  ),
  on(getCentrosDistribuicaoSuccess, (state, { cds }) => ({
    ...state,
    data: {
      ...state.data,
      cds,
    },
    status: 'success',
    error: null,
  })),
  on(
    getCentrosDistribuicaoFailure,
    getCentroDistribuicaoByIdFailure,
    setCentroDistribuicaoDoCarrinhoFailure,
    (state, { error }) => ({
      ...state,
      data: {
        ...initialState.data,
      },
      status: 'error',
      error: error.error ? error.error.error.message : error.message,
    })
  ),
  on(migrarCentroDistribuicaoFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error.error ? error.error.error.message : error.message,
  })),
  on(confirmarCentrosDistribuicao, (state) => ({
    ...state,
    data: {
      ...state.data,
      cdConfirmado: true,
    },
  })),
  on(
    FilialActions.getFilialPorVipCommerceFilialIdSuccess,
    (state, { possui_loja_autonoma }) => ({
      ...state,
      data: {
        ...state.data,
        possuiLojaAutonoma: possui_loja_autonoma,
      },
    })
  )
);

export function reducer(
  state: CentrosDistribuicaoState | undefined,
  action: Action
) {
  return centroDistribuicaoReducer(state, action);
}
