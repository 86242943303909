import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RippleModule } from '@vip/ui/ripple';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';
import { DropdownItemComponent } from './dropdown-item/dropdown-item.component';

@NgModule({
  imports: [CommonModule, RippleModule],
  declarations: [DropdownMenuComponent, DropdownItemComponent],
  exports: [DropdownMenuComponent, DropdownItemComponent],
})
export class DropdownMenuModule {}
