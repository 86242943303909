import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IFidelidade, IFilial, IFilialScripts } from '@vip/core';

export const getFilialPorVipCommerceFilialId = createAction(
  '[Filial/API] Get Filial Por VipCommerce Filial Id',
  props<{ vipCommerceFilialId: number }>()
);

export const getFilialPorVipCommerceFilialIdSuccess = createAction(
  '[Filial/API] Get Filial Por VipCommerce Filial Id Success',
  props<{
    filial: IFilial;
    fidelidade: IFidelidade;
    possui_loja_autonoma: boolean;
  }>()
);

export const getFilialPorVipCommerceFilialIdFailure = createAction(
  '[Filial/API] Get Filial Por VipCommerce Filial Id Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getFilialScripts = createAction(
  '[Filial/API] Get Filial Script',
  props<{ filialId: number }>()
);

export const getFilialScriptsSuccess = createAction(
  '[Filial/API] Get Filial Script Success',
  props<{ scripts: Array<IFilialScripts> }>()
);

export const getFilialScriptsFailure = createAction(
  '[Filial/API] Get Filial Script Failure',
  props<{ error: HttpErrorResponse }>()
);
