<div
  *ngIf="termosUso"
  class="vip-termos-uso-container flex flex-col flex-grow"
  [ngClass]="{ '!h-auto': isDesktopModal }"
>
  <vip-screen-title title="Termos de Uso" [customActions]="true">
    <vip-button-icon-rounded
      type="outline"
      (btnClick)="handleGoBack()"
      class="z-30 pl-1"
    >
      <i
        class="vip-color-secondary-main {{
          openAsModal ? 'icon-close' : 'icon-arrow_back'
        }}"
      ></i>
    </vip-button-icon-rounded>
  </vip-screen-title>

  <div
    *ngIf="termosUso"
    class="flex flex-col flex-grow"
    [class.m-3]="!isDesktopModal"
  >
    <div
      class="flex flex-col flex-grow break-words"
      [ngClass]="{
        '-mr-[1.875rem] pr-[1.4375rem] max-h-[60dvh] overflow-y-auto':
          isDesktopModal
      }"
    >
      <p
        class="mb-3 font-bold text-sm"
        [ngClass]="{ 'vip-modal-title': isDesktopModal }"
      >
        Versão dos termos de uso: {{ termosUso.id }}
      </p>
      <div
        class="text-sm termos-uso-texto"
        [innerHtml]="sanitizer.bypassSecurityTrustHtml(termosUso.texto)"
      ></div>
    </div>

    <div
      *ngIf="aprovacaoPendente"
      [ngClass]="{
        'mt-2 py-3': !isDesktopModal,
        'vip-modal-buttons': isDesktopModal
      }"
    >
      <vip-checkbox
        data-cy="confirm-checkbox"
        label="Li e aceito os termos de uso"
        [class.mb-4]="!isDesktopModal"
        [disabled]="loading"
        (changeCheckout)="onChangeAceiteTermosUso($event)"
      ></vip-checkbox>

      <vip-button
        data-cy="confirm-button"
        type="raised"
        [class.my-1]="!isDesktopModal"
        [size]="isDesktopModal ? 'small' : 'medium'"
        (click)="!loading && aceitarTermos()"
        [disabled]="loading || aceiteTermosUso === false"
      >
        <span> Confirmar </span>
      </vip-button>
    </div>
  </div>
</div>
