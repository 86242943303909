import { Route } from '@angular/router';
import { ManutencaoGuard } from '@vip/container/manutencao';
export const AplicativoRootRoutes: Route[] = [
  {
    path: 'manutencao',
    canActivate: [ManutencaoGuard],
    loadChildren: async () =>
      (await import('@vip/container/manutencao')).ManutencaoContainerModule,
  },
  {
    path: 'sem-conexao',
    loadChildren: async () =>
      (await import('@vip/container/sem-conexao')).SemConexaoContainerModule,
  },
  { path: '**', redirectTo: '' },
];
