<vip-stepper-desktop
  *ngIf="showStepper"
  [logoSrc]="logoSrc"
  [fallbackImageUrl]="fallbackImageUrl"
  [steps]="steps"
  [url_google]="url_google"
></vip-stepper-desktop>

<div class="content-wrapper">
  <vip-tabs-entrega-retirada
    class="w-screen"
    [enderecos]="enderecos"
    [cdsEntrega]="cdsEntrega"
    [cdsRetirada]="cdsRetirada"
    [enderecoSelecionado]="enderecoSelecionado"
    [cdSelecionado]="cdSelecionado"
    [formaEntregaFromState]="formaEntregaFromState"
    [tiposEntrega]="tiposEntrega"
    [horarioSelecionado$]="horarioSelecionado$"
    [tipoEntregaSelecionado]="tipoEntregaSelecionado"
    [changeTab$]="changeTab$"
    [exibirTiposEntrega]="exibirTiposEntrega"
    [exibirTiposRetirada]="exibirTiposRetirada"
    (formaEntregaChange)="formaEntregaChange.emit($event)"
    (enderecoEntregaChange)="enderecoEntregaChange.emit($event)"
    (cdChange)="cdChange.emit($event)"
    (horarioChange)="horarioChange.emit($event)"
    (confirmAlterarHorarioClick)="confirmAlterarHorarioClick.emit($event)"
    (tipoEntregaChanged)="tipoEntregaChanged.emit($event)"
    (editarEndereco)="editarEndereco.emit($event)"
  ></vip-tabs-entrega-retirada>

  <vip-card-detalhes-cobranca
    class="w-full max-w-[20rem]"
    confirmButtonText="Ir para pagamento"
    [isDesktop]="true"
    [showCashback]="false"
    [showContinuarComprando]="true"
    [valorCompra]="totalCompra"
    [taxaEntrega]="taxaEntrega"
    [valorDescontos]="valorDescontos"
    [descontos]="descontos"
    [taxaServico]="taxaServico"
    [disablePagamentoButton]="disablePagamentoButton"
    [cardBasicBorder]="false"
    (irParaPagamento)="criarCompraClicked.emit()"
    (continuarComprando)="goToLoja()"
  ></vip-card-detalhes-cobranca>
</div>
