import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalPerguntasModule } from '@vip/ui/modal-perguntas';

import { CardBasicoModule } from '@vip/ui/card-basico';
import { CardPerguntasSobreCompraComponent } from './card-perguntas-sobre-compra/card-perguntas-sobre-compra.component';
import { SkeletonModule } from '@vip/ui/skeleton';
import { RadioModule } from '@vip/ui/radio';

@NgModule({
  imports: [
    CommonModule,
    CardBasicoModule,
    ModalPerguntasModule,
    SkeletonModule,
    RadioModule,
  ],
  declarations: [CardPerguntasSobreCompraComponent],
  exports: [CardPerguntasSobreCompraComponent],
})
export class CardPerguntasSobreCompraModule {}
