<ng-container *ngIf="!isLoading; else loadingTemplate">
  <nav
    class="grid grid-cols-12 p-4"
    aria-label="Menu de busca"
    [attr.aria-expanded]="opened"
  >
    <div class="col-span-3 flex">
      <ul class="flex-grow" role="menubar">
        <h2 class="vip-column-title vip-color-primary-main whitespace-nowrap">
          Sugestões de Pesquisa
        </h2>
        <li *ngIf="noSuggestions; else suggestionsTemplate">
          <span class="text-xs vip-color-first-default"
            >Nenhuma sugestão encontrada</span
          >
        </li>
        <ng-template #suggestionsTemplate>
          <li *ngFor="let suggestion of suggestions; let index = index">
            <a
              role="menuitem"
              [attr.title]="suggestion"
              [attr.tabindex]="opened ? 0 : -1"
              [attr.data-cy]="'vip_termo_sugerido_' + index"
              class="vip-termo-sugerido vip-color-first-default"
              (click)="searchByTermClick.emit(suggestion)"
              (keydown)="handleSuggestionKeyboardEntry($event, suggestion)"
              >{{ suggestion }}</a
            >
          </li>
        </ng-template>
      </ul>
      <hr class="vip-divider-vertical whitespace-nowrap h-full ml-4" />
    </div>
    <ul class="col-span-9 ml-4">
      <h2 class="vip-column-title vip-color-primary-main">Produtos</h2>
      <ng-container *ngIf="noResults; else resultsTemplate">
        <article data-cy="vip-no-results">
          <h2 class="font-bold text-base vip-color-second-default">
            Sua busca não retornou resultado! ):
          </h2>
          <h3 class="font-medium text-sm vip-color-second-default my-3">
            Tente novamente, aplicando as dicas abaixo ou envie o nome do
            produto não encontrado:
          </h3>
          <ul class="text-sm vip-color-second-default">
            <li>1. Verifique se a ortografia da palavra está correta</li>
            <li>
              2. Procure utilizar o nome exato do que procura, ou utilize
              palavras relacionadas
            </li>
            <li>
              3. Para buscar um ou mais produtos, acrescente ”;” (ex.: arroz;
              feijão...)
            </li>
          </ul>
        </article>
        <div *ngIf="isLogged" class="grid grid-cols-2 p-2">
          <vip-button
            size="small"
            data-cy="vip-solicita-produtos"
            [rounded]="true"
            (btnClick)="formProcuraProduto.emit($event)"
            >Não encontrou o que procurava ?</vip-button
          >
        </div>
      </ng-container>
      <ng-template #resultsTemplate>
        <vip-item-product
          *ngFor="let product of products; trackBy: trackProductFn"
          observeVisibility
          [debounceTime]="200"
          (visible)="onVisible(product)"
          [attr.data-cy]="'vip_item_product_' + product.id"
          [product]="product"
          [opened]="opened"
          [produtosNoCarrinho]="produtosNoCarrinho"
          (spinChanged)="spinChanged.emit($event)"
          (productClick)="productClick.emit(product)"
          (tagClick)="tagClick.emit()"
          (openedConfirmDeleteModal)="openedConfirmDeleteModal.emit($event)"
        ></vip-item-product>
      </ng-template>
    </ul>
  </nav>
  <vip-button
    type="normal"
    size="small"
    data-cy="vip_all_results"
    [disabled]="noResults"
    [rounded]="false"
    (btnClick)="searchByTermClick.emit(termoPesquisado)"
  >
    <span>{{
      noResults ? 'Nenhum resultado encontrado' : 'Ver todos os resultados'
    }}</span>
  </vip-button>
</ng-container>
<ng-template #loadingTemplate>
  <vip-search-menu-skeleton></vip-search-menu-skeleton>
</ng-template>
