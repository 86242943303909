import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  selector: 'vip-somente-retirada',
  templateUrl: './somente-retirada.component.html',
  styleUrls: ['../../validar-cep/validar-cep.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SomenteRetiradaComponent {
  @Output()
  confirm = new EventEmitter();
}
