import { Action, createReducer, on } from '@ngrx/store';

import * as ListaActions from './loading.actions';
import { IGenericState } from '@vip/state/utils';

export const LOADING_FEATURE_KEY = 'loading';

export type LoadingState = IGenericState<{ textLoading?: string }>;

export const initialState: LoadingState = {
  data: { textLoading: undefined },
  error: null,
  status: 'pending',
};

const loadingReducer = createReducer(
  initialState,
  on(ListaActions.setLoading, (state, { textLoading }) => ({
    ...state,
    data: { textLoading: textLoading },
    status: 'loading',
    error: null,
  })),
  on(ListaActions.disableLoading, () => ({
    ...initialState,
  }))
);

export function reducer(state: LoadingState | undefined, action: Action) {
  return loadingReducer(state, action);
}
