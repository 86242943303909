import { createSelector } from '@ngrx/store';
import { SelectorUtil } from '@vip/state/utils';
import {
  OPCAO_FATURAMENTO_FEATURE_KEY,
  OpcaoFaturamentoState,
} from './opcao-faturamento.reducer';

class OpcaoFaturamentoSelectors extends SelectorUtil<OpcaoFaturamentoState> {
  constructor() {
    super(OPCAO_FATURAMENTO_FEATURE_KEY);
  }

  getFormaFaturamento = () =>
    createSelector(
      this.createFeatureSelector,
      (state: OpcaoFaturamentoState) => state.data?.valorFormaFaturamento
    );
  getFaturamentoPadrao = () =>
    createSelector(
      this.createFeatureSelector,
      (state: OpcaoFaturamentoState) => state.data?.valorFaturamentoPadrao
    );
  getOpcaoFaturamentoSelecionada = () =>
    createSelector(
      this.createFeatureSelector,
      (state: OpcaoFaturamentoState) => state.opcaoFaturamentoSelecionada
    );
}

export const opcaoFaturamentoSelectors = new OpcaoFaturamentoSelectors();
