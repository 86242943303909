import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseComponentSkeletonComponent } from '@vip/ui/skeleton';

@Component({
  selector: 'vip-categoria-item-skeleton',
  templateUrl: './categoria-item-skeleton.component.html',
  styleUrls: ['./categoria-item-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriaItemSkeletonComponent extends BaseComponentSkeletonComponent {}
