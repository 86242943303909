import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IOpcaoFaturamento } from '@vip/core';

export const getOpcaoFaturamento = createAction(
  '[Pagamentos Page] Get OpcaoFaturamento'
);

export const getOpcaoFaturamentoSuccess = createAction(
  '[Pagamentos/API] Get OpcaoFaturamento Success',
  props<IOpcaoFaturamento>()
);

export const getOpcaoFaturamentoFailure = createAction(
  '[Pagamentos/API] Get OpcaoFaturamento Failure',
  props<{ error: HttpErrorResponse }>()
);

export const selectOpcaoFaturamento = createAction(
  '[Select Effects] Select OpcaoFaturamento',
  props<{ opcaoFaturamentoSelecionada: string | null }>()
);
