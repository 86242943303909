<div
  class="dropdown-content"
  [class.vip-common-backdrop]="backdrop"
  [class.hide]="!open"
  [class.show]="open"
  [ngClass]="[animationDirection]"
>
  <ul
    class="shadow-xl"
    [ngClass]="{
      'scroll-style': isDesktop
    }"
  >
    <ng-content></ng-content>
  </ul>
</div>
