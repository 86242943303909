import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { mergeTakeOne } from '@vip/state/utils';
import { distinctUntilChanged, filter, startWith, take } from 'rxjs/operators';
import { DialogService } from '@vip/ui/modal';
import { ICarrinho, ICentroDistribuicao, ICompra } from '@vip/core';
import { CarrinhoFacade } from '@vip/state/carrinho';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { CompraEmProcessoFacade } from '@vip/state/compra-em-processo';
import { combineLatest, of } from 'rxjs';
import { TipoEntregaFacade } from '@vip/state/tipo-entrega';
import { ParametrosFacade } from '@vip/state/parametros';
import { ClienteFacade } from '@vip/state/cliente';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MigrarCdService {
  cds$ = this.centroDistribuicaoFacade.todosCds$;
  cdSelecionado$ = this.centroDistribuicaoFacade.cd$;
  carrinho!: ICarrinho;
  compraEmProcesso?: ICompra | null;

  constructor(
    private centroDistribuicaoFacade: CentroDistribuicaoFacade,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private carrinhoFacade: CarrinhoFacade,
    private compraEmProcessoFacade: CompraEmProcessoFacade,
    private tipoEntregaFacade: TipoEntregaFacade,
    private clienteFacade: ClienteFacade,
    private parametrosFacade: ParametrosFacade,
    private route: ActivatedRoute
  ) {
    this.carrinhoFacade.carrinho$
      .pipe(untilDestroyed(this))
      .subscribe((carrinho) => {
        this.carrinho = carrinho;
      });
    this.compraEmProcessoFacade.compraEmProcesso$
      .pipe(untilDestroyed(this))
      .subscribe((compra) => {
        this.compraEmProcesso = compra;
      });
  }

  selecionarCdViaParametro() {
    this.centroDistribuicaoFacade.getCentroDistribuicaoRetirada();
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        ),
        filter((event) => !!event.url.match(/\?cd=/))
      )
      .subscribe(() => {
        this.cds$
          .pipe(
            filter((cds) => cds.length > 0),
            distinctUntilChanged(
              (prevCds, currCds) =>
                JSON.stringify(prevCds) === JSON.stringify(currCds)
            ),
            mergeTakeOne(this.activatedRoute.queryParams, this.cdSelecionado$),
            take(1)
          )
          .subscribe(([cds, params, cdAtual]) => {
            const cdEncontrado = cds.find((cd) => cd.id == params['cd']);
            if (params['cd'] && !cdEncontrado) {
              return this.exibirMensagemCdInativo();
            }

            if (cdEncontrado && cdEncontrado.id !== cdAtual.id) {
              combineLatest([
                this.clienteFacade.cliente$.pipe(take(1)),
                this.parametrosFacade.permitirCarrinhoAnonimo$.pipe(take(1)),
              ]).subscribe(([cliente, permitirCarrinhoAnonimo]) => {
                if (!cliente && !permitirCarrinhoAnonimo) {
                  this.migrarParaNovoCd(cdAtual, cdEncontrado);
                } else {
                  combineLatest([
                    this.carrinhoFacade.carrinho$.pipe(take(1)),
                    this.compraEmProcessoFacade.compraEmProcesso$.pipe(take(1)),
                  ]).subscribe(([carrinho, compraEmProcesso]) => {
                    if (
                      cdEncontrado.limite_itens_carrinho &&
                      carrinho.quantidade > cdEncontrado.limite_itens_carrinho
                    ) {
                      return this.exibirMensagemLimiteItensCarrinho(
                        cdEncontrado.limite_itens_carrinho
                      );
                    }
                    if (carrinho.quantidade > 0 || compraEmProcesso) {
                      this.exibirConfirmacaoMigracao(cdAtual, cdEncontrado);
                    } else {
                      this.migrarParaNovoCd(cdAtual, cdEncontrado);
                    }
                  });
                }
              });
            }
            this.limparParametroCd();
          });
      });
  }

  private exibirMensagemCdInativo() {
    this.dialogService.openDialog({
      title: 'Atenção',
      subTitle:
        'A Loja está inativa, alteração não permitida. Você permanecerá na Loja Atual.',
      open: true,
      disabled: false,
      buttonConfirmText: 'Ok, entendi!',
    });
    this.dialogService.dialogClick.subscribe(() => {
      this.limparParametroCd();
      this.dialogService.clearDialog();
    });
  }

  private exibirMensagemLimiteItensCarrinho(limite: number) {
    this.dialogService.openDialog({
      title: 'Atenção',
      subTitle: `Essa modalidade escolhida possui um limite de ${limite} itens. Favor alterar a quantidade de itens em seu carrinho ou escolher outra modalidade.`,
      open: true,
      disabled: false,
      buttonConfirmText: 'Ok, entendi!',
    });
    this.dialogService.dialogClick.subscribe(() => {
      this.limparParametroCd();
      this.dialogService.clearDialog();
    });
  }

  private exibirConfirmacaoMigracao(
    cdAtual: ICentroDistribuicao,
    novoCd: ICentroDistribuicao
  ) {
    this.dialogService.openDialog({
      title: 'Atenção',
      subTitle:
        'A confirmação desta alteração pode acarretar em alterações nos preços dos produtos e na disponibilidade dos mesmos. Deseja continuar?',
      open: true,
      disabled: false,
      buttonConfirmText: 'Sim',
      buttonCancelText: 'Cancelar',
    });

    this.dialogService.dialogClick.subscribe((res) => {
      if (res) {
        this.migrarParaNovoCd(cdAtual, novoCd);
      }
      this.dialogService.clearDialog();
    });
  }

  private migrarParaNovoCd(
    cdAtual: ICentroDistribuicao,
    novoCd: ICentroDistribuicao
  ) {
    this.tipoEntregaFacade.setFormaEntrega(false);
    this.limparParametroCd();
    this.centroDistribuicaoFacade.migrarCentroDistribuicao(cdAtual.id, novoCd);

    this.centroDistribuicaoFacade.migrarCDResponseSuccess$.subscribe(() => {
      this.centroDistribuicaoFacade.confirmarCd();
    });
  }

  private limparParametroCd() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { cd: null },
      queryParamsHandling: 'merge',
    });
  }
}
