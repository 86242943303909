<div
  *ngIf="produto"
  observeVisibility
  [debounceTime]="200"
  (visible)="!mostrarLabelPatrocinado ? this.visible.emit(produto) : null"
  vipRipple
  class="vip-card-produto flex flex-col justify-between rounded cursor-pointer vip-border"
  [attr.data-cy]="'card_produto_' + produto.id"
  [ngClass]="{
    'flex-col p-2': !isHorizontal,
    'p-3': isHorizontal
  }"
  (click)="handleCardClick()"
>
  <vip-tag
    *ngIf="
      produto.anunciado &&
      produto.disponivel &&
      !mostrarLabelPatrocinado &&
      produto.urls_eventos_ads
    "
    [classeOferta]="produtoAnunciadoTagName"
    [label]="produtoAnunciadoTagText"
    [tag]="produtoAnunciadoTagName"
    [ngClass]="{ 'absolute top-0 left-0': isHorizontal }"
  ></vip-tag>
  <vip-tag
    *ngIf="produto.em_oferta && produto.disponivel"
    [classeOferta]="produto.oferta?.classe_oferta"
    [label]="produto.oferta?.nome || ''"
    [tag]="produto.oferta?.tag"
    [ngClass]="{
      'absolute top-0 left-0': isHorizontal,
      '!top-3': adsValueParams
    }"
  ></vip-tag>

  <ng-container *ngIf="!isHorizontal; else horizontalTemplate">
    <div class="flex flex-col items-end">
      <div class="absolute inline-grid justify-center">
        <ng-container *ngTemplateOutlet="produtoAddListButton"></ng-container>
      </div>
    </div>
    <ng-container
      *ngTemplateOutlet="produtoImagem; context: { $implicit: produto }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="produtoSpin; context: { $implicit: produto }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="produtoDescricao; context: { $implicit: produto }"
    ></ng-container>
    <div class="flex flex-col">
      <ng-container *ngIf="produto.disponivel; else avisoIndisponivel">
        <ng-container
          *ngTemplateOutlet="produtoPreco; context: { $implicit: produto }"
        ></ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #horizontalTemplate>
    <ng-container
      *ngTemplateOutlet="produtoImagem; context: { $implicit: produto }"
    ></ng-container>
    <div class="flex flex-col flex-grow justify-between ml-2 w-full">
      <div class="vip-produto-infos-wrapper">
        <ng-container
          *ngTemplateOutlet="produtoDescricao; context: { $implicit: produto }"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="produtoPreco; context: { $implicit: produto }"
        ></ng-container>
      </div>
      <div class="flex justify-between items-center mt-3">
        <ng-container *ngIf="produto.disponivel; else avisoIndisponivel">
          <ng-container
            *ngTemplateOutlet="produtoSpin; context: { $implicit: produto }"
          ></ng-container>
        </ng-container>
        <ng-container
          *ngTemplateOutlet="
            produtoAddListButton;
            context: { $implicit: produto }
          "
        ></ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template #produtoImagem let-produto>
    <div class="flex flex-col items-center">
      <vip-image
        [alt]="produto.descricao"
        [attr.data-cy]="'card_produto_' + produto.id + '_imagem'"
        [defaultImageUrl]="defaultImageUrl"
        [imageUrl]="
          produto.imagem ? replaceImageSize(produto.imagem, '144x144') : ''
        "
        classNames="w-full"
        class="flex justify-center"
        [class.medium]="size === 'medium'"
        [ngClass]="{ 'mb-2 mx-1': !isHorizontal }"
      ></vip-image>

      <vip-tag-collection-group
        *ngIf="produto.colecoes"
        [attr.data-cy]="'card_produto_' + produto.id + '_tags_colecoes'"
        [collections]="produto.colecoes"
        [size]="fontSize"
        class="flex items-center"
      ></vip-tag-collection-group>
    </div>
  </ng-template>

  <ng-template #produtoSpin let-produto>
    <vip-spin
      *ngIf="produto.disponivel"
      [size]="isHorizontal ? 'ex-small' : fontSize"
      [large]="isHorizontal"
      [addIcon]="isHorizontal ? '' : 'icon-add'"
      [addLabel]="isHorizontal ? 'Adicionar ao carrinho' : ''"
      [ngClass]="{ 'w-full mr-2': isHorizontal }"
      [attr.data-cy]="'card_produto_' + produto.id + '_spin'"
      [disabled]="disableClick"
      [max]="produto | quantidadeMaximaProduto"
      [quantityUnity]="produto | quantidadeUnidadeDiferenteProduto"
      [quantity]="produto | quantidadeProduto: produtosNoCarrinho"
      [unityMeasureAbbreviation]="produto.unidade_sigla"
      [volumePrincipal]="produto.volume_principal"
      ariaControls="dropdown-carrinho"
      (changed)="handleSpinAlterado($event)"
      (adsClick)="chamarClickAds()"
      [possuiUnidadeDiferente]="
        produto.possui_unidade_diferente && !isReceitaRoute()
      "
      [seletorDePesoHabilitado]="
        produto.habilitar_seletor_unidade_peso && !isReceitaRoute()
      "
      [tipoDeMedida]="produto.seletor_medida_id"
      [seletorDeMedidaLista]="produto.seletor_medida_id"
      [produto]="produto"
      (click)="$event.stopPropagation()"
    ></vip-spin>
  </ng-template>

  <ng-template #produtoDescricao let-produto>
    <div>
      <div *ngIf="!produto.disponivel" class="mb-[15px]">
        <div
          class="flex justify-center w-full h-[35px] max-h-[35px] mb-2 items-center"
          [ngClass]="{ 'mb-2': isHorizontal }"
        >
          <span
            class="vip-card-produto-indisponivel-descricao flex text-sm sm:text-sm md:text-[16px] vip-color-error-main"
          >
            Produto indisponível
          </span>
        </div>
        <div class="flex items-center mb-1 mt-1 h-[38px] max-h-[38px]">
          <span
            class="vip-card-produto-descricao text-tiny vip-color-secondary-default mb-1'"
            data-cy="produto-descricao"
            [ngClass]="{
              'mb-1': !isHorizontal,
              'mb-1 mt-0': isHorizontal,
              'text-tiny': fontSize === 'small',
              'text-xs': fontSize !== 'small'
            }"
          >
            {{ produto.descricao }}
          </span>
        </div>
      </div>
      <div
        *ngIf="produto.disponivel"
        class="flex items-center mb-1"
        [ngClass]="produto.disponivel ? 'h-[38px] max-h-[38px]' : null"
      >
        <span
          class="vip-card-produto-descricao text-tiny vip-color-secondary-default mb-1'"
          data-cy="produto-descricao"
          [ngClass]="{
            'mb-1': !isHorizontal,
            'mb-1 mt-0': isHorizontal,
            'text-tiny': fontSize === 'small',
            'text-xs': fontSize !== 'small'
          }"
        >
          {{ produto.descricao }}
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template #produtoPreco let-produto>
    <vip-produto-preco
      *ngIf="produto.disponivel"
      [faixasParaMostrar]="
        produto.oferta?.faixas_precos ? produto.oferta?.faixas_precos.length : 2
      "
      [tamanhoMargens]="1"
      [tamanho]="
        fontSize === 'medium'
          ? TamanhoProdutoPreco.MEDIO
          : TamanhoProdutoPreco.PEQUENO
      "
      [produto]="produto"
      [hasCard]="true"
      [hasCombo]="produto.oferta?.classe_oferta === 'combo-vip'"
    ></vip-produto-preco>
  </ng-template>

  <ng-template #avisoIndisponivel>
    <div
      class="vip-card-produto-indisponivel flex"
      [ngClass]="{ 'flex-1': isHorizontal, 'mt-3': !isHorizontal }"
    >
      <vip-button
        (click)="
          aviseMeClick.emit(produto.produto_id); $event.stopPropagation()
        "
        *ngIf="!showUnavailableMessage; else avisoIndisponivelMessagem"
        [attr.data-cy]="'card_produto_btn_indisponivel_' + produto.id"
        [disabled]="disableClick"
        [ngClass]="{
          'w-full mr-2': isHorizontal,
          'flex self-end mb-1': !isHorizontal
        }"
        size="ex-small"
        type="outline"
      >
        Avise-me quando chegar
      </vip-button>

      <ng-template #avisoIndisponivelMessagem>
        <span
          [attr.data-cy]="'card_produto_msg_indisponivel_' + produto.id"
          class="vip-color-primary-main tiny:text-tiny text-xs font-medium flex text-center items-center padding_indisponivel"
          [ngClass]="{
            'w-full mr-2': isHorizontal,
            'flex self-end mb-1': !isHorizontal
          }"
        >
          Te avisaremos assim que o produto estiver disponível
        </span>
      </ng-template>
    </div>
  </ng-template>
  <ng-template #produtoAddListButton>
    <vip-button-icon-rounded
      *ngIf="showAddListButton"
      (click)="handleListClick($event)"
      [attr.data-cy]="'card_produto_' + produto.id + '_add_lista'"
      [disabled]="disableClick"
      [size]="fontSize"
      type="mini-fab"
    >
      <i class="icon-format_list_bulleted"></i>
    </vip-button-icon-rounded>
  </ng-template>
</div>
