import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { LayoutUtilsService } from '@vip/core';

@Component({
  selector: 'vip-confirmar-senha',
  templateUrl: './confirmar-senha.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmarSenhaComponent {
  @Output()
  save = new EventEmitter();
  showPassword = false;
  validations = {
    password: {
      required: 'Por favor, informe a senha',
    },
  };
  formGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public layoutUtilsService: LayoutUtilsService
  ) {
    this.formGroup = this.formBuilder.group({
      password: ['', [Validators.required]],
    });
  }

  get isDesktop(): boolean {
    return this.layoutUtilsService.isDesktop();
  }

  confirmar() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.save.emit({ ...this.formGroup.value });
    }
  }
}
